import { getWebSocket, MerchxWebSocket } from 'utils/webSocket'
import * as t from '../actionTypes'

export type WebSocketUnsubscribeFromChannelAction = BaseFSA<undefined>
type WebSocketUnsubscribeFromChannel = (channel: string, socket?: MerchxWebSocket) => MrxThunk<WebSocketUnsubscribeFromChannelAction>

const unsubscribeFromChannel: WebSocketUnsubscribeFromChannel = (channel, socket = getWebSocket()) => async (dispatch, getState) => {
  if (getState().websocket.isAlive) {
    console.log('Unsubscribe from channel started', channel)
    socket.emit('unsubscribeFromChannel', channel)
    dispatch({
      type: t.UNSUBSCRIBE_FROM_CHANNEL,
      meta: { done: true }
    })
  }
}

export { unsubscribeFromChannel }
