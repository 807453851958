import { Duration } from 'moment'

const formatInt = (int: number): string => {
  if (int < 10) {
    return `0${int}`
  }
  return `${int}`
}

const formatDuration = (duration: Duration, showMilliseconds = false): string => {
  const milliseconds = duration.milliseconds()
  const seconds = duration.seconds()
  const minutes = duration.minutes()
  const hours = duration.hours()
  const days = duration.days()
  return `${days} days ${formatInt(hours)}:${formatInt(minutes)}:${formatInt(seconds)}${
    showMilliseconds ? `.${formatInt(milliseconds)}` : ''
  }`
}

export default formatDuration
