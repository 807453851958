import { PayPalSettingsDto, CreatePayPalSettingDto } from '@merchx-v2/shared-types/dist/dto/paypalSettings'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import * as t from '../actionTypes'

export type CreatePayPalSettingAction = FSA<undefined, PayPalSettingsDto, string>
type CreatePayPalSetting = (payPalSettingData: CreatePayPalSettingDto) => MrxThunk<CreatePayPalSettingAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'createPayPalSetting', PayPalSettingsDto>

const CREATE_PAYPAL_SETTING = `
  mutation createPayPalSetting ($payPalSettingData: PayPalSettingInput!) {
    createPayPalSetting (payPalSettingData: $payPalSettingData) {
      id
      name
      accountEmail
      clientId
      secretKey
      accessToken
      createdAt
      updatedAt
    }
  }
`

const createPayPalSetting: CreatePayPalSetting = (payPalSettingData) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.CREATE_PAYPAL_SETTING,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_PAYPAL_SETTING,
      variables: { payPalSettingData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createPayPalSetting } = data
    if (!createPayPalSetting) {
      throw new Error("Can't create PayPal Setting!")
    }

    dispatch({
      type: t.CREATE_PAYPAL_SETTING,
      payload: createPayPalSetting,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.CREATE_PAYPAL_SETTING,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { createPayPalSetting }
