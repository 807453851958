import produce from 'immer'
import createReducer from 'store/createReducer'
import { isSuccessAction, isErrorAction } from 'types'
import { addItemToPagination } from 'utils/addItemToPagination'
import { removeItemFromPagination } from 'utils/removeItemFromPagination'
import { actionTypes as nt } from 'features/navigation'
import { actionTypes as wt } from 'features/workspaces'
import * as t from './actionTypes'

import { CategoriesState } from './types'
import {
  CreateCategoryAction,
  ChangeCurrentPageAction,
  ChangePageSizeAction,
  FetchCategoryAction,
  FetchCategoriesListAction,
  FetchCategoriesForOptionsAction,
  RemoveCategoryAction,
  SetProfileCategoriesAction,
  UpdateCategoryAction
} from './actions'

const initState: CategoriesState = {
  categories: {},
  categoriesForOptions: [],
  pagination: {},
  UIState: {
    isCategoriesForOptions: false,
    isCreateCategory: false,
    isFetchCategory: false,
    isListLoading: false,
    isRemoveCategory: false,
    isSetProductCategories: false,
    isUpdateCategory: false
  }
}

const setDefaults = (draft: CategoriesState, campaignId: number) => {
  if (!campaignId) {
    throw new Error('CampaignId undefined')
  }

  if (!draft.categories[campaignId]) {
    draft.categories[campaignId] = {}
  }

  if (!draft.pagination[campaignId]) {
    draft.pagination[campaignId] = {
      pages: {},
      total: 0,
      currentPage: 1,
      size: 6
    }
  }

  if (!draft.categoriesForOptions) {
    draft.categoriesForOptions = []
  }
}

const reducer = createReducer(initState, {
  [t.CREATE_CATEGORY]: (state, action: CreateCategoryAction) =>
    produce(state, (draft) => {
      draft.UIState.isCreateCategory = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isCreateCategory = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { id, campaignId } = action.payload
        setDefaults(draft, campaignId)
        draft.categories[campaignId][id] = action.payload
        addItemToPagination(draft.pagination[campaignId], id)
        draft.categoriesForOptions.length && draft.categoriesForOptions.push(action.payload)
      }
    }),

  [t.FETCH_CATEGORIES_LIST]: (state, action: FetchCategoriesListAction) =>
    produce(state, (draft) => {
      draft.UIState.isListLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isListLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { page, total, items, campaignId } = action.payload
        setDefaults(draft, campaignId)
        items.forEach((item) => {
          draft.categories[campaignId][item.id] = item
        })
        draft.pagination[campaignId].total = total
        draft.pagination[campaignId].pages[page] = items.map((item) => item.id)
      }
    }),

  [t.FETCH_CATEGORY]: (state, action: FetchCategoryAction) =>
    produce(state, (draft) => {
      draft.UIState.isFetchCategory = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isFetchCategory = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { campaignId, id: categoryId } = action.payload
        if (!draft.categories[campaignId]) {
          draft.categories[campaignId] = {}
        }
        draft.categories[campaignId][categoryId] = action.payload
      }
    }),

  [t.FETCH_CATEGORIES_FOR_OPTIONS]: (state, action: FetchCategoriesForOptionsAction) =>
    produce(state, (draft) => {
      draft.UIState.isCategoriesForOptions = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isCategoriesForOptions = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.categoriesForOptions = action.payload
      }
    }),

  [t.REMOVE_CATEGORY]: (state, action: RemoveCategoryAction) =>
    produce(state, (draft) => {
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { campaignId, categoryId } = action.payload
        if (draft.categories[campaignId][categoryId]) {
          delete draft.categories[campaignId][categoryId]
        }
        removeItemFromPagination(draft.pagination[campaignId], categoryId)
        if (draft.categoriesForOptions.length) {
          draft.categoriesForOptions = draft.categoriesForOptions.filter((item) => item.id !== categoryId)
        }
      }
    }),

  [t.UPDATE_CATEGORY]: (state, action: UpdateCategoryAction) =>
    produce(state, (draft) => {
      draft.UIState.isUpdateCategory = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isUpdateCategory = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { categoryId, categoryData, campaignId } = action.payload
        if (!draft.categories[campaignId]) {
          draft.categories[campaignId] = {}
        }
        draft.categories[campaignId][categoryId].name = action.payload.categoryData.name

        if (draft.categoriesForOptions.length) {
          const optionToUpdate = draft.categoriesForOptions.find((item) => item.id === categoryId)
          if (optionToUpdate) {
            optionToUpdate.name = categoryData.name
          }
        }
      }
    }),

  [t.SET_PRODUCT_CATEGORIES]: (state, action: SetProfileCategoriesAction) =>
    produce(state, (draft) => {
      draft.UIState.isSetProductCategories = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.error = action.payload
      }
    }),

  [t.CHANGE_CURRENT_PAGE]: (state, action: ChangeCurrentPageAction) =>
    produce(state, (draft) => {
      if (action.payload) {
        const { campaignId, page } = action.payload
        draft.pagination[campaignId].currentPage = page
      }
    }),

  [t.CHANGE_PAGE_SIZE]: (state, action: ChangePageSizeAction) =>
    produce(state, (draft) => {
      if (action.payload) {
        const { campaignId, size } = action.payload
        setDefaults(draft, campaignId)
        draft.pagination[campaignId].size = size
        // draft.pagination[campaignId].currentPage = 1
      }
    }),

  [wt.SWITCH_WORKSPACE]: (state) =>
    produce(state, (draft) => {
      draft.pagination = initState.pagination
      draft.categoriesForOptions = []
    }),

  [nt.LOCATION_CHANGE]: (state) =>
    produce(state, (draft) => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default reducer
