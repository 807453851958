import * as t from './actionTypes'
import createReducer from 'store/createReducer'
import produce from 'immer'
import { actionTypes as nt } from 'features/navigation'
import { actionTypes as wt } from 'features/workspaces'
import { isSuccessAction, isErrorAction } from 'types'
import { AuthenticationState } from './types'
import { LoginAction, RegisterAction } from './actions'
import { CreateWorkspaceAction } from 'features/workspaces/actions/createWorkspace'

const initialState: AuthenticationState = {
  user: null,
  UIState: {
    isLoading: false
  }
}

export default createReducer<AuthenticationState>(initialState, {
  [t.RESET_FORM]: (state) =>
    produce(state, (draft) => {
      delete draft.UIState.error
    }),

  [t.LOGIN]: (state, action: LoginAction) =>
    produce(state, (draft) => {
      draft.UIState.isLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.error = action.payload
        draft.UIState.isLoading = false
      }

      if (isSuccessAction(action)) {
        draft.user = action.payload
        if (action.payload.rememberMe) {
          localStorage.setItem('token', action.payload.token)
          localStorage.setItem('refreshToken', action.payload.refreshToken)
        } else {
          sessionStorage.setItem('token', action.payload.token)
          sessionStorage.setItem('refreshToken', action.payload.refreshToken)
        }

        delete draft.user.token
        delete draft.user.refreshToken
      }
    }),

  [t.REGISTER]: (state, action: RegisterAction) =>
    produce(state, (draft) => {
      draft.UIState.isLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.error = action.payload
        draft.UIState.isLoading = false
      }
    }),

  [t.REFRESH_TOKEN]: (state, action: BaseFSA<{ token: string; refreshToken: string }>) =>
    produce(state, (draft) => {
      if (action.payload) {
        if (draft.user) {
          if (state.user?.rememberMe) {
            localStorage.setItem('token', action.payload.token)
            localStorage.setItem('refreshToken', action.payload.refreshToken)
          } else {
            sessionStorage.setItem('token', action.payload.token)
            sessionStorage.setItem('refreshToken', action.payload.refreshToken)
          }
        }
      }
    }),

  [t.REFRESH_TOKEN_ERROR]: (state, action: BaseFSA<string>) =>
    produce(state, (draft) => {
      draft.UIState.error = action.payload
    }),

  [wt.CREATE_WORKSPACE]: (state, action: CreateWorkspaceAction) =>
    produce(state, (draft) => {
      if (isSuccessAction(action)) {
        draft.user.workspacesRoles.push({ workspaceId: action.payload.id, role: 'WORKSPACE_ADMIN' })
      }
    }),

  [t.LOGOUT]: (state) =>
    produce(state, (draft) => {
      draft.user = null
      sessionStorage.clear()
      localStorage.clear()
    }),

  [nt.LOCATION_CHANGE]: (state) =>
    produce(state, (draft) => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})
