import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { SimpleTextSetting } from '../types'

type SimpleTextSettingsForOptionsSelector = (state: ReduxState) => SimpleTextSetting[]
const getSimpleTextSettingsForOptions: SimpleTextSettingsForOptionsSelector = state =>
  state.simpleTextSettings.simpleTextSettingsForOptions || ([] as SimpleTextSetting[])

export const simpleTextSettingsForOptionsSelector: SimpleTextSettingsForOptionsSelector = createSelector(
  getSimpleTextSettingsForOptions,
  simpleTextSettings => simpleTextSettings
)
