import { createSelector, Selector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { NavigationState } from '../types'

const navigationStateSelector: Selector<ReduxState, NavigationState> = (state) => state.navigation

export default createSelector(navigationStateSelector, (state) => {
  const result = []
  const pathParts = state.activeTab.split('/')
  if (pathParts.length > 1) {
    result.push(`/${pathParts[1]}`)
  }

  if (pathParts.length > 2) {
    result.push(`/${pathParts[1]}/${pathParts[2]}`)
  }

  if (pathParts.length > 3) {
    result.push(`/${pathParts[1]}/${pathParts[2]}/${pathParts[3]}`)
  }

  return result
})
