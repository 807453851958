import React, { useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import moment from 'moment'
import { Table, Layout, PageHeader, Tag, Select, Typography, Tooltip, Progress, Popconfirm, Modal, Button, Card, Statistic } from 'antd'
import { CloseOutlined, RedoOutlined, SyncOutlined } from '@ant-design/icons'
import notification from 'mrx-notification'
import { TaskStatus, TaskType, TASK_TYPE } from '@merchx-v2/shared-types'
import { Breadcrumbs } from 'components'
import useInterval from 'utils/useInterval'
import useHeightResize from 'utils/useHeightResize'
import formatDuration from 'utils/formatDuration'
import * as rootStyles from 'assets/layoutStyle'
import styles from './TasksQueueList.module.scss'
import { connector, PropsFromRedux } from './container'
import { Task } from '../../types'
import { ColumnProps } from 'antd/es/table'

const tagColors: Record<TaskStatus, string> = {
  NEW: 'cyan',
  PROCESSED: 'blue',
  IN_PROGRESS: 'gold',
  CANCELED: 'volcano',
  COMPLETED: 'green'
}

const canRestart: TaskStatus[] = ['IN_PROGRESS', 'PROCESSED', 'CANCELED', 'COMPLETED']
const canCancel: TaskStatus[] = ['NEW', 'PROCESSED', 'IN_PROGRESS']

type HeightResizeRule = [number, number, number]

const rules: HeightResizeRule[] = [
  [0, 800, 5],
  [800, 1000, 8],
  [1000, 1200, 10],
  [1200, Infinity, 10]
]

const taskTypesOption = Object.values(TASK_TYPE).map((task, index) => ({
  key: `TaskType_${index + 1}`,
  value: task,
  label: task[0].toUpperCase() + task.slice(1).replaceAll('_', ' ').toLocaleLowerCase()
}))

const TasksQueueList = (props: PropsFromRedux) => {
  const {
    isLoading,
    error,
    tasksList,
    currentPage,
    total,
    totalInProgressTasks,
    totalNewTasks,
    pageSize,

    cancelTask,
    changeCurrentPage,
    changePageSize,
    fetchTasksList,
    restartTask
  } = props
  const { history } = useReactRouter()
  const [delay, setDelay] = useState(30000)
  const [selectedStatus, setSelectedStatus] = useState<TaskStatus | undefined>()
  const [selectedTaskType, setSelectedTaskType] = useState<TaskType | undefined>()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState<Task>()
  const [now, setNow] = useState(moment())

  const handleChangePageSize = (newSize: number) => {
    changePageSize(newSize)
    fetchTasksList(selectedStatus, selectedTaskType)
  }

  useHeightResize(handleChangePageSize, rules)

  useEffect(() => {
    fetchTasksList(selectedStatus, selectedTaskType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTasksList, currentPage, pageSize])

  useInterval(() => {
    fetchTasksList(selectedStatus, selectedTaskType)
  }, delay)

  useInterval(() => {
    setNow(moment())
  }, 1000)

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Tasks queue list page error!',
        description: error
      })
    }
  }, [error])

  const doConfirmCancel = async (taskId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    const isCanceled = await cancelTask(taskId)
    if (isCanceled) {
      notification.success({
        message: 'Successfully',
        description: 'Task was canceled successfully!'
      })
    }
  }

  const doConfirmRestart = async (taskId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    const isRestarted = await restartTask(taskId)
    if (isRestarted) {
      notification.success({
        message: 'Successfully',
        description: 'Task was restarted successfully!'
      })
    }
  }

  const renderDataSource = () => {
    let data: Task[] = tasksList
    if (selectedStatus) {
      data = data.filter((item) => item.status === selectedStatus)
    }
    if (selectedTaskType) {
      data = data.filter((item) => item.taskType === selectedTaskType)
    }
    return data
  }

  const handleStatusChanged = async (newStatus: TaskStatus) => {
    setSelectedStatus(newStatus)
    await fetchTasksList(newStatus, selectedTaskType)
    changeCurrentPage(1)
  }

  const handleTaskTypeChanged = async (newTaskType: TaskType) => {
    setSelectedTaskType(newTaskType)
    await fetchTasksList(selectedStatus, newTaskType)
    changeCurrentPage(1)
  }

  const handleOnRow = (record: Task) => {
    return {
      onClick: () => {
        if (record.message) {
          setIsModalVisible(true)
          setSelectedRecord(record)
        }
      }
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const columns: ColumnProps<Task>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Created At',
      render: (createdAt: Date) => {
        return moment(createdAt).format('YYYY/MM/DD HH:mm:ss')
      },
      dataIndex: 'createdAt',
      key: 'createdAt'
    },
    {
      title: 'Start time',
      render: (startTime: Date) => moment(startTime).format('YYYY/MM/DD HH:mm:ss'),
      dataIndex: 'startDate',
      key: 'startDate'
    },
    {
      title: 'Status',
      render: (record: Task) => {
        return <Tag color={tagColors[record.status]}>{record.status}</Tag>
      },
      key: 'status'
    },
    {
      title: 'Task Type',
      render: (record: Task) => {
        const taskType = (record.taskType.charAt(0).toUpperCase() + record.taskType.slice(1).toLowerCase()).replace(/_/g, ' ')
        const payloadTaskType = record.payload?.taskType
          ? (record.payload?.taskType?.charAt(0).toUpperCase() + record.payload?.taskType?.slice(1).toLowerCase()).replace(/_/g, ' ')
          : ''
        return taskType + (payloadTaskType ? ` (${payloadTaskType})` : '')
      },
      key: 'type'
    },
    {
      title: 'Progress',
      render: (record: Task) => {
        return <Progress percent={record.progress} size='small' status='active' />
      },
      key: 'progress'
    },
    {
      title: 'Retries',
      render: (record: Task) => {
        let color = 'green'
        if (record.retryNumber === 2) {
          color = 'orange'
        } else if (record.retryNumber === 3) {
          color = 'volcano'
        }
        return <Tag color={color}>{record.retryNumber}</Tag>
      }
    },
    {
      title: 'Elapsed Time',
      render: (record: Task) => {
        if (record.startedAt !== null) {
          const start = moment(record.startedAt)
          const end = record.endedAt !== null ? moment(record.endedAt) : now
          const showMilliseconds = record.endedAt !== null
          const duration = moment.duration(end.diff(start))
          return <div>{duration ? formatDuration(duration, showMilliseconds) : ''}</div>
        }
      },
      key: 'elapsedTime'
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (record: Task) => (
        <div className={styles.actionIcons}>
          {canRestart.includes(record.status) && (
            <span>
              <Popconfirm
                title='Are you sure restart this task?'
                onConfirm={(e) => doConfirmRestart(record.id, e)}
                onCancel={(e) => e?.stopPropagation()}
                okText='Yes'
                cancelText='No'
              >
                <RedoOutlined
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                />
              </Popconfirm>
            </span>
          )}
          {canCancel.includes(record.status) && (
            <span>
              <Popconfirm
                title='Are you sure cancel this task?'
                onConfirm={(e) => doConfirmCancel(record.id, e)}
                onCancel={(e) => e?.stopPropagation()}
                okText='Yes'
                cancelText='No'
              >
                <CloseOutlined
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                />
              </Popconfirm>
            </span>
          )}
        </div>
      )
    }
  ]

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title='Tasks Queue List'
          extra={[
            <Typography.Text key='1'>Status:</Typography.Text>,
            <Select
              key='2'
              placeholder='Select Status'
              allowClear
              style={{ width: 200 }}
              // @ts-ignore
              onChange={(value) => handleStatusChanged(value)}
            >
              <Select.Option key='3' value='NEW'>
                New
              </Select.Option>
              <Select.Option key='7' value='PROCESSED'>
                Processed
              </Select.Option>
              <Select.Option key='4' value='IN_PROGRESS'>
                In progress
              </Select.Option>
              <Select.Option key='5' value='CANCELED'>
                Canceled
              </Select.Option>
              <Select.Option key='19' value='COMPLETED'>
                Completed
              </Select.Option>
            </Select>,
            <Typography.Text key='6'>Type:</Typography.Text>,
            <Select
              key='7'
              placeholder='Select Type'
              allowClear
              style={{ width: 200 }}
              onChange={(value) => handleTaskTypeChanged(value as TaskType)}
              options={taskTypesOption}
            />,
            <Tooltip key='18' title='Refresh Time'>
              <Select key='12' defaultValue={30000} allowClear style={{ width: 200 }} onChange={(value) => setDelay(value)}>
                <Select.Option key='13' value={5000}>
                  5 second
                </Select.Option>
                <Select.Option key='14' value={15000}>
                  15 second
                </Select.Option>
                <Select.Option key='15' value={30000}>
                  30 second
                </Select.Option>
                <Select.Option key='16' value={60000}>
                  1 minute
                </Select.Option>
              </Select>
            </Tooltip>,
            <Tooltip title='Hard Refresh' key='19' placement='bottomRight'>
              <SyncOutlined key='17' onClick={() => fetchTasksList()} />
            </Tooltip>
          ]}
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Tasks Queue', url: '/' }
          ]}
        />
      </Layout.Content>

      <div className={styles.cardsContainer}>
        <Card className={styles.cardRate}>
          <Statistic title='Total NEWS' value={totalNewTasks} />
        </Card>
        <Card className={styles.cardRate}>
          <Statistic title='Total IN PROGRESS' value={totalInProgressTasks} />
        </Card>
      </div>

      <Layout.Content className={styles.contentComponent}>
        <Table
          columns={columns}
          dataSource={renderDataSource()}
          bordered
          rowKey={(record) => record.id}
          onRow={handleOnRow}
          loading={isLoading}
          pagination={{
            onChange: changeCurrentPage,
            current: currentPage,
            total,
            pageSize
          }}
        />
      </Layout.Content>

      <Modal
        title='Task Message'
        visible={isModalVisible}
        footer={[
          <Button key='submit' type='primary' onClick={handleCancel}>
            OK
          </Button>
        ]}
        onCancel={handleCancel}
      >
        {selectedRecord?.message ? selectedRecord.message : 'No message!'}
      </Modal>
    </Layout>
  )
}

export default connector(TasksQueueList)
