import * as t from '../actionTypes'

export type ChangeCurrentPageAction = BaseFSA<number>
type ChangeCurrentPage = (page: number) => MrxThunk<ChangeCurrentPageAction>

const changeCurrentPage: ChangeCurrentPage = page => async dispatch => {
  dispatch({
    type: t.CHANGE_CURRENT_PAGE,
    payload: page
  })
}

export { changeCurrentPage }
