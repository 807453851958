import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Init crash reporting
const env = process.env.NODE_ENV || ''
if (env && env !== 'development') {
  Sentry.init({
    dsn: 'https://4372441b03634e71b5dd84b237ea5bbd@o395089.ingest.sentry.io/5246361',
    environment: env,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  })

  LogRocket.init('wywkdv/merchx');
  LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setExtra('sessionURL', sessionURL);
    })
  })
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
