// TODO - remove becouse of legacy

import { QuizDto } from '@merchx-v2/shared-types'
import { useQuery } from 'react-query'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'quiz', QuizDto>

// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchQuiz = (quizId: number) => Promise<QuizDto>

const FETCH_QUIZ = `
  query fetchQuiz($quizId: Int!) {
    quiz(quizId: $quizId) {
      id

      name
      campaignId
      logicType
      ownerType
      ownerId
  
      questions { 
        id

        question
        imageId
        image {
          id
          name
          extension
          ownerType
          ownerId
          mimeType
          s3bucket
          s3key
          signedUrl
          createdAt
          updatedAt
        }
        quizId
    
        answers {
          id

          answer
          isCorrect
          quizResultId
      
          countOfChooses
      
          archivedAt
          createdAt
          updatedAt
        }
    
        archivedAt
        createdAt
        updatedAt
      }

      results {
        id

        result
        imageId
        image {
          id
          name
          extension
          ownerType
          ownerId
          mimeType
          s3bucket
          s3key
          signedUrl
          createdAt
          updatedAt
        }
        quizId
        link
        description
        countOfCorrects
    
        archivedAt
        createdAt
        updatedAt
      }
  
      isLogicValid
  
      countOfPlays
  
      archivedAt
      createdAt
      updatedAt
    }
  }
`

const fetchQuiz: FetchQuiz = async (quizId) => {
  try {
    if (quizId) {
      const {
        data: { data, errors }
      }: QueryResponse = await axios.post('/graphql', {
        query: FETCH_QUIZ,
        variables: { quizId }
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { quiz } = data
      if (!quiz) {
        throw new Error("Can't get quiz!")
      }

      return quiz
    }
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useQuiz = (quizId: number) => useQuery(['quiz', quizId], () => fetchQuiz(quizId))
