import React, { useEffect, useState, memo } from 'react'
import { Button, Input, Typography, Layout } from 'antd'
import useRouter from 'use-react-router'
import notification from 'mrx-notification'
import { UploadFile } from 'antd/lib/upload/interface'
import { AssetDto, CreateStoreDto } from '@merchx-v2/shared-types'

import { queryClient } from 'queryClient'
import { Loading, AvatarUploader, UploadImages, QuillEditorAntd } from 'components'
import { useRemoveAsset, useUploadAsset } from 'features/assets/hooks'

import { useStore, useUpdateStore } from '../../hooks'
import styles from './StoreSettings.module.scss'

type UpdateState = {
  mainImageUploaded: boolean
  mainImageRemoved: boolean
  avatarImageUploaded: boolean
  avatarImageRemoved: boolean
  bannerImageUploaded: boolean
  bannerImageRemoved: boolean
}

type Props = {
  storeId: number
  isActive: boolean
}

const StoreSettings: React.FC<Props> = ({ storeId, isActive }) => {
  const router = useRouter()

  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [supportEmail, setSupportEmail] = useState<string>('')

  const [hasCustomSeo, setHasCustomSeo] = useState<boolean>()
  const [seoTitle, setSeoTitle] = useState<string>('')
  const [seoUrl, setSeoUrl] = useState<string>('')
  const [seoDescription, setSeoDescription] = useState<string>('')
  const [seoKeywords, setSeoKeywords] = useState<string>('')

  const [avatarImageToUpload, setAvatarImageToUpload] = useState<UploadFile<any> | undefined>(undefined)
  const [avatarImageToRemove, setAvatarImageToRemove] = useState<AssetDto | undefined>(undefined)
  const uploadAvatarImage = useUploadAsset()
  const removeAvatarImage = useRemoveAsset()

  const [mainImageToUpload, setMainImageToUpload] = useState<UploadFile<any> | undefined>(undefined)
  const [mainImageToRemove, setMainImageToRemove] = useState<AssetDto | undefined>(undefined)
  const uploadMainImage = useUploadAsset()
  const removeMainImage = useRemoveAsset()

  const [bannerLink, setBannerLink] = useState<string>('')
  const [bannerImageToUpload, setBannerImageToUpload] = useState<UploadFile<any> | undefined>(undefined)
  const [bannerImageToRemove, setBannerImageToRemove] = useState<AssetDto | undefined>(undefined)
  const uploadBannerImage = useUploadAsset()
  const removeBannerImage = useRemoveAsset()

  const [facebookLink, setFacebookLink] = useState<string>('')
  const [twitterLink, setTwitterLink] = useState<string>('')
  const [instagramLink, setInstagramLink] = useState<string>('')
  const [linkedInLink, setLinkedInLink] = useState<string>('')
  const [pinterestLink, setPinterestLink] = useState<string>('')
  const [tiktokLink, setTiktokLink] = useState<string>('')
  const [youtubeLink, setYoutubeLink] = useState('')
  const [threadsLink, setThreadsLink] = useState('')
  const [location, setLocation] = useState('')

  const store = useStore(storeId)
  const updateStore = useUpdateStore()

  const [updateState, setUpdateState] = useState<UpdateState>({
    mainImageUploaded: false,
    mainImageRemoved: false,
    avatarImageUploaded: false,
    avatarImageRemoved: false,
    bannerImageUploaded: false,
    bannerImageRemoved: false
  })

  useEffect(() => {
    if (store.data) {
      setName(store.data.name)
      setDescription(store.data.description)
      setSupportEmail(store.data.supportEmail)

      setBannerLink(store.data.bannerLink)

      setHasCustomSeo(store.data.hasCustomSeo)
      setSeoTitle(store.data.seoTitle)
      setSeoUrl(store.data.seoUrl)
      setSeoDescription(store.data.seoDescription)
      setSeoKeywords(store.data.seoKeywords)

      setFacebookLink(store.data.facebookLink)
      setTwitterLink(store.data.twitterLink)
      setInstagramLink(store.data.instagramLink)
      setLinkedInLink(store.data.linkedInLink)
      setPinterestLink(store.data.pinterestLink)
      setTiktokLink(store.data.tiktokLink)
      setYoutubeLink(store.data.youtubeLink)
      setThreadsLink(store.data.threadsLink)

      setLocation(store.data.location)
    }
  }, [store.dataUpdatedAt])

  // Этот эффект у нас следит за процессом выгрузки картинок после обновления
  // Если все выгружено успешно то мы увидим оповещение и инвалидируем запрос
  useEffect(() => {
    if (
      updateState.avatarImageRemoved &&
      updateState.avatarImageUploaded &&
      updateState.mainImageRemoved &&
      updateState.mainImageUploaded &&
      updateState.bannerImageRemoved &&
      updateState.bannerImageUploaded
    ) {
      queryClient.invalidateQueries(['store', storeId])
      if (updateStore.isSuccess) {
        notification.success({
          message: 'Successfully',
          description: 'Store was updated successfully!'
        })
      }
    }
  }, [updateState, updateStore.isSuccess])

  const onFinishHandler = async () => {
    setUpdateState({
      mainImageUploaded: !mainImageToUpload,
      mainImageRemoved: !mainImageToRemove,
      avatarImageUploaded: !avatarImageToUpload,
      avatarImageRemoved: !avatarImageToRemove,
      bannerImageUploaded: !bannerImageToUpload,
      bannerImageRemoved: !bannerImageToRemove
    })

    const storeData: CreateStoreDto = {
      name,
      description,
      supportEmail,
      ownerType: store.data.ownerType,
      ownerId: store.data.ownerId,

      bannerLink,

      isActive,

      hasCustomSeo,
      seoUrl,
      seoTitle,
      seoDescription,
      seoKeywords,

      facebookLink,
      twitterLink,
      instagramLink,
      linkedInLink,
      pinterestLink,
      tiktokLink,
      youtubeLink,
      threadsLink,
      location
    }

    updateStore.mutate(
      { storeId: storeId, storeData },
      {
        onSuccess: () => {
          // Remove avatar asset
          if (avatarImageToRemove) {
            removeAvatarImage.mutate(
              { ownerType: 'STORE', ownerId: storeId, assetId: avatarImageToRemove.id, role: 'STORE_AVATAR' },
              {
                // Мы знаем что у нас один аватар, если их будет несколько то эта логика не будет работать
                onSuccess: () =>
                  setUpdateState((prev) => {
                    setAvatarImageToRemove(undefined)
                    return { ...prev, avatarImageRemoved: true }
                  })
              }
            )
          }

          // Upload new avatar image
          if (avatarImageToUpload) {
            uploadAvatarImage.mutate(
              {
                ownerType: 'STORE',
                ownerId: storeId,
                assetData: {
                  name: avatarImageToUpload.name,
                  type: avatarImageToUpload.type,
                  filename: avatarImageToUpload.name,
                  role: 'STORE_AVATAR'
                },
                file: avatarImageToUpload.originFileObj as File
              },
              {
                // Мы знаем что у нас один аватар, если их будет несколько то эта логика не будет работать
                // onSuccess: () => setUpdateState((prev) => ({ ...prev, avatarImagesUploaded: true }))
                onSuccess: () =>
                  setUpdateState((prev) => {
                    setAvatarImageToUpload(undefined)
                    return { ...prev, avatarImageUploaded: true }
                  })
              }
            )
          }

          // Remove main image asset
          if (mainImageToRemove) {
            removeMainImage.mutate(
              { ownerType: 'STORE', ownerId: storeId, assetId: mainImageToRemove.id, role: 'STORE_MAIN_IMAGE' },
              {
                // Мы знаем что у нас одна главная картинка, если их будет несколько то эта логика не будет работать
                // onSuccess: () => setUpdateState((prev) => ({ ...prev, mainImagesRemoved: true }))
                onSuccess: () =>
                  setUpdateState((prev) => {
                    setMainImageToRemove(undefined)
                    return { ...prev, mainImageRemoved: true }
                  })
              }
            )
          }

          // Upload new main image
          if (mainImageToUpload) {
            uploadMainImage.mutate(
              {
                ownerType: 'STORE',
                ownerId: storeId,
                assetData: {
                  name: mainImageToUpload.name,
                  type: mainImageToUpload.type,
                  filename: mainImageToUpload.name,
                  role: 'STORE_MAIN_IMAGE'
                },
                file: mainImageToUpload.originFileObj as File
              },
              {
                // Мы знаем что у нас одна главная картинка, если их будет несколько то эта логика не будет работать
                // onSuccess: () => setUpdateState((prev) => ({ ...prev, mainImagesUploaded: true }))
                onSuccess: () =>
                  setUpdateState((prev) => {
                    setMainImageToUpload(undefined)
                    return { ...prev, mainImageUploaded: true }
                  })
              }
            )
          }

          // Remove banner image asset
          if (bannerImageToRemove) {
            removeBannerImage.mutate(
              { ownerType: 'STORE', ownerId: storeId, assetId: bannerImageToRemove.id, role: 'STORE_BANNER_IMAGE' },
              {
                // Мы знаем что у нас один баннер, если их будет несколько то эта логика не будет работать
                // onSuccess: () => setUpdateState((prev) => ({ ...prev, bannerImagesRemoved: true }))
                onSuccess: () =>
                  setUpdateState((prev) => {
                    setBannerImageToRemove(undefined)
                    return { ...prev, bannerImageRemoved: true }
                  })
              }
            )
          }

          // Upload new banner image
          if (bannerImageToUpload) {
            uploadBannerImage.mutate(
              {
                ownerType: 'STORE',
                ownerId: storeId,
                assetData: {
                  name: bannerImageToUpload.name,
                  type: bannerImageToUpload.type,
                  filename: bannerImageToUpload.name,
                  role: 'STORE_BANNER_IMAGE'
                },
                file: bannerImageToUpload.originFileObj as File
              },
              {
                // Мы знаем что у нас один баннер, если их будет несколько то эта логика не будет работать
                // onSuccess: () => setUpdateState((prev) => ({ ...prev, bannerImagesUploaded: true }))
                onSuccess: () =>
                  setUpdateState((prev) => {
                    setBannerImageToUpload(undefined)
                    return { ...prev, bannerImageUploaded: true }
                  })
              }
            )
          }
        }
      }
    )
  }

  const handleDescriptionChange = (newDescription: string) => {
    setDescription(newDescription)
  }

  if (!store.data) return <Loading />

  return (
    <div>
      <Layout.Content className={styles.contentComponent}>
        <div className={styles.contentLeft}>
          <div className={styles.labeledItem}>
            <Typography className={styles.typography}>Name:</Typography>
            <Input value={name} onChange={(e) => setName(e.target.value)} placeholder='new sub store name' />
          </div>
          <div className={styles.labeledItem}>
            <Typography className={styles.typography}>Description:</Typography>
            <QuillEditorAntd limit={400} value={description} onChange={handleDescriptionChange} />
          </div>
          <div className={styles.labeledItem}>
            <Typography className={styles.typography}>Location:</Typography>
            <Input value={location} onChange={(e) => setLocation(e.target.value)} />
          </div>
          <div className={styles.labeledItem}>
            <Typography className={styles.typography}>Email Store:</Typography>
            <Input value={supportEmail} onChange={(e) => setSupportEmail(e.target.value)} />
          </div>
          <Typography className={styles.typography}>Social Media Links:</Typography>
          <div className={styles.container}>
            <div className={styles.labeledItem}>
              <Typography className={styles.typography}>Facebook</Typography>
              <Input value={facebookLink} onChange={(e) => setFacebookLink(e.target.value)} />
            </div>
            <div className={styles.labeledItem}>
              <Typography className={styles.typography}>Twitter</Typography>
              <Input value={twitterLink} onChange={(e) => setTwitterLink(e.target.value)} />
            </div>
            <div className={styles.labeledItem}>
              <Typography className={styles.typography}>Instagram</Typography>
              <Input value={instagramLink} onChange={(e) => setInstagramLink(e.target.value)} />
            </div>
            <div className={styles.labeledItem}>
              <Typography className={styles.typography}>Pinterest</Typography>
              <Input value={pinterestLink} onChange={(e) => setPinterestLink(e.target.value)} />
            </div>
            <div className={styles.labeledItem}>
              <Typography className={styles.typography}>TikTok</Typography>
              <Input value={tiktokLink} onChange={(e) => setTiktokLink(e.target.value)} />
            </div>
            <div className={styles.labeledItem}>
              <Typography className={styles.typography}>Linked In</Typography>
              <Input value={linkedInLink} onChange={(e) => setLinkedInLink(e.target.value)} />
            </div>
            <div className={styles.labeledItem}>
              <Typography className={styles.typography}>Youtube</Typography>
              <Input value={youtubeLink} onChange={(e) => setYoutubeLink(e.target.value)} />
            </div>
            <div className={styles.labeledItem}>
              <Typography className={styles.typography}>Threads</Typography>
              <Input value={threadsLink} onChange={(e) => setThreadsLink(e.target.value)} />
            </div>
          </div>
        </div>
        <div className={styles.contentRight}>
          <div className={styles.labeledItem}>
            <Typography className={styles.typography}>Logo</Typography>

            <AvatarUploader
              aspectRatio={105 / 105}
              shape='round'
              assets={store.data?.avatarImage ? [store.data.avatarImage] : []}
              filesToUpload={avatarImageToUpload ? [avatarImageToUpload] : []}
              assetsToRemove={avatarImageToRemove ? [avatarImageToRemove] : []}
              onChange={(filesToUpload, assetsToRemove) => {
                setAvatarImageToRemove(assetsToRemove.length ? assetsToRemove[0] : null)
                setAvatarImageToUpload(filesToUpload.length ? filesToUpload[0] : null)
              }}
            />
          </div>
          <div className={styles.labeledItem}>
            <Typography className={styles.typography}>Profile Banner</Typography>

            <UploadImages
              className={styles.mainImagePreviewContainer}
              aspectRatio={352 / 110}
              assets={store.data?.mainImage ? [store.data.mainImage] : []}
              filesToUpload={mainImageToUpload ? [mainImageToUpload] : []}
              assetsToRemove={mainImageToRemove ? [mainImageToRemove] : []}
              numberOfImages={1}
              onChange={(filesToUpload, assetsToRemove) => {
                setMainImageToRemove(assetsToRemove.length ? assetsToRemove[0] : null)
                setMainImageToUpload(filesToUpload.length ? filesToUpload[0] : null)
              }}
            />
          </div>
          <div className={styles.labeledItem}>
            <Typography className={styles.typography}>Promotional Banner</Typography>
            <UploadImages
              className={styles.bannerImagePreviewContainer}
              aspectRatio={352 / 110}
              assets={store.data?.bannerImage ? [store.data.bannerImage] : []}
              filesToUpload={bannerImageToUpload ? [bannerImageToUpload] : []}
              assetsToRemove={bannerImageToRemove ? [bannerImageToRemove] : []}
              numberOfImages={1}
              onChange={(filesToUpload, assetsToRemove) => {
                setBannerImageToRemove(assetsToRemove.length ? assetsToRemove[0] : null)
                setBannerImageToUpload(filesToUpload.length ? filesToUpload[0] : null)
              }}
            />
          </div>
          <div className={styles.labeledItem}>
            <Typography className={styles.typography}>Add link for Promotional Banner</Typography>
            <Input
              disabled={!(store.data?.bannerImage || bannerImageToUpload)}
              value={bannerLink}
              onChange={(e) => setBannerLink(e.target.value)}
              placeholder='Banner link'
            />
          </div>
        </div>
      </Layout.Content>
      <Layout.Footer className={styles.footer}>
        <Button onClick={router.history.goBack} className={styles.cancelButton}>
          Cancel
        </Button>
        <Button
          type='primary'
          onClick={onFinishHandler}
          loading={
            updateStore.isLoading ||
            uploadAvatarImage.isLoading ||
            removeAvatarImage.isLoading ||
            uploadMainImage.isLoading ||
            removeMainImage.isLoading ||
            uploadBannerImage.isLoading ||
            removeBannerImage.isLoading
          }
          disabled={
            updateStore.isLoading ||
            uploadAvatarImage.isLoading ||
            removeAvatarImage.isLoading ||
            uploadMainImage.isLoading ||
            removeMainImage.isLoading ||
            uploadBannerImage.isLoading ||
            removeBannerImage.isLoading
          }
        >
          Save
        </Button>
      </Layout.Footer>
    </div>
  )
}

export default memo(StoreSettings)
