import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { NginxSetting } from '../types'

type NginxSettingsForOptionsSelector = (state: ReduxState) => NginxSetting[]
const getNginxSettingsForOptions: NginxSettingsForOptionsSelector = state =>
  state.nginxSettings.nginxSettingsForOptions || ([] as NginxSetting[])

export const nginxSettingsForOptionsSelector: NginxSettingsForOptionsSelector = createSelector(
  getNginxSettingsForOptions,
  nginxSettings => nginxSettings
)
