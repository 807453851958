import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { DTGProduct } from '../types'

type DTGProductListSelector = (state: ReduxState) => DTGProduct[]
const getDTGProductsList: DTGProductListSelector = state => {
  const result = [] as DTGProduct[]
  const pageIds = state.DTGProducts.pagination.pages[state.DTGProducts.pagination.currentPage] || []
  pageIds.forEach(pageId => {
    result.push(state.DTGProducts.DTGProducts[pageId])
  })
  return result
}

export const DTGProductListSelector: DTGProductListSelector = createSelector(getDTGProductsList, list => list)
