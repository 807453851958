import React, { useEffect } from 'react'
import moment from 'moment-timezone'
import { Button, Drawer, DatePicker, InputNumber, Form, Select } from 'antd'
import notification from 'mrx-notification'
import styles from './UpdateProcessingCost.module.scss'
import { connector, PropsFromRedux, OwnProps } from './container'
import settings from 'config/settings'

const {
  costs: {
    processing: { accuracy }
  }
} = settings

const UpdateProcessingCost: React.FC<PropsFromRedux & OwnProps> = ({
  isLoading,
  saveProcessingCost,
  onClose,
  processingCost,
  productTags
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    processingCost &&
      form.setFieldsValue({
        date: moment(processingCost.date),
        paymentPlugin: processingCost.paymentPlugin,
        ...processingCost.rows.reduce(
          (acc, { productTag, value }) => ({
            ...acc,
            [productTag ? productTag.name : 'total']: (value / Math.pow(10, accuracy)).toFixed(accuracy)
          }),
          {}
        )
      })
  }, [processingCost, form])

  const onFinishHandler = async (values: any) => {
    const rows =
      productTags &&
      productTags.map((productTag) => ({
        productTagId: productTag.id,
        value: Math.round(values[productTag.name] * Math.pow(10, accuracy)),
        accuracy
      }))
    rows.push({
      productTagId: null,
      value: Math.round(values.total * Math.pow(10, accuracy)),
      accuracy
    })
    const isSaved = await saveProcessingCost({
      paymentPlugin: values.paymentPlugin,
      date: values.date.toDate(),
      rows
    })
    if (isSaved) {
      notification.success({
        message: 'Successfully',
        description: 'Processing cost was updated successfully!'
      })
      onClose()
    }
  }

  return (
    <Drawer title='Create Processing Cost' width='400' onClose={onClose} visible={!!processingCost} forceRender>
      <Form layout='vertical' form={form} hideRequiredMark onFinish={onFinishHandler}>
        <Form.Item
          name='date'
          label='Date'
          rules={[
            {
              required: true,
              message: 'Please select a date!'
            }
          ]}
        >
          <DatePicker className={styles.formItem} format='MM/DD/YYYY HH:mm:ss' showTime />
        </Form.Item>
        <Form.Item name='paymentPlugin' label='Payment Plugin' rules={[{ required: true, message: 'Please select a payment plugin!' }]}>
          <Select className={styles.formItem}>
            <Select.Option value='AUTHORIZE'>Authorize</Select.Option>
            <Select.Option value='STRIPE'>Stripe</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name='total' label='Total' rules={[{ required: true, message: 'Please input total cost' }]}>
          <InputNumber min={0} type='number' step='any' className={styles.formItem} />
        </Form.Item>
        {productTags &&
          productTags.map((productTag) => (
            <Form.Item
              name={productTag.name}
              label={productTag.name}
              key={productTag.id}
              rules={[{ required: true, message: `Please input ${productTag.name.toLowerCase()} cost` }]}
            >
              <InputNumber min={0} type='number' step='any' className={styles.formItem} />
            </Form.Item>
          ))}
        <div className={styles.buttonsContainer}>
          <Button onClick={onClose} className={styles.createButton}>
            Close
          </Button>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default connector(UpdateProcessingCost)
