import { StripeSettingsDto } from '@merchx-v2/shared-types/dist/dto/stripeSettings/stripe-settings.dto'
import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type StripeSettingSelector = (state: ReduxState, stripeSettingId?: number) => StripeSettingsDto | undefined

const getStripeSetting: StripeSettingSelector = (state, stripeSettingId) => {
  let result
  if (stripeSettingId && Object.prototype.hasOwnProperty.call(state.stripeSettings.stripeSettings, stripeSettingId)) {
    result = state.stripeSettings.stripeSettings[stripeSettingId] as StripeSettingsDto
  }
  return result
}

export const stripeSettingSelector: StripeSettingSelector = createSelector(getStripeSetting, (stripeSetting) => stripeSetting)
