import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { NginxSetting } from '../types'

export type FetchNginxSettingsForOptionsAction = FSA<undefined, NginxSetting[], string>
type FetchNginxSettingsForOptions = (searchText?: string) => MrxThunk<FetchNginxSettingsForOptionsAction>
type QueryResponse = GraphQLResponse<'nginxSettingsForOptions', NginxSetting[]>

const FETCH_NGINX_SETTINGS_FOR_OPTIONS = `
  query fetchNginxSettingsForOptions ($filters: NginxSettingsFilters!) {
    nginxSettingsForOptions (filters: $filters) {
      id
      name
    }
  }
`

const fetchNginxSettingsForOptions: FetchNginxSettingsForOptions = searchText => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_NGINX_SETTINGS_FOR_OPTIONS,
    meta: { done: false }
  })

  try {
    const {
      workspaces: {
        activeWorkspace: { id: workspaceId }
      }
    } = getState()
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_NGINX_SETTINGS_FOR_OPTIONS,
      variables: { filters: { workspaceId, searchText } }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { nginxSettingsForOptions } = data
    if (!nginxSettingsForOptions) {
      throw new Error("Can't get nginx settings!")
    }

    dispatch({
      type: t.FETCH_NGINX_SETTINGS_FOR_OPTIONS,
      payload: nginxSettingsForOptions,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_NGINX_SETTINGS_FOR_OPTIONS,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchNginxSettingsForOptions }
