import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { SimpleTextSetting } from '../types'

type SimpleTextSettingSelector = (state: ReduxState, simpleTextSettingId?: number) => SimpleTextSetting | undefined

const getSimpleTextSetting: SimpleTextSettingSelector = (state, simpleTextSettingId) => {
  let result
  if (simpleTextSettingId && Object.prototype.hasOwnProperty.call(state.simpleTextSettings.simpleTextSettings, simpleTextSettingId)) {
    result = state.simpleTextSettings.simpleTextSettings[simpleTextSettingId] as SimpleTextSetting
  }
  return result
}

export const simpleTextSettingSelector: SimpleTextSettingSelector = createSelector(
  getSimpleTextSetting,
  (simpleTextSetting) => simpleTextSetting
)
