import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

export type UpdateCampaignLandingsFromTemplateAction = FSA<undefined, boolean, string>
type UpdateCampaignLandingsFromTemplate = (
  campaignId: number,
  templatesIds: number[]
) => MrxThunk<UpdateCampaignLandingsFromTemplateAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'updateCampaignLandingsFromTemplate', boolean>

const UPDATE_CAMPAIGN_LANDINGS_FROM_TEMPLATE = `
  mutation UpdateCampaignLandingsFromTemplate ($campaignId: Int!, $templatesIds: [Int]!) {
    updateCampaignLandingsFromTemplate(campaignId: $campaignId, templatesIds: $templatesIds)
  }
`

const updateCampaignLandingsFromTemplate: UpdateCampaignLandingsFromTemplate = (campaignId, templatesIds) => async (dispatch) => {
  let result = false
  dispatch({
    type: t.UPDATE_CAMPAIGN_LANDINGS_FROM_TEMPLATE,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_CAMPAIGN_LANDINGS_FROM_TEMPLATE,
      variables: { campaignId, templatesIds }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateCampaignLandingsFromTemplate } = data
    if (!updateCampaignLandingsFromTemplate) {
      throw new Error("Can't get Top Campaigns report!")
    }

    dispatch({
      type: t.UPDATE_CAMPAIGN_LANDINGS_FROM_TEMPLATE,
      payload: updateCampaignLandingsFromTemplate,
      meta: { done: true }
    })

    result = true
  } catch (err) {
    dispatch({
      type: t.UPDATE_CAMPAIGN_LANDINGS_FROM_TEMPLATE,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return result
}

export { updateCampaignLandingsFromTemplate }
