import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

const FETCH_CAMPAIGN_LEVEL_REPORT = `
query GetCampaignAnalysisReport($filters: CampaignAnalysisReportFilters!) {
  campaignAnalysisReport(filters: $filters) {
    day
    campaignName
    pnl
    margins
    expenses
    revenue
    totalSales
    upsellTakeRate
    averageCostOfGoods
    refunds
    refundsRate
    facebookRevenue
    facebookTotalSales
    facebookTotalUpsells
    facebookTotalStickers
    facebookUpsellTakeRate
    facebookRefunds
    facebookRefundRate
    facebookAverageCostOfGoods
    adSpend
    numberOfClicks
    costPerAction
    costPerClick
    conversionRate
    emailRevenue
    emailTotalSales
    emailTotalUpsells
    emailTotalStickers
    emailUpsellTakeRate
    emailRefunds
    emailRefundRate
    emailAverageCostOfGoods
    subscriptionRevenue
    subscriptionTotalSales
    subscriptionTotalUpsells
    subscriptionTotalStickers
    subscriptionUpsellTakeRate
    subscriptionRefunds
    subscriptionRefundRate
    subscriptionAverageCostOfGoods
  }
}
`

const fetchCampaignLevelReportList = (startDate, endDate, campaignIds) => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_CAMPAIGN_LEVEL_REPORT
  })

  try {
    const {
      workspaces: {
        activeWorkspace: { id: workspaceId }
      }
    } = getState()

    const {
      data: {
        data: { campaignAnalysisReport },
        errors
      }
    } = await axios.post('/graphql', {
      query: FETCH_CAMPAIGN_LEVEL_REPORT,
      variables: { filters: { startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD'), workspaceId, campaignIds } }
    })

    if (!campaignAnalysisReport) {
      throw new Error("Can't get overview report!")
    }

    guardFromErrors(errors)
    dispatch({
      type: t.FETCH_CAMPAIGN_LEVEL_REPORT,
      payload: campaignAnalysisReport,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_CAMPAIGN_LEVEL_REPORT,
      payload: extractErrorInfo(err),
      meta: { error: true }
    })
  }
}

export default fetchCampaignLevelReportList
