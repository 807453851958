import produce from 'immer'
import * as t from './actionTypes'
import { actionTypes as nt } from 'features/navigation'
import { actionTypes as wt } from 'features/workspaces'
import { isSuccessAction, isErrorAction } from 'types'
import { SitesManagementState } from './types'
import createReducer from 'store/createReducer'
import { ChangeCurrentPageAction, FetchSitesManagementDashboardAction, RedeployAllLandingsAction } from './actions'

const initState: SitesManagementState = {
  sitesManagementDashboard: {},
  totalLandings: 0,
  activeLandings: 0,
  archiveLandings: 0,
  newLandings: 0,
  updatesAvailable: 0,
  needToRedeploy: 0,
  pagination: {
    pages: {},
    total: 0,
    currentPage: 1,
    size: 10
  },
  UIState: {
    isFetchSitesManagementDashboard: false,
    isRedeployAllLandings: false
  }
}

const reducer = createReducer(initState, {
  [t.FETCH_SITES_MANAGEMENT_DASHBOARD]: (state, action: FetchSitesManagementDashboardAction) =>
    produce(state, draft => {
      draft.UIState.isFetchSitesManagementDashboard = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isFetchSitesManagementDashboard = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const {
          items,
          total,
          totalLandings,
          activeLandings,
          archivedLandings,
          newLandings,
          updatesAvailable,
          needToRedeploy
        } = action.payload.sitesManagementDashboard
        items.forEach(item => {
          draft.sitesManagementDashboard[item.id] = item
        })
        draft.activeLandings = activeLandings
        draft.archiveLandings = archivedLandings
        draft.needToRedeploy = needToRedeploy
        draft.newLandings = newLandings
        draft.totalLandings = totalLandings
        draft.updatesAvailable = updatesAvailable
        draft.pagination.total = total
        draft.pagination.pages[action.payload.page] = items.map(item => item.id)
      }
    }),

  [t.REDEPLOY_ALL_LANDINGS]: (state, action: RedeployAllLandingsAction) =>
    produce(state, draft => {
      draft.UIState.isRedeployAllLandings = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isRedeployAllLandings = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
      }
    }),

  [t.CHANGE_CURRENT_PAGE]: (state, action: ChangeCurrentPageAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.pagination.currentPage = action.payload
      }
    }),

  [wt.SWITCH_WORKSPACE]: state =>
    produce(state, draft => {
      draft.pagination = initState.pagination
    }),

  [nt.LOCATION_CHANGE]: state =>
    produce(state, draft => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default reducer
