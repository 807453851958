import React from 'react'
import { SearchableSelectBase } from 'components'
import { PropsFromRedux, connector } from './container'

const DTGSizesSelect = (props: PropsFromRedux) => {
  const { fetchOptions, ...rest } = props

  return <SearchableSelectBase onSearch={(searchText: string) => fetchOptions(searchText)} {...rest} />
}

export default connector(DTGSizesSelect)
