import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Workspace } from '../types'

export type FetchWorkspaceAction = FSA<undefined, Workspace, string>
type FetchWorkspace = (workspaceId: number) => MrxThunk<FetchWorkspaceAction>
type QueryResponse = GraphQLResponse<'workspace', Workspace>

const FETCH_WORKSPACE = `
  query fetchWorkspace($workspaceId: Int!) {
    workspace(workspaceId: $workspaceId) {
      id
      name
      members {
        id
        userId
        email
        role
        createdAt
      }
      autoArchiveCampaignsWithoutSales
      daysWithoutSalesToArchive
      deploymentPlugin
      deploymentSettingsId
      deploymentSettingsName,
      paymentPlugin
      paymentSettingsId
      paymentSettingsName,
      fulfillmentPlugin
      fulfillmentSettingsId
      fulfillmentSettingsName,
      mailingPlugin
      mailingSettingsId
      mailingSettingsName,
      smsPlugin
      smsSettingsId
      smsSettingsName,
      shippingCost
      applyShippingOnce
      processingCost
      applyProcessingOnce
      allowMultiplePayments
    }
  }
`

const fetchWorkspace: FetchWorkspace = (workspaceId) => async (dispatch) => {
  dispatch({
    type: t.FETCH_WORKSPACE,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_WORKSPACE,
      variables: { workspaceId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { workspace } = data
    if (!workspace) {
      throw new Error("Can't get workspace data!")
    }

    dispatch({
      type: t.FETCH_WORKSPACE,
      payload: workspace,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_WORKSPACE,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchWorkspace }
