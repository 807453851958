import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { SiteType } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Dependency, DependencyInput } from '../types'

type CreateDependencyArgs = {
  ownerType: SiteType
  ownerId: number
  dependencyData: DependencyInput
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'createDependency', Dependency>

const CREATE_DEPENDENCY = `
  mutation CreateDependency($ownerType: SiteType!, $ownerId: Int!, $dependencyData: DependencyInput!) {
    createDependency(ownerType: $ownerType, ownerId: $ownerId, dependencyData: $dependencyData) {
      id
      ownerType
      ownerId
      ownerVersion
      name
      version
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type CreateDependency = (args: CreateDependencyArgs) => Promise<Dependency>

const createDependency: CreateDependency = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_DEPENDENCY,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createDependency } = data
    if (!createDependency) {
      throw new Error("Can't create dependency!")
    }

    return createDependency
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type CreateDependencyEvents = {
  onSettled: (data?: Dependency) => void
}

const createDependencyEvents: CreateDependencyEvents = {
  onSettled: (dependency) => {
    if (dependency?.id) {
      queryClient.invalidateQueries([`${dependency.ownerType.toLowerCase()}`, +dependency.ownerId])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCreateDependency = () => useMutation(createDependency, createDependencyEvents)
