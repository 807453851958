import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { ProcessingCost } from '../types'

type ProcessingCostsList = {
  items: ProcessingCost[]
  total: number
}

type Payload = ProcessingCostsList & {
  page: number
}

export type FetchProcessingCostsAction = FSA<undefined, Payload, string>
type FetchProcessingCosts = () => MrxThunk<FetchProcessingCostsAction, Promise<void>>
type QueryResponse = GraphQLResponse<'processingCostsListPage', ProcessingCostsList>

const FETCH_PROCESSING_COSTS = `
  query ProcessingCostsListPage($page: Int, $size: Int, $filters: ProcessingCostsFilters!) {
    processingCostsListPage(page: $page, size: $size, filters: $filters) {
      total
      items {
        id
        paymentPlugin
        date
        rows {
          productTag {
            id
            name
          }
          value
          accuracy
        }
      }
    }
  }
`

export const fetchProcessingCosts: FetchProcessingCosts = () => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_PROCESSING_COSTS,
    meta: { done: false }
  })

  try {
    const {
      processingCosts: {
        datesRange,
        pagination: { size, currentPage: page }
      },
      workspaces: {
        activeWorkspace: { id: workspaceId }
      }
    } = getState()
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_PROCESSING_COSTS,
      variables: {
        page,
        size,
        filters: { workspaceId, startDate: datesRange[0]?.toDate(), endDate: datesRange[1].toDate() }
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { processingCostsListPage } = data
    if (!processingCostsListPage) {
      throw new Error("Can't get processing costs!")
    }

    dispatch({
      type: t.FETCH_PROCESSING_COSTS,
      payload: {
        ...processingCostsListPage,
        page
      },
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_PROCESSING_COSTS,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}
