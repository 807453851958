import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

type ZipwhipSettingInput = {
  name: string
  username: string
  password: string
}

type Payload = {
  zipwhipSettingId: number
  zipwhipSettingData: ZipwhipSettingInput
}

export type UpdateZipwhipSettingAction = FSA<undefined, Payload, string>

type UpdateZipwhipSetting = (args: Payload) => MrxThunk<UpdateZipwhipSettingAction, Promise<boolean>>

type QueryResponse = GraphQLResponse<'updateZipwhipSetting', boolean>

const UPDATE_ZIPWHIP_SETTING = `
  mutation updateZipwhipSetting ($zipwhipSettingId: Int!, $zipwhipSettingData: ZipwhipSettingInput!) {
    updateZipwhipSetting (zipwhipSettingId: $zipwhipSettingId, zipwhipSettingData: $zipwhipSettingData)
  }
`

const updateZipwhipSetting: UpdateZipwhipSetting = (args) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.UPDATE_ZIPWHIP_SETTING,
    meta: { done: false }
  })

  try {
    const { zipwhipSettingId, zipwhipSettingData } = args
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_ZIPWHIP_SETTING,
      variables: { zipwhipSettingId, zipwhipSettingData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateZipwhipSetting } = data
    if (!updateZipwhipSetting) {
      throw new Error("Can't update Zipwhip setting!")
    }

    dispatch({
      type: t.UPDATE_ZIPWHIP_SETTING,
      payload: args,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.UPDATE_ZIPWHIP_SETTING,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { updateZipwhipSetting }
