import React from 'react'
import { Table } from 'antd'
import { Link } from 'react-router-dom'
import moment from 'moment'
import numeral from 'numeral'
import { ColumnsType } from 'antd/lib/table'
import { CustomerSubscriptionOrder } from '../../types'
import { connector, PropsFromRedux } from './container'

type Props = PropsFromRedux & {
  orders: CustomerSubscriptionOrder[]
}

const OrdersTab = (props: Props) => {
  const { orders } = props

  const columns: ColumnsType<CustomerSubscriptionOrder> = [
    {
      title: 'Order ID',
      render: (_value, record) => <Link to={`/sales/orders?orderId=${record.id}`}>{record.id}</Link>,
      key: 'orderId'
    },
    {
      title: 'Date',
      render: record => `${moment(record.createdAt).format('MM/DD/YYYY HH:mm:ss')}`,
      key: 'date'
    },
    {
      title: 'Cycle',
      dataIndex: 'subscriptionCycleNumber',
      key: 'subscriptionCycleNumber'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'BIN',
      dataIndex: 'bin',
      key: 'bin'
    },
    {
      title: 'Card last 4 digits',
      dataIndex: 'cardNumber',
      key: 'cardNumber'
    },
    {
      title: 'Expiry date',
      dataIndex: 'cardExpDate',
      key: 'cardExpDate'
    },
    {
      title: 'Total',
      render: record => `${numeral(record.total / 100).format('$0,0.00')}`,
      key: 'total'
    }
  ]

  return (
    <>
      <Table columns={columns} dataSource={orders} bordered rowKey={record => record.id} pagination={false} />
    </>
  )
}

export default connector(OrdersTab)
