import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import { connect } from 'react-redux'
import useReactRouter from 'use-react-router'
import { CheckOutlined, DownloadOutlined } from '@ant-design/icons'
import { Button, DatePicker, PageHeader, Select, Layout, Form, Drawer } from 'antd'
import notification from 'mrx-notification'
import { Breadcrumbs } from 'components'
import * as actions from '../../actions'
import * as selectors from '../../selectors'
import OverviewReportTable from '../OverviewReportTable'
import CampaignLevelReportTable from '../CampaignLevelReport'
import * as rootStyles from 'assets/layoutStyle'
import styles from './OverviewReportList.module.scss'

const dateFormat = 'MM/DD/YYYY'

const OverviewReportList = ({
  campaigns,
  overviewReportRows,
  campaignLevelRows,
  isLoading,
  isLoadingCsv,
  error,
  fetchOverviewReportList,
  fetchCampaignsForOptions,
  fetchCampaignLevelReportList,
  fetchCampaignAnalysisCsvReport,
  fetchOverviewCsvReport
}) => {
  const { history } = useReactRouter()
  const [startDate, setStartDate] = useState(moment().startOf('day'))
  const [endDate, setEndDate] = useState(
    moment()
      .add(1, 'day')
      .endOf('day')
  )
  const [selectedCampaign, setSelectedCampaign] = useState()
  const [selectedView, setSelectedView] = useState('all')
  const [isVisible, setVisible] = useState(false)

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Overview report page error!',
        description: error
      })
    }
  }, [error])

  const [form] = Form.useForm()

  const isOverviewReport = !selectedCampaign
  const reportRows = isOverviewReport ? overviewReportRows : campaignLevelRows

  useEffect(() => {
    fetchCampaignsForOptions()
  }, [fetchCampaignsForOptions])

  const onDateChange = dates => {
    setStartDate(dates[0])
    setEndDate(dates[1])
  }

  const handleCampaignChange = value => {
    setSelectedCampaign(value)
  }

  const handleViewChange = value => {
    setSelectedView(value)
  }

  const handleSubmit = () => {
    if (isOverviewReport) {
      fetchOverviewReportList(startDate, endDate)
    } else {
      fetchCampaignLevelReportList(startDate, endDate, selectedCampaign)
    }
  }

  const handleClose = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleExportCSV = async () => {
    let signedUrl
    if (isOverviewReport) {
      signedUrl = await fetchOverviewCsvReport(startDate, endDate)
    } else {
      signedUrl = await fetchCampaignAnalysisCsvReport(startDate, endDate, selectedCampaign)
    }
    signedUrl && window.open(signedUrl, '_blank')
  }

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          style={{ position: 'fixed', zIndex: 1, width: 'auto', minWidth: '88vw', border: 'none' }}
          className={styles['overview-report-list__page-header']}
          onBack={() => history.goBack()}
          title={isOverviewReport ? 'Overview report' : 'Campaign level report'}
          extra={[
            <Button
              key='1'
              onClick={() => {
                setVisible(!isVisible)
              }}
            >
              Settings
            </Button>,
            <DatePicker.RangePicker
              key='5'
              defaultValue={[startDate, endDate]}
              format={dateFormat}
              ranges={{
                Today: [
                  moment().startOf('day'),
                  moment()
                    .add(1, 'day')
                    .endOf('day')
                ],
                'Last 7 days': [moment().subtract(7, 'days'), moment().startOf('day')],
                'This Week': [moment().startOf('week'), moment().endOf('week')],
                'This Month': [moment().startOf('month'), moment().endOf('month')]
              }}
              onChange={onDateChange}
              allowClear={false}
            />,
            <Button key='6' type='primary' icon={<CheckOutlined />} onClick={handleSubmit} loading={isLoading} disabled={isLoading}>
              Go
            </Button>,
            <Button key='7' icon={<DownloadOutlined />} disabled={isLoadingCsv} loading={isLoadingCsv} onClick={handleExportCSV}>
              CSV
            </Button>
          ]}
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Reporting', url: '/' },
            { title: 'Overview', url: '/reporting/overview' }
          ]}
        />
      </Layout.Content>
      <Layout.Content style={rootStyles.contentComponent}>
        <div className={styles['overview-report-list__table-container']}>
          {!isOverviewReport && (
            <CampaignLevelReportTable
              selectedView={selectedView}
              campaignLevelRows={reportRows}
              isLoading={isLoading}
              campaigns={campaigns}
            />
          )}
          {isOverviewReport && (
            <OverviewReportTable
              overviewReportRows={reportRows}
              selectedView={selectedView}
              startDate={startDate}
              endDate={endDate}
              isLoading={isLoading}
            />
          )}
        </div>
      </Layout.Content>

      <Drawer title='Overview Settings' width='400' onClose={handleClose} visible={isVisible}>
        <Form layout='vertical' hideRequiredMark form={form} initialValues={{ view: selectedView }}>
          <Form.Item name='campaignIds' label='Campaigns'>
            <Select
              mode='multiple'
              key='2'
              placeholder='Please select Campaign'
              onChange={handleCampaignChange}
              allowClear
              value={selectedCampaign}
            >
              {campaigns.map(item => {
                return (
                  <Select.Option value={item.id} key={item.id}>
                    {item.name}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>

          <Form.Item name='view' label='View'>
            <Select key='4' placeholder='View' style={{ width: '100%' }} onChange={value => handleViewChange(value)} allowClear={false}>
              <Select.Option value='all'>All</Select.Option>
              <Select.Option value='facebook'>Facebook</Select.Option>
              <Select.Option value='ecom'>Ecom</Select.Option>
              <Select.Option value='email'>Email</Select.Option>
              <Select.Option value='subscription'>Subscription</Select.Option>
            </Select>
          </Form.Item>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button type='primary' htmlType='submit' loading={isLoading} onClick={handleClose}>
              Close
            </Button>
          </div>
        </Form>
      </Drawer>
    </Layout>
  )
}

const mapStateToProps = state => ({
  campaigns: selectors.campaignsForOptionsSelector(state),
  overviewReportRows: selectors.overviewReportRowsSelector(state),
  campaignLevelRows: selectors.campaignLevelRowsSelector(state),
  isLoading: selectors.isLoadingListOverviewReportSelector(state),
  isLoadingCsv: selectors.isLoadingListOverviewCsvReportSelector(state),
  error: selectors.errorListOverviewReportSelector(state)
})

const mapDispatchToProps = {
  fetchOverviewReportList: actions.fetchOverviewReportList,
  fetchOverviewCsvReport: actions.fetchOverviewCsvReport,
  fetchCampaignsForOptions: actions.fetchCampaignsForOptions,
  fetchCampaignLevelReportList: actions.fetchCampaignLevelReportList,
  fetchCampaignAnalysisCsvReport: actions.fetchCampaignAnalysisCsvReport
}
export default connect(mapStateToProps, mapDispatchToProps)(OverviewReportList)
