import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { ProductTag, ProductTagsState } from '../types'
import featureStateSelector from './featureStateSelector'

export const productTagsListSelector = createSelector<ReduxState, ProductTagsState, ProductTag[]>(
  featureStateSelector,
  state => {
    const { pages, currentPage } = state.pagination
    return (pages[currentPage] || []).map(id => state.productTags[id])
  }
)
