import React, { useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import moment, { Moment } from 'moment'
import { Chart } from 'react-google-charts'
import { Layout, PageHeader, DatePicker, Button } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import notification from 'mrx-notification'
import { Breadcrumbs, Loading } from 'components'
import { RangeValue } from 'rc-picker/lib/interface'
import timeHelpers from 'utils/timeHelpers'
import * as rootStyles from 'assets/layoutStyle'
import styles from './TopCampaignsRateReport.module.scss'
import { connector, PropsFromRedux } from './container'

type Params = {
  startDate?: string
  endDate?: string
}

const TopCampaignsRateReport = (props: PropsFromRedux) => {
  const {
    topCampaignsRateReport,
    isLoading,
    error,

    fetchTopCampaignsRateReport
  } = props
  const { history, match } = useReactRouter()

  const params = match.params as Params
  const [startDate, setStartDate] = useState<Moment>(params.startDate ? moment(params.startDate) : moment().startOf('day'))
  const [endDate, setEndDate] = useState<Moment>(
    params.endDate
      ? moment(params.endDate)
      : moment()
          .add(1, 'day')
          .endOf('day')
  )

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Top Campaigns Rate report error!',
        description: error
      })
    }
  }, [error])

  useEffect(() => {
    fetchTopCampaignsRateReport(timeHelpers.getNYStartOfDay(startDate), timeHelpers.getNYEndOfDay(endDate))
  }, [fetchTopCampaignsRateReport, startDate, endDate])

  const handleSubmit = () => {
    fetchTopCampaignsRateReport(timeHelpers.getNYStartOfDay(startDate), timeHelpers.getNYEndOfDay(endDate))
  }

  const onDateChange = (dates: RangeValue<Moment>) => {
    if (dates && dates[0] && dates[1]) {
      setStartDate(dates[0])
      setEndDate(dates[1])
    }
  }

  const data = topCampaignsRateReport.reduce(
    (accum, current) => {
      const total = current.total / 100
      // @ts-ignore
      accum.push([current.name, total])
      return accum
    },
    [['DonutChart', 'Rate']]
  )

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title='Top Campaigns Rate Report'
          extra={[
            <DatePicker.RangePicker
              key='2'
              defaultValue={[startDate, endDate]}
              format='MM/DD/YYYY'
              ranges={{
                Today: [
                  moment().startOf('day'),
                  moment()
                    .add(1, 'day')
                    .endOf('day')
                ],
                'Last 7 days': [moment().subtract(7, 'days'), moment().startOf('day')],
                'This Week': [moment().startOf('week'), moment().endOf('week')],
                'This Month': [moment().startOf('month'), moment().endOf('month')]
              }}
              onChange={onDateChange}
            />,
            <Button key='3' type='primary' icon={<CheckOutlined />} onClick={handleSubmit} loading={isLoading}>
              Go
            </Button>
          ]}
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Reports', url: '/' },
            { title: 'Top Campaigns Rate', url: '/' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <div className={styles.donutChart}>
          <Chart
            width='1000px'
            height='560px'
            chartType='PieChart'
            loader={isLoading && <Loading />}
            data={data}
            options={{
              pieHole: 0.4
            }}
            rootProps={{ 'data-testid': '3' }}
          />
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default connector(TopCampaignsRateReport)
