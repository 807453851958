import { PluginType } from '@merchx-v2/shared-types'
import { useQuery } from 'react-query'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

import { PluginSettingSettings } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'defaultPluginSettings', PluginSettingSettings[]>

const GET_DEFAULT_CUSTOM_GATEWAY_SETTINGS = `
query defaultPluginSettings ($pluginType: PluginType!, $pluginName: String!) {
  defaultPluginSettings (pluginType: $pluginType, pluginName: $pluginName) {
    setting
    value
  }
}
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type GetDefaultPluginSettings = (pluginType: PluginType, pluginName: string) => Promise<PluginSettingSettings[]>

const getDefaultPluginSettings: GetDefaultPluginSettings = async (pluginType, pluginName) => {
  if (!pluginType || !pluginName) return null

  let getDefaultSettings = true
  switch (pluginName) {
    case 'CUSTOM_GATEWAY': {
      pluginName = 'CUSTOM_GATEWAY'
      break
    }
    default: {
      getDefaultSettings = false
    }
  }

  if (!getDefaultSettings) return null

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: GET_DEFAULT_CUSTOM_GATEWAY_SETTINGS,
      variables: { pluginType, pluginName }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { defaultPluginSettings } = data
    if (!defaultPluginSettings) {
      throw new Error("Can't get default plugin settings!")
    }

    return defaultPluginSettings
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

export const useGetDefaultPluginSettings = (pluginType?: PluginType, pluginName?: string) =>
  useQuery(['defaultPluginSettings', pluginType, pluginName], () => getDefaultPluginSettings(pluginType, pluginName))
