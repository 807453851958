import { PluginSettingOwner, PluginType } from '@merchx-v2/shared-types'
import { useQuery } from 'react-query'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

import { PluginSetting } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'pluginSettings', PluginSetting>

const FETCH_PLUGIN_SETTINGS = `
query pluginSettings ($workspaceId: Int!, $ownerType: PluginSettingOwner!, $ownerId: Int!, $pluginType: PluginType!) {
  pluginSettings (workspaceId: $workspaceId, ownerType: $ownerType, ownerId: $ownerId, pluginType: $pluginType) {
    id
    workspaceId
    ownerType
    ownerId
    pluginType
    pluginName
    actualFrom
    settings {
      setting
      value
    }
    createdAt
    updatedAt
  }
}
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchPluginSettings = (
  workspaceId: number,
  ownerType: PluginSettingOwner,
  ownerId: number,
  pluginType?: PluginType
) => Promise<PluginSetting>

const fetchPluginSettings: FetchPluginSettings = async (workspaceId, ownerType, ownerId, pluginType) => {
  if (!pluginType) return null

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_PLUGIN_SETTINGS,
      variables: { workspaceId, ownerType, ownerId, pluginType }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { pluginSettings } = data
    if (!pluginSettings) {
      throw new Error("Can't get plugin settings!")
    }

    return pluginSettings
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const usePluginSettings = (workspaceId: number, ownerType: PluginSettingOwner, ownerId: number, pluginType?: PluginType) =>
  useQuery(['pluginSettings', workspaceId, ownerType, ownerId, pluginType], () =>
    fetchPluginSettings(workspaceId, ownerType, ownerId, pluginType)
  )
