import React, { useState } from 'react'
import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters
} from 'react-query'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Button, InputNumber, Radio, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { InventoryDto } from '@merchx-v2/shared-types'
import { InventoryPage } from '../../hooks/useInventoryList'
import styles from './InventoryList.module.scss'

type Props = {
  inventory: InventoryDto[]
  disabled?: boolean
  dataLength: number
  isLoading: boolean
  hasNextPage: boolean
  fetchNextPage: (options?: FetchNextPageOptions) => Promise<InfiniteQueryObserverResult<InventoryPage, unknown>>
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<InfiniteData<InventoryPage>, unknown>>

  onAddVariantItemsToStock: (productId: number, variantKey: string, quantity: number) => void
  onSetVariantItemsOnStock: (productId: number, variantKey: string, quantity: number) => void
  onMessage: (message: string) => void
}

const InventoryList = (props: Props) => {
  const [activeMode, setActiveMode] = useState<Record<string, 'Add' | 'Set'>>({})
  const [quantities, setQuantities] = useState<Record<string, number>>({})

  const columns: ColumnsType<InventoryDto> = [
    {
      title: 'Product',
      dataIndex: ['product', 'name'],
      render: (_value, record) => (
        <div className={styles.variantNameContainer}>
          <span>{record.product?.displayName || ''}</span>
          <span className={styles.variantKey}>{record.variantKey}</span>
        </div>
      )
    },
    {
      title: 'Available',
      dataIndex: 'stock'
    },
    {
      title: 'When sold out',
      render: (_value, record) => (record.product?.sellWhenOutOfStock ? 'allow to sell' : 'stop selling')
    },
    {
      title: 'Edit quantity',
      render: (_value, record) => (
        <div key={`${record.productId}/${record.variantKey}`} className={styles.actionsContainer}>
          <Radio.Group
            options={['Add', 'Set']}
            optionType='button'
            buttonStyle='solid'
            onChange={(e) => setActiveMode((prev) => ({ ...prev, [`${record.productId}/${record.variantKey}`]: e.target.value }))}
            value={activeMode[`${record.productId}/${record.variantKey}`] || 'Add'}
          />
          <InputNumber
            min={0}
            onChange={(newValue) => setQuantities((prev) => ({ ...prev, [`${record.productId}/${record.variantKey}`]: newValue }))}
            value={quantities[`${record.productId}/${record.variantKey}`] || 0}
          />
          <Button
            disabled={!quantities[`${record.productId}/${record.variantKey}`]}
            onClick={async () => {
              if (!props.disabled) {
                const operationType = activeMode[`${record.productId}/${record.variantKey}`] || 'Add'
                switch (operationType) {
                  case 'Add': {
                    await props.onAddVariantItemsToStock(
                      record.productId,
                      record.variantKey,
                      quantities[`${record.productId}/${record.variantKey}`]
                    )
                    break
                  }

                  case 'Set':
                  default: {
                    await props.onSetVariantItemsOnStock(
                      record.productId,
                      record.variantKey,
                      quantities[`${record.productId}/${record.variantKey}`]
                    )
                    break
                  }
                }
                setQuantities((prev) => ({ ...prev, [`${record.productId}/${record.variantKey}`]: 0 }))
              } else {
                // Form disabled
                props.onMessage('You need to save the changes first!')
              }
            }}
          >
            Save
          </Button>
        </div>
      )
    }
  ]

  return (
    <InfiniteScroll
      style={{ paddingBottom: '30px' }}
      dataLength={props.dataLength}
      next={props.fetchNextPage}
      hasMore={props.hasNextPage}
      loader={<h4>Loading...</h4>}
      refreshFunction={props.refetch}
      scrollThreshold={0.8}
    >
      <Table
        columns={columns}
        dataSource={props.inventory}
        bordered
        rowKey={(record, index) => `${index}/${record.productId}/${record.variantKey}`}
        loading={props.isLoading}
        pagination={false}
      />
    </InfiniteScroll>
  )
}

export default InventoryList
