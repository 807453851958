import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { ShippingMethodDto, UpdateShippingMethodDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'updateShippingMethod', boolean>

const UPDATE_METHOD = `
  mutation updateShippingMethod($shippingMethodId: Int!, $shippingMethodData: ShippingMethodInput!) {
    updateShippingMethod(shippingMethodId: $shippingMethodId, shippingMethodData: $shippingMethodData)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateShippingMethodArgs = {
  shippingMethodId: number
  shippingMethodData: UpdateShippingMethodDto
}

type UpdateShippingMethod = (args: UpdateShippingMethodArgs) => Promise<UpdateShippingMethodArgs>

const updateShippingMethod: UpdateShippingMethod = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_METHOD,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateShippingMethod } = data
    if (!updateShippingMethod) {
      throw new Error("Can't update shippingMethod!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateShippingMethodContext = { prevShippingMethod?: ShippingMethodDto }

type UpdateShippingMethodEvents = {
  onMutate: (variables: UpdateShippingMethodArgs) => Promise<UpdateShippingMethodContext>
  onError: (error: string, variables: UpdateShippingMethodArgs, context: UpdateShippingMethodContext) => void
  onSettled: (data?: UpdateShippingMethodArgs) => void
}

const updateShippingMethodEvents: UpdateShippingMethodEvents = {
  onMutate: async (variables: UpdateShippingMethodArgs) => {
    await queryClient.cancelQueries(['shippingMethod', variables.shippingMethodId])

    // Snapshot the previous value
    const prevShippingMethod = queryClient.getQueryData<ShippingMethodDto>(['shippingMethod', variables.shippingMethodId])

    // Optimistically update to the new values
    if (prevShippingMethod) {
      queryClient.setQueryData<ShippingMethodDto>(['shippingMethod', variables.shippingMethodId], {
        ...prevShippingMethod,
        id: variables.shippingMethodId,
        ...variables.shippingMethodData
      })
    }

    return { prevShippingMethod }
  },
  onError: (_err, variables, context) => {
    if (context?.prevShippingMethod) {
      // Restore previous version of country on any error
      queryClient.setQueryData<ShippingMethodDto>(['shippingMethod', variables.shippingMethodId], context.prevShippingMethod)
    }
  },
  onSettled: (data) => {
    if (data?.shippingMethodId) {
      queryClient.invalidateQueries(['shippingMethod', data.shippingMethodId])
      queryClient.invalidateQueries(['shippingMethodsList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUpdateShippingMethod = () => useMutation(updateShippingMethod, updateShippingMethodEvents)
