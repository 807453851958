import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { AwsCertificate } from '../types'

export type FetchAwsCertificateAction = FSA<undefined, AwsCertificate, string>
type FetchAwsCertificate = (awsCertificateId: number) => MrxThunk<FetchAwsCertificateAction>
type QueryResponse = GraphQLResponse<'awsCertificate', AwsCertificate>

const FETCH_AWS_CERTIFICATE = `
  query fetchAwsCertificate ($awsCertificateId: Int!) {
    awsCertificate (awsCertificateId: $awsCertificateId) {
      id
      certificateArn
      domainName
      expiringAt
      issuedAt
      createdAt
      updatedAt
    }
  }
`

const fetchAwsCertificate: FetchAwsCertificate = (awsCertificateId) => async (dispatch) => {
  dispatch({
    type: t.FETCH_AWS_CERTIFICATE,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_AWS_CERTIFICATE,
      variables: { awsCertificateId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { awsCertificate } = data
    if (!awsCertificate) {
      throw new Error("Can't get aws  certificate!")
    }

    dispatch({
      type: t.FETCH_AWS_CERTIFICATE,
      payload: awsCertificate,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_AWS_CERTIFICATE,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchAwsCertificate }
