import { ParametricSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type isLoadingKeyType = 'isListLoading' | 'isSaveProcessingCostLoading'

export const isLoadingSelectorByKey: ParametricSelector<ReduxState, isLoadingKeyType, boolean> = (
  state,
  isLoadingKey
) => state.processingCosts.UIState[isLoadingKey] || false

export default isLoadingSelectorByKey