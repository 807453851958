import settings from 'config/settings'
import { Store } from 'redux'
import axios, { AxiosRequestConfig } from 'axios'
import * as authenticationFeature from 'features/authentication'
import { store } from 'store/configureStore'
import throttle from './throttle'

const instance = axios.create({ baseURL: settings.backendUrl })

console.log('Axios util was initialized!')

const REFRESH_TOKEN = `
  mutation refreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      refreshToken
      rememberMe
    }
  }
`

export const updateToken = throttle(async (res: any, store: Store) => {
  try {
    const refreshToken = sessionStorage.getItem('refreshToken') || localStorage.getItem('refreshToken')

    if (!refreshToken) {
      throw new Error('Refresh token is not defined!')
    }

    const response = await axios.post(`${settings.backendUrl}/graphql`, {
      query: REFRESH_TOKEN,
      variables: {
        refreshToken
      }
    })

    const { data, errors } = response.data

    if (errors) {
      const message = errors.map((item: any) => item.message).join('\n')
      console.log('Refresh token error - ', message)
      throw new Error(message)
    }

    if (!data) {
      throw new Error('Refresh token update respond with empty body!')
    }

    const newData = data?.refreshToken
    if (!newData) {
      throw new Error('Refresh token data is empty!')
    }
    const { token: newToken, refreshToken: newRefreshToken, rememberMe } = newData

    if (!newToken || !newRefreshToken) {
      throw new Error('New token or refreshToken was not defined in response!')
    }

    store.dispatch({
      type: authenticationFeature.actionTypes.REFRESH_TOKEN,
      payload: { token: newToken, refreshToken: newRefreshToken, rememberMe }
    })

    res.config.headers.crmauthorization = `Bearer ${newToken}`
    return instance(res.config)
  } catch (exception) {
    console.log('Axios refresh token error', exception)

    // logout
    store.dispatch({
      type: authenticationFeature.actionTypes.REFRESH_TOKEN_ERROR,
      payload: exception.message
    })

    authenticationFeature.actions.logout()(store.dispatch, store.getState, null)
  }
}, 10000)

export const initializeAxiosInterceptor = (store: any) => {
  instance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token')

      if (!token) {
        throw new Error('Unauthorized!')
      }

      config.headers.crmauthorization = token ? `Bearer ${token}` : config.headers.crmauthorization
      return config
    },
    (err: any) => Promise.reject(err)
  )

  instance.interceptors.response.use(
    (res: any): any => {
      if (res.data.errors && res.data.errors.find((error: any): any => error.message === 'Unauthorized!')) {
        return updateToken(res, store)
      }
      return res
    },
    (err: any): any => {
      if (err.status !== 401) {
        console.log('Err', err)
        return Promise.reject(err)
      } else {
        console.log('Response error')
        return updateToken(err.response, store)
      }
    }
  )
}

initializeAxiosInterceptor(store)

export default instance
