import * as React from 'react'
import { Spin } from 'antd'
import styles from './Loading.module.scss'

const Loading = () => {
  return (
    <div className={styles.root}>
      <Spin />
    </div>
  )
}

export { Loading }
