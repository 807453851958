import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as reportsFeature from 'features/reports'

const mapStateToProps = (state: ReduxState) => {
  return {
    topLandingsReport: reportsFeature.selectors.topLandingsReportSelector(state),
    topCampaignsRateReport: reportsFeature.selectors.topCampaignsRateReportSelector(state),
    salesForGoogleChart: reportsFeature.selectors.salesForGoogleChartSelector(state),
    topCampaignsReport: reportsFeature.selectors.topCampaignsReportSelector(state),
    dashboardReport: reportsFeature.selectors.dashboardDataReportSelector(state),
    error: reportsFeature.selectors.errorSelector(state),
    isLoading: reportsFeature.selectors.isLoadingSelector(state, 'isDashboardReportLoading'),
    reportDates: reportsFeature.selectors.reportDatesSelector(state)
  }
}

const mapDispatchToProps = {
  fetchDashboardReport: reportsFeature.actions.fetchDashboardReport,
  setReportDates: reportsFeature.actions.setReportDates
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector>
