import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'addVariantItemsToStock', boolean>

const ADD_VARIANT_ITEMS_TO_STOCK = `
  mutation AddVariantItemsToStock($campaignId: Int!, $productId: Int!, $variantKey: String!, $quantity: Int!) {
    addVariantItemsToStock(campaignId: $campaignId, productId: $productId, variantKey: $variantKey, quantity: $quantity)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type AddVariantItemsToStockArgs = {
  campaignId: number
  productId: number
  variantKey: string
  quantity: number
}

type AddVariantItemsToStock = (args: AddVariantItemsToStockArgs) => Promise<boolean>

const addVariantItemsToStock: AddVariantItemsToStock = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: ADD_VARIANT_ITEMS_TO_STOCK,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { addVariantItemsToStock } = data
    if (!addVariantItemsToStock) {
      throw new Error("Can't add the number of stock items!")
    }

    return addVariantItemsToStock
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type AddVariantItemsToStockEvents = {
  onSettled: (isSaved?: boolean) => void
}

const addVariantItemsToStockEvents: AddVariantItemsToStockEvents = {
  onSettled: (isSaved) => {
    if (isSaved) {
      queryClient.invalidateQueries(['inventoryList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useAddVariantItemsToStock = () => useMutation(addVariantItemsToStock, addVariantItemsToStockEvents)
