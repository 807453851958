import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { CustomerSubscriptionNote } from '../types'

type CustomerSubscriptionNoteInput = {
  workspaceId: number
  customerSubscriptionId: number
  note: string
}

type UpdateCustomerSubscriptionNoteArgs = {
  customerSubscriptionNoteId: number
  customerSubscriptionNoteData: CustomerSubscriptionNoteInput
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'updateCustomerSubscriptionNote', UpdateCustomerSubscriptionNoteArgs>

const UPDATE_CUSTOMER_SUBSCRIPTION_NOTE = `
  mutation updateCustomerSubscriptionNote($customerSubscriptionNoteId: Int!, $customerSubscriptionNoteData: CustomerSubscriptionNoteInput!) {
    updateCustomerSubscriptionNote(customerSubscriptionNoteId: $customerSubscriptionNoteId, customerSubscriptionNoteData: $customerSubscriptionNoteData)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateCustomerSubscriptionNote = (args: UpdateCustomerSubscriptionNoteArgs) => Promise<UpdateCustomerSubscriptionNoteArgs>

const updateCustomerSubscriptionNote: UpdateCustomerSubscriptionNote = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_CUSTOMER_SUBSCRIPTION_NOTE,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateCustomerSubscriptionNote } = data
    if (!updateCustomerSubscriptionNote) {
      throw new Error("Can't update customer subscription note!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateCustomerSubscriptionNoteContext = { prevCustomerSubscriptionNote?: CustomerSubscriptionNote }

type UpdateCustomerSubscriptionNoteEvents = {
  onMutate: (variables: UpdateCustomerSubscriptionNoteArgs) => Promise<UpdateCustomerSubscriptionNoteContext>
  onError: (error: string, variables: UpdateCustomerSubscriptionNoteArgs, context: UpdateCustomerSubscriptionNoteContext) => void
  onSettled: (data?: UpdateCustomerSubscriptionNoteArgs) => void
}

const updateCustomerSubscriptionNoteEvents: UpdateCustomerSubscriptionNoteEvents = {
  onMutate: async (variables: UpdateCustomerSubscriptionNoteArgs) => {
    await queryClient.cancelQueries(['customerSubscriptionNote', variables.customerSubscriptionNoteId])

    // Snapshot the previous value
    const prevCustomerSubscriptionNote = queryClient.getQueryData<CustomerSubscriptionNote>([
      'customerSubscriptionNote',
      variables.customerSubscriptionNoteId
    ])

    // Optimistically update to the new values
    if (prevCustomerSubscriptionNote) {
      queryClient.setQueryData<CustomerSubscriptionNote>(['customerSubscriptionNote', variables.customerSubscriptionNoteId], {
        ...prevCustomerSubscriptionNote,
        id: variables.customerSubscriptionNoteId,
        ...variables
      })
    }

    return { prevCustomerSubscriptionNote }
  },
  onError: (_err, variables, context) => {
    if (context?.prevCustomerSubscriptionNote) {
      // Restore previous version of customer subscription note on any error
      queryClient.setQueryData<CustomerSubscriptionNote>(
        ['customerSubscriptionNote', variables.customerSubscriptionNoteId],
        context.prevCustomerSubscriptionNote
      )
    }
  },
  onSettled: (data) => {
    if (data?.customerSubscriptionNoteId) {
      queryClient.invalidateQueries(['customerSubscriptionNote', data.customerSubscriptionNoteId])
      queryClient.invalidateQueries(['customerSubscription'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUpdateCustomerSubscriptionNote = () => useMutation(updateCustomerSubscriptionNote, updateCustomerSubscriptionNoteEvents)
