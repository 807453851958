import axios from 'utils/axios'
import { useQuery } from 'react-query'
import { CurrencyDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'currency', CurrencyDto>

const FETCH_CURRENCY = `
  query fetchCurrency ($currencyId: Int!) {
    currency (currencyId: $currencyId) {
      id
      name
      code
      symbol
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchCurrency = (currencyId: number) => Promise<CurrencyDto>

const fetchCurrency: FetchCurrency = async (currencyId) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_CURRENCY,
      variables: { currencyId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { currency } = data
    if (!currency) {
      throw new Error("Can't get currency!")
    }

    return currency
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCurrency = (currencyId: number) => useQuery(['currency', currencyId], () => fetchCurrency(currencyId))
