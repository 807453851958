import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type PageSizeSelector = (state: ReduxState) => number

const getPageSizeSelector: PageSizeSelector = state => state.alerts.pagination.size

export const pageSizeSelector: PageSizeSelector = createSelector(
  getPageSizeSelector,
  pageSize => pageSize
)
