import React, { useEffect } from 'react'
import useReactRouter from 'use-react-router'
import { Table, Popconfirm, Layout, PageHeader } from 'antd'
import notification from 'mrx-notification'
import { ColumnsType } from 'antd/lib/table'
import useHeightResize from 'utils/useHeightResize'
import { Breadcrumbs } from 'components'
import * as rootStyles from 'assets/layoutStyle'
import styles from './ShipstationSettingsList.module.scss'
import { connector, PropsFromRedux } from './container'
import { ShipstationSetting } from '../../types'
import CreateShipstationSetting from '../CreateShipstationSetting'

type HeightResizeRule = [number, number, number]
const rules: HeightResizeRule[] = [
  [0, 800, 8],
  [800, Infinity, 10]
]

const ShipstationSettingsList = (props: PropsFromRedux) => {
  const {
    shipstationSettingsList,
    isLoading,
    error,
    isNeedToFetchList,
    total,
    currentPage,

    changeCurrentPage,
    changePageSize,
    fetchShipstationSettingsList,
    removeShipstationSetting
  } = props

  const { history } = useReactRouter()

  const handleChangePageSize = (newSize: number) => {
    changePageSize(newSize)
    fetchShipstationSettingsList()
  }

  useHeightResize(handleChangePageSize, rules)

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Shipstation settings list page error!',
        description: error
      })
    }
  }, [error])

  useEffect(() => {
    isNeedToFetchList && fetchShipstationSettingsList()
  }, [fetchShipstationSettingsList, isNeedToFetchList])

  const doConfirm = async (shipstationSettingId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    const isRemoved = await removeShipstationSetting(shipstationSettingId)
    if (isRemoved) {
      notification.success({
        message: 'Successfully',
        description: 'Shipstation setting was deleted successfully!'
      })
    }
  }

  const columns: ColumnsType<ShipstationSetting> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'API Key',
      render: (record: ShipstationSetting) => <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{record.apiKey}</div>,
      key: 'apiKey'
    },
    {
      title: 'API Secret',
      render: (record: ShipstationSetting) => <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{record.apiSecret}</div>,
      key: 'apiSecret'
    },
    {
      title: 'Store ID',
      dataIndex: 'storeId',
      key: 'storeId'
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (record: ShipstationSetting) => (
        <span>
          <Popconfirm
            title='Are you sure delete this setting?'
            onConfirm={(e) => doConfirm(record.id, e)}
            onCancel={(e) => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <a
              href='/#'
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              Remove
            </a>
          </Popconfirm>
        </span>
      )
    }
  ]

  return (
    <Layout className={styles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader onBack={() => history.goBack()} title='Shipstation Settings List' extra={[<CreateShipstationSetting key='1' />]} />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Settings', url: '/' },
            { title: 'Plugins', url: '' },
            { title: 'Shipstation Settings', url: '/settings/plugins' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <Table
          columns={columns}
          dataSource={shipstationSettingsList}
          bordered
          rowKey={(record) => record.id}
          loading={isLoading}
          pagination={{
            onChange: changeCurrentPage,
            current: currentPage,
            total
          }}
        />
      </Layout.Content>
    </Layout>
  )
}

export default connector(ShipstationSettingsList)
