import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { UserInviteInput, WorkspaceMember } from '../types'

export type InviteUserAction = FSA<undefined, WorkspaceMember, string>
type InviteUser = (workspaceId: number, userInvite: UserInviteInput) => MrxThunk<InviteUserAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'inviteUserToWorkspace', WorkspaceMember>

const INVITE_USER = `
  mutation inviteUser($workspaceId: Int!, $userInvite: UserInviteInput!) {
    inviteUserToWorkspace(workspaceId: $workspaceId, userInvite: $userInvite) {
      id
      userId
      email
      role
      createdAt
    }
  }
`

export const inviteUser: InviteUser = (workspaceId, userInvite) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.INVITE_USER,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: INVITE_USER,
      variables: { workspaceId, userInvite }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { inviteUserToWorkspace } = data
    if (!inviteUserToWorkspace) {
      throw new Error("Can't create invite!")
    }

    dispatch({
      type: t.INVITE_USER,
      payload: inviteUserToWorkspace,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.INVITE_USER,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isSaved
}
