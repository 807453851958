import React, { useState, useEffect, useRef } from 'react'
import { PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Drawer, Input, Form, Upload } from 'antd'
import notification from 'mrx-notification'
import styles from './CreateNginxSetting.module.scss'
import { PropsFromRedux, connector } from './container'

const CreateNginxSetting = (props: PropsFromRedux) => {
  const [isVisible, setVisible] = useState(false)
  const [privateKey, setPrivateKey] = useState('')
  const refEl = useRef<Input>(null)

  const { isLoading, createNginxSetting } = props

  useEffect(() => {
    if (isVisible) {
      refEl.current && refEl.current.focus()
    }
  }, [isVisible])

  const showDrawer = () => {
    setVisible(!isVisible)
  }

  const [form] = Form.useForm()

  const handleClose = () => {
    setVisible(false)
    form.resetFields()
  }

  const onFinishHandler = async (values: any) => {
    const isSaved = await createNginxSetting({
      name: values.name,
      host: values.host,
      port: values.port,
      userName: values.userName,
      privateKey
    })
    if (isSaved) {
      notification.success({
        message: 'Successfully',
        description: 'Nginx Setting was created successfully!'
      })
      handleClose()
    }
  }

  return (
    <>
      <Button type='primary' onClick={showDrawer}>
        <PlusOutlined />
        CREATE
      </Button>
      <Drawer title='Create Nginx Setting' width='400' onClose={handleClose} visible={isVisible} forceRender>
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
          <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input Nginx Setting name!' }]}>
            <Input ref={refEl} />
          </Form.Item>
          <Form.Item
            name='host'
            label='Host (IP or domain name)'
            rules={[{ required: true, message: 'Please input Nginx Setting host!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='port'
            label='SSH Port (default 22)'
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='userName'
            label='User name'
            rules={[{ required: true, message: 'Please input Nginx Setting secret user name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name='privateKey' label='Private key file' valuePropName='' rules={[{ required: true, message: 'Please upload file!' }]}>
            <Upload
              name='file'
              accept='*.pem'
              customRequest={(info: any) => {
                const reader = new FileReader()
                reader.readAsText(info.file)
                reader.onload = () => {
                  setPrivateKey(reader.result.toString())
                }
              }}
              showUploadList={false}
            >
              <Button>
                <UploadOutlined /> Click to Upload
              </Button>
            </Upload>
          </Form.Item>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button onClick={handleClose} className={styles.createButton}>
              Close
            </Button>
            <Button type='primary' htmlType='submit' loading={isLoading}>
              Create
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

export default connector(CreateNginxSetting)
