import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { TagOwner } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'saveTags', boolean>

const SAVE_TAGS = `
  mutation saveTags ($ownerId: Int!, $ownerType: TagOwner!, $tags: [String]!) {
    saveTags (ownerId: $ownerId, ownerType: $ownerType, tags: $tags)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type SaveTagsArgs = {
  ownerId: number
  ownerType: TagOwner
  tags: string[]
}

type SaveTags = (args: SaveTagsArgs) => Promise<SaveTagsArgs>

const saveTags: SaveTags = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: SAVE_TAGS,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { saveTags } = data
    if (!saveTags) {
      throw new Error("Can't archive landing!")
    }

    return args
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type SaveTagsEvents = {
  onSettled: (data?: SaveTagsArgs) => void
}

const saveTagsEvents: SaveTagsEvents = {
  onSettled: (data) => {
    if (data?.tags) {
      queryClient.invalidateQueries([`${data.ownerType.toLowerCase()}`, data.ownerId])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useSaveTags = () => useMutation(saveTags, saveTagsEvents)
