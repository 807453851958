import React, { useEffect } from 'react'
import useReactRouter from 'use-react-router'
import { Button, PageHeader, Table, Layout, Popconfirm } from 'antd'
import { EditTwoTone, DeleteTwoTone } from '@ant-design/icons'
import notification from 'mrx-notification'
import { ColumnsType } from 'antd/lib/table'
import { Breadcrumbs } from 'components'
import * as rootStyles from 'assets/layoutStyle'
import useHeightResize from 'utils/useHeightResize'
import { Category } from '../../types'
import styles from './CategoriesList.module.scss'
import { connector, PropsFromRedux } from './container'

const CategoriesList = ({
  workspaceId,
  campaignId,
  isLoading,
  error,
  total,
  size,
  currentPage,
  categoriesList,
  showHeader = true,
  searchQuery,

  changeCurrentPage,
  changePageSize,
  fetchCategoriesList,
  removeCategory
}: PropsFromRedux) => {
  const { history } = useReactRouter()

  const handleChangePageSize = (newSize: number) => {
    changePageSize(newSize, campaignId)
    // console.log('Handle change page size', newSize)
    // fetchCategoriesList({ workspaceId, campaignId, searchText: searchQuery })
  }

  useHeightResize(handleChangePageSize)

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Categories error!',
        description: error
      })
    }
  }, [error])

  useEffect(() => {
    changeCurrentPage(1, campaignId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery])

  useEffect(() => {
    console.log('Fetch categories list', campaignId, currentPage, size, searchQuery)
    fetchCategoriesList({ workspaceId, campaignId, searchText: searchQuery })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, currentPage, size, searchQuery])

  const doConfirm = async (categoryId: number, index: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    const isRemoved = await removeCategory(categoryId, campaignId)
    if (isRemoved) {
      notification.success({
        message: 'Successfully',
        description: 'Category was deleted successfully!'
      })
    }
  }

  const columns: ColumnsType<Category> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '',
      dataIndex: '',
      key: 'edit',
      width: '10%',
      align: 'center',
      render: (record: Category) => (
        <span>
          <EditTwoTone
            style={{ fontSize: '20px' }}
            onClick={(e) => {
              e.stopPropagation()
              history.push(`/campaigns/${record.campaignId}/categories/${record.id}/edit`)
            }}
          />
        </span>
      )
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '10%',
      align: 'center',
      render: (record: Category, _category, index) => (
        <span>
          <Popconfirm
            title='Are you sure delete this category?'
            onConfirm={(e) => doConfirm(record.id, index, e)}
            onCancel={(e) => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <DeleteTwoTone
              twoToneColor='#ec1c24'
              style={{ fontSize: '20px' }}
              onClick={(e) => {
                e.stopPropagation()
              }}
            />
          </Popconfirm>
        </span>
      )
    }
  ]

  return (
    <Layout style={rootStyles.root}>
      {showHeader && (
        <>
          <Layout.Header style={rootStyles.header}>
            <PageHeader
              onBack={() => history.goBack()}
              title='Categories'
              extra={[
                <div key='1' className={styles.extraContent}>
                  <div className={styles.createButton}>
                    <Button type='primary' onClick={() => history.push(`/campaigns/${campaignId}/categories/create`)}>
                      + CREATE CATEGORY
                    </Button>
                  </div>
                </div>
              ]}
            />
          </Layout.Header>
          <Layout.Content style={rootStyles.contentBreadcrumbs}>
            <Breadcrumbs
              items={[
                { title: 'Main', url: '/' },
                { title: 'Campaigns', url: '/' },
                { title: 'Categories', url: '/' }
              ]}
            />
          </Layout.Content>
        </>
      )}
      <Layout.Content className={showHeader && styles.contentComponent}>
        <Table
          columns={columns}
          dataSource={categoriesList}
          bordered
          // onRow={handleOnRow}
          rowKey={(record) => record.id}
          pagination={{
            current: currentPage,
            pageSize: size,
            total,
            onChange: (page: number) => changeCurrentPage(page, campaignId)
          }}
          loading={isLoading}
        />
      </Layout.Content>
    </Layout>
  )
}

export default connector(CategoriesList)
