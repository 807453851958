import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { ProductColor } from '../types'

export type FetchProductColorAction = FSA<undefined, ProductColor, string>
type FetchProductColor = (productColorId: number) => MrxThunk<FetchProductColorAction>
type QueryResponse = GraphQLResponse<'productColor', ProductColor>

const FETCH_PRODUCT_COLOR = `
  query fetchProductColor ($productColorId: Int!) {
    productColor(productColorId: $productColorId) {
      id
      name
      displayName
      createdAt
      updatedAt
    }
  }
`

const fetchProductColor: FetchProductColor = (productColorId) => async (dispatch) => {
  dispatch({
    type: t.FETCH_PRODUCT_COLOR,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_PRODUCT_COLOR,
      variables: { productColorId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { productColor } = data
    if (!productColor) {
      throw new Error("Can't get productColor!")
    }

    dispatch({
      type: t.FETCH_PRODUCT_COLOR,
      payload: productColor,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_PRODUCT_COLOR,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchProductColor }
