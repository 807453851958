// TODO - remove becouse of legacy

import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

const mapStateToProps = (state: ReduxState) => ({
  adSpendsRows: selectors.listRowsSelector(state),
  isLoading: selectors.isLoadingSelectorByKey(state, 'isLoadingList'),
  dateList: selectors.dateListSelector(state),
  error: selectors.errorSelector(state)
})

const mapDispatchToProps = {
  fetchAdSpends: actions.fetchAdSpends,
  changeDate: actions.changeDateList
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector>
