import { getWebSocket, MerchxWebSocket } from 'utils/webSocket'
import * as t from '../actionTypes'

export type WebSocketSubscribeToChannelAction = BaseFSA<undefined>
type WebSocketSubscribeToChannel = (channel: string, socket?: MerchxWebSocket) => MrxThunk<WebSocketSubscribeToChannelAction>

const subscribeToChannel: WebSocketSubscribeToChannel = (channel, socket = getWebSocket()) => async (dispatch, getState) => {
  if (getState().websocket.isAlive) {
    console.log('Subscribe to channel started', channel)
    socket.emit('subscribeToChannel', channel)
    dispatch({
      type: t.SUBSCRIBE_TO_CHANNEL,
      meta: { done: true }
    })
  }
}

export { subscribeToChannel }
