import React from 'react'
import { SearchableSelectBase } from 'components'
import { PropsFromRedux, connector } from './container'

const FulfillmentSettingsSelect = (props: PropsFromRedux) => {
  const { plugin, fetchCustomGatewaySettingsOptions, fetchShipstationSettingsOptions, ...rest } = props

  switch (plugin) {
    case 'CUSTOM_GATEWAY':
      return <SearchableSelectBase plugin={plugin} onSearch={fetchCustomGatewaySettingsOptions} {...rest} />

    case 'SHIPSTATION':
    default:
      return <SearchableSelectBase plugin={plugin} onSearch={fetchShipstationSettingsOptions} {...rest} />
  }
}

export default connector(FulfillmentSettingsSelect)
