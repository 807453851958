import React, { useState, useEffect, useRef } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Drawer, Input, Form, Select } from 'antd'
import notification from 'mrx-notification'
import { DTGProductsSelect } from 'features/DTGProducts/components'
import styles from './CreateDTGSize.module.scss'
import { PropsFromRedux, connector } from './container'

const CreateDTGSize = (props: PropsFromRedux) => {
  const [isVisible, setVisible] = useState(false)
  const refEl = useRef<Input>(null)

  const { isLoading, createDTGSize } = props

  useEffect(() => {
    if (isVisible) {
      refEl.current && refEl.current.focus()
    }
  }, [isVisible])

  const showDrawer = () => {
    setVisible(!isVisible)
  }

  const [form] = Form.useForm()

  const handleClose = () => {
    setVisible(false)
    form.resetFields()
  }

  const onFinishHandler = async (values: any) => {
    const isSaved = await createDTGSize({
      name: values.name,
      size: values.size,
      DTGProductId: +values.DTGProductId,
      DTGSizeId: +values.DTGSizeId
    })
    if (isSaved) {
      notification.success({
        message: 'Successfully',
        description: 'DTG Size was created successfully!'
      })
      handleClose()
    }
  }

  return (
    <>
      <Button type='primary' onClick={showDrawer}>
        <PlusOutlined />
        CREATE
      </Button>
      <Drawer title='Create DTG Size' width='400' onClose={handleClose} visible={isVisible} forceRender>
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
          <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input DTG Size name!' }]}>
            <Input style={{ width: '100%' }} ref={refEl} />
          </Form.Item>

          <Form.Item name='size' label='Size' rules={[{ required: true, message: 'Please select product size!' }]}>
            <Select>
              <Select.Option key='SM' value='SM'>
                SM
              </Select.Option>
              <Select.Option key='MD' value='MD'>
                MD
              </Select.Option>
              <Select.Option key='LG' value='LG'>
                LG
              </Select.Option>
              <Select.Option key='XL' value='XL'>
                XL
              </Select.Option>
              <Select.Option key='2XL' value='2XL'>
                2XL
              </Select.Option>
              <Select.Option key='3XL' value='3XL'>
                3XL
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item name='DTGProductId' label='DTG Product' rules={[{ required: true, message: 'Please select DTG product!' }]}>
            <DTGProductsSelect
              initialOption={{
                id: 0,
                name: 'Select DTG product'
              }}
              onSelect={(selectedOption: SelectOption) => {
                form.setFieldsValue({
                  DTGProductId: selectedOption?.id
                })
              }}
            />
          </Form.Item>

          <Form.Item
            name='DTGSizeId'
            label='DTG Size ID'
            rules={[{ required: true, pattern: /\d/, message: 'Please input correct DTG Size ID!' }]}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button onClick={handleClose} className={styles.closeButton}>
              Close
            </Button>
            <Button type='primary' htmlType='submit' loading={isLoading}>
              Create
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

export default connector(CreateDTGSize)
