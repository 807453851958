import axios from 'utils/axios'
import { useQuery } from 'react-query'
import { DomainDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'domain', DomainDto>

const FETCH_DOMAIN = `
  query fetchDomain ($domainId: Int!) {
    domain (domainId: $domainId) {
      id
      name
      registrar
      registrarLogin
      registrarPassword
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchDomain = (domainId?: number) => Promise<DomainDto>

const fetchDomain: FetchDomain = async (domainId) => {
  try {
    if (!domainId) {
      return undefined
    }
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_DOMAIN,
      variables: { domainId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { domain } = data
    if (!domain) {
      throw new Error("Can't get domain!")
    }

    return domain
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useDomain = (domainId?: number) => useQuery(['domain', domainId], () => fetchDomain(domainId))
