import React, { useContext, useState, useEffect, useRef } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Drawer, Input, Form, DatePicker } from 'antd'
import { GlobalContext } from 'appContexts'
import { ProductsSelect } from 'features/products/components'
import styles from './CreateSubscriptionPrice.module.scss'

type Props = {
  handleCreateSubscription: (newValues: any) => void
}

const CreateSubscriptionPrice = (props: Props) => {
  const { handleCreateSubscription } = props
  const [isVisible, setVisible] = useState(false)
  const refEl = useRef<Input>(null)

  const { campaignId } = useContext(GlobalContext)

  useEffect(() => {
    if (isVisible) {
      refEl.current && refEl.current.focus()
    }
  }, [isVisible])

  const showDrawer = () => {
    setVisible(!isVisible)
  }

  const [form] = Form.useForm()

  const handleClose = () => {
    setVisible(false)
    form.resetFields()
  }

  const onFinishHandler = async (values: any) => {
    handleCreateSubscription({ ...values, date: values.date.toISOString() })
    handleClose()
  }

  return (
    <>
      <Button type='primary' onClick={showDrawer}>
        <PlusOutlined />
        ADD
      </Button>
      <Drawer title='Create Subscription Record' width='400' onClose={handleClose} visible={isVisible} forceRender>
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form} initialValues={{ quantity: 1 }}>
          <Form.Item
            name='date'
            label='Date'
            rules={[
              {
                required: true,
                message: 'Please select a date!'
              }
            ]}
          >
            <DatePicker className={styles.formItem} format='MM/DD/YYYY' style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item name='product' label='Product' rules={[{ required: true, message: 'Please select product!' }]}>
            <ProductsSelect
              campaignId={campaignId}
              settingId={0}
              filters={['CREW', 'STICKER', 'HOODIE', 'EXTERNAL_PRODUCT', 'TOTEBAG', 'TSHIRT', 'FACE_MASK']}
              initialOption={{
                id: 0,
                name: 'Select a product'
              }}
              onSelect={(selectedOption: SelectOption) => {
                form.setFieldsValue({
                  product: selectedOption
                })
              }}
            />
          </Form.Item>
          <Form.Item name='quantity' label='Quantity' rules={[{ required: true, message: 'Please input quantity!' }]}>
            <Input type='number' style={{ width: '100%' }} />
          </Form.Item>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button onClick={handleClose} className={styles.closeButton}>
              Close
            </Button>
            <Button type='primary' htmlType='submit'>
              Create
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

export default CreateSubscriptionPrice
