import { useQuery } from 'react-query'
import axios from 'utils/axios'
import { guardFromErrors } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'ordersListCsv', string>

const FETCH_ORDERS_LIST_CSV = `
  query ordersListCsv($filters: OrdersFilters!) {
    ordersListCsv(filters: $filters)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchOrdersListCsvFilters = {
  customerId?: number
  billingAddressId?: number
  shippingAddressId?: number
  status?: string
  saleSource?: string
  saleSourceNotIn?: string[]
  customSaleSourceIn?: string[]
  sorting?: {
    field?: string
    order?: 'ASC' | 'DESC'
  }
  query?: string
  productTags?: string[]
  startDate?: Date
  endDate?: Date
}

type FetchOrdersList = (filters: FetchOrdersListCsvFilters) => Promise<string>

const fetchOrdersListCsv: FetchOrdersList = async (filters) => {
  const {
    data: { data, errors }
  }: QueryResponse = await axios.post('/graphql', {
    query: FETCH_ORDERS_LIST_CSV,
    variables: { filters }
  })

  guardFromErrors(errors)

  if (!data) {
    throw new Error('Response body is empty!')
  }

  const { ordersListCsv } = data
  if (!ordersListCsv) {
    throw new Error("Can't get orders list csv!")
  }

  return ordersListCsv
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useOrdersListCsv = (filters: FetchOrdersListCsvFilters) =>
  useQuery(['ordersListCsv', filters], () => fetchOrdersListCsv(filters), { enabled: false, retry: 0 })
