import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import * as t from '../actionTypes'

export type RemovePayPalSettingAction = FSA<undefined, number, string>
type RemovePayPalSettingColor = (payPalSettingId: number) => MrxThunk<RemovePayPalSettingAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'removePayPalSetting', boolean>

const REMOVE_PAYPAL_SETTING = `
  mutation removePayPalSetting ($payPalSettingId: Int!) {
    removePayPalSetting (payPalSettingId: $payPalSettingId)
  }
`

const removePayPalSetting: RemovePayPalSettingColor = (payPalSettingId) => async (dispatch) => {
  let isRemoved = false

  dispatch({
    type: t.REMOVE_PAYPAL_SETTING,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_PAYPAL_SETTING,
      variables: { payPalSettingId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Empty response!')
    }

    const { removePayPalSetting } = data
    if (!removePayPalSetting) {
      throw new Error("Can't remove PayPal setting!")
    }

    dispatch({
      type: t.REMOVE_PAYPAL_SETTING,
      payload: payPalSettingId,
      meta: { done: true }
    })

    isRemoved = true
  } catch (err) {
    dispatch({
      type: t.REMOVE_PAYPAL_SETTING,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isRemoved
}

export { removePayPalSetting }
