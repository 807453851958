import React, { useContext } from 'react'
import { Form } from 'antd'
import notification from 'mrx-notification'
import { SettingFilter, SettingOwner, SettingType } from '@merchx-v2/shared-types'
import { GlobalContext } from 'appContexts'
import ProductsSelect from 'features/products/components/ProductsSelect'
import { useUpdateSettingValue } from '../../hooks'
import { Setting } from '../../types'

type PropsType = {
  ownerType: SettingOwner
  ownerId: number
  setting: Setting
  selfUpdate?: boolean
  landingId?: number

  onValueChanged?: (setting: Setting, newValue: any) => void
}

const FormItemProductSetting = (props: PropsType) => {
  const { selfUpdate = false, setting, landingId, ownerType, ownerId, onValueChanged } = props

  const { campaignId } = useContext(GlobalContext)

  const updateSettingValue = useUpdateSettingValue()

  if (!setting) {
    return
  }

  const handleOnValueChanged = async (value) => {
    if (onValueChanged) {
      onValueChanged(setting, JSON.stringify(value))
    }

    if (selfUpdate) {
      updateSettingValue.mutate(
        {
          ownerType,
          ownerId,
          settingId: setting.id,
          settingData: {
            name: setting.name,
            alias: setting.alias,
            type: setting.type as SettingType,
            value: JSON.stringify(value),
            required: setting.required,
            filters: setting.filters as SettingFilter[]
          }
        },
        {
          onSuccess: () => {
            notification.success({
              message: 'Successfully',
              description: `${setting.name} setting was updated!`
            })
          },
          onError: (error) => {
            notification.error({
              message: `${setting.name} setting error!`,
              description: error.toString()
            })
          }
        }
      )
    }
  }

  return (
    <Form.Item
      name={setting.alias}
      label={setting.name}
      key={setting.id}
      initialValue={setting.jsonValue?.id || ''}
      rules={[{ required: setting.required, message: `Please input "${setting.name}" value` }]}
    >
      <ProductsSelect
        landingId={+landingId}
        campaignId={campaignId}
        settingId={setting.id}
        initialOption={{
          id: setting?.jsonValue?.id || 0,
          name: setting?.jsonValue?.name || 'Select a product'
        }}
        onSelect={(selectedOption: SelectOption) => handleOnValueChanged(selectedOption)}
        filters={setting?.filters}
      />
    </Form.Item>
  )
}

export default FormItemProductSetting
