import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { TopCampaignsRate } from '../types'

type TopCampaignsRateReportSelector = (state: ReduxState) => TopCampaignsRate[]

const getTopCampaignsRate: TopCampaignsRateReportSelector = state => {
  let result = [] as TopCampaignsRate[]

  if (state.reports.topCampaignsRate) {
    result = Object.values(state.reports.topCampaignsRate) as TopCampaignsRate[]
  }

  return result
}

export const topCampaignsRateReportSelector: TopCampaignsRateReportSelector = createSelector(
  getTopCampaignsRate,
  topCampaignsRate => topCampaignsRate
)
