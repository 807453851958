import { SettingData } from './types'

export const postmarkSettingsData: SettingData[] = [
  {
    name: 'apiKey',
    label: 'API Key',
    rules: [{ required: true, message: 'Please input postmark setting API key!' }]
  },
  {
    name: 'from',
    label: 'From',
    rules: [{ required: true, message: 'Please input postmark setting from email!' }]
  }
]
