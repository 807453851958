import produce from 'immer'
import * as t from './actionTypes'
import { actionTypes as nt } from 'features/navigation'
import { actionTypes as wt } from 'features/workspaces'
import { isSuccessAction, isErrorAction } from 'types'
import { ShipstationSettingsState } from './types'
import createReducer from 'store/createReducer'
import { addItemToPagination } from 'utils/addItemToPagination'
import { removeItemFromPagination } from 'utils/removeItemFromPagination'
import {
  ChangeCurrentPageAction,
  ChangePageSizeAction,
  CreateShipstationSettingAction,
  FetchShipstationSettingsListAction,
  FetchShipstationSettingsForOptionsAction,
  RemoveShipstationSettingAction,
  UpdateShipstationSettingAction
} from './actions'

const initState: ShipstationSettingsState = {
  shipstationSettings: {},
  shipstationSettingsForOptions: [],
  pagination: {
    currentPage: 1,
    total: 0,
    pages: {},
    size: 10
  },
  UIState: {
    isCreateShipstationSetting: false,
    isListLoading: false,
    isRemoveShipstationSetting: false,
    isShipstationSettingsForOptionsLoading: false,
    isUpdateShipstationSetting: false
  }
}

const reducer = createReducer(initState, {
  [t.CREATE_SHIPSTATION_SETTING]: (state, action: CreateShipstationSettingAction) =>
    produce(state, draft => {
      draft.UIState.isCreateShipstationSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isCreateShipstationSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { id } = action.payload
        draft.shipstationSettings[id] = action.payload
        addItemToPagination(draft.pagination, id)
      }
    }),

  [t.FETCH_SHIPSTATION_SETTINGS_LIST]: (state, action: FetchShipstationSettingsListAction) =>
    produce(state, draft => {
      draft.UIState.isListLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isListLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        action.payload.items.forEach(item => {
          draft.shipstationSettings[item.id] = item
        })
        const { page, total, items } = action.payload
        draft.pagination.total = total
        draft.pagination.pages[page] = items.map(item => item.id)
      }
    }),

  [t.FETCH_SHIPSTATION_SETTINGS_FOR_OPTIONS]: (state, action: FetchShipstationSettingsForOptionsAction) =>
    produce(state, draft => {
      draft.UIState.isShipstationSettingsForOptionsLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isShipstationSettingsForOptionsLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.shipstationSettingsForOptions = action.payload
      }
    }),

  [t.REMOVE_SHIPSTATION_SETTING]: (state, action: RemoveShipstationSettingAction) =>
    produce(state, draft => {
      draft.UIState.isRemoveShipstationSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isRemoveShipstationSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.shipstationSettings[action.payload]) {
          delete draft.shipstationSettings[action.payload]
          removeItemFromPagination(draft.pagination, action.payload)
        }
      }
    }),
  [t.UPDATE_SHIPSTATION_SETTING]: (state, action: UpdateShipstationSettingAction) =>
    produce(state, draft => {
      draft.UIState.isUpdateShipstationSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isUpdateShipstationSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.shipstationSettings[action.payload.shipstationSettingId]) {
          draft.shipstationSettings[action.payload.shipstationSettingId].name = action.payload.shipstationSettingData.name
          draft.shipstationSettings[action.payload.shipstationSettingId].apiKey = action.payload.shipstationSettingData.apiKey
          draft.shipstationSettings[action.payload.shipstationSettingId].apiSecret = action.payload.shipstationSettingData.apiSecret
          draft.shipstationSettings[action.payload.shipstationSettingId].storeId = action.payload.shipstationSettingData.storeId
        }
      }
    }),

  [t.CHANGE_CURRENT_PAGE]: (state, action: ChangeCurrentPageAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.pagination.currentPage = action.payload
      }
    }),

  [t.CHANGE_PAGE_SIZE]: (state, action: ChangePageSizeAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.pagination.size = action.payload
      }
    }),

  [wt.SWITCH_WORKSPACE]: state =>
    produce(state, draft => {
      draft.pagination = initState.pagination
    }),

  [nt.LOCATION_CHANGE]: state =>
    produce(state, draft => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default reducer
