import { BuildOwner, EnvironmentType } from '@merchx-v2/shared-types'
import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { PreviewState } from '../types'

type SiteBuildInfoSelector = (
  state: ReduxState,
  ownerType: BuildOwner,
  ownerId: number,
  version: number,
  environment: EnvironmentType
) => PreviewState

const getSiteBuildInfo: SiteBuildInfoSelector = (state, ownerType, ownerId, version, environment) => {
  let result = {} as PreviewState

  if (Object.prototype.hasOwnProperty.call(state.sitePreviews[ownerType], ownerId)) {
    if (Object.prototype.hasOwnProperty.call(state.sitePreviews[ownerType][ownerId], version)) {
      if (Object.prototype.hasOwnProperty.call(state.sitePreviews[ownerType][ownerId][version], environment)) {
        result = state.sitePreviews[ownerType][ownerId][version][environment]
      }
    }
  }
  return result
}

export const siteBuildInfoSelector: SiteBuildInfoSelector = createSelector(getSiteBuildInfo, (preview) => preview)
