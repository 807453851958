import axios from 'axios'
import * as t from '../actionTypes'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import settings from 'config/settings'
import { changeLocation } from 'features/navigation/actions'

export type RegisterAction = FSA<undefined, boolean, string>
type Register = (
  email: string,
  password: string,
  irstName: string,
  lastName: string
) => MrxThunk<RegisterAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'signUp', boolean>

const REGISTER = `
  mutation Register($email: String!, $password: String!, $firstName: String!, $lastName: String!) {
    signUp(email: $email, password: $password, firstName: $firstName, lastName: $lastName)
  }
`

export const register: Register = (email, password, firstName, lastName) => async (dispatch, getState) => {
  let isSaved = false
  dispatch({
    type: t.REGISTER,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post(`${settings.backendUrl}/graphql`, {
      query: REGISTER,
      variables: { email, password, firstName, lastName }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { signUp } = data
    if (!signUp) {
      throw new Error("Can't register!")
    }

    dispatch({
      type: t.REGISTER,
      payload: signUp,
      meta: { done: true }
    })
    isSaved = true

    changeLocation('/login')(dispatch, getState, null)
  } catch (err) {
    dispatch({
      type: t.REGISTER,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isSaved
}
