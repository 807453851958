import { CustomerAccountDto } from '@merchx-v2/shared-types'
import { useQuery } from 'react-query'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'getCustomerAccount', CustomerAccountDto>

// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchCustomerAccount = (customerAccountId: number) => Promise<CustomerAccountDto>

const fetchCustomerAccount: FetchCustomerAccount = async (customerAccountId) => {
  try {
    const FETCH_CUSTOMER_ACCOUNT = `
query getCustomerAccount ($customerAccountId: Int!) {
  getCustomerAccount (customerAccountId: $customerAccountId) {
    id
    campaignId
    customerId
    addressId
    email
    username
    firstName
    lastName

    isEmailConfirmed
    isDeactivated
    bannedTill
    lastLoginAt

    image {
      signedUrl
    }
    customer {
      id
      firstName
      lastName
      phone
      createdFromLandingUrl
    }

    createdAt
    updatedAt
    deletedAt
  }
}
`
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_CUSTOMER_ACCOUNT,
      variables: { customerAccountId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { getCustomerAccount } = data
    if (!getCustomerAccount) {
      throw new Error("Can't get customer account!")
    }

    return getCustomerAccount
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCustomerAccount = (customerAccountId: number) =>
  useQuery(['customerAccount', customerAccountId], () => fetchCustomerAccount(customerAccountId))
