import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

import { PluginSetting, PluginSettingInput } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'savePluginSettings', PluginSetting>

const SAVE_PLUGIN_SETTINGS = `
mutation savePluginSettings ($pluginSettingData: PluginSettingInput!) {
  savePluginSettings (pluginSettingData: $pluginSettingData) {
    id
    workspaceId
    ownerType
    ownerId
    pluginType
    pluginName
    actualFrom
    settings {
      setting
      value
    }
    createdAt
    updatedAt
  }
}
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type SavePluginSettings = (pluginSettingData: PluginSettingInput) => Promise<PluginSetting>

const savePluginSettings: SavePluginSettings = async pluginSettingData => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: SAVE_PLUGIN_SETTINGS,
      variables: { pluginSettingData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { savePluginSettings } = data
    if (!savePluginSettings) {
      throw new Error("Can't create plugin settings!")
    }

    return savePluginSettings
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type SavePluginSettingsEvents = {
  onSettled: (data?: PluginSetting) => void
}

const savePluginSettingsEvents: SavePluginSettingsEvents = {
  onSettled: pluginSetting => {
    if (pluginSetting?.id) {
      queryClient.invalidateQueries(['pluginSettingsList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useSavePluginSettings = () => useMutation(savePluginSettings, savePluginSettingsEvents)
