import React, { useState } from 'react'
import { Menu } from 'antd'
import * as authorizeSettingsFeature from 'features/authorizeSettings'
import * as awsSettingsFeature from 'features/awsSettings'
import * as nginxSettingsFeature from 'features/nginxSettings'
import * as customGatewayFeature from 'features/customGatewaySettings'
import * as payPalSettingsFeature from 'features/payPalSettings'
import * as postmarkSettingsFeature from 'features/postmarkSettings'
import * as sendgridSettingsFeature from 'features/sendgridSettings'
import * as shipstationSettingsFeature from 'features/shipstationSettings'
import * as simpleTextSettingsFeature from 'features/simpleTextSettings'
import * as stripeSettingsFeature from 'features/stripeSettings'
import * as zipwhipSettingsFeature from 'features/zipwhipSettings'
import styles from './Plugins.module.scss'

const { AuthorizeSettingsList } = authorizeSettingsFeature.components
const { AwsSettingsList } = awsSettingsFeature.components
const { NginxSettingsList } = nginxSettingsFeature.components
const { CustomGatewaySettingsList } = customGatewayFeature.components
const { PayPalSettingsList } = payPalSettingsFeature.components
const { PostmarkSettingsList } = postmarkSettingsFeature.components
const { SendgridSettingsList } = sendgridSettingsFeature.components
const { ShipstationSettingsList } = shipstationSettingsFeature.components
const { SimpleTextSettingsList } = simpleTextSettingsFeature.components
const { StripeSettingsList } = stripeSettingsFeature.components
const { ZipwhipSettingsList } = zipwhipSettingsFeature.components

const Plugins = () => {
  const [activeComponent, setActiveComponent] = useState<string>('authorizeSettings')

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case 'authorizeSettings': {
        return <AuthorizeSettingsList />
      }
      case 'awsSettings': {
        return <AwsSettingsList />
      }
      case 'nginxSettings': {
        return <NginxSettingsList />
      }
      case 'customGateway': {
        return <CustomGatewaySettingsList />
      }
      case 'payPalSettings': {
        return <PayPalSettingsList />
      }
      case 'postmarkSettings': {
        return <PostmarkSettingsList />
      }
      case 'shipstationSettings': {
        return <ShipstationSettingsList />
      }
      case 'sendgridSettings': {
        return <SendgridSettingsList />
      }
      case 'simpleTextSettings': {
        return <SimpleTextSettingsList />
      }
      case 'stripeSettings': {
        return <StripeSettingsList />
      }
      case 'zipwhipSettings': {
        return <ZipwhipSettingsList />
      }
    }
  }

  return (
    <div className={styles.contentContainer}>
      <Menu mode='inline' style={{ width: 200 }}>
        <Menu.SubMenu key='payment' title='Payment'>
          <Menu.Item key='authorize' onClick={() => setActiveComponent('authorizeSettings')}>
            Authorize Settings
          </Menu.Item>
          <Menu.Item key='stripe' onClick={() => setActiveComponent('stripeSettings')}>
            Stripe Settings
          </Menu.Item>
          <Menu.Item key='payPal' onClick={() => setActiveComponent('payPalSettings')}>
            PayPal Settings
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu key='deployment' title='Deployment'>
          <Menu.Item key='awsSettings' onClick={() => setActiveComponent('awsSettings')}>
            AWS Settings
          </Menu.Item>
          <Menu.Item key='nginxSettings' onClick={() => setActiveComponent('nginxSettings')}>
            Nginx Settings
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu key='fulfillment' title='Fulfillment'>
          <Menu.Item key='customGateway' onClick={() => setActiveComponent('customGateway')}>
            Custom Gateway Settings
          </Menu.Item>
          <Menu.Item key='shipstation' onClick={() => setActiveComponent('shipstationSettings')}>
            Shipstation Settings
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu key='mailing' title='Mailing'>
          <Menu.Item key='postmarkSettings' onClick={() => setActiveComponent('postmarkSettings')}>
            Postmark Settings
          </Menu.Item>
          <Menu.Item key='sendgrid' onClick={() => setActiveComponent('sendgridSettings')}>
            Sendgrid Settings
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu key='sms' title='SMS'>
          <Menu.Item key='simpleText' onClick={() => setActiveComponent('simpleTextSettings')}>
            Simple Text Settings
          </Menu.Item>
          <Menu.Item key='zipwhip' onClick={() => setActiveComponent('zipwhipSettings')}>
            Zipwhip Settings
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
      {renderActiveComponent()}
    </div>
  )
}

export default Plugins
