import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

const mapStateToProps = (state: ReduxState) => {
  return {
    sitesManagementDashboard: selectors.sitesManagementListSelector(state),
    error: selectors.errorSelector(state),
    isLoading: selectors.isLoadingSelector(state, 'isFetchSitesManagementDashboard' || 'isRedeployAllLandings'),
    total: selectors.totalSelector(state),
    currentPage: selectors.currentPageSelector(state),
    isNeedToFetchList: selectors.isNeedToFetchListSelector(state),
    totalLandings: selectors.featureStateSelector(state).totalLandings,
    activeLandings: selectors.featureStateSelector(state).activeLandings,
    archivedLandings: selectors.featureStateSelector(state).archiveLandings,
    newLandings: selectors.featureStateSelector(state).newLandings,
    updatesAvailable: selectors.featureStateSelector(state).updatesAvailable,
    needToRedeploy: selectors.featureStateSelector(state).needToRedeploy
  }
}

const mapDispatchToProps = {
  changeCurrentPage: actions.changeCurrentPage,
  fetchSitesManagementDashboard: actions.fetchSitesManagementDashboard,
  redeployAllLandings: actions.redeployAllLandings
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector>
