import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { NginxSettingInput } from '../types'

type Payload = {
  nginxSettingId: number
  nginxSettingData: NginxSettingInput
}

export type UpdateNginxSettingAction = FSA<undefined, Payload, string>

type UpdateNginxSetting = (args: Payload) => MrxThunk<UpdateNginxSettingAction, Promise<boolean>>

type QueryResponse = GraphQLResponse<'updateNginxSetting', boolean>

const UPDATE_NGINX_SETTING = `
  mutation updateNginxSetting ($nginxSettingId: Int!, $nginxSettingData: NginxSettingInput!) {
    updateNginxSetting (nginxSettingId: $nginxSettingId, nginxSettingData: $nginxSettingData)
  }
`

const updateNginxSetting: UpdateNginxSetting = (args) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.UPDATE_NGINX_SETTING,
    meta: { done: false }
  })

  try {
    const { nginxSettingId, nginxSettingData } = args
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_NGINX_SETTING,
      variables: { nginxSettingId, nginxSettingData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateNginxSetting } = data
    if (!updateNginxSetting) {
      throw new Error("Can't update Nginx setting!")
    }

    dispatch({
      type: t.UPDATE_NGINX_SETTING,
      payload: args,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.UPDATE_NGINX_SETTING,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { updateNginxSetting }
