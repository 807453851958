import { Payment } from 'features/orders/types'

type PaymentForRefund = {
  id: number
  maxAmount: number
  externalTransactionId: string
}

export const getAvailablePaymentsForRefund = (payments: Payment[] | undefined = []): PaymentForRefund[] => {
  const result: PaymentForRefund[] = []

  const successPayments = payments.filter(item => item.status === 'success')

  for (const successPayment of successPayments) {
    const total = payments
      .filter((payment) => payment.externalTransactionId === successPayment.externalTransactionId)
      .reduce((sym, payment) => sym + payment.total, 0)

    if (total > 0) {
      result.push({
        id: successPayment.id,
        externalTransactionId: successPayment.externalTransactionId,
        maxAmount: total
      })
    }
  }

  return result
}
