import { useQuery } from 'react-query'
import axios from 'utils/axios'
import { guardFromErrors } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'storeProductsSoldCSV', string>

const FETCH_CSV = `
  query storeProductsSoldCSV($storeId: Int!) {
    storeProductsSoldCSV(storeId: $storeId)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchProductsSold = (storeId: number) => Promise<string>

const fetchStoreProductsSoldCsv: FetchProductsSold = async (storeId) => {
  const {
    data: { data, errors }
  }: QueryResponse = await axios.post('/graphql', {
    query: FETCH_CSV,
    variables: { storeId }
  })

  guardFromErrors(errors)

  if (!data) {
    throw new Error('Response body is empty!')
  }

  const { storeProductsSoldCSV } = data
  if (!storeProductsSoldCSV) {
    throw new Error("Can't get products sold csv!")
  }

  return storeProductsSoldCSV
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useStoreProductsSoldCsv = (storeId: number) =>
  useQuery(['storeProductsSoldCSV', storeId], () => fetchStoreProductsSoldCsv(storeId), { enabled: false, retry: 0 })
