import React from 'react'
import { Table, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { CartItem } from '../../types'
import styles from './ProductsTab.module.scss'

type Props = {
  products: CartItem[]
  promocode: string
  subtotal: number
  shipping: number
  processing: number
  promocodeDiscount: number
  total: number
}

const ProductsTab = (props: Props) => {
  const { products, promocode, subtotal, shipping, processing, promocodeDiscount, total } = props

  const columns: ColumnsType<CartItem> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '5%'
    },
    {
      title: 'Name',
      dataIndex: ['product', 'displayName'],
      key: 'displayName',
      width: '25%'
    },
    {
      title: 'Variant Key',
      dataIndex: ['productPrice', 'variantKey'],
      key: 'variantKey'
    },
    {
      title: 'SKU',
      dataIndex: ['product', 'sku'],
      key: 'sku',
      width: '14%'
    },
    {
      title: 'Customer Comment',
      dataIndex: 'customerComment',
      key: 'customerComment'
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '14%'
    },
    {
      title: 'Price',
      render: (_value, record) => `$${(record.price / 100).toFixed(2)}`,
      key: 'price',
      width: '14%'
    },
    {
      title: 'Discount',
      render: (_value, record) => `$${(record.discount / 100).toFixed(2)}`,
      key: 'discount',
      width: '14%'
    },
    {
      title: 'Final price',
      render: (_value, record) => `$${(record.finalPrice / 100).toFixed(2)}`,
      key: 'finalPrice',
      width: '14%'
    },
    {
      title: 'Total',
      render: (_value, record) => `$${(record.total / 100).toFixed(2)}`,
      key: 'total',
      width: '14%'
    }
  ]

  return (
    <Table
      columns={columns}
      dataSource={products}
      bordered
      rowKey={(record) => record.id}
      pagination={false}
      summary={() => {
        return (
          <>
            <Table.Summary.Row className={styles.totalRow}>
              <Table.Summary.Cell index={0} colSpan={8} />
              <Table.Summary.Cell index={8}>
                <Typography>Sub total</Typography>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={9}>
                <Typography>{`$${(subtotal / 100).toFixed(2)}`}</Typography>
              </Table.Summary.Cell>
            </Table.Summary.Row>

            {promocode && (
              <Table.Summary.Row className={styles.totalRow}>
                <Table.Summary.Cell index={0} colSpan={6} />
                <Table.Summary.Cell index={6}>
                  <Typography>Promocode</Typography>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7}>
                  <Typography>{promocode}</Typography>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8}>
                  <Typography>Promocode discount</Typography>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9}>
                  <Typography style={{ color: 'red' }}>{`-$${(promocodeDiscount / 100).toFixed(2)}`}</Typography>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}

            <Table.Summary.Row className={styles.totalRow}>
              <Table.Summary.Cell index={0} colSpan={4} />
              <Table.Summary.Cell index={4}>
                <Typography>Shipping</Typography>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>{`$${(shipping / 100).toFixed(2)}`}</Table.Summary.Cell>
              <Table.Summary.Cell index={6}>
                <Typography>Processing</Typography>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7}>{`$${(processing / 100).toFixed(2)}`}</Table.Summary.Cell>
              <Table.Summary.Cell index={8}>
                <Typography>Grand total</Typography>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={9}>
                <Typography>{`$${(total / 100).toFixed(2)}`}</Typography>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        )
      }}
    />
  )
}

export default ProductsTab
