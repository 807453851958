// TODO - remove becouse of legacy

import React, { useContext, useState } from 'react'
import { SearchableSelectBase } from 'components'
import { GlobalContext } from 'appContexts'
import { useLandingsOptions } from '../../hooks'
import { LandingTypeEnum } from '../../types'

type PropsType = {
  initialOption: SelectOption
  disabled?: boolean
  onSelect: (selectOption: SelectOption) => void
  landingType?: LandingTypeEnum
  allowClear?: boolean
  campaignId?: number
  placeholder?: string
}

const LandingsSelect = (props: PropsType) => {
  const { landingType } = props

  const { workspaceId } = useContext(GlobalContext)

  const [searchText, setSearchText] = useState<string>('')
  const { data, isLoading } = useLandingsOptions({ workspaceId, searchText, landingType })

  return (
    <SearchableSelectBase
      {...props}
      options={data?.map((item) => ({ id: item.id, name: item.name }))}
      onSearch={(newSearchText: string) => setSearchText(newSearchText)}
      isLoading={isLoading}
    />
  )
}

export default LandingsSelect
