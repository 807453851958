import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { AuthorizeSetting } from '../types'

type AuthorizeSettingSelector = (state: ReduxState, authorizeSettingId?: number) => AuthorizeSetting | undefined

const getAuthorizeSetting: AuthorizeSettingSelector = (state, authorizeSettingId) => {
  let result
  if (authorizeSettingId && Object.prototype.hasOwnProperty.call(state.authorizeSettings.authorizeSettings, authorizeSettingId)) {
    result = state.authorizeSettings.authorizeSettings[authorizeSettingId] as AuthorizeSetting
  }
  return result
}

export const authorizeSettingSelector: AuthorizeSettingSelector = createSelector(
  getAuthorizeSetting,
  (authorizeSetting) => authorizeSetting
)
