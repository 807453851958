import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { SubscriptionStatsReport } from '../types'

type Args = {
  campaignId?: number
  landingIds?: number[]
  subscriptionProductId?: number
  withSubtractionActive?: boolean
}

type Payload = SubscriptionStatsReport & {
  withSubtractionActive?: boolean
}

export type FetchSubscriptionStatsReportAction = FSA<undefined, Payload, string>
type FetchSubscriptionStatsReport = (args: Args) => MrxThunk<FetchSubscriptionStatsReportAction>
type QueryResponse = GraphQLResponse<'subscriptionStatsReport', SubscriptionStatsReport>

const FETCH_SUBSCRIPTION_STATS_REPORT = `
  query SubscriptionStatsReport ($workspaceId: Int!, $campaignId: Int, $subscriptionProductId: Int, $landingIds: [Int], $withSubtractionActive: Boolean, $startDate: DateTime, $endDate: DateTime) {
    subscriptionStatsReport(workspaceId: $workspaceId, campaignId: $campaignId, subscriptionProductId: $subscriptionProductId, landingIds: $landingIds, withSubtractionActive: $withSubtractionActive, startDate: $startDate, endDate: $endDate) {
      takeRate
      cycles {
        cycle
        rebillRate
        averageCancel
        averageCustomerCancel
        averageInternalCancel
        averageSystemCancel
        averageDecline
        churnRate
      }
    }
  }
`

const fetchSubscriptionStatsReport: FetchSubscriptionStatsReport =
  ({ campaignId, subscriptionProductId, landingIds, withSubtractionActive }) =>
  async (dispatch, getState) => {
    dispatch({
      type: t.FETCH_SUBSCRIPTION_STATS_REPORT,
      meta: { done: false }
    })

    try {
      const {
        workspaces: {
          activeWorkspace: { id: workspaceId }
        },
        reports: { subscriptionStatsDatesRange }
      } = getState()

      const {
        data: { data, errors }
      }: QueryResponse = await axios.post('/graphql', {
        query: FETCH_SUBSCRIPTION_STATS_REPORT,
        variables: {
          workspaceId,
          campaignId,
          subscriptionProductId,
          landingIds,
          withSubtractionActive,
          startDate: subscriptionStatsDatesRange[0] && subscriptionStatsDatesRange[0].toDate(),
          endDate: subscriptionStatsDatesRange[1] && subscriptionStatsDatesRange[1].toDate()
        }
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { subscriptionStatsReport } = data
      if (!subscriptionStatsReport) {
        throw new Error("Can't get canceled subscriptions report!")
      }

      dispatch({
        type: t.FETCH_SUBSCRIPTION_STATS_REPORT,
        payload: { ...subscriptionStatsReport, withSubtractionActive },
        meta: { done: true }
      })
    } catch (err) {
      dispatch({
        type: t.FETCH_SUBSCRIPTION_STATS_REPORT,
        payload: extractErrorInfo(err),
        error: true
      })
    }
  }

export { fetchSubscriptionStatsReport }
