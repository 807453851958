import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import * as t from '../actionTypes'
import { CampaignTemplates } from '../types'

export type FetchCampaignTemplatesReportReportAction = FSA<undefined, CampaignTemplates[], string>
type FetchCampaignTemplatesReport = (campaignId: number, fullMode?: boolean) => MrxThunk<FetchCampaignTemplatesReportReportAction>
type QueryResponse = GraphQLResponse<'campaignTemplatesReport', CampaignTemplates[]>

const FETCH_CAMPAIGN_TEMPLATES_REPORT = `
  query CampaignTemplatesReport ($campaignId: Int!, $fullMode: Boolean) {
    campaignTemplatesReport (campaignId: $campaignId, fullMode: $fullMode) {
      landingId
      templateId
      subroute
      templateName
      landingsCount
      isActual
    }
  }
`

const fetchCampaignTemplatesReport: FetchCampaignTemplatesReport =
  (campaignId, fullMode = true) =>
  async (dispatch) => {
    dispatch({
      type: t.FETCH_CAMPAIGN_TEMPLATES_REPORT,
      meta: { done: false }
    })

    try {
      const {
        data: { data, errors }
      }: QueryResponse = await axios.post('/graphql', {
        query: FETCH_CAMPAIGN_TEMPLATES_REPORT,
        variables: { campaignId, fullMode }
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { campaignTemplatesReport } = data
      if (!campaignTemplatesReport) {
        throw new Error("Can't get campaign templates report!")
      }

      dispatch({
        type: t.FETCH_CAMPAIGN_TEMPLATES_REPORT,
        payload: campaignTemplatesReport,
        meta: { done: true }
      })
    } catch (err) {
      dispatch({
        type: t.FETCH_CAMPAIGN_TEMPLATES_REPORT,
        payload: extractErrorInfo(err),
        error: true
      })
    }
  }

export { fetchCampaignTemplatesReport }
