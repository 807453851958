import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as workspacesFeature from 'features/workspaces'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

type OwnProps = {
  campaignId: number
  showHeader?: boolean
  usePagination?: boolean
  searchQuery?: string
}

const mapStateToProps = (state: ReduxState, { campaignId, usePagination = true }: OwnProps) => {
  const pagination = selectors.paginationSelector(state, campaignId)
  return {
    categoriesList: usePagination
      ? selectors.categoriesListSelector(state, campaignId)
      : selectors.allCategoriesListSelector(state, campaignId),
    workspaceId: workspacesFeature.selectors.activeWorkspaceIdSelector(state),
    total: pagination.total,
    size: pagination.size,
    currentPage: pagination.currentPage,
    isLoading: selectors.isLoadingSelector(state, 'isListLoading'),
    error: selectors.errorSelector(state),
    isNeedToFetchList: selectors.isNeedToFetchListSelector(state, campaignId)
  }
}

const mapDispatchToProps = {
  fetchCategoriesList: actions.fetchCategoriesList,
  changeCurrentPage: actions.changeCurrentPage,
  changePageSize: actions.changePageSize,
  removeCategory: actions.removeCategory
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
