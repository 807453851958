import React, { useContext, useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import moment from 'moment'
import { Moment } from 'moment-timezone'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Table, Layout, PageHeader, Tag, Form, Button, Input, Drawer, Select, Popconfirm, DatePicker } from 'antd'
import { DeleteTwoTone, DownloadOutlined, EditTwoTone } from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table'
import { CustomerSubscriptionStatus } from '@merchx-v2/shared-types'
import notification from 'mrx-notification'
import { GlobalContext } from 'appContexts'
import { Breadcrumbs } from 'components'
import * as rootStyles from 'assets/layoutStyle'
import { CampaignsSelect } from 'features/campaigns/components'
import { useCustomerSubscriptionsList, useCustomerSubscriptionsListCsv, useCancelCustomerSubscription } from '../../hooks'
import { CustomerSubscription } from '../../types'
import CustomerSubscriptionDetails from '../CustomerSubscriptionDetails'

import styles from './CustomerSubscriptionsList.module.scss'

const tagColors: Record<CustomerSubscriptionStatus, string> = {
  SUSPENDED: 'orange',
  TERMINATED: 'error',
  CANCELED: 'red',
  FAILED: 'red',
  ACTIVE: 'green',
  EXPIRED: 'default',
  EXPIRING: 'yellow'
}

const { RangePicker } = DatePicker
const dateFormat = 'MM/DD/YYYY'

const CustomerSubscriptionsList = () => {
  const { workspaceId } = useContext(GlobalContext)

  const [sorting, setSorting] = useState<SortingType>()
  const { history, location } = useReactRouter()
  const [searchQuery, setSearchQuery] = useState<string>()
  const [selectedStatus, setSelectedStatus] = useState<string>()
  const [selectedSource, setSelectedSource] = useState<string>()
  const [selectedCampaignId, setSelectedCampaignId] = useState<number>()
  const [datesRange, setDateRange] = useState<[Moment, Moment]>()
  const [isVisible, setVisible] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!sessionStorage.getItem('subscriptionsCampaignId')) {
      setSelectedCampaignId(+sessionStorage.getItem('subscriptionsCampaignId'))
    }
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!sessionStorage.getItem('subscriptionsStatus')) {
      setSelectedStatus(sessionStorage.getItem('subscriptionsStatus'))
    }
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!sessionStorage.getItem('subscriptionsSource')) {
      setSelectedSource(sessionStorage.getItem('subscriptionsSource'))
    }
    const dateFrom = sessionStorage.getItem('subscriptionsDatesRangeFrom')
      ? moment(sessionStorage.getItem('subscriptionsDatesRangeFrom'))
      : moment().startOf('day')
    const dateTo = sessionStorage.getItem('subscriptionsDatesRangeTo')
      ? moment(sessionStorage.getItem('subscriptionsDatesRangeTo'))
      : moment().add(1, 'day').endOf('day')
    setDateRange([dateFrom, dateTo])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const customerSubscriptionsList = useCustomerSubscriptionsList({
    workspaceId,
    query: searchQuery,
    status: selectedStatus,
    saleSource: selectedSource,
    campaignId: selectedCampaignId,
    sorting: sorting,
    startDate: datesRange && datesRange[0],
    endDate: datesRange && datesRange[1]
  })

  const customerSubscriptionsCsv = useCustomerSubscriptionsListCsv(workspaceId)
  const cancelCustomerSubscription = useCancelCustomerSubscription()

  useEffect(() => {
    if (customerSubscriptionsList.error) {
      notification.error({
        message: 'Customer subscriptions list error!',
        description:
          customerSubscriptionsList.error instanceof Error
            ? customerSubscriptionsList.error.message
            : customerSubscriptionsList.error.toString()
      })
    }
  }, [customerSubscriptionsList.error])

  useEffect(() => {
    if (customerSubscriptionsCsv.error) {
      notification.error({
        message: 'Customer subscriptions list csv error!',
        description:
          customerSubscriptionsCsv.error instanceof Error
            ? customerSubscriptionsCsv.error.message
            : customerSubscriptionsCsv.error.toString()
      })
    }
  }, [customerSubscriptionsCsv.error])

  useEffect(() => {
    if (cancelCustomerSubscription.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Customer subscription was canceled successfully!'
      })
    }
  }, [cancelCustomerSubscription.isSuccess])

  useEffect(() => {
    customerSubscriptionsCsv.data && window.open(customerSubscriptionsCsv.data, '_blank')
  }, [customerSubscriptionsCsv.data])

  const customerSubscriptions: CustomerSubscription[] = []
  customerSubscriptionsList &&
    customerSubscriptionsList.data &&
    customerSubscriptionsList.data.pages.forEach((page) => page.items.forEach((item) => customerSubscriptions.push(item)))

  const page = customerSubscriptionsList.data?.pages.length
    ? customerSubscriptionsList.data?.pages[customerSubscriptionsList.data?.pages.length - 1].currentPage
    : 1
  const size = customerSubscriptionsList.data?.pages.length
    ? customerSubscriptionsList.data?.pages[customerSubscriptionsList.data?.pages.length - 1].pageSize
    : 30

  const sortingHandler = (pagination, filters, sorter) => {
    if (sorter?.order) {
      setSorting({ field: sorter.field || sorter.columnKey, order: sorter.order === 'ascend' ? 'ASC' : 'DESC' })
    } else {
      setSorting(undefined)
    }
  }

  const [form] = Form.useForm()

  const doConfirm = async (customerSubscriptionId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    cancelCustomerSubscription.mutate(customerSubscriptionId)
  }

  const columns: ColumnsType<CustomerSubscription> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true
    },
    {
      title: 'Trial Start',
      render: (_value, record) => moment(record.creationDate).format('MM/DD/YYYY HH:mm:ss'),
      key: 'creationDate',
      sorter: true
    },
    {
      title: 'Status',
      render: (_value, record) => <Tag color={tagColors[record.status]}>{record.status.toUpperCase()}</Tag>,
      key: 'status'
    },
    {
      title: 'Customer',
      render: (_value, record) => `${record.customer?.firstName || ''} ${record.customer?.lastName || ''}`,
      key: 'customer'
    },
    {
      title: 'Customer Email',
      dataIndex: ['customer', 'email'],
      key: 'customer.email'
    },
    {
      title: 'Order ID',
      dataIndex: 'orderId',
      key: 'orderId'
    },
    {
      title: 'Sale source',
      dataIndex: 'saleSource',
      key: 'saleSource'
    },
    {
      title: 'Campaign Name',
      dataIndex: 'campaignName',
      key: 'campaignName'
    },
    {
      title: 'Landing Name',
      dataIndex: ['landing', 'name'],
      key: 'landing.name'
    },
    {
      title: 'Subscription Start',
      render: (_value, record) => moment(record.startDate).format('MM/DD/YYYY HH:mm:ss'),
      key: 'startDate'
    },
    {
      title: 'Next Billing Date (Expiring Date)',
      render: (_value, record) => moment(record.paidTill).format('MM/DD/YYYY HH:mm:ss'),
      key: 'paidTill'
    },
    {
      title: 'Cancellation Date',
      render: (_value, record) => (record.canceledAt ? moment(record.canceledAt).format('MM/DD/YYYY HH:mm:ss') : ''),
      key: 'canceledAt'
    },
    {
      title: 'Cycles',
      dataIndex: 'cycles',
      key: 'cycles'
    },
    {
      title: 'Total',
      render: (_value, record) => `$${(record.total / 100).toFixed(2)}`,
      key: 'total',
      align: 'right',
      sorter: true
    },
    {
      title: '',
      dataIndex: '',
      key: 'edit',
      width: '5%',
      align: 'center',
      render: (record) => (
        <span>
          <EditTwoTone
            style={{ fontSize: '20px' }}
            onClick={(e) => {
              e.stopPropagation()
              history.push(`/sales/customerSubscriptions?customerSubscriptionId=${record.id}`)
            }}
          />
        </span>
      )
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '5%',
      align: 'center',
      render: (_value, record) => (
        <span>
          <Popconfirm
            title='Are you sure cancel this subscription?'
            onConfirm={(e) => doConfirm(+record.id, e)}
            onCancel={(e) => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <DeleteTwoTone
              twoToneColor='#ec1c24'
              style={{ fontSize: '20px' }}
              onClick={(e) => {
                e.stopPropagation()
              }}
            />
          </Popconfirm>
        </span>
      )
    }
  ]

  const handleDrawerClose = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleOnCustomerSubscriptionDetailsClose = () => {
    history.push(`/sales/customerSubscriptions`)
  }

  const handleCampaignChange = (value) => {
    setSelectedCampaignId(value.id)
    if (value) {
      sessionStorage.setItem('subscriptionsCampaignId', value.id)
    } else {
      sessionStorage.removeItem('subscriptionsCampaignId')
    }
  }

  const handleStatusChange = (value) => {
    setSelectedStatus(value)
    if (value) {
      sessionStorage.setItem('subscriptionsStatus', value)
    } else {
      sessionStorage.removeItem('subscriptionsStatus')
    }
  }

  const handleSourceChange = (value) => {
    setSelectedSource(value)
    if (value) {
      sessionStorage.setItem('subscriptionsSource', value)
    } else {
      sessionStorage.removeItem('subscriptionsSource')
    }
  }

  const changeDatesRange = (dates: [Moment, Moment]) => {
    if (dates !== null) {
      setDateRange(dates)
      sessionStorage.setItem('subscriptionsDatesRangeFrom', dates[0].toString())
      sessionStorage.setItem('subscriptionsDatesRangeTo', dates[1].toString())
    }
  }

  const onSearchInputChangeHandler = (value) => {
    setDateRange([null, null])
    setSearchQuery(value)
  }

  const customerSubscriptionId = location.search && location.search.match(/\d+/g).toString()

  const handleExportCSV = () => customerSubscriptionsCsv.refetch()

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title='Subscriptions'
          extra={[
            <Button key='1' onClick={() => setVisible(!isVisible)}>
              Settings
            </Button>,
            <Input.Search
              key='search-field'
              placeholder='Input search text'
              onSearch={(value) => onSearchInputChangeHandler(value)}
              style={{ width: 200 }}
              allowClear
            />,
            <Button
              key='csv'
              icon={<DownloadOutlined />}
              disabled={customerSubscriptionsCsv.isLoading}
              loading={customerSubscriptionsCsv.isLoading}
              onClick={handleExportCSV}
            >
              CSV
            </Button>
          ]}
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Sales', url: '/' },
            { title: 'Subscriptions', url: '/' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <InfiniteScroll
          style={{ paddingBottom: '30px' }}
          dataLength={page * size}
          next={customerSubscriptionsList.fetchNextPage}
          hasMore={!!customerSubscriptionsList.hasNextPage}
          loader={<h4>Loading...</h4>}
          refreshFunction={customerSubscriptionsList.refetch}
          scrollThreshold={0.8}
        >
          <Table
            columns={columns}
            dataSource={customerSubscriptions.map((item) => item).flat()}
            bordered
            rowKey={(record) => record.id}
            loading={customerSubscriptionsList.isLoading}
            onChange={sortingHandler}
            pagination={false}
          />
        </InfiniteScroll>
      </Layout.Content>
      <CustomerSubscriptionDetails customerSubscriptionId={+customerSubscriptionId} onClose={handleOnCustomerSubscriptionDetailsClose} />
      <Drawer title='Subscriptions Settings' width='400' onClose={handleDrawerClose} visible={isVisible}>
        <Form
          layout='vertical'
          hideRequiredMark
          form={form}
          initialValues={{ campaignId: selectedCampaignId, status: selectedStatus, saleSource: selectedSource }}
        >
          <Form.Item name='campaignId' label='Campaigns'>
            <CampaignsSelect
              workspaceId={workspaceId}
              initialOption={{ id: selectedCampaignId, name: '' }}
              onSelect={handleCampaignChange}
              placeholder='Please select Campaign'
            />
          </Form.Item>

          <Form.Item name='status' label='Status'>
            <Select key='3' placeholder='Please select Status' onChange={handleStatusChange} allowClear value={selectedStatus}>
              <Select.Option value='ACTIVE' key='ACTIVE'>
                Active
              </Select.Option>
              <Select.Option value='EXPIRED' key='EXPIRED'>
                Expired
              </Select.Option>
              <Select.Option value='SUSPENDED' key='SUSPENDED'>
                Suspended
              </Select.Option>
              <Select.Option value='CANCELED' key='CANCELED'>
                Canceled
              </Select.Option>
              <Select.Option value='TERMINATED' key='TERMINATED'>
                Terminated
              </Select.Option>
              <Select.Option value='FAILED' key='FAILED'>
                Failed
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='saleSource' label='Source'>
            <Select key='4' placeholder='Please select Source' onChange={handleSourceChange} allowClear value={selectedSource}>
              <Select.Option value='email' key='email'>
                Email
              </Select.Option>
              <Select.Option value='facebook' key='facebook'>
                Facebook
              </Select.Option>
              <Select.Option value='google shopping' key='google shopping'>
                Google shopping
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label='Dates range'>
            <RangePicker
              className={styles.rangePicker}
              defaultValue={datesRange}
              format={dateFormat}
              ranges={{
                Today: [moment().startOf('day'), moment().add(1, 'day').endOf('day')],
                'Last 7 days': [moment().subtract(7, 'days'), moment().startOf('day')],
                'This Week': [moment().startOf('week'), moment().endOf('week')],
                'This Month': [moment().startOf('month'), moment().endOf('month')]
              }}
              onChange={changeDatesRange}
            />
          </Form.Item>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button type='primary' htmlType='submit' loading={customerSubscriptionsList.isLoading} onClick={handleDrawerClose}>
              Close
            </Button>
          </div>
        </Form>
      </Drawer>
    </Layout>
  )
}

export default CustomerSubscriptionsList
