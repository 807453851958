import React from 'react'
import { Avatar, Tooltip, Layout } from 'antd'
import CreateWorkspace from '../CreateWorkspace'
import { connector, PropsFromRedux } from './container'
import styles from './WorkspacesList.module.scss'

const WorkspacesList: React.FC<PropsFromRedux> = ({ workspacesList, switchWorkspace }) => {
  const handleActiveWorkspaceChanged = ({ currentTarget }: React.MouseEvent<HTMLElement>) => {
    switchWorkspace(+currentTarget.dataset.id!)
  }

  return (
    <Layout
      className={styles.listContainer}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0
      }}
    >
      {workspacesList.map((item) => (
        <Tooltip key={item.id} placement='right' title={item.name}>
          <div data-id={item.id} onClick={handleActiveWorkspaceChanged}>
            <Avatar shape='square' size='large' className={styles.avatar}>
              {item.name.charAt(0).toUpperCase()}
            </Avatar>
          </div>
        </Tooltip>
      ))}
      <CreateWorkspace />
    </Layout>
  )
}

export default connector(WorkspacesList)
