import React, { useState, useEffect } from 'react'
import { Button, Input, Typography, UploadFile } from 'antd'
import { CustomGatewayArtworkDto, ProductType } from '@merchx-v2/shared-types'
import { UploadImages } from 'components'
import { ArtworkInput } from 'features/types'
import useDebounce from 'utils/useDebounce'
import styles from './CustomGatewayArtwork.module.scss'
import { DTGProductsSelect } from 'features/DTGProducts/components'


const getMaxPrintArea = (productType: ProductType): { width: string; height: string } => {
  switch (productType) {
    case 'HOODIE': {
      return {
        width: '220px',
        height: '148px'
      }
    }

    default: {
      return {
        width: '200px',
        height: '248px'
      }
    }
  }
}

type Props = {
  productType: ProductType
  artwork?: CustomGatewayArtworkDto
  withoutImages?: boolean
  onChange: (args: ArtworkInput) => void
}
const CustomGatewayArtwork = (props: Props) => {
  const { artwork, productType, withoutImages = false, onChange } = props
  const [DTGArtworkForPreview, setDTGArtworkForPreview] = useState<string>(artwork?.preview || '')
  const [DTGBackArtworkForPreview, setDTGBackArtworkForPreview] = useState<string>(artwork?.backPreview || '')
  const [DTGProductId, setDTGProductId] = useState<number>(artwork?.DTGProductId)
  const [CGProductType, setCGProductType] = useState<string>(artwork?.productType)
  const [artworkFile, setArtworkFile] = useState<UploadFile | null>(null)
  const [backArtworkFile, setBackArtworkFile] = useState<UploadFile | null>(null)
  const debouncedCGProdType = useDebounce(CGProductType, 500)

  useEffect(() => {
    if (debouncedCGProdType && debouncedCGProdType !== artwork?.productType) {
      onChange({
        DTGProductId,
        backFile: backArtworkFile,
        file: artworkFile,
        productType: CGProductType
      })
    }
  }, [debouncedCGProdType])

  const onArtworkChange = (args: ArtworkInput) => {
    onChange(args)
  }

  return (
    <div className={styles.wrapper}>
      <Typography>DTG Artwork</Typography>

      <div style={{ marginBottom: '10px' }}>
        <DTGProductsSelect
          initialOption={{ id: artwork?.DTGProductId }}
          onSelect={(selectedOption: SelectOption) => {
            setDTGProductId(selectedOption?.id)
            onArtworkChange({
              DTGProductId: selectedOption?.id,
              backFile: backArtworkFile,
              file: artworkFile,
              productType: CGProductType
            })
          }}
        />
        {artwork && !withoutImages && (
          <Typography style={{ fontSize: '10px', fontWeight: 700, marginTop: '6px' }}>
            Virtual product ID: {artwork.virtualProductId || '...pending'}
          </Typography>
        )}
      </div>

      <div style={{ marginBottom: '10px' }}>
        Extended Product Type
        <Input defaultValue={CGProductType} placeholder='Input CG product type to be shown as part of description in items' onChange={e => setCGProductType(e.target.value)} />
      </div>

      {!withoutImages && (
        <>
          <div className={styles.artworkUploadContainer}>
            <div className={styles.artworkUploader}>
              <Typography style={{ fontSize: '12px' }}>
                Front Side Artwork
              </Typography>
              <UploadImages
                filesToUpload={[]}
                assetsToRemove={[]}
                numberOfImages={1}
                onChange={(filesToUpload) => {
                  if (filesToUpload[0]) {
                    setDTGArtworkForPreview(filesToUpload[0].url)
                    setArtworkFile(filesToUpload[0])
                    onArtworkChange({
                      DTGProductId,
                      file: filesToUpload[0],
                      backFile: backArtworkFile,
                      productType: CGProductType
                    })
                  }
                }}
              />
            </div>
            <div className={styles.artworkUploader}>
              <Typography style={{ fontSize: '12px' }}>
                Back Side Artwork
              </Typography>
              <UploadImages
                filesToUpload={[]}
                assetsToRemove={[]}
                numberOfImages={1}
                onChange={(filesToUpload) => {
                  if (filesToUpload[0]) {
                    setDTGBackArtworkForPreview(filesToUpload[0].url)
                    setBackArtworkFile(filesToUpload[0])
                    onArtworkChange({
                      DTGProductId,
                      file: artworkFile,
                      backFile: filesToUpload[0],
                      productType: CGProductType
                    })
                  }
                }}
              />
            </div>
          </div>
          {(DTGArtworkForPreview || DTGBackArtworkForPreview) && (
            <div className={styles.artworkPreviewContainer}>
              {DTGArtworkForPreview && (
                <div className={styles.artworkPreview}>
                  <Typography style={{ color: 'black', marginTop: '10px', fontSize: '12px' }}>Front print area preview</Typography>
                  <div className={styles.artworkContainer} style={{ ...getMaxPrintArea(productType as ProductType) }}>
                    <img src={DTGArtworkForPreview} alt='artwork' />
                  </div>
                  {artwork?.original && (
                    <a href={artwork.original} className={styles.downloadButton} target='_blank' rel='noreferrer'>
                      <Button>Download artwork</Button>
                    </a>
                  )}
                </div>
              )}

              {DTGBackArtworkForPreview && (
                <div className={styles.artworkPreview}>
                  <Typography style={{ color: 'black', marginTop: '10px', fontSize: '12px' }}>Back print area preview</Typography>
                  <div className={styles.artworkContainer} style={{ ...getMaxPrintArea(productType as ProductType) }}>
                    <img src={DTGBackArtworkForPreview} alt='artwork' />
                  </div>
                  {artwork?.backOriginal && (
                    <a href={artwork.backOriginal} className={styles.downloadButton} target='_blank' rel='noreferrer'>
                      <Button>Download artwork</Button>
                    </a>
                  )}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default CustomGatewayArtwork
