import React, { useState } from 'react'
import { Table, Popconfirm, Card } from 'antd'
import notification from 'mrx-notification'
import { ColumnsType } from 'antd/lib/table'
import { SiteType } from '@merchx-v2/shared-types'
import { useRemoveDependency } from '../../hooks'
import { Dependency } from '../../types'
import CreateDependency from '../CreateDependency'
import UpdateDependency from '../UpdateDependency'

import styles from './DependenciesList.module.scss'

type PropsType = {
  ownerType: SiteType
  ownerId: number
  dependencies: Dependency[]
}

const DependenciesList = (props: PropsType) => {
  const { ownerType, ownerId, dependencies } = props

  const [selectedDependencyId, setSelectedDependencyId] = useState<number>()
  const [isUpdateVisible, setIsUpdateVisible] = useState<boolean>(false)

  const removeDependency = useRemoveDependency()

  const doConfirm = async (dependencyId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    removeDependency.mutate(
      { ownerType, ownerId, dependencyId },
      {
        onSuccess: () => {
          notification.success({
            message: 'Successfully',
            description: 'Template Dependency was removed successfully!'
          })
        },
        onError: (error) => {
          notification.error({
            message: 'Error!',
            description: error.toString()
          })
        }
      }
    )
  }

  const handleOnRow = (record: Dependency) => ({
    onClick() {
      setSelectedDependencyId(record.id)
      setIsUpdateVisible(true)
    }
  })

  const handleOnDependencyClose = () => {
    setIsUpdateVisible(false)
  }

  const columns: ColumnsType<Dependency> = [
    {
      title: '#',
      width: '10%',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Version',
      width: '15%',
      dataIndex: 'version',
      key: 'version'
    },
    {
      title: 'Action',
      width: '15%',
      dataIndex: '',
      key: 'x',
      render: (record: Dependency) => (
        <span>
          <Popconfirm
            title='Are you sure delete this dependence?'
            onConfirm={(e) => doConfirm(record.id, e)}
            onCancel={(e) => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <a
              href='/#'
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              Remove
            </a>
          </Popconfirm>
        </span>
      )
    }
  ]

  const dependencyForUpdate = dependencies.find((item) => item.id === selectedDependencyId)

  return (
    <div className={styles.tableContainer}>
      <Card
        title={<span className={styles.cardTitle}>Dependencies</span>}
        extra={<CreateDependency ownerType={ownerType} ownerId={ownerId} />}
      >
        <UpdateDependency
          ownerType={ownerType}
          ownerId={ownerId}
          dependencyId={selectedDependencyId}
          dependency={dependencyForUpdate}
          onClose={handleOnDependencyClose}
          visible={isUpdateVisible}
        />
        <Table columns={columns} dataSource={dependencies} bordered rowKey={(record) => record.id} pagination={false} onRow={handleOnRow} />
      </Card>
    </div>
  )
}

export default DependenciesList
