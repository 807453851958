import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

type Payload = {
  awsCertificateId: number
  awsCertificateData: AwsCertificateInput
}

export type UpdateAwsCertificateAction = FSA<undefined, Payload, string>

type UpdateAwsCertificate = (args: Payload) => MrxThunk<UpdateAwsCertificateAction, Promise<boolean>>

type QueryResponse = GraphQLResponse<'updateAwsCertificate', boolean>

const UPDATE_AWS_CERTIFICATE = `
  mutation updateAwsCertificate($awsCertificateId: Int!, $awsCertificateData: AwsCertificateInput!) {
    updateAwsCertificate(awsCertificateId: $awsCertificateId, awsCertificateData: $awsCertificateData)
  }
`

type AwsCertificateInput = {
  domainName: string
  certificateArn: string
  expiringAt: Date
  issuedAt: Date
}

const updateAwsCertificate: UpdateAwsCertificate = (args) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.UPDATE_AWS_CERTIFICATE,
    meta: { done: false }
  })

  try {
    const { awsCertificateId, awsCertificateData } = args
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_AWS_CERTIFICATE,
      variables: { awsCertificateId, awsCertificateData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateAwsCertificate } = data
    if (!updateAwsCertificate) {
      throw new Error("Can't update aws certificate!")
    }

    dispatch({
      type: t.UPDATE_AWS_CERTIFICATE,
      payload: args,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.UPDATE_AWS_CERTIFICATE,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { updateAwsCertificate }
