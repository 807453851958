// TODO - remove becouse of legacy

import React, { useEffect, useRef } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { Console } from '@nicksrandall/console-feed'
import { Message } from '@nicksrandall/console-feed/lib/definitions/Component'
import { convertLoggerMessageToFeed } from 'utils/convertLoggerMessageToFeed'
import { PropsFromRedux, connector } from './container'
import styles from './LandingBuildLogs.module.scss'

const LandingBuildLogs = (props: PropsFromRedux) => {
  const { landingId, builds, version, subscribeToChannel, unsubscribeFromChannel } = props

  const feedRef = useRef()

  const buildLogs = builds?.reduce((accum, item) => {
    if (!accum[item.version]) {
      accum[item.version] = [] as Message[]
    }

    accum[item.version] = [convertLoggerMessageToFeed(item.logs)]
    return accum
  }, {} as Record<number, Message[]>)

  useEffect(() => {
    const channel = `deploy-landing/${landingId}`
    subscribeToChannel(channel)

    return () => {
      unsubscribeFromChannel(channel)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landingId])

  useDeepCompareEffect(() => {
    if (feedRef.current) {
      // @ts-ignore
      feedRef.current.scrollTop = feedRef.current.scrollHeight
    }
  }, [builds])

  return (
    <div className={styles.buildsContainer}>
      <div ref={feedRef} className={styles.buildLogsContainer}>
        <Console
          styles={{
            BASE_COLOR: 'black',
            LOG_COLOR: '#CCC1C1',
            LOG_INFO_COLOR: '#BC3FB7',
            LOG_RESULT_COLOR: '#12BC79',
            LOG_WARN_COLOR: '#E5E510'
          }}
          // @ts-ignore
          logs={buildLogs[version] || []}
        />
      </div>
    </div>
  )
}

export default connector(LandingBuildLogs)
