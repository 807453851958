import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type isLoadingKeyType =
  | 'isCreateStripeSetting'
  | 'isListLoading'
  | 'isRemoveStripeSetting'
  | 'isUpdateStripeSetting'
  | 'isStripeSettingsForOptionsLoading'

type IsLoadingSelector = (state: ReduxState, isLoadingKey: isLoadingKeyType) => boolean

const getIsLoadingSelectorByKey = (state: ReduxState, isLoadingKey: isLoadingKeyType) => state.stripeSettings.UIState[isLoadingKey] || false

export const isLoadingSelector: IsLoadingSelector = createSelector(getIsLoadingSelectorByKey, (isLoading) => isLoading)
