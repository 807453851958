import { useQuery } from 'react-query'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

import { Customer } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'customer', Customer>

const FETCH_CUSTOMER = `
  query fetchCustomer ($customerId: Int!) {
    customer(customerId: $customerId) {
      id
      email
      firstName
      lastName
      phone
      totalRevenue
      numberOfOrders
      customerOrders {
        id
        status
        total
        bin
        cardNumber
        cardExpDate
        campaignName
        saleSource
        createdAt
      }
      createdFromLandingUrl
      membershipType
      numberOfSubscriptions
      customerSubscriptions {
        id
        status
        total
        paymentSubscriptionId
        createdAt
        campaignName
        saleSource
        startDate
        interval
        repeatFrequency
      }
      customerTags {
        id
        tag
      }
      customerOrdersNotes {
        orderId
        saleSource
        createdAt
        note
        campaign {
          name
        }
      }
      createdAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchCustomer = (customerId: number) => Promise<Customer>

const fetchCustomer: FetchCustomer = async (customerId) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_CUSTOMER,
      variables: { customerId: parseInt(customerId + '') }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { customer } = data
    if (!customer) {
      throw new Error("Can't get customer!")
    }

    return customer
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCustomer = (customerId: number) => useQuery(['customer', customerId], () => fetchCustomer(customerId))
