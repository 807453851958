import { useInfiniteQuery } from 'react-query'
import { CustomerAccountsFiltersDto } from '@merchx-v2/shared-types'
import hash from 'object-hash'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { GraphQLCustomerAccountsListPageItem } from '../types'

const DEFAULT_PAGE_SIZE = 15

export type CustomerAccountsPage = {
  items: GraphQLCustomerAccountsListPageItem[]
  total: number
  currentPage: number
  pageSize: number
  filters?: CustomerAccountsFiltersDto
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'customerAccountsListPage', CustomerAccountsPage>

const FETCH_CUSTOMER_ACCOUNTS_LIST = `
query customerAccountsListPage ($page: Int, $size: Int, $filters: CustomerAccountsListPageFilters!) {
  customerAccountsListPage (page: $page, size: $size, filters: $filters) {
    items {
      id
      email
      username
      firstName
      lastName
  
      isEmailConfirmed
      isDeactivated
      bannedTill
      lastLoginAt
  
      createdAt
      deletedAt
    }
    total
  }
}
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type PageParam = {
  page?: number
  size?: number
  filters: CustomerAccountsFiltersDto
}

type FetchCustomerAccountsListArgs = {
  queryKey: string | string[]
  pageParam?: PageParam
}

type FetchCustomerAccountsList = (args: FetchCustomerAccountsListArgs) => Promise<CustomerAccountsPage>

const fetchCustomerAccountsList: FetchCustomerAccountsList = async ({ pageParam }) => {
  const { page = 1, size = DEFAULT_PAGE_SIZE, filters = undefined } = pageParam || {}

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_CUSTOMER_ACCOUNTS_LIST,
      variables: { page, size, filters }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { customerAccountsListPage } = data
    if (!customerAccountsListPage) {
      throw new Error("Can't get customer accounts list page!")
    }

    return {
      items: customerAccountsListPage.items,
      total: customerAccountsListPage.total,
      currentPage: page,
      pageSize: size,
      filters
    }
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

type GetNextPageParam = (args: CustomerAccountsPage) => PageParam | undefined

type CustomerAccountsListEvents = {
  keepPreviousData: boolean
  getPreviousPageParam: GetNextPageParam
  getNextPageParam: GetNextPageParam
}

const customerAccountsListEvents: CustomerAccountsListEvents = {
  keepPreviousData: true,
  getPreviousPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, filters }) => {
    if (currentPage > 1) {
      return {
        page: currentPage - 1,
        size: pageSize,
        filters
      }
    }

    return undefined
  },
  getNextPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, total = 0, filters }) => {
    if (pageSize * currentPage < total) {
      return {
        page: currentPage + 1,
        size: pageSize,
        filters
      }
    }
    return undefined
  }
}

export const useCustomerAccountsList = (filters: CustomerAccountsFiltersDto) => {
  const cacheKey = ['customerAccounts', hash(filters)]

  return useInfiniteQuery(
    cacheKey,
    (prev) =>
      fetchCustomerAccountsList({
        queryKey: cacheKey,
        pageParam: { ...prev.pageParam, filters }
      }),
    customerAccountsListEvents
  )
}
