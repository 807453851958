// TODO - remove becouse of legacy

import { Landing, StepState, WizardStepStatus } from '../../types'

const getTemplatesStep: (landing: Landing, currentStep: number) => StepState = (landing, currentStep) => {
  const isValid = landing?.id !== undefined
  let status = 'wait' as WizardStepStatus

  if (isValid) {
    status = 'finish' as WizardStepStatus
  }

  if (currentStep === 1) {
    status = 'process' as WizardStepStatus
  }

  if (currentStep !== 1 && !isValid) {
    status = 'error' as WizardStepStatus
  }

  return {
    isValid,
    status
  }
}

export { getTemplatesStep }
