import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { SubscriptionStatsReport } from '../types'

type SubscriptionStatsReportWithoutActiveSelector = (state: ReduxState) => SubscriptionStatsReport | void

const getSubscriptionStatsReportSelector = (state: ReduxState) => state.reports.subscriptionStatsReportWithoutActive

export const subscriptionStatsReportWithoutActive: SubscriptionStatsReportWithoutActiveSelector = createSelector(
  getSubscriptionStatsReportSelector,
  subscriptionStats => subscriptionStats
)
