import { useInfiniteQuery } from 'react-query'
import { OrderItemHoldReason } from '@merchx-v2/shared-types/dist/constants/orderItemHoldReason'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { OrderItemsList, OrderItemsListFilters, GraphQLOrderItemsList } from '../types'
import hash from 'object-hash'

const DEFAULT_PAGE_SIZE = 30

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'orderItemsListPage', GraphQLOrderItemsList>

const FETCH_ORDER_ITEMS_LIST = `
  query fetchOrderItemsListPage ($page: Int, $size: Int, $filters: OrderItemsFilters!) {
    orderItemsListPage(page: $page, size: $size, filters: $filters) {
      items {
        id
        orderId
        productId
        product {
          campaignId
        }
        displayName
        status
        total
        createdAt
      }
      total
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type PageParam = {
  page?: number
  size?: number
  filters?: {
    workspaceId: number
    groupByProduct?: boolean
    status?: string
    holdReason?: OrderItemHoldReason
  }
}

type FetchOrderItemsListArgs = {
  queryKey: string | string[]
  pageParam?: PageParam
}

type FetchOrderItemsList = (args: FetchOrderItemsListArgs) => Promise<OrderItemsList>

const fetchOrderItemsList: FetchOrderItemsList = async ({ pageParam }) => {
  const { page = 1, size = DEFAULT_PAGE_SIZE, filters } = pageParam
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_ORDER_ITEMS_LIST,
      variables: { page, size, filters }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { orderItemsListPage } = data
    if (!orderItemsListPage) {
      throw new Error("Can't get order items list page!")
    }

    return {
      items: orderItemsListPage.items,
      total: orderItemsListPage.total,
      currentPage: page,
      pageSize: size,
      filters
    }
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

type GetNextPageParam = (args: OrderItemsList) => PageParam

type OrderItemsListEvents = {
  keepPreviousData: boolean
  getPreviousPageParam: GetNextPageParam
  getNextPageParam: GetNextPageParam
}

const orderItemsListEvents: OrderItemsListEvents = {
  keepPreviousData: true,
  getPreviousPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, filters }) => {
    if (currentPage > 1) {
      return {
        page: currentPage - 1,
        size: pageSize,
        filters
      }
    }

    return undefined
  },
  getNextPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, total = 0, filters }) => {
    if (pageSize * currentPage < total) {
      return {
        page: currentPage + 1,
        size: pageSize,
        filters
      }
    }

    return undefined
  }
}

export const useOrderItemsList = (filters: OrderItemsListFilters) => {
  const cacheKey = hash(filters)

  return useInfiniteQuery<OrderItemsList, Error, OrderItemsList>(
    ['orderItemsList', cacheKey],
    (prev) => {
      return fetchOrderItemsList({
        queryKey: ['orderItemsList', cacheKey],
        pageParam: { ...prev.pageParam, filters }
      })
    },
    orderItemsListEvents
  )
}
