import React, { useEffect } from 'react'
import { Button, Input, Form } from 'antd'
import notification from 'mrx-notification'
import { connector, PropsFromRedux } from './container'
import logo from 'assets/logo.png'
import styles from './Register.module.scss'

enum Fileds {
  email = 'email',
  password = 'password',
  firstName = 'firstName',
  lastName = 'lastName'
}

const Register: React.FC<PropsFromRedux> = props => {
  const { error, isLoading, register } = props
  const [form] = Form.useForm()

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Error was raised!',
        description: error
      })
      form.resetFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const onFinish = async (values: Partial<typeof Fileds>) => {
    const isSaved = await register(values.email!, values.password!, values.firstName!, values.lastName!)
    if (isSaved) {
      notification.success({
        message: 'Successfully',
        description: 'New user was created successfully!'
      })
    }
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    }
  }
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0
      },
      sm: {
        span: 16,
        offset: 8
      }
    }
  }

  return (
    <div className={styles.registerFormContainer}>
      <div className={styles.imageContainer}>
        <img className={styles.logo} src={logo} alt='Please, Log In' />
      </div>
      <Form {...formItemLayout} onFinish={onFinish} className={styles.registerForm} form={form} scrollToFirstError>
        <Form.Item
          className={styles.formItem}
          name={Fileds.email}
          label='E-mail'
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!'
            },
            {
              required: true,
              message: 'Please input your E-mail!'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={Fileds.password}
          label='Password'
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please input your password!'
            }
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name='confirm'
          label='Confirm Password'
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!'
            },
            ({ getFieldValue }) => ({
              validator(_rule, value) {
                if (!value || getFieldValue(Fileds.password) === value) {
                  return Promise.resolve()
                }

                return Promise.reject(new Error('The two passwords that you entered do not match!'))
              }
            })
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name={Fileds.firstName}
          label='First Name'
          rules={[{ required: true, message: 'Please input your First Name!', whitespace: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={Fileds.lastName}
          label='Last Name'
          rules={[{ required: true, message: 'Please input your Last Name!', whitespace: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button loading={isLoading} type='primary' htmlType='submit' className={styles.registerFormButton}>
            Register
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default connector(Register)
