import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { ShippingRuleCriteriaDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'removeShippingRuleCriteria', boolean>

const REMOVE_SHIPPING_RULE = `
  mutation removeShippingRuleCriteria ($shippingRuleCriteriaId: Int!) {
    removeShippingRuleCriteria(shippingRuleCriteriaId: $shippingRuleCriteriaId) 
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveShippingRuleCriteria = (shippingRuleCriteriaId: number) => Promise<number>

const removeShippingRuleCriteria: RemoveShippingRuleCriteria = async (shippingRuleCriteriaId) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_SHIPPING_RULE,
      variables: {
        shippingRuleCriteriaId
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeShippingRuleCriteria } = data
    if (!removeShippingRuleCriteria) {
      throw new Error("Can't remove shipping rule criteria!")
    }

    return shippingRuleCriteriaId
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveShippingRuleCriteriaContext = { prevShippingRuleCriteria?: ShippingRuleCriteriaDto }

type RemoveShippingRuleCriteriaEvents = {
  onMutate: (shippingRuleCriteriaId: number) => Promise<RemoveShippingRuleCriteriaContext>
  onError: (error: string, shippingRuleCriteriaId: number, context: RemoveShippingRuleCriteriaContext) => void
  onSettled: (shippingRuleCriteriaId?: number) => void
}

const removeShippingRuleCriteriaEvents: RemoveShippingRuleCriteriaEvents = {
  onMutate: async (shippingRuleCriteriaId) => {
    await queryClient.cancelQueries(['shippingRuleCriteria', shippingRuleCriteriaId])

    // Snapshot the previous value
    const prevShippingRuleCriteria = queryClient.getQueryData<ShippingRuleCriteriaDto>(['shippingRuleCriteria', shippingRuleCriteriaId])

    if (prevShippingRuleCriteria) {
      queryClient.removeQueries(['shippingRuleCriteria', shippingRuleCriteriaId])
    }

    return { prevShippingRuleCriteria }
  },
  onError: (_err, shippingRuleCriteriaId, context) => {
    if (context?.prevShippingRuleCriteria) {
      // Restore currrency on any error
      queryClient.setQueryData<ShippingRuleCriteriaDto>(['shippingRuleCriteria', shippingRuleCriteriaId], context.prevShippingRuleCriteria)
    }
  },
  onSettled: (shippingRuleCriteriaId) => {
    if (shippingRuleCriteriaId) {
      queryClient.invalidateQueries(['shippingRuleCriteria', shippingRuleCriteriaId])
      queryClient.invalidateQueries(['shippingRuleCriteriasList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useRemoveShippingRuleCriteria = () => useMutation(removeShippingRuleCriteria, removeShippingRuleCriteriaEvents)
