import { connect, ConnectedProps } from 'react-redux'
import { SiteType } from '@merchx-v2/shared-types'
import { ReduxState } from 'store/createRootReducer'
import * as workspacesFeature from 'features/workspaces'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

type OwnProps = {
  ownerType: SiteType
  ownerId: number
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  const { ownerType, ownerId } = ownProps
  return {
    isSnapshotsLoading: selectors.isLoadingSelector(state, 'isLoadingSnapshots'),
    isTakeSnapshot: selectors.isLoadingSelector(state, 'isTakeSnapshot'),
    isRestoreFromFile: selectors.isLoadingSelector(state, 'isRestoreFromFile'),
    isRestoreFromSnapshot: selectors.isLoadingSelector(state, 'isRestoreFromSnapshot'),
    snapshots: selectors.siteSnapshotsSelector(state, ownerType, ownerId),
    workspaceId: workspacesFeature.selectors.activeWorkspaceIdSelector(state)
  }
}

const mapDispatchToProps = {
  fetchSnapshots: actions.fetchSiteSnapshots,
  restoreSiteFromFile: actions.restoreSiteFromFile,
  restoreSiteFromSnapshot: actions.restoreSiteFromSnapshot,
  takeSnapshot: actions.takeSiteSnapshot
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
