import axios from 'utils/axios'
import { useQuery } from 'react-query'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Cart } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'cart', Cart>

// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchCart = (cartId: number) => Promise<Cart>

const fetchCart: FetchCart = async (cartId) => {
  try {
    const FETCH_CART = `
    query fetchCart ($cartId: Int!) {
    cart (cartId: $cartId) {
      id
      status
      cookie
      workspaceId
      landingId
      landing {
        name
        fulfillmentPlugin
        paymentPlugin
        campaign {
          id
          name
        }
      }
      customerId
      customer {
        id
        email
        firstName
        lastName
      }
      billingAddressId
      billingAddress {
        id
        country {
          id
          name
          iso2
          iso3
          worldPart
          code
        }
        zipCode
        state
        city
        address
        address2
      }
      shippingAddressId
      shippingAddress {
        id
        country {
          id
          name
          iso2
          iso3
          worldPart
          code
        }
        zipCode
        state
        city
        address
        address2
      }
      orderId
      promocode
      cartItems {
        id
        cartId
        productId
        product {
          id
          productType
          name
          displayName
          sku
          description
          image
        }
        productPrice {
          variantKey
        }
        productPriceId
        quantity
        price
        discount
        finalPrice
        shipping
        processing
        sum
        total
        customerComment
        createdAt
        updatedAt
      }
      subtotal
      shipping
      processing
      promocodeDiscount
      total
      lastPage
      comment
      clientIp
      deviceInfo
      createdAt
      updatedAt
    }
  }
`
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_CART,
      variables: { cartId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { cart } = data
    if (!cart) {
      throw new Error("Can't get cart!")
    }

    return cart
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCart = (cartId: number) => useQuery(['cart', cartId], () => fetchCart(cartId))
