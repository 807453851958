import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Table, Layout, PageHeader, Tabs, Tag, Typography, Button } from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table'
import numeral from 'numeral'
import moment from 'moment'
import notification from 'mrx-notification'
import useReactRouter from 'use-react-router'
import * as rootStyles from 'assets/layoutStyle'
import { Breadcrumbs } from 'components'

import UpdateCustomer from '../UpdateCustomer'
import { CustomerOrder, CustomerSubscription, CustomerOrdersNotes } from '../../types'
import { useAddCustomerTag, useCustomer, useRemoveCustomerTag } from '../../hooks'
import styles from './CustomerDetails.module.scss'
import { CustomerSubscriptionStatus } from '@merchx-v2/shared-types'

const tagDescriptions = {
  CHARGEBACK: { color: 'darkred', title: 'Chargeback' },
  PURCHASER: { color: 'blue', title: 'Purchaser' },
  ABANDONED_CART: { color: 'orange', title: 'Abandoned Cart' },
  AC_PURCHASER: { color: 'geekblue', title: 'AC Purchaser' },
  MULTI_PURCHASER: { color: 'green', title: 'Multipurchaser' },
  SOFT_DECLINED: { color: 'yellow', title: 'Soft Declined' },
  HARD_DECLINED: { color: 'volcano', title: 'Hard Declined' },
  READ_EMAILS: { color: 'purple', title: 'Read Emails' },
  EMAIL_BOUNCED: { color: 'gold', title: 'Email Bounced' },
  CLICK_IN_EMAILS: { color: 'purple', title: 'Click in Emails' },
  AVAILABLE_BY_EMAIL: { color: 'blue', title: 'Available by Email' },
  EMAIL_DROPPED: { color: 'gold', title: 'Email Dropped' },
  REPORTED_AS_A_SPAM: { color: 'volcano', title: 'Reported as a Spam' },
  UNSUBSCRIBED: { color: 'volcano', title: 'Unsubscribed from VIP' },
  UNSUBSCRIBED_EMAIL: { color: 'volcano', title: 'Unsubscribed from emails' },
  SUBSCRIBED: { color: 'cornflowerBlue', title: 'VIP' },
  VERIFIED: { color: 'cyan', title: '✓ Email verified' },
  ECOM_PURCHASER: { color: 'blue', title: 'Ecom Purchaser' },
  LANDER_PURCHASER: { color: 'blue', title: 'Lander Purchased' }
}

const tagColorsForOrders = {
  OPENED: { color: 'green', title: 'Opened' },
  NEW: { color: 'green', title: 'New' },
  PENDING: { color: 'yellow', title: 'Pending' },
  SHIPPING: { color: 'yellow', title: 'Shipping' },
  COMPLETED: { color: 'green', title: 'Completed' },
  FAILED: { color: 'red', title: 'Failed' },
  CANCELED: { color: 'red', title: 'Canceled' },
  CANCELLING: { color: 'red', title: 'Cancelling' },
  DISPATCHED: { color: 'orange', title: 'Dispatched' },
  HOLD: { color: 'orange', title: 'Hold' }
}

const tagColorsForSubscriptions: Record<CustomerSubscriptionStatus, { color: string; title: string }> = {
  SUSPENDED: { color: 'orange', title: 'Suspended' },
  TERMINATED: { color: 'error', title: 'Terminated' },
  CANCELED: { color: 'red', title: 'Canceled' },
  FAILED: { color: 'red', title: 'Failed' },
  ACTIVE: { color: 'green', title: 'Active' },
  EXPIRED: { color: 'default', title: 'Expired' },
  EXPIRING: { color: 'yellow', title: 'Expiring' }
}

const CustomerDetails = (props) => {
  const { match } = props

  const { history } = useReactRouter()

  const { data: customer, isLoading, error } = useCustomer(match?.params?.customerId)

  const addCustomerTag = useAddCustomerTag()
  const removeCustomerTag = useRemoveCustomerTag()

  const [isUpdateCustomer, setIsUpdateCustomer] = useState(false)

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Customer details page error!',
        description: error
      })
    }
  }, [error])

  useEffect(() => {
    if (addCustomerTag.isError) {
      notification.error({
        message: 'Error',
        description: addCustomerTag.error instanceof Error ? addCustomerTag.error.message : addCustomerTag.error.toString()
      })
    }

    if (removeCustomerTag.isError) {
      notification.error({
        message: 'Error',
        description: removeCustomerTag.error instanceof Error ? removeCustomerTag.error.message : removeCustomerTag.error.toString()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCustomerTag.isError, removeCustomerTag.isError])

  const handleChargebackAssign = () => {
    if (customer?.customerTags?.find((tag) => tag.tag === 'CHARGEBACK')) {
      removeCustomerTag.mutate({
        customerId: customer.id,
        tag: 'CHARGEBACK'
      })
    } else {
      addCustomerTag.mutate({
        customerId: customer.id,
        tag: 'CHARGEBACK'
      })
    }
  }

  const handleSubscribeEmailAssign = () => {
    if (customer?.customerTags?.find((tag) => tag.tag === 'UNSUBSCRIBED_EMAIL')) {
      removeCustomerTag.mutate({
        customerId: customer.id,
        tag: 'UNSUBSCRIBED_EMAIL'
      })
    } else {
      addCustomerTag.mutate({
        customerId: customer.id,
        tag: 'UNSUBSCRIBED_EMAIL'
      })
    }
  }

  const columns: ColumnsType<CustomerOrder> = [
    {
      title: 'Order ID',
      render: (_value, record) => <Link to={`/sales/customers/${customer.id}/orders/${record.id}`}>{record.id}</Link>,
      key: 'orderId'
    },
    {
      title: 'Campaign',
      dataIndex: 'campaignName',
      key: 'campaignName'
    },
    {
      title: 'Sale Source',
      dataIndex: 'saleSource',
      key: 'saleSource'
    },
    {
      title: 'Date',
      render: (record) => `${moment(record.createdAt).format('MM/DD/YYYY HH:mm:ss')}`,
      key: 'date'
    },
    {
      title: 'Status',
      render: (record) => {
        return <Tag color={tagColorsForOrders[record.status].color}>{tagColorsForOrders[record.status].title}</Tag>
      },
      key: 'status'
    },
    {
      title: 'BIN',
      dataIndex: 'bin',
      key: 'bin'
    },
    {
      title: 'Card last 4 digits',
      dataIndex: 'cardNumber',
      key: 'cardNumber'
    },
    {
      title: 'Expiry date',
      dataIndex: 'cardExpDate',
      key: 'cardExpDate'
    },
    {
      title: 'Total',
      render: (record) => `${numeral(record.total / 100).format('$0,0.00')}`,
      key: 'total'
    }
  ]
  const subscriptionColumns: ColumnsType<CustomerSubscription> = [
    {
      title: 'Subscription ID',
      render: (record) => <Link to={`/sales/customerSubscriptions?customerSubscriptionId=${record.id}`}>{record.id}</Link>,
      key: 'id'
    },
    {
      title: 'Campaign Name',
      dataIndex: 'campaignName',
      key: 'campaignName'
    },
    {
      title: 'Sale source',
      dataIndex: 'saleSource',
      key: 'saleSource'
    },
    {
      title: 'Status',
      render: (record) => {
        return <Tag color={tagColorsForSubscriptions[record.status].color}>{tagColorsForSubscriptions[record.status].title}</Tag>
      },
      key: 'status'
    },
    {
      title: 'Payment Subscription ID',
      dataIndex: 'paymentSubscriptionId',
      key: 'paymentSubscriptionID'
    },
    {
      title: 'Total',
      render: (_value, record) => `$${(record.total / 100).toFixed(2)}`,
      key: 'total',
      align: 'right'
    },
    {
      title: 'Interval',
      dataIndex: 'interval',
      key: 'interval'
    },
    {
      title: 'Repeat Frequency',
      dataIndex: 'repeatFrequency',
      key: 'repeatFrequency'
    },
    {
      title: 'Subscription Start',
      render: (_value, record) => moment(record.startDate).format('MM/DD/YYYY HH:mm:ss'),
      key: 'startDate'
    },
    {
      title: 'Trial Start',
      render: (_value, record) => moment(record.createdAt).format('MM/DD/YYYY HH:mm:ss'),
      key: 'createdAt'
    }
  ]

  const notesColumns: ColumnsType<CustomerOrdersNotes> = [
    {
      title: 'Order ID',
      render: (_value, record) => <Link to={`/sales/customers/${customer.id}/orders/${record.orderId}`}>{record.orderId}</Link>,
      key: 'orderId'
    },
    {
      title: 'Sale Source',
      dataIndex: 'saleSource'
    },
    {
      title: 'Campaign',
      dataIndex: ['campaign', 'name']
    },
    {
      title: 'Date',
      render: (_value, record) => moment(record.createdAt).format('MM/DD/YYYY HH:mm:ss'),
      sorter: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    },
    {
      title: 'Note',
      dataIndex: 'note'
    }
  ]

  const isChargeBack = !!customer?.customerTags?.find((tag) => tag.tag === 'CHARGEBACK')
  const isEmailUnsubscibed = !!customer?.customerTags?.find((tag) => tag.tag === 'UNSUBSCRIBED_EMAIL')

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader onBack={() => history.goBack()} title='Customer Details' />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Sales', url: '/' },
            { title: 'Customers', url: '/sales/customers' },
            { title: 'Customer Details', url: '/sales/customers' }
          ]}
        />
      </Layout.Content>

      {customer && (
        <div className={styles.contentComponent}>
          <Typography.Title level={3}>
            {`${customer.firstName} ${customer.lastName}`}{' '}
            <EditTwoTone className={styles.editCustomer} onClick={() => setIsUpdateCustomer(true)} />
          </Typography.Title>

          <div className={styles.manageButtons}>
            <Button type='primary' danger onClick={handleChargebackAssign}>
              {isChargeBack ? 'Unmark' : 'Mark'} as chargeback
            </Button>

            <Button onClick={handleSubscribeEmailAssign}>{isEmailUnsubscibed ? 'Subscribe' : 'Unsubscribe'} emails</Button>
          </div>

          {customer && (
            <div className={styles.listContainerRegular}>
              {
                // @ts-ignore
                customer.customerTags?.map((item: any) => {
                  return (
                    <Tag color={tagDescriptions[item.tag].color} key={item.id}>
                      {tagDescriptions[item.tag].title}
                    </Tag>
                  )
                })
              }
            </div>
          )}

          <div className={styles.listContainer}>
            <div className={styles.leftColumn}>
              <div className={styles.listItem}>Email: {customer.email}</div>
              <div className={styles.listItem}>Phone: {customer.phone}</div>
              <div className={styles.listItem}>Date Added: {moment(customer.createdAt).format('MM/DD/YYYY HH:mm:ss')}</div>
              <div className={styles.listItem}>
                Landing:{' '}
                <a target='_blank' rel='noopener noreferrer' href={`${customer.createdFromLandingUrl}`}>
                  {customer.createdFromLandingUrl}
                </a>
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.listItem}>Orders: {customer.numberOfOrders}</div>
              <div className={styles.listItem}>User VIP Status: {customer.membershipType === 'EXPIRED' ? 'CANCELED' : customer.membershipType}</div>
              <div className={styles.listItem}>Total: {`$${(customer.totalRevenue / 100).toFixed(2)}`}</div>
            </div>
          </div>

          <Tabs type='card' className={styles.tabsContainer}>
            <Tabs.TabPane tab={`Orders (${customer.numberOfOrders})`} key='1'>
              <Table
                columns={columns}
                dataSource={customer.customerOrders}
                bordered
                rowKey={(record) => record.id}
                loading={isLoading}
                pagination={false}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab={`Subscriptions (${customer.numberOfSubscriptions})`} key='2'>
              <Table
                columns={subscriptionColumns}
                dataSource={customer.customerSubscriptions}
                bordered
                rowKey={(record) => record.id}
                loading={isLoading}
                pagination={false}
                className={styles.subscriptionTable}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab={`Notes (${customer.customerOrdersNotes.length})`} key='3'>
              <Table
                columns={notesColumns}
                dataSource={customer.customerOrdersNotes}
                bordered
                rowKey={(record) => record.createdAt.toString()}
                loading={isLoading}
                pagination={false}
                className={styles.subscriptionTable}
              />
            </Tabs.TabPane>
          </Tabs>
          <UpdateCustomer visible={isUpdateCustomer} onClose={() => setIsUpdateCustomer(false)} customerId={customer.id} />
        </div>
      )}
    </Layout>
  )
}

export default CustomerDetails
