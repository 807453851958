import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Workspace } from '../types'

export type SwitchWorkspaceAction = FSA<undefined, Workspace, string>
type SwitchWorkspace = (workspaceId: number) => MrxThunk<SwitchWorkspaceAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'switchWorkspace', Workspace>

const SWITCH_WORKSPACE = `
  mutation switchWorkspace($workspaceId: Int!) {
    switchWorkspace(workspaceId: $workspaceId) {
      id
      name
      autoArchiveCampaignsWithoutSales
      daysWithoutSalesToArchive
      deploymentPlugin
      deploymentSettingsId
      deploymentSettingsName,
      paymentPlugin
      paymentSettingsId
      paymentSettingsName,
      fulfillmentPlugin
      fulfillmentSettingsId
      fulfillmentSettingsName,
      mailingPlugin
      mailingSettingsId
      mailingSettingsName,
      smsPlugin
      smsSettingsId
      smsSettingsName,
      shippingCost
      applyShippingOnce
      processingCost
      applyProcessingOnce
      allowMultiplePayments
    }
  }
`

export const switchWorkspace: SwitchWorkspace = (workspaceId) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.SWITCH_WORKSPACE,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: SWITCH_WORKSPACE,
      variables: { workspaceId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { switchWorkspace } = data
    if (!switchWorkspace) {
      throw new Error("Can't switch workspace!")
    }

    dispatch({
      type: t.SWITCH_WORKSPACE,
      payload: switchWorkspace,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.SWITCH_WORKSPACE,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isSaved
}
