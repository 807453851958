import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

type SimpleTextSettingInput = {
  name: string
  apiToken: string
}

type Payload = {
  simpleTextSettingId: number
  simpleTextSettingData: SimpleTextSettingInput
}

export type UpdateSimpleTextSettingAction = FSA<undefined, Payload, string>

type UpdateSimpleTextSetting = (args: Payload) => MrxThunk<UpdateSimpleTextSettingAction, Promise<boolean>>

type QueryResponse = GraphQLResponse<'updateSimpleTextSetting', boolean>

const UPDATE_SIMPLE_TEXT_SETTING = `
  mutation updateSimpleTextSetting ($simpleTextSettingId: Int!, $simpleTextSettingData: SimpleTextSettingInput!) {
    updateSimpleTextSetting (simpleTextSettingId: $simpleTextSettingId, simpleTextSettingData: $simpleTextSettingData)
  }
`

const updateSimpleTextSetting: UpdateSimpleTextSetting = (args) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.UPDATE_SIMPLE_TEXT_SETTING,
    meta: { done: false }
  })

  try {
    const { simpleTextSettingId, simpleTextSettingData } = args
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_SIMPLE_TEXT_SETTING,
      variables: { simpleTextSettingId, simpleTextSettingData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateSimpleTextSetting } = data
    if (!updateSimpleTextSetting) {
      throw new Error("Can't update Simple Text setting!")
    }

    dispatch({
      type: t.UPDATE_SIMPLE_TEXT_SETTING,
      payload: args,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.UPDATE_SIMPLE_TEXT_SETTING,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { updateSimpleTextSetting }
