import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { ProductImage } from '../types'

type ProductImagesListSelector = (state: ReduxState, productId?: number) => ProductImage[]

const getProductImages: ProductImagesListSelector = (state, productId) => {
  let result = [] as ProductImage[]
  if (productId) {
    if (Object.prototype.hasOwnProperty.call(state.productImages.productImages, productId)) {
      result = Object.values(state.productImages.productImages[productId]) as ProductImage[]
    }
  }
  return result
}

export const productImagesListSelector: ProductImagesListSelector = createSelector(getProductImages, (productImages) => productImages)
