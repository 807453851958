import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import * as t from '../actionTypes'
import { Category } from '../types'

export type FetchCategoryAction = FSA<undefined, Category, string>
type FetchCategory = (categoryId: number) => MrxThunk<FetchCategoryAction>
type QueryResponse = GraphQLResponse<'category', Category>

const FETCH_CATEGORY = `
  query fetchCategory($categoryId: Int!) {
    category(categoryId: $categoryId) {
      id
      name
      campaignId
      createdAt
      updatedAt
    }
  }
`

const fetchCategory: FetchCategory = (categoryId) => async (dispatch) => {
  dispatch({
    type: t.FETCH_CATEGORY,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_CATEGORY,
      variables: { categoryId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { category } = data
    if (!category) {
      throw new Error("Can't get category!")
    }

    dispatch({
      type: t.FETCH_CATEGORY,
      payload: category,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_CATEGORY,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchCategory }
