import axios from 'utils/axios'
import { useQuery } from 'react-query'
import { DomainDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'domainsForOptions', DomainDto[]>

const FETCH_DOMAINS_FOR_OPTIONS = `
  query fetchDomainsForOptions ($workspaceId: Int!, $searchText: String) {
    domainsForOptions (workspaceId: $workspaceId, searchText: $searchText) {
      id
      name
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchDomainsForOptions = (searchText: string, limit?: number) => Promise<DomainDto[]>

const fetchDomainsForOptions: FetchDomainsForOptions = async (searchText, limit = 20) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_DOMAINS_FOR_OPTIONS,
      variables: { filters: { searchText, limit } }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { domainsForOptions } = data
    if (!domainsForOptions) {
      throw new Error("Can't get domains!")
    }

    return domainsForOptions
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useDomainsOptions = (searchText: string, limit = 20) =>
  useQuery(['domainsOptions', searchText], () => fetchDomainsForOptions(searchText, limit))
