import React, { useEffect, useState } from 'react'
import hash from 'object-hash'
import { Input, Tag, Tooltip, Select } from 'antd'
import { TagOwner } from '@merchx-v2/shared-types'
import useDebounce from 'utils/useDebounce'
import { useSimpleProductTagsOptions } from '../../hooks'

type PropsType = {
  onTagCreated?: (newTag: string) => void
  onTagRenamed?: (oldTag: string, newTag: string) => void
  onTagRemoved?: (removedTag: string) => void
  onTagsChanged?: (tagsList: string[]) => void

  ownerType?: TagOwner
  campaignId?: number
  initTags?: string[]
  placeholder?: string
  refreshOnInitTagsChanged?: boolean
}

const ManageTags = (props: PropsType) => {
  const {
    onTagCreated,
    onTagRemoved,
    onTagRenamed,
    onTagsChanged,

    placeholder = 'Add tag',
    ownerType = 'PRODUCT',
    initTags = [],
    refreshOnInitTagsChanged = false,
    campaignId
  } = props

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_isOpened, setIsOpened] = React.useState<boolean>(false)
  const [tags, setTags] = useState(initTags)

  const [newTag, setNewTag] = useState('')

  const [renamedTag, setRenamedTag] = useState('')
  const [renameTagIndex, setRenameTagIndex] = useState(-1)

  const debouncedSearchText = useDebounce(newTag, 500)

  const options = useSimpleProductTagsOptions({ campaignId, ownerType, searchText: debouncedSearchText })

  useEffect(() => {
    if (refreshOnInitTagsChanged) {
      setTags(initTags)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(initTags)])

  const handleTagRemoved = (removedTag) => {
    const newTagsState = tags.filter((tag) => tag !== removedTag)
    setTags(newTagsState)

    onTagRemoved && onTagRemoved(removedTag)
    onTagsChanged && onTagsChanged(newTagsState)
  }

  const handleTagCreated = (newTag: string) => {
    let newTags = [...tags]
    if (newTag && newTags.indexOf(newTag) === -1) {
      newTags = [...tags, newTag]
    }
    setTags(newTags)

    onTagCreated && onTagCreated(newTag)
    onTagsChanged && onTagsChanged(newTags)
  }

  const handleEditInputChange = (e) => {
    setRenamedTag(e.target.value)
  }

  const handleTagRenamed = () => {
    const newTags = [...tags]
    onTagRenamed && onTagRenamed(newTags[renameTagIndex], renamedTag)

    newTags[renameTagIndex] = renamedTag

    setTags(newTags)
    setRenameTagIndex(-1)
    setRenamedTag('')

    onTagsChanged && onTagsChanged(newTags)
  }

  const handleValueChanged = (newTag: string) => {
    console.log(newTag)
    setNewTag(newTag)
    handleTagCreated(newTag)
  }

  const handleDropdownOpened = (opened: boolean) => {
    setIsOpened(opened)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleTagCreated(newTag)
    }
  }

  return (
    <>
      <Select
        style={{ width: '100%' }}
        showSearch
        placeholder={placeholder || ''}
        value={newTag || undefined}
        filterOption={false}
        onDropdownVisibleChange={handleDropdownOpened}
        onChange={handleValueChanged}
        onSearch={(search: string) => setNewTag(search)}
        loading={options.isLoading}
        allowClear
        onKeyDown={handleKeyDown}
      >
        {options?.data?.map((item, idx) => (
          <Select.Option key={idx} value={item}>
            {item}
          </Select.Option>
        ))}
      </Select>

      {tags.map((tag, index) => {
        if (renameTagIndex === index) {
          return (
            <Input
              key={tag}
              size='small'
              style={{ width: '78px', marginRight: '8px', marginTop: '10px', verticalAlign: 'top' }}
              value={renamedTag}
              onChange={handleEditInputChange}
              onBlur={handleTagRenamed}
              onPressEnter={handleTagRenamed}
            />
          )
        }

        const isLongTag = tag.length > 20

        const tagElem = (
          <Tag style={{ userSelect: 'none', marginTop: '10px' }} key={tag} closable onClose={() => handleTagRemoved(tag)}>
            <span
              onDoubleClick={(e) => {
                setRenameTagIndex(index)
                setRenamedTag(tag)
                e.preventDefault()
              }}
            >
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </span>
          </Tag>
        )
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        )
      })}
    </>
  )
}

export default ManageTags
