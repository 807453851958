// TODO - remove becouse of legacy

import React, { useState, useEffect } from 'react'
import { Button, Card, Menu } from 'antd'
import notification from 'mrx-notification'
import useInterval from 'utils/useInterval'
import TasksQueueListForDeploymentTab from 'features/tasksQueue/components/TasksQueueListForDeplomentTab'
import LandingBuildLogs from 'features/landings/components/LandingBuildLogs'
import LandingWizardBuild from 'features/landings/components/LandingWizardBuilds'
import { useDeployLanding } from '../../hooks'
import styles from './LandingWizardDeploy.module.scss'
import { PropsFromRedux, connector } from './container'

const LandingWizardDeploy = (props: PropsFromRedux) => {
  const { landingId, builds, landing, fetchTasksList } = props

  const [activeComponent, setActiveComponent] = useState<string>('buildLogs')
  const [activeMenuItem, setActiveMenuItem] = useState<string[]>(['buildLogs'])

  const deployLanding = useDeployLanding()

  useEffect(() => {
    fetchTasksList(undefined, undefined, 'LANDING', landingId)
  }, [fetchTasksList, landingId])

  useInterval(() => {
    fetchTasksList(undefined, undefined, 'LANDING', landingId)
  }, 15000)

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case 'buildLogs': {
        return <LandingBuildLogs landingId={landingId} builds={landing.builds} version={landing.version} />
      }
      case 'builds': {
        return <LandingWizardBuild landingId={landingId} builds={builds} />
      }
      case 'tasks': {
        return <TasksQueueListForDeploymentTab />
      }
    }
  }

  return (
    <div className={styles.wizardDeployContainer}>
      <Card
        className={styles.card}
        title={<span className={styles.cardTitle}>Deployment</span>}
        extra={[
          <Button
            key='deployButton'
            type='primary'
            disabled={landing?.status === 'DEPRECATED'}
            onClick={() =>
              deployLanding.mutate(landingId, {
                onSuccess: () => {
                  notification.success({
                    message: 'Success',
                    description: 'Landing was deployed successfully'
                  })
                },
                onError: (error) => {
                  notification.error({
                    message: 'Deploy landing error',
                    description: error.toString()
                  })
                }
              })
            }
            loading={deployLanding.isLoading}
          >
            Deploy
          </Button>
        ]}
      >
        <div className={styles.contentContainer}>
          <Menu mode='inline' style={{ width: 200 }} selectedKeys={activeMenuItem}>
            <Menu.Item
              key='buildLogs'
              onClick={() => {
                setActiveComponent('buildLogs')
                setActiveMenuItem(['buildLogs'])
              }}
            >
              Deployment logs
            </Menu.Item>
            <Menu.Item
              key='builds'
              onClick={() => {
                setActiveComponent('builds')
                setActiveMenuItem(['builds'])
              }}
            >
              Builds
            </Menu.Item>
            <Menu.Item
              key='tasks'
              onClick={() => {
                setActiveComponent('tasks')
                setActiveMenuItem(['tasks'])
              }}
            >
              Tasks
            </Menu.Item>
          </Menu>
          {renderActiveComponent()}
        </div>
      </Card>
    </div>
  )
}

export default connector(LandingWizardDeploy)
