import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { OrderNote } from '../types'

type OrderNoteInput = {
  workspaceId: number
  orderId: number
  note: string
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'createOrderNote', OrderNote>

const CREATE_ORDER_NOTE = `
  mutation createOrderNote ($orderNoteData: OrderNoteInput!) {
    createOrderNote(orderNoteData: $orderNoteData) {
      id
      workspaceId
      orderId
      userId
      author {
        id
        email
        avatar
        firstName
        lastName
        createdAt
      }
      note
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type CreateOrderNote = (orderNoteData: OrderNoteInput) => Promise<OrderNote>

const createOrderNote: CreateOrderNote = async (orderNoteData) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_ORDER_NOTE,
      variables: { orderNoteData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createOrderNote } = data
    if (!createOrderNote) {
      throw new Error("Can't create order note!")
    }

    return createOrderNote
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type CreateOrderNoteEvents = {
  onSettled: (data?: OrderNote) => void
}

const createOrderNoteEvents: CreateOrderNoteEvents = {
  onSettled: (orderNote) => {
    if (orderNote?.id) {
      queryClient.invalidateQueries(['order'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCreateOrderNote = () => useMutation(createOrderNote, createOrderNoteEvents)
