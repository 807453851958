import { SettingData } from './types'

export const payPalSettingsData: SettingData[] = [
  {
    name: 'accountEmail',
    label: 'Account Email',
    rules: [{ required: true, message: 'Please input Account Email!' }]
  },
  {
    name: 'clientId',
    label: 'Client ID',
    rules: [{ required: true, message: 'Please input Client ID!' }]
  },
  {
    name: 'secretKey',
    label: 'Secret Key',
    rules: [{ required: true, message: 'Please input Secret Key!' }]
  },
  {
    name: 'accessToken',
    label: 'Access Token',
    rules: [{ required: true, message: 'Please input Access Token!' }]
  }
]
