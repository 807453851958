import React, { useEffect, useState } from 'react'
import { Loading } from 'components'
import { Button } from 'antd'
import { connector, PropsFromRedux } from './container'
import logo from 'assets/logo.png'
import styles from './ValidateEmail.module.scss'

const ValidateEmail: React.FC<PropsFromRedux> = ({ validateUserEmail, token, history }) => {
  const [status, setStatus] = useState<'init' | 'success' | 'fail'>('init')
  
  const handleGoLogin = () => history.push('/login')

  useEffect(() => {
    token && validateUserEmail(token).then(result => setStatus(result ? 'success' : 'fail'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  console.log(token)

  switch (status) {
    case 'init':
      return <Loading />
    case 'success':
      return (
        <div className={styles.container}>
          <img className={styles.logo} src={logo} alt='logo' />
          <h1>Welcome to onboard!</h1>
          <Button type='primary' onClick={handleGoLogin} className={styles.loginFormButton}>
            Go to login
          </Button>
        </div>
      )
    case 'fail':
      return (
        <div className={styles.container}>
          <img className={styles.logo} src={logo} alt='logo' />
          <h1>Something went wrong ask you admin to resend the email.</h1>
        </div>
      )
  }
}

export default connector(ValidateEmail)
