// TODO - remove becouse of legacy

import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Template, TemplateInput } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'updateTemplate', boolean>

const UPDATE_TEMPLATE = `
  mutation updateTemplate($templateId: Int!, $templateData: TemplateInput!) {
    updateTemplate(templateId: $templateId, templateData: $templateData)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateTemplateArgs = {
  templateId: number
  templateData: TemplateInput
}

type UpdateTemplate = (args: UpdateTemplateArgs) => Promise<UpdateTemplateArgs>

const updateTemplate: UpdateTemplate = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_TEMPLATE,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateTemplate } = data
    if (!updateTemplate) {
      throw new Error("Can't update template!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateTemplateContext = { prevTemplate?: Template }

type UpdateTemplateEvents = {
  onMutate: (variables: UpdateTemplateArgs) => Promise<UpdateTemplateContext>
  onError: (error: string, variables: UpdateTemplateArgs, context: UpdateTemplateContext) => void
  onSettled: (data?: UpdateTemplateArgs) => void
}

const updateTemplateEvents: UpdateTemplateEvents = {
  onMutate: async (variables: UpdateTemplateArgs) => {
    await queryClient.cancelQueries(['template', variables.templateId])

    // Snapshot the previous value
    const prevTemplate = queryClient.getQueryData<Template>(['template', variables.templateId])

    // Optimistically update to the new values
    if (prevTemplate) {
      queryClient.setQueryData<Template>(['template', variables.templateId], {
        ...prevTemplate,
        id: variables.templateId,
        ...variables.templateData
      })
    }

    return { prevTemplate }
  },
  onError: (_err, variables, context) => {
    if (context?.prevTemplate) {
      // Restore previous version of template on any error
      queryClient.setQueryData<Template>(['template', variables.templateId], context.prevTemplate)
    }
  },
  onSettled: (data) => {
    if (data?.templateId) {
      queryClient.invalidateQueries(['template', data.templateId])
      queryClient.invalidateQueries(['templatesList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUpdateTemplate = () => useMutation(updateTemplate, updateTemplateEvents)
