import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Order } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'updateOrder', Order>

const UPDATE_ORDER = `
mutation updateOrder ($workspaceId: Int!, $orderData: OrderDataInput!) {
  updateOrder(workspaceId: $workspaceId, orderData: $orderData) {
    id
    customerId
    customer {
      id
      email
      firstName
      lastName
      createdAt
      updatedAt
    }
    billingAddressId
    billingAddress {
      id
      firstName
      lastName
      countryId
      country {
        id
        name
        iso2
        iso3
        worldPart
        code
        currencyId
        currency {
          id
          name
          code
          symbol
        }
      }
      zipCode
      state
      city
      address
      address2
    }
    shippingAddressId
    shippingAddress {
      id
      firstName
      lastName
      countryId
      country {
        id
        name
        iso2
        iso3
        worldPart
        code
        currencyId
        currency {
          id
          name
          code
          symbol
        }
      }
      zipCode
      state
      city
      address
      address2
    }
    saleSource
    status
    holdReasons
    orderItems {
      id
      productId
      productPriceId
      productTagId
      productTag {
        id
        name
      }
      storeId
      store {
        id
        name
      }
      customerComment
      asset {
        id
        name
        extension
        ownerType
        ownerId
        mimeType
        s3bucket
        s3key
        signedUrl
        createdAt
        updatedAt
      }
      displayName
      description
      sku
      quantity
      price
      discount
      status
      sum
      shipping
      processing
      cost
      totalCost
      profit
      total
      createdAt
    }
    orderEvents {
      id
      event
      createdAt
    }
    orderTasks {
      id
      status
      taskType
      progress
      retryNumber
      message
      startDate
      startedAt
      endedAt
    }
    orderNotes {
      id
      note
      workspaceId
      userId
      author {
        id
        email
        avatar
        firstName
        lastName
        createdAt
      }
      createdAt
    }
    payments {
      id
      orderId
      systemType
      paymentMethod
      externalTransactionId
      status
      reason
      total
      comment
      bin
      cardNumber
      cardExpDate
    }
    total
    promocode
    promocodeDiscount
    totalCost
    comment
    trackingNumber
    invoiceId
    shippedAt
    createdAt
    updatedAt
  }
}
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type AddressInput = {
  countryId: number
  zipCode: string
  state: string
  city: string
  address: string
  address2?: string
}

type OrderItemInput = {
  id: number
  productId: number
  productPriceId: number
  displayName: string
  description: string
  sku: string
  quantity: number
  price: number
  discount: number
  shipping: number
  processing: number
}

type OrderDataInput = {
  orderId: number
  billingAddress: AddressInput
  shippingAddress: AddressInput
  orderItems: OrderItemInput[]
  comment: string
}

type UpdateOrderArgs = {
  workspaceId: number
  orderData: OrderDataInput
}

type UpdateOrder = (args: UpdateOrderArgs) => Promise<Order>

const updateOrder: UpdateOrder = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_ORDER,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateOrder } = data
    if (!updateOrder) {
      throw new Error("Can't update order!")
    }

    return updateOrder
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateOrderContext = { prevOrder?: Order }

type UpdateOrderEvents = {
  onMutate: (variables: UpdateOrderArgs) => Promise<UpdateOrderContext>
  onError: (error: string, variables: UpdateOrderArgs, context: UpdateOrderContext) => void
  onSettled: (data?: Order) => void
}

const updateOrderEvents: UpdateOrderEvents = {
  onMutate: async (variables: UpdateOrderArgs) => {
    await queryClient.cancelQueries(['order', variables.orderData.orderId])

    // Snapshot the previous value
    const prevOrder = queryClient.getQueryData<Order>(['order', variables.orderData.orderId])

    // Optimistically update to the new values
    if (prevOrder) {
      queryClient.setQueryData<Order>(['order', variables.orderData.orderId], {
        ...prevOrder,
        id: variables.orderData.orderId,
        ...variables.orderData.orderItems
      })
    }

    return { prevOrder }
  },
  onError: (_err, variables, context) => {
    if (context?.prevOrder) {
      // Restore previous version of order on any error
      queryClient.setQueryData<Order>(['order', variables.orderData.orderId], context.prevOrder)
    }
  },
  onSettled: (data) => {
    if (data?.id) {
      queryClient.invalidateQueries(['order', data.id])
      queryClient.invalidateQueries(['ordersList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUpdateOrder = () => useMutation(updateOrder, updateOrderEvents)
