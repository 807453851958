import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type ProductsOptionsSelector = (state: ReduxState, campaignId: number, settingId: number) => SelectOption[]

const getProductsOptions: ProductsOptionsSelector = (state, campaignId, settingId) => {
  let result = [] as SelectOption[]
  if (Object.prototype.hasOwnProperty.call(state.products.options, campaignId)) {
    if (Object.prototype.hasOwnProperty.call(state.products.options[campaignId], settingId)) {
      result =
        state.products.options[campaignId][settingId].map((item) => ({
          id: item.id,
          name: item.name
        })) || ([] as SelectOption[])
    }
  }
  return result
}

export const productsSelectOptionsSelector: ProductsOptionsSelector = createSelector(getProductsOptions, (options) => options)
