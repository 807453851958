// TODO - remove becouse of legacy

import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'

type OwnProps = {
  quizId?: number
  match?: {
    params?: {
      quizId?: string
    }
  }
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  let { quizId } = ownProps

  if (!quizId && ownProps?.match?.params?.quizId) {
    quizId = +ownProps.match.params.quizId
  }

  return {
    quizId
  }
}

const mapDispatchToProps = {}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
