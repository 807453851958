import axios from 'utils/axios'
import { useQuery } from 'react-query'
import { CurrencyDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'currenciesForOptions', CurrencyDto[]>

const FETCH_CURRENCIES_FOR_OPTIONS = `
  query fetchCurrenciesForOptions($searchText: String) {
    currenciesForOptions(searchText: $searchText) {
      id
      name
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchCurrenciesForOptions = (searchText: string, limit?: number) => Promise<CurrencyDto[]>

const fetchCurrenciesForOptions: FetchCurrenciesForOptions = async (searchText, limit = 20) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_CURRENCIES_FOR_OPTIONS,
      variables: { filters: { searchText, limit } }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { currenciesForOptions } = data
    if (!currenciesForOptions) {
      throw new Error("Can't get currencies!")
    }

    return currenciesForOptions
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCurrenciesOptions = (searchText: string, limit = 20) =>
  useQuery(['currenciesOptions', searchText], () => fetchCurrenciesForOptions(searchText, limit))
