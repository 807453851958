import backendAxios from 'utils/axios'
import shoppingCartsAxios from 'utils/shoppingCartAxios'
import { useMutation } from 'react-query'
import { PromocodeDto, CreatePromocodeDto, CrmPromocodeDto } from '@merchx-v2/shared-types'
import { queryClient } from 'queryClient'
import { extractResponseError, guardFromErrors } from 'utils/graphqlHelpers'

const CREATE_PROMOCODE_FOR_CRM = `
  mutation createPromocode($promocodeData: PromocodeInput!) {
    createPromocode(promocodeData: $promocodeData) {
      id

      code

      headline
      title
      buttonText
      descriptionHeadline
      description

      assets {
        id
        name
        extension
        ownerType
        ownerId
        mimeType
        s3bucket
        s3key
        signedUrl
        createdAt
        updatedAt
      }

      condition
      conditionValue

      logicType
      logicValue

      availabilities

      expirationTypes
      expirationValues

      orderId
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'createPromocode', CrmPromocodeDto>

type CreatePromocode = (args: CreatePromocodeDto) => Promise<{ createdPromocode: PromocodeDto; createdPromocodePostgres: CrmPromocodeDto }>

const createPromocode: CreatePromocode = async (args) => {
  try {
    const response = await shoppingCartsAxios.post('/promocodes/create', args)

    const createdPromocode: PromocodeDto = response.data

    if (!createdPromocode) {
      throw new Error("Can't create promocode!")
    }

    const {
      data: { data, errors }
    }: QueryResponse = await backendAxios.post('/graphql', {
      query: CREATE_PROMOCODE_FOR_CRM,
      variables: {
        promocodeData: {
          code: args.code,

          headline: args.headline,
          title: args.title,
          buttonText: args.buttonText,
          descriptionHeadline: args.descriptionHeadline,
          description: args.description,

          condition: args.condition || null,
          conditionValue: args?.conditionValue ? JSON.stringify(args.conditionValue) : '',

          logicType: args.logicType || null,
          logicValue: args.logicValue ? JSON.stringify(args.logicValue) : '0',

          availabilities: args.availabilities ? JSON.stringify(args.availabilities) : '[]',

          expirationTypes: args.expirationTypes ? JSON.stringify(args.expirationTypes) : '[]',
          expirationValues: args.expirationValues ? JSON.stringify(args.expirationValues) : '[]'
        }
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createPromocode: createdPromocodePostgres } = data
    if (!createdPromocodePostgres) {
      throw new Error("Can't create product!")
    }

    return {
      createdPromocode,
      createdPromocodePostgres
    }
  } catch (err) {
    extractResponseError(err)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type CreatePromocodeEvents = {
  onSettled: (data?: { createdPromocode: PromocodeDto; createdPromocodePostgres: CrmPromocodeDto }) => void
}

const createPromocodeEvents: CreatePromocodeEvents = {
  onSettled: (promocode) => {
    if (promocode?.createdPromocode) {
      queryClient.invalidateQueries(['promocodesPage'])
      queryClient.setQueryData(['promocode', promocode.createdPromocode.code], promocode)
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCreatePromocode = () => useMutation(createPromocode, createPromocodeEvents)
