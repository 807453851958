import React, { useEffect } from 'react'
import useReactRouter from 'use-react-router'
import { Table, Popconfirm, Layout, PageHeader } from 'antd'
import notification from 'mrx-notification'
import { ColumnsType } from 'antd/lib/table'
import { Breadcrumbs } from 'components'
import * as rootStyles from 'assets/layoutStyle'
import styles from './SimpleTextSettingsList.module.scss'
import { connector, PropsFromRedux } from './container'
import { SimpleTextSetting } from '../../types'
import CreateSimpleTextSetting from '../CreateSimpleTextSetting'

const SimpleTextSettingsList = (props: PropsFromRedux) => {
  const {
    simpleTextSettingsList,
    isLoading,
    error,
    isNeedToFetchList,
    total,
    currentPage,

    removeSimpleTextSetting,
    fetchSimpleTextSettingsList,
    changeCurrentPage
  } = props

  const { history } = useReactRouter()

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Simple Text settings list page error!',
        description: error
      })
    }
  }, [error])

  useEffect(() => {
    isNeedToFetchList && fetchSimpleTextSettingsList()
  }, [fetchSimpleTextSettingsList, isNeedToFetchList])

  const doConfirm = async (simpleTextSettingId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    const isRemoved = await removeSimpleTextSetting(simpleTextSettingId)
    if (isRemoved) {
      notification.success({
        message: 'Successfully',
        description: 'Simple Text setting was deleted successfully!'
      })
    }
  }

  const columns: ColumnsType<SimpleTextSetting> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'API Token',
      render: (record: SimpleTextSetting) => <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{record.apiToken}</div>,
      key: 'apiToken'
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (record: SimpleTextSetting) => (
        <span>
          <Popconfirm
            title='Are you sure delete this setting?'
            onConfirm={(e) => doConfirm(record.id, e)}
            onCancel={(e) => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <a
              href='/#'
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              Remove
            </a>
          </Popconfirm>
        </span>
      )
    }
  ]

  return (
    <Layout className={styles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader onBack={() => history.goBack()} title='Simple Text Settings List' extra={[<CreateSimpleTextSetting key='1' />]} />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Settings', url: '/' },
            { title: 'Plugins', url: '' },
            { title: 'Simple Text Settings', url: '/settings/plugins' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <Table
          columns={columns}
          dataSource={simpleTextSettingsList}
          bordered
          rowKey={(record) => record.id}
          loading={isLoading}
          pagination={{
            onChange: changeCurrentPage,
            current: currentPage,
            total
          }}
        />
      </Layout.Content>
    </Layout>
  )
}

export default connector(SimpleTextSettingsList)
