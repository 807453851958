import { SiteType } from '@merchx-v2/shared-types'
import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type SiteSnapshotsSelector = (state: ReduxState, ownerType: SiteType, ownerId: number) => SelectOption[]

const getSnapshots: SiteSnapshotsSelector = (state, ownerType, ownerId) => {
  let result = [] as SelectOption[]
  if (Object.prototype.hasOwnProperty.call(state.siteSnapshots.siteSnapshots[ownerType], ownerId)) {
    result = Object.values(state.siteSnapshots.siteSnapshots[ownerType][ownerId]) as SelectOption[]
  }
  return result
}

export const siteSnapshotsSelector: SiteSnapshotsSelector = createSelector(getSnapshots, (snapshots) => snapshots)
