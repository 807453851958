import React, { useEffect, useState } from 'react'
import { Form, Button, Modal, Dropdown, Input, Menu, Spin, Upload } from 'antd'
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons'
import { UploadChangeParam } from 'antd/lib/upload/interface'
import notification from 'mrx-notification'
import { connector, PropsFromRedux } from './container'

const MenuSpin = () => <Spin indicator={<LoadingOutlined style={{ marginLeft: '8px', fontSize: 14 }} spin />} />

type ModalState = {
  title: string
  okText: string
  content?: string
  isShowUpload?: boolean
  isSaveToFile?: boolean
  successDescription: string
  onConfirm?: () => Promise<void>
}

const SnapshotsMenu = ({
  workspaceId,
  ownerType,
  ownerId,
  isTakeSnapshot,
  isSnapshotsLoading,
  isRestoreFromFile,
  isRestoreFromSnapshot,
  snapshots,

  fetchSnapshots,
  restoreSiteFromFile,
  restoreSiteFromSnapshot,
  takeSnapshot
}: PropsFromRedux) => {
  const [form] = Form.useForm()
  const [modalData, setModalData] = useState<ModalState>()
  const [isShowModal, setShowModal] = useState<boolean>(false)
  const [file, setFile] = useState<File>()

  const siteType = ownerType === 'TEMPLATE' ? 'Template' : 'Landing'

  useEffect(() => {
    fetchSnapshots(workspaceId, ownerType, ownerId)
  }, [workspaceId, ownerId, fetchSnapshots, ownerType])

  const handleCloseModal = () => {
    if (!isTakeSnapshot && !isRestoreFromFile && !isRestoreFromSnapshot) {
      form.resetFields()
      setShowModal(false)
      setFile(undefined)
    } else {
      notification.warn({
        message: `${siteType} details`,
        description: 'Update in progress, please wait till it will be finished!'
      })
    }
  }

  const handleConfirm = async () => {
    if (modalData?.onConfirm) {
      // It's RestoreSiteFromSnapshot
      await modalData.onConfirm()
    } else {
      // It's RestoreSiteFromFile or TakeSnapshot action
      form.validateFields().then(async ({ name }: any) => {
        let isSaved = false
        if (modalData?.isShowUpload) {
          if (file) {
            isSaved = await restoreSiteFromFile(ownerType, ownerId, file)
          }
        } else {
          isSaved = await takeSnapshot(ownerType, ownerId, name, modalData?.isSaveToFile)
        }
        if (isSaved) {
          notification.success({
            message: `${siteType} details`,
            description: modalData?.successDescription
          })
          handleCloseModal()
        }
      })
    }
  }

  const handleSaveToFile = () => {
    setShowModal(true)
    setModalData({
      title: `Save ${siteType} snapshot to file`,
      okText: 'Export',
      isSaveToFile: true,
      successDescription: `${siteType} snapshot was created successfully! Download will be started soon!`
    })
  }

  const handleTakeSnapshot = () => {
    setShowModal(true)
    setModalData({
      title: `Take ${siteType} snapshot`,
      okText: 'Create',
      successDescription: `${siteType} snapshot was created successfully!`
    })
  }

  const handleRestoreFromFile = () => {
    setShowModal(true)
    setModalData({
      title: `Restore ${siteType} from the file`,
      okText: 'Restore',
      isShowUpload: true,
      successDescription: `${siteType} was restored from file successfully!`
    })
  }

  const onRestoreFromSnapshotConfirmed = async (siteSnapshotId: number) => {
    const isRestored = await restoreSiteFromSnapshot(siteSnapshotId, ownerType, ownerId)
    if (isRestored) {
      notification.success({
        message: `${siteType} details`,
        description: `${siteType} was restored from a snapshot successfully!`
      })

      handleCloseModal()
    }
  }

  const handleRestoreFromSnapshot = (snapshotId: number) => {
    setShowModal(true)
    setModalData({
      title: 'Restore site from the snapshot',
      okText: 'Restore',
      content: 'Are you sure want to restore the site from the snapshot? All previous data will be lost!',
      successDescription: `${siteType} was restored from snapshot successfully!`,
      onConfirm: () => onRestoreFromSnapshotConfirmed(snapshotId)
    })
  }

  const uploadProps = {
    name: 'file',
    accept: '.json',
    customRequest(info: any) {
      setFile(info.file)

      if (!form.getFieldValue('name')) {
        form.setFieldsValue({ name: info.file.name })
      }
    },
    showUploadList: false,
    onChange(info: UploadChangeParam) {
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
      } else if (info.file.status === 'error') {
        notification.error({
          message: `${siteType} details`,
          description: `${info.file.name} file upload failed.`
        })
      }
    }
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={handleSaveToFile}>Save to file</Menu.Item>
      <Menu.SubMenu title='Restore till' disabled={isSnapshotsLoading || !snapshots.length}>
        {snapshots.map((snapshot) => (
          <Menu.Item key={snapshot.id} onClick={() => handleRestoreFromSnapshot(snapshot.id)}>
            {snapshot.name}
          </Menu.Item>
        ))}
      </Menu.SubMenu>
      <Menu.Item onClick={handleRestoreFromFile}>Restore from file</Menu.Item>
    </Menu>
  )

  return (
    <>
      <Modal
        title={modalData?.title || ''}
        visible={isShowModal}
        okText={modalData?.okText || 'Ok'}
        onOk={handleConfirm}
        confirmLoading={isTakeSnapshot || isRestoreFromFile || isRestoreFromSnapshot}
        onCancel={handleCloseModal}
      >
        {!modalData?.content && (
          <Form form={form} initialValues={{ name: 'Snapshot' }}>
            <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input snapshot name' }]}>
              <Input />
            </Form.Item>
            {modalData?.isShowUpload && (
              <Form.Item
                name='upload'
                rules={[{ required: modalData?.isShowUpload, message: 'Please upload snapshot file!' }]}
                valuePropName=''
              >
                <Upload {...uploadProps}>
                  <Button>
                    <UploadOutlined /> Click to Upload
                  </Button>
                </Upload>
              </Form.Item>
            )}
          </Form>
        )}
        {modalData?.content}
      </Modal>
      <Dropdown.Button key='more' overlay={menu} disabled={isTakeSnapshot} onClick={handleTakeSnapshot}>
        Take snapshot {isTakeSnapshot && <MenuSpin />}
      </Dropdown.Button>
    </>
  )
}

export default connector(SnapshotsMenu)
