import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Region } from '../types'

export type FetchRegionsForOptionsAction = FSA<undefined, Region[], string>
type FetchRegionsForOptions = (countryId: number, searchText?: string, limit?: number) => MrxThunk<FetchRegionsForOptionsAction>
type QueryResponse = GraphQLResponse<'regionsForOptions', Region[]>

const FETCH_REGIONS_FOR_OPTIONS = `
  query fetchRegionsForOptions ($filters: RegionsFilters!) {
    regionsForOptions (filters: $filters) {
      id
      name
    }
  }
`

const fetchRegionsForOptions: FetchRegionsForOptions = (countryId, searchText, limit) => async (dispatch) => {
  dispatch({
    type: t.FETCH_REGIONS_FOR_OPTIONS,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_REGIONS_FOR_OPTIONS,
      variables: { filters: { countryId, searchText, limit } }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { regionsForOptions } = data
    if (!regionsForOptions) {
      throw new Error("Can't get regions!")
    }

    dispatch({
      type: t.FETCH_REGIONS_FOR_OPTIONS,
      payload: regionsForOptions,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_REGIONS_FOR_OPTIONS,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchRegionsForOptions }
