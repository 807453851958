import React from 'react'
import moment from 'moment-timezone'
import numeral from 'numeral'
import { Table, Typography } from 'antd'
import styles from './CampaignLevelReportTable.module.scss'

const createColumn = (title, key, format, opts = {}) => ({
  title,
  render: record => numeral(record[key]).format(format),
  key,
  align: 'right',
  ...opts
})

const CampaignLevelReportTable = ({ selectedView, campaignLevelRows, isLoading }) => {
  const moneyFormat = '$0,0.00'
  const integerFormat = '0,0'

  const facebookColumnClassname =
    selectedView === 'all' || selectedView === 'facebook'
      ? styles['overview-report-list__column-shown']
      : styles['overview-report-list__column-hidden']

  const emailColumnClassname =
    selectedView === 'all' || selectedView === 'email'
      ? styles['overview-report-list__column-shown']
      : styles['overview-report-list__column-hidden']

  const subscriptionColumnClassname =
    selectedView === 'all' || selectedView === 'subscription'
      ? styles['overview-report-list__column-shown']
      : styles['overview-report-list__column-hidden']

  const columns = [
    {
      title: 'General Information',
      children: [
        createColumn('Date', 'date', '', {
          render: record => (record.day === 'Totals' ? 'Totals' : moment(record.day).format('MM/DD/YYYY'))
        }),
        {
          title: 'Campaign',
          key: 'campaign',
          render: (_value, record) => record.campaignName,
          className: styles.campaignsCell
        },
        createColumn('PNL', 'pnl', moneyFormat),
        createColumn('Margins', 'margins', '', {
          render: record => `${numeral(record.margins).format('0.00')}%`
        }),
        createColumn('Expenses', 'expenses', moneyFormat),
        createColumn('Revenue', 'revenue', moneyFormat)
      ]
    },
    {
      title: 'Totals',
      children: [
        createColumn('Tot. Sales', 'totalSales', integerFormat),
        createColumn('Upsell Take Rate', 'upsellTakeRate', '', {
          render: record => `${numeral(record.upsellTakeRate).format('0.00')}%`
        }),
        createColumn('AVG COGS', 'averageCostOfGoods', moneyFormat),
        createColumn('Refunds', 'refunds', moneyFormat),
        createColumn('Refund%', 'refundsRate', '', {
          render: record => `${numeral(record.refundsRate).format('0.00')}%`
        })
      ]
    },
    {
      title: 'Facebook',
      className: facebookColumnClassname,
      children: [
        createColumn('Tot. Rev', 'facebookRevenue', moneyFormat, { className: facebookColumnClassname }),
        createColumn('Tot. Sales', 'facebookTotalSales', integerFormat, { className: facebookColumnClassname }),
        createColumn('Tot.Upsells', 'facebookTotalUpsells', integerFormat, { className: facebookColumnClassname }),
        createColumn('Tot. Stickers', 'facebookTotalStickers', integerFormat, {
          className: facebookColumnClassname
        }),
        createColumn('UTR', 'facebookUpsellTakeRate', '', {
          render: record => `${numeral(record.facebookUpsellTakeRate).format('0.0')}%`,
          className: facebookColumnClassname
        }),
        createColumn('AVG COG', 'facebookAverageCostOfGoods', moneyFormat, { className: facebookColumnClassname }),
        createColumn('Refunds', 'facebookRefunds', moneyFormat, { className: facebookColumnClassname }),
        createColumn('Refund,%', 'facebookRefundRate', '', {
          render: record => `${numeral(record.facebookRefundRate).format('0.0')}%`,
          className: facebookColumnClassname
        }),
        createColumn('Ad Spend', 'adSpend', moneyFormat, { className: facebookColumnClassname }),
        createColumn('Clicks', 'numberOfClicks', integerFormat, { className: facebookColumnClassname }),
        createColumn('CPA', 'costPerAction', moneyFormat, { className: facebookColumnClassname }),
        createColumn('CPC', 'costPerClick', moneyFormat, { className: facebookColumnClassname }),
        createColumn('CR,%', 'conversionRate', '', {
          render: record => `${numeral(record.conversionRate).format('0.0')}%`,
          className: facebookColumnClassname
        })
      ]
    },
    {
      title: 'Email',
      className: emailColumnClassname,
      children: [
        createColumn('Tot. Rev', 'emailRevenue', moneyFormat, { className: emailColumnClassname }),
        createColumn('Tot. Sales', 'emailTotalSales', integerFormat, { className: emailColumnClassname }),
        createColumn('Tot.Upsells', 'emailTotalUpsells', integerFormat, { className: emailColumnClassname }),
        createColumn('Tot. Stickers', 'emailTotalStickers', integerFormat, { className: emailColumnClassname }),
        createColumn('UTR,%', 'emailUpsellTakeRate', '', {
          render: record => `${numeral(record.emailUpsellTakeRate).format('0.0')}%`,
          className: emailColumnClassname
        }),
        createColumn('AVG COG', 'emailAverageCostOfGoods', moneyFormat, { className: emailColumnClassname }),
        createColumn('Refunds', 'emailRefunds', moneyFormat, { className: emailColumnClassname }),
        createColumn('Refund,%', 'emailRefundRate', '', {
          render: record => `${numeral(record.emailRefundRate).format('0.0')}%`,
          className: emailColumnClassname
        })
      ]
    },
    {
      title: 'Subscription',
      className: subscriptionColumnClassname,
      children: [
        createColumn('Tot. Rev', 'subscriptionRevenue', moneyFormat, { className: subscriptionColumnClassname }),
        createColumn('Tot. Sales', 'subscriptionTotalSales', integerFormat, {
          className: subscriptionColumnClassname
        }),
        createColumn('Tot.Upsells', 'subscriptionTotalUpsells', integerFormat, {
          className: subscriptionColumnClassname
        }),
        createColumn('Tot. Stickers', 'subscriptionTotalStickers', integerFormat, {
          className: subscriptionColumnClassname
        }),
        createColumn('UTR,%', 'subscriptionUpsellTakeRate', '', {
          render: record => `${numeral(record.subscriptionUpsellTakeRate).format('0.0')}%`,
          className: subscriptionColumnClassname
        }),
        createColumn('AVG COG', 'subscriptionAverageCostOfGoods', moneyFormat, {
          className: subscriptionColumnClassname
        }),
        createColumn('Refunds', 'subscriptionRefunds', moneyFormat, { className: subscriptionColumnClassname }),
        createColumn('Refund,%', 'subscriptionRefundRate', '', {
          render: record => `${numeral(record.subscriptionRefundRate).format('0.0')}%`,
          className: subscriptionColumnClassname
        })
      ]
    }
  ]

  return (
    <Table
      columns={columns}
      dataSource={campaignLevelRows}
      bordered
      size='middle'
      scroll={{ x: true }}
      rowKey={record => record.id}
      pagination={false}
      loading={isLoading}
      rowClassName={record => (record.day === 'Totals' ? styles['ant-table-row'] : '')}
      summary={pageData => {
        let pnlTotal = 0
        let marginsTotal = 0
        let expensesTotal = 0
        let revenueTotal = 0
        let totalSalesTotal = 0
        let upsellTakeRateTotal = 0
        let averageCostOfGoodsTotal = 0
        let refundsTotal = 0
        let refundsRateTotal = 0

        let facebookRevenueTotal = 0
        let facebookTotalSalesTotal = 0
        let facebookTotalUpsellsTotal = 0
        let facebookTotalStickersTotal = 0
        let facebookUpsellTakeRateTotal = 0
        let facebookAverageCostOfGoodsTotal = 0
        let facebookRefundsTotal = 0
        let facebookRefundRateTotal = 0
        let adSpendTotal = 0
        let numberOfClicksTotal = 0
        let costPerActionTotal = 0
        let costPerClickTotal = 0
        let conversionRateTotal = 0

        let emailRevenueTotal = 0
        let emailTotalSalesTotal = 0
        let emailTotalUpsellsTotal = 0
        let emailTotalStickersTotal = 0
        let emailUpsellTakeRateTotal = 0
        let emailAverageCostOfGoodsTotal = 0
        let emailRefundsTotal = 0
        let emailRefundRateTotal = 0

        let subscriptionRevenueTotal = 0
        let subscriptionTotalSalesTotal = 0
        let subscriptionTotalUpsellsTotal = 0
        let subscriptionTotalStickersTotal = 0
        let subscriptionUpsellTakeRateTotal = 0
        let subscriptionAverageCostOfGoodsTotal = 0
        let subscriptionRefundsTotal = 0
        let subscriptionRefundRateTotal = 0

        pageData.forEach(
          ({
            pnl,
            margins,
            expenses,
            revenue,
            totalSales,
            upsellTakeRate,
            averageCostOfGoods,
            refunds,
            refundsRate,

            facebookRevenue,
            facebookTotalSales,
            facebookTotalUpsells,
            facebookTotalStickers,
            facebookUpsellTakeRate,
            facebookAverageCostOfGoods,
            facebookRefunds,
            facebookRefundRate,
            adSpend,
            numberOfClicks,
            costPerAction,
            costPerClick,
            conversionRate,

            emailRevenue,
            emailTotalSales,
            emailTotalUpsells,
            emailTotalStickers,
            emailUpsellTakeRate,
            emailAverageCostOfGoods,
            emailRefunds,
            emailRefundRate,

            subscriptionRevenue,
            subscriptionTotalSales,
            subscriptionTotalUpsells,
            subscriptionTotalStickers,
            subscriptionUpsellTakeRate,
            subscriptionAverageCostOfGoods,
            subscriptionRefunds,
            subscriptionRefundRate
          }) => {
            pnlTotal += pnl
            marginsTotal += margins
            expensesTotal += expenses
            revenueTotal += revenue
            totalSalesTotal += totalSales
            upsellTakeRateTotal += upsellTakeRate //
            averageCostOfGoodsTotal += averageCostOfGoods
            refundsTotal += refunds
            refundsRateTotal += refundsRate //

            facebookRevenueTotal += facebookRevenue
            facebookTotalSalesTotal += facebookTotalSales
            facebookTotalUpsellsTotal += facebookTotalUpsells
            facebookTotalStickersTotal += facebookTotalStickers
            facebookUpsellTakeRateTotal = +facebookUpsellTakeRate //
            facebookAverageCostOfGoodsTotal += facebookAverageCostOfGoods
            facebookRefundsTotal += facebookRefunds
            facebookRefundRateTotal += facebookRefundRate //
            adSpendTotal = +adSpend
            numberOfClicksTotal += numberOfClicks
            costPerActionTotal += costPerAction
            costPerClickTotal += costPerClick
            conversionRateTotal = conversionRate //

            emailRevenueTotal += emailRevenue
            emailTotalSalesTotal += emailTotalSales
            emailTotalUpsellsTotal += emailTotalUpsells
            emailTotalStickersTotal += emailTotalStickers
            emailUpsellTakeRateTotal += emailUpsellTakeRate //
            emailAverageCostOfGoodsTotal += emailAverageCostOfGoods
            emailRefundsTotal += emailRefunds
            emailRefundRateTotal += emailRefundRate //

            subscriptionRevenueTotal += subscriptionRevenue
            subscriptionTotalSalesTotal += subscriptionTotalSales
            subscriptionTotalUpsellsTotal += subscriptionTotalUpsells
            subscriptionTotalStickersTotal += subscriptionTotalStickers
            subscriptionUpsellTakeRateTotal += subscriptionUpsellTakeRate
            subscriptionAverageCostOfGoodsTotal += subscriptionAverageCostOfGoods
            subscriptionRefundsTotal += subscriptionRefunds
            subscriptionRefundRateTotal += subscriptionRefundRate
          }
        )

        const totalRows = pageData.length || 1

        if (!pageData.length) {
          return null
        }

        return (
          <>
            <Table.Summary.Row className={styles.totalRow}>
              <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
              <Table.Summary.Cell index={1} />
              <Table.Summary.Cell index={2} className={styles.totalCell}>
                <Typography>{`${numeral(pnlTotal).format('$0,0.00')}`}</Typography>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} className={styles.totalCell}>
                <Typography>{`${numeral(marginsTotal / totalRows / 100).format('0.00%')}`}</Typography>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4} className={styles.totalCell}>
                <Typography>{`${numeral(expensesTotal).format('$0,0.00')}`}</Typography>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5} className={styles.totalCell}>
                <Typography>{`${numeral(revenueTotal).format('$0,0.00')}`}</Typography>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6} className={styles.totalCell}>
                <Typography>{totalSalesTotal}</Typography>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7} className={styles.totalCell}>
                <Typography>{`${numeral(upsellTakeRateTotal / totalRows / 100).format('0.00%')}`}</Typography>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={8} className={styles.totalCell}>
                <Typography>{`${numeral(averageCostOfGoodsTotal).format('$0,0.00')}`}</Typography>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={9} className={styles.totalCell}>
                <Typography>{`${numeral(refundsTotal).format('$0,0.00')}`}</Typography>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={10} className={styles.totalCell}>
                <Typography>{`${numeral(refundsRateTotal / totalRows / 100).format('0.00%')}`}</Typography>
              </Table.Summary.Cell>

              {['all', 'facebook'].includes(selectedView) && (
                <>
                  <Table.Summary.Cell index={11} className={styles.totalCell}>
                    <Typography>{`${numeral(facebookRevenueTotal).format('$0,0.00')}`}</Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={12} className={styles.totalCell}>
                    <Typography>{facebookTotalSalesTotal}</Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={13} className={styles.totalCell}>
                    <Typography>{facebookTotalUpsellsTotal}</Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={14} className={styles.totalCell}>
                    <Typography>{facebookTotalStickersTotal}</Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={15} className={styles.totalCell}>
                    <Typography>{`${numeral(facebookUpsellTakeRateTotal / totalRows / 100).format('0.00%')}`}</Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={16} className={styles.totalCell}>
                    <Typography>{`${numeral(facebookAverageCostOfGoodsTotal).format('$0,0.00')}`}</Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={17} className={styles.totalCell}>
                    <Typography>{`${numeral(facebookRefundsTotal).format('$0,0.00')}`}</Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={18} className={styles.totalCell}>
                    <Typography>{`${numeral(facebookRefundRateTotal / totalRows / 100).format('0.00%')}`}</Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={19} className={styles.totalCell}>
                    <Typography>{`${numeral(adSpendTotal).format('$0,0.00')}`}</Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={20} className={styles.totalCell}>
                    <Typography>{numberOfClicksTotal}</Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={21} className={styles.totalCell}>
                    <Typography>{`${numeral(costPerActionTotal).format('$0,0.00')}`}</Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={22} className={styles.totalCell}>
                    <Typography>{`${numeral(costPerClickTotal).format('$0,0.00')}`}</Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={23} className={styles.totalCell}>
                    <Typography>{`${numeral(conversionRateTotal / totalRows).format('0.0')}%`}</Typography>
                  </Table.Summary.Cell>
                </>
              )}

              {['all', 'email'].includes(selectedView) && (
                <>
                  <Table.Summary.Cell index={24} className={styles.totalCell}>
                    <Typography>{`${numeral(emailRevenueTotal).format('$0,0.00')}`}</Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={25} className={styles.totalCell}>
                    <Typography>{emailTotalSalesTotal}</Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={26} className={styles.totalCell}>
                    <Typography>{emailTotalUpsellsTotal}</Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={27} className={styles.totalCell}>
                    <Typography>{emailTotalStickersTotal}</Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={28} className={styles.totalCell}>
                    <Typography>{`${numeral(emailUpsellTakeRateTotal / totalRows / 100).format('0.00%')}`}</Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={29} className={styles.totalCell}>
                    <Typography>{`${numeral(emailAverageCostOfGoodsTotal).format('$0,0.00')}`}</Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={30} className={styles.totalCell}>
                    <Typography>{`${numeral(emailRefundsTotal).format('$0,0.00')}`}</Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={31} className={styles.totalCell}>
                    <Typography>{`${numeral(emailRefundRateTotal / totalRows).format('0.00%')}`}</Typography>
                  </Table.Summary.Cell>
                </>
              )}

              {['all', 'subscription'].includes(selectedView) && (
                <>
                  <Table.Summary.Cell index={24} className={styles.totalCell}>
                    <Typography>{`${numeral(subscriptionRevenueTotal).format('$0,0.00')}`}</Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={25} className={styles.totalCell}>
                    <Typography>{subscriptionTotalSalesTotal}</Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={26} className={styles.totalCell}>
                    <Typography>{subscriptionTotalUpsellsTotal}</Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={27} className={styles.totalCell}>
                    <Typography>{subscriptionTotalStickersTotal}</Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={28} className={styles.totalCell}>
                    <Typography>{`${numeral(subscriptionUpsellTakeRateTotal / totalRows / 100).format('0.00%')}`}</Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={29} className={styles.totalCell}>
                    <Typography>{`${numeral(subscriptionAverageCostOfGoodsTotal).format('$0,0.00')}`}</Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={30} className={styles.totalCell}>
                    <Typography>{`${numeral(subscriptionRefundsTotal).format('$0,0.00')}`}</Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={31} className={styles.totalCell}>
                    <Typography>{`${numeral(subscriptionRefundRateTotal / totalRows).format('0.00%')}`}</Typography>
                  </Table.Summary.Cell>
                </>
              )}
            </Table.Summary.Row>
          </>
        )
      }}
    />
  )
}

export default CampaignLevelReportTable
