import React, { useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Button, Table } from 'antd'
import notification from 'mrx-notification'
import { createTableColumns } from './createTableColumns'
import { useStorePayoutsList } from '../../hooks'
import styles from './StorePayoutsList.module.scss'
import SendPayoutModal from '../SendPayoutModal'

type Props = {
  storeId: number
}

const StorePayoutsList: React.FC<Props> = ({ storeId }) => {
  const [isSendPayoutModalVisible, setIsSendPayoutModalVisible] = useState(false)

  const storePayoutsList = useStorePayoutsList({ storeId })
  const payoutsList = useMemo(() => storePayoutsList.data?.pages.map((page) => page.items).flat() || [], [storePayoutsList.dataUpdatedAt])
  useEffect(() => storePayoutsList.remove, [])

  useEffect(() => {
    if (storePayoutsList.error) {
      notification.error({
        message: 'Stores error!',
        description: storePayoutsList.error instanceof Error ? storePayoutsList.error.message : storePayoutsList.error.toString()
      })
    }
  }, [storePayoutsList.error])

  const page = storePayoutsList.data?.pages.at(-1).currentPage || 1
  const size = storePayoutsList.data?.pages.at(-1).pageSize || 30
  const columns = useMemo(() => createTableColumns(), [])
  return (
    <div className={styles.container}>
      <SendPayoutModal visibleModal={isSendPayoutModalVisible} onCancel={() => setIsSendPayoutModalVisible(false)} storeId={storeId} />
      <div className={styles.buttonsContainer}>
        <Button onClick={() => setIsSendPayoutModalVisible(true)}>Send Payout</Button>
      </div>
      <InfiniteScroll
        style={{ paddingBottom: '30px' }}
        dataLength={page * size}
        next={storePayoutsList.fetchNextPage}
        hasMore={storePayoutsList.hasNextPage}
        loader={<h4>Loading...</h4>}
        refreshFunction={storePayoutsList.refetch}
        scrollableTarget='storeScroll'
      >
        <Table
          columns={columns}
          dataSource={payoutsList}
          bordered
          rowKey={(record) => record.id}
          pagination={false}
          loading={storePayoutsList.isLoading}
        />
      </InfiniteScroll>
    </div>
  )
}

export default React.memo(StorePayoutsList)
