import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

import { Customer, CustomerInput } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'updateCustomer', boolean>

const UPDATE_CUSTOMER = `
  mutation updateCustomer($customerId: Int!, $customerData: CustomerInput!, $isChangeCustomerAccountEmail: Boolean) {
    updateCustomer(customerId: $customerId, customerData: $customerData, isChangeCustomerAccountEmail: $isChangeCustomerAccountEmail)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateCustomerArgs = {
  customerId: number
  customerData: CustomerInput
  isChangeCustomerAccountEmail: boolean
}

type UpdateCustomer = (args: UpdateCustomerArgs) => Promise<UpdateCustomerArgs>

const updateCustomer: UpdateCustomer = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_CUSTOMER,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateCustomer } = data
    if (!updateCustomer) {
      throw new Error("Can't update customer!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateCustomerContext = { prevCustomer?: Customer }

type UpdateCustomerEvents = {
  onMutate: (variables: UpdateCustomerArgs) => Promise<UpdateCustomerContext>
  onError: (error: string, variables: UpdateCustomerArgs, context: UpdateCustomerContext) => void
  onSettled: (data?: UpdateCustomerArgs) => void
}

const updateCustomerEvents: UpdateCustomerEvents = {
  onMutate: async (variables: UpdateCustomerArgs) => {
    await queryClient.cancelQueries(['customer', variables.customerId])

    // Snapshot the previous value
    const prevCustomer = queryClient.getQueryData<Customer>(['customer', variables.customerId])

    // Optimistically update to the new values
    if (prevCustomer) {
      queryClient.setQueryData<Customer>(['customer', variables.customerId], {
        ...prevCustomer,
        id: variables.customerId,
        ...variables.customerData
      })
    }

    return { prevCustomer }
  },
  onError: (_err, variables, context) => {
    if (context?.prevCustomer) {
      // Restore previous version of customer on any error
      queryClient.setQueryData<Customer>(['customer', variables.customerId], context.prevCustomer)
    }
  },
  onSettled: (data) => {
    if (data?.customerId) {
      queryClient.invalidateQueries(['customer', data.customerId])
      queryClient.invalidateQueries(['customersList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUpdateCustomer = () => useMutation(updateCustomer, updateCustomerEvents)
