// TODO - remove becouse of legacy

import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'
import * as productTags from 'features/productTags'

const mapStateToProps = (state: ReduxState) => ({
  reportRows: selectors.reportRowsSelector(state),
  isLoading: selectors.isLoadingSelectorByKey(state, 'isLoadingReport'),
  datesRange: selectors.datesRangeReportSelector(state),
  productTags: productTags.selectors.productTagsForOptionsSelector(state),
  error: selectors.errorSelector(state)
})

const mapDispatchToProps = {
  changeDatesRange: actions.changeDatesRangeReport,
  fetchAdSpendReport: actions.fetchAdSpendReport,
  fetchProductTagsForOptions: productTags.actions.fetchProductTagsForOptions
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector>
