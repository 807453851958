// TODO - remove becouse of legacy

import { Landing, StepState, WizardStepStatus } from '../../types'

const getInitialsStep: (landing: Landing, currentStep: number) => StepState = (landing, currentStep) => {
  const isValid = landing?.id !== undefined
  let status = 'wait' as WizardStepStatus

  if (isValid) {
    status = 'finish' as WizardStepStatus
  }

  if (currentStep === 0) {
    status = 'process' as WizardStepStatus
  }

  if (currentStep !== 0 && !isValid) {
    status = 'error' as WizardStepStatus
  }

  return {
    isValid,
    status
  }
}

export { getInitialsStep }
