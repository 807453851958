import { createSelector } from 'reselect'
import paginationSelector from './paginationSelector'
import processingCostsSelector from './processingCostsSelector'

export const processingCostsListSelector = createSelector(
  [processingCostsSelector, paginationSelector],
  (processingCosts, pagination) => {
    const { pages, currentPage } = pagination
    return (pages[currentPage] || []).map(id => processingCosts[id])
  }
)
