import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

const mapStateToProps = (state: ReduxState) => ({
  workspace: selectors.workspaceSelector(state),
  isLoading: selectors.isLoadingSelector(state, 'isWorkspaceLoading'),
  error: selectors.errorSelector(state)
})

const mapDispatchToProps = {
  fetchWorkspace: actions.fetchWorkspace,
  updateWorkspace: actions.updateWorkspace
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector>
