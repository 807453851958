import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

type OwnProps = {
  initialOption: SelectOption
  disabled?: boolean
  onSelect: (selectOption: SelectOption) => void
}

const mapStateToProps = (state: ReduxState) => ({
  isLoading: selectors.isLoadingSelector(state, 'isPostmarkSettingsForOptionsLoading'),
  options: selectors.postmarkSettingsSelectOptionsSelector(state)
})

const mapDispatchToProps = {
  fetchOptions: actions.fetchPostmarkSettingsForOptions
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
