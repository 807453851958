import produce from 'immer'
import * as t from './actionTypes'
import { actionTypes as nt } from 'features/navigation'
import { actionTypes as wt } from 'features/workspaces'
import { isSuccessAction, isErrorAction } from 'types'
import { SendgridSettingsState } from './types'
import createReducer from 'store/createReducer'
import { addItemToPagination } from 'utils/addItemToPagination'
import { removeItemFromPagination } from 'utils/removeItemFromPagination'
import {
  ChangeCurrentPageAction,
  ChangePageSizeAction,
  CreateSendgridSettingAction,
  FetchSendgridSettingsListAction,
  FetchSendgridSettingsForOptionsAction,
  RemoveSendgridSettingAction,
  UpdateSendgridSettingAction
} from './actions'

const initState: SendgridSettingsState = {
  sendgridSettings: {},
  sendgridSettingsForOptions: [],
  pagination: {
    currentPage: 1,
    total: 0,
    pages: {},
    size: 10
  },
  UIState: {
    isCreateSendgridSetting: false,
    isListLoading: false,
    isRemoveSendgridSetting: false,
    isSendgridSettingsForOptionsLoading: false,
    isUpdateSendgridSetting: false
  }
}

const reducer = createReducer(initState, {
  [t.CREATE_SENDGRID_SETTING]: (state, action: CreateSendgridSettingAction) =>
    produce(state, draft => {
      draft.UIState.isCreateSendgridSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isCreateSendgridSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { id } = action.payload
        draft.sendgridSettings[id] = action.payload
        addItemToPagination(draft.pagination, id)
      }
    }),

  [t.FETCH_SENDGRID_SETTINGS_LIST]: (state, action: FetchSendgridSettingsListAction) =>
    produce(state, draft => {
      draft.UIState.isListLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isListLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        action.payload.items.forEach(item => {
          draft.sendgridSettings[item.id] = item
        })
        const { page, total, items } = action.payload
        draft.pagination.total = total
        draft.pagination.pages[page] = items.map(item => item.id)
      }
    }),

  [t.FETCH_SENDGRID_SETTINGS_FOR_OPTIONS]: (state, action: FetchSendgridSettingsForOptionsAction) =>
    produce(state, draft => {
      draft.UIState.isSendgridSettingsForOptionsLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isSendgridSettingsForOptionsLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.sendgridSettingsForOptions = action.payload
      }
    }),

  [t.REMOVE_SENDGRID_SETTING]: (state, action: RemoveSendgridSettingAction) =>
    produce(state, draft => {
      draft.UIState.isRemoveSendgridSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isRemoveSendgridSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.sendgridSettings[action.payload]) {
          delete draft.sendgridSettings[action.payload]
          removeItemFromPagination(draft.pagination, action.payload)
        }
      }
    }),
  [t.UPDATE_SENDGRID_SETTING]: (state, action: UpdateSendgridSettingAction) =>
    produce(state, draft => {
      draft.UIState.isUpdateSendgridSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isUpdateSendgridSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.sendgridSettings[action.payload.sendgridSettingId]) {
          draft.sendgridSettings[action.payload.sendgridSettingId].name = action.payload.sendgridSettingData.name
          draft.sendgridSettings[action.payload.sendgridSettingId].apiKey = action.payload.sendgridSettingData.apiKey
          draft.sendgridSettings[action.payload.sendgridSettingId].from = action.payload.sendgridSettingData.from
          draft.sendgridSettings[action.payload.sendgridSettingId].fromName = action.payload.sendgridSettingData.fromName
        }
      }
    }),

  [t.CHANGE_CURRENT_PAGE]: (state, action: ChangeCurrentPageAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.pagination.currentPage = action.payload
      }
    }),

  [t.CHANGE_PAGE_SIZE]: (state, action: ChangePageSizeAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.pagination.size = action.payload
      }
    }),

  [wt.SWITCH_WORKSPACE]: state =>
    produce(state, draft => {
      draft.pagination = initState.pagination
    }),

  [nt.LOCATION_CHANGE]: state =>
    produce(state, draft => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default reducer
