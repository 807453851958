import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import * as t from '../actionTypes'
import { Category } from '../types'

type CategorysList = {
  items: Category[]
  total: number
}

type Payload = CategorysList & {
  page: number
  campaignId: number
}

type CategoryFilters = {
  workspaceId: number
  campaignId: number
  sorting?: SortingType
  searchText?: string
}

export type FetchCategoriesListAction = FSA<undefined, Payload, string>
type FetchCategorysList = (filters: CategoryFilters) => MrxThunk<FetchCategoriesListAction>
type QueryResponse = GraphQLResponse<'categoriesListPage', CategorysList>

const FETCH_CATEGORIES_LIST = `
query categoriesListPage($page: Int, $size: Int, $filters: CategoriesFilters!) {
  categoriesListPage(page: $page, size: $size, filters: $filters) {
    items {
      id
      name
      campaignId
      createdAt
      updatedAt
    }
    total
  }
}
`

const fetchCategoriesList: FetchCategorysList = (filters) => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_CATEGORIES_LIST,
    meta: { done: false }
  })

  try {
    const {
      categories: { pagination }
    } = getState()

    let page = 1
    let size = 6
    if (pagination[filters.campaignId]) {
      page = pagination[filters.campaignId].currentPage
      size = pagination[filters.campaignId].size
    }

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_CATEGORIES_LIST,
      variables: { page, size, filters: { ...filters } }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { categoriesListPage } = data
    if (!categoriesListPage) {
      throw new Error("Can't get categories list!")
    }

    dispatch({
      type: t.FETCH_CATEGORIES_LIST,
      payload: {
        items: categoriesListPage.items,
        total: categoriesListPage.total,
        page,
        campaignId: filters.campaignId
      },
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_CATEGORIES_LIST,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchCategoriesList }
