import { useInfiniteQuery } from 'react-query'
import hash from 'object-hash'
import { Moment } from 'moment'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

import { Order } from '../types'
import { OrderHoldReason } from '@merchx-v2/shared-types'

const DEFAULT_PAGE_SIZE = 15

export type OrdersPage = {
  items: Order[]
  total: number
  currentPage: number
  pageSize: number
  filters?: OrderFilters
}

type OrderFilters = {
  customerId?: number
  billingAddressId?: number
  shippingAddressId?: number
  status?: string
  statusNotIn?: string[]
  holdReasons?: OrderHoldReason[]
  saleSource?: string
  saleSourceNotIn?: string[]
  customSaleSourceIn?: string[]
  sorting?: SortingType
  query?: string
  startDate?: Moment
  endDate?: Moment
  productTags?: string[]
  orderStates?: string[]
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'ordersListPage', OrdersPage>

const FETCH_ORDERS_LIST = `
  query fetchOrderListPage ($page: Int, $size: Int, $filters: OrdersFilters!) {
    ordersListPage(page: $page, size: $size, filters: $filters) {
      items {
        id
        customer {
          firstName
          lastName
          email
        }
        attributes {
          attribute
          value
        }
        invoiceId
        saleSource
        customSaleSource
        status
        holdReasons
        shipping
        total
        promocode
        trackingNumber
        createdAt
        cycle
        loggedIn
        multipurchaser
      }
      total
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type PageParam = {
  page?: number
  size?: number
  filters: OrderFilters
}

type FetchOrdersListArgs = {
  queryKey: string | string[]
  pageParam?: PageParam
}

type FetchOrdersList = (args: FetchOrdersListArgs) => Promise<OrdersPage>

const fetchOrdersList: FetchOrdersList = async ({ pageParam }) => {
  const { page = 1, size = DEFAULT_PAGE_SIZE, filters = {} } = pageParam || {}
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_ORDERS_LIST,
      variables: { page, size, filters }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { ordersListPage } = data
    if (!ordersListPage) {
      throw new Error("Can't get orders list page!")
    }

    return {
      items: ordersListPage.items,
      total: ordersListPage.total,
      currentPage: page,
      pageSize: size
    }
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

type GetNextPageParam = (args: OrdersPage) => PageParam

type OrdersListEvents = {
  keepPreviousData: boolean
  getPreviousPageParam: GetNextPageParam
  getNextPageParam: GetNextPageParam
}

const ordersListEvents: OrdersListEvents = {
  keepPreviousData: true,
  getPreviousPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, filters }) => {
    if (currentPage > 1) {
      return {
        page: currentPage - 1,
        size: pageSize,
        filters
      }
    }

    return undefined
  },
  getNextPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, total = 0, filters }) => {
    if (pageSize * currentPage < total) {
      return {
        page: currentPage + 1,
        size: pageSize,
        filters
      }
    }
    return undefined
  }
}

export const useOrdersList = (filters?: OrderFilters) => {
  const cacheKey = ['ordersList', hash(filters)]

  return useInfiniteQuery(
    cacheKey,
    (prev) =>
      fetchOrdersList({
        queryKey: cacheKey,
        pageParam: { ...prev.pageParam, filters }
      }),
    ordersListEvents
  )
}
