import { AxiosResponse } from 'axios'

export const guardFromErrors = (errors?: [{ message: string }]) => {
  if (errors) {
    throw new Error(errors.map((error) => error.message).join('\n'))
  }
}

export const extractErrorInfo = (exception: any): string => {
  let message = exception?.message

  const { response } = exception
  if (response) {
    const { data } = response

    if (data) {
      const { errors } = data
      message = errors ? errors.map((item: any) => item.message).join('\n') : ''
    }
  }

  return message
}

export const extractResponseError = (response: AxiosResponse) => {
  const error = JSON.parse(response?.request?.responseText)

  const message =
    typeof error?.message === 'string' ? error?.message : error?.message?.reduce((curr, message) => curr + `${message} \n`, '')
  if (error?.statusCode === 400) throw new Error(message)
  if (error?.statusCode === 401) throw new Error(message)
  if (error?.statusCode === 403) throw new Error(message)
  if (error?.statusCode === 404) throw new Error(message)

  if (!response?.data) throw new Error('Response body is empty!')

  throw new Error(extractErrorInfo(error))
}
