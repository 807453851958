// TODO - remove becouse of legacy

import { isSettingValueValid } from 'utils/isSettingValueDefined'
import { Landing, StepState, WizardStepStatus } from '../../types'

const getSettingsStep: (landing: Landing, currentStep: number, step?: number) => StepState = (landing, currentStep, step = 2) => {
  let isValid = true
  let status = 'wait' as WizardStepStatus
  if (landing?.settings.length > 0) {
    landing?.settings.forEach((setting) => {
      if (setting.required) {
        isValid = isValid && isSettingValueValid(setting)
      }
    })
  }

  if (isValid) {
    status = 'finish' as WizardStepStatus
  }

  if (currentStep === step) {
    status = 'process' as WizardStepStatus
  }

  if (currentStep !== step && !isValid) {
    status = 'error' as WizardStepStatus
  }

  return {
    isValid,
    status
  }
}

export { getSettingsStep }
