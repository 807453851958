import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Layout, Menu, Typography } from 'antd'
import {
  BarChartOutlined,
  DatabaseOutlined,
  DollarOutlined,
  InteractionOutlined,
  GiftOutlined,
  LogoutOutlined,
  SettingOutlined,
  ShopOutlined,
  SkinOutlined,
  ToolOutlined,
  UserOutlined
} from '@ant-design/icons'
import notification from 'mrx-notification'
import { GlobalContext } from 'appContexts'
import { useCampaignsList } from 'features/campaigns/hooks'
import CampaignsNavList from 'features/campaigns/components/CampaignsNavList'

import styles from './Navigation.module.scss'
import { connector, PropsFromRedux } from './container'
import { Campaign } from 'features/campaigns/types'

const StyledSider = styled(Layout.Sider)`
  .ant-layout-sider-children {
    overflow-x: hidden;
    overflow-y: auto;
    width: 220px;
  }
`

type NavigationProps = PropsFromRedux & {
  showCampaigns: boolean
  isCollapsed: boolean
  onShowCampaignsChange: () => void
  onCollapsed: () => void
}

const requiredRoles = (userRoles = [], requiredRoles = []) => {
  let hasAccess = false
  userRoles.forEach((role) => {
    if (requiredRoles.includes(role)) {
      hasAccess = true
    }
  })

  return hasAccess
}

const Navigation: React.FC<NavigationProps> = ({
  activeTabs,
  user,
  showCampaigns,
  isCollapsed,

  logout,
  onShowCampaignsChange,
  onCollapsed
}) => {
  const [activeCampaignName, setActiveCampaignName] = useState('')
  const [openKeysState, setOpenKeysState] = useState([''])

  const { campaignId, workspaceId } = useContext(GlobalContext)

  const campaignsList = useCampaignsList({
    workspaceId
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const campaigns: Campaign[] = []
  campaignsList &&
    campaignsList.data &&
    campaignsList.data.pages.forEach((page) => page.items.forEach((campaign) => campaigns.push(campaign)))

  useEffect(() => {
    if (campaignsList.error) {
      notification.error({
        message: 'Campaigns List error!',
        description: campaignsList.error instanceof Error ? campaignsList.error.message : campaignsList.error.toString()
      })
    }
  }, [campaignsList.error])

  useEffect(() => {
    if (campaignId) {
      const selectedItems = campaigns.filter((item) => item.id === campaignId)
      if (selectedItems.length) {
        setActiveCampaignName(selectedItems[0].name)
      }
    }
  }, [campaignId, campaigns])

  const rootSubmenuKeys = ['/sales', '/reporting', '/settings', '/catalogs']

  const onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find((key) => openKeysState.indexOf(key) === -1)
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeysState(openKeys)
    } else {
      setOpenKeysState(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  const userRoles = user?.roles ? [...user.roles] : ['guest']
  if (user?.workspacesRoles) {
    for (const item of user.workspacesRoles) {
      if (item.workspaceId === workspaceId) {
        userRoles.push(item.role)
      }
    }
  }

  return (
    <div className={styles.menuContainer}>
      {showCampaigns && <CampaignsNavList />}
      <StyledSider
        collapsible
        collapsed={isCollapsed}
        onCollapse={onCollapsed}
        className={styles.menu}
        width={220}
        style={{
          overflow: 'hidden',
          height: '100vh',
          position: 'fixed',
          zIndex: 99,
          left: showCampaigns ? 50 : 0
        }}
      >
        {!isCollapsed && (
          <div className={styles.avatarContainer}>
            <div className={styles.activeCampaign}>
              <Typography.Text className={styles.activeCampaignText}>{activeCampaignName}</Typography.Text>
              <SettingOutlined onClick={onShowCampaignsChange} />
            </div>
            <Typography.Text className={styles.avatarText}>{`${user?.firstName} ${user?.lastName}`}</Typography.Text>
          </div>
        )}

        <Menu
          theme='dark'
          className={styles.antMenu}
          defaultSelectedKeys={activeTabs}
          mode='inline'
          selectedKeys={activeTabs}
          openKeys={openKeysState}
          onOpenChange={onOpenChange}
        >
          <Menu.Item key='/stores'>
            <NavLink to='/stores' />
            <ShopOutlined />
            <span>Stores</span>
          </Menu.Item>

          <Menu.Item key='/products'>
            <NavLink to='/products' />
            <SkinOutlined />
            <span>Products</span>
          </Menu.Item>

          <Menu.Item key='/promocodes'>
            <NavLink to='/promocodes' />
            <GiftOutlined />
            <span>Promocodes</span>
          </Menu.Item>

          <Menu.Item key='/customerAccounts'>
            <NavLink to='/customerAccounts' />
            <UserOutlined />
            <span>Customer Accounts</span>
          </Menu.Item>

          <Menu.SubMenu
            key='/sales'
            title={
              <span>
                <DollarOutlined />
                <span>Sales</span>
              </span>
            }
          >
            <Menu.Item key='/sales/orders'>
              <NavLink to='/sales/orders' />
              Orders
            </Menu.Item>
            <Menu.Item key='/sales/invoices'>
              <NavLink to='/sales/invoices' />
              Invoices
            </Menu.Item>
            <Menu.Item key='/sales/customerSubscriptions'>
              <NavLink to='/sales/customerSubscriptions' />
              Subscriptions
            </Menu.Item>
            <Menu.Item key='/sales/needDesign'>
              <NavLink to='/sales/needDesign' />
              Need Design
            </Menu.Item>
            <Menu.Item key='/sales/customizableList'>
              <NavLink to='/sales/customizableList' />
              Customizable List
            </Menu.Item>
            <Menu.Item key='/sales/holdedOrders'>
              <NavLink to='/sales/holdedOrders' />
              Holded Orders
            </Menu.Item>
            <Menu.Item key='/sales/customers'>
              <NavLink to='/sales/customers' />
              Customers
            </Menu.Item>
            <Menu.Item key='/sales/carts'>
              <NavLink to='/sales/carts' />
              Carts
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.SubMenu
            key='/reporting'
            title={
              <span>
                <BarChartOutlined />
                <span>Reporting</span>
              </span>
            }
          >
            <Menu.Item key='/reporting/customersData'>
              <NavLink to='/reporting/customersData' />
              Customers Data
            </Menu.Item>
            <Menu.Item key='/reporting/canceledSubscriptions'>
              <NavLink to='/reporting/canceledSubscriptions' />
              Canceled Subscriptions
            </Menu.Item>

            <Menu.Item key='/reporting/overview'>
              <NavLink to='/reporting/overview' />
              Overview
            </Menu.Item>
            <Menu.Item key='/reporting/subscriptionStats'>
              <NavLink to='/reporting/subscriptionStats' />
              Subscription Stats
            </Menu.Item>
            <Menu.Item key='/reporting/inventory'>
              <NavLink to='/reporting/inventory' />
              Inventory
            </Menu.Item>
            {requiredRoles(userRoles, ['DEVELOPER']) && (
              <Menu.Item key='/reporting/salesFunnelAnalyze'>
                <NavLink to='/reporting/salesFunnelAnalyze' />
                Sales Funnel Analyze
              </Menu.Item>
            )}
            {requiredRoles(userRoles, ['accountant']) && (
              <Menu.ItemGroup title='Costs'>
                <Menu.Item key='/reporting/costs/processing'>
                  <NavLink to='/reporting/costs/processing' />
                  Processing
                </Menu.Item>
              </Menu.ItemGroup>
            )}
          </Menu.SubMenu>

          <Menu.SubMenu
            key='/settings'
            title={
              <span>
                <SettingOutlined />
                <span>Settings</span>
              </span>
            }
          >
            {requiredRoles(userRoles, ['SYSTEM_ADMIN', 'WORKSPACE_ADMIN']) && (
              <Menu.Item key='/settings/defaults'>
                <NavLink to='/settings/defaults' />
                Defaults
              </Menu.Item>
            )}
            {requiredRoles(userRoles, ['SYSTEM_ADMIN', 'WORKSPACE_ADMIN']) && (
              <Menu.Item key='/settings/domains'>
                <NavLink to='/settings/domains' />
                Domains
              </Menu.Item>
            )}
            {requiredRoles(userRoles, ['SYSTEM_ADMIN', 'WORKSPACE_ADMIN']) && (
              <Menu.Item key='/settings/plugins'>
                <NavLink to='/settings/plugins' />
                Plugins
              </Menu.Item>
            )}
            {requiredRoles(userRoles, ['SYSTEM_ADMIN', 'WORKSPACE_ADMIN']) && (
              <Menu.Item key='/settings/shippingMethods'>
                <NavLink to='/settings/shippingMethods' />
                Shipping Methods
              </Menu.Item>
            )}
            {requiredRoles(userRoles, ['SYSTEM_ADMIN', 'WORKSPACE_ADMIN']) && (
              <Menu.Item key='/settings/shippingRules'>
                <NavLink to='/settings/shippingRules' />
                Shipping Rules
              </Menu.Item>
            )}
            {requiredRoles(userRoles, ['SYSTEM_ADMIN', 'WORKSPACE_ADMIN']) && (
              <Menu.Item key='/settings/campaignPlugins'>
                <NavLink to='/settings/campaignPlugins' />
                Campaign Plugins
              </Menu.Item>
            )}

            {requiredRoles(userRoles, ['SYSTEM_ADMIN']) && (
              <Menu.Item key='/settings/users'>
                <NavLink to='/settings/users' />
                Users
              </Menu.Item>
            )}
          </Menu.SubMenu>

          <Menu.SubMenu
            key='/catalogs'
            title={
              <span>
                <DatabaseOutlined />
                <span>Catalogs</span>
              </span>
            }
          >
            {requiredRoles(userRoles, ['DEVELOPER']) && (
              <Menu.Item key='/catalogs/awsCertificates'>
                <NavLink to='/catalogs/awsCertificates' />
                AWS Certificates
              </Menu.Item>
            )}
            <Menu.Item key='/catalogs/DTGProducts'>
              <NavLink to='/catalogs/DTGProducts' />
              DTG Products
            </Menu.Item>
            <Menu.Item key='/catalogs/DTGSizes'>
              <NavLink to='/catalogs/DTGSizes' />
              DTG Sizes
            </Menu.Item>
            <Menu.Item key='/catalogs/DTGVariants'>
              <NavLink to='/catalogs/DTGVariants' />
              DTG Variants
            </Menu.Item>
            {requiredRoles(userRoles, ['DEVELOPER']) && (
              <Menu.Item key='/catalogs/countries'>
                <NavLink to='/catalogs/countries' />
                Countries
              </Menu.Item>
            )}
            {requiredRoles(userRoles, ['DEVELOPER']) && (
              <Menu.Item key='/catalogs/currencies'>
                <NavLink to='/catalogs/currencies' />
                Currencies
              </Menu.Item>
            )}
            {requiredRoles(userRoles, ['DEVELOPER']) && (
              <Menu.Item key='/catalogs/colors'>
                <NavLink to='/catalogs/colors' />
                Colors
              </Menu.Item>
            )}
            {requiredRoles(userRoles, ['DEVELOPER']) && (
              <Menu.Item key='/catalogs/tags'>
                <NavLink to='/catalogs/tags' />
                Tags
              </Menu.Item>
            )}
            {requiredRoles(userRoles, ['DEVELOPER']) && (
              <Menu.Item key='/catalogs/sizes'>
                <NavLink to='/catalogs/sizes' />
                Sizes
              </Menu.Item>
            )}
          </Menu.SubMenu>

          <Menu.SubMenu
            key='/services'
            title={
              <span>
                <ToolOutlined />
                <span>Services</span>
              </span>
            }
          >
            <Menu.Item key='/services/redeployment'>
              <NavLink to='/services/redeployment' />
              <span>Redeployment</span>
            </Menu.Item>
            {requiredRoles(userRoles, ['SYSTEM_ADMIN']) && (
              <Menu.Item key='/services/alerts'>
                <NavLink to='/services/alerts' />
                Alerts
              </Menu.Item>
            )}
          </Menu.SubMenu>

          {requiredRoles(userRoles, ['DEVELOPER']) && (
            <Menu.Item key='/tasksQueue'>
              <NavLink to='/tasksQueue' />
              <InteractionOutlined />
              <span>Tasks Queue</span>
            </Menu.Item>
          )}
          <Menu.Item key='/login' onClick={logout}>
            <LogoutOutlined />
            <span>Log Out</span>
          </Menu.Item>
        </Menu>
      </StyledSider>
    </div>
  )
}

export default connector(Navigation)
