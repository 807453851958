import React, { useState, useEffect, useRef } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Drawer, Input, Form } from 'antd'
import notification from 'mrx-notification'
import styles from './CreateStripeSetting.module.scss'
import { PropsFromRedux, connector } from './container'

const CreateStripeSetting = (props: PropsFromRedux) => {
  const [isVisible, setVisible] = useState(false)
  const refEl = useRef<Input>(null)

  const { isLoading, createStripeSetting } = props

  useEffect(() => {
    if (isVisible) {
      refEl.current && refEl.current.focus()
    }
  }, [isVisible])

  const showDrawer = () => {
    setVisible(!isVisible)
  }

  const [form] = Form.useForm()

  const handleClose = () => {
    setVisible(false)
    form.resetFields()
  }

  const onFinishHandler = async (values: any) => {
    const isSaved = await createStripeSetting({
      name: values.name,
      apiVersion: values.apiVersion,
      host: values.host,
      port: values.port,
      secretKey: values.secretKey,
      publishableKey: values.publishableKey
    })
    if (isSaved) {
      notification.success({
        message: 'Successfully',
        description: 'Stripe Setting was created successfully!'
      })
      handleClose()
    }
  }

  return (
    <>
      <Button type='primary' onClick={showDrawer}>
        <PlusOutlined />
        CREATE
      </Button>
      <Drawer title='Create Stripe Setting' width='400' onClose={handleClose} visible={isVisible} forceRender>
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
          <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input stripe setting name!' }]}>
            <Input ref={refEl} />
          </Form.Item>
          <Form.Item name='apiVersion' label='API Version' rules={[{ required: true, message: 'Please input API Version!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name='host' label='Host' rules={[{ required: true, message: 'Please input Host!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name='port' label='Port' rules={[{ required: true, message: 'Please input Port!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name='secretKey' label='Secret Key' rules={[{ required: true, message: 'Please input Secret Key!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name='publishableKey' label='Publishable Key' rules={[{ required: true, message: 'Please input Publishable key!' }]}>
            <Input />
          </Form.Item>
          <div className={styles.buttonsContainer}>
            <Button onClick={handleClose} className={styles.createButton}>
              Close
            </Button>
            <Button type='primary' htmlType='submit' loading={isLoading}>
              Create
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

export default connector(CreateStripeSetting)
