import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Table, Tag, Tooltip, Progress, Popconfirm, Modal, Button } from 'antd'
import { RedoOutlined } from '@ant-design/icons'
import { ColumnProps } from 'antd/es/table'
import notification from 'mrx-notification'

import { Task } from 'features/tasksQueue/types'
import { OrderTask } from 'features/orders/types'
import { useCustomerSubscription } from '../../hooks'
import { connector, PropsFromRedux } from './container'
import styles from './TasksTab.module.scss'

const tagColors = {
  NEW: 'cyan',
  IN_PROGRESS: 'gold',
  CANCELED: 'volcano',
  COMPLETED: 'green'
}

const canRestart = ['IN_PROGRESS', 'PROCESSED', 'CANCELED', 'COMPLETED']

type TasksTabType = PropsFromRedux & {
  customerSubscriptionId?: number
}

const TasksTab = (props: TasksTabType) => {
  const {
    customerSubscriptionId,

    restartTask
  } = props

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState<Task>()
  // eslint-disable-next-line
  const [now, _setNow] = useState(moment())

  const customerSubscription = useCustomerSubscription(customerSubscriptionId)

  useEffect(() => {
    if (customerSubscription.error) {
      notification.error({
        message: 'Tasks list error!',
        description:
          customerSubscription.error instanceof Error ? customerSubscription.error.message : customerSubscription.error.toString()
      })
    }
  }, [customerSubscription.error])

  const doConfirmRestart = async (taskId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    const isRestarted = await restartTask(taskId)
    if (isRestarted) {
      notification.success({
        message: 'Successfully',
        description: 'Task was restarted successfully!'
      })
    }
  }

  const handleOnRow = (record) => {
    return {
      onClick: () => {
        setIsModalVisible(true)
        setSelectedRecord(record)
      }
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const columns: ColumnProps<OrderTask>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Status',
      render: (record: OrderTask) => {
        return <Tag color={tagColors[record.status]}>{record.status}</Tag>
      },
      key: 'status'
    },
    {
      title: 'Task Type',
      render: (record: OrderTask) => {
        const taskType = (record.taskType.charAt(0).toUpperCase() + record.taskType.slice(1).toLowerCase()).replace(/_/g, ' ')
        const payloadTaskType = record.payload?.taskType
          ? (record.payload?.taskType?.charAt(0).toUpperCase() + record.payload?.taskType?.slice(1).toLowerCase()).replace(/_/g, ' ')
          : ''
        return taskType + (payloadTaskType ? ` (${payloadTaskType})` : '')
      },
      key: 'type'
    },
    {
      title: 'Progress',
      render: (record: OrderTask) => {
        return <Progress percent={record.progress} size='small' status='active' />
      },
      key: 'progress'
    },
    {
      title: 'Retries',
      render: (record: OrderTask) => {
        let color = 'green'
        if (record.retryNumber === 2) {
          color = 'orange'
        } else if (record.retryNumber === 3) {
          color = 'volcano'
        }
        return <Tag color={color}>{record.retryNumber}</Tag>
      }
    },
    {
      title: 'Planned time',
      render: (record: OrderTask) => <div>{moment(record.startDate).format('MM/DD/YYYY HH:mm:ss')}</div>
    },
    {
      title: 'Executed at',
      render: (record: OrderTask) => (record.startedAt ? <div>{moment(record.startedAt).format('MM/DD/YYYY HH:mm:ss')}</div> : <></>)
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (record: OrderTask) => (
        <div className={styles.actionIcons}>
          {canRestart.includes(record.status) && (
            <span>
              <Popconfirm
                title='Are you sure restart this task?'
                onConfirm={(e) => doConfirmRestart(record.id, e)}
                onCancel={(e) => e?.stopPropagation()}
                okText='Yes'
                cancelText='No'
              >
                <Tooltip title='Restart Task'>
                  <RedoOutlined
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  />
                </Tooltip>
              </Popconfirm>
            </span>
          )}
        </div>
      )
    }
  ]

  return (
    <>
      <Table
        columns={columns}
        dataSource={customerSubscription.data.customerSubscriptionTasks.map((item) => item).flat()}
        bordered
        rowKey={(record) => record.id}
        onRow={handleOnRow}
        loading={customerSubscription.isLoading}
      />

      <Modal
        title='Task Message'
        visible={isModalVisible}
        footer={[
          <Button key='submit' type='primary' onClick={handleCancel}>
            OK
          </Button>
        ]}
        onCancel={handleCancel}
      >
        {selectedRecord?.message ? selectedRecord.message : 'No message!'}
        <br />
        {selectedRecord?.payload ? `Payload: ${JSON.stringify(selectedRecord.payload)}` : 'Empty payload!'}
      </Modal>
    </>
  )
}

export default connector(TasksTab)
