import React, { useEffect, useState } from 'react'
import { Button, Form, Card } from 'antd'
import hash from 'object-hash'
import notification from 'mrx-notification'
import { SettingOwner } from '@merchx-v2/shared-types'
import { useBatchUpdateSettingsValues, useUploadSettingImage } from '../../hooks'
import styles from './SettingsForLanding.module.scss'
import { Setting, SettingDataInput, CustomFile } from '../../types'

import FormItemSetting from '../FormItemSetting'
import { Landing } from 'features/landings/types'

type SettingImage = {
  setting: Setting
  image: CustomFile
}

const getUpdatedSettings = (settings: Setting[]) => {
  return settings.map(
    (item) =>
      ({
        alias: item.alias,
        name: item.name,
        type: item.type,
        // value: item.value,
        // TODO lot of templates have wrong saved boolean setting, to not bring a pain it needs to setup default value until time will fix it
        value: item.type === 'BOOLEAN' && item.value === '' ? 'false' : item.value,
        required: item.required
      } as SettingDataInput)
  )
}

type PropsType = {
  ownerType: SettingOwner
  ownerId: number
  landing: Landing
  selfUpdate?: boolean
  nextStep?: number
  onNextButtonClick?: (nextStep: number) => void
}

const SettingsForLanding = (props: PropsType) => {
  const {
    ownerType,
    ownerId,
    landing,
    selfUpdate,
    nextStep = 3,

    onNextButtonClick
  } = props

  const [form] = Form.useForm()

  const [owner, setOwner] = useState<string>(ownerType)

  const page = landing?.pages?.filter((item) => item.id === ownerId)
  const autoresponder = landing?.autoresponders?.filter((item) => item.id === ownerId)

  const settings = owner === 'LANDING' ? landing?.settings : owner === 'PAGE' ? page[0].settings : autoresponder[0].settings

  const [settingsImages, setSettingsImages] = useState<Record<string, SettingImage>>({})
  const [updatedSettings, setUpdatedSettings] = useState<SettingDataInput[]>(getUpdatedSettings(settings))

  const batchUpdateSettingsValues = useBatchUpdateSettingsValues()
  const uploadSettingImage = useUploadSettingImage()

  useEffect(() => {
    setOwner(ownerType)
  }, [ownerType])

  useEffect(() => {
    if (settings.length) {
      setUpdatedSettings(getUpdatedSettings(settings))
      form.setFieldsValue(
        settings.reduce((accum, current) => {
          if (!accum[current.alias]) {
            if (current.type !== 'BOOLEAN') {
              accum[current.alias] = current.value
            } else {
              accum[current.alias] = current.value === 'true'
            }
          }
          return accum
        }, {})
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(settings)])

  const handleClose = () => {
    form.resetFields()
  }

  const onFinishHandler = async () => {
    await form.validateFields()
    batchUpdateSettingsValues.mutate(
      { ownerType, ownerId, settingsData: updatedSettings },
      {
        onSuccess: () => {
          notification.success({
            message: 'Successfully',
            description: 'Setting was updated successfully!'
          })

          if (!selfUpdate) {
            onNextButtonClick(nextStep)
          }
        },
        onError: (error) => {
          notification.error({
            message: 'Settings for Landing page error!',
            description: error.toString()
          })
        }
      }
    )

    const updatedImagesSettingsIds = Object.keys(settingsImages)

    for (const settingId of updatedImagesSettingsIds) {
      const {
        setting,
        image: { file, name }
      } = settingsImages[settingId]

      uploadSettingImage.mutate(
        {
          ownerType: setting.ownerType,
          ownerId: setting.ownerId,
          settingId: +settingId,
          assetData: { name, file }
        },
        {
          onError: (error) => {
            notification.error({
              message: 'Settings for Landing page error!',
              description: error.toString()
            })
          }
        }
      )
    }
  }

  const handleSettingValueChanged = (setting: Setting, newValue: string) => {
    setUpdatedSettings(
      updatedSettings.map((item) => {
        let updatedItem = item
        if (item.alias === setting.alias) {
          updatedItem = {
            alias: item.alias,
            name: item.name,
            type: item.type,
            value: newValue,
            required: item.required
          }
        }
        return updatedItem
      })
    )

    if (setting.type === 'PRODUCT') {
      form.setFieldsValue({
        [setting.alias]: newValue
      })
    }
  }

  const handleOnImageUploaded = (setting: Setting, image: CustomFile) => {
    setSettingsImages({
      ...settingsImages,
      [setting.id]: {
        image,
        setting
      }
    })
  }

  return (
    <>
      <Card
        className={styles.card}
        title={<span className={styles.cardTitle}>Settings</span>}
        extra={[
          onNextButtonClick && (
            <Button
              key='settings-for-landings-next-button'
              type='primary'
              htmlType='submit'
              loading={batchUpdateSettingsValues.isLoading}
              onClick={onFinishHandler}
            >
              NEXT
            </Button>
          )
        ]}
      >
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
          {settings.map((item) => (
            <FormItemSetting
              key={item.id}
              setting={item}
              selfUpdate={selfUpdate}
              setFieldsValue={form.setFieldsValue}
              onValueChanged={handleSettingValueChanged}
              onImageUploaded={handleOnImageUploaded}
              landingId={landing.id}
              ownerType={ownerType}
              ownerId={ownerId}
            />
          ))}
          {!selfUpdate && !onNextButtonClick && (
            <div className={styles.buttonContainer}>
              <Button onClick={handleClose} className={styles.cancelButton}>
                Cancel
              </Button>
              <Button type='primary' htmlType='submit' loading={batchUpdateSettingsValues.isLoading}>
                Save
              </Button>
            </div>
          )}
        </Form>
      </Card>
    </>
  )
}

export default SettingsForLanding
