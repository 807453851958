import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { ProductColor, ProductColorsState } from '../types'
import featureStateSelector from './featureStateSelector'

export const productColorsListSelector = createSelector<ReduxState, ProductColorsState, ProductColor[]>(
  featureStateSelector,
  state => {
    const { pages, currentPage } = state.pagination
    return (pages[currentPage] || []).map(id => state.productColors[id])
  }
)
