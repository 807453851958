import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type isLoadingKeyType =
  | 'isCustomersDataLoading'
  | 'isCustomersCsvDataLoading'
  | 'isDashboardReportLoading'
  | 'isFailedFulfillmentReportLoading' // TODO - remove becouse of legacy
  | 'isFailedFulfillmentCsvReportLoading' // TODO - remove becouse of legacy
  | 'isSalesFunnelAnalyzeLoading'
  | 'isTopCampaignsRateLoading'
  | 'isTopCampaignsLoading'
  | 'isTopLandingsLoading'
  | 'isCanceledSubscriptionsLoading'
  | 'isCampaignTemplatesReportLoading'
  | 'isSubscriptionStatsReportLoading'

type IsLoadingSelector = (state: ReduxState, isLoadingKey: isLoadingKeyType) => boolean

const getIsLoadingSelectorByKey = (state: ReduxState, isLoadingKey: isLoadingKeyType) => state.reports.UIState[isLoadingKey] || false

export const isLoadingSelector: IsLoadingSelector = createSelector(getIsLoadingSelectorByKey, (isLoading) => isLoading)
