import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { DTGProduct } from '../types'

export type CreateDTGProductAction = FSA<undefined, DTGProduct, string>
type CreateDTGProduct = (domainData: DTGProductInput) => MrxThunk<CreateDTGProductAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'createDTGProduct', DTGProduct>

const CREATE_DTG_PRODUCT = `
  mutation createDTGProduct ($DTGProductData: DTGProductInput!) {
    createDTGProduct(DTGProductData: $DTGProductData) {
      id
      name
      categoryId
      DTGProductId
      createdAt
      updatedAt
    }
  }
`
type DTGProductInput = {
  name: string
  DTGProductId: number
  categoryId: number
}

const createDTGProduct: CreateDTGProduct = DTGProductData => async dispatch => {
  let isSaved = false

  dispatch({
    type: t.CREATE_DTG_PRODUCT,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_DTG_PRODUCT,
      variables: { DTGProductData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createDTGProduct } = data
    if (!createDTGProduct) {
      throw new Error("Can't create DTGProduct!")
    }

    dispatch({
      type: t.CREATE_DTG_PRODUCT,
      payload: createDTGProduct,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.CREATE_DTG_PRODUCT,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { createDTGProduct }
