import React, { useEffect } from 'react'
import { Form, Input } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import notification from 'mrx-notification'
import { SettingOwner, SettingType } from '@merchx-v2/shared-types'
import useDebounce from 'utils/useDebounce'
import { useUpdateSettingValue } from '../../hooks'
import { Setting } from '../../types'

type PropsType = {
  ownerType: SettingOwner
  ownerId: number
  setting: Setting
  selfUpdate?: boolean
  onValueChanged?: (setting: Setting, newValue: any) => void
  setFieldsValue?: (store: any) => void
}

const FormItemStringSetting = React.memo((props: PropsType) => {
  const { selfUpdate = false, setting, ownerType, ownerId, onValueChanged, setFieldsValue } = props
  const [valueState, setValueState] = React.useState<string>(setting.value)
  const debouncedText = useDebounce(valueState, 500)

  const updateSettingValue = useUpdateSettingValue()

  useEffect(() => {
    if (debouncedText !== undefined) {
      if (onValueChanged && setting.value !== debouncedText) {
        onValueChanged(setting, debouncedText)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedText])

  const greenButtonClickHandler = () => {
    if (selfUpdate && setting.value !== valueState) {
      updateSettingValue.mutate(
        {
          ownerType,
          ownerId,
          settingId: setting.id,
          settingData: {
            name: setting.name,
            alias: setting.alias,
            type: setting.type as SettingType,
            value: valueState,
            required: setting.required
          }
        },
        {
          onSuccess: () => {
            notification.success({
              message: 'Successfully',
              description: `${setting.name} setting was updated!`
            })
          },
          onError: (error) => {
            notification.error({
              message: `${setting.name} setting error!`,
              description: error.toString()
            })
          }
        }
      )
    }
  }

  const redButtonClickHandler = () => {
    setValueState(setting.value)

    setFieldsValue({
      [setting.alias]: setting.value
    })
  }

  return (
    <Form.Item
      name={setting.alias}
      label={setting.name}
      key={setting.id}
      initialValue={setting.value}
      validateStatus={setting.required && !valueState ? 'error' : 'success'}
      rules={[{ required: setting.required, message: `Please input "${setting.name}" value` }]}
    >
      <Input
        style={{ width: '100%' }}
        onChange={(e) => setValueState(e.target.value)}
        suffix={
          <div>
            {selfUpdate && setting.value !== valueState && (
              <>
                <CloseOutlined style={{ color: 'tomato' }} onClick={redButtonClickHandler} />
                <CheckOutlined style={{ color: '#52c41a', marginLeft: '5px' }} onClick={greenButtonClickHandler} />
              </>
            )}
          </div>
        }
      />
    </Form.Item>
  )
})

export default FormItemStringSetting
