import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { CustomersData } from '../types'

type CustomersDataReportSelector = (state: ReduxState) => CustomersData[]

const getCustomersData: CustomersDataReportSelector = state => {
  let result = [] as CustomersData[]

  if (state.reports.customersData) {
    result = Object.values(state.reports.customersData) as CustomersData[]
  }

  return result
}

export const customersDataReportSelector: CustomersDataReportSelector = createSelector(
  getCustomersData,
  customersData => customersData
)
