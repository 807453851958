import { createSelector, ParametricSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { Region } from '../types'

const getRegion: ParametricSelector<ReduxState, number | undefined, Region | undefined> = (state, countryId, regionId) => {
  let result
  if (countryId && Object.prototype.hasOwnProperty.call(state.regions.regions, countryId)) {
    if (regionId && Object.prototype.hasOwnProperty.call(state.regions.regions[countryId], regionId)) {
      result = state.regions.regions[countryId][regionId] as Region
    }
  }

  return result
}

export const regionSelector = createSelector(getRegion, (region) => region)
