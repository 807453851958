// TODO - remove becouse of legacy

import React, { useContext, useState, useEffect, useRef } from 'react'
import useReactRouter from 'use-react-router'
import useCustomCompareEffect from 'use-custom-compare-effect'
import { PageHeader, Layout, Menu, Popconfirm, Button } from 'antd'
import { Console } from '@nicksrandall/console-feed'
import notification from 'mrx-notification'
import { GlobalContext } from 'appContexts'
import { Breadcrumbs, Loading } from 'components'
import { DeleteOutlined } from '@ant-design/icons'
import * as assetsFeature from 'features/assets'
import * as autorespondersFeature from 'features/autoresponders'
import * as componentsFeature from 'features/components'
import * as dependenciesFeature from 'features/dependencies'
import * as pagesFeature from 'features/pages'
import * as rootStyles from 'assets/layoutStyle'
import * as siteSnapshotsFeature from 'features/siteSnapshots'
import { useRemoveAutoresponder } from 'features/autoresponders/hooks'
import { useRemoveComponent } from 'features/components/hooks'
import { useRemovePage } from 'features/pages/hooks'
import { useTemplate, useBuildTemplate } from '../../hooks'
import styles from './TemplateDetails.module.scss'

import { PropsFromRedux, connector } from './container'
import { SettingsList } from 'features/settings/components'
import { Autoresponder } from 'features/autoresponders/types'
import { TemplateBuild } from 'features/templates/types'
import { convertLoggerMessageToFeed } from 'utils/convertLoggerMessageToFeed'
import { Message } from '@nicksrandall/console-feed/lib/definitions/Component'

const { AssetsList } = assetsFeature.components
const { AutoresponderDetails, CreateAutoresponder } = autorespondersFeature.components
const { CreateComponent, ComponentDetails } = componentsFeature.components
const { DependenciesList } = dependenciesFeature.components
const { CreatePage, PageDetails } = pagesFeature.components
const { SnapshotsMenu } = siteSnapshotsFeature.components

const TemplateDetails = ({
  match: {
    params: { templateId }
  },

  subscribeToChannel,
  unsubscribeFromChannel
}: PropsFromRedux) => {
  const { history } = useReactRouter()

  const feedRef = useRef()
  const [activeMenuItem, setActiveMenuItem] = useState<string[]>(['assets'])
  const [activeAutoresponderId, setActiveAutoresponderId] = useState<number>()
  const [activePageId, setActivePageId] = useState<number>()
  const [activeComponentId, setActiveComponentId] = useState<number>()
  const [activeBuildVersion, setActiveBuildVersion] = useState<number>(0)
  const [activeComponent, setActiveComponent] = useState<string>('assets')
  const [isCreateAutoresponderVisible, setIsCreateAutoresponderVisible] = useState(false)
  const [isCreateTemplatePageVisible, setIsCreateTemplatePageVisible] = useState(false)
  const [isCreateComponentVisible, setIsCreateComponentVisible] = useState(false)
  const [isTemplateBuildExist, setIsTemplateBuildExist] = useState<boolean>(true)
  const [isSublander, setIsSublander] = useState(false)

  const removeAutoresponder = useRemoveAutoresponder()
  const removeComponent = useRemoveComponent()
  const removePage = useRemovePage()

  const buildTemplate = useBuildTemplate()
  const template = useTemplate(+templateId)

  const { workspaceId } = useContext(GlobalContext)

  const buildLogs = template?.data?.builds?.reduce((accum, item) => {
    if (!accum[item.version]) {
      accum[item.version] = [] as Message[]
    }

    accum[item.version] = [convertLoggerMessageToFeed(item.logs)]
    return accum
  }, {} as Record<number, Message[]>)

  useEffect(() => {
    if (template.error) {
      notification.error({
        message: 'Templates page error!',
        description: template.error instanceof Error ? template.error.message : template.error.toString()
      })
    }
  }, [template.error])

  useEffect(() => {
    const channel = `build-template/${templateId}`
    subscribeToChannel(channel)

    return () => {
      unsubscribeFromChannel(channel)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId])

  useCustomCompareEffect(
    () => {
      setActiveBuildVersion(template?.data?.version)
      setActiveMenuItem([`builds-${template?.data?.version}`])
      setIsTemplateBuildExist(
        template?.data?.builds?.some(
          (build) => build.version === template.data.version && build.hashRouterArtifactsS3path && build.browserRouterArtifactsS3path
        )
      )
      setIsSublander(template?.data?.templateType === 'SUBLANDER' || template?.data?.templateType === 'QUIZ')
    },
    [template],
    () =>
      // skip update?
      template?.data?.version === activeBuildVersion || !template?.data?.builds?.some((build) => build.version === template?.data?.version)
  )

  useEffect(() => {
    setIsTemplateBuildExist(
      template?.data?.builds?.some(
        (build) => build.version === activeBuildVersion && build.hashRouterArtifactsS3path && build.browserRouterArtifactsS3path
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBuildVersion])

  useEffect(() => {
    if (feedRef.current) {
      // @ts-ignore
      feedRef.current.scrollTop = feedRef.current.scrollHeight
    }
  }, [buildLogs])

  const onAutoresponderSelectedHandler = (component: any, autoresponderId: number) => {
    setActiveComponent(component)
    setActiveAutoresponderId(autoresponderId)
    setActiveMenuItem([`autoresponder-${autoresponderId}`])
  }

  const onPageSelectHandler = (component: any, pageId: number) => {
    setActiveComponent(component)
    setActivePageId(pageId)
    setActiveMenuItem([`page-${pageId}`])
  }

  const onComponentSelectHandler = (component: any, componentId: number) => {
    setActiveComponent(component)
    setActiveComponentId(componentId)
    setActiveMenuItem([`component-${componentId}`])
  }

  const onBuildVersionSelectHandler = (component: any, version: number) => {
    setActiveComponent(component)
    setActiveBuildVersion(version)
    setActiveMenuItem([`build-${version}`])
  }

  const doConfirmPageDelete = async (e: any, pageId: number) => {
    e.stopPropagation()
    removePage.mutate(
      { ownerType: 'TEMPLATE', ownerId: +templateId, pageId: pageId },
      {
        onSuccess: () => {
          setActivePageId(undefined)
          notification.success({
            message: 'Successfully',
            description: 'Template Autoresponder was deleted successfully!'
          })
        },
        onError: (error) => {
          notification.error({
            message: 'Error!',
            description: error.toString()
          })
        }
      }
    )
  }

  const doConfirmComponentDelete = async (e: any, componentId: number) => {
    e.stopPropagation()
    removeComponent.mutate(
      { ownerType: 'TEMPLATE', ownerId: +templateId, componentId },
      {
        onSuccess: () => {
          setActiveComponentId(undefined)
          notification.success({
            message: 'Successfully',
            description: 'Template Page was deleted successfully!'
          })
        },
        onError: (error) => {
          notification.error({
            message: 'Error!',
            description: error.toString()
          })
        }
      }
    )
  }

  const doConfirmAutoresponderDelete = async (e: any, autoresponderId: number) => {
    e.stopPropagation()
    removeAutoresponder.mutate(
      { ownerType: 'TEMPLATE', ownerId: +templateId, autoresponderId },
      {
        onSuccess: () => {
          setActiveAutoresponderId(undefined)
          notification.success({
            message: 'Successfully',
            description: 'Template Autoresponder was deleted successfully!'
          })
        },
        onError: (error) => {
          notification.error({
            message: 'Error!',
            description: error.toString()
          })
        }
      }
    )
  }

  const doCancel = (e: any) => {
    e.stopPropagation()
  }

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case 'assets': {
        return <AssetsList ownerType='TEMPLATE' ownerId={+templateId} assets={template?.data?.assets} />
      }
      case 'autoresponder': {
        if (!activeAutoresponderId) {
          return null
        }
        return (
          <AutoresponderDetails
            ownerType='TEMPLATE'
            ownerId={+templateId}
            autoresponderId={activeAutoresponderId}
            source={template?.data}
          />
        )
      }
      case 'component': {
        if (!activeComponentId) {
          return null
        }
        return (
          <ComponentDetails
            componentId={activeComponentId}
            components={template?.data?.components}
            ownerType='TEMPLATE'
            ownerId={+templateId}
          />
        )
      }
      case 'dependencies': {
        return <DependenciesList ownerType='TEMPLATE' ownerId={+templateId} dependencies={template?.data?.dependencies} />
      }
      case 'settings': {
        return <SettingsList ownerType='TEMPLATE' ownerId={+templateId} settings={template?.data?.settings} />
      }
      case 'page': {
        if (!activePageId) {
          return null
        }
        return <PageDetails ownerType='TEMPLATE' ownerId={+templateId} pageId={activePageId} source={template?.data} />
      }
      case 'builds': {
        // @ts-ignore
        return (
          <div ref={feedRef} className={styles.buildLogsContainer}>
            <Console
              styles={{
                BASE_COLOR: 'black',
                LOG_COLOR: '#CCC1C1',
                LOG_INFO_COLOR: '#BC3FB7',
                LOG_RESULT_COLOR: '#12BC79',
                LOG_WARN_COLOR: '#E5E510'
              }}
              logs={buildLogs[activeBuildVersion] || []}
            />
          </div>
        )
      }
    }
  }

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title={template.data ? template.data.name : 'Template'}
          extra={[
            <div key='Actions' className={styles.headerActionsContainer}>
              {template?.data?.version && (
                <div key='version' className={styles.versionInfo}>
                  Version {template.data.version}
                </div>
              )}
              {!isTemplateBuildExist && template?.data?.version && (
                <Button
                  onClick={() => {
                    buildTemplate.mutate({ workspaceId, templateId: +templateId, version: template.data.version })
                    setActiveComponent('builds')
                    setActiveBuildVersion(template.data.version)
                    setActiveMenuItem([`build-${template.data.version}`])
                  }}
                >
                  Build
                </Button>
              )}
              <SnapshotsMenu key='snapshots' ownerType='TEMPLATE' ownerId={+templateId} />
            </div>
          ]}
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Settings', url: '/' },
            { title: 'Templates', url: '/settings/templates' },
            { title: 'Template Details', url: '/settings/templates/' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        {template.isLoading && <Loading />}
        {!(template.isLoading || buildTemplate.isLoading) && (
          <div className={styles.contentContainer}>
            <Menu mode='inline' style={{ width: 300 }} selectedKeys={activeMenuItem}>
              {!isSublander && (
                <>
                  <Menu.Item
                    key='assets'
                    onClick={() => {
                      setActiveComponent('assets')
                      setActiveMenuItem(['assets'])
                    }}
                  >
                    Assets
                  </Menu.Item>

                  <Menu.SubMenu key='components' title='Components'>
                    <Menu.Item
                      key='4'
                      style={{ color: '#1890ff' }}
                      onClick={() => {
                        setActiveComponent('createComponent')
                        setIsCreateComponentVisible(!isCreateComponentVisible)
                      }}
                    >
                      + ADD COMPONENT
                    </Menu.Item>
                    {template?.data?.components.map((item: any) => {
                      return (
                        <Menu.Item key={`component-${item.id}`} onClick={() => onComponentSelectHandler('component', item.id)}>
                          <div className={styles.menuItemContainer}>
                            {item.name}
                            <Popconfirm
                              title='Are you sure delete this template component?'
                              onConfirm={(e) => doConfirmComponentDelete(e, item.id)}
                              onCancel={(e) => doCancel(e)}
                              okText='Yes'
                              cancelText='No'
                            >
                              <DeleteOutlined />
                            </Popconfirm>
                          </div>
                        </Menu.Item>
                      )
                    })}
                  </Menu.SubMenu>

                  <Menu.Item
                    key='dependencies'
                    onClick={() => {
                      setActiveComponent('dependencies')
                      setActiveMenuItem(['dependencies'])
                    }}
                  >
                    Dependencies
                  </Menu.Item>
                </>
              )}

              <Menu.Item
                key='settings'
                onClick={() => {
                  setActiveComponent('settings')
                  setActiveMenuItem(['settings'])
                }}
              >
                Settings
              </Menu.Item>

              <Menu.SubMenu key='pages' title='Pages'>
                <Menu.Item
                  key='4'
                  style={{ color: '#1890ff' }}
                  onClick={() => {
                    setActiveComponent('createPage')
                    setIsCreateTemplatePageVisible(!isCreateTemplatePageVisible)
                  }}
                >
                  + ADD PAGE
                </Menu.Item>
                {template?.data?.pages.map((item: any) => {
                  return (
                    <Menu.Item key={`page-${item.id}`} onClick={() => onPageSelectHandler('page', item.id)}>
                      <div className={styles.menuItemContainer}>
                        {item.name}
                        <Popconfirm
                          title='Are you sure delete this template page?'
                          onConfirm={(e) => doConfirmPageDelete(e, item.id)}
                          onCancel={(e) => doCancel(e)}
                          okText='Yes'
                          cancelText='No'
                        >
                          <DeleteOutlined />
                        </Popconfirm>
                      </div>
                    </Menu.Item>
                  )
                })}
              </Menu.SubMenu>

              <Menu.SubMenu key='autoresponders' title='Autoresponders'>
                <Menu.Item
                  key='5'
                  style={{ color: '#1890ff' }}
                  onClick={() => {
                    setActiveComponent('createAutoresponder')
                    setIsCreateAutoresponderVisible(!isCreateAutoresponderVisible)
                  }}
                >
                  + ADD AUTORESPONDER
                </Menu.Item>
                {template?.data?.autoresponders.map((item: Autoresponder) => {
                  return (
                    <Menu.Item key={`autoresponder-${item.id}`} onClick={() => onAutoresponderSelectedHandler('autoresponder', item.id)}>
                      <div className={styles.menuItemContainer}>
                        {item.autoresponderType}
                        <Popconfirm
                          title='Are you sure delete this template autoresponder?'
                          onConfirm={(e) => doConfirmAutoresponderDelete(e, item.id)}
                          onCancel={(e) => doCancel(e)}
                          okText='Yes'
                          cancelText='No'
                        >
                          <DeleteOutlined style={{ marginLeft: '15px' }} />
                        </Popconfirm>
                      </div>
                    </Menu.Item>
                  )
                })}
              </Menu.SubMenu>

              {!isSublander && (
                <Menu.SubMenu key='builds' title='Builds'>
                  {template?.data?.version && !template?.data?.builds?.some((build) => build.version === template?.data?.version) && (
                    <Menu.Item
                      key={`build-${template?.data?.version}`}
                      onClick={() => onBuildVersionSelectHandler('builds', template?.data?.version)}
                    >
                      <div className={styles.menuItemContainer}>{template?.data?.version}</div>
                    </Menu.Item>
                  )}
                  {template?.data?.builds?.map((build: TemplateBuild) => {
                    return (
                      <Menu.Item key={`build-${build.version}`} onClick={() => onBuildVersionSelectHandler('builds', build.version)}>
                        <div className={styles.menuItemContainer}>{build.version}</div>
                      </Menu.Item>
                    )
                  })}
                </Menu.SubMenu>
              )}
            </Menu>

            {renderActiveComponent()}
            <CreateComponent
              ownerType='TEMPLATE'
              ownerId={+templateId}
              isVisible={isCreateComponentVisible}
              onClose={() => setIsCreateComponentVisible(false)}
              onComponentCreated={(newComponentId) => onComponentSelectHandler('component', newComponentId)}
            />
            <CreatePage
              ownerType='TEMPLATE'
              ownerId={+templateId}
              isVisible={isCreateTemplatePageVisible}
              onClose={() => setIsCreateTemplatePageVisible(false)}
              onPageCreated={(newPageId) => onPageSelectHandler('page', newPageId)}
            />
            <CreateAutoresponder
              ownerType='TEMPLATE'
              ownerId={+templateId}
              isVisible={isCreateAutoresponderVisible}
              onClose={() => setIsCreateAutoresponderVisible(false)}
              onAutoresponderCreated={(newAutoresponderId) => {
                onAutoresponderSelectedHandler('autoresponder', newAutoresponderId)
              }}
            />
          </div>
        )}
      </Layout.Content>
    </Layout>
  )
}

export default connector(TemplateDetails)
