import axios from 'utils/axios'
import { ProductPriceOwner, ProductPriceInputV2Dto, PublicProductVariantValuesV2Dto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Product } from 'features/types'
import * as eternalListsFeature from 'features/eternalLists'
import * as t from '../actionTypes'
import { GraphQLProduct, GraphQLProductInput } from '../types'

type Payload = {
  list: 'products'
  items: Product[]
}

type CreateProductArgs = {
  productData: GraphQLProductInput
  productPriceOwnerType: ProductPriceOwner
  productPriceOwnerId: number
  productPricesData: ProductPriceInputV2Dto[]
  variantValues: PublicProductVariantValuesV2Dto[]
  disabledVariantKeys: string[]
  removedVariantKeys: string[]
  mainPriceVariant?: string
}

export type CreateProductAction = FSA<undefined, Payload, string>
type CreateProduct = (
  args: CreateProductArgs
) => MrxThunk<eternalListsFeature.actions.CreateEternalItemsAction<'products'>, Promise<Product | undefined>>
type QueryResponse = GraphQLResponse<'createProduct', GraphQLProduct>

const CREATE_PRODUCT = `
  mutation CreateProduct($productData: ProductInput!, $productPriceOwnerType: ProductPriceOwner!, $productPriceOwnerId: Int!, $productPricesData: [ProductPriceInput]!, $variantValues: [ProductVariantValuesV2Input], $mainPriceVariant: String) {
    createProduct(productData: $productData, productPriceOwnerType: $productPriceOwnerType, productPriceOwnerId: $productPriceOwnerId, productPricesData: $productPricesData, variantValues: $variantValues, mainPriceVariant: $mainPriceVariant) {
      id
      campaignId
      campaign {
        name
      }
      storeId
      storeName
      productType
      name
      displayName
      description
      sku
      tags
      image
      images {
        id
        workspaceId
        name
        extension
        productId
        imageUrl
        s3bucket
        s3key
        createdAt
        updatedAt
      }
      assets {
        id
        name
        extension
        ownerType
        ownerId
        mimeType
        s3bucket
        s3key
        signedUrl
        createdAt
        updatedAt
      }
      ownerId
      ownerType
      owner {
        id
        firstName
        lastName
        email
        createdFromLandingUrl
        createdAt
        updatedAt
      }
      hasCustomShipping
      customShippingPrice

      variantValues {
        variantOption
        values
        valueObjects {
          id
          value
          assets {
            id
            name
            extension
            ownerType
            ownerId
            mimeType
            s3bucket
            s3key
            signedUrl
            createdAt
            updatedAt
          }
        }
        isMainOption
      }

      useReplacementForStock
      stockProductId
      stockProductName
      sellWhenOutOfStock
      
      productType2
      isActive
      customizable
      sortIndex
      createdAt
      updatedAt
    }
  }
`

const createProduct: CreateProduct =
  ({ productData, productPriceOwnerType, productPriceOwnerId, productPricesData, variantValues, mainPriceVariant }) =>
  async (dispatch) => {
    let isSaved

    dispatch({
      type: t.CREATE_PRODUCT,
      meta: { done: false }
    })

    try {
      const {
        data: { data, errors }
      }: QueryResponse = await axios.post('/graphql', {
        query: CREATE_PRODUCT,
        variables: { productData, productPriceOwnerType, productPriceOwnerId, productPricesData, variantValues, mainPriceVariant }
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { createProduct } = data
      if (!createProduct) {
        throw new Error("Can't create product!")
      }

      dispatch({
        type: t.CREATE_PRODUCT,
        payload: { list: 'products', items: [createProduct] },
        meta: { done: true }
      })
      dispatch({
        type: eternalListsFeature.actionTypes.CREATE_ETERNAL_LIST_ITEMS,
        payload: { list: 'products', items: [createProduct] },
        meta: { done: true }
      })
      isSaved = createProduct
    } catch (err) {
      dispatch({
        type: t.CREATE_PRODUCT,
        payload: extractErrorInfo(err) as string,
        error: true
      })
    }

    return isSaved
  }

export { createProduct }
