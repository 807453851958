import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import useReactRouter from 'use-react-router'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Table, Layout, PageHeader, Popconfirm, Input } from 'antd'
import { DeleteTwoTone } from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table'
import { DomainDto } from '@merchx-v2/shared-types'
import { GetComponentProps } from 'rc-table/lib/interface'
import notification from 'mrx-notification'
import { Breadcrumbs } from 'components'
import { workspaceSelector } from 'features/workspaces/selectors'
import { Workspace } from 'features/workspaces/types'

import { useDomainsList, useRemoveDomain } from '../../hooks'
import CreateDomain from '../CreateDomain'
import DomainDetails from '../DomainDetails'
import * as rootStyles from 'assets/layoutStyle'
import styles from './DomainsList.module.scss'

type Props = {
  showHeader?: boolean
  workspace: Workspace
}

const DomainsList = (props: Props) => {
  const { showHeader = true, workspace } = props
  const [selectedDomainId, setSelectedDomainId] = useState<number>()
  const [searchText, setSearchText] = useState<string>()

  const { history } = useReactRouter()

  const removeDomain = useRemoveDomain()
  const domainsList = useDomainsList(workspace?.id ? +workspace.id : undefined, searchText)
  const domains: DomainDto[] = []
  domainsList && domainsList.data && domainsList.data.pages.forEach((page) => page.items.forEach((domain) => domains.push(domain)))

  const page = domainsList.data?.pages.length ? domainsList.data?.pages[domainsList.data?.pages.length - 1].currentPage : 1
  const size = domainsList.data?.pages.length ? domainsList.data?.pages[domainsList.data?.pages.length - 1].pageSize : 30

  useEffect(() => {
    if (domainsList.error) {
      notification.error({
        message: 'Domains List page error!',
        description: domainsList.error instanceof Error ? domainsList.error.message : domainsList.error.toString()
      })
    }
  }, [domainsList.error])

  useEffect(() => {
    if (removeDomain.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Domain was removed successfully!'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeDomain.isSuccess])

  const doConfirm = async (domainId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    removeDomain.mutate(domainId)
  }

  const handleOnRow: GetComponentProps<DomainDto> = (record) => ({
    onClick: () => {
      setSelectedDomainId(record.id)
    }
  })

  const handleOnDomainClose = () => {
    setSelectedDomainId(null)
  }

  const onSearchInputChangeHandler = (value) => {
    setSearchText(value)
  }

  const columns: ColumnsType<DomainDto> = [
    {
      title: 'Domain',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '7%',
      align: 'center',
      render: (_value, record) => (
        <span>
          <Popconfirm
            title='Are you sure delete this domain?'
            onConfirm={(e) => doConfirm(record.id, e)}
            onCancel={(e) => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <DeleteTwoTone
              twoToneColor='#ec1c24'
              style={{ fontSize: '20px' }}
              onClick={(e) => {
                e.stopPropagation()
              }}
            />
          </Popconfirm>
        </span>
      )
    }
  ]

  return (
    <Layout style={rootStyles.root}>
      {showHeader && (
        <>
          <Layout.Header style={rootStyles.header}>
            <PageHeader
              onBack={() => history.goBack()}
              title='Domains List'
              extra={[
                <Input.Search
                  key='search-field'
                  placeholder='Input search text'
                  onSearch={(value) => onSearchInputChangeHandler(value)}
                  style={{ width: 200 }}
                  allowClear
                />,
                <CreateDomain key='1' />
              ]}
            />
          </Layout.Header>
          <Layout.Content style={rootStyles.contentBreadcrumbs}>
            <Breadcrumbs
              items={[
                { title: 'Main', url: '/' },
                { title: 'Campaigns', url: '/' },
                { title: 'Domains', url: '/' }
              ]}
            />
          </Layout.Content>
        </>
      )}
      <Layout.Content className={showHeader && styles.contentComponent}>
        <DomainDetails domainId={selectedDomainId} onClose={handleOnDomainClose} />
        <InfiniteScroll
          style={{ paddingBottom: '30px' }}
          dataLength={page * size}
          next={domainsList.fetchNextPage}
          hasMore={!!domainsList.hasNextPage}
          loader={<h4>Loading...</h4>}
          refreshFunction={domainsList.refetch}
          scrollThreshold={0.8}
        >
          <Table
            columns={columns}
            dataSource={domains.map((item) => item).flat()}
            bordered
            onRow={handleOnRow}
            rowKey={(record) => record.id}
            loading={domainsList.isLoading}
            pagination={false}
          />
        </InfiniteScroll>
      </Layout.Content>
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  workspace: workspaceSelector(state)
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DomainsList)
