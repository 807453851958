import { createSelector } from 'reselect'

const getSimpleTextSettingsOptions: SelectOptionsSelector = (state) =>
  state.simpleTextSettings.simpleTextSettingsForOptions.map((item) => ({
    id: item.id,
    name: item.name
  })) || ([] as SelectOption[])

export const simpleTextSettingsSelectOptionsSelector: SelectOptionsSelector = createSelector(
  getSimpleTextSettingsOptions,
  (options) => options
)
