// TODO - remove becouse of legacy

import React, { useEffect } from 'react'
import useReactRouter from 'use-react-router'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button, DatePicker, PageHeader, Table, Layout } from 'antd'
import notification from 'mrx-notification'
import { ColumnsType } from 'antd/lib/table'
import { Breadcrumbs } from 'components'
import CreateAdSpend from '../CreateAdSpend'
import * as rootStyles from 'assets/layoutStyle'
import { connector, PropsFromRedux } from './container'
import { AdSpendRow } from '../../types'

const dateFormat = 'MM/DD/YYYY'

const columns: ColumnsType<AdSpendRow> = [
  {
    title: '#',
    dataIndex: ['campaign', 'id'],
    key: '#'
  },
  {
    title: 'Campaign',
    dataIndex: ['campaign', 'name'],
    key: 'campaign',
    sorter: (a, b) => (a.campaign.name.toLowerCase() < b.campaign.name.toLowerCase() ? -1 : 1),
    defaultSortOrder: 'ascend',
    sortDirections: ['descend', 'ascend']
  },
  {
    title: 'Ad Spend',
    render: (record) => `$${record.sumOfExpenses.toFixed(2)}`,
    key: 'adSpend',
    sorter: (a, b) => a.sumOfExpenses - b.sumOfExpenses,
    sortDirections: ['descend', 'ascend']
  },
  {
    title: 'Clicks',
    dataIndex: 'numberOfClicks',
    key: 'clicks',
    sorter: (a, b) => a.numberOfClicks - b.numberOfClicks,
    sortDirections: ['descend', 'ascend']
  }
]

const AdSpendList: React.FC<PropsFromRedux> = ({ adSpendsRows, isLoading, error, dateList, changeDate, fetchAdSpends }) => {
  const { history } = useReactRouter()

  useEffect(() => {
    !adSpendsRows && fetchAdSpends()
  }, [adSpendsRows, fetchAdSpends])

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Ad Spend list page error!',
        description: error
      })
    }
  }, [error])

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title='Ad Spend List'
          extra={[
            <DatePicker key={dateList.toString()} format={dateFormat} value={dateList} onChange={changeDate} allowClear={false} />,
            <Button key='2' disabled={isLoading} onClick={() => changeDate(dateList.subtract(1, 'day'))}>
              <LeftOutlined />
              Prev
            </Button>,
            <Button key='3' disabled={isLoading} onClick={() => changeDate(dateList.add(1, 'day'))}>
              Next
              <RightOutlined />
            </Button>,
            <CreateAdSpend key='4' />
          ]}
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Reporting', url: '/' },
            { title: 'Ad Spend List', url: '/reporting/adspends/list' }
          ]}
        />
      </Layout.Content>
      <Layout.Content style={rootStyles.contentComponent}>
        <Table
          columns={columns}
          dataSource={adSpendsRows}
          bordered
          pagination={false}
          rowKey={(record) => record.campaign.id}
          loading={isLoading}
        />
      </Layout.Content>
    </Layout>
  )
}

export default connector(AdSpendList)
