import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { AwsSetting } from '../types'

type AwsSettingsListSelector = (state: ReduxState) => AwsSetting[]
const getAwsSettingsList: AwsSettingsListSelector = state => {
  const result = [] as AwsSetting[]
  const pageIds = state.awsSettings.pagination.pages[state.awsSettings.pagination.currentPage] || []
  pageIds.forEach(pageId => {
    result.push(state.awsSettings.awsSettings[pageId])
  })
  return result
}

export const awsSettingsListSelector: AwsSettingsListSelector = createSelector(getAwsSettingsList, list => list)
