import axios from 'utils/shoppingCartAxios'
import backendAxios from 'utils/axios'
import { useQuery } from 'react-query'
import { CrmPromocodeDto, PromocodeDto } from '@merchx-v2/shared-types'
import { extractResponseError, guardFromErrors } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'promocode', CrmPromocodeDto>

// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

const FETCH_PROMOCODE = `
  query getPromocode($code: String!) {
    promocode(code: $code) {
      id
      code
      assets {
        id
        name
        extension
        ownerType
        ownerId
        mimeType
        s3bucket
        s3key
        signedUrl
        createdAt
        updatedAt
      }
    }
  }
`

type FetchPromocode = (promocode?: string) => Promise<{ promocode: PromocodeDto; promocodePostgres: CrmPromocodeDto }>

const fetchPromocode: FetchPromocode = async (code) => {
  if (!code) return

  try {
    const response = await axios.get(`/promocodes/${code}`)

    const existedPromocode: PromocodeDto = response.data

    if (!existedPromocode) {
      throw new Error('Response body is empty!')
    }

    const {
      data: { data, errors }
    }: QueryResponse = await backendAxios.post('/graphql', {
      query: FETCH_PROMOCODE,
      variables: {
        code
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { promocode: existedPromocodePostgres } = data
    if (!existedPromocodePostgres) {
      throw new Error("Can't get promocode!")
    }

    return { promocode: existedPromocode, promocodePostgres: existedPromocodePostgres }
  } catch (error) {
    extractResponseError(error)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const usePromocode = (promocode: string) => useQuery(['promocode', promocode], () => fetchPromocode(promocode))
