import React, { useContext, useEffect } from 'react'
import { InventoryDto } from '@merchx-v2/shared-types'
import notification from 'mrx-notification'
import { GlobalContext } from 'appContexts'
import { useAddVariantItemsToStock, useInventoryList, useSetVariantItemsOnStock } from '../../hooks'
import InventoryList from '../InventoryList'

type Props = {
  disabled: boolean
  productId?: number
}

const ProductInventory = (props: Props) => {
  const { campaignId } = useContext(GlobalContext)

  const addVariantItemsToStock = useAddVariantItemsToStock()
  const setVariantItemsOnStock = useSetVariantItemsOnStock()
  const inventoryList = useInventoryList({ campaignId, productId: props.productId })

  let inventory: InventoryDto[] = []
  inventoryList && inventoryList.data && inventoryList.data.pages.forEach((page) => page.items.forEach((order) => inventory.push(order)))
  inventory = inventory.flat()

  const page = inventoryList.data?.pages.length ? inventoryList.data?.pages[inventoryList.data?.pages.length - 1].currentPage : 1
  const size = inventoryList.data?.pages.length ? inventoryList.data?.pages[inventoryList.data?.pages.length - 1].pageSize : 30

  useEffect(() => {
    if (inventoryList.error) {
      notification.error({
        message: 'Inventory list error!',
        description: inventoryList.error instanceof Error ? inventoryList.error.message : inventoryList.error.toString()
      })
    }
  }, [inventoryList.error])

  useEffect(() => {
    if (addVariantItemsToStock.error) {
      notification.error({
        message: 'Add variant items to stock error!',
        description:
          addVariantItemsToStock.error instanceof Error ? addVariantItemsToStock.error.message : addVariantItemsToStock.error.toString()
      })
    }
  }, [addVariantItemsToStock.error])

  useEffect(() => {
    if (setVariantItemsOnStock.error) {
      notification.error({
        message: 'Set variant items to stock error!',
        description:
          setVariantItemsOnStock.error instanceof Error ? setVariantItemsOnStock.error.message : setVariantItemsOnStock.error.toString()
      })
    }
  }, [setVariantItemsOnStock.error])

  const handleMessage = (message: string) => {
    notification.info({
      message: 'Inventory list',
      description: message
    })
  }

  return (
    <InventoryList
      inventory={inventory}
      disabled={props.disabled}
      dataLength={page * size}
      isLoading={
        inventoryList.isLoading || inventoryList.isFetching || addVariantItemsToStock.isLoading || setVariantItemsOnStock.isLoading
      }
      hasNextPage={!!inventoryList.hasNextPage}
      fetchNextPage={inventoryList.fetchNextPage}
      refetch={inventoryList.refetch}
      onAddVariantItemsToStock={(productId: number, variantKey: string, quantity: number) =>
        addVariantItemsToStock.mutate({ campaignId, productId, variantKey, quantity })
      }
      onSetVariantItemsOnStock={(productId: number, variantKey: string, quantity: number) =>
        setVariantItemsOnStock.mutate({ campaignId, productId, variantKey, quantity })
      }
      onMessage={handleMessage}
    />
  )
}

export default ProductInventory
