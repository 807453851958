import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

export type RemoveDTGSizeAction = FSA<undefined, number, string>
type RemoveDTGSize = (DTGSize: number) => MrxThunk<RemoveDTGSizeAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'removeDTGSize', boolean>

const REMOVE_DTG_SIZE = `
  mutation removeDTGSize ($DTGSizeId: Int!) {
    removeDTGSize(DTGSizeId: $DTGSizeId) 
  }
`

const removeDTGSize: RemoveDTGSize = (DTGSizeId) => async (dispatch) => {
  let isRemoved = false

  dispatch({
    type: t.REMOVE_DTG_SIZE,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_DTG_SIZE,
      variables: { DTGSizeId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Empty response!')
    }

    const { removeDTGSize } = data
    if (!removeDTGSize) {
      throw new Error("Can't remove DTGSize!")
    }

    dispatch({
      type: t.REMOVE_DTG_SIZE,
      payload: DTGSizeId,
      meta: { done: true }
    })

    isRemoved = true
  } catch (err) {
    dispatch({
      type: t.REMOVE_DTG_SIZE,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isRemoved
}

export { removeDTGSize }
