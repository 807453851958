import React, { useState } from 'react'
import * as navigation from '../../features/navigation'
import { Layout } from 'antd'

const {
  components: { Navigation }
} = navigation

type Props = {
  children: React.ReactNode
}

const MainLayout: React.FC<Props> = props => {
  const [showCampaigns, setShowCampaigns] = useState(false)
  const [isCollapsed, setCollapsed] = useState(false)

  const onShowCampaignsChangeHandler = () => {
    setShowCampaigns(!showCampaigns)
  }

  const onCollapsedHandler = () => {
    setCollapsed(!isCollapsed)
  }

  const campaignsWidth = showCampaigns ? 50 : 0
  const siderWidth = isCollapsed ? 80 : 220
  const layoutWidth = campaignsWidth + siderWidth

  return (
    <Layout style={{ height: '100%', display: 'flex', flex: 1, marginLeft: `${layoutWidth}px` }}>
      <Navigation
        showCampaigns={showCampaigns}
        onShowCampaignsChange={onShowCampaignsChangeHandler}
        isCollapsed={isCollapsed}
        onCollapsed={onCollapsedHandler}
      />
      <div style={{ display: 'flex', flex: '1' }}>{props.children}</div>
    </Layout>
  )
}

export default MainLayout
