import { createSelector, Selector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { AuthenticationState, User } from 'features/authentication/types'

const userStateSelector: Selector<ReduxState, AuthenticationState> = state => state.authentication

export const websocketUserStateSelector = createSelector<ReduxState, AuthenticationState, User | null>(
  userStateSelector,
  state => state.user
)
