import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type isLoadingKeyType =
  | 'isCreateWorkspace'
  | 'isWorkspaceLoading'
  | 'isWorkspaceListLoading'
  | 'isInviteUser'
  | 'isRemoveUser'
  | 'isSwitchWorkspace'
  | 'isUpdateWorkspace'
  | 'isUserEmailValidating'

type IsLoadingSelector = (state: ReduxState, isLoadingKey: isLoadingKeyType) => boolean

const getIsLoadingSelectorByKey = (state: ReduxState, isLoadingKey: isLoadingKeyType) => state.workspaces.UIState[isLoadingKey] || false

export const isLoadingSelector: IsLoadingSelector = createSelector(getIsLoadingSelectorByKey, isLoading => isLoading)
