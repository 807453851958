import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Payment } from '../types'

export type RefundPaymentInput = {
  paymentId: number
  refundPaymentData: {
    amount: number
    comment?: string
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'refundPayment', Payment>

const REFUND_PAYMENT = `
  mutation RefundPayment($paymentId: Int!, $refundPaymentData: RefundPaymentDataInput!) {
    refundPayment(paymentId: $paymentId, refundPaymentData: $refundPaymentData) {
      id
      status
      systemType
      paymentMethod
      externalTransactionId
      total
      orderId
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type RefundPayment = (args: RefundPaymentInput) => Promise<RefundPaymentInput>

const refundPayment: RefundPayment = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REFUND_PAYMENT,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { refundPayment } = data
    if (!refundPayment) {
      throw new Error("Can't update order tracking number!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type RefundPaymentContext = { prevPayment?: Payment }

type RefundPaymentEvents = {
  onMutate: (variables: RefundPaymentInput) => Promise<RefundPaymentContext>
  onError: (error: string, variables: RefundPaymentInput, context: RefundPaymentContext) => void
  onSettled: (data?: RefundPaymentInput) => void
}

const refundPaymentEvents: RefundPaymentEvents = {
  onMutate: async (variables: RefundPaymentInput) => {
    await queryClient.cancelQueries(['refundPayment', variables.paymentId])

    // Snapshot the previous value
    const prevPayment = queryClient.getQueryData<Payment>(['refundPayment', variables.paymentId])

    // Optimistically update to the new values
    if (prevPayment) {
      queryClient.setQueryData<Payment>(['refundPayment', variables.paymentId], {
        ...prevPayment,
        id: variables.paymentId,
        ...variables
      })
    }

    return { prevPayment }
  },
  onError: (_err, variables, context) => {
    if (context?.prevPayment) {
      // Restore previous version of payment on any error
      queryClient.setQueryData<any>(['refundPayment', variables.paymentId], context.prevPayment)
    }
  },
  onSettled: (data) => {
    if (data?.paymentId) {
      queryClient.invalidateQueries(['refundPayment', data.paymentId])
      queryClient.invalidateQueries(['order'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useRefundPayment = () => useMutation(refundPayment, refundPaymentEvents)
