import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { ProcessingCostInput, ProcessingCost } from '../types'

export type SaveProcessingCostAction = FSA<undefined, ProcessingCost, string>
type saveProcessingCost = (processingCostData: ProcessingCostInput) => MrxThunk<SaveProcessingCostAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'saveProcessingCost', ProcessingCost>

const SAVE_PROCESSING_COST = `
mutation SaveProcessingCost($workspaceId: Int!, $processingCostData: ProcessingCostInput!) {
  saveProcessingCost(workspaceId: $workspaceId, processingCostData: $processingCostData) {
    id
    paymentPlugin
    date
    rows {
      productTag {
        id
        name
      }
      value
      accuracy
    }
  }
}
`

export const saveProcessingCost: saveProcessingCost = (processingCostData) => async (dispatch, getState) => {
  let isSaved = false

  dispatch({
    type: t.SAVE_PROCESSING_COST,
    meta: { done: false }
  })

  try {
    const workspaceId = getState()?.workspaces?.activeWorkspace?.id

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: SAVE_PROCESSING_COST,
      variables: { workspaceId, processingCostData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { saveProcessingCost } = data
    if (!saveProcessingCost) {
      throw new Error("Can't create processing cost!")
    }

    dispatch({
      type: t.SAVE_PROCESSING_COST,
      payload: saveProcessingCost,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.SAVE_PROCESSING_COST,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isSaved
}
