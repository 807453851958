// TODO - remove becouse of legacy

import React from 'react'
import { CreateQuizResultDto } from '@merchx-v2/shared-types'
import { Input, Typography } from 'antd'
import styles from './EditResultCorrects.module.scss'

type Props = {
  result: CreateQuizResultDto
  disabled?: boolean
  onCountOfCorrectsChanged: (corrects: number) => void
}

const EditResultCorrects = (args: Props) => {
  const { result, disabled, onCountOfCorrectsChanged } = args

  return (
    <div className={styles.root}>
      <div className={styles.labeledInput}>
        <Typography style={{ color: 'black', paddingBottom: '8px' }}>Count of corrects for {result.result}</Typography>
        <div className={styles.fieldContainer}>
          <Input
            value={result.countOfCorrects || 0}
            type='number'
            onChange={(e) => onCountOfCorrectsChanged(+e.target.value)}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  )
}

export default EditResultCorrects
