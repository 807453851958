import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { DTGSize } from '../types'

export type CreateDTGSizeAction = FSA<undefined, DTGSize, string>
type CreateDTGSize = (domainData: DTGSizeInput) => MrxThunk<CreateDTGSizeAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'createDTGSize', DTGSize>

const CREATE_DTG_SIZE = `
  mutation createDTGSize ($DTGSizeData: DTGSizeInput!) {
    createDTGSize(DTGSizeData: $DTGSizeData) {
      id
      name
      DTGProductId
      DTGProduct {
        name
      }
      size
      DTGSizeId
      createdAt
      updatedAt
    }
  }
`
type DTGSizeInput = {
  name: string
  size: string
  DTGProductId: number
  DTGSizeId: number
}

const createDTGSize: CreateDTGSize = DTGSizeData => async dispatch => {
  let isSaved = false

  dispatch({
    type: t.CREATE_DTG_SIZE,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_DTG_SIZE,
      variables: { DTGSizeData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createDTGSize } = data
    if (!createDTGSize) {
      throw new Error("Can't create DTGSize!")
    }

    dispatch({
      type: t.CREATE_DTG_SIZE,
      payload: createDTGSize,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.CREATE_DTG_SIZE,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { createDTGSize }
