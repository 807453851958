import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { DTGSize } from '../types'

type DTGSizesForOptionsSelector = (state: ReduxState) => DTGSize[]
const getDTGSizesForOptions: DTGSizesForOptionsSelector = state =>
  state.DTGSizes.DTGSizesForOptions || ([] as DTGSize[])

export const DTGSizeForOptionsSelector: DTGSizesForOptionsSelector = createSelector(
  getDTGSizesForOptions,
  DTGSizes => DTGSizes
)
