import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { ZipwhipSetting } from '../types'

type ZipwhipSettingsList = {
  items: ZipwhipSetting[]
  total: number
}

type Payload = ZipwhipSettingsList & {
  page: number
}

export type FetchZipwhipSettingsListAction = FSA<undefined, Payload, string>
type FetchZipwhipSettingsList = () => MrxThunk<FetchZipwhipSettingsListAction>
type QueryResponse = GraphQLResponse<'zipwhipSettingsListPage', ZipwhipSettingsList>

const FETCH_ZIPWHIP_SETTINGS_LIST = `
  query fetchZipwhipSettingsList ($page: Int, $size: Int, $workspaceId: Int!) {
    zipwhipSettingsListPage (page: $page, size: $size, workspaceId: $workspaceId) {
      items {
        id
        name
        username
        password
      }
      total
    }
  }
`

const fetchZipwhipSettingsList: FetchZipwhipSettingsList = () => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_ZIPWHIP_SETTINGS_LIST,
    meta: { done: false }
  })

  try {
    const {
      workspaces: {
        activeWorkspace: { id: workspaceId }
      },
      zipwhipSettings: {
        pagination: { currentPage: page, size }
      }
    } = getState()
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_ZIPWHIP_SETTINGS_LIST,
      variables: { page, size, workspaceId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { zipwhipSettingsListPage } = data
    if (!zipwhipSettingsListPage) {
      throw new Error("Can't get Zipwhip Settings list page!")
    }

    dispatch({
      type: t.FETCH_ZIPWHIP_SETTINGS_LIST,
      payload: {
        page,
        total: zipwhipSettingsListPage.total,
        items: zipwhipSettingsListPage.items
      },
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_ZIPWHIP_SETTINGS_LIST,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchZipwhipSettingsList }
