import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type TotalInProgressTasksSelector = (state: ReduxState) => number
const getTotalInProgressTasks: TotalInProgressTasksSelector = state => state.tasksQueue.totalInProgressTasks

export const totalInProgressTasksSelector: TotalInProgressTasksSelector = createSelector(
  getTotalInProgressTasks,
  totalInProgress => totalInProgress
)
