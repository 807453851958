import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { CreateShippingRuleActionDto, ShippingRuleActionDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'updateShippingRuleAction', ShippingRuleActionDto>

const UPDATE_SHIPPING_RULE_ACTION = `
  mutation updateShippingRuleAction ($shippingRuleActionId: Int!, $shippingRuleActionData: ShippingRuleActionInput!) {
    updateShippingRuleAction(shippingRuleActionId: $shippingRuleActionId, shippingRuleActionData: $shippingRuleActionData) {
      id
      name
      isActive
      campaignId
      actionType
      actionPayload
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateShippingRuleActionArgs = {
  shippingRuleActionId: number
  shippingRuleActionData: CreateShippingRuleActionDto
}

type UpdateShippingRuleAction = (args: UpdateShippingRuleActionArgs) => Promise<UpdateShippingRuleActionArgs>

const updateShippingRuleAction: UpdateShippingRuleAction = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_SHIPPING_RULE_ACTION,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateShippingRuleAction } = data
    if (!updateShippingRuleAction) {
      throw new Error("Can't update shippingRuleAction!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateShippingRuleActionContext = { prevShippingRuleAction?: ShippingRuleActionDto }

type UpdateShippingRuleActionEvents = {
  onMutate: (variables: UpdateShippingRuleActionArgs) => Promise<UpdateShippingRuleActionContext>
  onError: (error: string, variables: UpdateShippingRuleActionArgs, context: UpdateShippingRuleActionContext) => void
  onSettled: (data?: UpdateShippingRuleActionArgs) => void
}

const updateShippingRuleActionEvents: UpdateShippingRuleActionEvents = {
  onMutate: async (variables: UpdateShippingRuleActionArgs) => {
    await queryClient.cancelQueries(['shippingRuleAction', variables.shippingRuleActionId])

    // Snapshot the previous value
    const prevShippingRuleAction = queryClient.getQueryData<ShippingRuleActionDto>(['shippingRuleAction', variables.shippingRuleActionId])

    // Optimistically update to the new values
    if (prevShippingRuleAction) {
      queryClient.setQueryData<ShippingRuleActionDto>(['shippingRuleAction', variables.shippingRuleActionId], {
        ...prevShippingRuleAction,
        id: variables.shippingRuleActionId,
        ...variables.shippingRuleActionData
      })
    }

    return { prevShippingRuleAction }
  },
  onError: (_err, variables, context) => {
    if (context?.prevShippingRuleAction) {
      // Restore previous version of country on any error
      queryClient.setQueryData<ShippingRuleActionDto>(['shippingRuleAction', variables.shippingRuleActionId], context.prevShippingRuleAction)
    }
  },
  onSettled: (data) => {
    if (data?.shippingRuleActionId) {
      queryClient.invalidateQueries(['shippingRuleAction', data.shippingRuleActionId])
      queryClient.invalidateQueries(['shippingRuleActionsList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUpdateShippingRuleAction = () => useMutation(updateShippingRuleAction, updateShippingRuleActionEvents)

