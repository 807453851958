import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Region } from '../types'

type Payload = {
  countryId: number
  region: Region
}

export type FetchRegionAction = FSA<undefined, Payload, string>
type FetchRegion = (countryId: number, regionId: number) => MrxThunk<FetchRegionAction>
type QueryResponse = GraphQLResponse<'region', Region>

const FETCH_REGION = `
  query fetchRegion ($regionId: Int!) {
    region (regionId: $regionId) {
      id
      name
      iso2
      countryId
      country {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`

const fetchRegion: FetchRegion = (countryId, regionId) => async (dispatch) => {
  dispatch({
    type: t.FETCH_REGION,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_REGION,
      variables: { regionId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { region } = data
    if (!region) {
      throw new Error("Can't get region!")
    }

    dispatch({
      type: t.FETCH_REGION,
      payload: {
        countryId,
        region
      },
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_REGION,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchRegion }
