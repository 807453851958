// TODO - remove becouse of legacy

import { connect, ConnectedProps } from 'react-redux'
import { subscribeToChannel, unsubscribeFromChannel } from 'features/websocket/actions'

type OwnProps = {
  match: {
    params: { templateId: string }
  }
}

const mapStateToProps = () => {}

const mapDispatchToProps = {
  subscribeToChannel,
  unsubscribeFromChannel
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
