import React, { useState } from 'react'
import { Table, Popconfirm, Typography, Modal, Card, Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import notification from 'mrx-notification'
import { ColumnsType } from 'antd/lib/table'
import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface'
import { SiteType, AssetDto } from '@merchx-v2/shared-types'
import { useRemoveAsset, useUploadAsset } from '../../hooks'
import styles from './AssetsList.module.scss'
import UploadAsset from '../UploadAsset'

type PropsType = {
  assets: AssetDto[]
  ownerType: SiteType
  ownerId: number
}

const { Dragger } = Upload

const AssetsList = (props: PropsType) => {
  const { ownerType, ownerId, assets } = props

  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState<AssetDto>()
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [numberOfUploaded, setNumberOfUploaded] = useState(0)

  const removeAsset = useRemoveAsset()
  const uploadAsset = useUploadAsset()

  const doConfirm = async (assetId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    removeAsset.mutate(
      { ownerType, ownerId, assetId },
      {
        onSuccess: () => {
          notification.success({
            message: 'Successfully',
            description: 'Asset was deleted successfully!'
          })
        },
        onError: (error) => {
          notification.error({
            message: 'Error!',
            description: error.toString()
          })
        }
      }
    )
  }

  const columns: ColumnsType<AssetDto> = [
    {
      title: '#',
      width: '10%',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name: string, record: AssetDto) => `${name}.${record.extension}`
    },
    {
      title: 'Action',
      width: '20%',
      dataIndex: '',
      key: 'x',
      render: (record: AssetDto) => (
        <span>
          <Popconfirm
            title='Are you sure delete this asset?'
            onConfirm={(e) => doConfirm(record.id, e)}
            onCancel={(e) => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <a
              href='/#'
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              Remove
            </a>
          </Popconfirm>
        </span>
      )
    }
  ]

  const handleCancel = () => setPreviewVisible(false)

  const handleOnRow = (record: AssetDto) => {
    return {
      onClick: () => {
        setPreviewImage(record)
        setPreviewVisible(true)
      }
    }
  }

  const handleUploadRequest = async (info: any) => {
    const name = info.file.name.trim().replace(/\s+/g, '_')
    uploadAsset.mutate(
      { ownerType, ownerId, file: info.file, assetData: { name, type: info.file.type, filename: info.file.name, role: 'DEFAULT' } },
      {
        onSuccess: () => {
          info.onSuccess({}, info.file)
          setNumberOfUploaded(numberOfUploaded + 1)
          if (numberOfUploaded === fileList.length) {
            notification.success({
              message: 'Successfully',
              description: fileList.length === 1 ? 'Asset was created successfully!' : 'Assets were created successfully!'
            })
            setNumberOfUploaded(0)
            setFileList([])
          }
        },
        onError: () => {
          info.onError(new Error('Upload failed'))
        }
      }
    )
  }

  const handleUploaderChange = (info: UploadChangeParam) => setFileList(info.fileList)

  return (
    <div className={styles.tableContainer}>
      <Card title={<span className={styles.cardTitle}>Assets</span>} extra={<UploadAsset ownerType={ownerType} ownerId={ownerId} />}>
        <Dragger
          onChange={handleUploaderChange}
          multiple
          className={styles.dragger}
          fileList={fileList}
          name='file'
          accept='image/*, video/*, .js, .css, .scss, .ttf, .wof, .eof'
          customRequest={handleUploadRequest}
        >
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <p className='ant-upload-text'>Click or drag file to this area to upload</p>
        </Dragger>
        <Table
          className={styles.table}
          columns={columns}
          dataSource={assets}
          bordered
          rowKey={(record) => record.id}
          pagination={false}
          onRow={handleOnRow}
        />
        <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
          {previewImage?.mimeType === 'text/javascript' || previewImage?.mimeType === 'text/css' ? (
            <Typography>No preview for js or css file</Typography>
          ) : (
            <img alt='example' style={{ width: '100%' }} src={previewImage?.signedUrl} />
          )}
        </Modal>
      </Card>
    </div>
  )
}

export default AssetsList
