import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as authenticationSelectors from 'features/authentication/selectors'

export type OwnProps = {
  orderId?: number
  source?: 'orders' | 'invoices'
  onClose: () => void
}

const mapStateToProps = (state: ReduxState) => {
  return {
    user: authenticationSelectors.userStateSelector(state)
  }
}

const mapDispatchToProps = {}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
