import React from 'react'
import styles from './EditProductPriceVariant.module.scss'
import ProductPriceInput from '../ProductPriceInput'

type Props = {
  variant?: string
  mainVariant?: string
}

const EditProductPriceVariant = (props: Props) => {
  const { variant, mainVariant } = props

  return (
    <div className={styles.productFormRow}>
      <div className={styles.productFormRowItem}>
        <ProductPriceInput mainVariant={mainVariant} variant={variant} />
      </div>
    </div>
  )
}

export default EditProductPriceVariant
