import React, { useState, useEffect, useRef } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Drawer, Input, Form } from 'antd'
import notification from 'mrx-notification'
import styles from './CreateDTGProduct.module.scss'
import { PropsFromRedux, connector } from './container'

const CreateDTGProduct = (props: PropsFromRedux) => {
  const [isVisible, setVisible] = useState(false)
  const refEl = useRef<Input>(null)

  const { isLoading, createDTGProduct } = props

  useEffect(() => {
    if (isVisible) {
      refEl.current && refEl.current.focus()
    }
  }, [isVisible])

  const showDrawer = () => {
    setVisible(!isVisible)
  }

  const [form] = Form.useForm()

  const handleClose = () => {
    setVisible(false)
    form.resetFields()
  }

  const onFinishHandler = async (values: any) => {
    const isSaved = await createDTGProduct({
      name: values.name,
      DTGProductId: +values.DTGProductId,
      categoryId: +values.categoryId
    })
    if (isSaved) {
      notification.success({
        message: 'Successfully',
        description: 'DTG Product was created successfully!'
      })
      handleClose()
    }
  }

  return (
    <>
      <Button type='primary' onClick={showDrawer}>
        <PlusOutlined />
        CREATE
      </Button>
      <Drawer title='Create DTG Product' width='400' onClose={handleClose} visible={isVisible} forceRender>
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
          <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input DTG Product name!' }]}>
            <Input style={{ width: '100%' }} ref={refEl} />
          </Form.Item>

          <Form.Item
            name='DTGProductId'
            label='DTG Product ID'
            rules={[{ required: true, pattern: /\d/, message: 'Please input Correct DTG Product ID!' }]}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            name='categoryId'
            label='Category ID'
            rules={[{ required: true, pattern: /\d/, message: 'Please input Correct category ID!' }]}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>

          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button onClick={handleClose} className={styles.closeButton}>
              Close
            </Button>
            <Button type='primary' htmlType='submit' loading={isLoading}>
              Create
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

export default connector(CreateDTGProduct)
