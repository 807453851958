import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { CustomersData } from '../types'
import { Moment } from 'moment'

export type FetchCustomersDataReportAction = FSA<undefined, CustomersData[], string>
type FetchCustomersDataReports = (startDate: Moment, endDate: Moment) => MrxThunk<FetchCustomersDataReportAction>
type QueryResponse = GraphQLResponse<'customersDataReport', CustomersData[]>

const FETCH_CUSTOMERS_DATA_REPORT = `
  query fetchCustomersDataReport ($startDate: String!, $endDate: String!, $workspaceId: Int!) {
    customersDataReport(startDate: $startDate, endDate: $endDate, workspaceId: $workspaceId) {
      date
      orderId
      status
      customerName
      customerEmail
      customerPhone
      orderItems
      skus
      subtotal
      shipping
      processing
      total
      paid
      refund
      totalReceived
      campaign
      landing
      mid
      corporation
      saleSource
      shippingCountry
      shippingState
      shippingZipCode
      bin
      cardNumber
      cardExpDate
    }
  }
`

const fetchCustomersDataReport: FetchCustomersDataReports = (startDate, endDate) => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_CUSTOMERS_DATA_REPORT,
    meta: { done: false }
  })

  try {
    const {
      workspaces: {
        activeWorkspace: { id: workspaceId }
      }
    } = getState()
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_CUSTOMERS_DATA_REPORT,
      variables: { startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD'), workspaceId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { customersDataReport } = data
    if (!customersDataReport) {
      throw new Error("Can't get Customers Data report!")
    }

    dispatch({
      type: t.FETCH_CUSTOMERS_DATA_REPORT,
      payload: customersDataReport,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_CUSTOMERS_DATA_REPORT,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchCustomersDataReport }
