import React, { useContext } from 'react'
import { Input, Form, Typography, FormInstance } from 'antd'
import { GlobalContext } from 'appContexts'
import { Product } from 'features/types'
import { ManageProductTags } from 'features/tags/components'
import { StoresSelect } from 'features/stores/components'
import EditProductPriceVariantImage from '../EditProductPriceVariantImage'
import styles from './EditProductInfo.module.scss'
import { QuillEditorAntd } from 'components'

type Props = {
  product?: Product
  form: FormInstance
}

const EditProductInfo = (props: Props) => {
  const { product, form } = props

  const { campaignId } = useContext(GlobalContext)

  return (
    <div className={styles.productFormBlock}>
      <Typography style={{ color: 'black', fontWeight: 'bold', paddingBottom: '8px' }}>Product Name</Typography>
      <div className={styles.productFormRow}>
        <Form.Item
          name='displayName'
          label='Product name'
          rules={[{ required: true, message: 'Please input product  name!' }]}
          className={styles.productFormRowItem}
        >
          <Input />
        </Form.Item>
        <div className={styles.productFormRowItem}>
          <Typography style={{ color: 'black', paddingBottom: '8px' }}>Product Tag</Typography>
          <Form.Item noStyle dependencies={[['tags']]}>
            {(formInstance) => {
              const tags = formInstance.getFieldValue('tags')

              return (
                <ManageProductTags
                  onTagsChanged={(newTagsList) => form.setFieldsValue({ tags: newTagsList })}
                  placeholder='Add a product tag'
                  refreshOnInitTagsChanged
                  campaignId={campaignId}
                  initTags={tags}
                />
              )
            }}
          </Form.Item>
        </div>
      </div>

      <div className={styles.productFormRow}>
        <Form.Item
          name='description'
          label='Description'
          rules={[{ required: true, message: 'Please input product description!' }]}
          className={styles.productFormRowItem}
        >
          <QuillEditorAntd />
        </Form.Item>

        <Form.Item
          name='storeId'
          label='Store'
          rules={[{ required: true, message: 'Please select store!' }]}
          className={styles.productFormRowItem}
        >
          <StoresSelect
            initialOption={product ? { id: product?.storeId, name: product?.storeName } : { id: 0, name: '' }}
            onSelect={(newStore) =>
              form.setFieldsValue({
                storeId: newStore?.id
              })
            }
            allowClear
          />
        </Form.Item>
      </div>

      <div className={styles.productFormRow}>
        <div className={styles.productFormRowImage}>
          <EditProductPriceVariantImage isArtwork={false} />
        </div>
      </div>
    </div>
  )
}

export default EditProductInfo
