import * as t from '../actionTypes'
import cleanAxios from 'axios'
import axios from 'utils/axios'
import { SiteType } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

type Payload = {
  ownerType: SiteType
  ownerId: number
  siteSnapshot: SiteSnapshot
}

type SiteSnapshot = {
  id: number
  name: string
  signedUrl: string
}

export type TakeSiteSnapshotAction = FSA<undefined, Payload, string>

type TakeSiteSnapshot = (
  ownerType: SiteType,
  ownerId: number,
  name: string,
  exportToFile?: boolean
) => MrxThunk<TakeSiteSnapshotAction, Promise<boolean>>

type QueryResponse = GraphQLResponse<'takeSiteSnapshot', SiteSnapshot>

const TAKE_SITE_SNAPSHOT = `
  mutation TakeSiteSnapshot($ownerType: SiteType!, $ownerId: Int!, $name: String!) {
    takeSiteSnapshot(ownerType: $ownerType, ownerId: $ownerId, name: $name) {
      id
      name
      signedUrl
    }
  }
`

const takeSiteSnapshot: TakeSiteSnapshot =
  (ownerType, ownerId, name, exportToFile = false) =>
  async (dispatch) => {
    let isSaved = false

    dispatch({
      type: t.TAKE_SITE_SNAPSHOT,
      meta: { done: false }
    })

    try {
      const {
        data: { data, errors }
      }: QueryResponse = await axios.post('/graphql', {
        query: TAKE_SITE_SNAPSHOT,
        variables: { ownerType, ownerId: +ownerId, name }
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { takeSiteSnapshot } = data
      if (!takeSiteSnapshot) {
        throw new Error("Can't create site snapshot!")
      }

      dispatch({
        type: t.TAKE_SITE_SNAPSHOT,
        payload: { ownerType, ownerId, siteSnapshot: takeSiteSnapshot },
        meta: { done: true }
      })
      if (exportToFile) {
        cleanAxios({
          url: takeSiteSnapshot.signedUrl,
          method: 'GET',
          responseType: 'blob' // important
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${name}.json`)
          document.body.appendChild(link)
          link.click()
        })
      }
      isSaved = true
    } catch (err) {
      dispatch({
        type: t.TAKE_SITE_SNAPSHOT,
        payload: extractErrorInfo(err) as string,
        error: true
      })
    }

    return isSaved
  }

export { takeSiteSnapshot }
