import React, { useEffect, useState } from 'react'
import { Modal, Form, Input, Typography } from 'antd'
import notification from 'mrx-notification'
import { getAvailablePaymentsForRefund } from 'utils/getAvailablePaymentsForRefund'
import { Payment } from '../../types'
import { useRefundPayment } from '../../hooks'

type Props = {
  payments: Payment[]
  paymentId?: number | null
  visibleModal: boolean
  onCancel: () => void
  onFinish?: () => void
}

const RefundPaymentModal = (props: Props) => {
  const { payments, paymentId, visibleModal, onCancel, onFinish = () => {} } = props
  const [form] = Form.useForm()

  const [customError, setCustomError] = useState('')

  const refundPayment = useRefundPayment()

  const availablePayments = getAvailablePaymentsForRefund(payments)
  const currentPayment = paymentId ? availablePayments.find(item => item.id === paymentId) : availablePayments[0]

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentId])

  useEffect(() => {
    if (currentPayment) {
      form.setFieldsValue({
        amount: ((currentPayment?.maxAmount || 0) / 100).toFixed(2)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleModal])

  useEffect(() => {
    if (refundPayment.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Refund payment was successfully!'
      })
      onCancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refundPayment.isSuccess])

  useEffect(() => {
    if (refundPayment.error) {
      notification.error({
        message: 'Error',
        description: refundPayment.error.toString()
      })
    }
  }, [refundPayment.error])

  useEffect(() => {
    if (customError) {
      notification.error({
        message: 'Error',
        description: customError
      })
    }
  }, [customError])

  const handleOk = async () => {
    const { amount, comment } = await form.validateFields()
    const amountInCents = Math.round(parseFloat(amount) * 100)

    if (amountInCents > currentPayment.maxAmount) {
      setCustomError(`Amount is more than available: max $${(currentPayment.maxAmount / 100).toFixed(2)}`)
      return
    }

    refundPayment.mutate({
      paymentId: paymentId || payments[0].id,
      refundPaymentData: {
        amount: amountInCents,
        comment
      }
    })

    onFinish()
  }


  return (
    <Modal
      visible={visibleModal}
      title='Refund payment'
      onOk={handleOk}
      okButtonProps={{
        disabled: refundPayment.isLoading,
        loading: refundPayment.isLoading
      }}
      onCancel={onCancel}
    >
      <Form
        layout='vertical'
        form={form}
        hideRequiredMark
        initialValues={{ amount: ((currentPayment?.maxAmount || 0) / 100).toFixed(2) }}
      >
        {currentPayment && <Typography>Transaction ID: {currentPayment.externalTransactionId}</Typography>}
        <Form.Item name='amount' label='Amount' rules={[{ required: true, message: 'Please input amount!' }]}>
          <Input type='number' />
        </Form.Item>
        <Form.Item name='comment' label='Comment'>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default RefundPaymentModal
