import React, { useState, useEffect } from 'react'
import { StoreDto, STORE_MODIFICATOR_TYPE, StoreCostModificatorType, StoreCostModificatorDto, PRODUCT_TYPE } from '@merchx-v2/shared-types'
import { Table, Form, InputNumber, Button, Modal, Select } from 'antd'
import { EditOutlined, CloseOutlined } from '@ant-design/icons'
import notification from 'mrx-notification'
import { useUpdateStoreCostModificators } from '../../hooks'
import styles from './StoreCostModificators.module.scss'

type Props = {
  store?: StoreDto
}

type FormProps = {
  conditionType: StoreCostModificatorType
  conditionValue: string
  changeValue: number
}

const StoreCostModificators = ({ store }: Props) => {
  const [costModificators, setCostModificators] = useState(store?.costModificators || [])
  const [isModal, setIsModal] = useState(false)
  const [selectedConditionType, setSelectedConditionType] = useState('')

  const updateCostModificators = useUpdateStoreCostModificators()

  const [form] = Form.useForm<FormProps>()

  useEffect(() => {
    setCostModificators(store?.costModificators || [])
  }, [store])

  useEffect(() => {
    if (updateCostModificators.isError) {
      notification.error({
        message: 'Error!',
        // @ts-ignore
        description: updateCostModificators.error instanceof Error ? updateCostModificators.error.message : updateCostModificators.error.toString()
      })
    }

    if (updateCostModificators.isSuccess) {
      notification.success({
        message: 'Success',
        description: 'Cost modificators updated successfully'
      })
    }
  }, [updateCostModificators.isError, updateCostModificators.isSuccess])

  const cancelModal = () => {
    setIsModal(false)
    form.resetFields()
    setSelectedConditionType('')
  }

  const costModificatorUpdateHandler = async (values: FormProps) => {
    const newModificators = [
      ...costModificators
        .filter(item => !(item.conditionType === values.conditionType && item.conditionValue === values.conditionValue))
        .map(item => ({
          conditionType: item.conditionType,
          conditionValue: item.conditionValue,
          changeValue: item.changeValue
        })),
      {
        conditionType: values.conditionType,
        conditionValue: values.conditionValue,
        changeValue: values.changeValue * 100
      }
    ]

    await updateCostModificators.mutate({
      storeId: store.id,
      costModificators: newModificators
    })

    cancelModal()
    setCostModificators(newModificators.map(item => ({ storeId: store.id, ...item })))
  }

  const onCostModificatorEdit = (costModificator: StoreCostModificatorDto) => {
    form.setFieldsValue(costModificator)
    setSelectedConditionType(costModificator.conditionType)
    setIsModal(true)
  }

  const onCostModificatorDelete = async (costModificator: StoreCostModificatorDto) => {
    const newModificators = [
      ...costModificators
        .filter(item => !(item.conditionType === costModificator.conditionType && item.conditionValue === costModificator.conditionValue))
      ]

    setCostModificators(newModificators)
    await updateCostModificators.mutate({
      storeId: store.id,
      costModificators: newModificators.map(item => ({
        conditionType: item.conditionType,
        conditionValue: item.conditionValue,
        changeValue: item.changeValue
      }))
    })
  }

  const columns = [
    {
      title: 'Condition Type',
      dataIndex: 'conditionType',
      key: 'conditionType'
    },
    {
      title: 'Condition Value',
      dataIndex: 'conditionValue',
      key: 'conditionValue'
    },
    {
      title: 'Change Value',
      render: record => `$${(record.changeValue / 100).toFixed(2)}`,
      key: 'changeValue'
    },
    {
      title: 'Actions',
      render: (record) => <>
        <Button onClick={() => onCostModificatorEdit(record)} type='link' icon={<EditOutlined />} />
        <Button onClick={() => onCostModificatorDelete(record)} type='link' danger icon={<CloseOutlined />} />
      </>,
      key: 'actions'
    }
  ]

  return (
    <div className={styles.container}>
      <Modal
        open={isModal}
        title='Add cost modificator'
        onCancel={cancelModal}
        okText='Add'
        onOk={() => form.submit()}
      >
        <Form
          form={form}
          layout='vertical'
          onFinish={costModificatorUpdateHandler}
        >
          <Form.Item label='Select condition type' name='conditionType' rules={[{ required: true, message: 'This field is required' }]}>
            <Select placeholder='Select condition type' onChange={value => setSelectedConditionType(value)}>
              {Object.values(STORE_MODIFICATOR_TYPE).map(item => (
                <Select.Option key={item}>{item}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label='Select condition value' name='conditionValue' rules={[{ required: true, message: 'This field is required' }]}>

              <Select placeholder='Select condition value'>
                {selectedConditionType === STORE_MODIFICATOR_TYPE.PRODUCT_TYPE && Object.values(PRODUCT_TYPE).map(item => (
                  <Select.Option key={item}>{item}</Select.Option>
                ))}
              </Select>
          </Form.Item>
          <Form.Item label='Set change value' name='changeValue' rules={[{ required: true, message: 'This field is required' }]}>
            <InputNumber precision={2} prefix='$' />
          </Form.Item>
        </Form>
      </Modal>
      <div className={styles.leftSided}>
        <Button type='primary' onClick={() => setIsModal(true)}>+ Add</Button>
      </div>
      <Table
        columns={columns}
        dataSource={costModificators}
        bordered
        rowKey={(record) => record.conditionValue}
        loading={updateCostModificators.isLoading}
      />
    </div>
  )
}

export default StoreCostModificators
