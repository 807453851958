import { useQuery } from 'react-query'
import axios from 'utils/axios'
import { guardFromErrors } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'customerSubscriptionsExportToCSV', string>

const FETCH_CUSTOMER_SUBSCRIPTIONS_CSV = `
  query CustomerSubscriptionsExportToCSV($workspaceId: Int!) {
    customerSubscriptionsExportToCSV(workspaceId: $workspaceId)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchCustomerSubscriptionsList = (workspaceId: number) => Promise<string>

const fetchCustomerSubscriptionsListCsv: FetchCustomerSubscriptionsList = async (workspaceId) => {
  const {
    data: { data, errors }
  }: QueryResponse = await axios.post('/graphql', {
    query: FETCH_CUSTOMER_SUBSCRIPTIONS_CSV,
    variables: { workspaceId }
  })

  guardFromErrors(errors)

  if (!data) {
    throw new Error('Response body is empty!')
  }

  const { customerSubscriptionsExportToCSV } = data
  if (!customerSubscriptionsExportToCSV) {
    throw new Error("Can't get customer subscriptions list csv!")
  }

  return customerSubscriptionsExportToCSV
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCustomerSubscriptionsListCsv = (workspaceId: number) =>
  useQuery(['customerSubscriptionsListCsv', workspaceId], () => fetchCustomerSubscriptionsListCsv(workspaceId), {
    enabled: false,
    retry: 0
  })
