import { LogLevel } from '@merchx-v2/shared-types'
import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Alert } from '../types'

type AlertsList = {
  items: Alert[]
  total: number
}

type Payload = AlertsList & {
  page: number
}

export type FetchAlertsListAction = FSA<undefined, Payload, string>
type FetchAlertsList = (level: LogLevel, query: string) => MrxThunk<FetchAlertsListAction>
type QueryResponse = GraphQLResponse<'alertLogsListPage', AlertsList>

const FETCH_ALERTS_LIST = `
  query fetchAlertsLogListPage ($page: Int, $size: Int, $filters: AlertLogsFilters!) {
    alertLogsListPage(page: $page, size: $size, filters: $filters) {
      items {
        id
        source
        level
        message
        createdAt
        updatedAt
      }
      total
    }
  }
`

const fetchAlertsList: FetchAlertsList = (level, query) => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_ALERTS_LIST,
    meta: { done: false }
  })

  try {
    const {
      alerts: {
        pagination: { size, currentPage: page }
      }
    } = getState()

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_ALERTS_LIST,
      variables: { page, size, filters: { level, query } }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { alertLogsListPage } = data
    if (!alertLogsListPage) {
      throw new Error("Can't get alerts list page!")
    }

    dispatch({
      type: t.FETCH_ALERTS_LIST,
      payload: {
        items: alertLogsListPage.items,
        total: alertLogsListPage.total,
        page
      },
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_ALERTS_LIST,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchAlertsList }
