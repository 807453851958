import React from 'react'
import { Input, Form, Typography, Select, Switch } from 'antd'
import { productSkuPattern, productSkuPatternErrorMessage, PRODUCT_TYPE } from '@merchx-v2/shared-types'
import { ProductType2 } from 'consts'
import { convertToSku } from 'utils/convertToSku'
import styles from './EditProductType.module.scss'

const EditProductType = () => {
  return (
    <div className={styles.productFormBlock}>
      <Typography style={{ color: 'black', fontWeight: 'bold', paddingBottom: '8px' }}>Product Type</Typography>
      <div className={styles.productFormRow}>
        <Form.Item
          name='productType'
          label='Product type (Deprecated)'
          rules={[{ required: true, message: 'Please select product type!' }]}
          className={styles.productFormRowItem}
        >
          <Select>
            {Object
              .values(PRODUCT_TYPE)
              .filter((type) => type !== PRODUCT_TYPE.TSHIRT_LEGACY)
              .map((type) => (
                <Select.Option
                  key={type}
                  value={type}
                >
                  {type.split('_').map(i => `${i.charAt(0).toUpperCase()}${i.slice(1).toLowerCase()}`).join(' ')}
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>
      </div>

      <Form.Item
        name='productType2'
        label='Product type (New)'
        rules={[{ required: true, message: 'Please select new product type!' }]}
        className={styles.productFormRowItem}
      >
        <Select>
          <Select.Option value={ProductType2.PHYSICAL}>Physical</Select.Option>
          <Select.Option value={ProductType2.DIGITAL}>Digital</Select.Option>
          <Select.Option value={ProductType2.SUBSCRIPTION}>Subscription</Select.Option>
        </Select>
      </Form.Item>

      <div className={styles.productFormRow}>
        <Form.Item noStyle dependencies={[['displayName']]}>
          {(form) => {
            const sku = form.getFieldValue('sku')
            const displayName = form.getFieldValue('displayName') || ''
            if (!sku) form.setFieldValue('sku', convertToSku(displayName))

            return (
              <Form.Item
                name='sku'
                label='SKU'
                initialValue={sku || convertToSku(displayName)}
                normalize={(val) => convertToSku(val || '')}
                rules={[
                  { pattern: productSkuPattern, message: productSkuPatternErrorMessage },
                  { required: true, message: 'Please input product SKU!' }
                ]}
                className={styles.productFormRowItem}
              >
                <Input />
              </Form.Item>
            )
          }}
        </Form.Item>
      </div>

      <div className={styles.productFormRowItem}>
        <Form.Item name='customizable' label='Customizable' valuePropName='checked' className={styles.productFormRowItem}>
          <Switch />
        </Form.Item>
      </div>

      <div className={styles.productFormRowItem}>
        <Form.Item name='hasCustomShipping' label='Custom shipping' valuePropName='checked' className={styles.productFormRowItem}>
          <Switch />
        </Form.Item>
      </div>

      <Form.Item noStyle dependencies={[['hasCustomShipping']]}>
        {(form) => {
          if (!form.getFieldValue('hasCustomShipping')) return null

          return (
            <Form.Item required name='customShippingPrice' label='Custom shipping price'>
              <Input type='number' prefix='$' style={{ width: '100%' }} />
            </Form.Item>
          )
        }}
      </Form.Item>
    </div>
  )
}

export default EditProductType
