import React, { useEffect, useState } from 'react'
import ImgCrop from 'antd-img-crop'
import { Upload, Modal } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { UploadFile, RcFile } from 'antd/lib/upload/interface'
import hash from 'object-hash'
import styled from 'styled-components'
import { AssetDto } from '@merchx-v2/shared-types/dist/dto/assets/asset.dto'
import notification from 'mrx-notification'

type AvatarUploaderProps = {
  assets?: AssetDto[]
  assetsToRemove?: AssetDto[]
  filesToUpload: UploadFile<any>[]
  numberOfImages?: number
  isDTGImage?: boolean
  aspectRatio?: number
  shape?: 'rect' | 'round'
  onChange: (filesToUpload: UploadFile<any>[], filesToRemove?: AssetDto[]) => void
}

type GetBase64 = (file: File) => Promise<string>

const getBase64: GetBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })
}

const StyledUpload = styled(Upload)`
  .ant-upload.ant-upload-select-picture-card avatar-uploader {
    width: 100%;
    height: 165px;
  }
`

const AvatarUploader = (props: AvatarUploaderProps) => {
  const { assets = [], assetsToRemove = [], filesToUpload = [], numberOfImages = 1, aspectRatio, shape = 'rect', onChange } = props

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [previewVisible, setPreviewVisible] = useState<boolean>(false)
  const [previewImage, setPreviewImage] = useState<string>('')
  const [previewTitle, setPreviewTitle] = useState<string>('')
  const [fileList, setFileList] = useState<UploadFile<any>[]>([])

  useEffect(() => {
    const existFiles = []
    for (const asset of assets) {
      if (!assetsToRemove.some((removed) => removed.id === asset.id)) {
        existFiles.push({
          uid: asset.id + '',
          name: asset.name,
          status: 'done',
          size: 0,
          type: asset.mimeType,
          url: asset.signedUrl
        } as UploadFile)
      }
    }

    setFileList([...existFiles, ...filesToUpload])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(assets), hash(filesToUpload, { excludeKeys: (key) => key === 'originFileObj' }), hash(assetsToRemove)])

  const handleCancel = () => setPreviewVisible(false)

  const uploadButton = (
    <div>
      {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      notification.error({
        message: 'You can only upload JPG/PNG file!'
      })
    }
    const isLt10M = file.size / 1024 / 1024 < 10
    if (!isLt10M) {
      notification.error({
        message: 'Image must smaller than 2MB!'
      })
    }
    return (isJpgOrPng && isLt10M) || Upload.LIST_IGNORE
  }

  const handleUpload = async (info: any) => {
    setIsLoading(true)
    const fileName = info.file.name.trim().replace(/\s+/g, '_')
    const uploadedFile = info.file as any

    const newListOfFilesToUpload = [
      ...filesToUpload,
      {
        uid: uploadedFile.uid,
        name: fileName,
        status: 'done',
        size: uploadedFile.size,
        type: uploadedFile.type,
        originFileObj: info.file,
        url: await getBase64(info.file)
      } as UploadFile
    ]

    setIsLoading(false)
    onChange(newListOfFilesToUpload, assetsToRemove)
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
  }

  const handleRemove = (file: UploadFile<any>) => {
    const assetToRemove = assets.find((asset) => asset.id + '' === file.uid)
    if (assetToRemove) {
      if (!assetsToRemove.some((asset) => asset.id + '' === file.uid)) {
        const newListOfAssetsToRemove = [...assetsToRemove, assetToRemove]
        onChange(filesToUpload, newListOfAssetsToRemove)
      }
    }

    const canceledFile = filesToUpload.find((fileToUpload) => fileToUpload.uid === file.uid)
    if (canceledFile) {
      const newListOfFilesToUpload = filesToUpload.filter((fileToUpload) => fileToUpload.uid !== file.uid)
      onChange(newListOfFilesToUpload, assetsToRemove)
    }
  }

  return (
    <>
      {!aspectRatio && (
        <StyledUpload
          name='avatar'
          listType='picture-card'
          className='avatar-uploader'
          fileList={fileList}
          beforeUpload={beforeUpload}
          customRequest={handleUpload}
          onPreview={handlePreview}
          onRemove={handleRemove}
        >
          {fileList.length >= numberOfImages ? null : uploadButton}
        </StyledUpload>
      )}
      {aspectRatio && (
        <ImgCrop aspect={aspectRatio} shape={shape} rotate quality={1}>
          <StyledUpload
            name='image'
            listType='picture-card'
            className='avatar-uploader'
            fileList={fileList}
            beforeUpload={beforeUpload}
            customRequest={handleUpload}
            onPreview={handlePreview}
            onRemove={handleRemove}
          >
            {fileList.length >= numberOfImages ? null : uploadButton}
          </StyledUpload>
        </ImgCrop>
      )}
      <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt='example' style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  )
}

export default AvatarUploader
