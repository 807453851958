import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'
import * as productTags from 'features/productTags'

const mapStateToProps = (state: ReduxState) => ({
  isLoading: selectors.isLoadingSelectorByKey(state, 'isSaveProcessingCostLoading'),
  productTags: productTags.selectors.productTagsForOptionsSelector(state)
})

const mapDispatchToProps = {
  saveProcessingCost: actions.saveProcessingCost
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector>
