import { Message } from 'console-feed/lib/definitions/Component'

export const convertLoggerMessageToFeed = (message: string) => {
  const result = {
    method: 'log',
    // TODO its not working on old browsers (not so old)
    // message: message?.replaceAll('\n', '').replace('\x1b[0m', '')
    message: message?.replace(/\n/g, '').replace('\x1b[0m', '')
  }

  // Error
  if (message.includes('\x1b[31m')) {
    result.method = 'error'
    result.message = result.message.replace('\x1b[31m', '')
  }

  // Info
  if (message.includes('\x1b[35m')) {
    result.method = 'info'
    result.message = result.message.replace('\x1b[35m', '')
  }

  // Warn
  if (message.includes('\x1b[33m')) {
    result.method = 'warn'
    result.message = result.message.replace('\x1b[33m', '')
  }

  // Result
  if (message.includes('\x1b[32m')) {
    result.method = 'result'
    result.message = result.message.replace('\x1b[32m', '')
  }

  return {
    method: result.method,
    data: [result.message]
  } as Message
}
