import React, { useState, useEffect, useRef } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Drawer, Input, Form } from 'antd'
import notification from 'mrx-notification'
import styles from './CreateAuthorizeSetting.module.scss'
import { PropsFromRedux, connector } from './container'

const CreateAuthorizeSetting = (props: PropsFromRedux) => {
  const [isVisible, setVisible] = useState(false)
  const refEl = useRef<Input>(null)

  const { isLoading, createAuthorizeSetting } = props

  useEffect(() => {
    if (isVisible) {
      refEl.current && refEl.current.focus()
    }
  }, [isVisible])

  const showDrawer = () => {
    setVisible(!isVisible)
  }

  const [form] = Form.useForm()

  const handleClose = () => {
    setVisible(false)
    form.resetFields()
  }

  const onFinishHandler = async (values: any) => {
    const isSaved = await createAuthorizeSetting({
      name: values.name,
      apiGateway: values.apiGateway,
      apiLoginId: values.apiLoginId,
      transactionKey: values.transactionKey,
      clientKey: values.clientKey,
      signatureKey: values.signatureKey
    })
    if (isSaved) {
      notification.success({
        message: 'Successfully',
        description: 'Authorize Setting was created successfully!'
      })
      handleClose()
    }
  }

  return (
    <>
      <Button type='primary' onClick={showDrawer}>
        <PlusOutlined />
        CREATE
      </Button>
      <Drawer title='Create Authorize Setting' width='400' onClose={handleClose} visible={isVisible} forceRender>
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
          <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input authorize setting name!' }]}>
            <Input ref={refEl} />
          </Form.Item>
          <Form.Item name='apiGateway' label='API Gateway' rules={[{ required: true, message: 'Please input API Gateway!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name='apiLoginId' label='API Login ID' rules={[{ required: true, message: 'Please input API login ID!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name='transactionKey' label='Transaction Key' rules={[{ required: true, message: 'Please input transaction key!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name='clientKey' label='Client Key'>
            <Input />
          </Form.Item>
          <Form.Item name='signatureKey' label='Signature Key'>
            <Input />
          </Form.Item>
          <div className={styles.buttonsContainer}>
            <Button onClick={handleClose} className={styles.createButton}>
              Close
            </Button>
            <Button type='primary' htmlType='submit' loading={isLoading}>
              Create
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

export default connector(CreateAuthorizeSetting)
