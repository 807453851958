import React, { useEffect, useState } from 'react'
import { Popconfirm, Layout, PageHeader, DatePicker, Typography, Button, Tabs, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import moment, { Moment } from 'moment'
import notification from 'mrx-notification'
import useReactRouter from 'use-react-router'
import * as rootStyles from 'assets/layoutStyle'
import { Breadcrumbs } from 'components'
import { useBanUser, useUser, useUnbanUser } from '../../hooks'
import styles from './UserDetails.module.scss'

const dateFormat = 'MM/DD/YYYY'

const UserDetails = (props) => {
  const { match } = props

  const { history } = useReactRouter()

  const { data: user, error } = useUser(+match?.params?.userId)
  const banUser = useBanUser()
  const unbanUser = useUnbanUser()

  const isBanned = new Date(user?.bannedTill) > new Date()

  const [bannedTill, setBannedTill] = useState<Moment>()

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'User details page error!',
        description: (error as Error).message
      })
    }
  }, [error])

  useEffect(() => {
    if (banUser.error) {
      notification.error({
        message: 'Ban User error!',
        description: banUser.error instanceof Error ? banUser.error.message : banUser.error.toString()
      })
    }
  }, [banUser.error])

  useEffect(() => {
    if (unbanUser.error) {
      notification.error({
        message: 'Unban user error!',
        description: unbanUser.error instanceof Error ? unbanUser.error.message : unbanUser.error.toString()
      })
    }
  }, [unbanUser.error])

  const doConfirm = () => {
    if (!user) return

    if (isBanned) {
      unbanUser.mutate({ userId: user.id })
      return
    }

    banUser.mutate({ userId: user.id, bannedTill: bannedTill.toDate() })
  }

  const data = user
    ? user.workspaces.map((workspace) => ({
        id: workspace.id,
        name: workspace.name,
        // @ts-ignore
        role: user.workspacesRoles?.find((role) => role.workspaceId === workspace.id)?.role || '',
        isActive: user.activeWorkspace?.id === workspace.id,
        isDefault: user.defaultWorkspaceId === workspace.id
      }))
    : []

  const columns: ColumnsType<typeof data[0]> = [
    {
      title: 'Workspace ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role'
    },
    {
      title: 'Is Active',
      render: (record) => (record.isActive ? 'Yes' : ''),
      key: 'isActive'
    },
    {
      title: 'isDefault',
      render: (record) => (record.isDefault ? 'Yes' : ''),
      key: 'isDefault'
    }
  ]

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title='User Details'
          extra={
            user
              ? [
                  <DatePicker key='1' format={dateFormat} value={bannedTill} onChange={setBannedTill} allowClear={false} />,
                  <Popconfirm
                    key='cancel-button'
                    title={`Are you sure ${isBanned ? 'unban' : 'ban'} this user?`}
                    onConfirm={() => doConfirm()}
                    onCancel={(e) => e?.stopPropagation()}
                    okText='Yes'
                    cancelText='No'
                  >
                    <Button disabled={banUser.isLoading || unbanUser.isLoading} key='2'>
                      {isBanned ? 'Unban' : 'Ban'} user
                    </Button>
                  </Popconfirm>
                ]
              : []
          }
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Users', url: '/settings/users' },
            { title: 'User Details', url: '/settings/users' }
          ]}
        />
      </Layout.Content>

      {user && (
        <div className={styles.contentComponent}>
          <Typography.Title level={3}>{`${user.firstName} ${user.lastName}`} </Typography.Title>

          <div className={styles.listContainer}>
            {user.avatar && (
              <div className={styles.leftColumn}>
                <img width={200} height={200} src={user.avatar} alt={user.email} style={{ marginBottom: 10, objectFit: 'contain' }} />
              </div>
            )}
            <div className={styles.leftColumn}>
              <div className={styles.listItem}>ID: {user.id}</div>
              <div className={styles.listItem}>Email: {user.email}</div>
              <div className={styles.listItem}>Date Added: {moment(user.createdAt).format('MM/DD/YYYY HH:mm:ss')}</div>
              <div className={styles.listItem}>
                Last Login: {user.lastLoginAt ? moment(user.lastLoginAt).format('MM/DD/YYYY HH:mm:ss') : ''}
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.listItem}>Email Confirmation: {user.isEmailConfirmed ? 'Yes' : 'No'}</div>
              <div className={styles.listItem}>Banned Till: {isBanned ? moment(user.bannedTill).format('MM/DD/YYYY HH:mm:ss') : ''}</div>
              <div className={styles.listItem}>Roles: {user.roles.join(', ')}</div>
            </div>
          </div>

          <Tabs type='card' className={styles.tabsContainer}>
            <Tabs.TabPane tab='Workspaces' key='1'>
              <Table columns={columns} dataSource={data} bordered rowKey={(record) => record.id} pagination={false} />
            </Tabs.TabPane>
          </Tabs>
        </div>
      )}
    </Layout>
  )
}

export default UserDetails
