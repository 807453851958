import FormData from 'form-data'
import axiosVanila from 'axios'
import { UploadFile as UploadFileType } from 'antd/lib/upload/interface'
import axios from 'utils/axios'
import { FileOwner } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { PresignedUrl } from '../types'
import * as t from '../actionTypes'

type UploadFileArgs = {
  ownerType: FileOwner
  ownerId: number
  file: UploadFileType<any>
  newFileName?: string
}

export type UploadFileAction = FSA<undefined, string, string>
type UploadFile = (args: UploadFileArgs) => MrxThunk<UploadFileAction, Promise<any>>

type PresignedResponse = GraphQLResponse<'uploadFile', PresignedUrl>
type RemoveOldFilesResponse = GraphQLResponse<'removeOldFiles', boolean>
const CREATE_PRESIGNED_URL = `
 mutation uploadFile($ownerType: FileOwner!, $ownerId: Int!, $fileData: UploadFileInput!) {
   uploadFile(ownerType: $ownerType, ownerId: $ownerId, fileData: $fileData)
 }
`
const REMOVE_OLD_FILES = `
 mutation removeOldFiles($ownerType: FileOwner!, $ownerId: Int!, $fileData: UploadFileInput!) {
   removeOldFiles(ownerType: $ownerType, ownerId: $ownerId, fileData: $fileData)
 }`

const uploadFile: UploadFile =
  ({ ownerType, ownerId, file, newFileName }) =>
  async (dispatch) => {
    let fileData

    dispatch({
      type: t.UPLOAD_FILE,
      meta: { done: false }
    })

    try {
      const {
        data: { data, errors }
      }: PresignedResponse = await axios.post('/graphql', {
        query: CREATE_PRESIGNED_URL,
        variables: {
          ownerType,
          ownerId,
          fileData: {
            type: file.type,
            filename: file.name,
            newFileName
          }
        }
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { uploadFile } = data

      if (!uploadFile) {
        throw new Error("Can't upload file!")
      }

      const formData = new FormData()
      Object.entries(uploadFile.fields).forEach(([key, value]) => {
        formData.append(key, value)
      })
      formData.append('file', file?.originFileObj ? file.originFileObj : file)
      await axiosVanila.post(uploadFile.url, formData)

      const {
        data: { data: removeData, errors: removeErrors }
      }: RemoveOldFilesResponse = await axios.post('/graphql', {
        query: REMOVE_OLD_FILES,
        variables: {
          ownerType,
          ownerId,
          fileData: {
            type: file.type,
            filename: file.name,
            newFileName
          }
        }
      })

      guardFromErrors(removeErrors)

      if (!removeData) {
        throw new Error('Response body is empty!')
      }

      const { removeOldFiles } = removeData

      if (!removeOldFiles) {
        throw new Error("Can't remove old files!")
      }

      const regexp = /(?:\.([^.]+))?$/
      const extMatches = regexp.exec(file.name)
      if (!extMatches) {
        throw new Error('Only files with extensions supported!')
      }

      if (extMatches.length < 2) {
        throw new Error('Can not extract file extension!')
      }

      const extension = extMatches[1]

      fileData = {
        url: `${uploadFile.url}/${uploadFile.fields.key}`,
        s3key: uploadFile.fields.key,
        extension
      }

      dispatch({
        type: t.UPLOAD_FILE,
        payload: fileData.url,
        meta: { done: true }
      })

      return fileData
    } catch (err) {
      dispatch({
        type: t.UPLOAD_FILE,
        payload: extractErrorInfo(err),
        error: true
      })
    }

    return fileData
  }

export { uploadFile }
