import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

type OwnProps = {
  showHeader?: boolean
}

const mapStateToProps = (state: ReduxState) => {
  return {
    error: selectors.errorSelector(state)
  }
}

const mapDispatchToProps = {
  removeProduct: actions.removeProduct
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
