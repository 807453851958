import produce from 'immer'
import * as t from './actionTypes'
import { actionTypes as nt } from 'features/navigation'
import { actionTypes as wt } from 'features/workspaces'
import { isSuccessAction, isErrorAction } from 'types'
import { StripeSettingsState } from './types'
import createReducer from 'store/createReducer'
import { addItemToPagination } from 'utils/addItemToPagination'
import { removeItemFromPagination } from 'utils/removeItemFromPagination'
import {
  ChangeCurrentPageAction,
  ChangePageSizeAction,
  CreateStripeSettingAction,
  FetchStripeSettingsListAction,
  FetchStripeSettingsForOptionsAction,
  RemoveStripeSettingAction,
  UpdateStripeSettingAction
} from './actions'

const initState: StripeSettingsState = {
  stripeSettings: {},
  stripeSettingsForOptions: [],
  pagination: {
    currentPage: 1,
    total: 0,
    pages: {},
    size: 10
  },
  UIState: {
    isStripeSettingsForOptionsLoading: false,
    isCreateStripeSetting: false,
    isListLoading: false,
    isRemoveStripeSetting: false,
    isUpdateStripeSetting: false
  }
}

const reducer = createReducer(initState, {
  [t.CREATE_STRIPE_SETTING]: (state, action: CreateStripeSettingAction) =>
    produce(state, (draft) => {
      draft.UIState.isCreateStripeSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isCreateStripeSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { id } = action.payload

        draft.stripeSettings[id] = action.payload
        addItemToPagination(draft.pagination, id)
      }
    }),

  [t.FETCH_STRIPE_SETTINGS_LIST]: (state, action: FetchStripeSettingsListAction) =>
    produce(state, (draft) => {
      draft.UIState.isListLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isListLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        action.payload.items.forEach((item) => {
          draft.stripeSettings[item.id] = item
        })
        const { page, total, items } = action.payload
        draft.pagination.total = total
        draft.pagination.pages[page] = items.map((item) => item.id)
      }
    }),

  [t.FETCH_STRIPE_SETTINGS_FOR_OPTIONS]: (state, action: FetchStripeSettingsForOptionsAction) =>
    produce(state, (draft) => {
      draft.UIState.isStripeSettingsForOptionsLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isStripeSettingsForOptionsLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.stripeSettingsForOptions = action.payload
      }
    }),

  [t.REMOVE_STRIPE_SETTING]: (state, action: RemoveStripeSettingAction) =>
    produce(state, (draft) => {
      draft.UIState.isRemoveStripeSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isRemoveStripeSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.stripeSettings[action.payload]) {
          delete draft.stripeSettings[action.payload]
          removeItemFromPagination(draft.pagination, action.payload)
        }
      }
    }),
  [t.UPDATE_STRIPE_SETTING]: (state, action: UpdateStripeSettingAction) =>
    produce(state, (draft) => {
      draft.UIState.isUpdateStripeSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isUpdateStripeSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.stripeSettings[action.payload.stripeSettingId]) {
          const { name, apiVersion, host, port, secretKey, publishableKey } = action.payload.stripeSettingData
          const setting = draft.stripeSettings[action.payload.stripeSettingId]
          setting.name = name
          setting.apiVersion = apiVersion
          setting.host = host
          setting.port = port
          setting.secretKey = secretKey
          setting.publishableKey = publishableKey
        }
      }
    }),

  [t.CHANGE_CURRENT_PAGE]: (state, action: ChangeCurrentPageAction) =>
    produce(state, (draft) => {
      if (action.payload) {
        draft.pagination.currentPage = action.payload
      }
    }),

  [t.CHANGE_PAGE_SIZE]: (state, action: ChangePageSizeAction) =>
    produce(state, (draft) => {
      if (action.payload) {
        draft.pagination.size = action.payload
      }
    }),

  [wt.SWITCH_WORKSPACE]: (state) =>
    produce(state, (draft) => {
      draft.pagination = initState.pagination
    }),

  [nt.LOCATION_CHANGE]: (state) =>
    produce(state, (draft) => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default reducer
