import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type isLoadingKeyType =
  | 'isCreateProductAttribute'
  | 'isFetchProductAttribute'
  | 'isListLoading'
  | 'isRemoveProductAttribute'
  | 'isUpdateProductAttribute'

type IsLoadingSelector = (state: ReduxState, isLoadingKey: isLoadingKeyType) => boolean

const getIsLoadingSelectorByKey = (state: ReduxState, isLoadingKey: isLoadingKeyType) =>
  state.productAttributes.UIState[isLoadingKey] || false

export const isLoadingSelector: IsLoadingSelector = createSelector(getIsLoadingSelectorByKey, isLoading => isLoading)
