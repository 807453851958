// TODO - remove becouse of legacy

import React, { useContext, useState, useEffect } from 'react'
import useReactRouter from 'use-react-router'
import hash from 'object-hash'
import { Button, Input, Form, Layout, PageHeader, Select, Typography, Switch, Modal, Drawer } from 'antd'
import {
  AssetOwner,
  CreateQuizDto,
  CreateQuizQuestionDto,
  CreateQuizResultDto,
  QuizLogicType,
  QUIZ_LOGIC_TYPE
} from '@merchx-v2/shared-types'
import notification from 'mrx-notification'
import { v1 as uuid } from 'uuid'
import { GlobalContext } from 'appContexts'
import { UploadFile } from 'antd/lib/upload/interface'

import * as rootStyles from 'assets/layoutStyle'

import { useRemoveAsset, useUploadAsset } from 'features/assets/hooks'
import { useCampaign } from 'features/campaigns/hooks'
import { useCreateQuiz, useQuiz, useUpdateQuiz, useDuplicateQuiz } from '../../hooks'
import QuizQuestionInput from '../QuizQuestionInput'
import QuizQuestionAnswerInput from '../QuizQuestionAnswerInput'
import QuizResultInput from '../QuizResultInput'
import EditResultCorrects from '../EditResultCorrects'
import { PropsFromRedux, connector } from './container'
import styles from './EditQuiz.module.scss'

type ImageToSave = {
  file: UploadFile<any>
  type: AssetOwner
  id: number
}

const EditQuiz = (props: PropsFromRedux) => {
  const { quizId } = props

  const { campaignId } = useContext(GlobalContext)

  const quiz = useQuiz(quizId)
  const campaign = useCampaign(campaignId)
  const removeAsset = useRemoveAsset()
  const uploadAsset = useUploadAsset()
  const createQuiz = useCreateQuiz()
  const updateQuiz = useUpdateQuiz()
  const duplicateQuiz = useDuplicateQuiz()

  const isLoading = removeAsset.isLoading || uploadAsset.isLoading || createQuiz.isLoading || updateQuiz.isLoading

  const [isActive, setIsActive] = useState(true)
  const [questions, setQuestions] = useState<CreateQuizQuestionDto[]>([])
  const [results, setResults] = useState<CreateQuizResultDto[]>([])
  const [quizLogicType, setQuizLogicType] = useState<QuizLogicType>('TRIVIA')

  const [previewImage, setPreviewImage] = useState('')
  const [imagesToSave, setImagesToSave] = useState<ImageToSave[]>([])

  const { history } = useReactRouter()
  const [form] = Form.useForm()
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const [newQuizName, setNewQuizName] = useState('')

  useEffect(() => {
    if (campaign.error) {
      notification.error({
        message: 'Create Quiz error!',
        description: campaign.error instanceof Error ? campaign.error.message : campaign.error.toString()
      })
    }
  }, [campaign.error])

  useEffect(() => {
    if (removeAsset.error) {
      notification.error({
        message: 'Error!',
        description: removeAsset.error.toString()
      })
    }
  }, [removeAsset.error])

  useEffect(() => {
    if (removeAsset.isSuccess) {
      notification.success({
        message: 'Success!',
        description: 'Asset was delete successfully'
      })
    }
  }, [removeAsset.isSuccess])

  useEffect(() => {
    if (duplicateQuiz.isSuccess) {
      notification.success({
        message: 'Success!',
        description: 'Quiz duplicated successfully'
      })
    }
  }, [duplicateQuiz.isSuccess])

  useEffect(() => {
    if (uploadAsset.error) {
      notification.error({
        message: 'Error!',
        description: uploadAsset.error instanceof Error ? uploadAsset.error.message : uploadAsset.error.toString()
      })
    }
  }, [uploadAsset.error])

  useEffect(() => {
    if (uploadAsset.isSuccess) {
      notification.success({
        message: 'Success!',
        description: 'Asset was upload successfully'
      })
    }
  }, [uploadAsset.isSuccess])

  useEffect(() => {
    if (quiz.data) {
      setIsActive(!quiz.data.archivedAt)

      form.setFieldsValue({
        name: quiz.data.name,
        ownerType: quiz.data.ownerType,
        ownerId: quiz.data.ownerId,
        archivedAt: quiz.data.archivedAt,
        campaignId: quiz.data.campaignId,
        logicType: quiz.data.logicType
      })

      setQuestions(quiz.data.questions)
      setResults(quiz.data.results)
      setQuizLogicType(quiz.data.logicType)
    } else {
      form.setFieldsValue({
        ownerType: 'MERCHX',
        campaignId,
        logicType: 'TRIVIA'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(quiz)])

  const handleFormClose = () => {
    setIsActive(false)
    form.resetFields()
    setQuestions([])
    setResults([])
    setImagesToSave([])
    setPreviewImage('')
    history.goBack()
  }

  const handleClickDuplicateQuiz = async () => {
    await duplicateQuiz.mutateAsync({ quizId: quiz.data.id, newName: newQuizName })
    setIsDrawerVisible(false)
  }

  const onFinishHandler = async (values: CreateQuizDto) => {
    try {
      const resultsToUpdate: CreateQuizResultDto[] = []

      for (const quizResult of results) {
        if (typeof quizResult.id === 'string') {
          delete quizResult.id
        }

        const { id, result, imageId: sourceImageId, quizId, link, description, countOfCorrects } = quizResult

        let imageId = sourceImageId
        const imageToSave = imagesToSave.find((image) => image.type === 'QUIZ_RESULT' && image.id === id)

        if (imageToSave) {
          await uploadAsset
            .mutateAsync({
              ownerType: imageToSave.type,
              ownerId: imageToSave.id,
              assetData: { name: imageToSave.file.name, type: imageToSave.file.type, filename: imageToSave.file.name, role: 'DEFAULT' },
              file: imageToSave.file as any
            })
            .then(async (data) => {
              imageId = data.id
            })
        }

        resultsToUpdate.push({
          id,
          result,
          imageId,
          quizId,
          link,
          description,
          countOfCorrects
        })
      }
      const questionsToUpdate: CreateQuizQuestionDto[] = []

      for (const quizQuestion of questions) {
        if (typeof quizQuestion.id === 'string') {
          delete quizQuestion.id
        }

        const answers = quizQuestion.answers.map((questionAnswer) => {
          if (typeof questionAnswer.id === 'string') {
            delete questionAnswer.id
          }

          const { id, answer, isCorrect, quizResultId } = questionAnswer

          return { id, answer, isCorrect, quizResultId }
        })

        const { id, question, imageId: sourceImageId, quizId } = quizQuestion

        let imageId = sourceImageId
        const imageToSave = imagesToSave.find((image) => image.type === 'QUIZ_QUESTION' && image.id === id)

        if (imageToSave) {
          await uploadAsset
            .mutateAsync({
              ownerType: imageToSave.type,
              ownerId: imageToSave.id,
              assetData: { name: imageToSave.file.name, type: imageToSave.file.type, filename: imageToSave.file.name, role: 'DEFAULT' },
              file: imageToSave.file as any
            })
            .then(async (data) => {
              imageId = data.id
            })
        }

        questionsToUpdate.push({ id, question, imageId, quizId, answers })
      }

      const quizData: CreateQuizDto = {
        name: values.name,
        ownerType: quiz.data?.ownerType || 'MERCHX',
        ownerId: values.ownerId,
        campaignId: quiz.data?.campaignId || campaignId,
        logicType: values.logicType,

        results: resultsToUpdate,
        questions: questionsToUpdate
      }

      if (!isActive) {
        quizData.archivedAt = new Date()
      }

      let isSaved = false

      if (!quiz.data) {
        const createdQuiz = await createQuiz.mutateAsync({ quizData })

        isSaved = !!createdQuiz
      } else {
        isSaved = !!(await updateQuiz.mutateAsync({ quizId: quiz.data.id, quizData }))
      }

      if (isSaved) {
        notification.success({
          message: 'Successfully',
          description: `Quiz was ${quiz ? 'updated' : 'created'} successfully!`
        })
        handleFormClose()
      }
    } catch (e) {}
  }

  const addQuestion = () => {
    setQuestions([...questions, { id: uuid(), question: '', quizId: quiz.data?.id, answers: [] }])
  }
  const onQuestionChanged = (questionIndex: number, question: string) => {
    const newQuestions = JSON.parse(JSON.stringify(questions))

    const changedQuestion = newQuestions[questionIndex]
    changedQuestion.question = question

    setQuestions(newQuestions)
  }
  const onQuestionRemoved = (questionIndex: number) => {
    const newQuestions = JSON.parse(JSON.stringify(questions))
    newQuestions.splice(questionIndex, 1)

    setQuestions(newQuestions)
  }

  const addResult = () => {
    setResults([...results, { id: uuid(), result: '', quizId: quiz.data?.id, countOfCorrects: 0 }])
  }
  const onResultChanged = (resultIndex: number, result: string) => {
    const newResults = JSON.parse(JSON.stringify(results))

    newResults[resultIndex].result = result

    setResults(newResults)
  }
  const onDescriptionChanged = (resultIndex: number, description: string) => {
    const newResults = JSON.parse(JSON.stringify(results))

    newResults[resultIndex].description = description

    setResults(newResults)
  }
  const onLinkChanged = (resultIndex: number, link: string) => {
    const newResults = JSON.parse(JSON.stringify(results))

    newResults[resultIndex].link = link

    setResults(newResults)
  }
  const onCountOfCorrectsChanged = (resultIndex, countOfCorrects: number) => {
    const newResults = JSON.parse(JSON.stringify(results))

    newResults[resultIndex].countOfCorrects = countOfCorrects

    setResults(newResults)
  }
  const onResultRemoved = (resultIndex: number) => {
    const newResults = JSON.parse(JSON.stringify(results))
    newResults.splice(resultIndex, 1)

    setResults(newResults)
  }

  const addAnswer = (questionIndex: number) => {
    const newQuestions = JSON.parse(JSON.stringify(questions))

    newQuestions[questionIndex].answers.push({ id: uuid(), answer: '', isCorrect: false })
    setQuestions(newQuestions)
  }
  const onAnswerChanged = (questionIndex: number, answerIndex: number, answer: string) => {
    const newQuestions = JSON.parse(JSON.stringify(questions))

    const changedQuestion = newQuestions[questionIndex]
    const changedAnswer = changedQuestion.answers[answerIndex]
    changedAnswer.answer = answer

    setQuestions(newQuestions)
  }
  const onChangeCorrectAnswer = (questionIndex: number, answerIndex: number) => {
    const newQuestions = JSON.parse(JSON.stringify(questions))

    const changedQuestion = newQuestions[questionIndex]
    const changedAnswers = changedQuestion.answers.map((answer, index) => ({ ...answer, isCorrect: index === answerIndex }))

    changedQuestion.answers = changedAnswers

    setQuestions(newQuestions)
  }
  const onLinkAnswerToResult = (questionIndex: number, answerIndex: number, resultIndex: number) => {
    const targetResult = results[resultIndex]

    const newQuestions: CreateQuizQuestionDto[] = JSON.parse(JSON.stringify(questions))

    newQuestions[questionIndex].answers[answerIndex].quizResultId = targetResult?.id

    setQuestions(newQuestions)
  }
  const onAnswerRemoved = (questionIndex: number, answerIndex: number) => {
    const newQuestions = JSON.parse(JSON.stringify(questions))

    newQuestions[questionIndex].answers.splice(answerIndex, 1)

    setQuestions(newQuestions)
  }

  const handleFormValueChanged = (changedValues) => {
    if (changedValues.logicType) {
      setQuizLogicType(changedValues.logicType)
    }
  }

  const handleAddImage = (data: ImageToSave) => {
    const { file, type, id } = data
    file.url = URL.createObjectURL(file)

    const newImages = [...imagesToSave.filter((item) => item.id !== id || item.type !== type)]
    setImagesToSave([...newImages, { file, type, id }])
  }

  const handleDrawerClose = () => setIsDrawerVisible(false)
  const handleDrawerOpen = () => setIsDrawerVisible(true)

  const drawerFooter = (
    <div className={styles.drawerFooter}>
      <Button onClick={handleDrawerClose} className={styles.cancelButton}>
        Cancel
      </Button>
      <Button onClick={handleClickDuplicateQuiz} type='primary' htmlType='submit' loading={duplicateQuiz.isLoading}>
        Add
      </Button>
    </div>
  )

  return (
    <Layout style={rootStyles.root} className={styles.baseContainer}>
      {campaign?.data?.status === 'MIGRATING' && (
        <div className={styles.blockPage}>
          <div className={styles.blockPageContainer}>
            <p className={styles.blockPageTitle}>
              Campaign is moving to another workspace <span onClick={() => history.goBack()}>Go back</span>
            </p>
          </div>
        </div>
      )}
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title={`${quiz.data ? 'Update' : 'Create'} quiz`}
          extra={
            !quiz.data
              ? undefined
              : [
                  <Typography.Text key='label'>Quiz Active:</Typography.Text>,
                  <Switch key='active-status' checked={isActive} onChange={setIsActive} disabled={isLoading} />,
                  <Button key='duplicate-quiz' onClick={handleDrawerOpen}>
                    DuplicateQuiz
                  </Button>
                ]
          }
        />
      </Layout.Header>
      <Layout.Content className={styles.contentComponent}>
        <Form
          layout='vertical'
          hideRequiredMark
          onFinish={onFinishHandler}
          onValuesChange={handleFormValueChanged}
          form={form}
          initialValues={{
            name: quiz.data?.name,
            ownerType: quiz.data?.ownerType || 'MERCHX',
            ownerId: quiz.data?.ownerId,
            logicType: quiz.data?.logicType || 'MATCH'
          }}
        >
          <div className={styles.quizFormBlock}>
            <Typography style={{ color: 'black', fontWeight: 'bold', paddingBottom: '8px' }}>Quiz Name</Typography>
            <div className={styles.quizFormRow}>
              <Form.Item
                name='name'
                label='Quiz name'
                rules={[{ required: true, message: 'Please input quiz  name!' }]}
                className={styles.quizFormRowItem}
              >
                <Input disabled={isLoading} />
              </Form.Item>
              <div className={styles.quizFormRowItem}>
                <Typography style={{ color: 'black', fontWeight: 'normal', paddingTop: '22px', fontStyle: 'italic' }}>
                  Warning: You will be able to add images for questions and results after they will be created
                </Typography>
              </div>
            </div>
            <div className={styles.quizFormRow}>
              <Form.Item
                name='logicType'
                label='Quiz Logic'
                rules={[{ required: true, message: 'Please select quiz logic!' }]}
                className={styles.quizFormRowItem}
              >
                <Select disabled={isLoading}>
                  <Select.Option value={QUIZ_LOGIC_TYPE.MATCH}>Match</Select.Option>
                  <Select.Option value={QUIZ_LOGIC_TYPE.TRIVIA}>Trivia</Select.Option>
                </Select>
              </Form.Item>
            </div>
          </div>

          <div className={styles.quizFormBlock}>
            <Typography style={{ color: 'black', fontWeight: 'bold', paddingBottom: '8px' }}>Questions</Typography>

            <div className={styles.quizFormRow}>
              {questions.map((question, index) => (
                <div className={styles.quizFormRowItem} style={{ paddingBottom: '20px' }} key={'Q' + question.id}>
                  <QuizQuestionInput
                    question={question}
                    disabled={isLoading}
                    onQuestionChanged={(val) => onQuestionChanged(index, val)}
                    onQuestionRemoved={() => onQuestionRemoved(index)}
                    handleAddImage={(file) => handleAddImage({ file, type: 'QUIZ_QUESTION', id: question.id })}
                    setPreviewImage={setPreviewImage}
                    previewImage={imagesToSave.find((image) => image.type === 'QUIZ_QUESTION' && image.id === question.id)?.file.url}
                  />

                  <div className={styles.previewBlockItem} style={{ borderBottom: '1px solid lightgray' }}>
                    <div className={styles.quizFormRow}>
                      {question.answers.map((answer, answerIndex) => (
                        <QuizQuestionAnswerInput
                          answer={answer}
                          disabled={isLoading}
                          onAnswerChanged={(val) => onAnswerChanged(index, answerIndex, val)}
                          onAnswerRemoved={() => onAnswerRemoved(index, answerIndex)}
                          key={'QA' + answer.id}
                        />
                      ))}
                    </div>

                    <Button
                      className={styles.moreOptionBtn}
                      onClick={() => {
                        addAnswer(index)
                      }}
                      disabled={isLoading}
                    >
                      Add new answer
                    </Button>
                  </div>
                </div>
              ))}
            </div>

            <Button
              className={styles.moreOptionBtn}
              onClick={() => {
                addQuestion()
              }}
              disabled={isLoading}
            >
              Add new question
            </Button>
          </div>

          <div className={styles.quizFormBlock}>
            <Typography style={{ color: 'black', fontWeight: 'bold', paddingBottom: '8px' }}>Results</Typography>

            <div className={styles.quizFormRow}>
              {results.map((result, index) => (
                <div className={styles.quizFormRowItem} key={'R' + result.id}>
                  <QuizResultInput
                    result={result}
                    disabled={isLoading}
                    onResultChanged={(val) => onResultChanged(index, val)}
                    onResultRemoved={() => onResultRemoved(index)}
                    onDescriptionChanged={(val) => onDescriptionChanged(index, val)}
                    onLinkChanged={(val) => onLinkChanged(index, val)}
                    handleAddImage={(file) => handleAddImage({ file, type: 'QUIZ_RESULT', id: result.id })}
                    setPreviewImage={setPreviewImage}
                    previewImage={imagesToSave.find((image) => image.type === 'QUIZ_RESULT' && image.id === result.id)?.file.url}
                  />
                </div>
              ))}
            </div>

            <Button
              className={styles.moreOptionBtn}
              onClick={() => {
                addResult()
              }}
              disabled={isLoading}
            >
              Add new result
            </Button>
          </div>

          {quizLogicType === 'TRIVIA' && (
            <div className={styles.quizFormBlock}>
              <Typography style={{ color: 'black', fontWeight: 'bold', paddingBottom: '8px' }}>Logic</Typography>

              <div className={styles.quizFormRow}>
                {results.map((result, index) => (
                  <div className={styles.quizFormRowItem} key={'RC' + result.id}>
                    <EditResultCorrects
                      result={result}
                      disabled={isLoading}
                      onCountOfCorrectsChanged={(e) => onCountOfCorrectsChanged(index, e)}
                    />
                  </div>
                ))}
              </div>

              <div className={styles.quizFormRow}>
                {questions.map((question, index) => {
                  let correctAnswerIndex = question.answers.findIndex((answer) => answer.isCorrect)

                  if (correctAnswerIndex < 0) {
                    correctAnswerIndex = undefined
                  }
                  return (
                    <div className={styles.quizFormRowItem} key={'QC' + question.id}>
                      Correct answer for `{question.question}`
                      <div className={styles.previewBlockItem}>
                        <div className={styles.quizFormRow}>
                          <Select
                            key='3'
                            placeholder='Please select Correct Answer'
                            onChange={(answerIndex) => onChangeCorrectAnswer(index, +answerIndex)}
                            allowClear
                            value={correctAnswerIndex}
                            style={{ marginBottom: 20 }}
                            disabled={isLoading}
                          >
                            {question.answers.map((answer, answerIndex) => (
                              <Select.Option value={answerIndex} key={'QAC' + answer.id}>
                                {answer.answer}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}

          {quizLogicType === 'MATCH' && (
            <div className={styles.quizFormBlock}>
              <Typography style={{ color: 'black', fontWeight: 'bold', paddingBottom: '8px' }}>Logic</Typography>

              {results.some((result) => typeof result.id === 'string') && (
                <Typography style={{ color: 'red', fontWeight: 'bold', paddingBottom: '8px' }}>
                  Please, save quiz before you will start to fill logic
                </Typography>
              )}

              <div className={styles.quizFormRow}>
                {questions.map((question, index) => (
                  <div className={styles.quizFormRowItem} key={'QL' + question.id}>
                    Choose linked result for `{question.question}`&apos;s answers
                    <div className={styles.previewBlockItem} style={{ marginTop: 8 }}>
                      {question.answers.map((answer, answerIndex) => {
                        let linkedResultIndex = results.findIndex((result) => result.id === answer.quizResultId)

                        if (linkedResultIndex < 0) {
                          linkedResultIndex = undefined
                        }

                        return (
                          <div className={styles.quizFormRow} key={'QAL' + answer.id}>
                            {answer.answer}
                            <div className={styles.quizFormRowItem}>
                              <Select
                                key='3'
                                placeholder='Please select linked result'
                                onChange={(resultIndex) => onLinkAnswerToResult(index, answerIndex, +resultIndex)}
                                allowClear
                                value={linkedResultIndex}
                                style={{ marginBottom: 20 }}
                                disabled={isLoading}
                              >
                                {results.map((result, resultIndex) => (
                                  <Select.Option value={resultIndex} key={'QA' + answer.id + 'RL' + result.id}>
                                    {result.result}
                                  </Select.Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <Modal visible={!!previewImage} title='image' footer={null} onCancel={() => setPreviewImage(undefined)}>
            <img alt='example' style={{ width: '100%' }} src={previewImage} />
          </Modal>

          <div className={styles.buttonsContainer}>
            <Button onClick={handleFormClose} className={styles.createButton}>
              Cancel
            </Button>
            <Button type='primary' htmlType='submit' loading={isLoading}>
              Save
            </Button>
          </div>
        </Form>
      </Layout.Content>
      <Drawer footer={drawerFooter} onClose={handleDrawerClose} title='Duplicate quiz' width='400' visible={isDrawerVisible}>
        <div className={styles.drawer}>
          <article>New Quiz Name</article>
          <Input value={newQuizName} onChange={(e) => setNewQuizName(e.currentTarget.value)} />
        </div>
      </Drawer>
    </Layout>
  )
}

export default connector(EditQuiz)
