import React, { useEffect } from 'react'
import { Button, Drawer, Input, Form } from 'antd'
import notification from 'mrx-notification'
import styles from './UpdateProductColor.module.scss'
import { connector, PropsFromRedux } from './container'

type Props = PropsFromRedux & {
  productColorId?: number
  onClose: () => void
  visible: boolean
}

const UpdateProductColor = (props: Props) => {
  const {
    onClose,
    isLoading,
    productColor,
    productColorId,
    visible,

    fetchProductColor,
    updateProductColor
  } = props

  const [form] = Form.useForm()

  useEffect(() => {
    fetchProductColor(productColorId)
  }, [fetchProductColor, productColorId])

  useEffect(() => {
    if (productColor) {
      form.setFieldsValue({
        name: productColor.name,
        displayName: productColor.displayName
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productColor])

  if (!productColor) {
    return null
  }

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  // TODO - fix any type
  const onFinishHandler = async (values: any) => {
    const isSaved = await updateProductColor({
      productColorId,
      productColorData: {
        name: values.name,
        displayName: values.displayName
      }
    })
    if (isSaved) {
      notification.success({
        message: 'Successfully',
        description: 'Product Color was updated successfully!'
      })
      handleClose()
    }
  }

  return (
    <Drawer title='Update Product Color' width='400' onClose={handleClose} visible={visible}>
      <Form
        layout='vertical'
        hideRequiredMark
        onFinish={onFinishHandler}
        form={form}
        initialValues={{
          name: productColor?.name,
          displayName: productColor?.displayName
        }}
      >
        <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input productColor name!' }]}>
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name='displayName' label='Display Name' rules={[{ required: true, message: 'Please input productColor display name!' }]}>
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right'
          }}
        >
          <Button onClick={handleClose} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default connector(UpdateProductColor)
