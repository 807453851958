import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { CustomGatewaySetting } from '../types'

export type FetchCustomGatewaySettingsForOptionsAction = FSA<undefined, CustomGatewaySetting[], string>
type FetchCustomGatewaySettingsForOptions = (searchText?: string) => MrxThunk<FetchCustomGatewaySettingsForOptionsAction>
type QueryResponse = GraphQLResponse<'customGatewaySettingsForOptions', CustomGatewaySetting[]>

const FETCH_CUSTOM_GATEWAY_SETTINGS_FOR_OPTIONS = `
  query fetchCustomGatewaySettingsForOptions ($workspaceId: Int!) {
    customGatewaySettingsForOptions (workspaceId: $workspaceId) {
      id
      name
    }
  }
`

const fetchCustomGatewaySettingsForOptions: FetchCustomGatewaySettingsForOptions = () => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_CUSTOM_GATEWAY_SETTINGS_FOR_OPTIONS,
    meta: { done: false }
  })

  try {
    const {
      workspaces: {
        activeWorkspace: { id: workspaceId }
      }
    } = getState()
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_CUSTOM_GATEWAY_SETTINGS_FOR_OPTIONS,
      variables: { workspaceId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { customGatewaySettingsForOptions } = data
    if (!customGatewaySettingsForOptions) {
      throw new Error("Can't get Custom Gateway settings!")
    }

    dispatch({
      type: t.FETCH_CUSTOM_GATEWAY_SETTINGS_FOR_OPTIONS,
      payload: customGatewaySettingsForOptions,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_CUSTOM_GATEWAY_SETTINGS_FOR_OPTIONS,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchCustomGatewaySettingsForOptions }
