import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { CustomGatewaySetting } from '../types'

type CustomGatewaySettingsListSelector = (state: ReduxState) => CustomGatewaySetting[]
const getCustomGatewaySettingsList: CustomGatewaySettingsListSelector = state => {
  const result = [] as CustomGatewaySetting[]
  const pageIds = state.customGatewaySettings.pagination.pages[state.customGatewaySettings.pagination.currentPage] || []
  pageIds.forEach(pageId => {
    result.push(state.customGatewaySettings.customGatewaySettings[pageId])
  })
  return result
}

export const customGatewaySettingsListSelector: CustomGatewaySettingsListSelector = createSelector(
  getCustomGatewaySettingsList,
  list => list
)
