import React, { useEffect } from 'react'
import { Button, Drawer, Input, Form } from 'antd'
import notification from 'mrx-notification'
import styles from './UpdateRegion.module.scss'
import { connector, PropsFromRedux } from './container'

type Props = PropsFromRedux & {
  countryId?: number
  regionId?: number
  onClose: () => void
  visible: boolean
}

const UpdateCountry = (props: Props) => {
  const {
    onClose,
    isLoading,
    region,
    regionId,
    countryId,
    visible,

    fetchRegion,
    updateRegion
  } = props

  const [form] = Form.useForm()

  useEffect(() => {
    if (countryId !== undefined) {
      fetchRegion(countryId, regionId)
    }
  }, [fetchRegion, regionId, countryId])

  useEffect(() => {
    if (region) {
      form.setFieldsValue({
        countryId: region.countryId,
        name: region.name,
        iso2: region.iso2
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region])

  if (!region) {
    return null
  }

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  // TODO - fix any type
  const onFinishHandler = async (values: any) => {
    const isSaved = await updateRegion({
      countryId,
      regionId,
      regionData: {
        countryId: values.countryId,
        name: values.name,
        iso2: values.iso2
      }
    })
    if (isSaved) {
      notification.success({
        message: 'Successfully',
        description: 'Region was updated successfully!'
      })
      handleClose()
    }
  }

  return (
    <Drawer title='Update Region' width='400' onClose={handleClose} visible={visible}>
      <Form
        layout='vertical'
        hideRequiredMark
        onFinish={onFinishHandler}
        form={form}
        initialValues={{
          countryId: region.countryId,
          name: region.name,
          iso2: region.iso2
        }}
      >
        <Form.Item name='countryId' label='Country' noStyle rules={[{ required: true, message: 'Please select country!' }]}>
          <Input type='hidden' />
        </Form.Item>

        <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input region name!' }]}>
          <Input style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item name='iso2' label='ISO 2' rules={[{ required: true, message: 'Please input ISO 2!' }]}>
          <Input style={{ width: '100%' }} />
        </Form.Item>

        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right'
          }}
        >
          <Button onClick={handleClose} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default connector(UpdateCountry)
