import * as t from '../actionTypes'
import { Moment } from 'moment-timezone'

export type ChangeSubscriptionStatsDatesRangeAction = Required<Omit<BaseFSA<[Moment, Moment]>, 'meta'>>
type SubscriptionStatsChangeDatesRange = (range: [Moment, Moment]) => MrxThunk<ChangeSubscriptionStatsDatesRangeAction, Promise<void>>

export const subscriptionStatsChangeDatesRange: SubscriptionStatsChangeDatesRange = range => async dispatch => {
  dispatch({
    type: t.CHANGE_SUBSCRIPTION_STATS_DATES_RANGE,
    payload: [range[0].utc(), range[1].utc()]
  })
}
