import { createSelector, ParametricSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { ProductColor } from '../types'

const getProductColor: ParametricSelector<ReduxState, number | undefined, ProductColor | undefined> = (state, productColorId) => {
  let result
  if (productColorId && Object.prototype.hasOwnProperty.call(state.productColors.productColors, productColorId)) {
    result = state.productColors.productColors[productColorId] as ProductColor
  }
  return result
}

export const productColorSelector = createSelector(getProductColor, (productColor) => productColor)
