import React, { useEffect } from 'react'
import useReactRouter from 'use-react-router'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Table, Layout, PageHeader, Popconfirm } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { DTGVariantDto } from '@merchx-v2/shared-types/dist/dto/dtgVariants/dtg-variant.dto'
import notification from 'mrx-notification'
import { Breadcrumbs } from 'components'
import * as rootStyles from 'assets/layoutStyle'
import { useDTGVariantsList, useRemoveDTGVariant } from '../../hooks'
import styles from './DTGVariantList.module.scss'
import CreateDTGVariant from '../CreateDTGVariant'

const DTGVariantsList = () => {

  const { history } = useReactRouter()

  const dtgVariantsList = useDTGVariantsList()
  const removeDTGVariant = useRemoveDTGVariant()

  const dtgVariants: DTGVariantDto[] = []

  dtgVariantsList &&
  dtgVariantsList.data &&
  dtgVariantsList.data.pages.forEach((page) => page.items.forEach((currency) => dtgVariants.push(currency)))

  const page = dtgVariantsList.data?.pages.length ? dtgVariantsList.data?.pages[dtgVariantsList.data?.pages.length - 1].currentPage : 1
  const size = dtgVariantsList.data?.pages.length ? dtgVariantsList.data?.pages[dtgVariantsList.data?.pages.length - 1].pageSize : 30

  useEffect(() => {
    return () => dtgVariantsList.remove()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (dtgVariantsList.error) {
      notification.error({
        message: 'DTG Variants list page error!',
        description: dtgVariantsList.error instanceof Error ? dtgVariantsList.error.message : dtgVariantsList.error.toString()
      })
    }
  }, [dtgVariantsList.error])

  useEffect(() => {
    if (removeDTGVariant.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'DTG Variant was removed successfully!'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeDTGVariant.isSuccess])

  const doConfirm = async (DTGVariantId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    removeDTGVariant.mutate(DTGVariantId)
  }

  const columns: ColumnsType<DTGVariantDto> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'DTG Product',
      render: (_value, record) => record.DTGProduct.name,
      key: 'DTGProduct.name'
    },
    {
      title: 'External Variant Id',
      render: (_value, record) => record.externalVariantId,
      key: 'size'
    },
    {
      title: 'Variant Key',
      dataIndex: 'variantKey',
      key: 'variantKey'
    },
    {
      title: 'Action',
      key: 'x',
      render: (_value, record) => (
        <span>
          <Popconfirm
            title='Are you sure delete this variant?'
            onConfirm={(e) => doConfirm(record.id, e)}
            onCancel={(e) => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <a
              href='/#'
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              Remove
            </a>
          </Popconfirm>
        </span>
      )
    }
  ]

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader onBack={() => history.goBack()} title='DTG Sizes List' extra={[<CreateDTGVariant key='1' />]} />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Catalogs', url: '/' },
            { title: 'DTG Sizes List', url: '/' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <InfiniteScroll
          style={{ paddingBottom: '30px' }}
          dataLength={page * size}
          next={dtgVariantsList.fetchNextPage}
          hasMore={!!dtgVariantsList.hasNextPage}
          loader={<h4>Loading...</h4>}
          refreshFunction={dtgVariantsList.refetch}
          scrollThreshold={0.8}
        >
          <Table
            columns={columns}
            dataSource={dtgVariants.map((item) => item).flat()}
            bordered
            rowKey={(record) => record.id}
            loading={false}
            pagination={false}
          />
        </InfiniteScroll>
      </Layout.Content>
    </Layout>
  )
}

export default DTGVariantsList
