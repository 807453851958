import React, { useEffect } from 'react'
import useReactRouter from 'use-react-router'
import { Table, Popconfirm, Layout, PageHeader } from 'antd'
import notification from 'mrx-notification'
import { ColumnsType } from 'antd/lib/table'
import useHeightResize from 'utils/useHeightResize'
import { Breadcrumbs } from 'components'
import * as rootStyles from 'assets/layoutStyle'
import styles from './NginxSettingsList.module.scss'
import { connector, PropsFromRedux } from './container'
import { NginxSetting } from '../../types'
import CreateNginxSetting from '../CreateNginxSetting'

type HeightResizeRule = [number, number, number]
const rules: HeightResizeRule[] = [
  [0, 800, 8],
  [800, Infinity, 10]
]

const NginxSettingsList = (props: PropsFromRedux) => {
  const {
    nginxSettings,
    isLoading,
    error,
    isNeedToFetchList,
    total,
    currentPage,

    changeCurrentPage,
    changePageSize,
    fetchNginxSettingsList,
    removeNginxSetting
  } = props

  const { history } = useReactRouter()

  const handleChangePageSize = (newSize: number) => {
    changePageSize(newSize)
    fetchNginxSettingsList()
  }

  useHeightResize(handleChangePageSize, rules)

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Nginx settings list page error!',
        description: error
      })
    }
  }, [error])

  useEffect(() => {
    isNeedToFetchList && fetchNginxSettingsList()
  }, [fetchNginxSettingsList, isNeedToFetchList])

  const doConfirm = async (nginxSettingId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    const isRemoved = await removeNginxSetting(nginxSettingId)
    if (isRemoved) {
      notification.success({
        message: 'Successfully',
        description: 'Nginx setting was deleted successfully!'
      })
    }
  }

  const columns: ColumnsType<NginxSetting> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Host',
      dataIndex: 'host',
      key: 'host'
    },
    {
      title: 'SSH port',
      dataIndex: 'port',
      key: 'port'
    },
    {
      title: 'User name',
      dataIndex: 'userName',
      key: 'userName'
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (_value, record) => (
        <span>
          <Popconfirm
            title='Are you sure delete this setting?'
            onConfirm={(e) => doConfirm(record.id, e)}
            onCancel={(e) => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <a
              href='/#'
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              Remove
            </a>
          </Popconfirm>
        </span>
      )
    }
  ]

  return (
    <Layout className={styles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader onBack={() => history.goBack()} title='Nginx Settings List' extra={[<CreateNginxSetting key='1' />]} />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Settings', url: '/' },
            { title: 'Plugins', url: '' },
            { title: 'Nginx Settings', url: '/settings/plugins' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <Table
          columns={columns}
          dataSource={nginxSettings}
          bordered
          rowKey={(record) => record.id}
          loading={isLoading}
          pagination={{
            onChange: changeCurrentPage,
            current: currentPage,
            total
          }}
        />
      </Layout.Content>
    </Layout>
  )
}

export default connector(NginxSettingsList)
