import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

type OwnProps = {
  categoryId?: number
  match?: {
    params?: {
      campaignId?: number
      categoryId?: number
    }
  }
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  let { categoryId } = ownProps

  if (!categoryId && ownProps?.match?.params?.categoryId) {
    categoryId = +ownProps.match.params.categoryId
  }

  const campaignId = +ownProps?.match?.params?.campaignId

  return {
    categoryId,
    isLoading: selectors.isLoadingSelector(state, 'isCreateCategory') || selectors.isLoadingSelector(state, 'isUpdateCategory'),
    campaignId: +ownProps?.match?.params?.campaignId,
    category: selectors.categorySelector(state, campaignId, categoryId),
    error: selectors.errorSelector(state)
  }
}

const mapDispatchToProps = {
  createCategory: actions.createCategory,
  fetchCategory: actions.fetchCategory,
  updateCategory: actions.updateCategory
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
