import React, { useState, useEffect, useContext } from 'react'
import moment, { Moment } from 'moment'
import { Table, Layout, PageHeader, Input, Tag, Drawer, Form, Select, Button, DatePicker } from 'antd'
import { EyeTwoTone, DownloadOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table'
import * as QueryString from 'query-string'
import notification from 'mrx-notification'
import InfiniteScroll from 'react-infinite-scroll-component'
import useReactRouter from 'use-react-router'
import { GlobalContext } from 'appContexts'
import { Breadcrumbs } from 'components'
import * as rootStyles from 'assets/layoutStyle'

import { Customer } from '../../types'
import { useCustomersList, useCustomersListCsv } from '../../hooks'
import styles from './CustomersList.module.scss'
import useDebounce from 'utils/useDebounce'

const tagDescriptions = {
  CHARGEBACK: { color: 'darkred', title: 'Chargeback' },
  PURCHASER: { color: 'blue', title: 'Purchaser' },
  ABANDONED_CART: { color: 'orange', title: 'Abandoned Cart' },
  AC_PURCHASER: { color: 'geekblue', title: 'AC Purchaser' },
  MULTI_PURCHASER: { color: 'green', title: 'Multipurchaser' },
  SOFT_DECLINED: { color: 'yellow', title: 'Soft Declined' },
  HARD_DECLINED: { color: 'volcano', title: 'Hard Declined' },
  READ_EMAILS: { color: 'purple', title: 'Read Emails' },
  EMAIL_BOUNCED: { color: 'gold', title: 'Email Bounced' },
  CLICK_IN_EMAILS: { color: 'purple', title: 'Click in Emails' },
  AVAILABLE_BY_EMAIL: { color: 'blue', title: 'Available by Email' },
  EMAIL_DROPPED: { color: 'gold', title: 'Email Dropped' },
  REPORTED_AS_A_SPAM: { color: 'volcano', title: 'Reported as a Spam' },
  UNSUBSCRIBED: { color: 'volcano', title: 'Unsubscribed from VIP' },
  UNSUBSCRIBED_EMAIL: { color: 'volcano', title: 'Unsubscribed from emails' },
  SUBSCRIBED: { color: 'cornflowerBlue', title: 'VIP' },
  VERIFIED: { color: 'cyan', title: '✓ Email verified' },
  ECOM_PURCHASER: { color: 'blue', title: 'Ecom Purchaser' },
  LANDER_PURCHASER: { color: 'blue', title: 'Lander Purchased' }
}

const dateFormat = 'MM/DD/YYYY'

const CustomersList = () => {
  const [searchQuery, setSearchQuery] = useState<string>()
  const [selectedTags, setSelectedTags] = useState()
  const [sorting, setSorting] = useState<SortingType>({ field: 'id', order: 'DESC' })
  const [isVisible, setVisible] = useState(false)
  const [emailStatuses, setEmailStatuses] = useState([])
  const [datesRange, setDatesRange] = useState<[Moment, Moment]>()
  const [emailContains, setEmailContains] = useState<string>()
  const [phoneContains, setPhoneContains] = useState<string>()

  const debouncedSearchQuery = useDebounce(searchQuery, 300)
  const debouncedEmailContains = useDebounce(emailContains, 300)
  const debouncedPhoneContains = useDebounce(phoneContains, 300)

  const { workspaceId } = useContext(GlobalContext)

  const { history } = useReactRouter()
  const [form] = Form.useForm()

  useEffect(() => {
    const filtersFromParams = QueryString.parse(history.location.search, { arrayFormat: 'bracket' })

    if (typeof filtersFromParams.searchQuery === 'string') {
      setSearchQuery(filtersFromParams.searchQuery)
    }
    if (typeof filtersFromParams.emailContains === 'string') {
      setEmailContains(filtersFromParams.emailContains)
    }
    if (typeof filtersFromParams.phoneContains === 'string') {
      setPhoneContains(filtersFromParams.phoneContains)
    }
    if (filtersFromParams.selectedTags?.length) {
      setSelectedTags(filtersFromParams.selectedTags as any)
    }
    if (typeof filtersFromParams.sorting === 'string') {
      setSorting(JSON.parse(filtersFromParams.sorting) as unknown as SortingType)
    }
    if (filtersFromParams.emailStatuses?.length) {
      setEmailStatuses(filtersFromParams.emailStatuses as any)
    }
    if (filtersFromParams.datesRange?.length) {
      // @ts-ignore
      const data = filtersFromParams.datesRange.map((time) => moment(+time))
      setDatesRange(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const data = datesRange?.map((time) => time.toDate().getTime())
    const url = QueryString.stringify(
      {
        searchQuery: debouncedSearchQuery,
        selectedTags,
        sorting: JSON.stringify(sorting),
        emailStatuses,
        datesRange: data,
        emailContains: debouncedEmailContains,
        phoneContains: debouncedPhoneContains
      },
      { skipEmptyString: true, arrayFormat: 'bracket' }
    )
    history.push(`${history.location.pathname}?${url}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery, selectedTags, sorting, emailStatuses, datesRange, debouncedEmailContains, debouncedPhoneContains])

  const customersList = useCustomersList({
    workspaceId,
    query: debouncedSearchQuery,
    customerTags: selectedTags,
    sorting,
    startDate: datesRange && datesRange[0].toDate(),
    endDate: datesRange && datesRange[1].toDate()
  })

  const customers: Customer[] = []
  customersList &&
    customersList.data &&
    customersList.data.pages.forEach((page) => page.items.forEach((customer) => customers.push(customer)))

  const page = customersList.data?.pages.length ? customersList.data?.pages[customersList.data?.pages.length - 1].currentPage : 1
  const size = customersList.data?.pages.length ? customersList.data?.pages[customersList.data?.pages.length - 1].pageSize : 30

  const customerListCsv = useCustomersListCsv({
    workspaceId,
    tags: selectedTags,
    emailStatuses,
    emailContains,
    phoneContains,
    startDate: datesRange && datesRange[0].toDate(),
    endDate: datesRange && datesRange[1].toDate()
  })

  useEffect(() => {
    customerListCsv.data && window.open(customerListCsv.data, '_blank')
  }, [customerListCsv.data])

  useEffect(() => {
    if (customersList.error) {
      notification.error({
        message: 'Customers list page error!',
        description: customersList.error.message
      })
    }
  }, [customersList.error])

  useEffect(() => {
    if (customerListCsv.error) {
      notification.error({
        message: 'Customers list page error!',
        description: customerListCsv.error.message
      })
    }
  }, [customerListCsv.error])

  const sortingHandler = (pagination, filters, sorter) => {
    if (sorter?.order) {
      setSorting({ field: sorter.field, order: sorter.order === 'ascend' ? 'ASC' : 'DESC' })
    } else {
      setSorting(undefined)
    }
  }

  const columns: ColumnsType<Customer> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      sortOrder: sorting?.field === 'id' ? (sorting.order === 'ASC' ? 'ascend' : 'descend') : undefined
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
      sortOrder: sorting?.field === 'firstName' ? (sorting.order === 'ASC' ? 'ascend' : 'descend') : undefined
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: true,
      sortOrder: sorting?.field === 'lastName' ? (sorting.order === 'ASC' ? 'ascend' : 'descend') : undefined
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      sortOrder: sorting?.field === 'email' ? (sorting.order === 'ASC' ? 'ascend' : 'descend') : undefined
    },
    {
      title: 'Phone',
      dataIndex: 'phone'
    },
    {
      title: 'Tag',
      render: (record) =>
        record.customerTags?.map((item: any) => {
          return (
            // @ts-ignore
            <Tag color={tagDescriptions[item.tag].color} key={item.id}>
              {tagDescriptions[item.tag].title}
            </Tag>
          )
        }),
      key: 'tag'
    },
    {
      title: 'Orders',
      dataIndex: 'numberOfOrders',
      key: 'numberOfOrders'
    },
    {
      title: 'Subscriptions',
      dataIndex: 'numberOfSubscriptions',
      key: 'numberOfSubscriptions'
    },
    {
      title: 'Notes',
      dataIndex: 'numberOfNotes'
    },
    {
      title: '',
      dataIndex: '',
      key: 'edit',
      width: '7%',
      align: 'center',
      render: (record) => (
        <span>
          <EyeTwoTone
            style={{ fontSize: '20px' }}
            onClick={(e) => {
              e.stopPropagation()
              history.push(`/sales/customers/${record.id}`)
            }}
          />
        </span>
      )
    }
  ]

  const handleDrawerClose = () => {
    setVisible(false)
  }

  const handleTagChange = (value) => {
    if (value.length === 0) {
      setSelectedTags(undefined)
    } else {
      setSelectedTags(value)
    }
  }

  const handleEmailStatusChange = (value) => {
    setEmailStatuses(value)
  }

  const onSearchInputChangeHandler = (value) => {
    setSearchQuery(value)
  }

  const handleExportCSV = () => customerListCsv.refetch()

  const handleSetDatesRange = (value) => {
    setDatesRange(value)
  }

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title='Customers'
          extra={[
            <Button key='1' onClick={() => setVisible(!isVisible)}>
              Settings
            </Button>,
            <Input.Search
              key='2'
              placeholder='Input search text'
              onChange={(el) => onSearchInputChangeHandler(el.target.value)}
              enterButton='Search'
              style={{ width: 200 }}
              value={searchQuery}
              allowClear
            />,
            <Button
              key='csv'
              icon={<DownloadOutlined />}
              disabled={customerListCsv.isLoading}
              loading={customerListCsv.isLoading}
              onClick={handleExportCSV}
            >
              CSV
            </Button>
          ]}
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Sales', url: '/' },
            { title: 'Customers', url: '/' }
          ]}
        />
      </Layout.Content>
      <div className={styles.contentComponent}>
        <InfiniteScroll
          dataLength={page * size}
          next={customersList.fetchNextPage}
          hasMore={!!customersList.hasNextPage}
          loader={<h4>Loading...</h4>}
          refreshFunction={customersList.refetch}
          scrollThreshold={0.8}
        >
          <Table
            columns={columns}
            dataSource={customers.map((item) => item).flat()}
            bordered
            rowKey={(record) => record.id}
            loading={customersList.isLoading}
            onChange={sortingHandler}
            pagination={false}
          />
        </InfiniteScroll>
      </div>
      <Drawer forceRender title='Customers Data Settings' width='400' onClose={handleDrawerClose} visible={isVisible}>
        <Form layout='vertical' hideRequiredMark form={form} initialValues={{ customerTags: selectedTags }}>
          <Form.Item label='Dates range'>
            <DatePicker.RangePicker
              className={styles.rangePicker}
              defaultValue={datesRange}
              value={datesRange}
              format={dateFormat}
              ranges={{
                Today: [moment().startOf('day'), moment().add(1, 'day').endOf('day')],
                'Last 7 days': [moment().subtract(7, 'days'), moment().startOf('day')],
                'This Week': [moment().startOf('week'), moment().endOf('week')],
                'This Month': [moment().startOf('month'), moment().endOf('month')]
              }}
              onChange={handleSetDatesRange}
            />
          </Form.Item>
          <Form.Item label='Customer Tags'>
            <Select
              mode='multiple'
              placeholder='Please select Tag'
              onChange={handleTagChange}
              allowClear
              value={selectedTags}
              defaultValue={selectedTags}
            >
              <Select.Option value='ABANDONED_CART' key='ABANDONED_CART'>
                Abandoned Cart
              </Select.Option>
              <Select.Option value='PURCHASER'>Purchaser</Select.Option>
              <Select.Option value='AC_PURCHASER'>AC Purchaser</Select.Option>
              <Select.Option value='MULTI_PURCHASER'>Multipurchaser</Select.Option>
              <Select.Option value='SOFT_DECLINED'>Soft Declined</Select.Option>
              <Select.Option value='HARD_DECLINED'>Hard Declined</Select.Option>
              <Select.Option value='READ_EMAILS'>Read Emails</Select.Option>
              <Select.Option value='EMAIL_BOUNCED'>Emails Bounced</Select.Option>
              <Select.Option value='CLICK_IN_EMAILS'>Click in emails</Select.Option>
              <Select.Option value='AVAILABLE_BY_EMAIL'>Available by email</Select.Option>
              <Select.Option value='EMAIL_DROPPED'>Email Dropped</Select.Option>
              <Select.Option value='REPORTED_AS_A_SPAM'>Reported as a Spam</Select.Option>
              <Select.Option value='UNSUBSCRIBED'>Unsubscribed from VIP</Select.Option>
              <Select.Option value='UNSUBSCRIBED_EMAIL'>Unsubscribed from emails</Select.Option>
              <Select.Option value='SUBSCRIBED'>VIP</Select.Option>
              <Select.Option value='CHARGEBACK'>Chargeback</Select.Option>
              <Select.Option value='VERIFIED'>Verified</Select.Option>
              <Select.Option value='ECOM_PURCHASER'>Ecom Purchaser</Select.Option>
              <Select.Option value='LANDER_PURCHASER'>Lander Purchaser</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label='Email Statuses'>
            <Select mode='multiple' onChange={handleEmailStatusChange} allowClear value={emailStatuses}>
              <Select.Option value='VALID'>Valid</Select.Option>
              <Select.Option value='INVALID'>Invalid</Select.Option>
              <Select.Option value='CATCH_ALL'>Catch all</Select.Option>
              <Select.Option value='SPAMTRAP'>Spamtrap</Select.Option>
              <Select.Option value='ABUSE'>Abuse</Select.Option>
              <Select.Option value='DO_NOT_MAIL'>Do not mail</Select.Option>
              <Select.Option value='UNKNOWN'>Uncnown</Select.Option>
              <Select.Option value='DEFAULT'>Not checked</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label='E-mail contains'>
            <Input onChange={(el) => setEmailContains(el.target.value)} value={emailContains} />
          </Form.Item>
          <Form.Item label='Phone contains'>
            <Input onChange={(el) => setPhoneContains(el.target.value)} value={phoneContains} />
          </Form.Item>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button type='primary' htmlType='submit' loading={customersList.isLoading} onClick={handleDrawerClose}>
              Close
            </Button>
          </div>
        </Form>
      </Drawer>
    </Layout>
  )
}

export default CustomersList
