import { UserDto } from '@merchx-v2/shared-types'
import { useQuery } from 'react-query'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'user', UserDto>

// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchUser = (userId: number) => Promise<UserDto>

const fetchUser: FetchUser = async (userId) => {
  try {
    const FETCH_USER = `
query User ($userId: Int!) {
  user (userId: $userId) {
    id
    email
    avatar
    firstName
    lastName
    lastLoginAt
    token
    refreshToken
    roles
    workspacesRoles {
      workspaceId
      role
    }
    workspaces {
      id
      name
    }
    defaultWorkspaceId
    activeWorkspace {
      id
      name
    }
    defaultCampaignId
    bannedTill
    isEmailConfirmed
    rememberMe
    createdAt
    updatedAt
  }
}
`
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_USER,
      variables: { userId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { user } = data
    if (!user) {
      throw new Error("Can't get user!")
    }

    return user
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUser = (userId: number) => useQuery(['user', userId], () => fetchUser(userId))
