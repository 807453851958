import * as t from '../actionTypes'

type Payload = {
  status: boolean
  landingId: number
}

export type UpdateLandingStatusAction = BaseFSA<Payload>
type UpdateLandingStatus = (landingId: number, status?: boolean) => MrxThunk<UpdateLandingStatusAction>

const updateLandingStatus: UpdateLandingStatus =
  (landingId, status = true) =>
  async (dispatch) => {
    dispatch({
      type: t.UPDATE_LANDING_STATUS,
      payload: {
        landingId,
        status
      }
    })
  }

export { updateLandingStatus }
