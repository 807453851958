import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { CustomGatewaySetting } from '../types'

type CustomGatewaySettingSelector = (state: ReduxState, customGatewaySettingId?: number) => CustomGatewaySetting | undefined

const getCustomGatewaySetting: CustomGatewaySettingSelector = (state, customGatewaySettingId) => {
  let result
  if (
    customGatewaySettingId &&
    Object.prototype.hasOwnProperty.call(state.customGatewaySettings.customGatewaySettings, customGatewaySettingId)
  ) {
    result = state.customGatewaySettings.customGatewaySettings[customGatewaySettingId] as CustomGatewaySetting
  }
  return result
}

export const customGatewaySettingSelector: CustomGatewaySettingSelector = createSelector(
  getCustomGatewaySetting,
  (customGatewaySetting) => customGatewaySetting
)
