import { io, Socket } from 'socket.io-client'
import { ClientToServerEvents, ServerToClientEvents } from '@merchx-v2/web-socket'
import settings from 'config/settings'

export type MerchxWebSocket = Socket<ServerToClientEvents, ClientToServerEvents>
let webSocket: MerchxWebSocket

const getWebSocket = () => {
  if (webSocket) {
    return webSocket
  }

  webSocket = io(settings.backendUrl, { transports: ['websocket'] })

  return webSocket
}

export { getWebSocket }
