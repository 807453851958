import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { CreateShippingMethodDto, ShippingMethodDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'createShippingMethod', ShippingMethodDto>

const CREATE_SHIPPING_METHOD = `
  mutation createShippingMethod ($shippingMethodData: ShippingMethodInput!) {
    createShippingMethod(shippingMethodData: $shippingMethodData) {
      id
      carrierCode
      serviceCode
      isDefault
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type CreateShippingMethod = (shippingMethodData: CreateShippingMethodDto) => Promise<ShippingMethodDto>

const createShippingMethod: CreateShippingMethod = async shippingMethodData => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_SHIPPING_METHOD,
      variables: { shippingMethodData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createShippingMethod } = data
    if (!createShippingMethod) {
      throw new Error("Can't create shippingMethod!")
    }

    return createShippingMethod
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type CreateShippingMethodEvents = {
  onSettled: (data?: ShippingMethodDto) => void
}

const createShippingMethodEvents: CreateShippingMethodEvents = {
  onSettled: shippingMethod => {
    if (shippingMethod?.id) {
      queryClient.invalidateQueries(['shippingMethodsList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCreateShippingMethod = () => useMutation(createShippingMethod, createShippingMethodEvents)
