import { SubscribeResult } from '@merchx-v2/web-socket/dist/webSocket/types/subscribeResult'
import * as t from '../actionTypes'

export type WebSocketUnsubscribeFromChannelResultAction = BaseFSA<SubscribeResult>
type WebSocketUnsubscribeFromChannelResult = (subscribeResult: SubscribeResult) => MrxThunk<WebSocketUnsubscribeFromChannelResultAction>

const unsubscribeFromChannelResult: WebSocketUnsubscribeFromChannelResult = subscribeResult => async dispatch => {
  console.log('Unsubscribe from channel result', subscribeResult)
  dispatch({
    type: t.UNSUBSCRIBE_FROM_CHANNEL_RESULT,
    payload: subscribeResult,
    meta: { done: true }
  })
}

export { unsubscribeFromChannelResult }
