import React from 'react'
import { SearchableSelectBase } from 'components'
import { PropsFromRedux, connector } from './container'

const DeploymentSettingsSelect = (props: PropsFromRedux) => {
  const { plugin, fetchAwsSettingsOptions, fetchNginxSettingsOptions, ...rest } = props

  switch (plugin) {
    case 'AMAZON':
      return <SearchableSelectBase plugin={plugin} onSearch={fetchAwsSettingsOptions} {...rest} />

    case 'NGINX':
    default:
      return <SearchableSelectBase plugin={plugin} onSearch={fetchNginxSettingsOptions} {...rest} />
  }
}

export default connector(DeploymentSettingsSelect)
