import React, { useEffect } from 'react'
import { Button, Drawer, Input, Form } from 'antd'
import notification from 'mrx-notification'
import styles from './UpdateAuthorizeSetting.module.scss'
import { connector, PropsFromRedux, OwnProps } from './container'

type UpdateAuthorizeSettingProps = PropsFromRedux & OwnProps
const UpdateAuthorizeSetting = (props: UpdateAuthorizeSettingProps) => {
  const {
    isLoading,
    authorizeSettingId,
    authorizeSetting,

    updateAuthorizeSetting,
    onClose
  } = props

  const [form] = Form.useForm()

  useEffect(() => {
    authorizeSetting && form.setFieldsValue(authorizeSetting)
  }, [authorizeSetting, form])

  const onFinishHandler = async (values: any) => {
    const isSaved = await updateAuthorizeSetting({
      authorizeSettingId,
      authorizeSettingData: {
        name: values.name,
        apiGateway: values.apiGateway,
        apiLoginId: values.apiLoginId,
        transactionKey: values.transactionKey,
        clientKey: values.clientKey,
        signatureKey: values.signatureKey
      }
    })
    if (isSaved) {
      notification.success({
        message: 'Successfully',
        description: 'Authorize Setting was updated successfully!'
      })
      onClose()
    }
  }

  return (
    <Drawer title='Update Authorize Setting' width='400' onClose={onClose} visible={!!authorizeSettingId} forceRender>
      <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
        <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input authorize setting name!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name='apiGateway' label='API Gateway' rules={[{ required: true, message: 'Please input API Gateway!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name='apiLoginId' label='API Login ID' rules={[{ required: true, message: 'Please input API login ID!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name='transactionKey' label='Transaction Key' rules={[{ required: true, message: 'Please input transaction key!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name='clientKey' label='Client Key'>
          <Input />
        </Form.Item>
        <Form.Item name='signatureKey' label='Signature Key'>
          <Input />
        </Form.Item>
        <div className={styles.buttonsContainer}>
          <Button onClick={onClose} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default connector(UpdateAuthorizeSetting)
