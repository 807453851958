import { TaskOwner } from '@merchx-v2/shared-types'
import * as t from '../actionTypes'
import { Task } from '../types'

export type TaskLoadedAction = BaseFSA<{ ownerType: TaskOwner; ownerId: number; task: Task }>

export const taskLoaded =
  (ownerType: TaskOwner, ownerId: number, task: Task): MrxThunk<TaskLoadedAction> =>
  (dispatch) => {
    dispatch({
      type: t.TASK_LOADED,
      payload: {
        ownerType,
        ownerId,
        task
      }
    })
  }
