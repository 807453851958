import React, { useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Table, Layout, PageHeader, Popconfirm } from 'antd'
import { CheckCircleTwoTone } from '@ant-design/icons'
import { ShippingMethodDto } from '@merchx-v2/shared-types'
import { ColumnsType } from 'antd/lib/table'
import notification from 'mrx-notification'
import { Breadcrumbs } from 'components'
import * as rootStyles from 'assets/layoutStyle'

import { useShippingMethodsList, useRemoveShippingMethod } from '../../hooks'
import CreateShippingMethod from '../CreateShippingMethod'
import UpdateShippingMethod from '../UpdateShippingMethod'
import styles from './ShippingMethodsList.module.scss'

const ShippingMethodsList = React.memo(() => {
  const [selectedShippingMethod, setSelectedShippingMethod] = useState<ShippingMethodDto>()
  const [isUpdateDrawerVisible, setIsUpdateDrawerVisible] = useState<boolean>(false)

  const { history } = useReactRouter()

  const removeShippingMethod = useRemoveShippingMethod()
  const shippingMethodsList = useShippingMethodsList()
  const shippingMethods: ShippingMethodDto[] = []
  shippingMethodsList &&
    shippingMethodsList.data &&
    shippingMethodsList.data.pages.forEach((page) => page.items.forEach((shippingMethod) => shippingMethods.push(shippingMethod)))

  const page = shippingMethodsList.data?.pages.length ? shippingMethodsList.data?.pages[shippingMethodsList.data?.pages.length - 1].currentPage : 1
  const size = shippingMethodsList.data?.pages.length ? shippingMethodsList.data?.pages[shippingMethodsList.data?.pages.length - 1].pageSize : 30

  useEffect(() => {
    return () => shippingMethodsList.remove()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (shippingMethodsList.error) {
      notification.error({
        message: 'Shipping methods list page error!',
        description: shippingMethodsList.error instanceof Error ? shippingMethodsList.error.message : shippingMethodsList.error.toString()
      })
    }
  }, [shippingMethodsList.error])

  useEffect(() => {
    if (removeShippingMethod.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Shipping method was removed successfully!'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeShippingMethod.isSuccess])

  const doConfirm = async (shippingMethodId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    removeShippingMethod.mutate(shippingMethodId)
  }

  const handleOnRow = (record: ShippingMethodDto) => {
    return {
      onClick: () => {
        setSelectedShippingMethod(record)
        setIsUpdateDrawerVisible(true)
      }
    }
  }

  const handleOnShippingMethodClose = () => {
    setIsUpdateDrawerVisible(false)
  }

  const columns: ColumnsType<ShippingMethodDto> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Carrier Code',
      dataIndex: 'carrierCode',
      key: 'carrierCode'
    },
    {
      title: 'Service Code',
      dataIndex: 'serviceCode',
      key: 'serviceCode'
    },
    {
      title: 'Default',
      render: (_value, record) => record.isDefault ? <CheckCircleTwoTone /> : '',
      key: 'isDefault'
    },
    {
      title: 'Action',
      key: 'x',
      render: (_value, record) => (
        <span>
          <Popconfirm
            title='Are you sure delete this?'
            onConfirm={(e) => doConfirm(record.id, e)}
            onCancel={(e) => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <a
              href='/#'
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              Remove
            </a>
          </Popconfirm>
        </span>
      )
    }
  ]

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader onBack={() => history.goBack()} title='Shipping Methods List' extra={[<CreateShippingMethod key='1' />]} />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Settings', url: '/' },
            { title: 'Shipping Methods List', url: '/' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <InfiniteScroll
          style={{ paddingBottom: '30px' }}
          dataLength={page * size}
          next={shippingMethodsList.fetchNextPage}
          hasMore={!!shippingMethodsList.hasNextPage}
          loader={<h4>Loading...</h4>}
          refreshFunction={shippingMethodsList.refetch}
          scrollThreshold={0.8}
        >
          <Table
            columns={columns}
            dataSource={shippingMethods.map((item) => item).flat()}
            bordered
            rowKey={(record) => record.id}
            loading={shippingMethodsList.isLoading}
            onRow={handleOnRow}
            pagination={false}
          />
        </InfiniteScroll>
      </Layout.Content>
      {selectedShippingMethod && (
        <UpdateShippingMethod shippingMethod={selectedShippingMethod} onClose={handleOnShippingMethodClose} visible={isUpdateDrawerVisible} />
      )}
    </Layout>
  )
})

export default ShippingMethodsList
