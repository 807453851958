import React, { useEffect } from 'react'
import { Button, Drawer, Input, Form, DatePicker, Typography } from 'antd'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import notification from 'mrx-notification'
import styles from './UpdateAwsCertificate.module.scss'
import { connector, PropsFromRedux } from './container'

type Props = PropsFromRedux & {
  awsCertificateId?: number
  onClose: () => void
  visible: boolean
}

const UpdateAwsCertificate = (props: Props) => {
  const {
    onClose,
    isLoading,
    awsCertificate,
    awsCertificateId,
    visible,

    fetchAwsCertificate,
    updateAwsCertificate
  } = props

  const [form] = Form.useForm()

  useEffect(() => {
    fetchAwsCertificate(awsCertificateId)
  }, [fetchAwsCertificate, awsCertificateId])

  useEffect(() => {
    if (awsCertificate) {
      form.setFieldsValue({
        domainName: awsCertificate.domainName,
        certificateArn: awsCertificate.certificateArn,
        expiringAt: moment(awsCertificate.expiringAt),
        issuedAt: awsCertificate.issuedAt ? moment(awsCertificate.issuedAt) : null
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [awsCertificate])

  if (!awsCertificate) {
    return null
  }

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  // TODO - fix any type
  const onFinishHandler = async (values: any) => {
    const isSaved = await updateAwsCertificate({
      awsCertificateId,
      awsCertificateData: {
        domainName: values.domainName,
        certificateArn: values.certificateArn,
        expiringAt: values.expiringAt,
        issuedAt: values.issuedAt
      }
    })
    if (isSaved) {
      notification.success({
        message: 'Successfully',
        description: 'AWS Certificate was updated successfully!'
      })
      handleClose()
    }
  }

  return (
    <Drawer title='Update AWS Certificate' width='400' onClose={handleClose} visible={visible}>
      <Form
        layout='vertical'
        hideRequiredMark
        onFinish={onFinishHandler}
        form={form}
        initialValues={{
          domainName: awsCertificate.domainName,
          certificateArn: awsCertificate.certificateArn,
          expiringAt: moment(awsCertificate.expiringAt),
          issuedAt: moment(awsCertificate.issuedAt)
        }}
      >
        <Form.Item name='domainName' label='Domain Name' rules={[{ required: true, message: 'Please input domain name!' }]}>
          <Input style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item name='certificateArn' label='ARN' rules={[{ required: true, message: 'Please input ARN!' }]}>
          <Input style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item name='issuedAt' label='Issued At' rules={[{ required: false, message: 'Please input issued at' }]}>
          <DatePicker style={{ width: '100%' }} format='MM/DD/YYYY' />
        </Form.Item>

        <Form.Item name='expiringAt' label='Expiring At' rules={[{ required: true, message: 'Please input expiring at' }]}>
          <DatePicker style={{ width: '100%' }} format='MM/DD/YYYY' />
        </Form.Item>

        <Typography style={{ color: 'black', paddingBottom: '8px' }}>Alternative Names</Typography>
        <Form.List name='alternativeNames'>
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field) => (
                  <Form.Item required={false} key={field.key}>
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Please input alternative name or delete this field.'
                        }
                      ]}
                      noStyle
                    >
                      <Input placeholder='alternative name' style={{ width: '90%' }} />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className='dynamic-delete-button'
                        style={{ margin: '0 8px' }}
                        onClick={() => {
                          remove(field.name)
                        }}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type='dashed'
                    onClick={() => {
                      add()
                    }}
                    style={{ width: '60%' }}
                  >
                    <PlusOutlined /> Add name
                  </Button>
                </Form.Item>
              </div>
            )
          }}
        </Form.List>

        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right'
          }}
        >
          <Button onClick={handleClose} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default connector(UpdateAwsCertificate)
