import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { DTGVariantDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'removeDTGVariant', boolean>

const REMOVE_DTG_VARIANT = `
  mutation removeDTGVariant ($DTGVariantId: Int!) {
    removeDTGVariant(DTGVariantId: $DTGVariantId) 
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveDTGVariant = (DTGVariantId: number) => Promise<number>

const removeDTGVariant: RemoveDTGVariant = async (DTGVariantId) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_DTG_VARIANT,
      variables: {
        DTGVariantId
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeDTGVariant } = data
    if (!removeDTGVariant) {
      throw new Error("Can't remove DTGVariant!")
    }

    return DTGVariantId
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveDTGVariantContext = { prevDTGVariant?: DTGVariantDto }

type RemoveDTGVariantEvents = {
  onMutate: (DTGVariantId: number) => Promise<RemoveDTGVariantContext>
  onError: (error: string, DTGVariantId: number, context: RemoveDTGVariantContext) => void
  onSettled: (DTGVariantId?: number) => void
}

const removeDTGVariantEvents: RemoveDTGVariantEvents = {
  onMutate: async (DTGVariantId) => {
    await queryClient.cancelQueries(['DTGVariant', DTGVariantId])

    // Snapshot the previous value
    const prevDTGVariant = queryClient.getQueryData<DTGVariantDto>(['DTGVariant', DTGVariantId])

    if (prevDTGVariant) {
      queryClient.removeQueries(['DTGVariant', DTGVariantId])
    }

    return { prevDTGVariant }
  },
  onError: (_err, DTGVariantId, context) => {
    if (context?.prevDTGVariant) {
      // Restore currrency on any error
      queryClient.setQueryData<DTGVariantDto>(['DTGVariant', DTGVariantId], context.prevDTGVariant)
    }
  },
  onSettled: (DTGVariantId) => {
    if (DTGVariantId) {
      queryClient.invalidateQueries(['DTGVariant', DTGVariantId])
      queryClient.invalidateQueries(['DTGVariantsList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useRemoveDTGVariant = () => useMutation(removeDTGVariant, removeDTGVariantEvents)
