import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { ProductAttribute } from '../types'

type Payload = {
  productId: number
  productAttribute: ProductAttribute
}
export type CreateProductAttributeAction = FSA<undefined, Payload, string>
type CreateProductAttribute = (
  productIdId: number,
  productAttributeData: ProductAttributeInput
) => MrxThunk<CreateProductAttributeAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'createProductAttribute', ProductAttribute>

const CREATE_PRODUCT_ATTRIBUTE = `
  mutation createProductAttribute ($workspaceId: Int!, $productId: Int!, $productAttributeData: ProductAttributeDataInput!) {
    createProductAttribute (workspaceId: $workspaceId, productId: $productId, productAttributeData: $productAttributeData) {
      id
      attribute
      value
      createdAt
      updatedAt
    }
  }
    `
type ProductAttributeInput = {
  attribute: string
  value: string
}

const createProductAttribute: CreateProductAttribute = (productId, productAttributeData) => async (dispatch, getState) => {
  let isSaved = false

  dispatch({
    type: t.CREATE_PRODUCT_ATTRIBUTE,
    meta: { done: false }
  })

  try {
    const workspaceId = getState()?.workspaces?.activeWorkspace?.id
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_PRODUCT_ATTRIBUTE,
      variables: { workspaceId, productId, productAttributeData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createProductAttribute } = data
    if (!createProductAttribute) {
      throw new Error("Can't create Product Attribute!")
    }

    dispatch({
      type: t.CREATE_PRODUCT_ATTRIBUTE,
      payload: {
        productId,
        productAttribute: createProductAttribute
      },
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.CREATE_PRODUCT_ATTRIBUTE,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { createProductAttribute }
