import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

export type RemoveAuthorizeSettingAction = FSA<undefined, number, string>
type RemoveAuthorizeSettingColor = (authorizeSettingId: number) => MrxThunk<RemoveAuthorizeSettingAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'removeAuthorizeSetting', boolean>

const REMOVE_AUTHORIZE_SETTING = `
  mutation removeAuthorizeSetting ($authorizeSettingId: Int!) {
    removeAuthorizeSetting (authorizeSettingId: $authorizeSettingId)
  }
`

const removeAuthorizeSetting: RemoveAuthorizeSettingColor = (authorizeSettingId) => async (dispatch) => {
  let isRemoved = false

  dispatch({
    type: t.REMOVE_AUTHORIZE_SETTING,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_AUTHORIZE_SETTING,
      variables: { authorizeSettingId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Empty response!')
    }

    const { removeAuthorizeSetting } = data
    if (!removeAuthorizeSetting) {
      throw new Error("Can't remove authorize setting!")
    }

    dispatch({
      type: t.REMOVE_AUTHORIZE_SETTING,
      payload: authorizeSettingId,
      meta: { done: true }
    })

    isRemoved = true
  } catch (err) {
    dispatch({
      type: t.REMOVE_AUTHORIZE_SETTING,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isRemoved
}

export { removeAuthorizeSetting }
