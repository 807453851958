import produce from 'immer'
import * as t from './actionTypes'
import { actionTypes as nt } from 'features/navigation'
import { actionTypes as wt } from 'features/workspaces'
import { isSuccessAction, isErrorAction } from 'types'
import { ProductTagsState } from './types'
import createReducer from 'store/createReducer'
import { addItemToPagination } from 'utils/addItemToPagination'
import { removeItemFromPagination } from 'utils/removeItemFromPagination'
import {
  CreateProductTagAction,
  RemoveProductTagAction,
  FetchProductTagsListAction,
  FetchProductTagsForOptionsAction,
  ChangeCurrentPageAction,
  UpdateProductTagAction
} from './actions'

const initState: ProductTagsState = {
  productTags: {},
  productTagsForOptions: [],
  pagination: {
    pages: {},
    total: 0,
    currentPage: 1,
    size: 10
  },
  UIState: {
    isCreateProductTag: false,
    isRemoveProductTag: false,
    isUpdateProductTag: false,
    isListLoaded: false,
    isProductTagsForOptionsLoaded: false
  }
}

const reducer = createReducer(initState, {
  [t.CREATE_PRODUCT_TAG]: (state, action: CreateProductTagAction) =>
    produce(state, draft => {
      draft.UIState.isCreateProductTag = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isCreateProductTag = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { id } = action.payload
        draft.productTags[id] = action.payload
        addItemToPagination(draft.pagination, id)
        draft.productTagsForOptions && draft.productTagsForOptions.push(action.payload)
      }
    }),

  [t.FETCH_PRODUCT_TAGS_LIST]: (state, action: FetchProductTagsListAction) =>
    produce(state, draft => {
      draft.UIState.isListLoaded = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isListLoaded = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        action.payload.items.forEach(item => {
          draft.productTags[item.id] = item
        })
        const { page, total, items } = action.payload
        draft.pagination.total = total
        draft.pagination.pages[page] = items.map(item => item.id)
      }
    }),

  [t.FETCH_PRODUCT_TAGS_FOR_OPTIONS]: (state, action: FetchProductTagsForOptionsAction) =>
    produce(state, draft => {
      draft.UIState.isProductTagsForOptionsLoaded = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isProductTagsForOptionsLoaded = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.productTagsForOptions = action.payload
      }
    }),

  [t.REMOVE_PRODUCT_TAG]: (state, action: RemoveProductTagAction) =>
    produce(state, draft => {
      draft.UIState.isRemoveProductTag = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isRemoveProductTag = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.productTags[action.payload]) {
          delete draft.productTags[action.payload]
          removeItemFromPagination(draft.pagination, action.payload)
        }
        draft.productTagsForOptions = draft.productTagsForOptions && draft.productTagsForOptions.filter(item => item.id !== action.payload)
      }
    }),

  [t.UPDATE_PRODUCT_TAG]: (state, action: UpdateProductTagAction) =>
    produce(state, draft => {
      draft.UIState.isUpdateProductTag = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isUpdateProductTag = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.productTags[action.payload.productTagId]) {
          draft.productTags[action.payload.productTagId].name = action.payload.name
        }
        if (draft.productTagsForOptions.length) {
          draft.productTagsForOptions.find(item => item.id === action.payload.productTagId).name = action.payload.name
        }
      }
    }),

  [t.CHANGE_CURRENT_PAGE]: (state, action: ChangeCurrentPageAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.pagination.currentPage = action.payload
      }
    }),

  [wt.SWITCH_WORKSPACE]: state =>
    produce(state, draft => {
      draft.pagination = initState.pagination
      draft.productTagsForOptions = []
    }),

  [nt.LOCATION_CHANGE]: state =>
    produce(state, draft => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default reducer
