import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

const mapStateToProps = (state: ReduxState) => ({
  isLoading: selectors.isLoadingSelector(state, 'isCreateAuthorizeSetting')
})

const mapDispatchToProps = {
  createAuthorizeSetting: actions.createAuthorizeSetting
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector>
