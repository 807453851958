import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

export type RedeployCampaignLandingsAction = FSA<undefined, boolean, string>
type RedeployCampaignLandings = (campaignId: number, templatesIds: number[]) => MrxThunk<RedeployCampaignLandingsAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'redeployCampaignLandings', boolean>

const REDEPLOY_CAMPAIGN_LANDINGS = `
  mutation RedeployCampaignLandings ($campaignId: Int!, $templatesIds: [Int]!) {
    redeployCampaignLandings(campaignId: $campaignId, templatesIds: $templatesIds)
  }
`

const redeployCampaignLandings: RedeployCampaignLandings = (campaignId, templatesIds) => async (dispatch) => {
  let result = false
  dispatch({
    type: t.REDEPLOY_CAMPAIGN_LANDINGS,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REDEPLOY_CAMPAIGN_LANDINGS,
      variables: { campaignId, templatesIds }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { redeployCampaignLandings } = data
    if (!redeployCampaignLandings) {
      throw new Error("Can't redeploy campaign landings")
    }

    dispatch({
      type: t.REDEPLOY_CAMPAIGN_LANDINGS,
      payload: redeployCampaignLandings,
      meta: { done: true }
    })

    result = true
  } catch (err) {
    dispatch({
      type: t.REDEPLOY_CAMPAIGN_LANDINGS,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return result
}

export { redeployCampaignLandings }
