// TODO - remove becouse of legacy

import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'updateAllLandingsFromTemplate', boolean>

const UPDATE_ALL_LANDINGS_FROM_TEMPLATE = `
  mutation updateAllLandingsFromTemplate($workspaceId: Int!, $templateId: Int) {
    updateAllLandingsFromTemplate(workspaceId: $workspaceId, templateId: $templateId)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateAllLandingsFromTemplateArgs = {
  workspaceId: number
  templateId?: number
}

type UpdateAllLandingsFromTemplate = (args: UpdateAllLandingsFromTemplateArgs) => Promise<number>

const updateAllLandingsFromTemplate: UpdateAllLandingsFromTemplate = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_ALL_LANDINGS_FROM_TEMPLATE,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateAllLandingsFromTemplate } = data
    if (!updateAllLandingsFromTemplate) {
      throw new Error("Can't remove template!")
    }

    return args.templateId
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateAllLandingsFromTemplateEvents = {
  onSettled: (templateId?: number) => void
}

const updateAllLandingsFromTemplateEvents: UpdateAllLandingsFromTemplateEvents = {
  onSettled: (templateId) => {
    if (templateId) {
      // queryClient.invalidateQueries(['templatesList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUpdateAllLandingsFromTemplate = () => useMutation(updateAllLandingsFromTemplate, updateAllLandingsFromTemplateEvents)
