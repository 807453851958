import { createSelector } from 'reselect'
import { featureStateSelector } from './featureStateSelector'
import { ReduxState } from 'store/createRootReducer'
import { AuthenticationState } from '../types'

export const errorSelector = createSelector<ReduxState, AuthenticationState, string | undefined>(
  featureStateSelector,
  state => state.UIState.error
)

export default errorSelector
