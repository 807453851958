import React, { useContext, useEffect, useState } from 'react'
import { Button, Drawer, Input, Form, Tag, Tooltip, Select, Divider, Switch, Upload, Modal } from 'antd'
import { PlusOutlined, UploadOutlined } from '@ant-design/icons'
import notification from 'mrx-notification'
import { GlobalContext } from 'appContexts'
import { UploadFile } from 'antd/lib/upload/interface'
import { DomainsSelect } from 'features/domains/components'
import { useTagsOptions, useSaveTags } from 'features/tags/hooks'
import { useCampaign, useUpdateCampaign } from '../../hooks'
import styles from './UpdateCampaign.module.scss'
import { connector, PropsFromRedux } from './container'

const { TextArea } = Input

type Props = PropsFromRedux & {
  campaignId?: number
  onClose: () => void
  visible: boolean
}

const UpdateCampaign = (props: Props) => {
  const {
    campaignId,
    visible,

    onClose,
    uploadFile
  } = props

  const { workspaceId } = useContext(GlobalContext)

  const campaign = useCampaign(campaignId)
  const updateCampaign = useUpdateCampaign()
  const tagsForOptions = useTagsOptions({ workspaceId, ownerType: 'CAMPAIGN' })
  const saveTags = useSaveTags()

  const DTGAttribute = campaign?.data?.attributes?.find((item) => item.attribute === 'DTG_CAMPAIGN_ID')

  const [form] = Form.useForm()
  const [tagsState, setTagsState] = useState(campaign?.data?.tags || [])
  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [editInputIndex, setEditInputIndex] = useState(-1)
  const [editInputValue, setEditInputValue] = useState('')
  const [editInputRef, setEditInputRef] = useState()
  const [inputRef, setInputRef] = useState()
  const [hasCustomDomainState, setHasCustomDomainState] = useState(false)
  const [isDTGCampaign, setIsDTGCampaign] = useState<boolean>(!!DTGAttribute)
  const [previewImage, setPreviewImage] = useState('')
  const [previewVisible, setPreviewVisible] = useState(false)
  const [imageToSave, setImageToSave] = useState<UploadFile<any>>()

  useEffect(() => {
    setIsDTGCampaign(!!DTGAttribute)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign.data, onClose])

  useEffect(() => {
    if (campaign.data !== null) {
      form.setFieldsValue({
        name: campaign.data?.name,
        defaultDomain: campaign.data?.defaultDomain,
        hasCustomDomain: campaign.data?.hasCustomDomain,
        isDTGCampaign: isDTGCampaign,
        DTGCampaignId: DTGAttribute?.value,
        domainId: campaign.data?.domainId,
        mailingPlugin: campaign.data?.mailingPlugin,
        mailingSettingsId: campaign.data?.mailingSettingsId,
        googleGlobalTag: campaign.data?.googleGlobalTag
      })
      setTagsState(campaign.data?.tags)
      setHasCustomDomainState(campaign.data?.hasCustomDomain)
      setPreviewImage(campaign.data?.faviconUrl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign.data])

  useEffect(() => {
    if (inputVisible === true && inputRef) {
      // @ts-ignore
      inputRef.focus()
    }
  }, [inputVisible, inputRef])

  useEffect(() => {
    if (editInputRef) {
      // @ts-ignore
      editInputRef.focus()
    }
  }, [inputVisible, editInputRef])

  const handleClose = () => {
    setIsDTGCampaign(false)
    onClose()
  }

  if (!campaign) {
    return null
  }

  // TODO - fix any type
  const onFinishHandler = async (values: any) => {
    let isSaved = true
    let faviconUrl = campaign?.data?.faviconUrl

    if (imageToSave) {
      const faviconData = await uploadFile({ ownerId: campaignId, ownerType: 'CAMPAIGN', file: imageToSave })

      isSaved = isSaved && !!faviconData
      faviconUrl = faviconData?.url
      setImageToSave(undefined)
    }

    isSaved &&
      updateCampaign.mutate(
        {
          campaignId,
          campaignData: {
            name: values.name,
            defaultDomain: values.defaultDomain,
            DTGCampaignId: isDTGCampaign ? values.DTGCampaignId : undefined,
            hasCustomDomain: hasCustomDomainState,
            domainId: values.domainId,
            faviconUrl,
            seoTitle: values.seoTitle,
            seoDescription: values.seoDescription,
            seoKeywords: values.seoKeywords,
            mailingSettingsId: values.mailingSettingsId,
            googleGlobalTag: values.googleGlobalTag
          }
        },
        {
          onSuccess: () => {
            saveTags.mutate({ ownerId: campaignId, ownerType: 'CAMPAIGN', tags: tagsState })

            notification.success({
              message: 'Successfully',
              description: 'Campaign was updated successfully!'
            })
            handleClose()
          },
          onError: (error) => {
            notification.error({
              message: 'Error',
              description: error.toString()
            })
          }
        }
      )
  }

  const handleCloseTag = (removedTag) => {
    setTagsState(tagsState.filter((tag) => tag !== removedTag))
  }

  const showInput = () => {
    setInputVisible(true)
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  const handleInputConfirm = () => {
    let tags = tagsState
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue]
    }
    setTagsState(tags)
    setInputVisible(false)
    setInputValue('')
  }

  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value)
  }

  const handleEditInputConfirm = () => {
    const newTags = [...tagsState]
    newTags[editInputIndex] = editInputValue

    setTagsState(newTags)
    setEditInputIndex(-1)
    setEditInputValue('')
  }

  const saveInputRef = (input) => {
    setInputRef(input)
  }

  const saveEditInputRef = (input) => {
    setEditInputRef(input)
  }

  const handleTagsSelectorChange = (value) => {
    if (!tagsState.includes(value)) {
      setTagsState([...tagsState, value])
    }

    form.setFieldsValue({
      tags: ''
    })
  }

  const handleHasOwnDomainChange = () => {
    setHasCustomDomainState(!hasCustomDomainState)
  }

  const handleAddFavicon = async (file) => {
    file.url = URL.createObjectURL(file)
    setPreviewImage(file.url)
    setImageToSave(file)
  }

  return (
    <Drawer bodyStyle={{ padding: 0 }} title='Update Campaign' width='460' onClose={handleClose} visible={visible}>
      <Form
        layout='vertical'
        hideRequiredMark
        onFinish={onFinishHandler}
        form={form}
        className={styles.form}
        initialValues={{
          name: campaign.data?.name,
          isDTGCampaign: isDTGCampaign,
          DTGCampaignId: DTGAttribute?.value,
          defaultDomain: campaign.data?.defaultDomain,
          hasCustomDomain: campaign.data?.hasCustomDomain,
          seoTitle: campaign.data?.seoTitle,
          seoDescription: campaign.data?.seoDescription,
          seoKeywords: campaign.data?.seoKeywords
        }}
      >
        <div className={styles.formItems}>
          <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input campaign name!' }]}>
            <Input style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item name='defaultDomain' label='Default domain' className={styles.groupItem}>
            <Input addonBefore='https://' addonAfter='.merchx.com' disabled={!!campaign?.data?.defaultDomain} />
          </Form.Item>

          <Form.Item name='hasCustomDomain' label='Has custom domain' valuePropName='checked' className={styles.groupItem}>
            <Switch onChange={handleHasOwnDomainChange} />
          </Form.Item>

          {hasCustomDomainState && (
            <Form.Item
              name='domainId'
              label='Domain'
              className={styles.groupItem}
              rules={[{ required: true, message: 'Please select domain!' }]}
            >
              <DomainsSelect
                initialOption={{
                  id: campaign?.data?.domainId || 0,
                  name: campaign?.data?.domain?.name || 'Select a domain'
                }}
                onSelect={(selectedOption: SelectOption) => {
                  form.setFieldsValue({
                    domainId: selectedOption.id
                  })
                }}
              />
            </Form.Item>
          )}

          <Divider className={styles.divider} plain>
            Tags
          </Divider>

          <Form.Item name='tags' label='Tags'>
            <Select style={{ width: '100%' }} placeholder='Choose existing tag' onChange={handleTagsSelectorChange}>
              {tagsForOptions.data?.map((item) => {
                return (
                  <Select.Option value={item} key={item}>
                    {item}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>

          {tagsState &&
            tagsState.map((tag, index) => {
              if (editInputIndex === index) {
                return (
                  <Input
                    ref={saveEditInputRef}
                    key={tag}
                    size='small'
                    style={{ width: '78px', marginRight: '8px', marginTop: '10px', verticalAlign: 'top' }}
                    value={editInputValue}
                    onChange={handleEditInputChange}
                    onBlur={handleEditInputConfirm}
                    onPressEnter={handleEditInputConfirm}
                  />
                )
              }

              const isLongTag = tag.length > 20

              const tagElem = (
                <Tag style={{ userSelect: 'none', marginTop: '10px' }} key={tag} closable onClose={() => handleCloseTag(tag)}>
                  <span
                    onDoubleClick={(e) => {
                      setEditInputIndex(index)
                      setEditInputValue(tag)
                      e.preventDefault()
                    }}
                  >
                    {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                  </span>
                </Tag>
              )
              return isLongTag ? (
                <Tooltip title={tag} key={tag}>
                  {tagElem}
                </Tooltip>
              ) : (
                tagElem
              )
            })}

          {inputVisible && (
            <Input
              ref={saveInputRef}
              type='text'
              size='small'
              // is's not working with scss, just inline
              style={{
                width: '100%',
                marginTop: '15px',
                verticalAlign: 'top',
                height: '30px',
                display: 'flex',
                alignItems: 'center'
              }}
              className={styles.tagInput}
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
            />
          )}
          {!inputVisible && (
            <Tag onClick={showInput} style={{ width: '100%', marginTop: '15px', height: '30px', display: 'flex', alignItems: 'center' }}>
              <PlusOutlined style={{ marginRight: '10px' }} /> CREATE NEW TAG
            </Tag>
          )}

          <Divider plain className={styles.divider}>
            SEO
          </Divider>

          <Form.Item label='Favicon' valuePropName={null} className={styles.groupItem}>
            <div className={styles.uploadSettingImageContainer}>
              {!!(previewImage || imageToSave) && (
                <img
                  src={imageToSave?.url || previewImage}
                  alt=''
                  className={styles.image}
                  onClick={() => {
                    setPreviewVisible(true)
                  }}
                />
              )}
              <div>
                <Upload accept='image/*' customRequest={(info) => handleAddFavicon(info.file)} showUploadList={false}>
                  <Button
                    className={styles.uploadButton}
                    icon={<UploadOutlined />}
                    loading={updateCampaign.isLoading}
                    disabled={updateCampaign.isLoading}
                  >
                    Click to Upload
                  </Button>
                </Upload>
              </div>
            </div>
          </Form.Item>
          <Modal visible={previewVisible} title='Favicon' footer={null} onCancel={() => setPreviewVisible(false)}>
            <img alt='example' style={{ width: '100%' }} src={imageToSave?.url || previewImage} />
          </Modal>

          <Form.Item name='seoTitle' label='Title' className={styles.groupItem}>
            <Input />
          </Form.Item>

          <Form.Item name='seoDescription' label='Description' className={styles.groupItem}>
            <TextArea />
          </Form.Item>

          <Form.Item name='seoKeywords' label='Keywords' className={styles.groupItem}>
            <TextArea />
          </Form.Item>

          <Form.Item name='googleGlobalTag' label='Google Global Tag' className={styles.groupItem}>
            <TextArea />
          </Form.Item>

          <Form.Item name='isDTGCampaign' label='DTG Campaign' className={styles.dtgCampaignSwitch}>
            <Switch checked={isDTGCampaign} onClick={setIsDTGCampaign} />
          </Form.Item>

          {isDTGCampaign && (
            <Form.Item name='DTGCampaignId' label='DTG Campaign ID'>
              <Input />
            </Form.Item>
          )}
        </div>
        <div className={styles.buttonsContainer}>
          <Button onClick={handleClose} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit' loading={updateCampaign.isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default connector(UpdateCampaign)
