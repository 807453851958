import produce from 'immer'
import * as t from './actionTypes'
import { actionTypes as nt } from 'features/navigation'
import { actionTypes as wt } from 'features/workspaces'
import { isSuccessAction, isErrorAction } from 'types'
import { AwsCertificatesState } from './types'
import createReducer from 'store/createReducer'
import { addItemToPagination } from 'utils/addItemToPagination'
import { removeItemFromPagination } from 'utils/removeItemFromPagination'
import {
  ChangeCurrentPageAction,
  ChangePageSizeAction,
  CreateAwsCertificateAction,
  FetchAwsCertificateAction,
  FetchAwsCertificatesListAction,
  RemoveAwsCertificateAction,
  UpdateAwsCertificateAction
} from './actions'

const initState: AwsCertificatesState = {
  awsCertificates: {},
  pagination: {
    pages: {},
    total: 0,
    currentPage: 1,
    size: 10
  },
  UIState: {
    isCreateAwsCertificate: false,
    isFetchAwsCertificate: false,
    isListLoading: false,
    isRemoveAwsCertificate: false,
    isUpdateCertificate: false
  }
}

const reducer = createReducer(initState, {
  [t.CREATE_AWS_CERTIFICATE]: (state, action: CreateAwsCertificateAction) =>
    produce(state, draft => {
      draft.UIState.isCreateAwsCertificate = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isCreateAwsCertificate = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { id } = action.payload
        draft.awsCertificates[id] = action.payload
        addItemToPagination(draft.pagination, id)
      }
    }),

  [t.FETCH_AWS_CERTIFICATES_LIST]: (state, action: FetchAwsCertificatesListAction) =>
    produce(state, draft => {
      draft.UIState.isListLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isListLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        action.payload.items.forEach(item => {
          draft.awsCertificates[item.id] = item
        })
        draft.pagination.total = action.payload.total
        draft.pagination.pages[action.payload.page] = action.payload.items.map(item => item.id)
      }
    }),

  [t.FETCH_AWS_CERTIFICATE]: (state, action: FetchAwsCertificateAction) =>
    produce(state, draft => {
      draft.UIState.isFetchAwsCertificate = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isFetchAwsCertificate = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.awsCertificates[action.payload.id] = action.payload
      }
    }),

  [t.REMOVE_AWS_CERTIFICATE]: (state, action: RemoveAwsCertificateAction) =>
    produce(state, draft => {
      draft.UIState.isRemoveAwsCertificate = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isRemoveAwsCertificate = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.awsCertificates[action.payload]) {
          delete draft.awsCertificates[action.payload]
          removeItemFromPagination(draft.pagination, action.payload)
        }
      }
    }),

  [t.UPDATE_AWS_CERTIFICATE]: (state, action: UpdateAwsCertificateAction) =>
    produce(state, draft => {
      draft.UIState.isUpdateCertificate = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isUpdateCertificate = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { awsCertificateId, awsCertificateData } = action.payload
        if (draft.awsCertificates[awsCertificateId]) {
          draft.awsCertificates[awsCertificateId].domainName = awsCertificateData.domainName
          draft.awsCertificates[awsCertificateId].certificateArn = awsCertificateData.certificateArn
          draft.awsCertificates[awsCertificateId].issuedAt = awsCertificateData.issuedAt
          draft.awsCertificates[awsCertificateId].expiringAt = awsCertificateData.expiringAt
        }
      }
    }),

  [t.CHANGE_CURRENT_PAGE]: (state, action: ChangeCurrentPageAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.pagination.currentPage = action.payload
      }
    }),

  [t.CHANGE_PAGE_SIZE]: (state, action: ChangePageSizeAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.pagination.size = action.payload
      }
    }),

  [wt.SWITCH_WORKSPACE]: state =>
    produce(state, draft => {
      draft.pagination = initState.pagination
    }),

  [nt.LOCATION_CHANGE]: state =>
    produce(state, draft => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default reducer
