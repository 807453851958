import * as t from '../actionTypes'

type ChangeCurrentPagePayload = {
  page: number
  campaignId: number
}
export type ChangeCurrentPageAction = BaseFSA<ChangeCurrentPagePayload>
type ChangeCurrentPage = (page: number, campaignId: number) => MrxThunk<ChangeCurrentPageAction>

const changeCurrentPage: ChangeCurrentPage = (page, campaignId) => async (dispatch) => {
  dispatch({
    type: t.CHANGE_CURRENT_PAGE,
    payload: { page, campaignId }
  })
}

export { changeCurrentPage }
