import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

type AwsSettingInput = {
  name: string
  accessKeyId: string
  secretAccessKey: string
  region: string
}

type Payload = {
  awsSettingId: string
  awsSettingData: AwsSettingInput
}

export type UpdateAwsSettingAction = FSA<undefined, Payload, string>

type UpdateAwsSetting = (args: Payload) => MrxThunk<UpdateAwsSettingAction, Promise<boolean>>

type QueryResponse = GraphQLResponse<'updateAwsSetting', boolean>

const UPDATE_AWS_SETTING = `
  mutation updateAwsSetting ($awsSettingId: Int!, $awsSettingData: AwsSettingInput!) {
    updateAwsSetting (awsSettingId: $awsSettingId, awsSettingData: $awsSettingData)
  }
`

const updateAwsSetting: UpdateAwsSetting = (args) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.UPDATE_AWS_SETTING,
    meta: { done: false }
  })

  try {
    const { awsSettingId, awsSettingData } = args
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_AWS_SETTING,
      variables: { awsSettingId, awsSettingData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateAwsSetting } = data
    if (!updateAwsSetting) {
      throw new Error("Can't update Aws setting!")
    }

    dispatch({
      type: t.UPDATE_AWS_SETTING,
      payload: args,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.UPDATE_AWS_SETTING,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { updateAwsSetting }
