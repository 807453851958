import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { Moment } from 'moment'

type Result = {
  startDate: Moment
  endDate: Moment
}
type ReportDatesSelector = (state: ReduxState) => Result

const getReportDates: ReportDatesSelector = state => {
  const result = {} as Result

  if (state.reports.UIState.startDate) {
    result.startDate = state.reports.UIState.startDate
  }

  if (state.reports.UIState.endDate) {
    result.endDate = state.reports.UIState.endDate
  }

  return result
}

export const reportDatesSelector: ReportDatesSelector = createSelector(getReportDates, reportDates => reportDates)
