import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

type Payload = {
  categoryId: number
  categoryData: CategoryInput
  campaignId: number
}

export type UpdateCategoryAction = FSA<undefined, Payload, string>

type UpdateCategory = (args: Payload) => MrxThunk<UpdateCategoryAction, Promise<boolean>>

type QueryResponse = GraphQLResponse<'updateCategory', boolean>

type CategoryInput = {
  name: string
}

const UPDATE_CATEGORY = `
  mutation updateCategory ($categoryId: Int!, $categoryData: CategoryInput!) {
    updateCategory(categoryId: $categoryId, categoryData: $categoryData)
  }
`

const updateCategory: UpdateCategory = (args) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.UPDATE_CATEGORY,
    meta: { done: false }
  })

  try {
    const { categoryId, categoryData } = args
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_CATEGORY,
      variables: { categoryId, categoryData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateCategory } = data
    if (!updateCategory) {
      throw new Error("Can't update category!")
    }

    dispatch({
      type: t.UPDATE_CATEGORY,
      payload: args,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.UPDATE_CATEGORY,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { updateCategory }
