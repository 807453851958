import { createSelector, ParametricSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { ProductSize } from '../types'

const getProductSize: ParametricSelector<ReduxState, number | undefined, ProductSize | undefined> = (state, productSizeId) => {
  let result
  if (productSizeId && Object.prototype.hasOwnProperty.call(state.productSizes.productSizes, productSizeId)) {
    result = state.productSizes.productSizes[productSizeId] as ProductSize
  }
  return result
}

export const productSizeSelector = createSelector(getProductSize, (productSize) => productSize)
