import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { DTGSize } from '../types'

type DTGSizeListSelector = (state: ReduxState) => DTGSize[]
const getDTGSizesList: DTGSizeListSelector = state => {
  const result = [] as DTGSize[]
  const pageIds = state.DTGSizes.pagination.pages[state.DTGSizes.pagination.currentPage] || []
  pageIds.forEach(pageId => {
    result.push(state.DTGSizes.DTGSizes[pageId])
  })
  return result
}

export const DTGSizeListSelector: DTGSizeListSelector = createSelector(getDTGSizesList, list => list)
