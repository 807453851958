import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { SettingOwner } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Setting, SettingDataInput } from '../types'

type CreateSettingArgs = {
  ownerType: SettingOwner
  ownerId: number
  settingData: SettingDataInput
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'createSetting', Setting>

const CREATE_SETTING = `
mutation createSetting($ownerType: SettingOwnerEnum!, $ownerId: Int!, $settingData: SettingInput!) {
  createSetting(ownerType: $ownerType, ownerId: $ownerId, settingData: $settingData) {
    id
    ownerType
    ownerId
    ownerVersion
    name
    alias
    type
    value
    required
    createdAt
    updatedAt
  }
}
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type CreateSetting = (args: CreateSettingArgs) => Promise<Setting>

const createSetting: CreateSetting = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_SETTING,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createSetting } = data
    if (!createSetting) {
      throw new Error("Can't create setting!")
    }

    return createSetting
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type CreateSettingEvents = {
  onSettled: (data?: Setting) => void
}

const createSettingEvents: CreateSettingEvents = {
  onSettled: (data) => {
    if (data?.id) {
      queryClient.invalidateQueries(['template'])
      queryClient.invalidateQueries(['landing'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCreateSetting = () => useMutation(createSetting, createSettingEvents)
