import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { AuthorizeSetting } from '../types'

type AuthorizeSettingsListSelector = (state: ReduxState) => AuthorizeSetting[]
const getAuthorizeSettingsList: AuthorizeSettingsListSelector = state => {
  const result = [] as AuthorizeSetting[]
  const pageIds = state.authorizeSettings.pagination.pages[state.authorizeSettings.pagination.currentPage] || []
  pageIds.forEach(pageId => {
    result.push(state.authorizeSettings.authorizeSettings[pageId])
  })
  return result
}

export const authorizeSettingsListSelector: AuthorizeSettingsListSelector = createSelector(
  getAuthorizeSettingsList,
  list => list
)
