// TODO - remove becouse of legacy

import React, { useState } from 'react'
import { SearchableSelectBase } from 'components'
import { useTemplatesOptions } from '../../hooks'
import { TemplateTypeEnum } from 'features/types'

type PropsType = {
  initialOption: SelectOption
  disabled?: boolean
  onSelect: (selectOption: SelectOption) => void
  templateType?: TemplateTypeEnum
}

const TemplatesSelect = (props: PropsType) => {
  const { templateType } = props

  const [searchText, setSearchText] = useState<string>('')
  const { data, isLoading } = useTemplatesOptions({ searchText, templateType })

  return (
    <SearchableSelectBase
      {...props}
      options={data?.map((item) => ({ id: item.id, name: item.name }))}
      onSearch={(newSearchText: string) => setSearchText(newSearchText)}
      isLoading={isLoading}
    />
  )
}

export default TemplatesSelect
