// TODO - remove becouse of legacy

import { ColumnsType, ColumnType } from 'antd/lib/table'
import { ProductTagForOptions } from 'features/productTags/types'
import { AdSpendReportRow } from '../../types'

export default (productTags: ProductTagForOptions[]): ColumnsType<AdSpendReportRow> => [
  {
    title: 'Campaign',
    dataIndex: ['campaign', 'name'],
    sorter: (a, b) => (a.campaign.name.toLowerCase() < b.campaign.name.toLowerCase() ? 1 : -1),
    key: 'campaign'
  },
  {
    title: 'Ad Spend',
    render: (_value, record) => `$${(record.totalAdSpend / 100).toFixed(2)}`,
    sorter: (a, b) => (a.totalAdSpend < b.totalAdSpend ? 1 : -1),
    key: 'adSpend'
  },
  {
    title: 'Clicks',
    dataIndex: 'totalClicks',
    sorter: (a, b) => (a.totalClicks < b.totalClicks ? 1 : -1),
    key: 'clicks'
  },
  {
    title: 'Sales',
    children: productTags.length && [
      {
        title: 'All',
        key: 'all',
        render: (_value, record) => record.sales.find((item) => item.productTag === 'All')?.value || 0
      },
      ...productTags.map<ColumnType<AdSpendReportRow>>((productTag) => ({
        title: productTag.name,
        key: productTag.name,
        render: (_value, record) => record.sales.find((item) => item.productTag === productTag.name)?.value || 0
      }))
    ],
    key: 'sales'
  },
  {
    title: 'CPA',
    dataIndex: 'costPerAction',
    sorter: (a, b) => (a.costPerAction < b.costPerAction ? 1 : -1),
    key: 'cpa'
  },
  {
    title: 'CPC',
    dataIndex: 'costPerClick',
    sorter: (a, b) => (a.costPerClick < b.costPerClick ? 1 : -1),
    key: 'cpc'
  },
  {
    title: 'CR',
    dataIndex: 'conversionRate',
    sorter: (a, b) => (a.conversionRate < b.conversionRate ? 1 : -1),
    key: 'cr'
  },
  {
    title: 'Upsell Take Rate',
    dataIndex: 'upsellTakeRate',
    sorter: (a, b) => (a.upsellTakeRate < b.upsellTakeRate ? 1 : -1),
    key: 'upsellTakeRate'
  }
]
