import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { AvailablePluginNames, PluginSettingOwner, PluginType } from '@merchx-v2/shared-types'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

import { PluginSetting } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'removePluginSettings', boolean>

const REMOVE_PLUGIN_SETTINGS = `
mutation removePluginSettings ($workspaceId: Int!, $ownerType: PluginSettingOwner!, $ownerId: Int!, $pluginType: PluginType!, $pluginName: PluginName!) {
  removePluginSettings (workspaceId: $workspaceId, ownerType: $ownerType, ownerId: $ownerId, pluginType: $pluginType, pluginName: $pluginName)
}
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type RemovePluginSettingsArgs = {
  workspaceId: number
  ownerType: PluginSettingOwner
  ownerId: number
  pluginType: PluginType
  pluginName: AvailablePluginNames
}

type RemovePluginSettings = (args: RemovePluginSettingsArgs) => Promise<RemovePluginSettingsArgs>

const removePluginSettings: RemovePluginSettings = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_PLUGIN_SETTINGS,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removePluginSettings } = data
    if (!removePluginSettings) {
      throw new Error("Can't remove plugin settings!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type RemovePluginSettingsContext = { prevPluginSettings?: PluginSetting }

type RemovePluginSettingsEvents = {
  onMutate: (args: RemovePluginSettingsArgs) => Promise<RemovePluginSettingsContext>
  onError: (error: string, args: RemovePluginSettingsArgs, context: RemovePluginSettingsContext) => void
  onSettled: (args?: RemovePluginSettingsArgs) => void
}

const removePluginSettingsEvents: RemovePluginSettingsEvents = {
  onMutate: async (args) => {
    const { workspaceId, ownerType, ownerId, pluginType } = args
    await queryClient.cancelQueries(['pluginSettings', workspaceId, ownerType, ownerId, pluginType])

    // Snapshot the previous value
    const prevPluginSettings = queryClient.getQueryData<PluginSetting>(['pluginSettings', workspaceId, ownerType, ownerId, pluginType])

    if (prevPluginSettings) {
      queryClient.removeQueries(['pluginSettings', workspaceId, ownerType, ownerId, pluginType])
    }

    return { prevPluginSettings }
  },
  onError: (_err, args, context) => {
    const { workspaceId, ownerType, ownerId, pluginType } = args

    if (context?.prevPluginSettings) {
      // Restore plugin settings on any error
      queryClient.setQueryData<PluginSetting>(['pluginSettings', workspaceId, ownerType, ownerId, pluginType], context.prevPluginSettings)
    }
  },
  onSettled: (args) => {
    if (args) {
      const { workspaceId, ownerType, ownerId, pluginType } = args

      queryClient.invalidateQueries(['pluginSettings', workspaceId, ownerType, ownerId, pluginType])
      queryClient.invalidateQueries(['pluginSettingsList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useRemovePluginSettings = () => useMutation(removePluginSettings, removePluginSettingsEvents)
