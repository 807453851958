import axios from 'utils/axios'
import { useQuery } from 'react-query'
import { ShippingRuleActionDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'shippingRuleAction', ShippingRuleActionDto>

const FETCH_SHIPPING_RULE = `
  query fetchShippingRuleAction ($shippingRuleActionId: Int!) {
    shippingRuleAction (shippingRuleActionId: $shippingRuleActionId) {
      id
      name
      isActive
      actionType
      actionPayload
      
      criteria {
        id
        option
        operator
        result
      }
      
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchShippingRuleAction = (shippingRuleActionId?: number) => Promise<ShippingRuleActionDto>

const fetchShippingRuleAction: FetchShippingRuleAction = async (shippingRuleActionId) => {
  if (!shippingRuleActionId) return null

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_SHIPPING_RULE,
      variables: { shippingRuleActionId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { shippingRuleAction } = data
    if (!shippingRuleAction) {
      throw new Error("Can't get shippingRuleAction!")
    }

    return shippingRuleAction
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useShippingRuleAction = (shippingRuleActionId: number) => useQuery(['shippingRuleAction', shippingRuleActionId], () => fetchShippingRuleAction(shippingRuleActionId))
