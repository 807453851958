import axios from 'utils/axios'
import hash from 'object-hash'
import { useQuery } from 'react-query'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'simpleCustomSaleSourceForOptions', string[]>

const FETCH_CUSTOM_SALE_SOURCE_FOR_OPTIONS = `
  query simpleCustomSaleSourceForOptions ($filters: SimpleCustomSaleSourceForOptionsFilters!) {
    simpleCustomSaleSourceForOptions(filters: $filters)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type SimpleCustomSaleSourceForOptionsFilters = {
  searchText?: string
}

type FetchSimpleCustomSaleSourceForOptions = (args: SimpleCustomSaleSourceForOptionsFilters) => Promise<string[]>

const fetchSimpleCustomSaleSourceForOptions: FetchSimpleCustomSaleSourceForOptions = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_CUSTOM_SALE_SOURCE_FOR_OPTIONS,
      variables: {
        filters: args
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { simpleCustomSaleSourceForOptions } = data
    if (!simpleCustomSaleSourceForOptions) {
      throw new Error("Can't get simple custom sale source!")
    }

    return simpleCustomSaleSourceForOptions
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useSimpleCustomSaleSourceOptions = (filters: SimpleCustomSaleSourceForOptionsFilters) =>
  useQuery(['simpleCustomSaleSourceOptions', hash(filters)], () => fetchSimpleCustomSaleSourceForOptions(filters))
