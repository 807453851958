import React, { useEffect } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Button, Drawer, Input, Layout, PageHeader, Popconfirm, Tabs } from 'antd'
import notification from 'mrx-notification'
import * as rootStyles from 'assets/layoutStyle'
import { EventsTab, NotesTab, OrdersTab, TasksTab, ShippingAddressTab, PaymentAddressTab } from '../../components'
import { useCustomerSubscription, useCancelCustomerSubscription } from '../../hooks'

import styles from './CustomerSubscriptionDetails.module.scss'

type Props = {
  customerSubscriptionId?: number
  onClose: () => void
}

const CustomerSubscriptionDetails = (props: Props) => {
  const {
    customerSubscriptionId,

    onClose
  } = props

  const { data: customerSubscription } = useCustomerSubscription(customerSubscriptionId)
  const cancelCustomerSubscription = useCancelCustomerSubscription()

  useEffect(() => {
    if (cancelCustomerSubscription.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Customer Subscription was canceled successfully!'
      })
    }
  }, [cancelCustomerSubscription.isSuccess])

  const doConfirm = () => {
    cancelCustomerSubscription.mutate(customerSubscriptionId)
  }

  return (
    <Drawer title='Customer Subscription Details' width='80%' onClose={onClose} visible={!!customerSubscriptionId} forceRender>
      <Layout style={rootStyles.root}>
        <PageHeader
          style={{ margin: '-24px' }}
          title='Customer Subscription Details'
          extra={[
            <Popconfirm
              key='cancel-button'
              title='Are you sure want to cancel this subscription?'
              onConfirm={() => doConfirm()}
              onCancel={(e) => e?.stopPropagation()}
              okText='Yes'
              cancelText='No'
            >
              <Button
                disabled={cancelCustomerSubscription.isLoading || ['CANCELED', 'TERMINATED'].includes(customerSubscription?.status)}
                onClick={() => console.log('cancel')}
                key='2'
              >
                Cancel subscription
              </Button>
            </Popconfirm>
          ]}
        />
        {customerSubscription && (
          <>
            <div className={styles.listContainer}>
              <div className={styles.leftColumn}>
                <div className={styles.listItem}>Customer Subscription ID: {customerSubscription.id}</div>
                <div className={styles.listItem}>
                  Customer:{' '}
                  <Link to={`/sales/customers/${customerSubscription.customerId}`}>
                    {`${customerSubscription.customer.firstName} ${customerSubscription.customer.lastName}`}
                  </Link>
                </div>
                <div className={styles.listItem}>Email: {customerSubscription.customer.email}</div>
                <div className={styles.listItem}>
                  Campaign:{' '}
                  <Link to={`/campaigns/${customerSubscription?.landing?.campaign?.id}`}>
                    {customerSubscription?.landing?.campaign?.name}
                  </Link>
                </div>
                <div className={styles.listItem}>Site URL: {customerSubscription.landing?.siteUrl}</div>
              </div>

              <div className={styles.centralColumn}>
                <div className={styles.listItem}>Status: {customerSubscription.status}</div>
                <div className={styles.listItem}>Cycle: {customerSubscription.cycle}</div>
                <div className={styles.listItem}>
                  Trial Start: {moment(customerSubscription.creationDate).format('MM/DD/YYYY HH:mm:ss') || ''}
                </div>
                <div className={styles.listItem}>
                  Subscription Start: {moment(customerSubscription.startDate).format('MM/DD/YYYY HH:mm:ss') || ''}
                </div>
                <div className={styles.listItem}>
                  Next Billing Date (Expiring Date): {moment(customerSubscription.paidTill).format('MM/DD/YYYY HH:mm:ss') || ''}
                </div>
              </div>

              <div className={styles.rightColumn}>
                <div className={styles.listItem}>Total: {`$${(customerSubscription.total / 100).toFixed(2)}`}</div>
                <div className={styles.listItem}>Sale Source: {customerSubscription.saleSource}</div>
                <div className={styles.listItem}>
                  Canceled At:{' '}
                  {customerSubscription.canceledAt ? moment(customerSubscription.canceledAt).format('MM/DD/YYYY HH:mm:ss') : ''}
                </div>
                <div className={styles.listItem}>Canceled By: {customerSubscription.canceledBy}</div>
                <div className={styles.listItem}>Cancel Reason: {customerSubscription.cancelReason || ''}</div>
              </div>
            </div>
            <Tabs type='card' className={styles.tabsContainer}>
              <Tabs.TabPane tab='Orders' key='1'>
                <OrdersTab orders={customerSubscription.orders} />
              </Tabs.TabPane>
              <Tabs.TabPane tab='Shipping Address' key='2'>
                <ShippingAddressTab address={customerSubscription.shippingAddress} />
              </Tabs.TabPane>
              <Tabs.TabPane tab='Payment Address' key='3'>
                <PaymentAddressTab address={customerSubscription.billingAddress} />
              </Tabs.TabPane>
              <Tabs.TabPane tab='Events' key='4'>
                <EventsTab events={customerSubscription.customerSubscriptionEvents} />
              </Tabs.TabPane>
              <Tabs.TabPane tab='Tasks' key='5'>
                <TasksTab customerSubscriptionId={customerSubscriptionId} />
              </Tabs.TabPane>
              <Tabs.TabPane tab='Notes' key='6'>
                <NotesTab customerSubscriptionId={customerSubscriptionId} />
              </Tabs.TabPane>
              <Tabs.TabPane tab='Comment' key='7'>
                <Input.TextArea value={customerSubscription.comment} />
              </Tabs.TabPane>
            </Tabs>
          </>
        )}
      </Layout>
    </Drawer>
  )
}

export default CustomerSubscriptionDetails
