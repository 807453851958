import React from 'react'
import { SearchableSelectBase } from 'components'
import { PropsFromRedux, connector } from './container'

const MailingSettingsSelect = (props: PropsFromRedux) => {
  const { plugin, fetchSendgridSettingsOptions, fetchPostmarkSettingsForOptions, ...rest } = props

  switch (plugin) {
    case 'SENDGRID':
      return <SearchableSelectBase plugin={plugin} onSearch={fetchSendgridSettingsOptions} {...rest} />

    case 'POSTMARK':
    default:
      return <SearchableSelectBase plugin={plugin} onSearch={fetchPostmarkSettingsForOptions} {...rest} />
  }
}

export default connector(MailingSettingsSelect)
