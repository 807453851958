import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { TopCampaign } from '../types'
import { sortByMultipleKeys } from 'utils/sorting'

type TopCampaignsReportSelector = (state: ReduxState) => TopCampaign[]

const getTopCampaigns: TopCampaignsReportSelector = state => {
  let result = [] as TopCampaign[]

  if (state.reports.topCampaigns) {
    result = Object.values(state.reports.topCampaigns) as TopCampaign[]
    result.sort(sortByMultipleKeys('-total'))
  }

  return result
}

export const topCampaignsReportSelector: TopCampaignsReportSelector = createSelector(
  getTopCampaigns,
  topCampaigns => topCampaigns
)
