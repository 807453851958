import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type Result = {
  customers?: number
  ordersCount?: number
  totalAmount?: number
  totalAmountRate?: number
  salvageRate?: number
  multiPurchaserRate?: number
}
type DashboardDataReportSelector = (state: ReduxState) => Result

const getDashboardData: DashboardDataReportSelector = state => {
  const result = {} as Result

  if (state.reports.customers) {
    result.customers = state.reports.customers
  }

  if (state.reports.ordersCount) {
    result.ordersCount = state.reports.ordersCount
  }

  if (state.reports.totalAmount) {
    result.totalAmount = state.reports.totalAmount
  }

  if (state.reports.totalAmountRate) {
    result.totalAmountRate = state.reports.totalAmountRate
  }

  if (state.reports.salvageRate) {
    result.salvageRate = state.reports.salvageRate
  }

  if (state.reports.multiPurchaserRate) {
    result.multiPurchaserRate = state.reports.multiPurchaserRate
  }

  return result
}

export const dashboardDataReportSelector: DashboardDataReportSelector = createSelector(
  getDashboardData,
  dashboardData => dashboardData
)
