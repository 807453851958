import React, { useState } from 'react'
import { Input, Form, Typography, FormInstance, Button, AutoComplete, Select, Switch } from 'antd'
import { DeleteTwoTone } from '@ant-design/icons'
import { getSizeVariantsForOptionsByProductType } from '@merchx-v2/shared-frontend-functions'
import { ProductAttributes } from 'consts'
import { Product } from 'features/types'
import { ManageFormTags } from 'features/tags/components'
import styles from './EditProductVariants.module.scss'

const { Option } = Select

const options = [{ value: ProductAttributes.color }, { value: ProductAttributes.size }]

type Props = {
  product?: Product
  form: FormInstance
  supplierId?: number
}

const colorOptions = {
  BLACK: 'Black',
  BLUE: 'Blue',
  CRANBERRY: 'Cranberry',
  CUSTOM: 'Custom',
  DARK_GRAY: 'Dark Gray',
  GOLD: 'Gold',
  GRAY: 'Gray',
  IRISH_GREEN: 'Irish green',
  KHAKI: 'Khaki',
  LIGHT_BLUE: 'Light blue',
  LIGHT_PINK: 'Light pink',
  MILITARY_GREEN: 'Military green',
  MAROON: 'Maroon',
  NAVY: 'Navy',
  ORANGE: 'Orange',
  PINK: 'Pink',
  PURPLE: 'Purple',
  RED: 'Red',
  ROYAL_BLUE: 'Royal blue',
  SPRUCE: 'Spruce',
  STONE: 'Stone',
  WHITE: 'White',
  YELLOW: 'Yellow'
}

const EditProductPrices = (props: Props) => {
  const { supplierId, form } = props

  const [newVariantOption, setNewVariantOption] = useState('')
  const [colorManual, setColorManual] = useState(false)

  if (supplierId !== -1) return null

  const productType = form.getFieldValue('productType')

  return (
    <div className={styles.productFormBlock}>
      <div className={styles.productFormRow}>
        <div className={styles.productFormRowItem}>
          <Typography style={{ color: 'black', paddingBottom: '8px' }}>Add Main Variant?</Typography>
          <div className={styles.moreOptionBtn}>
            <Form.Item noStyle dependencies={[['shouldAddMainVariant']]}>
              {(formInstance) => {
                const shouldAddMainVariant = formInstance.getFieldValue('shouldAddMainVariant')

                return (
                  <>
                    <Button
                      className={styles.saveButton}
                      type={shouldAddMainVariant ? 'primary' : undefined}
                      onClick={() => form.setFieldsValue({ shouldAddMainVariant: true })}
                    >
                      Yes
                    </Button>
                  </>
                )
              }}
            </Form.Item>

            <Form.Item noStyle dependencies={[['shouldAddMainVariant']]}>
              {(formInstance) => {
                const shouldAddMainVariant = formInstance.getFieldValue('shouldAddMainVariant')

                return (
                  <>
                    <Button
                      type={shouldAddMainVariant === false ? 'primary' : undefined}
                      onClick={() => form.setFieldsValue({ shouldAddMainVariant: false })}
                      className={styles.createButton}
                    >
                      No
                    </Button>
                  </>
                )
              }}
            </Form.Item>
          </div>
        </div>
      </div>

      <Form.Item noStyle dependencies={[['shouldAddMainVariant', 'mainVariant', 'mainVariantValues']]}>
        {(formInstance) => {
          const mainVariant = formInstance.getFieldValue('mainVariant')
          const shouldAddMainVariant = formInstance.getFieldValue('shouldAddMainVariant')
          const mainVariantValues = formInstance.getFieldValue('mainVariantValues') || []

          if (!shouldAddMainVariant) return null
          if (!mainVariant) return null

          return (
            <div className={styles.productFormOptionBlock}>
              <div className={styles.productFormPriceRow}>
                <div className={styles.labeledItem}>
                  <Form.Item name='mainVariant' noStyle>
                    <Typography style={{ color: 'black', paddingBottom: '8px' }}>Main Variant</Typography>
                    <Input className={styles.variantOptionInput} value={mainVariant} disabled />
                  </Form.Item>
                </div>

                {colorManual || mainVariant !== 'COLOR' ? (
                  <div className={styles.optionsContainer}>
                    <ManageFormTags
                      onTagsChanged={(newTagsList) => form.setFieldsValue({ mainVariantValues: newTagsList })}
                      owner={mainVariant}
                      placeholder='Add new value'
                      initTags={mainVariantValues}
                      refreshOnInitTagsChanged
                    />
                    <DeleteTwoTone
                      className={styles.deleteTwoTone}
                      twoToneColor='tomato'
                      onClick={() => form.setFieldsValue({ mainVariant: '' })}
                    />
                  </div>
                ) : (
                  <Select
                    className={styles.colorSelect}
                    value={mainVariantValues}
                    mode='multiple'
                    onChange={(newTagsList) => form.setFieldsValue({ mainVariantValues: newTagsList })}
                  >
                    {Object.keys(colorOptions).map(key => (
                      <Option key={key} value={key}>{colorOptions[key]}</Option>
                    ))}
                  </Select>
                )}
                <div hidden={mainVariant !== 'COLOR'} className={styles.colorManual} data-checked={colorManual}>
                  <Switch checked={colorManual} onChange={setColorManual} />
                  &nbsp;&nbsp;Manual
                </div>
              </div>
            </div>
          )
        }}
      </Form.Item>

      <Form.Item noStyle dependencies={[['shouldAddMainVariant', 'mainVariant']]}>
        {(formInstance) => {
          const mainVariant = formInstance.getFieldValue('mainVariant')
          const shouldAddMainVariant = formInstance.getFieldValue('shouldAddMainVariant')

          if (!shouldAddMainVariant) return null
          if (mainVariant) return null

          return (
            <div className={styles.productFormOptionBlock}>
              <div className={styles.productFormPriceRow} id='productOptionsModalContainer' style={{ position: 'relative' }}>
                <div className={styles.labeledItem}>
                  <Typography style={{ color: 'black', paddingBottom: '8px' }}>Main Variant</Typography>
                  <AutoComplete
                    options={options}
                    value={newVariantOption}
                    filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                    style={{ width: '200px' }}
                    getPopupContainer={() => document.getElementById('productOptionsModalContainer')!}
                    onChange={(val) => {
                      setNewVariantOption(val.toUpperCase())
                    }}
                  />
                </div>
              </div>

              <div className={styles.moreOptionBtn}>
                <Button
                  className={styles.saveButton}
                  type='primary'
                  onClick={() => {
                    form.setFieldsValue({ mainVariant: newVariantOption, mainVariantValues: [] })
                    if (newVariantOption === 'SIZE') {
                      form.setFieldsValue({ mainVariantValues: getSizeVariantsForOptionsByProductType(productType) })
                    }
                    setNewVariantOption('')
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          )
        }}
      </Form.Item>

      <Form.Item noStyle dependencies={[['shouldAddMainVariant', 'mainVariant', 'shouldAddSecondaryVariant']]}>
        {(formInstance) => {
          const mainVariant = formInstance.getFieldValue('mainVariant')
          const shouldAddMainVariant = formInstance.getFieldValue('shouldAddMainVariant')
          const shouldAddSecondaryVariant = formInstance.getFieldValue('shouldAddSecondaryVariant')

          if (!shouldAddMainVariant) return null
          if (!mainVariant) return null

          return (
            <div className={styles.productFormOptionBlock}>
              <div className={styles.productFormRowItem}>
                <Typography style={{ color: 'black', paddingBottom: '8px' }}>Add Secondary Variant?</Typography>
                <div className={styles.moreOptionBtn}>
                  <Button
                    className={styles.saveButton}
                    type={shouldAddSecondaryVariant ? 'primary' : undefined}
                    onClick={() => form.setFieldsValue({ shouldAddSecondaryVariant: true })}
                  >
                    Yes
                  </Button>
                  <Button
                    type={shouldAddSecondaryVariant === false ? 'primary' : undefined}
                    onClick={() => form.setFieldsValue({ shouldAddSecondaryVariant: false })}
                    className={styles.createButton}
                  >
                    No
                  </Button>
                </div>
              </div>
            </div>
          )
        }}
      </Form.Item>

      <Form.Item noStyle dependencies={[['shouldAddMainVariant', 'mainVariant', 'shouldAddSecondaryVariant', 'secondaryVariantValues']]}>
        {(formInstance) => {
          const mainVariant = formInstance.getFieldValue('mainVariant')
          const shouldAddMainVariant = formInstance.getFieldValue('shouldAddMainVariant')
          const shouldAddSecondaryVariant = formInstance.getFieldValue('shouldAddSecondaryVariant')
          const secondaryVariant = formInstance.getFieldValue('secondaryVariant')
          const secondaryVariantValues = formInstance.getFieldValue('secondaryVariantValues')

          if (!shouldAddMainVariant) return null
          if (!mainVariant) return null
          if (!shouldAddSecondaryVariant) return null
          if (!secondaryVariant) return null

          return (
            <div className={styles.productFormOptionBlock}>
              <div className={styles.productFormPriceRow}>
                <div className={styles.labeledItem}>
                  <Typography style={{ color: 'black', paddingBottom: '8px' }}>Secondary Variant</Typography>
                  <Input className={styles.variantOptionInput} value={secondaryVariant} disabled />
                </div>

                {colorManual || secondaryVariant !== 'COLOR' ? (
                  <div className={styles.optionsContainer}>
                    <ManageFormTags
                      onTagsChanged={(newTagsList) => form.setFieldsValue({ secondaryVariantValues: newTagsList })}
                      owner={secondaryVariant}
                      placeholder='Add new value'
                      initTags={secondaryVariantValues}
                    />
                    <DeleteTwoTone
                      twoToneColor='tomato'
                      style={{ position: 'absolute', top: '37px', left: '220px' }}
                      onClick={() => form.setFieldsValue({ secondaryVariant: '' })}
                    />
                  </div>
                ) : (
                  <Select
                    className={styles.colorSelect}
                    value={secondaryVariantValues}
                    mode='multiple'
                    onChange={(newTagsList) => form.setFieldsValue({ secondaryVariantValues: newTagsList })}
                  >
                    {Object.keys(colorOptions).map(key => (
                      <Option key={key} value={key}>{colorOptions[key]}</Option>
                    ))}
                  </Select>
                )}
                <div hidden={secondaryVariant !== 'COLOR'} className={styles.colorManual} data-checked={colorManual}>
                  <Switch checked={colorManual} onChange={setColorManual} />
                  &nbsp;&nbsp;Manual
                </div>
              </div>
            </div>
          )
        }}
      </Form.Item>

      <Form.Item noStyle dependencies={[['shouldAddMainVariant', 'mainVariant', 'shouldAddSecondaryVariant', 'secondaryVariantValues']]}>
        {(formInstance) => {
          const mainVariant = formInstance.getFieldValue('mainVariant')
          const shouldAddMainVariant = formInstance.getFieldValue('shouldAddMainVariant')
          const shouldAddSecondaryVariant = formInstance.getFieldValue('shouldAddSecondaryVariant')
          const secondaryVariant = formInstance.getFieldValue('secondaryVariant')

          if (!shouldAddMainVariant) return null
          if (!mainVariant) return null
          if (!shouldAddSecondaryVariant) return null
          if (secondaryVariant) return null

          return (
            <div className={styles.productFormOptionBlock}>
              <div className={styles.productFormPriceRow} id='productOptionsModalContainer' style={{ position: 'relative' }}>
                <div className={styles.labeledItem}>
                  <Typography style={{ color: 'black', paddingBottom: '8px' }}>Secondary Variant</Typography>
                  <AutoComplete
                    options={options}
                    value={newVariantOption}
                    filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                    style={{ width: '200px' }}
                    getPopupContainer={() => document.getElementById('productOptionsModalContainer')!}
                    onChange={(val) => {
                      setNewVariantOption(val.toUpperCase())
                    }}
                  />
                </div>
              </div>

              <div className={styles.moreOptionBtn}>
                <Button
                  className={styles.saveButton}
                  type='primary'
                  onClick={() => {
                    form.setFieldsValue({ secondaryVariant: newVariantOption, secondaryVariantValues: [] })
                    if (newVariantOption === 'SIZE') {
                      form.setFieldsValue({ secondaryVariantValues: getSizeVariantsForOptionsByProductType(productType) })
                    }
                    setNewVariantOption('')
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          )
        }}
      </Form.Item>
    </div>
  )
}

export default EditProductPrices
