import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { AwsCertificate } from '../types'

type AwsCertificatesListSelector = (state: ReduxState) => AwsCertificate[]
const getAwsCertificatesList: AwsCertificatesListSelector = state => {
  const result = [] as AwsCertificate[]
  const pageIds = state.awsCertificates.pagination.pages[state.awsCertificates.pagination.currentPage] || []
  pageIds.forEach(pageId => {
    result.push(state.awsCertificates.awsCertificates[pageId])
  })
  return result
}

export const awsCertificatesListSelector: AwsCertificatesListSelector = createSelector(
  getAwsCertificatesList,
  list => list
)
