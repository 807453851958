import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { SiteType } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Dependency, DependencyInput } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'updateDependency', boolean>

const UPDATE_DEPENDENCY = `
  mutation UpdateDependency($dependencyId: Int!, $dependencyData: DependencyInput!) {
    updateDependency(dependencyId: $dependencyId, dependencyData: $dependencyData)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateDependencyArgs = {
  ownerType: SiteType
  ownerId: number
  dependencyId: number
  dependencyData: DependencyInput
}

type UpdateDependency = (args: UpdateDependencyArgs) => Promise<UpdateDependencyArgs>

const updateDependency: UpdateDependency = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_DEPENDENCY,
      variables: {
        dependencyId: args.dependencyId,
        dependencyData: args.dependencyData
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateDependency } = data
    if (!updateDependency) {
      throw new Error("Can't update dependency!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateDependencyContext = { prevDependency?: Dependency }

type UpdateDependencyEvents = {
  onMutate: (variables: UpdateDependencyArgs) => Promise<UpdateDependencyContext>
  onError: (error: string, variables: UpdateDependencyArgs, context: UpdateDependencyContext) => void
  onSettled: (data?: UpdateDependencyArgs) => void
}

const updateDependencyEvents: UpdateDependencyEvents = {
  onMutate: async (variables: UpdateDependencyArgs) => {
    await queryClient.cancelQueries(['dependency', variables.dependencyId])

    // Snapshot the previous value
    const prevDependency = queryClient.getQueryData<Dependency>(['dependency', variables.dependencyId])

    // Optimistically update to the new values
    if (prevDependency) {
      queryClient.setQueryData<Dependency>(['dependency', variables.dependencyId], {
        ...prevDependency,
        id: variables.dependencyId,
        ...variables.dependencyData
      })
    }

    return { prevDependency }
  },
  onError: (_err, variables, context) => {
    if (context?.prevDependency) {
      // Restore previous version of dependency on any error
      queryClient.setQueryData<Dependency>(['dependency', variables.dependencyId], context.prevDependency)
    }
  },
  onSettled: (data) => {
    if (data?.dependencyId) {
      queryClient.invalidateQueries([`${data.ownerType.toLowerCase()}`, +data.ownerId])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUpdateDependency = () => useMutation(updateDependency, updateDependencyEvents)
