import * as t from '../actionTypes'
import { Product } from 'features/types'
import { productImagesLoaded } from 'features/productImages/actions/productImagesLoaded'
import { GraphQLProduct } from '../types'

export type ProductsLoadedAction = BaseFSA<{ products: Product[] }>

export const productsLoaded =
  (products: GraphQLProduct[]): MrxThunk<ProductsLoadedAction> =>
  (dispatch) => {
    const newProducts = products.map((product) => {
      dispatch(productImagesLoaded(product.id, product.images))

      return {
        id: product.id,
        name: product.name,
        displayName: product.displayName,
        description: product.description,
        productType: product.productType,
        campaignId: product.campaignId,
        image: product.image,
        images: product.images,
        attributes: product.attributes,
        sku: product.sku,
        hasCustomShipping: product.hasCustomShipping,
        customShippingPrice: product.customShippingPrice,
        subscriptionItems: product.subscriptionItems,
        prices: product.prices,
        isActive: product.isActive,
        createdAt: product.createdAt,
        updatedAt: product.updatedAt
      } as Product
    })

    dispatch({
      type: t.PRODUCTS_LOADED,
      payload: {
        products: newProducts
      }
    })
  }
