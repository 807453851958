// TODO - remove becouse of legacy

import React, { useEffect, useState } from 'react'
import { Card, Form, Select, Input, Button, Switch, InputNumber } from 'antd'
import { Store } from 'antd/lib/form/interface'
import notification from 'mrx-notification'
import { DeploymentPlugin, FulfillmentPlugin, MailingPlugin, PaymentPlugin, SmsPlugin } from '@merchx-v2/shared-types'
import { useCreateLanding, useUpdateLanding } from '../../hooks'
import styles from './LandingWizardInitials.module.scss'
import { connector, PropsFromRedux } from './container'
import {
  DeploymentSettingsSelect,
  FulfillmentSettingsSelect,
  MailingSettingsSelect,
  PaymentSettingsSelect,
  SmsSettingsSelect
} from 'features/plugins/components'
import { LandingInput } from '../../types'

interface FormStore extends Store {
  name: string
  campaignId: number
  campaignName?: string
  workspaceId: number
  deploymentPlugin: DeploymentPlugin
  deploymentSettingsId: number
  deploymentSettingsName?: string
  fulfillmentPlugin: FulfillmentPlugin
  fulfillmentSettingsId: number
  fulfillmentSettingsName?: string
  mailingPlugin: MailingPlugin
  mailingSettingsId: number
  mailingSettingsName?: string
  alternativeMailingPlugin: MailingPlugin
  alternativeMailingSettingsId: number
  alternativeMailingSettingsName?: string
  smsPlugin: SmsPlugin
  smsSettingsId: number
  smsSettingsName?: string
  paymentPlugin: PaymentPlugin
  paymentSettingsId: number
  paymentSettingsName?: string
  hasFixedPrices: boolean
  fixedPrice: string
  subroute: string
  subdomain: string
  saleSource: string
  shippingCost: string
  hasCustomShipping: boolean
  processingCost: string
  applyProcessingOnce: boolean
  allowMultiplePayments: boolean
}

type LandingWizardInitialsType = PropsFromRedux & {}

const emptyOption = {
  id: 0,
  name: 'Not set'
}

const LandingWizardInitials = (props: LandingWizardInitialsType) => {
  const { workspace, campaignId, landing, onNextButtonClick, landingType = 'LANDING' } = props

  const [selectedPaymentPlugin, setSelectedPaymentPlugin] = useState<PaymentPlugin>()
  const [selectedPaymentSettings, setSelectedPaymentSettings] = useState<SelectOption>(emptyOption)
  const [selectedFulfillmentPlugin, setSelectedFulfillmentPlugin] = useState<FulfillmentPlugin>()
  const [selectedFulfillmentSettings, setSelectedFulfillmentSettings] = useState<SelectOption>(emptyOption)
  const [selectedMailingPlugin, setSelectedMailingPlugin] = useState<MailingPlugin>()
  const [selectedMailingSettings, setSelectedMailingSettings] = useState<SelectOption>(emptyOption)
  const [selectedDeploymentPlugin, setSelectedDeploymentPlugin] = useState<DeploymentPlugin>()
  const [selectedDeploymentSettings, setSelectedDeploymentSettings] = useState<SelectOption>(emptyOption)
  const [selectedSmsPlugin, setSelectedSmsPlugin] = useState<SmsPlugin>()
  const [selectedSmsSettings, setSelectedSmsSettings] = useState<SelectOption>(emptyOption)
  const [selectedAlternativeMailingPlugin, setSelectedAlternativeMailingPlugin] = useState<MailingPlugin>()
  const [selectedAlternativeMailingSettings, setSelectedAlternativeMailingSettings] = useState<SelectOption>(emptyOption)

  const [advancedMode, setAdvancedMode] = useState(false)

  const [form] = Form.useForm()

  const createLanding = useCreateLanding()
  const updateLanding = useUpdateLanding()

  useEffect(() => {
    if (landing) {
      form.setFieldsValue({
        name: landing.name,
        campaignId: landing.campaignId,
        subdomain: landing.subdomain,
        subroute: landing.subroute,
        saleSource: landing.saleSource,
        paymentPlugin: landing.paymentPlugin,
        paymentSettingsId: landing.paymentSettingsId,
        fulfillmentPlugin: landing.fulfillmentPlugin,
        fulfillmentSettingsId: landing.fulfillmentSettingsId,
        mailingPlugin: landing.mailingPlugin,
        mailingSettingsId: landing.mailingSettingsId,
        alternativeMailingPlugin: landing.alternativeMailingPlugin,
        alternativeMailingSettingsId: landing.alternativeMailingSettingsId,
        deploymentPlugin: landing.deploymentPlugin,
        deploymentSettingsId: landing.deploymentSettingsId,
        smsPlugin: landing.smsPlugin,
        smsSettingsId: landing.smsSettingsId,
        hasCustomShipping: landing.hasCustomShipping,
        shippingCost: landing.shippingCost / 100,
        processingCost: landing.processingCost / 100,
        applyProcessingOnce: landing.applyProcessingOnce,
        allowMultiplePayments: landing.allowMultiplePayments,
        hasFixedPrices: landing.hasFixedPrices,
        fixedPrice: landing.fixedPrice / 100
      })

      setSelectedPaymentPlugin(landing.paymentPlugin)
      setSelectedPaymentSettings(
        landing.paymentSettingsId
          ? {
              id: landing.paymentSettingsId,
              name: landing.paymentSettingsName
            }
          : emptyOption
      )

      setSelectedFulfillmentPlugin(landing.fulfillmentPlugin)
      setSelectedFulfillmentSettings(
        landing.fulfillmentSettingsId
          ? {
              id: landing.fulfillmentSettingsId,
              name: landing.fulfillmentSettingsName
            }
          : emptyOption
      )

      setSelectedMailingPlugin(landing.mailingPlugin)
      setSelectedMailingSettings(
        landing.mailingSettingsId
          ? {
              id: landing.mailingSettingsId,
              name: landing.mailingSettingsName
            }
          : emptyOption
      )

      setSelectedDeploymentPlugin(landing.deploymentPlugin)
      setSelectedDeploymentSettings(
        landing.deploymentSettingsId
          ? {
              id: landing.deploymentSettingsId,
              name: landing.deploymentSettingsName
            }
          : emptyOption
      )

      setSelectedSmsPlugin(landing.smsPlugin)
      setSelectedSmsSettings(
        landing.smsSettingsId
          ? {
              id: landing.smsSettingsId,
              name: landing.smsSettingsName
            }
          : emptyOption
      )

      setSelectedAlternativeMailingPlugin(landing.alternativeMailingPlugin)
      setSelectedAlternativeMailingSettings(
        landing.alternativeMailingSettingsId
          ? {
              id: landing.alternativeMailingSettingsId,
              name: landing.alternativeMailingSettingsName
            }
          : emptyOption
      )
    }
  }, [form, landing])

  useEffect(() => {
    if (workspace && !landing) {
      form.setFieldsValue({
        paymentPlugin: workspace.paymentPlugin,
        paymentSettingsId: workspace.paymentSettingsId,
        fulfillmentPlugin: workspace.fulfillmentPlugin,
        fulfillmentSettingsId: workspace.fulfillmentSettingsId,
        mailingPlugin: workspace.mailingPlugin,
        mailingSettingsId: workspace.mailingSettingsId,
        alternativeMailingPlugin: workspace.mailingPlugin,
        alternativeMailingSettingsId: workspace.mailingSettingsId,
        deploymentPlugin: workspace.deploymentPlugin,
        deploymentSettingsId: workspace.deploymentSettingsId,
        smsPlugin: workspace.smsPlugin,
        smsSettingsId: workspace.smsSettingsId,
        shippingCost: 12,
        processingCost: 3,
        saleSource: 'facebook',
        applyProcessingOnce: true,
        allowMultiplePayments: true,
        hasCustomShipping: true,
        hasFixedPrices: false,
        fixedPrice: 0
      })
      setSelectedPaymentPlugin(workspace.paymentPlugin)
      setSelectedPaymentSettings(
        workspace.paymentSettingsId
          ? {
              id: workspace.paymentSettingsId,
              name: workspace.paymentSettingsName
            }
          : emptyOption
      )

      setSelectedFulfillmentPlugin(workspace.fulfillmentPlugin)
      setSelectedFulfillmentSettings(
        workspace.fulfillmentSettingsId
          ? {
              id: workspace.fulfillmentSettingsId,
              name: workspace.fulfillmentSettingsName
            }
          : emptyOption
      )

      setSelectedMailingPlugin(workspace.mailingPlugin)
      setSelectedMailingSettings(
        workspace.mailingSettingsId
          ? {
              id: workspace.mailingSettingsId,
              name: workspace.mailingSettingsName
            }
          : emptyOption
      )

      setSelectedDeploymentPlugin(workspace.deploymentPlugin)
      setSelectedDeploymentSettings(
        workspace.deploymentSettingsId
          ? {
              id: workspace.deploymentSettingsId,
              name: workspace.deploymentSettingsName
            }
          : emptyOption
      )

      setSelectedSmsPlugin(workspace.smsPlugin)
      setSelectedSmsSettings(
        workspace.smsSettingsId
          ? {
              id: workspace.smsSettingsId,
              name: workspace.smsSettingsName
            }
          : emptyOption
      )

      setSelectedAlternativeMailingPlugin(workspace.mailingPlugin)
      setSelectedAlternativeMailingSettings(
        workspace.mailingSettingsId
          ? {
              id: workspace.mailingSettingsId,
              name: workspace.mailingSettingsName
            }
          : emptyOption
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace, landing])

  const processUpdating = async (landingData: LandingInput) => {
    if (landing?.id) {
      if (form.isFieldsTouched()) {
        await updateLanding.mutateAsync(
          { landingId: landing.id, landingData },
          {
            onSuccess: () => {
              notification.success({
                message: 'Successfully',
                description: `Landing Initials was updated successfully!`
              })
              onNextButtonClick(1)
            },
            onError: (error) => {
              notification.error({
                message: 'Landing wizard updating error',
                description: error.toString()
              })
            }
          }
        )
      } else {
        onNextButtonClick(1)
      }
    } else {
      await createLanding.mutateAsync(landingData, {
        onSuccess: (data) => {
          notification.success({
            message: 'Successfully',
            description: `Landing Initials was created successfully!`
          })
          onNextButtonClick(1, data.id)
        },
        onError: (error) => {
          notification.error({
            message: 'Landing wizard creation error',
            description: error.toString()
          })
        }
      })
    }
  }

  const handleFormValueChanged = (values: FormStore) => {
    if (values.fixedPrice !== undefined) {
      form.setFieldsValue({ hasFixedPrices: true })
    }
  }

  const onFinishHandler = async (values: FormStore) => {
    const landingData: LandingInput = {
      ...values,

      workspaceId: workspace.id,
      landingType,
      campaignId: campaignId,
      shippingCost: Math.round(parseFloat(values.shippingCost) * 100),
      processingCost: Math.round(parseFloat(values.processingCost) * 100),
      fixedPrice: Math.round(parseFloat(values.fixedPrice) * 100),
      seoTitle: landing?.seoTitle || '',
      seoDescription: landing?.seoDescription || '',
      seoKeywords: landing?.seoKeywords || ''
    }

    await processUpdating(landingData)
  }

  const onPaymentPluginValueChange = () => {
    setSelectedPaymentPlugin(form.getFieldValue('paymentPlugin'))
    setSelectedPaymentSettings(emptyOption)
    form.setFieldsValue({ paymentSettingsId: null })
  }

  const onMailingPluginValueChange = () => {
    setSelectedMailingPlugin(form.getFieldValue('mailingPlugin'))
    setSelectedMailingSettings(emptyOption)
    form.setFieldsValue({ mailingSettingsId: null })
  }

  const onAlternativeMailingPluginValueChange = () => {
    setSelectedAlternativeMailingPlugin(form.getFieldValue('mailingPlugin'))
    setSelectedAlternativeMailingSettings(emptyOption)
    form.setFieldsValue({ alternativeMailingSettingsId: null })
  }

  const onFulfillmentPluginValueChange = () => {
    setSelectedFulfillmentPlugin(form.getFieldValue('fulfillmentPlugin'))
    setSelectedFulfillmentSettings(emptyOption)
    form.setFieldsValue({ fulfillmentSettingsId: null })
  }

  const onDeploymentPluginValueChange = () => {
    setSelectedDeploymentPlugin(form.getFieldValue('deploymentPlugin'))
    setSelectedDeploymentSettings(emptyOption)
    form.setFieldsValue({ deploymentSettingsId: null })
  }

  const onSmsPluginValueChange = () => {
    setSelectedSmsPlugin(form.getFieldValue('smsPlugin'))
    setSelectedSmsSettings(emptyOption)
    form.setFieldsValue({ smsSettingsId: null })
  }

  return (
    <Form
      layout='vertical'
      className={styles.form}
      hideRequiredMark
      onFinish={onFinishHandler}
      onValuesChange={handleFormValueChanged}
      form={form}
      initialValues={{
        name: landing?.name,
        campaignId: landing?.campaignId,
        saleSource: landing?.saleSource,
        subroute: landing?.subroute,
        subdomain: landing?.subdomain,
        paymentPlugin: landing?.paymentPlugin || workspace?.paymentPlugin,
        paymentSettingsId: landing?.paymentSettingsId || workspace?.paymentSettingsId,
        fulfillmentPlugin: landing?.fulfillmentPlugin || workspace?.fulfillmentPlugin,
        fulfillmentSettingsId: landing?.fulfillmentSettingsId || workspace?.fulfillmentSettingsId,
        mailingPlugin: landing?.mailingPlugin || workspace?.mailingPlugin,
        mailingSettingsId: landing?.mailingSettingsId || workspace?.mailingSettingsId,
        alternativeMailingPlugin: landing?.alternativeMailingPlugin || workspace?.mailingPlugin,
        alternativeMailingSettingsId: landing?.alternativeMailingSettingsId || workspace?.mailingSettingsId,
        deploymentPlugin: landing?.deploymentPlugin || workspace?.deploymentPlugin,
        deploymentSettingsId: landing?.deploymentSettingsId || workspace?.deploymentSettingsId,
        smsPlugin: landing?.smsPlugin || workspace?.smsPlugin,
        smsSettingsId: landing?.smsSettingsId || workspace?.smsSettingsId,
        shippingCost: landing?.shippingCost !== undefined ? landing?.shippingCost / 100 : 0,
        processingCost: landing?.processingCost !== undefined ? landing?.processingCost / 100 : 0,
        hasCustomShipping: landing?.hasCustomShipping !== undefined ? landing?.hasCustomShipping : true,
        applyProcessingOnce: landing?.applyProcessingOnce !== undefined ? landing?.applyProcessingOnce : true,
        allowMultiplePayments: landing?.allowMultiplePayments !== undefined ? landing?.allowMultiplePayments : true,
        hasFixedPrices: !!landing?.hasFixedPrices,
        fixedPrice: landing?.fixedPrice || 0
      }}
    >
      <Card
        className={styles.card}
        title={<span className={styles.cardTitle}>Initials</span>}
        extra={[
          <Button
            key='1'
            type='primary'
            htmlType='submit'
            loading={createLanding.isLoading || updateLanding.isLoading}
            className={styles.saveButton}
          >
            NEXT
          </Button>
        ]}
      >
        <div className={styles.group}>
          <Form.Item
            name='name'
            label='Name'
            rules={[{ required: true, message: 'Please input landing name!' }]}
            className={styles.groupItem}
          >
            <Input type='text' />
          </Form.Item>

          <Form.Item
            name='saleSource'
            label='Sale source'
            rules={[{ required: true, message: 'Please select sale source' }]}
            className={styles.groupItem}
          >
            <Select placeholder='Select sale source'>
              <Select.Option value='facebook'>Facebook</Select.Option>
              <Select.Option value='email'>Email</Select.Option>
              <Select.Option value='constant contact'>Costant Contact</Select.Option>
              <Select.Option value='google shopping'>Google Shopping</Select.Option>
              <Select.Option value='sms'>SMS</Select.Option>
            </Select>
          </Form.Item>
        </div>

        <div className={styles.group}>
          <Form.Item
            name='subroute'
            label='Subroute'
            rules={[{ pattern: /^\/[^/]*$/gm, message: 'Subroutes with subpathes not allowed!' }]}
            className={styles.groupItem}
          >
            <Input type='text' />
          </Form.Item>
        </div>

        <div className={styles.group}>
          <Form.Item
            name='shippingCost'
            label='Shipping cost'
            rules={[{ required: true, message: 'Please input shipping cost' }]}
            className={styles.groupItem}
          >
            <InputNumber min={0.0} prefix='$' className={styles.inputField} />
          </Form.Item>

          <Form.Item
            name='processingCost'
            label='Processing cost'
            rules={[{ required: true, message: 'Please input processing cost' }]}
            className={styles.groupItem}
          >
            <InputNumber min={0.0} prefix='$' className={styles.inputField} />
          </Form.Item>
        </div>

        <div className={styles.group}>
          <Form.Item name='hasCustomShipping' label='Use Custom Shipping' valuePropName='checked' className={styles.groupItem}>
            <Switch />
          </Form.Item>
          {/* <Form.Item name='applyProcessingOnce' label='Apply processing once' valuePropName='checked' className={styles.groupItem}>
            <Switch />
          </Form.Item> */}
          <Form.Item name='allowMultiplePayments' label='Allow multiple payments' valuePropName='checked' className={styles.groupItem}>
            <Switch />
          </Form.Item>
        </div>

        <div className={styles.group}>
          <Form.Item name='hasFixedPrices' label='Has fixed prices' valuePropName='checked' className={styles.groupItem}>
            <Switch />
          </Form.Item>
          <Form.Item name='fixedPrice' label='Fixed price' className={styles.groupItem}>
            <InputNumber min={0.0} prefix='$' className={styles.inputField} />
          </Form.Item>
        </div>

        <Button onClick={() => setAdvancedMode(!advancedMode)} className={styles.advancedButton}>
          {advancedMode ? 'Normal Mode' : 'Advanced Mode'}
        </Button>

        {advancedMode && (
          <>
            <div className={styles.group}>
              <Form.Item
                name='deploymentPlugin'
                label='Deployment Plugin'
                rules={[
                  {
                    required: true,
                    message: 'Please select Deployment Plugin!'
                  }
                ]}
                className={styles.groupItem}
              >
                <Select placeholder='Select Deployment Plugin' onChange={onDeploymentPluginValueChange}>
                  <Select.Option value='AMAZON'>Amazon</Select.Option>
                  <Select.Option value='NGINX'>Nginx</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name='deploymentSettingsId'
                label='Deployment Settings'
                rules={[
                  {
                    required: true,
                    message: 'Please select Deployment Settings!'
                  }
                ]}
                className={styles.groupItem}
              >
                <DeploymentSettingsSelect
                  plugin={selectedDeploymentPlugin}
                  initialOption={selectedDeploymentSettings}
                  onSelect={(selectedOption: SelectOption) => {
                    form.setFieldsValue({
                      deploymentSettingsId: selectedOption?.id
                    })
                    setSelectedDeploymentSettings(selectedOption)
                  }}
                />
              </Form.Item>
            </div>

            <div className={styles.group}>
              <Form.Item
                name='paymentPlugin'
                label='Payment Plugin'
                rules={[
                  {
                    required: true,
                    message: 'Please select Payment Plugin!'
                  }
                ]}
                className={styles.groupItem}
              >
                <Select placeholder='Select Payments Plugin' onChange={onPaymentPluginValueChange}>
                  <Select.Option value='AUTHORIZE'>Authorize</Select.Option>
                  <Select.Option value='STRIPE'>Stripe</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name='paymentSettingsId'
                label='Payment Settings'
                rules={[
                  {
                    required: true,
                    message: 'Please select Payment Settings!'
                  }
                ]}
                className={styles.groupItem}
              >
                <PaymentSettingsSelect
                  plugin={selectedPaymentPlugin}
                  initialOption={selectedPaymentSettings}
                  onSelect={(selectedOption: SelectOption) => {
                    form.setFieldsValue({
                      paymentSettingsId: selectedOption?.id
                    })
                    setSelectedPaymentSettings(selectedOption)
                  }}
                />
              </Form.Item>
            </div>

            <div className={styles.group}>
              <Form.Item
                name='fulfillmentPlugin'
                label='Fulfillment Plugin'
                rules={[
                  {
                    required: true,
                    message: 'Please select Fulfillment Plugin!'
                  }
                ]}
                className={styles.groupItem}
              >
                <Select placeholder='Select Fulfillment Plugin' onChange={onFulfillmentPluginValueChange}>
                  <Select.Option value='CUSTOM_GATEWAY'>Custom Gateway</Select.Option>
                  <Select.Option value='SHIPSTATION'>Shipstation</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name='fulfillmentSettingsId'
                label='Fulfillment Settings'
                rules={[
                  {
                    required: true,
                    message: 'Please select Fulfillment Settings!'
                  }
                ]}
                className={styles.groupItem}
              >
                <FulfillmentSettingsSelect
                  plugin={selectedFulfillmentPlugin}
                  initialOption={selectedFulfillmentSettings}
                  onSelect={(selectedOption: SelectOption) => {
                    form.setFieldsValue({
                      fulfillmentSettingsId: selectedOption?.id
                    })
                    setSelectedFulfillmentSettings(selectedOption)
                  }}
                />
              </Form.Item>
            </div>

            <div className={styles.group}>
              <Form.Item
                name='mailingPlugin'
                label='Mailing Plugin'
                rules={[
                  {
                    required: true,
                    message: 'Please select Mailing Plugin!'
                  }
                ]}
                className={styles.groupItem}
              >
                <Select placeholder='Select Mailing Plugin' onChange={onMailingPluginValueChange}>
                  <Select.Option value='POSTMARK'>Postmark</Select.Option>
                  <Select.Option value='SENDGRID'>Sendgrid</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name='mailingSettingsId'
                label='Mailing Settings'
                rules={[
                  {
                    required: true,
                    message: 'Please select Mailing Settings!'
                  }
                ]}
                className={styles.groupItem}
              >
                <MailingSettingsSelect
                  plugin={selectedMailingPlugin}
                  initialOption={selectedMailingSettings}
                  onSelect={(selectedOption: SelectOption) => {
                    form.setFieldsValue({
                      mailingSettingsId: selectedOption?.id
                    })
                    setSelectedMailingSettings(selectedOption)
                  }}
                />
              </Form.Item>
            </div>

            <div className={styles.group}>
              <Form.Item
                name='alternativeMailingPlugin'
                label='Alternative Mailing Plugin'
                rules={[
                  {
                    required: true,
                    message: 'Please select Alternative Mailing Plugin!'
                  }
                ]}
                className={styles.groupItem}
              >
                <Select placeholder='Select Alternative Mailing Plugin' onChange={onAlternativeMailingPluginValueChange}>
                  <Select.Option value='POSTMARK'>Postmark</Select.Option>
                  <Select.Option value='SENDGRID'>Sendgrid</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name='alternativeMailingSettingsId'
                label='Alternative Mailing Settings'
                rules={[
                  {
                    required: true,
                    message: 'Please select Alternative Mailing Settings!'
                  }
                ]}
                className={styles.groupItem}
              >
                <MailingSettingsSelect
                  plugin={selectedAlternativeMailingPlugin}
                  initialOption={selectedAlternativeMailingSettings}
                  onSelect={(selectedOption: SelectOption) => {
                    form.setFieldsValue({
                      alternativeMailingSettingsId: selectedOption?.id
                    })
                    setSelectedAlternativeMailingSettings(selectedOption)
                  }}
                />
              </Form.Item>
            </div>

            <div className={styles.group}>
              <Form.Item name='smsPlugin' label='Sms Plugin' className={styles.groupItem}>
                <Select placeholder='Select Sms Plugin' onChange={onSmsPluginValueChange}>
                  <Select.Option value='SIMPLE_TEXT'>Simple Text</Select.Option>
                  <Select.Option value='ZIPWHIP'>Zipwhip</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item name='smsSettingsId' label='Sms Settings' className={styles.groupItem}>
                <SmsSettingsSelect
                  plugin={selectedSmsPlugin}
                  initialOption={selectedSmsSettings}
                  onSelect={(selectedOption: SelectOption) => {
                    form.setFieldsValue({
                      smsSettingsId: selectedOption?.id
                    })
                    setSelectedSmsSettings(selectedOption)
                  }}
                />
              </Form.Item>
            </div>
          </>
        )}
      </Card>
    </Form>
  )
}

export default connector(LandingWizardInitials)
