import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import moment from 'moment'
import { StoreInviteDto } from '@merchx-v2/shared-types'
import { Button, Tag } from 'antd'

const tagColors = {
  WAITING_RESPONSE: 'orange',
  ACCEPTED: 'green',
  DECLINED: 'red',
  EXPIRED: 'red'
}

export const createTableColumns = (onMarkAsAccepted: (email: string) => void): ColumnsType<StoreInviteDto> => {
  return [
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Status',
      key: 'status',
      width: 300,
      render: (_value, record) => <Tag color={tagColors[record.status]}>{record.status}</Tag>
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 300,
      render: (_value, record) => <Button disabled={['ACCEPTED', 'DECLINED'].includes(record.status)} onClick={() => onMarkAsAccepted(record.email)}>Mark as accepted</Button>
    },
    {
      title: 'Date',
      render: (record) => moment(record.createdAt).format('MM/DD/YYYY HH:mm a'),
      key: 'createdAt',
      width: '200px'
    }
  ]
}
