import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Table, Popconfirm } from 'antd'
import { EditOutlined, DeleteTwoTone } from '@ant-design/icons'
import notification from 'mrx-notification'

import { ColumnProps } from 'antd/es/table'
import { useCustomerSubscription, useRemoveCustomerSubscriptionNote } from '../../hooks'
import { CustomerSubscriptionNote } from '../../types'
import CreateCustomerSubscriptionNote from '../CreateCustomerSubscriptionNote'
import UpdateCustomerSubscriptionNote from '../UpdateCustomerSubscriptionNote'
import { connector, PropsFromRedux } from './container'
import styles from './NotesTab.module.scss'

type NotesTabType = PropsFromRedux & {
  customerSubscriptionId?: number
}

const NotesTab = (props: NotesTabType) => {
  const { customerSubscriptionId, user } = props

  const [selectedRecord, setSelectedRecord] = useState<CustomerSubscriptionNote>()
  const [isUpdateDrawerVisible, setIsUpdateDrawerVisible] = useState<boolean>(false)

  const customerSubscription = useCustomerSubscription(customerSubscriptionId)
  const removeCustomerSubscriptionNote = useRemoveCustomerSubscriptionNote()

  useEffect(() => {
    if (customerSubscription.error) {
      notification.error({
        message: 'Customer subscriptions list error!',
        description:
          customerSubscription.error instanceof Error ? customerSubscription.error.message : customerSubscription.error.toString()
      })
    }
  }, [customerSubscription.error])

  useEffect(() => {
    if (removeCustomerSubscriptionNote.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Customer Subscription Note was removed successfully!'
      })
    }
  }, [removeCustomerSubscriptionNote.isSuccess])

  const doConfirm = async (customerSubscriptionNoteId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    removeCustomerSubscriptionNote.mutate(customerSubscriptionNoteId)
  }

  console.log('record', selectedRecord)

  const columns: ColumnProps<CustomerSubscriptionNote>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Author Name',
      render: (record: CustomerSubscriptionNote) => {
        return (
          <div>
            {record.author?.firstName} {record.author?.lastName}
          </div>
        )
      },
      key: 'firstName'
    },
    {
      title: 'Note',
      render: (record: CustomerSubscriptionNote) => {
        return <div>{record.note}</div>
      },
      key: 'note'
    },
    {
      title: 'Date',
      render: (record: CustomerSubscriptionNote) => moment(record.createdAt).format('MM/DD/YYYY HH:mm:ss'),
      key: 'createdAt'
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (record: CustomerSubscriptionNote) => {
        if (record.userId !== user.id && !user.roles.includes('WORKSPACE_ADMIN')) return null

        return (
          <div className={styles.actionIcons}>
            <span
              style={{ marginRight: '20px' }}
              onClick={(e) => {
                e.preventDefault()
                setSelectedRecord(record)
                setIsUpdateDrawerVisible(true)
              }}
            >
              <EditOutlined style={{ fontSize: '20px', cursor: 'pointer' }} />
            </span>
            <span>
              <Popconfirm
                title='Are you sure delete this note?'
                onConfirm={(e) => doConfirm(record.id, e)}
                onCancel={(e) => e?.stopPropagation()}
                okText='Yes'
                cancelText='No'
              >
                <DeleteTwoTone
                  twoToneColor='#ec1c24'
                  style={{ fontSize: '20px' }}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                />
              </Popconfirm>
            </span>
          </div>
        )
      }
    }
  ]

  const handleOnClose = () => {
    setIsUpdateDrawerVisible(false)
  }

  const renderUpdate = (e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    return (
      <UpdateCustomerSubscriptionNote
        customerSubscriptionId={customerSubscriptionId}
        customerSubscriptionNoteId={selectedRecord?.id}
        onClose={handleOnClose}
        visible={isUpdateDrawerVisible}
      />
    )
  }

  return (
    <>
      <CreateCustomerSubscriptionNote className={styles.createButton} customerSubscriptionId={customerSubscriptionId} />
      <Table
        columns={columns}
        dataSource={customerSubscription.data.customerSubscriptionNotes.map((item) => item).flat()}
        bordered
        rowKey={(record) => record.id}
        loading={customerSubscription.isLoading}
      />
      {selectedRecord && renderUpdate()}
    </>
  )
}

export default connector(NotesTab)
