import { SettingData } from './types'

export const shipStationSettingsData: SettingData[] = [
  {
    name: 'apiKey',
    label: 'API Key',
    rules: [{ required: true, message: 'Please input shipstation setting API Key!' }]
  },
  {
    name: 'apiSecret',
    label: 'API Secret',
    rules: [{ required: true, message: 'Please input shipstation setting API Secret!' }]
  },
  {
    name: 'storeId',
    label: 'Store ID',
    rules: [{ required: true, message: 'Please input shipstation setting Store ID!' }],
    type: 'number'
  }
]
