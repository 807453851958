import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'

type OwnProps = {
  className?: string
  children: React.ReactNode
  loadMore: Function
  hasMore: boolean
  threshold?: number
  pageStart?: number
}

const EternalList = (props: OwnProps) => {
  const { children, loadMore, className, pageStart = 0, hasMore, threshold } = props

  return (
    <InfiniteScroll
      loadMore={loadMore}
      className={className}
      hasMore={hasMore}
      pageStart={pageStart}
      style={{ width: '100%' }}
      initialLoad={false}
      useCapture
      threshold={threshold}
      useWindow={false}
    >
      {children}
    </InfiniteScroll>
  )
}

export default EternalList
