// TODO - remove becouse of legacy

import { Landing, StepState, WizardStepStatus } from '../../types'

const getQuizStep: (landing: Landing, currentStep: number) => StepState = (landing, currentStep) => {
  const isValid = !!landing?.landingQuiz?.id
  let status = 'wait' as WizardStepStatus

  if (isValid) {
    status = 'finish' as WizardStepStatus
  }

  if (currentStep === 2) {
    status = 'process' as WizardStepStatus
  }

  if (currentStep !== 2 && !isValid) {
    status = 'error' as WizardStepStatus
  }

  return {
    isValid,
    status
  }
}

export { getQuizStep }
