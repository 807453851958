import axios from 'utils/axios'
import { useQuery } from 'react-query'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

import { Country } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'countriesForOptions', Country[]>

const FETCH_COUNTRIES_FOR_OPTIONS = `
  query fetchCountriesForOptions($filters: CountriesFilters) {
    countriesForOptions(filters: $filters) {
      id
      name
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchCountriesForOptions = (searchText: string, limit?: number) => Promise<Country[]>

const fetchCountriesForOptions: FetchCountriesForOptions = async (searchText, limit = 20) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_COUNTRIES_FOR_OPTIONS,
      variables: { filters: { searchText, limit } }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { countriesForOptions } = data
    if (!countriesForOptions) {
      throw new Error("Can't get product tags!")
    }

    return countriesForOptions
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCountriesOptions = (searchText: string, limit = 20) =>
  useQuery(['countriesOptions', searchText], () => fetchCountriesForOptions(searchText, limit))
