// TODO - remove becouse of legacy

import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { TemplateBuild } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'buildTemplate', TemplateBuild>

const BUILD_TEMPLATE = `
  mutation buildTemplate($workspaceId: Int!, $templateId: Int!, $version: Int!) {
    buildTemplate(workspaceId: $workspaceId, templateId: $templateId, version: $version) {
      id
      templateId
      version
      progress
      status
      browserRouterArtifactsS3path
      hashRouterArtifactsS3path
      logs
      startedAt
      endedAt
      buildTime
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type BuildTemplateArgs = {
  workspaceId: number
  templateId: number
  version: number
}

type BuildTemplate = (args: BuildTemplateArgs) => Promise<TemplateBuild>

const buildTemplate: BuildTemplate = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: BUILD_TEMPLATE,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { buildTemplate } = data
    if (!buildTemplate) {
      throw new Error("Can't build template!")
    }

    return buildTemplate
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type BuildTemplateEvents = {
  onSettled: (data?: TemplateBuild) => void
}

const buildTemplateEvents: BuildTemplateEvents = {
  onSettled: (templateBuild) => {
    if (templateBuild?.id) {
      queryClient.invalidateQueries(['template'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useBuildTemplate = () => useMutation(buildTemplate, buildTemplateEvents)
