import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import useReactRouter from 'use-react-router'
import moment from 'moment'
import useCustomCompareEffect from 'use-custom-compare-effect'
import hash from 'object-hash'
import { Layout, PageHeader, Tabs, Button, Input, Empty, Modal } from 'antd'
import notification from 'mrx-notification'
import { Breadcrumbs, Loading } from 'components'
import * as rootStyles from 'assets/layoutStyle'
import { Address } from 'features/addresses/types'
import { OrderItem } from 'features/orders/types'
import { useOrder, useUpdateOrder } from '../../hooks'
import { AddressTabManual } from '../../components'
import OrderItemsTabManual from '../OrderItemsTabManual'
import { connector, PropsFromRedux } from './container'
import styles from './ManualOrder.module.scss'

type Props = PropsFromRedux & {
  match: { params: { orderId: number } }
  onClose: () => void
}

const ManualOrder = (props: Props) => {
  const {
    match: {
      params: { orderId }
    },
    workspaceId
  } = props

  const order = useOrder(+orderId)
  const updateOrder = useUpdateOrder()

  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)
  const [orderHash, setOrderHash] = useState<string>(hash(order || {}))
  const [orderItems, setOrderItems] = useState<OrderItem[]>([])
  const [shippingAddress, setShippingAddress] = useState<Address>(order?.data?.shippingAddress || ({} as Address))
  const [billingAddress, setBillingAddress] = useState<Address>(order?.data?.billingAddress || ({} as Address))
  const [comment, setComment] = useState<string>(order?.data?.comment || '')
  const [isUpdateModal, setIsUpdateModal] = useState<boolean>(false)

  const { history } = useReactRouter()

  useCustomCompareEffect(
    () => {
      if (order.data) {
        setOrderHash(hash(order.data))

        setOrderItems(order.data.orderItems.filter((item: OrderItem) => item.status === 'ACTIVE'))
        setShippingAddress(order.data.shippingAddress)
        setBillingAddress(order.data.billingAddress)
        setComment(order.data.comment || '')
      }
    },
    [order.data],
    () => order.data === undefined || hash(order.data) === orderHash // call only at false
  )

  useEffect(() => {
    if (updateOrder.error) {
      notification.error({
        message: 'Can not update order!',
        description: updateOrder.error.toString()
      })
    }
  }, [updateOrder.error])

  useEffect(() => {
    if (updateOrder.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Order was updated successfully!'
      })
    }
  }, [updateOrder.isSuccess])

  const handleOnClose = () => {
    setIsDrawerVisible(false)
  }

  const handleSaveOrder = async () => {
    const prepearedOrderItems = orderItems.map((item) => {
      return {
        id: item.id,
        productId: item.productId,
        productPriceId: item.productPriceId,
        displayName: item.displayName,
        description: item.description,
        sku: item.sku,
        quantity: item.quantity,
        price: item.price,
        discount: item.discount,
        shipping: item.shipping,
        processing: item.processing
      }
    })

    setIsUpdateModal(false)
    updateOrder.mutate({
      workspaceId,
      orderData: {
        orderId: +orderId,
        billingAddress: {
          countryId: billingAddress.countryId,
          zipCode: billingAddress.zipCode,
          state: billingAddress.state,
          city: billingAddress.city,
          address: billingAddress.address,
          address2: billingAddress.address2
        },
        shippingAddress: {
          countryId: shippingAddress.countryId,
          zipCode: shippingAddress.zipCode,
          state: shippingAddress.state,
          city: shippingAddress.city,
          address: shippingAddress.address,
          address2: shippingAddress.address2
        },
        orderItems: prepearedOrderItems,
        comment
      }
    })
  }

  const handleOrderItemsChanged = (newOrderItems: OrderItem[]) => {
    setOrderItems(newOrderItems)
  }

  const handleShippingAddressChanged = (newAddress: Address) => {
    setShippingAddress(newAddress)
  }

  const handleBillingAddressChanged = (newAddress: Address) => {
    setBillingAddress(newAddress)
  }

  const isOrderProcessedInFulfillment = ['COMPLETED', 'DISPATCHED'].includes(order?.data?.status)

  return (
    <Layout style={rootStyles.root}>
      <Modal
        title={
          <p>
            <span style={{ color: 'orange' }}>Fulfillment warning!</span>
          </p>
        }
        visible={isUpdateModal}
        onOk={handleSaveOrder}
        onCancel={() => setIsUpdateModal(false)}
      >
        This action will <span style={{ color: 'purple' }}>DUPLICATE</span> order in fulfillment. Are you sure you want to continue?
      </Modal>
      <PageHeader
        onBack={() => history.goBack()}
        title='Manual Order Creation'
        extra={[
          <Button key='save-button' type='primary' onClick={isOrderProcessedInFulfillment ? () => setIsUpdateModal(true) : handleSaveOrder}>
            SAVE
          </Button>
        ]}
      />
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Sales', url: '/' },
            { title: 'Orders', url: '/sales/orders' },
            { title: 'Manual Order Creation', url: '/sales/orders' }
          ]}
        />
      </Layout.Content>
      {order.isLoading && <Loading />}
      {!order.isLoading && !order.data && (
        <div className={styles.emptyContainer}>
          <Empty />
        </div>
      )}
      {!order.isLoading && order.data && (
        <div className={styles.contentContainer}>
          <div className={styles.listContainer}>
            <div className={styles.leftColumn}>
              <div className={styles.listItem}>
                Order ID: <Link to={`/sales/orders?orderId=${order.data.id}`}>{order.data.id}</Link>
              </div>
              <div className={styles.listItem}>
                Customer:{' '}
                <Link to={`/sales/orders/${order.data.id}/customers/${order.data.customerId}`}>
                  {`${order.data.customer.firstName} ${order.data.customer.lastName}`}
                </Link>
              </div>
              <div className={styles.listItem}>Date Added: {moment(order.data.createdAt).format('MM/DD/YYYY HH:mm:ss')}</div>
              <div className={styles.listItem}>Shipping Method: {order.data.landing?.fulfillmentPlugin}</div>
              <div className={styles.listItem}>Sale Source: {order.data.saleSource}</div>
              <div className={styles.listItemTrack}>
                Tracking Number: <div style={{ marginLeft: '5px' }}>{order.data.trackingNumber}</div>
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.listItem}>
                Campaign: <Link to={`/campaigns/${order?.data?.landing?.campaign?.id}`}>{order?.data?.landing?.campaign?.name}</Link>
              </div>
              <div className={styles.listItem}>Status: {order.data.status}</div>
              <div className={styles.listItem}>Email: {order.data.customer.email}</div>
              <div className={styles.listItem}>
                Promocode: <Link to={`/promocodes/${order?.data?.promocode}`}>{order?.data?.promocode}</Link>
              </div>
              <div className={styles.listItem}>Total: {`$${(order.data.total / 100).toFixed(2)}`}</div>
              <div className={styles.listItem}>Payment Method: {order.data.landing?.paymentPlugin}</div>
              <div className={styles.listItem}>Site URL: {order.data.landing?.siteUrl}</div>
            </div>
          </div>

          <Tabs
            type='card'
            className={styles.tabsContainer}
            tabBarExtraContent={
              order?.data?.saleSource !== 'invoice'
               ? (<Button type='primary' onClick={() => setIsDrawerVisible(true)}>
                    + ADD PRODUCT
                  </Button>)
               : null
            }
          >
            <Tabs.TabPane tab='Products' key='1'>
              <OrderItemsTabManual
                campaignId={order.data.landing?.campaign?.id}
                order={order.data}
                visible={isDrawerVisible}
                onOrderItemsChanged={handleOrderItemsChanged}
                onClose={handleOnClose}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Shipping Address' key='2'>
              <AddressTabManual sourceAddress={order?.data?.shippingAddress} onAddressChanged={handleShippingAddressChanged} />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Payment Address' key='3'>
              <AddressTabManual sourceAddress={order?.data?.billingAddress} onAddressChanged={handleBillingAddressChanged} />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Notes' key='4'>
              <Input.TextArea allowClear value={comment} onChange={(e) => setComment(e.target.value)} placeholder='Type some notes here' />
            </Tabs.TabPane>
          </Tabs>
        </div>
      )}
    </Layout>
  )
}

export default connector(ManualOrder)
