import React from 'react'
import slugify from 'slugify'
import { Button, Drawer, Input, Form } from 'antd'
import { productSkuPattern, productSkuPatternErrorMessage } from '@merchx-v2/shared-types'
import notification from 'mrx-notification'
import { convertToSku } from 'utils/convertToSku'
import styles from './DuplicateProduct.module.scss'
import { connector, PropsFromRedux } from './container'

const DuplicateProduct = (props: PropsFromRedux) => {
  const {
    onClose,
    isLoading,
    productId,
    campaignId,
    visible,

    duplicateProduct
  } = props

  const [form] = Form.useForm()

  const handleClose = (productId?: number) => {
    form.resetFields()
    onClose(productId)
  }

  const handleFormValueChanged = (changedValues) => {
    if (changedValues.displayName) {
      const newSeoUrl = slugify(changedValues.displayName, { lower: true, remove: /[*+~.()'"!:@{}]/g })
      form.setFieldsValue({
        sku: convertToSku(changedValues.displayName),
        seoUrl: newSeoUrl
      })
    }

    if (changedValues.name) {
      const newSeoUrl = slugify(changedValues.name, { lower: true, remove: /[*+~.()'"!:@{}]/g })
      form.setFieldsValue({
        sku: convertToSku(changedValues.name),
        seoUrl: newSeoUrl
      })
    }

    if (changedValues.sku) {
      const newSku = convertToSku(changedValues.sku)
      if (newSku !== changedValues.sku) {
        form.setFieldsValue({
          sku: newSku
        })
      }
    }

    if (changedValues.seoUrl) {
      const newSeoUrl = slugify(changedValues.seoUrl, { lower: true, remove: /[*+~.()'"!:@{}]/g })
      if (newSeoUrl !== changedValues.seoUrl) {
        form.setFieldsValue({
          seoUrl: newSeoUrl
        })
      }
    }
  }

  // TODO - fix any type
  const onFinishHandler = async (values: any) => {
    const isDuplicated = await duplicateProduct({
      productId,
      productData: {
        name: values.name,
        displayName: values.displayName,
        description: values.description,
        sku: values.sku,
        seoUrl: values.seoUrl
      },
      productPriceOwnerId: campaignId,
      productPriceOwnerType: 'CAMPAIGN'
    })
    if (isDuplicated) {
      notification.success({
        message: 'Successfully',
        description: 'Product was duplicated successfully!'
      })
      handleClose(isDuplicated.id)
    }
  }

  return (
    <Drawer title='Duplicate Product' width='400' onClose={() => handleClose()} visible={visible}>
      <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form} onValuesChange={handleFormValueChanged}>
        <Form.Item name='displayName' label='Product name' rules={[{ required: true, message: 'Please input product  name!' }]}>
          <Input />
        </Form.Item>

        <Form.Item name='name' label='Dropdown name' rules={[{ required: true, message: 'Please input dropdown product name!' }]}>
          <Input />
        </Form.Item>

        <Form.Item name='description' label='Description' rules={[{ required: true, message: 'Please input product description!' }]}>
          <Input.TextArea />
        </Form.Item>

        <Form.Item name='sku' label='SKU' rules={[{ pattern: productSkuPattern, message: productSkuPatternErrorMessage }, { required: true, message: 'Please input product SKU!' }]}>
          <Input />
        </Form.Item>

        <Form.Item name='seoUrl' label='SEO Url' rules={[{ required: true, message: 'Please input product SEO URL!' }]}>
          <Input />
        </Form.Item>

        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right'
          }}
        >
          <Button onClick={() => handleClose()} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Duplicate
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default connector(DuplicateProduct)
