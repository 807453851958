import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Address } from '../types'

type AddressesList = {
  items: Address[]
  total: number
}

type Payload = AddressesList & {
  page: number
}

export type FetchAddressesListAction = FSA<undefined, Payload, string>
type FetchOAddressesList = (workspaceId: number, page: number) => MrxThunk<FetchAddressesListAction>
type QueryResponse = GraphQLResponse<'addressesListPage', AddressesList>

const FETCH_ADDRESSES_LIST = `
 query fetchAddressesList ($page: Int, $size: Int, $workspaceId: Int!) {
  addressesListPage (page: $page, size: $size, workspaceId: $workspaceId) {
    items {
      id
      firstName
      lastName
      countryId
      country {
        id
        name
        code
      }
      zipCode
      state
      city
      address
      address2
      createdAt
      updatedAt
    }
    total
  }
}
`

const fetchAddressesList: FetchOAddressesList =
  (workspaceId, page = 1) =>
  async (dispatch, getState) => {
    dispatch({
      type: t.FETCH_ADDRESSES_LIST,
      meta: { done: false }
    })

    try {
      const {
        addresses: {
          pagination: { size }
        }
      } = getState()

      const {
        data: { data, errors }
      }: QueryResponse = await axios.post('/graphql', {
        query: FETCH_ADDRESSES_LIST,
        variables: { page, size, workspaceId }
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { addressesListPage } = data
      if (!addressesListPage) {
        throw new Error("Can't get addresses list page!")
      }

      dispatch({
        type: t.FETCH_ADDRESSES_LIST,
        payload: {
          items: addressesListPage.items,
          total: addressesListPage.total,
          page
        },
        meta: { done: true }
      })
    } catch (err) {
      dispatch({
        type: t.FETCH_ADDRESSES_LIST,
        payload: extractErrorInfo(err),
        error: true
      })
    }
  }

export { fetchAddressesList }
