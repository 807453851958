import { useInfiniteQuery } from 'react-query'
import hash from 'object-hash'
import { Moment } from 'moment-timezone'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

import { CustomerSubscription } from '../types'

const DEFAULT_PAGE_SIZE = 15

type CustomerSubscriptionFilters = {
  workspaceId: number
  landingId?: number
  customerId?: number
  billingAddressId?: number
  shippingAddressId?: number
  status?: string
  saleSource?: string
  campaignId?: number
  sorting?: SortingType
  query?: string
  startDate?: Moment
  endDate?: Moment
}

export type CustomerSubscriptionsPage = {
  items: CustomerSubscription[]
  total: number
  currentPage: number
  pageSize: number
  filters?: CustomerSubscriptionFilters
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'customerSubscriptionsListPage', CustomerSubscriptionsPage>

const FETCH_CUSTOMER_SUBSCRIPTION_LIST = `
  query fetchCustomerSubscriptionListPage ($page: Int, $size: Int, $filters: CustomerSubscriptionsFilters!) {
    customerSubscriptionsListPage(page: $page, size: $size, filters: $filters) {
      items {
        id
        creationDate
        customer {
          firstName
          lastName
          email
        }
        landing {
          name
        }
        saleSource
        status
        total
        paymentSubscriptionId
        orderId
        campaignName
        interval
        repeatFrequency
        comment
        cycles
        creationDate
        paidTill
        startDate
        createdAt
        canceledAt
        canceledBy
        cancelReason
      }
      total
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type PageParam = {
  page?: number
  size?: number
  filters: CustomerSubscriptionFilters
}

type FetchCustomerSubscriptionsListArgs = {
  queryKey: string | string[]
  pageParam?: PageParam
}

type FetchCustomerSubscriptionsList = (args: FetchCustomerSubscriptionsListArgs) => Promise<CustomerSubscriptionsPage>

const fetchCustomerSubscriptionsList: FetchCustomerSubscriptionsList = async ({ pageParam }) => {
  const { page = 1, size = DEFAULT_PAGE_SIZE, filters = {} } = pageParam || {}
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_CUSTOMER_SUBSCRIPTION_LIST,
      variables: { page, size, filters }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { customerSubscriptionsListPage } = data
    if (!customerSubscriptionsListPage) {
      throw new Error("Can't get customer subscriptions list page!")
    }

    return {
      items: customerSubscriptionsListPage.items,
      total: customerSubscriptionsListPage.total,
      currentPage: page,
      pageSize: size
    }
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

type GetNextPageParam = (args: CustomerSubscriptionsPage) => PageParam

type CustomerSubscriptionsListEvents = {
  keepPreviousData: boolean
  getPreviousPageParam: GetNextPageParam
  getNextPageParam: GetNextPageParam
}

const customerSubscriptionsListEvents: CustomerSubscriptionsListEvents = {
  keepPreviousData: true,
  getPreviousPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, filters }) => {
    if (currentPage > 1) {
      return {
        page: currentPage - 1,
        size: pageSize,
        filters
      }
    }

    return undefined
  },
  getNextPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, total = 0, filters }) => {
    if (pageSize * currentPage < total) {
      return {
        page: currentPage + 1,
        size: pageSize,
        filters
      }
    }
    return undefined
  }
}

export const useCustomerSubscriptionsList = (filters?: CustomerSubscriptionFilters) => {
  const cacheKey = ['customerSubscriptionsList', hash(filters)]

  return useInfiniteQuery(
    cacheKey,
    (prev) =>
      fetchCustomerSubscriptionsList({
        queryKey: cacheKey,
        pageParam: { ...prev.pageParam, filters }
      }),
    customerSubscriptionsListEvents
  )
}
