import { SettingData } from './types'

export const amazonSettingsData: SettingData[] = [
  {
    name: 'accessKey',
    label: 'Access Key',
    rules: [{ required: true, message: 'Please input AWS Setting access key!' }]
  },
  {
    name: 'secretAccessKey',
    label: 'Secret Access Key',
    rules: [{ required: true, message: 'Please input AWS Setting secret access key!' }]
  },
  {
    name: 'region',
    label: 'Region',
    rules: [{ required: true, message: 'Please select AWS Setting region!' }],
    options: [{ key: '1', value: 'us-east-1', title: 'US-East-1' }]
  }
]
