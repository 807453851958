import React, { useEffect } from 'react'
import moment from 'moment'
import useReactRouter from 'use-react-router'
import { Layout, PageHeader, Tabs, Button, Popconfirm, Tag } from 'antd'
import notification from 'mrx-notification'
import { Link } from 'react-router-dom'
import * as rootStyles from 'assets/layoutStyle'
import { Breadcrumbs } from 'components'
import { useAddCustomerTag, useRemoveCustomerTag } from 'features/customers/hooks'
import { EventsTab, ProductsTab, PaymentsTab, ShippingAddressTab, PaymentAddressTab, TasksTab, NotesTab } from '../../components'
import { useOrder, useCancelOrder } from '../../hooks'
import styles from './OrderDetailsForCustomerDetails.module.scss'

type Props = {
  match: { params: { orderId: string } }
  onClose: () => void
}

const OrderDetailsForCustomerDetails = (props: Props) => {
  const { match } = props

  const { history } = useReactRouter()
  const orderId = +match.params.orderId

  const addCustomerTag = useAddCustomerTag()
  const removeCustomerTag = useRemoveCustomerTag()

  const order = useOrder(orderId)
  const cancelOrder = useCancelOrder()

  useEffect(() => {
    if (addCustomerTag.isError) {
      notification.error({
        message: 'Error',
        description: addCustomerTag.error instanceof Error ? addCustomerTag.error.message : addCustomerTag.error.toString()
      })
    }

    if (removeCustomerTag.isError) {
      notification.error({
        message: 'Error',
        description: removeCustomerTag.error instanceof Error ? removeCustomerTag.error.message : removeCustomerTag.error.toString()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCustomerTag.isError, removeCustomerTag.isError])

  useEffect(() => {
    if (cancelOrder.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Order was canceled successfully!'
      })
    }
  }, [cancelOrder.isSuccess])

  const doConfirm = async () => {
    cancelOrder.mutate({ orderId, shouldVoid: true })
  }

  const handleChargebackAssign = () => {
    if (order?.data?.customer?.customerTags?.find((tag) => tag.tag === 'CHARGEBACK')) {
      removeCustomerTag.mutate({
        customerId: order.data.customerId,
        tag: 'CHARGEBACK'
      })
    } else {
      addCustomerTag.mutate({
        customerId: order.data.customerId,
        tag: 'CHARGEBACK'
      })
    }
  }

  const customerChargebackTag = order?.data?.customer?.customerTags?.find((tag) => tag.tag === 'CHARGEBACK')

  return (
    <Layout style={rootStyles.root}>
      <PageHeader
        onBack={() => history.goBack()}
        title='Order Details'
        extra={[
          <Popconfirm
            key='cancel-button'
            title='Are you sure cancel this order?'
            onConfirm={() => doConfirm()}
            onCancel={(e) => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <Button disabled={cancelOrder.isLoading || order?.data?.status === 'CANCELED'} key='2'>
              Void Order
            </Button>
          </Popconfirm>,

          <Button key='manual-order-creation' type='primary' onClick={() => history.push(`/sales/orders/edit/${orderId}`)}>
            Edit Order
          </Button>
        ]}
      />
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Sales', url: '/' },
            { title: 'Orders', url: '/sales/orders' },
            { title: 'Order Details', url: '/sales/orderDetails' }
          ]}
        />
      </Layout.Content>
      {order && (
        <>
          <div className={styles.listContainer}>
            <div className={styles.leftColumn}>
              <div className={styles.listItem}>Order ID: {order?.data?.id}</div>
              <div className={styles.listItem}>
                Customer:{' '}
                <Link to={`/sales/orders/${order?.data?.id}/customers/${order?.data?.customerId}`}>
                  {`${order?.data?.customer.firstName} ${order?.data?.customer.lastName}`}
                </Link>
                {customerChargebackTag && (
                  <Tag color='darkred' style={{ marginLeft: '10px' }}>
                    Chargeback
                  </Tag>
                )}
                <Button style={{ marginLeft: '6px' }} type='primary' danger onClick={handleChargebackAssign}>
                  {customerChargebackTag ? 'Unmark' : 'Mark'} as chargeback
                </Button>
              </div>
              <div className={styles.listItem}>Date Added: {moment(order?.data?.createdAt).format('MM/DD/YYYY HH:mm:ss')}</div>
              <div className={styles.listItem}>Shipping Method: {order?.data?.landing?.fulfillmentPlugin}</div>
              <div className={styles.listItem}>
                Sale Source: {order?.data?.saleSource}
                {order?.data?.customSaleSource ? ` (${order.data.customSaleSource})` : ''}
              </div>
              <div className={styles.listItem}>Tracking Number: {order?.data?.trackingNumber}</div>
              {order?.data?.customerSubscriptionId && (
                <div className={styles.listItem}>
                  Subscription:{' '}
                  <Link to={`/sales/customerSubscriptions?customerSubscriptionId=${order.data.customerSubscriptionId}`}>
                    {order.data.customerSubscriptionId}
                  </Link>
                </div>
              )}
              <div className={styles.listItem}>
                Landing:{' '}
                <Link to={`/campaigns/${order?.data?.landing?.campaign?.id}/landingWizard?landingId=${order?.data?.landingId}&step=0`}>
                  {order?.data?.landing?.name}
                </Link>
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.listItem}>
                Campaign: <Link to={`/campaigns/${order?.data?.landing?.campaign?.id}`}>{order?.data?.landing?.campaign?.name}</Link>
              </div>
              <div className={styles.listItem}>Status: {order?.data?.status}</div>
              <div className={styles.listItem}>Email: {order?.data?.customer.email}</div>
              <div className={styles.listItem}>Phone: {order?.data?.customer.phone}</div>
              <div className={styles.listItem}>
                Promocode: <Link to={`/promocodes/${order?.data?.promocode}`}>{order?.data?.promocode}</Link>
              </div>
              <div className={styles.listItem}>Total: {`$${(order?.data?.total / 100).toFixed(2)}`}</div>
              <div className={styles.listItem}>Payment Method: {order?.data?.landing?.paymentPlugin}</div>
              <div className={styles.listItem}>
                Site URL:{' '}
                <a target='_blank' rel='noopener noreferrer' href={`${order?.data?.landing?.siteUrl}`}>
                  {order?.data?.landing?.siteUrl}
                </a>
              </div>
              <div className={styles.listItem}>Subscription payment #: {order?.data?.subscriptionCycleNumber}</div>
            </div>
          </div>
          <Tabs type='card' className={styles.tabsContainer}>
            <Tabs.TabPane tab='Products' key='1'>
              <ProductsTab
                products={order?.data?.orderItems}
                payments={order?.data?.payments}
                subTotal={order?.data?.subTotal}
                total={order?.data?.total}
                shipping={order?.data?.shipping}
                processing={order?.data?.processing}
                totalCost={order?.data?.totalCost}
                promocodeDiscount={order?.data?.promocodeDiscount}
                orderRefresh={() => order.refetch()}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Payments' key='2'>
              <PaymentsTab payments={order?.data?.payments} />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Shipping Address' key='3'>
              <ShippingAddressTab
                address={order?.data?.shippingAddress}
                addressValidation={order?.data?.addressValidation}
                orderId={orderId}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Payment Address' key='4'>
              <PaymentAddressTab address={order?.data?.billingAddress} />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Events' key='5'>
              <EventsTab events={order?.data?.orderEvents} />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Tasks' key='6'>
              <TasksTab orderId={+order?.data?.id} />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Notes' key='7'>
              <NotesTab orderId={+order?.data?.id} />
            </Tabs.TabPane>
          </Tabs>
        </>
      )}
    </Layout>
  )
}

export default OrderDetailsForCustomerDetails
