// TODO - remove becouse of legacy

import { ColumnsType } from 'antd/lib/table'
import { AdSpendRow } from '../../types'

export default (handleSave: (record: AdSpendRow) => void): ColumnsType<AdSpendRow> => [
  {
    title: '#',
    dataIndex: ['campaign', 'id'],
    width: '10%'
  },
  {
    title: 'Campaign',
    dataIndex: ['campaign', 'name'],
    sorter: (a, b) => (a.campaign.name.toLowerCase() < b.campaign.name.toLowerCase() ? -1 : 1),
    defaultSortOrder: 'ascend',
    width: '30%'
  },
  {
    title: 'Ad spend',
    dataIndex: 'sumOfExpenses',
    width: '30%',
    render: (_value, record) => record.sumOfExpenses.toFixed(2),
    onCell: (record) => ({
      record,
      editable: true,
      title: 'Ad spend',
      dataIndex: 'sumOfExpenses',
      isInputNumber: true,
      handleSave
    })
  },
  {
    title: 'Clicks',
    dataIndex: 'numberOfClicks',
    width: '30%',
    onCell: (record) => ({
      record,
      editable: true,
      title: 'Clicks',
      dataIndex: 'numberOfClicks',
      isInputNumber: true,
      handleSave
    })
  }
]
