import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

type Props = {
  productId: number
  campaignId: number
  onClose: (productId?: number) => void
  visible: boolean
}

const mapStateToProps = (state: ReduxState) => {
  return {
    isLoading: selectors.isLoadingSelector(state, 'isDuplicateProduct')
  }
}

const mapDispatchToProps = {
  duplicateProduct: actions.duplicateProduct
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & Props
