// TODO - remove becouse of legacy

import { isSettingValueValid } from 'utils/isSettingValueDefined'
import { Landing, StepState, WizardStepStatus } from '../../types'

const getPagesStep: (landing: Landing, currentStep: number, step?: number) => StepState = (landing, currentStep, step = 3) => {
  let isValid = false
  let status = 'wait' as WizardStepStatus

  if (landing?.pages.length > 0) {
    isValid = true
    landing?.pages.forEach((page) => {
      if (!page.canBeSkippedOnBuild) {
        page.settings.forEach((setting) => {
          if (setting.required) {
            isValid = isValid && isSettingValueValid(setting)
          }
        })
      }
    })
  }

  if (isValid) {
    status = 'finish' as WizardStepStatus
  }

  if (currentStep === step) {
    status = 'process' as WizardStepStatus
  }

  if (currentStep !== step && !isValid) {
    status = 'error' as WizardStepStatus
  }

  return {
    isValid,
    status
  }
}

export { getPagesStep }
