import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { ZipwhipSetting } from '../types'

type ZipwhipSettingSelector = (state: ReduxState, zipwhipSettingId?: number) => ZipwhipSetting | undefined

const getZipwhipSetting: ZipwhipSettingSelector = (state, zipwhipSettingId) => {
  let result
  if (zipwhipSettingId && Object.prototype.hasOwnProperty.call(state.zipwhipSettings.zipwhipSettings, zipwhipSettingId)) {
    result = state.zipwhipSettings.zipwhipSettings[zipwhipSettingId] as ZipwhipSetting
  }
  return result
}

export const zipwhipSettingSelector: ZipwhipSettingSelector = createSelector(getZipwhipSetting, (zipwhipSetting) => zipwhipSetting)
