import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'removeCustomerTag', boolean>

const REMOVE_CUSTOMER_TAG = `
  mutation removeCustomerTag ($customerId: Int!, $tag: CustomerTagEnum!) {
    removeCustomerTag(customerId: $customerId, tag: $tag)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveCustomerTagArgs = {
  customerId: number
  tag: string
}

type RemoveCustomerTag = (args: RemoveCustomerTagArgs) => Promise<number>

const removeCustomerTag: RemoveCustomerTag = async ({ customerId, tag }) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_CUSTOMER_TAG,
      variables: {
        customerId,
        tag
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeCustomerTag } = data
    if (!removeCustomerTag) {
      throw new Error("Can't remove customer tag!")
    }

    return customerId
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveCustomerTagEvents = {
  onSettled: (customerId?: number) => void
}

const removeCustomerTagEvents: RemoveCustomerTagEvents = {
  onSettled: (customerId) => {
    if (customerId) {
      queryClient.invalidateQueries(['customer', customerId])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useRemoveCustomerTag = () => useMutation(removeCustomerTag, removeCustomerTagEvents)
