const getNumberedSortingParams = (field: string, inverse = false) => ({
  sortBy: field[0] === '-' ? field.substr(1) : field,
  sortDirection: (field[0] === '-' ? -1 : 1) * (inverse ? -1 : 1)
})

const sortByKey = (field: string) => {
  const { sortBy, sortDirection } = getNumberedSortingParams(field)
  return (a: any, b: any) => {
    let result = 0
    try {
      // try to sort as a numbers
      result = parseInt(a[sortBy]) < parseInt(b[sortBy]) ? -1 : parseInt(a[sortBy]) > parseInt(b[sortBy]) ? 1 : 0
    } catch (excepiton) {
      // sort as a strings
      result = a[sortBy] < b[sortBy] ? -1 : a[sortBy] > b[sortBy] ? 1 : 0
    }
    return result * sortDirection
  }
}

export const sortByMultipleKeys = (...props: string[]) => (obj1: any, obj2: any) => {
  let i = 0
  let result = 0
  while (result === 0 && i < props.length) {
    result = sortByKey(props[i])(obj1, obj2)
    i++
  }
  return result
}
