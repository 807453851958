import axios from 'utils/shoppingCartAxios'
import { useMutation } from 'react-query'
import { PromocodeDto } from '@merchx-v2/shared-types'
import { queryClient } from 'queryClient'
import { extractResponseError } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type DeactivatePromocode = (promocode: string) => Promise<PromocodeDto>

const deactivatePromocode: DeactivatePromocode = async (promocode) => {
  try {
    const response = await axios.post(`/promocodes/${promocode}/deactivate`)

    const data: PromocodeDto = response.data

    if (!data) {
      throw new Error("Can't deactivate promocode!")
    }

    return data
  } catch (err) {
    extractResponseError(err)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type DeactivatePromocodeEvents = {
  onSettled: (data?: PromocodeDto) => void
}

const deactivatePromocodeEvents: DeactivatePromocodeEvents = {
  onSettled: (promocode) => {
    if (promocode?.code) {
      queryClient.invalidateQueries(['promocodes'])
      queryClient.setQueryData(['promocode', promocode.code], promocode)
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useDeactivatePromocode = (promocode: string) => useMutation(() => deactivatePromocode(promocode), deactivatePromocodeEvents)
