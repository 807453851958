import * as t from '../actionTypes'

export type WebSocketErrorAction = BaseFSA<string>
type WebSocketError = (error: Error) => MrxThunk<WebSocketErrorAction>

const wsError: WebSocketError = error => async dispatch => {
  console.log('WebSocket error', error)
  dispatch({
    type: t.WS_ERROR,
    payload: error.message,
    meta: { done: true }
  })
}

export { wsError }
