import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { DomainDto, UpdateDomainDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'updateDomain', boolean>

const UPDATE_DOMAIN = `
  mutation updateDomain($domainId: Int!, $domainData: DomainInput!) {
    updateDomain(domainId: $domainId, domainData: $domainData)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateDomainArgs = {
  domainId: number
  domainData: UpdateDomainDto
}

type UpdateDomain = (args: UpdateDomainArgs) => Promise<UpdateDomainArgs>

const updateDomain: UpdateDomain = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_DOMAIN,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateDomain } = data
    if (!updateDomain) {
      throw new Error("Can't update domain!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateDomainContext = { prevDomain?: DomainDto }

type UpdateDomainEvents = {
  onMutate: (variables: UpdateDomainArgs) => Promise<UpdateDomainContext>
  onError: (error: string, variables: UpdateDomainArgs, context: UpdateDomainContext) => void
  onSettled: (data?: UpdateDomainArgs) => void
}

const updateDomainEvents: UpdateDomainEvents = {
  onMutate: async (variables: UpdateDomainArgs) => {
    await queryClient.cancelQueries(['domain', variables.domainId])

    // Snapshot the previous value
    const prevDomain = queryClient.getQueryData<DomainDto>(['domain', variables.domainId])

    // Optimistically update to the new values
    if (prevDomain) {
      queryClient.setQueryData<DomainDto>(['domain', variables.domainId], {
        ...prevDomain,
        id: variables.domainId,
        ...variables.domainData
      })
    }

    return { prevDomain }
  },
  onError: (_err, variables, context) => {
    if (context?.prevDomain) {
      // Restore previous version of country on any error
      queryClient.setQueryData<DomainDto>(['domain', variables.domainId], context.prevDomain)
    }
  },
  onSettled: (data) => {
    if (data?.domainId) {
      queryClient.invalidateQueries(['domain', data.domainId])
      queryClient.invalidateQueries(['domainsList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUpdateDomain = () => useMutation(updateDomain, updateDomainEvents)
