import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'
import { Props } from './UpdateProductTag'

const mapStateToProps = (state: ReduxState, ownProps: Props) => {
  return {
    isLoading: selectors.isLoadingSelector(state, 'isUpdateProductTag'),
    productTag: selectors.productTagSelector(state, ownProps.productTagId)
  }
}

const mapDispatchToProps = {
  updateProductTag: actions.updateProductTag
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector>
