// TODO - remove becouse of legacy

import { connect, ConnectedProps } from 'react-redux'
import * as taslQueueActions from 'features/tasksQueue/actions'
import { Build, Landing } from 'features/landings/types'

type OwnProps = {
  landingId: number
  landing: Landing
  builds: Build[]
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = {
  fetchTasksList: taslQueueActions.fetchTaskList
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
