import { useInfiniteQuery } from 'react-query'
import hash from 'object-hash'
import { ProductPriceOwner } from '@merchx-v2/shared-types'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

import { Product } from 'features/types'

const DEFAULT_PAGE_SIZE = 30

export type ProductsPage = {
  items: Product[]
  total: number
  currentPage: number
  pageSize: number
  filters?: ProductFilters
}

type ProductFilters = {
  workspaceId: number
  campaignId?: number
  searchText?: string
  status?: 'ALL' | 'ACTIVE' | 'DISABLED'
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'productsListPage', ProductsPage>

const FETCH_PRODUCTS_LIST = `
  query fetchProductsList ($page: Int, $size: Int, $filters: ProductsListPageFilters!, $productPriceOwnerType: ProductPriceOwner!, $productPriceOwnerId: Int!) {
    productsListPage(page: $page, size: $size, filters: $filters) {
      items {
        id
        campaignId
        campaign {
          id
          name
        }
        ownerId
        ownerType
        owner {
          id
          firstName
          lastName
          email
          createdFromLandingUrl
          createdAt
          updatedAt
        }
        
        storeId
        storeName

        name
        displayName
        productType
        prices(ownerType: $productPriceOwnerType, ownerId: $productPriceOwnerId) {
          id
          price
          discount
          attributes {
            id
            attribute
            value
          }
        }
        productType2
        isActive
      }
      total
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type PageParam = {
  page?: number
  size?: number
  filters: ProductFilters
  productPriceOwnerType?: ProductPriceOwner
  productPriceOwnerId?: number
}

type FetchProductsListArgs = {
  queryKey: string | string[]
  pageParam?: PageParam
}

type FetchProductsList = (args: FetchProductsListArgs) => Promise<ProductsPage>

const fetchProductsList: FetchProductsList = async ({ pageParam }) => {
  const { page = 1, size = DEFAULT_PAGE_SIZE, filters = {}, productPriceOwnerType, productPriceOwnerId } = pageParam || {}
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_PRODUCTS_LIST,
      variables: { page, size, filters, productPriceOwnerType, productPriceOwnerId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { productsListPage } = data
    if (!productsListPage) {
      throw new Error("Can't get products list page!")
    }

    return {
      items: productsListPage.items,
      total: productsListPage.total,
      currentPage: page,
      pageSize: size
    }
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

type GetNextPageParam = (args: ProductsPage) => PageParam | undefined

type ProductsListEvents = {
  keepPreviousData: boolean
  getPreviousPageParam: GetNextPageParam
  getNextPageParam: GetNextPageParam
}

const productsListEvents: ProductsListEvents = {
  keepPreviousData: true,
  getPreviousPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, filters }) => {
    if (currentPage > 1) {
      return {
        page: currentPage - 1,
        size: pageSize,
        filters
      }
    }

    return undefined
  },
  getNextPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, total = 0, filters }) => {
    if (pageSize * currentPage < total) {
      return {
        page: currentPage + 1,
        size: pageSize,
        filters
      }
    }
    return undefined
  }
}

type Args = {
  filters?: ProductFilters
  productPriceOwnerType?: ProductPriceOwner
  productPriceOwnerId?: number
}

export const useProductsList = (args: Args) => {
  const cacheKey = ['productsList', hash(args)]

  return useInfiniteQuery(
    cacheKey,
    (prev) =>
      fetchProductsList({
        queryKey: cacheKey,
        pageParam: { ...prev.pageParam, ...args }
      }),
    productsListEvents
  )
}
