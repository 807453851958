import React, { ReactNode, useEffect, useState } from 'react'
import { Typography, Button, Tree, Upload, Form, Input } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import hash from 'object-hash'
import { AssetOwner, SettingFilter, SettingOwner, SettingType } from '@merchx-v2/shared-types'
import { UploadFile } from 'antd/lib/upload/interface'
import notification from 'mrx-notification'
import { TreeInput, TreeItemTitle } from 'components'
import { useUploadAsset } from 'features/assets/hooks'
import { useUpdateSettingValue } from '../../hooks'
import { Setting } from '../../types'
import styles from './FormItemTestimonialsSetting.module.scss'

type TreeDataRow = {
  title: ReactNode
  key: string
  children?: TreeDataRow[]
}

type Testimonial = {
  title: string
  description?: string
  imageId: number
  image?: any
  fileName?: string
  file?: File
}

const mapCanByMoved = {
  Testimonial: ['Testimonial']
}

type PropsType = {
  ownerType: SettingOwner
  ownerId: number
  setting: Setting
  selfUpdate?: boolean
  onValueChanged?: (setting: Setting, newValue: any) => void
}

const FormItemTestimonialsSetting = (props: PropsType) => {
  const {
    ownerType,
    ownerId,
    setting,
    selfUpdate,

    onValueChanged
  } = props

  const [testimonials, setTestimonials] = useState<Testimonial[]>((setting.jsonValue as Testimonial[]) || [])
  // const [treeData, setTreeData] = useState<TreeDataRow[]>([])

  const updateSettingValue = useUpdateSettingValue()
  const uploadAsset = useUploadAsset()

  useEffect(() => {
    onValueChanged && onValueChanged(setting, JSON.stringify(testimonials))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testimonials])

  useEffect(() => {
    if (setting?.jsonValue) {
      setTestimonials(setting.jsonValue as Testimonial[])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(setting?.jsonValue || {})])

  if (!setting) {
    return
  }

  const onDrop = (info) => {
    const dragKey = info.dragNode.props.eventKey
    const dragPos = info.dragNode.props.pos.split('-')
    const dropPos = info.node.props.pos.split('-')

    const dragSource = info.dragNode.props.eventKey.split('-')
    const dropSource = info.node.props.eventKey.split('-')

    if (!mapCanByMoved[dragSource[0]]?.includes(dropSource[0])) {
      return
    }

    const newData = testimonials.map((item) => ({
      ...item
    }))

    if (dragKey.includes('Testimonial-')) {
      newData.splice(dropPos[1], 0, newData.splice(dragPos[1], 1)[0])
      setTestimonials(newData)
    }
  }

  const handleSave = async (testimonials: Testimonial[]) => {
    updateSettingValue.mutate(
      {
        ownerType,
        ownerId,
        settingId: setting.id,
        settingData: {
          name: setting.name,
          alias: setting.alias,
          type: setting.type as SettingType,
          value: JSON.stringify(testimonials),
          required: setting.required,
          filters: setting.filters as SettingFilter[]
        }
      },
      {
        onSuccess: () => {
          notification.success({
            message: 'Successfully',
            description: `${setting.name} setting was updated!`
          })
        },
        onError: (error) => {
          notification.error({
            message: `${setting.name} setting error!`,
            description: error.toString()
          })
        }
      }
    )
  }

  const handleTestimonialAdded = (testimonialName) => {
    setTestimonials((prev) => [...prev, { title: testimonialName, imageId: 0, description: '' }])
  }

  const handleRemoveTestimonial = (index) => {
    const newData = [...testimonials]
    if (index > -1) {
      newData.splice(index, 1)
    }
    setTestimonials(newData)
  }

  const handleAddTestimonialImage = async (file: UploadFile<any>, name: string, index: number) => {
    file.url = URL.createObjectURL(file)
    const fixedName = name.trim().replace(/\s+/g, '_') as string
    const updatedSelectArray = testimonials.map((item) => ({ ...item }))
    updatedSelectArray[index].image = file
    updatedSelectArray[index].fileName = fixedName

    await uploadAsset.mutateAsync(
      {
        ownerType: setting.ownerType as AssetOwner,
        ownerId: setting.ownerId,
        assetData: { name: fixedName, type: file.type, filename: file.name, role: 'DEFAULT' },
        file: file.originFileObj
      },
      {
        onSuccess: (data) => {
          notification.success({
            message: 'Successfully',
            description: 'Image was uploaded successfully!'
          })
          updatedSelectArray[index].imageId = data.id
        },
        onError: (error) => {
          notification.error({
            message: 'Error',
            description: error.toString()
          })
        }
      }
    )

    setTestimonials(updatedSelectArray)
    await handleSave(updatedSelectArray)
  }

  const handleDescriptionValueChanged = (description, index) => {
    const updatedSelectArray = testimonials.map((item) => ({ ...item }))
    updatedSelectArray[index].description = description
    setTestimonials(updatedSelectArray)
  }

  const convertTestimonialsToTreeData = (testimonials: Testimonial[]) => {
    const result = [] as TreeDataRow[]

    for (let tIdx = 0; tIdx < testimonials.length; tIdx++) {
      const testimonial = testimonials[tIdx]
      const mainMenuItem = {
        title: <TreeItemTitle title={testimonial.title} onRemove={() => handleRemoveTestimonial(tIdx)} level='testimonial' />,
        key: `Testimonial-${tIdx}`,
        children: []
      } as TreeDataRow

      const image = {
        title: (
          <>
            {testimonial.image && <img src={testimonial.image.signedUrl || testimonial.image.url} alt='' className={styles.image} />}
            <Upload
              name={`${tIdx}testimonialImage`}
              accept='image/*'
              customRequest={async (info: any) => {
                const fileName = info.file.name.replace(/\.[^.]+$/gm, '')
                await handleAddTestimonialImage(info.file, fileName, tIdx)
              }}
              showUploadList={false}
            >
              <Button loading={false}>
                <UploadOutlined /> Click to Upload
              </Button>
            </Upload>
          </>
        ),
        key: `Image-${tIdx}`
      }

      const description = {
        title: (
          <Form.Item
            style={{ width: 230 }}
            label='Description'
            name={`${setting.name}${tIdx}Description`}
            initialValue={testimonial.description}
            rules={[
              {
                required: false,
                whitespace: true,
                message: 'Please input description.'
              }
            ]}
          >
            <Input style={{ width: 300 }} onChange={(e) => handleDescriptionValueChanged(e.target.value, tIdx)} />
          </Form.Item>
        ),
        key: `Description-${tIdx}`
      }

      mainMenuItem.children.push(image)
      mainMenuItem.children.push(description)
      result.push(mainMenuItem)
    }
    return result
  }

  const getTreeWithAButtons = () => {
    const result = convertTestimonialsToTreeData(testimonials)

    result.push({
      title: <TreeInput title='+ Add testimonial' onFinish={handleTestimonialAdded} />,
      key: 'AddTestimonialButton'
    })
    return result
  }

  return (
    <div>
      <Typography style={{ marginBottom: '10px', fontWeight: 'bold', fontSize: '18px' }}>{setting.name} </Typography>
      <Tree className='draggable-tree' draggable blockNode onDrop={onDrop} selectable={false} treeData={getTreeWithAButtons()} />
      {selfUpdate && (
        <Button type='primary' onClick={() => handleSave(testimonials)}>
          Save
        </Button>
      )}
    </div>
  )
}

export default FormItemTestimonialsSetting
