import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

type Payload = {
  productSizeId: number
  productSizeData: ProductSizeInput
}

export type UpdateProductSizeAction = FSA<undefined, Payload, string>

type UpdateProductSize = (args: Payload) => MrxThunk<UpdateProductSizeAction, Promise<boolean>>

type QueryResponse = GraphQLResponse<'updateProductSize', boolean>

const UPDATE_PRODUCT_SIZE = `
  mutation updateProductSize ($productSizeId: Int!, $productSizeData: ProductSizeDataInput!) {
    updateProductSize(productSizeId: $productSizeId, productSizeData: $productSizeData)
  }
`

type ProductSizeInput = {
  name: string
  displayName: string
}

const updateProductSize: UpdateProductSize = (args) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.UPDATE_PRODUCT_SIZE,
    meta: { done: false }
  })

  try {
    const { productSizeId, productSizeData } = args
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_PRODUCT_SIZE,
      variables: { productSizeId, productSizeData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateProductSize } = data
    if (!updateProductSize) {
      throw new Error("Can't update product size!")
    }

    dispatch({
      type: t.UPDATE_PRODUCT_SIZE,
      payload: args,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.UPDATE_PRODUCT_SIZE,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { updateProductSize }
