import { connect, ConnectedProps } from 'react-redux'
import { PaymentPlugin } from '@merchx-v2/shared-types'
import { ReduxState } from 'store/createRootReducer'
import * as authorizeSettingsFeature from 'features/authorizeSettings'
import * as stripeSettingsFeature from 'features/stripeSettings'
import * as payPalSettingsFeature from 'features/payPalSettings'

type OwnProps = {
  plugin: PaymentPlugin
  initialOption: SelectOption
  disabled?: boolean
  onSelect: (selectOption: SelectOption) => void
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  let isLoading = false
  let options = []

  switch (ownProps.plugin) {
    case 'AUTHORIZE': {
      isLoading = authorizeSettingsFeature.selectors.isLoadingSelector(state, 'isAuthorizeSettingsForOptionsLoading')
      options = authorizeSettingsFeature.selectors.authorizeSettingsSelectOptionsSelector(state)
      break
    }
    case 'STRIPE': {
      isLoading = stripeSettingsFeature.selectors.isLoadingSelector(state, 'isStripeSettingsForOptionsLoading')
      options = stripeSettingsFeature.selectors.stripeSettingsSelectOptionsSelector(state)
      break
    }

    case 'PAYPAL': {
      isLoading = payPalSettingsFeature.selectors.isLoadingSelector(state, 'isPayPalSettingsForOptionsLoading')
      options = payPalSettingsFeature.selectors.payPalSettingsSelectOptionsSelector(state)
      break
    }
  }

  return {
    isLoading,
    options
  }
}

const mapDispatchToProps = {
  fetchAuthorizeSettingsOptions: authorizeSettingsFeature.actions.fetchAuthorizeSettingsForOptions,
  fetchStripeSettingsOptions: stripeSettingsFeature.actions.fetchStripeSettingsForOptions,
  fetchPayPalSettingsOptions: payPalSettingsFeature.actions.fetchPayPalSettingsForOptions
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
