import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Table, Layout, Tag, Progress, Popconfirm, Modal, Button } from 'antd'
import { CloseOutlined, RedoOutlined } from '@ant-design/icons'
import notification from 'mrx-notification'
import useInterval from 'utils/useInterval'
import formatDuration from 'utils/formatDuration'
import styles from './TasksQueueForRedeploymentTab.module.scss'
import { connector, PropsFromRedux } from './container'
import { UpdateLandingsTask } from '../../types'
import { ColumnsType } from 'antd/es/table'

const tagColors = {
  NEW: 'cyan',
  PROCESSED: 'blue',
  IN_PROGRESS: 'gold',
  CANCELED: 'volcano',
  COMPLETED: 'green'
}

const canRestart = ['IN_PROGRESS', 'PROCESSED', 'CANCELED', 'COMPLETED']
const canCancel = ['NEW', 'PROCESSED', 'IN_PROGRESS']

const TasksQueueForRedeploymentTab = (props: PropsFromRedux) => {
  const {
    error,
    tasksList,
    currentPage,
    total,
    campaignId,

    cancelTask,
    changeCurrentPage,
    restartTask,
    fetchTasksList
  } = props

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState<UpdateLandingsTask>()
  const [now, setNow] = useState(moment())

  useInterval(() => {
    setNow(moment())
  }, 1000)

  useInterval(() => {
    fetchTasksList(campaignId)
  }, 30000)

  useEffect(() => {
    fetchTasksList(campaignId)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Tasks queue list page error!',
        description: error
      })
    }
  }, [error])

  const doConfirmCancel = async (taskId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    const isCanceled = await cancelTask(taskId)
    if (isCanceled) {
      notification.success({
        message: 'Successfully',
        description: 'Task was canceled successfully!'
      })
    }
  }

  const doConfirmRestart = async (taskId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    const isRestarted = await restartTask(taskId)
    if (isRestarted) {
      notification.success({
        message: 'Successfully',
        description: 'Task was restarted successfully!'
      })
    }
  }

  const handleOnRow = (record: UpdateLandingsTask) => {
    return {
      onClick: () => {
        if (record.message) {
          setIsModalVisible(true)
          setSelectedRecord(record)
        }
      }
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const columns: ColumnsType<UpdateLandingsTask> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Status',
      render: (record: UpdateLandingsTask) => {
        return <Tag color={tagColors[record.status]}>{record.status}</Tag>
      },
      key: 'status'
    },
    {
      title: 'Task Type',
      render: (_value, record) => {
        return record.taskType === 'UPDATE_CAMPAIGN_LANDINGS_FROM_TEMPLATE' ? 'Update landings' : 'Redeploy landings'
      },
      dataIndex: 'taskType'
    },
    {
      title: 'Progress',
      render: (_value, record) => {
        return <Progress percent={record.progress} size='small' status='active' />
      },
      key: 'progress'
    },
    {
      title: 'Retries',
      render: (_value, record) => {
        let color = 'green'
        if (record.retryNumber === 2) {
          color = 'orange'
        } else if (record.retryNumber === 3) {
          color = 'volcano'
        }
        return <Tag color={color}>{record.retryNumber}</Tag>
      }
    },
    {
      title: 'Elapsed Time',
      render: (_value, record) => {
        if (record.startedAt !== null) {
          const start = moment(record.startedAt)
          const end = record.endedAt !== null ? moment(record.endedAt) : now
          const showMilliseconds = record.endedAt !== null
          const duration = moment.duration(end.diff(start))
          return <div>{duration ? formatDuration(duration, showMilliseconds) : ''}</div>
        }
      },
      key: 'elapsedTime'
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (_value, record) => (
        <div className={styles.actionIcons}>
          {canRestart.includes(record.status) && (
            <span>
              <Popconfirm
                title='Are you sure restart this task?'
                onConfirm={(e) => doConfirmRestart(record.id, e)}
                onCancel={(e) => e?.stopPropagation()}
                okText='Yes'
                cancelText='No'
              >
                <RedoOutlined
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                />
              </Popconfirm>
            </span>
          )}
          {canCancel.includes(record.status) && (
            <span>
              <Popconfirm
                title='Are you sure cancel this task?'
                onConfirm={(e) => doConfirmCancel(record.id, e)}
                onCancel={(e) => e?.stopPropagation()}
                okText='Yes'
                cancelText='No'
              >
                <CloseOutlined
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                />
              </Popconfirm>
            </span>
          )}
        </div>
      )
    }
  ]

  const expandedRowRender = (record: UpdateLandingsTask) => {
    const columns = [{ title: '', dataIndex: 'name' }]

    const data = [{ name: 'Templates' }]

    if (record.failedLandings.length) {
      data.push({ name: 'Failed landings' })
    }

    const subExtendedRowRender = (value) => {
      if (value.name === data[0].name) {
        const columns = [
          { title: 'ID', dataIndex: 'id', width: '60px' },
          { title: 'Name', dataIndex: 'name' }
        ]
        return <Table columns={columns} showHeader={false} dataSource={record.templates} rowKey={(r) => r.id} pagination={false} />
      } else {
        const columns = [
          {
            title: 'ID',
            dataIndex: 'id',
            render: (_value, record) => (
              <Link to={`/campaigns/${campaignId}/landingWizard?landingId=${record.id}&step=5`}>{record.id}</Link>
            ),
            width: '60px'
          },
          { title: 'Name', dataIndex: 'name' }
        ]
        return <Table columns={columns} showHeader={false} dataSource={record.failedLandings} rowKey={(r) => r.id} pagination={false} />
      }
    }

    return (
      <Table
        columns={columns}
        showHeader={false}
        dataSource={data}
        rowKey={(r) => r.name}
        expandable={{ expandedRowRender: subExtendedRowRender }}
        pagination={false}
      />
    )
  }

  return (
    <div className={styles.contentWrapper}>
      <Layout.Content className={styles.contentComponent}>
        <Table
          columns={columns}
          dataSource={tasksList}
          className={styles.table}
          bordered
          rowKey={(record) => record.id}
          onRow={handleOnRow}
          expandable={{ expandedRowRender }}
          scroll={{ y: 'calc(100vh - 340px)' }}
          pagination={{
            onChange: changeCurrentPage,
            current: currentPage,
            total
          }}
        />
      </Layout.Content>

      <Modal
        title='Task Message'
        visible={isModalVisible}
        footer={[
          <Button key='submit' type='primary' onClick={handleCancel}>
            OK
          </Button>
        ]}
        onCancel={handleCancel}
      >
        {selectedRecord?.message ? selectedRecord.message : 'No message!'}
      </Modal>
    </div>
  )
}

export default connector(TasksQueueForRedeploymentTab)
