import React, { useEffect, useState } from 'react'
import { Input, Typography } from 'antd'
import styles from './ManageSeo.module.scss'

type InitValuesType = {
  hasCustomSeo: boolean
  urlPrefix?: string
  title: string
  url: string
  description: string
  keywords: string
}

type Props = {
  initValues: InitValuesType
  onCustomSeoChanged?: (value: boolean) => void
  onTitleChanged?: (value: string) => void
  onUrlChanged?: (value: string) => void
  onDescriptionChanged?: (value: string) => void
  onKeywordsChanged?: (value: string) => void
  withoutBorder?: boolean
  showTitle?: boolean
  showDescription?: boolean
  showKeywords?: boolean
}

const ManageSeo = (props: Props) => {
  const {
    initValues,
    onCustomSeoChanged,
    onDescriptionChanged,
    onKeywordsChanged,
    onTitleChanged,
    onUrlChanged,
    withoutBorder = false,
    showTitle = true,
    showDescription = true,
    showKeywords = true
  } = props
  const urlPrefix = `${props.initValues.urlPrefix}/`
  const [hasCustomSeo, setHasCustomSeo] = useState<boolean>(initValues.hasCustomSeo)
  const [pageTitle, setPageTitle] = useState<string>(initValues.title)
  const [pageUrl, setPageUrl] = useState<string>(initValues.url)
  const [pageDescription, setPageDescription] = useState<string>(initValues.description)
  const [keywords, setKeywords] = useState<string>(initValues.keywords)

  useEffect(() => {
    setHasCustomSeo(initValues.hasCustomSeo)
    setPageTitle(initValues.title)
    setPageUrl(initValues.url)
    setPageDescription(initValues.description)
    setKeywords(initValues.keywords)
  }, [initValues])

  const handleCustomSeoChanged = (value: boolean) => {
    setHasCustomSeo(value)
    onCustomSeoChanged && onCustomSeoChanged(value)
  }

  const handleTitleChanged = (value: string) => {
    setPageTitle(value)
    onTitleChanged && onTitleChanged(value)
  }

  const handleUrlChanged = (value: string) => {
    setPageUrl(value)
    onUrlChanged && onUrlChanged(value)
  }

  const handleDescriptionChanged = (value: string) => {
    setPageDescription(value)
    onDescriptionChanged && onDescriptionChanged(value)
  }

  const handleKeywordsChanged = (value: string) => {
    setKeywords(value)
    onKeywordsChanged && onKeywordsChanged(value)
  }

  return (
    <div className={withoutBorder ? styles.seoBlockWithoutBorder : styles.seoBlock}>
      <div className={styles.seoBlockLeftPart}>
        <Typography style={{ color: 'black', fontWeight: 'bold', paddingBottom: '8px' }}>SEO</Typography>
        {hasCustomSeo && (
          <>
            <div className={styles.labeledItem} hidden={!showTitle}>
              <Typography style={{ color: 'black', paddingBottom: '8px' }}>Page Title</Typography>
              <Input onChange={(e) => handleTitleChanged(e.target.value)} defaultValue={pageTitle} />
            </div>
            <div className={styles.labeledItem}>
              <Typography style={{ color: 'black', paddingBottom: '8px' }}>URL and handle</Typography>
              <Input
                className={styles.seoUrlInput}
                prefix={urlPrefix}
                onChange={(e) => handleUrlChanged(e.target.value)}
                defaultValue={pageUrl}
              />
            </div>
            <div className={styles.labeledItem} hidden={!showDescription}>
              <Typography style={{ color: 'black', paddingBottom: '8px' }}>Description</Typography>
              <Input.TextArea onChange={(e) => handleDescriptionChanged(e.target.value)} defaultValue={pageDescription} />
            </div>
            <div className={styles.labeledItem} hidden={!showKeywords}>
              <Typography style={{ color: 'black', paddingBottom: '8px' }}>Keywords</Typography>
              <Input.TextArea value={keywords} onChange={(e) => handleKeywordsChanged(e.target.value)} defaultValue={keywords} />
            </div>
          </>
        )}
      </div>
      <div className={styles.seoBlockRightPart}>
        <Typography style={{ color: 'black', fontWeight: 'bold', paddingBottom: '8px', textDecoration: 'underline' }}>
          <div className={styles.seoCustomizeButton} onClick={() => handleCustomSeoChanged(!hasCustomSeo)}>
            {hasCustomSeo ? 'Use default' : 'Customize'}
          </div>
        </Typography>
        {hasCustomSeo && (
          <>
            <Typography style={{ color: 'black', fontWeight: 'bold', paddingBottom: '8px' }}>Search Engine Preview</Typography>
            <div className={styles.examplePageTitle} onClick={() => window.open(`${urlPrefix}${pageUrl}`)}>
              {pageTitle}
            </div>
            <div className={styles.exampleUrl} onClick={() => window.open(`${urlPrefix}${pageUrl}`)}>
              {urlPrefix}
              {pageUrl}
            </div>
            <div className={styles.exampleDescription}>{pageDescription}</div>
          </>
        )}
      </div>
    </div>
  )
}

export default ManageSeo
