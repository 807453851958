import React, { useEffect, useState } from 'react'
import { Table, Popconfirm, Card } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import notification from 'mrx-notification'

import styles from './RegionsList.module.scss'
import { connector, PropsFromRedux } from './container'
import CreateRegion from '../CreateRegion'
import UpdateRegion from '../UpdateRegion'
import { Region } from '../../types'
import { Country } from 'features/countries/types'

type RegionsListProps = PropsFromRedux & {
  country: Country
}

const RegionsList = (props: RegionsListProps) => {
  const {
    regionsList,
    isLoading,
    isNeedToFetchListSelector,
    error,
    currentPage,
    total,
    country,

    changeCurrentPage,
    fetchRegionsList,
    removeRegion
  } = props

  const [selectedRegionId, setSelectedRegionId] = useState<number>()
  const [isUpdateDrawerVisible, setIsUpdateDrawerVisible] = useState(false)

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Regions list page error!',
        description: error
      })
    }
  }, [error])

  useEffect(() => {
    isNeedToFetchListSelector && fetchRegionsList(country.id, currentPage)
  }, [fetchRegionsList, isNeedToFetchListSelector, country, currentPage])

  const doConfirm = async (regionId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    const isRemoved = await removeRegion(country.id, regionId)
    if (isRemoved) {
      notification.success({
        message: 'Successfully',
        description: 'Region was deleted successfully!'
      })
    }
  }

  const columns: ColumnsType<Region> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'ISO 2',
      dataIndex: 'iso2',
      key: 'iso2'
    },
    {
      title: 'Action',
      key: 'x',
      render: (_value, record) => (
        <span>
          <Popconfirm
            title='Are you sure delete this region?'
            onConfirm={(e) => doConfirm(record.id, e)}
            onCancel={(e) => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <a
              href='/#'
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              Remove
            </a>
          </Popconfirm>
        </span>
      )
    }
  ]

  const handleOnRow = (record: Region) => {
    return {
      onClick: () => {
        setSelectedRegionId(record.id)
        setIsUpdateDrawerVisible(true)
      }
    }
  }

  const handleOnCurrencyClose = () => {
    setIsUpdateDrawerVisible(false)
  }

  return (
    <div className={styles.tableContainer}>
      <Card title={<span className={styles.cardTitle}>Regions</span>} extra={<CreateRegion country={country} />}>
        {selectedRegionId && (
          <UpdateRegion
            countryId={country.id}
            regionId={selectedRegionId}
            onClose={handleOnCurrencyClose}
            visible={isUpdateDrawerVisible}
          />
        )}
        <Table
          columns={columns}
          dataSource={regionsList}
          bordered
          rowKey={(record) => record.id}
          onRow={handleOnRow}
          loading={isLoading}
          pagination={{
            current: currentPage,
            total,
            pageSize: 4,
            onChange: (page: number) => changeCurrentPage(page, country.id)
          }}
        />
      </Card>
    </div>
  )
}

export default connector(RegionsList)
