import React, { useEffect } from 'react'
import { Button, Drawer, Input, Form } from 'antd'
import notification from 'mrx-notification'
import { SiteType } from '@merchx-v2/shared-types'
import { useUpdateDependency } from '../../hooks'
import { Dependency } from '../../types'
import styles from './UpdateDependency.module.scss'

export type PropsType = {
  ownerType: SiteType
  ownerId: number
  dependencyId?: number
  dependency: Dependency
  visible: boolean

  onClose: () => void
}

const UpdateDependency = (props: PropsType) => {
  const { dependency, dependencyId, visible, ownerType, ownerId, onClose } = props

  const [form] = Form.useForm()

  const updateDependency = useUpdateDependency()

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  useEffect(() => {
    visible && dependency && form.setFieldsValue(dependency)
  }, [dependency, form, visible])

  if (!dependency) {
    return null
  }

  // TODO - fix any type
  const onFinishHandler = async (values: any) => {
    dependencyId &&
      updateDependency.mutate(
        {
          ownerType,
          ownerId,
          dependencyId,
          dependencyData: {
            name: values.name,
            version: values.version
          }
        },
        {
          onSuccess: () => {
            notification.success({
              message: 'Successfully',
              description: 'Template Dependency was updated successfully!'
            })
            handleClose()
          },
          onError: (error) => {
            notification.error({
              message: 'Error!',
              description: error.toString()
            })
          }
        }
      )
  }

  return (
    <Drawer title='Update Template Dependency' width='400' onClose={handleClose} visible={visible} forceRender>
      <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
        <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input dependency name!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name='version' label='Version' rules={[{ required: true, message: 'Please input dependency version!' }]}>
          <Input />
        </Form.Item>
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right'
          }}
        >
          <Button onClick={handleClose} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit' loading={updateDependency.isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default UpdateDependency
