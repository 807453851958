import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type isLoadingKeyType =
  | 'isCreateCategory'
  | 'isFetchCategory'
  | 'isListLoading'
  | 'isRemoveCategory'
  | 'isCategoriesForOptions'
  | 'isSetProductCategories'
  | 'isUpdateCategory'

type IsLoadingSelector = (state: ReduxState, isLoadingKey: isLoadingKeyType) => boolean

const getIsLoadingSelectorByKey = (state: ReduxState, isLoadingKey: isLoadingKeyType) => state.categories.UIState[isLoadingKey] || false

export const isLoadingSelector: IsLoadingSelector = createSelector(getIsLoadingSelectorByKey, isLoading => isLoading)
