import { useQuery } from 'react-query'
import { SettingFilter, StoreOwnerType } from '@merchx-v2/shared-types'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'productsForOptions', SelectOption[]>

const FETCH_PRODUCTS_OPTIONS = `
  query fetchProductsForOptions($filters: ProductsForOptionsFilters!) {
    productsForOptions(filters: $filters) {
      id
      name
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchProductsOptionsArgs = {
  campaignId: number
  settingId?: number
  searchText?: string
  filters?: SettingFilter[]
  ownerType?: StoreOwnerType
  ownerId?: number
}

type FetchProductsOptions = (args: FetchProductsOptionsArgs) => Promise<SelectOption[]>

const fetchProductsOptions: FetchProductsOptions = async ({ filters = [], campaignId, searchText, ownerType, ownerId }) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_PRODUCTS_OPTIONS,
      variables: { filters: { campaignId, filters, searchText, ownerType, ownerId } }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { productsForOptions } = data
    if (!productsForOptions) {
      throw new Error("Can't get products options!")
    }

    return productsForOptions
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useProductsOptions = (args: FetchProductsOptionsArgs) => useQuery('productsOptions', () => fetchProductsOptions(args))
