import React, { useEffect } from 'react'
import useReactRouter from 'use-react-router'
import { Table, Layout, PageHeader, Popconfirm } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import notification from 'mrx-notification'
import useHeightResize from 'utils/useHeightResize'
import { Breadcrumbs } from 'components'
import * as rootStyles from 'assets/layoutStyle'
import styles from './DTGSizesList.module.scss'
import { connector, PropsFromRedux } from './container'
import CreateDTGSize from '../CreateDTGSize'
import { DTGSize } from '../../types'

type HeightResizeRule = [number, number, number]
const rules: HeightResizeRule[] = [
  [0, 800, 8],
  [800, Infinity, 10]
]

const DTGSizesList = (props: PropsFromRedux) => {
  const {
    DTGSizeList,
    isLoading,
    error,
    currentPage,
    total,

    changeCurrentPage,
    changePageSize,
    fetchDTGSizesList,
    removeDTGSize
  } = props

  const { history } = useReactRouter()

  const handleChangePageSize = (newSize: number) => {
    changePageSize(newSize)
    fetchDTGSizesList()
  }

  useHeightResize(handleChangePageSize, rules)

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'DTG Sizes list page error!',
        description: error
      })
    }
  }, [error])

  useEffect(() => {
    fetchDTGSizesList()
  }, [fetchDTGSizesList])

  const doConfirm = async (DTGSizeId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    const isRemoved = await removeDTGSize(DTGSizeId)
    if (isRemoved) {
      notification.success({
        message: 'Successfully',
        description: 'DTG Size was deleted successfully!'
      })
    }
  }

  const columns: ColumnsType<DTGSize> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'DTG Product',
      render: (_value, record) => record.DTGProduct.name,
      key: 'DTGProduct.name'
    },
    {
      title: 'Size ',
      render: (_value, record) => record.size,
      key: 'size'
    },
    {
      title: 'DTG Size Id',
      dataIndex: 'DTGSizeId',
      key: 'DTGSizeId'
    },
    {
      title: 'Action',
      key: 'x',
      render: (_value, record) => (
        <span>
          <Popconfirm
            title='Are you sure delete this setting?'
            onConfirm={(e) => doConfirm(record.id, e)}
            onCancel={(e) => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <a
              href='/#'
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              Remove
            </a>
          </Popconfirm>
        </span>
      )
    }
  ]

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader onBack={() => history.goBack()} title='DTG Sizes List' extra={[<CreateDTGSize key='1' />]} />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Catalogs', url: '/' },
            { title: 'DTG Sizes List', url: '/' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <Table
          columns={columns}
          dataSource={DTGSizeList}
          bordered
          rowKey={(record) => record.id}
          loading={isLoading}
          pagination={{
            current: currentPage,
            total,
            onChange: changeCurrentPage
          }}
        />
      </Layout.Content>
    </Layout>
  )
}

export default connector(DTGSizesList)
