import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { CanceledSubscriptions } from '../types'

type CanceledSubscriptionsSelector = (state: ReduxState) => CanceledSubscriptions[] | void

const getSubscriptionsSelector = (state: ReduxState) => state.reports.canceledSubscriptions

export const canceledSubscriptionsSelector: CanceledSubscriptionsSelector = createSelector(
  getSubscriptionsSelector,
  subscriptions => subscriptions
)
