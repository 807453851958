import React, { useContext, useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import moment from 'moment'
import { Moment } from 'moment-timezone'
import InfiniteScroll from 'react-infinite-scroll-component'
import { DatePicker, Table, Layout, PageHeader, Form, Button, Drawer, Select, Tag } from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table'
import { OrderHoldReason } from '@merchx-v2/shared-types'
import notification from 'mrx-notification'
import { GlobalContext } from 'appContexts'
import { Breadcrumbs } from 'components'
import * as rootStyles from 'assets/layoutStyle'
import { CampaignsSelect } from 'features/campaigns/components'
import { Order } from 'features/orders/types'
import OrderDetails from '../OrderDetails'
import styles from './HoldedOrdersList.module.scss'
import { useOrdersList } from 'features/orders/hooks'

const { RangePicker } = DatePicker
const dateFormat = 'MM/DD/YYYY'

const HoldedOrdersList = () => {
  const { workspaceId } = useContext(GlobalContext)

  const [sorting, setSorting] = useState<SortingType>()
  const { history, location } = useReactRouter()
  const [selectedCampaignId, setSelectedCampaignId] = useState<number>()
  const [holdReasons, setHoldReasons] = useState<OrderHoldReason[]>()
  const [datesRange, setDateRange] = useState<[Moment, Moment]>([null, null])
  const [orderStates, setOrderStates] = useState<string[]>([])

  const [isVisible, setVisible] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!sessionStorage.getItem('orderCampaignId')) {
      setSelectedCampaignId(+sessionStorage.getItem('orderCampaignId'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ordersList = useOrdersList({
    status: 'HOLD',
    holdReasons,
    sorting: sorting,
    startDate: datesRange[0],
    endDate: datesRange[1],
    orderStates
  })

  const orders: Order[] = []
  ordersList && ordersList.data && ordersList.data.pages.forEach((page) => page.items.forEach((order) => orders.push(order)))

  const page = ordersList.data?.pages.length ? ordersList.data?.pages[ordersList.data?.pages.length - 1].currentPage : 1
  const size = ordersList.data?.pages.length ? ordersList.data?.pages[ordersList.data?.pages.length - 1].pageSize : 30

  useEffect(() => {
    if (ordersList.error) {
      notification.error({
        message: 'Orders list page error!',
        description: ordersList.error instanceof Error ? ordersList.error.message : ordersList.error.toString()
      })
    }
  }, [ordersList.error])

  const sortingHandler = (_pagination, _filters, sorter) => {
    if (sorter?.order) {
      setSorting({ field: sorter.field || sorter.columnKey, order: sorter.order === 'ascend' ? 'ASC' : 'DESC' })
    } else {
      setSorting(undefined)
    }
  }

  const [form] = Form.useForm()

  const columns: ColumnsType<Order> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true
    },
    {
      title: 'Reasons',
      render: (_value, record) => record.holdReasons?.join(' '),
      key: 'holdReasons'
    },
    {
      title: 'Date',
      render: (_value, record) => moment(record.createdAt).format('MM/DD/YYYY HH:mm:ss'),
      key: 'createdAt',
      sorter: true
    },
    {
      title: 'Order State',
      key: 'orderState',
      render: (_value, record) => {
        const isReachedOut = !!record.attributes.find((item) => item.attribute === 'ORDER_STATE' && item.value === 'REACHED_OUT')
        const color = isReachedOut ? 'orange' : 'green'
        const text = isReachedOut ? 'Reached Out' : 'Free'
        return <Tag color={color}>{text}</Tag>
      }
    },
    {
      title: 'Customer',
      render: (_value, record) => `${record.customer?.firstName || ''} ${record.customer?.lastName || ''}`,
      key: 'customer'
    },
    {
      title: 'Promocode',
      dataIndex: 'promocode',
      key: 'promocode'
    },
    {
      title: 'Sale source',
      dataIndex: 'saleSource',
      key: 'saleSource'
    },
    {
      title: 'Campaign Name',
      dataIndex: 'campaignName',
      key: 'campaignName'
    },
    {
      title: 'BIN',
      dataIndex: 'bin',
      key: 'bin'
    },
    {
      title: 'Card last 4 digits',
      dataIndex: 'cardNumber',
      key: 'cardNumber'
    },
    {
      title: 'Expiry date',
      dataIndex: 'cardExpDate',
      key: 'cardExpDate'
    },
    {
      title: 'Cycle',
      key: 'cycle',
      dataIndex: 'cycle'
    },
    {
      title: 'Total',
      render: (_value, record) => `$${(record.total / 100).toFixed(2)}`,
      key: 'total',
      align: 'right',
      sorter: true
    },
    {
      title: '',
      dataIndex: '',
      key: 'edit',
      width: '10%',
      align: 'center',
      render: (record) => (
        <span>
          <EditTwoTone
            style={{ fontSize: '20px' }}
            onClick={(e) => {
              e.stopPropagation()
              history.push(`/sales/holdedOrders?orderId=${record.id}`)
            }}
          />
        </span>
      )
    }
  ]

  const handleDrawerClose = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleOnOrderDetailsClose = () => {
    ordersList.refetch()
    history.push(`/sales/holdedOrders`)
  }

  const handleCampaignChange = (value) => {
    setSelectedCampaignId(value.id)
    if (value) {
      sessionStorage.setItem('orderCampaignId', value.id)
    } else {
      sessionStorage.removeItem('orderCampaignId')
    }
  }

  const changeDatesRange = (dates: [Moment, Moment]) => {
    if (dates !== null) {
      setDateRange(dates)
    }
  }

  const orderId = location.search && location.search.match(/\d+/g).toString()

  const drawerFooter = (
    <Button type='primary' htmlType='submit' loading={ordersList.isLoading} onClick={handleDrawerClose}>
      Close
    </Button>
  )

  const handleChangeHoldReasons = (value: OrderHoldReason[]) => setHoldReasons(value)

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title={`Holded Orders (${ordersList.data?.pages[0]?.total || 0} total)`}
          extra={[
            <Button key='1' onClick={() => setVisible(!isVisible)}>
              Settings
            </Button>
          ]}
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Sales', url: '/' },
            { title: 'Holded Orders', url: '/' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <InfiniteScroll
          style={{ paddingBottom: '30px' }}
          dataLength={page * size}
          next={ordersList.fetchNextPage}
          hasMore={!!ordersList.hasNextPage}
          loader={<h4>Loading...</h4>}
          refreshFunction={ordersList.refetch}
          scrollThreshold={0.8}
        >
          <Table
            columns={columns}
            dataSource={orders.flat()}
            bordered
            rowKey={(record) => record.id}
            loading={ordersList.isLoading}
            onChange={sortingHandler}
            pagination={false}
          />
        </InfiniteScroll>
      </Layout.Content>
      {orderId && <OrderDetails orderId={+orderId} onClose={handleOnOrderDetailsClose} />}
      <Drawer
        title='Order Data Settings'
        width='400'
        onClose={handleDrawerClose}
        visible={isVisible}
        footer={drawerFooter}
        footerStyle={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <Form layout='vertical' hideRequiredMark form={form} initialValues={{ campaignId: selectedCampaignId }}>
          <Form.Item name='campaignId' label='Campaigns'>
            <CampaignsSelect
              workspaceId={workspaceId}
              initialOption={{ id: selectedCampaignId, name: '' }}
              placeholder='Please select Campaign'
              onSelect={handleCampaignChange}
              allowClear
            />
          </Form.Item>
          <Form.Item name='holdReason' label='Hold reason'>
            <Select<OrderHoldReason[]>
              mode='multiple'
              key='hold-reason'
              placeholder='Hold Reason'
              onChange={handleChangeHoldReasons}
              allowClear
              value={holdReasons}
            >
              <Select.Option value='NEED_DESIGN' key='NEED_DESIGN'>
                Need Design
              </Select.Option>
              <Select.Option value='INVALID_ADDRESS' key='INVALID_ADDRESS'>
                Invalid Address
              </Select.Option>
              <Select.Option value='MANUAL_HOLD' key='MANUAL_HOLD'>
                Manual Hold
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='orderStates' label='Order states'>
            <Select
              key='order-states'
              mode='multiple'
              placeholder='Order states'
              onChange={(value) => setOrderStates(value)}
              allowClear
              value={orderStates}
            >
              <Select.Option value='REACHED_OUT' key='REACHED_OUT'>
                Reached out
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label='Dates range'>
            <RangePicker
              className={styles.rangePicker}
              defaultValue={datesRange}
              format={dateFormat}
              ranges={{
                Today: [moment().startOf('day'), moment().add(1, 'day').endOf('day')],
                'Last 7 days': [moment().subtract(7, 'days'), moment().startOf('day')],
                'This Week': [moment().startOf('week'), moment().endOf('week')],
                'This Month': [moment().startOf('month'), moment().endOf('month')]
              }}
              onChange={changeDatesRange}
            />
          </Form.Item>
        </Form>
      </Drawer>
    </Layout>
  )
}

export default HoldedOrdersList
