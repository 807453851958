import React, { useCallback, useEffect, useState } from 'react'
import { Button, Switch } from 'antd'
import notification from 'mrx-notification'
import { CodeEditor, Loading } from 'components'
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { SiteType } from '@merchx-v2/shared-types'
import SettingsList from 'features/settings/components/SettingsList'
import * as settingsFeature from 'features/settings'
import { SitePreview } from 'features/sitePreviews/components'
import { useUpdateAutoresponder } from '../../hooks'
import styles from './AutoresponderDetails.module.scss'
import { Landing } from 'features/landings/types'
import { Template } from 'features/templates/types'

const { SettingsForLanding } = settingsFeature.components

type PropsType = {
  ownerType: SiteType
  ownerId?: number
  autoresponderId: number
  source?: Landing | Template
}

const AutoresponderDetails = (props: PropsType) => {
  const { ownerType, ownerId, autoresponderId, source } = props

  const [sourceCode, setSourceCode] = useState<string>('')
  const [orderItemSourceCode, setOrderItemSourceCode] = useState<string>('')
  const [subjectSourceCode, setSubjectSourceCode] = useState<string>('')
  const [fromName, setFromName] = useState<string>('')
  const [useAlternativeEmailing, setUseAlternativeEmailing] = useState<boolean>(false)

  const updateAutoresponder = useUpdateAutoresponder()

  const autoresponder = source?.autoresponders?.find((item) => item.id === autoresponderId)

  const resetState = useCallback(() => {
    setSourceCode(autoresponder?.sourceCode || '')
    setOrderItemSourceCode(autoresponder?.orderItemSourceCode || '')
    setSubjectSourceCode(autoresponder?.subjectSourceCode || '')
    setFromName(autoresponder?.fromName || '')
    setUseAlternativeEmailing(autoresponder?.useAlternativeEmailing || false)
  }, [autoresponder])

  useEffect(() => {
    if (autoresponder) {
      resetState()
    }
  }, [autoresponder, resetState])

  if (!autoresponder) {
    return <Loading />
  }

  const handleCancel = () => {
    resetState()
  }

  const handleSubmit = async () => {
    updateAutoresponder.mutate(
      {
        ownerType,
        ownerId,
        autoresponderId,
        autoresponderData: {
          autoresponderType: autoresponder.autoresponderType,
          sourceCode,
          orderItemSourceCode,
          subjectSourceCode,
          fromName,
          useAlternativeEmailing
        }
      },
      {
        onSuccess: () => {
          notification.success({
            message: 'Successfully',
            description: 'Autoresponder was updated successfully!'
          })
        },
        onError: (error) => {
          notification.error({
            message: 'Error!',
            description: error.toString()
          })
        }
      }
    )
  }

  return (
    <div className={styles.root}>
      <Tabs className={styles.tabs}>
        <div className={styles.buttonsContainer}>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button loading={updateAutoresponder.isLoading} type='primary' className={styles.createButton} onClick={handleSubmit}>
            Save
          </Button>
        </div>
        <TabList>
          <Tab>Main body code</Tab>
          <Tab>Order item code</Tab>
          <Tab>Subject code</Tab>
          <Tab>From Name</Tab>
          <Tab>Settings</Tab>
          <Tab>Preview</Tab>
        </TabList>
        <TabPanel className={styles.tabPanel}>
          <div className={styles.htmlTab}>
            <CodeEditor value={sourceCode} onChange={setSourceCode} />
          </div>
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          <div className={styles.htmlTab}>
            <CodeEditor value={orderItemSourceCode} onChange={setOrderItemSourceCode} />
          </div>
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          <div className={styles.htmlTab}>
            <CodeEditor value={subjectSourceCode} onChange={setSubjectSourceCode} />
          </div>
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          <div className={styles.htmlTab}>
            <CodeEditor value={fromName} onChange={setFromName} />
          </div>
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          <div className={styles.settingsTab}>
            <div className={styles.switchContainer}>
              <span>Use Alternative Mailing Plugin</span>
              <Switch
                className={styles.switch}
                checked={useAlternativeEmailing}
                checkedChildren='true'
                unCheckedChildren='false'
                onChange={setUseAlternativeEmailing}
              />
            </div>
            {ownerType === 'LANDING' && source && (
              <SettingsForLanding ownerType='AUTORESPONDER' ownerId={autoresponderId} landing={source as Landing} />
            )}
            {ownerType === 'TEMPLATE' && (
              <SettingsList ownerType='AUTORESPONDER' ownerId={autoresponderId} settings={autoresponder?.settings} />
            )}
          </div>
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          <div className={styles.htmlTab}>
            <SitePreview ownerType='AUTORESPONDER' ownerId={autoresponderId} version={autoresponder.version} environment='PREVIEW' />
          </div>
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default AutoresponderDetails
