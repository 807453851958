import produce from 'immer'
import * as t from './actionTypes'
import { actionTypes as nt } from 'features/navigation'
import { isSuccessAction, isErrorAction } from 'types'

import { AddressesState } from './types'
import createReducer from 'store/createReducer'
import { FetchAddressesListAction, ChangeCurrentPageAction } from './actions'

const initState: AddressesState = {
  addresses: {},
  pagination: {
    pages: {},
    total: 0,
    currentPage: 1,
    size: 10
  },
  UIState: {
    isListLoading: false
  }
}

const reducer = createReducer(initState, {
  [t.FETCH_ADDRESSES_LIST]: (state, action: FetchAddressesListAction) =>
    produce(state, draft => {
      draft.UIState.isListLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isListLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        action.payload.items.forEach(item => {
          draft.addresses[item.id] = item
        })
        draft.pagination.total = action.payload.total
        draft.pagination.pages[action.payload.page] = action.payload.items.map(item => item.id)
      }
    }),

  [t.CHANGE_CURRENT_PAGE]: (state, action: ChangeCurrentPageAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.pagination.currentPage = action.payload
      }
    }),

  [nt.LOCATION_CHANGE]: state =>
    produce(state, draft => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default reducer
