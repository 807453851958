import React, { useState, useEffect, useRef, useContext } from 'react'
import { Button, Drawer, Input, Checkbox, Form, Select, Table, Popconfirm } from 'antd'
import { Store } from 'antd/lib/form/interface'
import { ColumnsType } from 'antd/lib/table'
import {
  SHIPPING_ACTION_TYPE,
  ShippingActionType,
  ShippingRuleCriteriaDto,
  ShippingActionPayload,
  SetCGRetailerPayload,
  SetDtgProductIdPayload,
  SetShippingMethodPayload
} from '@merchx-v2/shared-types'
import notification from 'mrx-notification'
import { GlobalContext } from 'appContexts'
import { useDTGProductsOptions } from 'features/DTGProducts/hooks'
import { useShippingMethodsOptions } from 'features/shippingMethods/hooks'

import { useCreateShippingRuleAction, useShippingRuleAction, useRemoveShippingRuleCriteria, useUpdateShippingRuleAction } from '../../hooks'
import ShippingCriteriaForm from '../ShippingCriteriaForm'
import styles from './EditShippingRuleAction.module.scss'

const { Option } = Select

interface FormStore extends Store {
  name: string
  isActive: boolean
}

type Props = {
  shippingRuleActionId?: number
  isVisible: boolean
  onCancel?: () => void
}

const EditShippingRuleAction = ({ isVisible, shippingRuleActionId, onCancel }: Props) => {
  const [actionType, setActionType] = useState<ShippingActionType>()
  const [actionPayload, setActionPayload] = useState<ShippingActionPayload>()
  const [isActionError, setIsActionError] = useState(false)
  const [ruleActionId, setRuleActionId] = useState(shippingRuleActionId)
  const [isCriteriaForm, setIsCriteriaForm] = useState(false)

  const refEl = useRef(null)
  const retailerIdRef = useRef(null)
  const retailerKeyRef = useRef(null)

  const { campaignId } = useContext(GlobalContext)

  const createShippingRuleAction = useCreateShippingRuleAction()
  const updateShippingRuleAction = useUpdateShippingRuleAction()
  const shippingRuleAction = useShippingRuleAction(ruleActionId)
  const DTGProductsOptions = useDTGProductsOptions('')
  const shippingMethodsOptions = useShippingMethodsOptions('')
  const removeShippingRuleCriteria = useRemoveShippingRuleCriteria()

  useEffect(() => {
    if (isVisible) {
      refEl.current && refEl.current.focus()
    }
  }, [isVisible])

  const [form] = Form.useForm()

  const handleClose = () => {
    form.resetFields()
    onCancel()
  }

  useEffect(() => {
    if (ruleActionId === undefined) {
      setActionType(undefined)
      setActionPayload(undefined)
      form.resetFields()
    }
  }, [ruleActionId])

  useEffect(() => {
    if (shippingRuleAction.data) {
      setActionType(shippingRuleAction.data.actionType)
      setActionPayload(shippingRuleAction.data.actionPayload)
      form.setFieldsValue({
        name: shippingRuleAction.data.name,
        isActive: shippingRuleAction.data.isActive
      })
    }
  }, [shippingRuleAction.data, isVisible])

  useEffect(() => {
    if (removeShippingRuleCriteria.isSuccess) {
      shippingRuleAction.refetch()
    }
  }, [removeShippingRuleCriteria.isSuccess])

  useEffect(() => {
    setRuleActionId(shippingRuleActionId)
  }, [shippingRuleActionId])

  useEffect(() => {
    if (createShippingRuleAction.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Shipping rule action was created successfully!'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createShippingRuleAction.isSuccess])

  useEffect(() => {
    if (updateShippingRuleAction.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Shipping rule action was updated successfully!'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateShippingRuleAction.isSuccess])

  useEffect(() => {
    if (createShippingRuleAction.data) {
      setRuleActionId(createShippingRuleAction.data.id)
    }
  }, [createShippingRuleAction.data])

  const onFinishHandler = (values: FormStore) => {
    setIsActionError(false)
    if (!actionType || !actionPayload) {
      setIsActionError(true)
    } else {
      if (ruleActionId) {
        updateShippingRuleAction.mutate({
          shippingRuleActionId: ruleActionId,
          shippingRuleActionData: {
            ...values,
            campaignId,
            actionType,
            actionPayload
          }
        })
      } else {
        createShippingRuleAction.mutate({
          ...values,
          campaignId,
          actionType,
          actionPayload
        })
      }
    }
  }

  const doConfirmRemoveCriteria = async (shippingRuleCriteriaId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    await removeShippingRuleCriteria.mutate(shippingRuleCriteriaId)
  }


  const criteriaColumns: ColumnsType<ShippingRuleCriteriaDto> = [
    {
      title: 'Option',
      dataIndex: 'option',
      key: 'option'
    },
    {
      title: 'Operator',
      dataIndex: 'operator',
      key: 'operator'
    },
    {
      title: 'Value',
      dataIndex: 'result',
      key: 'result'
    },
    {
      title: 'Actions',
      key: 'x',
      render: (_value, record) => (
        <span>
          <Popconfirm
            title='Are you sure delete this?'
            onConfirm={(e) => doConfirmRemoveCriteria(record.id, e)}
            onCancel={(e) => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <a
              href='/#'
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              Remove
            </a>
          </Popconfirm>
        </span>
      )
    }
  ]

  const onFinishCriteriaCreate = async () => {
    await shippingRuleAction.refetch()
    setIsCriteriaForm(false)
  }

  return (
    <>
      <Drawer title='Create ShippingRule' width='600' onClose={handleClose} visible={isVisible} forceRender>
        <Form
          layout='vertical'
          hideRequiredMark
          onFinish={onFinishHandler}
          form={form}
          initialValues={{
            name: shippingRuleAction.data?.name || '',
            isActive: shippingRuleAction.data?.isActive || false
          }}
        >
          <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input name!' }]}>
            <Input style={{ width: '100%' }} ref={refEl} />
          </Form.Item>

          <Form.Item name='isActive' label='Active' valuePropName='checked'>
            <Checkbox style={{ width: '100%' }} />
          </Form.Item>

          <div className={styles.miniForm}>
            <div>
              Action Type
              <Select key={shippingRuleAction.data?.actionType} placeholder='Select action type' defaultValue={shippingRuleAction.data?.actionType} onChange={value => setActionType(value)}>
                {Object.keys(SHIPPING_ACTION_TYPE).map(item => (
                  <Option key={item}>
                    {item.split('_').map(item => item.charAt(0) + item.slice(1).toLowerCase()).join(' ')}
                  </Option>
                ))}
              </Select>
            </div>
            {actionType && (
              <div>
                Payload
                {actionType === SHIPPING_ACTION_TYPE.SET_DTG_PRODUCT_ID && (
                  <div className={styles.formItem}>
                    DTG Product
                    <Select
                      key={JSON.stringify(shippingRuleAction.data?.actionPayload)}
                      placeholder='Select DTG Product'
                      defaultValue={(shippingRuleAction.data?.actionPayload as SetDtgProductIdPayload)?.DTGProductId}
                      onChange={value => setActionPayload({ DTGProductId: value })}
                    >
                      {DTGProductsOptions.data?.map(item => (
                        <Option key={item.id}>{item.name}</Option>
                      ))}
                    </Select>
                  </div>
                )}

                {actionType === SHIPPING_ACTION_TYPE.SET_SHIPPING_METHOD && (
                  <div className={styles.formItem}>
                    Shipment Method
                    <Select
                      key={JSON.stringify(shippingRuleAction.data?.actionPayload)}
                      placeholder='Select Shipment Method'
                      defaultValue={(shippingRuleAction.data?.actionPayload as SetShippingMethodPayload)?.shippingMethodId}
                      onChange={value => setActionPayload({
                        shippingMethodId: value
                      })}
                    >
                      {shippingMethodsOptions.data?.map(item => (
                        <Option key={item.id}>{item.name}</Option>
                      ))}
                    </Select>
                  </div>
                )}

                {actionType === SHIPPING_ACTION_TYPE.SET_CG_RETAILER && (
                  <div className={styles.formItem}>
                    Custom Gateway Retailer Company Ref
                    <Input
                      ref={retailerIdRef}
                      defaultValue={(shippingRuleAction.data?.actionPayload as SetCGRetailerPayload)?.retailerId}
                      onChange={event => setActionPayload({
                        retailerId: event.target.value,
                        retailerAPIKey: retailerKeyRef.current?.input.value || ''
                      })}
                    />

                    Custom Gateway Retailer Company API Key
                    <Input
                      ref={retailerKeyRef}
                      defaultValue={(shippingRuleAction.data?.actionPayload as SetCGRetailerPayload)?.retailerAPIKey}
                      onChange={event => setActionPayload({
                        retailerId: retailerIdRef.current?.input.value || '',
                        retailerAPIKey: event.target.value
                      })}
                    />
                  </div>
                )}
              </div>
            )}
            {isActionError && <span style={{ color: 'red' }}>Fill the form</span>}
          </div>

          {!!shippingRuleAction.data?.criteria.length && (
            <>
              Criteria
              <Table
                columns={criteriaColumns}
                dataSource={shippingRuleAction.data?.criteria.map((item) => item).flat()}
                bordered
                rowKey={(record) => record.id}
                loading={shippingRuleAction.isLoading}
                pagination={false}
              />
            </>
          )}
          {isCriteriaForm && ruleActionId && <ShippingCriteriaForm actionType={actionType} shippingRuleActionId={ruleActionId} onFinish={onFinishCriteriaCreate} />}
          {!isCriteriaForm && ruleActionId && <Button onClick={() => setIsCriteriaForm(true)}>Add criteria</Button>}

          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button onClick={handleClose} className={styles.closeButton}>
              Close
            </Button>
            <Button type='primary' htmlType='submit' loading={createShippingRuleAction.isLoading}>
              {ruleActionId ? 'Save' : 'Create'}
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

export default EditShippingRuleAction
