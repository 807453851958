import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import * as eternalListsFeature from 'features/eternalLists'
import * as t from '../actionTypes'
import { Product } from 'features/types'

type ProductsList = {
  items: Product[]
  total: number
}

type Payload = ProductsList & {
  filters: { campaignId?: number }
  page: number
}

export type FetchProductsListAction = FSA<undefined, Payload | undefined, string>
type FetchProductsList = (
  campaignId?: number,
  usePagination?: boolean,
  searchText?: string
) => MrxThunk<eternalListsFeature.actions.FetchEternalListAction<'products'>>
type QueryResponse = GraphQLResponse<'productsListPage', ProductsList>

const FETCH_PRODUCTS_LIST = `
  query fetchProductsList ($page: Int, $size: Int, $filters: ProductsListPageFilters!) {
    productsListPage(page: $page, size: $size, filters: $filters) {
      items {
        id
        productType
        name
        displayName
        description
        campaignId
        campaign {
          name
        }
        hasCustomShipping
        customShippingPrice
        sku
        tags
        image
        ownerId
        ownerType
        owner {
          id
          firstName
          lastName
          email
          createdFromLandingUrl
          createdAt
          updatedAt
        }
        subscriptionItems {
          date
          productId
          product {
            id
            name
            productType
          }
          quantity
        }
        productType2
        isActive
        sortIndex
      }
      total
    }
  }
`

const fetchProductsList: FetchProductsList =
  (campaignId, usePagination = true, searchText) =>
    async (dispatch, getState) => {
      dispatch({
        type: t.FETCH_PRODUCTS_LIST,
        meta: { done: false }
      })

      try {
        const {
          eternalLists: { products: pagination },
          workspaces: {
            activeWorkspace: { id: workspaceId }
          }
        } = getState()

        let page = 1
        let size = 6
        if (pagination.pages.length) {
          page = pagination.currentPage
          size = pagination.size
        }

        size = usePagination ? size : 30

        const {
          data: { data, errors }
        }: QueryResponse = await axios.post('/graphql', {
          query: FETCH_PRODUCTS_LIST,
          variables: { page, size, filters: { workspaceId, campaignId, searchText } }
        })

        guardFromErrors(errors)

        if (!data) {
          throw new Error('Response body is empty!')
        }

        const { productsListPage } = data
        if (!productsListPage) {
          throw new Error("Can't get products list page!")
        }

        dispatch({
          type: eternalListsFeature.actionTypes.FETCH_ETERNAL_LIST,
          payload: {
            items: productsListPage.items,
            total: productsListPage.total,
            page,
            size,
            list: 'products',
            filters: { campaignId, searchText, workspaceId }
          },
          meta: { done: true }
        })
        dispatch({
          type: t.FETCH_PRODUCTS_LIST,
          payload: undefined,
          meta: { done: true }
        })
      } catch (err) {
        dispatch({
          type: t.FETCH_PRODUCTS_LIST,
          payload: extractErrorInfo(err),
          error: true
        })
      }
    }

export { fetchProductsList }
