import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { AuthorizeSetting } from '../types'

type AuthorizeSettingsForOptionsSelector = (state: ReduxState) => AuthorizeSetting[]
const getAuthorizeSettingsForOptions: AuthorizeSettingsForOptionsSelector = state =>
  state.authorizeSettings.authorizeSettingsForOptions || ([] as AuthorizeSetting[])

export const authorizeSettingsForOptionsSelector: AuthorizeSettingsForOptionsSelector = createSelector(
  getAuthorizeSettingsForOptions,
  authorizeSettings => authorizeSettings
)
