import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

type OwnProps = {
  productColorId: number
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  return {
    isLoading: selectors.isLoadingSelector(state, 'isUpdateProductColor'),
    productColor: selectors.productColorSelector(state, ownProps.productColorId)
  }
}

const mapDispatchToProps = {
  fetchProductColor: actions.fetchProductColor,
  updateProductColor: actions.updateProductColor
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
