import { createSelector } from 'reselect'

const getAuthorizeSettingsOptions: SelectOptionsSelector = (state) =>
  state.authorizeSettings.authorizeSettingsForOptions.map((item) => ({
    id: item.id,
    name: item.name
  })) || ([] as SelectOption[])

export const authorizeSettingsSelectOptionsSelector: SelectOptionsSelector = createSelector(
  getAuthorizeSettingsOptions,
  (options) => options
)
