// TODO - remove becouse of legacy

import React from 'react'
import { CreateQuizQuestionAnswerDto } from '@merchx-v2/shared-types'
import { Input, Typography } from 'antd'
import { DeleteTwoTone } from '@ant-design/icons'
import styles from './QuizQuestionAnswerInput.module.scss'

type Props = {
  answer: CreateQuizQuestionAnswerDto
  disabled?: boolean
  onAnswerChanged: (answer: string) => void
  onAnswerRemoved: () => void
}

const QuizQuestionAnswerInput = (args: Props) => {
  const { answer, disabled, onAnswerChanged, onAnswerRemoved } = args

  return (
    <div className={styles.root}>
      <div className={styles.labeledInput}>
        <Typography style={{ color: 'black', paddingBottom: '8px' }}>Answer</Typography>
        <div className={styles.fieldContainer}>
          <Input value={answer.answer} onChange={(e) => onAnswerChanged(e.target.value)} disabled={disabled} />

          <DeleteTwoTone twoToneColor='tomato' style={{ marginLeft: 10, opacity: 0 }} onClick={() => onAnswerRemoved()} />
        </div>
      </div>
    </div>
  )
}

export default QuizQuestionAnswerInput
