import React from 'react'
import { Input, Typography, Form, Switch, Checkbox } from 'antd'
import { productSkuPattern, productSkuPatternErrorMessage } from '@merchx-v2/shared-types'
import { convertToSku } from 'utils/convertToSku'
import styles from './ProductPriceInput.module.scss'

type ProductPriceInputArgs = {
  variant?: string
  mainVariant?: string
}

const ProductPriceInput = (props: ProductPriceInputArgs) => {
  const { variant, mainVariant } = props
  const mainPriceVariant = Form.useWatch('mainPriceVariant')

  const form = Form.useFormInstance()
  const disabledVariantKeys: string[] = Form.useWatch('disabledVariantKeys', form) || []

  let currVariant = mainVariant || ''
  if (variant) {
    currVariant += `/${variant}`
  }

  const handleCheckboxChange = (value: string) => () => {
    form.setFieldValue('mainPriceVariant', value)
  }

  return (
    <div className={styles.root}>
      {variant && (
        <div className={styles.labeledInput}>
          <Typography style={{ color: 'black', paddingBottom: '8px', whiteSpace: 'nowrap' }}>Is Active?</Typography>
          <Switch
            key='active-status'
            checked={!disabledVariantKeys.some((key) => key === currVariant)}
            onChange={() => {
              if (disabledVariantKeys.some((key) => key === currVariant)) {
                form.setFieldValue(
                  'disabledVariantKeys',
                  disabledVariantKeys.filter((key) => key !== currVariant)
                )
              } else {
                form.setFieldValue('disabledVariantKeys', [...disabledVariantKeys, currVariant])
              }
            }}
          />
        </div>
      )}

      {variant && (
        <div className={styles.labeledInput}>
          <Typography style={{ color: 'black', paddingBottom: '8px' }}>Variant</Typography>
          <Input style={{ width: '188px' }} value={variant} disabled />
        </div>
      )}

      <div className={styles.labeledInput}>
        <Typography style={{ color: 'black', paddingBottom: '8px' }}>Price</Typography>
        <Form.Item
          name={
            variant && mainVariant
              ? ['prices', mainVariant, variant, 'price']
              : mainVariant
              ? ['prices', mainVariant, 'price']
              : ['price', 'price']
          }
          rules={[{ required: true, message: 'Please input variant price!' }]}
        >
          <Input prefix='$' style={{ width: '188px' }} />
        </Form.Item>
      </div>

      <div className={styles.labeledInput}>
        <Typography style={{ color: 'black', paddingBottom: '8px' }}>Discount</Typography>
        <Form.Item
          name={
            variant && mainVariant
              ? ['prices', mainVariant, variant, 'discount']
              : mainVariant
              ? ['prices', mainVariant, 'discount']
              : ['price', 'discount']
          }
          rules={[{ required: true, message: 'Please input variant discount!' }]}
          initialValue='0.00'
        >
          <Input prefix='$' style={{ width: '188px' }} />
        </Form.Item>
      </div>

      <div className={styles.labeledInput}>
        <Typography style={{ color: 'black', paddingBottom: '8px' }}>SKU</Typography>

        <Form.Item noStyle dependencies={['sku', 'displayName']}>
          {(form) => {
            const name =
              variant && mainVariant
                ? ['prices', mainVariant, variant, 'sku']
                : mainVariant
                ? ['prices', mainVariant, 'sku']
                : ['price', 'sku']
            const sku = form.getFieldValue('sku') || ''

            let generatedSku = sku

            if (mainVariant) {
              generatedSku += `-${mainVariant}`
            }

            if (variant) {
              generatedSku += `-${variant}`
            }

            form.setFieldValue(name, convertToSku(generatedSku))

            return (
              <Form.Item
                name={name}
                normalize={(val) => convertToSku(val || '')}
                rules={[
                  { required: true, pattern: productSkuPattern, message: 'Please input variant SKU! ' + productSkuPatternErrorMessage }
                ]}
                initialValue={convertToSku(generatedSku)}
              >
                <Input style={{ width: '396px' }} />
              </Form.Item>
            )
          }}
        </Form.Item>
      </div>
      {!variant && mainVariant && (
        <Checkbox className={styles.checkbox} checked={mainPriceVariant === mainVariant} onChange={handleCheckboxChange(mainVariant)}>
          Display in a lists
        </Checkbox>
      )}
    </div>
  )
}

export default ProductPriceInput
