import React, { useContext, useEffect } from 'react'
import { Button, Drawer, DatePicker, Input, Form } from 'antd'
import { GlobalContext } from 'appContexts'
import { ProductsSelect } from 'features/products/components'
import styles from './UpdateSubscriptionPrice.module.scss'
import moment from 'moment'
import { SubscriptionItem } from 'features/types'

type Props = {
  subscriptionRecord: SubscriptionItem
  onClose: () => void
  visible: boolean
  handleUpdateSubscription: (newValues: any) => void
}

const UpdateSubscriptionPrice = (props: Props) => {
  const { onClose, subscriptionRecord, visible, handleUpdateSubscription } = props

  const [form] = Form.useForm()

  const { campaignId } = useContext(GlobalContext)

  useEffect(() => {
    if (subscriptionRecord && Object.keys(subscriptionRecord).length) {
      form.setFieldsValue({
        date: moment(subscriptionRecord.date),
        product: subscriptionRecord.product,
        quantity: subscriptionRecord.quantity
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionRecord])

  if (!subscriptionRecord) {
    return null
  }

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  // TODO - fix any type
  const onFinishHandler = async (values: any) => {
    handleUpdateSubscription({ ...values, date: values.date.toISOString() })
    handleClose()
  }

  return (
    <Drawer title='Update Subscription Record' width='400' onClose={handleClose} visible={visible}>
      <Form
        layout='vertical'
        hideRequiredMark
        onFinish={onFinishHandler}
        form={form}
        initialValues={{
          date: moment(subscriptionRecord?.date),
          product: subscriptionRecord?.product,
          quantity: subscriptionRecord?.quantity
        }}
      >
        <Form.Item
          name='date'
          label='Date'
          rules={[
            {
              required: true,
              message: 'Please select a date!'
            }
          ]}
        >
          <DatePicker className={styles.formItem} format='MM/DD/YYYY' style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item name='product' label='Product' rules={[{ required: true, message: 'Please select product!' }]}>
          <ProductsSelect
            campaignId={+campaignId}
            settingId={0}
            initialOption={{
              id: subscriptionRecord?.product?.id || 0,
              name: subscriptionRecord?.product?.name || 'Select a product'
            }}
            onSelect={(selectedOption: SelectOption) => {
              form.setFieldsValue({
                product: selectedOption
              })
            }}
          />
        </Form.Item>
        <Form.Item name='quantity' label='Quantity' rules={[{ required: true, message: 'Please input quantity!' }]}>
          <Input type='number' style={{ width: '100%' }} />
        </Form.Item>
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right'
          }}
        >
          <Button onClick={handleClose} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit'>
            Save
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default UpdateSubscriptionPrice
