import React, { useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Table, Layout, PageHeader, Popconfirm, Button } from 'antd'
import { CheckCircleTwoTone, PlusOutlined } from '@ant-design/icons'
import { ShippingRuleActionDto } from '@merchx-v2/shared-types'
import { ColumnsType } from 'antd/lib/table'
import notification from 'mrx-notification'
import { Breadcrumbs } from 'components'
import * as rootStyles from 'assets/layoutStyle'

import { useShippingRulesList, useRemoveShippingRuleAction } from '../../hooks'
import EditShippingRuleAction from '../EditShippingRuleAction'
import styles from './ShippingRulesList.module.scss'

const ShippingRulesList = React.memo(() => {
  const [selectedShippingRuleId, setSelectedShippingRuleId] = useState<number | undefined>()
  const [isCreateAction, setIsCreateAction] = useState<boolean>(false)

  const { history } = useReactRouter()

  const removeShippingRule = useRemoveShippingRuleAction()
  const shippingRulesList = useShippingRulesList()
  const shippingRules: ShippingRuleActionDto[] = []
  shippingRulesList &&
    shippingRulesList.data &&
    shippingRulesList.data.pages.forEach((page) => page.items.forEach((shippingRule) => shippingRules.push(shippingRule)))

  const page = shippingRulesList.data?.pages.length ? shippingRulesList.data?.pages[shippingRulesList.data?.pages.length - 1].currentPage : 1
  const size = shippingRulesList.data?.pages.length ? shippingRulesList.data?.pages[shippingRulesList.data?.pages.length - 1].pageSize : 30

  useEffect(() => {
    return () => shippingRulesList.remove()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (shippingRulesList.error) {
      notification.error({
        message: 'Shipping methods list page error!',
        description: shippingRulesList.error instanceof Error ? shippingRulesList.error.message : shippingRulesList.error.toString()
      })
    }
  }, [shippingRulesList.error])

  useEffect(() => {
    if (removeShippingRule.isSuccess) {
      setSelectedShippingRuleId(undefined)
      notification.success({
        message: 'Successfully',
        description: 'Shipping method was removed successfully!'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeShippingRule.isSuccess])

  const doConfirm = async (shippingRuleId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    removeShippingRule.mutate(shippingRuleId)
  }

  const handleOnRow = (record: ShippingRuleActionDto) => {
    return {
      onClick: () => {
        setSelectedShippingRuleId(record.id)
        setIsCreateAction(true)
      }
    }
  }

  const handleCancelEdit = () => {
    setSelectedShippingRuleId(undefined)
    setIsCreateAction(false)
  }

  const columns: ColumnsType<ShippingRuleActionDto> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Active',
      render: (_value, record) => record.isActive ? <CheckCircleTwoTone /> : '',
      key: 'isActive'
    },
    {
      title: 'Actions',
      key: 'x',
      render: (_value, record) => (
        <span>
          <Popconfirm
            title='Are you sure delete this?'
            onConfirm={(e) => doConfirm(record.id, e)}
            onCancel={(e) => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <a
              href='/#'
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              Remove
            </a>
          </Popconfirm>
        </span>
      )
    }
  ]

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title='Shipping Rules List'
          extra={[
            <Button type='primary' onClick={() => setIsCreateAction(true)} key='1'>
              <PlusOutlined />
              CREATE
            </Button>
          ]}
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Settings', url: '/' },
            { title: 'Shipping Rules List', url: '/' }
          ]}
        />
      </Layout.Content>
        <EditShippingRuleAction
          onCancel={handleCancelEdit}
          isVisible={isCreateAction}
          shippingRuleActionId={selectedShippingRuleId}
        />
      <Layout.Content className={styles.contentComponent}>
        <InfiniteScroll
          style={{ paddingBottom: '30px' }}
          dataLength={page * size}
          next={shippingRulesList.fetchNextPage}
          hasMore={!!shippingRulesList.hasNextPage}
          loader={<h4>Loading...</h4>}
          refreshFunction={shippingRulesList.refetch}
          scrollThreshold={0.8}
        >
          <Table
            columns={columns}
            dataSource={shippingRules.map((item) => item).flat()}
            bordered
            rowKey={(record) => record.id}
            loading={shippingRulesList.isLoading}
            onRow={handleOnRow}
            pagination={false}
          />
        </InfiniteScroll>
      </Layout.Content>
    </Layout>
  )
})

export default ShippingRulesList
