import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'unbanUser', boolean>

const UNBAN_USER = `
  mutation unbanUser ($userId: Int!) {
    unbanUser(userId: $userId)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UnbanUserArgs = {
  userId: number
}

type UnbanUser = (args: UnbanUserArgs) => Promise<number>

const unbanUser: UnbanUser = async ({ userId }) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UNBAN_USER,
      variables: {
        userId
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { unbanUser } = data
    if (!unbanUser) {
      throw new Error("Can't unban user!")
    }

    return userId
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UnbanUserEvents = {
  onSettled: (customerId?: number) => void
}

const unbanUserEvents: UnbanUserEvents = {
  onSettled: (userId) => {
    if (userId) {
      queryClient.invalidateQueries(['user', userId])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUnbanUser = () => useMutation(unbanUser, unbanUserEvents)
