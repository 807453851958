import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { PostmarkSetting } from '../types'

type PostmarkSettingsForOptionsSelector = (state: ReduxState) => PostmarkSetting[]
const getPostmarkSettingsForOptions: PostmarkSettingsForOptionsSelector = state =>
  state.postmarkSettings.postmarkSettingsForOptions || ([] as PostmarkSetting[])

export const postmarkSettingsForOptionsSelector: PostmarkSettingsForOptionsSelector = createSelector(
  getPostmarkSettingsForOptions,
  postmarkSettings => postmarkSettings
)
