import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { SitesManagementDashboard } from '../types'

type Payload = {
  sitesManagementDashboard: SitesManagementDashboard
  page: number
}

export type FetchSitesManagementDashboardAction = FSA<undefined, Payload, string>
type FetchSitesManagementDashboard = () => MrxThunk<FetchSitesManagementDashboardAction>
type QueryResponse = GraphQLResponse<'sitesManagementDashboard', SitesManagementDashboard>

const FETCH_SITES_MANAGEMENT_DASHBOARD = `
  query fetchSitesManagementDashboard ($page: Int, $size: Int, $workspaceId: Int!) {
    sitesManagementDashboard(page: $page, size: $size, workspaceId: $workspaceId) {
      totalLandings
      activeLandings
      archivedLandings
      newLandings
      updatesAvailable
      needToRedeploy
      items {
        id
        name
        campaignName
        siteUrl
        errors
        status
        needToRedeploy
      }
      total
    }
  }
`

const fetchSitesManagementDashboard: FetchSitesManagementDashboard = () => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_SITES_MANAGEMENT_DASHBOARD,
    meta: { done: false }
  })

  try {
    const {
      sitesManagement: {
        pagination: { size, currentPage: page }
      },
      workspaces: {
        activeWorkspace: { id: workspaceId }
      }
    } = getState()

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_SITES_MANAGEMENT_DASHBOARD,
      variables: { page, size, workspaceId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { sitesManagementDashboard } = data
    if (!sitesManagementDashboard) {
      throw new Error("Can't get sites management dashboard report!")
    }

    dispatch({
      type: t.FETCH_SITES_MANAGEMENT_DASHBOARD,
      payload: {
        sitesManagementDashboard,
        page
      },
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_SITES_MANAGEMENT_DASHBOARD,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchSitesManagementDashboard }
