import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type isLoadingKeyType =
  | 'isCreateProduct'
  | 'isDuplicateProduct'
  | 'isFetchProduct'
  | 'isFetchProductsForOptions'
  | 'isListLoading'
  | 'isRemoveProduct'
  | 'isUpdateProduct'
  | 'isSetActiveProduct'
  | 'isUpdatePrices'
  | 'isUpdateVirtualProduct'

type IsLoadingSelector = (state: ReduxState, isLoadingKey: isLoadingKeyType) => boolean

const getIsLoadingSelectorByKey = (state: ReduxState, isLoadingKey: isLoadingKeyType) => state.products.UIState[isLoadingKey] || false

export const isLoadingSelector: IsLoadingSelector = createSelector(getIsLoadingSelectorByKey, (isLoading) => isLoading)
