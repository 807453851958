import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

export type RemoveProductTagAction = FSA<undefined, number, string>
type RemoveProductTag = (productTagId: number) => MrxThunk<RemoveProductTagAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'removeProductTag', boolean>

const REMOVE_PRODUCT_TAG = `
  mutation removeProductTag($productTagId: Int!) {
    removeProductTag(productTagId: $productTagId) 
  }
`

const removeProductTag: RemoveProductTag = (productTagId) => async (dispatch) => {
  let isRemoved = false

  dispatch({
    type: t.REMOVE_PRODUCT_TAG,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_PRODUCT_TAG,
      variables: { productTagId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Empty response!')
    }

    const { removeProductTag } = data
    if (!removeProductTag) {
      throw new Error("Can't remove product tag!")
    }

    dispatch({
      type: t.REMOVE_PRODUCT_TAG,
      payload: productTagId,
      meta: { done: true }
    })

    isRemoved = true
  } catch (err) {
    dispatch({
      type: t.REMOVE_PRODUCT_TAG,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isRemoved
}

export { removeProductTag }
