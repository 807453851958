import React, { useEffect } from 'react'
import { Modal, Form, Input, DatePicker } from 'antd'
import notification from 'mrx-notification'
import { useSendPayout } from 'features/stores/hooks'

type Props = {
  storeId: number
  visibleModal: boolean
  onCancel: () => void
}

const RefundPaymentModal = (props: Props) => {
  const { storeId, visibleModal, onCancel } = props
  const [form] = Form.useForm()

  const sendPayout = useSendPayout()

  useEffect(() => {
    if (sendPayout.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Store Payout was send successfully! Keep in mind that you have to send it manually!'
      })
      onCancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendPayout.isSuccess])

  useEffect(() => {
    if (sendPayout.error) {
      notification.error({
        message: 'Error',
        description: sendPayout.error.toString()
      })
    }
  }, [sendPayout.error])

  const handleOk = async () => {
    const { note, sum, paymentMethod, periodStart, periodEnd } = await form.validateFields()

    sendPayout.mutate({
      storeId,
      sum: Math.round(parseFloat(sum) * 100),
      note,
      periodStart,
      periodEnd,
      paymentMethod
    })
  }

  return (
    <Modal
      visible={visibleModal}
      title='Increase Balance'
      onOk={handleOk}
      okButtonProps={{
        disabled: sendPayout.isLoading,
        loading: sendPayout.isLoading
      }}
      onCancel={onCancel}
    >
      <Form layout='vertical' form={form} hideRequiredMark>
        <Form.Item name='sum' label='Amount' rules={[{ required: true, message: 'Please input Amount!' }]}>
          <Input type='number' />
        </Form.Item>
        <Form.Item name='note' label='Comment' rules={[{ required: false, message: 'Please input Commentary!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name='paymentMethod' label='Payment Method' rules={[{ required: true, message: 'Please input Payment Method!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name='periodStart' label='Period Start' rules={[{ required: true, message: 'Please input Period Start' }]}>
          <DatePicker style={{ width: '100%' }} format='MM/DD/YYYY' />
        </Form.Item>
        <Form.Item name='periodEnd' label='Period End' rules={[{ required: true, message: 'Please input Period End' }]}>
          <DatePicker style={{ width: '100%' }} format='MM/DD/YYYY' />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default RefundPaymentModal
