import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

type Payload = {
  countryId: number
  regionId: number
  regionData: RegionInput
}

export type UpdateRegionAction = FSA<undefined, Payload, string>

type UpdateRegion = (args: Payload) => MrxThunk<UpdateRegionAction, Promise<boolean>>

type QueryResponse = GraphQLResponse<'updateRegion', boolean>

const UPDATE_REGION = `
  mutation updateRegion($regionId: Int!, $regionData: RegionInput!) {
    updateRegion(regionId: $regionId, regionData: $regionData)
  }
`

type RegionInput = {
  countryId: number
  name: string
  iso2: string
}

const updateRegion: UpdateRegion = (args) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.UPDATE_REGION,
    meta: { done: false }
  })

  try {
    const { regionId, regionData } = args
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_REGION,
      variables: { regionId, regionData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateRegion } = data
    if (!updateRegion) {
      throw new Error("Can't update region!")
    }

    dispatch({
      type: t.UPDATE_REGION,
      payload: args,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.UPDATE_REGION,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { updateRegion }
