// TODO - remove becouse of legacy

import { connect, ConnectedProps } from 'react-redux'
import { Build } from 'features/landings/types'

type OwnProps = {
  landingId?: number
  builds: Build[]
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = {}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
