import React, { useState, useEffect, useRef } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Drawer, Input, Form, Select } from 'antd'
import notification from 'mrx-notification'
import styles from './CreateCountry.module.scss'
import { CurrenciesSelect } from 'features/currencies/components'
import { useCreateCountry } from '../../hooks'
import { Store } from 'antd/lib/form/interface'

interface FormStore extends Store {
  name: string
  currencyId: number
  iso2: string
  iso3: string
  worldPart: string
  code: string
}

const CreateCountry = () => {
  const [isVisible, setVisible] = useState(false)
  const refEl = useRef<Input>(null)

  useEffect(() => {
    if (isVisible) {
      refEl.current && refEl.current.focus()
    }
  }, [isVisible])

  const showDrawer = () => {
    setVisible(!isVisible)
  }

  const [form] = Form.useForm()

  const createCountry = useCreateCountry()

  const handleClose = () => {
    setVisible(false)
    form.resetFields()
  }

  useEffect(() => {
    if (createCountry.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Country was created successfully!'
      })
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createCountry.isSuccess])

  const onFinishHandler = (values: FormStore) => {
    createCountry.mutate(values)
  }

  return (
    <>
      <Button type='primary' onClick={showDrawer}>
        <PlusOutlined />
        CREATE
      </Button>
      <Drawer title='Create Country' width='400' onClose={handleClose} visible={isVisible} forceRender>
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
          <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input country name!' }]}>
            <Input style={{ width: '100%' }} ref={refEl} />
          </Form.Item>

          <Form.Item name='currencyId' label='Currency' rules={[{ required: true, message: 'Please select country currency!' }]}>
            <CurrenciesSelect
              initialOption={{
                id: 0,
                name: 'Select a currency'
              }}
              onSelect={(selectedOption: SelectOption) => {
                form.setFieldsValue({
                  currencyId: selectedOption?.id
                })
              }}
            />
          </Form.Item>

          <Form.Item name='iso2' label='ISO 2' rules={[{ required: true, message: 'Please input ISO 2!' }]}>
            <Input style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item name='iso3' label='ISO 3' rules={[{ required: true, message: 'Please input ISO 3' }]}>
            <Input style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item name='worldPart' label='World Part' rules={[{ required: true, message: 'Please select world part!' }]}>
            <Select style={{ width: '100%' }}>
              <Select.Option value='America'>America</Select.Option>
              <Select.Option value='Africa'>Africa</Select.Option>
              <Select.Option value='Asia'>Asia</Select.Option>
              <Select.Option value='Australia'>Australia</Select.Option>
              <Select.Option value='Europe'>Europe</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item name='code' label='Code' rules={[{ required: true, message: 'Please input code' }]}>
            <Input style={{ width: '100%' }} />
          </Form.Item>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button onClick={handleClose} className={styles.closeButton}>
              Close
            </Button>
            <Button type='primary' htmlType='submit' loading={createCountry.isLoading}>
              Create
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

export default CreateCountry
