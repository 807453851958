import React from 'react'
import { SearchableSelectBase } from 'components'
import { PropsFromRedux, connector } from './container'

const RegionsSelect = (props: PropsFromRedux) => {
  const { fetchOptions, countryId, ...rest } = props

  return <SearchableSelectBase onSearch={(searchText: string) => fetchOptions(countryId, searchText)} {...rest} />
}

export default connector(RegionsSelect)
