import { ProductPriceOwner } from '@merchx-v2/shared-types'
import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as filesFeature from 'features/files'
import * as productImagesFeature from 'features/productImages'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

type OwnProps = {
  productPriceOwnerType?: ProductPriceOwner
  productPriceOwnerId?: number
  productId?: number
  match?: {
    params?: {
      landingId?: string
      productId?: string
    }
  }
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  let { productId } = ownProps

  if (!productId && ownProps?.match?.params?.productId) {
    productId = +ownProps.match.params.productId
  }

  const landingId = +ownProps?.match?.params?.landingId

  return {
    landingId,
    productId,
    isLoading:
      selectors.isLoadingSelector(state, 'isCreateProduct') ||
      filesFeature.selectors.isLoadingSelector(state, 'isUploadFile') ||
      productImagesFeature.selectors.isLoadingSelector(state, 'isCreateProductImage'),
    productError: selectors.errorSelector(state),
    productImages: productImagesFeature.selectors.productImagesListSelector(state, productId),
    attributesOptions: selectors.productAvailableAttributeValuesSelector(state)
  }
}

const mapDispatchToProps = {
  createProduct: actions.createProduct,
  fetchProductAvailableAttributeValues: actions.fetchProductAvailableAttributeValues,
  fetchProductImagesList: productImagesFeature.actions.fetchProductImagesList,
  uploadFile: filesFeature.actions.uploadFile,
  updateProductV2: actions.updateProductV2,
  removeProductImage: productImagesFeature.actions.removeProductImage,

  saveSubscriptionData: actions.saveSubscriptionData
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
