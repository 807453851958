import { useMutation } from 'react-query'
import { OrderHoldReason } from '@merchx-v2/shared-types'
import axios from 'utils/axios'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Order } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'holdOrder', Order>

const HOLD_ORDER = `
  mutation HoldOrder($orderId: Int!, $holdReason: OrderHoldReason!) {
    holdOrder(orderId: $orderId, holdReason: $holdReason) {
      id
      status
      holdReasons
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type HoldOrderArgs = {
  orderId: number
  holdReason?: OrderHoldReason
}
type HoldOrder = (args: HoldOrderArgs) => Promise<Order>

const holdOrder: HoldOrder = async ({ orderId, holdReason = 'MANUAL_HOLD' }) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: HOLD_ORDER,
      variables: { orderId, holdReason }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { holdOrder } = data
    if (!holdOrder) {
      throw new Error("Can't hold order!")
    }

    return holdOrder
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type HoldOrderEvents = {
  onSettled: (data?: Order) => void
}

const holdOrderEvents: HoldOrderEvents = {
  onSettled: (order) => {
    if (order?.id) {
      queryClient.invalidateQueries(['order'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useHoldOrder = () => useMutation(holdOrder, holdOrderEvents)
