import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { AwsSetting } from '../types'

export type FetchAwsSettingsForOptionsAction = FSA<undefined, AwsSetting[], string>
type FetchAwsSettingsForOptions = (searchText?: string) => MrxThunk<FetchAwsSettingsForOptionsAction>
type QueryResponse = GraphQLResponse<'awsSettingsForOptions', AwsSetting[]>

const FETCH_AWS_SETTINGS_FOR_OPTIONS = `
  query fetchAwsSettingsForOptions ($filters: AwsSettingsFilters!) {
    awsSettingsForOptions (filters: $filters) {
      id
      name
    }
  }
`

const fetchAwsSettingsForOptions: FetchAwsSettingsForOptions = searchText => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_AWS_SETTINGS_FOR_OPTIONS,
    meta: { done: false }
  })

  try {
    const {
      workspaces: {
        activeWorkspace: { id: workspaceId }
      }
    } = getState()
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_AWS_SETTINGS_FOR_OPTIONS,
      variables: { filters: { workspaceId, searchText } }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { awsSettingsForOptions } = data
    if (!awsSettingsForOptions) {
      throw new Error("Can't get aws settings!")
    }

    dispatch({
      type: t.FETCH_AWS_SETTINGS_FOR_OPTIONS,
      payload: awsSettingsForOptions,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_AWS_SETTINGS_FOR_OPTIONS,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchAwsSettingsForOptions }
