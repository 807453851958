import React, { useEffect, useState } from 'react'
import { Button, Drawer, Input, Form } from 'antd'
import hash from 'object-hash'
import { PublicProductPriceV2Dto } from '@merchx-v2/shared-types'
import { useProductV2 } from 'features/products/hooks/useProductV2'
import styles from './UpdateOrderItem.module.scss'
import { OrderItem } from '../../types'

type Props = {
  orderItem: OrderItem
  campaignId: number
  landingId: number
  onClose: () => void
  visible: boolean

  updateOrderItemHandler: (newValues) => void
}

const UpdateOrderItem = (props: Props) => {
  const { onClose, visible, orderItem, updateOrderItemHandler } = props

  const product = useProductV2({
    campaignId: props.campaignId,
    landingId: props.landingId,
    productId: orderItem.productId
  })

  const [selectedProductPrice, setSelectedProductPrice] = useState<PublicProductPriceV2Dto>()

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      displayName: orderItem?.displayName,
      sku: orderItem?.sku,
      quantity: orderItem?.quantity,
      price: (orderItem?.price / 100).toFixed(2),
      discount: (orderItem?.discount / 100).toFixed(2),
      shipping: (orderItem?.shipping / 100).toFixed(2),
      processing: (orderItem?.processing / 100).toFixed(2)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderItem])

  useEffect(() => {
    const defaultProductPrice = product?.data?.prices?.find((item) => item.id === orderItem?.productPriceId)
    console.log('Default product', defaultProductPrice)
    if (defaultProductPrice) {
      setSelectedProductPrice(defaultProductPrice)
      form.setFieldsValue({
        variantKey: defaultProductPrice.variantKey,
        sku: defaultProductPrice.sku
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(product || {}), hash(orderItem)])

  const handleClose = () => {
    form.resetFields()
    setSelectedProductPrice(undefined)
    onClose()
  }

  // TODO - fix any type
  const onFinishHandler = async (values: any) => {
    updateOrderItemHandler({ ...values, productPriceId: selectedProductPrice.id })
    handleClose()
  }

  return (
    <Drawer title='Update Order Item' width='400' onClose={handleClose} visible={visible}>
      <Form
        layout='vertical'
        hideRequiredMark
        onFinish={onFinishHandler}
        form={form}
        initialValues={{ sku: orderItem?.sku, quantity: orderItem?.quantity }}
      >
        <Form.Item name='displayName' label='Display Name' rules={[{ required: true, message: 'Please input display name!' }]}>
          <Input style={{ width: '100%' }} disabled />
        </Form.Item>

        {selectedProductPrice?.variantKey && (
          <Form.Item name='variantKey' label='Variant' rules={[{ required: true }]}>
            <Input style={{ width: '100%' }} disabled />
          </Form.Item>
        )}

        <Form.Item name='sku' label='SKU' rules={[{ required: true, message: 'Please input sku!' }]}>
          <Input style={{ width: '100%' }} disabled />
        </Form.Item>

        <Form.Item name='quantity' label='Quantity' rules={[{ required: true, message: 'Please input quantity!' }]}>
          <Input style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item name='price' label='Price' rules={[{ required: true, message: 'Please input product price!' }]}>
          <Input type='number' disabled />
        </Form.Item>

        <Form.Item name='discount' label='Discount' rules={[{ required: true, message: 'Please input product discount!' }]}>
          <Input type='number' disabled />
        </Form.Item>

        <Form.Item name='shipping' label='Shipping' rules={[{ required: true, message: 'Please input product shipping cost!' }]}>
          <Input type='number' />
        </Form.Item>

        <Form.Item name='processing' label='Processing' rules={[{ required: true, message: 'Please input product processing cost!' }]}>
          <Input type='number' />
        </Form.Item>

        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right'
          }}
        >
          <Button onClick={handleClose} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit'>
            Save
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default UpdateOrderItem
