import React, { useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import moment, { Moment } from 'moment'
import numeral from 'numeral'
import { Table, Layout, PageHeader, DatePicker, Button } from 'antd'
import { CheckOutlined, DownloadOutlined } from '@ant-design/icons'
import notification from 'mrx-notification'
import { Breadcrumbs } from 'components'
import { RangeValue } from 'rc-picker/lib/interface'
import * as rootStyles from 'assets/layoutStyle'
import styles from './CustomersDataReport.module.scss'
import { connector, PropsFromRedux } from './container'

const CustomersDataReport = (props: PropsFromRedux) => {
  const {
    customersDataReport,
    isLoading,
    isLoadingCsv,
    error,

    fetchCustomersDataReport,
    fetchCustomersCsvDataReport
  } = props

  const { history } = useReactRouter()

  const [startDate, setStartDate] = useState<Moment>(moment().startOf('day'))
  const [endDate, setEndDate] = useState<Moment>(
    moment()
      .add(1, 'day')
      .endOf('day')
  )

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Customers Data report error!',
        description: error
      })
    }
  }, [error])

  const handleSubmit = () => {
    fetchCustomersDataReport(startDate, endDate)
  }

  const onDateChange = (dates: RangeValue<Moment>) => {
    if (dates && dates[0] && dates[1]) {
      setStartDate(dates[0])
      setEndDate(dates[1])
    }
  }

  const columns = [
    {
      title: 'Common',
      children: [
        {
          title: 'Date',
          dataIndex: 'date',
          render: record => moment(record).format('MM/DD/YYYY'),
          key: 'date'
        },
        {
          title: 'Order ID',
          dataIndex: 'orderId',
          key: 'orderId'
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status'
        },
        {
          title: 'Customer Name',
          dataIndex: 'customerName',
          key: 'customerName'
        },
        {
          title: 'Customer Email',
          dataIndex: 'customerEmail',
          key: 'customerEmail'
        },
        {
          title: 'Customer Phone',
          dataIndex: 'customerPhone',
          key: 'customerPhone'
        }
      ]
    },
    {
      title: 'Order Info',
      children: [
        {
          title: 'Order Items',
          dataIndex: 'orderItems',
          key: 'orderItems'
        },
        {
          title: 'SKUs',
          dataIndex: 'skus',
          key: 'skus'
        },
        {
          title: 'Subtotal',
          render: record => `${numeral(record.subtotal / 100).format('$0,0.00')}`,
          key: 'subtotal'
        },
        {
          title: 'Shipping',
          render: record => `${numeral(record.shipping / 100).format('$0,0.00')}`,
          key: 'shipping'
        },
        {
          title: 'Processing',
          render: record => `${numeral(record.processing / 100).format('$0,0.00')}`,
          key: 'processing'
        },
        {
          title: 'Total',
          render: record => `${numeral(record.total / 100).format('$0,0.00')}`,
          key: 'total'
        }
      ]
    },
    {
      title: 'Payments',
      children: [
        {
          title: 'Paid',
          render: record => `${numeral(record.paid / 100).format('$0,0.00')}`,
          key: 'paid'
        },
        {
          title: 'Refund',
          render: record => `${numeral(record.refund / 100).format('$0,0.00')}`,
          key: 'refund'
        },
        {
          title: 'Total Received',
          render: record => `${numeral(record.totalReceived / 100).format('$0,0.00')}`,
          key: 'totalReceived'
        },
        {
          title: 'BIN',
          dataIndex: 'bin',
          key: 'bin'
        },
        {
          title: 'Card last 4 digits',
          dataIndex: 'cardNumber',
          key: 'cardNumber'
        },
        {
          title: 'Expiry date',
          dataIndex: 'cardExpDate',
          key: 'cardExpDate'
        }
      ]
    },
    {
      title: 'Sale Source',
      children: [
        {
          title: 'Campaign',
          dataIndex: 'campaign',
          key: 'campaign'
        },
        {
          title: 'Landing',
          dataIndex: 'landing',
          key: 'landing'
        },
        {
          title: 'MID',
          dataIndex: 'mid',
          key: 'mid'
        },
        {
          title: 'Corporation',
          dataIndex: 'corporation',
          key: 'mid'
        },
        {
          title: 'Sale Source',
          dataIndex: 'saleSource',
          key: 'saleSource  '
        }
      ]
    },
    {
      title: 'Shipping',
      children: [
        {
          title: 'ShippingCountry',
          dataIndex: 'shippingCountry',
          key: 'shippingCountry'
        },
        {
          title: 'Shipping State',
          dataIndex: 'shippingState',
          key: 'shippingState'
        },
        {
          title: 'Shipping ZIP Code',
          dataIndex: 'shippingZipCode',
          key: 'shippingZipCode'
        }
      ]
    }
  ]

  const handleExportCSV = async () => {
    const signedUrl = await fetchCustomersCsvDataReport(startDate, endDate)
    signedUrl && window.open(signedUrl, '_blank')
  }

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title='Customers Data Report'
          extra={[
            <DatePicker.RangePicker
              key='2'
              defaultValue={[startDate, endDate]}
              format='MM/DD/YYYY'
              ranges={{
                Today: [
                  moment().startOf('day'),
                  moment()
                    .add(1, 'day')
                    .endOf('day')
                ],
                'Last 7 days': [moment().subtract(7, 'days'), moment().startOf('day')],
                'This Week': [moment().startOf('week'), moment().endOf('week')],
                'This Month': [moment().startOf('month'), moment().endOf('month')]
              }}
              onChange={onDateChange}
            />,
            <Button key='3' type='primary' icon={<CheckOutlined />} onClick={handleSubmit} loading={isLoading}>
              Go
            </Button>,
            <Button key='4' icon={<DownloadOutlined />} disabled={isLoadingCsv} loading={isLoadingCsv} onClick={handleExportCSV}>
              CSV
            </Button>
          ]}
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Reports', url: '/' },
            { title: 'Customers Data Report', url: '/' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <Table
          columns={columns}
          dataSource={customersDataReport}
          bordered
          rowKey={record => record.orderId}
          pagination={false}
          loading={isLoading}
        />
      </Layout.Content>
    </Layout>
  )
}

export default connector(CustomersDataReport)
