import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Input, Checkbox, Form } from 'antd'
import notification from 'mrx-notification'
import { GlobalContext } from 'appContexts'
import logo from 'assets/logo.png'
import { connector, PropsFromRedux } from './container'
import styles from './Login.module.scss'

const Login: React.FC<PropsFromRedux> = props => {
  const { error, isLoading, resetForm, login } = props

  const { setCampaignId, setWorkspaceId } = useContext(GlobalContext)

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Error login!',
        description: error
      })
      resetForm()
    }
  }, [error, resetForm])

  const onFinish = (values: Record<string, any>) => {
    login(values.email, values.password, values.rememberMe, setCampaignId, setWorkspaceId)
  }

  return (
    <div className={styles.loginFormContainer}>
      <div className={styles.imageContainer}>
        <img className={styles.logo} src={logo} alt='Please, Log In' />
      </div>
      <Form onFinish={onFinish} className={styles.loginForm} initialValues={{ rememberMe: true }}>
        <Form.Item name='email' rules={[{ required: true, message: 'Please input your email!' }]}>
          <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='Email' />
        </Form.Item>
        <Form.Item name='password' rules={[{ required: true, message: 'Please input your Password!' }]}>
          <Input.Password prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='Password' />
        </Form.Item>
        <div className={styles.rememberBox}>
          <Form.Item name='rememberMe' valuePropName='checked'>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <Link className='login-form-forgot' to='#'>
            Forgot password
          </Link>
        </div>
        <Button loading={isLoading} type='primary' htmlType='submit' className={styles.loginFormButton}>
          Log in
        </Button>
        <span>Or </span>
        <Link to='/register'>register now!</Link>
      </Form>
    </div>
  )
}

export default connector(Login)
