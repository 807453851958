import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { Region } from '../types'

type RegionsListSelector = (state: ReduxState, countryId: number) => Region[]
const getRegionsList: RegionsListSelector = (state, countryId) => {
  const result = [] as Region[]
  if (countryId) {
    if (Object.prototype.hasOwnProperty.call(state.regions.pagination, countryId)) {
      const pageIds = state.regions.pagination[countryId].pages[state.regions.pagination[countryId].currentPage] || []
      if (Object.prototype.hasOwnProperty.call(state.regions.regions, countryId)) {
        pageIds.forEach((pageId) => {
          result.push(state.regions.regions[countryId][pageId])
        })
      }
    }
  }

  return result
}

export const regionsListSelector: RegionsListSelector = createSelector(getRegionsList, (list) => list)
