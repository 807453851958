import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { CustomerInput } from 'features/customers/types'
import { Order } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'createInvoiceOrder', Order>

const CREATE_INVOICE_ORDER = `
mutation createInvoiceOrder ($customerId: Int, $customerData: CustomerInput, $orderData: OrderDataInput!, $shippingPrice: Int, $processingPrice: Int) {
  createInvoiceOrder(customerId: $customerId, customerData: $customerData, orderData: $orderData, shippingPrice: $shippingPrice, processingPrice: $processingPrice) {
    id
    customerId
    customer {
      id
      email
      firstName
      lastName
      createdAt
      updatedAt
    }
    billingAddressId
    billingAddress {
      id
      firstName
      lastName
      countryId
      country {
        id
        name
        iso2
        iso3
        worldPart
        code
        currencyId
        currency {
          id
          name
          code
          symbol
        }
      }
      zipCode
      state
      city
      address
      address2
    }
    shippingAddressId
    shippingAddress {
      id
      firstName
      lastName
      countryId
      country {
        id
        name
        iso2
        iso3
        worldPart
        code
        currencyId
        currency {
          id
          name
          code
          symbol
        }
      }
      zipCode
      state
      city
      address
      address2
    }
    saleSource
    customSaleSource
    status
    holdReasons
    orderItems {
      id
      productId
      productPriceId
      productTagId
      productTag {
        id
        name
      }
      storeId
      store {
        id
        name
      }
      customerComment
      asset {
        id
        name
        extension
        ownerType
        ownerId
        mimeType
        s3bucket
        s3key
        signedUrl
        createdAt
        updatedAt
      }
      displayName
      sku
      quantity
      price
      discount
      status
      sum
      shipping
      processing
      cost
      totalCost
      profit
      total
      createdAt
    }
    orderEvents {
      id
      event
      createdAt
    }
    orderTasks {
      id
      status
      taskType
      progress
      retryNumber
      message
      startDate
      startedAt
      endedAt
    }
    orderNotes {
      id
      note
      workspaceId
      userId
      author {
        id
        email
        avatar
        firstName
        lastName
        createdAt
      }
      createdAt
    }
    payments {
      id
      orderId
      systemType
      paymentMethod
      externalTransactionId
      status
      reason
      total
      comment
      bin
      cardNumber
      cardExpDate
    }
    total
    promocode
    promocodeDiscount
    totalCost
    comment
    invoiceId
    trackingNumber
    shippedAt
    createdAt
    updatedAt
  }
}
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type AddressInput = {
  countryId: number
  zipCode: string
  state: string
  city: string
  address: string
  address2?: string
}

type OrderItemInput = {
  id: number
  productId: number
  productPriceId: number
  displayName: string
  sku: string
  quantity: number
  price: number
  discount: number
  shipping: number
  processing: number
}

type OrderDataInput = {
  billingAddress: AddressInput
  shippingAddress: AddressInput
  orderItems: OrderItemInput[]
  shippingMethodId?: number | null
  comment: string
  customSaleSource?: string
}

type CreateInvoiceOrderArgs = {
  customerId?: number
  customerData?: CustomerInput
  orderData: OrderDataInput
  shippingPrice?: number
  processingPrice?: number
}

type CreateInvoiceOrder = (args: CreateInvoiceOrderArgs) => Promise<Order>

const createInvoiceOrder: CreateInvoiceOrder = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_INVOICE_ORDER,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createInvoiceOrder } = data
    if (!createInvoiceOrder?.id) {
      throw new Error("Can't create invoice order!")
    }

    return createInvoiceOrder
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type CreateInvoiceOrderEvents = {
  onSettled: (data?: Order) => void
}

const createInvoiceOrderEvents: CreateInvoiceOrderEvents = {
  onSettled: (data) => {
    if (data?.id) {
      queryClient.invalidateQueries(['order', data.id])
      queryClient.invalidateQueries(['ordersList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCreateInvoiceOrder = () => useMutation(createInvoiceOrder, createInvoiceOrderEvents)
