import { SettingData } from './types'

export const stripeSettingsData: SettingData[] = [
  {
    name: 'apiVersion',
    label: 'API Version',
    rules: [{ required: true, message: 'Please input API Version!' }]
  },
  {
    name: 'host',
    label: 'Host',
    rules: [{ required: true, message: 'Please input Host!' }]
  },
  {
    name: 'port',
    label: 'Port',
    rules: [{ required: true, message: 'Please input Port!' }]
  },
  {
    name: 'secretKey',
    label: 'Secret Key',
    rules: [{ required: true, message: 'Please input Secret Key!' }]
  },
  {
    name: 'publishableKey',
    label: 'Publishable Key',
    rules: [{ required: true, message: 'Please input Publishable Key!' }]
  }
]
