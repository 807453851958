import { PayPalSettingsDto } from '@merchx-v2/shared-types/dist/dto/paypalSettings/paypal-settings.dto'
import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type PayPalSettingsListSelector = (state: ReduxState) => PayPalSettingsDto[]
const getPayPalSettingsList: PayPalSettingsListSelector = (state) => {
  const result = [] as PayPalSettingsDto[]
  const pageIds = state.payPalSettings.pagination.pages[state.payPalSettings.pagination.currentPage] || []
  pageIds.forEach((pageId) => {
    result.push(state.payPalSettings.payPalSettings[pageId])
  })
  return result
}

export const payPalSettingsListSelector: PayPalSettingsListSelector = createSelector(getPayPalSettingsList, (list) => list)
