// TODO - remove becouse of legacy

import { isSettingValueValid } from 'utils/isSettingValueDefined'
import { Landing, StepState, WizardStepStatus } from '../../types'

const getAutorespondersStep: (landing: Landing, currentStep: number, step?: number) => StepState = (landing, currentStep, step = 4) => {
  let isValid = false
  let status = 'wait' as WizardStepStatus

  if (landing?.autoresponders.length > 0) {
    isValid = true
    landing?.autoresponders.forEach((autoresponder) => {
      autoresponder.settings.forEach((setting) => {
        if (setting.required) {
          isValid = isValid && isSettingValueValid(setting)
        }
      })
    })
  }

  if (isValid) {
    status = 'finish' as WizardStepStatus
  }

  if (currentStep === step) {
    status = 'process' as WizardStepStatus
  }

  if (currentStep !== step && !isValid) {
    status = 'error' as WizardStepStatus
  }

  return {
    isValid,
    status
  }
}

export { getAutorespondersStep }
