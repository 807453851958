import React, { useState, useEffect } from 'react'
import { Drawer, Input, Form, Button } from 'antd'
import notification from 'mrx-notification'
import { CopyOutlined, GlobalOutlined } from '@ant-design/icons'
import CopyToClipboard from 'react-copy-to-clipboard'

import { useUpdateDomain, useDomain } from '../../hooks'
import styles from './DomainDetails.module.scss'

export type Props = {
  domainId?: number
  onClose: () => void
}

const DomainDetails = (props: Props) => {
  const { onClose, domainId } = props

  const { data: domain } = useDomain(domainId)

  const [editMode, setEditMode] = useState(false)
  const [domainName, setDomainName] = useState(domain?.name || '')
  const [registrarLogin, setRegistrarLogin] = useState(domain?.registrarLogin || '')
  const [registrarPassword, setRegistrarPassword] = useState(domain?.registrarPassword || '')

  const [form] = Form.useForm()

  const updateDomain = useUpdateDomain()

  useEffect(() => {
    if (domain) {
      form.setFieldsValue({
        name: domain.name,
        registrar: domain.registrar,
        registrarLogin: domain.registrarLogin,
        registrarPassword: domain.registrarPassword
      })
      setRegistrarLogin(domain.registrarLogin)
      setRegistrarPassword(domain.registrarPassword)
    }
  }, [domain, form])

  const handleClose = () => {
    onClose()
    setEditMode(false)
  }

  const handleGoToDomain = () => {
    setDomainName(form.getFieldValue('domain'))
  }

  const handleOnChangeLogin = () => {
    setRegistrarLogin(form.getFieldValue('_registrarLogin'))
  }

  const handleOnChangePassword = () => {
    setRegistrarPassword(form.getFieldValue('_registrarPassword'))
  }

  const onCopyHandler = () => {
    notification.success({
      message: 'Copy to cliplboard!',
      duration: 4,
      description: `Information was copied to buffer successfully!`
    })
  }

  useEffect(() => {
    if (updateDomain.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Domain was updated successfully!'
      })
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDomain.isSuccess])

  const onFinishHandler = (values: any) => {
    updateDomain.mutate({ domainId, domainData: values })
  }

  return (
    <Drawer title='Domain Details' width='400' onClose={handleClose} visible={!!domainId} forceRender>
      <Form layout='vertical' hideRequiredMark form={form} onFinish={onFinishHandler} initialValues={{ name: domain?.name }}>
        <Form.Item label='Domain' className={styles.formItemContainer}>
          <div className={styles.formItem}>
            <Form.Item name='name' style={{ width: '100%' }}>
              <Input style={{ width: '100%' }} disabled={!editMode} addonBefore='https://' onChange={handleGoToDomain} />
            </Form.Item>
            <Button onClick={handleGoToDomain} href={`https://${domainName}`} target='_blank' icon={<GlobalOutlined />} />
          </div>
        </Form.Item>

        <Form.Item name='registrar' label='Registrar'>
          <Input style={{ width: '100%' }} disabled={!editMode} />
        </Form.Item>

        <Form.Item label='Registrar Login' className={styles.formItemContainer}>
          <div className={styles.formItem}>
            <Form.Item name='registrarLogin' style={{ width: '100%' }}>
              <Input style={{ width: '100%' }} disabled={!editMode} onChange={handleOnChangeLogin} />
            </Form.Item>
            <CopyToClipboard key='copy-to-clipboard' text={registrarLogin} onCopy={onCopyHandler}>
              <Button icon={<CopyOutlined />} />
            </CopyToClipboard>
          </div>
        </Form.Item>

        <Form.Item label='Registrar Password'>
          <div className={styles.formItem}>
            <Form.Item name='registrarPassword' style={{ width: '100%' }}>
              <Input.Password style={{ width: '100%' }} disabled={!editMode} onChange={handleOnChangePassword} />
            </Form.Item>
            <CopyToClipboard key='copy-to-clipboard' text={registrarPassword} onCopy={onCopyHandler}>
              <Button icon={<CopyOutlined />} />
            </CopyToClipboard>
          </div>
        </Form.Item>

        {editMode ? (
          <div className={styles.buttonsContainer}>
            <Button onClick={handleClose} className={styles.cancelButton}>
              Cancel
            </Button>
            <Button type='primary' htmlType='submit' loading={updateDomain.isLoading}>
              Update
            </Button>
          </div>
        ) : (
          <div className={styles.buttonsContainer}>
            <Button onClick={() => setEditMode(!editMode)} type='primary'>
              Edit
            </Button>
          </div>
        )}
      </Form>
    </Drawer>
  )
}

export default DomainDetails
