import React from 'react'
import moment from 'moment'
import { Timeline } from 'antd'
import styles from './EventsTab.module.scss'
import { CustomerSubscriptionEvent } from '../../types'

type Props = {
  events: CustomerSubscriptionEvent[]
}

const EventsTab = (props: Props) => {
  const { events } = props
  return (
    <Timeline mode='left' className={styles.timeline}>
      {events.map(item => (
        <Timeline.Item label={moment(item.createdAt).format('MM/DD/YYYY HH:mm:ss')} key={item.id}>
          {item.event}
        </Timeline.Item>
      ))}
    </Timeline>
  )
}

export default EventsTab
