import React, { useEffect, useState, memo } from 'react'
import { RouteComponentProps } from 'react-router'
import * as QueryString from 'query-string'
import { Typography, Layout, PageHeader, Tabs, Switch } from 'antd'
import * as rootStyles from 'assets/layoutStyle'
import { useStore } from '../../hooks'
import { Breadcrumbs } from 'components'
import styles from './EditStore.module.scss'
import StoreProducts from '../StoreProducts'
import StoreSettings from '../StoreSettings'
import StoreInvitesList from '../StoreInvitesList'
import StoreUsersList from '../StoreUsersList'
import StoreProfitsList from '../StoreProfitsList'
import StorePayoutsList from '../StorePayoutsList'
import StoreCostModificators from '../StoreCostModificators'

type ActiveTabs = 'settings' | 'products'

const EditStore: React.FC<RouteComponentProps<{ storeId: string }>> = ({ match, history, location }) => {
  const {
    params: { storeId }
  } = match

  const [isActive, setIsActive] = useState(false)
  const [activeTab, setActiveTab] = useState<ActiveTabs>(() => (QueryString.parse(location.search)?.tab as ActiveTabs) || 'settings')
  const store = useStore(+storeId)

  useEffect(() => {
    if (store.data) {
      setIsActive(store.data.isActive)
    }
  }, [store.dataUpdatedAt])

  useEffect(() => store.remove, [])

  const activeTabKeyChangeHandler = (value) => {
    history.replace(`${location.pathname}?tab=${value}`)
    setActiveTab(value)
  }

  return (
    <Layout style={rootStyles.root} className={styles.baseContainer}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={history.goBack}
          title='Edit store'
          extra={[
            <div className={styles.activeSwitch} key='1'>
              <Switch onChange={() => setIsActive((prev) => !prev)} checked={isActive} title='Active' />
              <Typography>Active</Typography>
            </div>
          ]}
        />
      </Layout.Header>
      <div style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Stores', url: '/stores' },
            { title: 'Stores Edit', url: `/stores/${storeId}/edit` }
          ]}
        />
      </div>
      <Layout.Content>
        <Tabs type='card' className={styles.tabs} activeKey={activeTab} onChange={activeTabKeyChangeHandler}>
          <Tabs.TabPane tab='Settings' key='settings'>
            <StoreSettings storeId={+storeId} isActive={isActive} />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Products' key='products'>
            <StoreProducts storeId={+storeId} />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Users' key='users'>
            <StoreUsersList storeId={+storeId} />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Invites' key='invites'>
            <StoreInvitesList storeId={+storeId} />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Balance' key='balance'>
            <StoreProfitsList storeId={+storeId} store={store.data} />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Payouts' key='payouts'>
            <StorePayoutsList storeId={+storeId} />
          </Tabs.TabPane>
          <Tabs.TabPane className={styles.tabPane} tab='Cost Modificators' key='costModificators'>
            <StoreCostModificators store={store.data} />
          </Tabs.TabPane>
        </Tabs>
      </Layout.Content>
    </Layout>
  )
}

export default memo(EditStore)
