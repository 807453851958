import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { CurrencyDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'removeCurrency', boolean>

const REMOVE_CURRENCY = `
  mutation removeCurrency ($currencyId: Int!) {
    removeCurrency(currencyId: $currencyId) 
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveCurrency = (currencyId: number) => Promise<number>

const removeCurrency: RemoveCurrency = async (currencyId) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_CURRENCY,
      variables: {
        currencyId
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeCurrency } = data
    if (!removeCurrency) {
      throw new Error("Can't remove currency!")
    }

    return currencyId
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveCurrencyContext = { prevCurrency?: CurrencyDto }

type RemoveCurrencyEvents = {
  onMutate: (currencyId: number) => Promise<RemoveCurrencyContext>
  onError: (error: string, currencyId: number, context: RemoveCurrencyContext) => void
  onSettled: (currencyId?: number) => void
}

const removeCurrencyEvents: RemoveCurrencyEvents = {
  onMutate: async (currencyId) => {
    await queryClient.cancelQueries(['currency', currencyId])

    // Snapshot the previous value
    const prevCurrency = queryClient.getQueryData<CurrencyDto>(['currency', currencyId])

    if (prevCurrency) {
      queryClient.removeQueries(['currency', currencyId])
    }

    return { prevCurrency }
  },
  onError: (_err, currencyId, context) => {
    if (context?.prevCurrency) {
      // Restore currrency on any error
      queryClient.setQueryData<CurrencyDto>(['currency', currencyId], context.prevCurrency)
    }
  },
  onSettled: (currencyId) => {
    if (currencyId) {
      queryClient.invalidateQueries(['currency', currencyId])
      queryClient.invalidateQueries(['currenciesList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useRemoveCurrency = () => useMutation(removeCurrency, removeCurrencyEvents)
