import React, { useState, useEffect, useRef } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Drawer, Input, Form } from 'antd'
import notification from 'mrx-notification'
import styles from './CreateInvite.module.scss'
import { connector, PropsFromRedux } from './container'

const CreateInvite: React.FC<PropsFromRedux> = props => {
  const [isVisible, setVisible] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const refEl = useRef<Input>(null)

  const { isLoading, workspaceId, inviteUser } = props

  useEffect(() => {
    if (isVisible) {
      refEl.current && refEl.current.focus()
    }
  }, [isVisible])

  const showDrawer = () => {
    setVisible(!isVisible)
  }

  const [form] = Form.useForm()

  const handleClose = () => {
    setVisible(false)
    form.resetFields()
  }

  const onFinishHandle = async (values: any) => {
    // TODO - fix any
    const userInvite = {
      email: values.email,
      password: values.password,
      firstName: values.firstName,
      lastName: values.lastName,
      message: message
    }
    const isSaved = await inviteUser(workspaceId, userInvite)
    if (isSaved) {
      notification.success({
        message: 'Successfully',
        description: 'Invite was created successfully!'
      })
      handleClose()
    }
  }

  const handleMessageChange = ({ target }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(target.value)
  }

  return (
    <>
      <Button type='primary' onClick={showDrawer}>
        <PlusOutlined />
        INVITE
      </Button>
      <Drawer title='Invite User' width='400' onClose={handleClose} visible={isVisible} forceRender>
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandle} form={form}>
          <Form.Item
            name='email'
            label='E-mail'
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!'
              },
              {
                required: true,
                message: 'Please input your E-mail!'
              }
            ]}
          >
            <Input ref={refEl} />
          </Form.Item>
          <Form.Item
            name='password'
            label='Password'
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please input your password!'
              }
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name='confirm'
            label='Confirm Password'
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!'
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }

                  return Promise.reject(new Error('The two passwords that you entered do not match!'))
                }
              })
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item name='firstName' label='First Name' rules={[{ required: true, message: 'Please input your First Name!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name='lastName' label='Last Name' rules={[{ required: true, message: 'Please input your Last Name!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name='message' label='Message'>
            <Input.TextArea placeholder='Please input your message here' allowClear onChange={handleMessageChange} />
          </Form.Item>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button onClick={handleClose} className={styles.createButton}>
              Close
            </Button>
            <Button type='primary' htmlType='submit' loading={isLoading}>
              Invite
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

export default connector(CreateInvite)
