import React, { useState, useEffect, useRef } from 'react'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { Button, Drawer, Input, Form, DatePicker, Typography } from 'antd'
import notification from 'mrx-notification'
import styles from './CreateAwsCertificate.module.scss'
import { PropsFromRedux, connector } from './container'

const CreateCountry = (props: PropsFromRedux) => {
  const [isVisible, setVisible] = useState(false)
  const refEl = useRef<Input>(null)

  const { isLoading, createAwsCertificate } = props

  useEffect(() => {
    if (isVisible) {
      refEl.current && refEl.current.focus()
    }
  }, [isVisible])

  const showDrawer = () => {
    setVisible(!isVisible)
  }

  const [form] = Form.useForm()

  const handleClose = () => {
    setVisible(false)
    form.resetFields()
  }

  const onFinishHandler = async (values: any) => {
    const isSaved = await createAwsCertificate({
      domainName: values.domainName,
      certificateArn: values.certificateArn,
      expiringAt: values.expiringAt,
      issuedAt: values.issuedAt ? values.issuedAt : null
    })
    if (isSaved) {
      notification.success({
        message: 'Successfully',
        description: 'AWS Certificate was created successfully!'
      })
      handleClose()
    }
  }

  return (
    <>
      <Button type='primary' onClick={showDrawer}>
        <PlusOutlined />
        CREATE
      </Button>
      <Drawer title='Create AWS Certificate' width='400' onClose={handleClose} visible={isVisible} forceRender>
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
          <Form.Item name='domainName' label='Domain Name' rules={[{ required: true, message: 'Please input domain name!' }]}>
            <Input style={{ width: '100%' }} ref={refEl} />
          </Form.Item>

          <Form.Item name='certificateArn' label='ARN' rules={[{ required: true, message: 'Please input ARN!' }]}>
            <Input style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item name='issuedAt' label='Issued At' rules={[{ required: false, message: 'Please input issued at' }]}>
            <DatePicker style={{ width: '100%' }} format='MM/DD/YYYY' />
          </Form.Item>

          <Form.Item name='expiringAt' label='Expiring At' rules={[{ required: true, message: 'Please input expiring at' }]}>
            <DatePicker style={{ width: '100%' }} format='MM/DD/YYYY' />
          </Form.Item>

          <Typography style={{ color: 'black', paddingBottom: '8px' }}>Alternative Names</Typography>
          <Form.List name='alternativeNames'>
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map(field => (
                    <Form.Item required={false} key={field.key}>
                      <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Please input alternative name or delete this field.'
                          }
                        ]}
                        noStyle
                      >
                        <Input placeholder='alternative name' style={{ width: '90%' }} />
                      </Form.Item>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className='dynamic-delete-button'
                          style={{ margin: '0 8px' }}
                          onClick={() => {
                            remove(field.name)
                          }}
                        />
                      ) : null}
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button
                      type='dashed'
                      onClick={() => {
                        add()
                      }}
                      style={{ width: '60%' }}
                    >
                      <PlusOutlined /> Add name
                    </Button>
                  </Form.Item>
                </div>
              )
            }}
          </Form.List>

          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button onClick={handleClose} className={styles.closeButton}>
              Close
            </Button>
            <Button type='primary' htmlType='submit' loading={isLoading}>
              Create
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

export default connector(CreateCountry)
