import produce from 'immer'
import moment from 'moment-timezone'
import * as t from './actionTypes'
import { actionTypes as nt } from 'features/navigation'
import { actionTypes as wt } from 'features/workspaces'
import { isSuccessAction, isErrorAction } from 'types'
import { ProcessingCostsState } from './types'
import createReducer from 'store/createReducer'
import {
  SaveProcessingCostAction,
  FetchProcessingCostsAction,
  ChangeCurrentPageAction,
  ChangeDatesRangeAction,
  RemoveProcessingCostAction
} from './actions'

const initState: ProcessingCostsState = {
  datesRange: [
    null,
    moment()
      .add(1, 'day')
      .endOf('day')
  ],
  processingCosts: {},
  pagination: {
    pages: {},
    total: 0,
    currentPage: 1,
    size: 10
  },
  UIState: {
    issaveProcessingCostLoading: false,
    isListLoading: false
  }
}

const reducer = createReducer(initState, {
  [t.SAVE_PROCESSING_COST]: (state, action: SaveProcessingCostAction) =>
    produce(state, draft => {
      draft.UIState.issaveProcessingCostLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.issaveProcessingCostLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { currentPage, pages } = draft.pagination
        if (!draft.processingCosts[action.payload.id]) {
          pages[currentPage] = pages[currentPage] || []
          pages[currentPage].splice(0, 0, action.payload.id)
        }
        draft.processingCosts[action.payload.id] = action.payload
      }
    }),

  [t.FETCH_PROCESSING_COSTS]: (state, action: FetchProcessingCostsAction) =>
    produce(state, draft => {
      draft.UIState.isListLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isListLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { page, total, items } = action.payload
        items.forEach(item => {
          draft.processingCosts[item.id] = item
        })
        draft.pagination.total = total
        draft.pagination.pages[page] = items.map(item => item.id)
      }
    }),

  [t.REMOVE_PROCESSING_COST]: (state, action: RemoveProcessingCostAction) =>
    produce(state, draft => {
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.pagination.pages[draft.pagination.currentPage] = draft.pagination.pages[draft.pagination.currentPage].filter(
          item => item !== action.payload
        )
      }
    }),

  [t.CHANGE_CURRENT_PAGE]: (state, action: ChangeCurrentPageAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.pagination.currentPage = action.payload
      }
    }),

  [t.CHANGE_DATES_RANGE]: (state, action: ChangeDatesRangeAction) =>
    produce(state, draft => {
      draft.datesRange = action.payload
      draft.pagination = initState.pagination
    }),

  [wt.SWITCH_WORKSPACE]: state =>
    produce(state, draft => {
      draft.pagination = initState.pagination
    }),

  [nt.LOCATION_CHANGE]: state =>
    produce(state, draft => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default reducer
