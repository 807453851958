import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { ShipstationSetting } from '../types'

type ShipstationSettingsListSelector = (state: ReduxState) => ShipstationSetting[]
const getShipstationSettingsList: ShipstationSettingsListSelector = state => {
  const result = [] as ShipstationSetting[]
  const pageIds = state.shipstationSettings.pagination.pages[state.shipstationSettings.pagination.currentPage] || []
  pageIds.forEach(pageId => {
    result.push(state.shipstationSettings.shipstationSettings[pageId])
  })
  return result
}

export const shipstationSettingsListSelector: ShipstationSettingsListSelector = createSelector(
  getShipstationSettingsList,
  list => list
)
