// TODO - remove becouse of legacy

import React, { useContext, useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import moment from 'moment-timezone'
import { CheckOutlined } from '@ant-design/icons'
import { PageHeader, DatePicker, Button, Select, Table, Layout } from 'antd'
import notification from 'mrx-notification'
import { GlobalContext } from 'appContexts'
import { ColumnsType } from 'antd/lib/table'
import { Breadcrumbs } from 'components'
import * as rootStyles from 'assets/layoutStyle'
import { useCampaignsOptions } from 'features/campaigns/hooks'
import styles from './AdSpendsReport.module.scss'
import { connector, PropsFromRedux } from './container'
import { AdSpendReportRow } from '../../types'
import getColumns from './getColumns'

const dateFormat = 'MM/DD/YYYY'

const AdSpendReport: React.FC<PropsFromRedux> = ({
  reportRows,
  isLoading,
  datesRange,
  error,
  productTags,

  changeDatesRange,
  fetchAdSpendReport,
  fetchProductTagsForOptions
}) => {
  const { workspaceId } = useContext(GlobalContext)

  const campaignsForOptions = useCampaignsOptions({ workspaceId })

  const { history } = useReactRouter()
  const [selectedCampaign, setSelectedCampaign] = useState<number[]>([])
  const [columns, setColumns] = useState<ColumnsType<AdSpendReportRow>>(getColumns([]))

  useEffect(() => {
    fetchProductTagsForOptions(undefined, 1024)
  }, [fetchProductTagsForOptions])

  useEffect(() => {
    productTags.length && setColumns(getColumns(productTags))
  }, [productTags, fetchProductTagsForOptions])

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Ad Spend report page error!',
        description: error
      })
    }
  }, [error])

  const handleSubmit = () => {
    fetchAdSpendReport(selectedCampaign)
  }

  const handleCampaignChange = (value: number[]) => {
    setSelectedCampaign(value)
  }

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title='Ad Spend Report'
          extra={[
            <Select<number[]>
              key='1'
              mode='multiple'
              className={styles.select}
              placeholder='Select a campaign'
              onChange={handleCampaignChange}
              allowClear
            >
              {campaignsForOptions.data &&
                campaignsForOptions.data?.map((item) => (
                  <Select.Option value={item.id} key={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
            </Select>,

            <DatePicker.RangePicker
              key='2'
              defaultValue={datesRange}
              format={dateFormat}
              ranges={{
                Today: [moment().startOf('day'), moment().add(1, 'day').endOf('day')],
                'Last 7 days': [moment().subtract(7, 'days'), moment().startOf('day')],
                'This Week': [moment().startOf('week'), moment().endOf('week')],
                'This Month': [moment().startOf('month'), moment().endOf('month')]
              }}
              onChange={changeDatesRange}
            />,
            <Button key='3' type='primary' icon={<CheckOutlined />} onClick={handleSubmit} loading={isLoading}>
              Go
            </Button>
          ]}
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Reporting', url: '/' },
            { title: 'Ad Spend Report', url: '/reporting/adspends/report' }
          ]}
        />
      </Layout.Content>
      <Layout.Content style={rootStyles.contentComponent}>
        <Table
          className={styles.tableContainer}
          columns={columns}
          dataSource={reportRows}
          bordered
          pagination={false}
          rowKey={(record) => record.campaign.id}
          loading={isLoading}
        />
      </Layout.Content>
    </Layout>
  )
}

export default connector(AdSpendReport)
