import React, { useEffect } from 'react'
import { Button, Drawer, Input, Form } from 'antd'
import notification from 'mrx-notification'
import styles from './UpdateStripeSetting.module.scss'
import { connector, PropsFromRedux, OwnProps } from './container'

type UpdateStripeSettingProps = PropsFromRedux & OwnProps
const UpdateStripeSetting = (props: UpdateStripeSettingProps) => {
  const {
    isLoading,
    stripeSettingId,
    stripeSetting,

    updateStripeSetting,
    onClose
  } = props

  const [form] = Form.useForm()

  useEffect(() => {
    stripeSetting && form.setFieldsValue(stripeSetting)
  }, [stripeSetting, form])

  const onFinishHandler = async (values: any) => {
    const isSaved = await updateStripeSetting({
      stripeSettingId,
      stripeSettingData: {
        name: values.name,
        apiVersion: values.apiVersion,
        host: values.host,
        port: values.port,
        secretKey: values.secretKey,
        publishableKey: values.publishableKey
      }
    })
    if (isSaved) {
      notification.success({
        message: 'Successfully',
        description: 'Stripe Setting was updated successfully!'
      })
      onClose()
    }
  }

  return (
    <Drawer title='Update Stripe Setting' width='400' onClose={onClose} visible={!!stripeSettingId} forceRender>
      <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
        <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input stripe setting name!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name='apiVersion' label='API Version' rules={[{ required: true, message: 'Please input API Version!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name='host' label='Host' rules={[{ required: true, message: 'Please input Host!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name='port' label='Port' rules={[{ required: true, message: 'Please input Port!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name='secretKey' label='Secret Key' rules={[{ required: true, message: 'Please input Secret Key!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name='publishableKey' label='Publishable Key' rules={[{ required: true, message: 'Please input Publishable key!' }]}>
          <Input />
        </Form.Item>
        <div className={styles.buttonsContainer}>
          <Button onClick={onClose} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default connector(UpdateStripeSetting)
