import React, { useState, useEffect, useRef } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Drawer, Input, Form } from 'antd'
import { Store } from 'antd/lib/form/interface'
import notification from 'mrx-notification'
import { useCreateCurrency } from '../../hooks'

import styles from './CreateCurrency.module.scss'

interface FormStore extends Store {
  name: string
  code: string
  symbol: string
}

const CreateCurrency = () => {
  const [isVisible, setVisible] = useState(false)
  const refEl = useRef<Input>(null)

  const createCurrency = useCreateCurrency()

  useEffect(() => {
    if (isVisible) {
      refEl.current && refEl.current.focus()
    }
  }, [isVisible])

  const showDrawer = () => {
    setVisible(!isVisible)
  }

  const [form] = Form.useForm()

  const handleClose = () => {
    setVisible(false)
    form.resetFields()
  }

  useEffect(() => {
    if (createCurrency.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Currency was created successfully!'
      })
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createCurrency.isSuccess])

  const onFinishHandler = async (values: FormStore) => {
    createCurrency.mutate(values)
  }

  return (
    <>
      <Button type='primary' onClick={showDrawer}>
        <PlusOutlined />
        CREATE
      </Button>
      <Drawer title='Create Currency' width='400' onClose={handleClose} visible={isVisible} forceRender>
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
          <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input currency name!' }]}>
            <Input style={{ width: '100%' }} ref={refEl} />
          </Form.Item>

          <Form.Item name='code' label='Code' rules={[{ required: true, message: 'Please input currency code!' }]}>
            <Input style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name='symbol' label='Symbol' rules={[{ required: true, message: 'Please input currency symbol!' }]}>
            <Input style={{ width: '100%' }} />
          </Form.Item>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button onClick={handleClose} className={styles.closeButton}>
              Close
            </Button>
            <Button type='primary' htmlType='submit' loading={createCurrency.isLoading}>
              Create
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

export default CreateCurrency
