import React from 'react'
import { Popconfirm } from 'antd'
import moment from 'moment-timezone'
import { ColumnsType, ColumnType } from 'antd/lib/table'
import { ProductTagForOptions } from 'features/productTags/types'
import { ProcessingCost } from '../../types'
import settings from 'config/settings'

const { costs: { processing: { accuracy } } } = settings

const dateFormat = 'MM/DD/YYYY HH:mm:ss'
export default (
  productTags: ProductTagForOptions[],
  doConfirm: (processingCost: ProcessingCost, e?: React.MouseEvent<HTMLElement>) => void
): ColumnsType<ProcessingCost> => {
  const columns: ColumnsType<ProcessingCost> = [
    {
      title: 'Date',
      render: (_value, record) => moment(record.date).format(dateFormat),
      key: 'date'
    },
    {
      title: 'Payment plugin',
      dataIndex: 'paymentPlugin'
    },
    {
      title: 'Total',
      render: (_value, record) =>
        `$${((record.rows.find(item => item.productTag === null)?.value || 0) / Math.pow(10, accuracy)).toFixed(accuracy)}`,
      align: 'right',
      key: 'total'
    },
    ...productTags.map<ColumnType<ProcessingCost>>(productTag => ({
      title: productTag.name,
      render: (_value, record) =>
        `$${((record.rows.find(item => item.productTag.id === productTag.id)?.value || 0) / Math.pow(10, accuracy)).toFixed(accuracy)}`,
      align: 'right',
      key: productTag.id
    }))
  ]

  columns.push({
    title: 'Action',
    dataIndex: '',
    key: 'x',
    render: (_value, record) => (
      <span>
        <Popconfirm
          title='Are you sure delete this labor cost?'
          onConfirm={e => doConfirm(record, e)}
          onCancel={e => e?.stopPropagation()}
          okText='Yes'
          cancelText='No'
        >
          <a
            href='/#'
            onClick={e => {
              e.stopPropagation()
            }}
          >
            Remove
          </a>
        </Popconfirm>
      </span>
    )
  })

  return columns
}
