import React, { useEffect } from 'react'
import { Button, Drawer, Input, Form } from 'antd'
import notification from 'mrx-notification'
import { useOrder, useUpdateOrderNote } from '../../hooks'
import { connector, PropsFromRedux } from './container'
import styles from './UpdateOrderNote.module.scss'

type Props = PropsFromRedux & {
  orderId?: number
  orderNoteId?: number
  onClose: () => void
  visible: boolean
}

const UpdateOrderNote = (props: Props) => {
  const { onClose, workspaceId, orderId, orderNoteId, visible } = props

  const order = useOrder(orderId)
  const updateOrderNote = useUpdateOrderNote()

  const [form] = Form.useForm()

  const orderNote = order.data.orderNotes.find(note => note.id === orderNoteId)

  useEffect(() => {
    if (orderNote) {
      form.setFieldsValue({
        note: orderNote.note
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderNote])

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  useEffect(() => {
    if (updateOrderNote.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Order Note was updated successfully!'
      })
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateOrderNote.isSuccess])

  if (!orderNote) {
    return null
  }

  // TODO - fix any type
  const onFinishHandler = async (values: any) => {
    updateOrderNote.mutate({
      orderNoteId,
      orderNoteData: {
        workspaceId,
        orderId,
        note: values.note
      }
    })
  }

  return (
    <Drawer title='Update Note' width='400' onClose={handleClose} visible={visible}>
      <Form
        layout='vertical'
        hideRequiredMark
        onFinish={onFinishHandler}
        form={form}
        initialValues={{
          note: orderNote?.note
        }}
      >
        <Form.Item name='note' label='Note' rules={[{ required: true, message: 'Please input order note!' }]}>
          <Input style={{ width: '100%' }} />
        </Form.Item>

        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right'
          }}
        >
          <Button onClick={handleClose} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit' loading={updateOrderNote.isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default connector(UpdateOrderNote)
