import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

export type RemovePostmarkSettingAction = FSA<undefined, number, string>
type RemovePostmarkSettingColor = (postmarkSettingId: number) => MrxThunk<RemovePostmarkSettingAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'removePostmarkSetting', boolean>

const REMOVE_POSTMARK_SETTING = `
  mutation removePostmarkSetting ($postmarkSettingId: Int!) {
    removePostmarkSetting(postmarkSettingId: $postmarkSettingId)
  }
`

const removePostmarkSetting: RemovePostmarkSettingColor = (postmarkSettingId) => async (dispatch) => {
  let isRemoved = false

  dispatch({
    type: t.REMOVE_POSTMARK_SETTING,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_POSTMARK_SETTING,
      variables: { postmarkSettingId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Empty response!')
    }

    const { removePostmarkSetting } = data
    if (!removePostmarkSetting) {
      throw new Error("Can't remove postmark setting!")
    }

    dispatch({
      type: t.REMOVE_POSTMARK_SETTING,
      payload: postmarkSettingId,
      meta: { done: true }
    })

    isRemoved = true
  } catch (err) {
    dispatch({
      type: t.REMOVE_POSTMARK_SETTING,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isRemoved
}

export { removePostmarkSetting }
