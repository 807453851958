import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type isLoadingKeyType = 'isUploadFile'
type IsLoadingSelector = (state: ReduxState, isLoadingKey: isLoadingKeyType) => boolean

const getIsLoadingSelectorByKey = (state: ReduxState, isLoadingKey: isLoadingKeyType) =>
  state.files.UIState[isLoadingKey] || false

export const isLoadingSelector: IsLoadingSelector = createSelector(getIsLoadingSelectorByKey, isLoading => isLoading)
