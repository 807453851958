import * as t from './actionTypes'
import produce from 'immer'
import { NavigationState } from './types'
import { ChangeLocationAction } from './actions'
import createReducer from 'store/createReducer'

const initState: NavigationState = {
  activeTab: ''
}

export default createReducer<NavigationState>(initState, {
  [t.LOCATION_CHANGE]: (state, action: ChangeLocationAction) =>
    produce(state, draft => {
      draft.activeTab = action.payload.location.pathname
    })
})
