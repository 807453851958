import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { OrderNote } from '../types'

type UpdateOrderNoteArgs = {
  orderNoteId: number
  orderNoteData: {
    workspaceId: number
    orderId: number
    note: string
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'updateOrderNote', UpdateOrderNoteArgs>

const UPDATE_ORDER_NOTE = `
  mutation updateOrderNote($orderNoteId: Int!, $orderNoteData: OrderNoteInput!) {
    updateOrderNote(orderNoteId: $orderNoteId, orderNoteData: $orderNoteData)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateOrderNote = (args: UpdateOrderNoteArgs) => Promise<UpdateOrderNoteArgs>

const updateOrderNote: UpdateOrderNote = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_ORDER_NOTE,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateOrderNote } = data
    if (!updateOrderNote) {
      throw new Error("Can't update order note!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateOrderNoteContext = { prevOrderNote?: OrderNote }

type UpdateOrderNoteEvents = {
  onMutate: (variables: UpdateOrderNoteArgs) => Promise<UpdateOrderNoteContext>
  onError: (error: string, variables: UpdateOrderNoteArgs, context: UpdateOrderNoteContext) => void
  onSettled: (data?: UpdateOrderNoteArgs) => void
}

const updateOrderNoteEvents: UpdateOrderNoteEvents = {
  onMutate: async (variables: UpdateOrderNoteArgs) => {
    await queryClient.cancelQueries(['orderNote', variables.orderNoteId])

    // Snapshot the previous value
    const prevOrderNote = queryClient.getQueryData<OrderNote>(['orderNote', variables.orderNoteId])

    // Optimistically update to the new values
    if (prevOrderNote) {
      queryClient.setQueryData<OrderNote>(['orderNote', variables.orderNoteId], {
        ...prevOrderNote,
        id: variables.orderNoteId,
        ...variables
      })
    }

    return { prevOrderNote }
  },
  onError: (_err, variables, context) => {
    if (context?.prevOrderNote) {
      // Restore previous version of order note on any error
      queryClient.setQueryData<OrderNote>(['orderNote', variables.orderNoteId], context.prevOrderNote)
    }
  },
  onSettled: (data) => {
    if (data?.orderNoteId) {
      queryClient.invalidateQueries(['orderNote', data.orderNoteId])
      queryClient.invalidateQueries(['order'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUpdateOrderNote = () => useMutation(updateOrderNote, updateOrderNoteEvents)
