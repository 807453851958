import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'resendInvoice', boolean>

const RESEND_INVOICE = `
  mutation ResendInvoice($orderId: Int!) {
    resendInvoice(orderId: $orderId)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type ResendInvoiceArgs = {
  orderId: number
}

type ResendInvoice = (args: ResendInvoiceArgs) => Promise<boolean>

const resendInvoice: ResendInvoice = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: RESEND_INVOICE,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { resendInvoice } = data
    if (!resendInvoice) {
      throw new Error("Can't resend invoice!")
    }

    return resendInvoice
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useResendInvoice = () => useMutation(resendInvoice)
