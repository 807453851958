import React, { useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import { PageHeader, Form, Layout, Button, Select, InputNumber, Switch, Input } from 'antd'
import notification from 'mrx-notification'
import { DeploymentPlugin, FulfillmentPlugin, MailingPlugin, PaymentPlugin, SmsPlugin } from '@merchx-v2/shared-types'
import { Breadcrumbs } from 'components'
import { connector, PropsFromRedux } from './container'

import * as rootStyles from 'assets/layoutStyle'
import styles from './WorkspaceDetails.module.scss'

import {
  DeploymentSettingsSelect,
  FulfillmentSettingsSelect,
  MailingSettingsSelect,
  PaymentSettingsSelect,
  SmsSettingsSelect
} from 'features/plugins/components'

import { Store } from 'antd/lib/form/interface'

interface FormStore extends Store {
  name: string
  deploymentPlugin: DeploymentPlugin
  deploymentSettingsId: number
  deploymentSettingsName?: string
  paymentPlugin: PaymentPlugin
  paymentSettingsId: number
  paymentSettingsName?: string
  fulfillmentPlugin: FulfillmentPlugin
  fulfillmentSettingsId: number
  fulfillmentSettingsName?: string
  mailingPlugin: MailingPlugin
  mailingSettingsId: number
  mailingSettingsName?: string
  smsPlugin: SmsPlugin
  smsSettingsId: number
  smsSettingsName?: string
  shippingCost: string
  processingCost: string
  applyProcessingOnce: boolean
  allowMultiplePayments: boolean
  autoArchiveCampaignsWithoutSales: boolean
  daysWithoutSalesToArchive: number
}

const emptyOption = {
  id: 0,
  name: 'Not set'
}

const WorkspaceDetails: React.FC<PropsFromRedux> = ({
  workspace,

  error,
  fetchWorkspace,
  updateWorkspace
}) => {
  const { history } = useReactRouter()

  useEffect(() => {
    fetchWorkspace(workspace.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [selectedPaymentPlugin, setSelectedPaymentPlugin] = useState<PaymentPlugin>()
  const [selectedPaymentSettings, setSelectedPaymentSettings] = useState<SelectOption>(emptyOption)
  const [selectedFulfillmentPlugin, setSelectedFulfillmentPlugin] = useState<FulfillmentPlugin>()
  const [selectedFulfillmentSettings, setSelectedFulfillmentSettings] = useState<SelectOption>(emptyOption)
  const [selectedMailingPlugin, setSelectedMailingPlugin] = useState<MailingPlugin>()
  const [selectedMailingSettings, setSelectedMailingSettings] = useState<SelectOption>(emptyOption)
  const [selectedDeploymentPlugin, setSelectedDeploymentPlugin] = useState<DeploymentPlugin>()
  const [selectedDeploymentSettings, setSelectedDeploymentSettings] = useState<SelectOption>(emptyOption)
  const [selectedSmsPlugin, setSelectedSmsPlugin] = useState<SmsPlugin>()
  const [selectedSmsSettings, setSelectedSmsSettings] = useState<SelectOption>(emptyOption)

  const [isChanged, setIsChanged] = useState(false)

  const [form] = Form.useForm()

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Workspace error!',
        description: error
      })
    }
  }, [error])

  useEffect(() => {
    if (workspace) {
      form.setFieldsValue({
        name: workspace.name,
        paymentPlugin: workspace.paymentPlugin,
        paymentSettingsId: workspace.paymentSettingsId,
        deploymentPlugin: workspace.deploymentPlugin,
        deploymentSettingsId: workspace.deploymentSettingsId,
        fulfillmentPlugin: workspace.fulfillmentPlugin,
        fulfillmentSettingsId: workspace.fulfillmentSettingsId,
        mailingPlugin: workspace.mailingPlugin,
        mailingSettingsId: workspace.mailingSettingsId,
        smsPlugin: workspace.smsPlugin,
        smsSettingsId: workspace.smsSettingsId,
        shippingCost: workspace.shippingCost / 100,
        processingCost: workspace.processingCost / 100,
        applyProcessingOnce: workspace.applyProcessingOnce,
        allowMultiplePayments: workspace.allowMultiplePayments,
        autoArchiveCampaignsWithoutSales: workspace.autoArchiveCampaignsWithoutSales,
        daysWithoutSalesToArchive: workspace.daysWithoutSalesToArchive
      })

      setSelectedPaymentPlugin(workspace.paymentPlugin)
      setSelectedPaymentSettings(
        workspace.paymentSettingsId
          ? {
              id: workspace.paymentSettingsId,
              name: workspace.paymentSettingsName
            }
          : emptyOption
      )

      setSelectedFulfillmentPlugin(workspace.fulfillmentPlugin)
      setSelectedFulfillmentSettings(
        workspace.fulfillmentSettingsId
          ? {
              id: workspace.fulfillmentSettingsId,
              name: workspace.fulfillmentSettingsName
            }
          : emptyOption
      )

      setSelectedMailingPlugin(workspace.mailingPlugin)
      setSelectedMailingSettings(
        workspace.mailingSettingsId
          ? {
              id: workspace.mailingSettingsId,
              name: workspace.mailingSettingsName
            }
          : emptyOption
      )

      setSelectedDeploymentPlugin(workspace.deploymentPlugin)
      setSelectedDeploymentSettings(
        workspace.deploymentSettingsId
          ? {
              id: workspace.deploymentSettingsId,
              name: workspace.deploymentSettingsName
            }
          : emptyOption
      )

      setSelectedSmsPlugin(workspace.smsPlugin)
      console.log({
        id: workspace.smsSettingsId,
        name: workspace.smsSettingsName
      })
      setSelectedSmsSettings(
        workspace.smsSettingsId
          ? {
              id: workspace.smsSettingsId,
              name: workspace.smsSettingsName
            }
          : emptyOption
      )
    }
  }, [form, workspace])

  const onFinishHandler = async (values: FormStore) => {
    const workspaceData = {
      ...values,

      shippingCost: Math.round(parseFloat(values.shippingCost) * 100),
      processingCost: Math.round(parseFloat(values.processingCost) * 100)
    }

    const isSaved = await updateWorkspace(workspaceData)
    if (isSaved) {
      setIsChanged(false)
      notification.success({
        message: 'Successfully',
        description: 'Workspace defaults was updated successfully!'
      })
    }
  }

  const onPaymentPluginValueChange = () => {
    setIsChanged(true)
    setSelectedPaymentPlugin(form.getFieldValue('paymentPlugin'))
    setSelectedPaymentSettings(emptyOption)
    form.setFieldsValue({ paymentSettingsId: null })
  }

  const onMailingPluginValueChange = () => {
    setIsChanged(true)
    setSelectedMailingPlugin(form.getFieldValue('mailingPlugin'))
    setSelectedMailingSettings(emptyOption)
    form.setFieldsValue({ mailingSettingsId: null })
  }

  const onFulfillmentPluginValueChange = () => {
    setIsChanged(true)
    setSelectedFulfillmentPlugin(form.getFieldValue('fulfillmentPlugin'))
    setSelectedFulfillmentSettings(emptyOption)
    form.setFieldsValue({ fulfillmentSettingsId: null })
  }

  const onDeploymentPluginValueChange = () => {
    setIsChanged(true)
    setSelectedDeploymentPlugin(form.getFieldValue('deploymentPlugin'))
    setSelectedDeploymentSettings(emptyOption)
    form.setFieldsValue({ deploymentSettingsId: null })
  }

  const onSmsPluginValueChange = () => {
    setIsChanged(true)
    setSelectedSmsPlugin(form.getFieldValue('smsPlugin'))
    setSelectedSmsSettings(emptyOption)
    form.setFieldsValue({ smsSettingsId: null })
  }

  return (
    <Form
      layout='vertical'
      hideRequiredMark
      form={form}
      onFinish={onFinishHandler}
      className={styles.form}
      initialValues={{
        name: workspace?.name,
        paymentPlugin: workspace?.paymentPlugin,
        paymentSettingsId: workspace?.paymentSettingsId,
        fulfillmentPlugin: workspace?.fulfillmentPlugin,
        fulfillmentSettingsId: workspace?.fulfillmentSettingsId,
        mailingPlugin: workspace?.mailingPlugin,
        mailingSettingsId: workspace?.mailingSettingsId,
        smsPlugin: workspace?.smsPlugin,
        smsSettingsId: workspace?.smsSettingsId,
        deploymentPlugin: workspace?.deploymentPlugin,
        deploymentSettingsId: workspace?.deploymentSettingsId,
        shippingCost: workspace?.shippingCost !== undefined ? workspace.shippingCost / 100 : 0,
        processingCost: workspace?.processingCost !== undefined ? workspace.processingCost / 100 : 0,
        applyProcessingOnce: workspace?.applyProcessingOnce !== undefined ? workspace.applyProcessingOnce : true,
        allowMultiplePayments: workspace?.allowMultiplePayments !== undefined ? workspace.allowMultiplePayments : true,
        autoArchiveCampaignsWithoutSales: workspace?.autoArchiveCampaignsWithoutSales,
        daysWithoutSalesToArchive: workspace?.daysWithoutSalesToArchive
      }}
    >
      <Layout style={rootStyles.root}>
        <Layout.Header style={rootStyles.header}>
          <PageHeader
            onBack={() => history.goBack()}
            title='Workspace Default Settings'
            extra={
              isChanged
                ? [
                    // eslint-disable-next-line react/jsx-indent
                    <Button key='1' type='primary' htmlType='submit'>
                      Save
                    </Button>
                  ]
                : []
            }
          />
        </Layout.Header>
        <Layout.Content style={rootStyles.contentBreadcrumbs}>
          <Breadcrumbs
            items={[
              { title: 'Main', url: '/' },
              { title: 'Settings', url: '/' },
              { title: 'Defaults', url: '/' }
            ]}
          />
        </Layout.Content>
        <Layout.Content className={styles.userListContainer}>
          <PageHeader title='Workspace Defaults' />
        </Layout.Content>
        <Layout.Content className={styles.contentComponent}>
          <Form.Item
            label='Name'
            name='name'
            rules={[
              {
                required: true,
                message: 'Please input workspace name!'
              }
            ]}
          >
            <Input onChange={() => setIsChanged(true)} />
          </Form.Item>
          <div className={styles.pluginContainer}>
            <Form.Item
              className={styles.plugin}
              name='paymentPlugin'
              label='Payment Plugin'
              rules={[
                {
                  required: true,
                  message: 'Please select Payment Plugin!'
                }
              ]}
            >
              <Select placeholder='Select Payments Plugin' onChange={onPaymentPluginValueChange}>
                <Select.Option value='AUTHORIZE'>Authorize</Select.Option>
                <Select.Option value='STRIPE'>Stripe</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              className={styles.plugin}
              name='paymentSettingsId'
              label='Payment Settings'
              rules={[
                {
                  required: true,
                  message: 'Please select Payment Settings!'
                }
              ]}
            >
              <PaymentSettingsSelect
                plugin={selectedPaymentPlugin}
                initialOption={selectedPaymentSettings}
                onSelect={(selectedOption: SelectOption) => {
                  setIsChanged(true)
                  form.setFieldsValue({
                    paymentSettingsId: selectedOption?.id
                  })
                  setSelectedPaymentSettings(selectedOption)
                }}
              />
            </Form.Item>
          </div>
          <div className={styles.pluginContainer}>
            <Form.Item
              className={styles.plugin}
              name='fulfillmentPlugin'
              label='Fulfillment Plugin'
              rules={[
                {
                  required: true,
                  message: 'Please select Fulfillment Plugin!'
                }
              ]}
            >
              <Select placeholder='Select Fulfillment Plugin' onChange={onFulfillmentPluginValueChange}>
                <Select.Option value='SHIPSTATION'>Shipstation</Select.Option>
                <Select.Option value='CUSTOM_GATEWAY'>Custom Gateway</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              className={styles.plugin}
              name='fulfillmentSettingsId'
              label='Fulfillment Settings'
              rules={[
                {
                  required: true,
                  message: 'Please select Fulfillment Settings!'
                }
              ]}
            >
              <FulfillmentSettingsSelect
                plugin={selectedFulfillmentPlugin}
                initialOption={selectedFulfillmentSettings}
                onSelect={(selectedOption: SelectOption) => {
                  setIsChanged(true)
                  form.setFieldsValue({
                    fulfillmentSettingsId: selectedOption?.id
                  })
                  setSelectedFulfillmentSettings(selectedOption)
                }}
              />
            </Form.Item>
          </div>
          <div className={styles.pluginContainer}>
            <Form.Item
              className={styles.plugin}
              name='mailingPlugin'
              label='Mailing Plugin'
              rules={[
                {
                  required: true,
                  message: 'Please select Mailing Plugin!'
                }
              ]}
            >
              <Select placeholder='Select Mailing Plugin' onChange={onMailingPluginValueChange}>
                <Select.Option value='POSTMARK'>Postmark</Select.Option>
                <Select.Option value='SENDGRID'>Sendgrid</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              className={styles.plugin}
              name='mailingSettingsId'
              label='Mailing Settings'
              rules={[
                {
                  required: true,
                  message: 'Please select Mailing Settings!'
                }
              ]}
            >
              <MailingSettingsSelect
                plugin={selectedMailingPlugin}
                initialOption={selectedMailingSettings}
                onSelect={(selectedOption: SelectOption) => {
                  setIsChanged(true)
                  form.setFieldsValue({
                    mailingSettingsId: selectedOption?.id
                  })
                  setSelectedMailingSettings(selectedOption)
                }}
              />
            </Form.Item>
          </div>

          <div className={styles.pluginContainer}>
            <Form.Item className={styles.plugin} name='smsPlugin' label='Sms Plugin'>
              <Select placeholder='Select Sms Plugin' onChange={onSmsPluginValueChange}>
                <Select.Option value='SIMPLE_TEXT'>Simple Text</Select.Option>
                <Select.Option value='ZIPWHIP'>Zipwhip</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item className={styles.plugin} name='smsSettingsId' label='Sms Settings'>
              <SmsSettingsSelect
                plugin={selectedSmsPlugin}
                initialOption={selectedSmsSettings}
                onSelect={(selectedOption: SelectOption) => {
                  setIsChanged(true)
                  form.setFieldsValue({
                    smsSettingsId: selectedOption?.id
                  })
                  setSelectedSmsSettings(selectedOption)
                }}
              />
            </Form.Item>
          </div>

          <div className={styles.pluginContainer}>
            <Form.Item
              className={styles.plugin}
              name='deploymentPlugin'
              label='Deployment Plugin'
              rules={[
                {
                  required: true,
                  message: 'Please select Deployment Plugin!'
                }
              ]}
            >
              <Select placeholder='Select Deployment Plugin' onChange={onDeploymentPluginValueChange}>
                <Select.Option value='AMAZON'>Amazon</Select.Option>
                <Select.Option value='NGINX'>Nginx</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              className={styles.plugin}
              name='deploymentSettingsId'
              label='Deployment Settings'
              rules={[
                {
                  required: true,
                  message: 'Please select Deployment Settings!'
                }
              ]}
            >
              <DeploymentSettingsSelect
                plugin={selectedDeploymentPlugin}
                initialOption={selectedDeploymentSettings}
                onSelect={(selectedOption: SelectOption) => {
                  setIsChanged(true)
                  form.setFieldsValue({
                    deploymentSettingsId: selectedOption?.id
                  })
                  setSelectedDeploymentSettings(selectedOption)
                }}
              />
            </Form.Item>
          </div>

          <div className={styles.group}>
            <Form.Item
              name='shippingCost'
              label='Shipping cost'
              rules={[
                {
                  required: true,
                  message: 'Please input shipping cost'
                }
              ]}
              className={styles.groupItem}
            >
              <InputNumber min={0.0} className={styles.inputField} onChange={() => setIsChanged(true)} />
            </Form.Item>

            <Form.Item
              name='processingCost'
              label='Processing cost'
              rules={[
                {
                  required: true,
                  message: 'Please input processing cost'
                }
              ]}
              className={styles.groupItem}
            >
              <InputNumber min={0.0} className={styles.inputField} onChange={() => setIsChanged(true)} />
            </Form.Item>
          </div>

          <div className={styles.group}>
            <Form.Item name='applyProcessingOnce' label='Apply processing once' valuePropName='checked' className={styles.groupItem}>
              <Switch onChange={() => setIsChanged(true)} />
            </Form.Item>

            <Form.Item name='allowMultiplePayments' label='Allow multiple payments' valuePropName='checked' className={styles.groupItem}>
              <Switch onChange={() => setIsChanged(true)} />
            </Form.Item>
          </div>

          <div className={styles.group}>
            <Form.Item
              name='autoArchiveCampaignsWithoutSales'
              label='Auto archive campaigns without sales'
              valuePropName='checked'
              className={styles.groupItem}
            >
              <Switch onChange={() => setIsChanged(true)} />
            </Form.Item>

            <Form.Item name='daysWithoutSalesToArchive' label='Days without sales to archive' className={styles.groupItem}>
              <InputNumber min={14} className={styles.inputField} onChange={() => setIsChanged(true)} />
            </Form.Item>
          </div>
        </Layout.Content>
      </Layout>
    </Form>
  )
}

export default connector(WorkspaceDetails)
