// TODO - remove becouse of legacy

import React, { useContext, useEffect, useState } from 'react'
import { Button, Card } from 'antd'
import notification from 'mrx-notification'
import { QuizzesSelect } from 'features/quizzes/components'
import { GlobalContext } from 'appContexts'
import { Landing } from '../../types'
import { useSetLandingQuiz } from '../../hooks/'
import styles from './LandingWizardQuiz.module.scss'

type PropsType = {
  landing: Landing
  nextStep?: number
  onNextButtonClick: (nextStep: number) => void
}

const LandingWizardQuiz = (props: PropsType) => {
  const {
    landing,
    nextStep = 3,

    onNextButtonClick
  } = props

  const { campaignId } = useContext(GlobalContext)

  const [selectedQuiz, setSelectedQuiz] = useState<SelectOption>()

  const setLandingQuiz = useSetLandingQuiz()

  useEffect(() => {
    if (landing.landingQuiz) {
      setSelectedQuiz({
        id: landing.landingQuiz.id,
        name: landing.landingQuiz.name
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landing])

  const handleGoToNext = async () => {
    if (selectedQuiz) {
      setLandingQuiz.mutate(
        { landingId: landing.id, quizId: selectedQuiz.id },
        {
          onSuccess: () => {
            notification.success({
              message: 'Successfully',
              description: 'Quiz was applied successfully!'
            })
            onNextButtonClick(nextStep)
          },
          onError: (error) => {
            notification.error({
              message: 'Set landing quiz error!',
              description: error.toString()
            })
          }
        }
      )
    }
  }

  return (
    <div className={styles.wizardDeployContainer}>
      <Card
        className={styles.card}
        title={<span className={styles.cardTitle}>Quiz</span>}
        extra={[
          <Button key='1' type='primary' onClick={handleGoToNext} loading={setLandingQuiz.isLoading}>
            NEXT
          </Button>
        ]}
      >
        <div className={styles.templatesContainer}>
          <div className={styles.leftContainer}>
            <QuizzesSelect
              campaignId={campaignId}
              disabled={setLandingQuiz.isLoading}
              selected={selectedQuiz?.id}
              initialOption={selectedQuiz}
              onSelect={setSelectedQuiz}
            />
          </div>
        </div>
      </Card>
    </div>
  )
}

export default LandingWizardQuiz
