import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type TotalSelector = (state: ReduxState, countryId: number) => number
const getTotalRegions: TotalSelector = (state, countryId) => {
  let total = 0
  if (countryId && Object.prototype.hasOwnProperty.call(state.regions.pagination, countryId)) {
    total = state.regions.pagination[countryId].total
  }
  return total
}

export const totalRegionsSelector: TotalSelector = createSelector(getTotalRegions, (total) => total)
