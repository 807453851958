import React, { useContext, useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import { Layout, PageHeader, Tabs, Button, Input, Select, Form, InputNumber } from 'antd'
import notification from 'mrx-notification'
import { PLATFORM_SALE_SOURCE } from '@merchx-v2/shared-types'
import { Breadcrumbs, Loading } from 'components'
import * as rootStyles from 'assets/layoutStyle'
import { Address } from 'features/addresses/types'
import { Order, OrderItem } from 'features/orders/types'
import { GlobalContext } from 'appContexts'
import { useCreateInvoiceOrder } from '../../hooks'
import { AddressTabManual } from '..'
import InvoiceItemsTabManual from '../InvoiceItemsTabManual'
import { connector } from './container'
import styles from './CreateInvoiceOrder.module.scss'
import { CustomersSelect } from 'features/customers/components'
import { CustomerInput } from 'features/customers/types'
import { ShippingMethodsSelect } from 'features/shippingMethods/components'

const CreateInvoiceOrder = () => {
  const createInvoiceOrder = useCreateInvoiceOrder()

  const { campaignId } = useContext(GlobalContext)

  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)
  const [orderItems, setOrderItems] = useState<OrderItem[]>([])
  const [shippingAddress, setShippingAddress] = useState<Address>({ countryId: 1 } as Address)
  const [shippingPrice, setShippingPrice] = useState(0)
  const [processingPrice, setProcessingPrice] = useState(0)
  const [comment, setComment] = useState<string>('')
  const [customSaleSource, setCustomSaleSource] = useState<string>('')
  const [selectedCampaignId, setSelectedCampaignId] = useState<number>()
  const [customerId, setCustomerId] = useState<number>()
  const [customerData, setCustomerData] = useState<CustomerInput>({} as CustomerInput)
  const [isNewCustomer, setIsNewCustomer] = useState(false)
  const [shippingMethodId, setShippingMethodId] = useState<number>()

  const { history } = useReactRouter()

  const [form] = Form.useForm()

  useEffect(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    setSelectedCampaignId(campaignId)
    if (!!sessionStorage.getItem('orderCampaignId') && !campaignId) {
      setSelectedCampaignId(+sessionStorage.getItem('orderCampaignId'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (createInvoiceOrder.error) {
      notification.error({
        message: 'Can not create invoice order!',
        description: createInvoiceOrder.error.toString()
      })
    }
  }, [createInvoiceOrder.error])

  useEffect(() => {
    if (createInvoiceOrder.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Invoice was created successfully!'
      })
    }
  }, [createInvoiceOrder.isSuccess])

  const handleOnClose = () => {
    setIsDrawerVisible(false)
  }
  const handleFormValueChanged = (changedValues) => {
    if (changedValues.email) {
      setCustomerData({ ...customerData, email: changedValues.email })
    }
    if (changedValues.firstName) {
      setCustomerData({ ...customerData, firstName: changedValues.firstName })
    }
    if (changedValues.lastName) {
      setCustomerData({ ...customerData, lastName: changedValues.lastName })
    }
    if (changedValues.phone) {
      setCustomerData({ ...customerData, phone: changedValues.phone })
    }
    if (changedValues.shippingPrice) {
      setShippingPrice(changedValues.shippingPrice)
    }
    if (changedValues.processingPrice) {
      setProcessingPrice(changedValues.processingPrice)
    }
    if (changedValues.customSaleSource) {
      setCustomSaleSource(changedValues.customSaleSource)
    }
  }

  const isCustomerValid = (args?: CustomerInput) => args?.email && args?.firstName && args?.lastName

  const handleSaveOrder = async () => {
    if (!customerId && !isCustomerValid(customerData)) {
      notification.error({
        message: 'Customer is not valid.',
        description: 'Please select customer or create a new one.'
      })
      return
    }

    const prepearedOrderItems = orderItems.map((item) => {
      return {
        id: item.id,
        productId: item.productId,
        productPriceId: item.productPriceId,
        displayName: item.displayName,
        sku: item.sku,
        quantity: item.quantity,
        price: item.price,
        discount: item.discount,
        shipping: item.shipping,
        processing: item.processing,
        description: item.description || ''
      }
    })

    createInvoiceOrder.mutate(
      {
        customerId: isNewCustomer ? undefined : customerId,
        customerData: isNewCustomer ? customerData : undefined,
        shippingPrice: Math.round(shippingPrice * 100),
        processingPrice: Math.round(processingPrice * 100),
        orderData: {
          billingAddress: {
            countryId: shippingAddress.countryId,
            zipCode: shippingAddress.zipCode,
            state: shippingAddress.state,
            city: shippingAddress.city,
            address: shippingAddress.address,
            address2: shippingAddress.address2
          },
          shippingAddress: {
            countryId: shippingAddress.countryId,
            zipCode: shippingAddress.zipCode,
            state: shippingAddress.state,
            city: shippingAddress.city,
            address: shippingAddress.address,
            address2: shippingAddress.address2
          },
          shippingMethodId,
          orderItems: prepearedOrderItems,
          comment,
          customSaleSource
        }
      },
      {
        onSettled: () => {
          form.resetFields()
          history.push('/sales/invoices')
        }
      }
    )
  }

  const handleOrderItemsChanged = (newOrderItems: OrderItem[]) => {
    setOrderItems(newOrderItems.map((item) => ({ ...item, status: 'ACTIVE' })))
  }

  const handleShippingAddressChanged = (newAddress: Address) => {
    setShippingAddress(newAddress)
  }

  return (
    <Layout style={rootStyles.root}>
      <PageHeader
        onBack={() => history.goBack()}
        title='Create Invoice'
        extra={[
          <Button key='save-button' type='primary' onClick={handleSaveOrder}>
            CREATE
          </Button>
        ]}
      />
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Sales', url: '/' },
            { title: 'Invoices', url: '/sales/invoices' },
            { title: 'Create Invoice', url: '/sales/invoices' }
          ]}
        />
      </Layout.Content>
      {createInvoiceOrder.isLoading && <Loading />}
      {!createInvoiceOrder.isLoading && (
        <div className={styles.contentContainer}>
          <Tabs
            type='card'
            className={styles.tabsContainer}
            tabBarExtraContent={
              <Button type='primary' onClick={() => setIsDrawerVisible(true)}>
                + ADD PRODUCT
              </Button>
            }
          >
            <Tabs.TabPane tab='General' key='1'>
              <Form
                layout='vertical'
                onValuesChange={handleFormValueChanged}
                form={form}
                className={styles.form}
                initialValues={{
                  shippingPrice,
                  processingPrice,
                  firstName: '',
                  lastName: '',
                  email: '',
                  phone: ''
                }}
              >
                <div className={styles.group}>
                  <Form.Item
                    name='shippingPrice'
                    label='Shipping Price'
                    rules={[{ required: true, message: 'Please input shipping price!' }]}
                    className={styles.groupItem}
                  >
                    <InputNumber min={0.0} precision={2} prefix='$' className={styles.inputField} />
                  </Form.Item>

                  <Form.Item
                    name='processingPrice'
                    label='Processing Price'
                    rules={[{ required: true, message: 'Please input processing price' }]}
                    className={styles.groupItem}
                  >
                    <InputNumber min={0.0} precision={2} prefix='$' className={styles.inputField} />
                  </Form.Item>
                </div>
                <div className={styles.group}>
                  <div className={styles.groupItem}>
                    <Form.Item label='Select shipping method'>
                      <ShippingMethodsSelect onSelect={selection => setShippingMethodId(selection.id)} />
                    </Form.Item>
                  </div>
                  <Form.Item
                    name='customSaleSource'
                    valuePropName={'select'}
                    label='Platform Source'
                    rules={[{ required: false, message: 'Please select Platform Source!' }]}
                    className={styles.groupItem}
                  >
                    <Select style={{ width: '100%' }} placeholder='Select platform sale source'>
                      {Object.keys(PLATFORM_SALE_SOURCE).map(item => (
                        <Select.Option key={item}>{item}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className={styles.group}>
                  <div className={styles.groupItem}>
                    <Form.Item name='customerId' label='Customer' className={styles.inputField}>
                      <CustomersSelect
                        disabled={isNewCustomer}
                        initialOption={{
                          id: 0,
                          name: 'Select Customer'
                        }}
                        allowClear
                        onSelect={(value) => setCustomerId(value.id)}
                      />
                    </Form.Item>

                    <Button onClick={() => setIsNewCustomer(!isNewCustomer)} className={styles.advancedButton}>
                      {isNewCustomer ? 'Select Customer' : 'Create Customer'}
                    </Button>

                    <Form.Item
                      hidden={!isNewCustomer}
                      name='firstName'
                      label='First Name'
                      rules={[{ required: !customerId, message: 'Please input first name!' }]}
                    >
                      <Input style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item
                      hidden={!isNewCustomer}
                      name='lastName'
                      label='last Name'
                      rules={[{ required: !customerId, message: 'Please input last name!' }]}
                    >
                      <Input style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item
                      hidden={!isNewCustomer}
                      name='email'
                      label='Email'
                      rules={[{ required: !customerId, message: 'Please input email!' }]}
                    >
                      <Input style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item hidden={!isNewCustomer} name='phone' label='Phone'>
                      <Input style={{ width: '100%' }} />
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </Tabs.TabPane>
            <Tabs.TabPane tab='Products' key='2'>
              <InvoiceItemsTabManual
                order={{ orderItems } as Order}
                campaignId={selectedCampaignId}
                visible={isDrawerVisible}
                onOrderItemsChanged={handleOrderItemsChanged}
                onClose={handleOnClose}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Shipping Address' key='3'>
              <AddressTabManual sourceAddress={shippingAddress} onAddressChanged={handleShippingAddressChanged} />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Notes' key='4'>
              <Input.TextArea allowClear value={comment} onChange={(e) => setComment(e.target.value)} placeholder='Type some notes here' />
            </Tabs.TabPane>
          </Tabs>
        </div>
      )}
    </Layout>
  )
}

export default connector(CreateInvoiceOrder)
