// TODO - remove becouse of legacy

import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Template } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'removeTemplate', boolean>

const REMOVE_TEMPLATE = `
  mutation removeTemplate($templateId: Int!) {
    removeTemplate(templateId: $templateId)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveTemplate = (templateId: number) => Promise<number>

const removeTemplate: RemoveTemplate = async (templateId) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_TEMPLATE,
      variables: {
        templateId
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeTemplate } = data
    if (!removeTemplate) {
      throw new Error("Can't remove template!")
    }

    return templateId
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveTemplateContext = { prevTemplate?: Template }

type RemoveTemplateEvents = {
  onMutate: (templateId: number) => Promise<RemoveTemplateContext>
  onError: (error: string, templateId: number, context: RemoveTemplateContext) => void
  onSettled: (templateId?: number) => void
}

const removeTemplateEvents: RemoveTemplateEvents = {
  onMutate: async (templateId) => {
    await queryClient.cancelQueries(['template', templateId])

    // Snapshot the previous value
    const prevTemplate = queryClient.getQueryData<Template>(['template', templateId])

    if (prevTemplate) {
      queryClient.removeQueries(['template', templateId])
    }

    return { prevTemplate }
  },
  onError: (_err, templateId, context) => {
    if (context?.prevTemplate) {
      // Restore template on any error
      queryClient.setQueryData<Template>(['template', templateId], context.prevTemplate)
    }
  },
  onSettled: (templateId: number) => {
    if (templateId) {
      queryClient.invalidateQueries(['template', templateId])
      queryClient.invalidateQueries(['templatesList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useRemoveTemplate = () => useMutation(removeTemplate, removeTemplateEvents)
