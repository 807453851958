import { createSelector, ParametricSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type isLoadingKeyType =
  | 'isCreateProductSize'
  | 'isFetchProductSize'
  | 'isListLoading'
  | 'isRemoveProductSize'
  | 'isUpdateProductSize'
  | 'isProductSizesForOptionsLoaded'

const getIsLoadingSelectorByKey: ParametricSelector<ReduxState, isLoadingKeyType, boolean> = (state, isLoadingKey) =>
  state.productSizes.UIState[isLoadingKey] || false

export const isLoadingSelector = createSelector(getIsLoadingSelectorByKey, isLoading => isLoading)
