import React, { useCallback, useEffect, useState } from 'react'
import { Button, Form, Input, Switch } from 'antd'
import { CloseOutlined, CheckOutlined } from '@ant-design/icons'
import { SiteType } from '@merchx-v2/shared-types'
import notification from 'mrx-notification'
import { CodeEditor, Loading } from 'components'
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { useUpdatePage } from '../../hooks'
import styles from './PageDetails.module.scss'
import SettingsList from 'features/settings/components/SettingsList'
import * as settingsFeature from 'features/settings'
import { Landing } from 'features/landings/types'
import { Template } from 'features/templates/types'

const { SettingsForLanding } = settingsFeature.components

type PropsType = {
  ownerType: SiteType
  ownerId: number
  pageId: number
  source: Landing | Template
}

const PageDetails = (props: PropsType) => {
  const { ownerType, pageId, source } = props

  const page = source.pages.find((item) => item.id === pageId)

  const [nameAttribute, setNameAttribute] = useState<string>('')
  const [routeAttribute, setRouteAttribute] = useState<string>('')
  const [goalNameAttribute, setGoalNameAttribute] = useState<string>('')
  const [canBeSkippedAttribute, setCanBeSkippedAttribute] = useState<boolean>(page?.canBeSkippedOnBuild || false)

  const [sourceCode, setSourceCode] = useState<string>('')
  const [sourceStyle, setSourceStyle] = useState<string>('')
  const [actionsCode, setActionsCode] = useState<string>('')
  const [reducerCode, setReducerCode] = useState<string>('')

  const updatePage = useUpdatePage()

  const resetState = useCallback(() => {
    setSourceCode(page?.sourceCode || '')
    setSourceStyle(page?.sourceStyle || '')
    setActionsCode(page?.actionsCode || '')
    setReducerCode(page?.reducerCode || '')
  }, [page])

  useEffect(() => {
    if (page) {
      resetState()
    }
  }, [page, resetState])

  const [form] = Form.useForm()

  useEffect(() => {
    if (page) {
      form.setFieldsValue({
        name: page.name,
        route: page.route,
        canBeSkippedOnBuild: page.canBeSkippedOnBuild,
        goalName: page.goalName
      })
      setNameAttribute(page.name || '')
      setRouteAttribute(page.route || '')
      setCanBeSkippedAttribute(page.canBeSkippedOnBuild)
      setGoalNameAttribute(page.goalName || '')
    }
  }, [page, form])

  if (!page) {
    return <Loading />
  }

  const handleCancel = () => {
    resetState()
  }

  // TODO - fix any type
  const onValuesChangeHandler = (changedValues: any) => {
    if (Object.prototype.hasOwnProperty.call(changedValues, 'name')) {
      setNameAttribute(changedValues.name || '')
    }
    if (Object.prototype.hasOwnProperty.call(changedValues, 'route')) {
      setRouteAttribute(changedValues.route || '')
    }
    if (Object.prototype.hasOwnProperty.call(changedValues, 'goalName')) {
      setGoalNameAttribute(changedValues.goalName || '')
    }

    setCanBeSkippedAttribute(changedValues.canBeSkippedOnBuild)
  }

  const handleSubmit = async () => {
    updatePage.mutate(
      {
        ownerType,
        ownerId: pageId,
        pageId,
        pageData: {
          name: nameAttribute,
          route: routeAttribute,
          canBeSkippedOnBuild: canBeSkippedAttribute,
          order: page.order,
          sourceCode,
          sourceStyle,
          actionsCode,
          reducerCode,
          goalName: goalNameAttribute
        }
      },
      {
        onSuccess: () => {
          notification.success({
            message: 'Successfully',
            description: 'Page was updated successfully!'
          })
        },
        onError: (error) => {
          notification.error({
            message: 'Error!',
            description: error.toString()
          })
        }
      }
    )
  }

  const disableSaveButton =
    page?.sourceCode === sourceCode &&
    page.sourceStyle === sourceStyle &&
    page?.actionsCode === actionsCode &&
    page?.reducerCode === reducerCode

  return (
    <div className={styles.root}>
      <Tabs className={styles.tabs}>
        <div className={styles.buttonsContainer}>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            disabled={disableSaveButton}
            loading={updatePage.isLoading}
            type='primary'
            className={styles.createButton}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
        <TabList>
          <Tab>HTML</Tab>
          <Tab>CSS</Tab>
          <Tab>Actions (Redux)</Tab>
          <Tab>Reducer (Redux)</Tab>
          <Tab>Settings</Tab>
          <Tab>Attributes</Tab>
        </TabList>
        <TabPanel className={styles.tabPanel}>
          <div className={styles.htmlTab}>
            <CodeEditor value={sourceCode} onChange={setSourceCode} />
          </div>
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          <div className={styles.cssTab}>
            <CodeEditor value={sourceStyle} isCss onChange={setSourceStyle} />
          </div>
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          <div className={styles.htmlTab}>
            <CodeEditor value={actionsCode} onChange={setActionsCode} />
          </div>
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          <div className={styles.htmlTab}>
            <CodeEditor value={reducerCode} onChange={setReducerCode} />
          </div>
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          <div className={styles.settingsTab}>
            {ownerType === 'LANDING' && source && <SettingsForLanding ownerType='PAGE' ownerId={pageId} landing={source as Landing} />}
            {ownerType === 'TEMPLATE' && <SettingsList ownerType='PAGE' ownerId={+pageId} settings={page?.settings} />}
          </div>
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          <div>
            <Form
              layout='vertical'
              hideRequiredMark
              onFinish={handleSubmit}
              form={form}
              onValuesChange={onValuesChangeHandler}
              initialValues={{
                name: page?.name,
                route: page?.route,
                canBeSkippedOnBuild: page?.canBeSkippedOnBuild || false,
                goalName: page?.goalName
              }}
            >
              <Form.Item
                name='name'
                label='Name'
                rules={[
                  {
                    required: true,
                    message: 'Please input template page name!'
                  }
                ]}
              >
                <Input type='text' />
              </Form.Item>
              <Form.Item
                name='route'
                label='Route'
                rules={[
                  {
                    required: true,
                    message: 'Please input template page route!'
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item name='goalName' label='Funnel goal name'>
                <Input type='text' />
              </Form.Item>
              <Form.Item
                name='canBeSkippedOnBuild'
                label='Can be skipped on bulid'
                valuePropName='checked'
                rules={[{ required: true, message: 'Please select a value!' }]}
              >
                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} checked={canBeSkippedAttribute} />
              </Form.Item>
            </Form>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default PageDetails
