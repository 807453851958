import React, { useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import moment from 'moment'
import { Table, Layout, PageHeader, Tag, Select, Typography, Modal, Input } from 'antd'
import notification from 'mrx-notification'
import { LogLevel } from '@merchx-v2/shared-types'
import { Breadcrumbs } from 'components'
import useHeightResize from 'utils/useHeightResize'
import * as rootStyles from 'assets/layoutStyle'
import styles from './AlertsList.module.scss'
import { connector, PropsFromRedux } from './container'
import { Alert } from '../../types'

const tagColors = {
  WARN: 'orange',
  INFO: 'blue',
  ERROR: 'red'
}

type HeightResizeRule = [number, number, number]
const rules: HeightResizeRule[] = [
  [0, 800, 8],
  [800, Infinity, 10]
]

const AlertsList = (props: PropsFromRedux) => {
  const {
    alerts,
    currentPage,
    error,
    isLoading,
    total,
    pageSize,

    changeCurrentPage,
    changePageSize,
    fetchAlertsList,
    isNeedToFetchList
  } = props
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState<Alert>()
  const [selectedLevel, setSelectedLevel] = useState<LogLevel>()
  const [searchQuery, setSearchQuery] = useState<string>()
  const { history } = useReactRouter()

  const handleChangePageSize = (newSize: number) => {
    changePageSize(newSize)
    fetchAlertsList(selectedLevel, searchQuery)
  }

  useHeightResize(handleChangePageSize, rules)

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Alerts list page error!',
        description: error
      })
    }
  }, [error])

  useEffect(() => {
    fetchAlertsList(selectedLevel, searchQuery)
  }, [fetchAlertsList, selectedLevel, searchQuery, pageSize, isNeedToFetchList])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Date',
      render: (record) => moment(record.createdAt).format('MM/DD/YYYY HH:mm:ss'),
      key: 'date'
    },
    {
      title: 'Level',
      render: (record: Alert) => {
        // @ts-ignore
        return <Tag color={tagColors[record.level]}>{record.level}</Tag>
      },
      key: 'level'
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source'
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message'
    }
  ]

  const onLevelSelectChange = (value: LogLevel) => {
    setSelectedLevel(value)
  }

  const handleOnRow = (record: Alert) => {
    return {
      onClick: () => {
        setIsModalVisible(true)
        setSelectedRecord(record)
      }
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title='Alerts Log'
          extra={[
            <Input.Search
              key='1'
              placeholder='Input search text'
              onSearch={(value) => setSearchQuery(value)}
              enterButton='Search'
              style={{ width: 200 }}
              allowClear
            />,
            <Typography.Text key='10'>Level:</Typography.Text>,
            <Select key='11' placeholder='Select Level' allowClear style={{ width: 200 }} onChange={onLevelSelectChange}>
              <Select.Option key='12' value='ERROR'>
                Error
              </Select.Option>
              <Select.Option key='13' value='INFO'>
                Info
              </Select.Option>
              <Select.Option key='14' value='WARN'>
                Warning
              </Select.Option>
            </Select>
          ]}
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Reporting', url: '/' },
            { title: 'Alerts Log', url: '/' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <Table
          columns={columns}
          dataSource={alerts}
          bordered
          rowKey={(record) => record.id}
          onRow={handleOnRow}
          loading={isLoading}
          pagination={{
            current: currentPage,
            total,
            onChange: changeCurrentPage,
            pageSize
          }}
        />
      </Layout.Content>

      <Modal title={selectedRecord?.source} visible={isModalVisible} footer={null} onCancel={handleCancel}>
        <p>Message: {selectedRecord?.message}</p>
      </Modal>
    </Layout>
  )
}

export default connector(AlertsList)
