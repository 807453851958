import React, { useEffect, useState } from 'react'
import hash from 'object-hash'
import { Card, Button } from 'antd'
import notification from 'mrx-notification'
import SubscriptionProductPriceInput from '../SubscriptionProductPriceInput'
import { PropsFromRedux, connector } from './container'
import styles from './SubscriptionPriceWizard.module.scss'
import CreateSubscriptionPrice from '../CreateSubscriptionPrice'
import UpdateSubscriptionPrice from '../UpdateSubscriptionPrice'
import { SubscriptionIntervalEnum } from '../../types'
import { SubscriptionItem } from 'features/types'

const SubscriptionPriceWizard = React.memo((props: PropsFromRedux) => {
  const {
    campaignId,
    product,
    productIndex,

    saveSubscriptionData,
    updateProductSubscriptions
  } = props

  const [selectedInterval, setSelectedInterval] = useState<SubscriptionIntervalEnum>('MONTH')
  const [selectedRepeatFrequency, setSelectedRepeatFrequency] = useState<number>(1)
  const [selectedPrice, setSelectedPrice] = useState<number>(5)
  const [selectedDiscount, setSelectedDiscount] = useState<number>(0)
  const [selectedMemberPrice, setSelectedMemberPrice] = useState<number>(5)
  const [dataSource, setDataSource] = useState<SubscriptionItem[]>([])
  const [isUpdateDrawerVisible, setIsUpdateDrawerVisible] = useState<boolean>(false)
  const [selectedSubscriptionRecord, setSelectedSubscriptionRecord] = useState<SubscriptionItem>()

  useEffect(() => {
    if (product?.prices?.length) {
      const price = product.prices[0]
      const interval = price.attributes.find((item) => item.attribute === 'SUBSCRIPTION_INTERVAL')
      if (interval) {
        setSelectedInterval(interval.value.toUpperCase() as SubscriptionIntervalEnum)
      }

      const frequency = price.attributes.find((item) => item.attribute === 'SUBSCRIPTION_REPEAT_FREQUENCY')
      if (frequency) {
        setSelectedRepeatFrequency(parseInt(frequency.value))
      }

      setSelectedPrice((price.price || 0) / 100)
      setSelectedMemberPrice((price.memberPrice || 0) / 100)
      setSelectedDiscount((price.discount || 0) / 100)
      setDataSource(
        product?.subscriptionItems?.map<SubscriptionItem>((item) => ({
          date: item.date,
          productId: product.id,
          product: { id: item.product?.id, name: item.product?.name },
          quantity: parseInt(item.quantity + '')
        }))
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(product)])

  const handleRemoveSubscriptionItemConfirmed = async (subscriptionId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    const subscriptionItems = dataSource.filter((item) => item.product.id !== subscriptionId)
    updateProductSubscriptions({ campaignId, productId: product.id, subscriptionItems, index: productIndex })
    setDataSource(subscriptionItems)
  }

  const handleSaveSubscriptionData = async () => {
    const isSaved = await saveSubscriptionData({
      campaignId: campaignId,
      productId: product.id,
      subscriptionData: {
        interval: selectedInterval,
        repeatFrequency: selectedRepeatFrequency,
        price: parseInt((selectedPrice * 100).toFixed(0)) || 0,
        discount: parseInt((selectedDiscount * 100).toFixed(0)) || 0,
        memberPrice: parseInt((selectedMemberPrice * 100).toFixed(0)) || 0,
        items: dataSource.map((item) => ({
          date: item.date,
          productId: item.product.id,
          quantity: parseInt(item.quantity + '')
        }))
      }
    })

    if (isSaved) {
      notification.success({
        message: 'Successfully',
        description: 'Subscription data was saved successfully!'
      })
    }
  }

  const handleCreateSubscription = (newValues) => {
    updateProductSubscriptions({ index: productIndex, campaignId, productId: product.id, subscriptionItems: [...dataSource, newValues] })
    setDataSource([...dataSource, newValues])
  }

  const handleUpdateSubscription = (newValues) => {
    const newData = [...dataSource]
    const index = newData.indexOf(selectedSubscriptionRecord)
    newData[index] = newValues
    updateProductSubscriptions({ index: productIndex, campaignId, productId: product.id, subscriptionItems: newData })
    setDataSource(newData)
  }

  const handleIntervalChanged = (value: SubscriptionIntervalEnum) => {
    setSelectedInterval(value)
  }

  const handleRepeatFrequencyChanged = (value: number) => {
    setSelectedRepeatFrequency(value)
  }

  const handlePriceChanged = (value: number) => {
    setSelectedPrice(value)
  }

  const handleMemberPriceChanged = (value: number) => {
    setSelectedMemberPrice(value)
  }

  const handleDiscountChanged = (value: number) => {
    setSelectedDiscount(value)
  }

  const handleOnClose = () => {
    setIsUpdateDrawerVisible(false)
  }

  return (
    <div className={styles.tableContainer}>
      <Card
        title={<span className={styles.cardTitle}>{['SUBSCRIPTION'].includes(product?.productType2) && 'Subscription Price'}</span>}
        extra={[
          <Button key='1' className={styles.saveButton} onClick={handleSaveSubscriptionData}>
            SAVE
          </Button>,
          <CreateSubscriptionPrice key='2' handleCreateSubscription={handleCreateSubscription} />
        ]}
      >
        <SubscriptionProductPriceInput
          price={selectedPrice}
          discount={selectedDiscount}
          memberPrice={selectedMemberPrice}
          interval={selectedInterval}
          repeatFrequency={selectedRepeatFrequency}
          dataSource={dataSource}
          onPriceChanged={handlePriceChanged}
          onDiscountChanged={handleDiscountChanged}
          onMemberPriceChanged={handleMemberPriceChanged}
          onIntervalChanged={handleIntervalChanged}
          onRepeatFrequencyChanged={handleRepeatFrequencyChanged}
          onDataSourceRemoved={handleRemoveSubscriptionItemConfirmed}
          setIsUpdateDrawerVisible={setIsUpdateDrawerVisible}
          setSelectedSubscriptionRecord={setSelectedSubscriptionRecord}
        />
      </Card>
      <UpdateSubscriptionPrice
        onClose={handleOnClose}
        visible={isUpdateDrawerVisible}
        subscriptionRecord={selectedSubscriptionRecord}
        handleUpdateSubscription={handleUpdateSubscription}
      />
    </div>
  )
})

export default connector(SubscriptionPriceWizard)
