import * as t from './actionTypes'
import { actionTypes as wt } from 'features/workspaces'
import { actionTypes as nt } from 'features/navigation'
import produce from 'immer'
import { isSuccessAction, isErrorAction } from 'types'
import { ZipwhipSettingsState } from './types'
import createReducer from 'store/createReducer'
import { addItemToPagination } from 'utils/addItemToPagination'
import { removeItemFromPagination } from 'utils/removeItemFromPagination'
import {
  ChangeCurrentPageAction,
  CreateZipwhipSettingAction,
  FetchZipwhipSettingsListAction,
  FetchZipwhipSettingsForOptionsAction,
  RemoveZipwhipSettingAction,
  UpdateZipwhipSettingAction
} from './actions'

const initState: ZipwhipSettingsState = {
  zipwhipSettings: {},
  zipwhipSettingsForOptions: [],
  pagination: {
    currentPage: 1,
    total: 0,
    pages: {},
    size: 10
  },
  UIState: {
    isCreateZipwhipSetting: false,
    isListLoading: false,
    isRemoveZipwhipSetting: false,
    isZipwhipSettingsForOptionsLoading: false,
    isUpdateZipwhipSetting: false
  }
}

const reducer = createReducer(initState, {
  [t.CREATE_ZIPWHIP_SETTING]: (state, action: CreateZipwhipSettingAction) =>
    produce(state, draft => {
      draft.UIState.isCreateZipwhipSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isCreateZipwhipSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { id } = action.payload
        draft.zipwhipSettings[id] = action.payload
        addItemToPagination(draft.pagination, id)
      }
    }),

  [t.FETCH_ZIPWHIP_SETTINGS_LIST]: (state, action: FetchZipwhipSettingsListAction) =>
    produce(state, draft => {
      draft.UIState.isListLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isListLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        action.payload.items.forEach(item => {
          draft.zipwhipSettings[item.id] = item
        })
        const { page, total, items } = action.payload
        draft.pagination.total = total
        draft.pagination.pages[page] = items.map(item => item.id)
      }
    }),

  [t.FETCH_ZIPWHIP_SETTINGS_FOR_OPTIONS]: (state, action: FetchZipwhipSettingsForOptionsAction) =>
    produce(state, draft => {
      draft.UIState.isZipwhipSettingsForOptionsLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isZipwhipSettingsForOptionsLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.zipwhipSettingsForOptions = action.payload
      }
    }),

  [t.REMOVE_ZIPWHIP_SETTING]: (state, action: RemoveZipwhipSettingAction) =>
    produce(state, draft => {
      draft.UIState.isRemoveZipwhipSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isRemoveZipwhipSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.zipwhipSettings[action.payload]) {
          delete draft.zipwhipSettings[action.payload]
          removeItemFromPagination(draft.pagination, action.payload)
        }
      }
    }),
  [t.UPDATE_ZIPWHIP_SETTING]: (state, action: UpdateZipwhipSettingAction) =>
    produce(state, draft => {
      draft.UIState.isUpdateZipwhipSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isUpdateZipwhipSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.zipwhipSettings[action.payload.zipwhipSettingId]) {
          draft.zipwhipSettings[action.payload.zipwhipSettingId].name = action.payload.zipwhipSettingData.name
          draft.zipwhipSettings[action.payload.zipwhipSettingId].username = action.payload.zipwhipSettingData.username
          draft.zipwhipSettings[action.payload.zipwhipSettingId].password = action.payload.zipwhipSettingData.password
        }
      }
    }),

  [t.CHANGE_CURRENT_PAGE]: (state, action: ChangeCurrentPageAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.pagination.currentPage = action.payload
      }
    }),

  [wt.SWITCH_WORKSPACE]: state =>
    produce(state, draft => {
      draft.pagination = initState.pagination
    }),

  [nt.LOCATION_CHANGE]: state =>
    produce(state, draft => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default reducer
