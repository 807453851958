import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { SendgridSetting } from '../types'

type SendgridSettingInput = {
  name: string
  apiKey: string
  from: string
  fromName?: string
}

export type CreateSendgridSettingAction = FSA<undefined, SendgridSetting, string>
type CreateSendgridSetting = (sendgridSettingData: SendgridSettingInput) => MrxThunk<CreateSendgridSettingAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'createSendgridSetting', SendgridSetting>

const CREATE_SENDGRID_SETTING = `
  mutation createSendgridSetting ($workspaceId: Int!, $sendgridSettingData: SendgridSettingInput!) {
    createSendgridSetting (workspaceId: $workspaceId, sendgridSettingData: $sendgridSettingData) {
      id
      name
      apiKey
      from
      fromName
    }
  }
`

const createSendgridSetting: CreateSendgridSetting = (sendgridSettingData) => async (dispatch, getState) => {
  let isSaved = false

  dispatch({
    type: t.CREATE_SENDGRID_SETTING,
    meta: { done: false }
  })

  try {
    const workspaceId = getState()?.workspaces?.activeWorkspace?.id
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_SENDGRID_SETTING,
      variables: { workspaceId, sendgridSettingData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createSendgridSetting } = data
    if (!createSendgridSetting) {
      throw new Error("Can't create Sendgrid Setting!")
    }

    dispatch({
      type: t.CREATE_SENDGRID_SETTING,
      payload: createSendgridSetting,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.CREATE_SENDGRID_SETTING,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { createSendgridSetting }
