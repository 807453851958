import { createSelector, Selector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { TasksQueueState } from '../types'

const getFeatureState: Selector<ReduxState, TasksQueueState> = state => state.tasksQueue

export const featureStateSelector = createSelector<ReduxState, TasksQueueState, TasksQueueState>(
  getFeatureState,
  state => state
)

export default featureStateSelector
