import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type IsNeedToFetchSelector = (state: ReduxState, campaignId: number) => boolean

const getIsNeedToFetchListSelector: IsNeedToFetchSelector = (state, campaignId) => {
  let result = true
  if (Object.prototype.hasOwnProperty.call(state.products.pagination, campaignId)) {
    const { currentPage, pages } = state.products.pagination[campaignId]
    result = !pages[currentPage]
  }

  return result
}

export const isNeedToFetchListSelector = createSelector(getIsNeedToFetchListSelector, (state) => state)

export default isNeedToFetchListSelector
