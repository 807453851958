// TODO - remove becouse of legacy

import React, { useContext, useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import { Table, Layout, PageHeader, Popconfirm, Input, Button, Switch } from 'antd'
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table'
import InfiniteScroll from 'react-infinite-scroll-component'
import { QuizDto } from '@merchx-v2/shared-types'
import notification from 'mrx-notification'
import { Breadcrumbs } from 'components'
import { GlobalContext } from 'appContexts'
import * as rootStyles from 'assets/layoutStyle'

import { useQuizzesList, useRemoveQuiz } from '../../hooks'
import styles from './QuizzesList.module.scss'

const QuizzesList = React.memo(() => {
  const { campaignId } = useContext(GlobalContext)

  const [searchText, setSearchText] = useState<string>('')
  const [showArchived, toggleArchived] = useState<boolean>(false)

  const { history } = useReactRouter()

  const removeQuiz = useRemoveQuiz()
  const quizzesList = useQuizzesList({ campaignId, searchText, archived: showArchived })
  const quizzes: QuizDto[] = []
  quizzesList && quizzesList.data && quizzesList.data.pages.forEach((page) => page.items.forEach((quiz) => quizzes.push(quiz)))

  const page = quizzesList.data?.pages.length ? quizzesList.data?.pages[quizzesList.data?.pages.length - 1].currentPage : 1
  const size = quizzesList.data?.pages.length ? quizzesList.data?.pages[quizzesList.data?.pages.length - 1].pageSize : 30

  useEffect(() => {
    return () => quizzesList.remove()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (quizzesList.error) {
      notification.error({
        message: 'Quizzes list page error!',
        description: quizzesList.error instanceof Error ? quizzesList.error.message : quizzesList.error.toString()
      })
    }
  }, [quizzesList.error])

  useEffect(() => {
    if (removeQuiz.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Quiz was removed successfully!'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeQuiz.isSuccess])

  const doConfirm = async (quizId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    removeQuiz.mutate(quizId)
  }

  const columns: ColumnsType<QuizDto> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Logic Type',
      dataIndex: 'logicType',
      key: 'logicType'
    },
    {
      title: 'Owner Type',
      dataIndex: 'ownerType',
      key: 'ownerType'
    },
    {
      title: 'Owner',
      render: (_value, record) =>
        record.ownerType === 'MERCHX' ? (
          record.campaign?.name
        ) : (
          <a style={{ fontSize: '20px' }} href={`/sales/customers/${record.ownerId}`}>
            {record.owner?.firstName} {record.owner?.lastName}
          </a>
        ),
      key: 'owner'
    },
    {
      title: 'Status',
      dataIndex: '',
      key: 'isLogicValid',
      render: (record) => (record.archivedAt ? 'ARCHIVED' : record.isLogicValid ? 'ACTIVE' : 'INVALID')
    },
    {
      title: 'Plays',
      dataIndex: 'countOfPlays',
      key: 'countOfPlays'
    },
    {
      title: '',
      dataIndex: '',
      key: 'edit',
      width: '10%',
      align: 'center',
      render: (record: QuizDto) => (
        <span>
          <EditTwoTone
            style={{ fontSize: '20px' }}
            onClick={(e) => {
              e.stopPropagation()
              history.push(`/quizzes/${record.id}/edit`)
            }}
          />
        </span>
      )
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '10%',
      align: 'center',
      render: (record: QuizDto) => (
        <Popconfirm
          title='Are you sure delete this quiz?'
          onConfirm={(e) => doConfirm(record.id, e)}
          onCancel={(e) => e?.stopPropagation()}
          okText='Yes'
          cancelText='No'
        >
          <DeleteTwoTone twoToneColor='#ec1c24' style={{ fontSize: '20px' }} onClick={(e) => e.stopPropagation()} />
        </Popconfirm>
      )
    }
  ]

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title='Quizzes List'
          extra={[
            <Switch
              checkedChildren='Show archived'
              unCheckedChildren='Show archived'
              defaultChecked={showArchived}
              key='3'
              onChange={toggleArchived}
            />,
            <Input.Search
              key='search-field'
              placeholder='Input search text'
              onSearch={(value) => setSearchText(value)}
              style={{ width: 200 }}
              allowClear
            />,
            <Button type='primary' key='create-button' onClick={() => history.push(`/quizzes/create`)}>
              + CREATE
            </Button>
          ]}
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Quizzes List', url: '/quizzesList' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <InfiniteScroll
          style={{ paddingBottom: '30px' }}
          dataLength={page * size}
          next={quizzesList.fetchNextPage}
          hasMore={!!quizzesList.hasNextPage}
          loader={<h4>Loading...</h4>}
          refreshFunction={quizzesList.refetch}
          scrollThreshold={0.8}
        >
          <Table
            columns={columns}
            dataSource={quizzes.map((item) => item).flat()}
            bordered
            rowKey={(record) => record.id}
            pagination={false}
            loading={quizzesList.isLoading}
          />
        </InfiniteScroll>
      </Layout.Content>
    </Layout>
  )
})

export default QuizzesList
