import { useQuery } from 'react-query'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'storesForOptions', SelectOption[]>

const FETCH_STORES_OPTIONS = `
  query fetchStoresForOptions($searchText: String!) {
    storesForOptions(searchText: $searchText) {
      id
      name
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchStoresOptionsArgs = {
  searchText?: string
}

type FetchStoresOptions = (args: FetchStoresOptionsArgs) => Promise<SelectOption[]>

const fetchStoresOptions: FetchStoresOptions = async ({ searchText }) => {
  if (!searchText) return [] as SelectOption[]

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_STORES_OPTIONS,
      variables: { searchText }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { storesForOptions } = data
    if (!storesForOptions) {
      throw new Error("Can't get stores options!")
    }

    return storesForOptions
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useStoresOptions = (args: FetchStoresOptionsArgs) => useQuery('storesOptions', () => fetchStoresOptions(args))
