import React from 'react'
import { Form, Input, InputNumber, Select, Upload, Button } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { AvailablePluginNames } from '@merchx-v2/shared-types'
import * as pluginSettingsData from './pluginSettingsData'

type Props = {
  pluginName?: AvailablePluginNames
  callback: pluginSettingsData.Callback
}

type RenderFieldProps = {
  type?: pluginSettingsData.FieldType
  rows?: number
  options?: pluginSettingsData.Option[]
  upload?: pluginSettingsData.Upload
  callback: pluginSettingsData.Callback
}
const renderField = ({ type, options, upload, rows, callback }: RenderFieldProps) => {
  if (type === 'number') {
    return <InputNumber style={{ width: '100%' }} />
  }

  if (options?.length) {
    return (
      <Select>
        {options.map((item) => (
          <Select.Option key={item.key} value={item.value}>
            {item.title}
          </Select.Option>
        ))}
      </Select>
    )
  }

  if (upload) {
    const { customRequest, ...uploadProps } = upload
    return (
      <Upload customRequest={customRequest(callback)} {...uploadProps}>
        <Button>
          <UploadOutlined /> Click to Upload
        </Button>
      </Upload>
    )
  }

  if (rows && rows > 1) {
    return <Input.TextArea rows={rows} />
  }

  return <Input />
}

const renderForm = (settings: pluginSettingsData.SettingData[], callback: pluginSettingsData.Callback) => (
  <>
    {settings.map((setting, index) => {
      const { type, options, upload, rows, ...props } = setting
      return (
        <Form.Item {...props} key={index}>
          {renderField({ type, options, upload, rows, callback })}
        </Form.Item>
      )
    })}
  </>
)

const mapPluginRender = {
  AMAZON: pluginSettingsData.amazonSettingsData,
  AUTHORIZE: pluginSettingsData.authorizeSettingsData,
  CUSTOM_GATEWAY: pluginSettingsData.customGatewaySettingsData,
  FACEBOOK_CATALOG: pluginSettingsData.facebookCatalogData,
  GOOGLE_SHOPPING: pluginSettingsData.googleShoppingSettingsData,
  NGINX: pluginSettingsData.nginxSettingsData,
  PAYPAL: pluginSettingsData.payPalSettingsData,
  POSTMARK: pluginSettingsData.postmarkSettingsData,
  SENDGRID: pluginSettingsData.sendgridSettingsData,
  SHIPSTATION: pluginSettingsData.shipStationSettingsData,
  STRIPE: pluginSettingsData.stripeSettingsData,
  TWILIO: pluginSettingsData.twilioSettingsData
}

const PluginSettingForm = ({ pluginName, callback }: Props) => {
  if (mapPluginRender[pluginName]) return renderForm(mapPluginRender[pluginName], callback)

  return null
}

export default PluginSettingForm
