// TODO - remove becouse of legacy

import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'

import * as workspaceFeature from 'features/workspaces'
import { Landing, LandingTypeEnum } from '../../types'

type OwnProps = {
  landingId?: number
  landing?: Landing
  landingType?: LandingTypeEnum
  campaignId?: number
  onNextButtonClick: (nextStep: number, newLandingId?: number) => void
}

const mapStateToProps = (state: ReduxState) => {
  return {
    workspace: workspaceFeature.selectors.workspaceSelector(state)
  }
}

const mapDispatchToProps = {}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
