import axios from 'utils/axios'
import { useMutation } from 'react-query'
import hash from 'object-hash'

import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { queryClient } from 'queryClient'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'markInviteToStoreAsAccepted', boolean>

const QUERY = `
  mutation MarkInviteToStoreAsAccepted ($storeId: Int!, $email: String!) {
    markInviteToStoreAsAccepted(storeId: $storeId, email: $email)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type MarkInviteToStoreAsAcceptedArgs = {
  storeId: number
  email: string
}

type MarkInviteToStoreAsAccepted = (args: MarkInviteToStoreAsAcceptedArgs) => Promise<MarkInviteToStoreAsAcceptedArgs>

const markInviteToStoreAsAccepted: MarkInviteToStoreAsAccepted = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: QUERY,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { markInviteToStoreAsAccepted } = data
    if (!markInviteToStoreAsAccepted) {
      throw new Error("Can't mark invite as accepted!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type MarkInviteToStoreAsAcceptedEvents = {
  onSettled: (data?: MarkInviteToStoreAsAcceptedArgs) => void
}

const markInviteToStoreAsAcceptedEvents: MarkInviteToStoreAsAcceptedEvents = {
  onSettled: (data) => {
    if (data?.storeId) {
      queryClient.invalidateQueries(['storeInvitesPage', hash({ storeId: data.storeId })])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useMarkInviteToStoreAsAccepted = () => useMutation(markInviteToStoreAsAccepted, markInviteToStoreAsAcceptedEvents)
