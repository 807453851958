export const addItemToPagination = (pagination: any, objectId: number) => {
  const { currentPage, total, size } = pagination
  if (!pagination.pages[currentPage]) {
    pagination.pages[currentPage] = []
  }
  const totalPages = Math.ceil(total / size)
  if (pagination.pages[currentPage].length < size) {
    pagination.pages[currentPage].push(objectId)
  } else {
    if (!pagination.pages[totalPages]) {
      pagination.pages[totalPages] = []
    }
    if (pagination.pages[totalPages].length < size) {
      pagination.pages[totalPages].push(objectId)
      pagination.currentPage = totalPages
    } else {
      const newPages = totalPages + 1
      pagination.pages[newPages] = []
      pagination.pages[newPages].push(objectId)
      pagination.currentPage = newPages
      pagination.total += 1
    }
  }
}
