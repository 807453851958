import React, { useEffect } from 'react'
import moment from 'moment'
import useReactRouter from 'use-react-router'
import { Link } from 'react-router-dom'
import { Button, Layout, PageHeader, Popconfirm, Tabs } from 'antd'
import notification from 'mrx-notification'
import * as rootStyles from 'assets/layoutStyle'
import { Breadcrumbs } from 'components'
import { useCustomerSubscription, useCancelCustomerSubscription } from '../../hooks'
import { OrdersTab, ShippingAddressTab, PaymentAddressTab } from '../../components'
import styles from './CustomerSubscriptionDetailsForCustomerDetails.module.scss'

type Props = {
  match: { params: { customerSubscriptionId: string } }
  onClose: () => void
}

const CustomerSubscriptionDetailsForCustomerDetails = (props: Props) => {
  const { match } = props

  const { history } = useReactRouter()
  const customerSubscriptionId = +match.params.customerSubscriptionId

  const cancelCustomerSubscription = useCancelCustomerSubscription()
  const customerSubscription = useCustomerSubscription(customerSubscriptionId)

  useEffect(() => {
    if (customerSubscription.error) {
      notification.error({
        message: 'Subscription error!',
        description:
          customerSubscription.error instanceof Error ? customerSubscription.error.message : customerSubscription.error.toString()
      })
    }
  }, [customerSubscription.error])

  useEffect(() => {
    if (cancelCustomerSubscription.error) {
      notification.error({
        message: 'Subscription cancellation error!',
        description: cancelCustomerSubscription.error.toString()
      })
    }
  }, [cancelCustomerSubscription.error])

  useEffect(() => {
    if (cancelCustomerSubscription.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Subscription was canceled successfully!'
      })
    }
  }, [cancelCustomerSubscription.isSuccess])

  const doConfirm = () => {
    cancelCustomerSubscription.mutate(customerSubscriptionId)
  }

  return (
    <Layout style={rootStyles.root}>
      <PageHeader
        onBack={() => history.goBack()}
        title='Customer Subscription Details'
        extra={[
          <Popconfirm
            key='cancel-button'
            title='Are you sure cancel this subscription?'
            onConfirm={() => doConfirm()}
            onCancel={(e) => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <Button
              disabled={cancelCustomerSubscription.isLoading || ['CANCELED', 'TERMINATED'].includes(customerSubscription?.status)}
              key='2'
            >
              Cancel Subscription
            </Button>
          </Popconfirm>
        ]}
      />
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Sales', url: '/' },
            { title: 'Subscriptions', url: '/sales/customerSubscription' },
            { title: 'Customer Subscription Details', url: '/sales/customerSubscriptionDetails' }
          ]}
        />
      </Layout.Content>
      {customerSubscription.data && (
        <>
          <div className={styles.listContainer}>
            <div className={styles.leftColumn}>
              <div className={styles.listItem}>Order ID: {customerSubscription.data.id}</div>
              <div className={styles.listItem}>
                Customer:{' '}
                <Link to={`/sales/customerSubscriptions/${customerSubscription.data.id}/customers/${customerSubscription.data.customerId}`}>
                  {`${customerSubscription.data.customer.firstName} ${customerSubscription.data.customer.lastName}`}
                </Link>
              </div>
              <div className={styles.listItem}>
                Trial Start: {moment(customerSubscription.data.creationDate).format('MM/DD/YYYY HH:mm:ss')}
              </div>
              <div className={styles.listItem}>
                Subscription Start: {moment(customerSubscription.data.startDate).format('MM/DD/YYYY HH:mm:ss')}
              </div>
              <div className={styles.listItem}>Payment Subscription Id: {customerSubscription.data.paymentSubscriptionId}</div>
              <div className={styles.listItem}>Interval: {customerSubscription.data.interval}</div>
              <div className={styles.listItem}>Repeat Frequency: {customerSubscription.data.repeatFrequency}</div>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.listItem}>Status: {customerSubscription.data.status}</div>
              <div className={styles.listItem}>Email: {customerSubscription.data.customer.email}</div>
              <div className={styles.listItem}>Total: {`$${(customerSubscription.data.total / 100).toFixed(2)}`}</div>
              <div className={styles.listItem}>Payment Method: {customerSubscription.data.landing?.paymentPlugin}</div>
            </div>
          </div>
          <Tabs type='card' className={styles.tabsContainer}>
            <Tabs.TabPane tab='Orders' key='1'>
              <OrdersTab orders={customerSubscription.data.orders} />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Shipping Address' key='2'>
              <ShippingAddressTab address={customerSubscription.data.shippingAddress} />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Payment Address' key='3'>
              <PaymentAddressTab address={customerSubscription.data.billingAddress} />
            </Tabs.TabPane>
          </Tabs>
        </>
      )}
    </Layout>
  )
}

export default CustomerSubscriptionDetailsForCustomerDetails
