import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { ShipstationSetting } from '../types'

type ShipstationSettingSelector = (state: ReduxState, shipstationSettingId?: number) => ShipstationSetting | undefined

const getShipstationSetting: ShipstationSettingSelector = (state, shipstationSettingId) => {
  let result
  if (shipstationSettingId && Object.prototype.hasOwnProperty.call(state.shipstationSettings.shipstationSettings, shipstationSettingId)) {
    result = state.shipstationSettings.shipstationSettings[shipstationSettingId] as ShipstationSetting
  }
  return result
}

export const shipstationSettingSelector: ShipstationSettingSelector = createSelector(
  getShipstationSetting,
  (shipstationSetting) => shipstationSetting
)
