import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type isLoadingKeyType = 'isListLoading'

type IsLoadingSelector = (state: ReduxState, isLoadingKey: isLoadingKeyType) => boolean

const getIsLoadingSelectorByKey = (state: ReduxState, isLoadingKey: isLoadingKeyType) =>
  state.addresses.UIState[isLoadingKey] || false

export const isLoadingSelector: IsLoadingSelector = createSelector(getIsLoadingSelectorByKey, isLoading => isLoading)
