import axios from 'utils/axios'
import { useQuery } from 'react-query'
import { StoreDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'store', StoreDto>

// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchStore = (storeId: number) => Promise<StoreDto>

const fetchStore: FetchStore = async (storeId) => {
  try {
    const FETCH_STORE = `
      query store ($storeId: Int!) {
        store (storeId: $storeId) {
          id
          
          name
          description

          ownerType
          ownerId
          ownerName
          
          mainImageId
          mainImage {
            id
            name
            extension
            mimeType
            s3bucket
            s3key
            signedUrl
            createdAt
            updatedAt
          }
          
          costModificators {
            conditionType
            conditionValue
            changeValue
          }

          avatarImageId
          avatarImage {
            id
            name
            extension
            mimeType
            s3bucket
            s3key
            signedUrl
            createdAt
            updatedAt
          }

          bannerLink
          bannerImageId
          bannerImage {
            id
            name
            extension
            mimeType
            s3bucket
            s3key
            signedUrl
            createdAt
            updatedAt
          }

          hasCustomSeo
          seoUrl
          seoTitle
          seoDescription
          seoKeywords

          isActive
          supportEmail

          facebookLink
          twitterLink
          instagramLink
          pinterestLink
          tiktokLink
          linkedInLink
          youtubeLink
          threadsLink

          location

          balance
          pendingBalance

          createdAt
          updatedAt
        }
      }
    `
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_STORE,
      variables: { storeId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { store } = data
    if (!store) {
      throw new Error("Can't get store!")
    }

    return store
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useStore = (storeId: number) => useQuery(['store', storeId], () => fetchStore(storeId))
