export const ACTIVE_WORKSPACE_LOADED = 'workspaces/ACTIVE_WORKSPACE_LOADED'
export const CREATE_WORKSPACE = 'workspaces/CREATE_WORKSPACE'
export const FETCH_WORKSPACE = 'workspaces/FETCH_WORKSPACE'
export const FETCH_WORKSPACES_LIST = 'workspaces/FETCH_WORKSPACES_LIST'
export const INVITE_USER = 'workspaces/INVITE_USER'
export const REMOVE_USER = 'workspaces/REMOVE_USER'
export const SWITCH_WORKSPACE = 'workspaces/SWITCH_WORKSPACE'
export const UPDATE_WORKSPACE = 'workspaces/UPDATE_WORKSPACE'
export const WORKSPACES_LOADED = 'workspaces/WORKSPACES_LOADED'
export const VALIDATE_USER_EMAIL = 'workspaces/VALIDATE_USER_EMAIL'
