import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import FormData from 'form-data'
import { AssetOwner, AssetDto, CreatePresignedUrlDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { createPresignedUrl } from './useCreatePresignedUrl'

type UploadAssetArgs = {
  ownerType: AssetOwner
  ownerId: number
  assetData: CreatePresignedUrlDto
  file: File
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponseUploadAsset = GraphQLResponse<'uploadAsset', AssetDto>
type QueryPresignedResponse = GraphQLResponse<'uploadPresignedAsset', AssetDto>

const UPLOAD_PRESIGNED_ASSET = `
  mutation uploadPresignedAsset($ownerType: AssetOwner!, $ownerId: Int!, $assetData: AssetPresignedInput!) {
    uploadPresignedAsset(ownerType: $ownerType, ownerId: $ownerId, assetData: $assetData) {
      id
      name
      extension
      ownerType
      ownerId
      ownerVersion
      mimeType
      s3bucket
      s3key
      signedUrl
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UploadAsset = (args: UploadAssetArgs) => Promise<AssetDto>

const uploadAsset: UploadAsset = async (args) => {
  try {
    let errors
    let queryResponse: { uploadPresignedAsset: AssetDto }

    const { ownerType, ownerId, file, assetData } = args
    const { name, role } = assetData
    const { type, name: filename } = file

    const formData = new FormData()

    if (file.type.startsWith('image/')) {
      const presignedUrl = await createPresignedUrl({ ownerType, ownerId, assetData: { name, type, filename, role }, file })
      const { data: result }: QueryPresignedResponse = await axios.post('/graphql', {
        query: UPLOAD_PRESIGNED_ASSET,
        variables: {
          ownerType,
          ownerId: +ownerId,
          assetData: {
            name,
            s3key: presignedUrl.fields.key,
            type: file.type,
            filename: file.name,
            dataHash: presignedUrl.dataHash,
            role
          }
        }
      })

      queryResponse = result.data
      errors = result.errors
    } else {
      formData.append(
        'operations',
        JSON.stringify({
          query: `
          mutation uploadAsset($ownerType: AssetOwner!, $ownerId: Int!, $assetData: AssetInput!) {
            uploadAsset(ownerType: $ownerType, ownerId: $ownerId, assetData: $assetData) {
              id
              name
              extension
              ownerType
              ownerId
              ownerVersion
              mimeType
              s3bucket
              s3key
              signedUrl
              createdAt
              updatedAt
            }
          }
        `,
          variables: {
            ownerType,
            ownerId: +ownerId,
            assetData: {
              name,
              file: null,
              role
            }
          }
        })
      )
      formData.append('map', JSON.stringify({ 0: ['variables.assetData.file'] }))
      formData.append('0', file)

      const { data: result }: QueryResponseUploadAsset = await axios.post('/graphql', formData)

      queryResponse = {
        uploadPresignedAsset: result.data.uploadAsset
      }
      errors = result.errors
    }

    guardFromErrors(errors)

    if (!queryResponse) {
      throw new Error('Response body is empty!')
    }

    const { uploadPresignedAsset } = queryResponse
    if (!uploadPresignedAsset) {
      throw new Error("Can't upload asset!")
    }

    return uploadPresignedAsset
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UploadAssetEvents = {
  onSettled: (data?: AssetDto) => void
}

const uploadAssetEvents: UploadAssetEvents = {
  onSettled: (data) => {
    if (data?.id) {
      // Нам не надо инвалидировать объект, это должен делать вызывающий компонент обновляющий объект
      // queryClient.invalidateQueries([`${data.ownerType.toLowerCase()}`, data.ownerId])
      queryClient.invalidateQueries(['assetsList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUploadAsset = () => useMutation(uploadAsset, uploadAssetEvents)
