import { connect, ConnectedProps } from 'react-redux'
import * as tasksQueueFeature from 'features/tasksQueue'

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = {
  restartTask: tasksQueueFeature.actions.restartTask
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector>
