import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { NginxSetting, NginxSettingInput } from '../types'

export type CreateNginxSettingAction = FSA<undefined, NginxSetting, string>
type CreateNginxSetting = (nginxSettingData: NginxSettingInput) => MrxThunk<CreateNginxSettingAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'createNginxSetting', NginxSetting>

const CREATE_NGINX_SETTING = `
  mutation createNginxSetting ($workspaceId: Int!, $nginxSettingData: NginxSettingInput!) {
    createNginxSetting (workspaceId: $workspaceId, nginxSettingData: $nginxSettingData) {
      id
      name
      host
      port
      userName
      createdAt
      updatedAt
    }
  }
`

const createNginxSetting: CreateNginxSetting = (nginxSettingData) => async (dispatch, getState) => {
  let isSaved = false

  dispatch({
    type: t.CREATE_NGINX_SETTING,
    meta: { done: false }
  })

  try {
    const workspaceId = getState()?.workspaces?.activeWorkspace?.id
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_NGINX_SETTING,
      variables: { workspaceId, nginxSettingData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createNginxSetting } = data
    if (!createNginxSetting) {
      throw new Error("Can't create Nginx Setting!")
    }

    dispatch({
      type: t.CREATE_NGINX_SETTING,
      payload: createNginxSetting,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.CREATE_NGINX_SETTING,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { createNginxSetting }
