import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Table, Tag, Tooltip, Progress, Popconfirm, Modal, Button, Select } from 'antd'
import { RedoOutlined } from '@ant-design/icons'
import { ColumnProps } from 'antd/es/table'
import notification from 'mrx-notification'
import { TaskType } from '@merchx-v2/shared-types'
import formatDuration from 'utils/formatDuration'
import { Task } from 'features/tasksQueue/types'

import { OrderTask } from '../../types'
import { useOrder, useResendOrderTask } from '../../hooks'
import { connector, PropsFromRedux } from './container'
import styles from './TasksTab.module.scss'

const tagColors = {
  NEW: 'cyan',
  IN_PROGRESS: 'gold',
  CANCELED: 'volcano',
  COMPLETED: 'green'
}

const canRestart = ['IN_PROGRESS', 'PROCESSED', 'CANCELED', 'COMPLETED']

type TasksTabType = PropsFromRedux & {
  orderId?: number
}

const TasksTab = (props: TasksTabType) => {
  const {
    orderId,

    restartTask
  } = props

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState<Task>()
  const [taskTypeToResend, setTaskTypeToResend] = useState<TaskType>()
  const [jobQueueKey, setJobQueueKey] = useState(1)
  // eslint-disable-next-line
  const [now, _setNow] = useState(moment())

  const order = useOrder(orderId)
  const resendOrderTask = useResendOrderTask()

  useEffect(() => {
    if (order.error) {
      notification.error({
        message: 'Tasks list error!',
        description: order.error instanceof Error ? order.error.message : order.error.toString()
      })
    }
  }, [order.error])

  const doConfirmRestart = async (taskId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    const isRestarted = await restartTask(taskId)
    if (isRestarted) {
      notification.success({
        message: 'Successfully',
        description: 'Task was restarted successfully!'
      })
    }
    // orderId && fetchOrder(orderId)
  }

  const doConfirmResendTask = async (e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    resendOrderTask.mutate({ orderId: orderId, taskType: taskTypeToResend })
    setJobQueueKey(jobQueueKey + 1)
  }

  const handleOnRow = (record) => {
    return {
      onClick: () => {
        setIsModalVisible(true)
        setSelectedRecord(record)
      }
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const columns: ColumnProps<OrderTask>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Status',
      render: (record: OrderTask) => {
        return <Tag color={tagColors[record.status]}>{record.status}</Tag>
      },
      key: 'status'
    },
    {
      title: 'Task Type',
      render: (record: OrderTask) => {
        const taskType = (record.taskType.charAt(0).toUpperCase() + record.taskType.slice(1).toLowerCase()).replace(/_/g, ' ')
        const payloadTaskType = record.payload?.taskType
          ? (record.payload?.taskType?.charAt(0).toUpperCase() + record.payload?.taskType?.slice(1).toLowerCase()).replace(/_/g, ' ')
          : ''
        return taskType + (payloadTaskType ? ` (${payloadTaskType})` : '')
      },
      key: 'type'
    },
    {
      title: 'Progress',
      render: (record: OrderTask) => {
        return <Progress percent={record.progress} size='small' status='active' />
      },
      key: 'progress'
    },
    {
      title: 'Retries',
      render: (record: OrderTask) => {
        let color = 'green'
        if (record.retryNumber === 2) {
          color = 'orange'
        } else if (record.retryNumber === 3) {
          color = 'volcano'
        }
        return <Tag color={color}>{record.retryNumber}</Tag>
      }
    },
    {
      title: 'Elapsed Time',
      render: (record: OrderTask) => {
        if (record.startedAt !== null) {
          const start = moment(record.startedAt)
          const end = record.endedAt !== null ? moment(record.endedAt) : now
          const showMilliseconds = record.endedAt !== null
          const duration = moment.duration(end.diff(start))
          return <div>{duration ? formatDuration(duration, showMilliseconds) : ''}</div>
        }
      },
      key: 'elapsedTime'
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (record: OrderTask) => (
        <div className={styles.actionIcons}>
          {canRestart.includes(record.status) && (
            <span>
              <Popconfirm
                title='Are you sure restart this task?'
                onConfirm={(e) => doConfirmRestart(record.id, e)}
                onCancel={(e) => e?.stopPropagation()}
                okText='Yes'
                cancelText='No'
              >
                <Tooltip title='Restart Task'>
                  <RedoOutlined
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  />
                </Tooltip>
              </Popconfirm>
            </span>
          )}
        </div>
      )
    }
  ]

  return (
    <>
      <div className={styles.selectTask}>
        <span>
          <Popconfirm
            title='Are you sure resend this task?'
            onConfirm={doConfirmResendTask}
            onCancel={(e) => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <Tooltip title='Resend Task'>
              <Button
                loading={order.isLoading}
                type='primary'
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                Resend Task
              </Button>
            </Tooltip>
          </Popconfirm>
        </span>

        <Select onSelect={(value: TaskType) => setTaskTypeToResend(value)} placeholder='Please select task type' style={{ width: '300px' }}>
          <Select.Option value='SEND_ORDER_CONFIRMATION_EMAIL'>SEND_ORDER_CONFIRMATION_EMAIL</Select.Option>
          <Select.Option value='SEND_ORDER_CANCELLATION_EMAIL'>SEND_ORDER_CANCELLATION_EMAIL</Select.Option>
          <Select.Option value='SEND_SHIPPING_CONFIRMATION_EMAIL'>SEND_SHIPPING_CONFIRMATION_EMAIL</Select.Option>
          <Select.Option value='SEND_SURVEY_EMAIL'>SEND_SURVEY_EMAIL</Select.Option>
          <Select.Option value='SEND_WELCOME_EMAIL_2'>SEND_WELCOME_EMAIL_2</Select.Option>
        </Select>
      </div>
      <Table
        columns={columns}
        dataSource={order.data.orderTasks}
        bordered
        rowKey={(record) => record.id}
        onRow={handleOnRow}
        loading={order.isLoading}
      />

      <Modal
        title='Task Message'
        visible={isModalVisible}
        footer={[
          <Button key='submit' type='primary' onClick={handleCancel}>
            OK
          </Button>
        ]}
        onCancel={handleCancel}
      >
        {selectedRecord?.message ? selectedRecord.message : 'No message!'}
      </Modal>
    </>
  )
}

export default connector(TasksTab)
