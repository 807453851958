import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { SendgridSetting } from '../types'

type SendgridSettingsList = {
  items: SendgridSetting[]
  total: number
}

type Payload = SendgridSettingsList & {
  page: number
}

export type FetchSendgridSettingsListAction = FSA<undefined, Payload, string>
type FetchSendgridSettingsList = () => MrxThunk<FetchSendgridSettingsListAction>
type QueryResponse = GraphQLResponse<'sendgridSettingsListPage', SendgridSettingsList>

const FETCH_SENDGRID_SETTINGS_LIST = `
  query fetchSendgridSettingsList ($page: Int, $size: Int, $workspaceId: Int!) {
    sendgridSettingsListPage (page: $page, size: $size, workspaceId: $workspaceId) {
      items {
        id
        name
        apiKey
        from
        fromName
      }
      total
    }
  }
`

const fetchSendgridSettingsList: FetchSendgridSettingsList = () => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_SENDGRID_SETTINGS_LIST,
    meta: { done: false }
  })

  try {
    const {
      workspaces: {
        activeWorkspace: { id: workspaceId }
      },
      sendgridSettings: {
        pagination: { currentPage: page, size }
      }
    } = getState()
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_SENDGRID_SETTINGS_LIST,
      variables: { page, size, workspaceId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { sendgridSettingsListPage } = data
    if (!sendgridSettingsListPage) {
      throw new Error("Can't get Sendgrid Settings list page!")
    }

    dispatch({
      type: t.FETCH_SENDGRID_SETTINGS_LIST,
      payload: {
        page,
        total: sendgridSettingsListPage.total,
        items: sendgridSettingsListPage.items
      },
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_SENDGRID_SETTINGS_LIST,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchSendgridSettingsList }
