import { connect, ConnectedProps } from 'react-redux'
import { FulfillmentPlugin } from '@merchx-v2/shared-types'
import { ReduxState } from 'store/createRootReducer'
import * as customGatewaySettingsFeature from 'features/customGatewaySettings'
import * as shipstationSettingsFeature from 'features/shipstationSettings'

type OwnProps = {
  plugin: FulfillmentPlugin
  initialOption: SelectOption
  disabled?: boolean
  onSelect: (selectOption: SelectOption) => void
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  let isLoading = false
  let options = []

  switch (ownProps.plugin) {
    case 'CUSTOM_GATEWAY': {
      isLoading = customGatewaySettingsFeature.selectors.isLoadingSelector(state, 'isCustomGatewaySettingsForOptionsLoading')
      options = customGatewaySettingsFeature.selectors.customGatewaySettingsSelectOptionsSelector(state)
      break
    }

    case 'SHIPSTATION': {
      isLoading = shipstationSettingsFeature.selectors.isLoadingSelector(state, 'isShipstationSettingsForOptionsLoading')
      options = shipstationSettingsFeature.selectors.shipstationSettingsSelectOptionsSelector(state)
      break
    }
  }

  return {
    isLoading,
    options
  }
}

const mapDispatchToProps = {
  fetchCustomGatewaySettingsOptions: customGatewaySettingsFeature.actions.fetchCustomGatewaySettingsForOptions,
  fetchShipstationSettingsOptions: shipstationSettingsFeature.actions.fetchShipstationSettingsForOptions
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
