import * as actions from './actions'
import * as actionTypes from './actionTypes'
import * as pages from './pages'
import reducer from './reducer'
import * as selectors from './selectors'
import * as types from './types'

console.log('Workspaces feature initialized!')

export { actions, actionTypes, pages, reducer, selectors, types }
