// TODO - remove becouse of legacy

import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Button, Card } from 'antd'
import { PictureTwoTone } from '@ant-design/icons'
import notification from 'mrx-notification'
import { useTemplatesList } from 'features/templates/hooks'
import { useSetLandingTemplate } from '../../hooks'
import styles from './LandingWizardTemplates.module.scss'
import { Template } from 'features/templates/types'
import { TemplateTypeEnum } from 'features/types'
import { Landing } from '../../types'

type PropsType = {
  landing: Landing
  templateType: TemplateTypeEnum
  onNextButtonClick: (nextStep: number) => void
}

const LandingWizardTemplates = (props: PropsType) => {
  const {
    landing,
    templateType,

    onNextButtonClick
  } = props
  const [selectedTemplate, setSelectedTemplate] = useState<Template>()

  const setLandingTemplate = useSetLandingTemplate()
  const templatesList = useTemplatesList({ templateType })
  const templates: Template[] = []
  templatesList &&
    templatesList.data &&
    templatesList.data.pages.forEach((page) => page.items.forEach((template) => templates.push(template)))

  const page = templatesList.data?.pages.length ? templatesList.data?.pages[templatesList.data?.pages.length - 1].currentPage : 1
  const size = templatesList.data?.pages.length ? templatesList.data?.pages[templatesList.data?.pages.length - 1].pageSize : 30

  useEffect(() => {
    if (templates?.length) {
      const selectedItem = templates.find((item) => item.id === landing.templateId)
      if (selectedItem && !selectedTemplate) {
        setSelectedTemplate(selectedItem)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates])

  const handleGoToNext = async () => {
    if (selectedTemplate) {
      setLandingTemplate.mutate(
        { landingId: landing.id, templateId: selectedTemplate.id },
        {
          onSuccess: () => {
            notification.success({
              message: 'Successfully',
              description: 'Template was saved successfully!'
            })
            onNextButtonClick(2)
          },
          onError: (error) => {
            notification.error({
              message: 'Set landing template error!',
              description: error.toString()
            })
          }
        }
      )
    }
  }

  return (
    <div className={styles.wizardDeployContainer}>
      <Card
        className={styles.card}
        title={<span className={styles.cardTitle}>Templates</span>}
        extra={[
          <Button key='1' type='primary' onClick={handleGoToNext} loading={setLandingTemplate.isLoading}>
            NEXT
          </Button>
        ]}
      >
        <div className={styles.templatesContainer}>
          <div className={styles.leftContainer}>
            <div className={styles.templatesListContainer}>
              <InfiniteScroll
                style={{ paddingBottom: '30px', display: 'flex', flexWrap: 'wrap' }}
                dataLength={page * size}
                next={templatesList.fetchNextPage}
                hasMore={!!templatesList.hasNextPage}
                loader={<h4>Loading...</h4>}
                refreshFunction={templatesList.refetch}
                scrollThreshold={0.8}
              >
                {templates?.map((template) => {
                  return (
                    <div
                      key={template.id}
                      className={selectedTemplate?.id === template.id ? styles.selectedTemplatesListItem : styles.templatesListItem}
                    >
                      <div className={styles.templatesListItemImageContainer} onClick={() => setSelectedTemplate(template)}>
                        {template.mainPreviewThumbImageUrl ? (
                          <img
                            className={styles.templatesListItemImage}
                            src={template.mainPreviewThumbImageUrl}
                            alt={template.name}
                            height='150px'
                          />
                        ) : (
                          <PictureTwoTone twoToneColor='#c6cccc' className={styles.pictureIcon} />
                        )}
                      </div>

                      <div className={styles.templatesListItemText}>{template.name}</div>
                    </div>
                  )
                })}
              </InfiniteScroll>
            </div>
          </div>

          {selectedTemplate && (
            <div className={styles.templatesPreviewContainer}>
              <div className={styles.leftPreviewContainer}>
                {selectedTemplate?.mainPreviewImageUrl && (
                  <div className={styles.previewImageContainer}>
                    <div style={{ textAlign: 'center' }}>Main preview image</div>
                    <img className={styles.previewImage} src={selectedTemplate?.mainPreviewImageUrl} alt={selectedTemplate?.name} />
                  </div>
                )}
              </div>
              <div className={styles.rightPreviewContainer}>
                {selectedTemplate.previewImage1Url && (
                  <div className={styles.previewImageContainer}>
                    <div style={{ textAlign: 'center' }}>Preview image 1</div>
                    <img className={styles.previewImage} src={selectedTemplate.previewImage1Url} alt={selectedTemplate.name} />
                  </div>
                )}

                {selectedTemplate.previewImage2Url && (
                  <div className={styles.previewImageContainer}>
                    <div style={{ textAlign: 'center' }}>Preview image 2</div>
                    <img className={styles.previewImage} src={selectedTemplate.previewImage2Url} alt={selectedTemplate.name} />
                  </div>
                )}

                {selectedTemplate.previewImage3Url && (
                  <div className={styles.previewImageContainer}>
                    <div style={{ textAlign: 'center' }}>Preview image 3</div>
                    <img className={styles.previewImage} src={selectedTemplate.previewImage3Url} alt={selectedTemplate.name} />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Card>
    </div>
  )
}

export default LandingWizardTemplates
