import React, { useEffect, useState } from 'react'
import { SettingFilter } from '@merchx-v2/shared-types'
import { SearchableSelectBase } from 'components'
import { useStoresOptions } from '../../hooks/useStoresOptions'

type PropsType = {
  initialOption: SelectOption
  disabled?: boolean
  filters?: SettingFilter[]
  allowClear?: boolean
  selected?: string | number
  onSelect: (selectOption: SelectOption) => void
}

const StoresSelect = (props: PropsType) => {
  const { ...rest } = props

  const [searchText, setSearchText] = useState<string>()

  const storeOptions = useStoresOptions({ searchText })

  useEffect(() => {
    storeOptions.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  return (
    <SearchableSelectBase
      onSearch={setSearchText}
      options={storeOptions.data}
      isLoading={storeOptions.isLoading || storeOptions.isRefetching}
      {...rest}
    />
  )
}

export default StoresSelect
