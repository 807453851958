import React, { useContext, useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import moment from 'moment-timezone'
import { Table, Layout, PageHeader, Button, DatePicker, Drawer, Select } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { ReloadOutlined } from '@ant-design/icons'
import notification from 'mrx-notification'
import { GlobalContext } from 'appContexts'
import { Breadcrumbs } from 'components'
import { CampaignsSelect } from 'features/campaigns/components'
import { useLandingsOptions } from 'features/landings/hooks'
import * as rootStyles from 'assets/layoutStyle'
import styles from './SubscriptionStatsReport.module.scss'
import { connector, PropsFromRedux } from './container'
import { SubscriptionStatsCycle } from '../../types'
import { ProductsSelect } from 'features/products/components'
import useDebounce from 'utils/useDebounce'

const dateFormat = 'MM/DD/YYYY'
const { Option } = Select

const getCycleTitle = (cycle: number): string => {
  switch (cycle) {
    case 0:
      return 'trial - 1st billing'
    case 1:
      return '1st billing - 2nd billing'
    case 2:
      return '2nd billing - 3rd billing'
    case 3:
      return '3rd billing - 4th billing'
    default:
      return `${cycle}th billing - ${cycle + 1}th billing`
  }
}

const SubscriptionStatsReport = (props: PropsFromRedux) => {
  const {
    subscriptionStatsReport,
    subscriptionStatsReportWithoutActive,
    isLoading,
    error,
    datesRange,

    fetchSubscriptionStatsReport,
    changeDatesRange
  } = props

  const { campaignId, workspaceId } = useContext(GlobalContext)

  const columns: ColumnsType<SubscriptionStatsCycle> = [
    {
      dataIndex: 'cycle',
      render: getCycleTitle
    },
    {
      title: 'Take rate %',
      render: (_value, record) => (record.cycle === 0 ? subscriptionStatsReport && subscriptionStatsReport.takeRate.toFixed(2) : '')
    },
    {
      title: 'Rebill Rate %',
      dataIndex: 'rebillRate',
      render: (value) => value.toFixed(2)
    },
    {
      title: 'Average Decline %',
      dataIndex: 'averageDecline',
      render: (value) => value.toFixed(2)
    },
    {
      title: 'Average Cancel %',
      dataIndex: 'averageCancel',
      render: (value) => value.toFixed(2)
    },
    {
      title: 'Average Cancel By Customer %',
      dataIndex: 'averageCustomerCancel',
      render: (value) => value.toFixed(2)
    },
    {
      title: 'Average Cancel By Internal User %',
      dataIndex: 'averageInternalCancel',
      render: (value) => value.toFixed(2)
    },
    {
      title: 'Average Cancel By System %',
      dataIndex: 'averageSystemCancel',
      render: (value) => value.toFixed(2)
    },
    {
      title: 'Churn Rate %',
      dataIndex: 'churnRate',
      render: (value) => value.toFixed(2)
    }
  ]

  const { history } = useReactRouter()
  const [selectedCampaignId, setSecectedCampaignId] = useState<number>(campaignId)
  const [landingIds, setLandingIds] = useState([])
  const [subscriptionProductId, setSubscriptionProductId] = useState<number>()
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [searchText, setSearchText] = React.useState<string>('')
  const debouncedSearchText = useDebounce(searchText, 500)

  const landingsForOptions = useLandingsOptions({ workspaceId, searchText: debouncedSearchText })

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Subscription stats report error!',
        description: error
      })
    }
  }, [error])

  useEffect(() => {
    if (!subscriptionStatsReport && !subscriptionStatsReportWithoutActive && !error && !isLoading) {
      fetchSubscriptionStatsReport({ withSubtractionActive: true })
      fetchSubscriptionStatsReport({ withSubtractionActive: false })
    }
  }, [subscriptionStatsReport, subscriptionStatsReportWithoutActive, isLoading, error, fetchSubscriptionStatsReport])

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible)
  }

  const handleCampaignChange = (value) => {
    setSecectedCampaignId(value.id)
    setSubscriptionProductId(undefined)
    setLandingIds([])
  }

  const handleLandingChange = (value) => {
    setLandingIds(value)
  }

  const handleFetchSubscriptionStatsReport = () => {
    fetchSubscriptionStatsReport({ campaignId: selectedCampaignId, subscriptionProductId, landingIds, withSubtractionActive: true })
    fetchSubscriptionStatsReport({ campaignId: selectedCampaignId, subscriptionProductId, landingIds, withSubtractionActive: false })
    setDrawerVisible(false)
  }

  const drawerFooter = (
    <div className={styles.drawerFooter}>
      <Button className={styles.okButton} type='primary' loading={isLoading} onClick={handleFetchSubscriptionStatsReport}>
        Ok
      </Button>
      <Button loading={isLoading} onClick={toggleDrawer}>
        Cancel
      </Button>
    </div>
  )

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <Drawer footer={drawerFooter} title='Filters' width='400' onClose={toggleDrawer} visible={drawerVisible}>
          <label className={styles.label}>Take rate range</label>
          <div className={styles.drawerContent}>
            <DatePicker.RangePicker
              className={styles.rangePicker}
              format={dateFormat}
              value={datesRange}
              ranges={{
                Today: [moment().startOf('day'), moment().add(1, 'day').endOf('day')],
                'Last 7 days': [moment().subtract(7, 'days'), moment().startOf('day')],
                'This Week': [moment().startOf('week'), moment().endOf('week')],
                'This Month': [moment().startOf('month'), moment().endOf('month')]
              }}
              onChange={changeDatesRange}
            />

            <label className={styles.label}>Campaign</label>
            <CampaignsSelect
              workspaceId={workspaceId}
              allowClear
              initialOption={{ id: 0, name: 'Select a campaign' }}
              onSelect={handleCampaignChange}
            />

            <label className={styles.label}>Subscription</label>
            <ProductsSelect
              allowClear
              disabled={!campaignId}
              campaignId={+campaignId}
              settingId={0}
              filters={['SUBSCRIPTION']}
              selected={subscriptionProductId}
              initialOption={{
                id: 0,
                name: 'Select a product'
              }}
              onSelect={(selectedOption: SelectOption) => {
                setSubscriptionProductId(selectedOption.id)
              }}
            />

            <label className={styles.label}>Landings</label>
            <Select
              className={styles.landingsSelector}
              allowClear
              disabled={!campaignId}
              filterOption={false}
              value={landingIds}
              mode='multiple'
              onChange={handleLandingChange}
              showSearch
              onSearch={(value) => setSearchText(value)}
            >
              {landingsForOptions?.data?.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
        </Drawer>
        <PageHeader
          onBack={() => history.goBack()}
          title='Subscription Stats'
          extra={[
            <div key='1' className={styles.extraContainer}>
              <Button className={styles.filtersButton} onClick={toggleDrawer}>
                Filters
              </Button>
              <Button type='primary' icon={<ReloadOutlined />} onClick={handleFetchSubscriptionStatsReport} disabled={isLoading}>
                Reload
              </Button>
            </div>
          ]}
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Reports', url: '/' },
            { title: 'Subscrption Stats', url: '/' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <h4 className={styles.title}>With removing active</h4>
        <Table
          columns={columns}
          dataSource={(subscriptionStatsReport && subscriptionStatsReport.cycles) || []}
          bordered
          rowKey={(record) => record.cycle}
          pagination={false}
          loading={isLoading}
        />
        <h4 className={styles.title}>Without removing active</h4>
        <Table
          columns={columns}
          dataSource={(subscriptionStatsReportWithoutActive && subscriptionStatsReportWithoutActive.cycles) || []}
          bordered
          rowKey={(record) => record.cycle}
          pagination={false}
          loading={isLoading}
        />
      </Layout.Content>
    </Layout>
  )
}

export default connector(SubscriptionStatsReport)
