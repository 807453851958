import produce from 'immer'
import * as t from './actionTypes'
import { actionTypes as nt } from 'features/navigation'
import { isSuccessAction, isErrorAction } from 'types'
import { ReportsState } from './types'
import createReducer from 'store/createReducer'
import {
  FetchCampaignTemplatesReportReportAction,
  FetchCustomersDataReportAction,
  FetchCustomersCsvDataReportAction,
  FetchDashboardReportAction,
  FetchFailedFulfillmentCsvReportAction, // TODO - remove becouse of legacy
  FetchFailedFulfillmentReportAction, // TODO - remove becouse of legacy
  FetchSalesFunnelAnalyzeReportAction,
  FetchTopCampaignsRateReportAction,
  FetchTopCampaignsListAction,
  FetchTopLandingsReportAction,
  FetchSubscriptionStatsReportAction,
  FetchCanceledSubscriptionsReportAction,
  SetReportDatesAction,
  UpdateLandingStatusAction,
  UpdateCampaignLandingsFromTemplateAction,
  RedeployCampaignLandingsAction,
  ChangeSubscriptionStatsDatesRangeAction
} from './actions'
import moment from 'moment'

const initState: ReportsState = {
  customersData: {},
  failedFulfillments: {},
  topLandings: {},
  topCampaignsRate: {},
  topCampaigns: {},
  totalAmountRate: 0,
  salvageRate: 0,
  multiPurchaserRate: 0,
  customers: 0,
  totalAmount: 0,
  ordersCount: 0,
  salesForGoogleChart: [],
  salesFunnelAnalyze: {
    chartData: [],
    totalData: []
  },
  canceledSubscriptions: null,
  campaignTemplatesReport: null,
  subscriptionStatsReport: null,
  subscriptionStatsReportWithoutActive: null,
  subscriptionStatsDatesRange: [moment().utc().subtract(1, 'days'), moment().utc().subtract(1, 'days')],
  UIState: {
    startDate: moment().startOf('day'),
    endDate: moment().add(1, 'day').endOf('day'),
    taxesReportDatesRange: [moment().startOf('day'), moment().add(1, 'day').endOf('day')],
    isCustomersDataLoading: false,
    isCustomersCsvDataLoading: false,
    isDashboardReportLoading: false,
    isFailedFulfillmentCsvReportLoading: false, // TODO - remove becouse of legacy
    isFailedFulfillmentReportLoading: false, // TODO - remove becouse of legacy
    isSalesFunnelAnalyzeLoading: false,
    isSalesGraphLoading: false,
    isSalesCsvGraphLoading: false,
    isTopCampaignsRateLoading: false,
    isTopCampaignsLoading: false,
    isTopLandingsLoading: false,
    isCanceledSubscriptionsLoading: false,
    isCampaignTemplatesReportLoading: false,
    isSubscriptionStatsReportLoading: false
  }
}

const reducer = createReducer(initState, {
  [t.FETCH_DASHBOARD_REPORT]: (state, action: FetchDashboardReportAction) =>
    produce(state, (draft) => {
      draft.UIState.isDashboardReportLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isDashboardReportLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.topCampaignsRate = {}
        draft.topCampaigns = {}
        draft.topLandings = {}

        action.payload.topCampaignsRate.forEach((campaignRateRow) => {
          draft.topCampaignsRate[campaignRateRow.id] = campaignRateRow
        })
        action.payload.topCampaigns.forEach((topCampaign) => {
          draft.topCampaigns[topCampaign.id] = topCampaign
        })
        action.payload.topLandings.forEach((topLanding) => {
          draft.topLandings[topLanding.id] = topLanding
        })
        draft.salesForGoogleChart = action.payload.salesForGoogleChart
        draft.salvageRate = action.payload.salvageRate
        draft.multiPurchaserRate = action.payload.multiPurchaserRate
        draft.customers = action.payload.customers
        draft.ordersCount = action.payload.ordersCount
        draft.totalAmount = action.payload.totalAmount
        draft.totalAmountRate = action.payload.totalAmountRate
      }
    }),

  // TODO - remove becouse of legacy
  [t.FETCH_FAILED_FULFILLMENT_REPORT]: (state, action: FetchFailedFulfillmentReportAction) =>
    produce(state, (draft) => {
      draft.UIState.isFailedFulfillmentReportLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isFailedFulfillmentReportLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        action.payload.forEach((item) => {
          draft.failedFulfillments[item.orderId] = item
        })
      }
    }),

  // TODO - remove becouse of legacy
  [t.FETCH_FAILED_FULFILLMENT_CSV_REPORT]: (state, action: FetchFailedFulfillmentCsvReportAction) =>
    produce(state, (draft) => {
      draft.UIState.isFailedFulfillmentCsvReportLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isFailedFulfillmentCsvReportLoading = false
        draft.UIState.error = action.payload
      }
    }),

  [t.FETCH_SALES_FUNNEL_ANALYZE_REPORT]: (state, action: FetchSalesFunnelAnalyzeReportAction) =>
    produce(state, (draft) => {
      draft.UIState.isSalesFunnelAnalyzeLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isSalesFunnelAnalyzeLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.topCampaignsRate = {}
        draft.salesFunnelAnalyze = action.payload
      }
    }),

  [t.FETCH_TOP_CAMPAIGNS_REPORT]: (state, action: FetchTopCampaignsListAction) =>
    produce(state, (draft) => {
      draft.UIState.isTopCampaignsLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isTopCampaignsLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.topCampaigns = {}
        action.payload.forEach((item) => {
          draft.topCampaigns[item.id] = item
        })
      }
    }),

  [t.FETCH_TOP_CAMPAIGNS_RATE_REPORT]: (state, action: FetchTopCampaignsRateReportAction) =>
    produce(state, (draft) => {
      draft.UIState.isTopCampaignsRateLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isTopCampaignsRateLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.topCampaignsRate = {}
        action.payload.forEach((item) => {
          draft.topCampaignsRate[item.id] = item
        })
      }
    }),

  [t.FETCH_TOP_LANDINGS_REPORT]: (state, action: FetchTopLandingsReportAction) =>
    produce(state, (draft) => {
      draft.UIState.isTopLandingsLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isTopLandingsLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.topLandings = {}
        action.payload.forEach((item) => {
          draft.topLandings[item.id] = item
        })
      }
    }),

  [t.FETCH_CUSTOMERS_DATA_REPORT]: (state, action: FetchCustomersDataReportAction) =>
    produce(state, (draft) => {
      draft.UIState.isCustomersDataLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isCustomersDataLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        action.payload.forEach((item) => {
          draft.customersData[item.orderId] = item
        })
      }
    }),

  [t.FETCH_CUSTOMERS_CSV_DATA_REPORT]: (state, action: FetchCustomersCsvDataReportAction) =>
    produce(state, (draft) => {
      draft.UIState.isCustomersCsvDataLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isCustomersCsvDataLoading = false
        draft.UIState.error = action.payload
      }
    }),

  [t.FETCH_CANCELED_SUBSCRIPTIONS_REPORT]: (state, action: FetchCanceledSubscriptionsReportAction) =>
    produce(state, (draft) => {
      draft.UIState.isCanceledSubscriptionsLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isCanceledSubscriptionsLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.canceledSubscriptions = action.payload
      }
    }),

  [t.FETCH_SUBSCRIPTION_STATS_REPORT]: (state, action: FetchSubscriptionStatsReportAction) =>
    produce(state, (draft) => {
      draft.UIState.isSubscriptionStatsReportLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isSubscriptionStatsReportLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (action.payload.withSubtractionActive) {
          draft.subscriptionStatsReport = action.payload
        } else {
          draft.subscriptionStatsReportWithoutActive = action.payload
        }
      }
    }),

  [t.FETCH_CAMPAIGN_TEMPLATES_REPORT]: (state, action: FetchCampaignTemplatesReportReportAction) =>
    produce(state, (draft) => {
      draft.UIState.isCampaignTemplatesReportLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isCampaignTemplatesReportLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.campaignTemplatesReport = action.payload
      }
    }),

  [t.UPDATE_CAMPAIGN_LANDINGS_FROM_TEMPLATE]: (state, action: UpdateCampaignLandingsFromTemplateAction) =>
    produce(state, (draft) => {
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.error = action.payload
      }
    }),

  [t.REDEPLOY_CAMPAIGN_LANDINGS]: (state, action: RedeployCampaignLandingsAction) =>
    produce(state, (draft) => {
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.error = action.payload
      }
    }),

  [t.UPDATE_LANDING_STATUS]: (state, action: UpdateLandingStatusAction) =>
    produce(state, (draft) => {
      draft.campaignTemplatesReport = state.campaignTemplatesReport.map((i) =>
        i.landingId === action.payload.landingId
          ? {
              ...i,
              isActual: action.payload.status
            }
          : i
      )
    }),

  [t.SET_REPORT_DATES]: (state, action: SetReportDatesAction) =>
    produce(state, (draft) => {
      if (action.payload) {
        const { startDate, endDate } = action.payload
        if (startDate) {
          draft.UIState.startDate = startDate
        }
        if (endDate) {
          draft.UIState.endDate = endDate
        }
      }
    }),

  [t.CHANGE_SUBSCRIPTION_STATS_DATES_RANGE]: (state, action: ChangeSubscriptionStatsDatesRangeAction) =>
    produce(state, (draft) => {
      draft.subscriptionStatsDatesRange = action.payload
      draft.subscriptionStatsReport = null
      draft.subscriptionStatsReportWithoutActive = null
    }),

  [nt.LOCATION_CHANGE]: (state) =>
    produce(state, (draft) => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default reducer
