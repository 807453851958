import React, { useState } from 'react'
import { Table, Tag, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { Payment, PaymentStatuses } from '../../types'
import RefundPaymentModal from '../RefundPaymentModal'
import styles from './PaymentsTab.module.scss'

const tagColors: Record<PaymentStatuses, string> = {
  success: 'green',
  failed: 'red',
  void: 'blue',
  refunded: 'volcano'
}

type Props = {
  payments: Payment[]
}

const PaymentsTab = (props: Props) => {
  const { payments } = props
  const [visibleModal, setVisibleModal] = useState(false)
  const [paymentId, setPaymentId] = useState<number>()

  const handleClickRefund = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setPaymentId(+event.currentTarget.dataset.id)
    setVisibleModal(true)
  }

  const columns: ColumnsType<Payment> = [
    {
      title: 'ID',
      render: (_value, record) => {
        return record.total > 0 ? <span>{record.id}</span> : <span style={{ color: 'tomato' }}>{record.id}</span>
      },
      key: 'id'
    },
    {
      title: 'Status',
      key: 'status',
      render: (_value, record) => <Tag color={tagColors[record.status]}>{record.status}</Tag>
    },
    {
      title: 'Transaction ID',
      render: (_value, record) => {
        return record.total > 0 ? (
          <span>{record.externalTransactionId}</span>
        ) : (
          <span style={{ color: 'tomato' }}>{record.externalTransactionId}</span>
        )
      },
      key: 'externalTransactionId'
    },
    {
      title: 'Total',
      render: (_value, record) => {
        return record.total > 0 ? (
          <span>{`$${(record.total / 100).toFixed(2)}`}</span>
        ) : (
          <span style={{ color: 'tomato' }}>{`$${(record.total / 100).toFixed(2)}`}</span>
        )
      },
      key: 'total'
    },
    {
      title: 'Comment',
      key: 'comment',
      dataIndex: 'comment'
    },
    {
      title: 'BIN',
      dataIndex: 'bin',
      key: 'bin'
    },
    {
      title: 'Card last 4 digits',
      dataIndex: 'cardNumber',
      key: 'cardNumber'
    },
    {
      title: 'Expiry date',
      dataIndex: 'cardExpDate',
      key: 'cardExpDate'
    },
    {
      title: 'Action',
      key: 'action',
      render: (_value, record) => {
        let total = 0
        if (record.status === 'success') {
          total = payments
            .filter((payment) => payment.externalTransactionId === record.externalTransactionId)
            .reduce((sym, payment) => sym + payment.total, 0)
        }
        return (
          <>
            {total > 0 && (
              <span onClick={handleClickRefund} data-id={record.id}>
                <a href='/#'>Refund</a>
              </span>
            )}
          </>
        )
      }
    }
  ]

  return (
    <>
      <RefundPaymentModal
        payments={payments}
        visibleModal={visibleModal}
        onCancel={() => setVisibleModal(false)}
        paymentId={paymentId}
      />

      <Table
        columns={columns}
        dataSource={payments}
        bordered
        rowKey={(record) => record.id}
        pagination={false}
        summary={(pageData) => {
          let totalTotal = 0

          pageData.forEach(({ total }) => {
            totalTotal += total
          })

          return (
            <>
              <Table.Summary.Row className={styles.totalRow}>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={1} />
                <Table.Summary.Cell index={2} />
                <Table.Summary.Cell index={3}>
                  <Typography>{`$${(totalTotal / 100).toFixed(2)}`}</Typography>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} />
                <Table.Summary.Cell index={5} />
                <Table.Summary.Cell index={4} />
                <Table.Summary.Cell index={5} />
              </Table.Summary.Row>
            </>
          )
        }}
      />
    </>
  )
}

export default PaymentsTab
