import React, { useState } from 'react'
import { Button, Modal, Input, Typography } from 'antd'
import notification from 'mrx-notification'
import { useToggle } from 'utils/useToggle'
import { useInviteStoreAdmin } from '../../hooks'
import styles from './InviteStoreAdmin.module.scss'

type Props = {
  storeId: number
}

const InviteStoreAdmin: React.FC<Props> = ({ storeId }) => {
  const inviteStoreAdmin = useInviteStoreAdmin()

  const [isModalVisible, toggleModalVisible] = useToggle(false)
  const [storeOwnerEmail, setStoreOwnerEmail] = useState('')

  const handleClickOk = () => {
    if (!storeOwnerEmail) {
      notification.error({ message: 'Email should be selected!', type: 'error' })
      return
    }

    inviteStoreAdmin.mutate(
      {
        storeId,
        email: storeOwnerEmail
      },
      {
        onSuccess: toggleModalVisible,
        onError: (error) => {
          notification.error({
            message: 'Error!',
            description: error.toString()
          })
        }
      }
    )
  }

  return (
    <>
      <div className={styles.buttonContainer}>
        <Button onClick={toggleModalVisible}>Invite admin</Button>
      </div>
      <Modal open={isModalVisible} closable={false} onCancel={toggleModalVisible} onOk={handleClickOk}>
        <Typography.Title level={4} className={styles.title}>
          Invite admin to store
        </Typography.Title>
        <Typography className={styles.typography}>Email</Typography>
        <Input
          className={styles.fields}
          value={storeOwnerEmail}
          onChange={(e) => setStoreOwnerEmail(e.currentTarget.value)}
          disabled={inviteStoreAdmin.isLoading}
        />
      </Modal>
    </>
  )
}

export default React.memo(InviteStoreAdmin)
