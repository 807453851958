import { PayPalSettingsDto } from '@merchx-v2/shared-types/dist/dto/paypalSettings/paypal-settings.dto'
import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type PayPalSettingsForOptionsSelector = (state: ReduxState) => PayPalSettingsDto[]
const getPayPalSettingsForOptions: PayPalSettingsForOptionsSelector = (state) =>
  state.payPalSettings.payPalSettingsForOptions || ([] as PayPalSettingsDto[])

export const payPalSettingsForOptionsSelector: PayPalSettingsForOptionsSelector = createSelector(
  getPayPalSettingsForOptions,
  (payPalSettings) => payPalSettings
)
