// TODO - remove becouse of legacy

import * as components from './components'
import * as hooks from './hooks'
import * as pages from './pages'
import * as types from './types'

console.log('Landings feature initialized!')

export { components, hooks, pages, types }
