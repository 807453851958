// TODO - remove becouse of legacy

import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { FailedFulfillments } from '../types'

export type FetchFailedFulfillmentReportAction = FSA<undefined, FailedFulfillments[], string>
type FetchFailedFulfillmentReport = (campaignId: number) => MrxThunk<FetchFailedFulfillmentReportAction>
type QueryResponse = GraphQLResponse<'failedFulfillmentReport', FailedFulfillments[]>

const FETCH_FAILED_FULFILLMENT_REPORT = `
  query fetchFailedFulfillmentReport ($campaignId: Int!) {
    failedFulfillmentReport(campaignId: $campaignId) {
      date
      orderId
      status
    }
  }
`

const fetchFailedFulfillmentReport: FetchFailedFulfillmentReport = (campaignId) => async (dispatch) => {
  dispatch({
    type: t.FETCH_FAILED_FULFILLMENT_REPORT,
    meta: { done: false }
  })

  try {
    if (!campaignId) {
      throw new Error('Please select campaign!')
    }

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_FAILED_FULFILLMENT_REPORT,
      variables: { campaignId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { failedFulfillmentReport } = data
    if (!failedFulfillmentReport) {
      throw new Error("Can't get Failed Fulfillment report!")
    }

    dispatch({
      type: t.FETCH_FAILED_FULFILLMENT_REPORT,
      payload: failedFulfillmentReport,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_FAILED_FULFILLMENT_REPORT,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchFailedFulfillmentReport }
