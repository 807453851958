import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { AwsSetting } from '../types'

type AwsSettingInput = {
  name: string
  accessKeyId: string
  secretAccessKey: string
  region: string
}

export type CreateAwsSettingAction = FSA<undefined, AwsSetting, string>
type CreateAwsSetting = (awsSettingData: AwsSettingInput) => MrxThunk<CreateAwsSettingAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'createAwsSetting', AwsSetting>

const CREATE_AWS_SETTING = `
  mutation createAwsSetting ($workspaceId: Int!, $awsSettingData: AwsSettingInput!) {
    createAwsSetting (workspaceId: $workspaceId, awsSettingData: $awsSettingData) {
      id
      name
      accessKeyId
    	secretAccessKey
      region
      createdAt
      updatedAt
    }
  }
`

const createAwsSetting: CreateAwsSetting = (awsSettingData) => async (dispatch, getState) => {
  let isSaved = false

  dispatch({
    type: t.CREATE_AWS_SETTING,
    meta: { done: false }
  })

  try {
    const workspaceId = getState()?.workspaces?.activeWorkspace?.id
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_AWS_SETTING,
      variables: { workspaceId, awsSettingData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createAwsSetting } = data
    if (!createAwsSetting) {
      throw new Error("Can't create Aws Setting!")
    }

    dispatch({
      type: t.CREATE_AWS_SETTING,
      payload: createAwsSetting,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.CREATE_AWS_SETTING,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { createAwsSetting }
