import { SettingData } from './types'

export const authorizeSettingsData: SettingData[] = [
  {
    name: 'apiGateway',
    label: 'API Gateway',
    rules: [{ required: true, message: 'Please input API Gateway!' }]
  },
  {
    name: 'apiLoginId',
    label: 'API Login ID',
    rules: [{ required: true, message: 'Please input API login ID!' }]
  },
  {
    name: 'transactionKey',
    label: 'Transaction Key',
    rules: [{ required: true, message: 'Please input transaction key!' }]
  },
  {
    name: 'clientKey',
    label: 'Client Key'
  },
  {
    name: 'signatureKey',
    label: 'Signature Key'
  }
]
