import { StripeSettingsDto } from '@merchx-v2/shared-types/dist/dto/stripeSettings/stripe-settings.dto'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import * as t from '../actionTypes'

type StripeSettingsList = {
  items: StripeSettingsDto[]
  total: number
}

type Payload = StripeSettingsList & {
  page: number
}

export type FetchStripeSettingsListAction = FSA<undefined, Payload, string>
type FetchStripeSettingsList = () => MrxThunk<FetchStripeSettingsListAction>
type QueryResponse = GraphQLResponse<'stripeSettingsListPage', StripeSettingsList>

const FETCH_STRIPE_SETTINGS_LIST = `
  query fetchStripeSettingsList ($page: Int, $size: Int, $filters: StripeSettingsFilters!) {
    stripeSettingsListPage (page: $page, size: $size, filters: $filters) {
      items {
        id
        name
        apiVersion
        host
        port
        secretKey
        publishableKey
        createdAt
        updatedAt
      }
      total
    }
  }
`

const fetchStripeSettingsList: FetchStripeSettingsList = () => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_STRIPE_SETTINGS_LIST,
    meta: { done: false }
  })

  try {
    const {
      stripeSettings: {
        pagination: { currentPage: page, size }
      }
    } = getState()

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_STRIPE_SETTINGS_LIST,
      variables: { page, size, filters: {} }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { stripeSettingsListPage } = data
    if (!stripeSettingsListPage) {
      throw new Error("Can't get Stripe Settings list page!")
    }

    dispatch({
      type: t.FETCH_STRIPE_SETTINGS_LIST,
      payload: {
        page,
        total: stripeSettingsListPage.total,
        items: stripeSettingsListPage.items
      },
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_STRIPE_SETTINGS_LIST,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchStripeSettingsList }
