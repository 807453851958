import { SettingData, CustomRequestInitializer } from './types'


const customRequestInitializer: CustomRequestInitializer = setting => callback => info => {
  const reader = new FileReader()
  reader.readAsText(info.file)
  reader.onload = () => {
    callback(setting, reader.result.toString())
  }
}

export const nginxSettingsData: SettingData[] = [
  {
    name: 'host',
    label: 'Host (IP or domain name)',
    rules: [{ required: true, message: 'Please input Nginx Setting host!' }]
  },
  {
    name: 'port',
    label: 'SSH Port (default 22)'
  },
  {
    name: 'userName',
    label: 'User name',
    rules: [{ required: true, message: 'Please input Nginx Setting secret user name!' }]
  },
  {
    name: 'privateKey',
    label: 'Private key file',
    valuePropName: '',
    rules: [{ required: true, message: 'Please upload file!' }],
    type: 'upload',
    upload: {
      name: 'file',
      accept: '*.pem',
      customRequest: customRequestInitializer('privateKey'),
      showUploadList: false
    }
  }
]
