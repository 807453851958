import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

type SendgridSettingInput = {
  name: string
  apiKey: string
  from: string
  fromName?: string
}

type Payload = {
  sendgridSettingId: number
  sendgridSettingData: SendgridSettingInput
}

export type UpdateSendgridSettingAction = FSA<undefined, Payload, string>

type UpdateSendgridSetting = (args: Payload) => MrxThunk<UpdateSendgridSettingAction, Promise<boolean>>

type QueryResponse = GraphQLResponse<'updateSendgridSetting', boolean>

const UPDATE_SENDGRID_SETTING = `
  mutation updateSendgridSetting ($sendgridSettingId: Int!, $sendgridSettingData: SendgridSettingInput!) {
    updateSendgridSetting (sendgridSettingId: $sendgridSettingId, sendgridSettingData: $sendgridSettingData)
  }
`

const updateSendgridSetting: UpdateSendgridSetting = (args) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.UPDATE_SENDGRID_SETTING,
    meta: { done: false }
  })

  try {
    const { sendgridSettingId, sendgridSettingData } = args
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_SENDGRID_SETTING,
      variables: { sendgridSettingId, sendgridSettingData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateSendgridSetting } = data
    if (!updateSendgridSetting) {
      throw new Error("Can't update Sendgrid setting!")
    }

    dispatch({
      type: t.UPDATE_SENDGRID_SETTING,
      payload: args,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.UPDATE_SENDGRID_SETTING,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { updateSendgridSetting }
