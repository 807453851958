import React, { useState } from 'react'
import { Steps, Button, Card } from 'antd'
import notification from 'mrx-notification'
import styles from './PageWizard.module.scss'
import PageSettings from '../PageSettings'
import { WizardStepStatus, Landing } from 'features/landings/types'
import { isSettingValueValid } from 'utils/isSettingValueDefined'
import { Page } from 'features/pages/types'

type PropsType = {
  pages: Page[]
  landing: Landing
  landingVersion: number

  nextStep?: number
  onNextButtonClick: (nextStep: number) => void
}

const isPageValid = (page: Page) => {
  let isValid = true
  page.settings.forEach((setting) => {
    isValid = isValid && isSettingValueValid(setting)
  })

  return isValid
}

const getPageStatus: (page: Page, currentStep: number, index: number) => WizardStepStatus = (page, currentStep, index) => {
  if (currentStep === index) {
    return 'process'
  }

  if (isPageValid(page)) {
    return 'finish'
  } else if (page.canBeSkippedOnBuild) {
    return 'wait'
  }

  return 'error'
}

const PageWizard = (props: PropsType) => {
  const { pages, landingVersion, landing, nextStep = 4, onNextButtonClick } = props

  const [currentStep, setCurrentStep] = useState(0)

  const handleChangeCurrentStep = (newStep: number) => {
    setCurrentStep(newStep)
  }

  const handleGoToNext = () => {
    if (currentStep < pages.length - 1) {
      setCurrentStep(currentStep + 1)
    } else {
      let isPagesValid = true
      let errorMessage = ''
      pages.forEach((page) => {
        const pageHasCorrectData = isPageValid(page)
        isPagesValid = isPagesValid && pageHasCorrectData
        if (!pageHasCorrectData) {
          errorMessage += `Please fill all settings on page: ${page.name}!\n`
        }
      })

      if (isPagesValid) {
        onNextButtonClick(nextStep)
      } else {
        notification.error({
          message: 'Landing wizard',
          description: errorMessage
        })
      }
    }
  }

  const steps = pages.map((item, index) => {
    return {
      title: item.name,
      content: <PageSettings page={item} landing={landing} landingVersion={landingVersion} />,
      status: getPageStatus(item, currentStep, index)
    }
  })

  return (
    <div className={styles.pageWizardContainer}>
      <div className={styles.stepsContainer}>
        <Steps current={currentStep} size='small' onChange={handleChangeCurrentStep} className={styles.steps} direction='vertical'>
          {steps.map((item) => (
            <Steps.Step key={item.title} title={item.title} status={item.status} />
          ))}
        </Steps>
      </div>
      <Card
        className={styles.card}
        bodyStyle={{ padding: 0 }}
        title={<span className={styles.cardTitle}>Pages</span>}
        extra={[
          onNextButtonClick && (
            <Button key='page-wizard-next-button' type='primary' onClick={handleGoToNext}>
              NEXT
            </Button>
          )
        ]}
      >
        {steps.length && <div className={styles.content}>{steps[currentStep].content}</div>}
      </Card>
    </div>
  )
}

export default PageWizard
