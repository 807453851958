// TODO - remove becouse of legacy

import React, { useContext, useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import InfiniteScroll from 'react-infinite-scroll-component'
import { PageHeader, Table, Layout, Popconfirm, Button, Input, Tooltip } from 'antd'
import { WarningTwoTone, ExclamationCircleTwoTone, DeleteTwoTone, EditTwoTone, FileZipTwoTone } from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table'
import notification from 'mrx-notification'
import { GlobalContext } from 'appContexts'
import { Landing } from 'features/landings/types'
import * as rootStyles from 'assets/layoutStyle'
import { useArchiveLanding, useLandingsList, useRemoveLanding } from '../../hooks'
import styles from './QuizSublandersListPage.module.scss'

type PropsType = {
  showHeader: boolean
}

const QuizSublandersListPage = ({ showHeader = true }: PropsType) => {
  const { campaignId, workspaceId } = useContext(GlobalContext)
  const landingType = 'QUIZ'

  const [searchText, setSearchText] = useState<string>('')
  const { history } = useReactRouter()

  const archiveLanding = useArchiveLanding()
  const removeLanding = useRemoveLanding()
  const landingsList = useLandingsList({ workspaceId, campaignId, searchText, landingType })
  const landings: Landing[] = []
  landingsList && landingsList.data && landingsList.data.pages.forEach((page) => page.items.forEach((landing) => landings.push(landing)))

  const page = landingsList.data?.pages.length ? landingsList.data?.pages[landingsList.data?.pages.length - 1].currentPage : 1
  const size = landingsList.data?.pages.length ? landingsList.data?.pages[landingsList.data?.pages.length - 1].pageSize : 30

  useEffect(() => {
    if (landingsList.error) {
      notification.error({
        message: 'Quiz sublander page error!',
        description: landingsList.error instanceof Error ? landingsList.error.message : landingsList.error.toString()
      })
    }
  }, [landingsList.error])

  const doConfirm = async (landingId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    removeLanding.mutate(landingId, {
      onSuccess: () => {
        notification.success({
          message: 'Successfully',
          description: 'Quiz sublanders List Page was deleted successfully!'
        })
      },
      onError: (error) => {
        notification.error({
          message: 'Error!',
          description: error.toString()
        })
      }
    })
  }

  const doArchive = async (landingId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    archiveLanding.mutate(landingId, {
      onSuccess: () => {
        notification.success({
          message: 'Successfully',
          description: 'Quiz Sublanders was sent to archiving!'
        })
      },
      onError: (error) => {
        notification.error({
          message: 'Error!',
          description: error.toString()
        })
      }
    })
  }

  const onSearchInputChangeHandler = (value) => {
    setSearchText(value)
  }

  const columns: ColumnsType<Landing> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Site URL',
      dataIndex: 'siteUrl',
      render: (siteUrl) => {
        return siteUrl ? (
          <a target='_blank' rel='noopener noreferrer' href={siteUrl} onClick={(e) => e.stopPropagation()}>
            {siteUrl}
          </a>
        ) : (
          ''
        )
      },
      key: 'siteUrl'
    },
    {
      title: 'Status',
      dataIndex: '',
      key: 'status',
      render: (record) => <div className={styles[`status_${record.status}`]}>{record.status}</div>
    },
    {
      title: '',
      render: (record) => (
        <span className={styles.iconContainer}>
          {record.isUpdateAvailable && (
            <Tooltip title='Update available'>
              <WarningTwoTone twoToneColor='#fba905' className={styles.warningIcon} />
            </Tooltip>
          )}
          {record.isNeedToRedeploy && (
            <Tooltip title='Needed redeploy'>
              <ExclamationCircleTwoTone twoToneColor='tomato' className={styles.warningIcon} />
            </Tooltip>
          )}
        </span>
      ),
      width: '10%'
    },
    {
      title: '',
      dataIndex: '',
      key: 'edit',
      width: '10%',
      align: 'center',
      render: (record) => (
        <span>
          <EditTwoTone
            style={{ fontSize: '20px' }}
            onClick={(e) => {
              e.stopPropagation()
              history.push(`quizSublanders/quizSublanderWizard?quizSublanderId=${record.id}&step=0`)
            }}
          />
        </span>
      )
    },
    {
      title: '',
      dataIndex: '',
      key: 'archive',
      width: '10%',
      align: 'center',
      render: (record) => (
        <Popconfirm
          title='Are you sure archive this quiz sublander?'
          onConfirm={(e) => doArchive(record.id, e)}
          onCancel={(e) => e?.stopPropagation()}
          okText='Yes'
          cancelText='No'
        >
          <FileZipTwoTone
            twoToneColor='#8900d9'
            style={{ fontSize: '20px' }}
            onClick={(e) => {
              e.stopPropagation()
            }}
          />
        </Popconfirm>
      )
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '10%',
      align: 'center',
      render: (record) => (
        <Popconfirm
          title='Are you sure delete this quiz sublander?'
          onConfirm={(e) => doConfirm(record.id, e)}
          onCancel={(e) => e?.stopPropagation()}
          okText='Yes'
          cancelText='No'
        >
          <DeleteTwoTone
            twoToneColor='#ec1c24'
            style={{ fontSize: '20px' }}
            onClick={(e) => {
              e.stopPropagation()
            }}
          />
        </Popconfirm>
      )
    }
  ]

  return (
    <Layout style={rootStyles.root}>
      {showHeader && (
        <Layout.Header style={rootStyles.header}>
          <PageHeader
            title='Quiz Sublanders List'
            extra={[
              <Input.Search
                key='search-field'
                placeholder='Input search text'
                onSearch={(value) => onSearchInputChangeHandler(value)}
                style={{ width: 200 }}
                allowClear
              />,
              <Button type='primary' key='create-button' onClick={() => history.push(`/quizSublanders/quizSublanderWizard`)}>
                + CREATE
              </Button>
            ]}
          />
        </Layout.Header>
      )}
      <Layout.Content className={showHeader && styles.contentComponent}>
        <InfiniteScroll
          style={{ paddingBottom: '30px' }}
          dataLength={page * size}
          next={landingsList.fetchNextPage}
          hasMore={!!landingsList.hasNextPage}
          loader={<h4>Loading...</h4>}
          refreshFunction={landingsList.refetch}
          scrollableTarget='colectionScroll'
        >
          <Table
            columns={columns}
            dataSource={landings.map((item) => item).flat()}
            bordered
            rowKey={(record) => record.id}
            loading={landingsList.isLoading}
            pagination={false}
          />
        </InfiniteScroll>
      </Layout.Content>
    </Layout>
  )
}

export default QuizSublandersListPage
