import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

import { Country, CountryInput } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'createCountry', Country>

const CREATE_COUNTRY = `
  mutation createCountry ($countryData: CountryInput!) {
    createCountry(countryData: $countryData) {
      id
      name
      iso2
      iso3
      worldPart
      code
      currencyId 
      currency {
        id
        name
        code
        symbol
      }
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type CreateCountry = (countryData: CountryInput) => Promise<Country>

const createCountry: CreateCountry = async countryData => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_COUNTRY,
      variables: { countryData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createCountry } = data
    if (!createCountry) {
      throw new Error("Can't create country!")
    }

    return createCountry
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type CreateCountryEvents = {
  onSettled: (data?: Country) => void
}

const createCountryEvents: CreateCountryEvents = {
  onSettled: country => {
    if (country?.id) {
      queryClient.invalidateQueries(['countriesList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCreateCountry = () => useMutation(createCountry, createCountryEvents)
