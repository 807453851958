import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import notification from 'mrx-notification'
import { Modal, Button } from 'antd'
import { Loading, Router } from 'components'
import configureStore, { history } from 'store'
import { initializeAxiosInterceptor } from 'utils/axios'
import { GlobalContext } from 'appContexts'
import { loginByToken } from 'features/authentication/actions/loginByToken'
import { queryClient } from '../queryClient'

import './App.css'
import WebSocketWrapper from 'features/websocket/components/WebSocketWrapper'
import { getWebSocket } from 'utils/webSocket'

// Setup redux
const { store: reduxStore } = configureStore()

const App = ({ onUpdateServiceWorkerConfirmed }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [newServiceWorkerDetected, setSWUpdateDetected] = useState(false)
  const [serviceWorkerRegistration, setSWRegistration] = useState(false)

  const [campaignId, setCampaignId] = useState()
  const [workspaceId, setWorkspaceId] = useState()
  const [webSocket] = useState(getWebSocket())

  const globalContextValue = {
    campaignId,
    setCampaignId,
    workspaceId,
    setWorkspaceId,
    webSocket
  }

  const handleAppLoaded = () => {
    setIsLoading(false)
    initializeAxiosInterceptor(reduxStore)
  }

  useEffect(() => {
    // Configure axios
    const token = sessionStorage.getItem('token') || localStorage.getItem('token')

    if (token) {
      reduxStore
        .dispatch(loginByToken(token))
        .then((user) => {
          // console.log('User authorized by token', user)
          setCampaignId(user?.defaultCampaignId)
          setWorkspaceId(user?.activeWorkspace?.id)
          handleAppLoaded()
        })
        .catch((error) => {
          console.log('Auth error', error)
        })
    } else {
      handleAppLoaded()
    }
  }, [])

  const handleNewServiceWorker = (event) => {
    setSWRegistration(event.detail.registration)
    setSWUpdateDetected(true)
  }

  useEffect(() => {
    document.addEventListener('onNewServiceWorker', handleNewServiceWorker)

    return () => {
      document.removeEventListener('onNewServiceWorker', handleNewServiceWorker)
    }
  })

  notification.config({
    placement: 'topCenter'
  })

  if (isLoading) {
    return <Loading />
  }

  return (
    <GlobalContext.Provider value={globalContextValue}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <Provider store={reduxStore}>
          <WebSocketWrapper>
            <ConnectedRouter history={history}>
              <div style={{ width: '100%', height: '100%' }}>
                <Modal
                  title='Update available!'
                  centered
                  visible={newServiceWorkerDetected}
                  footer={[
                    <Button key='back' onClick={() => setSWUpdateDetected(false)}>
                      Later
                    </Button>,
                    <Button key='submit' type='primary' onClick={() => onUpdateServiceWorkerConfirmed(serviceWorkerRegistration)}>
                      Update now
                    </Button>
                  ]}
                >
                  <p>We have a new version of the App. Do you want to apply it?</p>
                  <p> Be carefull, page will be refreshed! You can do it later manually closing all App tabs in a browser.</p>
                </Modal>
                <Router />
              </div>
            </ConnectedRouter>
          </WebSocketWrapper>
        </Provider>
      </QueryClientProvider>
    </GlobalContext.Provider>
  )
}

export const store = reduxStore
export default App
