import produce from 'immer'
import * as t from './actionTypes'
import { actionTypes as nt } from 'features/navigation'
import { actionTypes as wt } from 'features/workspaces'
import { isSuccessAction, isErrorAction } from 'types'
import { AuthorizeSettingsState } from './types'
import createReducer from 'store/createReducer'
import { addItemToPagination } from 'utils/addItemToPagination'
import { removeItemFromPagination } from 'utils/removeItemFromPagination'
import {
  ChangeCurrentPageAction,
  ChangePageSizeAction,
  CreateAuthorizeSettingAction,
  FetchAuthorizeSettingsListAction,
  FetchAuthorizeSettingsForOptionsAction,
  RemoveAuthorizeSettingAction,
  UpdateAuthorizeSettingAction
} from './actions'

const initState: AuthorizeSettingsState = {
  authorizeSettings: {},
  authorizeSettingsForOptions: [],
  pagination: {
    currentPage: 1,
    total: 0,
    pages: {},
    size: 10
  },
  UIState: {
    isAuthorizeSettingsForOptionsLoading: false,
    isCreateAuthorizeSetting: false,
    isListLoading: false,
    isRemoveAuthorizeSetting: false,
    isUpdateAuthorizeSetting: false
  }
}

const reducer = createReducer(initState, {
  [t.CREATE_AUTHORIZE_SETTING]: (state, action: CreateAuthorizeSettingAction) =>
    produce(state, draft => {
      draft.UIState.isCreateAuthorizeSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isCreateAuthorizeSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { id } = action.payload

        draft.authorizeSettings[id] = action.payload
        addItemToPagination(draft.pagination, id)
      }
    }),

  [t.FETCH_AUTHORIZE_SETTINGS_LIST]: (state, action: FetchAuthorizeSettingsListAction) =>
    produce(state, draft => {
      draft.UIState.isListLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isListLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        action.payload.items.forEach(item => {
          draft.authorizeSettings[item.id] = item
        })
        const { page, total, items } = action.payload
        draft.pagination.total = total
        draft.pagination.pages[page] = items.map(item => item.id)
      }
    }),

  [t.FETCH_AUTHORIZE_SETTINGS_FOR_OPTIONS]: (state, action: FetchAuthorizeSettingsForOptionsAction) =>
    produce(state, draft => {
      draft.UIState.isAuthorizeSettingsForOptionsLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isAuthorizeSettingsForOptionsLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.authorizeSettingsForOptions = action.payload
      }
    }),

  [t.REMOVE_AUTHORIZE_SETTING]: (state, action: RemoveAuthorizeSettingAction) =>
    produce(state, draft => {
      draft.UIState.isRemoveAuthorizeSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isRemoveAuthorizeSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.authorizeSettings[action.payload]) {
          delete draft.authorizeSettings[action.payload]
          removeItemFromPagination(draft.pagination, action.payload)
        }
      }
    }),
  [t.UPDATE_AUTHORIZE_SETTING]: (state, action: UpdateAuthorizeSettingAction) =>
    produce(state, draft => {
      draft.UIState.isUpdateAuthorizeSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isUpdateAuthorizeSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.authorizeSettings[action.payload.authorizeSettingId]) {
          const { name, apiGateway, apiLoginId, transactionKey, clientKey, signatureKey } = action.payload.authorizeSettingData
          const setting = draft.authorizeSettings[action.payload.authorizeSettingId]
          setting.name = name
          setting.apiGateway = apiGateway
          setting.transactionKey = transactionKey
          setting.apiLoginId = apiLoginId
          setting.clientKey = clientKey
          setting.signatureKey = signatureKey
        }
      }
    }),

  [t.CHANGE_CURRENT_PAGE]: (state, action: ChangeCurrentPageAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.pagination.currentPage = action.payload
      }
    }),

  [t.CHANGE_PAGE_SIZE]: (state, action: ChangePageSizeAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.pagination.size = action.payload
      }
    }),

  [wt.SWITCH_WORKSPACE]: state =>
    produce(state, draft => {
      draft.pagination = initState.pagination
    }),

  [nt.LOCATION_CHANGE]: state =>
    produce(state, draft => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default reducer
