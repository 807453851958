import React, { useEffect } from 'react'
import { Button, Drawer, Input, Form, Select } from 'antd'
import notification from 'mrx-notification'
import styles from './UpdateProductAttribute.module.scss'
import { connector, PropsFromRedux } from './container'

type Props = PropsFromRedux & {
  productId?: number
  productAttributeId?: number
  onClose: () => void
  visible: boolean
}

const UpdateProductAttribute = (props: Props) => {
  const {
    onClose,
    isLoading,
    productAttribute,
    productId,
    productAttributeId,
    visible,

    fetchProductAttribute,
    updateProductAttribute
  } = props

  const [form] = Form.useForm()

  useEffect(() => {
    if (productId !== undefined) {
      fetchProductAttribute(productId, productAttributeId)
    }
  }, [fetchProductAttribute, productId, productAttributeId])

  useEffect(() => {
    if (productAttribute) {
      form.setFieldsValue({
        productAttribute: productAttribute.attribute,
        value: productAttribute.value
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productAttribute])

  if (!productAttribute) {
    return null
  }

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  // TODO - fix any type
  const onFinishHandler = async (values: any) => {
    const isSaved = await updateProductAttribute({
      productId,
      productAttributeId,
      productAttributeData: {
        attribute: values.productAttribute,
        value: values.value
      }
    })
    if (isSaved) {
      notification.success({
        message: 'Successfully',
        description: 'Product Attribute was updated successfully!'
      })
      handleClose()
    }
  }

  return (
    <Drawer title='Update Product Attribute' width='400' onClose={handleClose} visible={visible}>
      <Form
        layout='vertical'
        hideRequiredMark
        onFinish={onFinishHandler}
        form={form}
        initialValues={{
          productAttribute: productAttribute.attribute,
          value: productAttribute.value
        }}
      >
        <Form.Item name='productAttribute' label='Product Attribute' rules={[{ required: true, message: 'Please select country!' }]}>
          <Select style={{ width: '100%' }}>
            <Select.Option value={productAttribute.attribute} key={productAttribute.id}>
              {productAttribute.attribute}
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item name='value' label='Value' rules={[{ required: true, message: 'Please input attribute value!' }]}>
          <Input style={{ width: '100%' }} />
        </Form.Item>

        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right'
          }}
        >
          <Button onClick={handleClose} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default connector(UpdateProductAttribute)
