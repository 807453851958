// TODO - remove becouse of legacy

import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as filesFeature from 'features/files'
import { Landing } from '../../types'

type OwnProps = {
  landingId?: number
  landing: Landing
  onNextButtonClick: (nextStep: number) => void
  nextStep?: number
}

const mapStateToProps = (state: ReduxState) => {
  return {
    isLoading: filesFeature.selectors.isLoadingSelector(state, 'isUploadFile')
  }
}

const mapDispatchToProps = {
  uploadFile: filesFeature.actions.uploadFile
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
