import React, { useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Button, Input, Layout, PageHeader, Table, Radio } from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import notification from 'mrx-notification'
import { ColumnsType } from 'antd/lib/table'
import { Breadcrumbs } from 'components'
import * as rootStyles from 'assets/layoutStyle'
import { usePromocodesList } from '../../hooks'
import styles from './PromocodesList.module.scss'
import { PromocodeDto } from '@merchx-v2/shared-types'

type PropsType = {
  showHeader?: boolean
  searchQuery?: string
}

const PromocodesList = ({ showHeader = true }: PropsType) => {
  const { history } = useReactRouter()

  const [searchQuery, setSearchQuery] = useState<string>()
  const [promocodesType, setPromocodesType] = useState<'MERCHX' | 'GENERATED'>('MERCHX')

  const promocodesTypeOptions = [
    { label: 'Merchx', value: 'MERCHX' },
    { label: 'Generated', value: 'GENERATED' }
  ]

  const promocodesList = usePromocodesList({ promocode: searchQuery, queryGeneratedPromocodes: promocodesType === 'GENERATED' })

  const promocodes: PromocodeDto[] = []
  promocodesList &&
    promocodesList.data &&
    promocodesList.data.pages.forEach((page) => page.items.forEach((promocode) => promocodes.push(promocode)))

  const page = promocodesList.data?.pages.length ? promocodesList.data?.pages[promocodesList.data?.pages.length - 1].currentPage : 1
  const size = promocodesList.data?.pages.length ? promocodesList.data?.pages[promocodesList.data?.pages.length - 1].pageSize : 30

  useEffect(() => {
    if (promocodesList.error) {
      notification.error({
        message: 'Promocodes error!',
        description: promocodesList.error instanceof Error ? promocodesList.error.message : promocodesList.error.toString()
      })
    }
  }, [promocodesList.error])

  const onSearchInputChangeHandler = (value) => {
    setSearchQuery(value)
  }

  const columns: ColumnsType<PromocodeDto> = [
    {
      title: 'Promocode',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Title',
      dataIndex: 'title',
      render: (_value, record) => record.title.slice(0, 15),
      key: 'title'
    },
    {
      title: 'Condition',
      dataIndex: 'condition',
      key: 'condition'
    },
    {
      title: 'Logic',
      dataIndex: 'logicType',
      key: 'logicType'
    },
    // {
    //   title: 'Availabillities',
    //   dataIndex: 'availabilities',
    //   key: 'availabilities'
    // },
    // {
    //   title: 'Expirations',
    //   dataIndex: 'expirationTypes',
    //   key: 'expirationTypes'
    // },
    {
      title: '',
      dataIndex: '',
      key: 'edit',
      width: '10%',
      align: 'center',
      render: (_value, record) => (
        <span>
          <EditTwoTone
            style={{ fontSize: '20px' }}
            onClick={(e) => {
              e.stopPropagation()
              history.push(`/promocodes/${record.code}`)
            }}
          />
        </span>
      )
    }
  ]

  const handleOnRow = (record: PromocodeDto) => {
    return {
      onClick: () => {
        history.push(`/promocodes/${record.code}/details`)
      }
    }
  }

  return (
    <Layout style={rootStyles.root}>
      {showHeader && (
        <>
          <Layout.Header style={rootStyles.header}>
            <PageHeader
              onBack={() => history.goBack()}
              title='Promocodes'
              extra={[
                <div key='1' className={styles.extraContent}>
                  <Radio.Group
                    options={promocodesTypeOptions}
                    onChange={(event) => setPromocodesType(event.target.value)}
                    value={promocodesType}
                    optionType='button'
                    buttonStyle='solid'
                  />
                  <Input.Search
                    key='search-field'
                    placeholder='Search'
                    onSearch={(value) => onSearchInputChangeHandler(value)}
                    style={{ width: 200, marginLeft: 10 }}
                    allowClear
                  />
                  <div className={styles.createButton}>
                    <Button type='primary' disabled={promocodesType === 'GENERATED'} onClick={() => history.push(`/promocodes/create`)}>
                      + CREATE PROMOCODE
                    </Button>
                  </div>
                </div>
              ]}
            />
          </Layout.Header>
          <Layout.Content style={rootStyles.contentBreadcrumbs}>
            <Breadcrumbs
              items={[
                { title: 'Main', url: '/' },
                { title: 'Campaigns', url: '/' },
                { title: 'Promocodes', url: '/promocodes' }
              ]}
            />
          </Layout.Content>
        </>
      )}
      <Layout.Content className={showHeader && styles.contentComponent} id='pik'>
        <InfiniteScroll
          style={{ paddingBottom: '30px' }}
          dataLength={page * size}
          next={promocodesList.fetchNextPage}
          hasMore={!!promocodesList.hasNextPage}
          loader={<h4>Loading...</h4>}
          refreshFunction={promocodesList.refetch}
          scrollableTarget='promocodeScroll'
        >
          <Table
            columns={columns}
            dataSource={promocodes.map((item) => item).flat()}
            bordered
            rowKey={(record) => record.code}
            onRow={handleOnRow}
            pagination={false}
            loading={promocodesList.isLoading}
          />
        </InfiniteScroll>
      </Layout.Content>
    </Layout>
  )
}

export default PromocodesList
