import { connect, ConnectedProps } from 'react-redux'
import * as filesFeature from 'features/files'

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = {
  uploadFile: filesFeature.actions.uploadFile
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector>
