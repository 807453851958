import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

const getIsNeedToFetchListSelector = (state: ReduxState, countryId: number) => {
  let result = true
  if (Object.prototype.hasOwnProperty.call(state.regions.pagination, countryId)) {
    const { currentPage, pages } = state.regions.pagination[countryId]
    result = !pages[currentPage]
  }
  return result
}

export const isNeedToFetchListSelector = createSelector(getIsNeedToFetchListSelector, (state) => state)

export default isNeedToFetchListSelector
