import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { SalesFunnelAnalyze } from '../types'

type SalesForGoogleChartSelector = (state: ReduxState) => SalesFunnelAnalyze

const getSalesFunnelAnalyzeReportSelector: SalesForGoogleChartSelector = state => {
  return state.reports.salesFunnelAnalyze
}

export const salesFunnelAnalyzeReportSelector: SalesForGoogleChartSelector = createSelector(
  getSalesFunnelAnalyzeReportSelector,
  salesFunnel => salesFunnel
)
