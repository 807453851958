import { createSelector, Selector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { WebsocketState } from '../types'

const aliveStateSelector: Selector<ReduxState, WebsocketState> = state => state.websocket

export const websocketAliveStateSelector = createSelector<ReduxState, WebsocketState, boolean>(
  aliveStateSelector,
  state => state.isAlive
)
