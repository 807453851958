// TODO - remove becouse of legacy

import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { AdSpendReportRow } from '../types'

export type FetchAdSpendReportAction = FSA<undefined, AdSpendReportRow[], string>
type FetchAdSpendReport = (campaignIds: number[]) => MrxThunk<FetchAdSpendReportAction, Promise<void>>
type QueryResponse = GraphQLResponse<'adSpendsReport', AdSpendReportRow[]>

const FETCH_AD_SPENDS_REPORT = `
  query AdSpendsReport($filters: AdSpendsReportFilters!) {
    adSpendsReport(filters: $filters) {
      campaign {
        id,
        name
      }
      sales {
        productTag
        value
      }
      totalAdSpend
      totalClicks
      costPerAction
      costPerClick
      conversionRate
      upsellTakeRate
    }
  }
`

export const fetchAdSpendReport: FetchAdSpendReport = (campaignIds) => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_AD_SPENDS_REPORT,
    meta: { done: false }
  })

  try {
    const workspaceId = getState()?.workspaces?.activeWorkspace?.id
    const { datesRangeReport } = getState()?.adSpends

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_AD_SPENDS_REPORT,
      variables: {
        filters: {
          workspaceId,
          startDate: datesRangeReport[0].format('YYYY-MM-DD'),
          endDate: datesRangeReport[1].format('YYYY-MM-DD'),
          campaignIds
        }
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { adSpendsReport } = data

    if (!adSpendsReport) {
      throw new Error("Can't fetch ad spend report!")
    }

    dispatch({
      type: t.FETCH_AD_SPENDS_REPORT,
      payload: adSpendsReport,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_AD_SPENDS_REPORT,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}
