import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Table, Typography, Form, Drawer, Input, Button, Popconfirm, Select } from 'antd'
import hash from 'object-hash'
import { ColumnsType } from 'antd/lib/table'
import moment from 'moment'
import { convertToSku } from 'utils/convertToSku'
import styles from './OrderItemsTabManual.module.scss'
import { Order, OrderItem } from '../../types'
import OrderItemProductsSelect from 'features/products/components/OrderItemProductsSelect'
import { ProductOption, ProductPrice } from 'features/types'
import { useProduct } from 'features/products/hooks'
import UpdateOrderItem from '../UpdateOrderItem'

type PropsType = {
  order: Order
  campaignId?: number
  productId?: number
  visible: boolean
  onClose: () => void
  onOrderItemsChanged: (products: OrderItem[]) => void
}

const OrderItemsTabManual = (props: PropsType) => {
  const { campaignId, order, visible, onClose, onOrderItemsChanged } = props

  const [selectedProductId, setSelectedProductId] = useState<number>()
  const [selectedProductPrice, setSelectedProductPrice] = useState<ProductPrice>()
  const [orderItems, setOrderItems] = useState(order.orderItems.filter((item: OrderItem) => item.status === 'ACTIVE') || [])
  const [selectedOrderItem, setSelectedOrderItem] = useState<OrderItem>()
  const [activeOrderItemRowIndex, setActiveOrderItemRowIndex] = useState<number>()
  const [isUpdateDrawerVisible, setIsUpdateDrawerVisible] = useState<boolean>(false)

  const [variantKeys, setVariantKeys] = useState<SelectOption[]>([])
  const [variantPrices, setVariantPrices] = useState<Record<string, ProductPrice>>({})

  const [form] = Form.useForm()

  const product = useProduct({
    productPriceOwnerType: order.landingId ? 'LANDING' : 'CAMPAIGN',
    productPriceOwnerId: order.landingId || campaignId,
    productId: selectedProductId,
    campaignId
  })

  useEffect(() => {
    if (order && order.orderItems.length) {
      setOrderItems(order.orderItems.filter((item: OrderItem) => item.status === 'ACTIVE'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(order)])

  useEffect(() => {
    const prices = product?.data?.prices?.length ? product.data.prices : product?.data?.campaignPrices

    if (prices) {
      const newVariantPrices: Record<string, ProductPrice> = {}
      const newVariantKeys: SelectOption[] = []
      for (const productPrice of prices) {
        const variantKey = productPrice.variantKey.toUpperCase()
        newVariantPrices[variantKey] = productPrice
        newVariantKeys.push({ id: +variantKey, name: variantKey })
      }

      setVariantPrices(newVariantPrices)
      setVariantKeys(newVariantKeys)
    } else {
      setVariantPrices({})
      setVariantKeys([])
    }

    if (prices?.length === 1) {
      setSelectedProductPrice(prices[0])
    }

    if (product?.data?.productType) {
      if (['STICKER', 'TOTEBAG', 'FACE_MASK', 'TSHIRT', 'HOODIE', 'CREW'].includes(product.data.productType)) {
        const newPrice = prices[0]
        setSelectedProductPrice(newPrice)
        form.setFieldsValue({
          sku: newPrice?.sku,
          price: ((newPrice?.price || 0) / 100).toFixed(2),
          discount: ((newPrice?.discount || 0) / 100).toFixed(2),
          shipping: !orderItems.length || !order?.landing?.applyShippingOnce ? ((order?.landing?.shippingCost || 0) / 100).toFixed(2) : 0,
          processing:
            !orderItems.length || !order?.landing?.applyProcessingOnce ? ((order?.landing?.processingCost || 0) / 100).toFixed(2) : 0
        })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(product || {})])

  const handleRemoveOrderItem = async (productId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    const newOrderItems = orderItems.filter((item) => item.productId !== productId)
    setOrderItems(newOrderItems)
    onOrderItemsChanged(newOrderItems)
  }

  const handleClose = () => {
    form.resetFields()
    setVariantKeys([])
    setVariantPrices({})
    setSelectedProductPrice(undefined)
    setSelectedProductId(undefined)
    onClose()
  }

  const handleVariantKeyChanged = (newVariantKey: string) => {
    const newPrice = variantPrices[newVariantKey]
    if (newPrice) {
      setSelectedProductPrice(newPrice)
      form.setFieldsValue({
        sku: newPrice.sku,
        price: (newPrice.price / 100).toFixed(2),
        discount: (newPrice.discount / 100).toFixed(2),
        shipping: !orderItems.length || !order?.landing?.applyShippingOnce ? ((order?.landing?.shippingCost || 0) / 100).toFixed(2) : 0,
        processing:
          !orderItems.length || !order?.landing?.applyProcessingOnce ? ((order?.landing?.processingCost || 0) / 100).toFixed(2) : 0
      })
    }
  }

  const handleOnFormValuesChange = (values: any) => {
    if (values.sku) {
      form.setFieldsValue({ sku: convertToSku(values.sku) })
    }
  }

  const handleProductSelected = (value: ProductOption) => {
    form.setFieldsValue({
      productId: value.id,
      displayName: value.name
    })
    setVariantKeys([])
    setVariantPrices({})
    setSelectedProductId(typeof value.id === 'string' ? +value.id : value.id)
  }

  const onCreateProductFinishHandler = (values: any) => {
    const newOrderItems = [
      ...orderItems,
      {
        ...values,
        description: product.data.description,
        quantity: parseInt(values.quantity),
        productId: selectedProductId,
        productPriceId: selectedProductPrice.id,
        price: Math.round(values.price * 100) || 0,
        discount: Math.round(values.discount * 100) || 0,
        shipping: Math.round(values.shipping * 100) || 0,
        processing: Math.round(values.processing * 100) || 0
      }
    ]
    setOrderItems(newOrderItems)
    onOrderItemsChanged(newOrderItems)
    handleClose()
  }

  const handleOnRow = (record: OrderItem, index: number) => {
    if (record.id) {
      return
    }
    return {
      onClick: () => {
        setSelectedProductId(record.productId)
        setSelectedOrderItem(record)
        setIsUpdateDrawerVisible(true)
        setActiveOrderItemRowIndex(index)
      }
    }
  }

  const handleOnClose = () => {
    setIsUpdateDrawerVisible(false)
  }

  const updateOrderItemHandler = (newValues) => {
    const newOrderItems = orderItems.map((item) => ({ ...item }))
    const itemToUpdate = newOrderItems[activeOrderItemRowIndex]
    itemToUpdate.productPriceId = newValues.productPriceId
    itemToUpdate.displayName = newValues.displayName
    itemToUpdate.sku = newValues.sku
    itemToUpdate.quantity = parseInt(newValues.quantity)
    itemToUpdate.price = Math.round(newValues.price * 100) || 0
    itemToUpdate.discount = Math.round(newValues.discount * 100) || 0
    itemToUpdate.shipping = Math.round(newValues.shipping * 100) || 0
    itemToUpdate.processing = Math.round(newValues.processing * 100) || 0

    setOrderItems(newOrderItems)
    onOrderItemsChanged(newOrderItems)
  }

  const columns: ColumnsType<OrderItem> = [
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'displayName'
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku'
    },
    {
      title: 'Store',
      render: (record) => (record.store ? <Link to={`/stores/${record.store.id}/edit`}>{record.store.name}</Link> : ''),
      key: 'storeId'
    },
    {
      title: 'Date added',
      render: (_value, record) => `${record.createdAt ? moment(record.createdAt).format('MM/DD/YYYY HH:mm:ss') : ''}`,
      key: 'createdAt'
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: 'Price',
      render: (_value, record) => `$${(record?.price / 100).toFixed(2)}`,
      key: 'price'
    },
    {
      title: 'Discount',
      render: (_value, record) => `$${(record?.discount / 100).toFixed(2)}`,
      key: 'discount'
    },
    {
      title: 'Shipping',
      render: (_value, record) => `$${(record?.shipping / 100).toFixed(2)}`,
      key: 'shipping'
    },
    {
      title: 'Processing',
      render: (_value, record) => `$${(record?.processing / 100).toFixed(2)}`,
      key: 'processing'
    },
    {
      title: 'Total',
      render: (_value, record) =>
        `$${(((record?.price + record?.processing + record?.shipping - record?.discount) * record?.quantity) / 100).toFixed(2)}`,
      key: 'total'
    },
    {
      title: 'Action',
      key: 'x',
      render: (_value, record) => (
        <span>
          <Popconfirm
            title='Are you sure delete this product?'
            onConfirm={(e) => handleRemoveOrderItem(record.productId, e)}
            onCancel={(e) => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <a
              href='/#'
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              Remove
            </a>
          </Popconfirm>
        </span>
      )
    }
  ]

  return (
    <>
      {selectedOrderItem && (
        <UpdateOrderItem
          onClose={handleOnClose}
          visible={isUpdateDrawerVisible}
          orderItem={selectedOrderItem}
          campaignId={campaignId}
          landingId={order.landingId}
          updateOrderItemHandler={updateOrderItemHandler}
        />
      )}
      <Table
        columns={columns}
        rowClassName={(e) => (e.id ? styles.disabledRow : '')}
        dataSource={orderItems}
        bordered
        rowKey={(record) => record.id || record.productId + 'pp' + record.productPriceId}
        pagination={false}
        onRow={handleOnRow}
        summary={(pageData) => {
          let totalShipping = 0
          let totalProcessing = 0
          let totalTotal = 0

          pageData.forEach(({ shipping, processing, price, discount, quantity }) => {
            totalShipping += shipping
            totalProcessing += processing
            totalTotal += (price + shipping + processing - discount) * quantity
          })

          return (
            <>
              <Table.Summary.Row className={styles.totalRow}>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={1} />
                <Table.Summary.Cell index={2} />
                <Table.Summary.Cell index={3} />
                <Table.Summary.Cell index={4} />
                <Table.Summary.Cell index={5}>
                  <Typography>{`$${((order?.promocodeDiscount || 0) / 100).toFixed(2)}`}</Typography>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  <Typography>{`$${((totalShipping || 0) / 100).toFixed(2)}`}</Typography>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7}>
                  <Typography>{`$${((totalProcessing || 0) / 100).toFixed(2)}`}</Typography>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8}>
                  <Typography>{`$${((totalTotal || 0) / 100).toFixed(2) || 0}`}</Typography>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9} />
              </Table.Summary.Row>

              <Drawer title='Create Product' width='400' onClose={handleClose} visible={visible} bodyStyle={{ marginBottom: '60px' }}>
                <Form
                  layout='vertical'
                  hideRequiredMark
                  onFinish={onCreateProductFinishHandler}
                  onValuesChange={handleOnFormValuesChange}
                  form={form}
                  initialValues={{
                    quantity: 1
                  }}
                >
                  <Form.Item name='productId' label='Product' rules={[{ required: true, message: 'Please select product!' }]}>
                    <OrderItemProductsSelect
                      campaignId={campaignId || order?.landing?.campaign?.id}
                      initialOption={{
                        id: 0,
                        name: 'Select a product'
                      }}
                      onSelect={(selectedOption: ProductOption) => handleProductSelected(selectedOption)}
                    />
                  </Form.Item>

                  {variantKeys.length > 1 && (
                    <Form.Item name='variantKey' label='Variant' rules={[{ required: true, message: 'Please select variant!' }]}>
                      <Select onChange={(newVariant) => handleVariantKeyChanged(newVariant)}>
                        {variantKeys.map((item) => (
                          <Select.Option key={item.name} value={item.name}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}

                  <Form.Item name='displayName' rules={[{ required: false }]} style={{ display: 'none' }}>
                    <Input type='hidden' />
                  </Form.Item>

                  <Form.Item name='sku' label='SKU' rules={[{ required: true, message: 'Please input product SKU!' }]}>
                    <Input disabled />
                  </Form.Item>

                  <Form.Item name='quantity' label='Quantity' rules={[{ required: true, message: 'Please input product quantity!' }]}>
                    <Input type='number' />
                  </Form.Item>

                  <Form.Item name='price' label='Price' rules={[{ required: true, message: 'Please input product price!' }]}>
                    <Input type='number' />
                  </Form.Item>

                  <Form.Item name='discount' label='Discount' rules={[{ required: true, message: 'Please input product discount!' }]}>
                    <Input type='number' />
                  </Form.Item>

                  <Form.Item name='shipping' label='Shipping' rules={[{ required: true, message: 'Please input product shipping cost!' }]}>
                    <Input type='number' />
                  </Form.Item>

                  <Form.Item
                    name='processing'
                    label='Processing'
                    rules={[{ required: true, message: 'Please input product processing cost!' }]}
                  >
                    <Input type='number' />
                  </Form.Item>

                  <div className={styles.buttonsContainer}>
                    <Button onClick={handleClose} className={styles.cancelButton}>
                      Close
                    </Button>
                    <Button type='primary' htmlType='submit' disabled={!selectedProductPrice}>
                      Create
                    </Button>
                  </div>
                </Form>
              </Drawer>
            </>
          )
        }}
      />
    </>
  )
}

export default OrderItemsTabManual
