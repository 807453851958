import { createSelector, ParametricSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { ProductAttribute } from '../types'

const getProductAttribute: ParametricSelector<ReduxState, number | undefined, ProductAttribute | undefined> = (
  state,
  productId,
  productAttributeId
) => {
  let result
  if (productId && Object.prototype.hasOwnProperty.call(state.productAttributes.productAttributes, productId)) {
    if (
      productAttributeId &&
      Object.prototype.hasOwnProperty.call(state.productAttributes.productAttributes[productId], productAttributeId)
    ) {
      result = state.productAttributes.productAttributes[productId][productAttributeId] as ProductAttribute
    }
  }

  return result
}

export const productAttributeSelector = createSelector(getProductAttribute, (productAttribute) => productAttribute)
