import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

type Payload = {
  categoryId: number
  campaignId: number
}

export type RemoveCategoryAction = FSA<undefined, Payload, string>

type RemoveCategory = (categoryId: number, campaignId: number) => MrxThunk<RemoveCategoryAction, Promise<boolean>>

type QueryResponse = GraphQLResponse<'removeCategory', boolean>

const REMOVE_CATEGORY = `
  mutation RemoveCategory($categoryId: Int!) {
    removeCategory(categoryId: $categoryId)
  }
`

const removeCategory: RemoveCategory = (categoryId, campaignId) => async (dispatch) => {
  let isRemoved = false

  dispatch({
    type: t.REMOVE_CATEGORY,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_CATEGORY,
      variables: { categoryId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeCategory } = data
    if (!removeCategory) {
      throw new Error("Can't remove category!")
    }

    dispatch({
      type: t.REMOVE_CATEGORY,
      payload: { categoryId, campaignId },
      meta: { done: true }
    })

    isRemoved = true
  } catch (err) {
    dispatch({
      type: t.REMOVE_CATEGORY,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isRemoved
}

export { removeCategory }
