// TODO - remove becouse of legacy

import { connect, ConnectedProps } from 'react-redux'
import { subscribeToChannel, unsubscribeFromChannel } from 'features/websocket/actions'
import { Build } from '../../types'

type OwnProps = {
  landingId: number
  builds: Build[]
  version: number
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  subscribeToChannel,
  unsubscribeFromChannel
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
