// import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import numeral from 'numeral'
import moment from 'moment'
import { StorePayoutDto } from '@merchx-v2/shared-types'
// import { Tag } from 'antd'

// const tagColors: Record<StorePayoutStatusType, string> = {
//   REQUESTED: 'orange',
//   PROCESSED: 'green',
//   DECLINED: 'red'
// }

export const createTableColumns = (): ColumnsType<StorePayoutDto> => {
  return [
    {
      title: 'ID',
      dataIndex: 'id'
    },
    // {
    //   title: 'Status',
    //   key: 'status',
    //   width: 300,
    //   render: (_value, record) => <Tag color={tagColors[record.status]}>{record.status}</Tag>
    // },
    {
      title: 'Payment Method',
      dataIndex: 'paymentMethod'
    },
    {
      title: 'Note',
      dataIndex: 'Note'
    },
    {
      title: 'Period',
      render: (record) => `${moment(record.periodStart).format('MM/DD/YYYY')} - ${moment(record.periodEnd).format('MM/DD/YYYY')}`,
      key: 'periodStart',
      width: '420px'
    },
    {
      title: 'Amount',
      render: (_value, record) => numeral(record.sum / 100).format('$0,0.00'),
      dataIndex: 'sum'
    },
    {
      title: 'Date',
      render: (record) => moment(record.processedAt).format('MM/DD/YYYY HH:mm a'),
      key: 'processedAt',
      width: '200px'
    }
  ]
}
