import * as t from './actionTypes'
import { actionTypes as wt } from 'features/workspaces'
import { actionTypes as nt } from 'features/navigation'
import produce from 'immer'
import { isSuccessAction, isErrorAction } from 'types'
import { SimpleTextSettingsState } from './types'
import createReducer from 'store/createReducer'
import { addItemToPagination } from 'utils/addItemToPagination'
import { removeItemFromPagination } from 'utils/removeItemFromPagination'
import {
  ChangeCurrentPageAction,
  CreateSimpleTextSettingAction,
  FetchSimpleTextSettingsListAction,
  FetchSimpleTextSettingsForOptionsAction,
  RemoveSimpleTextSettingAction,
  UpdateSimpleTextSettingAction
} from './actions'

const initState: SimpleTextSettingsState = {
  simpleTextSettings: {},
  simpleTextSettingsForOptions: [],
  pagination: {
    currentPage: 1,
    total: 0,
    pages: {},
    size: 10
  },
  UIState: {
    isCreateSimpleTextSetting: false,
    isListLoading: false,
    isRemoveSimpleTextSetting: false,
    isSimpleTextSettingsForOptionsLoading: false,
    isUpdateSimpleTextSetting: false
  }
}

const reducer = createReducer(initState, {
  [t.CREATE_SIMPLE_TEXT_SETTING]: (state, action: CreateSimpleTextSettingAction) =>
    produce(state, draft => {
      draft.UIState.isCreateSimpleTextSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isCreateSimpleTextSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { id } = action.payload
        draft.simpleTextSettings[id] = action.payload
        addItemToPagination(draft.pagination, id)
      }
    }),

  [t.FETCH_SIMPLE_TEXT_SETTINGS_LIST]: (state, action: FetchSimpleTextSettingsListAction) =>
    produce(state, draft => {
      draft.UIState.isListLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isListLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        action.payload.items.forEach(item => {
          draft.simpleTextSettings[item.id] = item
        })
        const { page, total, items } = action.payload
        draft.pagination.total = total
        draft.pagination.pages[page] = items.map(item => item.id)
      }
    }),

  [t.FETCH_SIMPLE_TEXT_SETTINGS_FOR_OPTIONS]: (state, action: FetchSimpleTextSettingsForOptionsAction) =>
    produce(state, draft => {
      draft.UIState.isSimpleTextSettingsForOptionsLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isSimpleTextSettingsForOptionsLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.simpleTextSettingsForOptions = action.payload
      }
    }),

  [t.REMOVE_SIMPLE_TEXT_SETTING]: (state, action: RemoveSimpleTextSettingAction) =>
    produce(state, draft => {
      draft.UIState.isRemoveSimpleTextSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isRemoveSimpleTextSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.simpleTextSettings[action.payload]) {
          delete draft.simpleTextSettings[action.payload]
          removeItemFromPagination(draft.pagination, action.payload)
        }
      }
    }),
  [t.UPDATE_SIMPLE_TEXT_SETTING]: (state, action: UpdateSimpleTextSettingAction) =>
    produce(state, draft => {
      draft.UIState.isUpdateSimpleTextSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isUpdateSimpleTextSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.simpleTextSettings[action.payload.simpleTextSettingId]) {
          draft.simpleTextSettings[action.payload.simpleTextSettingId].name = action.payload.simpleTextSettingData.name
          draft.simpleTextSettings[action.payload.simpleTextSettingId].apiToken = action.payload.simpleTextSettingData.apiToken
        }
      }
    }),

  [t.CHANGE_CURRENT_PAGE]: (state, action: ChangeCurrentPageAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.pagination.currentPage = action.payload
      }
    }),

  [wt.SWITCH_WORKSPACE]: state =>
    produce(state, draft => {
      draft.pagination = initState.pagination
    }),

  [nt.LOCATION_CHANGE]: state =>
    produce(state, draft => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default reducer
