import axios from 'utils/shoppingCartAxios'
import { useMutation } from 'react-query'
import { PromocodeDto } from '@merchx-v2/shared-types'
import { queryClient } from 'queryClient'
import { extractResponseError } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type Args = {
  code: string
  imageUrl: string
}

type UpdatePromocodeImage = (args: Args) => Promise<PromocodeDto>

const updatePromocodeImage: UpdatePromocodeImage = async (args) => {
  try {
    const response = await axios.post(`/promocodes/${args.code}/set-image/`, { imageUrl: args.imageUrl })

    const data: PromocodeDto = response.data

    if (!data) {
      throw new Error("Can't update promocode!")
    }

    return data
  } catch (err) {
    extractResponseError(err)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UpdatePromocodeImageEvents = {
  onSettled: (data?: PromocodeDto) => void
}

const updatePromocodeImageEvents: UpdatePromocodeImageEvents = {
  onSettled: (promocode) => {
    if (promocode?.code) {
      queryClient.invalidateQueries(['promocodes'])
      queryClient.setQueryData(['promocode', promocode.code], promocode)
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUpdatePromocodeImage = () => useMutation((args: Args) => updatePromocodeImage(args), updatePromocodeImageEvents)
