// TODO - remove becouse of legacy

import React, { useContext, useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import { PageHeader, Layout, Steps, Popconfirm, Button } from 'antd'
import notification from 'mrx-notification'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { GlobalContext } from 'appContexts'
import { Breadcrumbs, Loading } from 'components'
import * as rootStyles from 'assets/layoutStyle'
import { AutoresponderWizard } from 'features/autoresponders/components'
import * as settingsFeature from 'features/settings'
import * as pageFeature from 'features/pages'
import { LandingWizardInitials, LandingWizardTemplates } from '../../components'
import { useCampaign } from 'features/campaigns/hooks'
import { useLanding, useUpdateLandingFromTemplate } from '../../hooks'
import { WizardStepStatus, StepState } from '../../types'
import * as validations from '../../helpers'
import DuplicateLanding from '../DuplicateLanding'
import styles from './SublanderWizard.module.scss'
import { PropsFromRedux, connector } from './container'

const { SettingsForLanding } = settingsFeature.components
const { PageWizard } = pageFeature.components

const defaultStep = {
  isValid: false,
  status: 'wait' as WizardStepStatus
}

const activeStep = {
  isValid: false,
  status: 'process' as WizardStepStatus
}

const SublanderWizard = (props: PropsFromRedux) => {
  const { landingId, currentStep } = props

  const [initialsStep, setInitialsStep] = useState<StepState>(currentStep === 0 ? activeStep : defaultStep)
  const [templatesStep, setTemplatesStep] = useState<StepState>(currentStep === 2 ? activeStep : defaultStep)
  const [settingsStep, setSettingsStep] = useState<StepState>(currentStep === 3 ? activeStep : defaultStep)
  const [pagesStep, setPagesStep] = useState<StepState>(currentStep === 4 ? activeStep : defaultStep)
  const [autorespondersStep, setAutorespondersStep] = useState<StepState>(currentStep === 5 ? activeStep : defaultStep)
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)

  const { history } = useReactRouter()

  const { campaignId } = useContext(GlobalContext)

  const campaign = useCampaign(campaignId)
  const landing = useLanding(+landingId)
  const updateLandingFromTemplate = useUpdateLandingFromTemplate()

  useEffect(() => {
    if (landing.error) {
      notification.error({
        message: 'Sublander wizard error',
        description: landing.error instanceof Error ? landing.error.message : landing.error.toString()
      })
    }
  }, [landing.error])

  const validateForm = () => {
    setInitialsStep(validations.getInitialsStep(landing.data, currentStep))
    setTemplatesStep(validations.getTemplatesStep(landing.data, currentStep))
    setSettingsStep(validations.getSettingsStep(landing.data, currentStep))
    setPagesStep(validations.getPagesStep(landing.data, currentStep))
    setAutorespondersStep(validations.getAutorespondersStep(landing.data, currentStep))
  }

  useDeepCompareEffect(() => {
    if (landing) {
      validateForm()
    }

    if (landing?.data?.status === 'DEPRECATED') {
      history.push({
        // @ts-ignore
        pathname: props.match.url,
        search: `?landingId=${landingId}&step=7`
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landing])

  useEffect(() => {
    validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep])

  const handleChangeCurrentStep = (nextStep: number, newLandingId?: number) => {
    let canGoToNextStep = true

    let message = ''
    if (nextStep > 0) {
      if (!initialsStep.isValid && !newLandingId) {
        canGoToNextStep = false
        message = 'You should fill the Initial values wizard step!\n'
      }
    }

    if (nextStep > 2) {
      if (!initialsStep.isValid) {
        canGoToNextStep = false
        message += 'You should fill the Templates wizard step!\n'
      }
    }

    if (nextStep > 3) {
      if (!settingsStep.isValid) {
        canGoToNextStep = false
        message += '\nYou should fill the Settings wizard step!\n'
      }
    }

    if (nextStep > 4) {
      if (!pagesStep.isValid) {
        canGoToNextStep = false
        message += '\nYou should fill the Pages wizard step!\n'
      }
    }

    if (nextStep > 5) {
      if (!autorespondersStep.isValid) {
        canGoToNextStep = false
        message += '\nYou should fill the Autoresponders wizard step!\n'
      }
    }

    if (canGoToNextStep) {
      history.push({
        pathname: `/sublanders/sublanderWizard`,
        search: `?sublanderId=${newLandingId || landingId}&step=${nextStep}`
      })
    } else {
      notification.error({
        message: 'Sublander wizard',
        description: message
      })
    }
  }

  const steps = [
    {
      title: 'Main settings',
      status: initialsStep.status,
      disabled: landing?.data?.status === 'DEPRECATED',
      content: campaignId ? (
        <LandingWizardInitials
          landingId={+landingId}
          landingType='SUBLANDER'
          campaignId={campaignId}
          landing={landing.data}
          onNextButtonClick={handleChangeCurrentStep}
        />
      ) : null
    },

    {
      title: 'Select template',
      status: templatesStep.status,
      disabled: landing?.data?.status === 'DEPRECATED',
      content: landing.data && (
        <LandingWizardTemplates templateType='SUBLANDER' landing={landing?.data} onNextButtonClick={handleChangeCurrentStep} />
      )
    },
    {
      title: 'Additional settings',
      status: settingsStep.status,
      disabled: landing?.data?.status === 'DEPRECATED',
      // @ts-ignore
      content: landing.data ? (
        <SettingsForLanding ownerType='LANDING' ownerId={+landingId} landing={landing?.data} onNextButtonClick={handleChangeCurrentStep} />
      ) : null
    },
    {
      title: 'Pages settings',
      status: pagesStep.status,
      disabled: landing?.data?.status === 'DEPRECATED',
      content:
        landingId && landing.data && landing?.data?.version ? (
          <PageWizard
            pages={landing?.data?.pages || []}
            landing={landing?.data}
            landingVersion={landing?.data?.version}
            onNextButtonClick={handleChangeCurrentStep}
          />
        ) : null
    },
    {
      title: 'Autoresponders',
      status: autorespondersStep.status,
      disabled: landing?.data?.status === 'DEPRECATED',
      content: landing?.data ? <AutoresponderWizard autoresponders={landing?.data?.autoresponders || []} landing={landing?.data} /> : null
    }
  ]

  const handleOnClose = () => {
    setIsDrawerVisible(false)
  }

  return (
    <Layout style={rootStyles.root} className={styles.baseContainer}>
      {/* {isDuplicateInProgress && (
        <div className={styles.blockPage}>
          <div className={styles.blockPageContainer}>
            <p className={styles.blockPageTitle}>
              Landing is duplicating <span onClick={() => history.goBack()}>Go back</span>
            </p>
            <Progress percent={duplicateLandingJob?.progress as number} size='default' status='active' />
          </div>
        </div>
      )} */}
      {campaign?.data?.status === 'MIGRATING' && (
        <div className={styles.blockPage}>
          <div className={styles.blockPageContainer}>
            <p className={styles.blockPageTitle}>
              Campaign is moving to another workspace <span onClick={() => history.goBack()}>Go back</span>
            </p>
          </div>
        </div>
      )}
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title='Sublander Wizard'
          extra={[
            <Button key='duplicate-button' onClick={() => setIsDrawerVisible(true)}>
              Duplicate Sublander
            </Button>,
            <Popconfirm
              key='update-from-template'
              title='Sure update from the source template?'
              onConfirm={() =>
                updateLandingFromTemplate.mutate(+landingId, {
                  onError: (error) => {
                    notification.error({
                      message: 'Sublander wizard error',
                      description: error.toString()
                    })
                  }
                })
              }
            >
              <Button>Update from template</Button>
            </Popconfirm>
          ]}
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Sublander', url: '/sublanders' },
            { title: 'Sublander Wizard', url: `sublanders/sublanderWizard?landingId=${landingId}&step=0` }
          ]}
        />
      </Layout.Content>
      {landing.isLoading && <Loading />}
      {!landing.isLoading && (
        <>
          <div className={styles.stepsContainer}>
            <Steps current={currentStep} status='process' size='small' onChange={handleChangeCurrentStep} className={styles.steps}>
              {steps.map((item) => (
                <Steps.Step
                  key={item.title}
                  title={item.title}
                  disabled={item.disabled}
                  status={item.status as 'error' | 'finish' | 'wait' | 'process'}
                />
              ))}
            </Steps>
          </div>
          <div className={styles.content}>{steps[currentStep].content}</div>
        </>
      )}
      {landingId && campaignId && isDrawerVisible && (
        <DuplicateLanding landingId={+landingId} campaignId={campaignId} visible={isDrawerVisible} onClose={handleOnClose} />
      )}
    </Layout>
  )
}

export default connector(SublanderWizard)
