import { PayPalSettingsDto } from '@merchx-v2/shared-types/dist/dto/paypalSettings/paypal-settings.dto'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import * as t from '../actionTypes'

type PayPalSettingsList = {
  items: PayPalSettingsDto[]
  total: number
}

type Payload = PayPalSettingsList & {
  page: number
}

export type FetchPayPalSettingsListAction = FSA<undefined, Payload, string>
type FetchPayPalSettingsList = () => MrxThunk<FetchPayPalSettingsListAction>
type QueryResponse = GraphQLResponse<'payPalSettingsListPage', PayPalSettingsList>

const FETCH_PAYPAL_SETTINGS_LIST = `
  query fetchPayPalSettingsList ($page: Int, $size: Int, $filters: PayPalSettingsFilters!) {
    payPalSettingsListPage (page: $page, size: $size, filters: $filters) {
      items {
        id
        name
        accountEmail
        clientId
        secretKey
        accessToken
        createdAt
        updatedAt
      }
      total
    }
  }
`

const fetchPayPalSettingsList: FetchPayPalSettingsList = () => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_PAYPAL_SETTINGS_LIST,
    meta: { done: false }
  })

  try {
    const {
      payPalSettings: {
        pagination: { currentPage: page, size }
      }
    } = getState()

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_PAYPAL_SETTINGS_LIST,
      variables: { page, size, filters: {} }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { payPalSettingsListPage } = data
    if (!payPalSettingsListPage) {
      throw new Error("Can't get PayPal Settings list page!")
    }

    dispatch({
      type: t.FETCH_PAYPAL_SETTINGS_LIST,
      payload: {
        page,
        total: payPalSettingsListPage.total,
        items: payPalSettingsListPage.items
      },
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_PAYPAL_SETTINGS_LIST,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchPayPalSettingsList }
