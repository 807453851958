import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Table, Layout, Tag, Progress, Popconfirm, Modal, Button } from 'antd'
import { CloseOutlined, RedoOutlined } from '@ant-design/icons'
import notification from 'mrx-notification'
import useInterval from 'utils/useInterval'
import formatDuration from 'utils/formatDuration'
import styles from './TasksQueueListForProductTab.module.scss'
import { connector, PropsFromRedux } from './container'
import { Task } from '../../types'
import { ColumnProps } from 'antd/es/table'

const tagColors = {
  NEW: 'cyan',
  PROCESSED: 'blue',
  IN_PROGRESS: 'gold',
  CANCELED: 'volcano',
  COMPLETED: 'green'
}

type TasksQueueListForProductTab = PropsFromRedux & {
  productId?: number
}

const canRestart = ['IN_PROGRESS', 'PROCESSED', 'CANCELED', 'COMPLETED']
const canCancel = ['NEW', 'PROCESSED', 'IN_PROGRESS']

const TasksQueueListForProductTab = (props: TasksQueueListForProductTab) => {
  const {
    error,
    tasksList,
    currentPage,
    total,

    productId,

    cancelTask,
    changeCurrentPage,
    fetchTasksList,
    restartTask
  } = props

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState<Task>()
  const [now, setNow] = useState(moment())

  useEffect(() => {
    fetchTasksList(undefined, undefined, 'PRODUCT', productId)
  }, [fetchTasksList, productId])

  useInterval(() => {
    fetchTasksList(undefined, undefined, 'PRODUCT', productId)
  }, 15000)

  useInterval(() => {
    setNow(moment())
  }, 1000)

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Tasks queue list page error!',
        description: error
      })
    }
  }, [error])

  const doConfirmCancel = async (taskId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    const isCanceled = await cancelTask(taskId)
    if (isCanceled) {
      notification.success({
        message: 'Successfully',
        description: 'Task was canceled successfully!'
      })
    }
  }

  const doConfirmRestart = async (taskId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    const isRestarted = await restartTask(taskId)
    if (isRestarted) {
      notification.success({
        message: 'Successfully',
        description: 'Task was restarted successfully!'
      })
    }
  }

  const handleOnRow = (record: Task) => {
    return {
      onClick: () => {
        if (record.message) {
          setIsModalVisible(true)
          setSelectedRecord(record)
        }
      }
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const columns: ColumnProps<Task>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Status',
      render: (record: Task) => {
        return <Tag color={tagColors[record.status]}>{record.status}</Tag>
      },
      key: 'status'
    },
    {
      title: 'Task Type',
      render: (record: Task) => {
        const taskType = (record.taskType.charAt(0).toUpperCase() + record.taskType.slice(1).toLowerCase()).replace(/_/g, ' ')
        const payloadTaskType = record.payload?.taskType
          ? (record.payload?.taskType?.charAt(0).toUpperCase() + record.payload?.taskType?.slice(1).toLowerCase()).replace(/_/g, ' ')
          : ''
        return taskType + (payloadTaskType ? ` (${payloadTaskType})` : '')
      },
      key: 'type'
    },
    {
      title: 'Progress',
      render: (record: Task) => {
        return <Progress percent={record.progress} size='small' status='active' />
      },
      key: 'progress'
    },
    {
      title: 'Retries',
      render: (record: Task) => {
        let color = 'green'
        if (record.retryNumber === 2) {
          color = 'orange'
        } else if (record.retryNumber === 3) {
          color = 'volcano'
        }
        return <Tag color={color}>{record.retryNumber}</Tag>
      }
    },
    {
      title: 'Elapsed Time',
      render: (record: Task) => {
        if (record.startedAt !== null) {
          const start = moment(record.startedAt)
          const end = record.endedAt !== null ? moment(record.endedAt) : now
          const showMilliseconds = record.endedAt !== null
          const duration = moment.duration(end.diff(start))
          return <div>{duration ? formatDuration(duration, showMilliseconds) : ''}</div>
        }
      },
      key: 'elapsedTime'
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (record: Task) => (
        <div className={styles.actionIcons}>
          {canRestart.includes(record.status) && (
            <span>
              <Popconfirm
                title='Are you sure restart this task?'
                onConfirm={(e) => doConfirmRestart(record.id, e)}
                onCancel={(e) => e?.stopPropagation()}
                okText='Yes'
                cancelText='No'
              >
                <RedoOutlined
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                />
              </Popconfirm>
            </span>
          )}
          {canCancel.includes(record.status) && (
            <span>
              <Popconfirm
                title='Are you sure cancel this task?'
                onConfirm={(e) => doConfirmCancel(record.id, e)}
                onCancel={(e) => e?.stopPropagation()}
                okText='Yes'
                cancelText='No'
              >
                <CloseOutlined
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                />
              </Popconfirm>
            </span>
          )}
        </div>
      )
    }
  ]

  return (
    <div>
      <Layout.Content className={styles.contentComponent}>
        <Table
          columns={columns}
          dataSource={tasksList}
          bordered
          rowKey={(record) => record.id}
          onRow={handleOnRow}
          pagination={{
            onChange: changeCurrentPage,
            current: currentPage,
            total
          }}
        />
      </Layout.Content>

      <Modal
        title='Task Message'
        visible={isModalVisible}
        footer={[
          <Button key='submit' type='primary' onClick={handleCancel}>
            OK
          </Button>
        ]}
        onCancel={handleCancel}
      >
        {selectedRecord?.message ? selectedRecord.message : 'No message!'}
      </Modal>
    </div>
  )
}

export default connector(TasksQueueListForProductTab)
