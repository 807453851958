import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Category } from '../types'

type CategoryFilters = {
  searchText?: string
  workspaceId: number
  campaignId: number
  limit?: number
}

export type FetchCategoriesForOptionsAction = FSA<undefined, Category[], string>
type FetchCategorysForOptions = (filters: CategoryFilters) => MrxThunk<FetchCategoriesForOptionsAction>
type QueryResponse = GraphQLResponse<'categoriesForOptions', Category[]>

const FETCH_CATEGORIES_FOR_OPTIONS = `
  query categoriesForOptions($filters: CategoriesFilters!) {
    categoriesForOptions(filters: $filters) {
      id
      name
    }
  }
`

const fetchCategoriesForOptions: FetchCategorysForOptions = (filters) => async (dispatch) => {
  dispatch({
    type: t.FETCH_CATEGORIES_FOR_OPTIONS,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_CATEGORIES_FOR_OPTIONS,
      variables: { filters: { ...filters } }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { categoriesForOptions } = data
    if (!categoriesForOptions) {
      throw new Error("Can't get categories!")
    }

    dispatch({
      type: t.FETCH_CATEGORIES_FOR_OPTIONS,
      payload: categoriesForOptions,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_CATEGORIES_FOR_OPTIONS,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchCategoriesForOptions }
