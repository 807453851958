import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { SendgridSetting } from '../types'

type SendgridSettingSelector = (state: ReduxState, sendgridSettingId?: number) => SendgridSetting | undefined

const getSendgridSetting: SendgridSettingSelector = (state, sendgridSettingId) => {
  let result
  if (sendgridSettingId && Object.prototype.hasOwnProperty.call(state.sendgridSettings.sendgridSettings, sendgridSettingId)) {
    result = state.sendgridSettings.sendgridSettings[sendgridSettingId] as SendgridSetting
  }
  return result
}

export const sendgridSettingSelector: SendgridSettingSelector = createSelector(getSendgridSetting, (sendgridSetting) => sendgridSetting)
