import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

const mapStateToProps = (state: ReduxState) => {
  return {
    productTags: selectors.productTagsListSelector(state),
    error: selectors.errorSelector(state),
    isLoading: selectors.isLoadingSelector(state, 'isListLoaded'),
    total: selectors.totalProductTagsSelector(state),
    currentPage: selectors.currentPageSelector(state),
    isNeedToFetchList: selectors.isNeedToFetchListSelector(state)
  }
}

const mapDispatchToProps = {
  fetchProductTagsList: actions.fetchProductTagsList,
  changeCurrentPage: actions.changeCurrentPage,
  removeProductTag: actions.removeProductTag
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector>
