import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { CustomerSubscriptionNote } from '../types'

type CustomerSubscriptionNoteInput = {
  workspaceId: number
  customerSubscriptionId: number
  note: string
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'createCustomerSubscriptionNote', CustomerSubscriptionNote>

const CREATE_CUSTOMER_SUBSCRIPTION_NOTE = `
  mutation createCustomerSubscriptionNote ($customerSubscriptionNoteData: CustomerSubscriptionNoteInput!) {
    createCustomerSubscriptionNote(customerSubscriptionNoteData: $customerSubscriptionNoteData) {
      id
      workspaceId
      customerSubscriptionId
      userId
      author {
        id
        email
        avatar
        firstName
        lastName
        createdAt
      }
      note
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type CreateCustomerSubscriptionNote = (customerSubscriptionNoteData: CustomerSubscriptionNoteInput) => Promise<CustomerSubscriptionNote>

const createCustomerSubscriptionNote: CreateCustomerSubscriptionNote = async (customerSubscriptionNoteData) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_CUSTOMER_SUBSCRIPTION_NOTE,
      variables: { customerSubscriptionNoteData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createCustomerSubscriptionNote } = data
    if (!createCustomerSubscriptionNote) {
      throw new Error("Can't create customer subscription note!")
    }

    return createCustomerSubscriptionNote
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type CreateCustomerSubscriptionNoteEvents = {
  onSettled: (data?: CustomerSubscriptionNote) => void
}

const createCustomerSubscriptionNoteEvents: CreateCustomerSubscriptionNoteEvents = {
  onSettled: (customerSubscriptionNote) => {
    if (customerSubscriptionNote?.id) {
      queryClient.invalidateQueries(['customerSubscription'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCreateCustomerSubscriptionNote = () => useMutation(createCustomerSubscriptionNote, createCustomerSubscriptionNoteEvents)
