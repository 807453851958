import { StripeSettingsDto, CreateStripeSettingDto } from '@merchx-v2/shared-types/dist/dto/stripeSettings'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import * as t from '../actionTypes'

export type CreateStripeSettingAction = FSA<undefined, StripeSettingsDto, string>
type CreateStripeSetting = (stripeSettingData: CreateStripeSettingDto) => MrxThunk<CreateStripeSettingAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'createStripeSetting', StripeSettingsDto>

const CREATE_STRIPE_SETTING = `
  mutation createStripeSetting ($stripeSettingData: StripeSettingInput!) {
    createStripeSetting (stripeSettingData: $stripeSettingData) {
      id
      name
      apiVersion
      host
      port
      secretKey
      publishableKey
      createdAt
      updatedAt
    }
  }
`

const createStripeSetting: CreateStripeSetting = (stripeSettingData) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.CREATE_STRIPE_SETTING,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_STRIPE_SETTING,
      variables: { stripeSettingData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createStripeSetting } = data
    if (!createStripeSetting) {
      throw new Error("Can't create Stripe Setting!")
    }

    dispatch({
      type: t.CREATE_STRIPE_SETTING,
      payload: createStripeSetting,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.CREATE_STRIPE_SETTING,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { createStripeSetting }
