import produce from 'immer'
import * as t from './actionTypes'
import { actionTypes as nt } from 'features/navigation'
import { actionTypes as wt } from 'features/workspaces'
import { isSuccessAction, isErrorAction } from 'types'
import { ProductsState } from './types'
import createReducer from 'store/createReducer'
import { addItemToPagination } from 'utils/addItemToPagination'
import { removeItemFromPagination } from 'utils/removeItemFromPagination'
import {
  ChangeCurrentPageAction,
  ChangePageSizeAction,
  CreateProductAction,
  DuplicateProductAction,
  FetchProductAction,
  FetchProductsListAction,
  FetchOrderItemProductsForOptionsAction,
  RemoveProductAction,
  SaveSubscriptionDataAction,
  UpdateProductAction,
  SetActiveProductAction,
  FetchProductAttributesForOptionsAction,
  ProductsLoadedAction,
  UpdateProductSubscriptionsAction,
  FetchProductsListForOldTableAction
} from './actions'

const initState: ProductsState = {
  campaignProducts: {},
  options: {},
  productAvailableAttributeValues: [],
  orderItemsProductsForOptions: {},
  pagination: {},
  UIState: {
    isCreateProduct: false,
    isDuplicateProduct: false,
    isFetchProduct: false,
    isFetchProductsForOptions: false,
    isListLoading: false,
    isRemoveProduct: false,
    isSetProductPrices: false,
    isUpdateProduct: false,
    isUpdatePrices: false,
    isUpdateVirtualProduct: false,
    isSetActiveProduct: false
  }
}

const setDefaults = (draft: ProductsState, campaignId: number, settingId?: number) => {
  if (!campaignId) {
    throw new Error('CampaignId undefined')
  }

  if (!draft.campaignProducts[campaignId]) {
    draft.campaignProducts[campaignId] = {}
  }

  if (!draft.pagination[campaignId]) {
    draft.pagination[campaignId] = {
      pages: {},
      total: 0,
      currentPage: 1,
      size: 6
    }
  }

  if (!draft.options[campaignId]) {
    draft.options[campaignId] = {}
  }

  if (settingId) {
    if (!draft.options[campaignId][settingId]) {
      draft.options[campaignId][settingId] = []
    }
  }

  if (!draft.orderItemsProductsForOptions[campaignId]) {
    draft.orderItemsProductsForOptions[campaignId] = []
  }
}

const reducer = createReducer(initState, {
  [t.CREATE_PRODUCT]: (state, action: CreateProductAction) =>
    produce(state, (draft) => {
      draft.UIState.isCreateProduct = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isCreateProduct = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { items } = action.payload

        for (const item of items) {
          const { campaignId, id } = item
          setDefaults(draft, campaignId)
          draft.campaignProducts[campaignId][id] = item
          draft.campaignProducts[campaignId][id].customShippingPrice = item.customShippingPrice / 100
          addItemToPagination(draft.pagination[campaignId], item.id)
        }
      }
    }),

  [t.DUPLICATE_PRODUCT]: (state, action: DuplicateProductAction) =>
    produce(state, (draft) => {
      draft.UIState.isDuplicateProduct = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isDuplicateProduct = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { items } = action.payload

        for (const item of items) {
          const { campaignId, id } = item
          setDefaults(draft, campaignId)
          draft.campaignProducts[campaignId][id] = item
          draft.campaignProducts[campaignId][id].customShippingPrice = item.customShippingPrice / 100
          addItemToPagination(draft.pagination[campaignId], item.id)
        }
      }
    }),

  [t.FETCH_PRODUCT]: (state, action: FetchProductAction) =>
    produce(state, (draft) => {
      draft.UIState.isFetchProduct = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isFetchProduct = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { campaignId, id } = action.payload
        setDefaults(draft, campaignId)
        draft.campaignProducts[campaignId][id] = action.payload
        draft.campaignProducts[campaignId][id].customShippingPrice = action.payload.customShippingPrice / 100
      }
    }),

  [t.PRODUCTS_LOADED]: (state, action: ProductsLoadedAction) =>
    produce(state, (draft) => {
      if (action.payload) {
        const { products } = action.payload
        for (const loadedProduct of products) {
          const { id, campaignId } = loadedProduct
          setDefaults(draft, campaignId)
          draft.campaignProducts[campaignId][id] = loadedProduct
          draft.campaignProducts[campaignId][id].customShippingPrice = loadedProduct.customShippingPrice / 100
        }
      }
    }),

  [t.FETCH_PRODUCTS_LIST]: (state, action: FetchProductsListAction) =>
    produce(state, (draft) => {
      draft.UIState.isListLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isListLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (action.payload) {
          const { filters, page } = action.payload
          const { campaignId } = filters
          setDefaults(draft, campaignId)
          action.payload.items.forEach((item) => {
            draft.campaignProducts[campaignId][item.id] = item
          })
          draft.pagination[campaignId].total = action.payload.total
          draft.pagination[campaignId].pages[page] = action.payload.items.map((item) => item.id)
        }
      }
    }),

  [t.FETCH_PRODUCTS_LIST_FOR_OLD_TABLE]: (state, action: FetchProductsListForOldTableAction) =>
    produce(state, (draft) => {
      draft.UIState.isListLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isListLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { campaignId, page } = action.payload
        setDefaults(draft, campaignId)
        action.payload.items.forEach((item) => {
          draft.campaignProducts[campaignId][item.id] = item
        })
        draft.pagination[campaignId].total = action.payload.total
        draft.pagination[campaignId].pages[page] = action.payload.items.map((item) => item.id)
      }
    }),

  [t.FETCH_ORDER_ITEM_PRODUCTS_FOR_OPTIONS]: (state, action: FetchOrderItemProductsForOptionsAction) =>
    produce(state, (draft) => {
      draft.UIState.isFetchProductsForOptions = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isFetchProductsForOptions = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { campaignId, options } = action.payload
        setDefaults(draft, campaignId)
        draft.orderItemsProductsForOptions[campaignId] = options
      }
    }),

  [t.FETCH_PRODUCT_AVAILABLE_ATTRIBUTE_VALUES]: (state, action: FetchProductAttributesForOptionsAction) =>
    produce(state, (draft) => {
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.productAvailableAttributeValues = action.payload
      }
    }),

  [t.REMOVE_PRODUCT]: (state, action: RemoveProductAction) =>
    produce(state, (draft) => {
      draft.UIState.isRemoveProduct = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isRemoveProduct = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { filters, items } = action.payload
        const { campaignId } = filters

        for (const item of items) {
          const { itemId: productId } = item
          if (draft.campaignProducts[campaignId]) {
            if (draft.campaignProducts[campaignId][productId]) delete draft.campaignProducts[campaignId][productId]
            removeItemFromPagination(draft.pagination[campaignId], productId)
          }
        }
      }
    }),

  [t.UPDATE_PRODUCT]: (state, action: UpdateProductAction) =>
    produce(state, (draft) => {
      draft.UIState.isUpdateProduct = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isUpdateProduct = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { filters, item: productData } = action.payload
        const { campaignId, productId } = filters
        setDefaults(draft, campaignId)
        if (draft.campaignProducts[campaignId][productId]) {
          draft.campaignProducts[campaignId][productId].name = productData.name
          draft.campaignProducts[campaignId][productId].storeId = productData.storeId
          draft.campaignProducts[campaignId][productId].displayName = productData.displayName
          draft.campaignProducts[campaignId][productId].description = productData.description
          draft.campaignProducts[campaignId][productId].productType = productData.productType
          draft.campaignProducts[campaignId][productId].sku = productData.sku
          draft.campaignProducts[campaignId][productId].tags = productData.tags
          draft.campaignProducts[campaignId][productId].hasCustomShipping = productData.hasCustomShipping
          draft.campaignProducts[campaignId][productId].customShippingPrice = productData.customShippingPrice / 100
          draft.campaignProducts[campaignId][productId].attributes = productData.attributes
          draft.campaignProducts[campaignId][productId].isActive = productData.isActive
        }
      }
    }),

  [t.SET_ACTIVE_PRODUCT]: (state, action: SetActiveProductAction) =>
    produce(state, (draft) => {
      draft.UIState.isSetActiveProduct = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isSetActiveProduct = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { filters, item: productData } = action.payload
        const { campaignId, productId } = filters
        setDefaults(draft, campaignId)
        if (draft.campaignProducts[campaignId][productId]) {
          draft.campaignProducts[campaignId][productId].isActive = productData.isActive
        }
      }
    }),

  [t.UPDATE_VIRTUAL_PRODUCT]: (state, action: UpdateProductAction) =>
    produce(state, (draft) => {
      draft.UIState.isUpdateVirtualProduct = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isUpdateVirtualProduct = false
        draft.UIState.error = action.payload
      }
    }),

  [t.CHANGE_CURRENT_PAGE]: (state, action: ChangeCurrentPageAction) =>
    produce(state, (draft) => {
      if (action.payload) {
        const { campaignId, page } = action.payload
        draft.pagination[campaignId].currentPage = page
      }
    }),

  [t.CHANGE_PAGE_SIZE]: (state, action: ChangePageSizeAction) =>
    produce(state, (draft) => {
      if (action.payload) {
        const { campaignId, size } = action.payload
        setDefaults(draft, campaignId)
        draft.pagination[campaignId].size = size
        // draft.pagination[campaignId].currentPage = 1
      }
    }),

  [t.SAVE_SUBSCRIPTION_DATA]: (state, action: SaveSubscriptionDataAction) =>
    produce(state, (draft) => {
      if (isErrorAction(action)) {
        draft.UIState.error = action.payload
      }
    }),

  [t.UPDATE_PRODUCT_SUBSCRIPTIONS]: (state, action: UpdateProductSubscriptionsAction) =>
    produce(state, (draft) => {
      const { filters, item } = action.payload
      const { campaignId } = filters
      const { id: productId, subscriptionItems } = item

      draft.campaignProducts[campaignId][productId] = {
        ...state.campaignProducts[campaignId][productId],
        subscriptionItems
      }
    }),

  [wt.SWITCH_WORKSPACE]: (state) =>
    produce(state, (draft) => {
      draft.pagination = initState.pagination
    }),

  [nt.LOCATION_CHANGE]: (state) =>
    produce(state, (draft) => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default reducer
