import React, { useEffect } from 'react'
import Table, { ColumnsType } from 'antd/lib/table'
import { Button } from 'antd'
import { Address } from 'features/addresses/types'
import { AddressValidation } from '../../types'
import { AddressValidationDto } from '@merchx-v2/shared-types'
import { useConfirmCorrectedAddress } from '../../hooks'
import styles from './ShippingAddressTab.module.scss'
import notification from 'mrx-notification'

type Props = {
  address?: Address
  addressValidation?: AddressValidation
  orderId: number
}

const ShippingAddressTab = ({ address, addressValidation, orderId }: Props) => {
  const confirmCorrectedAddress = useConfirmCorrectedAddress()

  const addressColumns: ColumnsType<AddressValidation> = [
    {
      title: '',
      dataIndex: 'type'
    },
    {
      title: 'Country',
      render: (_value, record) => record.id === 2 && !record.city ? '' : address?.country?.name,
      key: 'country'
    },
    {
      title: 'Address 1',
      render: (value) => value.address || value.address1,
      key: 'adddress1'
    },
    {
      title: 'Address 2',
      dataIndex: 'address2'
    },
    {
      title: 'City',
      dataIndex: 'city'
    },
    {
      title: 'State',
      render: (value) => value.state || value.stateIso2,
      key: 'state'
    },
    {
      title: 'Zip Code',
      dataIndex: 'zipCode'
    },
    {
      title: 'Plus 4 Code',
      dataIndex: 'plus4Code'
    },
    {
      title: 'Action',
      render: (value) =>
        value === 2 && addressValidation?.changes && !addressValidation?.reasonsForBad ? (
          <Button
            loading={confirmCorrectedAddress.isLoading}
            onClick={() => confirmCorrectedAddress.mutate(orderId)}
          >
            Confirm
          </Button>
        ) : (
          ''
        ),
      dataIndex: 'id'
    }
  ]

  useEffect(() => {
    if (confirmCorrectedAddress.error) {
      notification.error({
        message: 'Error!',
        description:
          confirmCorrectedAddress.error instanceof Error ? confirmCorrectedAddress.error.message : confirmCorrectedAddress.error.toString()
      })
    }
  }, [confirmCorrectedAddress.error])

  useEffect(() => {
    if (confirmCorrectedAddress.isSuccess && confirmCorrectedAddress.data) {
      notification.success({
        message: 'Successfully',
        description: 'Address was updated successfully!'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmCorrectedAddress.isSuccess])

  const uspsResponseColumns: ColumnsType<AddressValidationDto> = [
    {
      title: 'USPS Response Changes',
      render: (_value, record) => <pre className={styles.notes}>{record.changes}</pre>,
      key: 'changes'
    },
    {
      title: 'Errors',
      render: (_value, record) => <pre className={styles.notes}>{record.reasonsForBad}</pre>,
      key: 'reasonsForBad'
    }
  ]

  const addressSource = [
    { ...address, type: 'Current', id: 1 },
    { ...addressValidation, type: 'USPS Response', id: 2 }
  ]

  const uspsResponseSource = addressValidation ? [addressValidation] : []
  return (
    <>
      <Table
        bordered
        columns={addressColumns}
        tableLayout='fixed'
        dataSource={addressSource}
        pagination={false}
        rowKey={(record: any) => record.id}
      />
      <Table
        bordered
        columns={uspsResponseColumns}
        tableLayout='fixed'
        dataSource={uspsResponseSource}
        pagination={false}
        rowKey={(record: any) => record.id}
      />
    </>
  )
}

export default ShippingAddressTab
