import axios from 'utils/axios'
import { useMutation } from 'react-query'
import hash from 'object-hash'

import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { queryClient } from 'queryClient'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'sendPayout', boolean>

const SEND_PAYOUT = `
  mutation SendPayout ($storeId: Int!, $sum: Int!, $periodStart: DateTime!, $periodEnd: DateTime!, $paymentMethod: String, $note: String) {
    sendPayout(storeId: $storeId, sum: $sum, periodStart: $periodStart, periodEnd: $periodEnd, paymentMethod: $paymentMethod, note: $note)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type SendPayoutArgs = {
  storeId: number
  sum: number
  periodStart: Date
  periodEnd: Date
  paymentMethod?: string
  note?: string
}

type SendPayout = (args: SendPayoutArgs) => Promise<SendPayoutArgs>

const sendPayout: SendPayout = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: SEND_PAYOUT,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { sendPayout } = data
    if (!sendPayout) {
      throw new Error("Can't decrease store balance!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type SendPayoutEvents = {
  onSettled: (data?: SendPayoutArgs) => void
}

const sendPayoutEvents: SendPayoutEvents = {
  onSettled: (data) => {
    if (data?.storeId) {
      queryClient.invalidateQueries(['storePayoutsPage', hash({ storeId: data.storeId })])
      queryClient.invalidateQueries(['storeProfitsPage', hash({ storeId: data.storeId })])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useSendPayout = () => useMutation(sendPayout, sendPayoutEvents)
