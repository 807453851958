// TODO - remove becouse of legacy

import axios from 'utils/axios'
import { useQuery } from 'react-query'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Template } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'template', Template>

const FETCH_TEMPLATE = `
query fetchTemplate($templateId: Int!) {
  template(templateId: $templateId) {
    id
    name
    workspaceId
    buildType
    version
    templateType
    route
    assets(ownerType: TEMPLATE) {
      id
      name
      extension
      ownerType
      ownerId
      mimeType
      s3bucket
      s3key
      signedUrl
      createdAt
      updatedAt
    }
    autoresponders(ownerType: TEMPLATE) {
      id
      ownerType
      ownerId
      autoresponderType
      sourceCode
      orderItemSourceCode
      useAlternativeEmailing
      subjectSourceCode
      version
      fromName
      settings {
        id
        ownerType
        ownerId
        name
        alias
        type
        value
        required
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    components(ownerType: TEMPLATE) {
      id
      name
      ownerType
      ownerId
      sourceCode
      sourceStyle
      createdAt
      updatedAt
    }
    dependencies(ownerType: TEMPLATE) {
      id
      name
      ownerType
      ownerId
      version
      createdAt
      updatedAt
    }
    pages(ownerType: TEMPLATE) {
      id
      name
      route
      order
      sourceCode
      sourceStyle
      actionsCode
      reducerCode
      goalName
      settings {
        id
        ownerType
        ownerId
        name
        alias
        type
        value
        required
        filters
        createdAt
        updatedAt
      }
      canBeSkippedOnBuild
      createdAt
      updatedAt
    }
    settings {
      id
      ownerType
      ownerId
      name
      alias
      type
      value
      required
      createdAt
      updatedAt
    }
    builds {
      id
      templateId
      version
      progress
      status
      hashRouterArtifactsS3path
      browserRouterArtifactsS3path
      logs
      startedAt
      endedAt
      buildTime
    }
    mainPreviewThumbImageId
    mainPreviewImageId
    previewImage1Id
    previewImage2Id
    previewImage3Id
    repository
    repositoryProvider
    createdAt
    updatedAt
  }
}
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchTemplate = (templateId: number) => Promise<Template>

const fetchTemplate: FetchTemplate = async (templateId) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_TEMPLATE,
      variables: { templateId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { template } = data
    if (!template) {
      throw new Error("Can't get template!")
    }

    return template
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useTemplate = (templateId: number) => useQuery(['template', templateId], () => fetchTemplate(templateId))
