import { useInfiniteQuery } from 'react-query'
import axios from 'utils/axios'
import { ShippingRulesPageDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

const DEFAULT_PAGE_SIZE = 30

/// ////////////////////////////////////////////////////////////////////////////////
// TYPES
/// ////////////////////////////////////////////////////////////////////////////////

export type ShippingRulesPage = ShippingRulesPageDto & {
  currentPage: number
  pageSize: number
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'shippingRulesListPage', ShippingRulesPage>

const FETCH_SHIPPING_RULES_LIST = `
  query fetchShippingRulesList($page: Int, $size: Int) {
    shippingRulesListPage(page: $page, size: $size) {
      items {
        id
        name
        isActive
        createdAt
        updatedAt
      }
      total
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type PageParam = {
  page?: number
  size?: number
}

type FetchShippingRulesListArgs = {
  queryKey: string | string[]
  pageParam?: PageParam
}

type FetchShippingRulesList = (args: FetchShippingRulesListArgs) => Promise<ShippingRulesPage>

const fetchShippingRulesList: FetchShippingRulesList = async ({ pageParam }) => {
  const { page = 1, size = DEFAULT_PAGE_SIZE } = pageParam || {}
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_SHIPPING_RULES_LIST,
      variables: { page, size }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { shippingRulesListPage } = data
    if (!shippingRulesListPage) {
      throw new Error("Can't get shipping rules list page!")
    }

    return {
      items: shippingRulesListPage.items,
      total: shippingRulesListPage.total,
      currentPage: page,
      pageSize: size
    }
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

type GetNextPageParam = (args: ShippingRulesPage) => PageParam | undefined

type ShippingRulesListEvents = {
  keepPreviousData: boolean
  getPreviousPageParam: GetNextPageParam
  getNextPageParam: GetNextPageParam
}

export const shippingRulesListEvents: ShippingRulesListEvents = {
  keepPreviousData: true,
  getPreviousPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0 }) => {
    if (currentPage > 1) {
      return {
        page: currentPage - 1,
        size: pageSize
      }
    }

    return undefined
  },
  getNextPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, total = 0 }) => {
    if (pageSize * currentPage < total) {
      return {
        page: currentPage + 1,
        size: pageSize
      }
    }

    return undefined
  }
}

export const useShippingRulesList = () => {
  const cacheKey = ['shippingRuleActionsList']

  return useInfiniteQuery(
    cacheKey,
    prev =>
      fetchShippingRulesList({
        queryKey: cacheKey,
        pageParam: { ...prev.pageParam }
      }),
    shippingRulesListEvents
  )
}
