import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

export type RemoveAwsSettingAction = FSA<undefined, number, string>
type RemoveAwsSetting = (awsSettingId: number) => MrxThunk<RemoveAwsSettingAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'removeAwsSetting', boolean>

const REMOVE_AWS_SETTING = `
  mutation removeAwsSetting ($awsSettingId: Int!) {
    removeAwsSetting (awsSettingId: $awsSettingId)
  }
`

const removeAwsSetting: RemoveAwsSetting = (awsSettingId) => async (dispatch) => {
  let isRemoved = false

  dispatch({
    type: t.REMOVE_AWS_SETTING,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_AWS_SETTING,
      variables: { awsSettingId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Empty response!')
    }

    const { removeAwsSetting } = data
    if (!removeAwsSetting) {
      throw new Error("Can't remove aws setting!")
    }

    dispatch({
      type: t.REMOVE_AWS_SETTING,
      payload: awsSettingId,
      meta: { done: true }
    })

    isRemoved = true
  } catch (err) {
    dispatch({
      type: t.REMOVE_AWS_SETTING,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isRemoved
}

export { removeAwsSetting }
