import * as React from 'react'
import useReactRouter from 'use-react-router'
import moment, { Moment } from 'moment'
import numeral from 'numeral'
import { Chart } from 'react-google-charts'
import { Layout, PageHeader, DatePicker, Button, Card, Statistic, Table } from 'antd'
import { Loading } from 'components'
import { ArrowUpOutlined, CheckOutlined, ArrowDownOutlined } from '@ant-design/icons'
import { ColumnProps } from 'antd/es/table'
import notification from 'mrx-notification'
import { RangeValue } from 'rc-picker/lib/interface'
import timeHelpers from 'utils/timeHelpers'
import * as rootStyles from 'assets/layoutStyle'
import styles from './Dashboard.module.scss'
import { Link } from 'react-router-dom'
import { connector, PropsFromRedux } from './container'
import { TopCampaign } from 'features/reports/types'

const columns: ColumnProps<TopCampaign>[] = [
  {
    title: 'Number',
    render: (text, record, index) => index + 1,
    key: 'number'
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Orders Count',
    dataIndex: 'ordersCount',
    key: 'ordersCount'
  },
  {
    title: 'Total',
    render: (record: TopCampaign) => `${numeral(record.total / 100).format('$0,0.00')}`,
    key: 'total',
    align: 'right'
  }
]

const Dashboard = (props: PropsFromRedux) => {
  const {
    dashboardReport,
    topLandingsReport,
    topCampaignsRateReport,
    salesForGoogleChart,
    topCampaignsReport,
    error,
    isLoading,
    reportDates,

    fetchDashboardReport,
    setReportDates
  } = props

  const [startDate, setStartDate] = React.useState<Moment>(reportDates.startDate)
  const [endDate, setEndDate] = React.useState<Moment>(reportDates.endDate)
  const { history } = useReactRouter()

  React.useEffect(() => {
    if (error) {
      notification.error({
        message: 'Dashboard reports error!',
        description: error
      })
    }
  }, [error])

  React.useEffect(() => {
    fetchDashboardReport(timeHelpers.getNYStartOfDay(startDate), timeHelpers.getNYEndOfDay(endDate))
  }, [fetchDashboardReport, startDate, endDate])

  const handleSubmit = () => {
    fetchDashboardReport(timeHelpers.getNYStartOfDay(startDate), timeHelpers.getNYEndOfDay(endDate))
  }

  const onDateChange = (dates: RangeValue<Moment>) => {
    if (dates && dates[0] && dates[1]) {
      setStartDate(dates[0])
      setEndDate(dates[1])
    }
    setReportDates(dates[0], dates[1])
  }

  const data = topCampaignsRateReport.reduce(
    (accum, current) => {
      const total = current.total / 100
      // @ts-ignore
      accum.push([current.name, total])
      return accum
    },
    [['DonutChart', 'Rate']]
  )

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          className={styles.pageHeader}
          onBack={() => history.goBack()}
          title='Dashboard'
          extra={[
            <DatePicker.RangePicker
              key='1'
              defaultValue={[startDate, endDate]}
              format='MM/DD/YYYY'
              ranges={{
                Today: [
                  moment().startOf('day'),
                  moment()
                    .add(1, 'day')
                    .endOf('day')
                ],
                'Last 7 days': [moment().subtract(7, 'days'), moment().startOf('day')],
                'This Week': [moment().startOf('week'), moment().endOf('week')],
                'This Month': [moment().startOf('month'), moment().endOf('month')]
              }}
              onChange={onDateChange}
            />,
            <Button key='3' type='primary' icon={<CheckOutlined />} onClick={handleSubmit} loading={isLoading}>
              Go
            </Button>
          ]}
        />
      </Layout.Header>

      <div className={styles.cardsContainer}>
        <Card className={styles.cardRate}>
          <Statistic title='Total' value={dashboardReport.totalAmount ? dashboardReport.totalAmount / 100 : 0} precision={2} prefix='$' />
        </Card>
        <Card className={styles.cardRate}>
          <Statistic
            title='Growth'
            value={dashboardReport.totalAmountRate}
            precision={2}
            valueStyle={dashboardReport.totalAmountRate > 0 ? { color: '#3f8600' } : { color: 'tomato' }}
            prefix={dashboardReport.totalAmountRate > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
            suffix='%'
          />
        </Card>
        <Card className={styles.cardRate}>
          <Statistic title='Num of Orders' value={dashboardReport.ordersCount} />
        </Card>
        <Card className={styles.cardRate}>
          <Statistic title='New Customers' value={dashboardReport.customers} />
        </Card>
        <Card className={styles.cardRate}>
          <Statistic
            title='Salvage Rate'
            value={dashboardReport.salvageRate}
            precision={2}
            valueStyle={dashboardReport.salvageRate > 0 ? { color: '#3f8600' } : { color: 'tomato' }}
            prefix={dashboardReport.salvageRate > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
            suffix='%'
          />
        </Card>
        <Card className={styles.cardRate}>
          <Statistic
            title='Multipurchaser Rate'
            value={dashboardReport.multiPurchaserRate}
            precision={2}
            valueStyle={dashboardReport.multiPurchaserRate > 0 ? { color: '#3f8600' } : { color: 'tomato' }}
            prefix={dashboardReport.multiPurchaserRate > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
            suffix='%'
          />
        </Card>
      </div>

      <div className={styles.salesReportContaier}>
        <Chart
          width='1200px'
          height='300px'
          chartType='LineChart'
          loader={isLoading && <Loading />}
          data={salesForGoogleChart}
          options={{
            hAxis: {
              title: 'Time'
            },
            vAxis: {
              title: 'Sales'
            },
            series: {
              1: { curveType: 'function' }
            },
            title: 'Sales',
            curveType: 'function'
          }}
          rootProps={{ 'data-testid': '2' }}
        />
        <div className={styles.salesReportLink}>
          <Link
            to={{
              pathname: `/reports/sales/${moment(startDate).format('YYYY.MM.DD')}/${moment(endDate).format('YYYY.MM.DD')}`
            }}
          >
            More
          </Link>
        </div>
      </div>

      <div className={styles.cardsContainer}>
        <Card
          title='Top Camps'
          className={styles.cardBottom}
          extra={
            <Link
              to={{
                pathname: `/reports/topCampaigns/${moment(startDate).format('YYYY.MM.DD')}/${moment(endDate).format('YYYY.MM.DD')}`
              }}
            >
              More
            </Link>
          }
        >
          <Table
            columns={columns}
            dataSource={topCampaignsReport}
            pagination={false}
            showHeader={false}
            rowKey={record => record.id}
            loading={isLoading}
          />
        </Card>
        <Card
          title='Top Campaigns Rate'
          className={styles.cardDonut}
          extra={
            <Link
              to={{
                pathname: `/reports/topCampaignsRate/${moment(startDate).format('YYYY.MM.DD')}/${moment(endDate).format('YYYY.MM.DD')}`
              }}
            >
              More
            </Link>
          }
        >
          <div className={styles.donutChart}>
            <Chart
              width='500px'
              height='280px'
              chartType='PieChart'
              loader={isLoading && <Loading />}
              data={data}
              options={{
                pieHole: 0.4
              }}
              rootProps={{ 'data-testid': '3' }}
            />
          </div>
        </Card>
        <Card
          title='Best Landings'
          className={styles.cardBottom}
          extra={
            <Link
              to={{
                pathname: `/reports/topLandings/${moment(startDate).format('YYYY.MM.DD')}/${moment(endDate).format('YYYY.MM.DD')}`
              }}
            >
              More
            </Link>
          }
        >
          <Table
            columns={columns}
            dataSource={topLandingsReport}
            pagination={false}
            showHeader={false}
            rowKey={record => record.id}
            loading={isLoading}
          />
        </Card>
      </div>
    </Layout>
  )
}

export default connector(Dashboard)
