import { connect, ConnectedProps } from 'react-redux'
import { MailingPlugin } from '@merchx-v2/shared-types'
import { ReduxState } from 'store/createRootReducer'
import * as postmarkSettingsFeature from 'features/postmarkSettings'
import * as sendgridSettingsFeature from 'features/sendgridSettings'

type OwnProps = {
  plugin: MailingPlugin
  initialOption: SelectOption
  disabled?: boolean
  onSelect: (selectOption: SelectOption) => void
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  let isLoading = false
  let options = []

  switch (ownProps.plugin) {
    case 'POSTMARK': {
      isLoading = postmarkSettingsFeature.selectors.isLoadingSelector(state, 'isPostmarkSettingsForOptionsLoading')
      options = postmarkSettingsFeature.selectors.postmarkSettingsSelectOptionsSelector(state)
      break
    }
    case 'SENDGRID': {
      isLoading = sendgridSettingsFeature.selectors.isLoadingSelector(state, 'isSendgridSettingsForOptionsLoading')
      options = sendgridSettingsFeature.selectors.sendgridSettingsSelectOptionsSelector(state)
      break
    }
  }

  return {
    isLoading,
    options
  }
}

const mapDispatchToProps = {
  fetchPostmarkSettingsForOptions: postmarkSettingsFeature.actions.fetchPostmarkSettingsForOptions,
  fetchSendgridSettingsOptions: sendgridSettingsFeature.actions.fetchSendgridSettingsForOptions
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
