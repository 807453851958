import React, { useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import { Table, Layout, PageHeader, Popconfirm } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import notification from 'mrx-notification'
import useHeightResize from 'utils/useHeightResize'
import { Breadcrumbs } from 'components'
import * as rootStyles from 'assets/layoutStyle'
import styles from './AwsCertificatesList.module.scss'
import { connector, PropsFromRedux } from './container'
import CreateAwsCertificate from '../CreateAwsCertificate'
import UpdateAwsCertificate from '../UpdateAwsCertificate'
import { AwsCertificate } from '../../types'
import moment from 'moment'

type HeightResizeRule = [number, number, number]

const rules: HeightResizeRule[] = [
  [0, 800, 6],
  [800, 1000, 7],
  [1000, 1200, 9],
  [1200, Infinity, 10]
]

const AwsCertificatesList = (props: PropsFromRedux) => {
  const {
    awsCertificatesList,
    isLoading,
    error,
    currentPage,
    total,
    isNeedToFetchList,

    changeCurrentPage,
    changePageSize,
    fetchAwsCertificatesList,
    removeAwsCertificate
  } = props

  const [selectedAwsCertificateId, setSelectedAwsCertificateId] = useState<number>()
  const [isUpdateDrawerVisible, setIsUpdateDrawerVisible] = useState(false)

  const { history } = useReactRouter()

  const handleChangePageSize = (newSize: number) => {
    changePageSize(newSize)
    fetchAwsCertificatesList()
  }

  useHeightResize(handleChangePageSize, rules)

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Aws Certificates list page error!',
        description: error
      })
    }
  }, [error])

  useEffect(() => {
    isNeedToFetchList && fetchAwsCertificatesList()
  }, [fetchAwsCertificatesList, isNeedToFetchList])

  const doConfirm = async (awsCertificateId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    const isRemoved = await removeAwsCertificate(awsCertificateId)
    if (isRemoved) {
      notification.success({
        message: 'Successfully',
        description: 'Aws Certificate was deleted successfully!'
      })
    }
  }

  const handleOnRow = (record: AwsCertificate) => {
    return {
      onClick: () => {
        setSelectedAwsCertificateId(record.id)
        setIsUpdateDrawerVisible(true)
      }
    }
  }

  const handleOnClose = () => {
    setIsUpdateDrawerVisible(false)
  }

  const columns: ColumnsType<AwsCertificate> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Domain Name',
      dataIndex: 'domainName',
      key: 'domainName'
    },
    {
      title: 'ARN',
      dataIndex: 'certificateArn',
      key: 'certificateArn'
    },
    {
      title: 'Issued At',
      render: (_value, record) => (record.issuedAt ? moment(record.issuedAt).format('MM/DD/YYYY') : 'No date'),
      key: 'issuedAt'
    },
    {
      title: 'Expiring At',
      render: (_value, record) => moment(record.expiringAt).format('MM/DD/YYYY'),
      key: 'expiringAt'
    },
    {
      title: 'Created At',
      render: (_value, record) => moment(record.createdAt).format('MM/DD/YYYY'),
      key: 'createdAt'
    },
    {
      title: 'Updated At',
      render: (_value, record) => moment(record.updatedAt).format('MM/DD/YYYY'),
      key: 'updatedAt'
    },
    {
      title: 'Action',
      key: 'x',
      render: (_value, record) => (
        <Popconfirm
          title='Are you sure delete this aws certificate?'
          onConfirm={(e) => doConfirm(record.id, e)}
          onCancel={(e) => e?.stopPropagation()}
          okText='Yes'
          cancelText='No'
        >
          <a
            href='/#'
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            Remove
          </a>
        </Popconfirm>
      )
    }
  ]

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader onBack={() => history.goBack()} title='Aws Certificates List' extra={[<CreateAwsCertificate key='1' />]} />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Settings', url: '/' },
            { title: 'AWS Certificates List', url: '/' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <Table
          columns={columns}
          dataSource={awsCertificatesList}
          bordered
          rowKey={(record) => record.id}
          onRow={handleOnRow}
          loading={isLoading}
          pagination={{
            current: currentPage,
            total,
            onChange: changeCurrentPage
          }}
        />
      </Layout.Content>
      {selectedAwsCertificateId && (
        <UpdateAwsCertificate awsCertificateId={selectedAwsCertificateId} onClose={handleOnClose} visible={isUpdateDrawerVisible} />
      )}
    </Layout>
  )
}

export default connector(AwsCertificatesList)
