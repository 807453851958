// TODO - remove becouse of legacy

import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Landing, LandingInput } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'updateLanding', boolean>

const UPDATE_LANDING = `
  mutation updateLanding($landingId: Int!, $landingData: LandingInput!) {
    updateLanding(landingId: $landingId, landingData: $landingData)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateLandingArgs = {
  landingId: number
  landingData: LandingInput
}

type UpdateLanding = (args: UpdateLandingArgs) => Promise<UpdateLandingArgs>

const updateLanding: UpdateLanding = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_LANDING,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateLanding } = data
    if (!updateLanding) {
      throw new Error("Can't update landing!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateLandingContext = { prevLanding?: Landing }

type UpdateLandingEvents = {
  onMutate: (variables: UpdateLandingArgs) => Promise<UpdateLandingContext>
  onError: (error: string, variables: UpdateLandingArgs, context: UpdateLandingContext) => void
  onSettled: (data?: UpdateLandingArgs) => void
}

const updateLandingEvents: UpdateLandingEvents = {
  onMutate: async (variables: UpdateLandingArgs) => {
    await queryClient.cancelQueries(['landing', variables.landingId])

    // Snapshot the previous value
    const prevLanding = queryClient.getQueryData<Landing>(['landing', variables.landingId])

    // Optimistically update to the new values
    if (prevLanding) {
      queryClient.setQueryData<Landing>(['landing', variables.landingId], {
        ...prevLanding,
        id: variables.landingId,
        ...variables.landingData
      })
    }

    return { prevLanding }
  },
  onError: (_err, variables, context) => {
    if (context?.prevLanding) {
      // Restore previous version of landing on any error
      queryClient.setQueryData<Landing>(['landing', variables.landingId], context.prevLanding)
    }
  },
  onSettled: (data) => {
    if (data?.landingId) {
      queryClient.invalidateQueries(['landing', data.landingId])
      queryClient.invalidateQueries(['landingsList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUpdateLanding = () => useMutation(updateLanding, updateLandingEvents)
