import { UpdateStripeSettingDto } from '@merchx-v2/shared-types/dist/dto/stripeSettings/update-stripe-settings.dto'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import axios from 'utils/axios'
import * as t from '../actionTypes'

type Payload = {
  stripeSettingId: number
  stripeSettingData: UpdateStripeSettingDto
}

export type UpdateStripeSettingAction = FSA<undefined, Payload, string>

type UpdateStripeSetting = (args: Payload) => MrxThunk<UpdateStripeSettingAction, Promise<boolean>>

type QueryResponse = GraphQLResponse<'updateStripeSetting', boolean>

const UPDATE_STRIPE_SETTING = `
  mutation updateStripeSetting ($stripeSettingId: Int!, $stripeSettingData: StripeSettingInput!) {
    updateStripeSetting (stripeSettingId: $stripeSettingId, stripeSettingData: $stripeSettingData)
  }
`

const updateStripeSetting: UpdateStripeSetting = (args) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.UPDATE_STRIPE_SETTING,
    meta: { done: false }
  })

  try {
    const { stripeSettingId, stripeSettingData } = args
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_STRIPE_SETTING,
      variables: { stripeSettingId, stripeSettingData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateStripeSetting } = data
    if (!updateStripeSetting) {
      throw new Error("Can't update Stripe setting!")
    }

    dispatch({
      type: t.UPDATE_STRIPE_SETTING,
      payload: args,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.UPDATE_STRIPE_SETTING,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { updateStripeSetting }
