import React, { useContext } from 'react'
import { Avatar, Tooltip, Layout } from 'antd'
import { GlobalContext } from 'appContexts'
import { useCampaignsList, useSetUserActiveCampaign } from '../../hooks'
import CreateCampaign from '../CreateCampaign'

import styles from './CampaignsNavList.module.scss'
import { Campaign } from 'features/campaigns/types'

const CampaignsNavList = () => {
  const { setCampaignId, workspaceId } = useContext(GlobalContext)

  const setUserActiveCampaign = useSetUserActiveCampaign()

  const campaignsList = useCampaignsList({ workspaceId })
  const campaigns: Campaign[] = []
  campaignsList &&
    campaignsList.data &&
    campaignsList.data.pages.forEach((page) => page.items.forEach((campaign) => campaigns.push(campaign)))

  const handleActiveCampaignChanged = async ({ currentTarget }: React.MouseEvent<HTMLElement>) => {
    setUserActiveCampaign.mutate(
      {
        campaignId: +currentTarget.dataset.id!
      },
      {
        onSuccess: () => {
          setCampaignId(+currentTarget.dataset.id!)
        }
      }
    )
  }

  return (
    <Layout
      className={styles.listContainer}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0
      }}
    >
      {campaigns.map((item) => (
        <Tooltip key={item.id} placement='right' title={item.name}>
          <div data-id={item.id} onClick={handleActiveCampaignChanged}>
            <Avatar shape='square' size='large' className={styles.avatar}>
              {item.name.charAt(0).toUpperCase()}
            </Avatar>
          </div>
        </Tooltip>
      ))}
      <CreateCampaign isForMenu />
    </Layout>
  )
}

export default CampaignsNavList
