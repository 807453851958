import React, { useContext, useEffect } from 'react'
import useReactRouter from 'use-react-router'
import hash from 'object-hash'
import { Button, Input, Form, Layout, Typography } from 'antd'
import { Store } from 'antd/lib/form/interface'
import notification from 'mrx-notification'
import { GlobalContext } from 'appContexts'
import { Loading } from 'components'
import * as rootStyles from 'assets/layoutStyle'
import { useCampaign } from 'features/campaigns/hooks'
import { PropsFromRedux, connector } from './container'
import styles from './EditCategory.module.scss'

interface EditCategoryFormStore extends Store {
  name: string
}

const EditCategory = (props: PropsFromRedux) => {
  const {
    category,
    categoryId,
    campaignId,
    isLoading,
    error,

    createCategory,
    fetchCategory,
    updateCategory
  } = props

  const { history } = useReactRouter()
  const [form] = Form.useForm()

  const { workspaceId } = useContext(GlobalContext)

  const campaign = useCampaign(+campaignId)

  useEffect(() => {
    if (categoryId) {
      fetchCategory(categoryId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId])

  useEffect(() => {
    if (category) {
      form.setFieldsValue({
        name: category.name
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(category)])

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Create Category error!',
        description: error
      })
    }
  }, [error])

  const handleFormClose = () => {
    form.resetFields()
    history.push(`/campaigns/${campaignId}?activeTab=categories`)
  }

  const onFinishHandler = async (values: EditCategoryFormStore) => {
    const categoryData = {
      name: values.name
    }

    let isSaved = false

    if (!category) {
      const createdCategory = await createCategory(workspaceId, campaignId, categoryData)

      isSaved = !!createdCategory
    } else {
      isSaved = await updateCategory({ categoryId, campaignId, categoryData })
    }

    if (isSaved) {
      notification.success({
        message: 'Successfully',
        description: `Category was ${category ? 'updated' : 'created'} successfully!`
      })
      handleFormClose()
    }
  }

  return (
    <Layout style={rootStyles.root} className={styles.baseContainer}>
      {campaign?.data?.status === 'MIGRATING' && (
        <div className={styles.blockPage}>
          <div className={styles.blockPageContainer}>
            <p className={styles.blockPageTitle}>
              Campaign is moving to another workspace <span onClick={() => history.goBack()}>Go back</span>
            </p>
          </div>
        </div>
      )}
      <Layout.Content className={styles.contentComponent}>
        {isLoading && <Loading />}
        {!isLoading && (
          <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
            <div className={styles.categoryFormBlock}>
              <Typography style={{ color: 'black', fontWeight: 'bold', paddingBottom: '8px' }}>Category</Typography>
              <div className={styles.categoryFormRow}>
                <Form.Item
                  name='name'
                  label='Category name'
                  rules={[{ required: true, message: 'Please input category  name!' }]}
                  className={styles.categoryFormRowItem}
                >
                  <Input />
                </Form.Item>
              </div>
            </div>

            <div className={styles.buttonsContainer}>
              <Button onClick={handleFormClose} className={styles.createButton}>
                Cancel
              </Button>
              <Button type='primary' htmlType='submit' loading={isLoading}>
                Save
              </Button>
            </div>
          </Form>
        )}
      </Layout.Content>
    </Layout>
  )
}

export default connector(EditCategory)
