// TODO - remove becouse of legacy

import React, { useEffect, useState } from 'react'
import { Card, Form, Input, Button, Upload, Modal } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { UploadFile } from 'antd/lib/upload/interface'
import notification from 'mrx-notification'
import { useUpdateLandingSeo } from '../../hooks'
import styles from './LandingWizardSeo.module.scss'
import { connector, PropsFromRedux } from './container'

const { TextArea } = Input

const LandingWizardInitials = (props: PropsFromRedux) => {
  const {
    landing,
    isLoading,
    nextStep,

    onNextButtonClick,
    uploadFile
  } = props

  const [previewImage, setPreviewImage] = useState('')
  const [previewVisible, setPreviewVisible] = useState(false)
  const [imageToSave, setImageToSave] = useState<UploadFile<any>>()

  const [form] = Form.useForm()

  const updateLandingSeo = useUpdateLandingSeo()

  useEffect(() => {
    if (landing) {
      form.setFieldsValue({
        seoTitle: landing?.seoTitle,
        seoDescription: landing?.seoDescription,
        seoKeywords: landing?.seoKeywords
      })
      setPreviewImage(landing?.faviconUrl)
    }
  }, [form, landing])

  const onFinishHandler = async (landingSeo: any) => {
    let isSaved = false
    let faviconUrl = landing?.faviconUrl

    if (imageToSave && landing) {
      const faviconData = await uploadFile({ ownerId: landing?.id, ownerType: 'LANDING', file: imageToSave })

      isSaved = !!faviconData
      faviconUrl = faviconData?.url
    }

    if (landing?.id && (form.isFieldsTouched() || imageToSave)) {
      updateLandingSeo.mutate(
        {
          landingId: landing?.id,
          landingSeo: {
            ...landingSeo,
            faviconUrl
          }
        },
        {
          onError: (error) => {
            notification.error({
              message: 'Landing Wizard SEO error',
              description: error.toString()
            })
          }
        }
      )
      setImageToSave(undefined)
    } else {
      onNextButtonClick(nextStep)
    }

    if (isSaved) {
      notification.success({
        message: 'Successfully',
        description: `Landing SEO was updated successfully!`
      })
      onNextButtonClick(nextStep)
    }
  }

  const handleAddFavicon = async (file) => {
    file.url = URL.createObjectURL(file)
    setPreviewImage(file.url)
    setImageToSave(file)
  }

  return (
    <Form
      layout='vertical'
      className={styles.form}
      hideRequiredMark
      onFinish={onFinishHandler}
      form={form}
      initialValues={{
        seoTitle: landing?.seoTitle,
        seoDescription: landing?.seoDescription,
        seoKeywords: landing?.seoKeywords
      }}
    >
      <Card
        className={styles.card}
        title={<span className={styles.cardTitle}>SEO</span>}
        extra={[
          <Button key='1' type='primary' htmlType='submit' loading={updateLandingSeo.isLoading} className={styles.saveButton}>
            NEXT
          </Button>
        ]}
      >
        <Form.Item label='Favicon' valuePropName={null} className={styles.groupItem}>
          <div className={styles.uploadSettingImageContainer}>
            {!!(previewImage || imageToSave) && (
              <img
                src={imageToSave?.url || previewImage}
                alt=''
                className={styles.image}
                onClick={() => {
                  setPreviewVisible(true)
                }}
              />
            )}
            <div>
              <Upload accept='image/*' customRequest={(info) => handleAddFavicon(info.file)} showUploadList={false}>
                <Button className={styles.uploadButton} icon={<UploadOutlined />} loading={isLoading} disabled={isLoading}>
                  Click to Upload
                </Button>
              </Upload>
            </div>
          </div>
        </Form.Item>
        <Modal visible={previewVisible} title='Favicon' footer={null} onCancel={() => setPreviewVisible(false)}>
          <img alt='example' style={{ width: '100%' }} src={imageToSave?.url || previewImage} />
        </Modal>

        <Form.Item name='seoTitle' label='Title'>
          <Input type='text' />
        </Form.Item>

        <Form.Item name='seoDescription' label='Description'>
          <TextArea />
        </Form.Item>

        <Form.Item name='seoKeywords' label='Keywords'>
          <TextArea />
        </Form.Item>
      </Card>
    </Form>
  )
}

export default connector(LandingWizardInitials)
