import { DeployLandingLogs } from '@merchx-v2/web-socket/dist/webSocket/types/deployLandingLogs'
import * as t from '../actionTypes'

export type DeployLandingLogsUpdatedAction = BaseFSA<DeployLandingLogs>
type DeployLandingLogsUpdated = (deployLogs: DeployLandingLogs) => MrxThunk<DeployLandingLogsUpdatedAction>

const deployLandingLogsUpdated: DeployLandingLogsUpdated = deployLogs => async dispatch => {
  dispatch({
    type: t.DEPLOY_LANDING_LOGS,
    payload: deployLogs,
    meta: { done: true }
  })
}

export { deployLandingLogsUpdated }
