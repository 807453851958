import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { DTGProduct } from '../types'

type DTGProductsList = {
  items: DTGProduct[]
  total: number
}

type Payload = DTGProductsList & {
  page: number
}

export type FetchDTGProductsListAction = FSA<undefined, Payload, string>
type FetchDTGProductsList = () => MrxThunk<FetchDTGProductsListAction>
type QueryResponse = GraphQLResponse<'DTGProductsListPage', DTGProductsList>

const FETCH_DTG_PRODUCT_LIST = `
  query DTGProductsListPage($page: Int, $size: Int) {
    DTGProductsListPage(page: $page, size: $size) {
      items {
        id
        name
        categoryId
        DTGProductId
        createdAt
        updatedAt
      }
      total
    }
  }
`

const fetchDTGProductsList: FetchDTGProductsList = () => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_DTG_PRODUCT_LIST,
    meta: { done: false }
  })

  try {
    const {
      DTGProducts: {
        pagination: { size, currentPage: page }
      }
    } = getState()

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_DTG_PRODUCT_LIST,
      variables: { page, size }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { DTGProductsListPage } = data
    if (!DTGProductsListPage) {
      throw new Error("Can't get DTGProduct list page!")
    }

    dispatch({
      type: t.FETCH_DTG_PRODUCT_LIST,
      payload: {
        items: DTGProductsListPage.items,
        total: DTGProductsListPage.total,
        page
      },
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_DTG_PRODUCT_LIST,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchDTGProductsList }
