import { SettingData } from './types'

export const twilioSettingsData: SettingData[] = [
  {
    name: 'accountSid',
    label: 'Account SID',
    rules: [{ required: true, message: 'Please input account SID!' }]
  },
  {
    name: 'authToken',
    label: 'Auth Token',
    rules: [{ required: true, message: 'Please input auth token!' }]
  },
  {
    name: 'fromPhone',
    label: 'From Phone Number (ex: +123456789098)',
    rules: [{ required: true, pattern: /^(\+)[0-9]{10,13}$/g, message: 'Please input from phone number!' }]
  }
]
