import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { AssetOwner, AssetDto, AssetRole } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'removeAsset', boolean>

const REMOVE_ASSET = `
  mutation removeAsset($assetId: Int!, $role: String) {
    removeAsset(assetId: $assetId, role: $role)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveAssetArgs = {
  ownerType: AssetOwner
  ownerId: number
  assetId: number
  role?: AssetRole
}

type RemoveAsset = (args: RemoveAssetArgs) => Promise<RemoveAssetArgs>

const removeAsset: RemoveAsset = async (args: RemoveAssetArgs) => {
  try {
    const { assetId, role = 'DEFAULT' } = args
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_ASSET,
      variables: {
        assetId,
        role
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeAsset } = data
    if (!removeAsset) {
      throw new Error("Can't remove asset!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveAssetContext = { prevAsset?: AssetDto }

type RemoveAssetEvents = {
  onMutate: (variables: RemoveAssetArgs) => Promise<RemoveAssetContext>
  onError: (error: string, variables: RemoveAssetArgs, context: RemoveAssetContext) => void
  onSettled: (variables?: RemoveAssetArgs) => void
}

const removeAssetEvents: RemoveAssetEvents = {
  onMutate: async ({ assetId }) => {
    await queryClient.cancelQueries(['asset', assetId])

    // Snapshot the previous value
    const prevAsset = queryClient.getQueryData<AssetDto>(['asset', assetId])

    if (prevAsset) {
      queryClient.removeQueries(['asset', assetId])
    }

    return { prevAsset }
  },
  onError: (_err, variables, context) => {
    if (context?.prevAsset) {
      // Restore asset on any error
      queryClient.setQueryData<AssetDto>(['asset', variables.assetId], context.prevAsset)
    }
  },
  onSettled: (data: RemoveAssetArgs) => {
    if (data.assetId) {
      // Нам не надо инвалидировать оунера тк это должен сделать компонент вызывающий данную форму
      // queryClient.invalidateQueries([`${data.ownerType.toLowerCase()}`, +data.ownerId])
      queryClient.invalidateQueries(['assetsList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useRemoveAsset = () => useMutation(removeAsset, removeAssetEvents)
