export const formRoot = {
  flex: 1
};

export const root = {
  display: 'flex',
  flex: 1,
  alignItems: 'center'
};

export const loadingIcon = {
  paddingLeft: '10px',
  fontSize: 20
};

export const cancelIcon = {
  fontSize: '20px',
  color: 'red',
  paddingLeft: '10px'
};

export const confirmIcon = {
  fontSize: '20px',
  color: '#0aa68e',
  paddingLeft: '5px'
};
