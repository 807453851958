import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { SendgridSetting } from '../types'

type SendgridSettingsListSelector = (state: ReduxState) => SendgridSetting[]
const getSendgridSettingsList: SendgridSettingsListSelector = state => {
  const result = [] as SendgridSetting[]
  const pageIds = state.sendgridSettings.pagination.pages[state.sendgridSettings.pagination.currentPage] || []
  pageIds.forEach((pageId: any) => {
    result.push(state.sendgridSettings.sendgridSettings[pageId])
  })
  return result
}

export const sendgridSettingsListSelector: SendgridSettingsListSelector = createSelector(
  getSendgridSettingsList,
  list => list
)
