import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { CampaignTemplates } from '../types'

type CampaignTemplatesReportSelector = (state: ReduxState) => CampaignTemplates[] | void

const getCampaignTemplatesReportSelector = (state: ReduxState) => state.reports.campaignTemplatesReport

export const campaignTeplatesReportSelector: CampaignTemplatesReportSelector = createSelector(
  getCampaignTemplatesReportSelector,
  campaignTeplates => campaignTeplates
)
