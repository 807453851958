import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { AwsCertificate } from '../types'

type AwsCertificatesList = {
  items: AwsCertificate[]
  total: number
}

type Payload = AwsCertificatesList & {
  page: number
}

export type FetchAwsCertificatesListAction = FSA<undefined, Payload, string>
type FetchAwsCertificatesList = () => MrxThunk<FetchAwsCertificatesListAction>
type QueryResponse = GraphQLResponse<'awsCertificatesListPage', AwsCertificatesList>

const FETCH_AWS_CERTIFICATES_LIST = `
  query fetchAwsCertificatesList ($page: Int, $size: Int, $filters: AwsCertificatesFilters!) {
    awsCertificatesListPage(page: $page, size: $size, filters: $filters) {
      items {
        id
        certificateArn
        domainName
        expiringAt
        issuedAt
        createdAt
        updatedAt
      }
      total
    }
  }
`

const fetchAwsCertificatesList: FetchAwsCertificatesList = () => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_AWS_CERTIFICATES_LIST,
    meta: { done: false }
  })

  try {
    const {
      awsCertificates: {
        pagination: { size, currentPage: page }
      },
      workspaces: {
        activeWorkspace: { id: workspaceId }
      }
    } = getState()

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_AWS_CERTIFICATES_LIST,
      variables: { page, size, filters: { workspaceId } }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { awsCertificatesListPage } = data
    if (!awsCertificatesListPage) {
      throw new Error("Can't get aws certificates list page!")
    }

    dispatch({
      type: t.FETCH_AWS_CERTIFICATES_LIST,
      payload: {
        items: awsCertificatesListPage.items,
        total: awsCertificatesListPage.total,
        page
      },
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_AWS_CERTIFICATES_LIST,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchAwsCertificatesList }
