import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { ProductSizesState } from '../types'
import featureStateSelector from './featureStateSelector'

export const isNeedToFetchListSelector = createSelector<ReduxState, ProductSizesState, boolean>(
  featureStateSelector,
  state => {
    const { pages, currentPage } = state.pagination
    return !pages[currentPage]
  }
)
