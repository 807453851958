import React, { useState, useRef, useEffect } from 'react'
import { PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Drawer, message, Input, Form, Upload } from 'antd'
import { AssetOwner } from '@merchx-v2/shared-types'
import notification from 'mrx-notification'
import { useUploadAsset } from '../../hooks'
import styles from './UploadAsset.module.scss'
import { UploadChangeParam, UploadProps } from 'antd/lib/upload/interface'

type PropsType = {
  ownerType: AssetOwner
  ownerId: number
  title?: string

  onAssetIdReceived?: (string) => Promise<void>
}

const UploadAsset = (props: PropsType) => {
  const [isVisible, setVisible] = useState(false)
  const [file, setFile] = useState<File>()
  const refEl = useRef<Input>(null)

  const { ownerType, ownerId, title, onAssetIdReceived } = props

  const uploadAsset = useUploadAsset()

  useEffect(() => {
    if (isVisible) {
      refEl.current && refEl.current.focus()
    }
  }, [isVisible])

  const showDrawer = () => {
    setVisible(!isVisible)
  }

  const [form] = Form.useForm()

  const handleClose = () => {
    setVisible(false)
    form.resetFields()
  }

  const uploadProps: UploadProps = {
    name: 'file',
    accept: 'image/*, video/*, .js, .css, .scss, .ttf, .wof, .eof',
    customRequest(info: any) {
      setFile(info.file)

      if (!form.getFieldValue('name')) {
        form.setFieldsValue({ name: info.file.name })
      }
    },
    showUploadList: false,

    onChange(info: UploadChangeParam) {
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    }
  }

  const onFinishHandler = async (values: any) => {
    if (file) {
      const name = values.name.trim().replace(/\s+/g, '_') as string
      uploadAsset.mutate(
        { ownerType, ownerId, assetData: { name, type: file.type, filename: file.name, role: 'DEFAULT' }, file },
        {
          onSuccess: () => {
            notification.success({
              message: 'Successfully',
              description: 'Asset was created successfully!'
            })
            onAssetIdReceived && onAssetIdReceived(uploadAsset.data.id)
            handleClose()
          },
          onError: (error) => {
            notification.error({
              message: 'Error!',
              description: error.toString()
            })
          }
        }
      )
    }
  }

  return (
    <>
      <Button type='primary' onClick={showDrawer} className={styles.createButton}>
        <PlusOutlined />
        {title || 'CREATE ASSET'}
      </Button>
      <Drawer title={title || 'Create Asset'} width='400' onClose={handleClose} visible={isVisible} forceRender>
        <Form layout='vertical' hideRequiredMark form={form} onFinish={onFinishHandler}>
          <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input asset name!' }]}>
            <Input ref={refEl} />
          </Form.Item>
          <Form.Item name='upload' valuePropName='' rules={[{ required: true, message: 'Please upload file!' }]}>
            <Upload {...uploadProps}>
              <Button>
                <UploadOutlined /> Click to Upload
              </Button>
            </Upload>
          </Form.Item>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button onClick={handleClose} className={styles.closeButton}>
              Close
            </Button>
            <Button type='primary' htmlType='submit' loading={uploadAsset.isLoading}>
              Create
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

export default UploadAsset
