// TODO - remove becouse of legacy

import React, { useContext, useEffect, useState } from 'react'
import { Button, Drawer, Input, Form, Select } from 'antd'
import notification from 'mrx-notification'
import { GlobalContext } from 'appContexts'
import { UploadAsset } from 'features/assets/components'
import { useAsset } from 'features/assets/hooks'
import { useTemplate, useUpdateTemplate } from '../../hooks'
import styles from './UpdateTemplate.module.scss'
import { TEMPLATE_ROUTE } from '@merchx-v2/shared-types'

type PropsType = {
  templateId?: number
  visible: boolean

  onClose: () => void
}

const UpdateTemplate = (props: PropsType) => {
  const {
    templateId,
    visible,

    onClose
  } = props

  const template = useTemplate(templateId)
  const updateTemplate = useUpdateTemplate()

  const [mainPreviewThumbImageId, setMainPreviewThumbImageId] = useState<number>()
  const [mainPreviewImageId, setMainPreviewImageId] = useState<number>()
  const [previewImage1Id, setPreviewImage1Id] = useState<number>()
  const [previewImage2Id, setPreviewImage2Id] = useState<number>()
  const [previewImage3Id, setPreviewImage3Id] = useState<number>()
  const [isGatsby, toggleGatsby] = useState(false)
  const [isSublander, setIsSublander] = useState(false)
  const [form] = Form.useForm()

  const mainPreviewThumbImage = useAsset(+template?.data?.mainPreviewThumbImageId)
  const mainPreviewImage = useAsset(+template?.data?.mainPreviewThumbImageId)
  const previewImage1 = useAsset(+template?.data?.previewImage1Id)
  const previewImage2 = useAsset(+template?.data?.previewImage2Id)
  const previewImage3 = useAsset(+template?.data?.previewImage3Id)

  const { workspaceId } = useContext(GlobalContext)

  useEffect(() => {
    if (template?.data) {
      form.setFieldsValue({
        name: template.data.name,
        buildType: template.data.buildType,
        repository: template.data.repository,
        repositoryProvider: template.data.repositoryProvider,
        route: template.data.route
      })
    }

    if (template?.data?.buildType === 'REACT_WITH_GATSBY') {
      toggleGatsby(true)
    }

    if (template?.data?.templateType === 'SUBLANDER' || template?.data?.templateType === 'QUIZ') {
      setIsSublander(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template])

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  useEffect(() => {
    if (template.error) {
      notification.success({
        message: 'Error!',
        description: template.error instanceof Error ? template.error.message : template.error.toString()
      })
    }
  }, [template.error])

  if (!template) {
    return null
  }

  // TODO - fix any type
  const onFinishHandler = async (values: any) => {
    updateTemplate.mutate(
      {
        templateId,
        templateData: {
          workspaceId,
          name: values.name,
          templateType: template?.data?.templateType,
          route: values.route,
          buildType: values.buildType,
          mainPreviewThumbImageId: mainPreviewThumbImageId,
          mainPreviewImageId: mainPreviewImageId,
          previewImage1Id: previewImage1Id,
          previewImage2Id: previewImage2Id,
          previewImage3Id: previewImage3Id,
          repository: values.repository,
          repositoryProvider: values.repositoryProvider
        }
      },
      {
        onSuccess: () => {
          notification.success({
            message: 'Successfully',
            description: 'Template was updated successfully!'
          })
          handleClose()
        },
        onError: (error) => {
          notification.error({
            message: 'Error!',
            description: error.toString()
          })
        }
      }
    )
  }

  const handleFormValueChanged = (changedValues) => {
    if (changedValues.buildType) {
      toggleGatsby(changedValues.buildType === 'REACT_WITH_GATSBY')
    }

    if (changedValues.templateType) {
      setIsSublander(changedValues.templateType === 'SUBLANDER' || changedValues.templateType === 'QUIZ')
    }
  }

  return (
    <Drawer title='Update Template' width='400' onClose={handleClose} visible={visible}>
      <Form
        layout='vertical'
        hideRequiredMark
        onFinish={onFinishHandler}
        onValuesChange={handleFormValueChanged}
        form={form}
        initialValues={{
          name: template?.data?.name,
          route: template?.data?.route,
          buildType: template?.data?.buildType,
          repository: template?.data?.repository,
          repositoryProvider: template?.data?.repositoryProvider
        }}
      >
        <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input template name!' }]}>
          <Input style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item name='buildType' label='Build type' rules={[{ required: true, message: 'Please input build type!' }]}>
          <Select>
            <Select.Option value='REACT_WITH_INTEGRATED_SETTINGS'>React + Integrated settings</Select.Option>
            <Select.Option value='REACT_WITH_SETTINGS_FILE'>React + Settings file</Select.Option>
            <Select.Option value='REACT_WITH_GATSBY'>React + Gatsby</Select.Option>
          </Select>
        </Form.Item>

        {isGatsby && (
          <>
            <Form.Item name='repository' label='Repository' rules={[{ required: true, message: 'Please input repository!' }]}>
              <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name='repositoryProvider'
              label='Repository Provider'
              rules={[{ required: true, message: 'Please input repository provider!' }]}
            >
              <Select>
                <Select.Option value='GITLAB'>GitLab</Select.Option>
              </Select>
            </Form.Item>
          </>
        )}

        {isSublander && (
          <>
            <Form.Item name='route' label='Template Route' rules={[{ required: true, message: 'Please input template route!' }]}>
              <Select>
                {Object.keys(TEMPLATE_ROUTE).map((templateRoute) => (
                  <Select.Option key={templateRoute} value={templateRoute}>
                    {templateRoute}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}

        {mainPreviewThumbImage && (
          <img
            width={200}
            height={200}
            src={mainPreviewThumbImage?.data?.signedUrl}
            alt={mainPreviewThumbImage?.data?.name}
            style={{ marginBottom: 10 }}
          />
        )}
        <div style={{ marginBottom: 10 }}>
          <UploadAsset
            title='Upload main preview thumb image'
            ownerType='TEMPLATE'
            ownerId={templateId}
            onAssetIdReceived={async (imageId) => setMainPreviewThumbImageId(imageId)}
          />
        </div>

        {mainPreviewImage && (
          <img
            width={200}
            height={200}
            src={mainPreviewImage?.data?.signedUrl}
            alt={mainPreviewImage?.data?.name}
            style={{ marginBottom: 10 }}
          />
        )}
        <div style={{ marginBottom: 10 }}>
          <UploadAsset
            title='Upload main preview image'
            ownerType='TEMPLATE'
            ownerId={templateId}
            onAssetIdReceived={async (imageId) => setMainPreviewImageId(imageId)}
          />
        </div>

        {previewImage1 && (
          <img width={200} height={200} src={previewImage1?.data?.signedUrl} alt={previewImage1?.data?.name} style={{ marginBottom: 10 }} />
        )}
        <div style={{ marginBottom: 10 }}>
          <UploadAsset
            title='Upload preview image 1'
            ownerType='TEMPLATE'
            ownerId={templateId}
            onAssetIdReceived={async (imageId) => setPreviewImage1Id(imageId)}
          />
        </div>

        {previewImage2 && (
          <img width={200} height={200} src={previewImage2?.data?.signedUrl} alt={previewImage2?.data?.name} style={{ marginBottom: 10 }} />
        )}
        <div style={{ marginBottom: 10 }}>
          <UploadAsset
            title='Upload preview image 2'
            ownerType='TEMPLATE'
            ownerId={templateId}
            onAssetIdReceived={async (imageId) => setPreviewImage2Id(imageId)}
          />
        </div>

        {previewImage3 && (
          <img width={200} height={200} src={previewImage3?.data?.signedUrl} alt={previewImage3?.data?.name} style={{ marginBottom: 10 }} />
        )}
        <div style={{ marginBottom: 10 }}>
          <UploadAsset
            title='Upload preview image 3'
            ownerType='TEMPLATE'
            ownerId={templateId}
            onAssetIdReceived={async (imageId) => setPreviewImage3Id(imageId)}
          />
        </div>

        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right'
          }}
        >
          <Button onClick={handleClose} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit' loading={updateTemplate.isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default UpdateTemplate
