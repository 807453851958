import React, { useState, useRef, useContext, useEffect } from 'react'
import { Input, Form } from 'antd'
import EditableContext from './EditableContext'

type Props<T> = {
  title: string
  editable: boolean
  children: React.ReactNode
  dataIndex: string
  record: T
  isInputNumber?: boolean
  handleSave?: (record: T) => void
}

const EditableCell = <T extends object>({ title, editable, children, dataIndex, record, isInputNumber = false, handleSave, ...restProps }: Props<T>) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef<Input>(null)
  const form = useContext(EditableContext)
  useEffect(() => {
    if (editing) {
      inputRef.current && inputRef.current.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
    form &&
      dataIndex &&
      form.setFieldsValue({
        [dataIndex]: record[dataIndex]
      })
  }

  const save = async () => {
    try {
      const values = await form?.validateFields()
      toggleEdit()
      if (isInputNumber) {
        values[dataIndex] = parseFloat(values[dataIndex].replace(/[^0-9.]/gm, ''))
      }
      handleSave && handleSave({ ...record, ...values })
    } catch (errInfo) {
      console.log('Save failed:', errInfo)
    }
  }

  let childNode = children

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`
          }
        ]}
      >
        <Input min={0} step='any' ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className='editable-cell-value-wrap' style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    )
  }

  return <td {...restProps}>{childNode}</td>
}

export default EditableCell
