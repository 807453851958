// TODO - DROP LEGACY LOGIC AND USE IT FROM REDUX IN ACTIONS
import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type ActiveWorkspaceIdSelector = (state: ReduxState) => number
const getActiveWorkspaceId: ActiveWorkspaceIdSelector = (state) => state.workspaces?.activeWorkspace?.id

export const activeWorkspaceIdSelector: ActiveWorkspaceIdSelector = createSelector(
  getActiveWorkspaceId,
  (activeWorkspaceId) => activeWorkspaceId
)
