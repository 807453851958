// TODO - remove becouse of legacy

import { DeploymentStatus } from '@merchx-v2/shared-types'
import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

type DeployLandingType = {
  deployment: {
    id: number
    workspaceId: number
    landingId: number
    deploymentPlugin: string
    deploymentSettingsId: number
    serviceDeploymentId: number
    serviceDeploymentValues: JSON
    status: DeploymentStatus
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'deployLanding', DeployLandingType>

const DEPLOY_LANDING = `
  mutation DeployLanding($landingId: Int!) {
    deployLanding(landingId: $landingId) {
      deployment {
        id
        workspaceId
        landingId
        deploymentPlugin
        deploymentSettingsId
        serviceDeploymentId
        serviceDeploymentValues
        status
      }
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type DeployLanding = (landingId: number) => Promise<DeployLandingType>

const deployLanding: DeployLanding = async (landingId) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: DEPLOY_LANDING,
      variables: { landingId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { deployLanding } = data
    if (!deployLanding) {
      throw new Error("Can't deploy landing!")
    }

    return deployLanding
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type DeployLandingEvents = {
  onSettled: (data?: DeployLandingType) => void
}

const deployLandingEvents: DeployLandingEvents = {
  onSettled: (data) => {
    if (data?.deployment.landingId) {
      queryClient.invalidateQueries(['landing', data.deployment.landingId])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useDeployLanding = () => useMutation(deployLanding, deployLandingEvents)
