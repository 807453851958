import axios from 'utils/axios'
import { useQuery } from 'react-query'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

import { Campaign, CampaignsFilters } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'campaignsForOptions', Campaign[]>

const FETCH_CAMPAIGNS_FOR_OPTIONS = `
  query fetchCampaignsForOptions($filters: CampaignsFilters!) {
  campaignsForOptions(filters: $filters) {
    id
    name
  }
}
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchCampaignsForOptions = (filters: CampaignsFilters) => Promise<Campaign[]>

const fetchCampaignsForOptions: FetchCampaignsForOptions = async (filters) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_CAMPAIGNS_FOR_OPTIONS,
      variables: { filters }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { campaignsForOptions } = data
    if (!campaignsForOptions) {
      throw new Error("Can't get campaigns!")
    }

    return campaignsForOptions
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCampaignsOptions = (filters: CampaignsFilters) =>
  useQuery(['campaignsOptions', filters], () => fetchCampaignsForOptions(filters))
