import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import * as t from '../actionTypes'

export type RemoveStripeSettingAction = FSA<undefined, number, string>
type RemoveStripeSettingColor = (stripeSettingId: number) => MrxThunk<RemoveStripeSettingAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'removeStripeSetting', boolean>

const REMOVE_STRIPE_SETTING = `
  mutation removeStripeSetting ($stripeSettingId: Int!) {
    removeStripeSetting (stripeSettingId: $stripeSettingId)
  }
`

const removeStripeSetting: RemoveStripeSettingColor = (stripeSettingId) => async (dispatch) => {
  let isRemoved = false

  dispatch({
    type: t.REMOVE_STRIPE_SETTING,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_STRIPE_SETTING,
      variables: { stripeSettingId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Empty response!')
    }

    const { removeStripeSetting } = data
    if (!removeStripeSetting) {
      throw new Error("Can't remove stripe setting!")
    }

    dispatch({
      type: t.REMOVE_STRIPE_SETTING,
      payload: stripeSettingId,
      meta: { done: true }
    })

    isRemoved = true
  } catch (err) {
    dispatch({
      type: t.REMOVE_STRIPE_SETTING,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isRemoved
}

export { removeStripeSetting }
