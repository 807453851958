import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query'
import hash from 'object-hash'
import { StorePayoutDto } from '@merchx-v2/shared-types'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

const DEFAULT_PAGE_SIZE = 30

export type StorePayoutsPage = {
  items: StorePayoutDto[]
  total: number
  currentPage: number
  pageSize: number
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'storePayoutsPage', StorePayoutsPage>

const FETCH_STORE_PAYOUTS_LIST = `
  query fetchStorePayoutsPage ($page: Int, $size: Int, $storeId: Int!) {
    storePayoutsPage(page: $page, size: $size, storeId: $storeId) {
      items {
        id
        storeId
        status
        note
        sum
        paymentMethod
        periodStart
        periodEnd
        requestedAt
        processedAt
        createdAt
        updatedAt
      }
      total
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type PageParam = {
  page?: number
  size?: number
  storeId: number
}

type FetchStoresPayoutsListArgs = {
  queryKey: string | string[]
  pageParam?: PageParam
}

type FetchStoresPage = (args: FetchStoresPayoutsListArgs) => Promise<StorePayoutsPage>

const fetchStorePayoutsPage: FetchStoresPage = async ({ pageParam }) => {
  const { page = 1, size = DEFAULT_PAGE_SIZE, storeId } = pageParam || {}
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_STORE_PAYOUTS_LIST,
      variables: { page, size, storeId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { storePayoutsPage } = data
    if (!storePayoutsPage) {
      throw new Error("Can't get store payouts page!")
    }

    return {
      items: storePayoutsPage.items,
      total: storePayoutsPage.total,
      currentPage: page,
      pageSize: size
    }
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

const queryOptions: UseInfiniteQueryOptions<StorePayoutsPage> = {
  keepPreviousData: true,
  refetchOnMount: true,
  retry: false,

  getPreviousPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0 }) => {
    if (currentPage > 1) {
      return {
        page: currentPage - 1,
        size: pageSize
      }
    }

    return undefined
  },
  getNextPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, total = 0 }) => {
    if (pageSize * currentPage < total) {
      return {
        page: currentPage + 1,
        size: pageSize
      }
    }

    return undefined
  }
}

type Args = {
  storeId: number
}

export const useStorePayoutsList = (args: Args) => {
  const cacheKey = ['storePayoutsPage', hash(args)]

  return useInfiniteQuery(
    cacheKey,
    (prev) =>
      fetchStorePayoutsPage({
        queryKey: cacheKey,
        pageParam: { ...prev.pageParam, ...args }
      }),
    queryOptions
  )
}
