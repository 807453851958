import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { SiteType } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Page, PageData } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'updatePage', boolean>

const UPDATE_PAGE = `
  mutation UpdatePage($pageId: Int!, $pageData: PageInput!) {
    updatePage(pageId: $pageId, pageData: $pageData)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UpdatePageArgs = {
  ownerType: SiteType
  ownerId: number
  pageId: number
  pageData: PageData
}

type UpdatePage = (args: UpdatePageArgs) => Promise<UpdatePageArgs>

const updatePage: UpdatePage = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_PAGE,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updatePage } = data
    if (!updatePage) {
      throw new Error("Can't update page!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UpdatePageContext = { prevPage?: Page }

type UpdatePageEvents = {
  onMutate: (variables: UpdatePageArgs) => Promise<UpdatePageContext>
  onError: (error: string, variables: UpdatePageArgs, context: UpdatePageContext) => void
  onSettled: (data?: UpdatePageArgs) => void
}

const updatePageEvents: UpdatePageEvents = {
  onMutate: async (variables: UpdatePageArgs) => {
    await queryClient.cancelQueries(['page', variables.pageId])

    // Snapshot the previous value
    const prevPage = queryClient.getQueryData<Page>(['page', variables.pageId])

    // Optimistically update to the new values
    if (prevPage) {
      queryClient.setQueryData<Page>(['page', variables.pageId], {
        ...prevPage,
        id: variables.pageId,
        ...variables.pageData
      })
    }

    return { prevPage }
  },
  onError: (_err, variables, context) => {
    if (context?.prevPage) {
      // Restore previous version of page on any error
      queryClient.setQueryData<Page>(['page', variables.pageId], context.prevPage)
    }
  },
  onSettled: (data) => {
    if (data?.pageId) {
      queryClient.invalidateQueries([`${data.ownerType.toLowerCase()}`, data.ownerId])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUpdatePage = () => useMutation(updatePage, updatePageEvents)
