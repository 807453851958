import React from 'react'
import moment from 'moment-timezone'
import numeral from 'numeral'
import { Table } from 'antd'
import styles from './OverviewReportTable.module.scss'

const createColumn = (title, key, format, opts = {}) => ({
  title,
  render: record => numeral(record[key]).format(format),
  key,
  align: 'right',
  ...opts
})

const OverviewReportTable = ({ overviewReportRows, selectedView, isLoading }) => {
  const moneyFormat = '$0,0.00'
  const integerFormat = '0,0'
  const decimalFormat = '0,0.00'

  const facebookColumnClassname =
    selectedView === 'all' || selectedView === 'facebook' ? styles['overview-report-list__column-shown'] : styles['overview-report-list__column-hidden']

  const emailColumnClassname = selectedView === 'all' || selectedView === 'email' ? styles['overview-report-list__column-shown'] : styles['overview-report-list__column-hidden']

  const subscriptionColumnClassname =
    selectedView === 'all' || selectedView === 'subscription' ? styles['overview-report-list__column-shown'] : styles['overview-report-list__column-hidden']

  const ecomColumnClassname =
    selectedView === 'all' || selectedView === 'ecom' ? styles['overview-report-list__column-shown'] : styles['overview-report-list__column-hidden']   

  const columns = [
    createColumn('Date', 'date', '', {
      render: record => (record.day === 'Totals' ? 'Totals' : moment(record.day).format('MM/DD/YYYY'))
    }),
    {
      title: 'General Information',
      children: [
        createColumn('Gross Profit', 'grossProfit', moneyFormat),
        createColumn('TotalSales', 'totalNumberOfSales', integerFormat),
        createColumn('Profit/Sale', 'profitToSale', moneyFormat),
        createColumn('Total Camps', 'numberOfCampaigns', decimalFormat),
        createColumn('Avg Sales/Camp', 'averageSalesToCampaigns', decimalFormat)
      ]
    },
    {
      title: 'Facebook',
      className: facebookColumnClassname,
      children: [
        createColumn('Profit', 'facebookProfit', moneyFormat, { className: facebookColumnClassname }),
        createColumn('Tot.Sales', 'facebookNumberOfSales', integerFormat, { className: facebookColumnClassname }),
        createColumn('Profit/Sale', 'facebookProfitToSale', moneyFormat, { className: facebookColumnClassname })
      ]
    },
    {
      title: 'Ad Spend',
      className: facebookColumnClassname,
      children: [
        createColumn('Total Ad Spend', 'totalAdSpend', moneyFormat, { className: facebookColumnClassname }),
        createColumn('Avg. CPA', 'averageCPA', moneyFormat, { className: facebookColumnClassname })
      ]
    },
    {
      title: 'Email',
      className: emailColumnClassname,
      children: [
        createColumn('Profit', 'emailProfit', moneyFormat, { className: emailColumnClassname }),
        createColumn('Tot.Sales', 'emailNumberOfSales', integerFormat, { className: emailColumnClassname }),
        createColumn('Profit/Sale', 'emailProfitToSale', moneyFormat, { className: emailColumnClassname }),
        createColumn('Email/FB Profit, %', 'emailProfitToFacebookProfit', '', {
          render: record => `${numeral(record.emailProfitToFacebookProfit).format('0.00')} %`,
          className: emailColumnClassname
        })
      ]
    },
    {
      title: 'Subscription',
      className: subscriptionColumnClassname,
      children: [
        createColumn('Profit', 'subscriptionProfit', moneyFormat, { className: subscriptionColumnClassname }),
        createColumn('Tot.Sales', 'subscriptionNumberOfSales', integerFormat, { className: subscriptionColumnClassname }),
        createColumn('Scheduled subscriptions', 'expectedSubscriptionsToCharge', integerFormat, { className: subscriptionColumnClassname }),
        createColumn('Profit/Sale', 'subscriptionProfitToSale', moneyFormat, { className: subscriptionColumnClassname })
      ]
    },
    {
      title: 'Ecom',
      className: ecomColumnClassname,
      children: [
        createColumn('Profit', 'ecomProfit', moneyFormat, { className: ecomColumnClassname }),
        createColumn('Tot.Sales', 'ecomNumberOfSales', integerFormat, { className: ecomColumnClassname }),
        createColumn('Profit/Sale', 'ecomProfitToSale', moneyFormat, { className: ecomColumnClassname })
      ]
    },
  ]

  return (
    <Table
      columns={columns}
      dataSource={overviewReportRows}
      bordered
      size='middle'
      scroll={{ x: true }}
      rowKey={record => record.id}
      pagination={false}
      loading={isLoading}
      rowClassName={record => (record.day === 'Totals' ? styles['ant-table-row'] : '')}
    />
  )
}

export default OverviewReportTable
