import React, { useEffect, useState } from 'react'
import { Form, Modal, Upload, Button, Popconfirm, message } from 'antd'
import hash from 'object-hash'
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import notification from 'mrx-notification'
import { useClearImageSetting, useUploadSettingImage } from '../../hooks'
import { CustomFile, Setting } from '../../types'
import styles from './FormItemVideoSetting.module.scss'

type PropsType = {
  setting: Setting
  selfUpdate?: boolean
  onValueChanged?: (setting: Setting, newValue: any) => void
  onVideoUploaded?: (setting: Setting, file: CustomFile) => void
}

const FormItemVideoSetting = React.memo((props: PropsType) => {
  const {
    setting,
    selfUpdate,

    onValueChanged,
    onVideoUploaded
  } = props

  const [previewVisible, setPreviewVisible] = useState<boolean>(false)
  const [previewVideo, setPreviewVideo] = useState<string>('')
  const [previewTitle, setPreviewTitle] = useState<string>('')

  const clearVideoSetting = useClearImageSetting()
  const uploadSettingVideo = useUploadSettingImage()

  useEffect(() => {
    // if setting already having the video URL let's use it to display the preview
    if (setting?.jsonValue?.signedUrl) {
      setPreviewVideo(setting.jsonValue.signedUrl)
      setPreviewTitle(setting.name)
    } else {
      setPreviewVideo('')
      setPreviewTitle('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(setting)])

  const handleCancel = () => setPreviewVisible(false)

  // Helping us to check the upload status of new video
  const handleOnVideoChanged = (info: UploadChangeParam) => {
    if (info.file.status !== 'uploading') {
    }
    if (info.file.status === 'done') {
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`)
    }
  }

  // Help us to update the video preview
  const handleVideo = async (file: any, name: string) => {
    file.url = URL.createObjectURL(file)
    const fixedName = name.trim().replace(/\s+/g, '_') as string
    setPreviewVideo(file.url)
    setPreviewTitle(fixedName)

    if (selfUpdate) {
      uploadSettingVideo.mutate(
        {
          ownerType: setting.ownerType,
          ownerId: setting.ownerId,
          settingId: setting.id,
          assetData: { name: fixedName, file }
        },
        {
          onSuccess: () => {
            notification.success({
              message: 'Successfully',
              description: `${setting.name} setting was updated!`
            })
          },
          onError: (error) => {
            notification.error({
              message: `${setting.name} setting error!`,
              description: error.toString()
            })
          }
        }
      )
    } else {
      onVideoUploaded(setting, { file, name: fixedName })
    }
  }

  const handleRemoveVideo = () => {
    clearVideoSetting.mutate(
      { ownerType: setting.ownerType, ownerId: +setting.ownerId, settingId: setting.id },
      {
        onSuccess: (data) => {
          notification.success({
            message: 'Successfully',
            description: 'Video was uploaded successfully!'
          })
          onValueChanged(setting, data + '')
        },
        onError: (error) => {
          notification.error({
            message: `${setting.name} setting error!`,
            description: error.toString()
          })
        }
      }
    )
  }

  return (
    <Form.Item
      name={setting.alias}
      label={setting.name}
      key={setting.id}
      valuePropName=''
      rules={[{ required: setting.required, message: 'Please upload file!' }]}
    >
      <div className={styles.uploadSettingVideoContainer}>
        {previewVideo && (
          <video
            autoPlay
            muted
            loop
            playsInline
            className={styles.video}
            onClick={() => {
              if (previewVideo) {
                setPreviewVisible(true)
              }
            }}
          >
            <source src={previewVideo} />
          </video>
        )}
        <div>
          <Upload
            name='file'
            accept='video/*'
            customRequest={(info: any) => {
              const fileName = info.file.name.replace(/\.[^.]+$/gm, '')
              handleVideo(info.file, fileName)
            }}
            showUploadList={false}
            onChange={(info: UploadChangeParam<UploadFile<any>>) => handleOnVideoChanged(info)}
          >
            <Button
              className={styles.uploadButton}
              icon={<UploadOutlined />}
              loading={uploadSettingVideo.isLoading}
              disabled={uploadSettingVideo.isLoading}
            >
              Click to Upload
            </Button>
          </Upload>
          {previewVideo && (
            <Popconfirm
              title='Are you sure delete this video?'
              onConfirm={handleRemoveVideo}
              onCancel={(e) => e?.stopPropagation()}
              okText='Yes'
              cancelText='No'
            >
              <Button
                className={styles.removeButton}
                loading={clearVideoSetting.isLoading}
                disabled={clearVideoSetting.isLoading || uploadSettingVideo.isLoading}
                icon={<DeleteOutlined />}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              />
            </Popconfirm>
          )}
        </div>
      </div>
      <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
        <video autoPlay muted loop playsInline style={{ width: '100%' }}>
          <source src={previewVideo} />
        </video>
      </Modal>
    </Form.Item>
  )
})

export default FormItemVideoSetting
