import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { OrderNote } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'removeOrderNote', boolean>

const REMOVE_ORDER_NOTE = `
  mutation removeOrderNote ($orderNoteId: Int!) {
    removeOrderNote (orderNoteId: $orderNoteId)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveOrderNote = (orderNoteId: number) => Promise<number>

const removeOrderNote: RemoveOrderNote = async (orderNoteId) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_ORDER_NOTE,
      variables: {
        orderNoteId
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeOrderNote } = data
    if (!removeOrderNote) {
      throw new Error("Can't remove order note!")
    }

    return orderNoteId
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveOrderNoteContext = { prevOrderNote?: OrderNote }

type RemoveOrderNoteEvents = {
  onMutate: (orderNoteId: number) => Promise<RemoveOrderNoteContext>
  onError: (error: string, orderNoteId: number, context: RemoveOrderNoteContext) => void
  onSettled: (orderNoteId?: number) => void
}

const removeOrderNoteEvents: RemoveOrderNoteEvents = {
  onMutate: async (orderNoteId) => {
    await queryClient.cancelQueries(['orderNote', orderNoteId])

    // Snapshot the previous value
    const prevOrderNote = queryClient.getQueryData<OrderNote>(['orderNote', orderNoteId])

    if (prevOrderNote) {
      queryClient.removeQueries(['orderNote', orderNoteId])
    }

    return { prevOrderNote }
  },
  onError: (_err, orderNoteId, context) => {
    if (context?.prevOrderNote) {
      // Restore currrency on any error
      queryClient.setQueryData<OrderNote>(['orderNote', orderNoteId], context.prevOrderNote)
    }
  },
  onSettled: (orderNoteId: number) => {
    if (orderNoteId) {
      queryClient.invalidateQueries(['orderNote', orderNoteId])
      queryClient.invalidateQueries(['order'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useRemoveOrderNote = () => useMutation(removeOrderNote, removeOrderNoteEvents)
