import { connect, ConnectedProps } from 'react-redux'
import { DeploymentPlugin } from '@merchx-v2/shared-types'
import { ReduxState } from 'store/createRootReducer'
import * as awsSettingsFeature from 'features/awsSettings'
import * as nginxSettingsFeature from 'features/nginxSettings'

type OwnProps = {
  plugin: DeploymentPlugin
  initialOption: SelectOption
  disabled?: boolean
  onSelect: (selectOption: SelectOption) => void
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  let isLoading = false
  let options = []

  switch (ownProps.plugin) {
    case 'AMAZON': {
      isLoading = awsSettingsFeature.selectors.isLoadingSelector(state, 'isAwsOptionsLoading')
      options = awsSettingsFeature.selectors.awsSettingsSelectOptionsSelector(state)
      break
    }

    case 'NGINX': {
      isLoading = nginxSettingsFeature.selectors.isLoadingSelector(state, 'isNginxOptionsLoading')
      options = nginxSettingsFeature.selectors.nginxSettingsSelectOptionsSelector(state)
      break
    }
  }

  return {
    isLoading,
    options
  }
}

const mapDispatchToProps = {
  fetchAwsSettingsOptions: awsSettingsFeature.actions.fetchAwsSettingsForOptions,
  fetchNginxSettingsOptions: nginxSettingsFeature.actions.fetchNginxSettingsForOptions
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
