// TODO - remove becouse of legacy

import React from 'react'
import { CreateQuizQuestionDto, QuizQuestionDto } from '@merchx-v2/shared-types'
import { Input, Upload, Button } from 'antd'
import { DeleteTwoTone, UploadOutlined } from '@ant-design/icons'
import styles from './QuizQuestionInput.module.scss'

type Props = {
  question: CreateQuizQuestionDto | QuizQuestionDto
  disabled?: boolean
  previewImage?: string
  onQuestionChanged: (question: string) => void
  onQuestionRemoved: () => void
  handleAddImage: (file: any) => void
  setPreviewImage: (url: string) => void
}

const QuizQuestionInput = (args: Props) => {
  const { question, disabled, previewImage, onQuestionChanged, onQuestionRemoved, handleAddImage, setPreviewImage } = args

  // @ts-ignore FIXME
  const imageUrl = previewImage || question.image?.signedUrl

  return (
    <div className={styles.root}>
      <div className={styles.labeledInput}>
        <div className={styles.fieldContainer}>
          <Input value={question.question} placeholder='Title' onChange={(e) => onQuestionChanged(e.target.value)} disabled={disabled} />
        </div>

        <div className={styles.imageContainer}>
          {!!imageUrl && (
            <img
              src={imageUrl}
              alt=''
              className={styles.image}
              onClick={() => {
                setPreviewImage(imageUrl)
              }}
            />
          )}
          <Upload accept='image/*' customRequest={(info) => handleAddImage(info.file)} showUploadList={false}>
            <Button icon={<UploadOutlined />} loading={disabled} disabled={disabled || typeof question.id !== 'number'}>
              Click to Upload
            </Button>
          </Upload>
        </div>
      </div>
      <DeleteTwoTone twoToneColor='tomato' style={{ marginLeft: 10, opacity: 0 }} onClick={() => onQuestionRemoved()} />
    </div>
  )
}

export default QuizQuestionInput
