import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'
import * as productTags from 'features/productTags'

const mapStateToProps = (state: ReduxState) => ({
  processingCosts: selectors.processingCostsListSelector(state),
  isLoading: selectors.isLoadingSelectorByKey(state, 'isListLoading'),
  isNeedToFetchList: selectors.isNeedToFetchListSelector(state),
  error: selectors.errorSelector(state),
  pagination: selectors.paginationSelector(state),
  datesRange: selectors.datesRangeSelector(state),
  productTags: productTags.selectors.productTagsForOptionsSelector(state),
})

const mapDispatchToProps = {
  fetchProcessingCosts: actions.fetchProcessingCosts,
  changeCurrentPage: actions.changeCurrentPage,
  changeDatesRange: actions.changeDatesRange,
  fetchProductTagsForOptions: productTags.actions.fetchProductTagsForOptions,
  removeProcessingCost: actions.removeProcessingCost
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector>
