import React, { useState, useEffect, useRef } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Drawer, Input, Form } from 'antd'
import { SiteType } from '@merchx-v2/shared-types'
import notification from 'mrx-notification'
import { useCreateDependency } from '../../hooks'
import styles from './CreateDependency.module.scss'

type PropsType = {
  ownerType: SiteType
  ownerId: number
}

const CreateDependency = (props: PropsType) => {
  const { ownerType, ownerId } = props

  const [isVisible, setVisible] = useState(false)
  const refEl = useRef<Input>(null)

  const [form] = Form.useForm()

  const createDependency = useCreateDependency()

  useEffect(() => {
    if (isVisible) {
      refEl.current && refEl.current.focus()
    }
  }, [isVisible])

  const handleClose = () => {
    setVisible(false)
    form.resetFields()
  }

  const showDrawer = () => {
    setVisible(!isVisible)
  }

  // TODO - fix any values
  const onFinishHandler = async (values: any) => {
    createDependency.mutate(
      {
        ownerType,
        ownerId,
        dependencyData: {
          name: values.name,
          version: values.version
        }
      },
      {
        onSuccess: () => {
          notification.success({
            message: 'Successfully',
            description: 'Template Dependency was created successfully!'
          })
          handleClose()
        },
        onError: (error) => {
          notification.error({
            message: 'Error!',
            description: error.toString()
          })
        }
      }
    )
  }

  return (
    <>
      <Button type='primary' onClick={showDrawer} className={styles.createButton}>
        <PlusOutlined />
        CREATE DEPENDENCY
      </Button>
      <Drawer title='Create Template Dependency' width='400' onClose={handleClose} visible={isVisible} forceRender>
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
          <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input dependency name!' }]}>
            <Input ref={refEl} />
          </Form.Item>
          <Form.Item name='version' label='Version' rules={[{ required: true, message: 'Please input dependency version!' }]}>
            <Input />
          </Form.Item>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button onClick={handleClose} className={styles.closeButton}>
              Close
            </Button>
            <Button type='primary' htmlType='submit' loading={createDependency.isLoading}>
              Create
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

export default CreateDependency
