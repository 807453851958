import * as t from '../actionTypes'
import { Workspace } from '../types'

export type WorkspacesLoadedAction = BaseFSA<Workspace[]>

export const workspacesLoaded = (workspaces: Workspace[]): MrxThunk<WorkspacesLoadedAction> => dispatch => {
  dispatch({
    type: t.WORKSPACES_LOADED,
    payload: workspaces
  })
}
