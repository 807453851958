import React, { useState, useEffect, useRef } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Drawer, Input, Form } from 'antd'
import { Store } from 'antd/lib/form/interface'
import notification from 'mrx-notification'
import { useCreateOrderNote } from '../../hooks'
import { connector, PropsFromRedux } from './container'
import styles from './CreateOrderNote.module.scss'

type Props = PropsFromRedux & {
  orderId?: number
  className?: string
}
interface FormStore extends Store {
  note: string
}

const CreateOrderNote = (props: Props) => {
  const [isVisible, setVisible] = useState(false)
  const refEl = useRef<Input>(null)

  const { orderId, className, workspaceId } = props

  const createOrderNote = useCreateOrderNote()

  useEffect(() => {
    if (isVisible) {
      refEl.current && refEl.current.focus()
    }
  }, [isVisible])

  const showDrawer = () => {
    setVisible(!isVisible)
  }

  const [form] = Form.useForm()

  const handleClose = () => {
    setVisible(false)
    form.resetFields()
  }

  useEffect(() => {
    if (createOrderNote.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Order Note was created successfully!'
      })
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderNote.isSuccess])

  const onFinishHandler = async (values: FormStore) => {
    createOrderNote.mutate({
      workspaceId,
      note: values.note,
      orderId: orderId
    })
  }

  return (
    <>
      <Button className={className} type='primary' onClick={showDrawer}>
        <PlusOutlined />
        CREATE NOTE
      </Button>
      <Drawer title='Create Note' width='400' onClose={handleClose} visible={isVisible} forceRender>
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
          <Form.Item name='note' label='Note' rules={[{ required: true, message: 'Please input note!' }]}>
            <Input style={{ width: '100%' }} ref={refEl} />
          </Form.Item>

          <div className={styles.buttonsContainer}>
            <Button onClick={handleClose} className={styles.createButton}>
              Close
            </Button>
            <Button type='primary' htmlType='submit' loading={createOrderNote.isLoading}>
              Create
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

export default connector(CreateOrderNote)
