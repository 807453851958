import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { CanceledSubscriptions } from '../types'

export type FetchCanceledSubscriptionsReportAction = FSA<undefined, CanceledSubscriptions[], string>
type FetchCanceledSubscriptionsReport = () => MrxThunk<FetchCanceledSubscriptionsReportAction>
type QueryResponse = GraphQLResponse<'canceledSubscriptions', CanceledSubscriptions[]>

const FETCH_CANCELED_SUBSCRIPTIONS_REPORT = `
  query CanceledSubscriptions ($workspaceId: Int!) {
    canceledSubscriptions (workspaceId: $workspaceId) {
      cycles
      customersCount
      cancelRate
    }
  }
`

const fetchCanceledSubscriptionsReport: FetchCanceledSubscriptionsReport = () => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_CANCELED_SUBSCRIPTIONS_REPORT,
    meta: { done: false }
  })

  try {
    const {
      workspaces: {
        activeWorkspace: { id: workspaceId }
      }
    } = getState()

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_CANCELED_SUBSCRIPTIONS_REPORT,
      variables: { workspaceId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { canceledSubscriptions } = data
    if (!canceledSubscriptions) {
      throw new Error("Can't get canceled subscriptions report!")
    }

    dispatch({
      type: t.FETCH_CANCELED_SUBSCRIPTIONS_REPORT,
      payload: canceledSubscriptions,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_CANCELED_SUBSCRIPTIONS_REPORT,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchCanceledSubscriptionsReport }
