import * as t from '../actionTypes'
import { changeLocation } from 'features/navigation/actions'

export type LogoutAction = BaseFSA<undefined>

export const logout = (): MrxThunk<LogoutAction> => (dispatch, getState) => {
  dispatch({
    type: t.LOGOUT
  })

  changeLocation('/login')(dispatch, getState, null)
}
