import axios from 'utils/axios'
import { useQuery } from 'react-query'
import { ShippingMethodDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type ShippingMethodForOptions = ShippingMethodDto & { name: string }

type QueryResponse = GraphQLResponse<'DTGProductsForOptions', ShippingMethodForOptions[]>

const FETCH_DTG_PRODUCTS_FOR_OPTIONS = `
  query fetchDTGProductsForOptions($searchText: String) {
    DTGProductsForOptions(searchText: $searchText) {
      id
      name
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchDTGProductsForOptions = (searchText: string, limit?: number) => Promise<ShippingMethodForOptions[]>

const fetchDTGProductsForOptions: FetchDTGProductsForOptions = async (searchText, limit = 20) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_DTG_PRODUCTS_FOR_OPTIONS,
      variables: { searchText, limit }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { DTGProductsForOptions } = data
    if (!DTGProductsForOptions) {
      throw new Error("Can't get DTGProducts!")
    }

    return DTGProductsForOptions
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useDTGProductsOptions = (searchText: string, limit = 20) =>
  useQuery(['DTGProductsOptions', searchText], () => fetchDTGProductsForOptions(searchText, limit))
