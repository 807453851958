import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

export type CancelTaskAction = FSA<undefined, number, string>
type CancelTask = (taskId: number) => MrxThunk<CancelTaskAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'cancelTask', boolean>

const CANCEL_TASK = `
  mutation cancelTask ($taskId: Int!) {
  cancelTask(taskId: $taskId)
}
`

const cancelTask: CancelTask = (taskId) => async (dispatch) => {
  let isCanceled = false

  dispatch({
    type: t.CANCEL_TASK,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CANCEL_TASK,
      variables: { taskId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Empty response!')
    }

    const { cancelTask } = data
    if (!cancelTask) {
      throw new Error("Can't cancel task!")
    }

    dispatch({
      type: t.CANCEL_TASK,
      payload: taskId,
      meta: { done: true }
    })

    isCanceled = true
  } catch (err) {
    dispatch({
      type: t.CANCEL_TASK,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isCanceled
}

export { cancelTask }
