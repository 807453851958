import produce from 'immer'
import * as t from './actionTypes'
import { actionTypes as nt } from 'features/navigation'
import { actionTypes as wt } from 'features/workspaces'
import { isSuccessAction, isErrorAction } from 'types'
import { DTGProductsState } from './types'
import createReducer from 'store/createReducer'
import { addItemToPagination } from 'utils/addItemToPagination'
import { removeItemFromPagination } from 'utils/removeItemFromPagination'
import {
  ChangeCurrentPageAction,
  ChangePageSizeAction,
  CreateDTGProductAction,
  FetchDTGProductsForOptionsAction,
  FetchDTGProductsListAction,
  RemoveDTGProductAction
} from './actions'

const initState: DTGProductsState = {
  DTGProducts: {},
  DTGProductsForOptions: [],
  pagination: {
    pages: {},
    total: 0,
    currentPage: 1,
    size: 10
  },
  UIState: {
    isCreateDTGProduct: false,
    isFetchDTGProduct: false,
    isOptionsLoading: false,
    isListLoading: false,
    isRemoveDTGProduct: false
  }
}

const reducer = createReducer(initState, {
  [t.CREATE_DTG_PRODUCT]: (state, action: CreateDTGProductAction) =>
    produce(state, draft => {
      draft.UIState.isCreateDTGProduct = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isCreateDTGProduct = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { id } = action.payload
        draft.DTGProducts[id] = action.payload
        addItemToPagination(draft.pagination, id)
      }
    }),

  [t.FETCH_DTG_PRODUCT_LIST]: (state, action: FetchDTGProductsListAction) =>
    produce(state, draft => {
      draft.UIState.isListLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isListLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        action.payload.items.forEach(item => {
          draft.DTGProducts[item.id] = item
        })
        draft.pagination.total = action.payload.total
        draft.pagination.pages[action.payload.page] = action.payload.items.map(item => item.id)
      }
    }),

  [t.FETCH_DTG_PRODUCT_FOR_OPTIONS]: (state, action: FetchDTGProductsForOptionsAction) =>
    produce(state, draft => {
      draft.UIState.isOptionsLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isOptionsLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.DTGProductsForOptions = action.payload
      }
    }),

  [t.REMOVE_DTG_PRODUCT]: (state, action: RemoveDTGProductAction) =>
    produce(state, draft => {
      draft.UIState.isRemoveDTGProduct = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isRemoveDTGProduct = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.DTGProducts[action.payload]) {
          delete draft.DTGProducts[action.payload]
          removeItemFromPagination(draft.pagination, action.payload)
        }
      }
    }),

  [t.CHANGE_CURRENT_PAGE]: (state, action: ChangeCurrentPageAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.pagination.currentPage = action.payload
      }
    }),

  [t.CHANGE_PAGE_SIZE]: (state, action: ChangePageSizeAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.pagination.size = action.payload
      }
    }),

  [wt.SWITCH_WORKSPACE]: state =>
    produce(state, draft => {
      draft.pagination = initState.pagination
    }),

  [nt.LOCATION_CHANGE]: state =>
    produce(state, draft => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default reducer
