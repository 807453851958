import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { SiteType } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Autoresponder } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'removeAutoresponder', boolean>

const REMOVE_AUTORESPONDER = `
  mutation removeAutoresponder($autoresponderId: Int!) {
    removeAutoresponder(autoresponderId: $autoresponderId)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveAutoresponderArgs = {
  ownerType: SiteType
  ownerId: number
  autoresponderId: number
}

type RemoveAutoresponder = (args: RemoveAutoresponderArgs) => Promise<RemoveAutoresponderArgs>

const removeAutoresponder: RemoveAutoresponder = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_AUTORESPONDER,
      variables: {
        autoresponderId: args.autoresponderId
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeAutoresponder } = data
    if (!removeAutoresponder) {
      throw new Error("Can't remove autoresponder!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveAutoresponderContext = { prevAutoresponder?: Autoresponder }

type RemoveAutoresponderEvents = {
  onMutate: (variables: RemoveAutoresponderArgs) => Promise<RemoveAutoresponderContext>
  onError: (error: string, variables: RemoveAutoresponderArgs, context: RemoveAutoresponderContext) => void
  onSettled: (variables?: RemoveAutoresponderArgs) => void
}

const removeAutoresponderEvents: RemoveAutoresponderEvents = {
  onMutate: async ({ autoresponderId }) => {
    await queryClient.cancelQueries(['autoresponder', autoresponderId])

    // Snapshot the previous value
    const prevAutoresponder = queryClient.getQueryData<Autoresponder>(['autoresponder', autoresponderId])

    if (prevAutoresponder) {
      queryClient.removeQueries(['autoresponder', autoresponderId])
    }

    return { prevAutoresponder }
  },
  onError: (_err, variables, context) => {
    if (context?.prevAutoresponder) {
      // Restore autoresponder on any error
      queryClient.setQueryData<Autoresponder>(['autoresponder', variables.autoresponderId], context.prevAutoresponder)
    }
  },
  onSettled: (data: RemoveAutoresponderArgs) => {
    if (data?.autoresponderId) {
      queryClient.invalidateQueries([`${data.ownerType.toLowerCase()}`, +data.ownerId])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useRemoveAutoresponder = () => useMutation(removeAutoresponder, removeAutoresponderEvents)
