// TODO - remove becouse of legacy

import { ParametricSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type isLoadingKeyType = 'isLoadingList' | 'isLoadingSave' | 'isLoadingReport'

export const isLoadingSelectorByKey: ParametricSelector<ReduxState, isLoadingKeyType, boolean> = (state, isLoadingKey) =>
  state.adSpends.UIState[isLoadingKey] || false

export default isLoadingSelectorByKey
