import { ProductPriceOwner } from '@merchx-v2/shared-types'
import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Product } from 'features/types'

export type FetchProductAction = FSA<undefined, Product, string>
type FetchProduct = (
  productPriceOwnerType: ProductPriceOwner,
  productPriceOwnerId: number,
  productId: number,
  campaignId?: number
) => MrxThunk<FetchProductAction>
type QueryResponse = GraphQLResponse<'product', Product>

const FETCH_PRODUCT = (isNeedCampaignPrices) => `
  query fetchProduct($productId: Int!, $productPriceOwnerType: ProductPriceOwner!, $productPriceOwnerId: Int!${
    isNeedCampaignPrices ? `, $campaignId: Int` : ''
  }) {
    product(productId: $productId) {
      id
      campaignId
      campaign {
        name
      }
      name
      displayName
      description
      sku
      tags

      productType
      productType2

      basePrice
      baseDiscount
      baseCost
      baseMemberPrice

      ownerId
      ownerType
      owner {
        id
        firstName
        lastName
        email
        createdFromLandingUrl
        createdAt
        updatedAt
      }

      weight
      weightAccuracy
      weightUnit

      hasCustomShipping
      customShippingPrice

      useReplacementForStock
      stockProductId
      stockProductName
      sellWhenOutOfStock
      
      prices(ownerType: $productPriceOwnerType, ownerId: $productPriceOwnerId) {
        id
        sku
        price
        discount
        memberPrice
        attributes {
          id
          attribute
          value
        }
      }

      isPriceChangingLocked

      ${
        isNeedCampaignPrices
          ? `campaignPrices: prices(ownerType: CAMPAIGN, ownerId: $campaignId) {
          id
          sku
          price
          discount
          memberPrice
          attributes {
            id
            attribute
            value
          }
        }`
          : ''
      }

      subscriptionItems {
        date
        productId
        product {
          id
          name
          productType
        }
        quantity
      }

      image
      images {
        id
        workspaceId
        name
        extension
        productId
        imageUrl
        s3bucket
        s3key
        createdAt
        updatedAt
      }

      assets {
        id
        name
        extension
        ownerType
        ownerId
        mimeType
        s3bucket
        s3key
        signedUrl
        createdAt
        updatedAt
      }

      hasCustomSeo
      seoTitle
      seoDescription
      seoUrl
      seoKeywords
      isActive
      customizable

      sortIndex
      createdAt
      updatedAt
    }
  }
`

const fetchProduct: FetchProduct = (productPriceOwnerType, productPriceOwnerId, productId, campaignId) => async (dispatch) => {
  dispatch({
    type: t.FETCH_PRODUCT,
    meta: { done: false }
  })

  try {
    const variables: any = { productPriceOwnerType, productPriceOwnerId, productId }

    if (campaignId) {
      variables.campaignId = campaignId
    }

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_PRODUCT(!!campaignId),
      variables
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { product } = data
    if (!product) {
      throw new Error("Can't get product!")
    }

    dispatch({
      type: t.FETCH_PRODUCT,
      payload: product,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_PRODUCT,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchProduct }
