import { connect, ConnectedProps } from 'react-redux'
import { match } from 'react-router'
import { ReduxState } from 'store/createRootReducer'

type OwnProps = {
  match: match<{ promocodeCode?: string }>
}

const mapStateToProps = (state: ReduxState, { match }: OwnProps) => {
  const { promocodeCode } = match.params
  console.log(match)
  return {
    promocodeCode
  }
}

const mapDispatchToProps = {}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
