import * as t from '../actionTypes'

type Payload = {
  size: number
  campaignId: number
}

export type ChangePageSizeAction = BaseFSA<Payload>
type ChangePageSize = (size: number, campaignId: number) => MrxThunk<ChangePageSizeAction>

const changePageSize: ChangePageSize = (size, campaignId) => async (dispatch) => {
  dispatch({
    type: t.CHANGE_PAGE_SIZE,
    payload: {
      size,
      campaignId
    }
  })
}

export { changePageSize }
