import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'setUserActiveCampaign', boolean>

const UPDATE_CAMPAIGN = `
  mutation setUserActiveCampaign ($campaignId: Int!) {
    setUserActiveCampaign(campaignId: $campaignId)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type SetUserActiveCampaignArgs = {
  campaignId: number
}

type SetUserActiveCampaign = (args: SetUserActiveCampaignArgs) => Promise<SetUserActiveCampaignArgs>

const setUserActiveCampaign: SetUserActiveCampaign = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_CAMPAIGN,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { setUserActiveCampaign } = data
    if (!setUserActiveCampaign) {
      throw new Error("Can't update campaign!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useSetUserActiveCampaign = () => useMutation(setUserActiveCampaign)
