import React from 'react';
import useReactRouter from 'use-react-router';
import { Breadcrumb } from 'antd';
import * as styles from './styles';

type Props = {
  items: Array<{ title: string; url: string }>;
}

const Breadcrumbs: React.FC<Props> = React.memo<Props>(({ items }) => {
  const { history } = useReactRouter();

  return (
    <Breadcrumb style={styles.breadcrumbs}>
      {items.map((crumb, index) => (
        <Breadcrumb.Item key={`${index}-${crumb.title}`} onClick={() => history.push(crumb.url)}>
          <span style={styles.breadcrumbItem}>{crumb.title}</span>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
});

export default Breadcrumbs;
