import React, { useEffect, useState } from 'react'
import { Popconfirm } from 'antd'
import { CloseCircleTwoTone } from '@ant-design/icons'
import notification from 'mrx-notification'
import { useRemovePluginSettings } from '../../hooks'
import { PluginSetting as PluginSettingType } from '../../types'
import styles from './PluginSetting.module.scss'

type Props = {
  plugin: PluginSettingType
  onClick?: () => void
}

const pluginLogoLinks = {
  GOOGLE_SHOPPING: 'https://cdn.merchx.com/brand-logo/google-shopping.jpg',
  CUSTOM_GATEWAY: 'https://cdn.merchx.com/brand-logo/custom-gateway.jpg',
  FACEBOOK_CATALOG: 'https://cdn.merchx.com/brand-logo/facebook-shop-logo.jpg',
  TWILIO: 'https://cdn.merchx.com/brand-logo/twilio-logo.jpg'
}

const PluginSetting = ({ plugin, onClick }: Props) => {
  const [isFocused, toggleFocused] = useState(false)
  const removePluginSettings = useRemovePluginSettings()

  useEffect(() => {
    if (removePluginSettings.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Plugin Settings was removed successfully!'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removePluginSettings.isSuccess])

  const doConfirm = async (e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    removePluginSettings.mutate({
      workspaceId: plugin.workspaceId,
      ownerType: plugin.ownerType,
      ownerId: plugin.ownerId,
      pluginType: plugin.pluginType,
      pluginName: plugin.pluginName
    })
  }

  const unfocusConfirm = (e?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>) => {
    e && e.stopPropagation()
    toggleFocused(false)
  }

  return (
    <div className={styles.pluginSettingContainer} onClick={onClick}>
      <div className={styles.pluginSetting} onClick={onClick}>
        {!!pluginLogoLinks[plugin.pluginName] && (
          <img src={pluginLogoLinks[plugin.pluginName]} alt={plugin.pluginName} className={styles.pluginLogo} />
        )}
        {plugin.pluginName[0] + plugin.pluginName.slice(1).toLowerCase().split('_').join(' ')}
      </div>

      <Popconfirm
        title='Are you sure delete this plugin?'
        onConfirm={doConfirm}
        onCancel={unfocusConfirm}
        okText='Yes'
        cancelText='No'
        onVisibleChange={(visible, e) => !visible && unfocusConfirm(e)}
      >
        <CloseCircleTwoTone
          twoToneColor='#ec1c24'
          style={{ fontSize: '20px' }}
          className={isFocused ? styles.removeButton : styles.removeButtonHidden}
          onClick={e => {
            e.stopPropagation()
            toggleFocused(true)
          }}
        />
      </Popconfirm>
    </div>
  )
}

export default PluginSetting
