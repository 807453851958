import React, { useContext, useEffect, useState, useMemo } from 'react'
import useReactRouter from 'use-react-router'
import { Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import * as QueryString from 'query-string'
import { Button, PageHeader, Table, Layout, Input, Select } from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import notification from 'mrx-notification'
import { ColumnsType } from 'antd/lib/table'
import { GlobalContext } from 'appContexts'
import { Breadcrumbs } from 'components'
import * as rootStyles from 'assets/layoutStyle'
import useDebounce from 'utils/useDebounce'
import { useProductsList } from '../../hooks'
import { Product } from 'features/types'
import styles from './ProductsList.module.scss'
import { connector, PropsFromRedux } from './container'

const { Option } = Select
enum ProductStatus {
  All = 'ALL',
  Active = 'ACTIVE',
  Disabled = 'DISABLED'
}

const ProductsList = ({ showHeader = true }: PropsFromRedux) => {
  const { campaignId, workspaceId } = useContext(GlobalContext)

  const productPriceOwnerType = 'CAMPAIGN'
  const productPriceOwnerId = campaignId

  const { history } = useReactRouter()

  const [searchQuery, setSearchQuery] = useState<string>()
  const [productStatus, setProductStatus] = useState<ProductStatus>(ProductStatus.All)

  const debouncedSearchValue = useDebounce(searchQuery, 300)

  useEffect(() => {
    const url = QueryString.stringify({
      search: debouncedSearchValue
    })
    history.push(`${history.location.pathname}?${url}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue])

  const productsList = useProductsList({
    filters: { workspaceId: +workspaceId, campaignId, searchText: debouncedSearchValue, status: productStatus },
    productPriceOwnerType,
    productPriceOwnerId
  })

  const products = useMemo(() => productsList.data?.pages.map(page => page.items).flat() || [], [productsList.dataUpdatedAt])

  const page = productsList.data?.pages.at(-1).currentPage || 1
  const size = productsList.data?.pages.at(-1).pageSize || 30

  useEffect(() => {
    const searchQueryFromParams = QueryString.parse(history.location.search)
    if (typeof searchQueryFromParams.search === 'string') {
      setSearchQuery(searchQueryFromParams.search)
    }

    return productsList.remove
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (productsList.error) {
      notification.error({
        message: 'Products error!',
        description: productsList.error instanceof Error ? productsList.error.message : productsList.error.toString()
      })
    }
  }, [productsList.error])

  const onSearchInputChangeHandler = (value) => {
    setSearchQuery(value)
  }

  const columns: ColumnsType<Product> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'name'
    },
    {
      title: 'Product Type',
      dataIndex: 'productType',
      key: 'productType'
    },
    {
      title: 'Store',
      render: (_value, record) => <Link to={`/stores/${record.storeId}/details`}>{record.storeName}</Link>,
      key: 'storeName'
    },
    {
      title: 'Status',
      dataIndex: '',
      key: 'isActive',
      render: (record) => (record.isActive ? 'ACTIVE' : 'DISABLED')
    },
    {
      title: 'Edit',
      dataIndex: '',
      key: 'edit',
      width: '10%',
      align: 'center',
      render: (record: Product) => (
        <Link to={`/products/${record.id}/edit`}>
          <EditTwoTone
            style={{ fontSize: '20px' }}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              history.push(`/products/${record.id}/edit`)
            }}
          />
        </Link>
      )
    }
  ]

  const handleProductStatusSelect = (value) => {
    setProductStatus(value)
  }

  return (
    <Layout style={rootStyles.root}>
      {showHeader && (
        <>
          <Layout.Header style={rootStyles.header}>
            <PageHeader
              onBack={() => history.goBack()}
              title='Products'
              extra={[
                <div key='1' className={styles.extraContent}>
                  <Input.Search
                    key='search-field'
                    placeholder='Search'
                    onChange={(e) => onSearchInputChangeHandler(e.target.value)}
                    style={{ width: 200, marginLeft: 10 }}
                    value={searchQuery}
                    allowClear
                  />

                  <Select className={styles.statusSelect} onChange={handleProductStatusSelect} value={productStatus} placeholder='Status'>
                    <Option value={ProductStatus.All}>All</Option>
                    <Option value={ProductStatus.Active}>Active</Option>
                    <Option value={ProductStatus.Disabled}>Disabled</Option>
                  </Select>

                  <div className={styles.createButton}>
                    <Button type='primary' onClick={() => history.push(`/products/create`)}>
                      + CREATE PRODUCT
                    </Button>
                  </div>
                </div>
              ]}
            />
          </Layout.Header>
          <Layout.Content style={rootStyles.contentBreadcrumbs}>
            <Breadcrumbs
              items={[
                { title: 'Main', url: '/' },
                { title: 'Products', url: '/products' }
              ]}
            />
          </Layout.Content>
        </>
      )}
      <Layout.Content className={showHeader && styles.contentComponent}>
        <InfiniteScroll
          style={{ paddingBottom: '30px' }}
          dataLength={page * size}
          next={productsList.fetchNextPage}
          hasMore={!!productsList.hasNextPage}
          loader={<h4>Loading...</h4>}
          refreshFunction={productsList.refetch}
          scrollThreshold={0.8}
        >
          <Table
            columns={columns}
            dataSource={products}
            bordered
            rowKey={(record) => record.id}
            pagination={false}
            loading={productsList.isLoading}
          />
        </InfiniteScroll>
      </Layout.Content>
    </Layout>
  )
}

export default connector(ProductsList)
