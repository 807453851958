import React, { useState, useEffect, useContext } from 'react'
import useReactRouter from 'use-react-router'
import { Layout, Button, PageHeader } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import notification from 'mrx-notification'
import { PluginSettingOwner } from '@merchx-v2/shared-types'
import { GlobalContext } from 'appContexts'
import * as rootStyles from 'assets/layoutStyle'
import { Breadcrumbs } from 'components'
import { PluginSetting, SavePluginSetting } from '../../components'
import { usePluginSettingsList } from '../../hooks'
import { PluginSetting as PluginSettingType } from '../../types'
import styles from './PluginSettingsList.module.scss'

type Props = {
  ownerType: PluginSettingOwner
  ownerId: number
  searchText?: string
}

const PluginSettingsList = React.memo(({ searchText }: Props) => {
  const [isVisible, setVisible] = useState(false)
  const [selectedPluginSetting, setSelectedPluginSetting] = useState<PluginSettingType>()
  const [createPluginVisible, setIsCreatePluginVisible] = useState(false)

  const { history } = useReactRouter()

  const { campaignId: ownerId, workspaceId } = useContext(GlobalContext)
  const ownerType = 'CAMPAIGN'

  const pluginSettingsList = usePluginSettingsList(workspaceId, { searchText, ownerType, ownerId })

  useEffect(() => {
    return () => pluginSettingsList.remove()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (pluginSettingsList.error) {
      notification.error({
        message: 'Plugin Settings list page error!',
        description: pluginSettingsList.error instanceof Error ? pluginSettingsList.error.message : pluginSettingsList.error.toString()
      })
    }
  }, [pluginSettingsList.error])

  const showDrawer = (plugin?: PluginSettingType) => {
    setSelectedPluginSetting(plugin)
    setVisible(!isVisible)
  }

  const renderUpdate = (e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    return (
      <SavePluginSetting
        onClose={showDrawer}
        visible={isVisible && !!selectedPluginSetting}
        ownerId={ownerId}
        ownerType={ownerType}
        pluginName={selectedPluginSetting?.pluginName}
        pluginType={selectedPluginSetting?.pluginType}
      />
    )
  }

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title='Plugins List'
          extra={[
            <Button type='primary' key='1' onClick={() => setIsCreatePluginVisible(true)}>
              <PlusOutlined />
              CREATE PLUGIN
            </Button>
          ]}
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Campaign Plugins', url: '/' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <div className={styles.baseContainer}>
          {pluginSettingsList.data?.pages
            .map((item) => item.items)
            .flat()
            .map((item) => (
              <PluginSetting plugin={item} key={item.id} onClick={() => showDrawer(item)} />
            ))}
        </div>
        {selectedPluginSetting && renderUpdate()}
        {!pluginSettingsList.isFetching && pluginSettingsList.hasNextPage && (
          <Button className={styles.loadMoreButton} onClick={() => pluginSettingsList.fetchNextPage()} type='primary'>
            Load more
          </Button>
        )}
        <SavePluginSetting
          ownerType='CAMPAIGN'
          ownerId={ownerId}
          visible={createPluginVisible}
          onClose={() => setIsCreatePluginVisible(false)}
        />
      </Layout.Content>
    </Layout>
  )
})

export default PluginSettingsList
