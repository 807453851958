// TODO - remove becouse of legacy

import axios from 'utils/axios'
import { useQuery } from 'react-query'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

import { Landing, LandingTypeEnum } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'landingsForOptions', Landing[]>

const FETCH_LANDINGS_FOR_OPTIONS = `
query fetchLandingsForOptions ($workspaceId: Int!, $searchText: String, $landingType: LandingType) {
  landingsForOptions(workspaceId: $workspaceId, searchText: $searchText, landingType: $landingType) {
    id
    name
  }
}
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type LandingsForOptionsArgs = {
  workspaceId: number
  searchText?: string
  landingType?: LandingTypeEnum
}

type FetchLandingsForOptions = (args: LandingsForOptionsArgs) => Promise<Landing[]>

const fetchLandingsForOptions: FetchLandingsForOptions = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_LANDINGS_FOR_OPTIONS,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { landingsForOptions } = data
    if (!landingsForOptions) {
      throw new Error("Can't get campaigns!")
    }

    return landingsForOptions
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useLandingsOptions = (args: LandingsForOptionsArgs) => useQuery(['landingsOptions', args], () => fetchLandingsForOptions(args))
