import * as t from '../actionTypes'

export type ChangeUpdateLandingsTasksCurrentPageAction = BaseFSA<number>
type ChangeUpdateLandingsTasksCurrentPage = (page: number) => MrxThunk<ChangeUpdateLandingsTasksCurrentPageAction>

const changeUpdateLandingsTasksCurrentPage: ChangeUpdateLandingsTasksCurrentPage = page => async dispatch => {
  dispatch({
    type: t.CHANGE_UPDATE_LANDINGS_TASKS_CURRENT_PAGE,
    payload: page
  })
}

export { changeUpdateLandingsTasksCurrentPage }
