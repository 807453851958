// TODO - remove becouse of legacy

import * as t from '../actionTypes'
import { Moment } from 'moment-timezone'

export type ChangeDatesRangeReportAction = Required<Omit<BaseFSA<[Moment, Moment]>, 'meta'>>
type ChangeDatesRangeReport = (range: [Moment, Moment]) => MrxThunk<ChangeDatesRangeReportAction, Promise<void>>

export const changeDatesRangeReport: ChangeDatesRangeReport = (range) => async (dispatch) => {
  dispatch({
    type: t.CHANGE_DATES_RANGE_REPORT,
    payload: range
  })
}
