import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query'
import hash from 'object-hash'
import { StoreDto, StoreOwnerType } from '@merchx-v2/shared-types'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

const DEFAULT_PAGE_SIZE = 30

export type StoresPage = {
  items: StoreDto[]
  total: number
  currentPage: number
  pageSize: number
  ownerType: StoreOwnerType
  searchText?: string
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'storesPage', StoresPage>

const FETCH_PROMOCODES_PAGE = `
  query fetchStoresPage ($page: Int, $size: Int, $searchText: String, $ownerType: StoreOwnerTypeEnum) {
    storesPage(page: $page, size: $size, searchText: $searchText, ownerType: $ownerType) {
      items {
        id
        name
        description
        
        ownerType
        ownerId
        ownerName
        numberOfProducts
        
        isActive

        hasCustomSeo
        seoUrl
        seoTitle
        seoDescription
        seoKeywords

        facebookLink
        twitterLink
        instagramLink
        linkedInLink
        pinterestLink
        tiktokLink
        youtubeLink
        threadsLink

        location

        createdAt
        updatedAt
      }
      total
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type PageParam = {
  page?: number
  size?: number
  ownerType: StoreOwnerType
  searchText?: string
}

type FetchStoresListArgs = {
  queryKey: string | string[]
  pageParam?: PageParam
}

type FetchStoresPage = (args: FetchStoresListArgs) => Promise<StoresPage>

const fetchStoresPage: FetchStoresPage = async ({ pageParam }) => {
  const { page = 1, size = DEFAULT_PAGE_SIZE, ownerType, searchText } = pageParam || {}
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_PROMOCODES_PAGE,
      variables: { page, size, ownerType, searchText }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { storesPage } = data
    if (!storesPage) {
      throw new Error("Can't get stores page!")
    }

    return {
      items: storesPage.items,
      total: storesPage.total,
      currentPage: page,
      pageSize: size,
      ownerType,
      searchText
    }
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

const queryOptions: UseInfiniteQueryOptions<StoresPage> = {
  keepPreviousData: true,
  refetchOnMount: true,
  retry: false,

  getPreviousPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, ownerType, searchText }) => {
    if (currentPage > 1) {
      return {
        page: currentPage - 1,
        size: pageSize,
        ownerType,
        searchText
      }
    }

    return undefined
  },
  getNextPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, total = 0, ownerType, searchText }) => {
    if (pageSize * currentPage < total) {
      return {
        page: currentPage + 1,
        size: pageSize,
        ownerType,
        searchText
      }
    }

    return undefined
  }
}

type Args = {
  ownerType: StoreOwnerType
  searchText?: string
}

export const useStoresList = (args: Args) => {
  const cacheKey = ['storesPage', hash(args)]

  return useInfiniteQuery(
    cacheKey,
    (prev) =>
      fetchStoresPage({
        queryKey: cacheKey,
        pageParam: { ...prev.pageParam, ...args }
      }),
    queryOptions
  )
}
