import React, { useContext, useEffect, useState } from 'react'
import * as QueryString from 'query-string'
import useReactRouter from 'use-react-router'
import { useLocation } from 'react-router-dom'
import { Layout, PageHeader, Button, Switch, Tabs, Popconfirm } from 'antd'
import notification from 'mrx-notification'
import { GlobalContext } from 'appContexts'
import * as rootStyles from 'assets/layoutStyle'
import { Breadcrumbs } from 'components'
import { TasksQueueForRedeploymentTab } from 'features/tasksQueue/components'
import { connector, PropsFromRedux } from './container'
import CampaignTemplatesReportTable from '../CampaignTemplatesReportTable'
import styles from './CampaignTemplatesReport.module.scss'

const { TabPane } = Tabs

const CampaignTemplatesReport = (props: PropsFromRedux) => {
  const { error, isLoading, updateCampaignLandingsFromTemplate, redeployCampaignLandings } = props

  const { campaignId } = useContext(GlobalContext)

  const { history } = useReactRouter()

  const [fullMode, setFullMode] = useState(false)
  const [currentTab, setCurrentTab] = useState('report')

  const handleChangeTab = (key) => {
    history.push(`/services/redeployment/${campaignId}?activeTab=${key}`)
    setCurrentTab(key)
  }

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Campaign templates report error!',
        description: error
      })
    }
  }, [error])

  const location = useLocation()

  useEffect(() => {
    const queryParams = QueryString.parse(location.search)
    setCurrentTab(queryParams.activeTab as string)
    // eslint-disable-next-line
  }, [location])

  const [templatesIds, setTemplatesIds] = useState([])

  const handleToggleMode = async () => {
    setFullMode(!fullMode)
  }

  const handleClickUpdateLandingsFromTemplate = async () => {
    const isTaskCreated = await updateCampaignLandingsFromTemplate(campaignId, templatesIds)
    if (isTaskCreated) {
      history.push(`/services/redeployment/${campaignId}?activeTab=tasks`)
    }
  }

  const handleClickRedeployCampaignLandings = async () => {
    const isTaskCreated = await redeployCampaignLandings(campaignId, templatesIds)
    if (isTaskCreated) {
      history.push(`/services/redeployment/${campaignId}?activeTab=tasks`)
    }
  }

  const handleSelectChange = (selectedRows) => {
    setTemplatesIds(selectedRows)
  }

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title='Campaign Templates Report'
          extra={[
            <div key='1' className={styles.extraContainer}>
              <div className={styles.switchContainer}>
                <span>Full mode</span>
                <Switch disabled={isLoading} title='Full mode' checked={fullMode} onChange={handleToggleMode} />
              </div>
              <Popconfirm
                disabled={isLoading || !templatesIds.length}
                title='Are you sure?'
                onConfirm={handleClickUpdateLandingsFromTemplate}
                onCancel={(e) => e?.stopPropagation()}
                okText='Yes'
                cancelText='No'
              >
                <Button className={styles.button} disabled={isLoading || !templatesIds.length}>
                  Update all landings
                </Button>
              </Popconfirm>
              <Popconfirm
                title='Are you sure?'
                disabled={isLoading || !templatesIds.length}
                onConfirm={handleClickRedeployCampaignLandings}
                onCancel={(e) => e?.stopPropagation()}
                okText='Yes'
                cancelText='No'
              >
                <Button className={styles.button} disabled={isLoading || !templatesIds.length}>
                  Redeploy all landings
                </Button>
              </Popconfirm>
            </div>
          ]}
        />
      </Layout.Header>
      <Layout.Content>
        <div style={rootStyles.contentBreadcrumbs}>
          <Breadcrumbs
            items={[
              { title: 'Main', url: '/' },
              { title: 'Reports', url: '/' },
              { title: 'Campaign Templates', url: '/' }
            ]}
          />
        </div>
        <Tabs type='card' onChange={handleChangeTab} activeKey={currentTab}>
          <TabPane tab='Report' key='report'>
            <Layout.Content className={styles.contentComponent}>
              <CampaignTemplatesReportTable
                selectedRowKeys={templatesIds}
                handleSelectChange={handleSelectChange}
                fullMode={fullMode}
                campaignId={campaignId}
              />
            </Layout.Content>
          </TabPane>
          <TabPane tab='Tasks' key='tasks'>
            <TasksQueueForRedeploymentTab campaignId={campaignId} />
          </TabPane>
        </Tabs>
      </Layout.Content>
    </Layout>
  )
}

export default connector(CampaignTemplatesReport)
