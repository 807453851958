import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { CreateCustomGatewayArtworkDto, CustomGatewayArtworkDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'createCustomGatewayArtwork', CustomGatewayArtworkDto>

const CREATE_ARTWORK = `
  mutation createCustomGatewayArtwork ($customGatewayArtworkData: CustomGatewayArtworkInput!) {
    createCustomGatewayArtwork(customGatewayArtworkData: $customGatewayArtworkData) {
      id
      DTGProductId
      virtualProductId
      color
      original
      preview
      backOriginal
      backPreview
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type CreateCustomGatewayArtwork = (customGatewayArtworkData: CreateCustomGatewayArtworkDto) => Promise<CustomGatewayArtworkDto>

const createCustomGatewayArtwork: CreateCustomGatewayArtwork = async customGatewayArtworkData => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_ARTWORK,
      variables: { customGatewayArtworkData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createCustomGatewayArtwork } = data
    if (!createCustomGatewayArtwork) {
      throw new Error("Can't create artwork!")
    }

    return createCustomGatewayArtwork
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCreateCustomGatewayArtwork = () => useMutation(createCustomGatewayArtwork)
