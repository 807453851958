import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import * as eternalListsFeature from 'features/eternalLists'
import { Product } from 'features/types'
import * as t from '../actionTypes'
import { GraphQLProductInput } from '../types'

type Payload = {
  list: 'products'
  index: number
  item: GraphQLProductInput
  filters: {
    campaignId: number
    productId: number
  }
}

type Args = {
  campaignId: number
  productId: number
  isActive: boolean
  index?: number
}

export type SetActiveProductAction = FSA<undefined, Payload, string>

type SetActiveProduct = (args: Args) => MrxThunk<eternalListsFeature.actions.UpdateEternalItemAction<'products'>, Promise<boolean>>

type QueryResponse = GraphQLResponse<'setActiveProduct', boolean>

const SET_ACTIVE_PRODUCT = `
  mutation setActiveProduct($productId: Int!, $isActive: Boolean!) {
    setActiveProduct(productId: $productId, isActive: $isActive)
  }
`

const setActiveProduct: SetActiveProduct = (args) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.SET_ACTIVE_PRODUCT,
    meta: { done: false }
  })

  try {
    const { productId, isActive, index, campaignId } = args

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: SET_ACTIVE_PRODUCT,
      variables: { productId, isActive }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { setActiveProduct } = data
    if (!setActiveProduct) {
      throw new Error("Can't set active status to product!")
    }

    if (index) {
      dispatch({
        type: t.SET_ACTIVE_PRODUCT,
        payload: {
          list: 'products',
          index,
          filters: { campaignId, productId },
          item: { id: productId, isActive } as Product
        },
        meta: { done: true }
      })
    }
    dispatch({
      type: eternalListsFeature.actionTypes.UPDATE_ETERNAL_LIST_ITEM,
      payload: {
        list: 'products',
        index,
        filters: { campaignId, productId },
        item: { id: productId, isActive } as Product
      },
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.SET_ACTIVE_PRODUCT,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { setActiveProduct }
