import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'

import * as actions from '../../actions'
import * as selectors from '../../selectors'

const mapStateToProps = (state: ReduxState) => {
  return {
    subscriptionStatsReport: selectors.subscriptionStatsReportSelector(state),
    subscriptionStatsReportWithoutActive: selectors.subscriptionStatsReportWithoutActive(state),
    error: selectors.errorSelector(state),
    datesRange: selectors.subscriptionStatsDatesRangeSelector(state),
    isLoading: selectors.isLoadingSelector(state, 'isSubscriptionStatsReportLoading')
  }
}

const mapDispatchToProps = {
  fetchSubscriptionStatsReport: actions.fetchSubscriptionStatsReport,
  changeDatesRange: actions.subscriptionStatsChangeDatesRange
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector>
