import React, { useEffect, useState } from 'react'
import { Upload, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import hash from 'object-hash'
import styled from 'styled-components'
import { UploadFile } from 'antd/lib/upload/interface'
import { ProductImage } from '../../types'

type UploadImageProps = {
  productImages?: ProductImage[]
  productImagesToRemove?: ProductImage[]
  filesToUpload: UploadFile<any>[]
  numberOfImages?: number
  isDTGImage?: boolean
  onChange: (filesToUpload: UploadFile<any>[], filesToRemove?: ProductImage[]) => void
}

const getBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const StyledUpload = styled(Upload)`
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 165px;
  }
`

const uploadButton = (
  <div style={{ width: '100%', height: '165px' }}>
    <div style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <PlusOutlined style={{ fontSize: '45px', color: '#dddddd' }} />
    </div>
  </div>
)

const UploadImages = (props: UploadImageProps) => {
  const { productImages = [], productImagesToRemove = [], filesToUpload = [], numberOfImages = 5, isDTGImage = false, onChange } = props

  const [previewVisible, setPreviewVisible] = useState<boolean>(false)
  const [previewImage, setPreviewImage] = useState<string>('')
  const [fileList, setFileList] = useState<UploadFile<any>[]>([])

  useEffect(() => {
    const existFiles = []
    for (const productImage of productImages) {
      if (!productImagesToRemove.some((removed) => removed.id === productImage.id)) {
        existFiles.push({
          uid: productImage.id + '',
          name: productImage.name,
          status: 'done',
          size: 0,
          url: productImage.imageUrl
        } as UploadFile)
      }
    }

    setFileList([...existFiles, ...filesToUpload])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(productImages), hash(filesToUpload, { excludeKeys: (key) => key === 'originFileObj' }), hash(productImagesToRemove)])

  const handleCancel = () => setPreviewVisible(false)

  const handleUpload = async (info: any) => {
    let fileName = 'original'
    if (!isDTGImage) {
      fileName = info.file.name.trim().replace(/\s+/g, '_')
    }
    const uploadedFile = info.file as any

    const newListOfFilesToUpload = [
      ...filesToUpload,
      {
        uid: uploadedFile.uid,
        name: fileName,
        status: 'done',
        size: uploadedFile.size,
        type: uploadedFile.type,
        originFileObj: info.file,
        url: URL.createObjectURL(info.file)
      } as UploadFile
    ]

    onChange(newListOfFilesToUpload, productImagesToRemove)
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
  }

  const handleRemove = (file: UploadFile<any>) => {
    const productImageToRemove = productImages.find((productImage) => productImage.id + '' === file.uid)
    if (productImageToRemove) {
      if (!productImagesToRemove.some((asset) => asset.id + '' === file.uid)) {
        const newListOfProductImagesToRemove = [...productImagesToRemove, productImageToRemove]
        onChange(filesToUpload, newListOfProductImagesToRemove)
      }
    }

    const canceledFile = filesToUpload.find((fileToUpload) => fileToUpload.uid === file.uid)
    if (canceledFile) {
      const newListOfFilesToUpload = filesToUpload.filter((fileToUpload) => fileToUpload.uid !== file.uid)
      onChange(newListOfFilesToUpload, productImagesToRemove)
    }
  }

  return (
    <>
      <StyledUpload
        listType='picture-card'
        fileList={fileList}
        customRequest={handleUpload}
        onPreview={handlePreview}
        onRemove={handleRemove}
      >
        {fileList.length >= numberOfImages ? null : uploadButton}
      </StyledUpload>
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt='example' style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  )
}

export default UploadImages
