import { useInfiniteQuery } from 'react-query'
import hash from 'object-hash'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Cart } from '../types'

const DEFAULT_PAGE_SIZE = 15

export type CartsPage = {
  items: Cart[]
  total: number
  currentPage: number
  pageSize: number
  filters?: CartsFilters
}

type CartsFilters = {
  status?: string
  query?: string
  sorting?: SortingType
  startDate?: Date
  endDate?: Date
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'cartsListPage', CartsPage>

const FETCH_CARTS_LIST_PAGE = `
  query fetchCartsList ($page: Int, $size: Int, $filters: CartsFilters!) {
    cartsListPage(page: $page, size: $size, filters: $filters) {
      items {
        id
        status
        customer {
          id
          email
          firstName
          lastName
        }
        billingAddressId
        shippingAddressId
        orderId
        promocode
        cartItems {
          id
          cartId
          productId
          productPriceId
          quantity
          price
          discount
          finalPrice
          shipping
          processing
          sum
          total
          customerComment
          createdAt
          updatedAt
        }
        comment
        total
        lastPage
        createdAt
        updatedAt
      }
      total
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type PageParam = {
  page?: number
  size?: number
  filters: CartsFilters
}

type FetchCartsListArgs = {
  queryKey: string | string[]
  pageParam?: PageParam
}

type FetchCartsList = (args: FetchCartsListArgs) => Promise<CartsPage>

const fetchCartsList: FetchCartsList = async ({ pageParam }) => {
  const { page = 1, size = DEFAULT_PAGE_SIZE, filters = {} } = pageParam || {}
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_CARTS_LIST_PAGE,
      variables: { page, size, filters }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { cartsListPage } = data
    if (!cartsListPage) {
      throw new Error("Can't get carts list page!")
    }

    return {
      items: cartsListPage.items,
      total: cartsListPage.total,
      currentPage: page,
      pageSize: size
    }
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

type GetNextPageParam = (args: CartsPage) => PageParam

type CartsListEvents = {
  keepPreviousData: boolean
  getPreviousPageParam: GetNextPageParam
  getNextPageParam: GetNextPageParam
}

const cartsListEvents: CartsListEvents = {
  keepPreviousData: true,
  getPreviousPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, filters }) => {
    if (currentPage > 1) {
      return {
        page: currentPage - 1,
        size: pageSize,
        filters
      }
    }

    return undefined
  },
  getNextPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, total = 0, filters }) => {
    if (pageSize * currentPage < total) {
      return {
        page: currentPage + 1,
        size: pageSize,
        filters
      }
    }
    return undefined
  }
}

export const useCartsList = (filters?: CartsFilters) => {
  const cacheKey = ['cartsList', hash(filters)]

  return useInfiniteQuery(
    cacheKey,
    (prev) =>
      fetchCartsList({
        queryKey: cacheKey,
        pageParam: { ...prev.pageParam, filters }
      }),
    cartsListEvents
  )
}
