import produce from 'immer'
import createReducer from 'store/createReducer'
import { isSuccessAction } from 'types'
import * as t from './actionTypes'
import { WebSocketSubscribeToChannelResultAction, WebSocketUnsubscribeFromChannelResultAction } from './actions'
import { WebsocketState } from './types'

const initState: WebsocketState = {
  isAlive: false,
  channels: {},
  UIState: {}
}

const reducer = createReducer(initState, {
  [t.WS_CONNECTED]: state =>
    produce(state, draft => {
      delete draft.UIState.error
      draft.isAlive = true
    }),

  [t.SUBSCRIBE_TO_CHANNEL_RESULT]: (state, action: WebSocketSubscribeToChannelResultAction) =>
    produce(state, draft => {
      if (isSuccessAction(action)) {
        const { channel, applied } = action.payload
        draft.channels[channel] = applied
      }
    }),

  [t.UNSUBSCRIBE_FROM_CHANNEL_RESULT]: (state, action: WebSocketUnsubscribeFromChannelResultAction) =>
    produce(state, draft => {
      if (isSuccessAction(action)) {
        const { channel, applied } = action.payload
        draft.channels[channel] = !applied
      }
    }),

  [t.WS_DISCONNECTED]: state =>
    produce(state, draft => {
      delete draft.UIState.error
      draft.isAlive = false
      const channels = Object.keys(draft.channels)
      for (const channel of channels) {
        draft.channels[channel] = false
      }
    }),

  [t.WS_ERROR]: (state, action) =>
    produce(state, draft => {
      draft.UIState.error = action.payload
      draft.isAlive = false
      const channels = Object.keys(draft.channels)
      for (const channel of channels) {
        draft.channels[channel] = false
      }
    })
})

export default reducer
