import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Workspace } from '../types'

export type CreateWorkspaceAction = FSA<undefined, Workspace, string>
type CreateWorkspace = (name: string) => MrxThunk<CreateWorkspaceAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'createWorkspace', Workspace>

const CREATE_WORKSPACE = `
  mutation createWorkspace($name: String!) {
    createWorkspace(name: $name) {
    id
    name
    members {
      id
      userId
      email
      role
      createdAt
    }
    createdAt
    updatedAt
    }
  }
`

export const createWorkspace: CreateWorkspace = name => async dispatch => {
  let isSaved = false

  dispatch({
    type: t.CREATE_WORKSPACE,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_WORKSPACE,
      variables: { name }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createWorkspace } = data
    if (!createWorkspace) {
      throw new Error("Can't create workspace!")
    }

    dispatch({
      type: t.CREATE_WORKSPACE,
      payload: createWorkspace,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.CREATE_WORKSPACE,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isSaved
}
