import * as t from '../actionTypes'
import axios from 'utils/axios'
import { TaskOwner, TaskStatus, TaskType } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Task } from '../types'

type TaskList = {
  items: Task[]
  total: number
  totalNewTasks: number
  totalInProgressTasks: number
}

type Payload = TaskList & {
  page: number
}

export type FetchTaskListAction = FSA<undefined, Payload, string>
type FetchTaskList = (status?: TaskStatus, taskType?: TaskType, ownerType?: TaskOwner, ownerId?: number) => MrxThunk<FetchTaskListAction>
type QueryResponse = GraphQLResponse<'tasksListPage', TaskList>

const FETCH_TASKS_LIST_PAGE = `
  query fetchTasksListPage ($page: Int, $size: Int, $filters: TasksFilters!) {
    tasksListPage (page: $page, size: $size, filters: $filters) {
      items {
        id
        status
        taskType
        progress
        retryNumber
        message
        payload
        startDate
        startedAt
        endedAt
        createdAt
        updatedAt
      }
      total
      totalNewTasks
      totalInProgressTasks
    }
  }
`

const fetchTaskList: FetchTaskList = (status, taskType, ownerType, ownerId) => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_TASKS_LIST_PAGE,
    meta: { done: false }
  })

  try {
    const {
      tasksQueue: {
        pagination: { size, currentPage: page }
      },
      workspaces: {
        activeWorkspace: { id: workspaceId }
      }
    } = getState()

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_TASKS_LIST_PAGE,
      variables: { page, size, filters: { workspaceId, status, taskType, ownerType, ownerId } }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { tasksListPage } = data
    if (!tasksListPage) {
      throw new Error("Can't get tasks queue list page!")
    }

    dispatch({
      type: t.FETCH_TASKS_LIST_PAGE,
      payload: {
        items: tasksListPage.items,
        total: tasksListPage.total,
        totalNewTasks: tasksListPage.totalNewTasks,
        totalInProgressTasks: tasksListPage.totalInProgressTasks,
        page: page
      },
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_TASKS_LIST_PAGE,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchTaskList }
