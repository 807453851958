import { createSelector, Selector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

const getIsNeedToFetchListSelector: Selector<ReduxState, boolean> = state => {
  const { currentPage, pages } = state.simpleTextSettings.pagination
  return !pages[currentPage]
}

export const isNeedToFetchListSelector = createSelector<ReduxState, boolean, boolean>(
  getIsNeedToFetchListSelector,
  state => state
)

export default isNeedToFetchListSelector
