import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

export type RemoveAwsCertificateAction = FSA<undefined, number, string>
type RemoveAwsCertificate = (awsCertificateId: number) => MrxThunk<RemoveAwsCertificateAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'removeAwsCertificate', boolean>

const REMOVE_AWS_CERTIFICATE = `
  mutation removeAwsCertificate ($awsCertificateId: Int!) {
    removeAwsCertificate(awsCertificateId: $awsCertificateId) 
  }
`

const removeAwsCertificate: RemoveAwsCertificate = (awsCertificateId) => async (dispatch) => {
  let isRemoved = false

  dispatch({
    type: t.REMOVE_AWS_CERTIFICATE,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_AWS_CERTIFICATE,
      variables: { awsCertificateId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Empty response!')
    }

    const { removeAwsCertificate } = data
    if (!removeAwsCertificate) {
      throw new Error("Can't remove aws certificate!")
    }

    dispatch({
      type: t.REMOVE_AWS_CERTIFICATE,
      payload: awsCertificateId,
      meta: { done: true }
    })

    isRemoved = true
  } catch (err) {
    dispatch({
      type: t.REMOVE_AWS_CERTIFICATE,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isRemoved
}

export { removeAwsCertificate }
