import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as workspacesFeature from 'features/workspaces'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

const mapStateToProps = (state: ReduxState) => {
  return {
    addressesList: selectors.addressesListSelector(state),
    workspaceId: workspacesFeature.selectors.activeWorkspaceIdSelector(state),
    error: selectors.errorSelector(state),
    isLoading: selectors.isLoadingSelector(state, 'isListLoading'),
    currentPage: selectors.currentPageSelector(state),
    total: selectors.totalAddressesSelector(state),
    isNeedToFetchList: selectors.isNeedToFetchListSelector(state)
  }
}

const mapDispatchToProps = {
  fetchAddressesList: actions.fetchAddressesList,
  changeCurrentPage: actions.changeCurrentPage
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector>
