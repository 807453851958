import React, { useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import { Table, Layout, PageHeader, Popconfirm } from 'antd'
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table'
import InfiniteScroll from 'react-infinite-scroll-component'
import notification from 'mrx-notification'
import { Breadcrumbs } from 'components'
import * as rootStyles from 'assets/layoutStyle'

import CreateCountry from '../CreateCountry'
import UpdateCountry from '../UpdateCountry'
import { Country } from '../../types'
import { useCountriesList, useRemoveCountry } from '../../hooks'
import styles from './CountriesList.module.scss'

const CountriesList = React.memo(() => {
  const [selectedCountryId, setSelectedCountryId] = useState<number>()
  const [isUpdateDrawerVisible, setIsUpdateDrawerVisible] = useState(false)

  const { history } = useReactRouter()

  const removeCountry = useRemoveCountry()
  const countriesList = useCountriesList()
  const countries: Country[] = []
  countriesList &&
    countriesList.data &&
    countriesList.data.pages.forEach((page) => page.items.forEach((country) => countries.push(country)))

  const page = countriesList.data?.pages.length ? countriesList.data?.pages[countriesList.data?.pages.length - 1].currentPage : 1
  const size = countriesList.data?.pages.length ? countriesList.data?.pages[countriesList.data?.pages.length - 1].pageSize : 30

  useEffect(() => {
    return () => countriesList.remove()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (countriesList.error) {
      notification.error({
        message: 'Countries list page error!',
        description: countriesList.error instanceof Error ? countriesList.error.message : countriesList.error.toString()
      })
    }
  }, [countriesList.error])

  useEffect(() => {
    if (removeCountry.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Country was removed successfully!'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeCountry.isSuccess])

  const doConfirm = async (countryId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    removeCountry.mutate(countryId)
  }

  const handleOnCurrencyClose = () => {
    setIsUpdateDrawerVisible(false)
  }

  const renderUpdate = (e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    return <UpdateCountry countryId={selectedCountryId} onClose={handleOnCurrencyClose} visible={isUpdateDrawerVisible} />
  }

  const columns: ColumnsType<Country> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'ISO 2',
      dataIndex: 'iso2',
      key: 'iso2'
    },
    {
      title: 'ISO 3',
      dataIndex: 'iso3',
      key: 'iso3'
    },
    {
      title: 'World Part',
      dataIndex: 'worldPart',
      key: 'worldPart'
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Currency',
      dataIndex: ['currency', 'name'],
      key: 'currency'
    },
    {
      title: '',
      dataIndex: '',
      key: 'edit',
      width: '10%',
      align: 'center',
      render: (record) => (
        <span>
          <EditTwoTone
            style={{ fontSize: '20px' }}
            onClick={(e) => {
              e.stopPropagation()
              setSelectedCountryId(record.id)
              setIsUpdateDrawerVisible(true)
            }}
          />
        </span>
      )
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '10%',
      align: 'center',
      render: (record: Country) => (
        <Popconfirm
          title='Are you sure delete this landing?'
          onConfirm={(e) => doConfirm(record.id, e)}
          onCancel={(e) => e?.stopPropagation()}
          okText='Yes'
          cancelText='No'
        >
          <DeleteTwoTone twoToneColor='#ec1c24' style={{ fontSize: '20px' }} onClick={(e) => e.stopPropagation()} />
        </Popconfirm>
      )
    }
  ]

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader onBack={() => history.goBack()} title='Countries List' extra={[<CreateCountry key='1' />]} />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Catalogs', url: '/' },
            { title: 'Countries List', url: '/' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <InfiniteScroll
          style={{ paddingBottom: '30px' }}
          dataLength={page * size}
          next={countriesList.fetchNextPage}
          hasMore={!!countriesList.hasNextPage}
          loader={<h4>Loading...</h4>}
          refreshFunction={countriesList.refetch}
          scrollThreshold={0.8}
        >
          <Table
            columns={columns}
            dataSource={countries.map((item) => item).flat()}
            bordered
            rowKey={(record) => record.id}
            pagination={false}
            loading={countriesList.isLoading}
          />
        </InfiniteScroll>
      </Layout.Content>
      {selectedCountryId && renderUpdate()}
    </Layout>
  )
})

export default CountriesList
