import { connect, ConnectedProps } from 'react-redux'
import { BuildOwner, EnvironmentType } from '@merchx-v2/shared-types'
import { ReduxState } from 'store/createRootReducer'
import * as workspacesFeature from 'features/workspaces'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

type OwnProps = {
  ownerType: BuildOwner
  ownerId: number
  version: number
  environment: EnvironmentType
  route?: string
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  const { ownerType, ownerId, version, environment } = ownProps
  const previewState = selectors.siteBuildInfoSelector(state, ownerType, ownerId, version, environment)
  return {
    buildInfo: previewState.buildInfo,
    isTaskCreating: previewState?.UIState?.isTaskCreating,
    error: previewState?.UIState?.error,
    workspaceId: workspacesFeature.selectors.activeWorkspaceIdSelector(state)
  }
}

const mapDispatchToProps = {
  buildPreview: actions.buildPreview,
  takeSiteBuildInfo: actions.takeSiteBuildInfo
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
