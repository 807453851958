import React, { useState } from 'react'
import { Typography, Form, Upload, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import ImgCrop from 'antd-img-crop'
import styled from 'styled-components'
import { CustomGatewayArtwork } from 'features/customGatewayArtwork/components'
import { ArtworkInput } from 'features/types'
import styles from './EditProductPriceVariantImage.module.scss'

const StyledUpload = styled(Upload)`
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 165px;
  }
`
const getBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const uploadButton = (
  <div style={{ width: '100%', height: '165px' }}>
    <div style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <PlusOutlined style={{ fontSize: '45px', color: '#dddddd' }} />
    </div>
  </div>
)

type Props = {
  mainVariant?: string
  // isArtwork default true
  isArtwork?: boolean
}

const EditProductPriceVariant = (props: Props) => {
  const { mainVariant, isArtwork = true } = props

  const [previewVisible, setPreviewVisible] = useState<boolean>(false)
  const [previewImage, setPreviewImage] = useState<string>('')
  const [previewTitle, setPreviewTitle] = useState<string>('')

  const handleCancel = () => setPreviewVisible(false)
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  return (
    <>
      <div className={styles.productFormRow}>
        <div className={styles.productFormRowItem}>
          <Typography>Product Image</Typography>

          <Form.Item noStyle dependencies={[['images', 'mainVariantImages', 'mainVariantValues']]}>
            {(formInstance) => {
              const images = formInstance.getFieldValue('images')
              const mainVariantImages = formInstance.getFieldValue('mainVariantImages')

              let countOfImages = images?.length || 0

              if (mainVariant) {
                countOfImages = mainVariantImages?.[mainVariant]?.length || 0
              }

              return (
                <>
                  <Form.Item name={mainVariant ? ['mainVariantImages', mainVariant] : 'images'} valuePropName='fileList'>
                    <ImgCrop aspect={1000 / 1000} shape='rect' rotate quality={1}>
                      <StyledUpload
                        onPreview={handlePreview}
                        accept='image/*'
                        className={styles.variantImage}
                        multiple
                        maxCount={5}
                        listType='picture-card'
                        fileList={mainVariant ? mainVariantImages?.[mainVariant] : images}
                        onChange={(e) => {
                          const generatedList = {
                            images: mainVariant ? images : normFile(e),
                            mainVariantImages: mainVariant ? { ...mainVariantImages, [mainVariant]: normFile(e) } : mainVariantImages
                          }
                          formInstance.setFieldsValue(generatedList)
                        }}
                      >
                        {countOfImages >= 5 ? null : uploadButton}
                      </StyledUpload>
                    </ImgCrop>
                  </Form.Item>
                  <Modal open={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
                    <img alt='example' style={{ width: '100%' }} src={previewImage} />
                  </Modal>
                </>
              )
            }}
          </Form.Item>
        </div>
      </div>

      {isArtwork && mainVariant && (
        <div className={styles.productFormRow}>
          <Form.Item noStyle dependencies={[['artworks', 'customizable', 'productType']]}>
            {(formInstance) => {
              const customizable = formInstance.getFieldValue('customizable')
              const selectedProductType = formInstance.getFieldValue('productType')
              const artworks = formInstance.getFieldValue('artworks')

              const variantArtwork = artworks.find((item) => item.color === mainVariant)

              const onArtworkChange = (args: ArtworkInput) => {
                const artworksInput = formInstance.getFieldValue('artworksInput') || {}
                artworksInput[mainVariant] = args
                formInstance.setFieldValue('artworksInput', artworksInput)
              }

              return (
                <div className={styles.productFormRowItem}>
                  <Form.Item name={['artworksInput', mainVariant]}>
                    <CustomGatewayArtwork
                      productType={selectedProductType}
                      artwork={variantArtwork}
                      withoutImages={customizable}
                      onChange={onArtworkChange}
                    />
                  </Form.Item>
                </div>
              )
            }}
          </Form.Item>
        </div>
      )}
    </>
  )
}

export default EditProductPriceVariant
