import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Campaign, CampaignInput } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'updateCampaign', boolean>

const UPDATE_CAMPAIGN = `
  mutation updateCampaign ($campaignId: Int!, $campaignData: CampaignInput!) {
    updateCampaign(campaignId: $campaignId, campaignData: $campaignData)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateCampaignArgs = {
  campaignId: number
  campaignData: CampaignInput
}

type UpdateCampaign = (args: UpdateCampaignArgs) => Promise<UpdateCampaignArgs>

const updateCampaign: UpdateCampaign = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_CAMPAIGN,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateCampaign } = data
    if (!updateCampaign) {
      throw new Error("Can't update campaign!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateCampaignContext = { prevCampaign?: Campaign }

type UpdateCampaignEvents = {
  onMutate: (variables: UpdateCampaignArgs) => Promise<UpdateCampaignContext>
  onError: (error: string, variables: UpdateCampaignArgs, context: UpdateCampaignContext) => void
  onSettled: (data?: UpdateCampaignArgs) => void
}

const updateCampaignEvents: UpdateCampaignEvents = {
  onMutate: async (variables: UpdateCampaignArgs) => {
    await queryClient.cancelQueries(['campaign', variables.campaignId])

    // Snapshot the previous value
    const prevCampaign = queryClient.getQueryData<Campaign>(['campaign', variables.campaignId])

    // Optimistically update to the new values
    if (prevCampaign) {
      queryClient.setQueryData<Campaign>(['campaign', variables.campaignId], {
        ...prevCampaign,
        id: variables.campaignId,
        ...variables.campaignData
      })
    }

    return { prevCampaign }
  },
  onError: (_err, variables, context) => {
    if (context?.prevCampaign) {
      // Restore previous version of collection on any error
      queryClient.setQueryData<Campaign>(['campaign', variables.campaignId], context.prevCampaign)
    }
  },
  onSettled: (data) => {
    if (data?.campaignId) {
      queryClient.invalidateQueries(['campaign', data.campaignId])
      queryClient.invalidateQueries(['campaignsList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUpdateCampaign = () => useMutation(updateCampaign, updateCampaignEvents)
