import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { SendgridSetting } from '../types'

type SendgridSettingsForOptionsSelector = (state: ReduxState) => SendgridSetting[]
const getSendgridSettingsForOptions: SendgridSettingsForOptionsSelector = state =>
  state.sendgridSettings.sendgridSettingsForOptions || ([] as SendgridSetting[])

export const sendgridSettingsForOptionsSelector: SendgridSettingsForOptionsSelector = createSelector(
  getSendgridSettingsForOptions,
  sendgridSettings => sendgridSettings
)
