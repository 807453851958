// TODO - remove becouse of legacy

import * as actions from './actions'
import * as actionTypes from './actionTypes'
import * as pages from './pages'
import reducer from './reducer'
import * as selectors from './selectors'

console.log('AdSpend feature initialized!')

export { actions, actionTypes, pages, reducer, selectors }
