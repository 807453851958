import { SubscribeResult } from '@merchx-v2/web-socket/dist/webSocket/types/subscribeResult'
import * as t from '../actionTypes'

export type WebSocketSubscribeToChannelResultAction = BaseFSA<SubscribeResult>
type WebSocketSubscribeToChannelResult = (subscribeResult: SubscribeResult) => MrxThunk<WebSocketSubscribeToChannelResultAction>

const subscribeToChannelResult: WebSocketSubscribeToChannelResult = subscribeResult => async dispatch => {
  console.log('Subscribe to channel result', subscribeResult)
  dispatch({
    type: t.SUBSCRIBE_TO_CHANNEL_RESULT,
    payload: subscribeResult,
    meta: { done: true }
  })
}

export { subscribeToChannelResult }
