import React, { useEffect } from 'react'
import useReactRouter from 'use-react-router'
import { Table, Layout, PageHeader, Button } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'
import notification from 'mrx-notification'
import { Breadcrumbs } from 'components'
import * as rootStyles from 'assets/layoutStyle'
import styles from './CanceledSubscriptions.module.scss'
import { connector, PropsFromRedux } from './container'

const CanceledSubscriptions = (props: PropsFromRedux) => {
  const {
    canceledSubscriptions,
    isLoading,
    error,

    fetchCanceledSubscriptionsReport
  } = props

  const { history } = useReactRouter()

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Canceled subscriptions report error!',
        description: error
      })
    }
  }, [error])

  useEffect(() => {
    !canceledSubscriptions && !isLoading && fetchCanceledSubscriptionsReport()
  }, [canceledSubscriptions, isLoading, fetchCanceledSubscriptionsReport])

  const columns = [
    {
      title: 'Cycles',
      dataIndex: 'cycles'
    },
    {
      title: 'Customers Count',
      dataIndex: 'customersCount'
    },
    {
      title: 'Cancel Rate',
      dataIndex: 'cancelRate'
    }
  ]

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title='Canceled Subscriptions'
          extra={[
            <Button key='3' type='primary' icon={<ReloadOutlined />} onClick={fetchCanceledSubscriptionsReport} disabled={isLoading}>
              Reload
            </Button>
          ]}
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Reports', url: '/' },
            { title: 'Canceled Subscriptions', url: '/' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <Table
          columns={columns}
          dataSource={canceledSubscriptions || []}
          bordered
          rowKey={record => record.cycles}
          pagination={false}
          loading={isLoading}
        />
      </Layout.Content>
    </Layout>
  )
}

export default connector(CanceledSubscriptions)
