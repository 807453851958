import React, { useEffect } from 'react'
import { Button, Drawer, Input, Form } from 'antd'
import notification from 'mrx-notification'
import styles from './UpdateProductSize.module.scss'
import { connector, PropsFromRedux } from './container'

type Props = PropsFromRedux & {
  productSizeId?: number
  onClose: () => void
  visible: boolean
}

const UpdateProductSize = (props: Props) => {
  const {
    onClose,
    isLoading,
    productSize,
    productSizeId,
    visible,

    fetchProductSize,
    updateProductSize
  } = props

  const [form] = Form.useForm()

  useEffect(() => {
    fetchProductSize(productSizeId)
  }, [fetchProductSize, productSizeId])

  useEffect(() => {
    if (productSize) {
      form.setFieldsValue({
        name: productSize.name,
        displayName: productSize.displayName
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSize])

  if (!productSize) {
    return null
  }

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  // TODO - fix any type
  const onFinishHandler = async (values: any) => {
    const isSaved = await updateProductSize({
      productSizeId,
      productSizeData: {
        name: values.name,
        displayName: values.displayName
      }
    })
    if (isSaved) {
      notification.success({
        message: 'Successfully',
        description: 'Product Size was updated successfully!'
      })
      handleClose()
    }
  }

  return (
    <Drawer title='Update Product Size' width='400' onClose={handleClose} visible={visible}>
      <Form
        layout='vertical'
        hideRequiredMark
        onFinish={onFinishHandler}
        form={form}
        initialValues={{
          name: productSize?.name,
          displayName: productSize?.displayName
        }}
      >
        <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input Product Size name!' }]}>
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name='displayName' label='Display Name' rules={[{ required: true, message: 'Please input Product Size display name!' }]}>
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right'
          }}
        >
          <Button onClick={handleClose} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default connector(UpdateProductSize)
