import * as t from '../actionTypes'
import axios from 'utils/axios'
import { SiteType } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

export type RestoreSiteFromSnapshotAction = FSA<undefined, number, string>
type RestoreSiteFromSnapshot = (
  siteSnapshotId: number,
  ownerType: SiteType,
  ownerId: number
) => MrxThunk<RestoreSiteFromSnapshotAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'restoreSiteFromSnapshot', number>

const RESTORE_SITE_FROM_SNAPSHOT = `
  mutation restoreSiteFromSnapshot($siteSnapshotId: Int!, $ownerType: SiteType!, $ownerId: Int!) {
    restoreSiteFromSnapshot(siteSnapshotId: $siteSnapshotId ownerType: $ownerType, ownerId: $ownerId)
  }
`

const restoreSiteFromSnapshot: RestoreSiteFromSnapshot = (siteSnapshotId, ownerType, ownerId) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.RESTORE_SITE_FROM_SNAPSHOT,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: RESTORE_SITE_FROM_SNAPSHOT,
      variables: { ownerType, ownerId: +ownerId, siteSnapshotId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { restoreSiteFromSnapshot } = data
    if (!restoreSiteFromSnapshot) {
      throw new Error("Can't upload asset!")
    }

    dispatch({
      type: t.RESTORE_SITE_FROM_SNAPSHOT,
      payload: restoreSiteFromSnapshot,
      meta: { done: true }
    })

    isSaved = true
  } catch (err) {
    dispatch({
      type: t.RESTORE_SITE_FROM_SNAPSHOT,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isSaved
}

export { restoreSiteFromSnapshot }
