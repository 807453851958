import * as t from '../actionTypes'
import { EternalListEnum } from '../types'

export type ChangePageSizePayload = {
  size: number
  list: EternalListEnum
}

export type ChangePageSizeAction = BaseFSA<ChangePageSizePayload>
type ChangePageSize = (size: number, list: EternalListEnum) => MrxThunk<ChangePageSizeAction>

const changePageSize: ChangePageSize = (size, list) => async dispatch => {
  dispatch({
    type: t.CHANGE_PAGE_SIZE,
    payload: { size, list }
  })
}

export { changePageSize }
