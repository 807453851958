import { connect, ConnectedProps } from 'react-redux'
import * as QueryString from 'query-string'
import { ReduxState } from 'store/createRootReducer'
import * as taskFeature from 'features/tasksQueue'

type CampaignDetailsTabs = 'domains' | 'products' | 'landings' | 'quizzes'

export type OwnProps = {
  match: { params: { campaignId: string } }
  location: { search: string }
  onClose: () => void
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  const queryParams = QueryString.parse(ownProps.location.search)
  const activeTabKey = (queryParams.activeTab as string as CampaignDetailsTabs) || 'products'
  return {
    activeTabKey,
    taskList: taskFeature.selectors.taskQueueListSelector(state)
  }
}

const mapDispatchToProps = {
  fetchTasksList: taskFeature.actions.fetchTaskList
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
