import { BuildLogsInfo } from '@merchx-v2/web-socket/dist/webSocket/types/buildLogsInfo'
import * as t from '../actionTypes'

export type BuildLogsUpdatedAction = BaseFSA<BuildLogsInfo>
type BuildLogsUpdated = (buildLogsInfo: BuildLogsInfo) => MrxThunk<BuildLogsUpdatedAction>

const buildLogsUpdated: BuildLogsUpdated = buildLogsInfo => async dispatch => {
  dispatch({
    type: t.BUILD_LOGS_UPDATED,
    payload: buildLogsInfo,
    meta: { done: true }
  })
}

export { buildLogsUpdated }
