import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { SalesForGoogleChart } from '../types'

type SalesForGoogleChartSelector = (state: ReduxState) => SalesForGoogleChart

const getSalesForGoogleChart: SalesForGoogleChartSelector = state => {
  return state.reports.salesForGoogleChart
}

export const salesForGoogleChartSelector: SalesForGoogleChartSelector = createSelector(
  getSalesForGoogleChart,
  sales => sales
)
