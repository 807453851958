import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { ProductTag } from '../types'

export type FetchProductTagsForOptionsAction = FSA<undefined, ProductTag[], string>
type FetchProductTagsForOptions = (searchText?: string, limit?: number) => MrxThunk<FetchProductTagsForOptionsAction>
type QueryResponse = GraphQLResponse<'productTagsForOptions', ProductTag[]>

const FETCH_PRODUCT_TAGS_FOR_OPTIONS = `
  query fetchProductTagsForOptions($filters: ProductTagsFilters!) {
    productTagsForOptions(filters: $filters) {
      id
      name
    }
  }
`

const fetchProductTagsForOptions: FetchProductTagsForOptions = (searchText, limit) => async dispatch => {
  dispatch({
    type: t.FETCH_PRODUCT_TAGS_FOR_OPTIONS,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_PRODUCT_TAGS_FOR_OPTIONS,
      variables: { filters: { searchText, limit } }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { productTagsForOptions } = data
    if (!productTagsForOptions) {
      throw new Error("Can't get product tags!")
    }

    dispatch({
      type: t.FETCH_PRODUCT_TAGS_FOR_OPTIONS,
      payload: productTagsForOptions,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_PRODUCT_TAGS_FOR_OPTIONS,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchProductTagsForOptions }
