import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import * as t from '../actionTypes'
import { Category, GraphQLCategory } from '../types'

export type CreateCategoryAction = FSA<undefined, Category, string>
type CreateCategory = (
  workspaceId: number,
  campaignId: number,
  categoryData: CategoryInput
) => MrxThunk<CreateCategoryAction, Promise<string>>
type QueryResponse = GraphQLResponse<'createCategory', GraphQLCategory>

type CategoryInput = {
  name: string
}

const CREATE_CATEGORY = `
  mutation createCategory ($workspaceId: Int!, $campaignId: Int!, $categoryData: CategoryInput!) {
    createCategory(workspaceId: $workspaceId, campaignId: $campaignId, categoryData: $categoryData) {
      id
      name
      workspaceId
      campaignId
      createdAt
      updatedAt
    }
  }
`

const createCategory: CreateCategory = (workspaceId, campaignId, categoryData) => async (dispatch) => {
  let categoryId

  dispatch({
    type: t.CREATE_CATEGORY,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_CATEGORY,
      variables: { workspaceId, campaignId, categoryData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createCategory } = data
    if (!createCategory) {
      throw new Error("Can't create category!")
    }

    dispatch({
      type: t.CREATE_CATEGORY,
      payload: createCategory as Category,
      meta: { done: true }
    })
    categoryId = createCategory.id
  } catch (err) {
    dispatch({
      type: t.CREATE_CATEGORY,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return categoryId
}

export { createCategory }
