import React, { useCallback, useEffect, useState } from 'react'
import notification from 'mrx-notification'
import { Switch } from 'antd'
import styles from './AutoresponderSettings.module.scss'
import * as settingsFeature from 'features/settings'
import { useUpdateAutoresponder } from '../../hooks'
import { SitePreview } from 'features/sitePreviews/components'
import { Landing } from 'features/landings/types'
import { Autoresponder } from '../../types'

type PropsType = {
  landing: Landing
  autoresponder: Autoresponder
}

const { SettingsForLanding } = settingsFeature.components

const AutoresponderSettings = (props: PropsType) => {
  const { autoresponder, landing } = props

  const [useAlternativeEmailing, setUseAlternativeEmailing] = useState<boolean>(false)

  const updateAutoresponder = useUpdateAutoresponder()

  const resetState = useCallback(() => {
    setUseAlternativeEmailing(autoresponder?.useAlternativeEmailing || false)
  }, [autoresponder])

  useEffect(() => {
    if (autoresponder) {
      resetState()
    }
  }, [autoresponder, resetState])

  const handleSubmit = async (useAlternativeEmailing) => {
    updateAutoresponder.mutate(
      {
        ownerType: 'LANDING',
        ownerId: landing.id,
        autoresponderId: autoresponder.id,
        autoresponderData: {
          autoresponderType: autoresponder.autoresponderType,
          sourceCode: autoresponder.sourceCode,
          orderItemSourceCode: autoresponder.orderItemSourceCode,
          subjectSourceCode: autoresponder.subjectSourceCode,
          fromName: autoresponder.fromName,
          useAlternativeEmailing
        }
      },
      {
        onSuccess: () => {
          notification.success({
            message: 'Success!',
            description: 'Autoresponder was updated successfully'
          })
        },
        onError: (error) => {
          notification.error({
            message: 'Update Autoresponder error!',
            description: error.toString()
          })
        }
      }
    )
  }

  return (
    <div className={styles.root}>
      <div className={styles.frameContainer}>
        <SitePreview ownerType='AUTORESPONDER' ownerId={autoresponder.id} version={autoresponder.version} environment='PREVIEW' />
      </div>
      <div className={styles.settingsContainer}>
        <div className={styles.switchContainer}>
          <span>Use Alternative Mailing Plugin</span>
          <Switch
            className={styles.switch}
            checked={useAlternativeEmailing}
            checkedChildren='true'
            unCheckedChildren='false'
            onChange={handleSubmit}
          />
        </div>
        <SettingsForLanding ownerType='AUTORESPONDER' ownerId={autoresponder.id} selfUpdate landing={landing} />
      </div>
    </div>
  )
}

export default AutoresponderSettings
