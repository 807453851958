import React from 'react'

type OnPageSizeChange = (newSize: number) => void

// Use next syntax to define the rules
// 1 Number - minimum height resolution
// 2 Number - maximum height resolution
// 3 Number - number of items on page on this resolution
type HeightResizeRule = [number, number, number]

const defaultArg: HeightResizeRule[] = [
  [0, 800, 5],
  [800, 1000, 6],
  [1000, 1200, 8],
  [1200, Infinity, 10]
]

const getPageSize = (rules: HeightResizeRule[]) => {
  let pageSize = 10

  for (const rule of rules) {
    if (window.innerHeight > rule[0] && window.innerHeight <= rule[1]) {
      pageSize = rule[2]
    }
  }

  return pageSize
}

const useHeightResize = (onPageSizeChange: OnPageSizeChange, rules: HeightResizeRule[] = defaultArg) => {
  const handleResize = () => {
    const newSize = getPageSize(rules)
    onPageSizeChange(newSize)
  }

  React.useEffect(() => {
    onPageSizeChange(getPageSize(rules))
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useHeightResize
