import React, { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Drawer, Input, Form, Select } from 'antd'
import notification from 'mrx-notification'
import styles from './CreateProductAttribute.module.scss'
import { PropsFromRedux, connector } from './container'

type CreateProductAttributeProps = PropsFromRedux & {
  productId: number
}

const CreateProductAttribute = (props: CreateProductAttributeProps) => {
  const [isVisible, setVisible] = useState(false)

  const { isLoading, productId, createProductAttribute } = props

  const showDrawer = () => {
    setVisible(!isVisible)
  }

  const [form] = Form.useForm()

  const handleClose = () => {
    setVisible(false)
    form.resetFields()
  }

  const onFinishHandler = async (values: any) => {
    const isSaved = await createProductAttribute(productId, {
      attribute: values.productAttribute,
      value: values.value
    })
    if (isSaved) {
      notification.success({
        message: 'Successfully',
        description: 'Product Attribute was created successfully!'
      })
      handleClose()
    }
  }

  return (
    <>
      <Button type='primary' onClick={showDrawer}>
        <PlusOutlined />
        CREATE
      </Button>
      <Drawer title='Create Product Attribute' width='400' onClose={handleClose} visible={isVisible}>
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
          <Form.Item name='productAttribute' label='Product Attribute' rules={[{ required: true, message: 'Please select Attribute!' }]}>
            <Select style={{ width: '100%' }}>
              <Select.Option value='COLOR' key={1}>
                Color
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='value' label='Value' rules={[{ required: true, message: 'Please input Value!' }]}>
            <Input style={{ width: '100%' }} />
          </Form.Item>

          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button onClick={handleClose} className={styles.closeButton}>
              Close
            </Button>
            <Button type='primary' htmlType='submit' loading={isLoading}>
              Create
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

export default connector(CreateProductAttribute)
