import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type isLoadingKeyType = 'isLoadingSnapshots' | 'isTakeSnapshot' | 'isRestoreFromSnapshot' | 'isRestoreFromFile'

type IsLoadingSelector = (state: ReduxState, isLoadingKey: isLoadingKeyType) => boolean

const getIsLoadingSelectorByKey = (state: ReduxState, isLoadingKey: isLoadingKeyType) =>
  state.siteSnapshots.UIState[isLoadingKey] || false

export const isLoadingSelector: IsLoadingSelector = createSelector(getIsLoadingSelectorByKey, isLoading => isLoading)
