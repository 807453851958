import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { ProductAttribute } from '../types'

type ProductAttributesListSelector = (state: ReduxState, productId?: number) => ProductAttribute[]

const getProductAttributes: ProductAttributesListSelector = (state, productId) => {
  let result = [] as ProductAttribute[]
  if (productId) {
    if (Object.prototype.hasOwnProperty.call(state.productAttributes.productAttributes, productId)) {
      result = Object.values(state.productAttributes.productAttributes[productId]) as ProductAttribute[]
    }
  }
  return result
}

export const productAttributesListSelector: ProductAttributesListSelector = createSelector(
  getProductAttributes,
  (productAttributes) => productAttributes
)
