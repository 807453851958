import { connect, ConnectedProps } from 'react-redux'
import { match } from 'react-router'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

export type OwnProps = {
  match: match<{ campaignId: string }>
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  return {
    error: selectors.errorSelector(state),
    isLoading: selectors.isLoadingSelector(state, 'isCampaignTemplatesReportLoading'),
    campaignId: +ownProps.match.params.campaignId
  }
}

const mapDispatchToProps = {
  updateCampaignLandingsFromTemplate: actions.updateCampaignLandingsFromTemplate,
  redeployCampaignLandings: actions.redeployCampaignLandings
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector>
