import React, { useContext, useEffect } from 'react'
import useReactRouter from 'use-react-router'
import { Layout, PageHeader, Button, Card, Statistic, Table } from 'antd'
import { CheckCircleTwoTone, WarningTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import notification from 'mrx-notification'
import { GlobalContext } from 'appContexts'
import * as rootStyles from 'assets/layoutStyle'
import { useDeployLanding } from 'features/landings/hooks'
import { useUpdateAllLandingsFromTemplate } from 'features/templates/hooks'
import styles from './SitesManagementDashboard.module.scss'
import { connector, PropsFromRedux } from './container'

const SitesManagementDashboard = (props: PropsFromRedux) => {
  const {
    isLoading,
    error,
    total,
    currentPage,
    sitesManagementDashboard,
    totalLandings,
    activeLandings,
    archivedLandings,
    newLandings,
    updatesAvailable,
    needToRedeploy,

    changeCurrentPage,
    fetchSitesManagementDashboard,
    redeployAllLandings
  } = props

  const { history } = useReactRouter()

  const { workspaceId } = useContext(GlobalContext)

  const deployLanding = useDeployLanding()
  const updateAllLandingsFromTemplate = useUpdateAllLandingsFromTemplate()

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Sites Management Dashboard reports error!',
        description: error
      })
    }
  }, [error])

  useEffect(() => {
    if (updateAllLandingsFromTemplate.error) {
      notification.error({
        message: 'Error!',
        description:
          updateAllLandingsFromTemplate.error instanceof Error
            ? updateAllLandingsFromTemplate.error.message
            : updateAllLandingsFromTemplate.error.toString()
      })
    }
  }, [updateAllLandingsFromTemplate.error])

  useEffect(() => {
    if (updateAllLandingsFromTemplate.isSuccess) {
      notification.success({
        message: 'Successfully!',
        description: 'All landings was updated from template'
      })
    }
  }, [updateAllLandingsFromTemplate.isSuccess])

  useEffect(() => {
    fetchSitesManagementDashboard()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Campaign',
      dataIndex: 'campaignName',
      key: 'campaignName'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Site URL',
      dataIndex: 'siteUrl',
      render: (siteUrl) => {
        return siteUrl ? (
          <a target='_blank' rel='noopener noreferrer' href={siteUrl}>
            {siteUrl}
          </a>
        ) : (
          ''
        )
      },
      key: 'siteUrl'
    },
    {
      title: 'Errors',
      dataIndex: 'errors',
      key: 'errors'
    },
    {
      title: 'Status',
      render: (record) => {
        if (record.status === 'deployed') {
          return <CheckCircleTwoTone twoToneColor='#52c41a' className={styles.statusIcon} />
        } else if (record.status === 'NEW') {
          return <WarningTwoTone twoToneColor='#fba905' className={styles.statusIcon} />
        } else if (record.status === 'archived') {
          return <CloseCircleTwoTone twoToneColor='tomato' className={styles.statusIcon} />
        }
      },
      key: 'status'
    },
    {
      title: 'Actions',
      render: (record) => {
        if (record.needToRedeploy) {
          return <Button onClick={() => deployLanding.mutate(record.id)}>Redeploy</Button>
        } else {
          if (record.status === 'deployed') {
            return <Button>Archive</Button>
          } else if (record.status === 'NEW') {
            return <Button onClick={() => deployLanding.mutate(record.id)}>Deploy</Button>
          }
        }
      },
      key: 'actions'
    },
    {
      title: '',
      render: (record) => (
        <Button
          onClick={() => {
            history.push(`/campaigns/${record.id}`)
          }}
        >
          Edit
        </Button>
      ),
      key: 'edit'
    }
  ]

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader className={styles.pageHeader} onBack={() => history.goBack()} title='Sites Management Dashboard' extra={[]} />
      </Layout.Header>

      <div className={styles.cardsContainer}>
        <Card className={styles.cardRate}>
          <Statistic title='Total' value={totalLandings} />
        </Card>
        <Card className={styles.cardRate}>
          <Statistic title='Active' value={activeLandings} />
        </Card>
        <Card className={styles.cardRate}>
          <Statistic title='Archived' value={archivedLandings} />
        </Card>
        <Card className={styles.cardRate}>
          <Statistic title='New' value={newLandings} />
        </Card>
        <Card>
          <div className={styles.innerCardContainer}>
            <Statistic title='Updates Available' value={updatesAvailable} />
            <Button
              className={styles.cardButton}
              onClick={() => updateAllLandingsFromTemplate.mutate({ workspaceId })}
              loading={updateAllLandingsFromTemplate.isLoading}
            >
              UPDATE ALL
            </Button>
          </div>
        </Card>
        <Card>
          <div className={styles.innerCardContainer}>
            <Statistic title='Need to Redeploy' value={needToRedeploy === null ? 0 : needToRedeploy} />
            <Button className={styles.cardButton} onClick={() => redeployAllLandings()} loading={isLoading}>
              REDEPLOY ALL
            </Button>
          </div>
        </Card>
      </div>

      <div className={styles.tableContainer}>
        <Table
          columns={columns}
          dataSource={sitesManagementDashboard}
          bordered
          loading={isLoading}
          pagination={{
            onChange: changeCurrentPage,
            current: currentPage,
            total
          }}
        />
      </div>
    </Layout>
  )
}

export default connector(SitesManagementDashboard)
