// TODO - remove becouse of legacy

import axios from 'utils/axios'
import { useQuery } from 'react-query'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Landing } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'landing', Landing>

const FETCH_LANDING = `
  query fetchLanding($landingId: Int!) {
    landing(landingId: $landingId) {
      id
      name
      campaignId
      campaign {
        id
        workspaceId
        name
        status
        defaultDomain
        createdAt
        updatedAt
      }
      workspaceId
      templateId
      landingType
      template {
        id
        name
      }
      saleSource
      subdomain
      subroute
      version
      deploymentPlugin
      deploymentSettingsId
      deploymentSettingsName
      paymentPlugin
      paymentSettingsId
      paymentSettingsName
      fulfillmentPlugin
      fulfillmentSettingsId
      fulfillmentSettingsName
      mailingPlugin
      mailingSettingsId
      mailingSettingsName
      alternativeMailingPlugin
      alternativeMailingSettingsId
      alternativeMailingSettingsName
      smsPlugin
      smsSettingsId
      smsSettingsName
      shippingCost
      hasCustomShipping
      processingCost
      applyProcessingOnce
      allowMultiplePayments
      hasFixedPrices
      fixedPrice

      landingQuiz {
        id
        name
      }

      assets(ownerType: LANDING) {
        id
        name
        extension
        ownerType
        ownerId
        mimeType
        s3bucket
        s3key
        signedUrl
      }
      autoresponders(ownerType: LANDING) {
        id
        ownerType
        ownerId
        autoresponderType
        sourceCode
        orderItemSourceCode
        useAlternativeEmailing
        subjectSourceCode
        version
        fromName
        settings {
          id
          ownerType
          ownerId
          name
          alias
          type
          value
          required
          jsonValue
        }
      }
      components(ownerType: LANDING) {
        id
        name
        ownerType
        ownerId
      }
      dependencies(ownerType: LANDING) {
        id
        name
        ownerType
        ownerId
        version
      }
      pages(ownerType: LANDING) {
        id
        name
        route
        order
        canBeSkippedOnBuild
        skipOnBuild
        settings {
          id
          ownerType
          ownerId
          name
          alias
          type
          value
          required
          jsonValue
          filters
        }
      }
      settings {
        id
        ownerType
        ownerId
        name
        alias
        type
        value
        required
        jsonValue
      }
      builds(ownerType: LANDING, ownerId: $landingId) {
        id
        ownerType
        ownerId
        version
        environment
        status
        progress
        artifactsUrl
        logs
        startedAt
        endedAt
        buildTime
        createdAt
        updatedAt
      }
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchLanding = (landingId: number) => Promise<Landing>

const fetchLanding: FetchLanding = async (landingId) => {
  try {
    if (!landingId) {
      return undefined
    }
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_LANDING,
      variables: { landingId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { landing } = data
    if (!landing) {
      throw new Error("Can't get landing!")
    }

    return landing
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useLanding = (landingId: number) => useQuery(['landing', landingId], () => fetchLanding(landingId))
