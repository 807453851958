import { extractErrorInfo } from 'utils/graphqlHelpers'
import { MerchxWebSocket } from 'utils/webSocket'
import * as t from '../actionTypes'
import { subscribeToChannel } from './subscribeToChannel'

export type WebSocketConnectedAction = FSA<undefined, undefined, string>
type WebSocketConnected = (socket: MerchxWebSocket) => MrxThunk<WebSocketConnectedAction>

const wsConnected: WebSocketConnected = socket => async (dispatch, getState) => {
  console.log('MerchX web socket connected!')

  dispatch({
    type: t.WS_CONNECTED,
    meta: { done: false }
  })

  try {
    const { channels } = getState().websocket

    for (const channel of Object.keys(channels)) {
      dispatch(subscribeToChannel(channel, socket))
    }
  } catch (err) {
    dispatch({
      type: t.WS_CONNECTED,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { wsConnected }
