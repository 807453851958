import axios from 'utils/shoppingCartAxios'
import { useMutation } from 'react-query'
import { PromocodeDto, CreatePromocodeDto } from '@merchx-v2/shared-types'
import { queryClient } from 'queryClient'
import { extractResponseError } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UpdatePromocode = (args: CreatePromocodeDto) => Promise<PromocodeDto>

const updatePromocode: UpdatePromocode = async (args) => {
  try {
    const response = await axios.post(`/promocodes/${args.code}/update`, args)

    const data: PromocodeDto = response.data

    if (!data) {
      throw new Error("Can't update promocode!")
    }

    return data
  } catch (err) {
    extractResponseError(err)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UpdatePromocodeEvents = {
  onSettled: (data?: PromocodeDto) => void
}

const updatePromocodeEvents: UpdatePromocodeEvents = {
  onSettled: (promocode) => {
    if (promocode?.code) {
      queryClient.invalidateQueries(['promocodes'])
      queryClient.setQueryData(['promocode', promocode.code], promocode)
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUpdatePromocode = () => useMutation((args: CreatePromocodeDto) => updatePromocode(args), updatePromocodeEvents)
