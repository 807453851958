import React, { useEffect } from 'react'
import useReactRouter from 'use-react-router'
import { Table, Layout, PageHeader, Pagination } from 'antd'
import notification from 'mrx-notification'
import { Breadcrumbs } from 'components'
import * as rootStyles from 'assets/layoutStyle'
import styles from './AddressesList.module.scss'
import { connector, PropsFromRedux } from './container'

const AddressesList = (props: PropsFromRedux) => {
  const {
    addressesList,
    isLoading,
    error,
    workspaceId,
    currentPage,
    total,
    isNeedToFetchList,

    changeCurrentPage,
    fetchAddressesList
  } = props

  const { history } = useReactRouter()

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Addresses list page error!',
        description: error
      })
    }
  }, [error])

  useEffect(() => {
    isNeedToFetchList && fetchAddressesList(workspaceId, currentPage)
  }, [workspaceId, currentPage, fetchAddressesList, isNeedToFetchList])

  const columns = [
    {
      title: 'Country',
      dataIndex: ['country', 'name'],
      key: 'country'
    },
    {
      title: 'ZIP Code',
      dataIndex: 'zipCode',
      key: 'zipCode'
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state'
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city'
    },
    {
      title: 'Address 1',
      dataIndex: 'address',
      key: 'address'
    },
    {
      title: 'Address 2',
      dataIndex: 'address2',
      key: 'address2'
    }
  ]

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader onBack={() => history.goBack()} title='Addresses List' />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Sales', url: '/' },
            { title: 'Addresses List', url: '/' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <Table
          columns={columns}
          dataSource={addressesList}
          bordered
          rowKey={record => record.id}
          pagination={false}
          loading={isLoading}
          footer={() => (
            <Pagination
              defaultCurrent={currentPage}
              total={total}
              style={{ textAlign: 'right' }}
              onChange={page => changeCurrentPage(page)}
            />
          )}
        />
      </Layout.Content>
    </Layout>
  )
}

export default connector(AddressesList)
