import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import useReactRouter from 'use-react-router'
import moment from 'moment'
import { Table, Layout, PageHeader, Tag } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { GlobalContext } from 'appContexts'
import * as rootStyles from 'assets/layoutStyle'
import { Breadcrumbs } from 'components'
import { OrderItem } from 'features/orders/types'
import notification from 'mrx-notification'
import InfiniteScroll from 'react-infinite-scroller'
import { useOrderItemsList } from '../../hooks'
import styles from './NeedDesignList.module.scss'

const tagColors = {
  hold: 'purple',
  dispatched: 'purple',
  deleted: 'red',
  active: 'green'
}

const NeedDesignList = React.memo(() => {
  const { history } = useReactRouter()
  const { workspaceId } = useContext(GlobalContext)

  const orderItemsList = useOrderItemsList({ workspaceId, status: 'HOLD', holdReason: 'ARTWORK_ABSENT', groupByProduct: true })

  useEffect(() => {
    if (orderItemsList.error) {
      notification.error({
        message: 'Order Items list page error!',
        description: orderItemsList.error
      })
    }
  }, [orderItemsList.error])

  const columns: ColumnsType<OrderItem> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true
    },
    {
      title: 'Date',
      render: (_value, record) => moment(record.createdAt).format('MM/DD/YYYY HH:mm:ss'),
      key: 'createdAt',
      sorter: true
    },
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'displayName'
    },
    {
      title: 'Product ID',
      render: (_value, record) => (
        <Link to={`/products/${record.productId}/edit`}>{record.productId}</Link>
      ),
      key: 'productId'
    },
    {
      title: 'Status',
      render: (_value, record) => <Tag color={tagColors[record.status]}>{record.status}</Tag>,
      key: 'status'
    }
  ]
  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader onBack={() => history.goBack()} title='Products Without Design' />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Sales', url: '/' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <InfiniteScroll
          style={{ paddingBottom: '30px' }}
          pageStart={0}
          loadMore={orderItemsList.fetchNextPage}
          hasMore={!orderItemsList.isFetching && orderItemsList.hasNextPage}
          useWindow={false}
        >
          <Table
            columns={columns}
            dataSource={orderItemsList.data?.pages.map((item) => item.items).flat()}
            bordered
            rowKey={(record) => record.id}
            pagination={false}
          />
        </InfiniteScroll>
      </Layout.Content>
    </Layout>
  )
})

export default NeedDesignList
