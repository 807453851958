import { useMutation } from 'react-query'
import axios from 'utils/axios'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Order } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'handleOrder', Order>

const HANDLE_ORDER = `
  mutation HandleOrder($orderId: Int!) {
    handleOrder(orderId: $orderId) {
      id
      attributes {
        attribute
        value
      }
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type HandleOrderArgs = {
  orderId: number
}
type HandleOrder = (args: HandleOrderArgs) => Promise<Order>

const handleOrder: HandleOrder = async ({ orderId }) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: HANDLE_ORDER,
      variables: { orderId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { handleOrder } = data
    if (!handleOrder) {
      throw new Error("Can't handle order!")
    }

    return handleOrder
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type HandleOrderEvents = {
  onSettled: (data?: Order) => void
}

const handleOrderEvents: HandleOrderEvents = {
  onSettled: (order) => {
    if (order?.id) {
      queryClient.invalidateQueries(['order'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useHandleOrder = () => useMutation(handleOrder, handleOrderEvents)
