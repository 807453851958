// TODO - remove becouse of legacy

import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { AdSpendRow } from '../types'

export type FetchAdSpendsAction = FSA<undefined, AdSpendRow[], string>
type FetchAdSpends = () => MrxThunk<FetchAdSpendsAction, Promise<void>>
type QueryResponse = GraphQLResponse<'adSpend', AdSpendRow[]>

const FETCH_AD_SPENDS = `
  query AdSpends($filters: AdSpendsFilters!) {
    adSpends(filters: $filters) {
      campaign {
        id
        name
      }
      sumOfExpenses
      numberOfClicks
    }
  }
`

export const fetchAdSpends: FetchAdSpends = () => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_AD_SPENDS,
    meta: { done: false }
  })

  try {
    const workspaceId = getState()?.workspaces?.activeWorkspace?.id
    const {
      adSpends: { dateList }
    } = getState()
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_AD_SPENDS,
      variables: { filters: { workspaceId, day: dateList.format('YYYY-MM-DD') } }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { adSpend } = data
    if (!adSpend) {
      throw new Error("Can't fetch ad spend report!")
    }

    dispatch({
      type: t.FETCH_AD_SPENDS,
      payload: adSpend.map((adSpend) => ({
        ...adSpend,
        sumOfExpenses: parseFloat((adSpend.sumOfExpenses / 100).toFixed(2))
      })),
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_AD_SPENDS,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}
