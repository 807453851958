import axios from 'utils/axios'
import { useQuery } from 'react-query'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

import { Country } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'country', Country>

const FETCH_COUNTRY = `
    query fetchCountry ($countryId: Int!) {
    country (countryId: $countryId) {
      id
      name
      iso2
      iso3
      worldPart
      code
      currencyId
      currency {
        id
        name
        code
        symbol
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchCountry = (countryId: number) => Promise<Country>

const fetchCountry: FetchCountry = async (countryId) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_COUNTRY,
      variables: { countryId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { country } = data
    if (!country) {
      throw new Error("Can't get country!")
    }

    return country
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCountry = (countryId: number) => useQuery(['country', countryId], () => fetchCountry(countryId))
