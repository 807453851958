import React from 'react'
import { Popconfirm } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { EditTwoTone, DeleteTwoTone } from '@ant-design/icons'
import styles from './StoreProducts.module.scss'
import { StoreProductsPageItemDto } from '@merchx-v2/shared-types/dist/dto/stores/store-products-page-item.dto'

type Props = {
  onEdit?: (productId: number) => void
  onRemove?: (productId: number) => void
  DragHandle: any
}

export const createTableColumns = ({ onEdit, onRemove, DragHandle }: Props): ColumnsType<StoreProductsPageItemDto> => {
  const handleEditClick: React.MouseEventHandler<HTMLElement> = (event) => {
    event.stopPropagation()
    const id = Number(event.currentTarget.dataset.id)
    onEdit && onEdit(id)
  }

  const handleRemoveClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()
    const id = Number(event.currentTarget.name)
    onRemove && onRemove(id)
  }

  return [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
      key: 'drag'
    },
    {
      title: 'ID',
      dataIndex: 'id',
      width: '100px',
      align: 'right'
    },
    {
      title: 'Supplier',
      dataIndex: 'supplierName'
    },
    {
      title: 'Product Name',
      dataIndex: 'productName'
    },
    {
      title: 'Product Type',
      dataIndex: 'productType'
    },
    {
      title: 'Price',
      dataIndex: 'price',
      render: (value) => (value ? `$${(value / 100).toFixed(2)}` : ''),
      align: 'right',
      width: '110px'
    },
    {
      title: 'Edit',
      key: 'edit',
      width: '20px',
      align: 'center',
      render: (_value, record) => <EditTwoTone onClick={handleEditClick} data-id={record.id} className={styles.editIcon} />
    },
    {
      title: 'Remove',
      key: 'remove',
      width: '20px',
      align: 'center',
      render: (_value, record) => (
        <Popconfirm
          okButtonProps={{ name: record.id.toString() }}
          title='Are you sure?'
          onConfirm={handleRemoveClick}
          okText='Yes'
          cancelText='No'
        >
          <DeleteTwoTone className={styles.editIcon} />
        </Popconfirm>
      )
    }
  ]
}
