import React, { useMemo, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import useRouter from 'use-react-router'
import { Table, Button } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import type { SortableContainerProps, SortEnd } from 'react-sortable-hoc'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { useSortProducts, useRemoveProductFromStore } from 'features/products/hooks'
import { useStoreProductsPage, useStoreProductsSoldCsv } from '../../hooks'
import { createTableColumns } from './createTableColumns'
import styles from './StoreProducts.module.scss'

type Props = {
  storeId: number
}

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />)
const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />)
const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />)

const StoreProducts: React.FC<Props> = ({ storeId }) => {
  const router = useRouter()
  const removeProductFromStore = useRemoveProductFromStore()
  const storeProductsSoldCsv = useStoreProductsSoldCsv(storeId)
  const sortProducts = useSortProducts()

  const storeProductsPage = useStoreProductsPage({ storeId })
  const storeProducts = useMemo(
    () => storeProductsPage.data?.pages.map((page) => page.items).flat() || [],
    [storeProductsPage.dataUpdatedAt]
  )
  useEffect(() => storeProductsPage.remove, [])

  useEffect(() => {
    storeProductsSoldCsv.data && window.open(storeProductsSoldCsv.data, '_blank')
  }, [storeProductsSoldCsv.data])

  const page = storeProductsPage.data?.pages.at(-1).currentPage || 1
  const size = storeProductsPage.data?.pages.at(-1).pageSize || 30

  const handleEditProduct = (productId) => {
    router.history.push(`/products/${productId}/edit`)
  }

  const handleRemoveProduct = (productId) => {
    removeProductFromStore.mutate(productId)
  }

  const columns = useMemo(() => createTableColumns({ DragHandle, onEdit: handleEditProduct, onRemove: handleRemoveProduct }), [])

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    const dragItem = storeProducts[oldIndex]
    const hoverItem = storeProducts[newIndex]
    if (!dragItem || !hoverItem || dragItem.id === hoverItem.id) return

    sortProducts.mutate({ productId: dragItem.id, newIndex: hoverItem.sortIndex })
  }

  const DraggableContainer = (props: SortableContainerProps) => (
    <SortableBody useDragHandle disableAutoscroll helperClass='row-dragging' onSortEnd={onSortEnd} {...props} />
  )

  const DraggableBodyRow: React.FC<any> = ({ ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = storeProducts.findIndex((x) => x.id === restProps['data-row-key'])
    return <SortableItem index={index} {...restProps} />
  }

  return (
    <div className={styles.container}>
      <div className={styles.buttonAligin}>
        <Button type='default' onClick={() => storeProductsSoldCsv.refetch()}>
          TOTAL SOLD CSV
        </Button>
        <Button type='primary' onClick={() => router.history.push(`/products/create?storeId=${storeId}`)}>
          CREATE PRODUCT
        </Button>
      </div>
      <InfiniteScroll
        style={{ paddingBottom: '30px' }}
        dataLength={page * size}
        next={storeProductsPage.fetchNextPage}
        hasMore={!!storeProductsPage.hasNextPage}
        loader={<h4>Loading...</h4>}
        refreshFunction={storeProductsPage.refetch}
        scrollThreshold={0.8}
      >
        <Table
          className={styles.table}
          columns={columns}
          dataSource={storeProducts}
          loading={storeProductsPage.isLoading || removeProductFromStore.isLoading || sortProducts.isLoading}
          bordered
          rowKey={(record) => record.id}
          pagination={false}
          dropdownPrefixCls='order'
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow
            }
          }}
        />
      </InfiniteScroll>
    </div>
  )
}

export default React.memo(StoreProducts)
