import React from 'react'
import { Input, Typography, Form, FormInstance } from 'antd'
import styles from './ManageFormSeo.module.scss'

type Props = {
  withoutBorder?: boolean
  showTitle?: boolean
  showDescription?: boolean
  showKeywords?: boolean
  form: FormInstance
  seoUrlPrefix: string
}

const ManageSeo = (props: Props) => {
  const { withoutBorder = false, showTitle = true, showDescription = true, showKeywords = true, form, seoUrlPrefix } = props

  return (
    <div className={withoutBorder ? styles.seoBlockWithoutBorder : styles.seoBlock}>
      <Form.Item name='hasCustomSeo' noStyle hidden>
        <Input disabled />
      </Form.Item>
      <div className={styles.seoBlockLeftPart}>
        <Typography style={{ color: 'black', fontWeight: 'bold', paddingBottom: '8px' }}>SEO</Typography>
        <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.hasCustomSeo !== curValues.hasCustomSeo}>
          {(formInstance) => {
            const hasCustomSeo = formInstance.getFieldValue('hasCustomSeo')

            if (!hasCustomSeo) return null

            return (
              <>
                <div className={styles.labeledItem} hidden={!showTitle}>
                  <Typography style={{ color: 'black', paddingBottom: '8px' }}>Page Title</Typography>
                  <Form.Item noStyle name='seoTitle'>
                    <Input />
                  </Form.Item>
                </div>
                <div className={styles.labeledItem}>
                  <Typography style={{ color: 'black', paddingBottom: '8px' }}>URL and handle</Typography>
                  <Form.Item noStyle name='seoUrl'>
                    <Input prefix={seoUrlPrefix} className={styles.seoUrlInput} />
                  </Form.Item>
                </div>
                <div className={styles.labeledItem} hidden={!showDescription}>
                  <Typography style={{ color: 'black', paddingBottom: '8px' }}>Description</Typography>
                  <Form.Item noStyle name='seoDescription'>
                    <Input.TextArea />
                  </Form.Item>
                </div>
                <div className={styles.labeledItem} hidden={!showKeywords}>
                  <Typography style={{ color: 'black', paddingBottom: '8px' }}>Keywords</Typography>
                  <Form.Item noStyle name='seoKeywords'>
                    <Input.TextArea />
                  </Form.Item>
                </div>
              </>
            )
          }}
        </Form.Item>
      </div>
      <div className={styles.seoBlockRightPart}>
        <Typography style={{ color: 'black', fontWeight: 'bold', paddingBottom: '8px', textDecoration: 'underline' }}>
          <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.hasCustomSeo !== curValues.hasCustomSeo}>
            {(formInstance) => {
              const hasCustomSeo = formInstance.getFieldValue('hasCustomSeo')

              return (
                <div className={styles.seoCustomizeButton} onClick={() => form.setFieldsValue({ hasCustomSeo: !hasCustomSeo })}>
                  {hasCustomSeo ? 'Use default' : 'Customize'}
                </div>
              )
            }}
          </Form.Item>
        </Typography>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) =>
            prevValues.hasCustomSeo !== curValues.hasCustomSeo ||
            prevValues.seoDescription !== curValues.seoDescription ||
            prevValues.seoUrl !== curValues.seoUrl ||
            prevValues.seoTitle !== curValues.seoTitle
          }
        >
          {(formInstance) => {
            const hasCustomSeo = formInstance.getFieldValue('hasCustomSeo')
            const seoDescription = formInstance.getFieldValue('seoDescription')
            const seoUrl = formInstance.getFieldValue('seoUrl')
            const seoTitle = formInstance.getFieldValue('seoTitle')

            if (!hasCustomSeo) return null

            return (
              <>
                <Typography style={{ color: 'black', fontWeight: 'bold', paddingBottom: '8px' }}>Search Engine Preview</Typography>
                <div className={styles.examplePageTitle} onClick={() => window.open(`${seoUrlPrefix}${seoUrl}`)}>
                  {seoTitle}
                </div>
                <div className={styles.exampleUrl} onClick={() => window.open(`${seoUrlPrefix}${seoUrl}`)}>
                  {seoUrlPrefix}
                  {seoUrl}
                </div>
                <div className={styles.exampleDescription}>{seoDescription}</div>
              </>
            )
          }}
        </Form.Item>
      </div>
    </div>
  )
}

export default ManageSeo
