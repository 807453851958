import { useInfiniteQuery } from 'react-query'
import axios from 'utils/axios'
import hash from 'object-hash'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { CountriesList } from '../types'

const DEFAULT_PAGE_SIZE = 30

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'countriesListPage', CountriesList>

const FETCH_COUNTRIES_LIST = `
  query fetchCountriesList ($page: Int, $size: Int, $filters: CountriesFilters) {
    countriesListPage (page: $page, size: $size, filters: $filters) {
      items {
        id
        name
        iso2
        iso3
        worldPart
        code
        currencyId
        currency{
          id
          name
          code
          symbol
        }
        createdAt
        updatedAt
      }
      total
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type PageParam = {
  page?: number
  size?: number
  filters?: {
    limit?: number
    searchText?: string
  }
}

type FetchCountriesListArgs = {
  queryKey: string | string[]
  pageParam?: PageParam
}

type FetchCountriesList = (args: FetchCountriesListArgs) => Promise<CountriesList>

const fetchCountriesList: FetchCountriesList = async ({ pageParam }) => {
  const { page = 1, size = DEFAULT_PAGE_SIZE, filters = undefined } = pageParam || {}
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_COUNTRIES_LIST,
      variables: { page, size, filters }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { countriesListPage } = data
    if (!countriesListPage) {
      throw new Error("Can't get countries list page!")
    }

    return {
      items: countriesListPage.items,
      total: countriesListPage.total,
      currentPage: page,
      pageSize: size,
      filters
    }
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

type GetNextPageParam = (args: CountriesList) => PageParam | undefined

type CountriesListEvents = {
  keepPreviousData: boolean
  getPreviousPageParam: GetNextPageParam
  getNextPageParam: GetNextPageParam
}

const countriesListEvents: CountriesListEvents = {
  keepPreviousData: true,
  getPreviousPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, filters }) => {
    if (currentPage > 1) {
      return {
        page: currentPage - 1,
        size: pageSize,
        filters
      }
    }

    return undefined
  },
  getNextPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, total = 0, filters }) => {
    if (pageSize * currentPage < total) {
      return {
        page: currentPage + 1,
        size: pageSize,
        filters
      }
    }

    return undefined
  }
}

export const useCountriesList = (searchText?: string) => {
  const cacheKey = ['countriesList', hash({ searchText })]

  return useInfiniteQuery(
    cacheKey,
    prev =>
      fetchCountriesList({
        queryKey: cacheKey,
        pageParam: { ...prev.pageParam, searchText }
      }),
    countriesListEvents
  )
}
