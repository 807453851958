import React, { useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import moment, { Moment } from 'moment'
import numeral from 'numeral'
import { Table, Layout, PageHeader, DatePicker, Button, Typography } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import notification from 'mrx-notification'
import { Breadcrumbs } from 'components'
import { RangeValue } from 'rc-picker/lib/interface'
import timeHelpers from 'utils/timeHelpers'
import * as rootStyles from 'assets/layoutStyle'
import styles from './TopLandingsReport.module.scss'
import { connector, PropsFromRedux } from './container'

type Params = {
  startDate?: string
  endDate?: string
}

const TopLandingsReport = (props: PropsFromRedux) => {
  const {
    topLandingsReport,
    isLoading,
    error,

    fetchTopLandingsReport
  } = props

  const { history, match } = useReactRouter()
  const params = match.params as Params

  const [startDate, setStartDate] = useState<Moment>(params.startDate ? moment(params.startDate) : moment().startOf('day'))
  const [endDate, setEndDate] = useState<Moment>(
    params.endDate
      ? moment(params.endDate)
      : moment()
          .add(1, 'day')
          .endOf('day')
  )

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Top Landings report error!',
        description: error
      })
    }
  }, [error])

  useEffect(() => {
    fetchTopLandingsReport(timeHelpers.getNYStartOfDay(startDate), timeHelpers.getNYEndOfDay(endDate))
  }, [fetchTopLandingsReport, startDate, endDate])

  const handleSubmit = () => {
    fetchTopLandingsReport(timeHelpers.getNYStartOfDay(startDate), timeHelpers.getNYEndOfDay(endDate))
  }

  const onDateChange = (dates: RangeValue<Moment>) => {
    if (dates && dates[0] && dates[1]) {
      setStartDate(dates[0])
      setEndDate(dates[1])
    }
  }

  const columns = [
    {
      title: 'Number',
      render: (text, record, index) => index + 1,
      key: 'number'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Orders Count',
      dataIndex: 'ordersCount',
      key: 'ordersCount'
    },
    {
      title: 'Total',
      render: record => `${numeral(record.total / 100).format('$0,0.00')}`,
      key: 'total'
    }
  ]

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title='Top Landings Report'
          extra={[
            <DatePicker.RangePicker
              key='2'
              defaultValue={[startDate, endDate]}
              format='MM/DD/YYYY'
              ranges={{
                Today: [
                  moment().startOf('day'),
                  moment()
                    .add(1, 'day')
                    .endOf('day')
                ],
                'Last 7 days': [moment().subtract(7, 'days'), moment().startOf('day')],
                'This Week': [moment().startOf('week'), moment().endOf('week')],
                'This Month': [moment().startOf('month'), moment().endOf('month')]
              }}
              onChange={onDateChange}
            />,
            <Button key='3' type='primary' icon={<CheckOutlined />} onClick={handleSubmit} loading={isLoading}>
              Go
            </Button>
          ]}
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Reports', url: '/' },
            { title: 'Top Landings', url: '/' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <Table
          columns={columns}
          dataSource={topLandingsReport}
          bordered
          rowKey={record => record.id}
          pagination={false}
          loading={isLoading}
          summary={pageData => {
            let totalTotal = 0

            pageData.forEach(({ total }) => {
              totalTotal += total
            })

            return (
              <>
                <Table.Summary.Row className={styles.totalRow}>
                  <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={1} />
                  <Table.Summary.Cell index={2} />
                  <Table.Summary.Cell index={3}>
                    <Typography>{`${numeral(totalTotal / 100).format('$0,0.00')}`}</Typography>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            )
          }}
        />
      </Layout.Content>
    </Layout>
  )
}

export default connector(TopLandingsReport)
