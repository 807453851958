import axios from 'utils/axios'
import { useQuery } from 'react-query'
import { AssetDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'asset', AssetDto>

// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchAsset = (assetId: number) => Promise<AssetDto>

const fetchAsset: FetchAsset = async (assetId) => {
  if (!assetId) return null

  try {
    const FETCH_ASSET = `
    query fetchAsset($assetId: Int!) {
      asset(assetId: $assetId) {
        id
        name
        extension
        ownerType
        ownerId
        mimeType
        s3bucket
        s3key
        signedUrl
        createdAt
        updatedAt
      }
    }
  `
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_ASSET,
      variables: { assetId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { asset } = data
    if (!asset) {
      throw new Error("Can't get asset!")
    }

    return asset
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useAsset = (assetId: number) => useQuery(['asset', assetId], () => fetchAsset(assetId))
