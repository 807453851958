import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

const FETCH_CAMPAIGNS_FOR_OPTIONS = `
  query fetchCampaignsForOptions($filters: CampaignsFilters!) {
    campaignsForOptions(filters: $filters) {
      id
      name
    }
  }
`

const fetchCampaignsForOptions = (isAllCampaigns = false) => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_CAMPAIGNS_FOR_OPTIONS
  })

  try {
    const workspaceId = getState()?.workspaces?.activeWorkspace?.id

    const {
      data: {
        data: { campaignsForOptions },
        errors
      }
    } = await axios.post('/graphql', {
      query: FETCH_CAMPAIGNS_FOR_OPTIONS,
      variables: { filters: { workspaceId, limit: 1024 } }
    })

    guardFromErrors(errors)

    if (!campaignsForOptions) {
      throw new Error("Can't fetch campaigns!")
    }

    const campaigns = isAllCampaigns ? [{ id: -2, name: 'All Campaigns' }, ...campaignsForOptions] : campaignsForOptions

    dispatch({
      type: t.FETCH_CAMPAIGNS_FOR_OPTIONS,
      payload: campaigns.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)),
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_CAMPAIGNS_FOR_OPTIONS,
      payload: extractErrorInfo(err),
      meta: { error: true }
    })
  }
}

export default fetchCampaignsForOptions
