import axios from 'utils/axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { queryClient } from 'queryClient'

type InviteStoreAdminArgs = {
  storeId: number
  email: string
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'inviteStoreAdmin', boolean>

const INVITE_STORE_ADMIN = `
mutation inviteStoreAdmin ($storeId: Int!, $email: String!) {
  inviteStoreAdmin (storeId: $storeId, email: $email)
}
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type AssignStore = (args: InviteStoreAdminArgs) => Promise<InviteStoreAdminArgs>

const assignStoreOwner: AssignStore = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: INVITE_STORE_ADMIN,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { inviteStoreAdmin } = data
    if (!inviteStoreAdmin) {
      throw new Error("Can't invite store admin!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

const inviteStoreAdminEvents: UseMutationOptions<InviteStoreAdminArgs, unknown, InviteStoreAdminArgs> = {
  retry: false,
  onSuccess: () => queryClient.invalidateQueries(['storeInvitesPage'])
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useInviteStoreAdmin = () => useMutation(assignStoreOwner, inviteStoreAdminEvents)
