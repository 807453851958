import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { RegionForOptions } from '../types'

type RegionsForOptionsSelector = (state: ReduxState) => RegionForOptions[]
const getRegionsForOptions: RegionsForOptionsSelector = state =>
  state.regions.regionsForOptions || ([] as RegionForOptions[])

export const regionsForOptionsSelector: RegionsForOptionsSelector = createSelector(
  getRegionsForOptions,
  regions => regions
)
