import { createSelector, Selector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { ProductSizesState } from '../types'

const getFeatureState: Selector<ReduxState, ProductSizesState> = state => state.productSizes

export const featureStateSelector = createSelector<ReduxState, ProductSizesState, ProductSizesState>(
  getFeatureState,
  state => state
)

export default featureStateSelector
