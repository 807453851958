import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { SalesFunnelAnalyze } from '../types'

type Args = {
  templateId?: number
  landingId?: number
}

export type FetchSalesFunnelAnalyzeReportAction = FSA<undefined, SalesFunnelAnalyze, string>
type FetchSalesFunnelAnalyzeReports = (args: Args) => MrxThunk<FetchSalesFunnelAnalyzeReportAction>
type QueryResponse = GraphQLResponse<'salesFunnelAnalyze', SalesFunnelAnalyze>

const FETCH_SALES_FUNNEL_ANALYZE_REPORT = `
  query fetchSalesFunnelAnalyze($templateId: Int, $landingId:ID, $workspaceId: Int) {
    salesFunnelAnalyze(workspaceId: $workspaceId, landingId: $landingId, templateId: $templateId) 
  }
`

const fetchSalesFunnelAnalyzeReport: FetchSalesFunnelAnalyzeReports =
  ({ landingId, templateId }) =>
  async (dispatch, getState) => {
    dispatch({
      type: t.FETCH_SALES_FUNNEL_ANALYZE_REPORT,
      meta: { done: false }
    })

    try {
      const {
        workspaces: {
          activeWorkspace: { id: workspaceId }
        }
      } = getState()
      const {
        data: { data, errors }
      }: QueryResponse = await axios.post('/graphql', {
        query: FETCH_SALES_FUNNEL_ANALYZE_REPORT,
        variables: {
          workspaceId,
          landingId,
          templateId
        }
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { salesFunnelAnalyze } = data
      if (!salesFunnelAnalyze) {
        throw new Error("Can't get Sales report!")
      }

      dispatch({
        type: t.FETCH_SALES_FUNNEL_ANALYZE_REPORT,
        payload: salesFunnelAnalyze,
        meta: { done: true }
      })
    } catch (err) {
      dispatch({
        type: t.FETCH_SALES_FUNNEL_ANALYZE_REPORT,
        payload: extractErrorInfo(err),
        error: true
      })
    }
  }

export { fetchSalesFunnelAnalyzeReport }
