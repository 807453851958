import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { DTGSize } from '../types'

type DTGSizesList = {
  items: DTGSize[]
  total: number
}

type Payload = DTGSizesList & {
  page: number
}

export type FetchDTGSizesListAction = FSA<undefined, Payload, string>
type FetchDTGSizesList = () => MrxThunk<FetchDTGSizesListAction>
type QueryResponse = GraphQLResponse<'DTGSizesListPage', DTGSizesList>

const FETCH_DTG_SIZE_LIST = `
  query DTGSizesListPage($page: Int, $size: Int) {
    DTGSizesListPage(page: $page, size: $size) {
      items {
        id
        name
        DTGProduct {
          name
        }
        size
        DTGSizeId
        createdAt
        updatedAt
      }
      total
    }
  }
`

const fetchDTGSizesList: FetchDTGSizesList = () => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_DTG_SIZE_LIST,
    meta: { done: false }
  })

  try {
    const {
      DTGSizes: {
        pagination: { size, currentPage: page }
      }
    } = getState()

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_DTG_SIZE_LIST,
      variables: { page, size }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { DTGSizesListPage } = data
    if (!DTGSizesListPage) {
      throw new Error("Can't get DTGSize list page!")
    }

    dispatch({
      type: t.FETCH_DTG_SIZE_LIST,
      payload: {
        items: DTGSizesListPage.items,
        total: DTGSizesListPage.total,
        page
      },
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_DTG_SIZE_LIST,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchDTGSizesList }
