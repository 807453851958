import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { CreateShippingRuleActionDto, ShippingRuleActionDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'createShippingRuleAction', ShippingRuleActionDto>

const CREATE_SHIPPING_RULE_ACTION = `
  mutation createShippingRuleAction ($shippingRuleActionData: ShippingRuleActionInput!) {
    createShippingRuleAction(shippingRuleActionData: $shippingRuleActionData) {
      id
      name
      isActive
      campaignId
      actionType
      actionPayload
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type CreateShippingRuleAction = (shippingRuleActionData: CreateShippingRuleActionDto) => Promise<ShippingRuleActionDto>

const createShippingRuleAction: CreateShippingRuleAction = async shippingRuleActionData => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_SHIPPING_RULE_ACTION,
      variables: { shippingRuleActionData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createShippingRuleAction } = data
    if (!createShippingRuleAction) {
      throw new Error("Can't create shippingRuleAction!")
    }

    return createShippingRuleAction
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type CreateShippingRuleEvents = {
  onSettled: (data?: ShippingRuleActionDto) => void
}

const createShippingRuleActionEvents: CreateShippingRuleEvents = {
  onSettled: shippingRule => {
    if (shippingRule?.id) {
      queryClient.invalidateQueries(['shippingRuleActionsList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCreateShippingRuleAction = () => useMutation(createShippingRuleAction, createShippingRuleActionEvents)

