import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { SimpleTextSetting } from '../types'

type SimpleTextSettingInput = {
  name: string
  apiToken: string
}

export type CreateSimpleTextSettingAction = FSA<undefined, SimpleTextSetting, string>
type CreateSimpleTextSetting = (simpleTextSettingData: SimpleTextSettingInput) => MrxThunk<CreateSimpleTextSettingAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'createSimpleTextSetting', SimpleTextSetting>

const CREATE_SIMPLE_TEXT_SETTING = `
  mutation createSimpleTextSetting ($workspaceId: Int!, $simpleTextSettingData: SimpleTextSettingInput!) {
    createSimpleTextSetting (workspaceId: $workspaceId, simpleTextSettingData: $simpleTextSettingData) {
      id
      name
      apiToken
    }
  }
`

const createSimpleTextSetting: CreateSimpleTextSetting = (simpleTextSettingData) => async (dispatch, getState) => {
  let isSaved = false

  dispatch({
    type: t.CREATE_SIMPLE_TEXT_SETTING,
    meta: { done: false }
  })

  try {
    const workspaceId = getState()?.workspaces?.activeWorkspace?.id
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_SIMPLE_TEXT_SETTING,
      variables: { workspaceId, simpleTextSettingData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createSimpleTextSetting } = data
    if (!createSimpleTextSetting) {
      throw new Error("Can't create Simple Text Setting!")
    }

    dispatch({
      type: t.CREATE_SIMPLE_TEXT_SETTING,
      payload: createSimpleTextSetting,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.CREATE_SIMPLE_TEXT_SETTING,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { createSimpleTextSetting }
