import { PayPalSettingsDto } from '@merchx-v2/shared-types/dist/dto/paypalSettings/paypal-settings.dto'
import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type PayPalSettingSelector = (state: ReduxState, payPalSettingId?: number) => PayPalSettingsDto | undefined

const getPayPalSetting: PayPalSettingSelector = (state, payPalSettingId) => {
  let result
  if (payPalSettingId && Object.prototype.hasOwnProperty.call(state.payPalSettings.payPalSettings, payPalSettingId)) {
    result = state.payPalSettings.payPalSettings[payPalSettingId] as PayPalSettingsDto
  }
  return result
}

export const payPalSettingSelector: PayPalSettingSelector = createSelector(getPayPalSetting, (payPalSetting) => payPalSetting)
