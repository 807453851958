import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { AuthorizeSetting } from '../types'

type AuthorizeSettingsList = {
  items: AuthorizeSetting[]
  total: number
}

type Payload = AuthorizeSettingsList & {
  page: number
}

export type FetchAuthorizeSettingsListAction = FSA<undefined, Payload, string>
type FetchAuthorizeSettingsList = () => MrxThunk<FetchAuthorizeSettingsListAction>
type QueryResponse = GraphQLResponse<'authorizeSettingsListPage', AuthorizeSettingsList>

const FETCH_AUTHORIZE_SETTINGS_LIST = `
  query fetchAuthorizeSettingsList ($page: Int, $size: Int, $filters: AuthorizeSettingsFilters!) {
    authorizeSettingsListPage (page: $page, size: $size, filters: $filters) {
      items {
        id
        name
        apiGateway
        apiLoginId
        transactionKey
        clientKey
        signatureKey
        createdAt
        updatedAt
      }
      total
    }
  }
`

const fetchAuthorizeSettingsList: FetchAuthorizeSettingsList = () => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_AUTHORIZE_SETTINGS_LIST,
    meta: { done: false }
  })

  try {
    const {
      workspaces: {
        activeWorkspace: { id: workspaceId }
      },
      authorizeSettings: {
        pagination: { currentPage: page, size }
      }
    } = getState()

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_AUTHORIZE_SETTINGS_LIST,
      variables: { page, size, filters: { workspaceId } }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { authorizeSettingsListPage } = data
    if (!authorizeSettingsListPage) {
      throw new Error("Can't get Authorize Settings list page!")
    }

    dispatch({
      type: t.FETCH_AUTHORIZE_SETTINGS_LIST,
      payload: {
        page,
        total: authorizeSettingsListPage.total,
        items: authorizeSettingsListPage.items
      },
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_AUTHORIZE_SETTINGS_LIST,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchAuthorizeSettingsList }
