import { ProductPriceOwner, ProductPriceInputV2Dto, PublicProductVariantValuesV2Dto } from '@merchx-v2/shared-types'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import * as eternalListsFeature from 'features/eternalLists'
import { Product } from 'features/types'
import * as t from '../actionTypes'
import { GraphQLProductInput } from '../types'

type Payload = {
  list: 'products'
  index: number
  item: GraphQLProductInput
  filters: {
    campaignId: number
    productId: number
  }
}

type Args = {
  campaignId: number
  productId: number
  productData: GraphQLProductInput
  productPriceOwnerType: ProductPriceOwner
  productPriceOwnerId: number
  productPricesData: ProductPriceInputV2Dto[]
  variantValues: PublicProductVariantValuesV2Dto[]
  disabledVariantKeys: string[]
  removedVariantKeys: string[]
  mainPriceVariant?: string
  index?: number
}

export type UpdateProductV2Action = FSA<undefined, Payload, string>

type UpdateProductV2 = (args: Args) => MrxThunk<eternalListsFeature.actions.UpdateEternalItemAction<'products'>, Promise<boolean>>

type QueryResponse = GraphQLResponse<'updateProductV2', boolean>

const UPDATE_PRODUCT = `
  mutation UpdateProductV2($productId: Int!, $productData: ProductInput!, $productPriceOwnerType: ProductPriceOwner!, $productPriceOwnerId: Int!, $productPricesData: [ProductPriceInputV2]!, $variantValues: [ProductVariantValuesV2Input], $disabledVariantKeys: [String], $removedVariantKeys: [String], $mainPriceVariant: String) {
    updateProductV2(productId: $productId, productData: $productData, productPriceOwnerType: $productPriceOwnerType, productPriceOwnerId: $productPriceOwnerId, productPricesData: $productPricesData, variantValues: $variantValues, disabledVariantKeys: $disabledVariantKeys, removedVariantKeys: $removedVariantKeys, mainPriceVariant: $mainPriceVariant)
  }
`

const updateProductV2: UpdateProductV2 = (args) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.UPDATE_PRODUCT,
    meta: { done: false }
  })

  try {
    const {
      productId,
      productData,
      productPriceOwnerType,
      productPriceOwnerId,
      productPricesData,
      index,
      campaignId,
      variantValues,
      disabledVariantKeys,
      removedVariantKeys,
      mainPriceVariant
    } = args

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_PRODUCT,
      variables: {
        productId,
        productData,
        productPriceOwnerType,
        productPriceOwnerId,
        productPricesData,
        variantValues,
        disabledVariantKeys,
        removedVariantKeys,
        mainPriceVariant
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateProductV2 } = data
    if (!updateProductV2) {
      throw new Error("Can't update product v2!")
    }

    if (index) {
      dispatch({
        type: t.UPDATE_PRODUCT,
        payload: {
          list: 'products',
          index,
          filters: { campaignId, productId },
          item: { id: productId, ...productData } as Product
        },
        meta: { done: true }
      })
    }
    dispatch({
      type: eternalListsFeature.actionTypes.UPDATE_ETERNAL_LIST_ITEM,
      payload: {
        list: 'products',
        index,
        filters: { campaignId, productId },
        item: { id: productId, ...productData } as Product
      },
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.UPDATE_PRODUCT,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { updateProductV2 }
