import { createSelector, ParametricSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { ProductImage } from '../types'

const getProductImage: ParametricSelector<ReduxState, string | undefined, ProductImage | undefined> = (
  state,
  productId,
  productImageId
) => {
  let result
  if (productId && Object.prototype.hasOwnProperty.call(state.productImages.productImages, productId)) {
    if (
      productImageId &&
      Object.prototype.hasOwnProperty.call(state.productImages.productImages[productId], productImageId)
    ) {
      result = state.productImages.productImages[productId][productImageId] as ProductImage
    }
  }

  return result
}

export const productImageSelector = createSelector(getProductImage, productImage => productImage)
