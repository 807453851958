// TODO - remove becouse of legacy

import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Task } from 'features/tasksQueue/types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'archiveLanding', Task>

const ARCHIVE_LANDING = `
  mutation ArchiveLanding($landingId: Int!) {
    archiveLanding(landingId: $landingId) {
      id
      workspaceId
      status
      taskType
      payload
      priority
      progress
      retryNumber
      message
      startedAt
      endedAt
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type ArchiveLanding = (landingId: number) => Promise<Task>

const archiveLanding: ArchiveLanding = async (landingId) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: ARCHIVE_LANDING,
      variables: { landingId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { archiveLanding } = data
    if (!archiveLanding) {
      throw new Error("Can't archive landing!")
    }

    return archiveLanding
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type ArchiveLandingEvents = {
  onSettled: (data?: Task) => void
}

const archiveLandingEvents: ArchiveLandingEvents = {
  onSettled: (data) => {
    if (data?.id) {
      queryClient.invalidateQueries(['task', data.id])
      queryClient.invalidateQueries(['tasksList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useArchiveLanding = () => useMutation(archiveLanding, archiveLandingEvents)
