import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { Alert } from '../types'

type AlertsListSelector = (state: ReduxState) => Alert[]
const getAlertsList: AlertsListSelector = state => {
  const result = [] as Alert[]
  const pageIds = state.alerts.pagination.pages[state.alerts.pagination.currentPage] || []
  pageIds.forEach(pageId => {
    result.push(state.alerts.alerts[pageId])
  })
  return result
}

export const alertsListSelector: AlertsListSelector = createSelector(getAlertsList, list => list)
