import React, { useState, useEffect, useRef } from 'react'
import { PlusOutlined, CloseCircleTwoTone } from '@ant-design/icons'
import { Button, Drawer, Input, InputNumber, Select, Form, Switch, Row, Col, Typography } from 'antd'
import notification from 'mrx-notification'
import { useCreateSetting } from '../../hooks'
import styles from './CreateSetting.module.scss'
import { connector, PropsFromRedux } from './container'

const CreateSetting = (props: PropsFromRedux) => {
  const { ownerType, ownerId, user } = props

  const [isVisible, setVisible] = useState(false)
  const [settingType, setSettingType] = useState('')
  const refEl = useRef<Input>(null)

  const [form] = Form.useForm()

  const createSetting = useCreateSetting()

  useEffect(() => {
    if (isVisible) {
      refEl.current && refEl.current.focus()
    }
  }, [isVisible])

  const showDrawer = () => {
    setVisible(!isVisible)
  }

  const onValuesChangeHandler = (changedValues: any) => {
    if (Object.prototype.hasOwnProperty.call(changedValues, 'type')) {
      setSettingType(changedValues.type)
    }
  }

  const handleClose = () => {
    setVisible(false)
    setSettingType('')
    form.resetFields()
  }

  // TODO - fix any type
  const onFinishHandler = async (values: any) => {
    let value = ''

    switch (values.type) {
      case 'SELECT': {
        value = JSON.stringify(values.select || [])
        break
      }
      case 'LANDING_PRODUCT':
      case 'PRODUCT': {
        value = ''
        break
      }
      case 'BOOLEAN': {
        value = values.value.toString()
        break
      }
      default: {
        value = values.value ? values.value.toString() : ''
      }
    }

    const filters =
      values.filters &&
      values.filters.map((item) => {
        return item.key
      })

    createSetting.mutate(
      {
        ownerType,
        ownerId,
        settingData: {
          name: values.name,
          alias: values.alias,
          type: values.type,
          value,
          required: values.required,
          filters: filters
        }
      },
      {
        onSuccess: () => {
          notification.success({
            message: 'Successfully',
            description: 'Setting was created successfully!'
          })
          handleClose()
        },
        onError: (error) => {
          notification.error({
            message: 'Error!',
            description: error.toString()
          })
        }
      }
    )
  }

  const renderInputForm = () => {
    switch (settingType) {
      case 'BOOLEAN': {
        return (
          <Form.Item name='value' label='Value' valuePropName='checked' initialValue>
            <Switch checkedChildren='true' unCheckedChildren='false' defaultChecked />
          </Form.Item>
        )
      }
      case 'INTEGER':
      case 'FLOAT': {
        return (
          <Form.Item name='value' label='Value' rules={[{ required: true, message: 'Please Input Setting Value' }]} initialValue='0'>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        )
      }
      case 'STRING': {
        return (
          <Form.Item name='value' label='Value' rules={[{ message: 'Please Input Setting Value' }]}>
            <Input style={{ width: '100%' }} />
          </Form.Item>
        )
      }

      case 'PRODUCTS_LIST':
      case 'PRODUCT': {
        return (
          <>
            <Form.List name='filters'>
              {(fields, { add, remove }) => {
                return (
                  <div>
                    <Typography style={{ color: 'black', marginBottom: '8px' }}>Filters</Typography>
                    {fields.map((field) => (
                      <Row key={field.key} className={styles.rowContainer}>
                        <Form.Item
                          name={[field.name, 'key']}
                          fieldKey={field.key}
                          style={{ width: '100%' }}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Please select product or delete this field.'
                            }
                          ]}
                        >
                          <Select style={{ width: '100%' }} placeholder='Please select'>
                            <Select.Option value='TSHIRT'>T-Shirt</Select.Option>
                            <Select.Option value='CREW'>Crew</Select.Option>
                            <Select.Option value='FACE_MASK'>Face Mask</Select.Option>
                            <Select.Option value='STICKER'>Sticker</Select.Option>
                            <Select.Option value='TOTEBAG'>Tote Bag</Select.Option>
                          </Select>
                        </Form.Item>
                        <CloseCircleTwoTone
                          twoToneColor='tomato'
                          className={styles.deleteButton}
                          onClick={() => {
                            remove(field.name)
                          }}
                        />
                      </Row>
                    ))}
                    <Form.Item>
                      <Button
                        type='dashed'
                        onClick={() => {
                          add()
                        }}
                      >
                        <PlusOutlined /> Add filter
                      </Button>
                    </Form.Item>
                  </div>
                )
              }}
            </Form.List>
          </>
        )
      }

      case 'SELECT_ARRAY':
      case 'SELECT': {
        return (
          <Form.List name='select'>
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field) => (
                    <Row key={field.key} className={styles.rowContainer}>
                      <Col>
                        <Form.Item
                          name={[field.name, 'key']}
                          fieldKey={field.key}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Please input setting key/value or delete this field.'
                            }
                          ]}
                        >
                          <Input style={{ width: '98%' }} placeholder='KEY' />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          name={[field.name, 'value']}
                          fieldKey={field.key}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Please input setting key/value or delete this field.'
                            }
                          ]}
                        >
                          <Input style={{ width: '98%' }} placeholder='VALUE' />
                        </Form.Item>
                      </Col>
                      <Col>
                        <CloseCircleTwoTone
                          twoToneColor='tomato'
                          className={styles['dynamic-delete-button']}
                          onClick={() => {
                            remove(field.name)
                          }}
                        />
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button
                      type='dashed'
                      onClick={() => {
                        add()
                      }}
                      style={{ width: '40%' }}
                    >
                      <PlusOutlined /> Add field
                    </Button>
                  </Form.Item>
                </div>
              )
            }}
          </Form.List>
        )
      }
    }
  }

  return (
    <>
      <Button onClick={showDrawer} type='primary' className={styles.createButton}>
        <PlusOutlined />
        CREATE SETTING
      </Button>
      <Drawer title='Create Setting' width='400' onClose={handleClose} visible={isVisible} forceRender>
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} onValuesChange={onValuesChangeHandler} form={form}>
          <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input Setting Name!' }]}>
            <Input ref={refEl} />
          </Form.Item>
          <Form.Item name='alias' label='Alias' rules={[{ required: true, message: 'Please input Setting Alias!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name='type' label='Type' rules={[{ required: true, message: 'Please select Setting Type!' }]}>
            <Select placeholder='Please select Setting Type'>
              <Select.Option value='BOOLEAN'>Boolean</Select.Option>
              <Select.Option value='INTEGER'>Integer</Select.Option>
              <Select.Option value='IMAGE'>Image</Select.Option>
              <Select.Option value='VIDEO'>Video</Select.Option>
              <Select.Option value='FLOAT'>Float</Select.Option>
              <Select.Option value='STRING'>String</Select.Option>
              <Select.Option value='SELECT'>Select</Select.Option>
              <Select.Option value='SELECT_ARRAY'>Select Array</Select.Option>
              <Select.Option value='PRODUCT'>Product</Select.Option>
              <Select.Option value='PRODUCTS_LIST'>Products list</Select.Option>
              <Select.Option value='TESTIMONIALS'>Testimonials</Select.Option>
              {user?.roles.includes('SYSTEM_ADMIN') && (
                <>
                  <Select.Option value='QUIZ'>Quiz</Select.Option>
                  <Select.Option value='TRIVIA_QUIZ'>Trivia Quiz</Select.Option>
                </>
              )}
            </Select>
          </Form.Item>
          <Form.Item name='required' label='Required' valuePropName='checked' initialValue>
            <Switch checkedChildren='true' unCheckedChildren='false' />
          </Form.Item>
          {renderInputForm()}
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button onClick={handleClose} className={styles.closeButton}>
              Close
            </Button>
            <Button type='primary' htmlType='submit' loading={createSetting.isLoading}>
              Create
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

export default connector(CreateSetting)
