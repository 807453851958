import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

type Payload = {
  productTagId: number
  name: string
}

export type UpdateProductTagAction = FSA<undefined, Payload, string>

type UpdateProductTag = (args: Payload) => MrxThunk<UpdateProductTagAction, Promise<boolean>>

type QueryResponse = GraphQLResponse<'updateProductTag', boolean>

const UPDATE_PRODUCT_TAG = `
  mutation updateProductTag($productTagId: Int!, $name: String!) {
    updateProductTag(productTagId: $productTagId, name: $name)
  }
`

const updateProductTag: UpdateProductTag = (args) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.UPDATE_PRODUCT_TAG,
    meta: { done: false }
  })

  try {
    const { productTagId, name } = args
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_PRODUCT_TAG,
      variables: { productTagId, name }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateProductTag } = data
    if (!updateProductTag) {
      throw new Error("Can't update product tag!")
    }

    dispatch({
      type: t.UPDATE_PRODUCT_TAG,
      payload: args,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.UPDATE_PRODUCT_TAG,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { updateProductTag }
