import React, { useEffect } from 'react'
import { Modal, Form, Input, Select } from 'antd'
import notification from 'mrx-notification'
import { useDecreaseBalance } from 'features/stores/hooks'
import { STORE_PROFIT_EVENTS } from '@merchx-v2/shared-types'

type Props = {
  payload?: string
  storeId: number
  sum?: number
  visibleModal: boolean
  onCancel: () => void
}

const RefundPaymentModal = (props: Props) => {
  const { storeId, payload = '', sum = 0, visibleModal, onCancel } = props
  const [form] = Form.useForm()

  const decreaseBalance = useDecreaseBalance()

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload, sum, storeId])

  useEffect(() => {
    if (decreaseBalance.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Store Balance was decreased successfully!'
      })
      onCancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decreaseBalance.isSuccess])

  useEffect(() => {
    if (decreaseBalance.error) {
      notification.error({
        message: 'Error',
        description: decreaseBalance.error.toString()
      })
    }
  }, [decreaseBalance.error])

  const handleOk = async () => {
    const { note, sum, event, payload } = await form.validateFields()

    decreaseBalance.mutate({
      storeId,
      sum: Math.round(parseFloat(sum) * 100),
      note,
      event,
      payload
    })
  }

  return (
    <Modal
      visible={visibleModal}
      title='Decrease Balance'
      onOk={handleOk}
      okButtonProps={{
        disabled: decreaseBalance.isLoading,
        loading: decreaseBalance.isLoading
      }}
      onCancel={onCancel}
    >
      <Form layout='vertical' form={form} hideRequiredMark initialValues={{ payload, sum: (sum / 100).toFixed(2) }}>
        <Form.Item name='sum' label='Amount' rules={[{ required: true, message: 'Please input Amount!' }]}>
          <Input type='number' />
        </Form.Item>
        <Form.Item name='note' label='Comment' rules={[{ required: true, message: 'Please input Commentary!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name='event' label='Event' rules={[{ required: true, message: 'Please select Event type!' }]}>
          <Select>
            <Select.Option value={STORE_PROFIT_EVENTS.ORDER_CHARGEBACK}>Order Chargeback</Select.Option>
            <Select.Option value={STORE_PROFIT_EVENTS.ORDER_REFUNDED}>Order Refunded</Select.Option>
            <Select.Option value={STORE_PROFIT_EVENTS.PAYOUT_SENT}>Payout Sent</Select.Option>
            <Select.Option value={STORE_PROFIT_EVENTS.MANUAL_DECREASED}>Other Reason</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name='payload' label='Payload (Change it only if you know what to do)'>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default RefundPaymentModal
