import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { TopLanding } from '../types'
import { sortByMultipleKeys } from 'utils/sorting'

type TopLandingsReportSelector = (state: ReduxState) => TopLanding[]

const getTopLandings: TopLandingsReportSelector = state => {
  let result = [] as TopLanding[]

  if (state.reports.topLandings) {
    result = Object.values(state.reports.topLandings) as TopLanding[]
    result.sort(sortByMultipleKeys('-total'))
  }

  return result
}

export const topLandingsReportSelector: TopLandingsReportSelector = createSelector(
  getTopLandings,
  topLandings => topLandings
)
