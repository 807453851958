import produce from 'immer'
import * as t from './actionTypes'
import { actionTypes as nt } from 'features/navigation'
import { actionTypes as wt } from 'features/workspaces'
import { isSuccessAction, isErrorAction } from 'types'
import { RegionsState } from './types'
import createReducer from 'store/createReducer'
import { addItemToPagination } from 'utils/addItemToPagination'
import { removeItemFromPagination } from 'utils/removeItemFromPagination'
import {
  ChangeCurrentPageAction,
  CreateRegionAction,
  FetchRegionAction,
  FetchRegionsForOptionsAction,
  FetchRegionsListAction,
  RemoveRegionAction,
  UpdateRegionAction
} from './actions'

const initState: RegionsState = {
  regions: {},
  regionsForOptions: [],
  pagination: {},
  UIState: {
    isCreateRegion: false,
    isFetchRegion: false,
    isListLoading: false,
    isRegionsForOptionsLoading: false,
    isRemoveRegion: false,
    isUpdateRegion: false
  }
}

const setCountryDefaults = (draft: RegionsState, countryId: number) => {
  if (!draft.regions[countryId]) {
    draft.regions[countryId] = {}
  }

  if (!draft.pagination[countryId]) {
    draft.pagination[countryId] = {
      pages: {},
      total: 0,
      currentPage: 1,
      size: 4
    }
  }
}
const reducer = createReducer(initState, {
  [t.CREATE_REGION]: (state, action: CreateRegionAction) =>
    produce(state, (draft) => {
      draft.UIState.isCreateRegion = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isCreateRegion = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { countryId, region } = action.payload
        setCountryDefaults(draft, countryId)
        draft.regions[countryId][region.id] = region
        addItemToPagination(draft.pagination[countryId], region.id)
      }
    }),

  [t.FETCH_REGIONS_LIST]: (state, action: FetchRegionsListAction) =>
    produce(state, (draft) => {
      draft.UIState.isListLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isListLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { countryId, items, total, page } = action.payload
        setCountryDefaults(draft, countryId)
        items.forEach((item) => {
          draft.regions[countryId][item.id] = item
        })
        draft.pagination[countryId].total = total
        draft.pagination[countryId].pages[page] = items.map((item) => item.id)
      }
    }),

  [t.FETCH_REGION]: (state, action: FetchRegionAction) =>
    produce(state, (draft) => {
      draft.UIState.isFetchRegion = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isFetchRegion = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { countryId, region } = action.payload
        setCountryDefaults(draft, countryId)
        draft.regions[countryId][region.id] = region
      }
    }),

  [t.FETCH_REGIONS_FOR_OPTIONS]: (state, action: FetchRegionsForOptionsAction) =>
    produce(state, (draft) => {
      draft.UIState.isRegionsForOptionsLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isRegionsForOptionsLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.regionsForOptions = action.payload
      }
    }),

  [t.REMOVE_REGION]: (state, action: RemoveRegionAction) =>
    produce(state, (draft) => {
      draft.UIState.isRemoveRegion = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isRemoveRegion = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { countryId, regionId } = action.payload
        setCountryDefaults(draft, countryId)
        if (draft.regions[countryId]) {
          if (draft.regions[countryId][regionId]) {
            delete draft.regions[countryId][regionId]
            removeItemFromPagination(draft.pagination[countryId], regionId)
          }
        }
      }
    }),

  [t.UPDATE_REGION]: (state, action: UpdateRegionAction) =>
    produce(state, (draft) => {
      draft.UIState.isUpdateRegion = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isUpdateRegion = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { countryId, regionId, regionData } = action.payload
        if (draft.regions[regionId]) {
          draft.regions[countryId][regionId].countryId = regionData.countryId
          draft.regions[countryId][regionId].name = regionData.name
          draft.regions[countryId][regionId].iso2 = regionData.iso2
        }
      }
    }),

  [t.CHANGE_CURRENT_PAGE]: (state, action: ChangeCurrentPageAction) =>
    produce(state, (draft) => {
      if (action.payload) {
        const { countryId, page } = action.payload
        draft.pagination[countryId].currentPage = page
      }
    }),

  [wt.SWITCH_WORKSPACE]: (state) =>
    produce(state, (draft) => {
      draft.pagination = initState.pagination
    }),

  [nt.LOCATION_CHANGE]: (state) =>
    produce(state, (draft) => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default reducer
