import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

type Payload = {
  productId: number
  categoryIds: [number]
}

export type SetProfileCategoriesAction = FSA<undefined, Payload, string>

type SetProfileCategories = (args: Payload) => MrxThunk<SetProfileCategoriesAction, Promise<boolean>>

type QueryResponse = GraphQLResponse<'setProductCategories', boolean>

const SET_PRODUCT_CATEGORIES = `
  mutation setProductCategories($productId: Int!, $categoryIds: [Int]!) {
    setProductCategories(productId: $productId, categoryIds: $categoryIds)
  }
`

const setProductCategories: SetProfileCategories = (args) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.SET_PRODUCT_CATEGORIES,
    meta: { done: false }
  })

  try {
    const { productId, categoryIds } = args
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: SET_PRODUCT_CATEGORIES,
      variables: { productId, categoryIds }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { setProductCategories } = data
    if (!setProductCategories) {
      throw new Error("Can't set Product Categories!")
    }

    dispatch({
      type: t.SET_PRODUCT_CATEGORIES,
      payload: args,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.SET_PRODUCT_CATEGORIES,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { setProductCategories }
