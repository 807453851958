import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { AwsSetting } from '../types'

type AwsSettingSelector = (state: ReduxState, awsSettingId?: number) => AwsSetting | undefined

const getAwsSetting: AwsSettingSelector = (state, awsSettingId) => {
  let result
  if (awsSettingId && Object.prototype.hasOwnProperty.call(state.awsSettings.awsSettings, awsSettingId)) {
    result = state.awsSettings.awsSettings[awsSettingId] as AwsSetting
  }
  return result
}

export const awsSettingSelector: AwsSettingSelector = createSelector(getAwsSetting, (awsSetting) => awsSetting)
