import React, { useEffect } from 'react'
import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import '@ant-design/compatible/assets/index.css'
import { Input, Spin } from 'antd'
import * as styles from './styles'

type Props = FormComponentProps & {
  label?: string
  value?: string
  tooltip: string
  isLoading: boolean
  showReset?: boolean
  onUpdateField: (value: string) => void
  onReset?: () => Promise<void>
}

const InputFieldUpdater: React.FC<Props> = props => {
  const {
    form: { getFieldDecorator, getFieldValue, setFields, validateFields, isFieldTouched },
    label,
    value,
    tooltip,
    isLoading,
    onUpdateField,
    showReset,
    onReset
  } = props

  useEffect(() => {
    setFields({
      value: { value }
    })
  }, [value, setFields])

  const handleUpdateValue = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()
    validateFields((err, values) => {
      if (!err) {
        onUpdateField(values.value)
        setFields({ value: { value: '', touched: false } })
      }
    })
  }

  const handleResetForm = () => {
    setFields({ value: { value, touched: false } })
    onReset && onReset()
  }

  const showActions = !isLoading && isFieldTouched('value')

  return (
    <Form style={styles.formRoot} layout='vertical' hideRequiredMark onSubmit={handleUpdateValue}>
      <Form.Item label={label}>
        {getFieldDecorator('value', {
          rules: [{ required: true, message: tooltip }]
        })(
          <div style={styles.root}>
            <Input readOnly={isLoading} placeholder={tooltip} value={getFieldValue('value')} />
            {isLoading && <Spin indicator={<LoadingOutlined style={styles.loadingIcon} spin />} />}
            {(showActions || showReset) && <CloseCircleOutlined style={styles.cancelIcon} onClick={handleResetForm} />}
            {showActions && <CheckCircleOutlined style={styles.confirmIcon} onClick={handleUpdateValue} />}
          </div>
        )}
      </Form.Item>
    </Form>
  )
}

export default Form.create<Props>()(InputFieldUpdater)
