import { StripeSettingsDto } from '@merchx-v2/shared-types/dist/dto/stripeSettings/stripe-settings.dto'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import * as t from '../actionTypes'

export type FetchStripeSettingsForOptionsAction = FSA<undefined, StripeSettingsDto[], string>
type FetchStripeSettingsForOptions = (searchText?: string) => MrxThunk<FetchStripeSettingsForOptionsAction>
type QueryResponse = GraphQLResponse<'stripeSettingsForOptions', StripeSettingsDto[]>

const FETCH_STRIPE_SETTINGS_FOR_OPTIONS = `
  query fetchStripeSettingsForOptions ($filters: StripeSettingsFilters!) {
    stripeSettingsForOptions (filters: $filters) {
      id
      name
    }
  }
`

const fetchStripeSettingsForOptions: FetchStripeSettingsForOptions = (searchText) => async (dispatch) => {
  dispatch({
    type: t.FETCH_STRIPE_SETTINGS_FOR_OPTIONS,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_STRIPE_SETTINGS_FOR_OPTIONS,
      variables: { filters: { searchText } }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { stripeSettingsForOptions } = data
    if (!stripeSettingsForOptions) {
      throw new Error("Can't get stripe settings!")
    }

    dispatch({
      type: t.FETCH_STRIPE_SETTINGS_FOR_OPTIONS,
      payload: stripeSettingsForOptions,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_STRIPE_SETTINGS_FOR_OPTIONS,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchStripeSettingsForOptions }
