import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { CreateDomainDto, DomainDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'createDomain', DomainDto>

const CREATE_DOMAIN = `
  mutation createDomain ($workspaceId: Int!, $domainData: DomainInput!) {
    createDomain(workspaceId: $workspaceId, domainData: $domainData) {
      id
      name
      registrar
      registrarLogin
      registrarPassword
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type CreateDomainArgs = {
  workspaceId: number
  domainData: CreateDomainDto
}

type CreateDomain = (args: CreateDomainArgs) => Promise<DomainDto>

const createDomain: CreateDomain = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_DOMAIN,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createDomain } = data
    if (!createDomain) {
      throw new Error("Can't create domain!")
    }

    return createDomain
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type CreateDomainEvents = {
  onSettled: (data?: DomainDto) => void
}

const createDomainEvents: CreateDomainEvents = {
  onSettled: (data) => {
    if (data?.id) {
      queryClient.invalidateQueries(['domainsList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCreateDomain = () => useMutation(createDomain, createDomainEvents)
