import React, { useEffect, useState } from 'react'
import { Layout, Drawer, PageHeader, Tabs, Button, Popconfirm, Table, Input, Tag } from 'antd'
import { CheckOutlined, CloseOutlined, FormOutlined } from '@ant-design/icons'
import moment from 'moment'
import notification from 'mrx-notification'
import { Link } from 'react-router-dom'
import useCustomCompareEffect from 'use-custom-compare-effect'
import useReactRouter from 'use-react-router'
import * as rootStyles from 'assets/layoutStyle'
import { getAvailablePaymentsForRefund } from 'utils/getAvailablePaymentsForRefund'
import { trackRefund } from 'utils/trackRefund'
import { useAddCustomerTag, useRemoveCustomerTag } from 'features/customers/hooks'
import { useCancelCustomerSubscription } from 'features/customerSubscriptions/hooks'
import {
  EventsTab,
  ProductsTab,
  PaymentsTab,
  RefundPaymentModal,
  ShippingAddressTab,
  PaymentAddressTab,
  TasksTab,
  NotesTab
} from '../../components'
import { replaceOrderStatus } from '../../helpers'
import {
  useOrder,
  useHandleOrder,
  useCancelOrder,
  useRefundPayment,
  useHoldOrder,
  useUnholdOrder,
  useUpdateOrderTrackingNumber,
  useResendInvoice,
  useReleaseInvoice
} from '../../hooks'
import { connector, PropsFromRedux } from './container'
import styles from './OrderDetails.module.scss'

type Props = PropsFromRedux

const OrderDetails = (props: Props) => {
  const {
    orderId,
    user,
    source = 'orders',

    onClose
  } = props

  const { history } = useReactRouter()

  const addCustomerTag = useAddCustomerTag()
  const removeCustomerTag = useRemoveCustomerTag()
  const handleOrder = useHandleOrder()
  const holdOrder = useHoldOrder()
  const releaseOrder = useUnholdOrder()
  const refundPayment = useRefundPayment()
  const updateOrderTrackingNumber = useUpdateOrderTrackingNumber()
  const cancelCustomerSubscription = useCancelCustomerSubscription()

  const order = useOrder(orderId)
  const cancelOrder = useCancelOrder()
  const resendInvoice = useResendInvoice()
  const releaseInvoice = useReleaseInvoice()

  const [isRefundModal, setIsRefundModal] = useState(false)
  const [isPaymentSettings, setIsPaymentSettings] = useState(false)
  const [isEditTrackingNumber, setIsEditTrackingNumber] = useState(false)
  const [trackingNumber, setTrackingNumber] = useState(order?.data?.trackingNumber || '')

  useEffect(() => {
    if (releaseOrder.isError) {
      notification.error({
        message: 'Error',
        description: releaseOrder.error instanceof Error ? releaseOrder.error.message : releaseOrder.error.toString()
      })
    }

    if (releaseInvoice.isError) {
      notification.error({
        message: 'Error',
        description: releaseInvoice.error instanceof Error ? releaseInvoice.error.message : releaseInvoice.error.toString()
      })
    }

    if (holdOrder.isError) {
      notification.error({
        message: 'Error',
        description: holdOrder.error instanceof Error ? holdOrder.error.message : holdOrder.error.toString()
      })
    }

    if (cancelOrder.isError) {
      notification.error({
        message: 'Error',
        description: cancelOrder.error.toString()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releaseOrder.isError, releaseInvoice.isError, holdOrder.isError, cancelOrder.isError])

  useEffect(() => {
    if (addCustomerTag.isError) {
      notification.error({
        message: 'Error',
        description: addCustomerTag.error instanceof Error ? addCustomerTag.error.message : addCustomerTag.error.toString()
      })
    }

    if (removeCustomerTag.isError) {
      notification.error({
        message: 'Error',
        description: removeCustomerTag.error instanceof Error ? removeCustomerTag.error.message : removeCustomerTag.error.toString()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCustomerTag.isError, removeCustomerTag.isError])

  useEffect(() => {
    if (updateOrderTrackingNumber.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: `Tracking number was updated!`
      })
    }
  }, [updateOrderTrackingNumber.isSuccess])

  useEffect(() => {
    if (cancelOrder.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Order was canceled successfully!'
      })
    }
  }, [cancelOrder.isSuccess])

  useEffect(() => {
    if (resendInvoice.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Invoice was send successfully!'
      })
    }
  }, [resendInvoice.isSuccess])

  useEffect(() => {
    if (releaseInvoice.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Invoice was released successfully!'
      })
    }
  }, [releaseInvoice.isSuccess])

  useEffect(() => {
    if (cancelCustomerSubscription.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Subscription was canceled successfully!'
      })
    }
  }, [cancelCustomerSubscription.isSuccess])

  useCustomCompareEffect(
    () => {
      if (!isEditTrackingNumber) {
        setTrackingNumber(order?.data?.trackingNumber)

        // eslint-disable-next-line react-hooks/exhaustive-deps
      }
    },
    [order],
    () => {
      return order?.data?.trackingNumber === trackingNumber
    }
  )

  const handleChargebackAssign = () => {
    if (order?.data?.customer?.customerTags?.find((tag) => tag.tag === 'CHARGEBACK')) {
      removeCustomerTag.mutate({
        customerId: order.data.customerId,
        tag: 'CHARGEBACK'
      })
    } else {
      addCustomerTag.mutate({
        customerId: order.data.customerId,
        tag: 'CHARGEBACK'
      })
    }
  }

  const doConfirm = async (shouldVoid: boolean) => {
    cancelOrder.mutate({ orderId, shouldVoid })

    trackRefund({
      orderId,
      products: order?.data?.orderItems.map((item) => ({ id: item.productId, quantity: item.quantity }))
    })
  }

  const handleCancelSubscription = () => {
    cancelCustomerSubscription.mutate(order.data.customerSubscriptionId)
  }

  let columns, dataSource

  const greenButtonClickHandler = () => {
    updateOrderTrackingNumber.mutate({ orderId: orderId, trackingNumber: trackingNumber })
    setIsEditTrackingNumber(false)
  }

  const redButtonClickHandler = () => {
    setIsEditTrackingNumber(false)
  }

  const onHandleOrder = () => {
    if (!order.data) return

    handleOrder.mutate({ orderId })
  }

  const onHoldOrder = () => {
    if (!order.data) return

    if (order.data.status === 'HOLD') {
      releaseOrder.mutate({ orderId })
      return
    }

    holdOrder.mutate({ orderId })
  }

  const onResendInvoice = () => {
    if (!order.data) return

    resendInvoice.mutate({ orderId })
  }

  const onReleaseInvoice = () => {
    if (!order.data) return

    releaseInvoice.mutate({ orderId })
  }

  switch (order?.data?.landing?.paymentPlugin) {
    case 'AUTHORIZE': {
      columns = [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id'
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: 'Api gateway',
          dataIndex: 'apiGateway',
          key: 'apiGateway'
        },
        {
          title: 'Api login id',
          dataIndex: 'apiLoginId',
          key: 'apiLoginId'
        },
        {
          title: 'Transaction Key',
          dataIndex: 'transactionKey',
          key: 'transactionKey'
        },
        {
          title: 'Client key',
          dataIndex: 'clientKey',
          key: 'clientKey'
        }
      ]
      dataSource = [order?.data?.paymentSettings?.authorizeSettings]
      break
    }
    case 'STRIPE': {
      columns = [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id'
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: 'Api version',
          dataIndex: 'apiVersion',
          key: 'apiVersion'
        },
        {
          title: 'Host',
          dataIndex: 'host',
          key: 'host'
        },
        {
          title: 'Port',
          dataIndex: 'port',
          key: 'port'
        },
        {
          title: 'Secret key',
          dataIndex: 'secretKey',
          key: 'secretKey'
        },
        {
          title: 'Publishable key',
          dataIndex: 'publishableKey',
          key: 'publishableKey'
        }
      ]
      dataSource = [order?.data?.paymentSettings?.stripeSettings]
      break
    }
    case 'PAYPAL': {
      columns = [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id'
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: 'Account Email',
          dataIndex: 'accountEmail',
          key: 'accountEmail'
        },
        {
          title: 'Client ID',
          dataIndex: 'clientId',
          key: 'clientId'
        },
        {
          title: 'Secret key',
          dataIndex: 'secretKey',
          key: 'secretKey'
        },
        {
          title: 'Access Token',
          dataIndex: 'accessToken',
          key: 'accessToken'
        }
      ]
      dataSource = [order?.data?.paymentSettings?.payPalSettings]
      break
    }
    default: {
      columns = []
      dataSource = []
    }
  }

  const customerChargebackTag = order?.data?.customer?.customerTags?.find((tag) => tag.tag === 'CHARGEBACK')
  const isReachedOut = !!order?.data?.attributes.find((item) => item.attribute === 'ORDER_STATE' && item.value === 'REACHED_OUT')

  return (
    <Drawer title='Order Details' width='80%' onClose={onClose} visible={!!orderId} forceRender>
      <RefundPaymentModal
        visibleModal={isRefundModal}
        onCancel={() => setIsRefundModal(false)}
        payments={order.data?.payments}
        onFinish={() =>
          trackRefund({
            orderId,
            products: order?.data?.orderItems.map((item) => ({ id: item.productId, quantity: item.quantity }))
          })
        }
      />
      <Layout style={rootStyles.root}>
        <PageHeader
          style={{ margin: '-24px' }}
          title='Order Details'
          className={isReachedOut ? styles.reachedOutOrder : ''}
          extra={[
            <Button
              key='refund-payment'
              type='primary'
              danger
              shape='round'
              disabled={!getAvailablePaymentsForRefund(order.data?.payments).length}
              onClick={() => setIsRefundModal(true)}
              loading={refundPayment.isLoading}
            >
              Refund
            </Button>,
            <Button
              key='resend-invoice'
              type='link'
              disabled={!['PENDING'].includes(order?.data?.status) || !order?.data?.invoiceId}
              onClick={onResendInvoice}
              loading={resendInvoice.isLoading}
            >
              Resend Invoice
            </Button>,
            <Button
              key='manual-order-creation'
              type='link'
              disabled={!['NEW', 'HOLD', 'SHIPPING'].includes(order?.data?.status)}
              onClick={onHoldOrder}
              loading={holdOrder.isLoading}
            >
              {order?.data?.status === 'HOLD' ? 'Release' : 'Hold'} Order
            </Button>,

            <Popconfirm
              key='cancel-button'
              title='Are you sure want to cancel this order?'
              onConfirm={() => doConfirm(true)}
              onCancel={(e) => e?.stopPropagation()}
              okText='Yes'
              cancelText='No'
            >
              <Button loading={cancelOrder.isLoading} disabled={cancelOrder.isLoading || order?.data?.status === 'CANCELED'}>
                Void entire order
              </Button>
            </Popconfirm>,
            <Popconfirm
              key='cancel-button'
              title='Are you sure want to cancel this order without void?'
              onConfirm={() => doConfirm(false)}
              onCancel={(e) => e?.stopPropagation()}
              okText='Yes'
              cancelText='No'
            >
              <Button loading={cancelOrder.isLoading} disabled={cancelOrder.isLoading || order?.data?.status === 'CANCELED'}>
                Cancel order without void
              </Button>
            </Popconfirm>,

            source === 'invoices' ? (
              <Popconfirm
                key='release-invoice'
                title='Are you sure want to release invoice?'
                onConfirm={onReleaseInvoice}
                onCancel={(e) => e?.stopPropagation()}
                okText='Yes'
                cancelText='No'
              >
                <Button loading={releaseInvoice.isLoading} disabled={releaseInvoice.isLoading || order?.data?.status !== 'PENDING'}>
                  Release Invoice
                </Button>
              </Popconfirm>
            ) : (
              <Popconfirm
                key='cancel-subscription'
                title='Are you sure want to cancel subscription?'
                onConfirm={() => handleCancelSubscription()}
                onCancel={(e) => e?.stopPropagation()}
                okText='Yes'
                cancelText='No'
              >
                <Button
                  loading={cancelCustomerSubscription.isLoading}
                  disabled={
                    cancelCustomerSubscription.isLoading ||
                    (order?.data?.customerSubscription?.status !== 'ACTIVE' && order?.data?.customerSubscription?.status !== 'SUSPENDED')
                  }
                >
                  Cancel subscription
                </Button>
              </Popconfirm>
            ),

            <Button key='edit-order' type='primary' onClick={() => history.push(`/sales/${source}/edit/${orderId}`)}>
              Edit Order
            </Button>
          ]}
        />
        {order.data && (
          <>
            <div className={styles.listContainer}>
              <div className={styles.leftColumn}>
                <div className={styles.listItem}>
                  Order ID: {order?.data?.id}
                  <Button
                    style={{ marginLeft: '6px' }}
                    className={isReachedOut ? styles.resolveButton : styles.reachOutButton}
                    type='primary'
                    loading={handleOrder.isLoading}
                    onClick={onHandleOrder}
                  >
                    {isReachedOut ? 'Resolve' : 'Reach out'}
                  </Button>
                </div>
                <div className={styles.listItem}>
                  Customer:{' '}
                  <Link to={`/sales/${source}/${order?.data?.id}/customers/${order?.data?.customerId}`}>
                    {`${order?.data?.customer.firstName} ${order?.data?.customer.lastName}`}
                  </Link>
                  {customerChargebackTag && (
                    <Tag color='darkred' style={{ marginLeft: '10px' }}>
                      Chargeback
                    </Tag>
                  )}
                  <Button style={{ marginLeft: '6px' }} type='primary' danger onClick={handleChargebackAssign}>
                    {customerChargebackTag ? 'Unmark' : 'Mark'} as chargeback
                  </Button>
                </div>
                <div className={styles.listItem}>Date Added: {moment(order?.data?.createdAt).format('MM/DD/YYYY HH:mm:ss')}</div>
                <div className={styles.listItem}>Shipping Method: {order?.data?.landing?.fulfillmentPlugin}</div>
                <div className={styles.listItem}>
                  Sale Source: {order?.data?.saleSource}
                  {order?.data?.customSaleSource ? ` (${order.data.customSaleSource})` : ''}
                </div>
                <div className={styles.listItemTrack}>
                  Tracking Number:
                  {isEditTrackingNumber ? (
                    <Input
                      onChange={(e) => setTrackingNumber(e.target.value)}
                      style={{ width: '60%', marginLeft: '6px' }}
                      suffix={
                        <div>
                          <>
                            <CloseOutlined style={{ color: 'tomato' }} onClick={redButtonClickHandler} />
                            <CheckOutlined style={{ color: '#52c41a', marginLeft: '5px' }} onClick={greenButtonClickHandler} />
                          </>
                        </div>
                      }
                    />
                  ) : (
                    <>
                      <div style={{ marginLeft: '5px' }}>{trackingNumber}</div>
                      <span className={styles.editIcon}>
                        <FormOutlined
                          style={{ color: '#1890FF', fontSize: '20px', marginLeft: '10px' }}
                          onClick={() => setIsEditTrackingNumber(true)}
                        />
                      </span>
                    </>
                  )}
                </div>
                {order?.data?.customerSubscriptionId && (
                  <div className={styles.listItem}>
                    Subscription:{' '}
                    <Link to={`/sales/customerSubscriptions?customerSubscriptionId=${order.data.customerSubscriptionId}`}>
                      {order.data.customerSubscriptionId}
                    </Link>
                  </div>
                )}
                {order?.data?.invoiceId && <div className={styles.listItem}>Invoice ID: {order?.data?.invoiceId}</div>}
              </div>
              <div className={styles.rightColumn}>
                <div className={styles.listItem}>Status: {replaceOrderStatus(order?.data?.status)}</div>
                <div className={styles.listItem}>Email: {order?.data?.customer.email}</div>
                <div className={styles.listItem}>Phone: {order?.data?.customer.phone}</div>
                <div className={styles.listItem}>
                  Promocode: <Link to={`/promocodes/${order?.data?.promocode}`}>{order?.data?.promocode}</Link>
                </div>
                <div className={styles.listItem}>Total: {`$${(order?.data?.total / 100).toFixed(2)}`}</div>
                <div className={styles.listItem}>
                  Payment Plugin: {order?.data?.payments?.[0]?.systemType || order?.data?.landing?.paymentPlugin}
                </div>
                <div className={styles.listItem}>
                  Payment Method: {order?.data?.payments?.[0]?.paymentMethod || order?.data?.landing?.paymentPlugin}
                </div>
                <div className={styles.listItem}>
                  Site URL:{' '}
                  <a target='_blank' rel='noopener noreferrer' href={`${order?.data?.landing?.siteUrl}`}>
                    {order?.data?.landing?.siteUrl}
                  </a>
                </div>
                <div className={styles.listItem}>Subscription payment #: {order?.data?.subscriptionCycleNumber}</div>
                <div className={styles.listItem}>Hold reasons #: {order?.data?.holdReasons?.join(', ')}</div>
              </div>
            </div>
            <Tabs type='card' className={styles.tabsContainer}>
              <Tabs.TabPane tab='Products' key='1'>
                <ProductsTab
                  orderId={orderId}
                  orderStatus={order?.data?.status}
                  products={order?.data?.orderItems}
                  payments={order?.data?.payments}
                  subTotal={order?.data?.subTotal}
                  total={order?.data?.total}
                  processing={order?.data?.processing}
                  shipping={order?.data?.shipping}
                  promocodeDiscount={order?.data?.promocodeDiscount}
                  totalCost={order?.data?.totalCost}
                  orderRefresh={() => order.refetch()}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab='Payments' key='2'>
                <PaymentsTab payments={order?.data?.payments} />
                {user?.roles.includes('SYSTEM_ADMIN') && (
                  <>
                    <Button
                      type={isPaymentSettings ? 'default' : 'primary'}
                      style={{ margin: '10px 0' }}
                      onClick={() => setIsPaymentSettings(!isPaymentSettings)}
                    >
                      {isPaymentSettings ? 'Hide ' : 'Show '}payment settings
                    </Button>
                    {isPaymentSettings && (
                      <Table
                        columns={columns}
                        dataSource={dataSource}
                        bordered
                        rowKey={(record) => record.id}
                        loading={refundPayment.isLoading}
                      />
                    )}
                  </>
                )}
              </Tabs.TabPane>
              <Tabs.TabPane tab='Shipping Address' key='3'>
                <ShippingAddressTab
                  address={order?.data?.shippingAddress}
                  addressValidation={order?.data?.addressValidation}
                  orderId={orderId}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab='Payment Address' key='4'>
                <PaymentAddressTab address={order?.data?.billingAddress} />
              </Tabs.TabPane>
              <Tabs.TabPane tab='Events' key='5'>
                <EventsTab events={order?.data?.orderEvents} />
              </Tabs.TabPane>
              <Tabs.TabPane tab='Tasks' key='6'>
                <TasksTab orderId={+order?.data?.id} />
              </Tabs.TabPane>
              <Tabs.TabPane tab='Notes' key='7'>
                <NotesTab orderId={+order?.data?.id} />
              </Tabs.TabPane>
            </Tabs>
          </>
        )}
      </Layout>
    </Drawer>
  )
}

export default connector(OrderDetails)
