import React, { useEffect, useState } from 'react'
import useCustomCompareEffect from 'use-custom-compare-effect'
import { Input } from 'antd'
import { CountriesSelect } from 'features/countries/components'
import { RegionsSelect } from 'features/regions/components'
import styles from './AddressTabManual.module.scss'
import { Address } from 'features/addresses/types'

type OwnProps = {
  sourceAddress: Address
  onAddressChanged: (address: Address) => void
}

const AddressTabManual = ({ sourceAddress, onAddressChanged }: OwnProps) => {
  const [selectedCountryId, setSelectedCountryId] = useState<number>(sourceAddress?.countryId || 1)
  const [selectedZipCode, setSelectedZipCode] = useState<string>(sourceAddress?.zipCode)
  const [selectedState, setSelectedState] = useState<string>(sourceAddress?.state)
  const [selectedCity, setSelectedCity] = useState<string>(sourceAddress?.city)
  const [selectedAddress1, setSelectedAddress1] = useState<string>(sourceAddress?.address)
  const [selectedAddress2, setSelectedAddress2] = useState<string>(sourceAddress?.address2)

  useCustomCompareEffect(
    () => {
      setSelectedCountryId(sourceAddress?.countryId || 1)
    },
    [sourceAddress],
    () => sourceAddress?.countryId === selectedCountryId
  )

  useEffect(() => {
    onAddressChanged({
      countryId: selectedCountryId,
      zipCode: selectedZipCode,
      state: selectedState,
      city: selectedCity,
      address: selectedAddress1,
      address2: selectedAddress2
    } as Address)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountryId, selectedZipCode, selectedState, selectedCity, selectedAddress1, selectedAddress2])

  const handleStateValueChange = (value) => setSelectedState(value?.name)
  const handleCountryChange = (value) => {
    setSelectedCountryId(value.id)
    setSelectedState(null)
    setSelectedZipCode(null)
    setSelectedCity(null)
    setSelectedAddress1(null)
    setSelectedAddress2(null)
  }

  return (
    <div className={styles.listContainer}>
      <div className={styles.leftColumn}>
        <div className={styles.listItem}>
          Country:
          <CountriesSelect
            initialOption={{
              id: 1,
              name: 'United states of America'
            }}
            onSelect={handleCountryChange}
          />
        </div>
        <div className={styles.listItem}>
          ZIP Code:
          <Input type='number' onChange={(e) => setSelectedZipCode(e.target.value)} value={selectedZipCode} />
        </div>
        <div className={styles.listItem}>
          State:
          <RegionsSelect disabled={!selectedCountryId} countryId={selectedCountryId} onSelect={handleStateValueChange} />
        </div>
      </div>
      <div className={styles.rightColumn}>
        <div className={styles.listItem}>
          City:
          <Input onChange={(e) => setSelectedCity(e.target.value)} value={selectedCity} />
        </div>
        <div className={styles.listItem}>
          Address 1:
          <Input onChange={(e) => setSelectedAddress1(e.target.value)} value={selectedAddress1} />
        </div>
        <div className={styles.listItem}>
          Address 2:
          <Input onChange={(e) => setSelectedAddress2(e.target.value)} value={selectedAddress2} />
        </div>
      </div>
    </div>
  )
}

export default AddressTabManual
