import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Campaign } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'removeCampaign', boolean>

const REMOVE_CAMPAIGN = `
  mutation RemoveCampaign($campaignId: Int!) {
    removeCampaign(campaignId: $campaignId)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveCampaign = (campaignId: number) => Promise<number>

const removeCampaign: RemoveCampaign = async (campaignId) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_CAMPAIGN,
      variables: {
        campaignId
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeCampaign } = data
    if (!removeCampaign) {
      throw new Error("Can't remove campaign!")
    }

    return campaignId
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveCampaignContext = { prevCampaign?: Campaign }

type RemoveCampaignEvents = {
  onMutate: (campaignId: number) => Promise<RemoveCampaignContext>
  onError: (error: string, campaignId: number, context: RemoveCampaignContext) => void
  onSettled: (campaignId?: number) => void
}

const removeCampaignEvents: RemoveCampaignEvents = {
  onMutate: async (campaignId) => {
    await queryClient.cancelQueries(['campaign', campaignId])

    // Snapshot the previous value
    const prevCampaign = queryClient.getQueryData<Campaign>(['campaign', campaignId])

    if (prevCampaign) {
      queryClient.removeQueries(['campaign', campaignId])
    }

    return { prevCampaign }
  },
  onError: (_err, campaignId, context) => {
    if (context?.prevCampaign) {
      // Restore campaign on any error
      queryClient.setQueryData<Campaign>(['campaign', campaignId], context.prevCampaign)
    }
  },
  onSettled: (campaignId: number) => {
    if (campaignId) {
      queryClient.invalidateQueries(['campaign', campaignId])
      queryClient.invalidateQueries(['campaignsList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useRemoveCampaign = () => useMutation(removeCampaign, removeCampaignEvents)
