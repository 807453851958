import React, { useEffect, useState, useContext } from 'react'
import { CustomerAccountDto, UserRole } from '@merchx-v2/shared-types'
import useReactRouter from 'use-react-router'
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import { Input, Layout, PageHeader, Table, Radio, Popover, Popconfirm } from 'antd'
import { EyeTwoTone, UsergroupAddOutlined, UsergroupDeleteOutlined } from '@ant-design/icons'
import notification from 'mrx-notification'
import { ColumnsType } from 'antd/lib/table'

import { GlobalContext } from 'appContexts'
import { Breadcrumbs } from 'components'
import * as rootStyles from 'assets/layoutStyle'

import { useCustomerAccountsList, useSetCustomerAccountRole } from '../../hooks'
import { GraphQLCustomerAccountsListPageItem } from '../../types'

import styles from './CustomerAccountsList.module.scss'

type PropsType = {
  showHeader?: boolean
  searchQuery?: string
}

const customerRoleOptions: { label: string; value: UserRole }[] = [
  { label: 'Customers', value: 'CUSTOMER' },
  { label: 'Suppliers', value: 'SUPPLIER' },
  { label: 'Store owners', value: 'STORE_OWNER' }
]

const CustomerAccountsList = ({ showHeader = true }: PropsType) => {
  const { history } = useReactRouter()
  const { workspaceId, campaignId } = useContext(GlobalContext)

  const [customerRole, setCustomerRole] = useState<'CUSTOMER' | 'SUPPLIER' | 'STORE_OWNER'>('CUSTOMER')
  const [searchQuery, setSearchQuery] = useState<string>()
  const [isRolesPopupOpened, setIsRolesPopupOpened] = useState<Record<number, boolean>>({})

  const customerAccountsList = useCustomerAccountsList({ workspaceId, campaignId, role: customerRole, searchText: searchQuery })
  const setCustomerAccountRole = useSetCustomerAccountRole()

  const customerAccounts: GraphQLCustomerAccountsListPageItem[] = []
  customerAccountsList &&
    customerAccountsList.data &&
    customerAccountsList.data.pages.forEach((page) => page.items.forEach((customerAccount) => customerAccounts.push(customerAccount)))

  const page = customerAccountsList.data?.pages.length
    ? customerAccountsList.data?.pages[customerAccountsList.data?.pages.length - 1].currentPage
    : 1
  const size = customerAccountsList.data?.pages.length
    ? customerAccountsList.data?.pages[customerAccountsList.data?.pages.length - 1].pageSize
    : 30

  useEffect(() => {
    if (customerAccountsList.error) {
      notification.error({
        message: 'customerAccounts error!',
        description:
          customerAccountsList.error instanceof Error ? customerAccountsList.error.message : customerAccountsList.error.toString()
      })
    }
  }, [customerAccountsList.error])

  useEffect(() => {
    if (setCustomerAccountRole.error) {
      notification.error({
        message: 'Set customer role error!',
        description:
          setCustomerAccountRole.error instanceof Error ? setCustomerAccountRole.error.message : setCustomerAccountRole.error.toString()
      })
    }
  }, [setCustomerAccountRole.error])

  const upgradeToSupplier = (customerAccountId: number) => {
    setCustomerAccountRole.mutate(
      { customerAccountId, role: 'SUPPLIER' },
      {
        onSuccess: (isSaved) => {
          if (isSaved) notification.info({ message: 'Customer was upgraded to Supplier successfully!' })
        }
      }
    )
  }

  const upgradeToStoreOwner = (customerAccountId: number) => {
    setCustomerAccountRole.mutate(
      { customerAccountId, role: 'STORE_OWNER' },
      {
        onSuccess: (isSaved) => {
          if (isSaved) notification.info({ message: 'Customer was upgraded to Store owner successfully!' })
        }
      }
    )
  }

  const downgradeToCustomer = (customerAccountId: number) => {
    setCustomerAccountRole.mutate(
      { customerAccountId, role: 'CUSTOMER' },
      {
        onSuccess: (isSaved) => {
          if (isSaved) notification.info({ message: 'Downgraded to customers successfully!' })
        }
      }
    )
  }

  const onSearchInputChangeHandler = (value) => {
    setSearchQuery(value)
  }

  const columns: ColumnsType<CustomerAccountDto> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Last Login At',
      render: (_value, record) => moment(record.lastLoginAt).format('MM/DD/YYYY'),
      key: 'lastLoginAt'
    },
    {
      title: 'Banned Till',
      render: (_value, record) => (record.bannedTill ? moment(record.bannedTill).format('MM/DD/YYYY') : ''),
      key: 'bannedTill'
    },
    {
      title: 'Created At',
      render: (_value, record) => moment(record.createdAt).format('MM/DD/YYYY'),
      key: 'createdAt'
    },
    {
      title: 'Status',
      render: (_value, record) =>
        record.deletedAt
          ? 'DELETED'
          : record.isDeactivated
          ? 'DEACTIVATED'
          : new Date(record.bannedTill) > new Date()
          ? 'BANNED'
          : !record.isEmailConfirmed
          ? 'NOT CONFIRMED'
          : 'ACTIVE',
      key: 'status'
    },
    {
      title: '',
      dataIndex: '',
      key: 'edit',
      width: '7%',
      align: 'center',
      render: (_value, record) => (
        <div>
          <span>
            <EyeTwoTone
              style={{ fontSize: '20px', paddingRight: '10px' }}
              onClick={(e) => {
                e.stopPropagation()
                history.push(`/customerAccounts/${record.id}`)
              }}
            />
          </span>
          {customerRole === 'CUSTOMER' && (
            <Popover
              content={
                <div className={styles.popupMenu}>
                  <Popconfirm
                    title='Are you sure want to convert customer to supplier？'
                    okText='Yes'
                    onConfirm={() => {
                      upgradeToSupplier(record.id)
                      setIsRolesPopupOpened((prev) => ({ ...prev, [record.id]: !isRolesPopupOpened[record.id] }))
                    }}
                    cancelText='No'
                  >
                    <div className={styles.menuItem}>
                      <span>Convert to supplier</span>
                    </div>
                  </Popconfirm>
                  <Popconfirm
                    title='Are you sure want to convert customer to store owner？'
                    okText='Yes'
                    onConfirm={() => {
                      upgradeToStoreOwner(record.id)
                      setIsRolesPopupOpened((prev) => ({ ...prev, [record.id]: !isRolesPopupOpened[record.id] }))
                    }}
                    cancelText='No'
                  >
                    <div className={styles.menuItem}>Convert to store owner</div>
                  </Popconfirm>
                </div>
              }
              title={<b>Upgrade role</b>}
              trigger='click'
              visible={isRolesPopupOpened[record.id]}
              onVisibleChange={() => setIsRolesPopupOpened((prev) => ({ ...prev, [record.id]: !isRolesPopupOpened[record.id] }))}
            >
              <UsergroupAddOutlined style={{ fontSize: '20px', color: 'rgb(24, 144, 255)' }} />
            </Popover>
          )}
          {customerRole !== 'CUSTOMER' && (
            <Popconfirm
              title='Are you sure want to downgrade role to customer？'
              okText='Yes'
              onConfirm={() => {
                downgradeToCustomer(record.id)
                setIsRolesPopupOpened((prev) => ({ ...prev, [record.id]: !isRolesPopupOpened[record.id] }))
              }}
              cancelText='No'
            >
              <UsergroupDeleteOutlined style={{ fontSize: '20px', color: 'rgb(24, 144, 255)' }} />
            </Popconfirm>
          )}
        </div>
      )
    }
  ]

  return (
    <Layout style={rootStyles.root}>
      {showHeader && (
        <>
          <Layout.Header style={rootStyles.header}>
            <PageHeader
              onBack={() => history.goBack()}
              title='Customer Accounts'
              extra={[
                <div key='1' className={styles.extraContent}>
                  <Radio.Group
                    options={customerRoleOptions}
                    onChange={(event) => setCustomerRole(event.target.value)}
                    value={customerRole}
                    optionType='button'
                    buttonStyle='solid'
                  />
                  <Input.Search
                    key='search-field'
                    placeholder='Search'
                    onSearch={(value) => onSearchInputChangeHandler(value)}
                    style={{ width: 200, marginLeft: 10 }}
                    allowClear
                  />
                </div>
              ]}
            />
          </Layout.Header>
          <Layout.Content style={rootStyles.contentBreadcrumbs}>
            <Breadcrumbs
              items={[
                { title: 'Main', url: '/' },
                { title: 'Campaigns', url: '/' },
                { title: 'Customer Accounts', url: '/' }
              ]}
            />
          </Layout.Content>
        </>
      )}
      <Layout.Content className={showHeader && styles.contentComponent} id='pik'>
        <InfiniteScroll
          style={{ paddingBottom: '30px' }}
          dataLength={page * size}
          next={customerAccountsList.fetchNextPage}
          hasMore={!!customerAccountsList.hasNextPage}
          loader={<h4>Loading...</h4>}
          refreshFunction={customerAccountsList.refetch}
          scrollableTarget='customerAccountScroll'
        >
          <Table
            columns={columns}
            dataSource={customerAccounts.map((item) => item).flat()}
            bordered
            rowKey={(record) => record.id}
            pagination={false}
            loading={customerAccountsList.isLoading && setCustomerAccountRole.isLoading}
          />
        </InfiniteScroll>
      </Layout.Content>
    </Layout>
  )
}

export default CustomerAccountsList
