import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type OrderItemProductsOptionsSelector = (state: ReduxState, campaignId: number) => SelectOption[]

const getOrderItemProductsOptions: OrderItemProductsOptionsSelector = (state, campaignId) => {
  let result = [] as SelectOption[]
  if (Object.prototype.hasOwnProperty.call(state.products.orderItemsProductsForOptions, campaignId)) {
    result =
      state.products.orderItemsProductsForOptions[campaignId].map((item) => ({
        id: item.id,
        name: item.name
      })) || ([] as SelectOption[])
  }
  return result
}

export const orderItemProductsSelectOptionsSelector: OrderItemProductsOptionsSelector = createSelector(
  getOrderItemProductsOptions,
  (options) => options
)
