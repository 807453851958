export const addItemsToList = (pagination: any, items: any[]) => {
  const { currentPage, size, total } = pagination

  if (pagination.pages.length === currentPage * size || pagination.pages.length === total) {
    return
  }

  for (const item of items) {
    pagination.pages = pagination.pages.filter(oldItem => oldItem.id !== item.id)
  }

  pagination.pages.push(...items)
}
