import LogRocket from 'logrocket'
import { createBrowserHistory } from 'history'
import { applyMiddleware, createStore, PreloadedState } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer, { ReduxState } from './createRootReducer'

export const history = createBrowserHistory()

console.log('Configure store started!')

export let store: any

const configureStore = (preloadedState?: PreloadedState<ReduxState>) => {
  const middlewares = [thunkMiddleware, routerMiddleware(history), LogRocket.reduxMiddleware()]
  const middlewareEnchancer = applyMiddleware(...middlewares)

  const enchancers = [middlewareEnchancer] // Add any enchancers here
  const composedEnchancers = composeWithDevTools(...enchancers)

  const rootReducer = createRootReducer(history)
  store = createStore(rootReducer, preloadedState, composedEnchancers)

  console.log('Configure store was finished!')
  return { store }
}

export default configureStore
