// TODO - remove becouse of legacy

import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Form, Select, Input, Tag, Tooltip } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import notification from 'mrx-notification'
import { GlobalContext } from 'appContexts'
import { useSaveTags, useTagsOptions } from 'features/tags/hooks'
import styles from './LandingWizardTags.module.scss'

type PropsType = {
  landingId: number
  tags: string[]
  onNextButtonClick?: (nextStep: number) => void
}

const LandingWizardTags = (props: PropsType) => {
  const { tags, landingId, onNextButtonClick } = props

  const [tagsState, setTagsState] = useState(tags !== [] ? tags : [])
  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [editInputIndex, setEditInputIndex] = useState(-1)
  const [editInputValue, setEditInputValue] = useState('')
  const [editInputRef, setEditInputRef] = useState()
  const [inputRef, setInputRef] = useState()

  const [form] = Form.useForm()

  const { workspaceId } = useContext(GlobalContext)

  const tagsForOptions = useTagsOptions({ workspaceId, ownerType: 'LANDING' })
  const saveTags = useSaveTags()

  useEffect(() => {
    if (inputVisible === true && inputRef) {
      // @ts-ignore
      inputRef.focus()
    }
  }, [inputVisible, inputRef])

  useEffect(() => {
    if (editInputRef) {
      // @ts-ignore
      editInputRef.focus()
    }
  }, [inputVisible, editInputRef])

  const onFinishHandler = async () => {
    saveTags.mutate(
      { ownerId: landingId, ownerType: 'LANDING', tags: tagsState },
      {
        onSuccess: () => {
          notification.success({
            message: 'Successfully',
            description: 'Tags was saved successfully!'
          })
          onNextButtonClick(7)
        }
      }
    )
  }

  const handleCloseTag = (removedTag) => {
    setTagsState(tagsState.filter((tag) => tag !== removedTag))
  }

  const showInput = () => {
    setInputVisible(true)
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  const handleInputConfirm = () => {
    let tags = tagsState
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue]
    }
    setTagsState(tags)
    setInputVisible(false)
    setInputValue('')
  }

  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value)
  }

  const handleEditInputConfirm = () => {
    const newTags = [...tagsState]
    newTags[editInputIndex] = editInputValue

    setTagsState(newTags)
    setEditInputIndex(-1)
    setEditInputValue('')
  }

  const saveInputRef = (input) => {
    setInputRef(input)
  }

  const saveEditInputRef = (input) => {
    setEditInputRef(input)
  }

  const handleTagsSelectorChange = (value) => {
    if (!tagsState.includes(value)) {
      setTagsState([...tagsState, value])
    }

    form.setFieldsValue({
      tags: ''
    })
  }

  return (
    <div>
      <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
        <Card
          className={styles.card}
          title={<span className={styles.cardTitle}>Tags</span>}
          extra={[
            <Button
              key='1'
              type='primary'
              htmlType='submit'
              className={styles.saveButton}
              // onClick={() => onNextButtonClick(6)}
            >
              NEXT
            </Button>
          ]}
        >
          <div className={styles.wizardTagsContainer}>
            <Form.Item name='tags'>
              <Select
                style={{ width: '100%' }}
                placeholder='Choose existing tag'
                onChange={handleTagsSelectorChange}
                loading={tagsForOptions.isLoading}
              >
                {tagsForOptions.data !== [] &&
                  tagsForOptions.data.map((item) => {
                    return (
                      <Select.Option value={item} key={item}>
                        {item}
                      </Select.Option>
                    )
                  })}
              </Select>
            </Form.Item>

            {tagsState &&
              tagsState.map((tag, index) => {
                if (editInputIndex === index) {
                  return (
                    <Input
                      ref={saveEditInputRef}
                      key={tag}
                      size='small'
                      style={{ width: '78px', marginRight: '8px', marginTop: '10px', verticalAlign: 'top' }}
                      value={editInputValue}
                      onChange={handleEditInputChange}
                      onBlur={handleEditInputConfirm}
                      onPressEnter={handleEditInputConfirm}
                    />
                  )
                }

                const isLongTag = tag.length > 20

                const tagElem = (
                  <Tag style={{ userSelect: 'none', marginTop: '10px' }} key={tag} closable onClose={() => handleCloseTag(tag)}>
                    <span
                      onDoubleClick={(e) => {
                        setEditInputIndex(index)
                        setEditInputValue(tag)
                        e.preventDefault()
                      }}
                    >
                      {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                    </span>
                  </Tag>
                )
                return isLongTag ? (
                  <Tooltip title={tag} key={tag}>
                    {tagElem}
                  </Tooltip>
                ) : (
                  tagElem
                )
              })}

            {inputVisible && (
              <Input
                ref={saveInputRef}
                type='text'
                size='small'
                // is's not working with scss, just inline
                style={{
                  width: '100%',
                  marginTop: '15px',
                  verticalAlign: 'top',
                  height: '30px',
                  display: 'flex',
                  alignItems: 'center'
                }}
                className={styles.tagInput}
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputConfirm}
                onPressEnter={handleInputConfirm}
              />
            )}
            {!inputVisible && (
              <Tag onClick={showInput} style={{ width: '100%', marginTop: '15px', height: '30px', display: 'flex', alignItems: 'center' }}>
                <PlusOutlined style={{ marginRight: '10px' }} /> CREATE NEW TAG
              </Tag>
            )}
          </div>
        </Card>
      </Form>
    </div>
  )
}

export default LandingWizardTags
