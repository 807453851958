import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { SiteType } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Autoresponder, AutoresponderInput } from '../types'

type CreateAutoresponderArgs = {
  ownerType: SiteType
  ownerId: number
  autoresponderData: AutoresponderInput
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'createAutoresponder', Autoresponder>

const CREATE_AUTORESPONDER = `
  mutation creareAutoresponder ($ownerType: SiteType!, $ownerId: Int!, $autoresponderData: AutoresponderInput!) {
    createAutoresponder (ownerType: $ownerType, ownerId: $ownerId, autoresponderData: $autoresponderData) {
      id
      ownerType
      ownerId
      autoresponderType
      sourceCode
      orderItemSourceCode
      useAlternativeEmailing
      subjectSourceCode
      fromName
      settings{
      id
      name
      alias
        type
        value
        jsonValue
      }
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type CreateAutoresponder = (args: CreateAutoresponderArgs) => Promise<Autoresponder>

const createAutoresponder: CreateAutoresponder = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_AUTORESPONDER,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createAutoresponder } = data
    if (!createAutoresponder) {
      throw new Error("Can't create autoresponder!")
    }

    return createAutoresponder
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type CreateAutoresponderEvents = {
  onSettled: (data?: Autoresponder) => void
}

const createAutoresponderEvents: CreateAutoresponderEvents = {
  onSettled: (autoresponder) => {
    if (autoresponder?.id) {
      queryClient.invalidateQueries([`${autoresponder.ownerType.toLowerCase()}`, +autoresponder.ownerId])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCreateAutoresponder = () => useMutation(createAutoresponder, createAutoresponderEvents)
