import LogRocket from 'logrocket'
import axios from 'axios'
import * as t from '../actionTypes'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import settings from 'config/settings'
import { GraphQLUser, User } from '../types'
import { changeLocation } from 'features/navigation/actions'
import * as workspaceFeature from 'features/workspaces'
import { logout } from './logout'

export type LoginAction = FSA<undefined, User, string>
type Login = (
  email: string,
  password: string,
  rememberMe: boolean,
  setCampaignId?: (id: number) => void,
  setWorkspaceId?: (id: number) => void
) => MrxThunk<LoginAction, Promise<void>>
type QueryResponse = GraphQLResponse<'login', GraphQLUser>

const LOGIN = `
  mutation Login($email: String!, $password: String!, $rememberMe: Boolean) {
    login(email: $email, password: $password, rememberMe: $rememberMe) {
      id
      email
      avatar
      firstName
      lastName
      lastLoginAt
      token
      refreshToken
      roles
      rememberMe
      workspacesRoles {
        workspaceId
        role
      }
      workspaces {
        id
        name
        createdAt
        updatedAt
      }
      defaultCampaignId
      defaultWorkspaceId
      activeWorkspace {
        id
        name
        autoArchiveCampaignsWithoutSales
        daysWithoutSalesToArchive
        deploymentPlugin
        deploymentSettingsId
        deploymentSettingsName
        paymentPlugin
        paymentSettingsId
        paymentSettingsName
        fulfillmentPlugin
        fulfillmentSettingsId
        fulfillmentSettingsName
        mailingPlugin
        mailingSettingsId
        mailingSettingsName
        smsPlugin
        smsSettingsId
        smsSettingsName
        shippingCost
        applyShippingOnce
        processingCost
        applyProcessingOnce
        allowMultiplePayments
      }
      createdAt
      updatedAt
    }
  }
`

export const login: Login =
  (email, password, rememberMe = false, setCampaignId = undefined, setWorkspaceId = undefined) =>
  async (dispatch, getState) => {
    dispatch({
      type: t.LOGIN,
      meta: {
        done: false
      }
    })

    try {
      const {
        data: { data, errors }
      }: QueryResponse = await axios.post(`${settings.backendUrl}/graphql`, {
        query: LOGIN,
        variables: { email, password, rememberMe }
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { login } = data
      if (!login) {
        throw new Error("Can't log in!")
      }

      dispatch({
        type: t.LOGIN,
        payload: login,
        meta: { done: true }
      })

      dispatch(workspaceFeature.actions.activeWorkspaceLoaded(login.activeWorkspace))
      dispatch(workspaceFeature.actions.workspacesLoaded(login.workspaces))

      if (setCampaignId) {
        setCampaignId(login.defaultCampaignId)
      }

      if (setWorkspaceId) {
        setWorkspaceId(login.activeWorkspace.id)
      }

      LogRocket.identify(login.id + '', {
        name: `${login.firstName} ${login.lastName}`,
        email
      })

      changeLocation('/')(dispatch, getState, null)
    } catch (err) {
      dispatch({
        type: t.LOGIN,
        payload: extractErrorInfo(err),
        error: true
      })

      dispatch(logout())
    }
  }
