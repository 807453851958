import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import { RouteComponentProps } from 'react-router'
import * as QueryString from 'query-string'
import * as actions from '../../actions'

const mapStateToProps = (state: ReduxState, ownProps: RouteComponentProps) => {
  const queryParams = QueryString.parse(ownProps.location.search)
  const token = queryParams.token as string

  return { token, history: ownProps.history }
}

const mapDispatchToProps = {
  validateUserEmail: actions.validateUserEmail
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector>
