import React, { useEffect, useState } from 'react'
import { Button, Drawer, Input, Form, Switch } from 'antd'
import notification from 'mrx-notification'
import styles from './UpdateCustomer.module.scss'
import { useCustomer, useUpdateCustomer } from '../../hooks'

type Props = {
  customerId: number
  onClose: () => void
  visible: boolean
}

const UpdateCustomer = (props: Props) => {
  const { onClose, customerId, visible } = props

  const [form] = Form.useForm()
  const { data: customer, isLoading } = useCustomer(customerId)
  const updateCustomer = useUpdateCustomer()

  const [isResetCustomerAccount, setIsResetCustomerAccount] = useState(false)

  useEffect(() => {
    if (customer) {
      form.setFieldsValue({
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        phone: customer.phone
      })
      setIsResetCustomerAccount(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  useEffect(() => {
    if (updateCustomer.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Customer was updated successfully!'
      })
      form.resetFields()
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCustomer.isSuccess])

  useEffect(() => {
    if (updateCustomer.isError) {
      notification.error({
        message: 'Error',
        description: updateCustomer.error.toString()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCustomer.isError])

  const onFinishHandler = async (values: any) => {
    const isChangeCustomerAccountEmail = values.isChangeCustomerAccountEmail
    delete values.isChangeCustomerAccountEmail
    await updateCustomer.mutate({ customerId, customerData: values, isChangeCustomerAccountEmail })
  }

  return (
    <Drawer bodyStyle={{ padding: 0 }} title='Update Customer' width='460' onClose={onClose} visible={visible}>
      <Form
        layout='vertical'
        hideRequiredMark
        onFinish={onFinishHandler}
        form={form}
        className={styles.form}
        initialValues={{
          firstName: customer?.firstName,
          lastName: customer?.lastName,
          email: customer?.email,
          phone: customer?.phone,
          isChangeCustomerAccountEmail: false
        }}
      >
        <div className={styles.formItems}>
          <Form.Item name='firstName' label='First Name' rules={[{ required: true, message: 'Please input first name!' }]}>
            <Input style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item name='lastName' label='last Name' rules={[{ required: true, message: 'Please input last name!' }]}>
            <Input style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item name='email' label='Email' rules={[{ required: true, message: 'Please input email!' }]}>
            <Input style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item name='phone' label='Phone'>
            <Input style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            name='isChangeCustomerAccountEmail'
            valuePropName='checked'
            label='Reset/Resend password'
            className={styles.customerAccountSwitch}
          >
            <Switch onChange={setIsResetCustomerAccount} />
          </Form.Item>
          {isResetCustomerAccount && <div className={styles.warningMessage}>You are about to reset customer account credentials</div>}
        </div>

        <div className={styles.buttonsContainer}>
          <Button onClick={onClose} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit' loading={isLoading || updateCustomer.isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default UpdateCustomer
