import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { ZipwhipSetting } from '../types'

type ZipwhipSettingsListSelector = (state: ReduxState) => ZipwhipSetting[]
const getZipwhipSettingsList: ZipwhipSettingsListSelector = state => {
  const result = [] as ZipwhipSetting[]
  const pageIds = state.zipwhipSettings.pagination.pages[state.zipwhipSettings.pagination.currentPage] || []
  pageIds.forEach(pageId => {
    result.push(state.zipwhipSettings.zipwhipSettings[pageId])
  })
  return result
}

export const zipwhipSettingsListSelector: ZipwhipSettingsListSelector = createSelector(
  getZipwhipSettingsList,
  list => list
)
