import React from 'react'
import { SearchableSelectBase } from 'components'
import { PropsFromRedux, connector } from './container'

const SmsSettingsSelect = (props: PropsFromRedux) => {
  const { plugin, fetchSimpleTextSettingsOptions, fetchZipwhipSettingsOptions, ...rest } = props

  switch (plugin) {
    case 'SIMPLE_TEXT':
      return <SearchableSelectBase plugin={plugin} onSearch={fetchSimpleTextSettingsOptions} {...rest} />

    case 'ZIPWHIP':
    default:
      return <SearchableSelectBase plugin={plugin} onSearch={fetchZipwhipSettingsOptions} {...rest} />
  }
}

export default connector(SmsSettingsSelect)
