import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { Product } from 'features/types'

type CampaignProductsSelector = (state: ReduxState, campaignId: number) => Product[]
const getCampaignProducts: CampaignProductsSelector = (state, campaignId) => {
  const result = [] as Product[]
  if (Object.prototype.hasOwnProperty.call(state.products.campaignProducts, campaignId)) {
    Object.keys(state.products.campaignProducts[campaignId]).forEach((productId) => {
      result.push(state.products.campaignProducts[campaignId][productId])
    })
  }

  return result
}

export const campaignProductsSelector: CampaignProductsSelector = createSelector(getCampaignProducts, (list) => list)
