import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { SiteType } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Autoresponder, AutoresponderInput } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'updateAutoresponder', boolean>

const UPDATE_AUTORESPONDER = `
  mutation UpdateAutoresponder($autoresponderId: Int!, $autoresponderData: AutoresponderInput!) {
    updateAutoresponder(autoresponderId: $autoresponderId, autoresponderData: $autoresponderData)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateAutoresponderArgs = {
  ownerType: SiteType
  ownerId: number
  autoresponderId: number
  autoresponderData: AutoresponderInput
}

type UpdateAutoresponder = (args: UpdateAutoresponderArgs) => Promise<UpdateAutoresponderArgs>

const updateAutoresponder: UpdateAutoresponder = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_AUTORESPONDER,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateAutoresponder } = data
    if (!updateAutoresponder) {
      throw new Error("Can't update autoresponder!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateAutoresponderContext = { prevAutoresponder?: Autoresponder }

type UpdateAutoresponderEvents = {
  onMutate: (variables: UpdateAutoresponderArgs) => Promise<UpdateAutoresponderContext>
  onError: (error: string, variables: UpdateAutoresponderArgs, context: UpdateAutoresponderContext) => void
  onSettled: (data?: UpdateAutoresponderArgs) => void
}

const updateAutoresponderEvents: UpdateAutoresponderEvents = {
  onMutate: async (variables: UpdateAutoresponderArgs) => {
    await queryClient.cancelQueries(['autoresponder', variables.autoresponderId])

    // Snapshot the previous value
    const prevAutoresponder = queryClient.getQueryData<Autoresponder>(['autoresponder', variables.autoresponderId])

    // Optimistically update to the new values
    if (prevAutoresponder) {
      queryClient.setQueryData<Autoresponder>(['autoresponder', variables.autoresponderId], {
        ...prevAutoresponder,
        id: variables.autoresponderId,
        ...variables.autoresponderData
      })
    }

    return { prevAutoresponder }
  },
  onError: (_err, variables, context) => {
    if (context?.prevAutoresponder) {
      // Restore previous version of autoresponder on any error
      queryClient.setQueryData<Autoresponder>(['autoresponder', variables.autoresponderId], context.prevAutoresponder)
    }
  },
  onSettled: (data) => {
    if (data?.autoresponderId) {
      queryClient.invalidateQueries([`${data.ownerType.toLowerCase()}`, data.ownerId])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUpdateAutoresponder = () => useMutation(updateAutoresponder, updateAutoresponderEvents)
