export const FETCH_CUSTOMERS_DATA_REPORT = 'reports/FETCH_CUSTOMERS_DATA_REPORT'
export const FETCH_CUSTOMERS_CSV_DATA_REPORT = 'reports/FETCH_CUSTOMERS_CSV_DATA_REPORT'
export const FETCH_CAMPAIGN_TEMPLATES_REPORT = 'reports/FETCH_CAMPAIGN_TEMPLATES_REPORT'
export const FETCH_DASHBOARD_REPORT = 'reports/FETCH_DASHBOARD_REPORT'
export const FETCH_FAILED_FULFILLMENT_REPORT = 'reports/FETCH_FAILED_FULFILLMENT_REPORT' // TODO - remove becouse of legacy
export const FETCH_FAILED_FULFILLMENT_CSV_REPORT = 'reports/FETCH_FAILED_FULFILLMENT_CSV_REPORT' // TODO - remove becouse of legacy
export const FETCH_CANCELED_SUBSCRIPTIONS_REPORT = 'reports/FETCH_CANCELED_SUBSCRIPTION_REPORT'
export const FETCH_SALES_FUNNEL_ANALYZE_REPORT = 'reports/FETCH_SALES_FUNNEL_ANALYZE_REPORT'
export const FETCH_TOP_CAMPAIGNS_REPORT = 'reports/FETCH_TOP_CAMPAIGNS_REPORT'
export const FETCH_TOP_CAMPAIGNS_RATE_REPORT = 'reports/FETCH_TOP_CAMPAIGNS_RATE_REPORT'
export const FETCH_TOP_LANDINGS_REPORT = 'reports/FETCH_TOP_LANDINGS_REPORT'
export const FETCH_SUBSCRIPTION_STATS_REPORT = 'reports/FETCH_SUBSCRIPTION_STATS_REPORT'
export const SET_REPORT_DATES = 'reports/SET_REPORT_DATES'
export const UPDATE_LANDING_STATUS = 'report/UPDATE_LANDING_STATUS'
export const UPDATE_CAMPAIGN_LANDINGS_FROM_TEMPLATE = 'report/UPDATE_CAMPAIGN_LANDINGS_FROM_TEMPLATE'
export const REDEPLOY_CAMPAIGN_LANDINGS = 'report/REDEPLOY_CAMPAIGN_LANDINGS'
export const CHANGE_SUBSCRIPTION_STATS_DATES_RANGE = 'report/CHANGE_SUBSCRIPTION_STATS_DATES_RANGE'
