import * as t from '../actionTypes'
import { Moment } from 'moment-timezone'

export type ChangeDatesRangeAction = Required<Omit<BaseFSA<[Moment, Moment]>, 'meta'>>
type ChangeDatesRange = (range: [Moment, Moment]) => MrxThunk<ChangeDatesRangeAction, Promise<void>>

export const changeDatesRange: ChangeDatesRange = range => async dispatch => {
  dispatch({
    type: t.CHANGE_DATES_RANGE,
    payload: range
  })
}
