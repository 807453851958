import produce from 'immer'
import createReducer from 'store/createReducer'
import { isSuccessAction } from 'types'
import { actionTypes as wt } from 'features/workspaces'
import { addItemsToList } from 'utils/addItemsToList'
import * as t from './actionTypes'
import { EternalListEnum, EternalListsState } from './types'
import {
  ChangeCurrentPageAction,
  ChangePageSizeAction,
  CreateEternalItemsAction,
  FetchEternalListAction,
  RemoveEternalItemsAction,
  UpdateEternalItemAction
} from './actions'

const initPaginationState = {
  pages: [],
  total: 0,
  currentPage: 1,
  size: 10
}

const initState: EternalListsState = {
  templates: initPaginationState,
  products: initPaginationState
}

const reducer = createReducer(initState, {
  [t.CREATE_ETERNAL_LIST_ITEMS]: (state, action: CreateEternalItemsAction<EternalListEnum>) =>
    produce(state, draft => {
      if (isSuccessAction(action)) {
        const { list, items } = action.payload

        // @ts-ignore
        draft[list].pages.push(...items)
        draft[list].total = draft[list].total + items.length
      }
    }),

  [t.REMOVE_ETERNAL_LIST_ITEMS]: (state, action: RemoveEternalItemsAction<EternalListEnum>) =>
    produce(state, draft => {
      if (isSuccessAction(action)) {
        const { list, items } = action.payload
        items.sort((a, b) => b.index - a.index)

        let removedItems = 0
        for (const itemToRemove of items) {
          const { index, itemId } = itemToRemove
          const item = draft[list].pages[index]

          if (item && itemId === item.id) {
            draft[list].pages.splice(index, 1)
            draft[list].total--
            removedItems++
          }
        }

        draft[list].currentPage = draft[list].currentPage - Math.round(removedItems / draft[list].total)
      }
    }),

  [t.UPDATE_ETERNAL_LIST_ITEM]: (state, action: UpdateEternalItemAction<EternalListEnum>) =>
    produce(state, draft => {
      if (isSuccessAction(action)) {
        const { list, item, index } = action.payload

        const itemList = draft[list].pages[index]

        if (itemList && itemList.id === item.id) {
          for (const field in item) {
            draft[list].pages[index][field] = item[field]
          }
        }
      }
    }),

  [t.FETCH_ETERNAL_LIST]: (state, action: FetchEternalListAction<EternalListEnum>) =>
    produce(state, draft => {
      if (isSuccessAction(action)) {
        const { list, page, total, size, items, filters } = action.payload

        if (draft[list]) {
          if (JSON.stringify(filters) !== JSON.stringify(draft[list].filters)) {
            draft[list].pages = items
          } else {
            addItemsToList(draft[list], items)
          }

          draft[list].filters = filters
          draft[list].total = total
          draft[list].currentPage = page
          draft[list].size = size
        }
      }
    }),

  [t.CHANGE_CURRENT_PAGE]: (state, action: ChangeCurrentPageAction) => // TODO Maybe it should be removed, cause list doesnt know how to jump to current page
    produce(state, draft => {
      const { list, page } = action.payload

      if (page) {
        draft[list].currentPage = page
      }
    }),

  [t.CHANGE_PAGE_SIZE]: (state, action: ChangePageSizeAction) =>
    produce(state, draft => {
      const { list, size } = action.payload

      if (size) {
        draft[list].size = size
      }
    }),

  [wt.SWITCH_WORKSPACE]: state =>
    produce(state, draft => {
      draft.templates = initPaginationState
    }),
})

export default reducer
