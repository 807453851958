import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { SendgridSetting } from '../types'

export type FetchSendgridSettingsForOptionsAction = FSA<undefined, SendgridSetting[], string>
type FetchSendgridSettingsForOptions = (searchText?: string) => MrxThunk<FetchSendgridSettingsForOptionsAction>
type QueryResponse = GraphQLResponse<'sendgridSettingsForOptions', SendgridSetting[]>

const FETCH_SENDGRID_SETTINGS_FOR_OPTIONS = `
  query fetchSendgridSettingsForOptions ($workspaceId: Int!, $limit: Int, $searchText: String) {
    sendgridSettingsForOptions (workspaceId: $workspaceId, limit: $limit, searchText: $searchText) {
      id
      name
    }
  }
`

const fetchSendgridSettingsForOptions: FetchSendgridSettingsForOptions = (searchText) => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_SENDGRID_SETTINGS_FOR_OPTIONS,
    meta: { done: false }
  })

  try {
    const {
      workspaces: {
        activeWorkspace: { id: workspaceId }
      }
    } = getState()
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_SENDGRID_SETTINGS_FOR_OPTIONS,
      variables: { workspaceId, searchText }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { sendgridSettingsForOptions } = data
    if (!sendgridSettingsForOptions) {
      throw new Error("Can't get sendgrid settings!")
    }

    dispatch({
      type: t.FETCH_SENDGRID_SETTINGS_FOR_OPTIONS,
      payload: sendgridSettingsForOptions,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_SENDGRID_SETTINGS_FOR_OPTIONS,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchSendgridSettingsForOptions }
