import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { ZipwhipSetting } from '../types'

type ZipwhipSettingInput = {
  name: string
  username: string
  password: string
}

export type CreateZipwhipSettingAction = FSA<undefined, ZipwhipSetting, string>
type CreateZipwhipSetting = (zipwhipSettingData: ZipwhipSettingInput) => MrxThunk<CreateZipwhipSettingAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'createZipwhipSetting', ZipwhipSetting>

const CREATE_ZIPWHIP_SETTING = `
  mutation createZipwhipSetting ($workspaceId: Int!, $zipwhipSettingData: ZipwhipSettingInput!) {
    createZipwhipSetting (workspaceId: $workspaceId, zipwhipSettingData: $zipwhipSettingData) {
      id
      name
      username
      password
    }
  }
`

const createZipwhipSetting: CreateZipwhipSetting = (zipwhipSettingData) => async (dispatch, getState) => {
  let isSaved = false

  dispatch({
    type: t.CREATE_ZIPWHIP_SETTING,
    meta: { done: false }
  })

  try {
    const workspaceId = getState()?.workspaces?.activeWorkspace?.id
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_ZIPWHIP_SETTING,
      variables: { workspaceId, zipwhipSettingData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createZipwhipSetting } = data
    if (!createZipwhipSetting) {
      throw new Error("Can't create Zipwhip Setting!")
    }

    dispatch({
      type: t.CREATE_ZIPWHIP_SETTING,
      payload: createZipwhipSetting,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.CREATE_ZIPWHIP_SETTING,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { createZipwhipSetting }
