import React, { useState, useEffect, useCallback } from 'react'
import useReactRouter from 'use-react-router'
import { DatePicker, PageHeader, Table, Layout, Button } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import notification from 'mrx-notification'
import { ColumnsType } from 'antd/lib/table'
import { Breadcrumbs } from 'components'
import CreateProcessingCost from '../CreateProcessingCost'
import UpdateProcessingCost from '../UpdateProcessingCost'
import * as rootStyles from 'assets/layoutStyle'
import styles from './ProcessingCostsList.module.scss'
import { connector, PropsFromRedux } from './container'
import { ProcessingCost } from '../../types'
import getColumns from './getColumns'

const { RangePicker } = DatePicker
const dateFormat = 'MM/DD/YYYY'

const ProcessingCostsList: React.FC<PropsFromRedux> = ({
  error,
  isLoading,
  processingCosts,
  productTags,
  pagination,
  isNeedToFetchList,
  datesRange,

  changeDatesRange,
  fetchProcessingCosts,
  fetchProductTagsForOptions,
  changeCurrentPage,
  removeProcessingCost
}) => {
  const { total, currentPage } = pagination
  const { history } = useReactRouter()
  const [columns, setColumns] = useState<ColumnsType<ProcessingCost>>([])
  const [selectedProcessingCostId, setSelectedProcessingCostId] = useState<number>()

  const doConfirm = useCallback(
    async (processingCost: ProcessingCost, e?: React.MouseEvent<HTMLElement>) => {
      e && e.stopPropagation()
      const isRemoved = await removeProcessingCost(processingCost)
      if (isRemoved) {
        notification.success({
          message: 'Successfully',
          description: 'Labor cost was deleted successfully!'
        })
      }
    },
    [removeProcessingCost]
  )

  useEffect(() => {
    isNeedToFetchList && fetchProcessingCosts()
  }, [isNeedToFetchList, fetchProcessingCosts])

  useEffect(() => {
    fetchProductTagsForOptions(undefined, 1024)
  }, [fetchProductTagsForOptions])

  useEffect(() => {
    productTags.length && setColumns(getColumns(productTags, doConfirm))
  }, [productTags, doConfirm])

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Processing costs list page error!',
        description: error
      })
    }
  }, [error])

  const handleOnRow = (record: ProcessingCost) => {
    return {
      onClick: () => {
        setSelectedProcessingCostId(record.id)
      }
    }
  }

  const handleOnClose = () => setSelectedProcessingCostId(undefined)

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          className={styles.pageHeader}
          onBack={() => history.goBack()}
          title='Processing Costs List'
          extra={[
            <RangePicker
              allowEmpty={[true, false]}
              key={`${datesRange[0]?.toString()}-${datesRange[1].toString()}`}
              format={dateFormat}
              value={datesRange}
              onChange={changeDatesRange}
              allowClear={false}
            />,
            <Button
              key='2'
              disabled={isLoading}
              onClick={() => changeDatesRange([datesRange[0]?.subtract(1, 'day'), datesRange[1].subtract(1, 'day')])}
            >
              <LeftOutlined />
              Prev
            </Button>,
            <Button
              key='3'
              disabled={isLoading}
              onClick={() => changeDatesRange([datesRange[0]?.add(1, 'day'), datesRange[1].add(1, 'day')])}
            >
              Next
              <RightOutlined />
            </Button>,
            <CreateProcessingCost key='4' />
          ]}
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Costs', url: '/' },
            { title: 'Processing', url: '/' }
          ]}
        />
      </Layout.Content>
      <Layout.Content style={rootStyles.contentComponent}>
        <UpdateProcessingCost processingCostId={selectedProcessingCostId} onClose={handleOnClose} />
        <Table
          className={styles.tableContainer}
          columns={columns}
          dataSource={processingCosts}
          bordered
          rowKey={(record) => record.date}
          onRow={handleOnRow}
          loading={isLoading}
          pagination={{
            onChange: changeCurrentPage,
            current: currentPage,
            total
          }}
        />
      </Layout.Content>
    </Layout>
  )
}

export default connector(ProcessingCostsList)
