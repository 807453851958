import React, { useEffect, useState, useContext } from 'react'
import { Drawer, Form, Button, Select } from 'antd'
import notification from 'mrx-notification'
import { AvailablePluginNames, PluginSettingOwner, PluginType } from '@merchx-v2/shared-types'
import { GlobalContext } from 'appContexts'
import { useGetDefaultPluginSettings, usePluginSettings, useSavePluginSettings } from '../../hooks'
import PluginSettingForm from '../PluginSettingForm'
import styles from './SavePluginSetting.module.scss'

const pluginTypes: PluginType[] = ['PAYMENT', 'EXTERNAL_SHOP_FEED', 'FULFILLMENT', 'MAILING', 'SMS', 'DEPLOYMENT']

const pluginNames = {
  PAYMENT: ['AUTHORIZE', 'STRIPE', 'PAYPAL'],
  EXTERNAL_SHOP_FEED: ['GOOGLE_SHOPPING', 'FACEBOOK_CATALOG'],
  FULFILLMENT: ['CUSTOM_GATEWAY', 'SHIPSTATION'],
  MAILING: ['SENDGRID', 'POSTMARK'],
  SMS: ['TWILIO'],
  DEPLOYMENT: ['AMAZON', 'NGINX']
}

type Props = {
  ownerType: PluginSettingOwner
  ownerId: number
  pluginName?: AvailablePluginNames
  pluginType?: PluginType
  visible: boolean
  onClose: () => void
}

const SavePluginSetting = (props: Props) => {
  const { onClose, ownerId, ownerType, visible } = props

  const [pluginName, setPluginName] = useState<AvailablePluginNames>()
  const [pluginType, setPluginType] = useState<PluginType>()
  const [uploadSettings, setUploadSettings] = useState({})
  const [showDefaultSettings, setShowDefaultSettings] = useState<boolean>(false)

  const [form] = Form.useForm()

  const { workspaceId } = useContext(GlobalContext)

  const { data: defaultPluginSettings } = useGetDefaultPluginSettings(pluginType, pluginName)
  const savePluginSettings = useSavePluginSettings()

  const { data: pluginSetting } = usePluginSettings(workspaceId, ownerType, ownerId, props.pluginType)

  useEffect(() => {
    if (props.pluginName) {
      setPluginName(props.pluginName)
    }
  }, [props.pluginName])

  useEffect(() => {
    if (props.pluginType) {
      setPluginType(props.pluginType)
    }
  }, [props.pluginType])

  const handleClose = () => {
    onClose()
    form.resetFields()
    setPluginType(undefined)
    setPluginName(undefined)
  }

  useEffect(() => {
    if (savePluginSettings.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Plugin Settings was saved successfully!'
      })
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savePluginSettings.isSuccess])

  useEffect(() => {
    if (pluginSetting) {
      const initialValues = {}

      if (pluginSetting.settings.length) {
        for (const setting of pluginSetting.settings) {
          initialValues[setting.setting] = setting.value
        }
      }

      form.setFieldsValue(initialValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pluginSetting])

  useEffect(() => {
    if (defaultPluginSettings && showDefaultSettings) {
      const initialValues = {}

      if (defaultPluginSettings.length) {
        for (const setting of defaultPluginSettings) {
          initialValues[setting.setting] = setting.value
        }
      }

      form.setFieldsValue(initialValues)
      setShowDefaultSettings(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPluginSettings, showDefaultSettings])

  const onFinishHandler = (values) => {
    const settings = []

    const combinedSettings = {
      ...values,
      ...uploadSettings
    }

    Object.keys(combinedSettings).forEach((key) => {
      settings.push({ setting: key, value: combinedSettings[key]?.toString() || '' })
    })

    savePluginSettings.mutate({
      workspaceId,
      ownerId,
      ownerType,
      pluginName,
      pluginType,
      settings
    })
  }

  const onSelectPluginType = (pluginType: PluginType) => {
    setPluginType(pluginType)
    setPluginName(undefined)
    form.resetFields()
  }

  const onSelectPluginName = (pluginName: AvailablePluginNames) => {
    setPluginName(pluginName)
    form.resetFields()
  }

  const onUploadFile = (setting: string, value: string) => {
    const newUploadSettings = { ...uploadSettings, [setting]: value }
    setUploadSettings(newUploadSettings)
  }

  return (
    <Drawer title='Save Plugin Settings' width='400' onClose={handleClose} visible={visible} forceRender>
      <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form} style={{ paddingBottom: '40px' }}>
        <Select
          style={{ width: '100%', marginBottom: '24px' }}
          placeholder='Select Plugin Type'
          value={pluginType}
          filterOption={false}
          onChange={onSelectPluginType}
          disabled={!!props.pluginType && !!props.pluginName}
        >
          {pluginTypes.map((item) => (
            <Select.Option key={item} value={item}>
              {item[0] + item.slice(1).toLowerCase().split('_').join(' ')}
            </Select.Option>
          ))}
        </Select>

        {pluginType && (
          <Select
            style={{ width: '100%', marginBottom: '24px' }}
            placeholder='Select Plugin'
            value={pluginName}
            filterOption={false}
            onChange={onSelectPluginName}
            disabled={!!props.pluginType && !!props.pluginName}
          >
            {pluginNames[pluginType].map((item) => (
              <Select.Option key={item} value={item}>
                {item[0] + item.slice(1).toLowerCase().split('_').join(' ')}
              </Select.Option>
            ))}
          </Select>
        )}

        <PluginSettingForm callback={onUploadFile} pluginName={pluginName} />

        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right'
          }}
        >
          {pluginName === 'CUSTOM_GATEWAY' && (
            <Button type='link' onClick={() => setShowDefaultSettings(true)} className={styles.createButton}>
              Use Default
            </Button>
          )}
          <Button onClick={handleClose} className={styles.createButton}>
            Close
          </Button>
          <Button type='primary' htmlType='submit' loading={savePluginSettings.isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default SavePluginSetting
