import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { CustomGatewaySetting, CustomGatewaySettingInput } from '../types'

export type CreateCustomGatewaySettingAction = FSA<undefined, CustomGatewaySetting, string>
type CreateCustomGatewaySetting = (
  customGatewaySettingData: CustomGatewaySettingInput
) => MrxThunk<CreateCustomGatewaySettingAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'createCustomGatewaySetting', CustomGatewaySetting>

const CREATE_CUSTOM_GATEWAY_SETTING = `
  mutation createCustomGatewaySetting ($workspaceId: Int!, $customGatewaySettingData: CustomGatewaySettingInput!) {
    createCustomGatewaySetting (workspaceId: $workspaceId, customGatewaySettingData: $customGatewaySettingData) {
      id
      name
      shippingCarrier
      carrierCode
      serviceCode
      clientId
      clientSecret
      retailerApiKey
      retailerCompanyRefId
      supplierApiKey
      supplierCompanyRefId
      internalLogin
      internalPassword
    }
  }
`

const createCustomGatewaySetting: CreateCustomGatewaySetting = (customGatewaySettingData) => async (dispatch, getState) => {
  let isSaved = false

  dispatch({
    type: t.CREATE_CUSTOM_GATEWAY_SETTING,
    meta: { done: false }
  })

  try {
    const workspaceId = getState()?.workspaces?.activeWorkspace?.id
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_CUSTOM_GATEWAY_SETTING,
      variables: { workspaceId, customGatewaySettingData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createCustomGatewaySetting } = data
    if (!createCustomGatewaySetting) {
      throw new Error("Can't create Custom Gateway Setting!")
    }

    dispatch({
      type: t.CREATE_CUSTOM_GATEWAY_SETTING,
      payload: createCustomGatewaySetting,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.CREATE_CUSTOM_GATEWAY_SETTING,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { createCustomGatewaySetting }
