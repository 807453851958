import * as t from '../actionTypes'

type Payload = { campaignId: number; page: number }
export type ChangeCurrentPageAction = BaseFSA<Payload>

type ChangeCurrentPage = (campaignId: number, page: number) => MrxThunk<ChangeCurrentPageAction>

const changeCurrentPage: ChangeCurrentPage = (campaignId, page) => async (dispatch) => {
  dispatch({
    type: t.CHANGE_CURRENT_PAGE,
    payload: { campaignId, page }
  })
}

export { changeCurrentPage }
