// TODO - remove becouse of legacy

import { useQuery } from 'react-query'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { QuizzesListFilters } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'quizzesForOptions', SelectOption[]>

const FETCH_QUIZZES_OPTIONS = `
  query fetchQuizzesForOptions($filters: QuizzesListPageFilters!) {
    quizzesForOptions(filters: $filters) {
      id
      name
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchQuizzesOptions = (args: QuizzesListFilters) => Promise<SelectOption[]>

const fetchQuizzesOptions: FetchQuizzesOptions = async (filters) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_QUIZZES_OPTIONS,
      variables: { filters }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { quizzesForOptions } = data
    if (!quizzesForOptions) {
      throw new Error("Can't get quizzes options!")
    }

    return quizzesForOptions
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useQuizzesOptions = (args: QuizzesListFilters) => useQuery('quizzesOptions', () => fetchQuizzesOptions(args))
