import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { DomainDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'removeDomain', boolean>

const REMOVE_DOMAIN = `
  mutation removeDomain ($domainId: Int!) {
    removeDomain(domainId: $domainId) 
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveDomain = (domainId: number) => Promise<number>

const removeDomain: RemoveDomain = async (domainId) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_DOMAIN,
      variables: {
        domainId
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeDomain } = data
    if (!removeDomain) {
      throw new Error("Can't remove domain!")
    }

    return domainId
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveDomainContext = { prevDomain?: DomainDto }

type RemoveDomainEvents = {
  onMutate: (domainId: number) => Promise<RemoveDomainContext>
  onError: (error: string, domainId: number, context: RemoveDomainContext) => void
  onSettled: (domainId?: number) => void
}

const removeDomainEvents: RemoveDomainEvents = {
  onMutate: async (domainId) => {
    await queryClient.cancelQueries(['domain', domainId])

    // Snapshot the previous value
    const prevDomain = queryClient.getQueryData<DomainDto>(['domain', domainId])

    if (prevDomain) {
      queryClient.removeQueries(['domain', domainId])
    }

    return { prevDomain }
  },
  onError: (_err, domainId, context) => {
    if (context?.prevDomain) {
      // Restore currrency on any error
      queryClient.setQueryData<DomainDto>(['domain', domainId], context.prevDomain)
    }
  },
  onSettled: (domainId: number) => {
    if (domainId) {
      queryClient.invalidateQueries(['domain', domainId])
      queryClient.invalidateQueries(['domainsList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useRemoveDomain = () => useMutation(removeDomain, removeDomainEvents)
