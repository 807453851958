import React, { useContext, useEffect, useRef, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Avatar, Button, Drawer, Input, Form, Select, Tag, Tooltip, Divider, Switch } from 'antd'
import notification from 'mrx-notification'
import { GlobalContext } from 'appContexts'
import { DomainsSelect } from 'features/domains/components'
import { useTagsOptions, useSaveTags } from 'features/tags/hooks'
import { useCreateCampaign } from '../../hooks'
import styles from './CreateCampaign.module.scss'

const { TextArea } = Input

type PropsType = {
  isForMenu?: boolean
}

const CreateCampaign = (props: PropsType) => {
  const { isForMenu = false } = props

  const { workspaceId } = useContext(GlobalContext)

  const [isVisible, setVisible] = useState<boolean>(false)

  const [tagsState, setTagsState] = useState([])
  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [editInputIndex, setEditInputIndex] = useState(-1)
  const [editInputValue, setEditInputValue] = useState('')
  const [editInputRef, setEditInputRef] = useState()
  const [inputRef, setInputRef] = useState()
  const [hasCustomDomainState, setHasCustomDomainState] = useState(false)
  const [isDTGCampaign, setIsDTGCampaign] = useState<boolean>()

  const refEl = useRef<Input>(null)
  const [form] = Form.useForm()

  const createCampaign = useCreateCampaign()
  const tagsForOptions = useTagsOptions({ workspaceId, ownerType: 'CAMPAIGN' })
  const saveTags = useSaveTags()

  useEffect(() => {
    if (isVisible) {
      refEl.current && refEl.current.focus()
    }
  }, [isVisible])

  useEffect(() => {
    if (inputVisible === true && inputRef) {
      // @ts-ignore
      inputRef.focus()
    }
  }, [inputVisible, inputRef])

  useEffect(() => {
    if (editInputRef) {
      // @ts-ignore
      editInputRef.focus()
    }
  }, [inputVisible, editInputRef])

  const handleClose = () => {
    setVisible(false)
    form.resetFields()
    setHasCustomDomainState(false)
    setIsDTGCampaign(false)
  }

  useEffect(() => {
    if (createCampaign.isSuccess) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createCampaign.isSuccess])

  const showDrawer = () => {
    setVisible(!isVisible)
  }

  const onFinishHandler = async (values: any) => {
    createCampaign.mutate(
      {
        workspaceId: workspaceId,
        campaignData: {
          name: values.name,
          defaultDomain: values.defaultDomain,
          hasCustomDomain: hasCustomDomainState,
          domainId: values.domainId,
          DTGCampaignId: values.DTGCampaignId,
          seoTitle: values.seoTitle,
          seoDescription: values.seoDescription,
          seoKeywords: values.seoKeywords,
          googleGlobalTag: values.googleGlobalTag
        }
      },
      {
        onSuccess: () => {
          saveTags.mutate({ ownerId: createCampaign?.data?.id, ownerType: 'CAMPAIGN', tags: tagsState })

          notification.success({
            message: 'Successfully',
            description: 'Campaign was created successfully!'
          })
          handleClose()
        },
        onError: (error) => {
          notification.error({
            message: 'Error!',
            description: error.toString()
          })
        }
      }
    )
  }

  const handleCloseTag = (removedTag) => {
    setTagsState(tagsState.filter((tag) => tag !== removedTag))
  }

  const showInput = () => {
    setInputVisible(true)
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  const handleInputConfirm = () => {
    let tags = tagsState
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue]
    }
    setTagsState(tags)
    setInputVisible(false)
    setInputValue('')
  }

  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value)
  }

  const handleEditInputConfirm = () => {
    const newTags = [...tagsState]
    newTags[editInputIndex] = editInputValue

    setTagsState(newTags)
    setEditInputIndex(-1)
    setEditInputValue('')
  }

  const saveInputRef = (input) => {
    setInputRef(input)
  }

  const saveEditInputRef = (input) => {
    setEditInputRef(input)
  }

  const handleTagsSelectorChange = (value) => {
    if (!tagsState.includes(value)) {
      setTagsState([...tagsState, value])
    }

    form.setFieldsValue({
      tags: ''
    })
  }

  const handleHasOwnDomainChange = () => {
    setHasCustomDomainState(!hasCustomDomainState)
  }

  return (
    <>
      {!isForMenu && (
        <Button type='primary' onClick={showDrawer}>
          <PlusOutlined />
          CREATE CAMPAIGN
        </Button>
      )}

      {isForMenu && (
        <div onClick={showDrawer}>
          <Avatar shape='square' size='large' icon={<PlusOutlined />} className={styles.button}>
            CREATE
          </Avatar>
        </div>
      )}
      <Drawer bodyStyle={{ padding: 0 }} title='Create Campaign' width='460' onClose={handleClose} visible={isVisible} forceRender>
        <Form layout='vertical' className={styles.form} hideRequiredMark onFinish={onFinishHandler} form={form}>
          <div className={styles.formItems}>
            <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input campaign name!' }]}>
              <Input ref={refEl} />
            </Form.Item>

            <Form.Item
              name='defaultDomain'
              label='Default domain'
              className={styles.groupItem}
              rules={[{ required: true, message: 'Please input default domain name!' }]}
            >
              <Input addonBefore='https://' addonAfter='.merchx.com' /* onChange={handleDefaultDomainChanged} */ />
            </Form.Item>

            <Form.Item name='hasCustomDomain' label='Has custom domain' valuePropName='checked' className={styles.groupItem}>
              <Switch onChange={handleHasOwnDomainChange} />
            </Form.Item>

            {hasCustomDomainState && (
              <Form.Item
                name='domainId'
                label='Domain'
                className={styles.groupItem}
                rules={[{ required: true, message: 'Please select domain!' }]}
              >
                <DomainsSelect
                  initialOption={{
                    id: 0,
                    name: 'Select a domain'
                  }}
                  onSelect={(selectedOption: SelectOption) => {
                    form.setFieldsValue({
                      domainId: selectedOption?.id
                    })
                  }}
                />
              </Form.Item>
            )}

            <Divider plain className={styles.divider}>
              Tags
            </Divider>

            <Form.Item name='tags' label='Tags'>
              <Select style={{ width: '100%' }} placeholder='Choose existing tag' onChange={handleTagsSelectorChange}>
                {tagsForOptions.data !== [] &&
                  tagsForOptions.data?.map((item) => {
                    return (
                      <Select.Option value={item} key={item}>
                        {item}
                      </Select.Option>
                    )
                  })}
              </Select>
            </Form.Item>

            {tagsState &&
              tagsState.map((tag, index) => {
                if (editInputIndex === index) {
                  return (
                    <Input
                      ref={saveEditInputRef}
                      key={tag}
                      size='small'
                      style={{ width: '78px', marginRight: '8px', marginTop: '10px', verticalAlign: 'top' }}
                      value={editInputValue}
                      onChange={handleEditInputChange}
                      onBlur={handleEditInputConfirm}
                      onPressEnter={handleEditInputConfirm}
                    />
                  )
                }

                const isLongTag = tag.length > 20

                const tagElem = (
                  <Tag style={{ userSelect: 'none', marginTop: '10px' }} key={tag} closable onClose={() => handleCloseTag(tag)}>
                    <span
                      onDoubleClick={(e) => {
                        setEditInputIndex(index)
                        setEditInputValue(tag)
                        e.preventDefault()
                      }}
                    >
                      {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                    </span>
                  </Tag>
                )
                return isLongTag ? (
                  <Tooltip title={tag} key={tag}>
                    {tagElem}
                  </Tooltip>
                ) : (
                  tagElem
                )
              })}

            {inputVisible && (
              <Input
                ref={saveInputRef}
                type='text'
                size='small'
                // is's not working with scss, just inline
                style={{
                  width: '100%',
                  marginTop: '15px',
                  verticalAlign: 'top',
                  height: '30px',
                  display: 'flex',
                  alignItems: 'center'
                }}
                className={styles.tagInput}
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputConfirm}
                onPressEnter={handleInputConfirm}
              />
            )}
            {!inputVisible && (
              <Tag onClick={showInput} style={{ width: '100%', marginTop: '15px', height: '30px', display: 'flex', alignItems: 'center' }}>
                <PlusOutlined style={{ marginRight: '10px' }} /> CREATE NEW TAG
              </Tag>
            )}

            <Divider plain className={styles.divider}>
              SEO
            </Divider>

            <Form.Item name='seoTitle' label='Title' className={styles.groupItem}>
              <Input />
            </Form.Item>

            <Form.Item name='seoDescription' label='Description' className={styles.groupItem}>
              <TextArea />
            </Form.Item>

            <Form.Item name='seoKeywords' label='Keywords' className={styles.groupItem}>
              <TextArea />
            </Form.Item>

            <Form.Item name='googleGlobalTag' label='Google Global Tag' className={styles.groupItem}>
              <TextArea />
            </Form.Item>

            <Form.Item name='isDTGCampaign' label='DTG Campaign' className={styles.dtgCampaignSwitch}>
              <Switch onChange={setIsDTGCampaign} />
            </Form.Item>

            {isDTGCampaign && (
              <Form.Item name='DTGCampaignId' label='DTG Campaign ID'>
                <Input />
              </Form.Item>
            )}
          </div>
          <div className={styles.buttonsContainer}>
            <Button onClick={handleClose} className={styles.closeButton}>
              Close
            </Button>
            <Button type='primary' htmlType='submit' loading={createCampaign.isLoading}>
              Create
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

export default CreateCampaign
