import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { ProductTag, GraphQLProductTag } from '../types'

export type CreateProductTagAction = FSA<undefined, ProductTag, string>
type CreateProductTag = (name: string) => MrxThunk<CreateProductTagAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'createProductTag', GraphQLProductTag>

const CREATE_PRODUCT_TAG = `
mutation createProductTag($name: String!) {
  createProductTag(name: $name) {
    id
    name
    createdAt
    updatedAt
  }
}
`

const createProductTag: CreateProductTag = name => async dispatch => {
  let isSaved = false

  dispatch({
    type: t.CREATE_PRODUCT_TAG,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_PRODUCT_TAG,
      variables: { name }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createProductTag } = data
    if (!createProductTag) {
      throw new Error("Can't create product tag!")
    }

    dispatch({
      type: t.CREATE_PRODUCT_TAG,
      payload: createProductTag,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.CREATE_PRODUCT_TAG,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { createProductTag }
