import React, { useContext, useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Table, Layout, Popconfirm, PageHeader, Tag, Select, Button, Input, Drawer, Form, Switch } from 'antd'
import { DeleteTwoTone, EditTwoTone, EyeOutlined, EyeInvisibleFilled } from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table'
import notification from 'mrx-notification'
import { GlobalContext } from 'appContexts'
import { CreateCampaign, UpdateCampaign } from 'features/campaigns/components'
import * as rootStyles from 'assets/layoutStyle'
import styles from './CampaignsList.module.scss'
import { Breadcrumbs } from 'components'
import { useTagsOptions } from 'features/tags/hooks'
import { useArchiveCampaign, useCampaignsList, useUnarchiveCampaign, useRemoveCampaign } from '../../hooks'
import { Campaign } from '../../types'

const mapDTG = {
  true: true,
  false: false
}

const CampaignsList = () => {
  const [selectedCampaignId, setSelectedCampaignId] = useState<string>()
  const [sorting, setSorting] = useState<SortingType>()
  const [isUpdateDrawerVisible, setIsUpdateDrawerVisible] = useState(false)
  const [tagsForFilter, setTagsForFilter] = useState<string[]>([])
  const [searchText, setSearchText] = useState<string>()
  const [isVisible, setVisible] = useState(false)
  const [isDTG, setIsDTG] = useState<string>('all')
  const [showArchived, toggleArchived] = useState(false)

  const { history } = useReactRouter()

  const { workspaceId } = useContext(GlobalContext)

  const tagsForOptions = useTagsOptions({ workspaceId, ownerType: 'CAMPAIGN' })
  const archiveCampaign = useArchiveCampaign()
  const unarchiveCampaign = useUnarchiveCampaign()
  const removeCampaign = useRemoveCampaign()
  const campaignsList = useCampaignsList({
    workspaceId,
    tags: tagsForFilter,
    sorting,
    searchText,
    isDTG: mapDTG[isDTG],
    showArchived
  })
  const campaigns: Campaign[] = []
  campaignsList &&
    campaignsList.data &&
    campaignsList.data.pages.forEach((page) => page.items.forEach((campaign) => campaigns.push(campaign)))

  const page = campaignsList.data?.pages.length ? campaignsList.data?.pages[campaignsList.data?.pages.length - 1].currentPage : 1
  const size = campaignsList.data?.pages.length ? campaignsList.data?.pages[campaignsList.data?.pages.length - 1].pageSize : 30

  useEffect(() => {
    if (campaignsList.error) {
      notification.error({
        message: 'Campaigns list page error!',
        description: campaignsList.error instanceof Error ? campaignsList.error.message : campaignsList.error.toString()
      })
    }
  }, [campaignsList.error])

  const doConfirm = async (campaignId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    removeCampaign.mutate(campaignId, {
      onSuccess: () => {
        notification.success({
          message: 'Successfully',
          description: 'Campaign was deleted successfully!'
        })
      },
      onError: (error) => {
        notification.error({
          message: 'Remove campaign error!',
          description: error.toString()
        })
      }
    })
  }

  const doConfirmArchive = async (campaignId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    archiveCampaign.mutate(campaignId, {
      onSuccess: () => {
        notification.success({
          message: 'Successfully',
          description: 'Campaign  was archived successfully!'
        })
      },
      onError: (error) => {
        notification.error({
          message: 'Archive campaign error!',
          description: error.toString()
        })
      }
    })
  }

  const doConfirmUnarchive = async (campaignId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    unarchiveCampaign.mutate(campaignId, {
      onSuccess: () => {
        notification.success({
          message: 'Successfully',
          description: 'Campaign was made visible successfully!'
        })
      },
      onError: (error) => {
        notification.error({
          message: 'Unarchive campaign error!',
          description: error.toString()
        })
      }
    })
  }

  const sortingHandler = (pagination, filters, sorter) => {
    if (sorter?.order) {
      setSorting({ field: sorter.field, order: sorter.order === 'ascend' ? 'ASC' : 'DESC' })
    } else {
      setSorting(undefined)
    }
  }

  const columns: ColumnsType<Campaign> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      render: (tags) => (
        <>
          {tags &&
            tags.map((tag) => {
              return (
                <Tag key={tag} color='green'>
                  {tag.toUpperCase()}
                </Tag>
              )
            })}
        </>
      )
    },
    {
      title: '',
      dataIndex: '',
      key: 'archived',
      render: (record) => (
        <>
          {!record.archived ? (
            <span>
              <Popconfirm
                title='Are you sure archive this campaign?'
                onConfirm={(e) => doConfirmArchive(record.id, e)}
                onCancel={(e) => e?.stopPropagation()}
                okText='Yes'
                cancelText='No'
              >
                <EyeInvisibleFilled
                  style={{ fontSize: '20px', marginRight: '20px' }}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                />
              </Popconfirm>
            </span>
          ) : (
            <span>
              <Popconfirm
                title='Are you sure make visible this template?'
                onConfirm={(e) => doConfirmUnarchive(record.id, e)}
                onCancel={(e) => e?.stopPropagation()}
                okText='Yes'
                cancelText='No'
              >
                <EyeOutlined
                  style={{ fontSize: '20px', marginRight: '20px' }}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                />
              </Popconfirm>
            </span>
          )}
        </>
      )
    },
    {
      title: '',
      dataIndex: '',
      key: 'edit',
      width: '10%',
      align: 'center',
      render: (record) => (
        <span>
          <EditTwoTone
            style={{ fontSize: '20px' }}
            onClick={(e) => {
              e.stopPropagation()
              setSelectedCampaignId(record.id)
              history.push(`/campaigns/${record.id}`)
            }}
          />
        </span>
      )
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '10%',
      align: 'center',
      render: (record: any) => (
        <Popconfirm
          title='Are you sure delete this landing?'
          onConfirm={(e) => doConfirm(record.id, e)}
          onCancel={(e) => e?.stopPropagation()}
          okText='Yes'
          cancelText='No'
        >
          <DeleteTwoTone
            twoToneColor='#ec1c24'
            style={{ fontSize: '20px' }}
            onClick={(e) => {
              e.stopPropagation()
            }}
          />
        </Popconfirm>
      )
    }
  ]

  const handleDrawerClose = () => {
    setVisible(false)
  }

  const handleOnClose = () => {
    setIsUpdateDrawerVisible(false)
  }

  const handleTagsForFilterChange = (value) => {
    setTagsForFilter(value)
  }

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title='Campaigns'
          extra={[
            <Switch
              checkedChildren='Show archived'
              unCheckedChildren='Show archived'
              defaultChecked={showArchived}
              key='3'
              onChange={toggleArchived}
            />,
            <Button key='1' onClick={() => setVisible(!isVisible)}>
              Settings
            </Button>,
            <Input.Search
              key='search-field'
              placeholder='Search'
              onSearch={(value) => setSearchText(value)}
              style={{ width: 200 }}
              allowClear
            />,
            <CreateCampaign key='4' />
          ]}
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Campaigns', url: '/' },
            { title: 'Campaigns List', url: '/' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <div className={styles.tableContainer}>
          <InfiniteScroll
            style={{ paddingBottom: '30px' }}
            dataLength={page * size}
            next={campaignsList.fetchNextPage}
            hasMore={!!campaignsList.hasNextPage}
            loader={<h4>Loading...</h4>}
            refreshFunction={campaignsList.refetch}
            scrollThreshold={0.8}
          >
            <Table
              columns={columns}
              dataSource={campaigns.map((item) => item).flat()}
              bordered
              rowKey={(record) => record.id}
              loading={campaignsList.isLoading}
              onChange={sortingHandler}
              pagination={false}
            />
          </InfiniteScroll>
        </div>
      </Layout.Content>
      {selectedCampaignId && <UpdateCampaign campaignId={+selectedCampaignId} onClose={handleOnClose} visible={isUpdateDrawerVisible} />}
      <Drawer title='Campaign Data Settings' width='400' onClose={handleDrawerClose} visible={isVisible}>
        <Form layout='vertical' hideRequiredMark>
          <Form.Item name='campaignId' label='Campaigns'>
            <Select
              key='multiple-select'
              mode='multiple'
              placeholder='Filter by tags'
              defaultValue={tagsForFilter}
              onChange={handleTagsForFilterChange}
              allowClear
              value={tagsForFilter}
            >
              {tagsForOptions &&
                tagsForOptions.data?.map((item) => {
                  return (
                    <Select.Option value={item} key={item}>
                      {item}
                    </Select.Option>
                  )
                })}
            </Select>
          </Form.Item>
          <Form.Item name='dtg' label='DTG'>
            <Select
              key='dtg-select'
              placeholder='Filter by DTG'
              defaultValue='all'
              onChange={(value) => setIsDTG(value)}
              allowClear
              value={isDTG}
            >
              <Select.Option key='all' value='all'>
                All
              </Select.Option>
              <Select.Option key='yes' value='true'>
                Yes
              </Select.Option>
              <Select.Option key='no' value='false'>
                No
              </Select.Option>
            </Select>
          </Form.Item>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button type='primary' htmlType='submit' loading={campaignsList.isLoading} onClick={handleDrawerClose}>
              Close
            </Button>
          </div>
        </Form>
      </Drawer>
    </Layout>
  )
}

export default CampaignsList
