import React, { useContext, useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import { Form, Layout, PageHeader, Typography, Switch, Input, Button, UploadFile, Tooltip } from 'antd'
import { Store } from 'antd/lib/form/interface'
import { WarningTwoTone } from '@ant-design/icons'
import hash from 'object-hash'
import { CustomGatewayArtworkDto, ARTWORK_FIT_MODE } from '@merchx-v2/shared-types'
import { getProductPriceVariantValue } from '@merchx-v2/shared-frontend-functions'
import notification from 'mrx-notification'
import { ProductType2, ProductTypes } from 'consts'
import { GlobalContext } from 'appContexts'
import { queryClient } from 'queryClient'
import settings from 'config/settings'

import { ManageFormSeo, ProgressLoader } from 'components'
import * as rootStyles from 'assets/layoutStyle'
import { Attribute, ArtworkInput } from 'features/types'
import { useRemoveAsset, useUploadAsset, createPresignedUrl } from 'features/assets/hooks'
import { useCampaign } from 'features/campaigns/hooks'
import { useCreateCustomGatewayArtwork, useUpdateCustomGatewayArtwork } from 'features/customGatewayArtwork/hooks'
import { isArtworksInputValid } from 'utils/isArtworksInputValid'

import { useProduct } from '../../hooks'
import { VariantPriceInputV2 } from 'features/products/types'
import EditProdctInfo from '../EditProductInfo'
import EditProdctPrices from '../EditProductPrices'
import EditProdctVariants from '../EditProductVariants'
import EditProductSupplier from '../EditProductSupplier'
import EditProductType from '../EditProductType'
import { PropsFromRedux, connector } from './container'
import styles from './EditProduct.module.scss'

const seoUrlPrefix = `${settings.shopUrl}/products`

interface EditProductFormStore extends Store {
  supplierId: number
  supplierProductId: number | null

  displayName: string
  tags: string[]
  description: string

  storeId: number | null

  productType: ProductTypes
  productType2: ProductType2
  sku: string
  attributes?: Attribute[]
  customizable?: boolean

  hasCustomShipping?: boolean
  customShippingPrice?: string

  shouldAddMainVariant: boolean
  mainVariant?: string
  mainVariantValues?: string[]

  shouldAddSecondaryVariant?: boolean
  secondaryVariant?: string
  secondaryVariantValues?: string[]

  price?: VariantPriceInputV2
  prices?: Record<string, VariantPriceInputV2> | Record<string, Record<string, VariantPriceInputV2>>

  hasCustomSeo: boolean
  seoTitle: string
  seoUrl: string
  seoDescription: string
  seoKeywords: string

  images?: UploadFile[]
  mainVariantImages?: Record<string, UploadFile[]>

  artworks: CustomGatewayArtworkDto[]
  artworksInput: Record<string, ArtworkInput>
  disabledVariantKeys?: string[]
  disabledMainVariants?: string[]
  removedVariantKeys?: string[]
}

const EditProduct = (props: PropsFromRedux) => {
  const { productId, landingId, isLoading, productError, createProduct, updateProductV2, setActiveProduct } = props

  const { campaignId } = useContext(GlobalContext)

  const productPriceOwnerType = landingId ? 'LANDING' : 'CAMPAIGN'
  const productPriceOwnerId = landingId || campaignId

  const product = useProduct({ campaignId, productId, productPriceOwnerType, productPriceOwnerId })
  const campaign = useCampaign(campaignId)
  const uploadAsset = useUploadAsset()
  const removeAsset = useRemoveAsset()
  const createArtwork = useCreateCustomGatewayArtwork()
  const updateArtwork = useUpdateCustomGatewayArtwork()

  const [isActive, setIsActive] = useState(false)
  const [isSaveLoading, setIsSaveLoading] = useState(false)
  const [isSavingFinished, setIsSavingFinished] = useState(false)

  const { history } = useReactRouter()
  const [form] = Form.useForm()

  const supplierProductId = Form.useWatch('supplierProductId', form)
  const supplierProduct = useProduct({ campaignId, productId: supplierProductId, productPriceOwnerId, productPriceOwnerType })

  const productImages = Form.useWatch('images', form)

  const mainVariantValues: string[] = Form.useWatch('mainVariantValues', form) || []
  const mainVariant = Form.useWatch('mainVariant', form)
  const shouldAddMainVariant = Form.useWatch('shouldAddMainVariant', form)

  const productVariantImages = Form.useWatch('mainVariantImages', form)

  useEffect(() => {
    if (campaign.error) {
      notification.error({
        message: 'Create Product error!',
        description: campaign.error instanceof Error ? campaign.error.message : campaign.error.toString()
      })
    }
  }, [campaign.error])

  useEffect(() => {
    if (productError) {
      notification.error({
        message: 'Create Product error!',
        description: productError
      })
    }
  }, [productError])

  useEffect(() => {
    if (product.error) {
      notification.error({
        message: 'Create Product error!',
        description: product.error instanceof Error ? product.error.message : product.error.toString()
      })
    }
  }, [product.error])

  useEffect(() => {
    if (removeAsset.error) {
      notification.error({
        message: 'Error!',
        description: removeAsset.error.toString()
      })
    }
  }, [removeAsset.error])

  useEffect(() => {
    if (removeAsset.isSuccess) {
      notification.success({
        message: 'Success!',
        description: 'Asset was delete successfully'
      })
    }
  }, [removeAsset.isSuccess])

  useEffect(() => {
    if (uploadAsset.error) {
      notification.error({
        message: 'Error!',
        description: uploadAsset.error instanceof Error ? uploadAsset.error.message : uploadAsset.error.toString()
      })
    }
  }, [uploadAsset.error])

  useEffect(() => {
    if (uploadAsset.isSuccess) {
      notification.success({
        message: 'Success!',
        description: 'Asset was upload successfully'
      })
    }
  }, [uploadAsset.isSuccess])

  useEffect(() => {
    if (createArtwork.error) {
      notification.error({
        message: 'Error!',
        description: createArtwork.error instanceof Error ? createArtwork.error.message : createArtwork.error.toString()
      })
    }

    if (updateArtwork.error) {
      notification.error({
        message: 'Error!',
        description: updateArtwork.error instanceof Error ? updateArtwork.error.message : updateArtwork.error.toString()
      })
    }
  }, [createArtwork.error, updateArtwork.error])

  const handleFormClose = () => {
    setIsActive(false)
    setIsSaveLoading(false)
    form.resetFields()
    product.remove()
    history.push('/products')
  }

  useEffect(() => {
    if (isSavingFinished && !isLoading && !uploadAsset.isLoading) {
      handleFormClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSavingFinished, isLoading, uploadAsset])

  useEffect(() => {
    form.setFieldsValue({
      customizable: false
    })

    if (product.data) {
      const attributes = product.data.attributes?.filter((item) => item.attribute !== 'DTG_PRODUCT_ID') // TODO this attribute will be unused
      const mainVariant = product.data.variantValues.find((item) => item.isMainOption)
      const secondaryVariant = product.data.variantValues.find((item) => !item.isMainOption)

      let price: VariantPriceInputV2
      let prices: Record<string, VariantPriceInputV2> | Record<string, Record<string, VariantPriceInputV2>> = {}

      if (!mainVariant && !secondaryVariant && product.data.prices?.length) {
        const data = product.data.prices[0]

        price = {
          price: (data.price / 100).toFixed(2),
          discount: (data.price / 100).toFixed(2),
          cost: '0.00',
          sku: data.sku
        }
      }

      if (!price && product.data.prices?.length) {
        prices = (product.data.prices || []).reduce((accum, current) => {
          if (!current.variantKey) return accum
          if (!current.variantSchema) return accum

          const currMainVariant = getProductPriceVariantValue(current.variantSchema, current.variantKey, mainVariant?.variantOption || '')

          if (!currMainVariant) return accum

          if (secondaryVariant) {
            const currSecondaryVariant = getProductPriceVariantValue(
              current.variantSchema,
              current.variantKey,
              secondaryVariant.variantOption
            )

            if (!currSecondaryVariant) return accum

            if (!accum[currMainVariant]) {
              accum[currMainVariant] = {}
            }

            accum[currMainVariant][currSecondaryVariant] = {
              price: (current.price / 100).toFixed(2),
              discount: (current.discount / 100).toFixed(2),
              memberPrice: (current.memberPrice / 100).toFixed(2),
              sku: current.sku
            }
          } else {
            accum[currMainVariant] = {
              price: (current.price / 100).toFixed(2),
              discount: (current.discount / 100).toFixed(2),
              memberPrice: (current.memberPrice / 100).toFixed(2),
              sku: current.sku
            }
          }

          return accum
        }, {})
      }

      const images = []
      for (const productImage of product.data.assets) {
        images.push({
          uid: productImage.id + '',
          name: productImage.name,
          status: 'done',
          size: 0,
          type: productImage.mimeType,
          url: productImage.signedUrl
        } as UploadFile)
      }
      const mainVariantImages = product.data.variantValues.reduce((accum, currentVariant) => {
        if (!currentVariant.isMainOption) return accum

        for (const variant of currentVariant.valueObjects || []) {
          // @ts-ignore
          accum[variant.value] =
            variant.assets?.map((item) => ({
              uid: item.id + '',
              name: item.name,
              status: 'done',
              size: 0,
              type: item.mimeType,
              url: item.signedUrl
            })) || []
        }

        return accum
      }, {} as Record<string, UploadFile>)

      const mainPrice = product.data.prices.find((price) => price.isMainPrice)

      form.setFieldsValue({
        supplierId: product.data.supplierProductId ? undefined : -1,
        supplierProductId: product.data.supplierProductId || null,

        displayName: product.data.displayName,
        tags: product.data.tags,
        description: product.data.description,

        storeId: product.data.storeId,

        productType: product.data.productType,
        productType2: product.data.productType2,
        sku: product.data.sku,
        attributes,
        customizable: product.data.customizable,

        hasCustomShipping: product.data.hasCustomShipping,
        customShippingPrice: (product.data.customShippingPrice / 100).toFixed(2),

        shouldAddMainVariant: !!mainVariant?.variantOption,
        mainVariant: mainVariant?.variantOption,
        mainVariantValues: mainVariant?.valueObjects?.map((item) => item.value),

        shouldAddSecondaryVariant: !!secondaryVariant?.variantOption,
        secondaryVariant: secondaryVariant?.variantOption,
        secondaryVariantValues: secondaryVariant?.valueObjects?.map((item) => item.value),

        price,
        prices,

        hasCustomSeo: product.data.hasCustomSeo,
        seoTitle: product.data.seoTitle,
        seoUrl: product.data.seoUrl,
        seoDescription: product.data.seoDescription,
        seoKeywords: product.data.seoKeywords,

        images,
        mainVariantImages,

        artworks: product.data?.artworks,
        disabledVariantKeys: product.data.variantKeys.filter((key) => !key.isActive).map((key) => key.variantKey),
        removedVariantKeys: [],
        mainPriceVariant: mainPrice
          ? getProductPriceVariantValue(mainPrice.variantSchema, mainPrice.variantKey, mainVariant?.variantOption || '')
          : ''
      })

      setIsActive(product.data.isActive)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(product)])

  useEffect(() => {
    if (supplierProduct.data) {
      const attributes = supplierProduct.data.attributes?.filter((item) => item.attribute !== 'DTG_PRODUCT_ID') // TODO this attribute will be unused
      const mainVariant = supplierProduct.data.variantValues.find((item) => item.isMainOption)
      const secondaryVariant = supplierProduct.data.variantValues.find((item) => !item.isMainOption)

      form.setFieldsValue({
        supplierId: supplierProduct.data.ownerId,
        productType: supplierProduct.data.productType,
        productType2: supplierProduct.data.productType2,
        sku: supplierProduct.data.sku,
        attributes,
        customizable: supplierProduct.data.customizable,

        hasCustomShipping: supplierProduct.data.hasCustomShipping,
        customShippingPrice: (supplierProduct.data.customShippingPrice / 100).toFixed(2),

        shouldAddMainVariant: !!mainVariant?.variantOption,
        mainVariant: mainVariant?.variantOption,
        mainVariantValues: mainVariant?.valueObjects?.map((item) => item.value),

        shouldAddSecondaryVariant: !!secondaryVariant?.variantOption,
        secondaryVariant: secondaryVariant?.variantOption,
        secondaryVariantValues: secondaryVariant?.valueObjects?.map((item) => item.value),

        disabledMainVariants: mainVariant?.valueObjects
          ?.filter((item) => !mainVariantValues.includes(item.value))
          .map((item) => item.value),
        removedVariantKeys: supplierProduct.data.variantKeys.filter((key) => !key.isActive).map((key) => key.variantKey)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(supplierProduct)])

  const onFinishHandler = async (values: EditProductFormStore) => {
    setIsSaveLoading(true)

    try {
      let isValid = true
      let errorMessage = ''

      if (values.shouldAddMainVariant === undefined) {
        isValid = false
        errorMessage += `Will you use main variant?`
      }

      if (values.shouldAddMainVariant === true && !values.mainVariant) {
        isValid = false
        errorMessage += `Main variant is required`
      }

      const mainVariantValues = (values.mainVariantValues || []).filter((item) => !(values?.disabledMainVariants || []).includes(item))

      if (values.shouldAddMainVariant === true && values.mainVariant && !mainVariantValues?.length) {
        isValid = false
        errorMessage += `Variant options for ${values.mainVariant} are required`
      }

      if (mainVariantValues.length && values.shouldAddSecondaryVariant === undefined) {
        isValid = false
        errorMessage += `Will you use secondary variant?`
      }

      if (mainVariantValues?.length && values.shouldAddSecondaryVariant === true && !values.secondaryVariant) {
        isValid = false
        errorMessage += `Secondary variant is required`
      }

      if (!isArtworksInputValid({
        artworks: values.artworks,
        artworksInput: values.artworksInput,
        forceImages: values.customizable
      })) {
        isValid = false
        errorMessage += `Incorrect artworks data`
      }

      if (
        mainVariantValues?.length &&
        values.shouldAddSecondaryVariant === true &&
        values.secondaryVariant &&
        !values.secondaryVariantValues?.length
      ) {
        isValid = false
        errorMessage += `Variant options for ${values.secondaryVariant} are required`
      }

      let basePrice = values.price

      if (isValid && !basePrice && values.prices) {
        if (values.shouldAddSecondaryVariant && values.secondaryVariantValues) {
          basePrice = values.prices[mainVariantValues[0]]?.[values.secondaryVariantValues[0]]
        } else {
          basePrice = values.prices[mainVariantValues[0]] as VariantPriceInputV2
        }
      }

      if (!basePrice) {
        isValid = false
        errorMessage += `No prices were represented`
      }

      if (!isValid) {
        notification.error({
          message: 'Save product error!',
          description: errorMessage
        })
        setIsSaveLoading(false)
        return
      }

      let productPricesData = [
        {
          sku: basePrice.sku,
          price: Math.round(parseFloat(basePrice.price) * 100),
          discount: Math.round(parseFloat(basePrice.discount) * 100),
          memberPrice: Math.round(parseFloat(basePrice.price) * 100),
          variantKey: '',
          variantSchema: ''
        }
      ]
      const variantValues = []

      if (values.mainVariant) {
        variantValues.push({
          variantOption: values.mainVariant,
          values: mainVariantValues.map((item) => item.toUpperCase()) || [],
          isMainOption: true
        })
      }
      if (values.secondaryVariant) {
        variantValues.push({
          variantOption: values.secondaryVariant,
          values: values.secondaryVariantValues.map((item) => item.toUpperCase()) || [],
          isMainOption: false
        })
      }

      if (mainVariantValues?.length) {
        productPricesData = []

        for (const mainVariant of mainVariantValues) {
          if (values.shouldAddSecondaryVariant && values.secondaryVariant && values.secondaryVariantValues?.length) {
            for (const secondaryVariant of values.secondaryVariantValues) {
              if (values.prices?.[mainVariant]?.[secondaryVariant]) {
                const item = values.prices[mainVariant][secondaryVariant] as VariantPriceInputV2
                productPricesData.push({
                  sku: item.sku,
                  price: Math.round(parseFloat(item.price) * 100),
                  discount: Math.round(parseFloat(item.discount) * 100),
                  memberPrice: Math.round(parseFloat(item.price) * 100),
                  variantKey: `${mainVariant}/${secondaryVariant}`,
                  variantSchema: `${values.mainVariant}/${values.secondaryVariant}`
                })
              }
            }
          } else {
            const item = values.prices[mainVariant] as VariantPriceInputV2
            productPricesData.push({
              sku: item.sku,
              price: Math.round(parseFloat(item.price) * 100),
              discount: Math.round(parseFloat(item.discount) * 100),
              memberPrice: Math.round(parseFloat(item.price) * 100),
              variantKey: mainVariant,
              variantSchema: values.mainVariant
            })
          }
        }
      }

      const productData = {
        name: values.displayName,
        displayName: values.displayName,
        description: values.description,
        productType: values.productType,
        productType2: values.productType2,
        customizable: values.customizable,
        storeId: values.storeId,
        sku: values.sku,
        tags: values.tags,
        supplierProductId: values.supplierProductId || null,
        hasCustomShipping: values.hasCustomShipping,
        basePrice: Math.round(parseFloat(basePrice.price) * 100),
        baseDiscount: Math.round(parseFloat(basePrice.discount) * 100),
        // FIXME Используй нормальный кост бля
        baseCost: Math.round(parseFloat(basePrice?.cost || '0') * 100 || 0),
        baseMemberPrice: Math.round(parseFloat(basePrice.price) * 100 || 0),
        weight: +values.weight || 0,
        weightAccuracy: 3,
        weightUnit: 'lb',
        customShippingPrice: Math.round(parseFloat(values.customShippingPrice || '0') * 100),

        useReplacementForStock: values.useReplacementForStock,
        stockProductId: values.stockProductId,
        sellWhenOutOfStock: values.sellWhenOutOfStock,

        hasCustomSeo: values.hasCustomSeo,
        seoTitle: values.seoTitle,
        seoUrl: values.seoUrl,
        seoDescription: values.seoDescription,
        seoKeywords: values.seoKeywords,
        isActive
      }

      let isSaved = false
      let activeProduct = product.data

      if (!product.data) {
        const createdProduct = await createProduct({
          productData,
          productPriceOwnerType,
          productPriceOwnerId,
          productPricesData,
          variantValues,
          disabledVariantKeys: values.disabledVariantKeys || [],
          removedVariantKeys: values.removedVariantKeys || [],
          mainPriceVariant: values.mainPriceVariant
        })

        isSaved = !!createdProduct
        activeProduct = createdProduct
      } else {
        isSaved = await updateProductV2({
          campaignId: campaignId,
          productId,
          productData,
          productPriceOwnerType,
          productPriceOwnerId,
          productPricesData,
          variantValues,
          disabledVariantKeys: values.disabledVariantKeys || [],
          removedVariantKeys: values.removedVariantKeys || [],
          mainPriceVariant: values.mainPriceVariant
        })
        queryClient.invalidateQueries(['product', { campaignId, productId, productPriceOwnerType, productPriceOwnerId }])
      }

      // HUGE block to save images, sorry
      if (isSaved && activeProduct) {
        const newMainImages =
          values.images?.filter((fileToUpload) => {
            return !fileToUpload.url || fileToUpload.thumbUrl || isNaN(+fileToUpload.uid)
          }) || []

        const mainImagesToRemove =
          product.data?.assets.filter((fileToRemove) => {
            return !values.images?.some((item) => +item.uid === fileToRemove.id)
          }) || []

        for (let i = 0; i < newMainImages.length; i++) {
          const fileToUpload = newMainImages[i]

          await uploadAsset.mutateAsync({
            ownerType: 'PRODUCT',
            ownerId: activeProduct.id,
            assetData: { name: fileToUpload.name, type: fileToUpload.type, filename: fileToUpload.name, role: 'DEFAULT' },
            file: fileToUpload.originFileObj as File
          })
        }

        for (let i = 0; i < mainImagesToRemove.length; i++) {
          await removeAsset.mutateAsync({ ownerType: 'PRODUCT', ownerId: activeProduct.id, assetId: mainImagesToRemove[i].id })
        }

        await Object.keys(values.mainVariantImages || {}).forEach(async (mainVariantValue) => {
          const variantOptionObject = activeProduct.variantValues.find((item) => item.variantOption === values.mainVariant)

          if (!variantOptionObject) return

          const variantValueObject = variantOptionObject.valueObjects?.find((item) => item.value === mainVariantValue)

          if (!variantValueObject) return

          const newVariantImages =
            values.mainVariantImages?.[mainVariantValue]?.filter((fileToUpload) => {
              return !fileToUpload.url || fileToUpload.thumbUrl || isNaN(+fileToUpload.uid)
            }) || []

          const variantImagesToRemove =
            variantValueObject.assets?.filter((fileToRemove) => {
              return !values.mainVariantImages?.[mainVariantValue]?.some((item) => +item.uid === fileToRemove.id)
            }) || []

          for (const asset of newVariantImages) {
            await uploadAsset.mutateAsync({
              ownerType: 'PRODUCT_VARIANT_VALUE',
              ownerId: +variantValueObject.id,
              assetData: { name: asset.name, type: asset.type, filename: asset.name, role: 'DEFAULT' },
              file: asset.originFileObj as File
            })
          }

          for (const asset of variantImagesToRemove) {
            await removeAsset.mutateAsync({ ownerType: 'PRODUCT_VARIANT_VALUE', ownerId: +variantValueObject.id, assetId: asset.id })
          }
        })

        // Upload artworks
        const artworkInputKeys = Object.keys(values.artworksInput || {})
        for (const key of artworkInputKeys) {
          const artworkInputData = values.artworksInput[key]

          if (!artworkInputData) continue

          const artworkForUpdate = values.artworks.find((artwork) => artwork.color === key)
          let imageS3Key: string | undefined
          let backImageS3Key: string | undefined

          if (artworkInputData.file) {
            const { type, name: filename } = artworkInputData.file

            const presignedUrl = await createPresignedUrl({
              ownerType: 'DTG_PRODUCT',
              ownerId: activeProduct.id,
              assetData: {
                name: artworkInputData.file.name,
                type,
                filename,
                role: 'DEFAULT'
              },
              file: artworkInputData.file.originFileObj as File
            })

            imageS3Key = presignedUrl.fields.key
          }

          if (artworkInputData.backFile) {
            const { type, name: filename } = artworkInputData.backFile

            const presignedUrl = await createPresignedUrl({
              ownerType: 'DTG_PRODUCT',
              ownerId: activeProduct.id,
              assetData: {
                name: `back-${artworkInputData.backFile.name}`,
                type,
                filename,
                role: 'DEFAULT'
              },
              file: artworkInputData.backFile.originFileObj as File
            })

            backImageS3Key = presignedUrl.fields.key
          }

          const artworkData = {
            productId: activeProduct.id,
            DTGProductId: artworkInputData.DTGProductId,
            color: key,
            imageS3Key,
            backImageS3Key,
            productType: artworkInputData.productType,
            fitMode: ARTWORK_FIT_MODE.CONTAIN // remain its here to be avail manipulate this field if it will be needed
          }
          console.log(artworkData)

          if (artworkForUpdate) {
            await updateArtwork.mutateAsync({
              customGatewayArtworkId: artworkForUpdate.id,
              customGatewayArtworkData: artworkData
            })
          } else {
            await createArtwork.mutateAsync(artworkData)
          }
        }
      }

      if (isSaved) {
        await setActiveProduct({ productId: activeProduct.id, isActive, campaignId })

        notification.success({
          message: 'Successfully',
          description: `Product was ${product ? 'updated' : 'created'} successfully!`
        })
        setIsSavingFinished(true)
      }
      setIsSaveLoading(false)
    } catch (_e) {
      setIsSaveLoading(false)
    }
  }

  const onFinishFailed = (errors) => {
    const description = errors.errorFields.map((item) => item.errors.join('\n')).join('\n')
    notification.error({
      message: `${product.data ? 'Update' : 'Create'} Product error!`,
      description
    })
  }

  const canSetIsActive = () => {
    if (productImages?.length) return true

    if (!productVariantImages || !mainVariant || !shouldAddMainVariant || !mainVariantValues?.length) return false
    if (mainVariantValues.length !== Object.keys(productVariantImages || {})?.length) return false

    return Object.values(productVariantImages || {}).every((item: any[]) => item?.length)
  }

  return (
    <Layout style={rootStyles.root} className={styles.baseContainer}>
      {campaign?.data?.status === 'MIGRATING' && (
        <div className={styles.blockPage}>
          <div className={styles.blockPageContainer}>
            <p className={styles.blockPageTitle}>
              Campaign is moving to another workspace <span onClick={() => history.goBack()}>Go back</span>
            </p>
          </div>
        </div>
      )}
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title={`${product.data ? 'Update' : 'Create'} product`}
          extra={[
            <Tooltip placement='left' key='tooltip' title='You should either provide image to Product or to All Main variants'>
              <WarningTwoTone twoToneColor='#fba905' className={styles.warningIcon} />
            </Tooltip>,
            !product.data
              ? [
                  <Typography.Text key='label'>Product Active:</Typography.Text>,
                  <Switch
                    key='active-status'
                    disabled={isLoading || isSaveLoading || uploadAsset.isLoading || !canSetIsActive()}
                    checked={isActive}
                    onChange={setIsActive}
                  />
                ]
              : [
                  <span key='upload-progress'>{uploadAsset.isLoading && <ProgressLoader title='Assets uploading' barsLength={8} />}</span>,
                  <Typography.Text key='label'>Product Active:</Typography.Text>,
                  <Switch
                    key='active-status'
                    disabled={isLoading || isSaveLoading || uploadAsset.isLoading || !canSetIsActive()}
                    checked={isActive}
                    onChange={setIsActive}
                  />
                ]
          ]}
        />
      </Layout.Header>
      <Layout.Content className={styles.contentComponent}>
        <Form
          layout='vertical'
          hideRequiredMark
          form={form}
          onFinish={onFinishHandler}
          onFinishFailed={onFinishFailed}
          disabled={isLoading || isSaveLoading || uploadAsset.isLoading}
          hidden={productId && !product.data}
        >
          <Form.Item name='supplierId' noStyle hidden>
            <Input disabled />
          </Form.Item>
          <Form.Item name='supplierProductId' noStyle hidden>
            <Input disabled />
          </Form.Item>
          <Form.Item name='productType' noStyle hidden>
            <Input disabled />
          </Form.Item>
          <Form.Item name='productType2' noStyle hidden>
            <Input disabled />
          </Form.Item>
          <Form.Item name='customizable' noStyle hidden>
            <Input disabled />
          </Form.Item>
          <Form.Item name='sku' noStyle hidden>
            <Input disabled />
          </Form.Item>
          <Form.Item name='shouldAddMainVariant' noStyle hidden>
            <Input disabled />
          </Form.Item>
          <Form.Item name='mainVariant' noStyle hidden>
            <Input disabled />
          </Form.Item>
          <Form.Item name='mainVariantValues' noStyle hidden>
            <Input disabled />
          </Form.Item>
          <Form.Item name='shouldAddSecondaryVariant' noStyle hidden>
            <Input disabled />
          </Form.Item>
          <Form.Item name='secondaryVariant' noStyle hidden>
            <Input disabled />
          </Form.Item>
          <Form.Item name='secondaryVariantValues' noStyle hidden>
            <Input disabled />
          </Form.Item>
          <Form.Item name='attributes' initialValue={[]} noStyle hidden>
            <Input disabled />
          </Form.Item>
          <Form.Item name='tags' noStyle hidden>
            <Input disabled />
          </Form.Item>
          <Form.Item name='images' noStyle hidden>
            <Input disabled />
          </Form.Item>
          <Form.Item name='artworks' initialValue={[]} noStyle hidden>
            <Input disabled />
          </Form.Item>
          <Form.Item name='disabledVariantKeys' noStyle hidden>
            <Input disabled />
          </Form.Item>
          <Form.Item name='disabledMainVariants' noStyle hidden>
            <Input disabled />
          </Form.Item>
          <Form.Item name='removedVariantKeys' noStyle hidden>
            <Input disabled />
          </Form.Item>
          <Form.Item name='mainPriceVariant' noStyle hidden>
            <Input disabled />
          </Form.Item>

          <EditProductSupplier form={form} />

          <EditProdctInfo product={product.data} form={form} />

          <Form.Item noStyle dependencies={[['supplierId']]}>
            {(formInstance) => {
              const supplierId = formInstance.getFieldValue('supplierId')

              if (!supplierId) return null

              return (
                <>
                  {supplierId === -1 && <EditProductType />}

                  <EditProdctVariants product={product.data} form={form} supplierId={supplierId} />
                  <EditProdctPrices />
                  <ManageFormSeo seoUrlPrefix={seoUrlPrefix} form={form} showDescription={false} showKeywords={false} showTitle={false} />
                </>
              )
            }}
          </Form.Item>

          <Form.Item noStyle dependencies={[['supplierId']]}>
            {(formInstance) => {
              const supplierId = formInstance.getFieldValue('supplierId')

              if (!supplierId) return null

              return (
                <div className={styles.buttonsContainer}>
                  <Button onClick={handleFormClose} className={styles.createButton}>
                    Cancel
                  </Button>
                  <Button type='primary' htmlType='submit' loading={isSaveLoading}>
                    Save
                  </Button>
                </div>
              )
            }}
          </Form.Item>
        </Form>
      </Layout.Content>
    </Layout>
  )
}

export default connector(EditProduct)
