import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { ProductAttribute } from '../types'

type Payload = {
  productId: number
  productAttributesList: ProductAttribute[]
}

export type FetchProductAttributesListAction = FSA<undefined, Payload, string>
type FetchProductAttributesList = (productId: number) => MrxThunk<FetchProductAttributesListAction>
type QueryResponse = GraphQLResponse<'productAttributesList', ProductAttribute[]>

const FETCH_PRODUCT_ATTRIBUTES_LIST = `
  query fetchProductAttributesList ($productId: Int!, $filters: ProductAttributesFilters!) {
    productAttributesList (productId: $productId, filters: $filters) {
      id
      attribute
      value
      createdAt
      updatedAt
    }
  }
`

const fetchProductAttributesList: FetchProductAttributesList = (productId) => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_PRODUCT_ATTRIBUTES_LIST,
    meta: { done: false }
  })

  try {
    const {
      workspaces: {
        activeWorkspace: { id: workspaceId }
      }
    } = getState()

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_PRODUCT_ATTRIBUTES_LIST,
      variables: { productId, filters: { workspaceId } }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { productAttributesList } = data
    if (!productAttributesList) {
      throw new Error("Can't get Product Attributes list page!")
    }

    dispatch({
      type: t.FETCH_PRODUCT_ATTRIBUTES_LIST,
      payload: {
        productAttributesList: productAttributesList,
        productId
      },
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_PRODUCT_ATTRIBUTES_LIST,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchProductAttributesList }
