import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { CreateStoreCostModificatorDto, StoreDto } from '@merchx-v2/shared-types'

import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'updateStoreCostModificators', boolean>

const UPDATE_SHOP = `
  mutation updateStoreCostModificators ($storeId: Int!, $costModificators: [StoreCostModificatorInput]!) {
    updateStoreCostModificators(storeId: $storeId, costModificators: $costModificators)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateStoreCostModificatorsArgs = {
  storeId: number
  costModificators: CreateStoreCostModificatorDto[]
}

type UpdateStoreCostModificators = (args: UpdateStoreCostModificatorsArgs) => Promise<UpdateStoreCostModificatorsArgs>

const updateStoreCostModificators: UpdateStoreCostModificators = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_SHOP,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateStoreCostModificators } = data
    if (!updateStoreCostModificators) {
      throw new Error("Can't update store cost modificators!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateStoreCostModificatorsContext = { prevStore?: StoreDto }

type UpdateStoreCostModificatorsEvents = {
  onError: (error: string, variables: UpdateStoreCostModificatorsArgs, context: UpdateStoreCostModificatorsContext) => void
  onSettled: (data?: UpdateStoreCostModificatorsArgs) => void
}

const updateStoreCostModificatorsEvents: UpdateStoreCostModificatorsEvents = {
  onError: (_err, variables, context) => {
    if (context?.prevStore) {
      // Restore previous version of store on any error
      queryClient.setQueryData<StoreDto>(['store', variables.storeId], context.prevStore)
    }
  },
  onSettled: (data) => {
    if (data?.storeId) {
      queryClient.invalidateQueries(['storesPage'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUpdateStoreCostModificators = () => useMutation(updateStoreCostModificators, updateStoreCostModificatorsEvents)
