import React, { useEffect, useState } from 'react'
import { Button, Select, Input } from 'antd'
import notification from 'mrx-notification'
import {
  PLATFORM_SALE_SOURCE,
  SHIPPING_CRITERIA_OPTION,
  SHIPPING_CRITERIA_OPTIONS_BY_ACTION_TYPE,
  SHIPPING_CRITERIA_OPERATORS_BY_OPTIONS,
  SHIPPING_CRITERIA_OPERATOR,
  ShippingActionType,
  ShippingCriteriaOption,
  ShippingCriteriaOperator
} from '@merchx-v2/shared-types'
import { useDTGProductsOptions } from 'features/DTGProducts/hooks'
import { useShippingMethodsOptions } from 'features/shippingMethods/hooks'

import { useCreateShippingRuleCriteria } from '../../hooks'
import styles from './ShippingCriteriaForm.module.scss'

const { Option } = Select

type Props = {
  shippingRuleActionId: number
  actionType?: ShippingActionType
  onFinish: () => void
}

const ShippingCriteriaForm = ({ shippingRuleActionId, actionType, onFinish }: Props) => {
  const [criteriaOption, setCriteriaOption] = useState<ShippingCriteriaOption>()
  const [criteriaOperator, setCriteriaOperator] = useState<ShippingCriteriaOperator>()
  const [criteriaValue, setCriteriaValue] = useState('')
  const [isCriteriaError, setIsCriteriaError] = useState(false)

  const DTGProductsOptions = useDTGProductsOptions('')
  const shippingMethodsOptions = useShippingMethodsOptions('')
  const createShippingCriteria = useCreateShippingRuleCriteria()

  useEffect(() => {
    if (createShippingCriteria.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Shipping rule criteria was created successfully!'
      })
      onFinish()
    }
  }, [createShippingCriteria.isSuccess])

  const onCreateCriteriaHandler = async () => {
    setIsCriteriaError(false)
    if (!criteriaOption || !criteriaOperator || !criteriaValue) {
      setIsCriteriaError(true)
    } else {
      await createShippingCriteria.mutate({
        shippingRuleActionId,
        option: criteriaOption,
        operator: criteriaOperator,
        result: criteriaValue
      })
    }
  }

  const criteriaOptions = actionType
    ? SHIPPING_CRITERIA_OPTIONS_BY_ACTION_TYPE[actionType]
    : Object.keys(SHIPPING_CRITERIA_OPTION)

  return (
    <div className={styles.miniForm}>
      <div>
        Option
        <Select placeholder='Select option' onChange={value => setCriteriaOption(value)}>
          {criteriaOptions.map(item => (
            <Option key={item}>
              {item.split('_').map(item => item.charAt(0) + item.slice(1).toLowerCase()).join(' ')}
            </Option>
          ))}
        </Select>
      </div>
      {criteriaOption && (
        <div>
          Operator
          <Select placeholder='Select operator' onChange={value => setCriteriaOperator(value)}>
            {SHIPPING_CRITERIA_OPERATORS_BY_OPTIONS[criteriaOption].map(item => (
              <Option key={item}>
                {item.split('_').map(item => item.charAt(0) + item.slice(1).toLowerCase()).join(' ')}
              </Option>
            ))}
          </Select>
        </div>
      )}
      {criteriaOperator && (
        <div>
          Value
          {criteriaOption === SHIPPING_CRITERIA_OPTION.PLATFORM_SALE_SOURCE && (
            <Select placeholder='Select platform sale source' onChange={value => setCriteriaValue(value)}>
              {Object.keys(PLATFORM_SALE_SOURCE).map(item => (
                <Select.Option key={item}>{item}</Select.Option>
              ))}
            </Select>
          )}
          {criteriaOption === SHIPPING_CRITERIA_OPTION.ORDER_WEIGHT && <Input placeholder='Input value' onChange={event => setCriteriaValue(event.target.value)} />}
          {criteriaOption === SHIPPING_CRITERIA_OPTION.SIZE && (
            <Select
              mode={criteriaOperator === SHIPPING_CRITERIA_OPERATOR.INCLUDES ? 'multiple' : undefined}
              placeholder='Select size'
              onChange={value => setCriteriaValue(criteriaOperator === SHIPPING_CRITERIA_OPERATOR.INCLUDES ? value.join(',') : value)}
            >
              <Option key='SM'>SM</Option>
              <Option key='MD'>MD</Option>
              <Option key='LG'>LG</Option>
              <Option key='XL'>XL</Option>
              <Option key='2XL'>2XL</Option>
              <Option key='3XL'>3XL</Option>
              <Option key='4XL'>4XL</Option>
              <Option key='5XL'>5XL</Option>
            </Select>
          )}
          {criteriaOption === SHIPPING_CRITERIA_OPTION.DTG_PRODUCT_ID && (
            <Select
              mode={criteriaOperator === SHIPPING_CRITERIA_OPERATOR.INCLUDES ? 'multiple' : undefined}
              placeholder='Select DTG Product'
              onChange={value => setCriteriaValue(criteriaOperator === SHIPPING_CRITERIA_OPERATOR.INCLUDES ? value.join(',') : value)}
            >
              {DTGProductsOptions.data?.map(item => (
                <Option key={item.id}>{item.name}</Option>
              ))}
            </Select>
          )}
          {criteriaOption === SHIPPING_CRITERIA_OPTION.SHIPPING_METHOD && (
            <Select
              mode='multiple'
              placeholder='Select Shipping Method'
              onChange={value => setCriteriaValue(value.join(','))}
            >
              {shippingMethodsOptions.data?.map(item => (
                <Option key={item.id}>{item.name}</Option>
              ))}
            </Select>
          )}
        </div>
      )}
      {isCriteriaError && <span style={{ color: 'red' }}>Fill the form</span>}
      <Button type='primary' onClick={onCreateCriteriaHandler}>Save</Button>
    </div>
  )
}

export default ShippingCriteriaForm
