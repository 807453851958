import React from 'react'
import { SettingOwner } from '@merchx-v2/shared-types'

import FormItemBooleanSetting from '../FormItemBooleanSetting'
import FormItemImageSetting from '../FormItemImageSetting'
import FormItemVideoSetting from '../FormItemVideoSetting'
import FormItemIntegerSetting from '../FormItemIntegerSetting'
import FormItemProductSetting from '../FormItemProductSetting'
import FormItemProductsListSetting from '../FormItemProductsListSetting '
import FormItemQuizSetting from '../FormItemQuizSetting'
import FormItemSelectArraySetting from '../FormItemSelectArraySetting'
import FormItemStringSetting from '../FormItemStringSetting'
import FormItemTriviaQuizSetting from '../FormItemTriviaQuizSetting'
import FormItemTestimonialsSetting from '../FormItemTestimonialsSetting'
import { CustomFile, Setting } from 'features/settings/types'

type PropsType = {
  setting: Setting
  selfUpdate?: boolean
  landingId?: number
  ownerType: SettingOwner
  ownerId: number

  onValueChanged?: (setting: Setting, newValue: any) => void
  onImageUploaded?: (setting: Setting, file: CustomFile) => void
  onVideoUploaded?: (setting: Setting, file: CustomFile) => void
  setFieldsValue?: (store: any) => void
}

const FormItemSetting = (props: PropsType) => {
  const {
    setting,
    landingId,
    selfUpdate = false,
    ownerType,
    ownerId,
    onValueChanged,
    onImageUploaded,
    onVideoUploaded,
    setFieldsValue
  } = props

  switch (setting.type) {
    case 'BOOLEAN': {
      return (
        <FormItemBooleanSetting
          setting={setting}
          selfUpdate={selfUpdate}
          onValueChanged={onValueChanged}
          ownerType={ownerType}
          ownerId={ownerId}
        />
      )
    }

    case 'INTEGER':
    case 'FLOAT': {
      return (
        <FormItemIntegerSetting
          setting={setting}
          selfUpdate={selfUpdate}
          onValueChanged={onValueChanged}
          ownerType={ownerType}
          ownerId={ownerId}
        />
      )
    }

    case 'IMAGE': {
      return (
        <FormItemImageSetting setting={setting} selfUpdate={selfUpdate} onValueChanged={onValueChanged} onImageUploaded={onImageUploaded} />
      )
    }

    case 'VIDEO': {
      return (
        <FormItemVideoSetting setting={setting} selfUpdate={selfUpdate} onValueChanged={onValueChanged} onVideoUploaded={onVideoUploaded} />
      )
    }

    case 'PRODUCT': {
      return (
        <FormItemProductSetting
          setting={setting}
          selfUpdate={selfUpdate}
          onValueChanged={onValueChanged}
          landingId={landingId}
          ownerType={ownerType}
          ownerId={ownerId}
        />
      )
    }

    case 'PRODUCTS_LIST': {
      return (
        <FormItemProductsListSetting
          setting={setting}
          selfUpdate={selfUpdate}
          onValueChanged={onValueChanged}
          landingId={landingId}
          ownerType={ownerType}
          ownerId={ownerId}
        />
      )
    }

    case 'TRIVIA_QUIZ': {
      return <FormItemTriviaQuizSetting setting={setting} onValueChanged={onValueChanged} setFieldsValue={setFieldsValue} />
    }

    case 'QUIZ': {
      return <FormItemQuizSetting setting={setting} onValueChanged={onValueChanged} />
    }

    case 'TESTIMONIALS': {
      return (
        <FormItemTestimonialsSetting
          setting={setting}
          onValueChanged={onValueChanged}
          selfUpdate={selfUpdate}
          ownerType={ownerType}
          ownerId={ownerId}
        />
      )
    }

    case 'SELECT_ARRAY': {
      return (
        <FormItemSelectArraySetting
          setting={setting}
          selfUpdate={selfUpdate}
          onValueChanged={onValueChanged}
          ownerType={ownerType}
          ownerId={ownerId}
        />
      )
    }

    default:
      return (
        <FormItemStringSetting
          setting={setting}
          selfUpdate={selfUpdate}
          onValueChanged={onValueChanged}
          setFieldsValue={setFieldsValue}
          ownerType={ownerType}
          ownerId={ownerId}
        />
      )
  }
}

export default FormItemSetting
