import React, { useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import moment, { Moment } from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Button, Drawer, Form, Input, Layout, PageHeader, Select, Tag, Table, DatePicker } from 'antd'
import { DownloadOutlined, EyeTwoTone } from '@ant-design/icons'
import { ColumnProps } from 'antd/es/table'
import { CartStatus } from '@merchx-v2/shared-types'
import notification from 'mrx-notification'
import { Breadcrumbs } from 'components'
import * as rootStyles from 'assets/layoutStyle'

import { useCartsList, useCartsListCsv } from '../../hooks'
import { Cart } from '../../types'
import CartDetails from '../CartDetails'
import styles from './CartsList.module.scss'

const dateFormat = 'MM/DD/YYYY'

const tagColors: Record<CartStatus, string> = {
  NEW: 'default',
  CONVERTED: 'green',
  ABANDONED: 'orange',
  DECLINED: 'red'
}

const CartsList = () => {
  const { history, location } = useReactRouter()
  const [form] = Form.useForm()

  const [searchQuery, setSearchQuery] = useState<string>()
  const [sorting, setSorting] = useState<SortingType>()
  const [isSettingsVisible, setIsSettingsVisible] = useState(false)
  const [selectedLandingId, setSelectedLandingId] = useState<number>()
  const [selectedStatus, setSelectedStatus] = useState<string>()
  const [datesRange, setDateRange] = useState<[Moment, Moment]>()

  useEffect(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!sessionStorage.getItem('cartsStatus')) {
      setSelectedStatus(sessionStorage.getItem('cartsStatus'))
    }
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!sessionStorage.getItem('cartsLandingId')) {
      setSelectedLandingId(+sessionStorage.getItem('cartsLandingId'))
    }
    const dateFrom = sessionStorage.getItem('cartsDatesRangeFrom')
      ? moment(sessionStorage.getItem('cartsDatesRangeFrom'))
      : moment().subtract(7, 'days').startOf('day')
    const dateTo = sessionStorage.getItem('cartsDatesRangeTo')
      ? moment(sessionStorage.getItem('cartsDatesRangeTo'))
      : moment().add(1, 'day').endOf('day')
    setDateRange([dateFrom, dateTo])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const cartsList = useCartsList({
    status: selectedStatus,
    startDate: datesRange?.at(0).toDate(),
    endDate: datesRange?.at(1).toDate(),
    query: searchQuery,
    sorting: sorting
  })

  const carts: Cart[] = []
  cartsList && cartsList.data && cartsList.data.pages.forEach((page) => page.items.forEach((cart) => carts.push(cart)))

  const page = cartsList.data?.pages.length ? cartsList.data?.pages[cartsList.data?.pages.length - 1].currentPage : 1
  const size = cartsList.data?.pages.length ? cartsList.data?.pages[cartsList.data?.pages.length - 1].pageSize : 30

  const cartsListCsv = useCartsListCsv({
    status: selectedStatus,
    query: searchQuery
  })

  useEffect(() => {
    if (cartsList.error) {
      notification.error({
        message: 'Carts list page error!',
        description: cartsList.error instanceof Error ? cartsList.error.message : cartsList.error.toString()
      })
    }
  }, [cartsList.error])

  useEffect(() => {
    if (cartsListCsv.error) {
      notification.error({
        message: 'Carts list csv error!',
        description: cartsListCsv.error instanceof Error ? cartsListCsv.error.message : cartsListCsv.error.toString()
      })
    }
  }, [cartsListCsv.error])

  useEffect(() => {
    cartsListCsv.data && window.open(cartsListCsv.data, '_blank')
  }, [cartsListCsv.data])

  const handleOnOrderDetailsClose = () => {
    history.push(`/sales/carts`)
  }

  const sortingHandler = (pagination, filters, sorter) => {
    if (sorter?.order) {
      setSorting({ field: sorter.field || sorter.columnKey, order: sorter.order === 'ascend' ? 'ASC' : 'DESC' })
    } else {
      setSorting(undefined)
    }
  }

  const handleStatusChange = (value) => {
    setSelectedStatus(value)
    if (value) {
      sessionStorage.setItem('cartsStatus', value)
    } else {
      sessionStorage.removeItem('cartsStatus')
    }
  }

  const changeDatesRange = (dates: [Moment, Moment]) => {
    if (dates !== null) {
      setDateRange(dates)
      sessionStorage.setItem('cartsDatesRangeFrom', dates[0].toString())
      sessionStorage.setItem('cartsDatesRangeTo', dates[1].toString())
    }
  }

  const handleSettingsDrawerClose = () => {
    setIsSettingsVisible(false)
    form.resetFields()
  }

  const handleExportCSV = () => cartsListCsv.refetch()

  const cartId = location.search && location.search.match(/\d+/g).toString()

  const columns: ColumnProps<Cart>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '20px',
      sorter: true
    },
    {
      title: 'Date',
      render: (record: Cart) => moment(record.createdAt).format('MM/DD/YYYY HH:mm:ss'),
      key: 'createdAt',
      sorter: true
    },
    {
      title: 'Status',
      render: (_value, record) => {
        return <Tag color={tagColors[record.status]}>{record.status}</Tag>
      },
      key: 'status'
    },
    {
      title: 'Customer',
      render: (record: Cart) => `${record.customer?.firstName || ''} ${record.customer?.lastName || ''}`,
      key: 'customer'
    },
    {
      title: 'Email',
      dataIndex: ['customer', 'email']
    },
    {
      title: 'Total',
      render: (record: Cart) => `$${(record.total / 100).toFixed(2)}`,
      key: 'total',
      align: 'right',
      sorter: true
    },
    {
      title: '',
      dataIndex: '',
      key: 'edit',
      width: '7%',
      align: 'center',
      render: (record) => (
        <span>
          <EyeTwoTone
            style={{ fontSize: '20px' }}
            onClick={(e) => {
              e.stopPropagation()
              history.push(`/sales/carts?cartId=${record.id}`)
            }}
          />
        </span>
      )
    }
  ]

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={history.goBack}
          title='Carts'
          extra={[
            <Button key='1' onClick={() => setIsSettingsVisible(!isSettingsVisible)}>
              Settings
            </Button>,
            <Input.Search
              key='2'
              placeholder='Search by customer'
              onSearch={(value) => {
                setDateRange([null, null])
                setSearchQuery(value)
              }}
              style={{ width: 200 }}
              allowClear
            />,
            <Button
              key='export-to-csv-button'
              icon={<DownloadOutlined />}
              disabled={cartsListCsv.isLoading}
              loading={cartsListCsv.isLoading}
              onClick={handleExportCSV}
            >
              CSV
            </Button>
          ]}
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Sales', url: '/' },
            { title: 'Carts', url: '/' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <InfiniteScroll
          style={{ paddingBottom: '30px' }}
          dataLength={page * size}
          next={cartsList.fetchNextPage}
          hasMore={!!cartsList.hasNextPage}
          loader={<h4>Loading...</h4>}
          refreshFunction={cartsList.refetch}
          scrollThreshold={0.8}
        >
          <Table
            columns={columns}
            dataSource={carts.map((item) => item).flat()}
            bordered
            rowKey={(record) => record.id}
            loading={cartsList.isLoading}
            onChange={sortingHandler}
            pagination={false}
          />
        </InfiniteScroll>
      </Layout.Content>
      {cartId && <CartDetails cartId={+cartId} onClose={handleOnOrderDetailsClose} />}
      <Drawer title='Carts Settings' width='400' onClose={handleSettingsDrawerClose} visible={isSettingsVisible}>
        <Form layout='vertical' hideRequiredMark form={form} initialValues={{ landingId: selectedLandingId, status: selectedStatus }}>
          <Form.Item name='status' label='Status'>
            <Select key='3' placeholder='Please select Status' onChange={handleStatusChange} allowClear value={selectedStatus}>
              <Select.Option value='NEW' key='NEW'>
                new
              </Select.Option>
              <Select.Option value='cart' key='converted'>
                converted
              </Select.Option>
              <Select.Option value='abandoned' key='abandoned'>
                abandoned
              </Select.Option>
              <Select.Option value='declined' key='declined'>
                declined
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='dateRange' label='Date'>
            <DatePicker.RangePicker
              className={styles.rangePicker}
              format={dateFormat}
              defaultValue={datesRange}
              ranges={{
                Today: [moment().startOf('day'), moment().add(1, 'day').endOf('day')],
                'Last 7 days': [moment().subtract(7, 'days'), moment().startOf('day')],
                'This Week': [moment().startOf('week'), moment().endOf('week')],
                'This Month': [moment().startOf('month'), moment().endOf('month')]
              }}
              onChange={changeDatesRange}
            />
          </Form.Item>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button htmlType='submit' loading={cartsList.isLoading} onClick={handleSettingsDrawerClose}>
              Close
            </Button>
          </div>
        </Form>
      </Drawer>
    </Layout>
  )
}

export default CartsList
