import { createSelector } from 'reselect'
import { ReduxState } from '../../../store/createRootReducer'
import { Category } from '../types'

type AllCategoriesListSelector = (state: ReduxState, campaignId: number) => Category[]
const getAllCategoriesList: AllCategoriesListSelector = (state, campaignId) => {
  let result = [] as Category[]
  if (Object.prototype.hasOwnProperty.call(state.categories.categories, campaignId)) {
    result = Object.values(state.categories.categories[campaignId])
  }

  return result
}

export const allCategoriesListSelector: AllCategoriesListSelector = createSelector(getAllCategoriesList, (list) => list)
