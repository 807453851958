import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import notification from 'mrx-notification'
import { CodeEditor, Loading } from 'components'
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs'
import { SiteType } from '@merchx-v2/shared-types'
import { useUpdateSourceCode, useUpdateSourceStyle } from '../../hooks'
import styles from './ComponentDetails.module.scss'
import { Component } from '../../types'

type PropsType = {
  ownerType: SiteType
  ownerId: number
  componentId: number
  components: Component[]
}

const ComponentDetails = (props: PropsType) => {
  const { componentId, components, ownerType, ownerId } = props

  const component = components.find((item) => item.id === componentId)

  const [code, setCode] = useState<string>('')
  const [style, setStyle] = useState<string>('')

  const updateSourceCode = useUpdateSourceCode()
  const updateSourceStyle = useUpdateSourceStyle()

  const resetState = () => {
    setCode(component?.sourceCode || '')
    setStyle(component?.sourceStyle || '')
  }

  useEffect(() => {
    if (component) {
      resetState()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [component])

  if (!component) {
    return <Loading />
  }

  const handleCancel = () => {
    resetState()
  }

  const handleSubmit = async () => {
    if (code !== component.sourceCode) {
      updateSourceCode.mutate(
        { componentId, sourceCode: code, ownerType, ownerId },
        {
          onSuccess: () => {
            notification.success({
              message: 'Successfully',
              description: 'Component was updated successfully!'
            })
          },
          onError: (error) => {
            notification.error({
              message: 'Error',
              description: error.toString()
            })
          }
        }
      )
    }

    if (style !== component.sourceStyle) {
      updateSourceStyle.mutate(
        { componentId, sourceStyle: style, ownerType, ownerId },
        {
          onSuccess: () => {
            notification.success({
              message: 'Successfully',
              description: 'Component was updated successfully!'
            })
          },
          onError: (error) => {
            notification.error({
              message: 'Error',
              description: error.toString()
            })
          }
        }
      )
    }
  }

  if (!component) {
    return null
  }

  const disableSaveButton = component?.sourceCode === code && component?.sourceStyle === style

  return (
    <div className={styles.root}>
      <Tabs className={styles.tabs}>
        <div className={styles.buttonsContainer}>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            disabled={disableSaveButton}
            loading={updateSourceCode.isLoading || updateSourceStyle.isLoading}
            type='primary'
            className={styles.createButton}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
        <TabList>
          <Tab>HTML</Tab>
          <Tab>CSS</Tab>
        </TabList>
        <TabPanel className={styles.tabPanel}>
          <div className={styles.htmlTab}>
            <CodeEditor value={code} onChange={setCode} />
          </div>
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          <div className={styles.cssTab}>
            <CodeEditor value={style} isCss onChange={setStyle} />
          </div>
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default ComponentDetails
