import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { ZipwhipSetting } from '../types'

type ZipwhipSettingsForOptionsSelector = (state: ReduxState) => ZipwhipSetting[]
const getZipwhipSettingsForOptions: ZipwhipSettingsForOptionsSelector = state =>
  state.zipwhipSettings.zipwhipSettingsForOptions || ([] as ZipwhipSetting[])

export const zipwhipSettingsForOptionsSelector: ZipwhipSettingsForOptionsSelector = createSelector(
  getZipwhipSettingsForOptions,
  zipwhipSettings => zipwhipSettings
)
