// TODO - remove becouse of legacy

import React, { useContext, useState, useEffect, useRef } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Drawer, Input, Form, Select } from 'antd'
import notification from 'mrx-notification'
import { GlobalContext } from 'appContexts'
import { useCreateTemplate } from '../../hooks'
import styles from './CreateTemplate.module.scss'
import { PropsFromRedux, connector } from './container'
import { TEMPLATE_ROUTE } from '@merchx-v2/shared-types'

const CreateTemplate = (props: PropsFromRedux) => {
  const { user } = props

  const [isVisible, setVisible] = useState(false)
  const [isGatsby, toggleGatsby] = useState(false)
  const [isSublander, setIsSublander] = useState(false)
  const refEl = useRef<Input>(null)

  const [form] = Form.useForm()

  const { workspaceId } = useContext(GlobalContext)

  const createTemplate = useCreateTemplate()

  const handleClose = () => {
    setVisible(false)
    form.resetFields()
  }

  useEffect(() => {
    if (isVisible) {
      refEl.current && refEl.current.focus()
    }
  }, [isVisible])

  const showDrawer = () => {
    setVisible(!isVisible)
  }

  const onFinishHandler = async (values: any) => {
    createTemplate.mutate(
      {
        workspaceId,
        name: values.name,
        buildType: values.buildType,
        templateType: values.templateType,
        route: values.route,
        repository: values.repository,
        repositoryProvider: values.repositoryProvider
      },
      {
        onSuccess: () => {
          notification.success({
            message: 'Successfully',
            description: 'Template was created successfully!'
          })
          handleClose()
        },
        onError: () => {
          notification.error({
            message: 'Error',
            description: createTemplate.error.toString()
          })
        }
      }
    )
  }

  const handleFormValueChanged = (changedValues) => {
    if (changedValues.buildType) {
      toggleGatsby(changedValues.buildType === 'REACT_WITH_GATSBY')
    }

    if (changedValues.templateType) {
      setIsSublander(changedValues.templateType === 'SUBLANDER' || changedValues.templateType === 'QUIZ')
    }
  }

  return (
    <>
      <Button type='primary' onClick={showDrawer}>
        <PlusOutlined />
        CREATE
      </Button>
      <Drawer title='Create Template' width='400' onClose={handleClose} visible={isVisible} forceRender>
        <Form
          onValuesChange={handleFormValueChanged}
          initialValues={{ templateType: 'LANDING' }}
          layout='vertical'
          hideRequiredMark
          onFinish={onFinishHandler}
          form={form}
        >
          <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input template name!' }]}>
            <Input type='text' ref={refEl} />
          </Form.Item>
          <Form.Item name='buildType' label='Build type' rules={[{ required: true, message: 'Please input build type!' }]}>
            <Select>
              <Select.Option value='REACT_WITH_INTEGRATED_SETTINGS'>React + Integrated settings</Select.Option>
              <Select.Option value='REACT_WITH_SETTINGS_FILE'>React + Settings file</Select.Option>
              <Select.Option value='REACT_WITH_GATSBY'>React + Gatsby</Select.Option>
            </Select>
          </Form.Item>
          {user?.roles.includes('SYSTEM_ADMIN') && (
            <Form.Item name='templateType' label='Template Type'>
              <Select defaultValue='LANDING'>
                <Select.Option value='LANDING'>Landing</Select.Option>
                <Select.Option value='QUIZ'>Quiz</Select.Option>
                <Select.Option value='SUBLANDER'>Sublander</Select.Option>
              </Select>
            </Form.Item>
          )}

          {isGatsby && (
            <>
              <Form.Item name='repository' label='Repository' rules={[{ required: true, message: 'Please input repository!' }]}>
                <Input style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item
                name='repositoryProvider'
                label='Repository Provider'
                rules={[{ required: true, message: 'Please input repository provider!' }]}
              >
                <Select>
                  <Select.Option value='GITLAB'>GitLab</Select.Option>
                </Select>
              </Form.Item>
            </>
          )}

          {isSublander && (
            <>
              <Form.Item name='route' label='Template Route' rules={[{ required: true, message: 'Please input template route!' }]}>
                <Select>
                  {Object.keys(TEMPLATE_ROUTE).map((templateRoute) => (
                    <Select.Option key={templateRoute} value={templateRoute}>
                      {templateRoute}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}

          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button onClick={handleClose} className={styles.createButton}>
              Close
            </Button>
            <Button type='primary' htmlType='submit' loading={createTemplate.isLoading}>
              Create
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

export default connector(CreateTemplate)
