import { createSelector, ParametricSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { ProductTag } from '../types'

const getProductTag: ParametricSelector<ReduxState, number, ProductTag | undefined> = (state, productTagId) => {
  let result
  if (Object.prototype.hasOwnProperty.call(state.productTags.productTags, productTagId)) {
    result = state.productTags.productTags[productTagId]
  }
  return result
}

export const productTagSelector = createSelector(getProductTag, (productTag) => productTag)
