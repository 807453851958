import React, { useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import { PageHeader, Tabs, Layout, Button } from 'antd'
import notification from 'mrx-notification'

import settings from 'config/settings'
import { ManageSeo, Breadcrumbs } from 'components'

import StoreProducts from '../StoreProducts'
import StoreCostModificators from '../StoreCostModificators'
import * as rootStyles from 'assets/layoutStyle'
import { useStore, useUpdateStore } from '../../hooks'
import styles from './StoreDetails.module.scss'
import StoreInvitesList from '../StoreInvitesList'

type Props = {
  match: { params: { storeId: string } }
}

const StoreDetails = ({
  match: {
    params: { storeId }
  }
}: Props) => {
  const { history } = useReactRouter()
  const updateStore = useUpdateStore()

  const store = useStore(+storeId)

  const [activeTab, setActiveTab] = useState<'details' | 'orders' | 'balance' | 'products'>('details')
  const [isStoreChanged, setIsStoreChanged] = useState(false)
  const [isStoreLoaded, setIsStoreLoaded] = useState(false)
  const [storeHasCustomSeo, setStoreHasCustomSeo] = useState<boolean | undefined>(undefined)
  const [storeSeoTitle, setStoreSeoTitle] = useState('')
  const [storeSeoUrl, setStoreSeoUrl] = useState('')
  const [storeSeoDescription, setStoreSeoDescription] = useState('')
  const [storeSeoKeywords, setStoreSeoKeywords] = useState('')

  useEffect(() => {
    if (store.error) {
      notification.error({
        message: 'Stores error!',
        description: store.error instanceof Error ? store.error.message : store.error.toString()
      })
    }
  }, [store.error])

  useEffect(() => {
    if (updateStore.isSuccess) {
      notification.success({ message: 'Store changed successfully' })
    }
  }, [updateStore.isSuccess])

  useEffect(() => {
    if (updateStore.error) {
      notification.error({
        message: 'Store change error!',
        // @ts-ignore
        description: updateStore.error instanceof Error ? updateStore.error.message : updateStore.error.toString()
      })
    }
  }, [updateStore.error])

  useEffect(() => {
    if (store.data) {
      setStoreSeoTitle(store.data.seoTitle)
      setStoreSeoUrl(store.data.seoUrl)
      setStoreSeoDescription(store.data.seoDescription)
      setStoreSeoKeywords(store.data.seoKeywords)
      setTimeout(() => setIsStoreLoaded(true), 50)
    }
  }, [store.data])

  useEffect(() => {
    if (!isStoreChanged && isStoreLoaded && (storeSeoTitle || storeSeoUrl || storeSeoDescription || storeSeoKeywords)) {
      setIsStoreChanged(true)
    }
  }, [storeSeoTitle, storeSeoUrl, storeSeoDescription, storeSeoKeywords])

  const activeTabKeyChangeHandler = (value) => {
    setActiveTab(value)
  }

  const renderTabExtraBar = () => {
    switch (activeTab) {
      case 'products':
        return (
          <Button onClick={() => alert('Add product to store')} type='primary'>
            + ADD PRODUCT
          </Button>
        )
      default:
    }
  }

  const handleUpdateStore = () => {
    if (store.data) {
      const {
        name,
        description,
        ownerType,
        ownerId,
        bannerLink,
        isActive,
        hasCustomSeo,
        supportEmail,
        facebookLink,
        twitterLink,
        instagramLink,
        linkedInLink,
        pinterestLink,
        tiktokLink,
        youtubeLink,
        threadsLink,
        location
      } = store.data
      updateStore.mutate({
        storeId: parseInt(storeId),
        storeData: {
          name,
          description,
          ownerType,
          ownerId,
          bannerLink,
          isActive,
          hasCustomSeo: storeHasCustomSeo !== undefined ? storeHasCustomSeo : hasCustomSeo,
          seoUrl: storeSeoUrl,
          seoTitle: storeSeoTitle,
          seoDescription: storeSeoDescription,
          seoKeywords: storeSeoKeywords,
          supportEmail,
          facebookLink,
          twitterLink,
          instagramLink,
          linkedInLink,
          pinterestLink,
          tiktokLink,
          youtubeLink,
          threadsLink,
          location
        }
      })
    }
    setIsStoreChanged(false)
  }

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader onBack={() => history.goBack()} title='Store Details' extra={[]} />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Store Details', url: '/' }
          ]}
        />
      </Layout.Content>

      <Layout.Content className={styles.content}>
        <Tabs
          type='card'
          className={styles.tabs}
          activeKey={activeTab}
          onChange={activeTabKeyChangeHandler}
          tabBarExtraContent={renderTabExtraBar()}
        >
          <Tabs.TabPane className={styles.tabPane} tab='Details' key='details'>
            <div className={styles.hellCrutch}>
              <ManageSeo
                initValues={{
                  hasCustomSeo: storeHasCustomSeo !== undefined ? storeHasCustomSeo : store?.data?.hasCustomSeo,
                  title: storeSeoTitle || store?.data?.seoTitle,
                  url: storeSeoUrl || store?.data?.seoUrl,
                  description: storeSeoDescription || store?.data?.seoDescription,
                  keywords: storeSeoKeywords || store?.data?.seoKeywords,
                  urlPrefix: settings.shopUrl
                }}
                onCustomSeoChanged={setStoreHasCustomSeo}
                onTitleChanged={setStoreSeoTitle}
                onUrlChanged={setStoreSeoUrl}
                onDescriptionChanged={setStoreSeoDescription}
                onKeywordsChanged={setStoreSeoKeywords}
              />
              <Button type='primary' onClick={handleUpdateStore} disabled={!isStoreChanged}>
                Save
              </Button>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane className={styles.tabPane} tab='Products' key='products'>
            <StoreProducts storeId={+storeId} />
          </Tabs.TabPane>
          <Tabs.TabPane className={styles.tabPane} tab='Invites' key='invites'>
            <StoreInvitesList storeId={+storeId} />
          </Tabs.TabPane>
          <Tabs.TabPane className={styles.tabPane} tab='Cost Modificators' key='costModificators'>
            <StoreCostModificators store={store.data} />
          </Tabs.TabPane>
        </Tabs>
      </Layout.Content>
    </Layout>
  )
}

export default StoreDetails
