import React, { useContext, useState, useEffect } from 'react'
import useReactRouter from 'use-react-router'
import hash from 'object-hash'
import { CloseCircleTwoTone, DeleteTwoTone } from '@ant-design/icons'
import { AutoComplete, Button, Divider, Input, Form, Layout, PageHeader, Select, Typography, Switch } from 'antd'
import { Store } from 'antd/lib/form/interface'
import { UploadFile } from 'antd/lib/upload/interface'
import notification from 'mrx-notification'
import { generateVariantKeysList } from '@merchx-v2/shared-frontend-functions'
import { AssetDto, productSkuPattern, productSkuPatternErrorMessage, ProductType } from '@merchx-v2/shared-types'
import { GlobalContext } from 'appContexts'
import { convertToSku } from 'utils/convertToSku'
import { ProductAttributes, ProductType2, ProductTypes } from 'consts'
import settings from 'config/settings'

import { queryClient } from 'queryClient'
import { UploadImages, ManageSeo, ManageAttributes, Loading, ProgressLoader } from 'components'
import * as rootStyles from 'assets/layoutStyle'
import { DTGProductsSelect } from 'features/DTGProducts/components'
import { UploadImages as UploadFiles } from 'features/productImages/components'
import { ManageTags, ManageProductTags } from 'features/tags/components'
import StoresSelect from 'features/stores/components/StoresSelect'
import { Attribute, SubscriptionItem } from 'features/types'
import { ProductImage } from 'features/productImages/types'
import { useRemoveAsset, useAssetsList, useUploadAsset } from 'features/assets/hooks'
import { useCampaign } from 'features/campaigns/hooks'

import { useProduct } from '../../hooks'
import { VariantPrices, VariantsValues, SubscriptionIntervalEnum } from '../../types'
import ProductPriceInput from '../ProductPriceInput'
import DuplicateProduct from '../DuplicateProduct'
import UpdateSubscriptionPrice from '../UpdateSubscriptionPrice'
import CreateSubscriptionPrice from '../CreateSubscriptionPrice'
import SubscriptionProductPriceInput from '../SubscriptionProductPriceInput'
import { PropsFromRedux, connector } from './container'
import styles from './EditProduct.module.scss'

const { Option } = Select

interface EditProductFormStore extends Store {
  name: string
  displayName: string
  description: string
  sku: string

  storeId: number | null

  productType: ProductTypes
  productType2: ProductType2
  DTGProductId?: number
  isSubscription: boolean

  basePrice: string
  baseDiscount: string
  baseCost: string
  baseMemberPrice: string

  weight: string
  weightAccuracy: string
  weightUnit: string

  hasCustomShipping?: boolean
  customShippingPrice?: string

  useReplacementForStock: boolean
  stockProductId: number | null
  sellWhenOutOfStock: boolean

  hasCustomSeo: boolean
  seoTitle: string
  seoUrl: string
  seoDescription: string
  seoKeywords: string
}

type NotStrictOptions = {
  id?: number
  name?: string
}

const seoUrlPrefix = `${settings.shopUrl}/products`

const options = [{ value: ProductAttributes.color }, { value: ProductAttributes.size }]
const { requiredProductAttributes } = settings

const getMaxPrintArea = (productType: ProductType): { width: string; height: string } => {
  switch (productType) {
    case 'HOODIE': {
      return {
        width: '220px',
        height: '148px'
      }
    }

    default: {
      return {
        width: '200px',
        height: '250px'
      }
    }
  }
}

const EditProduct = (props: PropsFromRedux) => {
  const {
    productId,
    landingId,
    isLoading,
    productImages,

    attributesOptions,
    productError,

    createProduct,
    fetchProductAvailableAttributeValues,
    fetchProductImagesList,
    updateProductV2,

    removeProductImage,
    uploadFile,
    saveSubscriptionData
  } = props

  const { campaignId } = useContext(GlobalContext)

  const productPriceOwnerType = landingId ? 'LANDING' : 'CAMPAIGN'
  const productPriceOwnerId = landingId || campaignId

  const product = useProduct({ campaignId, productId, productPriceOwnerType, productPriceOwnerId })
  const campaign = useCampaign(campaignId)
  const removeAsset = useRemoveAsset()
  const DTGAssets = useAssetsList({ ownerType: 'DTG_PRODUCT', ownerId: productId || -1 })
  const uploadAsset = useUploadAsset()

  // const [disableInventoryUpdate, setDisableInventoryUpdate] = useState(false)

  const [productImagesToRemove, setProductImagesToRemove] = useState<ProductImage[]>([])
  const [productImagesToUpload, setProductImagesToUpload] = useState<UploadFile<any>[]>([])

  const [isDTGCampaign, setIsDTGCampaign] = useState<boolean>(false)
  const [DTGArtworkForPreview, setDTGArtworkForPreview] = useState<string>('')
  const [variantDTGArtworkToRemove, setVariantDTGArtworkToRemove] = useState<Record<string, Record<string, AssetDto[]>>>({})
  const [variantDTGArtworkToCreate, setVariantDTGArtworkToCreate] = useState<Record<string, Record<string, UploadFile<any>[]>>>({})

  const [customShippingPriceVisible, setCustomShippingPriceVisible] = useState<boolean>()
  const [attributes, setAttributes] = useState<Attribute[]>([])
  const [DTGProductAttribute, setDTGProductAttribute] = useState<NotStrictOptions>(null)
  const [tags, setTags] = useState<string[]>([])

  // Price variants
  const [isFormNeedToBeSaved, setIsFormNeedToBeSaved] = useState<boolean>(false)
  const [variantOptions, setVariantOptions] = useState<string[]>([])
  const [variantValues, setVariantValues] = useState<VariantsValues>({})
  const [variantPrices, setVariantPrices] = useState<VariantPrices>({})
  const [selectedProductType, setSelectedProductType] = useState<string>(product?.data?.productType)
  const [newVariantOption, setNewVariantOption] = useState<string>()
  const [isAddNewVariantOptionVisible, setIsAddNewVariantOptionVisible] = useState<boolean>(false)
  const [mainVariantOption, setMainVariantOption] = useState<string>()
  const [variantImages, setVariantImages] = useState<Record<string, Record<string, AssetDto[]>>>({})
  const [variantImagesToRemove, setVariantImagesToRemove] = useState<Record<string, Record<string, AssetDto[]>>>({})
  const [variantImagesToCreate, setVariantImagesToCreate] = useState<Record<string, Record<string, UploadFile<any>[]>>>({})

  const [hasCustomSeo, setHasCustomSeo] = useState<boolean>(product?.data?.hasCustomSeo)
  const [seoTitle, setSeoTitle] = useState<string>(product?.data?.displayName || 'Page Title')
  const [seoUrl, setSeoUrl] = useState<string>('')
  const [seoDescription, setSeoDescription] = useState<string>(product?.data?.description || 'Description will show here')
  const [seoKeywords, setSeoKeywords] = useState<string>(product?.data?.seoKeywords)

  const [isSubscription, setIsSubscription] = useState<boolean>(false)

  const [selectedInterval, setSelectedInterval] = useState<SubscriptionIntervalEnum>('MONTH')
  const [selectedRepeatFrequency, setSelectedRepeatFrequency] = useState<number>(1)
  const [selectedSubscriptionPrice, setSelectedSubscriptionPrice] = useState<number>(5)
  const [selectedSubscriptionDiscount, setSelectedSubscriptionDiscount] = useState<number>(0)
  const [selectedSubscriptionMemberPrice, setSelectedSubscriptionMemberPrice] = useState<number>(5)
  const [dataSource, setDataSource] = useState<SubscriptionItem[]>([])
  const [isUpdateSubscriptionDrawerVisible, setIsUpdateSubscriptionDrawerVisible] = useState<boolean>(false)
  const [selectedSubscriptionRecord, setSelectedSubscriptionRecord] = useState<SubscriptionItem>()

  const [isActive, setIsActive] = useState(true)
  const [isDuplicateDrawerVisible, setIsDuplicateDrawerVisible] = useState(false)
  const [isSavingFinished, setIsSavingFinished] = useState(false)
  const [isSaveLoading, setIsSaveLoading] = useState(false)
  const [colorManual, setColorManual] = useState(false)

  const { history } = useReactRouter()
  const [form] = Form.useForm()

  useEffect(() => {
    if (campaign.error) {
      notification.error({
        message: 'Create Product error!',
        description: campaign.error instanceof Error ? campaign.error.message : campaign.error.toString()
      })
    }
  }, [campaign.error])

  useEffect(() => {
    if (productError) {
      notification.error({
        message: 'Create Product error!',
        description: productError
      })
    }
  }, [productError])

  useEffect(() => {
    if (product.error) {
      notification.error({
        message: 'Create Product error!',
        description: product.error instanceof Error ? product.error.message : product.error.toString()
      })
    }
  }, [product.error])

  useEffect(() => {
    if (DTGAssets.data && DTGAssets.data[0]?.signedUrl) {
      setDTGArtworkForPreview(DTGAssets.data[0].signedUrl)
    }
  }, [DTGAssets.data])

  useEffect(() => {
    if (DTGAssets.error) {
      notification.error({
        message: 'Create Product error!',
        description: DTGAssets.error instanceof Error ? DTGAssets.error.message : DTGAssets.error.toString()
      })
    }
  }, [DTGAssets.error])

  useEffect(() => {
    if (removeAsset.error) {
      notification.error({
        message: 'Error!',
        description: removeAsset.error.toString()
      })
    }
  }, [removeAsset.error])

  useEffect(() => {
    if (uploadAsset.error) {
      notification.error({
        message: 'Error!',
        description: uploadAsset.error instanceof Error ? uploadAsset.error.message : uploadAsset.error.toString()
      })
    }
  }, [uploadAsset.error])

  useEffect(() => {
    fetchProductAvailableAttributeValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (productId) {
      fetchProductImagesList(productId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId])

  const updateVariantPrices = (newVariantsValues: VariantsValues) => {
    const newVariantsKeys = generateVariantKeysList(newVariantsValues)
    const newVariantPrices: VariantPrices = {}

    for (const variantKey of newVariantsKeys) {
      if (variantPrices[variantKey.key]) {
        newVariantPrices[variantKey.key] = variantPrices[variantKey.key]
      } else {
        const baseSku = form.getFieldValue('sku') ? `${form.getFieldValue('sku')}-` : ''
        const price = form.getFieldValue('basePrice') || '0'
        const discount = form.getFieldValue('baseDiscount') || '0'
        const memberPrice = form.getFieldValue('baseMemberPrice') || '0'
        const sku = `${baseSku}${variantKey.key.toUpperCase().replace(/\//g, '-')}`
        newVariantPrices[variantKey.key] = {
          price,
          discount,
          memberPrice,
          sku,
          variantKey: variantKey.key,
          variantSchema: variantKey.schema
        }
      }
    }

    setVariantPrices(newVariantPrices)
  }

  const fillDefaultProductVariantOptions = (productType: string) => {
    const requiredVariantOptions = settings.requiredProductVariantOptions[productType] || []
    let minimumNumberOfPrices = 1
    for (const variantOption of requiredVariantOptions) {
      minimumNumberOfPrices *= settings.defaultProductVariantOptionValues[variantOption]?.length || 1
    }

    if (Object.keys(variantPrices).length !== minimumNumberOfPrices) {
      setIsFormNeedToBeSaved(true)
      const variantValues: Record<string, string[]> = {}
      for (const variantOption of requiredVariantOptions) {
        if (!variantValues[variantOption]) {
          variantValues[variantOption] = settings.defaultProductVariantOptionValues[variantOption] || []
        }
      }

      setVariantOptions(requiredVariantOptions)
      setVariantValues(variantValues)
      updateVariantPrices(variantValues)
    }
  }

  useEffect(() => {
    const DTGAttributeForSelect = product?.data?.attributes?.find((item) => item.attribute === 'DTG_PRODUCT_ID')
    setDTGProductAttribute({ id: +DTGAttributeForSelect?.value || 0 })
    if (product.data) {
      setCustomShippingPriceVisible(product.data.hasCustomShipping)
      setHasCustomSeo(product.data.hasCustomSeo)
      setSeoTitle(product.data.displayName)
      setSeoUrl(product.data.seoUrl)
      setSeoDescription(product.data.description)
      setSeoKeywords(product.data.seoKeywords)
      setIsActive(product.data.isActive)
      setSelectedProductType(product.data.productType)

      form.setFieldsValue({
        name: product.data.name,
        displayName: product.data.displayName,
        description: product.data.description,
        sku: product.data.sku,
        DTGProductId: DTGAttributeForSelect?.value,

        storeId: product.data.storeId,

        productType: product.data.productType,
        productType2: product.data.productType2,

        basePrice: (product.data.basePrice / 100).toFixed(2),
        baseDiscount: (product.data.baseDiscount / 100).toFixed(2),
        baseCost: (product.data.baseCost / 100).toFixed(2),
        baseMemberPrice: (product.data.baseMemberPrice / 100).toFixed(2),

        weight: product.data.weight,
        weightAccuracy: product.data.weightAccuracy,
        weightUnit: product.data.weightUnit,

        useReplacementForStock: product.data.useReplacementForStock,
        stockProductId: product.data.stockProductId,
        sellWhenOutOfStock: product.data.sellWhenOutOfStock,

        hasCustomShipping: product.data.hasCustomShipping,
        customShippingPrice: (product.data.customShippingPrice / 100).toFixed(2)
      })

      const mappedAttributes =
        product.data.attributes &&
        product.data.attributes.map(({ attribute, value }) => {
          const requiredAttributes = requiredProductAttributes.find((item) => item.productType === product?.data?.productType)
          const required = requiredAttributes && requiredAttributes.attributes.includes(attribute.toLocaleLowerCase() as any)
          return {
            attribute,
            value,
            required
          }
        })

      if (DTGProductAttribute) {
        mappedAttributes && setAttributes(mappedAttributes.filter((item) => item.attribute !== 'DTG_PRODUCT_ID'))
      } else {
        mappedAttributes && setAttributes(mappedAttributes)
      }

      setTags(product.data.tags)

      const isSubscriptionProduct = product.data.productType2 === ProductType2.SUBSCRIPTION
      setIsSubscription(isSubscriptionProduct)

      if (!isSubscriptionProduct) {
        const mainVariant = product.data.variantValues.find((item) => item.isMainOption)
        setMainVariantOption(mainVariant?.variantOption)
        setVariantOptions(product.data.variantValues.map((item) => item.variantOption))
        setVariantValues(
          product.data.variantValues.reduce((accum, currentVariant) => {
            if (!accum[currentVariant.variantOption]) {
              accum[currentVariant.variantOption] = currentVariant.values
            }
            return accum
          }, {} as Record<string, string[]>)
        )

        setVariantImages(
          product.data.variantValues.reduce((accum, currentVariant) => {
            if (!currentVariant.isMainOption) return accum

            for (const variant of currentVariant.valueObjects || []) {
              if (!accum[currentVariant.variantOption]) {
                accum[currentVariant.variantOption] = {}
              }

              accum[currentVariant.variantOption][variant.value] = variant.assets || []
            }

            return accum
          }, {} as Record<string, Record<string, AssetDto[]>>)
        )

        const reducedPrices = (product.data.prices || []).reduce((accum, current) => {
          if (!accum[current.variantKey]) {
            accum[current.variantKey] = { ...current }
            accum[current.variantKey].price = (current.price / 100).toFixed(2)
            accum[current.variantKey].discount = (current.discount / 100).toFixed(2)
            accum[current.variantKey].memberPrice = (current.memberPrice / 100).toFixed(2)
          }
          return accum
        }, {})

        if (Object.keys(reducedPrices).length === product.data.prices?.length && product.data.prices.length > 1) {
          // Load product prices variants
          setVariantPrices(reducedPrices)
        } else {
          fillDefaultProductVariantOptions(product.data.productType)
        }
      } else {
        if (product?.data?.prices?.length) {
          const price = product.data.prices[0]
          if (price?.attributes) {
            const interval = price.attributes.find((item) => item.attribute === 'SUBSCRIPTION_INTERVAL')
            if (interval) {
              setSelectedInterval(interval.value.toUpperCase() as SubscriptionIntervalEnum)
            }

            const frequency = price.attributes.find((item) => item.attribute === 'SUBSCRIPTION_REPEAT_FREQUENCY')
            if (frequency) {
              setSelectedRepeatFrequency(parseInt(frequency.value))
            }

            setSelectedSubscriptionPrice((price.price || 0) / 100)
            setSelectedSubscriptionMemberPrice((price.memberPrice || 0) / 100)
            setSelectedSubscriptionDiscount((price.discount || 0) / 100)
            setDataSource(
              product?.data?.subscriptionItems?.map<SubscriptionItem>((item) => ({
                date: item.date,
                productId: product.data.id,
                product: { id: item.product?.id, name: item.product?.name },
                quantity: parseInt(item.quantity + '')
              }))
            )
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(product)])

  useEffect(() => {
    setIsDTGCampaign(!!campaign?.data?.attributes?.find((item) => item.attribute === 'DTG_CAMPAIGN_ID'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(campaign)])

  const handleFormClose = () => {
    if (isFormNeedToBeSaved) {
      notification.warning({
        message: 'Product prices were changed!',
        description: 'Product had wrong prices, please save the changes!'
      })
      return
    }

    setIsSubscription(false)
    setProductImagesToUpload([])
    setIsActive(false)
    setIsSavingFinished(false)
    setIsSaveLoading(false)
    form.resetFields()
    history.goBack()
  }

  useEffect(() => {
    if (isSavingFinished && !isLoading && !uploadAsset.isLoading) {
      handleFormClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSavingFinished, isLoading, uploadAsset])

  const handleRemoveSubscriptionItemConfirmed = async (subscriptionId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    const subscriptionItems = dataSource.filter((item) => item.product.id !== subscriptionId)
    setDataSource(subscriptionItems)
  }

  const handleVariantValuesChanged = (variant: string, newValues: string[]) => {
    const newVariantValues = { ...variantValues, [variant]: newValues.map((item) => item.toUpperCase()) }
    setVariantValues(newVariantValues)
    updateVariantPrices(newVariantValues)
  }

  const handleNewVariantOptionCreated = () => {
    const newVariantOptions = [...variantOptions, newVariantOption]
    const newVariantValues = { ...variantValues, [newVariantOption]: [] }

    if (newVariantOption.toUpperCase() === 'SIZE') {
      newVariantValues[newVariantOption] = ['SM', 'MD', 'LG', 'XL', '2XL']
    }

    setVariantOptions(newVariantOptions)
    setVariantValues(newVariantValues)
    setNewVariantOption('')
    setIsAddNewVariantOptionVisible(false)
    updateVariantPrices(newVariantValues)
  }

  const handleAttributesChanged = (newValues: Attribute[]) => {
    setAttributes(newValues)
  }

  const handleTagsChanged = (newValues: string[]) => {
    setTags(newValues)
  }

  const handleVariantRemoved = (removedVariant: string) => {
    const newVariantOptions = variantOptions.filter((item) => item !== removedVariant)
    const newVariantsValues = { ...variantValues }
    delete newVariantsValues[removedVariant]

    setVariantOptions(newVariantOptions)
    setVariantValues(newVariantsValues)
    updateVariantPrices(newVariantsValues)
  }

  const handleCreateSubscription = (newValues) => {
    setDataSource([...dataSource, newValues])
  }

  const handleUpdateSubscription = (newValues) => {
    const newData = [...dataSource]
    const index = newData.indexOf(selectedSubscriptionRecord)
    newData[index] = newValues
    setDataSource(newData)
  }

  const handleIntervalChanged = (value: SubscriptionIntervalEnum) => {
    setSelectedInterval(value)
  }

  const handleRepeatFrequencyChanged = (value: number) => {
    setSelectedRepeatFrequency(value)
  }

  const handleSubscriptionPriceChanged = (value: number) => {
    setSelectedSubscriptionPrice(value)
  }

  const handleSubscriptionMemberPriceChanged = (value: number) => {
    setSelectedSubscriptionMemberPrice(value)
  }

  const handleSubscriptionDiscountChanged = (value: number) => {
    setSelectedSubscriptionDiscount(value)
  }

  const handleOnCloseSubscriptionForm = () => {
    setIsUpdateSubscriptionDrawerVisible(false)
  }

  const handleFormValueChanged = (changedValues: EditProductFormStore) => {
    if (changedValues.displayName) {
      form.setFieldsValue({
        sku: convertToSku(changedValues.displayName)
      })
    }

    if (changedValues.name) {
      form.setFieldsValue({
        sku: convertToSku(changedValues.name)
      })
    }

    if (changedValues.storeId) {
      form.setFieldsValue({
        storeId: changedValues.storeId
      })
    }

    if (changedValues.hasCustomShipping !== undefined) {
      setCustomShippingPriceVisible(changedValues.hasCustomShipping)
    }

    if (changedValues.sku) {
      const newSku = convertToSku(changedValues.sku)
      if (newSku !== changedValues.sku) {
        form.setFieldsValue({
          sku: newSku
        })
      }

      updateVariantPrices(variantValues)
    }

    if (changedValues.productType) {
      setSelectedProductType(changedValues.productType)
      fillDefaultProductVariantOptions(changedValues.productType)
    }

    if (changedValues.productType2) {
      setIsSubscription(changedValues.productType2 === ProductType2.SUBSCRIPTION)
    }

    if (changedValues.basePrice) {
      form.setFieldsValue({
        basePrice: changedValues.basePrice
      })

      if (!isSubscription) {
        const newVariantPrices: VariantPrices = {}
        Object.keys(variantPrices).forEach((key) => {
          if (variantPrices[key]) {
            newVariantPrices[key] = variantPrices[key]

            newVariantPrices[key].price = changedValues.basePrice
          }
        })
        setVariantPrices(newVariantPrices)
      }
    }

    if (changedValues.baseDiscount) {
      form.setFieldsValue({
        baseDiscount: changedValues.baseDiscount
      })

      if (!isSubscription) {
        const newVariantPrices: VariantPrices = {}
        Object.keys(variantPrices).forEach((key) => {
          if (variantPrices[key]) {
            newVariantPrices[key] = variantPrices[key]

            newVariantPrices[key].discount = changedValues.baseDiscount
          }
        })
        setVariantPrices(newVariantPrices)
      }
    }

    if (changedValues.baseMemberPrice) {
      form.setFieldsValue({
        baseMemberPrice: changedValues.baseMemberPrice
      })

      if (!isSubscription) {
        const newVariantPrices: VariantPrices = {}
        Object.keys(variantPrices).forEach((key) => {
          if (variantPrices[key]) {
            newVariantPrices[key] = variantPrices[key]

            newVariantPrices[key].memberPrice = changedValues.baseMemberPrice
          }
        })
        setVariantPrices(newVariantPrices)
      }
    }

    // if (changedValues.sellWhenOutOfStock || changedValues.useReplacementForStock || changedValues.stockProductId) {
    //   setDisableInventoryUpdate(true)
    // }
  }

  const onFinishHandler = async (values: EditProductFormStore) => {
    setIsSaveLoading(true)

    try {
      let isValid = true
      let errorMessage = ''

      const presentVariantOptions = Object.keys(variantValues)
      const requiredVariantOptions: string[] = settings.requiredProductVariantOptions[values.productType] || []

      for (const requiredOption of requiredVariantOptions) {
        if (!variantValues[requiredOption]?.length) {
          isValid = false
          errorMessage += `Variant option ${requiredOption} is required for ${values.productType} product type!\n`
        }
      }

      for (const variantOption of presentVariantOptions) {
        if (variantValues[variantOption]?.length === 0) {
          isValid = false
          errorMessage += `Variant option ${variantOption} should have minimum one value!\n`
        }
      }

      if (!isValid) {
        notification.error({
          message: 'Save product error!',
          description: errorMessage
        })
        setIsSaveLoading(true)
        return
      }

      const productData = {
        name: values.name,
        displayName: values.displayName,
        description: values.description,
        productType: values.productType,
        productType2: values.productType2,
        storeId: values.storeId,
        sku: values.sku,
        tags,
        hasCustomShipping: values.hasCustomShipping,
        basePrice: Math.round(parseFloat(values.basePrice) * 100),
        baseDiscount: Math.round(parseFloat(values.baseDiscount) * 100),
        baseCost: Math.round(parseFloat(values.baseCost) * 100 || 0),
        baseMemberPrice: Math.round(parseFloat(values.baseMemberPrice) * 100 || 0),
        weight: +values.weight || 0,
        weightAccuracy: 3,
        weightUnit: 'lb',
        attributes: attributes.map(({ attribute, value }) => ({ attribute, value })) || null,
        customShippingPrice: Math.round(parseFloat(values.customShippingPrice || '0') * 100),

        useReplacementForStock: values.useReplacementForStock,
        stockProductId: values.stockProductId,
        sellWhenOutOfStock: values.sellWhenOutOfStock,

        hasCustomSeo: hasCustomSeo,
        seoTitle: seoTitle,
        seoUrl: seoUrl,
        seoDescription: seoDescription,
        seoKeywords: seoKeywords,
        isActive
      }

      let isSaved = false

      let activeProduct = product.data

      if (!product.data) {
        const createdProduct = await createProduct({
          productData,
          productPriceOwnerType,
          productPriceOwnerId,
          productPricesData: Object.values(variantPrices).map((item) => ({
            sku: item.sku,
            price: Math.round(parseFloat(item.price) * 100),
            discount: Math.round(parseFloat(item.discount) * 100),
            memberPrice: Math.round(parseFloat(item.memberPrice) * 100),
            variantKey: item.variantKey,
            variantSchema: item.variantSchema,
            attributes: []
          })),
          variantValues: Object.keys(variantValues).reduce((accum, variantOption) => {
            accum.push({
              variantOption: variantOption.toUpperCase(),
              values: variantValues[variantOption].map((item) => item.toUpperCase()) || [],
              isMainOption: variantOption.toUpperCase() === mainVariantOption.toUpperCase()
            })
            return accum
          }, [])
        })

        isSaved = !!createdProduct
        activeProduct = createdProduct
      } else {
        isSaved = await updateProductV2({
          campaignId: campaignId,
          productId,
          productData,
          productPriceOwnerType,
          productPriceOwnerId,
          productPricesData: Object.values(variantPrices).map((item) => ({
            sku: item.sku,
            price: Math.round(parseFloat(item.price) * 100),
            discount: Math.round(parseFloat(item.discount) * 100),
            memberPrice: Math.round(parseFloat(item.memberPrice) * 100),
            variantKey: item.variantKey,
            variantSchema: item.variantSchema
          })),
          variantValues: Object.keys(variantValues).reduce((accum, variantOption) => {
            accum.push({
              variantOption: variantOption.toUpperCase(),
              values: variantValues[variantOption].map((item) => item.toUpperCase()) || [],
              isMainOption: variantOption.toUpperCase() === mainVariantOption.toUpperCase()
            })
            return accum
          }, []),
          disabledVariantKeys: values.disabledVariantKeys || [],
          removedVariantKeys: values.removedVariantKeys || []
        })
        queryClient.invalidateQueries(['product', { campaignId, productId, productPriceOwnerType, productPriceOwnerId }])
      }

      const freeProductImageIndex = ['0', '1', '2', '3', '4']

      for (const productImage of productImages) {
        const index = freeProductImageIndex.indexOf(productImage.name)

        if (index > -1) {
          freeProductImageIndex.splice(index, 1)
        }
      }

      if (isSaved && activeProduct) {
        // Remove product images
        for (const productImageToRemove of productImagesToRemove) {
          isSaved = isSaved && (await removeProductImage(activeProduct.id, productImageToRemove.id))

          if (isSaved) {
            freeProductImageIndex.push(productImageToRemove.name)
          }
        }

        if (isSaved) {
          setProductImagesToRemove([])
        }

        const imagesToUpload = [...productImagesToUpload]
        let newListOfFilesToUpload = [...productImagesToUpload]
        // Upload new product images
        for (let i = 0; i < imagesToUpload.length; i++) {
          const fileToUpload = imagesToUpload[i]

          const newFileName = freeProductImageIndex[i]
          if (!newFileName) {
            continue
          }

          const imageData = await uploadFile({
            ownerId: activeProduct.id,
            ownerType: 'PRODUCT',
            file: fileToUpload,
            newFileName
          })
          isSaved = isSaved && !!imageData

          if (isSaved) {
            newListOfFilesToUpload = newListOfFilesToUpload.filter((file) => fileToUpload.uid !== file.uid)
            setProductImagesToUpload(newListOfFilesToUpload)
          }
        }

        if (mainVariantOption) {
          if (variantImagesToCreate[mainVariantOption]) {
            await Object.keys(variantImagesToCreate[mainVariantOption]).forEach(async (variantValue) => {
              const variantOptionObject = activeProduct.variantValues.find((item) => item.variantOption === mainVariantOption)

              if (!variantOptionObject) return

              const variantValueObject = variantOptionObject.valueObjects?.find((item) => item.value === variantValue)

              if (!variantValueObject) return

              for (const asset of variantImagesToCreate[mainVariantOption][variantValue]) {
                await uploadAsset.mutate({
                  ownerType: 'PRODUCT_VARIANT_VALUE',
                  ownerId: +variantValueObject.id,
                  assetData: { name: asset.name, type: asset.type, filename: asset.name, role: 'DEFAULT' },
                  file: asset.originFileObj as File
                })
              }
            })
          }

          if (variantImagesToRemove[mainVariantOption]) {
            await Object.keys(variantImagesToRemove[mainVariantOption]).forEach(async (variantValue) => {
              const variantOptionObject = activeProduct.variantValues.find((item) => item.variantOption === mainVariantOption)

              if (!variantOptionObject) return

              const variantValueObject = variantOptionObject.valueObjects?.find((item) => item.value === variantValue)

              if (!variantValueObject) return

              for (const asset of variantImagesToRemove[mainVariantOption][variantValue]) {
                removeAsset.mutate({ ownerType: 'PRODUCT_VARIANT_VALUE', ownerId: +variantValueObject.id, assetId: asset.id })
              }
            })
          }
        }

        // setDisableInventoryUpdate(false)
      }

      if (isSaved && isSubscription) {
        isSaved =
          isSaved &&
          (await saveSubscriptionData({
            campaignId: campaignId,
            productId: activeProduct.id,
            subscriptionData: {
              interval: selectedInterval,
              repeatFrequency: selectedRepeatFrequency,

              price: productData.basePrice,
              discount: productData.baseDiscount,
              memberPrice: productData.baseMemberPrice,

              items: dataSource.map((item) => ({
                date: item.date,
                productId: item.product.id,
                quantity: parseInt(item.quantity + '')
              }))
            }
          }))
      }

      if (isSaved) {
        setIsFormNeedToBeSaved(false)
        notification.success({
          message: 'Successfully',
          description: `Product was ${product ? 'updated' : 'created'} successfully!`
        })
        setIsSavingFinished(true)
      }
      setIsSaveLoading(false)
    } catch (_e) {
      setIsSaveLoading(false)
    }
  }

  const handleOnClose = (productId?: number) => {
    setIsDuplicateDrawerVisible(false)

    if (productId) {
      setIsSubscription(false)
      setIsActive(false)
      setProductImagesToUpload([])
      form.resetFields()
      history.push(`/products/${productId}/edit`)
    }
  }

  const onVariantImagesToRemove = (variant: string, value: string, assets: AssetDto[]) => {
    setVariantImagesToRemove((prev) => ({
      ...prev,
      [variant]: {
        ...(prev[variant] || {}),
        [value]: assets
      }
    }))
  }

  const onVariantImagesToCreate = (variant: string, value: string, assets: UploadFile<any>[]) => {
    setVariantImagesToCreate((prev) => ({
      ...prev,
      [variant]: {
        ...(prev[variant] || {}),
        [value]: assets
      }
    }))
  }

  const onVariantDTGArtworkToRemove = (variant: string, value: string, assets: AssetDto[]) => {
    setVariantDTGArtworkToRemove((prev) => ({
      ...prev,
      [variant]: {
        ...(prev[variant] || {}),
        [value]: assets
      }
    }))
  }

  const onVariantDTGArtworkToCreate = (variant: string, value: string, assets: UploadFile<any>[]) => {
    setVariantDTGArtworkToCreate((prev) => ({
      ...prev,
      [variant]: {
        ...(prev[variant] || {}),
        [value]: assets
      }
    }))
  }

  return (
    <Layout style={rootStyles.root} className={styles.baseContainer}>
      {campaign?.data?.status === 'MIGRATING' && (
        <div className={styles.blockPage}>
          <div className={styles.blockPageContainer}>
            <p className={styles.blockPageTitle}>
              Campaign is moving to another workspace <span onClick={() => history.goBack()}>Go back</span>
            </p>
          </div>
        </div>
      )}
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title={`${product.data ? 'Update' : 'Create'} product`}
          extra={
            !product.data
              ? [
                  <Typography.Text key='label'>Product Active:</Typography.Text>,
                  <Switch key='active-status' checked={isActive} onChange={setIsActive} />
                ]
              : [
                  <span key='upload-progress'>{uploadAsset.isLoading && <ProgressLoader title='Assets uploading' barsLength={8} />}</span>,
                  <Typography.Text key='label'>Product Active:</Typography.Text>,
                  <Switch key='active-status' checked={isActive} onChange={setIsActive} />,
                  <Button key='duplicate-button' onClick={() => setIsDuplicateDrawerVisible(true)}>
                    Duplicate Product
                  </Button>
                ]
          }
        />
      </Layout.Header>
      <Layout.Content className={styles.contentComponent}>
        {isLoading && <Loading />}
        {!isLoading && (
          <Form
            layout='vertical'
            hideRequiredMark
            onFinish={onFinishHandler}
            form={form}
            onValuesChange={handleFormValueChanged}
            initialValues={{ baseDiscount: 0, baseCost: 0, weight: 0 }}
          >
            <div className={styles.productFormBlock}>
              <Typography style={{ color: 'black', fontWeight: 'bold', paddingBottom: '8px' }}>Product Name</Typography>
              <div className={styles.productFormRow}>
                <Form.Item
                  name='displayName'
                  label='Product name'
                  rules={[{ required: true, message: 'Please input product  name!' }]}
                  className={styles.productFormRowItem}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name='storeId'
                  label='Store'
                  rules={[{ required: false, message: 'Please select store!' }]}
                  className={styles.productFormRowItem}
                >
                  <StoresSelect
                    initialOption={{ id: product.data?.storeId || null, name: product.data?.storeName }}
                    onSelect={(newStore) =>
                      form.setFieldsValue({
                        storeId: newStore?.id
                      })
                    }
                    allowClear
                  />
                </Form.Item>
              </div>

              <div className={styles.productFormRow}>
                <Form.Item
                  name='description'
                  label='Description'
                  rules={[{ required: true, message: 'Please input product description!' }]}
                  className={styles.productFormRowItem}
                >
                  <Input.TextArea />
                </Form.Item>
                <div className={styles.productFormRowItem}>
                  <Typography style={{ color: 'black', paddingBottom: '8px' }}>Product Tag</Typography>
                  <ManageProductTags
                    onTagsChanged={(newTagsList) => handleTagsChanged(newTagsList)}
                    placeholder='Add a product tag'
                    initTags={tags}
                    refreshOnInitTagsChanged
                    campaignId={campaignId}
                  />
                </div>
              </div>
              <div className={styles.productFormRow}>
                <Form.Item
                  name='name'
                  label='Dropdown name'
                  rules={[{ required: true, message: 'Please input dropdown product name!' }]}
                  className={styles.productFormRowItem}
                >
                  <Input />
                </Form.Item>
              </div>
            </div>

            <div className={styles.productFormBlock}>
              <Typography style={{ color: 'black', fontWeight: 'bold', paddingBottom: '8px' }}>Product Type</Typography>
              <div className={styles.productFormRow}>
                <Form.Item
                  name='productType'
                  label='Product type (Deprecated)'
                  rules={[{ required: true, message: 'Please select product type!' }]}
                  className={styles.productFormRowItem}
                >
                  <Select>
                    <Select.Option value={ProductTypes.tshirt}>T-Shirt</Select.Option>
                    <Select.Option value={ProductTypes.crew}>Crew</Select.Option>
                    <Select.Option value={ProductTypes.hoodie}>Hoodie</Select.Option>
                    <Select.Option value={ProductTypes.faceMask}>Face Mask</Select.Option>
                    <Select.Option value={ProductTypes.sticker}>Sticker</Select.Option>
                    <Select.Option value={ProductTypes.subscription}>Subscription</Select.Option>
                    <Select.Option value={ProductTypes.totebag}>Tote Bag</Select.Option>
                    <Select.Option value={ProductTypes.externalProduct}>External product</Select.Option>
                    <Select.Option value={ProductTypes.longSleevedShirt}>Long Sleeved Shirt</Select.Option>
                  </Select>
                </Form.Item>
                {isDTGCampaign && (
                  <Form.Item name='DTGProductId' label='DTG Product' rules={[{ required: false }]} className={styles.productFormRowItem}>
                    <DTGProductsSelect
                      initialOption={{ id: DTGProductAttribute?.id }}
                      onSelect={(selectedOption: SelectOption) => {
                        form.setFieldsValue({
                          DTGProductId: selectedOption?.id
                        })
                      }}
                    />
                    {!!DTGProductAttribute?.id && (
                      <CloseCircleTwoTone
                        twoToneColor='tomato'
                        onClick={() => setDTGProductAttribute(null)}
                        className={styles.deleteButton}
                        style={{ margin: '0 4px' }}
                        id='removeDTGProductType'
                      />
                    )}
                  </Form.Item>
                )}
              </div>

              <Form.Item
                name='productType2'
                label='Product type (New)'
                rules={[{ required: true, message: 'Please select new product type!' }]}
                className={styles.productFormRowItem}
              >
                <Select>
                  <Select.Option value={ProductType2.PHYSICAL}>Physical</Select.Option>
                  <Select.Option value={ProductType2.DIGITAL}>Digital</Select.Option>
                  <Select.Option value={ProductType2.SUBSCRIPTION}>Subscription</Select.Option>
                </Select>
              </Form.Item>

              <div className={styles.productFormRow}>
                <Form.Item
                  name='sku'
                  label='SKU'
                  rules={[
                    { pattern: productSkuPattern, message: productSkuPatternErrorMessage },
                    { required: true, message: 'Please input product SKU!' }
                  ]}
                  className={styles.productFormRowItem}
                >
                  <Input />
                </Form.Item>

                <div className={styles.productFormRowItem}>
                  <Typography style={{ color: 'black', paddingBottom: '8px' }}>Attributes</Typography>
                  <ManageAttributes
                    attributesOptions={attributesOptions}
                    initValues={attributes}
                    onAttributesChanged={handleAttributesChanged}
                  />
                </div>
              </div>

              <div className={styles.productFormRowItem}>
                <Form.Item name='hasCustomShipping' label='Custom shipping' valuePropName='checked' className={styles.productFormRowItem}>
                  <Switch />
                </Form.Item>
              </div>

              {customShippingPriceVisible && (
                <Form.Item name='customShippingPrice' label='Custom shipping price'>
                  <Input type='number' prefix='$' style={{ width: '100%' }} />
                </Form.Item>
              )}
            </div>

            <div className={styles.productFormBlock}>
              <Typography style={{ color: 'black', fontWeight: 'bold', paddingBottom: '8px' }}>Upload Image</Typography>
              <div className={styles.productFormRow}>
                <div className={styles.productFormRowItem}>
                  <Typography>Product Image</Typography>
                  <UploadFiles
                    productImagesToRemove={productImagesToRemove}
                    filesToUpload={productImagesToUpload}
                    productImages={productImages}
                    onChange={(filesToUpload, productImagesToRemove) => {
                      setProductImagesToRemove(productImagesToRemove)
                      setProductImagesToUpload(filesToUpload)
                    }}
                  />
                </div>

                {isDTGCampaign && (
                  <div className={styles.productFormRowItem}>
                    <Typography>DTG Artwork</Typography>

                    {DTGArtworkForPreview && (
                      <div className={styles.artworkPreviewContainer}>
                        <Typography style={{ color: 'black', marginTop: '10px', fontSize: '12px' }}>Print area preview</Typography>
                        <div className={styles.artworkContainer} style={{ ...getMaxPrintArea(selectedProductType as ProductType) }}>
                          <img src={DTGArtworkForPreview} alt='artwork' />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className={styles.productFormBlock}>
              <Typography style={{ color: 'black', fontWeight: 'bold', paddingBottom: '8px' }}>Price</Typography>
              <div className={styles.productFormPriceRow}>
                <Form.Item name='basePrice' label='Product Price' rules={[{ required: true, message: 'Please input product price!' }]}>
                  <Input
                    type='number'
                    prefix='$'
                    style={{ width: '200px', marginRight: '20px' }}
                    disabled={product?.data?.isPriceChangingLocked || (productId && productPriceOwnerType === 'LANDING')}
                  />
                </Form.Item>
                <Form.Item name='baseDiscount' label='Product Discount'>
                  <Input
                    type='number'
                    prefix='$'
                    style={{ width: '200px', marginRight: '20px' }}
                    disabled={product?.data?.isPriceChangingLocked || (productId && productPriceOwnerType === 'LANDING')}
                  />
                </Form.Item>
                <Form.Item name='baseMemberPrice' label='Member Price' rules={[{ required: true, message: 'Please input member price!' }]}>
                  <Input
                    type='number'
                    prefix='$'
                    style={{ width: '200px', marginRight: '20px' }}
                    disabled={product?.data?.isPriceChangingLocked || (productId && productPriceOwnerType === 'LANDING')}
                  />
                </Form.Item>
              </div>
            </div>

            {isSubscription && (
              <div className={styles.productFormOptionBlock}>
                <Typography style={{ color: 'black', fontWeight: 'bold', paddingBottom: '8px' }}>Subscription products</Typography>
                <SubscriptionProductPriceInput
                  price={selectedSubscriptionPrice}
                  discount={selectedSubscriptionDiscount}
                  memberPrice={selectedSubscriptionMemberPrice}
                  interval={selectedInterval}
                  repeatFrequency={selectedRepeatFrequency}
                  dataSource={dataSource}
                  hidePrices
                  disabled={product?.data?.isPriceChangingLocked}
                  onPriceChanged={handleSubscriptionPriceChanged}
                  onDiscountChanged={handleSubscriptionDiscountChanged}
                  onMemberPriceChanged={handleSubscriptionMemberPriceChanged}
                  onIntervalChanged={handleIntervalChanged}
                  onRepeatFrequencyChanged={handleRepeatFrequencyChanged}
                  onDataSourceRemoved={handleRemoveSubscriptionItemConfirmed}
                  setIsUpdateDrawerVisible={setIsUpdateSubscriptionDrawerVisible}
                  setSelectedSubscriptionRecord={setSelectedSubscriptionRecord}
                />
                <div className={styles.createSubscriptionContainer}>
                  <CreateSubscriptionPrice key='2' handleCreateSubscription={handleCreateSubscription} />
                </div>
                <UpdateSubscriptionPrice
                  onClose={handleOnCloseSubscriptionForm}
                  visible={isUpdateSubscriptionDrawerVisible}
                  subscriptionRecord={selectedSubscriptionRecord}
                  handleUpdateSubscription={handleUpdateSubscription}
                />
              </div>
            )}
            {!isSubscription && (
              <div className={styles.productFormOptionBlock}>
                <Typography style={{ color: 'black', fontWeight: 'bold', paddingBottom: '8px' }}>Variants</Typography>
                {variantOptions.map((variantOption) => {
                  return (
                    <div key={variantOption} className={styles.productFormPriceRow}>
                      <div className={styles.labeledItem}>
                        <Typography style={{ color: 'black', paddingBottom: '8px' }}>Options</Typography>
                        <Input className={styles.variantOptionInput} value={variantOption} disabled />
                      </div>
                      {variantOption === 'COLOR' ? (
                        <>
                          {colorManual ? (
                            <div className={styles.optionsContainer}>
                              {/* <Typography style={{ color: 'black', paddingBottom: '8px' }} /> */}
                              <ManageTags
                                onTagsChanged={(newTagsList) => handleVariantValuesChanged(variantOption, newTagsList)}
                                owner={variantOption}
                                placeholder='Add new value'
                                initTags={variantValues[variantOption]}
                                refreshOnInitTagsChanged
                              />
                              <DeleteTwoTone
                                className={styles.deleteTwoTone}
                                twoToneColor='tomato'
                                onClick={() => handleVariantRemoved(variantOption)}
                              />
                            </div>
                          ) : (
                            <Select
                              className={styles.colorSelect}
                              value={variantValues.COLOR || []}
                              mode='multiple'
                              onChange={(newTagsList) => handleVariantValuesChanged('COLOR', newTagsList)}
                            >
                              <Option value='BLACK'>Black</Option>
                              <Option value='BLUE'>Blue</Option>
                              <Option value='GOLD'>Gold</Option>
                              <Option value='GRAY'>Gray</Option>
                              <Option value='IRISH_GREEN'>Irish green</Option>
                              <Option value='LIGHT_BLUE'>Light blue</Option>
                              <Option value='LIGHT_PINK'>Light pink</Option>
                              <Option value='MILITARY_GREEN'>Military green</Option>
                              <Option value='MAROON'>Maroon</Option>
                              <Option value='ORANGE'>Orange</Option>
                              <Option value='PURPLE'>Purple</Option>
                              <Option value='RED'>Red</Option>
                              <Option value='ROYAL_BLUE'>Royal blue</Option>
                              <Option value='WHITE'>White</Option>
                            </Select>
                          )}
                          <div className={styles.colorManual} data-checked={colorManual}>
                            <Switch checked={colorManual} onChange={setColorManual} />
                            &nbsp;&nbsp;Manual
                          </div>
                        </>
                      ) : (
                        <div className={styles.optionsContainer}>
                          {/* <Typography style={{ color: 'black', paddingBottom: '8px' }} /> */}
                          <ManageTags
                            onTagsChanged={(newTagsList) => handleVariantValuesChanged(variantOption, newTagsList)}
                            owner={variantOption}
                            placeholder='Add new value'
                            initTags={variantValues[variantOption]}
                            refreshOnInitTagsChanged
                          />
                          <DeleteTwoTone
                            twoToneColor='tomato'
                            style={{ position: 'absolute', top: '37px', left: '220px' }}
                            onClick={() => handleVariantRemoved(variantOption)}
                          />
                        </div>
                      )}
                    </div>
                  )
                })}

                {!!variantOptions.length && (
                  <>
                    <div className={styles.productFormPriceRow}>
                      <div className={styles.labeledItem}>
                        <Typography style={{ color: 'black', paddingBottom: '8px' }}>Main Option</Typography>
                        <Select
                          style={{ width: 205 }}
                          value={mainVariantOption}
                          onChange={(newMainVariant) => setMainVariantOption(newMainVariant)}
                        >
                          {variantOptions.map((variantOption) => (
                            <Option key={variantOption} value={variantOption}>
                              {variantOption}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div className={styles.productFormPriceRow}>
                      {mainVariantOption && variantValues[mainVariantOption]?.length > 0 && (
                        <div className={styles.labeledItem}>
                          <Typography style={{ color: 'black', paddingBottom: '8px' }}>Variant Images</Typography>

                          {variantValues[mainVariantOption].map((variantKey) => {
                            const variantPreviewImages = variantImages[mainVariantOption]?.[variantKey]
                              .filter(item => item.name !== 'artwork')|| []
                            const variantArtworks = variantImages[mainVariantOption]?.[variantKey]
                              .filter(item => item.name === 'artwork')|| []
                            return (
                              <div key={variantKey} className={styles.previewBlockItem}>
                                <div className={styles.previewBlockItemTitle}>
                                  <div className={styles.previewBlockItemName}>{variantKey}</div>
                                </div>
                                <UploadImages
                                  className={styles.variantImage}
                                  assets={variantPreviewImages}
                                  filesToUpload={variantImagesToCreate[mainVariantOption]?.[variantKey] || []}
                                  assetsToRemove={variantImagesToRemove[mainVariantOption]?.[variantKey] || []}
                                  numberOfImages={5}
                                  onChange={(filesToUpload, assetsToRemove) => {
                                    assetsToRemove.length && onVariantImagesToRemove(mainVariantOption, variantKey, assetsToRemove)
                                    filesToUpload.length && onVariantImagesToCreate(mainVariantOption, variantKey, filesToUpload)
                                  }}
                                />

                                <div style={{ paddingBottom: '8px' }}>
                                  <Typography style={{ color: 'grey', paddingBottom: '8px' }}>DTG Product ID</Typography>
                                  <DTGProductsSelect
                                    initialOption={{ id: DTGProductAttribute?.id }}
                                    onSelect={(selectedOption: SelectOption) => {
                                      form.setFieldsValue({
                                        DTGProductId: selectedOption?.id
                                      })
                                    }}
                                  />
                                  {!!DTGProductAttribute?.id && (
                                    <CloseCircleTwoTone
                                      twoToneColor='tomato'
                                      onClick={() => setDTGProductAttribute(null)}
                                      className={styles.deleteButton}
                                      style={{ margin: '0 4px' }}
                                      id='removeDTGProductType'
                                    />
                                  )}
                                </div>

                                <div>
                                  <Typography style={{ color: 'grey', paddingBottom: '8px' }}>Artwork</Typography>
                                  <UploadImages
                                    className={styles.variantImage}
                                    assets={variantArtworks}
                                    filesToUpload={variantDTGArtworkToCreate[mainVariantOption]?.[variantKey] || []}
                                    assetsToRemove={variantDTGArtworkToRemove[mainVariantOption]?.[variantKey] || []}
                                    numberOfImages={1}
                                    onChange={(filesToUpload, assetsToRemove) => {
                                      assetsToRemove.length && onVariantDTGArtworkToRemove(mainVariantOption, variantKey, assetsToRemove)
                                      filesToUpload.length && onVariantDTGArtworkToCreate(mainVariantOption, variantKey, filesToUpload)
                                    }}
                                  />
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      )}
                    </div>
                  </>
                )}

                {variantOptions.length > 0 && <Divider plain />}

                {isAddNewVariantOptionVisible && (
                  <>
                    <div className={styles.productFormPriceRow} id='productOptionsModalContainer' style={{ position: 'relative' }}>
                      <div className={styles.labeledItem}>
                        <Typography style={{ color: 'black', paddingBottom: '8px' }}>Options</Typography>
                        <AutoComplete
                          options={options}
                          value={newVariantOption}
                          filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                          style={{ width: '200px' }}
                          getPopupContainer={() => document.getElementById('productOptionsModalContainer')}
                          onChange={(val) => {
                            setNewVariantOption(val.toUpperCase())
                          }}
                        />
                      </div>
                    </div>

                    <div className={styles.moreOptionBtn}>
                      <Button className={styles.saveButton} type='primary' onClick={handleNewVariantOptionCreated}>
                        Save
                      </Button>
                      <Button
                        onClick={() => {
                          setNewVariantOption('')
                          setIsAddNewVariantOptionVisible(false)
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </>
                )}

                {!isAddNewVariantOptionVisible && (
                  <Button
                    disabled={variantOptions.length >= 2}
                    className={styles.moreOptionBtn}
                    onClick={() => {
                      setIsAddNewVariantOptionVisible(true)
                    }}
                  >
                    Add new variant
                  </Button>
                )}

                {Object.keys(variantPrices).length > 0 && (
                  <div className={styles.previewBlock}>
                    <Typography style={{ color: 'black', fontWeight: 'bold', paddingBottom: '8px' }}>Variant Prices</Typography>

                    {Object.keys(variantPrices).map((variantKey) => {
                      return (
                        <div key={variantKey} className={styles.previewBlockItem}>
                          <div className={styles.previewBlockItemTitle}>
                            <div className={styles.previewBlockItemName}>{variantKey}</div>
                          </div>
                          <ProductPriceInput />
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            )}

            <ManageSeo
              initValues={{
                hasCustomSeo,
                title: seoTitle,
                url: seoUrl,
                urlPrefix: seoUrlPrefix,
                description: seoDescription,
                keywords: seoKeywords
              }}
              onCustomSeoChanged={setHasCustomSeo}
              onTitleChanged={setSeoTitle}
              onUrlChanged={setSeoUrl}
              onDescriptionChanged={setSeoDescription}
              onKeywordsChanged={setSeoKeywords}
              showDescription={false}
              showKeywords={false}
              showTitle={false}
            />

            <div className={styles.buttonsContainer}>
              <Button onClick={handleFormClose} className={styles.createButton}>
                Cancel
              </Button>
              <Button type='primary' htmlType='submit' loading={isLoading || uploadAsset.isLoading || isSaveLoading}>
                Save
              </Button>
            </div>
          </Form>
        )}
      </Layout.Content>
      {productId && campaignId && isDuplicateDrawerVisible && (
        <DuplicateProduct productId={productId} campaignId={campaignId} visible={isDuplicateDrawerVisible} onClose={handleOnClose} />
      )}
    </Layout>
  )
}

export default connector(EditProduct)
