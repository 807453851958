import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'sortProducts', boolean>

const SORT_PRODUCTS = `
mutation sortProducts ($productId: Int!, $newIndex: Int!) {
  sortProducts (productId: $productId, newIndex: $newIndex)
}
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type SortProductsArgs = {
  productId: number
  newIndex: number
}

type SortProducts = (args: SortProductsArgs) => Promise<SortProductsArgs>

const sortProducts: SortProducts = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: SORT_PRODUCTS,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { sortProducts } = data
    if (!sortProducts) {
      throw new Error("Can't sort products!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

type SortProductEvents = {
  onSettled: (data?: SortProductsArgs) => void
}

const removeProductFromStoreEvents: SortProductEvents = {
  onSettled: () => {
    queryClient.invalidateQueries(['storeProductsPage'])
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useSortProducts = () => useMutation(sortProducts, removeProductFromStoreEvents)
