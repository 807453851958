import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { UpdateLandingsTask } from '../types'

export type Payload = {
  count: number
  items: UpdateLandingsTask[]
}

export type FetchUpdateLandingsTasksListAction = FSA<undefined, Payload, string>
type FetchUpdateLandingsTasksList = (campaignId: number) => MrxThunk<FetchUpdateLandingsTasksListAction>
type QueryResponse = GraphQLResponse<'updateLandingsTasksList', Payload>

const FETCH_UPDATE_LANDINGS_TASK_LIST = `
  query UpdateLandingsTasksList ($page: Int, $size: Int, $campaignId: Int!) {
    updateLandingsTasksList (page: $page, size: $size, campaignId: $campaignId) {
      items {
        id
        status
        taskType
        progress
        retryNumber
        message
        startDate
        startedAt
        endedAt
        createdAt
        updatedAt
        templates {
          id
          name
        }
        failedLandings {
          id
          name
        }
      }
      count
    }
  }
`

const fetchUpdateLandingsTasksList: FetchUpdateLandingsTasksList = (campaignId) => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_UPDATE_LANDINGS_TASK_LIST,
    meta: { done: false }
  })

  try {
    const {
      tasksQueue: {
        updateLandingsTasksPagination: { size, currentPage: page }
      }
    } = getState()

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_UPDATE_LANDINGS_TASK_LIST,
      variables: { page, size, campaignId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateLandingsTasksList } = data
    if (!updateLandingsTasksList) {
      throw new Error("Can't get tasks queue list page!")
    }

    dispatch({
      type: t.FETCH_UPDATE_LANDINGS_TASK_LIST,
      payload: updateLandingsTasksList,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_UPDATE_LANDINGS_TASK_LIST,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchUpdateLandingsTasksList }
