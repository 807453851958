import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { SiteType } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Component } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'updateComponentSourceStyle', boolean>

const UPDATE_COMPONENT_SOURCE_STYLE = `
  mutation UpdateComponentSourceStyle($componentId: Int!, $sourceStyle: String!) {
    updateComponentSourceStyle(componentId: $componentId, sourceStyle: $sourceStyle)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateComponentArgs = {
  componentId: number
  sourceStyle: string
  ownerType: SiteType
  ownerId: number
}

type UpdateComponent = (args: UpdateComponentArgs) => Promise<UpdateComponentArgs>

const updateComponent: UpdateComponent = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_COMPONENT_SOURCE_STYLE,
      variables: {
        componentId: args.componentId,
        sourceStyle: args.sourceStyle
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateComponentSourceStyle } = data
    if (!updateComponentSourceStyle) {
      throw new Error("Can't update component!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateComponentContext = { prevComponent?: Component }

type UpdateComponentEvents = {
  onMutate: (variables: UpdateComponentArgs) => Promise<UpdateComponentContext>
  onError: (error: string, variables: UpdateComponentArgs, context: UpdateComponentContext) => void
  onSettled: (data?: UpdateComponentArgs) => void
}

const updateComponentEvents: UpdateComponentEvents = {
  onMutate: async (variables: UpdateComponentArgs) => {
    await queryClient.cancelQueries(['component', variables.componentId])

    // Snapshot the previous value
    const prevComponent = queryClient.getQueryData<Component>(['component', variables.componentId])

    // Optimistically update to the new values
    if (prevComponent) {
      queryClient.setQueryData<Component>(['component', variables.componentId], {
        ...prevComponent,
        id: variables.componentId,
        ...variables
      })
    }

    return { prevComponent }
  },
  onError: (_err, variables, context) => {
    if (context?.prevComponent) {
      // Restore previous version of component on any error
      queryClient.setQueryData<Component>(['component', variables.componentId], context.prevComponent)
    }
  },
  onSettled: (data) => {
    if (data?.componentId) {
      queryClient.invalidateQueries([`${data.ownerType.toLowerCase()}`, +data.ownerId])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUpdateSourceStyle = () => useMutation(updateComponent, updateComponentEvents)
