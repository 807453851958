import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { CreateCurrencyDto, CurrencyDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'createCurrency', CurrencyDto>

const CREATE_CURRENCY = `
  mutation createCurrency ($currencyData: CurrencyInput!) {
    createCurrency(currencyData: $currencyData) {
      id
      name
      code
      symbol
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type CreateCurrency = (currencyData: CreateCurrencyDto) => Promise<CurrencyDto>

const createCurrency: CreateCurrency = async currencyData => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_CURRENCY,
      variables: { currencyData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createCurrency } = data
    if (!createCurrency) {
      throw new Error("Can't create currency!")
    }

    return createCurrency
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type CreateCurrencyEvents = {
  onSettled: (data?: CurrencyDto) => void
}

const createCurrencyEvents: CreateCurrencyEvents = {
  onSettled: currency => {
    if (currency?.id) {
      queryClient.invalidateQueries(['currenciesList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCreateCurrency = () => useMutation(createCurrency, createCurrencyEvents)
