import settings from 'config/settings'
import axios, { AxiosRequestConfig } from 'axios'

const instance = axios.create({ baseURL: settings.shoppingCartUrl })

console.log('Axios util was initialized!')

export const initializeAxiosInterceptor = () => {
  instance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token')

      if (!token) {
        throw new Error('Unauthorized!')
      }

      config.headers.crmauthorization = token ? `Bearer ${token}` : config.headers.crmauthorization
      return config
    },
    (err: any) => Promise.reject(err)
  )
}

initializeAxiosInterceptor()

export default instance
