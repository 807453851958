import React, { useState, useEffect, useRef } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Drawer, Input, Form, Avatar } from 'antd'
import notification from 'mrx-notification'
import styles from './CreateWorkspace.module.scss'
import { connector, PropsFromRedux } from './container'

enum Fields {
  name = 'name'
}

const CreateWorkspace: React.FC<PropsFromRedux> = props => {
  const [isVisible, setVisible] = useState(false)
  const refEl = useRef<Input>(null)

  const { isLoading, createWorkspace } = props

  useEffect(() => {
    if (isVisible) {
      refEl.current && refEl.current.focus()
    }
  }, [isVisible])

  const showDrawer = () => {
    setVisible(!isVisible)
  }

  const handleClose = () => {
    setVisible(false)
  }

  const onFinishHandler = async (values: Partial<typeof Fields>) => {
    const isSaved = await createWorkspace(values.name || '')
    if (isSaved) {
      notification.success({
        message: 'Successfully',
        description: 'Workspace was created successfully!'
      })
      handleClose()
    }
  }

  return (
    <>
      <div onClick={showDrawer} className={styles.buttonContainer}>
        <Avatar shape='square' size='large' icon={<PlusOutlined />} className={styles.button}>
          CREATE
        </Avatar>
      </div>

      <Drawer title='Create Workspace' width='400' onClose={handleClose} visible={isVisible} forceRender>
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler}>
          <Form.Item
            name={Fields.name}
            label='Name'
            rules={[{ required: true, message: 'Please input workspace name!' }]}
          >
            <Input ref={refEl} />
          </Form.Item>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button onClick={handleClose} className={styles.createButton}>
              Close
            </Button>
            <Button type='primary' htmlType='submit' loading={isLoading}>
              Create
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

export default connector(CreateWorkspace)
