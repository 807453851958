import * as t from './actionTypes'
import { actionTypes as nt } from 'features/navigation'
import createReducer from 'store/createReducer'
import { isSuccessAction, isErrorAction } from 'types'
import produce from 'immer'
import * as tt from '../authentication/actionTypes'
import { WorkspacesState } from './types'
import {
  ActiveWorkspaceLoadedAction,
  CreateWorkspaceAction,
  FetchWorkspaceAction,
  FetchWorkspacesListAction,
  InviteUserAction,
  RemoveUserAction,
  SwitchWorkspaceAction,
  UpdateWorkspaceAction,
  // ValidateUserEmailAction,
  WorkspacesLoadedAction
} from './actions'

const initialState: WorkspacesState = {
  workspaces: {},
  activeWorkspace: null,
  UIState: {
    isCreateWorkspace: false,
    isWorkspaceLoading: false,
    isWorkspaceListLoading: false,
    isInviteUser: false,
    isRemoveUser: false,
    isSwitchWorkspace: false,
    isUpdateWorkspace: false,
    isUserEmailValidating: false
  }
}

const workspacesReducer = createReducer<WorkspacesState>(initialState, {
  [t.ACTIVE_WORKSPACE_LOADED]: (state, action: ActiveWorkspaceLoadedAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.activeWorkspace = action.payload
      }
    }),

  [t.CREATE_WORKSPACE]: (state, action: CreateWorkspaceAction) =>
    produce(state, draft => {
      draft.UIState.isCreateWorkspace = !action.meta?.done
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.error = action.payload
        draft.UIState.isCreateWorkspace = false
      }

      if (isSuccessAction(action)) {
        draft.workspaces[action.payload.id] = action.payload
      }
    }),

  [t.FETCH_WORKSPACE]: (state, action: FetchWorkspaceAction) =>
    produce(state, draft => {
      draft.UIState.isWorkspaceListLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isWorkspaceListLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (action.payload) {
          if (draft.workspaces[action.payload.id]) {
            delete draft.workspaces[action.payload.id]
          }
          draft.workspaces[action.payload.id] = action.payload
          if (draft.activeWorkspace.id === action.payload.id) {
            draft.activeWorkspace = action.payload
          }
        }
      }
    }),

  [t.FETCH_WORKSPACES_LIST]: (state, action: FetchWorkspacesListAction) =>
    produce(state, draft => {
      draft.UIState.isWorkspaceListLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isWorkspaceListLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (action.payload) {
          action.payload.forEach(item => {
            draft.workspaces[item.id] = item
          })
        }
      }
    }),

  [t.INVITE_USER]: (state, action: InviteUserAction) =>
    produce(state, draft => {
      draft.UIState.isInviteUser = !action.meta?.done
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.error = action.payload
        draft.UIState.isInviteUser = false
      }

      if (isSuccessAction(action)) {
        draft.activeWorkspace.members = draft.activeWorkspace.members || []
        draft.activeWorkspace.members.push(action.payload)
      }
    }),

  [t.REMOVE_USER]: (state, action: RemoveUserAction) =>
    produce(state, draft => {
      if (isErrorAction(action)) {
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.activeWorkspace.members) {
          draft.activeWorkspace.members = draft.activeWorkspace.members.filter(item => item.id !== action.payload)
        }
      }
    }),

  [t.SWITCH_WORKSPACE]: (state, action: SwitchWorkspaceAction) =>
    produce(state, draft => {
      draft.UIState.isSwitchWorkspace = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isSwitchWorkspace = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.activeWorkspace = action.payload
        if (draft.workspaces[action.payload.id]) {
          draft.workspaces[action.payload.id] = action.payload
        }
      }
    }),

  [t.UPDATE_WORKSPACE]: (state, action: UpdateWorkspaceAction) =>
    produce(state, draft => {
      draft.UIState.isUpdateWorkspace = !action.meta?.done
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.error = action.payload
        draft.UIState.isUpdateWorkspace = false
      }

      if (isSuccessAction(action)) {
        const { workspaceId, workspaceData } = action.payload
        if (state.activeWorkspace.id === workspaceId) {
          draft.activeWorkspace = {
            ...state.activeWorkspace,
            ...workspaceData
          }
        }

        if (draft.workspaces[workspaceId]) {
          draft.workspaces[workspaceId] = {
            ...state.workspaces[workspaceId],
            ...workspaceData
          }
        }
      }
    }),

  [t.WORKSPACES_LOADED]: (state, action: WorkspacesLoadedAction) =>
    produce(state, draft => {
      if (action.payload) {
        action.payload.forEach(item => {
          draft.workspaces[item.id] = item
        })
      }
    }),

  [tt.LOGOUT]: state =>
    produce(state, draft => {
      draft.workspaces = {}
      draft.activeWorkspace = null
    }),

  [nt.LOCATION_CHANGE]: state =>
    produce(state, draft => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default workspacesReducer
