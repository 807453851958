import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { ShipstationSetting } from '../types'

type ShipstationSettingsList = {
  items: ShipstationSetting[]
  total: number
}

type Payload = ShipstationSettingsList & {
  page: number
}

export type FetchShipstationSettingsListAction = FSA<undefined, Payload, string>
type FetchShipstationSettingsList = () => MrxThunk<FetchShipstationSettingsListAction>
type QueryResponse = GraphQLResponse<'shipstationSettingsListPage', ShipstationSettingsList>

const FETCH_SHIPSTATION_SETTINGS_LIST = `
  query fetchShipstationSettingsList ($page: Int, $size: Int, $workspaceId: Int!) {
    shipstationSettingsListPage (page: $page, size: $size, workspaceId: $workspaceId) {
      items {
        id
        name
        apiKey
        apiSecret
        storeId
      }
      total
    }
  }
`

const fetchShipstationSettingsList: FetchShipstationSettingsList = () => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_SHIPSTATION_SETTINGS_LIST,
    meta: { done: false }
  })

  try {
    const {
      workspaces: {
        activeWorkspace: { id: workspaceId }
      },
      shipstationSettings: {
        pagination: { currentPage: page, size }
      }
    } = getState()
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_SHIPSTATION_SETTINGS_LIST,
      variables: { page, size, workspaceId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { shipstationSettingsListPage } = data
    if (!shipstationSettingsListPage) {
      throw new Error("Can't get Shipstation Settings list page!")
    }

    dispatch({
      type: t.FETCH_SHIPSTATION_SETTINGS_LIST,
      payload: {
        page,
        total: shipstationSettingsListPage.total,
        items: shipstationSettingsListPage.items
      },
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_SHIPSTATION_SETTINGS_LIST,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchShipstationSettingsList }
