import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { ProductImage } from '../types'
import * as t from '../actionTypes'

type Payload = {
  productId: number
  productImages: Record<number, ProductImage>
}
export type FetchProductImagesListAction = FSA<undefined, Payload, string>
type FetchProductImagesList = (productId: number) => MrxThunk<FetchProductImagesListAction>
type QueryResponse = GraphQLResponse<'productImagesList', ProductImage[]>

const FETCH_PRODUCT_IMAGES_LIST = `
  query fetchProductImagesList($productId: Int) {
    productImagesList(productId: $productId) {
      id
      workspaceId
      name
      extension
      productId
      s3bucket
      s3key
      imageUrl
      createdAt
      updatedAt
    }
  }
`

const fetchProductImagesList: FetchProductImagesList = (productId) => async (dispatch) => {
  dispatch({
    type: t.FETCH_PRODUCT_IMAGES_LIST,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_PRODUCT_IMAGES_LIST,
      variables: { productId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { productImagesList } = data
    if (!productImagesList) {
      throw new Error("Can't fetch product images list!")
    }

    dispatch({
      type: t.FETCH_PRODUCT_IMAGES_LIST,
      payload: {
        productId,
        productImages: productImagesList.reduce<Record<number, ProductImage>>((acc, cur) => {
          acc[cur.id] = cur
          return acc
        }, {})
      },
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_PRODUCT_IMAGES_LIST,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchProductImagesList }
