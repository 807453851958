import React from 'react'
import moment from 'moment'
import { Input, Typography, Select, Popconfirm, Table } from 'antd'
import { DeleteTwoTone, EditOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table'
import { SubscriptionItem } from 'features/types'
import { SubscriptionIntervalEnum } from '../../types'
import styles from './SubscriptionProductPriceInput.module.scss'

type SubscriptionProductPriceInputArgs = {
  price?: number
  discount?: number
  memberPrice?: number
  hidePrices?: boolean
  interval: SubscriptionIntervalEnum
  repeatFrequency: number
  dataSource: SubscriptionItem[]
  disabled?: boolean
  onPriceChanged?: (newPrice: number) => void
  onDiscountChanged?: (newDiscount: number) => void
  onMemberPriceChanged?: (newPrice: number) => void
  onIntervalChanged: (newInterval: SubscriptionIntervalEnum) => void
  onRepeatFrequencyChanged: (newRepeatFrequency: number) => void
  onDataSourceRemoved: (subscriptionId: number, e?: React.MouseEvent<HTMLElement>) => void
  setIsUpdateDrawerVisible: (visible: boolean) => void
  setSelectedSubscriptionRecord: (subscriptionItem: SubscriptionItem) => void
}

const SubscriptionProductPriceInput = (props: SubscriptionProductPriceInputArgs) => {
  const {
    price,
    discount,
    memberPrice,
    hidePrices,
    interval,
    repeatFrequency,
    dataSource,
    disabled,
    onPriceChanged,
    onDiscountChanged,
    onMemberPriceChanged,
    onIntervalChanged,
    onRepeatFrequencyChanged,
    onDataSourceRemoved,
    setIsUpdateDrawerVisible,
    setSelectedSubscriptionRecord
  } = props

  const columns: ColumnsType<SubscriptionItem> = [
    {
      title: 'Date',
      render: (record) => moment(record.date).format('MM/DD/YYYY'),
      key: 'date'
    },
    {
      title: 'Product',
      render: (record) => record.product.name,
      key: 'product'
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '15%',
      align: 'center',
      render: (record) => (
        <>
          <span
            style={{ marginRight: '20px' }}
            onClick={(e) => {
              e.stopPropagation()
              setSelectedSubscriptionRecord(record)
              setIsUpdateDrawerVisible(true)
            }}
          >
            <EditOutlined style={{ fontSize: '20px', cursor: 'pointer' }} />
          </span>
          <span>
            <Popconfirm
              title='Are you sure delete this record?'
              onConfirm={(e) => onDataSourceRemoved(record.product.id, e)}
              onCancel={(e) => e?.stopPropagation()}
              okText='Yes'
              cancelText='No'
            >
              <DeleteTwoTone
                twoToneColor='#ec1c24'
                style={{ fontSize: '20px' }}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              />
            </Popconfirm>
          </span>
        </>
      )
    }
  ]

  return (
    <div className={styles.root}>
      <div className={styles.settingsRow}>
        <div className={styles.settingItem}>
          <Typography style={{ color: 'rgb(0, 0, 0, 0.85)', paddingBottom: '8px' }}>Interval</Typography>
          <Select value={interval} disabled={disabled} style={{ width: '200px' }} onChange={onIntervalChanged}>
            <Select.Option value='DAY'>Day</Select.Option>
            <Select.Option value='MONTH'>Month</Select.Option>
          </Select>
        </div>

        <div className={styles.settingItem}>
          <Typography style={{ color: 'rgb(0, 0, 0, 0.85)', paddingBottom: '8px' }}>Repeat Frequency</Typography>
          <Input
            type='number'
            disabled={disabled}
            style={{ width: '200px' }}
            onChange={(e) => onRepeatFrequencyChanged(parseFloat(e.target.value))}
            value={repeatFrequency}
          />
        </div>
      </div>

      {!hidePrices && (
        <div className={styles.settingsRow}>
          <div className={styles.settingItem}>
            <Typography style={{ color: 'rgb(0, 0, 0, 0.85)', paddingBottom: '8px' }}>Price</Typography>
            <Input
              type='number'
              style={{ width: '200px' }}
              disabled={disabled}
              onChange={(e) => onPriceChanged(parseFloat(e.target.value))}
              value={price}
              prefix='$'
            />
          </div>

          <div className={styles.settingItem}>
            <Typography style={{ color: 'rgb(0, 0, 0, 0.85)', paddingBottom: '8px' }}>Discount</Typography>
            <Input
              type='number'
              style={{ width: '200px' }}
              disabled={disabled}
              onChange={(e) => onDiscountChanged(parseFloat(e.target.value))}
              value={discount}
              prefix='$'
            />
          </div>

          <div className={styles.settingItem}>
            <Typography style={{ color: 'rgb(0, 0, 0, 0.85)', paddingBottom: '8px' }}>Member Price</Typography>
            <Input
              type='number'
              style={{ width: '200px' }}
              disabled={disabled}
              onChange={(e) => onMemberPriceChanged(parseFloat(e.target.value))}
              value={memberPrice}
              prefix='$'
            />
          </div>
        </div>
      )}

      <Table columns={columns} dataSource={dataSource} bordered rowKey={(_record, index) => index} pagination={false} />
    </div>
  )
}

export default SubscriptionProductPriceInput
