import * as authentication from './authentication'
import * as adSpends from './adSpends'
import * as addresses from './addresses'
import * as alerts from './alerts'
import * as assets from './assets'
import * as authorizeSettings from './authorizeSettings'
import * as autoresponders from './autoresponders'
import * as awsCertificates from './awsCertificates'
import * as awsSettings from './awsSettings'
import * as campaigns from './campaigns'
import * as carts from './carts'
import * as categories from './categories'
import * as components from './components'
import * as countries from './countries'
import * as currencies from './currencies'
import * as customerAccounts from './customerAccounts'
import * as customers from './customers'
import * as customerSubscriptions from './customerSubscriptions'
import * as customGatewayArtwork from './customGatewayArtwork'
import * as customGatewaySettings from './customGatewaySettings'
import * as dependencies from './dependencies'
import * as domains from './domains'
import * as DTGProducts from './DTGProducts'
import * as DTGSizes from './DTGSizes'
import * as DTGVariants from './DTGVariants'
import * as eternalLists from './eternalLists'
import * as files from './files'
import * as landings from './landings' // TODO - remove becouse of legacy
import * as inventory from './inventory'
import * as templates from './templates' // TODO - remove becouse of legacy
import * as navigation from './navigation'
import * as nginxSettings from './nginxSettings'
import * as orderItems from './orderItems'
import * as orders from './orders'
import * as overviewReport from './overviewReport'
import * as pages from './pages'
import * as payPalSettings from './payPalSettings'
import * as plugins from './plugins'
import * as postmarkSettings from './postmarkSettings'
import * as processingCosts from './processingCosts'
import * as productAttributes from './productAttributes'
import * as productColors from './productColors'
import * as productImages from './productImages'
import * as productSizes from './productSizes'
import * as productTags from './productTags'
import * as promocodes from './promocodes'
import * as products from './products'
import * as quizzes from './quizzes' // TODO - remove becouse of legacy
import * as regions from './regions'
import * as reports from './reports'
import * as sendgridSettings from './sendgridSettings'
import * as settings from './settings'
import * as shippingMethods from './shippingMethods'
import * as shippingRules from './shippingRules'
import * as shipstationSetting from './shipstationSettings'
import * as stores from './stores'
import * as simpleTextSettings from './simpleTextSettings'
import * as sitePreviews from './sitePreviews'
import * as siteSnapshots from './siteSnapshots'
import * as sitesManagement from './sitesManagement'
import * as stripeSettings from './stripeSettings'
import * as tags from 'features/tags'
import * as tasksQueue from './tasksQueue'
import * as users from './users'
import * as websocket from './websocket'
import * as workspaces from './workspaces'
import * as zipwhipSettings from './zipwhipSettings'

export default {
  authentication,
  adSpends,
  addresses,
  alerts,
  assets,
  authorizeSettings,
  autoresponders,
  awsCertificates,
  awsSettings,
  campaigns,
  carts,
  categories,
  components,
  countries,
  currencies,
  customerAccounts,
  customers,
  customerSubscriptions,
  customGatewayArtwork,
  customGatewaySettings,
  dependencies,
  domains,
  DTGProducts,
  DTGSizes,
  DTGVariants,
  eternalLists,
  files,
  landings,
  inventory,
  templates,
  navigation,
  nginxSettings,
  orderItems,
  orders,
  overviewReport,
  pages,
  payPalSettings,
  plugins,
  postmarkSettings,
  processingCosts,
  productAttributes,
  productColors,
  productImages,
  productSizes,
  productTags,
  products,
  promocodes,
  quizzes,
  regions,
  reports,
  sendgridSettings,
  settings,
  shippingMethods,
  shippingRules,
  shipstationSetting,
  stores,
  simpleTextSettings,
  sitePreviews,
  siteSnapshots,
  sitesManagement,
  stripeSettings,
  tags,
  tasksQueue,
  users,
  websocket,
  workspaces,
  zipwhipSettings
}
