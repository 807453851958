// TODO - remove becouse of legacy

import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { CreateQuizDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'updateQuiz', boolean>

const UPDATE_QUIZ = `
  mutation UpdateQuiz($quizId: Int!, $quizData: QuizInput!) {
    updateQuiz(quizId: $quizId, quizData: $quizData)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateQuizArgs = {
  quizId: number
  quizData: CreateQuizDto
}

type UpdateQuiz = (args: UpdateQuizArgs) => Promise<UpdateQuizArgs>

const updateQuiz: UpdateQuiz = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_QUIZ,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateQuiz } = data
    if (!updateQuiz) {
      throw new Error("Can't update quiz!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateQuizEvents = {
  onSettled: (data?: UpdateQuizArgs) => void
}

const updateQuizEvents: UpdateQuizEvents = {
  onSettled: (data) => {
    if (data?.quizId) {
      queryClient.invalidateQueries(['quiz', data.quizId])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUpdateQuiz = () => useMutation(updateQuiz, updateQuizEvents)
