import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

export type RemoveProductColorAction = FSA<undefined, number, string>
type RemoveProductColor = (productColorId: number) => MrxThunk<RemoveProductColorAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'removeProductColor', boolean>

const REMOVE_PRODUCT_COLOR = `
  mutation removeProductColor ($productColorId: Int!) {
    removeProductColor(productColorId: $productColorId) 
  }
`

const removeProductColor: RemoveProductColor = (productColorId) => async (dispatch) => {
  let isRemoved = false

  dispatch({
    type: t.REMOVE_PRODUCT_COLOR,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_PRODUCT_COLOR,
      variables: { productColorId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Empty response!')
    }

    const { removeProductColor } = data
    if (!removeProductColor) {
      throw new Error("Can't remove product color!")
    }

    dispatch({
      type: t.REMOVE_PRODUCT_COLOR,
      payload: productColorId,
      meta: { done: true }
    })

    isRemoved = true
  } catch (err) {
    dispatch({
      type: t.REMOVE_PRODUCT_COLOR,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isRemoved
}

export { removeProductColor }
