import { createSelector, ParametricSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { AwsCertificate } from '../types'

const getAwsCertificate: ParametricSelector<ReduxState, number | undefined, AwsCertificate | undefined> = (state, awsCertificateId) => {
  let result
  if (awsCertificateId && Object.prototype.hasOwnProperty.call(state.awsCertificates.awsCertificates, awsCertificateId)) {
    result = state.awsCertificates.awsCertificates[awsCertificateId] as AwsCertificate
  }
  return result
}

export const awsCertificateSelector = createSelector(getAwsCertificate, (awsCertificate) => awsCertificate)
