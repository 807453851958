import React, { useEffect, useState } from 'react'
import hash from 'object-hash'
import { Input, Tag, Tooltip } from 'antd'
import styles from './ManageFormTags.module.scss'

type Props = {
  onTagCreated?: (newTag: string) => void
  onTagRemoved?: (removedTag: string) => void
  onTagsChanged?: (tagsList: string[]) => void

  owner?: string
  initTags?: string[]
  placeholder?: string
  refreshOnInitTagsChanged?: boolean
}

const ManageTags = (props: Props) => {
  const { onTagCreated, onTagRemoved, onTagsChanged, owner = '', initTags = [], refreshOnInitTagsChanged = false } = props

  const [tags, setTags] = useState(initTags)

  const [newTag, setNewTag] = useState('')

  useEffect(() => {
    if (refreshOnInitTagsChanged) {
      setTags(initTags)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(initTags)])

  useEffect(() => {
    setTags(initTags)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [owner])

  const handleTagRemoved = (removedTag) => {
    const newTagsState = tags.filter((tag) => tag !== removedTag)
    setTags(newTagsState)

    onTagRemoved && onTagRemoved(removedTag)
    onTagsChanged && onTagsChanged(newTagsState)
  }

  const handleInputChange = (e) => {
    setNewTag(e.target.value?.toUpperCase())
  }

  const handleTagCreated = (e) => {
    e.preventDefault()

    let newTags = [...tags]
    if (newTag && newTags.indexOf(newTag) === -1) {
      newTags = [...tags, newTag]
    }
    setTags(newTags)
    setNewTag('')

    onTagCreated && onTagCreated(newTag)
    onTagsChanged && onTagsChanged(newTags)
  }

  return (
    <>
      <Input
        type='text'
        size='small'
        // is's not working with scss, just inline
        style={{
          width: '200px',
          verticalAlign: 'top',
          marginTop: '22px',
          height: '32px',
          display: 'flex',
          alignItems: 'center'
        }}
        className={styles.tagInput}
        value={newTag}
        onChange={handleInputChange}
        onBlur={handleTagCreated}
        onPressEnter={handleTagCreated}
      />

      {tags.map((tag) => {
        const isLongTag = tag.length > 20

        const tagElem = (
          <Tag style={{ userSelect: 'none', marginTop: '10px' }} key={tag} closable onClose={() => handleTagRemoved(tag)}>
            <span>{isLongTag ? `${tag.slice(0, 20)}...` : tag}</span>
          </Tag>
        )
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        )
      })}
    </>
  )
}

export default ManageTags
