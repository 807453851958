import { PRODUCT_TYPE } from '@merchx-v2/shared-types'
import { ProductAttributes, ProductTypes } from 'consts'

const {
  REACT_APP_BACKEND_URL,
  REACT_APP_SHOP_URL,
  REACT_APP_SHOPPING_CARTS_HOST,
  REACT_APP_SITE_BUILDER_URL,
  REACT_APP_SITE_BUILDER_API_KEY
} = process.env

export default {
  backendUrl: REACT_APP_BACKEND_URL || 'http://localhost:4445',
  shopUrl: REACT_APP_SHOP_URL || 'http://localhost:3000',
  shoppingCartUrl: REACT_APP_SHOPPING_CARTS_HOST || 'http://localhost:4440',
  siteBuilderUrl: REACT_APP_SITE_BUILDER_URL || 'https://site-builder-staging.merchx.com',
  siteBuilderApiKey: REACT_APP_SITE_BUILDER_API_KEY || 'secret',
  requiredProductAttributes: [
    {
      productType: ProductTypes.tshirt,
      attributes: [ProductAttributes.color]
    },
    {
      productType: ProductTypes.crew,
      attributes: [ProductAttributes.color]
    }
  ],
  productTypeAttributes: {
    [ProductTypes.tshirt]: [ProductAttributes.size],
    [ProductTypes.crew]: [ProductAttributes.size],
    [ProductTypes.hoodie]: [ProductAttributes.size],
    [ProductTypes.externalProduct]: [],
    [ProductTypes.faceMask]: [],
    [ProductTypes.sticker]: [],
    [ProductTypes.totebag]: []
  },
  requiredProductVariantOptions: {
    [PRODUCT_TYPE.TSHIRT]: ['SIZE', 'COLOR'],
    [PRODUCT_TYPE.CREW]: ['SIZE', 'COLOR'],
    [PRODUCT_TYPE.HOODIE]: ['SIZE', 'COLOR'],
    [PRODUCT_TYPE.EXTERNAL_PRODUCT]: [],
    [PRODUCT_TYPE.FACE_MASK]: [],
    [PRODUCT_TYPE.STICKER]: [],
    [PRODUCT_TYPE.SUBSCRIPTION]: [],
    [PRODUCT_TYPE.TOTEBAG]: [],
    [PRODUCT_TYPE.TSHIRT_LEGACY]: []
  },
  defaultProductVariantOptionValues: {
    SIZE: ['SM', 'MD', 'LG', 'XL', '2XL']
  },
  costs: {
    processing: {
      accuracy: 4
    }
  }
}
