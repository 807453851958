// TODO - remove becouse of legacy

import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Landing } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'removeLanding', boolean>

const REMOVE_LANDING = `
  mutation removeLanding($landingId: Int!) {
    removeLanding(landingId: $landingId)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveLanding = (landingId: number) => Promise<number>

const removeLanding: RemoveLanding = async (landingId) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_LANDING,
      variables: {
        landingId
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeLanding } = data
    if (!removeLanding) {
      throw new Error("Can't remove landing!")
    }

    return landingId
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveLandingContext = { prevLanding?: Landing }

type RemoveLandingEvents = {
  onMutate: (landingId: number) => Promise<RemoveLandingContext>
  onError: (error: string, landingId: number, context: RemoveLandingContext) => void
  onSettled: (landingId?: number) => void
}

const removeLandingEvents: RemoveLandingEvents = {
  onMutate: async (landingId) => {
    await queryClient.cancelQueries(['landing', landingId])

    // Snapshot the previous value
    const prevLanding = queryClient.getQueryData<Landing>(['landing', landingId])

    if (prevLanding) {
      queryClient.removeQueries(['landing', landingId])
    }

    return { prevLanding }
  },
  onError: (_err, landingId, context) => {
    if (context?.prevLanding) {
      // Restore landing on any error
      queryClient.setQueryData<Landing>(['landing', landingId], context.prevLanding)
    }
  },
  onSettled: (landingId: number) => {
    if (landingId) {
      queryClient.invalidateQueries(['landing', landingId])
      queryClient.invalidateQueries(['landingsList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useRemoveLanding = () => useMutation(removeLanding, removeLandingEvents)
