import moment from 'moment-timezone'

const dbOffset = 300

const getNYStartOfDay = (date = moment()) =>
  moment(date)
    .startOf('day')
    .add(moment(date).utcOffset(), 'minutes')
    .add(dbOffset, 'minutes')
    .toDate()

const getNYEndOfDay = (date = moment()) =>
  moment(date)
    .endOf('day')
    .add(moment(date).utcOffset(), 'minutes')
    .add(dbOffset, 'minutes')
    .toDate()

const formatDate = (date: Date, format = 'YYYY-MM-DD HH:mm:ss', timezone: string | null = null) =>
  (timezone ? moment(date).tz(timezone) : moment(date)).format(format)

export default {
  getNYStartOfDay,
  getNYEndOfDay,

  formatDate
}
