import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { ShipstationSetting } from '../types'

type ShipstationSettingInput = {
  name: string
  apiKey: string
  apiSecret: string
  storeId: number
}

export type CreateShipstationSettingAction = FSA<undefined, ShipstationSetting, string>
type CreateShipstationSetting = (
  shipstationSettingData: ShipstationSettingInput
) => MrxThunk<CreateShipstationSettingAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'createShipstationSetting', ShipstationSetting>

const CREATE_SHIPSTATION_SETTING = `
  mutation createShipstationSetting ($workspaceId: Int!, $shipstationSettingData: ShipstationSettingInput!) {
    createShipstationSetting (workspaceId: $workspaceId, shipstationSettingData: $shipstationSettingData) {
      id
      name
      apiKey
      apiSecret
    	storeId
    }
  }
`

const createShipstationSetting: CreateShipstationSetting = (shipstationSettingData) => async (dispatch, getState) => {
  let isSaved = false

  dispatch({
    type: t.CREATE_SHIPSTATION_SETTING,
    meta: { done: false }
  })

  try {
    const workspaceId = getState()?.workspaces?.activeWorkspace?.id
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_SHIPSTATION_SETTING,
      variables: { workspaceId, shipstationSettingData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createShipstationSetting } = data
    if (!createShipstationSetting) {
      throw new Error("Can't create Shipstation Setting!")
    }

    dispatch({
      type: t.CREATE_SHIPSTATION_SETTING,
      payload: createShipstationSetting,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.CREATE_SHIPSTATION_SETTING,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { createShipstationSetting }
