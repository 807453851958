import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { CreateDTGVariantDto, DTGVariantDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'createDTGVariant', DTGVariantDto>

const CREATE_DTG_VARIANT = `
  mutation createDTGVariant ($DTGVariantData: DTGVariantInput!) {
    createDTGVariant(DTGVariantData: $DTGVariantData) {
      id
      DTGProductId
      externalVariantId
      variantKey
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type CreateDTGVariant = (DTGVariantData: CreateDTGVariantDto) => Promise<DTGVariantDto>

const createDTGVariant: CreateDTGVariant = async DTGVariantData => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_DTG_VARIANT,
      variables: { DTGVariantData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createDTGVariant } = data
    if (!createDTGVariant) {
      throw new Error("Can't create DTGVariant!")
    }

    return createDTGVariant
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type CreateDTGVariantEvents = {
  onSettled: (data?: DTGVariantDto) => void
}

const createDTGVariantEvents: CreateDTGVariantEvents = {
  onSettled: DTGVariant => {
    if (DTGVariant?.id) {
      queryClient.invalidateQueries(['DTGVariantsList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCreateDTGVariant = () => useMutation(createDTGVariant, createDTGVariantEvents)
