import * as t from '../actionTypes'

type LocationChangePayload = {
  location: {
    pathname: string
    search: string
    hash: string
  }
  action: string
}

export type ChangeLocationAction = Required<Omit<BaseFSA<LocationChangePayload>, 'meta'>>
type ChangeLocation = (pathname: string, search?: string) => MrxThunk<ChangeLocationAction, Promise<void>>

export const changeLocation: ChangeLocation = (pathname, search = '') => async dispatch => {
  dispatch({
    type: t.LOCATION_CHANGE,
    payload: {
      location: {
        pathname,
        search,
        hash: ''
      },
      action: 'POP'
    }
  })
}
