import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

export type OwnProps = {
  campaignId: number
}

const mapStateToProps = (state: ReduxState) => {
  return {
    currentPage: selectors.updateLandingsListCurrentPageSelector(state),
    error: selectors.errorSelector(state),
    isLoading: selectors.isLoadingSelector(state, 'isUpdateLandingsTasksLoading'),
    tasksList: selectors.updateLandingsTasksSelector(state),
    total: selectors.updateLandingsTasksTotalSelector(state)
  }
}

const mapDispatchToProps = {
  cancelTask: actions.cancelTask,
  changeCurrentPage: actions.changeUpdateLandingsTasksCurrentPage,
  restartTask: actions.restartTask,
  fetchTasksList: actions.fetchUpdateLandingsTasksList
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
