import { UpdatePayPalSettingDto } from '@merchx-v2/shared-types/dist/dto/paypalSettings/update-paypal-settings.dto'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import axios from 'utils/axios'
import * as t from '../actionTypes'

type Payload = {
  payPalSettingId: number
  payPalSettingData: UpdatePayPalSettingDto
}

export type UpdatePayPalSettingAction = FSA<undefined, Payload, string>

type UpdatePayPalSetting = (args: Payload) => MrxThunk<UpdatePayPalSettingAction, Promise<boolean>>

type QueryResponse = GraphQLResponse<'updatePayPalSetting', boolean>

const UPDATE_PAYPAL_SETTING = `
  mutation updatePayPalSetting ($payPalSettingId: Int!, $payPalSettingData: PayPalSettingInput!) {
    updatePayPalSetting (payPalSettingId: $payPalSettingId, payPalSettingData: $payPalSettingData)
  }
`

const updatePayPalSetting: UpdatePayPalSetting = (args) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.UPDATE_PAYPAL_SETTING,
    meta: { done: false }
  })

  try {
    const { payPalSettingId, payPalSettingData } = args
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_PAYPAL_SETTING,
      variables: { payPalSettingId, payPalSettingData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updatePayPalSetting } = data
    if (!updatePayPalSetting) {
      throw new Error("Can't update PayPal setting!")
    }

    dispatch({
      type: t.UPDATE_PAYPAL_SETTING,
      payload: args,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.UPDATE_PAYPAL_SETTING,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { updatePayPalSetting }
