import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

export type FetchProductColorsForOptionsAction = FSA<undefined, SelectOption[], string>
type FetchProductColorsForOptions = (searchText?: string) => MrxThunk<FetchProductColorsForOptionsAction>
type QueryResponse = GraphQLResponse<'productColorsForOptions', SelectOption[]>

const FETCH_PRODUCT_COLORS_FOR_OPTIONS = `
  query fetchProductColorsForOptions($filters: ProductColorsFilters!) {
    productColorsForOptions(filters: $filters) {
      id
      name
    }
  }
`

const fetchProductColorsForOptions: FetchProductColorsForOptions = searchText => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_PRODUCT_COLORS_FOR_OPTIONS,
    meta: { done: false }
  })

  try {
    const workspaceId = getState()?.workspaces?.activeWorkspace?.id
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_PRODUCT_COLORS_FOR_OPTIONS,
      variables: { filters: { workspaceId, searchText } }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { productColorsForOptions } = data
    if (!productColorsForOptions) {
      throw new Error("Can't get product colors!")
    }

    dispatch({
      type: t.FETCH_PRODUCT_COLORS_FOR_OPTIONS,
      payload: productColorsForOptions,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_PRODUCT_COLORS_FOR_OPTIONS,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchProductColorsForOptions }
