import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { ProductSize } from '../types'

export type FetchProductSizeAction = FSA<undefined, ProductSize, string>
type FetchProductSize = (productSizeId: number) => MrxThunk<FetchProductSizeAction>
type QueryResponse = GraphQLResponse<'productSize', ProductSize>

const FETCH_PRODUCT_SIZE = `
  query fetchProductSize ($productSizeId: Int!) {
    productSize(productSizeId: $productSizeId) {
      id
      name
      displayName
      createdAt
      updatedAt
    }
  }
`

const fetchProductSize: FetchProductSize = (productSizeId) => async (dispatch) => {
  dispatch({
    type: t.FETCH_PRODUCT_SIZE,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_PRODUCT_SIZE,
      variables: { productSizeId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { productSize } = data
    if (!productSize) {
      throw new Error("Can't get product size!")
    }

    dispatch({
      type: t.FETCH_PRODUCT_SIZE,
      payload: productSize,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_PRODUCT_SIZE,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchProductSize }
