// TODO - remove becouse of legacy

import React, { useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import InfiniteScroll from 'react-infinite-scroll-component'
import { PageHeader, Table, Layout, Popconfirm, Switch } from 'antd'
import { EditOutlined, EyeInvisibleFilled, EyeOutlined, DeleteTwoTone } from '@ant-design/icons'
import notification from 'mrx-notification'
import { ColumnsType } from 'antd/lib/table'
import { Breadcrumbs } from 'components'
import CreateTemplate from '../CreateTemplate'
import UpdateTemplate from '../UpdateTemplate'
import * as rootStyles from 'assets/layoutStyle'
import { useArchiveTemplate, useTemplatesList, useRemoveTemplate, useUnarchiveTemplate } from '../../hooks'
import styles from './TemplatesListPage.module.scss'
import { Template } from '../../types'

const TemplatesListPage = () => {
  const [selectedTemplateId, setSelectedTemplateId] = useState<number>()
  const [isUpdateDrawerVisible, setIsUpdateDrawerVisible] = useState<boolean>(false)
  const [isArchivedShow, setIsArchivedShow] = useState<boolean>(false)
  const { history } = useReactRouter()

  const archiveTemplate = useArchiveTemplate()
  const unarchiveTemplate = useUnarchiveTemplate()
  const removeTemplate = useRemoveTemplate()
  const templatesList = useTemplatesList({ templateType: null, showArchived: isArchivedShow })
  const templates: Template[] = []
  templatesList &&
    templatesList.data &&
    templatesList.data.pages.forEach((page) => page.items.forEach((template) => templates.push(template)))

  const page = templatesList.data?.pages.length ? templatesList.data?.pages[templatesList.data?.pages.length - 1].currentPage : 1
  const size = templatesList.data?.pages.length ? templatesList.data?.pages[templatesList.data?.pages.length - 1].pageSize : 30

  useEffect(() => {
    if (templatesList.error) {
      notification.error({
        message: 'Template list page error!',
        description: templatesList.error instanceof Error ? templatesList.error.message : templatesList.error.toString()
      })
    }
  }, [templatesList.error])

  const doConfirm = async (templateId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    removeTemplate.mutate(templateId, {
      onSuccess: () => {
        notification.success({
          message: 'Successfully',
          description: 'Template was deleted successfully!'
        })
      },
      onError: (error) => {
        notification.error({
          message: 'Error!',
          description: error.toString()
        })
      }
    })
  }

  const doConfirmArchive = async (templateId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    archiveTemplate.mutate(templateId, {
      onSuccess: () => {
        notification.success({
          message: 'Successfully',
          description: 'Template was deleted successfully!'
        })
      },
      onError: (error) => {
        notification.error({
          message: 'Template list page error!',
          description: error.toString()
        })
      }
    })
  }

  const doConfirmUnarchive = async (templateId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    unarchiveTemplate.mutate(templateId, {
      onSuccess: () => {
        notification.success({
          message: 'Successfully',
          description: 'Template was deleted successfully!'
        })
      },
      onError: (error) => {
        notification.error({
          message: 'Template list page error!',
          description: error.toString()
        })
      }
    })
  }

  const handleOnRow = (record: Template) => {
    return {
      onClick: () => {
        history.push(`/settings/templates/${record.id}`)
      }
    }
  }

  const handleOnClose = () => {
    setIsUpdateDrawerVisible(false)
  }

  const handleViewModeChange = (value) => {
    setIsArchivedShow(value)
  }

  const renderUpdate = (e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    return <UpdateTemplate templateId={selectedTemplateId} onClose={handleOnClose} visible={isUpdateDrawerVisible} />
  }

  const columns: ColumnsType<Template> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      render: (record: Template) => {
        if (record.archived) {
          return (
            <div>
              {record.name}
              {'  '}
              <span style={{ color: 'orange' }}>(ARCHIVED)</span>
            </div>
          )
        }
        return record.name
      },
      key: 'name'
    },
    {
      title: 'Template Type',
      dataIndex: 'templateType',
      key: 'templateType'
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '15%',
      align: 'center',
      render: (record: Template) => (
        <>
          {!record.archived ? (
            <span>
              <Popconfirm
                title='Are you sure archive this template?'
                onConfirm={(e) => doConfirmArchive(record.id, e)}
                onCancel={(e) => e?.stopPropagation()}
                okText='Yes'
                cancelText='No'
              >
                <EyeInvisibleFilled
                  style={{ fontSize: '20px', marginRight: '20px' }}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                />
              </Popconfirm>
            </span>
          ) : (
            <span>
              <Popconfirm
                title='Are you sure make visible this template?'
                onConfirm={(e) => doConfirmUnarchive(record.id, e)}
                onCancel={(e) => e?.stopPropagation()}
                okText='Yes'
                cancelText='No'
              >
                <EyeOutlined
                  style={{ fontSize: '20px', marginRight: '20px' }}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                />
              </Popconfirm>
            </span>
          )}
          <span
            style={{ marginRight: '20px' }}
            onClick={(e) => {
              e.stopPropagation()
              setSelectedTemplateId(record.id)
              setIsUpdateDrawerVisible(true)
            }}
          >
            <EditOutlined style={{ fontSize: '20px', cursor: 'pointer' }} />
          </span>
          <span>
            <Popconfirm
              title='Are you sure delete this template?'
              onConfirm={(e) => doConfirm(record.id, e)}
              onCancel={(e) => e?.stopPropagation()}
              okText='Yes'
              cancelText='No'
            >
              <DeleteTwoTone
                twoToneColor='#ec1c24'
                style={{ fontSize: '20px' }}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              />
            </Popconfirm>
          </span>
        </>
      )
    }
  ]

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title='Templates'
          extra={[
            <Switch
              checkedChildren='Show archived'
              unCheckedChildren='Show archived'
              defaultChecked={isArchivedShow}
              key='2'
              onChange={(value) => handleViewModeChange(value)}
            />,
            <CreateTemplate key='1' />
          ]}
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Settings', url: '/' },
            { title: 'Templates', url: '/' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <InfiniteScroll
          style={{ paddingBottom: '30px' }}
          dataLength={page * size}
          next={templatesList.fetchNextPage}
          hasMore={!!templatesList.hasNextPage}
          loader={<h4>Loading...</h4>}
          refreshFunction={templatesList.refetch}
          scrollThreshold={0.8}
        >
          <Table
            columns={columns}
            dataSource={templates.map((item) => item).flat()}
            bordered
            onRow={handleOnRow}
            rowKey={(record) => record.id}
            pagination={false}
            loading={templatesList.isLoading}
          />
        </InfiniteScroll>
      </Layout.Content>
      {selectedTemplateId && renderUpdate()}
    </Layout>
  )
}

export default TemplatesListPage
