import { extractErrorInfo } from 'utils/graphqlHelpers'
import { MerchxWebSocket } from 'utils/webSocket'
import { unsubscribeFromChannel } from './unsubscribeFromChannel'
import * as t from '../actionTypes'

export type WebSocketDisconnectAction = FSA<undefined, undefined, string>
type WebSocketDisconnect = (socket: MerchxWebSocket) => MrxThunk<WebSocketDisconnectAction>

const wsDisconnect: WebSocketDisconnect = socket => async (dispatch, getState) => {
  console.log('MerchX web socket disconnection started!')

  dispatch({
    type: t.WS_DISCONNECT,
    meta: { done: false }
  })

  try {
    const { channels } = getState().websocket

    for (const channel of Object.keys(channels)) {
      dispatch(unsubscribeFromChannel(channel, socket))
    }
  } catch (err) {
    dispatch({
      type: t.WS_DISCONNECT,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { wsDisconnect }
