import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { StoreDto, CreateStoreDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

type CreateStoreArgs = {
  storeData: CreateStoreDto
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'createStore', StoreDto>

const CREATE_SHOP = `
mutation createStore ($storeData: StoreInput!) {
  createStore (storeData: $storeData) {
    id
          
    name
    description

    ownerType
    ownerId
    ownerName
    
    hasCustomSeo
    seoUrl
    seoTitle
    seoDescription
    seoKeywords

    isActive

    supportEmail

    facebookLink
    twitterLink
    instagramLink
    pinterestLink
    tiktokLink
    linkedInLink
    youtubeLink
    threadsLink

    location

    createdAt
    updatedAt
  }
}
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type CreateStore = (args: CreateStoreArgs) => Promise<StoreDto>

const createStore: CreateStore = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_SHOP,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createStore } = data
    if (!createStore) {
      throw new Error("Can't create store!")
    }

    return createStore
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type CreateStoreEvents = {
  onSettled: (data?: StoreDto) => void
}

const createStoreEvents: CreateStoreEvents = {
  onSettled: (store) => {
    if (store?.id) {
      queryClient.invalidateQueries(['storesPage'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCreateStore = () => useMutation(createStore, createStoreEvents)
