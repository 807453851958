import React, { useState } from 'react'
import { Table, Card, Popconfirm } from 'antd'
import notification from 'mrx-notification'
import { SettingOwner } from '@merchx-v2/shared-types'
import { ColumnsType } from 'antd/lib/table'
import CreateSetting from '../CreateSetting'
import UpdateSetting from '../UpdateSetting'
import { useRemoveSetting } from '../../hooks'
import styles from './SettingsList.module.scss'
import { Setting } from '../../types'

type PropsType = {
  ownerType: SettingOwner
  ownerId: number
  settings: Setting[]
}

const SettingsList = (props: PropsType) => {
  const { ownerType, ownerId, settings } = props

  const [isUpdateVisible, setIsUpdateVisible] = useState<boolean>(false)
  const [selectedSettingId, setSelectedSettingId] = useState<number>()

  const removeSetting = useRemoveSetting()

  const doConfirm = async (settingId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    removeSetting.mutate(
      { ownerType, ownerId, settingId },
      {
        onSuccess: () => {
          notification.success({
            message: 'Successfully',
            description: 'Setting was deleted successfully!'
          })
        },
        onError: () => {
          notification.error({
            message: 'Remove setting error!',
            description: removeSetting.error.toString()
          })
        }
      }
    )
  }

  const handleOnRow = (record: Setting) => ({
    onClick() {
      setSelectedSettingId(record.id)
      setIsUpdateVisible(true)
    }
  })

  const handleOnSettingsClose = () => {
    setIsUpdateVisible(false)
  }

  const columns: ColumnsType<Setting> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Alias',
      dataIndex: 'alias',
      key: 'alias'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Required',
      dataIndex: 'required',
      render: (required: boolean) => (required ? 'Yes' : 'No'),
      key: 'required'
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (record: string) => {
        if (record !== null) {
          const hasSymbol = /\[\{/
          if (hasSymbol.test(record)) {
            return 'Options'
          }

          return record
        }
      }
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (record: Setting) => (
        <span>
          <Popconfirm
            title='Are you sure delete this setting?'
            onConfirm={(e) => doConfirm(record.id, e)}
            onCancel={(e) => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <a
              href='/#'
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              Remove
            </a>
          </Popconfirm>
        </span>
      )
    }
  ]

  return (
    <div className={styles.tableContainer}>
      <Card title={<span className={styles.cardTitle}>Settings</span>} extra={<CreateSetting ownerType={ownerType} ownerId={ownerId} />}>
        <UpdateSetting
          ownerType={ownerType}
          ownerId={ownerId}
          settingId={selectedSettingId!}
          onClose={handleOnSettingsClose}
          visible={isUpdateVisible}
          settings={settings}
        />
        <Table
          columns={columns}
          dataSource={settings.map((item) => item).flat()}
          bordered
          rowKey={(record) => record.id}
          pagination={false}
          onRow={handleOnRow}
        />
      </Card>
    </div>
  )
}

export default SettingsList
