import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { SiteType } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Dependency } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'removeDependency', boolean>

const REMOVE_DEPENDENCY = `
  mutation RemoveDependency($dependencyId: Int!) {
    removeDependency(dependencyId: $dependencyId)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveDependencyArgs = {
  ownerType: SiteType
  ownerId: number
  dependencyId: number
}

type RemoveDependency = (args: RemoveDependencyArgs) => Promise<RemoveDependencyArgs>

const removeDependency: RemoveDependency = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_DEPENDENCY,
      variables: {
        dependencyId: args.dependencyId
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeDependency } = data
    if (!removeDependency) {
      throw new Error("Can't remove dependency!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveDependencyContext = { prevDependency?: Dependency }

type RemoveDependencyEvents = {
  onMutate: (variables: RemoveDependencyArgs) => Promise<RemoveDependencyContext>
  onError: (error: string, variables: RemoveDependencyArgs, context: RemoveDependencyContext) => void
  onSettled: (data?: RemoveDependencyArgs) => void
}

const removeDependencyEvents: RemoveDependencyEvents = {
  onMutate: async (variables) => {
    await queryClient.cancelQueries(['dependency', variables.dependencyId])

    // Snapshot the previous value
    const prevDependency = queryClient.getQueryData<Dependency>(['dependency', variables.dependencyId])

    if (prevDependency) {
      queryClient.removeQueries(['dependency', variables.dependencyId])
    }

    return { prevDependency }
  },
  onError: (_err, variables, context) => {
    if (context?.prevDependency) {
      // Restore page on any error
      queryClient.setQueryData<Dependency>(['dependency', variables.dependencyId], context.prevDependency)
    }
  },
  onSettled: (data?: RemoveDependencyArgs) => {
    if (data) {
      queryClient.invalidateQueries([`${data.ownerType.toLowerCase()}`, +data.ownerId])
      queryClient.invalidateQueries(['dependenciesList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useRemoveDependency = () => useMutation(removeDependency, removeDependencyEvents)
