import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { CreateStoreDto, StoreDto } from '@merchx-v2/shared-types'

import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'updateStore', boolean>

const UPDATE_SHOP = `
  mutation updateStore ($storeId: Int!, $storeData: StoreInput!) {
    updateStore(storeId: $storeId, storeData: $storeData)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateStoreArgs = {
  storeId: number
  storeData: CreateStoreDto
}

type UpdateStore = (args: UpdateStoreArgs) => Promise<UpdateStoreArgs>

const updateStore: UpdateStore = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_SHOP,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateStore } = data
    if (!updateStore) {
      throw new Error("Can't update store!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateStoreContext = { prevStore?: StoreDto }

type UpdateStoreEvents = {
  onMutate: (variables: UpdateStoreArgs) => Promise<UpdateStoreContext>
  onError: (error: string, variables: UpdateStoreArgs, context: UpdateStoreContext) => void
  onSettled: (data?: UpdateStoreArgs) => void
}

const updateStoreEvents: UpdateStoreEvents = {
  onMutate: async (variables: UpdateStoreArgs) => {
    await queryClient.cancelQueries(['store', variables.storeId])

    // Snapshot the previous value
    const prevStore = queryClient.getQueryData<StoreDto>(['store', variables.storeId])

    // Optimistically update to the new values
    if (prevStore) {
      queryClient.setQueryData<StoreDto>(['store', variables.storeId], {
        ...prevStore,
        id: variables.storeId,
        ...variables.storeData
      })
    }

    return { prevStore }
  },
  onError: (_err, variables, context) => {
    if (context?.prevStore) {
      // Restore previous version of store on any error
      queryClient.setQueryData<StoreDto>(['store', variables.storeId], context.prevStore)
    }
  },
  onSettled: (data) => {
    if (data?.storeId) {
      queryClient.invalidateQueries(['storesPage'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUpdateStore = () => useMutation(updateStore, updateStoreEvents)
