import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

type OwnProps = {
  productId?: number
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  return {
    productAttributesList: selectors.productAttributesListSelector(state, ownProps.productId),
    error: selectors.errorSelector(state),
    isLoading: selectors.isLoadingSelector(state, 'isListLoading')
  }
}

const mapDispatchToProps = {
  fetchProductAttributesList: actions.fetchProductAttributesList,
  removeProductAttribute: actions.removeProductAttribute
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector>
