import * as t from '../actionTypes'
import axios from 'utils/axios'
import { SiteType } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

type Payload = {
  ownerType: SiteType
  ownerId: number
  siteSnapshots: SelectOption[]
}

export type FetchSiteSnapshotsAction = FSA<undefined, Payload, string>

type FetchSiteSnapshots = (workspaceId: number, ownerType: SiteType, ownerId: number) => MrxThunk<FetchSiteSnapshotsAction>

type QueryResponse = GraphQLResponse<'siteSnapshots', SelectOption[]>

const FETCH_SITE_SNAPSHOTS = `
  query SiteSnapshots($workspaceId: Int!, $ownerType: SiteType!, $ownerId: Int!) {
    siteSnapshots(workspaceId: $workspaceId, ownerType: $ownerType, ownerId: $ownerId) {
      id
      name
    }
  }
`

const fetchSiteSnapshots: FetchSiteSnapshots = (workspaceId, ownerType, ownerId) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.FETCH_SITE_SNAPSHOTS,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_SITE_SNAPSHOTS,
      variables: { workspaceId, ownerType, ownerId: +ownerId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { siteSnapshots } = data
    if (!siteSnapshots) {
      throw new Error("Can't fetch site snapshots!")
    }

    dispatch({
      type: t.FETCH_SITE_SNAPSHOTS,
      payload: { ownerType, ownerId, siteSnapshots },
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.FETCH_SITE_SNAPSHOTS,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { fetchSiteSnapshots }
