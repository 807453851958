import React, { useEffect } from 'react'
import { PlusOutlined, CloseCircleTwoTone } from '@ant-design/icons'
import { Button, Drawer, Input, InputNumber, Select, Form, Col, Row, Switch, Typography } from 'antd'
import notification from 'mrx-notification'
import { SettingOwner, SettingType } from '@merchx-v2/shared-types'
import { useUpdateSetting } from '../../hooks'
import styles from './UpdateSetting.module.scss'
import { Setting } from 'features/settings/types'

type PropsЕнзу = {
  ownerType: SettingOwner
  ownerId: number
  settingId: number
  visible: boolean
  settings: Setting[]
  onClose: () => void
}

const UpdateSetting = (props: PropsЕнзу) => {
  const { onClose, settingId, settings, visible, ownerType, ownerId } = props

  const setting = settings.find((item) => item.id === settingId)

  const [form] = Form.useForm()

  const updateSetting = useUpdateSetting()

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  useEffect(() => {
    visible &&
      setting &&
      form.setFieldsValue({
        name: setting?.name,
        type: setting?.type,
        alias: setting?.alias,
        value:
          setting?.type === 'SELECT'
            ? JSON.parse(setting?.value)
            : setting?.type === 'LANDING_PRODUCT' || setting?.type === 'PRODUCT'
            ? ''
            : setting?.value,
        required: setting?.required,
        filters: setting?.filters?.map((item) => ({ key: item }))
      })
  }, [setting, form, visible])

  // TODO - fix any type
  const onValuesChangeHandler = (changedValues: any) => {
    if (Object.prototype.hasOwnProperty.call(changedValues, 'type')) {
      form.setFieldsValue({
        value: ''
      })
    }
  }

  // TODO - fix any type
  const onFinishHandler = async (values: any) => {
    if (!setting) {
      return
    }

    const filters = values.filters?.map((item) => {
      return item.key
    })
    updateSetting.mutate(
      {
        ownerType,
        ownerId,
        settingId,
        settingData: {
          name: setting?.name,
          alias: setting?.alias,
          type: setting?.type as SettingType,
          value: setting?.type === 'SELECT' ? JSON.stringify(values.value || []) : values.value + '',
          filters: filters,
          required: setting?.required
        }
      },
      {
        onSuccess: () => {
          notification.success({
            message: 'Successfully',
            description: 'Setting was updated successfully!'
          })
          handleClose()
        },
        onError: (error) => {
          notification.error({
            message: 'Error!',
            description: error.toString()
          })
        }
      }
    )
  }

  const renderInputForm = () => {
    if (!setting) {
      return
    }
    switch (setting?.type) {
      case 'BOOLEAN': {
        return (
          <Form.Item
            name='value'
            label={setting?.name}
            initialValue={false}
            rules={[{ required: setting?.required, message: 'Please Choose Setting Value' }]}
          >
            <Switch checkedChildren='true' unCheckedChildren='false' defaultChecked />
          </Form.Item>
        )
      }
      case 'INTEGER':
      case 'FLOAT': {
        return (
          <Form.Item name='value' label={setting?.name} rules={[{ required: setting?.required, message: 'Please Input Setting Value' }]}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        )
      }
      case 'STRING': {
        return (
          <Form.Item name='value' label={setting?.name} rules={[{ required: setting?.required, message: 'Please Input Setting Value' }]}>
            <Input style={{ width: '100%' }} />
          </Form.Item>
        )
      }

      case 'PRODUCTS_LIST':
      case 'PRODUCT': {
        return (
          <>
            <Form.List name='filters'>
              {(fields, { add, remove }) => {
                return (
                  <div>
                    <Typography style={{ color: 'black', marginBottom: '8px' }}>Filters</Typography>
                    {fields.map((field) => (
                      <Row key={field.key} className={styles.rowContainer}>
                        <Form.Item
                          name={[field.name, 'key']}
                          fieldKey={field.key}
                          style={{ width: '100%' }}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Please select product or delete this field.'
                            }
                          ]}
                        >
                          <Select style={{ width: '100%' }} placeholder='Please select'>
                            <Select.Option value='TSHIRT'>T-Shirt</Select.Option>
                            <Select.Option value='CREW'>Crew</Select.Option>
                            <Select.Option value='FACE_MASK'>Face Mask</Select.Option>
                            <Select.Option value='STICKER'>Sticker</Select.Option>
                            <Select.Option value='TOTEBAG'>Tote Bag</Select.Option>
                          </Select>
                        </Form.Item>
                        <CloseCircleTwoTone
                          twoToneColor='tomato'
                          className={styles.deleteButton}
                          onClick={() => {
                            remove(field.name)
                          }}
                        />
                      </Row>
                    ))}
                    <Form.Item>
                      <Button
                        type='dashed'
                        onClick={() => {
                          add()
                        }}
                      >
                        <PlusOutlined /> Add filter
                      </Button>
                    </Form.Item>
                  </div>
                )
              }}
            </Form.List>
          </>
        )
      }

      case 'SELECT': {
        return (
          <Form.List name='value'>
            {(fields, { add, remove }) => {
              return (
                <div>
                  <Typography style={{ marginBottom: '10px', fontSize: '16px' }}>{setting?.name} (Key / Value)</Typography>
                  {fields.map((field) => (
                    <Row key={field.key} className={styles.rowContainer}>
                      <Col>
                        <Form.Item
                          name={[field.name, 'key']}
                          fieldKey={field.key}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Please input setting key/value or delete this field.'
                            }
                          ]}
                        >
                          <Input style={{ width: '98%' }} placeholder='KEY' />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          name={[field.name, 'value']}
                          fieldKey={field.key}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Please input setting key/value or delete this field.'
                            }
                          ]}
                        >
                          <Input style={{ width: '98%' }} placeholder='VALUE' />
                        </Form.Item>
                      </Col>
                      <Col>
                        <CloseCircleTwoTone
                          twoToneColor='tomato'
                          className={styles['dynamic-delete-button']}
                          onClick={() => {
                            remove(field.name)
                          }}
                        />
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button
                      type='dashed'
                      onClick={() => {
                        add()
                      }}
                      style={{ width: '40%' }}
                    >
                      <PlusOutlined /> Add field
                    </Button>
                  </Form.Item>
                </div>
              )
            }}
          </Form.List>
        )
      }
    }
  }

  return (
    <Drawer title='Update Setting' width='400' onClose={handleClose} visible={visible} forceRender>
      <Form
        layout='vertical'
        hideRequiredMark
        onFinish={onFinishHandler}
        form={form}
        onValuesChange={onValuesChangeHandler}
        initialValues={{ filters: setting?.filters }}
      >
        {renderInputForm()}
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right'
          }}
        >
          <Button onClick={handleClose} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit' loading={updateSetting.isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default UpdateSetting
