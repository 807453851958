import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { AuthorizeSetting } from '../types'

export type FetchAuthorizeSettingsForOptionsAction = FSA<undefined, AuthorizeSetting[], string>
type FetchAuthorizeSettingsForOptions = (searchText?: string) => MrxThunk<FetchAuthorizeSettingsForOptionsAction>
type QueryResponse = GraphQLResponse<'authorizeSettingsForOptions', AuthorizeSetting[]>

const FETCH_AUTHORIZE_SETTINGS_FOR_OPTIONS = `
  query fetchAuthorizeSettingsForOptions ($filters: AuthorizeSettingsFilters!) {
    authorizeSettingsForOptions (filters: $filters) {
      id
      name
    }
  }
`

const fetchAuthorizeSettingsForOptions: FetchAuthorizeSettingsForOptions = searchText => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_AUTHORIZE_SETTINGS_FOR_OPTIONS,
    meta: { done: false }
  })

  try {
    const {
      workspaces: {
        activeWorkspace: { id: workspaceId }
      }
    } = getState()
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_AUTHORIZE_SETTINGS_FOR_OPTIONS,
      variables: { filters: { workspaceId, searchText } }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { authorizeSettingsForOptions } = data
    if (!authorizeSettingsForOptions) {
      throw new Error("Can't get authorize settings!")
    }

    dispatch({
      type: t.FETCH_AUTHORIZE_SETTINGS_FOR_OPTIONS,
      payload: authorizeSettingsForOptions,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_AUTHORIZE_SETTINGS_FOR_OPTIONS,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchAuthorizeSettingsForOptions }
