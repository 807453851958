import React, { useState } from 'react'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import styles from './TreeInput.module.scss'

type Props = {
  title: string
  onFinish: (text: string) => void
}

const TreeInput = ({ title, onFinish }: Props) => {
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [text, setText] = useState<string>('')

  const handleConfirmClicked = () => {
    onFinish(text)
    setIsEdit(false)
    setText('')
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleConfirmClicked()
    }
  }

  const handleCancelClicked = () => {
    setText('')
    setIsEdit(false)
  }

  if (!isEdit) {
    return (
      <div className={styles.addButton} onClick={() => setIsEdit(true)}>
        {title}
      </div>
    )
  }

  return (
    <div>
      <input
        value={text}
        onChange={e => setText(e.target.value)}
        type='text'
        onKeyPress={handleKeyPress}
        className={styles.input}
      />

      <CloseOutlined style={{ color: 'tomato' }} onClick={handleCancelClicked} />
      <CheckOutlined style={{ color: '#52c41a', marginLeft: '5px' }} onClick={handleConfirmClicked} />
    </div>
  )
}

export { TreeInput }
