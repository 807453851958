import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

type OwnProps = {
  productSizeId: number
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  return {
    isLoading: selectors.isLoadingSelector(state, 'isUpdateProductSize'),
    productSize: selectors.productSizeSelector(state, ownProps.productSizeId)
  }
}

const mapDispatchToProps = {
  fetchProductSize: actions.fetchProductSize,
  updateProductSize: actions.updateProductSize
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
