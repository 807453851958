import React, { useEffect } from 'react'
import { Button, Checkbox, Drawer, Input, Form } from 'antd'
import { ShippingMethodDto } from '@merchx-v2/shared-types'
import { Store } from 'antd/lib/form/interface'
import notification from 'mrx-notification'

import { useUpdateShippingMethod } from '../../hooks'
import styles from './UpdateShippingMethod.module.scss'

interface FormStore extends Store {
  carrierCode: string
  serviceCode: string
  isDefault: boolean
}

type Props = {
  shippingMethod?: ShippingMethodDto
  onClose: () => void
  visible: boolean
}

const UpdateShippingMethod = (props: Props) => {
  const {
    shippingMethod,
    visible,

    onClose
  } = props

  const [form] = Form.useForm()

  const updateShippingMethod = useUpdateShippingMethod()

  useEffect(() => {
    if (shippingMethod) {
      form.setFieldsValue({
        carrierCode: shippingMethod.carrierCode,
        serviceCode: shippingMethod.serviceCode,
        isDefault: shippingMethod.isDefault
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingMethod])

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  useEffect(() => {
    if (updateShippingMethod.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Shipping method was updated successfully!'
      })
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateShippingMethod.isSuccess])

  useEffect(() => {
    if (updateShippingMethod.error) {
      notification.error({
        message: 'Error!',
        description: updateShippingMethod.error instanceof Error ? updateShippingMethod.error.message : updateShippingMethod.error.toString()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateShippingMethod.isError])

  if (!shippingMethod) {
    return null
  }

  const onFinishHandler = (values: FormStore) => {
    updateShippingMethod.mutate({ shippingMethodId: shippingMethod.id, shippingMethodData: values })
  }

  return (
    <Drawer title='Update Shipping Method' width='400' onClose={handleClose} visible={visible}>
      <Form
        layout='vertical'
        hideRequiredMark
        onFinish={onFinishHandler}
        form={form}
        initialValues={{
          carrierCode: shippingMethod?.carrierCode,
          serviceCode: shippingMethod?.serviceCode,
          isDefault: shippingMethod?.isDefault
        }}
      >
        <Form.Item name='carrierCode' label='Carrier Code' rules={[{ required: true, message: 'Please input carrier code!' }]}>
          <Input style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item name='serviceCode' label='Service Code' rules={[{ required: true, message: 'Please input service code!' }]}>
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name='isDefault' label='Default'>
          <Checkbox defaultChecked={shippingMethod?.isDefault} style={{ width: '100%' }} onChange={event => form.setFieldValue('isDefault', event.target.checked)} />
        </Form.Item>
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right'
          }}
        >
          <Button onClick={handleClose} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit'>
            Save
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default UpdateShippingMethod
