import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { ProductOption } from 'features/types'

type Payload = {
  campaignId: number
  options: ProductOption[]
}

export type FetchOrderItemProductsForOptionsAction = FSA<undefined, Payload, string>
type FetchOrderItemProductsForOptions = (campaignId: number, searchText?: string) => MrxThunk<FetchOrderItemProductsForOptionsAction>
type QueryResponse = GraphQLResponse<'productsForOptions', ProductOption[]>

const FETCH_ORDER_ITEM_PRODUCTS_FOR_OPTIONS = `
  query fetchProductsForOptions($filters: ProductsForOptionsFilters!) {
    productsForOptions(filters: $filters) {
      id
      name
    }
  }
`

const fetchOrderItemProductsForOptions: FetchOrderItemProductsForOptions = (campaignId, searchText) => async (dispatch) => {
  dispatch({
    type: t.FETCH_ORDER_ITEM_PRODUCTS_FOR_OPTIONS,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_ORDER_ITEM_PRODUCTS_FOR_OPTIONS,
      variables: { filters: { campaignId, searchText } }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { productsForOptions } = data
    if (!productsForOptions) {
      throw new Error("Can't get products!")
    }

    dispatch({
      type: t.FETCH_ORDER_ITEM_PRODUCTS_FOR_OPTIONS,
      payload: {
        campaignId,
        options: productsForOptions
      },
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_ORDER_ITEM_PRODUCTS_FOR_OPTIONS,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchOrderItemProductsForOptions }
