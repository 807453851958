import produce from 'immer'
import * as t from './actionTypes'
import { actionTypes as nt } from 'features/navigation'
import { actionTypes as wt } from 'features/workspaces'
import { isSuccessAction, isErrorAction } from 'types'
import { AwsSettingsState } from './types'
import createReducer from 'store/createReducer'
import { addItemToPagination } from 'utils/addItemToPagination'
import { removeItemFromPagination } from 'utils/removeItemFromPagination'
import {
  ChangeCurrentPageAction,
  ChangePageSizeAction,
  CreateAwsSettingAction,
  FetchAwsSettingsListAction,
  FetchAwsSettingsForOptionsAction,
  RemoveAwsSettingAction,
  UpdateAwsSettingAction
} from './actions'

const initState: AwsSettingsState = {
  awsSettings: {},
  awsSettingsForOptions: [],
  pagination: {
    currentPage: 1,
    total: 0,
    pages: {},
    size: 10
  },
  UIState: {
    isAwsOptionsLoading: false,
    isCreateAwsSetting: false,
    isListLoading: false,
    isRemoveAwsSetting: false,
    isUpdateAwsSetting: false
  }
}

const reducer = createReducer(initState, {
  [t.CREATE_AWS_SETTING]: (state, action: CreateAwsSettingAction) =>
    produce(state, draft => {
      draft.UIState.isCreateAwsSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isCreateAwsSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { id } = action.payload
        draft.awsSettings[id] = action.payload
        addItemToPagination(draft.pagination, id)
      }
    }),

  [t.FETCH_AWS_SETTINGS_LIST]: (state, action: FetchAwsSettingsListAction) =>
    produce(state, draft => {
      draft.UIState.isListLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isListLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        action.payload.items.forEach(item => {
          draft.awsSettings[item.id] = item
        })
        const { page, total, items } = action.payload
        draft.pagination.total = total
        draft.pagination.pages[page] = items.map(item => item.id)
      }
    }),

  [t.FETCH_AWS_SETTINGS_FOR_OPTIONS]: (state, action: FetchAwsSettingsForOptionsAction) =>
    produce(state, draft => {
      draft.UIState.isAwsOptionsLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isAwsOptionsLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.awsSettingsForOptions = action.payload
      }
    }),

  [t.REMOVE_AWS_SETTING]: (state, action: RemoveAwsSettingAction) =>
    produce(state, draft => {
      draft.UIState.isRemoveAwsSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isRemoveAwsSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.awsSettings[action.payload]) {
          delete draft.awsSettings[action.payload]
          removeItemFromPagination(draft.pagination, action.payload)
        }
      }
    }),
  [t.UPDATE_AWS_SETTING]: (state, action: UpdateAwsSettingAction) =>
    produce(state, draft => {
      draft.UIState.isUpdateAwsSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isUpdateAwsSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.awsSettings[action.payload.awsSettingId]) {
          draft.awsSettings[action.payload.awsSettingId].name = action.payload.awsSettingData.name
          draft.awsSettings[action.payload.awsSettingId].accessKeyId = action.payload.awsSettingData.accessKeyId
          draft.awsSettings[action.payload.awsSettingId].secretAccessKey = action.payload.awsSettingData.secretAccessKey
          draft.awsSettings[action.payload.awsSettingId].region = action.payload.awsSettingData.region
        }
      }
    }),

  [t.CHANGE_CURRENT_PAGE]: (state, action: ChangeCurrentPageAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.pagination.currentPage = action.payload
      }
    }),

  [t.CHANGE_PAGE_SIZE]: (state, action: ChangePageSizeAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.pagination.size = action.payload
      }
    }),

  [wt.SWITCH_WORKSPACE]: state =>
    produce(state, draft => {
      draft.pagination = initState.pagination
    }),

  [nt.LOCATION_CHANGE]: state =>
    produce(state, draft => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default reducer
