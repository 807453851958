import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { Product } from 'features/types'

type AllProductsListSelector = (state: ReduxState, campaignId: number) => Product[]
const getAllProductsList: AllProductsListSelector = (state, campaignId) => {
  let result = [] as Product[]
  if (Object.prototype.hasOwnProperty.call(state.products.campaignProducts, campaignId)) {
    result = Object.values(state.products.campaignProducts[campaignId])
  }

  return result
}

export const allProductsListSelector: AllProductsListSelector = createSelector(getAllProductsList, (list) => list)
