import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { SimpleTextSetting } from '../types'

type SimpleTextSettingsListSelector = (state: ReduxState) => SimpleTextSetting[]
const getSimpleTextSettingsList: SimpleTextSettingsListSelector = state => {
  const result = [] as SimpleTextSetting[]
  const pageIds = state.simpleTextSettings.pagination.pages[state.simpleTextSettings.pagination.currentPage] || []
  pageIds.forEach(pageId => {
    result.push(state.simpleTextSettings.simpleTextSettings[pageId])
  })
  return result
}

export const simpleTextSettingsListSelector: SimpleTextSettingsListSelector = createSelector(
  getSimpleTextSettingsList,
  list => list
)
