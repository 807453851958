import React, { useEffect } from 'react'
import { Button, Drawer, Input, Form } from 'antd'
import notification from 'mrx-notification'
import { useCustomerSubscription, useUpdateCustomerSubscriptionNote } from '../../hooks'
import { PropsFromRedux, connector } from './container'
import styles from './UpdateCustomerSubscriptionNote.module.scss'

const UpdateCustomerSubscriptionNote = (props: PropsFromRedux) => {
  const { onClose, customerSubscriptionId, customerSubscriptionNoteId, visible, workspaceId } = props

  const customerSubscription = useCustomerSubscription(customerSubscriptionId)
  const updateCustomerSubscriptionNote = useUpdateCustomerSubscriptionNote()

  const [form] = Form.useForm()

  const customerSubscriptionNote = customerSubscription.data.customerSubscriptionNotes.find(note => note.id === customerSubscriptionNoteId)

  useEffect(() => {
    if (customerSubscriptionNote) {
      form.setFieldsValue({
        note: customerSubscriptionNote.note
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSubscriptionNote])

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  useEffect(() => {
    if (updateCustomerSubscriptionNote.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Customer Subscription Note was updated successfully!'
      })
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCustomerSubscriptionNote.isSuccess])

  if (!customerSubscriptionNote) {
    return null
  }

  // TODO - fix any type
  const onFinishHandler = (values: any) => {
    updateCustomerSubscriptionNote.mutate({
      customerSubscriptionNoteId,
      customerSubscriptionNoteData: {
        workspaceId,
        note: values.note,
        customerSubscriptionId
      }
    })
  }

  return (
    <Drawer title='Update Note' width='400' onClose={handleClose} visible={visible}>
      <Form
        layout='vertical'
        hideRequiredMark
        onFinish={onFinishHandler}
        form={form}
        initialValues={{
          note: customerSubscriptionNote?.note
        }}
      >
        <Form.Item name='note' label='Note' rules={[{ required: true, message: 'Please input customer subscription note!' }]}>
          <Input style={{ width: '100%' }} />
        </Form.Item>

        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right'
          }}
        >
          <Button onClick={handleClose} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit' loading={updateCustomerSubscriptionNote.isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default connector(UpdateCustomerSubscriptionNote)
