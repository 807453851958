import React, { useEffect, useRef } from 'react'
import { Button, Drawer, Input, Form } from 'antd'
import { SiteType } from '@merchx-v2/shared-types'
import notification from 'mrx-notification'
import { useCreateComponent } from '../../hooks'
import styles from './CreateComponent.module.scss'

type PropsType = {
  ownerType: SiteType
  ownerId: number
  isVisible: boolean

  onClose: () => void
  onComponentCreated: (componentId: number) => void
}

const CreateComponent = (props: PropsType) => {
  const { ownerType, ownerId, isVisible, onClose, onComponentCreated } = props
  const refEl = useRef<Input>(null)

  const createComponent = useCreateComponent()

  useEffect(() => {
    if (isVisible) {
      refEl.current && refEl.current.focus()
    }
  }, [isVisible])
  const [form] = Form.useForm()

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  const onFinishHandler = async (values: any) => {
    createComponent.mutate(
      {
        ownerType,
        ownerId,
        componentData: {
          name: values.name
        }
      },
      {
        onSuccess: (data) => {
          notification.success({
            message: 'Successfully',
            description: 'Component was created successfully!'
          })
          onComponentCreated(data.id)
          handleClose()
        },
        onError: (error) => {
          notification.error({
            message: 'Error!',
            description: error.toString()
          })
        }
      }
    )
  }

  return (
    <Drawer title='Create Component' width='400' onClose={handleClose} visible={isVisible} forceRender>
      <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
        <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input  component name!' }]}>
          <Input ref={refEl} />
        </Form.Item>
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right'
          }}
        >
          <Button onClick={handleClose} className={styles.closeButton}>
            Close
          </Button>
          <Button type='primary' htmlType='submit' loading={createComponent.isLoading}>
            Create
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default CreateComponent
