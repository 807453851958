import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

export type RestartTaskAction = FSA<undefined, number, string>
type RestartTask = (taskId: number) => MrxThunk<RestartTaskAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'restartTask', boolean>

const RESTART_TASK = `
  mutation restartTask ($taskId: Int!) {
    restartTask(taskId: $taskId)
  }
`

const restartTask: RestartTask = (taskId) => async (dispatch) => {
  let isRestarted = false

  dispatch({
    type: t.RESTART_TASK,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: RESTART_TASK,
      variables: { taskId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Empty response!')
    }

    const { restartTask } = data
    if (!restartTask) {
      throw new Error("Can't restart task!")
    }

    dispatch({
      type: t.RESTART_TASK,
      payload: taskId,
      meta: { done: true }
    })

    isRestarted = true
  } catch (err) {
    dispatch({
      type: t.RESTART_TASK,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isRestarted
}

export { restartTask }
