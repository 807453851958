import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

const mapStateToProps = (state: ReduxState) => ({
  isAlive: selectors.websocketAliveStateSelector(state),
  error: selectors.errorSelector(state)
})

const mapDispatchToProps = {
  wsConnected: actions.wsConnected,
  wsDisconnect: actions.wsDisconnect,
  wsDisconnected: actions.wsDisconnected,
  wsError: actions.wsError,

  subscribeToChannelResult: actions.subscribeToChannelResult,
  unsubscribeFromChannelResult: actions.unsubscribeFromChannelResult,

  buildLogsUpdated: actions.buildLogsUpdated,
  deployLandingLogs: actions.deployLandingLogsUpdated,
  jobInfoUpdated: actions.jobInfoUpdated
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector>
