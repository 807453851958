import React, { useEffect } from 'react'
import { Button, Drawer, Input, Form } from 'antd'
import notification from 'mrx-notification'
import styles from './UpdatePayPalSetting.module.scss'
import { connector, PropsFromRedux, OwnProps } from './container'

type UpdatePayPalSettingProps = PropsFromRedux & OwnProps
const UpdatePayPalSetting = (props: UpdatePayPalSettingProps) => {
  const {
    isLoading,
    payPalSettingId,
    payPalSetting,

    updatePayPalSetting,
    onClose
  } = props

  const [form] = Form.useForm()

  useEffect(() => {
    payPalSetting && form.setFieldsValue(payPalSetting)
  }, [payPalSetting, form])

  const onFinishHandler = async (values: any) => {
    const isSaved = await updatePayPalSetting({
      payPalSettingId,
      payPalSettingData: {
        name: values.name,
        accountEmail: values.accountEmail,
        clientId: values.clientId,
        secretKey: values.secretKey,
        accessToken: values.accessToken
      }
    })
    if (isSaved) {
      notification.success({
        message: 'Successfully',
        description: 'PayPal Setting was updated successfully!'
      })
      onClose()
    }
  }

  return (
    <Drawer title='Update PayPal Setting' width='400' onClose={onClose} visible={!!payPalSettingId} forceRender>
      <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
        <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input PayPal setting name!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name='accountEmail' label='Account Email' rules={[{ required: true, message: 'Please input Account Email!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name='clientId' label='clientId' rules={[{ required: true, message: 'Please input Client ID!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name='secretKey' label='Secret Key' rules={[{ required: true, message: 'Please input Secret Key!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name='accessToken' label='Access Token' rules={[{ required: true, message: 'Please input Access Token!' }]}>
          <Input />
        </Form.Item>
        <div className={styles.buttonsContainer}>
          <Button onClick={onClose} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default connector(UpdatePayPalSetting)
