// TODO - remove becouse of legacy

import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Template } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'unarchiveTemplate', boolean>

const UNARCHIVE_TEMPLATE = `
  mutation unarchiveTemplate($templateId: Int!) {
    unarchiveTemplate(templateId: $templateId)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UnarchiveTemplate = (templateId: number) => Promise<number>

const unarchiveTemplate: UnarchiveTemplate = async (templateId) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UNARCHIVE_TEMPLATE,
      variables: {
        templateId
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { unarchiveTemplate } = data
    if (!unarchiveTemplate) {
      throw new Error("Can't unarchive template!")
    }

    return templateId
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UnarchiveTemplateContext = { prevTemplate?: Template }

type UnarchiveTemplateEvents = {
  onMutate: (templateId: number) => Promise<UnarchiveTemplateContext>
  onError: (error: string, templateId: number, context: UnarchiveTemplateContext) => void
  onSettled: (templateId?: number) => void
}

const unarchiveTemplateEvents: UnarchiveTemplateEvents = {
  onMutate: async (templateId) => {
    await queryClient.cancelQueries(['template', templateId])

    // Snapshot the previous value
    const prevTemplate = queryClient.getQueryData<Template>(['template', templateId])

    if (prevTemplate) {
      queryClient.removeQueries(['template', templateId])
    }

    return { prevTemplate }
  },
  onError: (_err, templateId, context) => {
    if (context?.prevTemplate) {
      // Restore template on any error
      queryClient.setQueryData<Template>(['template', templateId], context.prevTemplate)
    }
  },
  onSettled: (templateId: number) => {
    if (templateId) {
      queryClient.invalidateQueries(['template', templateId])
      queryClient.invalidateQueries(['templatesList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUnarchiveTemplate = () => useMutation(unarchiveTemplate, unarchiveTemplateEvents)
