import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { NginxSetting } from '../types'

type NginxSettingsListSelector = (state: ReduxState) => NginxSetting[]
const getNginxSettingsList: NginxSettingsListSelector = state => {
  const result = [] as NginxSetting[]
  const pageIds = state.nginxSettings.pagination.pages[state.nginxSettings.pagination.currentPage] || []
  pageIds.forEach(pageId => {
    result.push(state.nginxSettings.nginxSettings[pageId])
  })
  return result
}

export const nginxSettingsListSelector: NginxSettingsListSelector = createSelector(getNginxSettingsList, list => list)
