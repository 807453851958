import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

type OwnProps = {
  productId?: number
  productAttributeId?: number
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  return {
    isLoading: selectors.isLoadingSelector(state, 'isUpdateProductAttribute'),
    productAttribute: selectors.productAttributeSelector(state, ownProps.productId, ownProps.productAttributeId)
  }
}

const mapDispatchToProps = {
  fetchProductAttribute: actions.fetchProductAttribute,
  updateProductAttribute: actions.updateProductAttribute
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
