import React, { useEffect, useState, memo } from 'react'
import useReactRouter from 'use-react-router'
import { Button, Layout, PageHeader, Switch, Divider, Input, Typography, Radio } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import { StoreOwnerType } from '@merchx-v2/shared-types'
import notification from 'mrx-notification'
import hash from 'object-hash'

import settings from 'config/settings'
import { queryClient } from 'queryClient'
import { ManageSeo, AvatarUploader, UploadImages, QuillEditorAntd } from 'components'
import * as rootStyles from 'assets/layoutStyle'
import { useUploadAsset } from 'features/assets/hooks'
import { CustomersSelect } from 'features/customers/components'

import { useCreateStore } from '../../hooks/useCreateStore'
import styles from './CreateStore.module.scss'

const storesTypeOptions: { label: string; value: StoreOwnerType }[] = [
  { label: 'Merchx', value: 'MERCHX' },
  { label: 'Customer', value: 'CUSTOMER' }
]

type SaveState = {
  mainImageUploaded: boolean
  avatarImageUploaded: boolean
  bannerImageUploaded: boolean
}

const CreateStore = () => {
  const { history } = useReactRouter()

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [supportEmail, setSupportEmail] = useState('support@fanmadefits.com')

  const [ownerType, setOwnerType] = useState<StoreOwnerType>('MERCHX')
  const [ownerId, setOwnerId] = useState<number | null>(null)
  const [ownerName, setOwnerName] = useState<string>('Empty')

  const [avatarImageToUpload, setAvatarImageToUpload] = useState<UploadFile<any> | undefined>(undefined)
  const uploadAvatarImage = useUploadAsset()

  const [mainImageToUpload, setMainImageToUpload] = useState<UploadFile<any> | undefined>(undefined)
  const uploadMainImage = useUploadAsset()

  const [bannerLink, setBannerLink] = useState<string>('')
  const [bannerImageToUpload, setBannerImageToUpload] = useState<UploadFile<any> | undefined>(undefined)
  const uploadBannerImage = useUploadAsset()

  const [isActive, setIsActive] = useState(false)

  const [hasCustomSeo, setHasCustomSeo] = useState(true)
  const [seoTitle, setSeoTitle] = useState('')
  const [seoUrl, setSeoUrl] = useState('')
  const [seoDescription, setSeoDescription] = useState('')
  const [seoKeywords, setSeoKeywords] = useState('')

  const [facebookLink, setFacebookLink] = useState('')
  const [instagramLink, setInstagramLink] = useState('')
  const [twitterLink, setTwitterLink] = useState('')
  const [pinterestLink, setPinterestLink] = useState('')
  const [tiktokLink, setTiktokLink] = useState('')
  const [linkedInLink, setLinkedInLink] = useState('')
  const [youtubeLink, setYoutubeLink] = useState('')
  const [threadsLink, setThreadsLink] = useState('')
  const [location, setLocation] = useState('')

  const createStore = useCreateStore()

  const [saveState, setSaveState] = useState<SaveState>({
    mainImageUploaded: false,
    avatarImageUploaded: false,
    bannerImageUploaded: false
  })

  useEffect(() => {
    if (createStore.error) {
      notification.error({
        message: 'Create store error!',
        description: createStore.error instanceof Error ? createStore.error.message : createStore.error.toString()
      })
    }
  }, [createStore.error])

  useEffect(() => {
    if (createStore.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Store was updated successfully!'
      })

      setName('')
      setDescription('')

      setOwnerType('MERCHX')
      setOwnerId(null)
      setOwnerName('Empty')

      setBannerLink('')
      setIsActive(false)

      setHasCustomSeo(false)
      setSeoUrl('')
      setSeoKeywords('')
      setSeoTitle('')
      setSeoDescription('')

      setFacebookLink('')
      setInstagramLink('')
      setTwitterLink('')
      setPinterestLink('')
      setTiktokLink('')
      setLinkedInLink('')
      setYoutubeLink('')
      setThreadsLink('')
      setLocation('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStore.isSuccess])

  // Этот эффект у нас следит за процессом выгрузки картинок после создания
  // Если все выгружено успешно то мы увидим оповещение и инвалидируем запрос
  useEffect(() => {
    if (saveState.avatarImageUploaded && saveState.mainImageUploaded && saveState.bannerImageUploaded && createStore.data?.id) {
      queryClient.invalidateQueries(['store', createStore.data.id])
      if (createStore.isSuccess) {
        notification.success({
          message: 'Successfully',
          description: 'Store was updated successfully!'
        })

        history.push(`/stores/${createStore.data.id}/details`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(saveState), createStore.isSuccess])

  const handleCreate = async () => {
    setSaveState({
      mainImageUploaded: !mainImageToUpload,
      avatarImageUploaded: !avatarImageToUpload,
      bannerImageUploaded: !bannerImageToUpload
    })

    createStore.mutate(
      {
        storeData: {
          name,
          description,
          supportEmail,

          ownerType,
          ownerId: ownerType === 'CUSTOMER' ? ownerId : null,

          bannerLink,
          isActive,

          hasCustomSeo,
          seoUrl,
          seoDescription,
          seoKeywords,
          seoTitle,

          facebookLink,
          twitterLink,
          instagramLink,
          pinterestLink,
          tiktokLink,
          youtubeLink,
          threadsLink,
          linkedInLink,
          location
        }
      },
      {
        onSuccess: (savedStore) => {
          // Upload new avatar image
          if (avatarImageToUpload) {
            uploadAvatarImage.mutate(
              {
                ownerType: 'STORE',
                ownerId: savedStore.id,
                assetData: {
                  name: avatarImageToUpload.name,
                  type: avatarImageToUpload.type,
                  filename: avatarImageToUpload.name,
                  role: 'STORE_AVATAR'
                },
                file: avatarImageToUpload.originFileObj as File
              },
              {
                // Мы знаем что у нас один аватар, если их будет несколько то эта логика не будет работать
                // onSuccess: () => setUpdateState((prev) => ({ ...prev, avatarImagesUploaded: true }))
                onSuccess: () =>
                  setSaveState((prev) => {
                    setAvatarImageToUpload(undefined)
                    return { ...prev, avatarImageUploaded: true }
                  })
              }
            )
          }

          // Upload new main image
          if (mainImageToUpload) {
            uploadMainImage.mutate(
              {
                ownerType: 'STORE',
                ownerId: savedStore.id,
                assetData: {
                  name: mainImageToUpload.name,
                  type: mainImageToUpload.type,
                  filename: mainImageToUpload.name,
                  role: 'STORE_MAIN_IMAGE'
                },
                file: mainImageToUpload.originFileObj as File
              },
              {
                // Мы знаем что у нас одна главная картинка, если их будет несколько то эта логика не будет работать
                // onSuccess: () => setUpdateState((prev) => ({ ...prev, mainImagesUploaded: true }))
                onSuccess: () =>
                  setSaveState((prev) => {
                    setMainImageToUpload(undefined)
                    return { ...prev, mainImageUploaded: true }
                  })
              }
            )
          }

          // Upload new banner image
          if (bannerImageToUpload) {
            uploadBannerImage.mutate(
              {
                ownerType: 'STORE',
                ownerId: savedStore.id,
                assetData: {
                  name: bannerImageToUpload.name,
                  type: bannerImageToUpload.type,
                  filename: bannerImageToUpload.name,
                  role: 'STORE_BANNER_IMAGE'
                },
                file: bannerImageToUpload.originFileObj as File
              },
              {
                // Мы знаем что у нас один баннер, если их будет несколько то эта логика не будет работать
                // onSuccess: () => setUpdateState((prev) => ({ ...prev, bannerImagesUploaded: true }))
                onSuccess: () =>
                  setSaveState((prev) => {
                    setBannerImageToUpload(undefined)
                    return { ...prev, bannerImageUploaded: true }
                  })
              }
            )
          }
        }
      }
    )
  }

  const handleDescriptionChange = (newDescription: string) => {
    setDescription(newDescription)
  }

  return (
    <Layout style={rootStyles.root} className={styles.baseContainer}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title='Create store'
          extra={[
            <div className={styles.activeSwitch} key='1'>
              <Switch onChange={() => setIsActive(!isActive)} checked={isActive} title='Active' />
              <Typography>Active</Typography>
            </div>
          ]}
        />
      </Layout.Header>
      <Layout.Content className={styles.contentComponent}>
        <div key='main' className={styles.container}>
          <div className={styles.twoColumnsContainer}>
            <div className={styles.labeledItem}>
              <div className={styles.labeledItem}>
                <Typography className={styles.typography}>Store name</Typography>
                <Input value={name} onChange={(e) => setName(e.target.value)} placeholder='new sub store name' />
              </div>
              <div className={styles.labeledItem}>
                <Typography className={styles.typography}>Email store</Typography>
                <Input value={supportEmail} onChange={(e) => setSupportEmail(e.target.value)} />
              </div>
              <div className={styles.labeledItem}>
                <Typography className={styles.typography}>Avatar</Typography>
                <AvatarUploader
                  aspectRatio={105 / 105}
                  shape='round'
                  assets={[]}
                  filesToUpload={avatarImageToUpload ? [avatarImageToUpload] : []}
                  assetsToRemove={[]}
                  numberOfImages={1}
                  onChange={(filesToUpload) => {
                    setAvatarImageToUpload(filesToUpload.length ? filesToUpload[0] : null)
                  }}
                />
              </div>
            </div>
            <div className={styles.labeledItem}>
              <Typography className={styles.typography}>Description</Typography>
              <QuillEditorAntd limit={400} value={description} onChange={handleDescriptionChange} />
            </div>
            <div className={styles.labeledItem}>
              <Typography className={styles.typography}>Location</Typography>
              <Input value={location} onChange={(e) => setLocation(e.target.value)} />
            </div>
          </div>
        </div>
        <div key='images' className={styles.container}>
          <div className={styles.labeledItem}>
            <Typography className={styles.typography}>Main image</Typography>
            <UploadImages
              className={styles.mainImagePreviewContainer}
              aspectRatio={352 / 110}
              assets={[]}
              filesToUpload={mainImageToUpload ? [mainImageToUpload] : []}
              assetsToRemove={[]}
              numberOfImages={1}
              onChange={(filesToUpload) => {
                setMainImageToUpload(filesToUpload.length ? filesToUpload[0] : null)
              }}
            />
          </div>
        </div>
        <div key='banner' className={styles.container}>
          <div className={styles.labeledItem}>
            <Typography className={styles.typography}>Banner image</Typography>
            <UploadImages
              className={styles.bannerImagePreviewContainer}
              aspectRatio={352 / 110}
              assets={[]}
              filesToUpload={bannerImageToUpload ? [bannerImageToUpload] : []}
              assetsToRemove={[]}
              numberOfImages={1}
              onChange={(filesToUpload) => {
                setBannerImageToUpload(filesToUpload.length ? filesToUpload[0] : null)
              }}
            />
          </div>
          {bannerImageToUpload && (
            <div className={styles.labeledItem}>
              <Typography className={styles.typography}>Banner link</Typography>
              <Input value={bannerLink} onChange={(e) => setBannerLink(e.target.value)} placeholder='Banner link' />
            </div>
          )}
        </div>

        <div key='customer' className={styles.container}>
          <div className={styles.twoColumnsContainer}>
            <div className={styles.labeledItem}>
              <Typography className={styles.typography}>Owner</Typography>
              <Radio.Group
                options={storesTypeOptions}
                onChange={(event) => setOwnerType(event.target.value)}
                value={ownerType}
                optionType='button'
                buttonStyle='solid'
              />
            </div>
            {ownerType === 'CUSTOMER' && (
              <div className={styles.labeledItem}>
                <Typography className={styles.typography}>Customer</Typography>
                <CustomersSelect
                  initialOption={{ id: ownerId, name: ownerName }}
                  allowClear
                  onSelect={(customer) => {
                    setOwnerId(customer.id)
                    setOwnerName(customer.name)
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <ManageSeo
          initValues={{
            hasCustomSeo,
            title: seoTitle,
            url: seoUrl,
            description: seoDescription,
            keywords: seoKeywords,
            urlPrefix: settings.shopUrl
          }}
          onTitleChanged={setSeoTitle}
          onUrlChanged={setSeoUrl}
          onDescriptionChanged={setSeoDescription}
          onKeywordsChanged={setSeoKeywords}
          onCustomSeoChanged={setHasCustomSeo}
        />
        <Divider />

        <div key='socialNetworks' className={styles.container}>
          <div className={styles.labeledItem}>
            <Typography className={styles.typography}>Facebook</Typography>
            <Input value={facebookLink} onChange={(e) => setFacebookLink(e.target.value)} />
          </div>
          <div className={styles.labeledItem}>
            <Typography className={styles.typography}>Twitter</Typography>
            <Input value={twitterLink} onChange={(e) => setTwitterLink(e.target.value)} />
          </div>
          <div className={styles.labeledItem}>
            <Typography className={styles.typography}>Instagram</Typography>
            <Input value={instagramLink} onChange={(e) => setInstagramLink(e.target.value)} />
          </div>
          <div className={styles.labeledItem}>
            <Typography className={styles.typography}>Pinterest</Typography>
            <Input value={pinterestLink} onChange={(e) => setPinterestLink(e.target.value)} />
          </div>
          <div className={styles.labeledItem}>
            <Typography className={styles.typography}>TikTok</Typography>
            <Input value={tiktokLink} onChange={(e) => setTiktokLink(e.target.value)} />
          </div>
          <div className={styles.labeledItem}>
            <Typography className={styles.typography}>Linked In</Typography>
            <Input value={linkedInLink} onChange={(e) => setLinkedInLink(e.target.value)} />
          </div>
          <div className={styles.labeledItem}>
            <Typography className={styles.typography}>Youtube</Typography>
            <Input value={youtubeLink} onChange={(e) => setYoutubeLink(e.target.value)} />
          </div>
          <div className={styles.labeledItem}>
            <Typography className={styles.typography}>Threads</Typography>
            <Input value={threadsLink} onChange={(e) => setThreadsLink(e.target.value)} />
          </div>
        </div>
      </Layout.Content>
      <Layout.Footer className={styles.footer}>
        <Button onClick={() => history.goBack()}>Cancel</Button>
        <Button
          type='primary'
          onClick={handleCreate}
          loading={createStore.isLoading || uploadAvatarImage.isLoading || uploadMainImage.isLoading || uploadBannerImage.isLoading}
          disabled={createStore.isLoading || uploadAvatarImage.isLoading || uploadMainImage.isLoading || uploadBannerImage.isLoading}
        >
          Create
        </Button>
      </Layout.Footer>
    </Layout>
  )
}

export default memo(CreateStore)
