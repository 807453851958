import axios from 'utils/axios'
import { useQuery } from 'react-query'
import { ShippingMethodDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type ShippingMethodForOptions = ShippingMethodDto & { name: string }

type QueryResponse = GraphQLResponse<'shippingMethodsForOptions', ShippingMethodForOptions[]>

const FETCH_SHIPPING_METHODS_FOR_OPTIONS = `
  query fetchShippingMethodsForOptions($searchText: String) {
    shippingMethodsForOptions(searchText: $searchText) {
      id
      name
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchShippingMethodsForOptions = (searchText: string, limit?: number) => Promise<ShippingMethodForOptions[]>

const fetchShippingMethodsForOptions: FetchShippingMethodsForOptions = async (searchText, limit = 20) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_SHIPPING_METHODS_FOR_OPTIONS,
      variables: { searchText, limit }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { shippingMethodsForOptions } = data
    if (!shippingMethodsForOptions) {
      throw new Error("Can't get shippingMethods!")
    }

    return shippingMethodsForOptions
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useShippingMethodsOptions = (searchText: string, limit = 20) =>
  useQuery(['shippingMethodsOptions', searchText], () => fetchShippingMethodsForOptions(searchText, limit))
