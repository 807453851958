import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { ProductTag } from '../types'

type ProductTagsList = {
  items: ProductTag[]
  total: number
}

type Payload = ProductTagsList & {
  page: number
}

export type FetchProductTagsListAction = FSA<undefined, Payload, string>
type FetchProductTagsList = () => MrxThunk<FetchProductTagsListAction>
type QueryResponse = GraphQLResponse<'productTagsListPage', ProductTagsList>

const FETCH_PRODUCT_TAGS_LIST_PAGE = `
query productTagsList($page: Int, $size: Int) {
    productTagsListPage(page: $page, size: $size) {
      items {
        id
        name
      }
      total
    }
  }
`

const fetchProductTagsList: FetchProductTagsList = () => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_PRODUCT_TAGS_LIST,
    meta: { done: false }
  })

  try {
    const {
      productTags: {
        pagination: { size, currentPage: page }
      }
    } = getState()

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_PRODUCT_TAGS_LIST_PAGE,
      variables: { page, size }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { productTagsListPage } = data
    if (!productTagsListPage) {
      throw new Error("Can't get product tags list page!")
    }

    dispatch({
      type: t.FETCH_PRODUCT_TAGS_LIST,
      payload: {
        items: productTagsListPage.items,
        total: productTagsListPage.total,
        page
      },
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_PRODUCT_TAGS_LIST,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchProductTagsList }
