import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { AwsCertificate } from '../types'

export type CreateAwsCertificateAction = FSA<undefined, AwsCertificate, string>
type CreateAwsCertificate = (awsCertificateData: AwsCertificateInput) => MrxThunk<CreateAwsCertificateAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'createAwsCertificate', AwsCertificate>

const CREATE_AWS_CERTIFICATE = `
  mutation createAwsCertificate ($workspaceId: Int!, $awsCertificateData: AwsCertificateInput!) {
    createAwsCertificate(workspaceId: $workspaceId, awsCertificateData: $awsCertificateData) {
      id
      workspaceId
      certificateArn
      domainName
      expiringAt
      issuedAt
      createdAt
      updatedAt
    }
  }
`

type AwsCertificateInput = {
  domainName: string
  certificateArn: string
  expiringAt: Date
  issuedAt: Date
}

const createAwsCertificate: CreateAwsCertificate = (awsCertificateData) => async (dispatch, getState) => {
  let isSaved = false

  dispatch({
    type: t.CREATE_AWS_CERTIFICATE,
    meta: { done: false }
  })

  try {
    const workspaceId = getState()?.workspaces?.activeWorkspace?.id
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_AWS_CERTIFICATE,
      variables: { workspaceId, awsCertificateData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createAwsCertificate } = data
    if (!createAwsCertificate) {
      throw new Error("Can't create aws certificate!")
    }

    dispatch({
      type: t.CREATE_AWS_CERTIFICATE,
      payload: createAwsCertificate,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.CREATE_AWS_CERTIFICATE,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { createAwsCertificate }
