import * as t from '../actionTypes'
import { ProductPrice } from 'features/types'

export type ProductPricesLoadedAction = BaseFSA<{ productPrices: ProductPrice[] }>

export const productPricesLoaded = (productPrices: ProductPrice[]): MrxThunk<ProductPricesLoadedAction> => dispatch => {
  dispatch({
    type: t.PRODUCT_PRICES_LOADED,
    payload: {
      productPrices: productPrices.map(price => {
        return {
          id: price.id,
          name: price.name,
          displayName: price.displayName,
          workspaceId: price.workspaceId,
          ownerType: price.ownerType,
          ownerId: price.ownerId,
          productId: price.productId,
          sku: price.sku,
          attributes: price.attributes,
          price: price.price,
          discount: price.discount,
          createdAt: price.createdAt,
          updatedAt: price.updatedAt
        } as ProductPrice
      })
    }
  })
}
