import React, { useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import { Layout, PageHeader, Tag } from 'antd'
import notification from 'mrx-notification'
import * as rootStyles from 'assets/layoutStyle'
import { Breadcrumbs } from 'components'

import { SavePluginSetting } from 'features/plugins/components'
import { UpdateCampaign } from 'features/campaigns/components'
import { useCampaign } from '../../hooks'
import styles from './CampaignDetails.module.scss'
import { connector, PropsFromRedux, OwnProps } from './container'

type Props = PropsFromRedux & OwnProps

const CampaignDetails = (props: Props) => {
  const { match } = props

  const [isUpdateDrawerVisible, setIsUpdateDrawerVisible] = useState(false)

  const [isCreatePluginSettingDrawerVisible, setIsCreatePluginSettingDrawerVisible] = useState(false)
  const { history } = useReactRouter()
  const campaignId = +match.params.campaignId

  const campaign = useCampaign(campaignId)

  useEffect(() => {
    if (campaign.error) {
      notification.error({
        message: 'Campaigns details page error!',
        description: campaign.error instanceof Error ? campaign.error.message : campaign.error.toString()
      })
    }
  }, [campaign.error])

  const handleOnClose = () => {
    setIsUpdateDrawerVisible(false)
  }

  const handleOnClosePlugin = () => {
    setIsCreatePluginSettingDrawerVisible(!isCreatePluginSettingDrawerVisible)
  }

  const DTGAttribute = campaign?.data?.attributes?.find((item) => item.attribute === 'DTG_CAMPAIGN_ID')

  return (
    <Layout style={rootStyles.root} className={styles.baseContainer}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader title='Campaign Details' onBack={() => history.goBack()} />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Campaigns', url: '/campaigns' },
            { title: 'Campaign Details', url: '/campaigns' }
          ]}
        />
      </Layout.Content>
      {campaign?.data && (
        <>
          <div className={styles.listContainer}>
            <div className={styles.leftColumn}>
              <div className={styles.listItem}>Campaign ID: {campaign.data?.id}</div>
              <div className={styles.listItem}>Campaign Name: {campaign.data?.name}</div>
              <div className={styles.listItem}>Default domain: {campaign.data?.defaultDomain}.merchx.com</div>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.listItem}>Has custom domain: {campaign.data?.hasCustomDomain === false ? 'No' : 'Yes'}</div>
              <div className={styles.listItem}>Custom domain Name: {campaign.data?.domain?.name || ''}</div>
              <div className={styles.listItem}>
                Tags:{' '}
                {campaign.data?.tags.map((item) => (
                  <Tag key={item}>{item}</Tag>
                ))}
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.listItem}>Is DTG: {DTGAttribute ? 'Yes' : 'No'}</div>
              {DTGAttribute && <div className={styles.listItem}>DTG Campaign ID: {DTGAttribute.value}</div>}
            </div>
          </div>
        </>
      )}
      <UpdateCampaign campaignId={campaignId} onClose={handleOnClose} visible={isUpdateDrawerVisible} />
      <SavePluginSetting
        ownerType='CAMPAIGN'
        ownerId={campaignId}
        visible={isCreatePluginSettingDrawerVisible}
        onClose={handleOnClosePlugin}
      />
    </Layout>
  )
}

export default connector(CampaignDetails)
