import { StripeSettingsDto } from '@merchx-v2/shared-types/dist/dto/stripeSettings/stripe-settings.dto'
import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type StripeSettingsForOptionsSelector = (state: ReduxState) => StripeSettingsDto[]
const getStripeSettingsForOptions: StripeSettingsForOptionsSelector = (state) =>
  state.stripeSettings.stripeSettingsForOptions || ([] as StripeSettingsDto[])

export const stripeSettingsForOptionsSelector: StripeSettingsForOptionsSelector = createSelector(
  getStripeSettingsForOptions,
  (stripeSettings) => stripeSettings
)
