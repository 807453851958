import React, { useEffect, useState } from 'react'
import { SearchableSelectBase } from 'components'
import { useProductsOptions } from '../../hooks/useProductsOptions'

type PropsType = {
  campaignId: number
  initialOption: SelectOption
  disabled?: boolean
  onSelect: (selectOption: SelectOption) => void
}

const OrderItemProductsSelect = (props: PropsType) => {
  const { campaignId, ...rest } = props

  const [searchText, setSearchText] = useState<string>()

  const productOptions = useProductsOptions({ campaignId, searchText })

  useEffect(() => {
    productOptions.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  return (
    <SearchableSelectBase
      onSearch={setSearchText}
      options={productOptions.data}
      isLoading={productOptions.isLoading || productOptions.isRefetching}
      {...rest}
    />
  )
}

export default OrderItemProductsSelect
