import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

export type OwnProps = {
  authorizeSettingId: number
  onClose: () => void
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  return {
    isLoading: selectors.isLoadingSelector(state, 'isUpdateAuthorizeSetting'),
    authorizeSetting: selectors.authorizeSettingSelector(state, ownProps.authorizeSettingId)
  }
}

const mapDispatchToProps = {
  updateAuthorizeSetting: actions.updateAuthorizeSetting
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector>
