import React, { useState } from 'react'
import { Tag, Tooltip, Select } from 'antd'
import useDebounce from 'utils/useDebounce'
import { useSimpleCustomSaleSourceOptions } from '../../hooks'

type PropsType = {
  value?: string[]
  placeholder?: string
  onChange?: (customSaleSourceList: string[]) => void
}

const CustomSaleSourceSelector = (props: PropsType) => {
  const {
    placeholder = 'Please start typing the platform sale source',
    value,

    onChange
  } = props

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_isOpened, setIsOpened] = React.useState(false)
  const [searchText, setSearchText] = useState<string>()
  const debouncedSearchText = useDebounce(searchText, 500)

  const options = useSimpleCustomSaleSourceOptions({ searchText: debouncedSearchText })

  const handleTagRemoved = (removedTag) => {
    onChange(value.filter((tag) => tag !== removedTag))
  }

  const handleValueChanged = (customSaleSource: string) => {
    customSaleSource && !~value.indexOf(customSaleSource) && onChange([...value, customSaleSource])
    setSearchText(customSaleSource)
  }

  const handleDropdownOpened = (opened: boolean) => {
    setIsOpened(opened)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      options?.data?.includes(searchText) && !value.includes(searchText) && onChange([...value, searchText])
    }
  }

  return (
    <>
      <Select
        style={{ width: '100%' }}
        showSearch
        placeholder={placeholder}
        value={searchText}
        filterOption={false}
        onDropdownVisibleChange={handleDropdownOpened}
        onChange={handleValueChanged}
        onSearch={(search: string) => setSearchText(search)}
        loading={options.isLoading}
        onKeyDown={handleKeyDown}
        allowClear
      >
        {options?.data?.map((item, idx) => (
          <Select.Option key={idx} value={item}>
            {item}
          </Select.Option>
        ))}
      </Select>

      {value.map((customSaleSource) => {
        const isLongTag = customSaleSource.length > 20

        const tagElem = (
          <Tag style={{ userSelect: 'none', marginTop: '10px' }} key={customSaleSource} closable onClose={() => handleTagRemoved(customSaleSource)}>
            <span>{isLongTag ? `${customSaleSource.slice(0, 20)}...` : customSaleSource}</span>
          </Tag>
        )
        return isLongTag ? (
          <Tooltip title={customSaleSource} key={customSaleSource}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        )
      })}
    </>
  )
}

export default CustomSaleSourceSelector
