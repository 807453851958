import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'removeProductFromStore', boolean>

const REMOVE_PRODUCT_FROM_STORE = `
  mutation RemoveProductFromStore($productId: Int!) {
    removeProductFromStore(productId: $productId)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveProductFromStore = (productId: number) => Promise<boolean>

const removeProductFromStore: RemoveProductFromStore = async (productId) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_PRODUCT_FROM_STORE,
      variables: {
        productId
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeProductFromStore } = data
    if (!removeProductFromStore) {
      throw new Error("Can't remove landing!")
    }

    return removeProductFromStore
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveProductFromStoreEvents = {
  onSettled: (data?: boolean) => void
}

const removeProductFromStoreEvents: RemoveProductFromStoreEvents = {
  onSettled: () => {
    queryClient.invalidateQueries(['storeProductsPage'])
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useRemoveProductFromStore = () => useMutation(removeProductFromStore, removeProductFromStoreEvents)
