import * as t from '../actionTypes'
import { ProductImage } from '../types'

export type ProductImagesLoadedAction = BaseFSA<{ productId: number; productImages: Record<number, ProductImage> }>

export const productImagesLoaded =
  (productId: number, assets: ProductImage[]): MrxThunk<ProductImagesLoadedAction> =>
  (dispatch) => {
    dispatch({
      type: t.PRODUCT_IMAGES_LOADED,
      payload: {
        productId,
        productImages: assets.reduce<Record<number, ProductImage>>((acc, cur) => {
          acc[cur.id] = cur
          return acc
        }, {})
      }
    })
  }
