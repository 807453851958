import { SettingData } from './types'

export const googleShoppingSettingsData: SettingData[] = [
  {
    name: 'type',
    label: 'Auth type',
    rules: [{ required: true, message: 'Please select authorization type!' }],
    options: [{ key: '1', value: 'service_account', title: 'Service Account' }]
  },
  {
    name: 'merchantId',
    label: 'Merchant ID',
    rules: [{ required: true, message: 'Please input merchant ID!' }]
  },
  {
    name: 'privateKey',
    label: 'Private Key (certificate)',
    rules: [{ required: true, message: 'Please input private key!' }],
    rows: 3
  },
  {
    name: 'clientId',
    label: 'Client ID',
    rules: [{ required: true, message: 'Please input client ID!' }]
  },
  {
    name: 'clientEmail',
    label: 'Client Email',
    rules: [{ required: true, message: 'Please input client email!' }]
  }
]
