import * as t from '../actionTypes'
import axios from 'utils/axios'
import FormData from 'form-data'
import { SiteType } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

export type RestoreSiteFromFileAction = FSA<undefined, number, string>
type RestoreSiteFromFile = (ownerType: SiteType, ownerId: number, file: File) => MrxThunk<RestoreSiteFromFileAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'restoreSiteFromFile', number>

const restoreSiteFromFile: RestoreSiteFromFile = (ownerType, ownerId, file) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.RESTORE_SITE_FROM_FILE,
    meta: { done: false }
  })

  try {
    const formData = new FormData()

    formData.append(
      'operations',
      JSON.stringify({
        query: `
          mutation restoreSiteFromFile($ownerType: SiteType!, $ownerId: Int!, $file: Upload!) {
            restoreSiteFromFile(ownerType: $ownerType, ownerId: $ownerId, file: $file)
          }
        `,
        variables: {
          ownerType,
          ownerId: +ownerId,
          file: null
        }
      })
    )
    formData.append('map', JSON.stringify({ 0: ['variables.file'] }))
    formData.append('0', file)

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', formData)

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { restoreSiteFromFile } = data
    if (!restoreSiteFromFile) {
      throw new Error("Can't upload asset!")
    }

    dispatch({
      type: t.RESTORE_SITE_FROM_FILE,
      payload: restoreSiteFromFile,
      meta: { done: true }
    })

    isSaved = true
  } catch (err) {
    dispatch({
      type: t.RESTORE_SITE_FROM_FILE,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isSaved
}

export { restoreSiteFromFile }
