// TODO - remove becouse of legacy

import { useInfiniteQuery } from 'react-query'
import axios from 'utils/axios'
import hash from 'object-hash'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { QuizzesList, QuizzesListFilters } from '../types'

const DEFAULT_PAGE_SIZE = 30

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'quizzesListPage', QuizzesList>

const FETCH_QUIZZES_LIST = `
  query fetchQuizzesList ($page: Int, $size: Int, $filters: QuizzesListPageFilters!) {
    quizzesListPage (page: $page, size: $size, filters: $filters) {
      items {
        id


        campaign {
          id
          name
        }

        name
        campaignId
        logicType
        ownerType
        ownerId
        owner {
          id
          firstName
          lastName
          email
          createdFromLandingUrl
          createdAt
          updatedAt
        }
    
        isLogicValid
    
        countOfPlays
    
        archivedAt
        createdAt
        updatedAt
      }
      total
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type PageParam = {
  page?: number
  size?: number
  filters?: QuizzesListFilters
}

type FetchQuizzesListArgs = {
  queryKey: string | string[]
  pageParam?: PageParam
}

type FetchQuizzesList = (args: FetchQuizzesListArgs) => Promise<QuizzesList>

const fetchQuizzesList: FetchQuizzesList = async ({ pageParam }) => {
  const { page = 1, size = DEFAULT_PAGE_SIZE, filters = undefined } = pageParam || {}
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_QUIZZES_LIST,
      variables: { page, size, filters }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { quizzesListPage } = data
    if (!quizzesListPage) {
      throw new Error("Can't get quizzes list page!")
    }

    return {
      items: quizzesListPage.items,
      total: quizzesListPage.total,
      currentPage: page,
      pageSize: size,
      filters
    }
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

type GetNextPageParam = (args: QuizzesList) => PageParam | undefined

type QuizzesListEvents = {
  keepPreviousData: boolean
  getPreviousPageParam: GetNextPageParam
  getNextPageParam: GetNextPageParam
}

const quizzesListEvents: QuizzesListEvents = {
  keepPreviousData: true,
  getPreviousPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, filters }) => {
    if (currentPage > 1) {
      return {
        page: currentPage - 1,
        size: pageSize,
        filters
      }
    }

    return undefined
  },
  getNextPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, total = 0, filters }) => {
    if (pageSize * currentPage < total) {
      return {
        page: currentPage + 1,
        size: pageSize,
        filters
      }
    }

    return undefined
  }
}

export const useQuizzesList = (filters: QuizzesListFilters) => {
  const cacheKey = ['quizzesList', hash(filters)]

  return useInfiniteQuery(
    cacheKey,
    (prev) =>
      fetchQuizzesList({
        queryKey: cacheKey,
        pageParam: { ...prev.pageParam, filters }
      }),
    quizzesListEvents
  )
}
