import { handleActions } from 'redux-actions'
import produce from 'immer'
import * as t from './actionTypes'
import { actionTypes as nt } from 'features/navigation'
import { actionHasError, actionHasDone } from 'utils/actionsHelpers'

const initialState = {
  overviewList: [],
  campaignLevelList: [],
  campaignsForOptions: [],
  UIState: {
    list: {
      isLoading: false,
      isLoadingCsv: false
    },
    create: {
      isLoading: false
    }
  }
}

const overviewReportReducer = handleActions(
  {
    [t.FETCH_OVERVIEW_REPORT]: (state, action) =>
      produce(state, draft => {
        draft.UIState.list.isLoading = !action.meta
        delete draft.UIState.list.error

        if (actionHasError(action)) {
          draft.UIState.list.error = action.payload
        }

        if (actionHasDone(action)) {
          draft.overviewList = action.payload
        }
      }),

    [t.FETCH_CAMPAIGN_LEVEL_REPORT]: (state, action) =>
      produce(state, draft => {
        draft.UIState.list.isLoading = !action.meta
        delete draft.UIState.list.error

        if (actionHasError(action)) {
          draft.UIState.list.error = action.payload
        }

        if (actionHasDone(action)) {
          draft.campaignLevelList = action.payload
        }
      }),

    [t.FETCH_CAMPAIGN_LEVEL_CSV_REPORT]: (state, action) =>
      produce(state, draft => {
        draft.UIState.list.isLoadingCsv = !action.meta
        delete draft.UIState.list.error

        if (actionHasError(action)) {
          draft.UIState.list.error = action.payload
        }
      }),

    [t.FETCH_OVERVIEW_CSV_REPORT]: (state, action) =>
      produce(state, draft => {
        draft.UIState.list.isLoadingCsv = !action.meta
        delete draft.UIState.list.error

        if (actionHasError(action)) {
          draft.UIState.list.error = action.payload
        }
      }),

    [t.FETCH_CAMPAIGNS_FOR_OPTIONS]: (state, action) =>
      produce(state, draft => {
        draft.UIState.isLoading = !action.meta
        delete draft.UIState.error

        if (actionHasError(action)) {
          draft.UIState.error = action.payload
        }
        if (actionHasDone(action)) {
          draft.campaignsForOptions = action.payload
        }
      }),

    [nt.LOCATION_CHANGE]: state =>
      produce(state, draft => {
        if (draft.UIState.error) {
          delete draft.UIState.error
        }
      })
  },
  initialState
)

export default overviewReportReducer
