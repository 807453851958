import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { UserRole } from '@merchx-v2/shared-types'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'customerAccountSetRole', boolean>

const CUSTOMER_ACCOUNT_SET_ROLE = `
  mutation customerAccountSetRole ($customerAccountId: Int!, $role: UserRole!) {
    customerAccountSetRole(customerAccountId: $customerAccountId, role: $role)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type CustomerAccountSetRoleArgs = {
  customerAccountId: number
  role: UserRole
}

type CustomerAccountSetRole = (args: CustomerAccountSetRoleArgs) => Promise<boolean>

const setCustomerAccountRole: CustomerAccountSetRole = async ({ customerAccountId, role }) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CUSTOMER_ACCOUNT_SET_ROLE,
      variables: {
        customerAccountId,
        role
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { customerAccountSetRole } = data
    if (!customerAccountSetRole) {
      throw new Error("Can't set customer account role!")
    }

    return customerAccountSetRole
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type SetCustomerAccountRoleEvents = {
  onSettled: (isSaved?: boolean) => void
}

const setCustomerAccountRoleEvents: SetCustomerAccountRoleEvents = {
  onSettled: (isSaved) => {
    if (isSaved) {
      queryClient.invalidateQueries(['customerAccount'])
      queryClient.invalidateQueries(['customerAccounts'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useSetCustomerAccountRole = () => useMutation(setCustomerAccountRole, setCustomerAccountRoleEvents)
