import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { SettingOwner } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Setting, SettingDataInput } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'updateSetting', boolean>

const UPDATE_SETTING = `
  mutation updateSetting($settingId: Int!, $settingData: SettingInput!) {
    updateSetting(settingId: $settingId, settingData: $settingData)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateSettingArgs = {
  ownerType: SettingOwner
  ownerId: number
  settingId: number
  settingData: SettingDataInput
}

type UpdateSetting = (args: UpdateSettingArgs) => Promise<UpdateSettingArgs>

const updateSetting: UpdateSetting = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_SETTING,
      variables: {
        settingId: args.settingId,
        settingData: args.settingData
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateSetting } = data
    if (!updateSetting) {
      throw new Error("Can't update setting!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateSettingContext = { prevSetting?: Setting }

type UpdateSettingEvents = {
  onMutate: (variables: UpdateSettingArgs) => Promise<UpdateSettingContext>
  onError: (error: string, variables: UpdateSettingArgs, context: UpdateSettingContext) => void
  onSettled: (data?: UpdateSettingArgs) => void
}

const updateSettingEvents: UpdateSettingEvents = {
  onMutate: async (variables: UpdateSettingArgs) => {
    await queryClient.cancelQueries(['setting', variables.settingId])

    // Snapshot the previous value
    const prevSetting = queryClient.getQueryData<Setting>(['setting', variables.settingId])

    // Optimistically update to the new values
    if (prevSetting) {
      queryClient.setQueryData<Setting>(['setting', variables.settingId], {
        ...prevSetting,
        id: variables.settingId,
        ...variables.settingData
      })
    }

    return { prevSetting }
  },
  onError: (_err, variables, context) => {
    if (context?.prevSetting) {
      // Restore previous version of setting on any error
      queryClient.setQueryData<Setting>(['setting', variables.settingId], context.prevSetting)
    }
  },
  onSettled: (data) => {
    if (data?.settingId) {
      queryClient.invalidateQueries(['template'])
      queryClient.invalidateQueries(['landing'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUpdateSetting = () => useMutation(updateSetting, updateSettingEvents)
