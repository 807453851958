import { createSelector } from 'reselect'

const getCustomGatewaySettingsOptions: SelectOptionsSelector = state =>
  state.customGatewaySettings.customGatewaySettingsForOptions.map(item => ({
    id: item.id,
    name: item.name
  })) || ([] as SelectOption[])

export const customGatewaySettingsSelectOptionsSelector: SelectOptionsSelector = createSelector(
  getCustomGatewaySettingsOptions,
  options => options
)
