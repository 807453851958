import { useInfiniteQuery } from 'react-query'
import hash from 'object-hash'
import { PromocodeDto } from '@merchx-v2/shared-types'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

const DEFAULT_PAGE_SIZE = 30

export type PromocodesPage = {
  items: PromocodeDto[]
  total: number
  currentPage: number
  pageSize: number
  promocode?: string
  queryGeneratedPromocodes?: boolean
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'promocodesPage', PromocodesPage>

const FETCH_PROMOCODES_PAGE = `
  query fetchPromocodesPage ($page: Int, $size: Int, $promocode: String, $queryGeneratedPromocodes: Boolean) {
    promocodesPage(page: $page, size: $size, promocode: $promocode, queryGeneratedPromocodes: $queryGeneratedPromocodes) {
      items {
        id

        code
        headline
        title
        buttonText
        descriptionHeadline
        description
  
        condition
        conditionValue

        logicType
        logicValue

        availabilities

        expirationTypes
        expirationValues

        orderId
        createdAt
        updatedAt
      }
      total
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type PageParam = {
  page?: number
  size?: number
  promocode?: string
  queryGeneratedPromocodes?: boolean
}

type FetchPromocodesListArgs = {
  queryKey: string | string[]
  pageParam?: PageParam
}

type FetchPromocodesPage = (args: FetchPromocodesListArgs) => Promise<PromocodesPage>

const fetchPromocodesPage: FetchPromocodesPage = async ({ pageParam }) => {
  const { page = 1, size = DEFAULT_PAGE_SIZE, promocode, queryGeneratedPromocodes } = pageParam || {}
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_PROMOCODES_PAGE,
      variables: { page, size, promocode, queryGeneratedPromocodes }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { promocodesPage } = data
    if (!promocodesPage) {
      throw new Error("Can't get promocodes page!")
    }

    return {
      items: promocodesPage.items,
      total: promocodesPage.total,
      currentPage: page,
      pageSize: size,
      promocode,
      queryGeneratedPromocodes
    }
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

type GetNextPageParam = (args: PromocodesPage) => PageParam | undefined

type PromocodesPageEvents = {
  keepPreviousData: boolean
  refetchOnMount: boolean
  getPreviousPageParam: GetNextPageParam
  getNextPageParam: GetNextPageParam
}

const promocodesPageEvents: PromocodesPageEvents = {
  keepPreviousData: true,
  refetchOnMount: true,
  getPreviousPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, promocode, queryGeneratedPromocodes }) => {
    if (currentPage > 1) {
      return {
        page: currentPage - 1,
        size: pageSize,
        promocode,
        queryGeneratedPromocodes
      }
    }

    return undefined
  },
  getNextPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, total = 0, promocode, queryGeneratedPromocodes }) => {
    if (pageSize * currentPage < total) {
      return {
        page: currentPage + 1,
        size: pageSize,
        promocode,
        queryGeneratedPromocodes
      }
    }
    return undefined
  }
}

type Args = {
  promocode?: string
  queryGeneratedPromocodes?: boolean
}

export const usePromocodesList = (args: Args) => {
  const cacheKey = ['promocodesPage', hash(args)]

  return useInfiniteQuery(
    cacheKey,
    (prev) =>
      fetchPromocodesPage({
        queryKey: cacheKey,
        pageParam: { ...prev.pageParam, ...args }
      }),
    promocodesPageEvents
  )
}
