// TODO - remove becouse of legacy

import React, { useContext, useEffect, useState } from 'react'
import { Button, Drawer, Input, Form, Select, Tag, Tooltip, Divider } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import notification from 'mrx-notification'
import { GlobalContext } from 'appContexts'
import { useTagsOptions } from 'features/tags/hooks'
import { useDuplicateLanding } from '../../hooks'
import styles from './DuplicateLanding.module.scss'

type PropsType = {
  landingId: number
  campaignId: number
  onClose: (shouldBlock?: boolean) => void
  visible: boolean
}

const DuplicateLanding = (props: PropsType) => {
  const { onClose, landingId, visible } = props

  const [tagsState, setTagsState] = useState([])
  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [editInputIndex, setEditInputIndex] = useState(-1)
  const [editInputValue, setEditInputValue] = useState('')
  const [editInputRef, setEditInputRef] = useState()
  const [inputRef, setInputRef] = useState()

  const [form] = Form.useForm()

  const { workspaceId } = useContext(GlobalContext)

  const duplicateLanding = useDuplicateLanding()
  const tagsForOptions = useTagsOptions({ workspaceId, ownerType: 'LANDING' })

  const handleClose = (shouldBlock = false) => {
    form.resetFields()
    onClose(shouldBlock)
  }

  useEffect(() => {
    if (inputVisible === true && inputRef) {
      // @ts-ignore
      inputRef.focus()
    }
  }, [inputVisible, inputRef])

  useEffect(() => {
    if (editInputRef) {
      // @ts-ignore
      editInputRef.focus()
    }
  }, [inputVisible, editInputRef])

  // TODO - fix any type
  const onFinishHandler = async (values: any) => {
    duplicateLanding.mutate(
      {
        landingId,
        name: values.name,
        subroute: values.subroute,
        tags: tagsState
      },
      {
        onSuccess: () => {
          notification.success({
            message: 'Successfully',
            description: 'Landing was duplicated successfully!'
          })
          handleClose(true)
        },
        onError: (error) => {
          notification.error({
            message: 'Error!',
            description: error.toString()
          })
        }
      }
    )
  }

  const handleCloseTag = (removedTag) => {
    setTagsState(tagsState.filter((tag) => tag !== removedTag))
  }

  const showInput = () => {
    setInputVisible(true)
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  const handleInputConfirm = () => {
    let tags = tagsState
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue]
    }
    setTagsState(tags)
    setInputVisible(false)
    setInputValue('')
  }

  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value)
  }

  const handleEditInputConfirm = () => {
    const newTags = [...tagsState]
    newTags[editInputIndex] = editInputValue

    setTagsState(newTags)
    setEditInputIndex(-1)
    setEditInputValue('')
  }

  const saveInputRef = (input) => {
    setInputRef(input)
  }

  const saveEditInputRef = (input) => {
    setEditInputRef(input)
  }

  const handleTagsSelectorChange = (value) => {
    if (!tagsState.includes(value)) {
      setTagsState([...tagsState, value])
    }

    form.setFieldsValue({
      tags: ''
    })
  }

  return (
    <Drawer title='Duplicate Landing' width='400' onClose={() => handleClose()} visible={visible}>
      <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
        <Form.Item name='name' label='New Name' rules={[{ required: true, message: 'Please input new landing name!' }]}>
          <Input style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item name='subroute' label='New Subroute' rules={[{ required: true, message: 'Please input new landing subroute!' }]}>
          <Input style={{ width: '100%' }} />
        </Form.Item>

        <Divider plain className={styles.divider}>
          Tags
        </Divider>

        <Form.Item name='tags' label='Tags'>
          <Select style={{ width: '100%' }} placeholder='Choose existing tag' onChange={handleTagsSelectorChange}>
            {tagsForOptions.data?.map((item) => {
              return (
                <Select.Option value={item} key={item}>
                  {item}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>

        {tagsState &&
          tagsState.map((tag, index) => {
            if (editInputIndex === index) {
              return (
                <Input
                  ref={saveEditInputRef}
                  key={tag}
                  size='small'
                  style={{ width: '78px', marginRight: '8px', marginTop: '10px', verticalAlign: 'top' }}
                  value={editInputValue}
                  onChange={handleEditInputChange}
                  onBlur={handleEditInputConfirm}
                  onPressEnter={handleEditInputConfirm}
                />
              )
            }

            const isLongTag = tag.length > 20

            const tagElem = (
              <Tag style={{ userSelect: 'none', marginTop: '10px' }} key={tag} closable onClose={() => handleCloseTag(tag)}>
                <span
                  onDoubleClick={(e) => {
                    setEditInputIndex(index)
                    setEditInputValue(tag)
                    e.preventDefault()
                  }}
                >
                  {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                </span>
              </Tag>
            )
            return isLongTag ? (
              <Tooltip title={tag} key={tag}>
                {tagElem}
              </Tooltip>
            ) : (
              tagElem
            )
          })}

        {inputVisible && (
          <Input
            ref={saveInputRef}
            type='text'
            size='small'
            // is's not working with scss, just inline
            style={{
              width: '100%',
              marginTop: '15px',
              verticalAlign: 'top',
              height: '30px',
              display: 'flex',
              alignItems: 'center'
            }}
            className={styles.tagInput}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />
        )}
        {!inputVisible && (
          <Tag onClick={showInput} style={{ width: '100%', marginTop: '15px', height: '30px', display: 'flex', alignItems: 'center' }}>
            <PlusOutlined style={{ marginRight: '10px' }} /> CREATE NEW TAG
          </Tag>
        )}

        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right'
          }}
        >
          <Button onClick={() => handleClose()} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit' loading={duplicateLanding.isLoading}>
            Duplicate
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default DuplicateLanding
