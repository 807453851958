import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'setVariantItemsOnStock', boolean>

const SET_VARIANT_ITEMS_TO_STOCK = `
  mutation SetVariantItemsOnStock($campaignId: Int!, $productId: Int!, $variantKey: String!, $quantity: Int!) {
    setVariantItemsOnStock(campaignId: $campaignId, productId: $productId, variantKey: $variantKey, quantity: $quantity)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type SetVariantItemsOnStockArgs = {
  campaignId: number
  productId: number
  variantKey: string
  quantity: number
}

type SetVariantItemsOnStock = (args: SetVariantItemsOnStockArgs) => Promise<boolean>

const setVariantItemsOnStock: SetVariantItemsOnStock = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: SET_VARIANT_ITEMS_TO_STOCK,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { setVariantItemsOnStock } = data
    if (!setVariantItemsOnStock) {
      throw new Error("Can't set the number of stock items!")
    }

    return setVariantItemsOnStock
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type SetVariantItemsOnStockEvents = {
  onSettled: (isSaved?: boolean) => void
}

const setVariantItemsOnStockEvents: SetVariantItemsOnStockEvents = {
  onSettled: (isSaved) => {
    if (isSaved) {
      queryClient.invalidateQueries(['inventoryList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useSetVariantItemsOnStock = () => useMutation(setVariantItemsOnStock, setVariantItemsOnStockEvents)
