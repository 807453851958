import React, { useState } from 'react'
import { SearchableSelectBase } from 'components'
import { useDomainsOptions } from '../../hooks'

type Props = {
  initialOption: SelectOption
  disabled?: boolean
  onSelect: (selectOption: SelectOption) => void
}

const DomainsSelect = (props: Props) => {
  const [searchText, setSearchText] = useState<string>('')
  const { data, isLoading } = useDomainsOptions(searchText)

  return (
    <SearchableSelectBase
      {...props}
      options={data.map((item) => ({ id: item.id, name: item.name }))}
      onSearch={(newSearchText: string) => setSearchText(newSearchText)}
      isLoading={isLoading}
    />
  )
}

export default DomainsSelect
