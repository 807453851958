// TODO - remove becouse of legacy

import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Landing } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'duplicateLanding', boolean>

const DUPLICATE_LANDING = `
  mutation duplicateLanding ($landingId: Int!, $name: String!, $subroute: String!, $tags: [String]!) {
    duplicateLanding(landingId: $landingId, name: $name, subroute: $subroute, tags: $tags)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type DuplicateLandingArgs = {
  landingId: number
  name: string
  subroute: string
  tags: string[]
}

type DuplicateLanding = (args: DuplicateLandingArgs) => Promise<DuplicateLandingArgs>

const duplicateLanding: DuplicateLanding = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: DUPLICATE_LANDING,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { duplicateLanding } = data
    if (!duplicateLanding) {
      throw new Error("Can't duplicate landing!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type DuplicateLandingContext = { prevLanding?: Landing }

type DuplicateLandingEvents = {
  onMutate: (variables: DuplicateLandingArgs) => Promise<DuplicateLandingContext>
  onError: (error: string, variables: DuplicateLandingArgs, context: DuplicateLandingContext) => void
  onSettled: (data?: DuplicateLandingArgs) => void
}

const duplicateLandingEvents: DuplicateLandingEvents = {
  onMutate: async (variables: DuplicateLandingArgs) => {
    await queryClient.cancelQueries(['landing', variables.landingId])

    // Snapshot the previous value
    const prevLanding = queryClient.getQueryData<Landing>(['landing', variables.landingId])

    // Optimistically update to the new values
    if (prevLanding) {
      queryClient.setQueryData<Landing>(['landing', variables.landingId], {
        ...prevLanding,
        id: variables.landingId,
        ...variables
      })
    }

    return { prevLanding }
  },
  onError: (_err, variables, context) => {
    if (context?.prevLanding) {
      // Restore previous version of landing on any error
      queryClient.setQueryData<Landing>(['landing', variables.landingId], context.prevLanding)
    }
  },
  onSettled: (data) => {
    if (data?.landingId) {
      queryClient.invalidateQueries(['landing', data.landingId])
      queryClient.invalidateQueries(['landingsList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useDuplicateLanding = () => useMutation(duplicateLanding, duplicateLandingEvents)
