// TODO - remove becouse of legacy

import * as t from '../actionTypes'
import { Moment } from 'moment-timezone'

export type ChangeDateListAction = Required<Omit<BaseFSA<Moment>, 'meta'>>
type ChangeDateList = (date: Moment) => MrxThunk<ChangeDateListAction, Promise<void>>

export const changeDateList: ChangeDateList = (date) => async (dispatch) => {
  dispatch({
    type: t.CHANGE_DATE_LIST,
    payload: date
  })
}
