import { connect, ConnectedProps } from 'react-redux'
import { SettingOwner } from '@merchx-v2/shared-types'
import { ReduxState } from 'store/createRootReducer'
import userSelector from 'features/authentication/selectors/userStateSelector'

type OwnProps = {
  ownerType: SettingOwner
  ownerId: number
}

const mapStateToProps = (state: ReduxState) => ({
  user: userSelector(state)
})

const mapDispatchToProps = {}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
