import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

export type RedeployAllLandingsAction = FSA<undefined, number, string>
type RedeployAllLandings = () => MrxThunk<RedeployAllLandingsAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'redeployAllLandings', boolean>

const REDEPLOY_ALL_LANDINGS = `
  mutation redeployAllLandings($workspaceId: Int!) {
    redeployAllLandings(workspaceId: $workspaceId)
  }
`

const redeployAllLandings: RedeployAllLandings = () => async (dispatch, getState) => {
  const {
    workspaces: {
      activeWorkspace: { id: workspaceId }
    }
  } = getState()

  let isRedeployed = false

  dispatch({
    type: t.REDEPLOY_ALL_LANDINGS,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REDEPLOY_ALL_LANDINGS,
      variables: { workspaceId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Empty response!')
    }

    const { redeployAllLandings } = data
    if (!redeployAllLandings) {
      throw new Error("Can't redeploy landings!")
    }

    dispatch({
      type: t.REDEPLOY_ALL_LANDINGS,
      payload: workspaceId,
      meta: { done: true }
    })

    isRedeployed = true
  } catch (err) {
    dispatch({
      type: t.REDEPLOY_ALL_LANDINGS,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isRedeployed
}

export { redeployAllLandings }
