import React, { useEffect, useState } from 'react'
import { UserDto } from '@merchx-v2/shared-types'
import useReactRouter from 'use-react-router'
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import { Input, Layout, PageHeader, Table } from 'antd'
import { EyeTwoTone } from '@ant-design/icons'
import notification from 'mrx-notification'
import { ColumnsType } from 'antd/lib/table'
import { Breadcrumbs } from 'components'
import * as rootStyles from 'assets/layoutStyle'
import { useUsersList } from '../../hooks'
import { GraphQLUsersListPageItem } from '../../types'
import styles from './UsersList.module.scss'

type PropsType = {
  showHeader?: boolean
  searchQuery?: string
}

const UsersList = ({ showHeader = true }: PropsType) => {
  const { history } = useReactRouter()

  const [searchQuery, setSearchQuery] = useState<string>()

  const usersList = useUsersList({ searchText: searchQuery })

  const users: GraphQLUsersListPageItem[] = []
  usersList && usersList.data && usersList.data.pages.forEach((page) => page.items.forEach((user) => users.push(user)))

  const page = usersList.data?.pages.length ? usersList.data?.pages[usersList.data?.pages.length - 1].currentPage : 1
  const size = usersList.data?.pages.length ? usersList.data?.pages[usersList.data?.pages.length - 1].pageSize : 30

  useEffect(() => {
    if (usersList.error) {
      notification.error({
        message: 'users error!',
        description: usersList.error instanceof Error ? usersList.error.message : usersList.error.toString()
      })
    }
  }, [usersList.error])

  const onSearchInputChangeHandler = (value) => {
    setSearchQuery(value)
  }

  const columns: ColumnsType<UserDto> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName'
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Last Login At',
      render: (_value, record) => moment(record.lastLoginAt).format('MM/DD/YYYY'),
      key: 'lastLoginAt'
    },
    {
      title: 'Banned Till',
      render: (_value, record) => (new Date(record.bannedTill) > new Date() ? moment(record.bannedTill).format('MM/DD/YYYY') : ''),
      key: 'bannedTill'
    },
    {
      title: 'Created At',
      render: (_value, record) => moment(record.createdAt).format('MM/DD/YYYY'),
      key: 'createdAt'
    },
    {
      title: 'Status',
      render: (_value, record) =>
        new Date(record.bannedTill) > new Date() ? 'BANNED' : !record.isEmailConfirmed ? 'NOT CONFIRMED' : 'ACTIVE',
      key: 'status'
    },
    {
      title: '',
      dataIndex: '',
      key: 'edit',
      width: '7%',
      align: 'center',
      render: (record) => (
        <span>
          <EyeTwoTone
            style={{ fontSize: '20px' }}
            onClick={(e) => {
              e.stopPropagation()
              history.push(`/settings/users/${record.id}`)
            }}
          />
        </span>
      )
    }
  ]

  return (
    <Layout style={rootStyles.root}>
      {showHeader && (
        <>
          <Layout.Header style={rootStyles.header}>
            <PageHeader
              onBack={() => history.goBack()}
              title='Users'
              extra={[
                <div key='1' className={styles.extraContent}>
                  <Input.Search
                    key='search-field'
                    placeholder='Search'
                    onSearch={(value) => onSearchInputChangeHandler(value)}
                    style={{ width: 200, marginLeft: 10 }}
                    allowClear
                  />
                </div>
              ]}
            />
          </Layout.Header>
          <Layout.Content style={rootStyles.contentBreadcrumbs}>
            <Breadcrumbs
              items={[
                { title: 'Main', url: '/' },
                { title: 'Settings', url: '/settings/users' },
                { title: 'Users', url: '/settings/users' }
              ]}
            />
          </Layout.Content>
        </>
      )}
      <Layout.Content className={showHeader && styles.contentComponent} id='pik'>
        <InfiniteScroll
          style={{ paddingBottom: '30px' }}
          dataLength={page * size}
          next={usersList.fetchNextPage}
          hasMore={!!usersList.hasNextPage}
          loader={<h4>Loading...</h4>}
          refreshFunction={usersList.refetch}
          scrollableTarget='userScroll'
        >
          <Table
            columns={columns}
            dataSource={users.map((item) => item).flat()}
            bordered
            rowKey={(record) => record.id}
            pagination={false}
            loading={usersList.isLoading}
          />
        </InfiniteScroll>
      </Layout.Content>
    </Layout>
  )
}

export default UsersList
