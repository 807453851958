import produce from 'immer'
import * as t from './actionTypes'
import { actionTypes as nt } from 'features/navigation'
import { actionTypes as wt } from 'features/workspaces'
import { isSuccessAction, isErrorAction } from 'types'
import { CustomGatewaySettingsState } from './types'
import createReducer from 'store/createReducer'
import {
  ChangeCurrentPageAction,
  ChangePageSizeAction,
  CreateCustomGatewaySettingAction,
  FetchCustomGatewaySettingsListAction,
  FetchCustomGatewaySettingsForOptionsAction,
  RemoveCustomGatewaySettingAction,
  UpdateCustomGatewaySettingAction
} from './actions'

const initState: CustomGatewaySettingsState = {
  customGatewaySettings: {},
  customGatewaySettingsForOptions: [],
  pagination: {
    currentPage: 1,
    total: 0,
    pages: {},
    size: 10
  },
  UIState: {
    isCreateCustomGatewaySetting: false,
    isListLoading: false,
    isRemoveCustomGatewaySetting: false,
    isCustomGatewaySettingsForOptionsLoading: false,
    isUpdateCustomGatewaySetting: false
  }
}

const reducer = createReducer(initState, {
  [t.CREATE_CUSTOM_GATEWAY_SETTING]: (state, action: CreateCustomGatewaySettingAction) =>
    produce(state, (draft) => {
      draft.UIState.isCreateCustomGatewaySetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isCreateCustomGatewaySetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { id } = action.payload
        const { currentPage, pages } = draft.pagination
        draft.customGatewaySettings[id] = action.payload
        pages[currentPage] = pages[currentPage] || []
        pages[currentPage].push(id)
      }
    }),

  [t.FETCH_CUSTOM_GATEWAY_SETTINGS_LIST]: (state, action: FetchCustomGatewaySettingsListAction) =>
    produce(state, (draft) => {
      draft.UIState.isListLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isListLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        action.payload.items.forEach((item) => {
          draft.customGatewaySettings[item.id] = item
        })
        const { page, total, items } = action.payload
        draft.pagination.total = total
        draft.pagination.pages[page] = items.map((item) => item.id)
      }
    }),

  [t.FETCH_CUSTOM_GATEWAY_SETTINGS_FOR_OPTIONS]: (state, action: FetchCustomGatewaySettingsForOptionsAction) =>
    produce(state, (draft) => {
      draft.UIState.isCustomGatewaySettingsForOptionsLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isCustomGatewaySettingsForOptionsLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.customGatewaySettingsForOptions = action.payload
      }
    }),

  [t.REMOVE_CUSTOM_GATEWAY_SETTING]: (state, action: RemoveCustomGatewaySettingAction) =>
    produce(state, (draft) => {
      draft.UIState.isRemoveCustomGatewaySetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isRemoveCustomGatewaySetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.customGatewaySettings[action.payload]) {
          const { pages, currentPage } = draft.pagination
          pages[currentPage] = pages[currentPage].filter((id) => id !== action.payload)
          delete draft.customGatewaySettings[action.payload]
        }
      }
    }),
  [t.UPDATE_CUSTOM_GATEWAY_SETTING]: (state, action: UpdateCustomGatewaySettingAction) =>
    produce(state, (draft) => {
      draft.UIState.isUpdateCustomGatewaySetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isUpdateCustomGatewaySetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.customGatewaySettings[action.payload.customGatewaySettingId]) {
          draft.customGatewaySettings[action.payload.customGatewaySettingId].name = action.payload.customGatewaySettingData.name
          draft.customGatewaySettings[action.payload.customGatewaySettingId].retailerApiKey =
            action.payload.customGatewaySettingData.retailerApiKey
          draft.customGatewaySettings[action.payload.customGatewaySettingId].retailerCompanyRefId =
            action.payload.customGatewaySettingData.retailerCompanyRefId
          draft.customGatewaySettings[action.payload.customGatewaySettingId].supplierApiKey =
            action.payload.customGatewaySettingData.supplierApiKey
          draft.customGatewaySettings[action.payload.customGatewaySettingId].supplierCompanyRefId =
            action.payload.customGatewaySettingData.supplierCompanyRefId
        }
      }
    }),

  [t.CHANGE_CURRENT_PAGE]: (state, action: ChangeCurrentPageAction) =>
    produce(state, (draft) => {
      if (action.payload) {
        draft.pagination.currentPage = action.payload
      }
    }),

  [t.CHANGE_PAGE_SIZE]: (state, action: ChangePageSizeAction) =>
    produce(state, (draft) => {
      if (action.payload) {
        draft.pagination.size = action.payload
      }
    }),

  [wt.SWITCH_WORKSPACE]: (state) =>
    produce(state, (draft) => {
      draft.pagination = initState.pagination
    }),

  [nt.LOCATION_CHANGE]: (state) =>
    produce(state, (draft) => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default reducer
