import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { CustomerSubscriptionNote } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'removeCustomerSubscriptionNote', boolean>

const REMOVE_CUSTOMER_SUBSCRIPTION_NOTE = `
  mutation removeCustomerSubscriptionNote ($customerSubscriptionNoteId: Int!) {
    removeCustomerSubscriptionNote (customerSubscriptionNoteId: $customerSubscriptionNoteId)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveCustomerSubscriptionNote = (customerSubscriptionNoteId: number) => Promise<number>

const removeCustomerSubscriptionNote: RemoveCustomerSubscriptionNote = async (customerSubscriptionNoteId) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_CUSTOMER_SUBSCRIPTION_NOTE,
      variables: {
        customerSubscriptionNoteId
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeCustomerSubscriptionNote } = data
    if (!removeCustomerSubscriptionNote) {
      throw new Error("Can't remove customer subscription note!")
    }

    return customerSubscriptionNoteId
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveCustomerSubscriptionNoteContext = { prevCustomerSubscriptionNote?: CustomerSubscriptionNote }

type RemoveCustomerSubscriptionNoteEvents = {
  onMutate: (customerSubscriptionNoteId: number) => Promise<RemoveCustomerSubscriptionNoteContext>
  onError: (error: string, customerSubscriptionNoteId: number, context: RemoveCustomerSubscriptionNoteContext) => void
  onSettled: (customerSubscriptionNoteId?: number) => void
}

const removeCustomerSubscriptionNoteEvents: RemoveCustomerSubscriptionNoteEvents = {
  onMutate: async (customerSubscriptionNoteId) => {
    await queryClient.cancelQueries(['orderNote', customerSubscriptionNoteId])

    // Snapshot the previous value
    const prevCustomerSubscriptionNote = queryClient.getQueryData<CustomerSubscriptionNote>([
      'customerSubscriptionNote',
      customerSubscriptionNoteId
    ])

    if (prevCustomerSubscriptionNote) {
      queryClient.removeQueries(['customerSubscriptionNote', customerSubscriptionNoteId])
    }

    return { prevCustomerSubscriptionNote }
  },
  onError: (_err, customerSubscriptionNoteId, context) => {
    if (context?.prevCustomerSubscriptionNote) {
      // Restore currrency on any error
      queryClient.setQueryData<CustomerSubscriptionNote>(
        ['customerSubscriptionNote', customerSubscriptionNoteId],
        context.prevCustomerSubscriptionNote
      )
    }
  },
  onSettled: (customerSubscriptionNoteId: number) => {
    if (customerSubscriptionNoteId) {
      queryClient.invalidateQueries(['customerSubscriptionNote', customerSubscriptionNoteId])
      queryClient.invalidateQueries(['customerSubscription'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useRemoveCustomerSubscriptionNote = () => useMutation(removeCustomerSubscriptionNote, removeCustomerSubscriptionNoteEvents)
