import * as t from '../actionTypes'
import axios from 'axios'
import settings from 'config/settings'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

export type ValidateUserEmailAction = FSA<undefined, undefined, string>
type validateUserEmail = (token: string) => MrxThunk<ValidateUserEmailAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'validateUserEmail', boolean>

const VALIDATE_USER_EMAIL = `
  mutation ValidateUserEmail($token: String!) {
    validateUserEmail(token: $token)
  }
`

export const validateUserEmail: validateUserEmail = token => async dispatch => {
  let isConfirmed = false

  dispatch({
    type: t.VALIDATE_USER_EMAIL,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post(`${settings.backendUrl}/graphql`, {
      query: VALIDATE_USER_EMAIL,
      variables: { token }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { validateUserEmail } = data
    if (!validateUserEmail) {
      throw new Error("Can't validate email!")
    }

    isConfirmed = true
  } catch (err) {
    dispatch({
      type: t.VALIDATE_USER_EMAIL,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isConfirmed
}
