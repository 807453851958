import axios from 'utils/axios'
import { useQuery } from 'react-query'
import { AssetOwner, AssetDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'assetsList', AssetDto[]>

const FETCH_ASSETS_LIST = `
  query fetchAssetsList($ownerType: AssetOwner!, $ownerId: Int) {
    assetsList(ownerType: $ownerType, ownerId: $ownerId) {
      id
      name
      extension
      ownerType
      ownerId
      mimeType
      s3bucket
      s3key
      signedUrl
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchAssetsListArgs = {
  ownerType: AssetOwner
  ownerId: number
}

type FetchAssetsList = (args: FetchAssetsListArgs) => Promise<AssetDto[]>

const fetchAssetsList: FetchAssetsList = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_ASSETS_LIST,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { assetsList } = data
    if (!assetsList) {
      throw new Error("Can't get assets list page!")
    }

    return assetsList
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useAssetsList = (args: FetchAssetsListArgs) =>
  useQuery([`${args.ownerType.toLowerCase()}`, +args.ownerId], () => fetchAssetsList(args))
