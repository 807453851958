import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { ProductAttributeForOption } from '../types'

export type FetchProductAttributesForOptionsAction = FSA<undefined, ProductAttributeForOption[], string>
type FetchProductAttributesForOptions = () => MrxThunk<FetchProductAttributesForOptionsAction>
type QueryResponse = GraphQLResponse<'productAvailableAttributeValues', ProductAttributeForOption[]>

const FETCH_PRODUCT_AVAILABLE_ATTRIBUTE_VALUES = `
  query ProductAttributesForOptions($filters: AttributeAvailableValuesFilters!) {
    productAvailableAttributeValues(filters: $filters) {
      attribute
      values
    }
  }
`

const fetchProductAvailableAttributeValues: FetchProductAttributesForOptions = () => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_PRODUCT_AVAILABLE_ATTRIBUTE_VALUES,
    meta: { done: false }
  })

  const {
    workspaces: {
      activeWorkspace: { id: workspaceId }
    }
  } = getState()

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_PRODUCT_AVAILABLE_ATTRIBUTE_VALUES,
      variables: { filters: { workspaceId } }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { productAvailableAttributeValues } = data
    if (!productAvailableAttributeValues) {
      throw new Error("Can't get product attributes!")
    }

    dispatch({
      type: t.FETCH_PRODUCT_AVAILABLE_ATTRIBUTE_VALUES,
      payload: productAvailableAttributeValues,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_PRODUCT_AVAILABLE_ATTRIBUTE_VALUES,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchProductAvailableAttributeValues }
