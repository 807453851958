import * as t from './actionTypes'
import produce from 'immer'
import { BuildOwner, EnvironmentType } from '@merchx-v2/shared-types'
import { isSuccessAction, isErrorAction } from 'types'
import { SitePreviewsState, PreviewState } from './types'
import createReducer from 'store/createReducer'
import { TakeSiteBuildInfoAction } from './actions'
import { BuildPreviewAction } from './actions/buildPreview'

const initState: SitePreviewsState = {
  LANDING: {},
  TEMPLATE: {},
  AUTORESPONDER: {}
}

const setOwnerDefaults = (
  draft: SitePreviewsState,
  ownerType: BuildOwner,
  ownerId: number,
  version: number,
  environment: EnvironmentType
) => {
  if (!draft[ownerType][ownerId]) {
    draft[ownerType][ownerId] = {} as Record<number, Record<string, PreviewState>>
  }

  if (!draft[ownerType][ownerId][version]) {
    draft[ownerType][ownerId][version] = {} as Record<string, PreviewState>
  }

  if (!draft[ownerType][ownerId][version][environment])
    draft[ownerType][ownerId][version][environment] = {
      UIState: {
        isTaskCreating: false
      }
    } as PreviewState
}

const reducer = createReducer(initState, {
  [t.BUILD_PREVIEW]: (state, action: BuildPreviewAction) =>
    produce(state, (draft) => {
      if (action.payload) {
        const { ownerType, ownerId, version, environment } = action.payload
        setOwnerDefaults(draft, ownerType, ownerId, version, environment)
        draft[ownerType][ownerId][version][environment].UIState.isTaskCreating = !(action.meta && action.meta.done)
        delete draft[ownerType][ownerId][version][environment].UIState.error

        if (isErrorAction(action)) {
          draft[ownerType][ownerId][version][environment].UIState.isTaskCreating = false
          draft[ownerType][ownerId][version][environment].UIState.error = action.payload.error
        }

        if (isSuccessAction(action)) {
          draft[ownerType][ownerId][version][environment].buildInfo = action.payload.buildInfo
        }
      }
    }),

  [t.TAKE_SITE_BUILD_INFO]: (state, action: TakeSiteBuildInfoAction) =>
    produce(state, (draft) => {
      if (action.payload) {
        const { ownerType, ownerId, version, environment } = action.payload
        setOwnerDefaults(draft, ownerType, ownerId, version, environment)
        delete draft[ownerType][ownerId][version][environment].UIState.error

        if (isErrorAction(action)) {
          draft[ownerType][ownerId][version][environment].UIState.error = action.payload.error
        }

        if (isSuccessAction(action)) {
          draft[ownerType][ownerId][version][environment].buildInfo = action.payload.buildInfo
        }
      }
    })
})

export default reducer
