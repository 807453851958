// TODO - remove becouse of legacy

import { connect, ConnectedProps } from 'react-redux'
import * as QueryString from 'query-string'
import { ReduxState } from 'store/createRootReducer'

type OwnProps = {
  match: { params: { sublanderId: string } }
  location: { search: string }
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  const queryParams = QueryString.parse(ownProps.location.search)
  const currentStep = queryParams.step ? +queryParams.step : 0
  const landingId = +queryParams.sublanderId

  return {
    landingId,
    currentStep
  }
}

const mapDispatchToProps = {}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
