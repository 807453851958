import React, { useEffect, useState } from 'react'
import hash from 'object-hash'
import { Input, Tag, Tooltip } from 'antd'
import { PlusCircleFilled } from '@ant-design/icons'
import styles from './ManageTags.module.scss'

type Props = {
  onTagCreated?: (newTag: string) => void
  onTagRenamed?: (oldTag: string, newTag: string) => void
  onTagRemoved?: (removedTag: string) => void
  onTagsChanged?: (tagsList: string[]) => void

  owner?: string
  initTags?: string[]
  placeholder?: string
  refreshOnInitTagsChanged?: boolean
}

const ManageTags = (props: Props) => {
  const {
    onTagCreated,
    onTagRemoved,
    onTagRenamed,
    onTagsChanged,
    placeholder = 'Add tag',
    owner = '',
    initTags = [],
    refreshOnInitTagsChanged = false
  } = props

  const [tags, setTags] = useState(initTags)
  const [inputVisible, setInputVisible] = useState(false)

  const [newTag, setNewTag] = useState('')
  const [newTagRef, setNewTagRef] = useState()

  const [renamedTag, setRenamedTag] = useState('')
  const [renameTagIndex, setRenameTagIndex] = useState(-1)
  const [renameTagRef, setRenameTagRef] = useState()

  useEffect(() => {
    if (inputVisible === true && newTagRef) {
      // @ts-ignore
      newTagRef.focus()
    }
  }, [inputVisible, newTagRef])

  useEffect(() => {
    if (refreshOnInitTagsChanged) {
      setTags(initTags)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(initTags)])

  useEffect(() => {
    if (renameTagRef) {
      // @ts-ignore
      renameTagRef.focus()
    }
  }, [inputVisible, renameTagRef])

  useEffect(() => {
    setTags(initTags)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [owner])

  const handleTagRemoved = removedTag => {
    const newTagsState = tags.filter(tag => tag !== removedTag)
    setTags(newTagsState)

    onTagRemoved && onTagRemoved(removedTag)
    onTagsChanged && onTagsChanged(newTagsState)
  }

  const showInput = () => {
    setInputVisible(true)
  }

  const handleInputChange = e => {
    setNewTag(e.target.value)
  }

  const handleTagCreated = () => {
    let newTags = [...tags]
    if (newTag && newTags.indexOf(newTag) === -1) {
      newTags = [...tags, newTag]
    }
    setTags(newTags)
    setInputVisible(false)
    setNewTag('')

    onTagCreated && onTagCreated(newTag)
    onTagsChanged && onTagsChanged(newTags)
  }

  const handleEditInputChange = e => {
    setRenamedTag(e.target.value)
  }

  const handleTagRenamed = () => {
    const newTags = [...tags]
    onTagRenamed && onTagRenamed(newTags[renameTagIndex], renamedTag)

    newTags[renameTagIndex] = renamedTag

    setTags(newTags)
    setRenameTagIndex(-1)
    setRenamedTag('')

    onTagsChanged && onTagsChanged(newTags)
  }

  const saveInputRef = input => {
    setNewTagRef(input)
  }

  const saveEditInputRef = input => {
    setRenameTagRef(input)
  }

  return (
    <>
      {inputVisible && (
        <Input
          ref={saveInputRef}
          type='text'
          size='small'
          // is's not working with scss, just inline
          style={{
            width: '200px',
            verticalAlign: 'top',
            marginTop: '22px',
            height: '32px',
            display: 'flex',
            alignItems: 'center'
          }}
          className={styles.tagInput}
          value={newTag}
          onChange={handleInputChange}
          onBlur={handleTagCreated}
          onPressEnter={handleTagCreated}
        />
      )}
      {!inputVisible && (
        <Tag onClick={showInput} style={{ width: '200px', marginTop: '22px', height: '32px', display: 'flex', alignItems: 'center' }}>
          <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            {placeholder}
            <PlusCircleFilled />
          </span>
        </Tag>
      )}

      {tags.map((tag, index) => {
        if (renameTagIndex === index) {
          return (
            <Input
              ref={saveEditInputRef}
              key={tag}
              size='small'
              style={{ width: '78px', marginRight: '8px', marginTop: '10px', verticalAlign: 'top' }}
              value={renamedTag}
              onChange={handleEditInputChange}
              onBlur={handleTagRenamed}
              onPressEnter={handleTagRenamed}
            />
          )
        }

        const isLongTag = tag.length > 20

        const tagElem = (
          <Tag style={{ userSelect: 'none', marginTop: '10px' }} key={tag} closable onClose={() => handleTagRemoved(tag)}>
            <span
              onDoubleClick={e => {
                setRenameTagIndex(index)
                setRenamedTag(tag)
                e.preventDefault()
              }}
            >
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </span>
          </Tag>
        )
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        )
      })}
    </>
  )
}

export default ManageTags
