import { useQuery } from 'react-query'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { CustomerSelectOptionDto } from '@merchx-v2/shared-types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'customersForOptions', CustomerSelectOptionDto[]>

const FETCH_CUSTOMERS_OPTIONS = `
  query fetchCustomersForOptions($searchText: String) {
    customersForOptions(searchText: $searchText) {
      id
      firstName
      lastName
      email
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchCustomersOptions = (searchText?: string) => Promise<SelectOption[]>

const fetchCustomersOptions: FetchCustomersOptions = async (searchText) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_CUSTOMERS_OPTIONS,
      variables: { searchText }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { customersForOptions } = data
    if (!customersForOptions) {
      throw new Error("Can't get customers options!")
    }

    return customersForOptions.map((option) => ({
      id: option.id,
      name: `${option.firstName} ${option.lastName} (${option.email})`,
      email: option.email
    }))
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCustomersOptions = (searchText?: string) =>
  useQuery(['customersForOptions', searchText], () => fetchCustomersOptions(searchText))
