import produce from 'immer'
import * as t from './actionTypes'
import { actionTypes as nt } from 'features/navigation'
import { isSuccessAction, isErrorAction } from 'types'
import { ProductImagesState } from './types'
import createReducer from 'store/createReducer'
import { FetchProductImagesListAction, ProductImagesLoadedAction, RemoveProductImageAction } from './actions'

const initState: ProductImagesState = {
  productImages: {},
  UIState: {
    isCreateProductImage: false,
    isFetchProductImagesList: false,
    isRemoveProductImage: false
  }
}

const setProductImagesDefaults = (draft: ProductImagesState, productId: number) => {
  if (!draft.productImages[productId]) {
    draft.productImages[productId] = {}
  }
}

const reducer = createReducer(initState, {
  [t.FETCH_PRODUCT_IMAGES_LIST]: (state, action: FetchProductImagesListAction) =>
    produce(state, (draft) => {
      draft.UIState.isFetchProductImagesList = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isFetchProductImagesList = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { productId, productImages } = action.payload
        setProductImagesDefaults(draft, productId)
        draft.productImages[productId] = productImages
      }
    }),

  [t.PRODUCT_IMAGES_LOADED]: (state, action: ProductImagesLoadedAction) =>
    produce(state, (draft) => {
      if (action.payload) {
        const { productId, productImages } = action.payload
        setProductImagesDefaults(draft, productId)
        draft.productImages[productId] = productImages
      }
    }),

  [t.REMOVE_PRODUCT_IMAGE]: (state, action: RemoveProductImageAction) =>
    produce(state, (draft) => {
      draft.UIState.isRemoveProductImage = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isRemoveProductImage = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { productId, productImageId } = action.payload
        if (draft.productImages[productId]) {
          if (draft.productImages[productId][productImageId]) {
            delete draft.productImages[productId][productImageId]
          }
        }
      }
    }),

  [nt.LOCATION_CHANGE]: (state) =>
    produce(state, (draft) => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default reducer
