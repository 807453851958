import { createSelector, Selector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { ProductTagsState } from '../types'

const getFeatureState: Selector<ReduxState, ProductTagsState> = state => state.productTags

export const featureStateSelector = createSelector<ReduxState, ProductTagsState, ProductTagsState>(
  getFeatureState,
  state => state
)

export default featureStateSelector
