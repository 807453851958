import React, { useEffect } from 'react'
import { Button, Drawer, Input, Form } from 'antd'
import notification from 'mrx-notification'
import styles from './UpdateProductTag.module.scss'
import { connector, PropsFromRedux } from './container'

export type Props = {
  productTagId?: number
  visible: boolean
  onClose: () => void
}

const UpdateProductTag = (props: Props & PropsFromRedux) => {
  const { onClose, isLoading, productTagId, productTag, updateProductTag, visible } = props
  const [form] = Form.useForm()

  useEffect(() => {
    visible &&
      productTag &&
      form.setFieldsValue({
        name: productTag.name
      })
  }, [form, visible, productTag])

  if (!productTag) {
    return null
  }

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  // TODO - fix any type
  const onValuesChangeHandler = (changedValues: any) => {
    if (Object.prototype.hasOwnProperty.call(changedValues, 'type')) {
      form.setFieldsValue({
        value: ''
      })
    }
  }

  // TODO - fix any type
  const onFinishHandler = async (values: any) => {
    const isSaved = productTagId && (await updateProductTag({ productTagId, name: values.name }))
    if (isSaved) {
      notification.success({
        message: 'Successfully',
        description: 'Product Tag was updated successfully!'
      })
      handleClose()
    }
  }

  return (
    <Drawer title='Update Product Tag' width='400' onClose={handleClose} visible={visible} forceRender>
      <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form} onValuesChange={onValuesChangeHandler}>
        <Form.Item name='name' label={productTag.name} rules={[{ required: true, message: 'Please Input Product Tag Value' }]}>
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right'
          }}
        >
          <Button onClick={handleClose} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default connector(UpdateProductTag)
