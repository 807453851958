// TODO - remove becouse of legacy

import React, { useContext, useState, useEffect, useMemo, useCallback } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Drawer, DatePicker, Table } from 'antd'
import notification from 'mrx-notification'
import { GlobalContext } from 'appContexts'
import { EditableCell, EditableFormRow } from 'components'
import { connector } from './container'
import { useCampaignsOptions } from 'features/campaigns/hooks'
import { AdSpendRow } from '../../types'
import getColumns from './getColumns'
import styles from './CreateAdSpend.module.scss'

const CreateAdSpend = ({ isLoading, adSpendsRows, dateList, changeDate, saveAdSpends }) => {
  const { workspaceId } = useContext(GlobalContext)

  const campaignsForOptions = useCampaignsOptions({ workspaceId })

  const [isVisible, setVisible] = useState(false)
  const [dataSource, setDataSource] = useState([])

  const handleSave = useCallback(
    (record: AdSpendRow) => {
      const newData = [...dataSource]
      const index = newData.findIndex((item) => record.campaign.id === item.campaign.id)
      const item = newData[index]
      newData.splice(index, 1, {
        ...item,
        ...record
      })
      setDataSource(newData)
    },
    [dataSource]
  )

  const columns = useMemo(() => getColumns(handleSave), [handleSave])

  // Update the data tables after options will be loaded
  useEffect(() => {
    campaignsForOptions.data &&
      setDataSource(
        campaignsForOptions.data?.map((campaign) => {
          const filledRow = adSpendsRows?.find((row) => row.campaign.id === campaign.id + '')
          return {
            campaign,
            sumOfExpenses: filledRow?.sumOfExpenses || 0,
            numberOfClicks: filledRow?.numberOfClicks || 0
          }
        })
      )
  }, [campaignsForOptions.data, adSpendsRows])

  const showDrawer = () => {
    setVisible(!isVisible)
  }

  const handleClose = () => {
    setVisible(false)
  }

  const handleSubmit = useCallback(async () => {
    const isSaved = await saveAdSpends(
      dataSource
        .filter((item) => item.numberOfClicks > 0 || item.sumOfExpenses > 0)
        .map((item) => {
          return {
            campaignId: item.campaign.id,
            sumOfExpenses: Math.round(item.sumOfExpenses * 100),
            numberOfClicks: item.numberOfClicks
          }
        })
    )
    if (isSaved) {
      notification.success({
        message: 'Successfully',
        description: 'Ad spend was saved successfully!'
      })
      handleClose()
    }
  }, [saveAdSpends, dataSource])

  const components = {
    body: {
      row: EditableFormRow,
      cell: EditableCell
    }
  }

  return (
    <>
      <Button type='primary' onClick={showDrawer}>
        <PlusOutlined />
        {adSpendsRows?.length ? 'UPDATE' : 'ADD'}
      </Button>
      <Drawer title='Create AdSpend Cost' width='800' onClose={handleClose} visible={isVisible}>
        <DatePicker key={dateList.toString()} value={dateList} onChange={changeDate} className={styles.formItem} format='MM/DD/YYYY' />
        <Table
          components={components}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          rowKey={(record) => record.campaign.id}
        />
        <div className={styles.buttonsContainer}>
          <Button onClick={handleClose} className={styles.createButton}>
            Close
          </Button>
          <Button type='primary' onClick={handleSubmit} loading={isLoading}>
            Save
          </Button>
        </div>
      </Drawer>
    </>
  )
}

export default connector(CreateAdSpend)
