import { BuildOwner, EnvironmentType } from '@merchx-v2/shared-types'
import * as t from '../actionTypes'
import backendAxios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { SiteBuildInfo } from '../types'

type DefaultPayload = {
  ownerType: BuildOwner
  ownerId: number
  environment: EnvironmentType
  version: number
}

type Payload = DefaultPayload & {
  buildInfo: SiteBuildInfo
}

type ErrorPayload = DefaultPayload & {
  error: string
}

export type BuildPreviewAction = FSA<DefaultPayload, Payload, ErrorPayload>

type Args = {
  ownerType: BuildOwner
  ownerId: number
  environment: EnvironmentType
  version: number
}
type BuildPreview = (args: Args) => MrxThunk<BuildPreviewAction>
type QueryResponse = GraphQLResponse<'buildPreview', SiteBuildInfo>

const BUILD_PREVIEW = `
  mutation buildPreview($workspaceId: Int!, $ownerType: BuildOwnerType!, $ownerId: Int!, $environment: Environment!, $version: Int!) {
    buildPreview(workspaceId: $workspaceId, ownerType: $ownerType, ownerId: $ownerId, environment: $environment, version: $version) {
      version
      environment
      status
      progress
      artifactsUrl
      logs
    }
  }
`

const buildPreview: BuildPreview =
  ({ ownerType, ownerId, environment, version }) =>
  async (dispatch, getState) => {
    dispatch({
      type: t.BUILD_PREVIEW,
      payload: { ownerType, ownerId, environment, version },
      meta: { done: false }
    })

    try {
      const {
        workspaces: {
          activeWorkspace: { id: workspaceId }
        }
      } = getState()

      const {
        data: { data, errors }
      }: QueryResponse = await backendAxios.post('/graphql', {
        query: BUILD_PREVIEW,
        variables: { workspaceId, ownerType, ownerId, environment, version }
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { buildPreview } = data
      if (!buildPreview) {
        throw new Error("Can't get site build preview info!")
      }

      dispatch({
        type: t.BUILD_PREVIEW,
        payload: { ownerType, ownerId, environment, version, buildInfo: buildPreview },
        meta: { done: true }
      })
    } catch (err) {
      dispatch({
        type: t.BUILD_PREVIEW,
        payload: { ownerType, ownerId, environment, version, error: extractErrorInfo(err) },
        error: true
      })
    }
  }

export { buildPreview }
