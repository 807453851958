import React, { useState } from 'react'
import { Steps, Button, Card } from 'antd'
import notification from 'mrx-notification'
import styles from './AutoresponderWizard.module.scss'
import AutoresponderSettings from '../AutoresponderSettings'
import { WizardStepStatus, Landing } from 'features/landings/types'
import { isSettingValueValid } from 'utils/isSettingValueDefined'
import { Autoresponder } from '../../types'

const isAutoresponderValid = (autoresponder: Autoresponder) => {
  let isValid = true
  autoresponder.settings.forEach((setting) => {
    isValid = isValid && isSettingValueValid(setting)
  })

  return isValid
}

const getAutoresponderStatus: (autoresponder: Autoresponder, currentStep: number, index: number) => WizardStepStatus = (
  autoresponder,
  currentStep,
  index
) => {
  if (currentStep === index) {
    return 'process'
  }

  if (isAutoresponderValid(autoresponder)) {
    return 'finish'
  }

  return 'error'
}

type PropsType = {
  onNextButtonClick?: (nextStep: number) => void
  nextStep?: number
  autoresponders: Autoresponder[]
  landing: Landing
}

const AutoresponderWizard = ({ autoresponders, landing, onNextButtonClick, nextStep = 5 }: PropsType) => {
  const [currentStep, setCurrentStep] = useState(0)

  const handleChangeCurrentStep = (newStep: number) => {
    setCurrentStep(newStep)
  }

  const handleGoToNext = () => {
    if (currentStep < autoresponders.length - 1) {
      setCurrentStep(currentStep + 1)
    } else {
      let isAutorespondersValid = true
      let errorMessage = ''
      autoresponders.forEach((autoresponder) => {
        const autoresponderHasCorrectData = isAutoresponderValid(autoresponder)
        isAutorespondersValid = isAutorespondersValid && autoresponderHasCorrectData
        if (!autoresponderHasCorrectData) {
          errorMessage += `Please fill all settings on autoresponder: ${autoresponder.autoresponderType}!\n`
        }
      })

      if (!isAutoresponderValid) {
        notification.error({
          message: 'Landing wizard',
          description: errorMessage
        })
        return
      }

      if (onNextButtonClick) {
        onNextButtonClick(nextStep)
      }
    }
  }

  const steps = autoresponders.map((item, index) => {
    const title = (item.autoresponderType.charAt(0).toUpperCase() + item.autoresponderType.slice(1).toLowerCase()).replace(/_/g, ' ')
    return {
      title,
      status: getAutoresponderStatus(item, currentStep, index),
      content: <AutoresponderSettings autoresponder={item} landing={landing} />
    }
  })

  return (
    <div className={styles.autoresponderWizardContainer}>
      <div className={styles.stepsContainer}>
        <Steps current={currentStep} size='small' onChange={handleChangeCurrentStep} className={styles.steps} direction='vertical'>
          {steps.map((item) => (
            <Steps.Step key={item.title} title={item.title} status={item.status} />
          ))}
        </Steps>
      </div>
      <Card
        className={styles.card}
        bodyStyle={{ padding: 0 }}
        title={<span className={styles.cardTitle}>Autoresponders</span>}
        extra={[
          onNextButtonClick && (
            <Button key='autoresponder-wizard-next-button' type='primary' onClick={handleGoToNext}>
              NEXT
            </Button>
          )
        ]}
      >
        {steps.length && <div className={styles.content}>{steps[currentStep].content}</div>}
      </Card>
    </div>
  )
}

export default AutoresponderWizard
