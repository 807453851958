import React, { useEffect, useState } from 'react'
import { Progress } from 'antd'
import useDebounce from 'utils/debounce'
import styles from './ProgressLoader.module.scss'

type Props = {
  title?: string
  barsLength?: number
}

const ProgressLoader = ({ title, barsLength = 5 }: Props) => {
  const [progress, setProgress] = useState(0)

  const newProgress = progress >= 100 ? 0 : progress + 100 / barsLength
  const changeProgress = useDebounce(() => setProgress(newProgress), 1000)

  useEffect(() => {
    changeProgress()
  })

  return (
    <span className={title ? styles.container : ''}>
      {title && <span className={styles.title}>{title}</span>}
      <Progress percent={progress} showInfo={false} steps={barsLength} size='small' strokeColor='#52c41a' />
    </span>
  )
}

export { ProgressLoader }
