import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

const mapStateToProps = (state: ReduxState) => {
  return {
    productColors: selectors.productColorsListSelector(state),
    error: selectors.errorSelector(state),
    isLoading: selectors.isLoadingSelector(state, 'isListLoading'),
    isNeedToFetchList: selectors.isNeedToFetchListSelector(state),
    total: selectors.totalSelector(state),
    currentPage: selectors.currentPageSelector(state)
  }
}

const mapDispatchToProps = {
  changeCurrentPage: actions.changeCurrentPage,
  changePageSize: actions.changePageSize,
  fetchProductColorsList: actions.fetchProductColorsList,
  removeProductColor: actions.removeProductColor
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector>
