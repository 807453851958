import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { SiteType } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Page } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'removePage', boolean>

const REMOVE_PAGE = `
  mutation RemovePage($pageId: Int!) {
    removePage(pageId: $pageId)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type RemovePageArgs = {
  ownerType: SiteType
  ownerId: number
  pageId: number
}

type RemovePage = (args: RemovePageArgs) => Promise<RemovePageArgs>

const removePage: RemovePage = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_PAGE,
      variables: {
        pageId: args.pageId
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removePage } = data
    if (!removePage) {
      throw new Error("Can't remove page!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type RemovePageContext = { prevPage?: Page }

type RemovePageEvents = {
  onMutate: (variables: RemovePageArgs) => Promise<RemovePageContext>
  onError: (error: string, variables: RemovePageArgs, context: RemovePageContext) => void
  onSettled: (variables?: RemovePageArgs) => void
}

const removePageEvents: RemovePageEvents = {
  onMutate: async ({ pageId }) => {
    await queryClient.cancelQueries(['page', pageId])

    // Snapshot the previous value
    const prevPage = queryClient.getQueryData<Page>(['page', pageId])

    if (prevPage) {
      queryClient.removeQueries(['page', pageId])
    }

    return { prevPage }
  },
  onError: (_err, variables, context) => {
    if (context?.prevPage) {
      // Restore page on any error
      queryClient.setQueryData<Page>(['page', variables.pageId], context.prevPage)
    }
  },
  onSettled: (data: RemovePageArgs) => {
    if (data?.pageId) {
      queryClient.invalidateQueries([`${data.ownerType.toLowerCase()}`, +data.ownerId])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useRemovePage = () => useMutation(removePage, removePageEvents)
