import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { CurrencyDto, UpdateCurrencyDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'updateCurrency', boolean>

const UPDATE_CURRENCY = `
  mutation updateCurrency($currencyId: Int!, $currencyData: CurrencyInput!) {
    updateCurrency(currencyId: $currencyId, currencyData: $currencyData)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateCurrencyArgs = {
  currencyId: number
  currencyData: UpdateCurrencyDto
}

type UpdateCurrency = (args: UpdateCurrencyArgs) => Promise<UpdateCurrencyArgs>

const updateCurrency: UpdateCurrency = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_CURRENCY,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateCurrency } = data
    if (!updateCurrency) {
      throw new Error("Can't update currency!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateCurrencyContext = { prevCurrency?: CurrencyDto }

type UpdateCurrencyEvents = {
  onMutate: (variables: UpdateCurrencyArgs) => Promise<UpdateCurrencyContext>
  onError: (error: string, variables: UpdateCurrencyArgs, context: UpdateCurrencyContext) => void
  onSettled: (data?: UpdateCurrencyArgs) => void
}

const updateCurrencyEvents: UpdateCurrencyEvents = {
  onMutate: async (variables: UpdateCurrencyArgs) => {
    await queryClient.cancelQueries(['currency', variables.currencyId])

    // Snapshot the previous value
    const prevCurrency = queryClient.getQueryData<CurrencyDto>(['currency', variables.currencyId])

    // Optimistically update to the new values
    if (prevCurrency) {
      queryClient.setQueryData<CurrencyDto>(['currency', variables.currencyId], {
        ...prevCurrency,
        id: variables.currencyId,
        ...variables.currencyData
      })
    }

    return { prevCurrency }
  },
  onError: (_err, variables, context) => {
    if (context?.prevCurrency) {
      // Restore previous version of country on any error
      queryClient.setQueryData<CurrencyDto>(['currency', variables.currencyId], context.prevCurrency)
    }
  },
  onSettled: (data) => {
    if (data?.currencyId) {
      queryClient.invalidateQueries(['currency', data.currencyId])
      queryClient.invalidateQueries(['currenciesList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUpdateCurrency = () => useMutation(updateCurrency, updateCurrencyEvents)
