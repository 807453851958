import { connect, ConnectedProps } from 'react-redux'
import { SmsPlugin } from '@merchx-v2/shared-types'
import { ReduxState } from 'store/createRootReducer'
import * as simpleTextSettingsFeature from 'features/simpleTextSettings'
import * as zipwhipSettingsFeature from 'features/zipwhipSettings'

type OwnProps = {
  plugin: SmsPlugin
  initialOption: SelectOption
  disabled?: boolean
  onSelect: (selectOption: SelectOption) => void
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  let isLoading = false
  let options = []

  switch (ownProps.plugin) {
    case 'SIMPLE_TEXT': {
      isLoading = simpleTextSettingsFeature.selectors.isLoadingSelector(state, 'isSimpleTextSettingsForOptionsLoading')
      options = simpleTextSettingsFeature.selectors.simpleTextSettingsSelectOptionsSelector(state)
      break
    }
    case 'ZIPWHIP': {
      isLoading = zipwhipSettingsFeature.selectors.isLoadingSelector(state, 'isZipwhipSettingsForOptionsLoading')
      options = zipwhipSettingsFeature.selectors.zipwhipSettingsSelectOptionsSelector(state)
      break
    }
  }

  return {
    isLoading,
    options
  }
}

const mapDispatchToProps = {
  fetchSimpleTextSettingsOptions: simpleTextSettingsFeature.actions.fetchSimpleTextSettingsForOptions,
  fetchZipwhipSettingsOptions: zipwhipSettingsFeature.actions.fetchZipwhipSettingsForOptions
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
