import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { ShipstationSetting } from '../types'

type ShipstationSettingsForOptionsSelector = (state: ReduxState) => ShipstationSetting[]
const getShipstationSettingsForOptions: ShipstationSettingsForOptionsSelector = state =>
  state.shipstationSettings.shipstationSettingsForOptions || ([] as ShipstationSetting[])

export const shipstationSettingsForOptionsSelector: ShipstationSettingsForOptionsSelector = createSelector(
  getShipstationSettingsForOptions,
  shipstationSettings => shipstationSettings
)
