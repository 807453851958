import React, { useEffect } from 'react'
import { Switch, Card } from 'antd'
import notification from 'mrx-notification'
import * as settingsFeature from 'features/settings'
import { SitePreview } from 'features/sitePreviews/components'
import { useUpdatePage } from '../../hooks'
import styles from './PageSettings.module.scss'
import { Landing, PageForWizard } from 'features/landings/types'

type PropsType = {
  landing: Landing
  landingVersion?: number
  page: PageForWizard
}

const { SettingsForLanding } = settingsFeature.components

const PageSettings = (props: PropsType) => {
  const { page, landing, landingVersion } = props
  const [skipOnBuild, setSkipOnBuild] = React.useState<boolean>(page ? page.skipOnBuild : false)

  const updatePage = useUpdatePage()

  useEffect(() => {
    if (page) {
      setSkipOnBuild(page.skipOnBuild)
    }
  }, [page])

  const handleSkipOnBuildChanged = async () => {
    updatePage.mutate(
      {
        ownerType: 'LANDING',
        ownerId: landing.id,
        pageId: page.id,
        pageData: {
          name: page.name,
          route: page.route,
          order: page.order,
          sourceCode: page.sourceCode,
          sourceStyle: page.sourceStyle,
          actionsCode: page.actionsCode,
          reducerCode: page.reducerCode,
          canBeSkippedOnBuild: page.canBeSkippedOnBuild,
          skipOnBuild: !skipOnBuild
        }
      },
      {
        onSuccess: () => {
          if (updatePage.isSuccess) {
            notification.success({
              message: 'Successfully',
              description: 'Page was updated successfully!'
            })
          }
          setSkipOnBuild(!skipOnBuild)
        },
        onError: (error) => {
          notification.error({
            message: 'Error!',
            description: error.toString()
          })
        }
      }
    )
  }

  return (
    <div className={styles.root}>
      <div className={styles.frameContainer}>
        <SitePreview ownerType='LANDING' ownerId={landing.id} version={landingVersion} route={page?.route} environment='PREVIEW' />
      </div>
      <div className={styles.settingsContainer}>
        <Card className={styles.attributesCard} title={<div className={styles.attributesTitle}>Attributes</div>}>
          <div>
            <div>Skip on build</div>
            <Switch title='Skip on build' checked={skipOnBuild} onClick={handleSkipOnBuildChanged} />
          </div>
        </Card>
        <SettingsForLanding ownerType='PAGE' ownerId={page.id} selfUpdate landing={landing} />
      </div>
    </div>
  )
}

export default PageSettings
