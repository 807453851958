import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { AwsSetting } from '../types'

type AwsSettingsList = {
  items: AwsSetting[]
  total: number
}

type Payload = AwsSettingsList & {
  page: number
}

export type FetchAwsSettingsListAction = FSA<undefined, Payload, string>
type FetchAwsSettingsList = () => MrxThunk<FetchAwsSettingsListAction>
type QueryResponse = GraphQLResponse<'awsSettingsListPage', AwsSettingsList>

const FETCH_AWS_SETTINGS_LIST = `
  query fetchAwsSettingsList ($page: Int, $size: Int, $filters: AwsSettingsFilters!) {
    awsSettingsListPage(page: $page, size: $size, filters: $filters) {
      items {
        id
        name
        accessKeyId
        secretAccessKey
        region
        createdAt
        updatedAt
      }
      total
    }
  }
`

const fetchAwsSettingsList: FetchAwsSettingsList = () => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_AWS_SETTINGS_LIST,
    meta: { done: false }
  })

  try {
    const {
      workspaces: {
        activeWorkspace: { id: workspaceId }
      },
      awsSettings: {
        pagination: { currentPage: page, size }
      }
    } = getState()
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_AWS_SETTINGS_LIST,
      variables: { page, size, filters: { workspaceId } }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { awsSettingsListPage } = data
    if (!awsSettingsListPage) {
      throw new Error("Can't get Aws Settings list page!")
    }

    dispatch({
      type: t.FETCH_AWS_SETTINGS_LIST,
      payload: {
        page,
        total: awsSettingsListPage.total,
        items: awsSettingsListPage.items
      },
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_AWS_SETTINGS_LIST,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchAwsSettingsList }
