import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

export type OwnProps = {
  campaignId: number
  fullMode: boolean
  selectedRowKeys: string[]
  handleSelectChange: (keys: string[]) => void
}

const mapStateToProps = (state: ReduxState) => {
  return {
    campaignTemplatesReport: selectors.campaignTeplatesReportSelector(state),
    isLoading: selectors.isLoadingSelector(state, 'isCampaignTemplatesReportLoading')
  }
}

const mapDispatchToProps = {
  fetchCampaignTemplatesReport: actions.fetchCampaignTemplatesReport,
  updateLandingStatus: actions.updateLandingStatus
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
