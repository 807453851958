import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type CurrentPageSelector = (state: ReduxState) => number

const getCurrentPageSelector: CurrentPageSelector = state => state.addresses.pagination.currentPage

export const currentPageSelector: CurrentPageSelector = createSelector(
  getCurrentPageSelector,
  currentPage => currentPage
)
