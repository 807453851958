import { ProductPriceOwner } from '@merchx-v2/shared-types'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Product } from 'features/types'
import * as eternalListsFeature from 'features/eternalLists'
import * as t from '../actionTypes'
import { GraphQLProduct, GraphQLProductDuplicateInput } from '../types'

type Payload = {
  list: 'products'
  items: Product[]
}

type DuplicateProductArgs = {
  productId: number
  productData: GraphQLProductDuplicateInput
  productPriceOwnerType: ProductPriceOwner
  productPriceOwnerId: number
}

export type DuplicateProductAction = FSA<undefined, Payload, string>
type DuplicateProduct = (
  args: DuplicateProductArgs
) => MrxThunk<eternalListsFeature.actions.CreateEternalItemsAction<'products'>, Promise<Product | undefined>>
type QueryResponse = GraphQLResponse<'duplicateProduct', GraphQLProduct>

const DUPLICATE_PRODUCT = `
  mutation DuplicateProduct($productId: Int!, $productData: ProductDuplicateInput!, $productPriceOwnerType: ProductPriceOwner!, $productPriceOwnerId: Int!) {
    duplicateProduct(productId: $productId, productData: $productData, productPriceOwnerType: $productPriceOwnerType, productPriceOwnerId: $productPriceOwnerId) {
      id
      campaignId
      campaign {
        name
      }
      productType
      name
      displayName
      description
      sku
      image
      images {
        id
        workspaceId
        name
        extension
        productId
        imageUrl
        s3bucket
        s3key
        createdAt
        updatedAt
      }
      assets {
        id
        name
        extension
        ownerType
        ownerId
        mimeType
        s3bucket
        s3key
        signedUrl
        createdAt
        updatedAt
      }
      ownerId
      ownerType
      owner {
        id
        firstName
        lastName
        email
        createdFromLandingUrl
        createdAt
        updatedAt
      }
      hasCustomShipping
      customShippingPrice
      useReplacementForStock
      stockProductId
      stockProductName
      sellWhenOutOfStock
      productType2
      customizable
      isActive
      sortIndex
      createdAt
      updatedAt
    }
  }
`

const duplicateProduct: DuplicateProduct =
  ({ productId, productData, productPriceOwnerType, productPriceOwnerId }) =>
  async (dispatch) => {
    let isSaved

    dispatch({
      type: t.DUPLICATE_PRODUCT,
      meta: { done: false }
    })

    try {
      const {
        data: { data, errors }
      }: QueryResponse = await axios.post('/graphql', {
        query: DUPLICATE_PRODUCT,
        variables: { productId, productData, productPriceOwnerType, productPriceOwnerId }
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { duplicateProduct } = data
      if (!duplicateProduct) {
        throw new Error("Can't create product!")
      }

      dispatch({
        type: t.DUPLICATE_PRODUCT,
        payload: { list: 'products', items: [duplicateProduct] },
        meta: { done: true }
      })
      dispatch({
        type: eternalListsFeature.actionTypes.CREATE_ETERNAL_LIST_ITEMS,
        payload: { list: 'products', items: [duplicateProduct] },
        meta: { done: true }
      })
      isSaved = duplicateProduct
    } catch (err) {
      dispatch({
        type: t.DUPLICATE_PRODUCT,
        payload: extractErrorInfo(err) as string,
        error: true
      })
    }

    return isSaved
  }

export { duplicateProduct }
