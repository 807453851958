import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'
import { Country } from 'features/countries/types'

type OwnProps = {
  country?: Country
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  return {
    regionsList: selectors.regionsListSelector(state, ownProps.country.id),
    error: selectors.errorSelector(state),
    isLoading: selectors.isLoadingSelector(state, 'isListLoading'),
    currentPage: selectors.currentPageSelector(state, ownProps.country.id),
    total: selectors.totalRegionsSelector(state, ownProps.country.id),
    isNeedToFetchListSelector: selectors.isNeedToFetchListSelector(state, ownProps.country.id)
  }
}

const mapDispatchToProps = {
  fetchRegionsList: actions.fetchRegionsList,
  changeCurrentPage: actions.changeCurrentPage,
  removeRegion: actions.removeRegion
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector>
