import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

const FETCH_OVERVIEW_CSV_REPORT = `
query OverviewReportExportToCSV($filters: OverviewReportFilters!) {
  overviewReportExportToCSV(filters: $filters) 
}
`

const fetchOverviewCsvReport = (startDate, endDate) => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_OVERVIEW_CSV_REPORT
  })

  try {
    const {
      workspaces: {
        activeWorkspace: { id: workspaceId }
      }
    } = getState()

    const {
      data: { data, errors }
    } = await axios.post('/graphql', {
      query: FETCH_OVERVIEW_CSV_REPORT,
      variables: {
        filters: { startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD'), workspaceId }
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { overviewReportExportToCSV } = data
    if (!overviewReportExportToCSV) {
      throw new Error("Can't fetch overview report CSV file url!")
    }

    dispatch({
      type: t.FETCH_OVERVIEW_CSV_REPORT,
      meta: { done: true }
    })

    return overviewReportExportToCSV
  } catch (err) {
    dispatch({
      type: t.FETCH_OVERVIEW_CSV_REPORT,
      payload: extractErrorInfo(err),
      meta: { error: true }
    })
  }
}

export default fetchOverviewCsvReport
