import axios from 'utils/axios'
import { useQuery } from 'react-query'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { CustomerSubscription } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'customerSubscription', CustomerSubscription>

// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchCustomerSubscription = (customerSubscriptionId: number) => Promise<CustomerSubscription>

const fetchCustomerSubscription: FetchCustomerSubscription = async (customerSubscriptionId) => {
  try {
    const FETCH_CUSTOMER_SUBSCRIPTION = `
      query fetchCustomerSubscription ($customerSubscriptionId: Int!) {
        customerSubscription(customerSubscriptionId: $customerSubscriptionId) {
          id
          landingId
          landing {
            campaign {
              id
              name
            }
            paymentPlugin
            fulfillmentPlugin
            siteUrl
            shippingCost
            hasCustomShipping
            applyShippingOnce
            applyProcessingOnce
            processingCost
          }
          customerId
          customer {
            id
            email
            firstName
            lastName
            createdAt
            updatedAt
          }
          customerSubscriptionEvents {
            id
            event
            createdAt
          }
          customerSubscriptionNotes {
            id
            note
            workspaceId
            userId
            author {
              id
              email
              avatar
              firstName
              lastName
              createdAt
            }
            createdAt
          }
          customerSubscriptionTasks {
            id
            status
            taskType
            payload
            progress
            retryNumber
            message
            startDate
            startedAt
            endedAt
          }
          saleSource
          billingAddressId
          billingAddress {
            id
            firstName
            lastName
            countryId
            country {
              id
              name
              iso2
              iso3
              worldPart
              code
              currencyId
              currency {
                id
                name
                code
                symbol
              }
            }
            zipCode
            state
            city
            address
            address2
          }
          shippingAddressId
          shippingAddress {
            id
            firstName
            lastName
            countryId
            country {
              id
              name
              iso2
              iso3
              worldPart
              code
              currencyId
              currency {
                id
                name
                code
                symbol
              }
            }
            zipCode
            state
            city
            address
            address2
          }
          orders {
            id
            status
            total
            subscriptionCycleNumber
            createdAt
            bin
            cardNumber
            cardExpDate
            campaignName
            saleSource
          }
          status
          campaignName
          total
          cycle
          paymentSubscriptionId
          interval
          repeatFrequency
          creationDate
          startDate
          paidTill
          comment
          createdAt
          updatedAt
          canceledAt
          canceledBy
          cancelReason
        }
      }
    `

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_CUSTOMER_SUBSCRIPTION,
      variables: { customerSubscriptionId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { customerSubscription } = data
    if (!customerSubscription) {
      throw new Error("Can't get customer subscription!")
    }

    return customerSubscription
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCustomerSubscription = (customerSubscriptionId: number) =>
  useQuery(['customerSubscription', customerSubscriptionId], () => fetchCustomerSubscription(customerSubscriptionId))
