import React, { useState, useEffect, useRef } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Drawer, Input, Form } from 'antd'
import notification from 'mrx-notification'
import styles from './CreateSimpleTextSetting.module.scss'
import { PropsFromRedux, connector } from './container'

const CreateSimpleTextSetting = (props: PropsFromRedux) => {
  const [isVisible, setVisible] = useState(false)
  const refEl = useRef<Input>(null)

  const { isLoading, createSimpleTextSetting } = props

  useEffect(() => {
    if (isVisible) {
      refEl.current && refEl.current.focus()
    }
  }, [isVisible])

  const showDrawer = () => {
    setVisible(!isVisible)
  }

  const [form] = Form.useForm()

  const handleClose = () => {
    setVisible(false)
    form.resetFields()
  }

  const onFinishHandler = async (values: any) => {
    const isSaved = await createSimpleTextSetting({
      name: values.name,
      apiToken: values.apiToken
    })
    if (isSaved) {
      notification.success({
        message: 'Successfully',
        description: 'Simple Text Setting was created successfully!'
      })
      handleClose()
    }
  }

  return (
    <>
      <Button type='primary' onClick={showDrawer}>
        <PlusOutlined />
        CREATE
      </Button>
      <Drawer title='Create SimpleText Setting' width='400' onClose={handleClose} visible={isVisible} forceRender>
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
          <Form.Item
            name='name'
            label='Name'
            rules={[{ required: true, message: 'Please input Simple Text setting name!' }]}
          >
            <Input ref={refEl} />
          </Form.Item>
          <Form.Item
            name='apiToken'
            label='API Token'
            rules={[{ required: true, message: 'Please input Simple Text setting API Token!' }]}
          >
            <Input />
          </Form.Item>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button onClick={handleClose} className={styles.createButton}>
              Close
            </Button>
            <Button type='primary' htmlType='submit' loading={isLoading}>
              Create
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

export default connector(CreateSimpleTextSetting)
