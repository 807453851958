import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

type Payload = {
  productId: number
  productAttributeId: number
  productAttributeData: ProductAttributeInput
}

export type UpdateProductAttributeAction = FSA<undefined, Payload, string>

type UpdateProductAttribute = (args: Payload) => MrxThunk<UpdateProductAttributeAction, Promise<boolean>>

type QueryResponse = GraphQLResponse<'updateProductAttribute', boolean>

const UPDATE_PRODUCT_ATTRIBUTE = `
  mutation updateProductAttribute($productAttributeId: Int!, $productAttributeData: ProductAttributeDataInput!) {
    updateProductAttribute(productAttributeId: $productAttributeId, productAttributeData: $productAttributeData)
  }
`

type ProductAttributeInput = {
  attribute: string
  value: string
}

const updateProductAttribute: UpdateProductAttribute = (args) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.UPDATE_PRODUCT_ATTRIBUTE,
    meta: { done: false }
  })

  try {
    const { productAttributeId, productAttributeData } = args
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_PRODUCT_ATTRIBUTE,
      variables: { productAttributeId, productAttributeData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateProductAttribute } = data
    if (!updateProductAttribute) {
      throw new Error("Can't update Product Attribute!")
    }

    dispatch({
      type: t.UPDATE_PRODUCT_ATTRIBUTE,
      payload: args,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.UPDATE_PRODUCT_ATTRIBUTE,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { updateProductAttribute }
