import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Button, Drawer, Input, Form } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { CreateDomainDto } from '@merchx-v2/shared-types'
import notification from 'mrx-notification'
import { workspaceSelector } from 'features/workspaces/selectors'

import { useCreateDomain } from '../../hooks'
import styles from './CreateDomain.module.scss'

const CreateDomain = ({ workspace }) => {
  const [isVisible, setVisible] = useState(false)
  const refEl = useRef<Input>(null)

  const createDomain = useCreateDomain()

  useEffect(() => {
    if (isVisible) {
      refEl.current && refEl.current.focus()
    }
  }, [isVisible])

  const showDrawer = () => {
    setVisible(!isVisible)
  }

  const [form] = Form.useForm()

  const handleClose = () => {
    setVisible(false)
    form.resetFields()
  }

  useEffect(() => {
    if (createDomain.error) {
      notification.error({
        message: 'Create domain error!',
        description: createDomain.error instanceof Error ? createDomain.error.message : createDomain.error.toString()
      })
    }
  }, [createDomain.error])

  useEffect(() => {
    if (createDomain.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Domain was created successfully!'
      })
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createDomain.isSuccess])

  const onFinishHandler = (values: CreateDomainDto) => {
    createDomain.mutate({ workspaceId: workspace.id, domainData: values })
  }

  return (
    <>
      <Button type='primary' onClick={showDrawer}>
        <PlusOutlined />
        CREATE DOMAIN
      </Button>
      <Drawer title='Create Domain' width='400' onClose={handleClose} visible={isVisible} forceRender>
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
          <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input domain name!' }]}>
            <Input style={{ width: '100%' }} ref={refEl} />
          </Form.Item>

          <Form.Item name='registrar' label='Registrar' rules={[{ required: true, message: 'Please input domain registrar!' }]}>
            <Input style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name='registrarLogin'
            label='Registrar Login'
            rules={[{ required: true, message: 'Please input domain registrar login!' }]}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name='registrarPassword'
            label='Registrar Password'
            rules={[{ required: true, message: 'Please input domain registrar password!' }]}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>

          <div className={styles.buttonsContainer}>
            <Button onClick={handleClose} className={styles.createButton}>
              Close
            </Button>
            <Button type='primary' htmlType='submit' loading={createDomain.isLoading}>
              Create
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

const mapStateToProps = (state) => ({
  workspace: workspaceSelector(state)
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CreateDomain)
