import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

export type RemoveProductSizeAction = FSA<undefined, number, string>
type RemoveProductSize = (productSizeId: number) => MrxThunk<RemoveProductSizeAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'removeProductSize', boolean>

const REMOVE_PRODUCT_SIZE = `
  mutation removeProductSize ($productSizeId: Int!) {
    removeProductSize(productSizeId: $productSizeId) 
  }
`

const removeProductSize: RemoveProductSize = (productSizeId) => async (dispatch) => {
  let isRemoved = false

  dispatch({
    type: t.REMOVE_PRODUCT_SIZE,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_PRODUCT_SIZE,
      variables: { productSizeId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Empty response!')
    }

    const { removeProductSize } = data
    if (!removeProductSize) {
      throw new Error("Can't remove product size!")
    }

    dispatch({
      type: t.REMOVE_PRODUCT_SIZE,
      payload: productSizeId,
      meta: { done: true }
    })

    isRemoved = true
  } catch (err) {
    dispatch({
      type: t.REMOVE_PRODUCT_SIZE,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isRemoved
}

export { removeProductSize }
