import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query'
import hash from 'object-hash'
import { CustomerDto } from '@merchx-v2/shared-types'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

const DEFAULT_PAGE_SIZE = 30

export type StoreUsersPage = {
  items: CustomerDto[]
  total: number
  currentPage: number
  pageSize: number
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'storeUsersPage', StoreUsersPage>

const FETCH_STORE_USERS_LIST = `
  query fetchStoreUsersPage ($page: Int, $size: Int, $storeId: Int!) {
    storeUsersPage(page: $page, size: $size, storeId: $storeId) {
      items {
        id
        email
        firstName
        lastName
      }
      total
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type PageParam = {
  page?: number
  size?: number
  storeId: number
}

type FetchStoresUsersListArgs = {
  queryKey: string | string[]
  pageParam?: PageParam
}

type FetchStoresPage = (args: FetchStoresUsersListArgs) => Promise<StoreUsersPage>

const fetchStoreUsersPage: FetchStoresPage = async ({ pageParam }) => {
  const { page = 1, size = DEFAULT_PAGE_SIZE, storeId } = pageParam || {}
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_STORE_USERS_LIST,
      variables: { page, size, storeId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { storeUsersPage } = data
    if (!storeUsersPage) {
      throw new Error("Can't get store invites page!")
    }

    return {
      items: storeUsersPage.items,
      total: storeUsersPage.total,
      currentPage: page,
      pageSize: size
    }
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

const queryOptions: UseInfiniteQueryOptions<StoreUsersPage> = {
  keepPreviousData: true,
  refetchOnMount: true,
  retry: false,

  getPreviousPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0 }) => {
    if (currentPage > 1) {
      return {
        page: currentPage - 1,
        size: pageSize
      }
    }

    return undefined
  },
  getNextPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, total = 0 }) => {
    if (pageSize * currentPage < total) {
      return {
        page: currentPage + 1,
        size: pageSize
      }
    }

    return undefined
  }
}

type Args = {
  storeId: number
}

export const useStoreUsersList = (args: Args) => {
  const cacheKey = ['storeUsersPage', hash(args)]

  return useInfiniteQuery(
    cacheKey,
    (prev) =>
      fetchStoreUsersPage({
        queryKey: cacheKey,
        pageParam: { ...prev.pageParam, ...args }
      }),
    queryOptions
  )
}
