import { CustomerTagType } from '@merchx-v2/shared-types'
import { useQuery } from 'react-query'
import axios from 'utils/axios'
import { guardFromErrors } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'customersListCsv', string>

const FETCH_CUSTOMERS_LIST_CSV = `
  query fetchCustomersListCsv ($filters: CustomerCsvFilters!) {
    customersListCsv(filters: $filters)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchCustomersListCsvFilters = {
  workspaceId: number
  startDate?: Date
  endDate?: Date
  emailContains?: string
  phoneContains?: string
  emailStatuses?: CustomerEmailStatusEnum[]
  tags?: CustomerTagType[]
}

type FetchCustomersList = (filters: FetchCustomersListCsvFilters) => Promise<string>

const fetchCustomersListCsv: FetchCustomersList = async (filters) => {
  const {
    data: { data, errors }
  }: QueryResponse = await axios.post('/graphql', {
    query: FETCH_CUSTOMERS_LIST_CSV,
    variables: { filters }
  })

  guardFromErrors(errors)

  if (!data) {
    throw new Error('Response body is empty!')
  }

  const { customersListCsv } = data
  if (!customersListCsv) {
    throw new Error("Can't get customers list csv!")
  }

  return customersListCsv
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCustomersListCsv = (filters: FetchCustomersListCsvFilters) =>
  useQuery<string, Error, string>(['customersListCsv', filters], () => fetchCustomersListCsv(filters), { enabled: false, retry: 0 })
