import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { CreateShippingRuleCriteriaDto, ShippingRuleCriteriaDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'createShippingRuleCriteria', ShippingRuleCriteriaDto>

const CREATE_SHIPPING_RULE_CRITERIA = `
  mutation createShippingRuleCriteria ($shippingRuleCriteriaData: ShippingRuleCriteriaInput!) {
    createShippingRuleCriteria(shippingRuleCriteriaData: $shippingRuleCriteriaData) {
      id
      shippingRuleActionId
      option
      operator
      result
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type CreateShippingRuleCriteria = (shippingRuleCriteriaData: CreateShippingRuleCriteriaDto) => Promise<ShippingRuleCriteriaDto>

const createShippingRuleCriteria: CreateShippingRuleCriteria = async shippingRuleCriteriaData => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_SHIPPING_RULE_CRITERIA,
      variables: { shippingRuleCriteriaData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createShippingRuleCriteria } = data
    if (!createShippingRuleCriteria) {
      throw new Error("Can't create shippingRuleCriteria!")
    }

    return createShippingRuleCriteria
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCreateShippingRuleCriteria = () => useMutation(createShippingRuleCriteria)
