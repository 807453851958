import React, { useEffect, useState } from 'react'
import { Table, Popconfirm, Card } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import notification from 'mrx-notification'
import styles from './ProductAttributesList.module.scss'
import { connector, PropsFromRedux } from './container'
import CreateProductAttribute from '../CreateProductAttribute'
import UpdateProductAttribute from '../UpdateProductAttribute'
import { ProductAttribute } from '../../types'

type ProductAtributesListProps = PropsFromRedux & {
  productId: number
}

const ProductAttributesList = (props: ProductAtributesListProps) => {
  const {
    productAttributesList,
    isLoading,
    error,
    productId,

    fetchProductAttributesList,
    removeProductAttribute
  } = props

  const [selectedProductAttributeId, setSelectedProductAttributeId] = useState<number>()
  const [isUpdateDrawerVisible, setIsUpdateDrawerVisible] = useState(false)

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Product attributes list page error!',
        description: error
      })
    }
  }, [error])

  useEffect(() => {
    fetchProductAttributesList(productId)
  }, [fetchProductAttributesList, productId])

  const doConfirm = async (productAttributeId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    const isRemoved = await removeProductAttribute(productId, productAttributeId)
    if (isRemoved) {
      notification.success({
        message: 'Successfully',
        description: 'Product Attribute was deleted successfully!'
      })
    }
  }

  const columns: ColumnsType<ProductAttribute> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Attribute',
      dataIndex: 'attribute',
      key: 'attribute'
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value'
    },
    {
      title: 'Action',
      key: 'x',
      render: (_value, record) => (
        <span>
          <Popconfirm
            title='Are you sure delete this attribute?'
            onConfirm={(e) => doConfirm(record.id, e)}
            onCancel={(e) => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <a
              href='/#'
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              Remove
            </a>
          </Popconfirm>
        </span>
      )
    }
  ]

  const handleOnRow = (record: ProductAttribute) => {
    return {
      onClick: () => {
        setSelectedProductAttributeId(record.id)
        setIsUpdateDrawerVisible(true)
      }
    }
  }

  const handleOnClose = () => {
    setIsUpdateDrawerVisible(false)
  }

  return (
    <div className={styles.tableContainer}>
      <Card title={<span className={styles.cardTitle}>Product Attributes</span>} extra={<CreateProductAttribute productId={productId} />}>
        {selectedProductAttributeId && (
          <UpdateProductAttribute
            productId={productId}
            productAttributeId={selectedProductAttributeId}
            onClose={handleOnClose}
            visible={isUpdateDrawerVisible}
          />
        )}
        <Table
          columns={columns}
          dataSource={productAttributesList}
          bordered
          rowKey={(record) => record.id}
          onRow={handleOnRow}
          loading={isLoading}
          pagination={false}
        />
      </Card>
    </div>
  )
}

export default connector(ProductAttributesList)
