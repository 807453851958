import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Order } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'orderCancel', Order>

const CANCEL_ORDER = `
  mutation CancelOrder ($orderId: Int!, $shouldVoid: Boolean!) {
    orderCancel(orderId: $orderId, shouldVoid: $shouldVoid) {
      id
      status
      payments {
        id
        orderId
        systemType
        paymentMethod
        externalTransactionId
        status
        reason
        total
      }
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type Args = {
  orderId: number
  shouldVoid: boolean
}
type CancelOrder = (args: Args) => Promise<Order>

const cancelOrder: CancelOrder = async ({ orderId, shouldVoid }) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CANCEL_ORDER,
      variables: {
        orderId,
        shouldVoid
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { orderCancel } = data
    if (!orderCancel) {
      throw new Error("Can't cancel order!")
    }

    return orderCancel
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type CancelOrderContext = { prevOrder?: Order }

type CancelOrderEvents = {
  onMutate: (args: Args) => Promise<CancelOrderContext>
  onError: (error: string, args: Args, context: CancelOrderContext) => void
  onSettled: (order?: Order) => void
}

const cancelOrderEvents: CancelOrderEvents = {
  onMutate: async ({ orderId }) => {
    await queryClient.cancelQueries(['order', orderId])

    // Snapshot the previous value
    const prevOrder = queryClient.getQueryData<Order>(['order', orderId])
    return { prevOrder }
  },
  onError: (_err, { orderId }, context) => {
    if (context?.prevOrder) {
      // Restore order on any error
      queryClient.setQueryData<Order>(['order', orderId], context.prevOrder)
    }
  },
  onSettled: (order: Order) => {
    if (order?.id) {
      queryClient.invalidateQueries(['order', order.id])
      queryClient.invalidateQueries(['ordersList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCancelOrder = () => useMutation(cancelOrder, cancelOrderEvents)
