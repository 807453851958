import React, { useEffect } from 'react'
import { Modal, Form, Input, Select } from 'antd'
import notification from 'mrx-notification'
import { useIncreaseBalance } from 'features/stores/hooks'
import { STORE_PROFIT_EVENTS } from '@merchx-v2/shared-types'

type Props = {
  storeId: number
  visibleModal: boolean
  onCancel: () => void
}

const RefundPaymentModal = (props: Props) => {
  const { storeId, visibleModal, onCancel } = props
  const [form] = Form.useForm()

  const increaseBalance = useIncreaseBalance()

  useEffect(() => {
    if (increaseBalance.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Store Balance was increased successfully!'
      })
      onCancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [increaseBalance.isSuccess])

  useEffect(() => {
    if (increaseBalance.error) {
      notification.error({
        message: 'Error',
        description: increaseBalance.error.toString()
      })
    }
  }, [increaseBalance.error])

  const handleOk = async () => {
    const { note, sum, event, payload } = await form.validateFields()

    increaseBalance.mutate({
      storeId,
      sum: Math.round(parseFloat(sum) * 100),
      note,
      event,
      payload
    })
  }

  return (
    <Modal
      visible={visibleModal}
      title='Increase Balance'
      onOk={handleOk}
      okButtonProps={{
        disabled: increaseBalance.isLoading,
        loading: increaseBalance.isLoading
      }}
      onCancel={onCancel}
    >
      <Form layout='vertical' form={form} hideRequiredMark>
        <Form.Item name='sum' label='Amount' rules={[{ required: true, message: 'Please input Amount!' }]}>
          <Input type='number' />
        </Form.Item>
        <Form.Item name='note' label='Comment' rules={[{ required: true, message: 'Please input Commentary!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name='event' label='Event' rules={[{ required: true, message: 'Please select Event type!' }]}>
          <Select>
            <Select.Option value={STORE_PROFIT_EVENTS.ORDER_PAID}>Order Paid</Select.Option>
            <Select.Option value={STORE_PROFIT_EVENTS.MANUAL_INCREASED}>Manual Increased</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name='payload' label='Payload (Change it only if you know what to do)'>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default RefundPaymentModal
