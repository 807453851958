import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { SettingOwner } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Setting, SettingDataInput } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'updateSettingValue', boolean>

const UPDATE_SETTING_VALUE = `
  mutation updateSettingValue($settingId: Int!, $settingData: SettingInput!) {
    updateSettingValue(settingId: $settingId, settingData: $settingData) {
      id
      ownerType
      ownerId
      value
      jsonValue
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateSettingValueArgs = {
  ownerType: SettingOwner
  ownerId: number
  settingId: number
  settingData: SettingDataInput
}

type UpdateSettingValue = (args: UpdateSettingValueArgs) => Promise<UpdateSettingValueArgs>

const updateSettingValue: UpdateSettingValue = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_SETTING_VALUE,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateSettingValue } = data
    if (!updateSettingValue) {
      throw new Error("Can't update setting value!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateSettingValueContext = { prevSetting?: Setting }

type UpdateSettingValueEvents = {
  onMutate: (variables: UpdateSettingValueArgs) => Promise<UpdateSettingValueContext>
  onError: (error: string, variables: UpdateSettingValueArgs, context: UpdateSettingValueContext) => void
  onSettled: (data?: UpdateSettingValueArgs) => void
}

const updateSettingValueEvents: UpdateSettingValueEvents = {
  onMutate: async (variables: UpdateSettingValueArgs) => {
    await queryClient.cancelQueries(['setting', variables.settingId])

    // Snapshot the previous value
    const prevSetting = queryClient.getQueryData<Setting>(['setting', variables.settingId])

    // Optimistically update to the new values
    if (prevSetting) {
      queryClient.setQueryData<Setting>(['setting', variables.settingId], {
        ...prevSetting,
        id: variables.settingId,
        ...variables.settingData
      })
    }

    return { prevSetting }
  },
  onError: (_err, variables, context) => {
    if (context?.prevSetting) {
      // Restore previous version of setting on any error
      queryClient.setQueryData<Setting>(['setting', variables.settingId], context.prevSetting)
    }
  },
  onSettled: (data) => {
    if (data?.settingId) {
      queryClient.invalidateQueries(['template'])
      queryClient.invalidateQueries(['landing'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUpdateSettingValue = () => useMutation(updateSettingValue, updateSettingValueEvents)
