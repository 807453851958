import * as t from '../actionTypes'
import axios from 'utils/axios'
import { CustomGatewaySettingInput } from '../types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

type Payload = {
  customGatewaySettingId: number
  customGatewaySettingData: CustomGatewaySettingInput
}

export type UpdateCustomGatewaySettingAction = FSA<undefined, Payload, string>

type UpdateCustomGatewaySetting = (args: Payload) => MrxThunk<UpdateCustomGatewaySettingAction, Promise<boolean>>

type QueryResponse = GraphQLResponse<'updateCustomGatewaySetting', boolean>

const UPDATE_CUSTOM_GATEWAY_SETTING = `
  mutation updateCustomGatewaySetting ($customGatewaySettingId: Int!, $customGatewaySettingData: CustomGatewaySettingInput!) {
    updateCustomGatewaySetting (customGatewaySettingId: $customGatewaySettingId, customGatewaySettingData: $customGatewaySettingData)
  }
`

const updateCustomGatewaySetting: UpdateCustomGatewaySetting = (args) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.UPDATE_CUSTOM_GATEWAY_SETTING,
    meta: { done: false }
  })

  try {
    const { customGatewaySettingId, customGatewaySettingData } = args
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_CUSTOM_GATEWAY_SETTING,
      variables: { customGatewaySettingId, customGatewaySettingData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateCustomGatewaySetting } = data
    if (!updateCustomGatewaySetting) {
      throw new Error("Can't update Custom Gateway setting!")
    }

    dispatch({
      type: t.UPDATE_CUSTOM_GATEWAY_SETTING,
      payload: args,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.UPDATE_CUSTOM_GATEWAY_SETTING,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { updateCustomGatewaySetting }
