import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type Pagination = {
  pages: Record<number, number[]>
  total: number
  currentPage: number
  size: number
}

type PaginationSelector = (state: ReduxState, campaignId: number) => Pagination
const getPagination: PaginationSelector = (state, campaignId) => {
  let result = {
    pages: {},
    total: 0,
    currentPage: 1,
    size: 6
  }

  if (Object.prototype.hasOwnProperty.call(state.products.pagination, campaignId)) {
    result = state.products.pagination[campaignId]
  }

  return result
}

export const paginationSelector: PaginationSelector = createSelector(getPagination, (pagination) => pagination)
