import React, { useState, useEffect } from 'react'
import useReactRouter from 'use-react-router'
import { Table, Popconfirm, Layout, PageHeader } from 'antd'
import notification from 'mrx-notification'
import { ColumnsType } from 'antd/lib/table'
import { Breadcrumbs } from 'components'
import CreateProductTag from '../CreateProductTag'
import UpdateProductTag from '../UpdateProductTag'
import * as rootStyles from 'assets/layoutStyle'
import styles from './ProductTagsList.module.scss'
import { connector, PropsFromRedux } from './container'
import { ProductTag } from '../../types'

const ProductTagsList = (props: PropsFromRedux) => {
  const {
    productTags,
    removeProductTag,
    changeCurrentPage,
    isLoading,
    total,
    currentPage,
    isNeedToFetchList,
    fetchProductTagsList,
    error
  } = props

  const [selectedProductTagId, setSelectedProductTagId] = useState<number>()
  const [isUpdateVisible, setIsUpdateVisible] = useState<boolean>(false)
  const { history } = useReactRouter()

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Product Tags List page error!',
        description: error
      })
    }
  }, [error])

  useEffect(() => {
    isNeedToFetchList && fetchProductTagsList()
  }, [fetchProductTagsList, isNeedToFetchList])

  const doConfirm = async (productTagId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    const isRemoved = await removeProductTag(productTagId)
    if (isRemoved) {
      notification.success({
        message: 'Successfully',
        description: 'Product Tag was deleted successfully!'
      })
    }
  }

  const handleOnRow = (record: ProductTag) => {
    return {
      onClick: () => {
        setSelectedProductTagId(record.id)
        setIsUpdateVisible(true)
      }
    }
  }

  const handleOnProductTagClose = () => {
    setIsUpdateVisible(false)
  }

  const columns: ColumnsType<ProductTag> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (record: ProductTag) => (
        <span>
          <Popconfirm
            title='Are you sure delete this tag?'
            onConfirm={(e) => doConfirm(record.id, e)}
            onCancel={(e) => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <a
              href='/#'
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              Remove
            </a>
          </Popconfirm>
        </span>
      )
    }
  ]

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader onBack={() => history.goBack()} title='Product Tags' extra={[<CreateProductTag key='1' />]} />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Catalogs', url: '/' },
            { title: 'Tags', url: '/' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <UpdateProductTag productTagId={selectedProductTagId} onClose={handleOnProductTagClose} visible={isUpdateVisible} />
        <Table
          columns={columns}
          dataSource={productTags}
          bordered
          onRow={handleOnRow}
          rowKey={(record) => record.id}
          loading={isLoading}
          pagination={{
            onChange: changeCurrentPage,
            current: currentPage,
            total
          }}
        />
      </Layout.Content>
    </Layout>
  )
}

export default connector(ProductTagsList)
