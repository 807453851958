import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { queryClient } from 'queryClient'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'releaseInvoice', boolean>

const RELEASE_INVOICE = `
  mutation ReleaseInvoice($orderId: Int!) {
    releaseInvoice(orderId: $orderId)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type ReleaseInvoiceArgs = {
  orderId: number
}

type ReleaseInvoice = (args: ReleaseInvoiceArgs) => Promise<ReleaseInvoiceArgs>

const releaseInvoice: ReleaseInvoice = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: RELEASE_INVOICE,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { releaseInvoice } = data
    if (!releaseInvoice) {
      throw new Error("Can't release invoice!")
    }

    return args
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type ReleasePaymentEvents = {
  onSettled: (data?: ReleaseInvoiceArgs) => void
}

const releasePaymentEvents: ReleasePaymentEvents = {
  onSettled: (data) => {
    if (data?.orderId) {
      queryClient.invalidateQueries(['order', data.orderId])
      queryClient.invalidateQueries(['ordersList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useReleaseInvoice = () => useMutation(releaseInvoice, releasePaymentEvents)
