import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { StoreDto } from '@merchx-v2/shared-types'
import { EditTwoTone } from '@ant-design/icons'
import styles from './StoresList.module.scss'
import settings from 'config/settings'

type Props = {
  onEdit?: (storeId: number) => void
}

export const createTableColumns = ({ onEdit }: Props): ColumnsType<StoreDto> => {
  const handleEditClick: React.MouseEventHandler<HTMLElement> = (event) => {
    event.stopPropagation()
    const id = Number(event.currentTarget.dataset.id)
    onEdit && onEdit(id)
  }

  return [
    {
      title: 'ID',
      dataIndex: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Owner',
      dataIndex: 'ownerName'
    },
    {
      title: 'URL',
      render: (_value, record) => (
        <a href={`${settings.shopUrl}/${record.seoUrl}`} target='_blank' rel='noopener noreferrer'>
          {record.seoUrl}
        </a>
      ),
      key: 'seoUrl'
    },
    {
      title: '# of Products',
      dataIndex: 'numberOfProducts',
      width: '120px',
      align: 'right'
    },
    {
      title: 'Edit',
      key: 'edit',
      width: '20px',
      align: 'center',
      render: (_value, record) => <EditTwoTone onClick={handleEditClick} data-id={record.id} className={styles.editIcon} />
    }
  ]
}
