import React, { useEffect, useMemo } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Table } from 'antd'
import notification from 'mrx-notification'
import { createTableColumns } from './createTableColumns'
import { useStoreInvitesList, useMarkInviteToStoreAsAccepted } from '../../hooks'
import InviteStoreAdmin from '../InviteStoreAdmin'
import styles from './StoreInvitesList.module.scss'

type Props = {
  storeId: number
}

const StoreInvitesList: React.FC<Props> = ({ storeId }) => {
  const storeInvitesList = useStoreInvitesList({ storeId })
  const markInviteToStoreAsAccepted = useMarkInviteToStoreAsAccepted()

  const invitesList = useMemo(() => storeInvitesList.data?.pages.map((page) => page.items).flat() || [], [storeInvitesList.dataUpdatedAt])
  useEffect(() => storeInvitesList.remove, [])

  useEffect(() => {
    if (storeInvitesList.error) {
      notification.error({
        message: 'Stores error!',
        description: storeInvitesList.error instanceof Error ? storeInvitesList.error.message : storeInvitesList.error.toString()
      })
    }
  }, [storeInvitesList.error])

  const markAsAcceptedHandle = (email: string) => {
    markInviteToStoreAsAccepted.mutate({
      storeId,
      email
    })
  }

  const page = storeInvitesList.data?.pages.at(-1).currentPage || 1
  const size = storeInvitesList.data?.pages.at(-1).pageSize || 30
  const columns = useMemo(() => createTableColumns(markAsAcceptedHandle), [])
  return (
    <div className={styles.container}>
      <InviteStoreAdmin storeId={storeId} />
      <InfiniteScroll
        style={{ paddingBottom: '30px' }}
        dataLength={page * size}
        next={storeInvitesList.fetchNextPage}
        hasMore={storeInvitesList.hasNextPage}
        loader={<h4>Loading...</h4>}
        refreshFunction={storeInvitesList.refetch}
        scrollableTarget='storeScroll'
      >
        <Table
          columns={columns}
          dataSource={invitesList}
          bordered
          rowKey={(record) => record.email}
          pagination={false}
          loading={storeInvitesList.isLoading}
        />
      </InfiniteScroll>
    </div>
  )
}

export default React.memo(StoreInvitesList)
