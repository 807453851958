import React, { useEffect, useState } from 'react'
import { SettingFilter } from '@merchx-v2/shared-types'
import { SearchableSelectBase } from 'components'
import { useProductsOptions } from '../../hooks/useProductsOptions'

type PropsType = {
  landingId?: number
  campaignId: number
  ownerId: number
  initialOption: SelectOption
  disabled?: boolean
  filters?: SettingFilter[]
  allowClear?: boolean
  selected?: string | number
  onSelect: (selectOption: SelectOption) => void
  onClear?: () => void
}

const ProductsSelect = (props: PropsType) => {
  const { campaignId, filters, ownerId, ...rest } = props

  const [searchText, setSearchText] = useState<string>()

  const productOptions = useProductsOptions({ campaignId, filters, searchText, ownerId, ownerType: 'CUSTOMER' })

  useEffect(() => {
    productOptions.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  return (
    <SearchableSelectBase
      onSearch={setSearchText}
      options={productOptions.data}
      isLoading={productOptions.isLoading || productOptions.isRefetching}
      {...rest}
    />
  )
}

export default ProductsSelect
