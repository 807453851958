import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

type ShipstationSettingInput = {
  name: string
  apiKey: string
  apiSecret: string
  storeId: number
}

type Payload = {
  shipstationSettingId: number
  shipstationSettingData: ShipstationSettingInput
}

export type UpdateShipstationSettingAction = FSA<undefined, Payload, string>

type UpdateShipstationSetting = (args: Payload) => MrxThunk<UpdateShipstationSettingAction, Promise<boolean>>

type QueryResponse = GraphQLResponse<'updateShipstationSetting', boolean>

const UPDATE_SHIPSTATION_SETTING = `
  mutation updateShipstationSetting ($shipstationSettingId: Int!, $shipstationSettingData: ShipstationSettingInput!) {
    updateShipstationSetting (shipstationSettingId: $shipstationSettingId, shipstationSettingData: $shipstationSettingData)
  }
`

const updateShipstationSetting: UpdateShipstationSetting = (args) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.UPDATE_SHIPSTATION_SETTING,
    meta: { done: false }
  })

  try {
    const { shipstationSettingId, shipstationSettingData } = args
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_SHIPSTATION_SETTING,
      variables: { shipstationSettingId, shipstationSettingData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateShipstationSetting } = data
    if (!updateShipstationSetting) {
      throw new Error("Can't update Shipstation setting!")
    }

    dispatch({
      type: t.UPDATE_SHIPSTATION_SETTING,
      payload: args,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.UPDATE_SHIPSTATION_SETTING,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { updateShipstationSetting }
