import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import * as t from '../actionTypes'
import { SubscriptionData } from '../types'

type Args = {
  campaignId: number
  productId: number
  subscriptionData: SubscriptionData
}

type Payload = {
  campaignId: number
  productId: number
  subscriptionData: SubscriptionData
}

export type SaveSubscriptionDataAction = FSA<undefined, Payload, string>

type SaveSubscriptionData = (args: Args) => MrxThunk<SaveSubscriptionDataAction, Promise<boolean>>

type QueryResponse = GraphQLResponse<'saveSubscriptionData', boolean>

const SAVE_SUBSCRIPTION_DATA = `
  mutation SaveSubscriptionData($workspaceId: Int!, $campaignId: Int!, $productId: Int!, $subscriptionData: SubscriptionProductPriceInput!) {
    saveSubscriptionData(workspaceId: $workspaceId, campaignId: $campaignId, productId: $productId, subscriptionData: $subscriptionData)
  }
`

const saveSubscriptionData: SaveSubscriptionData = (args) => async (dispatch, getState) => {
  let isSaved = false

  dispatch({
    type: t.SAVE_SUBSCRIPTION_DATA,
    meta: { done: false }
  })

  try {
    const workspaceId = getState()?.workspaces?.activeWorkspace?.id
    const { campaignId, productId, subscriptionData } = args
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: SAVE_SUBSCRIPTION_DATA,
      variables: { workspaceId, campaignId, productId, subscriptionData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { saveSubscriptionData } = data
    if (!saveSubscriptionData) {
      throw new Error("Can't set product prices!")
    }

    dispatch({
      type: t.SAVE_SUBSCRIPTION_DATA,
      payload: { campaignId, productId, subscriptionData },
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.SAVE_SUBSCRIPTION_DATA,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { saveSubscriptionData }
