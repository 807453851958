import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

type PostmarkSettingInput = {
  name: string
  apiKey: string
  from: string
}

type Payload = {
  postmarkSettingId: number
  postmarkSettingData: PostmarkSettingInput
}

export type UpdatePostmarkSettingAction = FSA<undefined, Payload, string>

type UpdatePostmarkSetting = (args: Payload) => MrxThunk<UpdatePostmarkSettingAction, Promise<boolean>>

type QueryResponse = GraphQLResponse<'updatePostmarkSetting', boolean>

const UPDATE_POSTMARK_SETTING = `
  mutation updatePostmarkSetting ($postmarkSettingId: Int!, $postmarkSettingData: PostmarkSettingInput!) {
    updatePostmarkSetting (postmarkSettingId: $postmarkSettingId, postmarkSettingData: $postmarkSettingData)
  }
`

const updatePostmarkSetting: UpdatePostmarkSetting = (args) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.UPDATE_POSTMARK_SETTING,
    meta: { done: false }
  })

  try {
    const { postmarkSettingId, postmarkSettingData } = args
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_POSTMARK_SETTING,
      variables: { postmarkSettingId, postmarkSettingData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updatePostmarkSetting } = data
    if (!updatePostmarkSetting) {
      throw new Error("Can't update Postmark setting!")
    }

    dispatch({
      type: t.UPDATE_POSTMARK_SETTING,
      payload: args,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.UPDATE_POSTMARK_SETTING,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { updatePostmarkSetting }
