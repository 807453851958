// TODO - remove becouse of legacy

import { useInfiniteQuery } from 'react-query'
import hash from 'object-hash'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Landing, LandingTypeEnum } from '../types'

const DEFAULT_PAGE_SIZE = 30

export type LandingsPage = {
  items: Landing[]
  total: number
  currentPage: number
  pageSize: number
  filters?: LandingsFilter
}

type LandingsFilter = {
  workspaceId: number
  campaignId: number
  searchText?: string
  landingType?: LandingTypeEnum
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'landingsListPage', LandingsPage>

const FETCH_LANDINGS_LIST_PAGE = `
  query landingsListPage($page: Int, $size: Int, $filters: LandingsFilters!) {
    landingsListPage(page: $page, size: $size, filters: $filters) {
      items {
        id
        name
        siteUrl
        status
        isUpdateAvailable
        isNeedToRedeploy
      }
      total
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type PageParam = {
  page?: number
  size?: number
  filters: LandingsFilter
}

type FetchLandingsListArgs = {
  queryKey: string | string[]
  pageParam?: PageParam
}

type FetchLandingsList = (args: FetchLandingsListArgs) => Promise<LandingsPage>

const fetchLandingsList: FetchLandingsList = async ({ pageParam }) => {
  const { page = 1, size = DEFAULT_PAGE_SIZE, filters = undefined } = pageParam || {}

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_LANDINGS_LIST_PAGE,
      variables: { page, size, filters }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { landingsListPage } = data
    if (!landingsListPage) {
      throw new Error("Can't get landings list page!")
    }

    return {
      items: landingsListPage.items,
      total: landingsListPage.total,
      currentPage: page,
      pageSize: size,
      filters
    }
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

type GetNextPageParam = (args: LandingsPage) => PageParam | undefined

type LandingsListEvents = {
  keepPreviousData: boolean
  getPreviousPageParam: GetNextPageParam
  getNextPageParam: GetNextPageParam
}

const landingsListEvents: LandingsListEvents = {
  keepPreviousData: true,
  getPreviousPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, filters }) => {
    if (currentPage > 1) {
      return {
        page: currentPage - 1,
        size: pageSize,
        filters
      }
    }

    return undefined
  },
  getNextPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, total = 0, filters }) => {
    if (pageSize * currentPage < total) {
      return {
        page: currentPage + 1,
        size: pageSize,
        filters
      }
    }
    return undefined
  }
}

export const useLandingsList = (filters: LandingsFilter) => {
  const cacheKey = ['landingsList', hash(filters)]

  return useInfiniteQuery(
    cacheKey,
    (prev) =>
      fetchLandingsList({
        queryKey: cacheKey,
        pageParam: { ...prev.pageParam, filters }
      }),
    landingsListEvents
  )
}
