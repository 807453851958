import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type CurrentPageSelector = (state: ReduxState) => number

const getSizeSelector: CurrentPageSelector = state => state.authorizeSettings.pagination.size

export const sizeSelector: CurrentPageSelector = createSelector(
    getSizeSelector,
  size => size
)
