import { CustomGatewayArtworkDto } from '@merchx-v2/shared-types'
import { ArtworkInput } from 'features/types'

type IsArtworksInputValidArgs = {
  artworks: CustomGatewayArtworkDto[]
  artworksInput?: Record<string, ArtworkInput>
  forceImages?: boolean
}

export const isArtworksInputValid = ({ artworks, artworksInput = {}, forceImages = false }: IsArtworksInputValidArgs) => {
  const variantsForUpdate = []

  for (const artwork of artworks) {
    if (artwork.color && artworksInput[artwork.color]) {
      variantsForUpdate.push(artwork.color)

      if (!artworksInput[artwork.color].DTGProductId) {
        return false
      }
    }
  }

  if (!forceImages) {
    const variantsForCreate = Object.keys(artworksInput).filter(item => !variantsForUpdate.includes(item))

    for (const variantValue of variantsForCreate) {
      if (artworksInput[variantValue] && (!(artworksInput[variantValue].file || artworksInput[variantValue].backFile) || !artworksInput[variantValue].DTGProductId)) {
        return false
      }
    }
  }

  return true
}
