import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { SettingOwner } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Setting } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'clearImageSetting', boolean>

const CLEAR_IMAGE_SETTING = `
  mutation ClearImageSetting($settingId: Int!) {
    clearImageSetting(settingId: $settingId)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type ClearImageSettingArgs = {
  ownerType: SettingOwner
  ownerId: number
  settingId: number
}

type ClearImageSetting = (args: ClearImageSettingArgs) => Promise<ClearImageSettingArgs>

const clearImageSetting: ClearImageSetting = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CLEAR_IMAGE_SETTING,
      variables: { settingId: args.settingId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { clearImageSetting } = data
    if (!clearImageSetting) {
      throw new Error("Can't clear image setting!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type ClearImageSettingContext = { prevSetting?: Setting }

type ClearImageSettingEvents = {
  onMutate: (variables: ClearImageSettingArgs) => Promise<ClearImageSettingContext>
  onError: (error: string, variables: ClearImageSettingArgs, context: ClearImageSettingContext) => void
  onSettled: (variables?: ClearImageSettingArgs) => void
}

const clearImageSettingEvents: ClearImageSettingEvents = {
  onMutate: async ({ settingId }) => {
    await queryClient.cancelQueries(['setting', settingId])

    // Snapshot the previous value
    const prevSetting = queryClient.getQueryData<Setting>(['setting', settingId])

    // Optimistically update to the new values
    if (prevSetting) {
      queryClient.setQueryData<Setting>(['setting', settingId], {
        ...prevSetting,
        id: settingId
      })
    }

    return { prevSetting }
  },
  onError: (_err, variables, context) => {
    if (context?.prevSetting) {
      // Restore previous version of setting on any error
      queryClient.setQueryData<Setting>(['setting', variables.settingId], context.prevSetting)
    }
  },
  onSettled: (data: ClearImageSettingArgs) => {
    if (data?.settingId) {
      queryClient.invalidateQueries(['template'])
      queryClient.invalidateQueries(['landing'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useClearImageSetting = () => useMutation(clearImageSetting, clearImageSettingEvents)
