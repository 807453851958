import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Region } from '../types'

type Payload = {
  countryId: number
  region: Region
}
export type CreateRegionAction = FSA<undefined, Payload, string>
type CreateRegion = (countryId: number, regionData: RegionInput) => MrxThunk<CreateRegionAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'createRegion', Region>

const CREATE_REGION = `
  mutation createRegion ($regionData: RegionInput!) {
    createRegion (regionData: $regionData) {
      id
      name
      iso2
      countryId
      createdAt
      updatedAt
    }
  }
    `
type RegionInput = {
  countryId: number
  name: string
  iso2: string
}

const createRegion: CreateRegion = (countryId, regionData) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.CREATE_REGION,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_REGION,
      variables: { regionData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createRegion } = data
    if (!createRegion) {
      throw new Error("Can't create region!")
    }

    dispatch({
      type: t.CREATE_REGION,
      payload: {
        countryId,
        region: createRegion
      },
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.CREATE_REGION,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { createRegion }
