import React, { useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import { Table, Popconfirm, Layout, PageHeader } from 'antd'
import notification from 'mrx-notification'
import { ColumnsType } from 'antd/lib/table'
import useHeightResize from 'utils/useHeightResize'
import { Breadcrumbs } from 'components'
import CreateProductSize from '../CreateProductSize'
import UpdateProductSize from '../UpdateProductSize'
import * as rootStyles from 'assets/layoutStyle'
import styles from './ProductSizesList.module.scss'
import { connector, PropsFromRedux } from './container'
import { ProductSize } from '../../types'

type HeightResizeRule = [number, number, number]
const rules: HeightResizeRule[] = [
  [0, 800, 8],
  [800, Infinity, 10]
]

const ProductSizesList = (props: PropsFromRedux) => {
  const {
    productSizes,
    isLoading,
    error,
    total,
    currentPage,
    isNeedToFetchList,

    changeCurrentPage,
    changePageSize,
    fetchProductSizesList,
    removeProductSize
  } = props

  const [selectedProductSizeId, setSelectedProductSizeId] = useState<number>()
  const [isUpdateDrawerVisible, setIsUpdateDrawerVisible] = useState<boolean>(false)
  const { history } = useReactRouter()

  const handleChangePageSize = (newSize: number) => {
    changePageSize(newSize)
    fetchProductSizesList()
  }

  useHeightResize(handleChangePageSize, rules)

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Product Sizes list page error!',
        description: error
      })
    }
  }, [error])

  useEffect(() => {
    isNeedToFetchList && fetchProductSizesList()
  }, [fetchProductSizesList, isNeedToFetchList])

  const doConfirm = async (productSizeId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    const isRemoved = await removeProductSize(productSizeId)
    if (isRemoved) {
      notification.success({
        message: 'Successfully',
        description: 'Product Size was deleted successfully!'
      })
    }
  }

  const columns: ColumnsType<ProductSize> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (record: ProductSize) => (
        <span>
          <Popconfirm
            title='Are you sure delete this size?'
            onConfirm={(e) => doConfirm(record.id, e)}
            onCancel={(e) => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <a
              href='/#'
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              Remove
            </a>
          </Popconfirm>
        </span>
      )
    }
  ]

  const handleOnRow = (record: ProductSize) => {
    return {
      onClick: () => {
        setSelectedProductSizeId(record.id)
        setIsUpdateDrawerVisible(true)
      }
    }
  }

  const handleOnClose = () => {
    setIsUpdateDrawerVisible(false)
  }

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader onBack={() => history.goBack()} title='Product Size' extra={[<CreateProductSize key='1' />]} />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Catalogs', url: '/' },
            { title: 'Sizes List', url: '/' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <Table
          columns={columns}
          dataSource={productSizes}
          bordered
          rowKey={(record) => record.id}
          onRow={handleOnRow}
          pagination={{
            onChange: changeCurrentPage,
            current: currentPage,
            total
          }}
          loading={isLoading}
        />
      </Layout.Content>
      {selectedProductSizeId && (
        <UpdateProductSize productSizeId={selectedProductSizeId} onClose={handleOnClose} visible={isUpdateDrawerVisible} />
      )}
    </Layout>
  )
}

export default connector(ProductSizesList)
