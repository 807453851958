import { useInfiniteQuery } from 'react-query'
import axios from 'utils/axios'
import hash from 'object-hash'
import { DomainsPageDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

const DEFAULT_PAGE_SIZE = 30

/// ////////////////////////////////////////////////////////////////////////////////
// TYPES
/// ////////////////////////////////////////////////////////////////////////////////

export type DomainsPage = DomainsPageDto & {
  currentPage: number
  pageSize: number
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'domainsListPage', DomainsPage>

const FETCH_DOMAINS_LIST = `
  query fetchDomainsList ($page: Int, $size: Int, $workspaceId: Int!, $searchText: String) {
    domainsListPage (page: $page, size: $size, workspaceId: $workspaceId, searchText: $searchText) {
      items {
        id
        name
        registrar
        registrarLogin
        registrarPassword
        createdAt
        updatedAt
      }
      total
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type PageParam = {
  page?: number
  size?: number
  workspaceId?: number
  searchText?: string
}

type FetchDomainsListArgs = {
  queryKey: string | string[]
  pageParam?: PageParam
}

type FetchDomainsList = (args: FetchDomainsListArgs) => Promise<DomainsPage>

const fetchDomainsList: FetchDomainsList = async ({ pageParam }) => {
  const { page = 1, size = DEFAULT_PAGE_SIZE, workspaceId, searchText } = pageParam || {}
  try {
    if (!workspaceId) {
      return {
        items: [],
        total: 0,
        currentPage: 0,
        pageSize: DEFAULT_PAGE_SIZE
      }
    }
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_DOMAINS_LIST,
      variables: { page, size, workspaceId, searchText }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { domainsListPage } = data
    if (!domainsListPage) {
      throw new Error("Can't get domains list page!")
    }

    return {
      items: domainsListPage.items,
      total: domainsListPage.total,
      currentPage: page,
      pageSize: size
    }
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

type GetNextPageParam = (args: DomainsPage) => PageParam | undefined

type DomainsListEvents = {
  keepPreviousData: boolean
  getPreviousPageParam: GetNextPageParam
  getNextPageParam: GetNextPageParam
}

export const domainsListEvents: DomainsListEvents = {
  keepPreviousData: true,
  getPreviousPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0 }) => {
    if (currentPage > 1) {
      return {
        page: currentPage - 1,
        size: pageSize
      }
    }

    return undefined
  },
  getNextPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, total = 0 }) => {
    if (pageSize * currentPage < total) {
      return {
        page: currentPage + 1,
        size: pageSize
      }
    }

    return undefined
  }
}

export const useDomainsList = (workspaceId?: number, searchText?: string) => {
  const cacheKey = ['domainsList', hash({ workspaceId, searchText })]

  return useInfiniteQuery(
    cacheKey,
    (prev) =>
      fetchDomainsList({
        queryKey: cacheKey,
        pageParam: { ...prev.pageParam, workspaceId, searchText }
      }),
    domainsListEvents
  )
}
