import { ProductPriceOwner } from '@merchx-v2/shared-types'
import axios from 'utils/axios'
import { useQuery } from 'react-query'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Product } from 'features/types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'product', Product>

// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchProductArgs = {
  campaignId: number
  productId: number
  productPriceOwnerType: ProductPriceOwner
  productPriceOwnerId: number
}

type FetchProduct = (args: FetchProductArgs) => Promise<Product>

const fetchProduct: FetchProduct = async (args) => {
  try {
    const FETCH_PRODUCT = (isNeedCampaignPrices) => `
  query fetchProduct($productId: Int!, $productPriceOwnerType: ProductPriceOwner!, $productPriceOwnerId: Int!${
    isNeedCampaignPrices ? `, $campaignId: Int` : ''
  }) {
    product(productId: $productId) {
      id
      campaignId
      campaign {
        name
      }
      name
      displayName
      description
      sku
      tags

      ownerId
      ownerType
      owner {
        id
        firstName
        lastName
        email
        createdFromLandingUrl
        createdAt
        updatedAt
      }

      storeId
      storeName

      productType
      productType2
      customizable

      basePrice
      baseDiscount
      baseCost
      baseMemberPrice

      weight
      weightAccuracy
      weightUnit

      hasCustomShipping
      customShippingPrice

      useReplacementForStock
      stockProductId
      stockProductName
      sellWhenOutOfStock
      
      artworks {
        id
        DTGProductId
        virtualProductId
        color
        original
        preview
        productType
        backOriginal
        backPreview
      }
      
      prices(ownerType: $productPriceOwnerType, ownerId: $productPriceOwnerId) {
        id
        sku
        price
        discount
        memberPrice
        variantKey
        variantSchema
        isMainPrice
      }

      supplierProductId

      variantKeys {
        isActive
        variantKey
        variantSchema
      }
      variantValues {
        variantOption
        values
        valueObjects {
          id
          value
          assets {
            id
            name
            extension
            ownerType
            ownerId
            mimeType
            s3bucket
            s3key
            signedUrl
            createdAt
            updatedAt
          }
        }
        isMainOption
      }

      isPriceChangingLocked

      ${
        isNeedCampaignPrices
          ? `campaignPrices: prices(ownerType: CAMPAIGN, ownerId: $campaignId) {
          id
          sku
          price
          discount
          memberPrice
          variantKey
          variantSchema
        }`
          : ''
      }

      subscriptionItems {
        date
        productId
        product {
          id
          name
          productType
        }
        quantity
      }

      image
      images {
        id
        workspaceId
        name
        extension
        productId
        imageUrl
        s3bucket
        s3key
        createdAt
        updatedAt
      }

      assets {
        id
        name
        extension
        ownerType
        ownerId
        mimeType
        s3bucket
        s3key
        signedUrl
        createdAt
        updatedAt
      }

      hasCustomSeo
      seoTitle
      seoDescription
      seoUrl
      seoKeywords
      isActive
      
      createdAt
      updatedAt
    }
  }
`
    if (args.productId) {
      const {
        data: { data, errors }
      }: QueryResponse = await axios.post('/graphql', {
        query: FETCH_PRODUCT(!!args.campaignId),
        variables: args
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { product } = data
      if (!product) {
        throw new Error("Can't get product!")
      }

      return product
    }
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useProduct = (args: FetchProductArgs) => useQuery(['product', args], () => fetchProduct(args))
