import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { SettingOwner } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Setting } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'removeSetting', boolean>

const REMOVE_SETTING = `
mutation removeSetting($settingId: Int!) {
  removeSetting(settingId: $settingId)
}
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveSettingArgs = {
  ownerType: SettingOwner
  ownerId: number
  settingId: number
}

type RemoveSetting = (args: RemoveSettingArgs) => Promise<RemoveSettingArgs>

const removeSetting: RemoveSetting = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_SETTING,
      variables: {
        settingId: args.settingId
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeSetting } = data
    if (!removeSetting) {
      throw new Error("Can't remove setting!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveSettingContext = { prevSetting?: Setting }

type RemoveSettingEvents = {
  onMutate: (variables: RemoveSettingArgs) => Promise<RemoveSettingContext>
  onError: (error: string, variables: RemoveSettingArgs, context: RemoveSettingContext) => void
  onSettled: (variables?: RemoveSettingArgs) => void
}

const removeSettingEvents: RemoveSettingEvents = {
  onMutate: async ({ settingId }) => {
    await queryClient.cancelQueries(['setting', settingId])

    // Snapshot the previous value
    const prevSetting = queryClient.getQueryData<Setting>(['setting', settingId])

    if (prevSetting) {
      queryClient.removeQueries(['setting', settingId])
    }

    return { prevSetting }
  },
  onError: (_err, variables, context) => {
    if (context?.prevSetting) {
      // Restore setting on any error
      queryClient.setQueryData<Setting>(['setting', variables.settingId], context.prevSetting)
    }
  },
  onSettled: (data: RemoveSettingArgs) => {
    if (data?.settingId) {
      queryClient.invalidateQueries(['template'])
      queryClient.invalidateQueries(['landing'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useRemoveSetting = () => useMutation(removeSetting, removeSettingEvents)
