import React, { useEffect, useState, useMemo } from 'react'
import useReactRouter from 'use-react-router'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Button, Input, Layout, PageHeader, Table, Radio, CheckboxOptionType } from 'antd'
import { StoreDto, StoreOwnerType } from '@merchx-v2/shared-types'
import notification from 'mrx-notification'
import { Breadcrumbs } from 'components'
import * as rootStyles from 'assets/layoutStyle'
import { createTableColumns } from './createTableColumns'

import { useStoresList } from '../../hooks'
import styles from './StoresList.module.scss'

const storesTypeOptions: CheckboxOptionType[] = [
  { label: 'Merchx', value: 'MERCHX' },
  { label: 'Customer', value: 'CUSTOMER' }
]

type Props = {
  showHeader?: boolean
  searchQuery?: string
}

const StoresList: React.FC<Props> = ({ showHeader = true }) => {
  const { history } = useReactRouter()

  const [searchQuery, setSearchQuery] = useState('')
  const [storeOwnerType, setStoresType] = useState<StoreOwnerType>('MERCHX')

  const storesList = useStoresList({ searchText: searchQuery, ownerType: storeOwnerType })
  const stores = useMemo(() => storesList.data?.pages.map(page => page.items).flat() || [], [storesList.dataUpdatedAt])
  useEffect(() => storesList.remove, [])

  useEffect(() => {
    if (storesList.error) {
      notification.error({
        message: 'Stores error!',
        description: storesList.error instanceof Error ? storesList.error.message : storesList.error.toString()
      })
    }
  }, [storesList.error])

  const onSearchInputChangeHandler = (value) => {
    setSearchQuery(value)
  }

  const handleClickEdit = (storeId: number) => {
    history.push(`/stores/${storeId}/edit`)
  }

  const handleOnRow = (record: StoreDto) => {
    return {
      onClick: () => {
        history.push(`/stores/${record.id}/details`)
      }
    }
  }

  const page = storesList.data?.pages.at(-1).currentPage || 1
  const size = storesList.data?.pages.at(-1).pageSize || 30
  const columns = useMemo(() => createTableColumns({ onEdit: handleClickEdit }), [])

  return (
    <Layout style={rootStyles.root}>
      {showHeader && (
        <>
          <Layout.Header style={rootStyles.header}>
            <PageHeader
              onBack={() => history.goBack()}
              title='Stores'
              extra={[
                <Radio.Group
                  key={1}
                  options={storesTypeOptions}
                  onChange={(event) => setStoresType(event.target.value)}
                  value={storeOwnerType}
                  optionType='button'
                  buttonStyle='solid'
                />,
                <Input.Search
                  key={2}
                  placeholder='Search'
                  onSearch={(value) => onSearchInputChangeHandler(value)}
                  className={styles.search}
                  allowClear
                />,
                <Button
                  key={3}
                  className={styles.createButton}
                  type='primary'
                  disabled={storeOwnerType === 'CUSTOMER'}
                  onClick={() => history.push(`/stores/create`)}
                >
                  CREATE STORE
                </Button>
              ]}
            />
          </Layout.Header>
          <div style={rootStyles.contentBreadcrumbs}>
            <Breadcrumbs
              items={[
                { title: 'Main', url: '/' },
                { title: 'Stores', url: '/stores' }
              ]}
            />
          </div>
        </>
      )}
      <Layout.Content className={showHeader && styles.contentComponent}>
        <InfiniteScroll
          style={{ paddingBottom: '30px' }}
          dataLength={page * size}
          next={storesList.fetchNextPage}
          hasMore={storesList.hasNextPage}
          loader={<h4>Loading...</h4>}
          refreshFunction={storesList.refetch}
          scrollableTarget='storeScroll'
        >
          <Table
            columns={columns}
            dataSource={stores}
            bordered
            rowKey={(record) => record.id}
            onRow={handleOnRow}
            pagination={false}
            loading={storesList.isLoading}
          />
        </InfiniteScroll>
      </Layout.Content>
    </Layout>
  )
}

export default React.memo(StoresList)
