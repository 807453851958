import produce from 'immer'
import * as t from './actionTypes'
import { actionTypes as nt } from 'features/navigation'
import { actionTypes as wt } from 'features/workspaces'
import { isSuccessAction, isErrorAction } from 'types'
import { DTGSizesState } from './types'
import createReducer from 'store/createReducer'
import { addItemToPagination } from 'utils/addItemToPagination'
import { removeItemFromPagination } from 'utils/removeItemFromPagination'
import {
  ChangeCurrentPageAction,
  ChangePageSizeAction,
  CreateDTGSizeAction,
  FetchDTGSizesForOptionsAction,
  FetchDTGSizesListAction,
  RemoveDTGSizeAction
} from './actions'

const initState: DTGSizesState = {
  DTGSizes: {},
  DTGSizesForOptions: [],
  pagination: {
    pages: {},
    total: 0,
    currentPage: 1,
    size: 10
  },
  UIState: {
    isCreateDTGSize: false,
    isFetchDTGSize: false,
    isOptionsLoading: false,
    isListLoading: false,
    isRemoveDTGSize: false
  }
}

const reducer = createReducer(initState, {
  [t.CREATE_DTG_SIZE]: (state, action: CreateDTGSizeAction) =>
    produce(state, draft => {
      draft.UIState.isCreateDTGSize = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isCreateDTGSize = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { id } = action.payload
        draft.DTGSizes[id] = action.payload
        addItemToPagination(draft.pagination, id)
      }
    }),

  [t.FETCH_DTG_SIZE_LIST]: (state, action: FetchDTGSizesListAction) =>
    produce(state, draft => {
      draft.UIState.isListLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isListLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        action.payload.items.forEach(item => {
          draft.DTGSizes[item.id] = item
        })
        draft.pagination.total = action.payload.total
        draft.pagination.pages[action.payload.page] = action.payload.items.map(item => item.id)
      }
    }),

  [t.FETCH_DTG_SIZE_FOR_OPTIONS]: (state, action: FetchDTGSizesForOptionsAction) =>
    produce(state, draft => {
      draft.UIState.isOptionsLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isOptionsLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.DTGSizesForOptions = action.payload
      }
    }),

  [t.REMOVE_DTG_SIZE]: (state, action: RemoveDTGSizeAction) =>
    produce(state, draft => {
      draft.UIState.isRemoveDTGSize = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isRemoveDTGSize = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.DTGSizes[action.payload]) {
          delete draft.DTGSizes[action.payload]
          removeItemFromPagination(draft.pagination, action.payload)
        }
      }
    }),

  [t.CHANGE_CURRENT_PAGE]: (state, action: ChangeCurrentPageAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.pagination.currentPage = action.payload
      }
    }),

  [t.CHANGE_PAGE_SIZE]: (state, action: ChangePageSizeAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.pagination.size = action.payload
      }
    }),

  [wt.SWITCH_WORKSPACE]: state =>
    produce(state, draft => {
      draft.pagination = initState.pagination
    }),

  [nt.LOCATION_CHANGE]: state =>
    produce(state, draft => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default reducer
