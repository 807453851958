import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { ProductAttribute } from '../types'

type Payload = {
  productId: number
  productAttribute: ProductAttribute
}

export type FetchProductAttributeAction = FSA<undefined, Payload, string>
type FetchProductAttribute = (productId: number, productAttributeId: number) => MrxThunk<FetchProductAttributeAction>
type QueryResponse = GraphQLResponse<'productAttribute', ProductAttribute>

const FETCH_PRODUCT_ATTRIBUTE = `
  query fetchProductAttribute ($productAttributeId: Int!) {
    productAttribute (productAttributeId: $productAttributeId) {
      id
      attribute
      value
      createdAt
      updatedAt
    }
  }
`

const fetchProductAttribute: FetchProductAttribute = (productId, productAttributeId) => async (dispatch) => {
  dispatch({
    type: t.FETCH_PRODUCT_ATTRIBUTE,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_PRODUCT_ATTRIBUTE,
      variables: { productAttributeId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { productAttribute } = data
    if (!productAttribute) {
      throw new Error("Can't get Product Attribute!")
    }

    dispatch({
      type: t.FETCH_PRODUCT_ATTRIBUTE,
      payload: {
        productId,
        productAttribute
      },
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_PRODUCT_ATTRIBUTE,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchProductAttribute }
