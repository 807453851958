import React, { useEffect, useState } from 'react'
import { CreateDTGVariantDto } from '@merchx-v2/shared-types'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Drawer, Input, Form, Select } from 'antd'
import { Store } from 'antd/lib/form/interface'
import notification from 'mrx-notification'
import { DTGProductsSelect } from 'features/DTGProducts/components'
import styles from './CreateDTGVariant.module.scss'
import { useCreateDTGVariant } from '../../hooks'

interface FormStore extends Store {
  size: string
  color: string
  DTGProductId: string
  externalVariantId: string
}

const { Option } = Select

const CreateDTGVariant = () => {
  const [isVisible, setVisible] = useState(false)

  const createDTGVariant = useCreateDTGVariant()

  const [form] = Form.useForm()

  const handleClose = () => {
    setVisible(false)
    form.resetFields()
  }

  useEffect(() => {
    if (createDTGVariant.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'DTG Variant was created successfully!'
      })
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createDTGVariant.isSuccess])

  const showDrawer = () => {
    setVisible(!isVisible)
  }

  const onFinishHandler = async (values: FormStore) => {
    const data: CreateDTGVariantDto = {
      DTGProductId: parseInt(values.DTGProductId),
      externalVariantId: parseInt(values.externalVariantId),
      variantSchema: 'COLOR/SIZE',
      variantKey: `${values.color}/${values.size}`
    }
    createDTGVariant.mutate(data)
  }

  return (
    <>
      <Button type='primary' onClick={showDrawer}>
        <PlusOutlined />
        CREATE
      </Button>
      <Drawer title='Create DTG Variant' width='400' onClose={handleClose} visible={isVisible} forceRender>
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
          <Form.Item name='size' label='Size' rules={[{ required: true, message: 'Please select variant size!' }]}>
            <Select>
              <Option key='SM' value='SM'>
                SM
              </Option>
              <Option key='MD' value='MD'>
                MD
              </Option>
              <Option key='LG' value='LG'>
                LG
              </Option>
              <Option key='XL' value='XL'>
                XL
              </Option>
              <Option key='2XL' value='2XL'>
                2XL
              </Option>
              <Option key='3XL' value='3XL'>
                3XL
              </Option>
            </Select>
          </Form.Item>

          <Form.Item name='color' label='Color' rules={[{ required: true, message: 'Please select variant color!' }]}>
            <Select>
              <Option value='BLACK'>Black</Option>
              <Option value='BLUE'>Blue</Option>
              <Option value='CUSTOM'>Custom</Option>
              <Option value='GOLD'>Gold</Option>
              <Option value='GRAY'>Gray</Option>
              <Option value='IRISH_GREEN'>Irish green</Option>
              <Option value='LIGHT_BLUE'>Light blue</Option>
              <Option value='LIGHT_PINK'>Light pink</Option>
              <Option value='MILITARY_GREEN'>Military green</Option>
              <Option value='MAROON'>Maroon</Option>
              <Option value='ORANGE'>Orange</Option>
              <Option value='PURPLE'>Purple</Option>
              <Option value='RED'>Red</Option>
              <Option value='ROYAL_BLUE'>Royal blue</Option>
              <Option value='WHITE'>White</Option>
            </Select>
          </Form.Item>

          <Form.Item name='DTGProductId' label='DTG Product' rules={[{ required: true, message: 'Please select DTG product!' }]}>
            <DTGProductsSelect
              initialOption={{
                id: 0,
                name: 'Select DTG product'
              }}
              onSelect={(selectedOption: SelectOption) => {
                form.setFieldsValue({
                  DTGProductId: selectedOption?.id
                })
              }}
            />
          </Form.Item>

          <Form.Item
            name='externalVariantId'
            label='External Variant ID'
            rules={[{ required: true, pattern: /\d/, message: 'Please input correct external variant ID!' }]}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button onClick={handleClose} className={styles.closeButton}>
              Close
            </Button>
            <Button type='primary' htmlType='submit' loading={false}>
              Create
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

export default CreateDTGVariant
