import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Table, Button, Tag, Popconfirm } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import notification from 'mrx-notification'
import { useDeployLanding, useUpdateLandingFromTemplate } from 'features/landings/hooks'
import { connector, PropsFromRedux } from './container'
import { CampaignTemplates } from '../../types'
import styles from './CampaignTemplatesReportTable.module.scss'

const CampaignTemplatesReportTable = (props: PropsFromRedux) => {
  const {
    campaignTemplatesReport,
    isLoading,
    campaignId,
    fullMode,
    selectedRowKeys,

    handleSelectChange,
    fetchCampaignTemplatesReport,
    updateLandingStatus
  } = props

  const [landingsInUpdate, setLandingsInUpdate] = useState([])

  const deployLanding = useDeployLanding()
  const updateLandingFromTemplate = useUpdateLandingFromTemplate()

  useEffect(() => {
    fetchCampaignTemplatesReport(campaignId, fullMode)
  }, [campaignId, fullMode, fetchCampaignTemplatesReport])

  const handleUpdateLanding = async (landingId: number) => {
    setLandingsInUpdate([...landingsInUpdate, landingId])
    updateLandingFromTemplate.mutate(landingId, {
      onSuccess: (data) => {
        updateLandingStatus(data.id)
      },
      onError: (error) => {
        notification.error({
          message: 'Error',
          description: error.toString()
        })
      }
    })

    setLandingsInUpdate(landingsInUpdate.filter((i) => i !== landingId))
  }

  const columns: ColumnsType<CampaignTemplates> = fullMode
    ? [
        {
          title: 'Landing ID',
          render: (_value, record) => (
            <Link to={`/campaigns/${campaignId}/landingWizard?landingId=${record.landingId}&step=5`}>{record.landingId}</Link>
          ),
          sorter: (a, b) => (a.landingId < b.landingId ? -1 : 1)
        },
        {
          title: 'Subroute',
          dataIndex: 'subroute',
          sorter: (a, b) => (a.subroute < b.subroute ? -1 : 1)
        },
        {
          title: 'Template name',
          dataIndex: 'templateName',
          sorter: (a, b) => (a.templateName < b.templateName ? -1 : 1)
        },
        {
          title: 'Status',
          render: (_value, record) => (
            <Tag color={record.isActual ? 'green' : 'orange'}>{record.isActual ? 'up to date' : 'need to update'}</Tag>
          ),
          key: 'status',
          sorter: (a) => (a.isActual ? -1 : 1)
        },
        {
          title: 'Action',
          render: (_value, { isActual, landingId }) => {
            const loading = landingsInUpdate.includes(landingId)
            return isActual ? (
              <Popconfirm title='Deploy the landing?' onConfirm={() => deployLanding.mutate(+landingId)}>
                <Button type='ghost'>Deploy</Button>
              </Popconfirm>
            ) : (
              <Popconfirm title='Update the source template?' onConfirm={() => handleUpdateLanding(+landingId)}>
                <Button disabled={loading} loading={loading}>
                  Update
                </Button>
              </Popconfirm>
            )
          },
          align: 'right'
        }
      ]
    : [
        {
          title: 'Template ID',
          dataIndex: 'templateId',
          width: 160,
          sorter: (a, b) => (a.templateId < b.templateId ? -1 : 1)
        },
        {
          title: 'Landings count',
          dataIndex: 'landingsCount',
          width: 160,
          sorter: (a, b) => (a.landingsCount < b.landingsCount ? -1 : 1)
        },
        {
          title: 'Template name',
          dataIndex: 'templateName',
          sorter: (a, b) => (a.templateName < b.templateName ? -1 : 1)
        }
      ]

  return (
    <Table
      key={fullMode.toString()} // if columns has changed should be rerender
      columns={columns}
      dataSource={campaignTemplatesReport || []}
      className={styles.table}
      bordered
      rowKey={(record) => record.templateId}
      pagination={false}
      loading={isLoading}
      rowSelection={
        fullMode
          ? null
          : {
              onChange: handleSelectChange,
              selectedRowKeys
            }
      }
      scroll={{ y: 'calc(100vh - 275px)' }}
    />
  )
}

export default connector(CampaignTemplatesReportTable)
