import React, { useState, useEffect, useRef } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Drawer, Input, Form } from 'antd'
import notification from 'mrx-notification'
import styles from './CreateRegion.module.scss'
import { PropsFromRedux, connector } from './container'
import { Country } from 'features/countries/types'
import { CountriesSelect } from 'features/countries/components'

type CreateRegionProps = PropsFromRedux & {
  country: Country
}

const CreateRegion = (props: CreateRegionProps) => {
  const [isVisible, setVisible] = useState(false)
  const refEl = useRef<Input>(null)

  const { isLoading, country, createRegion } = props

  useEffect(() => {
    if (isVisible) {
      refEl.current && refEl.current.focus()
    }
  }, [isVisible])

  const showDrawer = () => {
    setVisible(!isVisible)
  }

  const [form] = Form.useForm()

  const handleClose = () => {
    setVisible(false)
    form.resetFields()
  }

  useEffect(() => {
    if (country) {
      form.setFieldsValue({
        countryId: country.id
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country])

  const onFinishHandler = async (values: any) => {
    const isSaved = await createRegion(country.id, {
      countryId: values.countryId,
      name: values.name,
      iso2: values.iso2
    })
    if (isSaved) {
      notification.success({
        message: 'Successfully',
        description: 'Region was created successfully!'
      })
      handleClose()
    }
  }

  return (
    <>
      <Button type='primary' onClick={showDrawer}>
        <PlusOutlined />
        CREATE
      </Button>
      <Drawer title='Create Region' width='400' onClose={handleClose} visible={isVisible} forceRender>
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form} initialValues={{ countryId: country?.id }}>
          <Form.Item name='countryId' label='Country' rules={[{ required: true, message: 'Please select country!' }]}>
            <CountriesSelect
              initialOption={{
                id: 1,
                name: 'United States of America'
              }}
              onSelect={(selectedOption: SelectOption) => {
                form.setFieldsValue({
                  countryId: selectedOption?.id
                })
              }}
            />
          </Form.Item>
          <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input region name!' }]}>
            <Input style={{ width: '100%' }} ref={refEl} />
          </Form.Item>

          <Form.Item name='iso2' label='ISO 2' rules={[{ required: true, message: 'Please input ISO 2!' }]}>
            <Input style={{ width: '100%' }} />
          </Form.Item>

          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button onClick={handleClose} className={styles.closeButton}>
              Close
            </Button>
            <Button type='primary' htmlType='submit' loading={isLoading}>
              Create
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

export default connector(CreateRegion)
