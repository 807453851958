import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

const mapStateToProps = (state: ReduxState) => ({
  isLoading: selectors.isLoadingSelector(state),
  error: selectors.errorSelector(state)
});

const mapDispatchToProps = {
  register: actions.register
};

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector>
