import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import * as eternalListsFeature from 'features/eternalLists'
import * as t from '../actionTypes'

type Payload = {
  items: eternalListsFeature.actions.ItemToRemove[]
  filters: { campaignId: number }
}

export type RemoveProductAction = FSA<undefined, Payload, string>
type RemoveProduct = (
  campaignId: number,
  productId: number,
  index: number
) => MrxThunk<eternalListsFeature.actions.RemoveEternalItemsAction<'products'>, Promise<boolean>>
type QueryResponse = GraphQLResponse<'removeProduct', boolean>

const REMOVE_PRODUCT = `
  mutation removeProduct($productId: Int!) {
    removeProduct(productId: $productId)
  }
`

const removeProduct: RemoveProduct = (campaignId, productId, index) => async (dispatch) => {
  let isRemoved = false

  dispatch({
    type: t.REMOVE_PRODUCT,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_PRODUCT,
      variables: { productId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Empty response!')
    }

    const { removeProduct } = data
    if (!removeProduct) {
      throw new Error("Can't remove product!")
    }

    dispatch({
      type: t.REMOVE_PRODUCT,
      payload: { list: 'products', items: [{ itemId: productId, index }], filters: { campaignId } },
      meta: { done: true }
    })
    dispatch({
      type: eternalListsFeature.actionTypes.REMOVE_ETERNAL_LIST_ITEMS,
      payload: { list: 'products', items: [{ itemId: productId, index }] },
      meta: { done: true }
    })

    isRemoved = true
  } catch (err) {
    dispatch({
      type: t.REMOVE_PRODUCT,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isRemoved
}

export { removeProduct }
