import { combineReducers } from 'redux'
import { connectRouter, RouterState } from 'connected-react-router'

import { History } from 'history'
import features from '../features'

import { AdSpendsState } from 'features/adSpends/types'
import { AddressesState } from 'features/addresses/types'
import { AuthenticationState } from 'features/authentication/types'
import { AlertsState } from 'features/alerts/types'
import { AuthorizeSettingsState } from 'features/authorizeSettings/types'
import { AwsCertificatesState } from 'features/awsCertificates/types'
import { AwsSettingsState } from 'features/awsSettings/types'
import { CategoriesState } from 'features/categories/types'
import { CustomGatewaySettingsState } from 'features/customGatewaySettings/types'
import { DTGProductsState } from 'features/DTGProducts/types'
import { DTGSizesState } from 'features/DTGSizes/types'
import { EternalListsState } from 'features/eternalLists/types'
import { FilesState } from 'features/files/types'
import { NavigationState } from 'features/navigation/types'
import { NginxSettingsState } from 'features/nginxSettings/types'
import { PayPalSettingsState } from 'features/payPalSettings/types'
import { PostmarkSettingsState } from 'features/postmarkSettings/types'
import { ProcessingCostsState } from 'features/processingCosts/types'
import { ProductAttributesState } from 'features/productAttributes/types'
import { ProductColorsState } from 'features/productColors/types'
import { ProductImagesState } from 'features/productImages/types'
import { ProductSizesState } from 'features/productSizes/types'
import { ProductTagsState } from 'features/productTags/types'
import { ProductsState } from 'features/products/types'
import { RegionsState } from 'features/regions/types'
import { ReportsState } from 'features/reports/types'
import { SendgridSettingsState } from 'features/sendgridSettings/types'
import { ShipstationSettingsState } from 'features/shipstationSettings/types'
import { SimpleTextSettingsState } from 'features/simpleTextSettings/types'
import { SitePreviewsState } from 'features/sitePreviews/types'
import { SiteSnapshotsState } from 'features/siteSnapshots/types'
import { SitesManagementState } from 'features/sitesManagement/types'
import { StripeSettingsState } from 'features/stripeSettings/types'
import { TasksQueueState } from 'features/tasksQueue/types'
import { WebsocketState } from 'features/websocket/types'
import { WorkspacesState } from 'features/workspaces/types'
import { ZipwhipSettingsState } from 'features/zipwhipSettings/types'

console.log('Create root reducer!')

export type ReduxState = {
  router: RouterState
  adSpends: AdSpendsState
  addresses: AddressesState
  alerts: AlertsState
  authentication: AuthenticationState
  authorizeSettings: AuthorizeSettingsState
  awsCertificates: AwsCertificatesState
  awsSettings: AwsSettingsState
  categories: CategoriesState
  customGatewaySettings: CustomGatewaySettingsState
  DTGProducts: DTGProductsState
  DTGSizes: DTGSizesState
  eternalLists: EternalListsState
  files: FilesState
  navigation: NavigationState
  nginxSettings: NginxSettingsState
  overviewReport: any
  payPalSettings: PayPalSettingsState
  postmarkSettings: PostmarkSettingsState
  processingCosts: ProcessingCostsState
  productAttributes: ProductAttributesState
  productColors: ProductColorsState
  productImages: ProductImagesState
  productSizes: ProductSizesState
  productTags: ProductTagsState
  products: ProductsState
  regions: RegionsState
  reports: ReportsState
  sendgridSettings: SendgridSettingsState
  shipstationSettings: ShipstationSettingsState
  simpleTextSettings: SimpleTextSettingsState
  sitePreviews: SitePreviewsState
  siteSnapshots: SiteSnapshotsState
  sitesManagement: SitesManagementState
  stripeSettings: StripeSettingsState
  tasksQueue: TasksQueueState
  websocket: WebsocketState
  workspaces: WorkspacesState
  zipwhipSettings: ZipwhipSettingsState
}

const createRootReducer = (history: History) =>
  // @ts-ignore
  combineReducers<ReduxState>({
    router: connectRouter(history),
    adSpends: features.adSpends.reducer,
    addresses: features.addresses.reducer,
    alerts: features.alerts.reducer,
    authentication: features.authentication.reducer,
    authorizeSettings: features.authorizeSettings.reducer,
    awsCertificates: features.awsCertificates.reducer,
    awsSettings: features.awsSettings.reducer,
    categories: features.categories.reducer,
    customGatewaySettings: features.customGatewaySettings.reducer,
    DTGProducts: features.DTGProducts.reducer,
    DTGSizes: features.DTGSizes.reducer,
    eternalLists: features.eternalLists.reducer,
    files: features.files.reducer,
    navigation: features.navigation.reducer,
    nginxSettings: features.nginxSettings.reducer,
    overviewReport: features.overviewReport.reducer,
    payPalSettings: features.payPalSettings.reducer,
    postmarkSettings: features.postmarkSettings.reducer,
    processingCosts: features.processingCosts.reducer,
    productAttributes: features.productAttributes.reducer,
    productColors: features.productColors.reducer,
    productImages: features.productImages.reducer,
    productSizes: features.productSizes.reducer,
    productTags: features.productTags.reducer,
    products: features.products.reducer,
    regions: features.regions.reducer,
    reports: features.reports.reducer,
    sendgridSettings: features.sendgridSettings.reducer,
    shipstationSettings: features.shipstationSetting.reducer,
    simpleTextSettings: features.simpleTextSettings.reducer,
    sitePreviews: features.sitePreviews.reducer,
    siteSnapshots: features.siteSnapshots.reducer,
    sitesManagement: features.sitesManagement.reducer,
    stripeSettings: features.stripeSettings.reducer,
    tasksQueue: features.tasksQueue.reducer,
    websocket: features.websocket.reducer,
    workspaces: features.workspaces.reducer,
    zipwhipSettings: features.zipwhipSettings.reducer
  })

export default createRootReducer
