import * as t from '../actionTypes'
import { Workspace } from '../types'

export type ActiveWorkspaceLoadedAction = BaseFSA<Workspace>

export const activeWorkspaceLoaded = (workspace: Workspace): MrxThunk<ActiveWorkspaceLoadedAction> => dispatch => {
  dispatch({
    type: t.ACTIVE_WORKSPACE_LOADED,
    payload: workspace
  })
}
