import * as t from '../actionTypes'
import { EternalListEnum } from '../types'

type ChangeCurrentPagePayload = {
  page: number
  list: EternalListEnum
}

export type ChangeCurrentPageAction = BaseFSA<ChangeCurrentPagePayload>
type ChangeCurrentPage = (page: number, list: EternalListEnum) => MrxThunk<ChangeCurrentPageAction>

const changeCurrentPage: ChangeCurrentPage = (page, list) => async dispatch => {
  dispatch({
    type: t.CHANGE_CURRENT_PAGE,
    payload: { page, list }
  })
}

export { changeCurrentPage }
