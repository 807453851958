import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { SiteType } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Component } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'removeComponent', boolean>

const REMOVE_COMPONENT = `
  mutation RemoveComponent($componentId: Int!) {
    removeComponent(componentId: $componentId)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveComponentArgs = {
  ownerType: SiteType
  ownerId: number
  componentId: number
}

type RemoveComponent = (args: RemoveComponentArgs) => Promise<RemoveComponentArgs>

const removeComponent: RemoveComponent = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_COMPONENT,
      variables: {
        componentId: args.componentId
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeComponent } = data
    if (!removeComponent) {
      throw new Error("Can't remove component!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveComponentContext = { prevComponent?: Component }

type RemoveComponentEvents = {
  onMutate: (variables: RemoveComponentArgs) => Promise<RemoveComponentContext>
  onError: (error: string, variables: RemoveComponentArgs, context: RemoveComponentContext) => void
  onSettled: (variables?: RemoveComponentArgs) => void
}

const removeComponentEvents: RemoveComponentEvents = {
  onMutate: async ({ componentId }) => {
    await queryClient.cancelQueries(['component', componentId])

    // Snapshot the previous value
    const prevComponent = queryClient.getQueryData<Component>(['component', componentId])

    if (prevComponent) {
      queryClient.removeQueries(['component', componentId])
    }

    return { prevComponent }
  },
  onError: (_err, variables, context) => {
    if (context?.prevComponent) {
      // Restore page on any error
      queryClient.setQueryData<Component>(['component', variables.componentId], context.prevComponent)
    }
  },
  onSettled: (data: RemoveComponentArgs) => {
    if (data) {
      queryClient.invalidateQueries([`${data.ownerType.toLowerCase()}`, +data.ownerId])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useRemoveComponent = () => useMutation(removeComponent, removeComponentEvents)
