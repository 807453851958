import produce from 'immer'
import * as t from './actionTypes'
import { actionTypes as nt } from 'features/navigation'
import { actionTypes as wt } from 'features/workspaces'
import { isSuccessAction, isErrorAction } from 'types'
import { PayPalSettingsState } from './types'
import createReducer from 'store/createReducer'
import { addItemToPagination } from 'utils/addItemToPagination'
import { removeItemFromPagination } from 'utils/removeItemFromPagination'
import {
  ChangeCurrentPageAction,
  ChangePageSizeAction,
  CreatePayPalSettingAction,
  FetchPayPalSettingsListAction,
  FetchPayPalSettingsForOptionsAction,
  RemovePayPalSettingAction,
  UpdatePayPalSettingAction
} from './actions'

const initState: PayPalSettingsState = {
  payPalSettings: {},
  payPalSettingsForOptions: [],
  pagination: {
    currentPage: 1,
    total: 0,
    pages: {},
    size: 10
  },
  UIState: {
    isPayPalSettingsForOptionsLoading: false,
    isCreatePayPalSetting: false,
    isListLoading: false,
    isRemovePayPalSetting: false,
    isUpdatePayPalSetting: false
  }
}

const reducer = createReducer(initState, {
  [t.CREATE_PAYPAL_SETTING]: (state, action: CreatePayPalSettingAction) =>
    produce(state, (draft) => {
      draft.UIState.isCreatePayPalSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isCreatePayPalSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { id } = action.payload

        draft.payPalSettings[id] = action.payload
        addItemToPagination(draft.pagination, id)
      }
    }),

  [t.FETCH_PAYPAL_SETTINGS_LIST]: (state, action: FetchPayPalSettingsListAction) =>
    produce(state, (draft) => {
      draft.UIState.isListLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isListLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        action.payload.items.forEach((item) => {
          draft.payPalSettings[item.id] = item
        })
        const { page, total, items } = action.payload
        draft.pagination.total = total
        draft.pagination.pages[page] = items.map((item) => item.id)
      }
    }),

  [t.FETCH_PAYPAL_SETTINGS_FOR_OPTIONS]: (state, action: FetchPayPalSettingsForOptionsAction) =>
    produce(state, (draft) => {
      draft.UIState.isPayPalSettingsForOptionsLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isPayPalSettingsForOptionsLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.payPalSettingsForOptions = action.payload
      }
    }),

  [t.REMOVE_PAYPAL_SETTING]: (state, action: RemovePayPalSettingAction) =>
    produce(state, (draft) => {
      draft.UIState.isRemovePayPalSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isRemovePayPalSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.payPalSettings[action.payload]) {
          delete draft.payPalSettings[action.payload]
          removeItemFromPagination(draft.pagination, action.payload)
        }
      }
    }),
  [t.UPDATE_PAYPAL_SETTING]: (state, action: UpdatePayPalSettingAction) =>
    produce(state, (draft) => {
      draft.UIState.isUpdatePayPalSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isUpdatePayPalSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.payPalSettings[action.payload.payPalSettingId]) {
          const { name, accountEmail, clientId, secretKey, accessToken } = action.payload.payPalSettingData
          const setting = draft.payPalSettings[action.payload.payPalSettingId]
          setting.name = name
          setting.accountEmail = accountEmail
          setting.clientId = clientId
          setting.secretKey = secretKey
          setting.accessToken = accessToken
        }
      }
    }),

  [t.CHANGE_CURRENT_PAGE]: (state, action: ChangeCurrentPageAction) =>
    produce(state, (draft) => {
      if (action.payload) {
        draft.pagination.currentPage = action.payload
      }
    }),

  [t.CHANGE_PAGE_SIZE]: (state, action: ChangePageSizeAction) =>
    produce(state, (draft) => {
      if (action.payload) {
        draft.pagination.size = action.payload
      }
    }),

  [wt.SWITCH_WORKSPACE]: (state) =>
    produce(state, (draft) => {
      draft.pagination = initState.pagination
    }),

  [nt.LOCATION_CHANGE]: (state) =>
    produce(state, (draft) => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default reducer
