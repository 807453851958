import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'unbanCustomerAccount', boolean>

const UNBAN_CUSTOMER_ACCOUNT = `
  mutation unbanCustomerAccount ($customerAccountId: Int!) {
    unbanCustomerAccount(customerAccountId: $customerAccountId)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UnbanCustomerAccountArgs = {
  customerAccountId: number
}

type UnbanCustomerAccount = (args: UnbanCustomerAccountArgs) => Promise<number>

const unbanCustomerAccount: UnbanCustomerAccount = async ({ customerAccountId }) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UNBAN_CUSTOMER_ACCOUNT,
      variables: {
        customerAccountId
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { unbanCustomerAccount } = data
    if (!unbanCustomerAccount) {
      throw new Error("Can't unban customer account!")
    }

    return customerAccountId
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UnbanCustomerAccountEvents = {
  onSettled: (customerId?: number) => void
}

const unbanCustomerAccountEvents: UnbanCustomerAccountEvents = {
  onSettled: (customerAccountId) => {
    if (customerAccountId) {
      queryClient.invalidateQueries(['customerAccount', customerAccountId])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUnbanCustomerAccount = () => useMutation(unbanCustomerAccount, unbanCustomerAccountEvents)
