import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

type Payload = {
  productId: number
  productImageId: number
}

export type RemoveProductImageAction = FSA<undefined, Payload, string>
type RemoveProductImage = (productId: number, productImageId: number) => MrxThunk<RemoveProductImageAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'removeProductImage', boolean>

const REMOVE_PRODUCT_ATTRIBUTE = `
  mutation removeProductImage ($productImageId: Int!) {
    removeProductImage (productImageId: $productImageId)
  }
`

const removeProductImage: RemoveProductImage = (productId, productImageId) => async (dispatch) => {
  let isRemoved = false

  dispatch({
    type: t.REMOVE_PRODUCT_IMAGE,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_PRODUCT_ATTRIBUTE,
      variables: { productImageId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Empty response!')
    }

    const { removeProductImage } = data
    if (!removeProductImage) {
      throw new Error("Can't remove Product Image!")
    }

    dispatch({
      type: t.REMOVE_PRODUCT_IMAGE,
      payload: { productId, productImageId },
      meta: { done: true }
    })

    isRemoved = true
  } catch (err) {
    dispatch({
      type: t.REMOVE_PRODUCT_IMAGE,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isRemoved
}

export { removeProductImage }
