import * as t from './actionTypes'
import { actionTypes as wt } from 'features/workspaces'
import { actionTypes as nt } from 'features/navigation'
import produce from 'immer'
import { isSuccessAction, isErrorAction } from 'types'
import { PostmarkSettingsState } from './types'
import createReducer from 'store/createReducer'
import { addItemToPagination } from 'utils/addItemToPagination'
import { removeItemFromPagination } from 'utils/removeItemFromPagination'
import {
  ChangeCurrentPageAction,
  CreatePostmarkSettingAction,
  FetchPostmarkSettingsListAction,
  FetchPostmarkSettingsForOptionsAction,
  RemovePostmarkSettingAction,
  UpdatePostmarkSettingAction
} from './actions'

const initState: PostmarkSettingsState = {
  postmarkSettings: {},
  postmarkSettingsForOptions: [],
  pagination: {
    currentPage: 1,
    total: 0,
    pages: {},
    size: 10
  },
  UIState: {
    isCreatePostmarkSetting: false,
    isListLoading: false,
    isRemovePostmarkSetting: false,
    isPostmarkSettingsForOptionsLoading: false,
    isUpdatePostmarkSetting: false
  }
}

const reducer = createReducer(initState, {
  [t.CREATE_POSTMARK_SETTING]: (state, action: CreatePostmarkSettingAction) =>
    produce(state, draft => {
      draft.UIState.isCreatePostmarkSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isCreatePostmarkSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { id } = action.payload
        draft.postmarkSettings[id] = action.payload
        addItemToPagination(draft.pagination, id)
      }
    }),

  [t.FETCH_POSTMARK_SETTINGS_LIST]: (state, action: FetchPostmarkSettingsListAction) =>
    produce(state, draft => {
      draft.UIState.isListLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isListLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        action.payload.items.forEach(item => {
          draft.postmarkSettings[item.id] = item
        })
        const { page, total, items } = action.payload
        draft.pagination.total = total
        draft.pagination.pages[page] = items.map(item => item.id)
      }
    }),

  [t.FETCH_POSTMARK_SETTINGS_FOR_OPTIONS]: (state, action: FetchPostmarkSettingsForOptionsAction) =>
    produce(state, draft => {
      draft.UIState.isPostmarkSettingsForOptionsLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isPostmarkSettingsForOptionsLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.postmarkSettingsForOptions = action.payload
      }
    }),

  [t.REMOVE_POSTMARK_SETTING]: (state, action: RemovePostmarkSettingAction) =>
    produce(state, draft => {
      draft.UIState.isRemovePostmarkSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isRemovePostmarkSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.postmarkSettings[action.payload]) {
          delete draft.postmarkSettings[action.payload]
          removeItemFromPagination(draft.pagination, action.payload)
        }
      }
    }),
  [t.UPDATE_POSTMARK_SETTING]: (state, action: UpdatePostmarkSettingAction) =>
    produce(state, draft => {
      draft.UIState.isUpdatePostmarkSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isUpdatePostmarkSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.postmarkSettings[action.payload.postmarkSettingId]) {
          draft.postmarkSettings[action.payload.postmarkSettingId].name = action.payload.postmarkSettingData.name
          draft.postmarkSettings[action.payload.postmarkSettingId].apiKey = action.payload.postmarkSettingData.apiKey
          draft.postmarkSettings[action.payload.postmarkSettingId].from = action.payload.postmarkSettingData.from
        }
      }
    }),

  [t.CHANGE_CURRENT_PAGE]: (state, action: ChangeCurrentPageAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.pagination.currentPage = action.payload
      }
    }),

  [wt.SWITCH_WORKSPACE]: state =>
    produce(state, draft => {
      draft.pagination = initState.pagination
    }),

  [nt.LOCATION_CHANGE]: state =>
    produce(state, draft => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default reducer
