import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { Category } from '../types'

type CategorySelector = (state: ReduxState, campaignId: number, categoryId: number) => Category | undefined

const getCategory: CategorySelector = (state, campaignId, categoryId) => {
  let result = null
  if (campaignId && categoryId) {
    if (Object.prototype.hasOwnProperty.call(state.categories.categories, campaignId)) {
      if (Object.prototype.hasOwnProperty.call(state.categories.categories[campaignId], categoryId)) {
        result = state.categories.categories[campaignId][categoryId] as Category
      }
    }
  }
  return result
}

export const categorySelector: CategorySelector = createSelector(getCategory, (category) => category)
