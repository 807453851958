import React from 'react'
import numeral from 'numeral'
import { ColumnsType } from 'antd/lib/table'
import moment from 'moment'
import { StoreProfitDto } from '@merchx-v2/shared-types'
import { Tag } from 'antd'

export const createTableColumns = (): ColumnsType<StoreProfitDto> => {
  return [
    {
      title: 'ID',
      dataIndex: 'id'
    },
    {
      title: 'Event',
      dataIndex: 'event'
    },
    {
      title: 'Note',
      dataIndex: 'note'
    },
    {
      title: 'Profit',
      render: (_value, record) => <Tag color={record.sum > 0 ? 'green' : 'red'}>{numeral(record.sum / 100).format('$0,0.00')}</Tag>,
      key: 'sum'
    },
    {
      title: 'Payload',
      render: (record) => JSON.stringify(record.payload),
      key: 'payload'
    },
    {
      title: 'Date',
      render: (record) => moment(record.createdAt).format('MM/DD/YYYY HH:mm a'),
      key: 'createdAt',
      width: '200px'
    }
  ]
}
