// TODO - remove becouse of legacy

import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import userStateSelector from 'features/authentication/selectors/userStateSelector'

const mapStateToProps = (state: ReduxState) => ({
  user: userStateSelector(state)
})

const mapDispatchToProps = {}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector>
