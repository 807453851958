import { createSelector } from 'reselect'

const getProductSizesOptions: SelectOptionsSelector = state =>
  state.productSizes.productSizesForOptions.map(item => ({
    id: item.id,
    name: item.name
  })) || ([] as SelectOption[])

export const productSizesSelectOptionsSelector: SelectOptionsSelector = createSelector(
  getProductSizesOptions,
  options => options
)
