import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Region } from '../types'

type RegionsList = {
  items: Region[]
  total: number
}

type Payload = RegionsList & {
  countryId: number
  page: number
}

export type FetchRegionsListAction = FSA<undefined, Payload, string>
type FetchRegionsList = (countryId: number, page: number) => MrxThunk<FetchRegionsListAction>
type QueryResponse = GraphQLResponse<'regionsListPage', RegionsList>

const FETCH_REGIONS_LIST = `
  query fetchRegionsList ($page: Int, $size: Int, $filters: RegionsFilters!) {
    regionsListPage(page: $page, size: $size, filters: $filters) {
      items {
        id
        name
        iso2
      }
      total
    }
  }
`

const fetchRegionsList: FetchRegionsList =
  (countryId, page = 1) =>
  async (dispatch, getState) => {
    dispatch({
      type: t.FETCH_REGIONS_LIST,
      meta: { done: false }
    })

    try {
      const {
        regions: { pagination }
      } = getState()

      let size = 4
      if (pagination) {
        if (pagination[countryId]) {
          size = pagination[countryId].size
        }
      }
      const {
        data: { data, errors }
      }: QueryResponse = await axios.post('/graphql', {
        query: FETCH_REGIONS_LIST,
        variables: { page, size, filters: { countryId } }
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { regionsListPage } = data
      if (!regionsListPage) {
        throw new Error("Can't get regions list page!")
      }

      dispatch({
        type: t.FETCH_REGIONS_LIST,
        payload: {
          items: regionsListPage.items,
          total: regionsListPage.total,
          countryId,
          page
        },
        meta: { done: true }
      })
    } catch (err) {
      dispatch({
        type: t.FETCH_REGIONS_LIST,
        payload: extractErrorInfo(err),
        error: true
      })
    }
  }

export { fetchRegionsList }
