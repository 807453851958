import axios from 'utils/axios'
import hash from 'object-hash'
import { useQuery } from 'react-query'
import { TagOwner } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'simpleProductTagsForOptions', string[]>

const FETCH_TAGS_FOR_OPTIONS = `
  query simpleProductTagsForOptions ($filters: SimpleProductTagsForOptionsFilters!) {
    simpleProductTagsForOptions(filters: $filters)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type SimpleProductTagsForOptionsFilters = {
  campaignId: number
  ownerType: TagOwner
  searchText?: string
}

type FetchSimpleProductTagsForOptions = (args: SimpleProductTagsForOptionsFilters) => Promise<string[]>

const fetchSimpleProductTagsForOptions: FetchSimpleProductTagsForOptions = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_TAGS_FOR_OPTIONS,
      variables: {
        filters: args
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { simpleProductTagsForOptions } = data
    if (!simpleProductTagsForOptions) {
      throw new Error("Can't get simple product tags!")
    }

    return simpleProductTagsForOptions
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useSimpleProductTagsOptions = (filters: SimpleProductTagsForOptionsFilters) =>
  useQuery(['simpleProductTagsOptions', hash(filters)], () => fetchSimpleProductTagsForOptions(filters))
