import { ProductPriceOwner } from '@merchx-v2/shared-types'
import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Product } from 'features/types'

type ProductsList = {
  items: Product[]
  total: number
}

type Payload = ProductsList & {
  campaignId: number
  page: number
}

export type FetchProductsListForOldTableAction = FSA<undefined, Payload, string>

type FetchProductsListForOldTable = (
  productPriceOwnerType: ProductPriceOwner,
  productPriceOwnerId: number,
  campaignId: number,
  usePagination?: boolean,
  searchText?: string
) => MrxThunk<FetchProductsListForOldTableAction>
type QueryResponse = GraphQLResponse<'productsListPage', ProductsList>

const FETCH_PRODUCTS_LIST_FOR_OLD_TABLE = `
  query fetchProductsList ($page: Int, $size: Int, $filters: ProductsListPageFilters!, $productPriceOwnerType: ProductPriceOwner!, $productPriceOwnerId: Int!) {
    productsListPage(page: $page, size: $size, filters: $filters) {
      items {
        id
        campaignId
        campaign {
          id
          name
        }
        name
        productType
        prices(ownerType: $productPriceOwnerType, ownerId: $productPriceOwnerId) {
          id
          price
          discount
          attributes {
            id
            attribute
            value
          }
        }
        ownerId
        ownerType
        owner {
          id
          firstName
          lastName
          email
          createdFromLandingUrl
          createdAt
          updatedAt
        }
        productType2
        isActive
        sortIndex
      }
      total
    }
  }
`

const fetchProductsListForOldTable: FetchProductsListForOldTable =
  (productPriceOwnerType, productPriceOwnerId, campaignId, usePagination = true, searchText) =>
    async (dispatch, getState) => {
      dispatch({
        type: t.FETCH_PRODUCTS_LIST_FOR_OLD_TABLE,
        meta: { done: false }
      })

      try {
        const {
          products: { pagination },
          workspaces: {
            activeWorkspace: { id: workspaceId }
          }
        } = getState()

        let page = 1
        let size = 6
        if (pagination[campaignId]) {
          page = pagination[campaignId].currentPage
          size = pagination[campaignId].size
        }

        const {
          data: { data, errors }
        }: QueryResponse = await axios.post('/graphql', {
          query: FETCH_PRODUCTS_LIST_FOR_OLD_TABLE,
          variables: {
            productPriceOwnerType,
            productPriceOwnerId,
            page,
            size: usePagination ? size : 200,
            filters: { workspaceId, campaignId, searchText }
          }
        })

        guardFromErrors(errors)

        if (!data) {
          throw new Error('Response body is empty!')
        }

        const { productsListPage } = data
        if (!productsListPage) {
          throw new Error("Can't get products list page!")
        }

        dispatch({
          type: t.FETCH_PRODUCTS_LIST_FOR_OLD_TABLE,
          payload: {
            items: productsListPage.items,
            total: productsListPage.total,
            page,
            campaignId
          },
          meta: { done: true }
        })
      } catch (err) {
        dispatch({
          type: t.FETCH_PRODUCTS_LIST_FOR_OLD_TABLE,
          payload: extractErrorInfo(err),
          error: true
        })
      }
    }

export { fetchProductsListForOldTable }
