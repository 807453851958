export const root = {
  background: '#fff',
  display: 'flex',
  flex: 1,
  width: '100%',
  height: '100%'
}

export const header = {
  background: '#fff',
  padding: 0,
  minHeight: '5rem'
}

export const contentBreadcrumbs = {
  background: '#F0F2F5',
  padding: '0 16px',
  display: 'flex',
  maxHeight: '3rem',
  minHeight: '3rem'
}

export const buttons = {
  textTransform: 'uppercase'
}

export const contentComponent = {
  padding: '1.5rem',
  background: '#ffffff'
}
