import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Moment } from 'moment'

export type FetchCustomersCsvDataReportAction = FSA<undefined, undefined, string>
type FetchCustomersDataReports = (startDate: Moment, endDate: Moment) => MrxThunk<FetchCustomersCsvDataReportAction, Promise<string>>
type QueryResponse = GraphQLResponse<'customersDataReportExportToCSV', string>

const FETCH_CUSTOMERS_CSV_DATA_REPORT = `
  query CustomersDataExportToCSV($startDate: String!, $endDate: String!, $workspaceId: Int!) {
    customersDataReportExportToCSV(startDate: $startDate, endDate: $endDate, workspaceId: $workspaceId)
  }
`

const fetchCustomersCsvDataReport: FetchCustomersDataReports = (startDate, endDate) => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_CUSTOMERS_CSV_DATA_REPORT,
    meta: { done: false }
  })

  try {
    const {
      workspaces: {
        activeWorkspace: { id: workspaceId }
      }
    } = getState()

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_CUSTOMERS_CSV_DATA_REPORT,
      variables: { startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD'), workspaceId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { customersDataReportExportToCSV } = data
    if (!customersDataReportExportToCSV) {
      throw new Error("Can't fetch customers data CSV file url!")
    }

    dispatch({
      type: t.FETCH_CUSTOMERS_CSV_DATA_REPORT,
      meta: { done: true },
      payload: undefined
    })
    return customersDataReportExportToCSV
  } catch (err) {
    dispatch({
      type: t.FETCH_CUSTOMERS_CSV_DATA_REPORT,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchCustomersCsvDataReport }
