// TODO - remove becouse of legacy

import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Landing, LandingInput } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'createLanding', Landing>

const CREATE_LANDING = `
  mutation createLanding($landingData: LandingInput!) {
    createLanding(landingData: $landingData) {
      id
      name
      siteUrl
      workspaceId
      landingQuiz {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type CreateLanding = (landingData: LandingInput) => Promise<Landing>

const createLanding: CreateLanding = async (landingData) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_LANDING,
      variables: { landingData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createLanding } = data
    if (!createLanding) {
      throw new Error("Can't create landing!")
    }

    return createLanding
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type CreateLandingEvents = {
  onSettled: (data?: Landing) => void
}

const createLandingEvents: CreateLandingEvents = {
  onSettled: (landing) => {
    if (landing?.id) {
      queryClient.invalidateQueries(['landingsList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCreateLanding = () => useMutation(createLanding, createLandingEvents)
