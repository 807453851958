import axios from 'axios'
import settings from 'config/settings'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import * as workspaceFeature from 'features/workspaces'

import * as t from '../actionTypes'
import { logout } from './logout'
import { GraphQLUser, User } from '../types'

export type LoginByTokenAction = FSA<undefined, User, string>
type LoginByToken = (token: string) => MrxThunk<LoginByTokenAction, Promise<GraphQLUser>>
type QueryResponse = GraphQLResponse<'loginByToken', GraphQLUser>

const LOGIN_BY_TOKEN = `
  mutation LoginByToken($token: String!) {
    loginByToken(token: $token) {
      id
      email
      avatar
      firstName
      lastName
      lastLoginAt
      token
      refreshToken
      roles
      rememberMe
      workspacesRoles {
        workspaceId
        role
      }
      workspaces {
        id
        name
        createdAt
        updatedAt
      }
      defaultCampaignId
      defaultWorkspaceId
      activeWorkspace {
        id
        name
        autoArchiveCampaignsWithoutSales
        daysWithoutSalesToArchive
        deploymentPlugin
        deploymentSettingsId
        deploymentSettingsName
        paymentPlugin
        paymentSettingsId
        paymentSettingsName
        fulfillmentPlugin
        fulfillmentSettingsId
        fulfillmentSettingsName
        mailingPlugin
        mailingSettingsId
        mailingSettingsName
        smsPlugin
        smsSettingsId
        smsSettingsName
        shippingCost
        applyShippingOnce
        processingCost
        applyProcessingOnce
        allowMultiplePayments
      }
      createdAt
      updatedAt
    }
  }
`

export const loginByToken: LoginByToken = token => async (dispatch) => {
  dispatch({
    type: t.LOGIN,
    meta: {
      done: false
    }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post(`${settings.backendUrl}/graphql`, {
      query: LOGIN_BY_TOKEN,
      variables: { token }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Login by token response body is empty!')
    }

    const { loginByToken } = data
    if (!loginByToken) {
      throw new Error('Unauthorized!')
    }

    dispatch({
      type: t.LOGIN,
      payload: loginByToken,
      meta: { done: true }
    })

    dispatch(workspaceFeature.actions.activeWorkspaceLoaded(loginByToken.activeWorkspace))
    dispatch(workspaceFeature.actions.workspacesLoaded(loginByToken.workspaces))
    return loginByToken
  } catch (err) {
    dispatch({
      type: t.LOGIN,
      payload: extractErrorInfo(err),
      error: true
    })

    dispatch(logout())
  }
}
