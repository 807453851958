import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { CustomerSubscription } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'cancelCustomerSubscription', CustomerSubscription>

const CANCEL_CUSTOMER_SUBSCRIPTION = `
  mutation CancelCustomerSubscription ($subscriptionId: Int!) {
    cancelCustomerSubscription(subscriptionId: $subscriptionId) {
      id
      status
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type CancelCustomerSubscription = (subscriptionId: number) => Promise<CustomerSubscription>

const cancelCustomerSubscription: CancelCustomerSubscription = async (subscriptionId) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CANCEL_CUSTOMER_SUBSCRIPTION,
      variables: {
        subscriptionId
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { cancelCustomerSubscription } = data
    if (!cancelCustomerSubscription) {
      throw new Error("Can't cancel customer subscription!")
    }

    return cancelCustomerSubscription
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type CancelCustomerSubscriptionContext = { prevCustomerSubscription?: CustomerSubscription }

type CancelCustomerSubscriptionEvents = {
  onMutate: (subscriptionId: number) => Promise<CancelCustomerSubscriptionContext>
  onError: (error: string, subscriptionId: number, context: CancelCustomerSubscriptionContext) => void
  onSettled: (customerSubscription?: CustomerSubscription) => void
}

const cancelCustomerSubscriptionEvents: CancelCustomerSubscriptionEvents = {
  onMutate: async (subscriptionId) => {
    await queryClient.cancelQueries(['customerSubscription', subscriptionId])

    // Snapshot the previous value
    const prevCustomerSubscription = queryClient.getQueryData<CustomerSubscription>(['customerSubscription', subscriptionId])

    if (prevCustomerSubscription) {
      queryClient.removeQueries(['customerSubscription', subscriptionId])
    }

    return { prevCustomerSubscription }
  },
  onError: (_err, subscriptionId, context) => {
    if (context?.prevCustomerSubscription) {
      // Restore customer subscription on any error
      queryClient.setQueryData<CustomerSubscription>(['customerSubscription', subscriptionId], context.prevCustomerSubscription)
    }
  },
  onSettled: (customerSubscription: CustomerSubscription) => {
    if (customerSubscription?.id) {
      queryClient.invalidateQueries(['customerSubscription', customerSubscription.id])
      queryClient.invalidateQueries(['customerSubscriptionsList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCancelCustomerSubscription = () => useMutation(cancelCustomerSubscription, cancelCustomerSubscriptionEvents)
