import { BuildOwner, EnvironmentType } from '@merchx-v2/shared-types'
import * as t from '../actionTypes'
import backendAxios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { SiteBuildInfo } from '../types'

type DefaultPayload = {
  ownerType: BuildOwner
  ownerId: number
  environment: EnvironmentType
  version: number
}

type Payload = DefaultPayload & {
  buildInfo: SiteBuildInfo
}

type ErrorPayload = DefaultPayload & {
  error: string
}

export type TakeSiteBuildInfoAction = FSA<DefaultPayload, Payload, ErrorPayload>

type Args = {
  ownerType: BuildOwner
  ownerId: number
  environment: EnvironmentType
  version: number
}
type TakeSiteBuildInfo = (args: Args) => MrxThunk<TakeSiteBuildInfoAction>
type QueryResponse = GraphQLResponse<'siteBuildInfo', SiteBuildInfo>

const GET_SITE_BUILD_INFO = `
  query getSiteBuildInfo($ownerType: BuildOwnerType!, $ownerId: Int!, $environment: Environment!, $version: Int!) {
    siteBuildInfo(ownerType: $ownerType, ownerId: $ownerId, environment: $environment, version: $version) {
      version
      environment
      status
      progress
      artifactsUrl
      logs
    }
  }
`

const takeSiteBuildInfo: TakeSiteBuildInfo =
  ({ ownerType, ownerId, environment, version }) =>
  async (dispatch, getState) => {
    dispatch({
      type: t.TAKE_SITE_BUILD_INFO,
      payload: { ownerType, ownerId, environment, version },
      meta: { done: false }
    })

    try {
      const state = getState()
      if (!state.authentication.user) {
        throw new Error('User is undefined!')
      }

      const {
        data: { data, errors }
      }: QueryResponse = await backendAxios.post('/graphql', {
        query: GET_SITE_BUILD_INFO,
        variables: { ownerType, ownerId, environment, version }
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { siteBuildInfo } = data
      if (!siteBuildInfo) {
        throw new Error("Can't get site build info!")
      }

      dispatch({
        type: t.TAKE_SITE_BUILD_INFO,
        payload: { ownerType, ownerId, environment, version, buildInfo: siteBuildInfo },
        meta: { done: true }
      })
    } catch (err) {
      dispatch({
        type: t.TAKE_SITE_BUILD_INFO,
        payload: { ownerType, ownerId, environment, version, error: extractErrorInfo(err) },
        error: true
      })
    }
  }

export { takeSiteBuildInfo }
