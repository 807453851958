import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

export type RemoveDTGProductAction = FSA<undefined, number, string>
type RemoveDTGProduct = (DTGProduct: number) => MrxThunk<RemoveDTGProductAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'removeDTGProduct', boolean>

const REMOVE_DTG_PRODUCT = `
  mutation removeDTGProduct ($DTGProductId: Int!) {
    removeDTGProduct(DTGProductId: $DTGProductId) 
  }
`

const removeDTGProduct: RemoveDTGProduct = (DTGProductId) => async (dispatch) => {
  let isRemoved = false

  dispatch({
    type: t.REMOVE_DTG_PRODUCT,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_DTG_PRODUCT,
      variables: { DTGProductId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Empty response!')
    }

    const { removeDTGProduct } = data
    if (!removeDTGProduct) {
      throw new Error("Can't remove DTGProduct!")
    }

    dispatch({
      type: t.REMOVE_DTG_PRODUCT,
      payload: DTGProductId,
      meta: { done: true }
    })

    isRemoved = true
  } catch (err) {
    dispatch({
      type: t.REMOVE_DTG_PRODUCT,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isRemoved
}

export { removeDTGProduct }
