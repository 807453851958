import { useMutation } from 'react-query'
import axios from 'utils/axios'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Order } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'unholdOrder', Order>

const UNHOLD_ORDER = `
  mutation UnholdOrder($orderId: Int!) {
    unholdOrder(orderId: $orderId) {
      id
      status
      holdReasons
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UnholdOrderArgs = {
  orderId: number
}
type UnholdOrder = (args: UnholdOrderArgs) => Promise<Order>

const unholdOrder: UnholdOrder = async ({ orderId }) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UNHOLD_ORDER,
      variables: { orderId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { unholdOrder } = data
    if (!unholdOrder) {
      throw new Error("Can't unhold order!")
    }

    return unholdOrder
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UnholdOrderEvents = {
  onSettled: (data?: Order) => void
}

const unholdOrderEvents: UnholdOrderEvents = {
  onSettled: (order) => {
    if (order?.id) {
      queryClient.invalidateQueries(['order'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUnholdOrder = () => useMutation(unholdOrder, unholdOrderEvents)
