import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

type OwnProps = {
  awsCertificateId: number
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  return {
    isLoading: selectors.isLoadingSelector(state, 'isUpdateAwsCertificate'),
    awsCertificate: selectors.awsCertificateSelector(state, ownProps.awsCertificateId)
  }
}

const mapDispatchToProps = {
  fetchAwsCertificate: actions.fetchAwsCertificate,
  updateAwsCertificate: actions.updateAwsCertificate
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
