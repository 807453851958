import { useInfiniteQuery } from 'react-query'
import axios from 'utils/axios'
import { CurrenciesPageDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

const DEFAULT_PAGE_SIZE = 30

/// ////////////////////////////////////////////////////////////////////////////////
// TYPES
/// ////////////////////////////////////////////////////////////////////////////////

export type CurrenciesPage = CurrenciesPageDto & {
  currentPage: number
  pageSize: number
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'currenciesListPage', CurrenciesPage>

const FETCH_CURRENCIES_LIST = `
  query fetchCurrenciesList($page: Int, $size: Int) {
    currenciesListPage(page: $page, size: $size) {
      items {
        id
        name
        code
        symbol
        createdAt
        updatedAt
      }
      total
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type PageParam = {
  page?: number
  size?: number
  filters?: {
    limit?: number
    searchText?: string
  }
}

type FetchCurrenciesListArgs = {
  queryKey: string | string[]
  pageParam?: PageParam
}

type FetchCurrenciesList = (args: FetchCurrenciesListArgs) => Promise<CurrenciesPage>

const fetchCurrenciesList: FetchCurrenciesList = async ({ pageParam }) => {
  const { page = 1, size = DEFAULT_PAGE_SIZE, filters = undefined } = pageParam || {}
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_CURRENCIES_LIST,
      variables: { page, size, filters }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { currenciesListPage } = data
    if (!currenciesListPage) {
      throw new Error("Can't get currencies list page!")
    }

    return {
      items: currenciesListPage.items,
      total: currenciesListPage.total,
      currentPage: page,
      pageSize: size,
      filters
    }
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

type GetNextPageParam = (args: CurrenciesPage) => PageParam | undefined

type CurrenciesListEvents = {
  keepPreviousData: boolean
  getPreviousPageParam: GetNextPageParam
  getNextPageParam: GetNextPageParam
}

export const currenciesListEvents: CurrenciesListEvents = {
  keepPreviousData: true,
  getPreviousPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0 }) => {
    if (currentPage > 1) {
      return {
        page: currentPage - 1,
        size: pageSize
      }
    }

    return undefined
  },
  getNextPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, total = 0 }) => {
    if (pageSize * currentPage < total) {
      return {
        page: currentPage + 1,
        size: pageSize
      }
    }

    return undefined
  }
}

export const useCurrenciesList = () => {
  const cacheKey = ['currenciesList']

  return useInfiniteQuery(
    cacheKey,
    prev =>
      fetchCurrenciesList({
        queryKey: cacheKey,
        pageParam: { ...prev.pageParam }
      }),
    currenciesListEvents
  )
}
