import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'banUser', boolean>

const BAN_USER = `
  mutation banUser ($userId: Int!, $bannedTill: DateTime!) {
    banUser(userId: $userId, bannedTill: $bannedTill)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type BanUserArgs = {
  userId: number
  bannedTill: Date
}

type BanUser = (args: BanUserArgs) => Promise<number>

const banUser: BanUser = async ({ userId, bannedTill }) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: BAN_USER,
      variables: {
        userId,
        bannedTill
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { banUser } = data
    if (!banUser) {
      throw new Error("Can't ban user!")
    }

    return userId
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type BanUserEvents = {
  onSettled: (customerId?: number) => void
}

const banUserEvents: BanUserEvents = {
  onSettled: (userId) => {
    if (userId) {
      queryClient.invalidateQueries(['user', userId])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useBanUser = () => useMutation(banUser, banUserEvents)
