import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { ProductSize } from '../types'

type ProductsListSelector = (state: ReduxState) => ProductSize[]
const getProductsList: ProductsListSelector = (state) => {
  const { pages, currentPage } = state.productSizes.pagination
  return (pages[currentPage] || []).map((id) => state.productSizes[id])
}

export const productSizesListSelector = createSelector(getProductsList, (list) => list)
