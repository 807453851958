import React from 'react'
import { CloseOutlined } from '@ant-design/icons'
import styles from './TreeItemTitle.module.scss'

type Props = {
  title: string
  onRemove: () => void
}

const TreeItemTitle = (props: Props) => {
  const { title, onRemove } = props

  const handleTitleClicked = () => {
    console.log('Handle on click')
  }

  const handleCancelClicked = () => {
    onRemove()
  }

  return (
    <div className={styles.titleContainer}>
      <div onClick={handleTitleClicked} className={styles.subtitle}>
        {title}
      </div>
      <CloseOutlined style={{ color: 'tomato' }} onClick={handleCancelClicked} />
    </div>
  )
}

export { TreeItemTitle }
