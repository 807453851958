import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { DTGProduct } from '../types'

export type FetchDTGProductsForOptionsAction = FSA<undefined, DTGProduct[], string>
type FetchDTGProductsForOptions = (searchText?: string) => MrxThunk<FetchDTGProductsForOptionsAction>
type QueryResponse = GraphQLResponse<'DTGProductsForOptions', DTGProduct[]>

const FETCH_DTG_PRODUCT_FOR_OPTIONS = `
  query DTGProductsForOptions($searchText: String) {
    DTGProductsForOptions(searchText: $searchText) {
      id
      name
    }
  }
`

const fetchDTGProductsForOptions: FetchDTGProductsForOptions = searchText => async (dispatch) => {
  dispatch({
    type: t.FETCH_DTG_PRODUCT_FOR_OPTIONS,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_DTG_PRODUCT_FOR_OPTIONS,
      variables: { searchText }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { DTGProductsForOptions } = data
    if (!DTGProductsForOptions) {
      throw new Error("Can't get DTGProducts!")
    }

    dispatch({
      type: t.FETCH_DTG_PRODUCT_FOR_OPTIONS,
      payload: DTGProductsForOptions,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_DTG_PRODUCT_FOR_OPTIONS,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchDTGProductsForOptions }
