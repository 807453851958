// TODO - remove becouse of legacy

import axios from 'utils/axios'
import { useQuery } from 'react-query'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Template, TemplatesFilters } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'templatesForOptions', Template[]>

const FETCH_TEMPLATES_FOR_OPTIONS = `
  query fetchTemplatesForOptions ($filters: TemplatesFilters!) {
    templatesForOptions(filters: $filters) {
      id
      name
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchTemplatesForOptions = (filters: TemplatesFilters) => Promise<Template[]>

const fetchTemplatesForOptions: FetchTemplatesForOptions = async (filters) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_TEMPLATES_FOR_OPTIONS,
      variables: { filters }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { templatesForOptions } = data
    if (!templatesForOptions) {
      throw new Error("Can't get templates!")
    }

    return templatesForOptions
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useTemplatesOptions = (filters: TemplatesFilters) =>
  useQuery(['templatesOptions', filters], () => fetchTemplatesForOptions(filters))
