import React, { useState, useEffect, useRef } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Drawer, Input, Form, Select } from 'antd'
import notification from 'mrx-notification'
import styles from './CreateCustomGatewaySetting.module.scss'
import { PropsFromRedux, connector } from './container'

const CreateCustomGatewaySetting = (props: PropsFromRedux) => {
  const [isVisible, setVisible] = useState(false)
  const refEl = useRef<Input>(null)

  const { isLoading, createCustomGatewaySetting } = props

  useEffect(() => {
    if (isVisible) {
      refEl.current && refEl.current.focus()
    }
  }, [isVisible])

  const showDrawer = () => {
    setVisible(!isVisible)
  }

  const [form] = Form.useForm()

  const handleClose = () => {
    setVisible(false)
    form.resetFields()
  }

  const onFinishHandler = async (values: any) => {
    const isSaved = await createCustomGatewaySetting({
      name: values.name,
      shippingCarrier: values.shippingCarrier,
      carrierCode: values.carrierCode,
      serviceCode: values.serviceCode,
      clientId: values.clientId,
      clientSecret: values.clientSecret,
      retailerApiKey: values.retailerApiKey,
      retailerCompanyRefId: values.retailerCompanyRefId,
      supplierApiKey: values.supplierApiKey,
      supplierCompanyRefId: values.supplierCompanyRefId,
      internalLogin: values.internalLogin,
      internalPassword: values.internalPassword
    })
    if (isSaved) {
      notification.success({
        message: 'Successfully',
        description: 'Custom Gateway Setting was created successfully!'
      })
      handleClose()
      handleClose()
    }
  }

  return (
    <>
      <Button type='primary' onClick={showDrawer}>
        <PlusOutlined />
        CREATE
      </Button>
      <Drawer title='Create Custom Gateway Setting' width='400' onClose={handleClose} visible={isVisible} forceRender>
        <Form
          layout='vertical'
          hideRequiredMark
          onFinish={onFinishHandler}
          form={form}
          style={{ paddingBottom: '40px' }}
        >
          <Form.Item
            name='name'
            label='Name'
            rules={[{ required: true, message: 'Please input Custom Gateway setting name!' }]}
          >
            <Input ref={refEl} />
          </Form.Item>
          <Form.Item
            name='clientId'
            label='Client ID'
            rules={[{ required: true, message: 'Please input Custom Gateway setting Client ID!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='clientSecret'
            label='Client Secret'
            rules={[{ required: true, message: 'Please input Custom Gateway setting Client Secret!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='retailerApiKey'
            label='Retailer API Key'
            rules={[{ required: true, message: 'Please input Custom Gateway setting Retailer API Key!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='retailerCompanyRefId'
            label='Retailer Company Ref Id'
            rules={[{ required: true, message: 'Please input Custom Gateway setting Retailer Company Ref Id!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='supplierApiKey'
            label='Supplier API Key'
            rules={[{ required: true, message: 'Please input Custom Gateway setting Supplier API Key!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='supplierCompanyRefId'
            label='Supplier Company Ref Id'
            rules={[{ required: true, message: 'Please input Custom Gateway setting Supplier Company Ref Id!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='internalLogin'
            label='Internal Login'
            rules={[{ required: true, message: 'Please input Custom Gateway setting Internal Login!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='internalPassword'
            label='Internal Password'
            rules={[{ required: true, message: 'Please input Custom Gateway setting Internal Password!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='carrierCode'
            label='Carrier Code'
            rules={[{ required: true, message: 'Please input Custom Gateway setting Carrier Code!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='serviceCode'
            label='Service Code'
            rules={[{ required: true, message: 'Please input Custom Gateway setting Service Code!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='shippingCarrier'
            label='Shipping Carrier'
            rules={[{ required: true, message: 'Please input Custom Gateway setting Shipping Carrier!' }]}
          >
            <Select>
              <Select.Option value='ShipStation'>ShipStation</Select.Option>
            </Select>
          </Form.Item>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button onClick={handleClose} className={styles.createButton}>
              Close
            </Button>
            <Button type='primary' htmlType='submit' loading={isLoading}>
              Create
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

export default connector(CreateCustomGatewaySetting)
