import * as eternalListsFeature from 'features/eternalLists'
import { Product, SubscriptionItem } from 'features/types'
import * as t from '../actionTypes'

type Payload = {
  index: number
  filters: { campaignId: number }
  item: { id: number; subscriptionItems: SubscriptionItem[] }
  list: 'products'
}

type Args = {
  productId: number
  campaignId: number
  subscriptionItems: SubscriptionItem[]
  index: number
}

export type UpdateProductSubscriptionsAction = BaseFSA<Payload>

export const updateProductSubscriptions =
  (args: Args): MrxThunk<eternalListsFeature.actions.UpdateEternalItemAction<'products'>> =>
  (dispatch) => {
    const { productId, campaignId, subscriptionItems, index } = args

    dispatch({
      type: t.UPDATE_PRODUCT_SUBSCRIPTIONS,
      payload: {
        index,
        filters: { campaignId },
        item: { id: productId, subscriptionItems } as Product,
        list: 'products'
      },
      meta: { done: true }
    })
    dispatch({
      type: eternalListsFeature.actionTypes.UPDATE_ETERNAL_LIST_ITEM,
      payload: {
        index,
        filters: { campaignId },
        item: { id: productId, subscriptionItems } as Product,
        list: 'products'
      },
      meta: { done: true }
    })
  }
