import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

type Payload = {
  productId: number
}

export type UpdateVirtualProductAction = FSA<undefined, Payload, string>

type UpdateVirtualProduct = (args: Payload) => MrxThunk<UpdateVirtualProductAction, Promise<boolean>>

type QueryResponse = GraphQLResponse<'updateVirtualProduct', boolean>

const UPDATE_VIRTUAL_PRODUCT = `
  mutation updateVirtualProduct($productId: Int!) {
    updateVirtualProduct(productId: $productId)
  }
`

const updateVirtualProduct: UpdateVirtualProduct = (args) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.UPDATE_VIRTUAL_PRODUCT,
    meta: { done: false }
  })

  try {
    const { productId } = args

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_VIRTUAL_PRODUCT,
      variables: { productId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateVirtualProduct } = data
    if (!updateVirtualProduct) {
      throw new Error("Can't update product!")
    }

    dispatch({
      type: t.UPDATE_VIRTUAL_PRODUCT,
      payload: args,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.UPDATE_VIRTUAL_PRODUCT,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { updateVirtualProduct }
