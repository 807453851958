import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { TopCampaign } from '../types'

export type FetchTopCampaignsListAction = FSA<undefined, TopCampaign[], string>
type FetchTopCampaignsList = (startDate: Date, endDate: Date) => MrxThunk<FetchTopCampaignsListAction>
type QueryResponse = GraphQLResponse<'topCampaignsReport', TopCampaign[]>

const FETCH_TOP_CAMPAIGNS_REPORT = `
  query fetchTopCampaignsList ($startDate: DateTime!, $endDate: DateTime!, $workspaceId: Int!) {
  topCampaignsReport(startDate: $startDate, endDate: $endDate, workspaceId: $workspaceId) {
    id
    name
    total
    ordersCount
  }
}
`

const fetchTopCampaignsReport: FetchTopCampaignsList = (startDate, endDate) => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_TOP_CAMPAIGNS_REPORT,
    meta: { done: false }
  })

  try {
    const {
      workspaces: {
        activeWorkspace: { id: workspaceId }
      }
    } = getState()
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_TOP_CAMPAIGNS_REPORT,
      variables: { startDate, endDate, workspaceId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { topCampaignsReport } = data
    if (!topCampaignsReport) {
      throw new Error("Can't get Top Campaigns report!")
    }

    dispatch({
      type: t.FETCH_TOP_CAMPAIGNS_REPORT,
      payload: topCampaignsReport,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_TOP_CAMPAIGNS_REPORT,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchTopCampaignsReport }
