import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type IsLoadingSelector = (state: ReduxState) => boolean

const getIsLoadingSelectorByKey = (state: ReduxState) => state.authentication.UIState.isLoading

const isLoadingSelector: IsLoadingSelector = createSelector(getIsLoadingSelectorByKey, (isLoading) => isLoading)

export default isLoadingSelector
