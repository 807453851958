import React, { useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import moment, { Moment } from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Table, Layout, PageHeader, Tag, Form, Button, Input, Drawer, Select, DatePicker } from 'antd'
import { DownloadOutlined, EditTwoTone } from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table'
import { OrderStatus, ORDER_STATUS } from '@merchx-v2/shared-types'
import notification from 'mrx-notification'
import { Breadcrumbs } from 'components'
import * as rootStyles from 'assets/layoutStyle'
import { Order } from 'features/orders/types'
import OrderDetails from '../OrderDetails'
import { ProductTagsSelector } from 'features/tags/components'
import styles from './OrdersList.module.scss'
import { useOrdersList, useOrdersListCsv } from 'features/orders/hooks'
import { replaceOrderStatus } from '../../helpers'

const tagColors: Record<OrderStatus, string> = {
  PENDING: 'purple',
  HOLD: 'purple',
  DISPATCHED: 'purple',
  SHIPPING: 'orange',
  FAILED: 'error',
  CANCELED: 'red',
  CANCELLING: 'magenta',
  COMPLETED: 'green',
  NEW: 'cyan',
  OPENED: 'default'
}

const { RangePicker } = DatePicker
const dateFormat = 'MM/DD/YYYY'

const OrdersList = () => {
  const [sorting, setSorting] = useState<SortingType>()
  const { history, location } = useReactRouter()
  const [searchQuery, setSearchQuery] = useState<string>()
  const [selectedStatus, setSelectedStatus] = useState<string>()
  const [selectedSource, setSelectedSource] = useState<string>()
  const [datesRange, setDateRange] = useState<[Moment, Moment]>([null, null])

  const [isVisible, setVisible] = useState(false)
  const [productTags, setProductTags] = useState([])

  useEffect(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!sessionStorage.getItem('orderStatus')) {
      setSelectedStatus(sessionStorage.getItem('orderStatus'))
    }
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!sessionStorage.getItem('orderSource')) {
      setSelectedSource(sessionStorage.getItem('orderSource'))
    }
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!sessionStorage.getItem('orderProductTags')) {
      setProductTags(sessionStorage.getItem('orderProductTags').split(','))
    }

    const dateFrom = sessionStorage.getItem('orderDatesRangeFrom')
      ? moment(sessionStorage.getItem('orderDatesRangeFrom'))
      : moment().startOf('day').subtract(1, 'week')
    const dateTo = sessionStorage.getItem('orderDatesRangeTo')
      ? moment(sessionStorage.getItem('orderDatesRangeTo'))
      : moment().add(1, 'day').endOf('day')
    setDateRange([dateFrom, dateTo])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ordersList = useOrdersList({
    query: searchQuery,
    status: selectedStatus,
    statusNotIn: [ORDER_STATUS.PENDING],
    saleSource: selectedSource,
    saleSourceNotIn: ['invoice'],
    sorting: sorting,
    startDate: datesRange[0],
    endDate: datesRange[1],
    productTags
  })

  const orders: Order[] = []
  ordersList && ordersList.data && ordersList.data.pages.forEach((page) => page.items.forEach((order) => orders.push(order)))

  const page = ordersList.data?.pages.length ? ordersList.data?.pages[ordersList.data?.pages.length - 1].currentPage : 1
  const size = ordersList.data?.pages.length ? ordersList.data?.pages[ordersList.data?.pages.length - 1].pageSize : 30

  const ordersListCvv = useOrdersListCsv({
    status: selectedStatus,
    saleSource: selectedSource,
    saleSourceNotIn: ['invoice'],
    startDate: datesRange[0] && datesRange[0].toDate(),
    endDate: datesRange[1] && datesRange[1].toDate(),
    productTags
  })

  useEffect(() => {
    if (ordersList.error) {
      notification.error({
        message: 'Orders list page error!',
        description: ordersList.error instanceof Error ? ordersList.error.message : ordersList.error.toString()
      })
    }
  }, [ordersList.error])

  useEffect(() => {
    if (ordersListCvv.error) {
      notification.error({
        message: 'Orders list csv error!',
        description: ordersListCvv.error instanceof Error ? ordersListCvv.error.message : ordersListCvv.error.toString()
      })
    }
  }, [ordersListCvv.error])

  useEffect(() => {
    ordersListCvv.data && window.open(ordersListCvv.data, '_blank')
  }, [ordersListCvv.data])

  const sortingHandler = (pagination, filters, sorter) => {
    if (sorter?.order) {
      setSorting({ field: sorter.field || sorter.columnKey, order: sorter.order === 'ascend' ? 'ASC' : 'DESC' })
    } else {
      setSorting(undefined)
    }
  }

  const [form] = Form.useForm()

  const columns: ColumnsType<Order> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true
    },
    {
      title: 'Date',
      render: (_value, record) => moment(record.createdAt).format('MM/DD/YYYY HH:mm:ss'),
      key: 'createdAt',
      sorter: true
    },
    {
      title: 'Status',
      render: (_value, record) => <Tag color={tagColors[record.status]}>{replaceOrderStatus(record.status)}</Tag>,
      key: 'status'
    },
    {
      title: 'Customer',
      render: (_value, record) => `${record.customer?.firstName || ''} ${record.customer?.lastName || ''}`,
      key: 'customer'
    },
    {
      title: 'Promocode',
      dataIndex: 'promocode',
      key: 'promocode'
    },
    {
      title: 'Tracking Number',
      dataIndex: 'trackingNumber',
      key: 'trackingNumber'
    },
    {
      title: 'Sale source',
      dataIndex: 'saleSource',
      key: 'saleSource'
    },
    {
      title: 'Cycle',
      key: 'cycle',
      dataIndex: 'cycle'
    },
    {
      title: 'Logged In',
      render: (_value, record) => record.loggedIn ? 'Yes' : 'No',
      key: 'loggedIn'
    },
    {
      title: 'Multipurchaser',
      render: (_value, record) => record.multipurchaser ? 'Yes' : 'No',
      key: 'multipurchaser'
    },
    {
      title: 'Shipping',
      render: (_value, record) => `$${(record.shipping / 100).toFixed(2)}`,
      key: 'shipping',
      align: 'right',
      sorter: true
    },
    {
      title: 'Total',
      render: (_value, record) => `$${(record.total / 100).toFixed(2)}`,
      key: 'total',
      align: 'right',
      sorter: true
    },
    {
      title: '',
      dataIndex: '',
      key: 'edit',
      width: '10%',
      align: 'center',
      render: (record) => (
        <span>
          <EditTwoTone
            style={{ fontSize: '20px' }}
            onClick={(e) => {
              e.stopPropagation()
              history.push(`/sales/orders?orderId=${record.id}`)
            }}
          />
        </span>
      )
    }
  ]

  const handleDrawerClose = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleOnOrderDetailsClose = () => {
    history.push(`/sales/orders`)
  }

  const handleStatusChange = (value) => {
    setSelectedStatus(value)
    if (value) {
      sessionStorage.setItem('orderStatus', value)
    } else {
      sessionStorage.removeItem('orderStatus')
    }
  }

  const handleSourceChange = (value) => {
    setSelectedSource(value)
    if (value) {
      sessionStorage.setItem('orderSource', value)
    } else {
      sessionStorage.removeItem('orderSource')
    }
  }

  const changeDatesRange = (dates: [Moment, Moment]) => {
    if (dates !== null) {
      setDateRange(dates)
      sessionStorage.setItem('orderDatesRangeFrom', dates[0].toString())
      sessionStorage.setItem('orderDatesRangeTo', dates[1].toString())
    }
  }

  const onSearchInputChangeHandler = (value) => {
    setDateRange([null, null])
    setSearchQuery(value)
  }

  const orderId = location.search && location.search.match(/\d+/g).toString()

  const handleChangeProductTags = (tags: string[]) => {
    setProductTags(tags)
    if (tags.length) {
      sessionStorage.setItem('orderProductTags', tags.join(','))
    } else {
      sessionStorage.removeItem('orderProductTags')
    }
  }

  const handleExportCSV = () => ordersListCvv.refetch()

  const drawerFooter = (
    <Button type='primary' htmlType='submit' loading={ordersList.isLoading} onClick={handleDrawerClose}>
      Close
    </Button>
  )

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title='Orders'
          extra={[
            <Button key='1' onClick={() => setVisible(!isVisible)}>
              Settings
            </Button>,
            <Input.Search
              key='search-field'
              placeholder='Search'
              onSearch={(value) => onSearchInputChangeHandler(value)}
              style={{ width: 200 }}
              allowClear
            />,
            <Button
              key='export-to-csv-button'
              icon={<DownloadOutlined />}
              disabled={ordersListCvv.isLoading}
              loading={ordersListCvv.isLoading}
              onClick={handleExportCSV}
            >
              CSV
            </Button>
          ]}
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Sales', url: '/' },
            { title: 'Orders', url: '/' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <InfiniteScroll
          style={{ paddingBottom: '30px' }}
          dataLength={page * size}
          next={ordersList.fetchNextPage}
          hasMore={!!ordersList.hasNextPage}
          loader={<h4>Loading...</h4>}
          refreshFunction={ordersList.refetch}
          scrollThreshold={0.8}
        >
          <Table
            columns={columns}
            dataSource={orders.flat()}
            bordered
            rowKey={(record) => record.id}
            loading={ordersList.isLoading}
            onChange={sortingHandler}
            pagination={false}
          />
        </InfiniteScroll>
      </Layout.Content>
      {orderId && <OrderDetails orderId={+orderId} onClose={handleOnOrderDetailsClose} />}
      <Drawer
        title='Order Data Settings'
        width='400'
        onClose={handleDrawerClose}
        visible={isVisible}
        footer={drawerFooter}
        footerStyle={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <Form
          layout='vertical'
          hideRequiredMark
          form={form}
          initialValues={{ status: selectedStatus, saleSource: selectedSource }}
        >
          <Form.Item name='status' label='Status'>
            <Select key='3' placeholder='Please select Status' onChange={handleStatusChange} allowClear value={selectedStatus}>
              <Select.Option value='SHIPPING' key='SHIPPING'>
                Shipping
              </Select.Option>
              <Select.Option value='FAILED' key='FAILED'>
                Failed
              </Select.Option>
              <Select.Option value='CANCELED' key='CANCELED'>
                Canceled
              </Select.Option>
              <Select.Option value='CANCELLING' key='CANCELLING'>
                Cancelling
              </Select.Option>
              <Select.Option value='COMPLETED' key='COMPLETED'>
                Completed
              </Select.Option>
              <Select.Option value='NEW' key='NEW'>
                New
              </Select.Option>
              <Select.Option value='OPENED' key='OPENED'>
                Opened
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='saleSource' label='Source'>
            <Select key='4' placeholder='Please select Source' onChange={handleSourceChange} allowClear value={selectedSource}>
              <Select.Option value='email' key='email'>
                Email
              </Select.Option>
              <Select.Option value='facebook' key='facebook'>
                Facebook
              </Select.Option>
              <Select.Option value='ecom' key='ecom'>
                Ecom
              </Select.Option>
              <Select.Option value='subscription' key='google shopping'>
                Subscription
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label='Dates range'>
            <RangePicker
              className={styles.rangePicker}
              defaultValue={datesRange}
              format={dateFormat}
              ranges={{
                Today: [moment().startOf('day'), moment().add(1, 'day').endOf('day')],
                'Last 7 days': [moment().subtract(7, 'days'), moment().startOf('day')],
                'This Week': [moment().startOf('week'), moment().endOf('week')],
                'This Month': [moment().startOf('month'), moment().endOf('month')]
              }}
              onChange={changeDatesRange}
            />
          </Form.Item>
          <Form.Item label='Product tags'>
            <ProductTagsSelector onChange={handleChangeProductTags} value={productTags} />
          </Form.Item>
        </Form>
      </Drawer>
    </Layout>
  )
}

export default OrdersList
