import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { NginxSetting } from '../types'

type NginxSettingSelector = (state: ReduxState, nginxSettingId?: number) => NginxSetting | undefined

const getNginxSetting: NginxSettingSelector = (state, nginxSettingId) => {
  let result
  if (nginxSettingId && Object.prototype.hasOwnProperty.call(state.nginxSettings.nginxSettings, nginxSettingId)) {
    result = state.nginxSettings.nginxSettings[nginxSettingId] as NginxSetting
  }
  return result
}

export const nginxSettingSelector: NginxSettingSelector = createSelector(getNginxSetting, (nginxSetting) => nginxSetting)
