import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import FormData from 'form-data'
import { SettingOwner } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Setting } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'uploadSettingImage', boolean>

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type AssetDataInput = {
  name: string
  file: any
  isTemplatePreview?: boolean
}

type UploadSettingImageArgs = {
  ownerType: SettingOwner
  ownerId: number
  settingId: number
  assetData: AssetDataInput
}

type UploadSettingImage = (args: UploadSettingImageArgs) => Promise<UploadSettingImageArgs>

const uploadSettingImage: UploadSettingImage = async (args) => {
  try {
    const formData = new FormData()

    formData.append(
      'operations',
      JSON.stringify({
        query: `
         mutation UploadSettingImage($ownerType: AssetOwner!, $ownerId: Int!, $settingId: Int!, $assetData: AssetInput!) {
            uploadSettingImage(ownerType: $ownerType, ownerId: $ownerId, settingId: $settingId, assetData: $assetData) {
              id
              name
              ownerType
              ownerId
              ownerVersion
              alias
              type
              value
              jsonValue
            }}
        `,
        variables: {
          ownerType: args.ownerType,
          ownerId: args.ownerId,
          settingId: args.settingId,
          assetData: {
            name: args.assetData.name,
            file: null
          }
        }
      })
    )
    formData.append('map', JSON.stringify({ 0: ['variables.assetData.file'] }))
    formData.append('0', args.assetData.file)

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', formData)

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { uploadSettingImage } = data
    if (!uploadSettingImage) {
      throw new Error("Can't upload setting image!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UploadSettingImageContext = { prevSetting?: Setting }

type UploadSettingImageEvents = {
  onMutate: (variables: UploadSettingImageArgs) => Promise<UploadSettingImageContext>
  onError: (error: string, variables: UploadSettingImageArgs, context: UploadSettingImageContext) => void
  onSettled: (data?: UploadSettingImageArgs) => void
}

const uploadSettingImageEvents: UploadSettingImageEvents = {
  onMutate: async (variables: UploadSettingImageArgs) => {
    await queryClient.cancelQueries(['setting', variables.settingId])

    // Snapshot the previous value
    const prevSetting = queryClient.getQueryData<Setting>(['setting', variables.settingId])

    // Optimistically update to the new values
    if (prevSetting) {
      queryClient.setQueryData<Setting>(['setting', variables.settingId], {
        ...prevSetting,
        id: variables.settingId,
        ...variables
      })
    }

    return { prevSetting }
  },
  onError: (_err, variables, context) => {
    if (context?.prevSetting) {
      // Restore previous version of setting on any error
      queryClient.setQueryData<Setting>(['setting', variables.settingId], context.prevSetting)
    }
  },
  onSettled: (data) => {
    if (data?.settingId) {
      queryClient.invalidateQueries(['template'])
      queryClient.invalidateQueries(['landing'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUploadSettingImage = () => useMutation(uploadSettingImage, uploadSettingImageEvents)
