import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { CustomGatewaySetting } from '../types'

type CustomGatewaySettingsForOptionsSelector = (state: ReduxState) => CustomGatewaySetting[]
const getCustomGatewaySettingsForOptions: CustomGatewaySettingsForOptionsSelector = state =>
  state.customGatewaySettings.customGatewaySettingsForOptions || ([] as CustomGatewaySetting[])

export const customGatewaySettingsForOptionsSelector: CustomGatewaySettingsForOptionsSelector = createSelector(
  getCustomGatewaySettingsForOptions,
  customGatewaySettings => customGatewaySettings
)
