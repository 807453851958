import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { OrderEvent, OrderNote } from '../types'

type UpdateOrderTrackingNumberArgs = {
  orderId: number
  trackingNumber: string
  isUpdated?: boolean
  orderEvent?: OrderEvent
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'updateOrderTrackingNumber', UpdateOrderTrackingNumberArgs>

const UPDATE_ORDER_TRACKING_NUMBER = `
  mutation updateOrderTrackingNumber($orderId: Int!, $trackingNumber: String!) {
    updateOrderTrackingNumber(orderId: $orderId, trackingNumber: $trackingNumber) {
      isUpdated
      orderEvent {
        id
        event
      }
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateOrderTrackingNumber = (args: UpdateOrderTrackingNumberArgs) => Promise<UpdateOrderTrackingNumberArgs>

const updateOrderTrackingNumber: UpdateOrderTrackingNumber = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_ORDER_TRACKING_NUMBER,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateOrderTrackingNumber } = data
    if (!updateOrderTrackingNumber) {
      throw new Error("Can't update order tracking number!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateOrderTrackingNumberContext = { prevOrderTrackingNumber?: any }

type UpdateOrderTrackingNumberEvents = {
  onMutate: (variables: UpdateOrderTrackingNumberArgs) => Promise<UpdateOrderTrackingNumberContext>
  onError: (error: string, variables: UpdateOrderTrackingNumberArgs, context: UpdateOrderTrackingNumberContext) => void
  onSettled: (data?: UpdateOrderTrackingNumberArgs) => void
}

const updateOrderTrackingNumberEvents: UpdateOrderTrackingNumberEvents = {
  onMutate: async (variables: UpdateOrderTrackingNumberArgs) => {
    await queryClient.cancelQueries(['orderTrackingNumber', variables.orderId])

    // Snapshot the previous value
    const prevOrderTrackingNumber = queryClient.getQueryData<any>(['orderTrackingNumber', variables.orderId])

    // Optimistically update to the new values
    if (prevOrderTrackingNumber) {
      queryClient.setQueryData<OrderNote>(['orderTrackingNumber', variables.orderId], {
        ...prevOrderTrackingNumber,
        id: variables.orderId,
        ...variables
      })
    }

    return { prevOrderTrackingNumber }
  },
  onError: (_err, variables, context) => {
    if (context?.prevOrderTrackingNumber) {
      // Restore previous version of order note on any error
      queryClient.setQueryData<any>(['orderTrackingNumber', variables.orderId], context.prevOrderTrackingNumber)
    }
  },
  onSettled: (data) => {
    if (data?.isUpdated) {
      queryClient.invalidateQueries(['orderTrackingNumber', data.orderEvent])
      queryClient.invalidateQueries(['order'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUpdateOrderTrackingNumber = () => useMutation(updateOrderTrackingNumber, updateOrderTrackingNumberEvents)
