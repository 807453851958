import React, { useState, useEffect, useContext } from 'react'
import useReactRouter from 'use-react-router'
import styled from 'styled-components'
import ImgCrop from 'antd-img-crop'
import { Button, Layout, PageHeader, Divider, Input, Select, DatePicker, Form, Popconfirm, Upload, Modal, UploadFile } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import {
  CrmPromocodeDto,
  PRODUCT_TYPE,
  PromocodeAvailabilityType,
  PromocodeConditionType,
  PromocodeConditionValuesDto,
  PromocodeExpirationType,
  PromocodeLogicType,
  PromocodeLogicValueDto,
  PROMOCODE_AVAILABILITY_TYPE,
  PROMOCODE_CONDITION_TYPE,
  PROMOCODE_EXPIRATION_TYPE,
  PROMOCODE_LOGIC_TYPE
} from '@merchx-v2/shared-types'
import moment from 'moment'
import notification from 'mrx-notification'
import slugify from 'slugify'
import { GlobalContext } from 'appContexts'
import * as rootStyles from 'assets/layoutStyle'
import { Breadcrumbs } from 'components'
import { useCampaign } from 'features/campaigns/hooks'
import { useRemoveAsset, useUploadAsset } from 'features/assets/hooks'
import { useCreatePromocode, useDeactivatePromocode, usePromocode, useUpdatePromocode, useUpdatePromocodeImage } from '../../hooks'
import styles from './EditPromocode.module.scss'
import { connector, PropsFromRedux } from './container'

const StyledUpload = styled(Upload)`
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 165px;
  }
`
const getBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const uploadButton = (
  <div style={{ width: '100%', height: '165px' }}>
    <div style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <PlusOutlined style={{ fontSize: '45px', color: '#dddddd' }} />
    </div>
  </div>
)

const productTypesForOptions = {
  [PRODUCT_TYPE.CREW]: 'Crew',
  [PRODUCT_TYPE.FACE_MASK]: 'Face Mask',
  [PRODUCT_TYPE.HOODIE]: 'Hoodie',
  [PRODUCT_TYPE.LONG_SLEEVED_SHIRT]: 'Long Sleeved Shirt',
  [PRODUCT_TYPE.STICKER]: 'Sticker',
  [PRODUCT_TYPE.TOTEBAG]: 'Totebag',
  [PRODUCT_TYPE.TSHIRT]: 'T-Shirt',
  [PRODUCT_TYPE.POSTER]: 'Poster',
  [PRODUCT_TYPE.MUG]: 'Mug',
  [PRODUCT_TYPE.HAT]: 'Hat',
  [PRODUCT_TYPE.STAINLESS_STEEL_WATER_BOTTLE]: 'Stainless Steel Water Bottle',
  [PRODUCT_TYPE.EMBROIDERED_BASEBALL_HAT]: 'Embroidered Baseball hat'
}

type CreatePromocodeFormStore = {
  code: string

  headline: string
  title: string
  buttonText: string
  description: string
  descriptionHeadline: string

  condition: PromocodeConditionType
  conditionValue?: PromocodeConditionValuesDto

  logicType: PromocodeLogicType
  logicValue: PromocodeLogicValueDto

  availabilities?: PromocodeAvailabilityType[]

  expirationTypes: PromocodeExpirationType[]
  expirationValues?: Record<PromocodeExpirationType, string>

  images?: UploadFile[]
}

const CreatePromocode = ({ promocodeCode }: PropsFromRedux) => {
  const { history } = useReactRouter()

  const [seoUrlPrefix, setSeoUrlPrefix] = useState('siteurl.com/')

  const { campaignId } = useContext(GlobalContext)

  const promocode = usePromocode(promocodeCode)
  const createPromocode = useCreatePromocode()
  const uploadAsset = useUploadAsset()
  const removeAsset = useRemoveAsset()
  const updatePromocode = useUpdatePromocode()
  const updatePromocodeImage = useUpdatePromocodeImage()
  const deactivatePromocode = useDeactivatePromocode(promocodeCode)

  const [form] = Form.useForm()

  const code = Form.useWatch('code', form)
  const expirationValues = Form.useWatch('expirationValues', form)

  const campaign = useCampaign(campaignId)

  const [previewVisible, setPreviewVisible] = useState<boolean>(false)
  const [previewImage, setPreviewImage] = useState<string>('')
  const [previewTitle, setPreviewTitle] = useState<string>('')

  const handleCancel = () => setPreviewVisible(false)
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  useEffect(() => {
    return () => {
      promocode.remove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (campaign?.data !== null) {
      if (campaign.data?.hasCustomDomain && campaign.data?.domain.name) {
        setSeoUrlPrefix(`https://${campaign.data.domain.name}/`)
      } else {
        setSeoUrlPrefix(`https://${campaign.data?.defaultDomain}.merchx.com/`)
      }
    }
  }, [campaign])

  useEffect(() => {
    if (promocode.error) {
      notification.error({
        message: 'Promocodes error!',
        description: promocode.error instanceof Error ? promocode.error.message : promocode.error.toString()
      })
    }
  }, [promocode.error])

  useEffect(() => {
    if (removeAsset.error) {
      notification.error({
        message: 'Error!',
        description: removeAsset.error.toString()
      })
    }
  }, [removeAsset.error])

  useEffect(() => {
    if (removeAsset.isSuccess) {
      notification.success({
        message: 'Success!',
        description: 'Asset was delete successfully'
      })
    }
  }, [removeAsset.isSuccess])

  useEffect(() => {
    if (uploadAsset.error) {
      notification.error({
        message: 'Error!',
        description: uploadAsset.error instanceof Error ? uploadAsset.error.message : uploadAsset.error.toString()
      })
    }
  }, [uploadAsset.error])

  useEffect(() => {
    if (uploadAsset.isSuccess) {
      notification.success({
        message: 'Success!',
        description: 'Asset was upload successfully'
      })
    }
  }, [uploadAsset.isSuccess])

  useEffect(() => {
    if (!promocode.data?.promocode || !promocode.data?.promocodePostgres) return

    const SUBTOTAL_GREATER =
      promocode.data.promocode.condition === 'SUBTOTAL_GREATER' ? (+promocode.data.promocode.conditionValue / 100).toFixed(2) : 0
    const PRODUCTS_IN_CART: PromocodeConditionValuesDto['PRODUCTS_IN_CART'] =
      promocode.data.promocode.condition === 'PRODUCTS_IN_CART'
        ? JSON.parse(promocode.data.promocode.conditionValue || '{}')
        : {
            PRODUCT_TYPE: undefined,
            NUMBER_OF_PRODUCTS_GREATER: 0
          }
    const QUANTITY_AND_NETTO_TOTAL_GREATER: PromocodeConditionValuesDto['QUANTITY_AND_NETTO_TOTAL_GREATER'] =
      promocode.data.promocode.condition === 'QUANTITY_AND_NETTO_TOTAL_GREATER'
        ? JSON.parse(promocode.data.promocode.conditionValue || '{}')
        : {
            PRODUCTS_QUANTITY_GREATER: 0,
            NETTO_TOTAL_GREATER: 0
          }
    if (QUANTITY_AND_NETTO_TOTAL_GREATER.NETTO_TOTAL_GREATER) {
      // @ts-ignore
      QUANTITY_AND_NETTO_TOTAL_GREATER.NETTO_TOTAL_GREATER = (+QUANTITY_AND_NETTO_TOTAL_GREATER.NETTO_TOTAL_GREATER / 100).toFixed(2)
    }

    const conditionValue: PromocodeConditionValuesDto = {
      // @ts-ignore
      SUBTOTAL_GREATER,
      PRODUCTS_IN_CART,
      QUANTITY_AND_NETTO_TOTAL_GREATER
    }

    const FIXED_DISCOUNT_FROM_SUBTOTAL =
      promocode.data.promocode.logicType === 'FIXED_DISCOUNT_FROM_SUBTOTAL' ? (+promocode.data.promocode.logicValue / 100).toFixed(2) : 0
    const PERCENT_DISCOUNT_FROM_SUBTOTAL =
      promocode.data.promocode.logicType === 'PERCENT_DISCOUNT_FROM_SUBTOTAL' ? +promocode.data.promocode.logicValue : 0
    const PERCENT_DISCOUNT_FOR_ALL_PRODUCT_TYPES: PromocodeLogicValueDto['PERCENT_DISCOUNT_FOR_ALL_PRODUCT_TYPES'] =
      promocode.data.promocode.logicType === 'PERCENT_DISCOUNT_FOR_ALL_PRODUCT_TYPES'
        ? JSON.parse(promocode.data.promocode.logicValue)
        : undefined
    const PERCENT_DISCOUNT_FOR_ONE_PRODUCT_TYPE: PromocodeLogicValueDto['PERCENT_DISCOUNT_FOR_ONE_PRODUCT_TYPE'] =
      promocode.data.promocode.logicType === 'PERCENT_DISCOUNT_FOR_ONE_PRODUCT_TYPE'
        ? JSON.parse(promocode.data.promocode.logicValue)
        : undefined
    const FREE_SHIPPING: PromocodeLogicValueDto['FREE_SHIPPING'] = promocode.data.promocode.logicType === 'FREE_SHIPPING' ? 100 : undefined

    const logicValue: PromocodeLogicValueDto = {
      // @ts-ignore
      FIXED_DISCOUNT_FROM_SUBTOTAL,
      PERCENT_DISCOUNT_FROM_SUBTOTAL,
      PERCENT_DISCOUNT_FOR_ALL_PRODUCT_TYPES,
      PERCENT_DISCOUNT_FOR_ONE_PRODUCT_TYPE,
      FREE_SHIPPING
    }

    const expirationValues: Partial<Record<PromocodeExpirationType, string>> = {}
    const expirationTypes = JSON.parse(promocode.data.promocode.expirationTypes || '[]')
    const parsedExpirationValues = JSON.parse(promocode.data.promocode.expirationValues || '[]')

    for (let i = 0; i < expirationTypes.length; i++) {
      const exp = expirationTypes[i]

      expirationValues[exp] = parsedExpirationValues[i]
    }

    if (expirationValues.ACTIVE_TILL) {
      // @ts-ignore
      expirationValues.ACTIVE_TILL = moment(expirationValues.ACTIVE_TILL)
    }

    form.setFieldsValue({
      code: promocode.data.promocode.code,

      headline: promocode.data.promocode.headline,
      title: promocode.data.promocode.title,
      buttonText: promocode.data.promocode.buttonText,
      description: promocode.data.promocode.description,
      descriptionHeadline: promocode.data.promocode.descriptionHeadline,

      condition: promocode.data.promocode.condition,
      conditionValue,

      logicType: promocode.data.promocode.logicType,
      logicValue,

      availabilities: JSON.parse(promocode.data.promocode.availabilities || '[]'),

      expirationTypes,
      expirationValues,

      images:
        promocode.data.promocodePostgres.assets.map((item) => ({
          uid: item.id + '',
          name: item.name,
          status: 'done',
          size: 0,
          type: item.mimeType,
          url: item.signedUrl
        })) || []
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promocode.data])

  const handleSubmit = async (values: CreatePromocodeFormStore) => {
    const conditionValue = values.conditionValue || {}

    if (conditionValue?.SUBTOTAL_GREATER) {
      conditionValue.SUBTOTAL_GREATER = Math.round(conditionValue.SUBTOTAL_GREATER * 100)
    }
    if (conditionValue?.PRODUCTS_IN_CART?.NUMBER_OF_PRODUCTS_GREATER) {
      conditionValue.PRODUCTS_IN_CART.NUMBER_OF_PRODUCTS_GREATER = +conditionValue.PRODUCTS_IN_CART.NUMBER_OF_PRODUCTS_GREATER
    }
    if (conditionValue?.QUANTITY_AND_NETTO_TOTAL_GREATER?.NETTO_TOTAL_GREATER) {
      conditionValue.QUANTITY_AND_NETTO_TOTAL_GREATER.NETTO_TOTAL_GREATER = Math.round(
        conditionValue.QUANTITY_AND_NETTO_TOTAL_GREATER.NETTO_TOTAL_GREATER * 100
      )
    }
    if (conditionValue?.QUANTITY_AND_NETTO_TOTAL_GREATER?.PRODUCTS_QUANTITY_GREATER) {
      conditionValue.QUANTITY_AND_NETTO_TOTAL_GREATER.PRODUCTS_QUANTITY_GREATER =
        +conditionValue.QUANTITY_AND_NETTO_TOTAL_GREATER.PRODUCTS_QUANTITY_GREATER
    }

    const logicValue = values.logicValue || {}
    if (logicValue?.FIXED_DISCOUNT_FROM_SUBTOTAL) {
      logicValue.FIXED_DISCOUNT_FROM_SUBTOTAL = Math.round(logicValue.FIXED_DISCOUNT_FROM_SUBTOTAL * 100)
    }
    if (logicValue?.PERCENT_DISCOUNT_FROM_SUBTOTAL) {
      logicValue.PERCENT_DISCOUNT_FROM_SUBTOTAL = +logicValue.PERCENT_DISCOUNT_FROM_SUBTOTAL
    }
    if (logicValue?.PERCENT_DISCOUNT_FOR_ONE_PRODUCT_TYPE?.PERCENT_DISCOUNT_FROM_PRODUCT_TYPE) {
      logicValue.PERCENT_DISCOUNT_FOR_ONE_PRODUCT_TYPE.PERCENT_DISCOUNT_FROM_PRODUCT_TYPE =
        +logicValue.PERCENT_DISCOUNT_FOR_ONE_PRODUCT_TYPE.PERCENT_DISCOUNT_FROM_PRODUCT_TYPE
    }
    if (logicValue?.PERCENT_DISCOUNT_FOR_ALL_PRODUCT_TYPES?.PERCENT_DISCOUNT_FROM_PRODUCT_TYPE) {
      logicValue.PERCENT_DISCOUNT_FOR_ALL_PRODUCT_TYPES.PERCENT_DISCOUNT_FROM_PRODUCT_TYPE =
        +logicValue.PERCENT_DISCOUNT_FOR_ALL_PRODUCT_TYPES.PERCENT_DISCOUNT_FROM_PRODUCT_TYPE
    }
    if (values.logicType === 'FREE_SHIPPING') {
      logicValue.FREE_SHIPPING = 100
    }

    const expirationValues = values.expirationValues || {}

    const data = {
      code: values.code,

      headline: values.headline,
      title: values.title,
      buttonText: values.buttonText,
      descriptionHeadline: values.descriptionHeadline,
      description: values.description,

      condition: values.condition,
      conditionValue,

      logicType: values.logicType,
      logicValue,

      availabilities: values.availabilities || [],

      expirationTypes: values.expirationTypes,
      expirationValues: values.expirationTypes?.map((exp) => expirationValues[exp])
    }

    let currPromocode: CrmPromocodeDto = promocode.data?.promocodePostgres
    let isSaved = false

    if (promocode.data) {
      await updatePromocode.mutateAsync(data, {
        onSuccess: () => {
          notification.success({
            message: 'Successfully',
            description: 'Promocode was updated successfully!'
          })
          isSaved = true
        },
        onError: (error: any) => {
          notification.error({
            message: 'Update promocode error!',
            description: error instanceof Error ? error.message : error.toString()
          })
        }
      })
    } else {
      const newPromocode = await createPromocode.mutateAsync(
        { ...data },
        {
          onSuccess: () => {
            notification.success({
              message: 'Successfully',
              description: 'Promocode was created successfully!'
            })
            isSaved = true
          },
          onError: (error: any) => {
            notification.error({
              message: 'Create promocode error!',
              description: error instanceof Error ? error.message : error.toString()
            })
          }
        }
      )

      currPromocode = newPromocode.createdPromocodePostgres
    }

    if (!isSaved) return

    const newMainImages =
      values.images?.filter((fileToUpload) => {
        return !fileToUpload.url || fileToUpload.thumbUrl || isNaN(+fileToUpload.uid)
      }) || []

    const mainImagesToRemove =
      promocode.data?.promocodePostgres.assets.filter((fileToRemove) => {
        return !values.images?.some((item) => +item.uid === fileToRemove.id)
      }) || []

    for (let i = 0; i < mainImagesToRemove.length; i++) {
      await removeAsset.mutateAsync({ ownerType: 'PROMOCODE', ownerId: currPromocode.id, assetId: mainImagesToRemove[i].id })
      await updatePromocodeImage.mutateAsync({ code: currPromocode.code, imageUrl: '' })
    }

    for (let i = 0; i < newMainImages.length; i++) {
      const fileToUpload = newMainImages[i]

      const asset = await uploadAsset.mutateAsync({
        ownerType: 'PROMOCODE',
        ownerId: currPromocode.id,
        assetData: { name: fileToUpload.name, type: fileToUpload.type, filename: fileToUpload.name, role: 'DEFAULT' },
        file: fileToUpload.originFileObj as File
      })

      await updatePromocodeImage.mutateAsync({ code: currPromocode.code, imageUrl: `${asset.s3bucket}/${asset.s3key}` })
    }

    history.goBack()
  }

  const doConfirm = () => {
    if (!promocodeCode) return

    deactivatePromocode.mutate(undefined, {
      onSuccess: () => {
        notification.success({
          message: 'Successfully',
          description: 'Promocode was deactivated successfully!'
        })

        history.goBack()
      },
      onError: (error: any) => {
        notification.error({
          message: 'Deactivate promocode error!',
          description: error instanceof Error ? error.message : error.toString()
        })
      }
    })
  }

  const onFinishFailed = (errors) => {
    const description = errors.errorFields.map((item) => item.errors.join('\n')).join('\n')
    notification.error({
      message: `${promocode.data ? 'Update' : 'Create'} Promocode error!`,
      description
    })
  }

  return (
    <Layout style={rootStyles.root} className={styles.baseContainer}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title={promocode.data ? 'Promocode Details' : 'Create promocode'}
          extra={[
            promocode.data ? (
              <Popconfirm
                key='cancel-button'
                title={`Are you sure deactivate this promocode?`}
                onConfirm={() => doConfirm()}
                onCancel={(e) => e?.stopPropagation()}
                okText='Yes'
                cancelText='No'
              >
                <Button
                  disabled={
                    deactivatePromocode.isLoading ||
                    (expirationValues?.ACTIVE_TILL && moment(expirationValues.ACTIVE_TILL).isBefore(moment())) ||
                    updatePromocode.isLoading ||
                    updatePromocodeImage.isLoading ||
                    createPromocode.isLoading ||
                    uploadAsset.isLoading ||
                    removeAsset.isLoading
                  }
                  key='2'
                >
                  Deactivate promocode
                </Button>
              </Popconfirm>
            ) : null
          ]}
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Promocodes', url: '/promocodes' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <Form
          layout='vertical'
          hideRequiredMark
          form={form}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
          disabled={
            createPromocode.isLoading ||
            updatePromocode.isLoading ||
            updatePromocodeImage.isLoading ||
            uploadAsset.isLoading ||
            removeAsset.isLoading ||
            deactivatePromocode.isLoading
          }
        >
          <div className={styles.container}>
            <Form.Item
              name='code'
              label='Code'
              rules={[{ required: true, message: 'Please input Code!' }]}
              normalize={(val) => slugify(val?.toUpperCase(), { lower: false, remove: /[*+~.()'"!:@{}]/g })}
            >
              <Input disabled={!!promocode.data} />
            </Form.Item>

            <Form.Item name='headline' label='Headline' rules={[{ required: true, message: 'Please input Headline!' }]}>
              <Input />
            </Form.Item>

            <Form.Item name='title' label='Title' rules={[{ required: true, message: 'Please input Title!' }]}>
              <Input />
            </Form.Item>

            <Form.Item name='buttonText' label='Button Text' rules={[{ required: true, message: 'Please input Button text!' }]}>
              <Input />
            </Form.Item>

            <Form.Item
              name='descriptionHeadline'
              label='Description Headline'
              rules={[{ required: true, message: 'Please input Description Headline!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item name='description' label='Description' rules={[{ required: true, message: 'Please input Description!' }]}>
              <Input />
            </Form.Item>

            <Form.Item noStyle dependencies={[['images']]}>
              {(formInstance) => {
                const images: UploadFile[] = formInstance.getFieldValue('images')

                return (
                  <>
                    <Form.Item name='images' valuePropName='fileList' label='Promocode Image' getValueFromEvent={normFile}>
                      <ImgCrop aspect={336 / 150} shape='rect' rotate beforeCrop={(file) => file.type !== 'image/gif'} quality={1}>
                        <StyledUpload
                          onPreview={handlePreview}
                          accept='image/*'
                          className={styles.variantImage}
                          multiple
                          maxCount={5}
                          listType='picture-card'
                          fileList={images}
                          onChange={(e) => {
                            const generatedList = {
                              images: normFile(e)
                            }
                            formInstance.setFieldsValue(generatedList)
                          }}
                        >
                          {images?.length ? null : uploadButton}
                        </StyledUpload>
                      </ImgCrop>
                    </Form.Item>
                    <Modal open={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
                      <img alt='example' style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                  </>
                )
              }}
            </Form.Item>
          </div>

          <div className={styles.container}>
            <Form.Item name='condition' label='Condition'>
              <Select placeholder='Select Condition' allowClear>
                <Select.Option value={PROMOCODE_CONDITION_TYPE.PRODUCTS_IN_CART}>Products in cart</Select.Option>
                <Select.Option value={PROMOCODE_CONDITION_TYPE.SUBTOTAL_GREATER}>Subtotal greater</Select.Option>
                <Select.Option value={PROMOCODE_CONDITION_TYPE.QUANTITY_AND_NETTO_TOTAL_GREATER}>
                  Quantity and netto total greater
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item noStyle dependencies={[['condition']]}>
              {(form) => {
                if (form.getFieldValue('condition') !== 'SUBTOTAL_GREATER') return null

                return (
                  <Form.Item
                    name={['conditionValue', 'SUBTOTAL_GREATER']}
                    label='Subtotal Greater'
                    rules={[{ required: true, message: 'Please input Condition Value!' }]}
                  >
                    <Input type='number' prefix='$' />
                  </Form.Item>
                )
              }}
            </Form.Item>

            <Form.Item noStyle dependencies={[['condition']]}>
              {(form) => {
                if (form.getFieldValue('condition') !== 'PRODUCTS_IN_CART') return null

                return (
                  <>
                    <Form.Item name={['conditionValue', 'PRODUCTS_IN_CART', 'PRODUCT_TYPE']} label='Product Type'>
                      <Select placeholder='Select Product Type' allowClear>
                        {Object.keys(productTypesForOptions).map((item) => (
                          <Select.Option key={item} value={item}>
                            {productTypesForOptions[item]}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name={['conditionValue', 'PRODUCTS_IN_CART', 'NUMBER_OF_PRODUCTS_GREATER']}
                      label='Number of Products Greater'
                      rules={[{ required: true, message: 'Please input Condition Value!' }]}
                    >
                      <Input suffix='qty' type='number' />
                    </Form.Item>
                  </>
                )
              }}
            </Form.Item>

            <Form.Item noStyle dependencies={[['condition']]}>
              {(form) => {
                if (form.getFieldValue('condition') !== 'QUANTITY_AND_NETTO_TOTAL_GREATER') return null

                return (
                  <>
                    <Form.Item
                      name={['conditionValue', 'QUANTITY_AND_NETTO_TOTAL_GREATER', 'PRODUCTS_QUANTITY_GREATER']}
                      label='Products Quantity Greater'
                    >
                      <Input suffix='qty' type='number' />
                    </Form.Item>

                    <Form.Item
                      name={['conditionValue', 'QUANTITY_AND_NETTO_TOTAL_GREATER', 'NETTO_TOTAL_GREATER']}
                      label='Netto Total Greater'
                      rules={[{ required: true, message: 'Please input Condition Value!' }]}
                    >
                      <Input type='number' />
                    </Form.Item>
                  </>
                )
              }}
            </Form.Item>
          </div>

          <div className={styles.container}>
            <Form.Item name='logicType' label='Logic Type' rules={[{ required: true, message: 'Please input Logic Type!' }]}>
              <Select placeholder='Select Logic Type'>
                <Select.Option value={PROMOCODE_LOGIC_TYPE.FIXED_DISCOUNT_FROM_SUBTOTAL}>Fixed Discount From Subtotal</Select.Option>
                <Select.Option value={PROMOCODE_LOGIC_TYPE.PERCENT_DISCOUNT_FROM_SUBTOTAL}>Percent Discount From Subtotal</Select.Option>
                <Select.Option value={PROMOCODE_LOGIC_TYPE.PERCENT_DISCOUNT_FOR_ALL_PRODUCT_TYPES}>
                  Percent Discount for All Product Types
                </Select.Option>
                <Select.Option value={PROMOCODE_LOGIC_TYPE.PERCENT_DISCOUNT_FOR_ONE_PRODUCT_TYPE}>
                  Percent Discount for One Product Type
                </Select.Option>
                <Select.Option value={PROMOCODE_LOGIC_TYPE.FREE_SHIPPING}>Free Shipping</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item noStyle dependencies={[['logicType']]}>
              {(form) => {
                if (form.getFieldValue('logicType') !== 'FIXED_DISCOUNT_FROM_SUBTOTAL') return null

                return (
                  <Form.Item
                    name={['logicValue', 'FIXED_DISCOUNT_FROM_SUBTOTAL']}
                    label='Fixed Discount from Subtotal'
                    rules={[{ required: true, message: 'Please input Fixed Discount!' }]}
                  >
                    <Input type='number' prefix='$' />
                  </Form.Item>
                )
              }}
            </Form.Item>

            <Form.Item noStyle dependencies={[['logicType']]}>
              {(form) => {
                if (form.getFieldValue('logicType') !== 'PERCENT_DISCOUNT_FROM_SUBTOTAL') return null

                return (
                  <Form.Item
                    name={['logicValue', 'PERCENT_DISCOUNT_FROM_SUBTOTAL']}
                    label='Percent Discount from Subtotal'
                    rules={[{ required: true, message: 'Please input Percent Discount!' }]}
                  >
                    <Input type='number' prefix='$' />
                  </Form.Item>
                )
              }}
            </Form.Item>

            <Form.Item noStyle dependencies={[['logicType']]}>
              {(form) => {
                if (form.getFieldValue('logicType') !== 'PERCENT_DISCOUNT_FOR_ONE_PRODUCT_TYPE') return null

                return (
                  <>
                    <Form.Item
                      name={['logicValue', 'PERCENT_DISCOUNT_FOR_ONE_PRODUCT_TYPE', 'PRODUCT_TYPE']}
                      label='Product Type'
                      rules={[{ required: true, message: 'Please input Product Type!' }]}
                    >
                      <Select placeholder='Select Product Type'>
                        {Object.keys(productTypesForOptions).map((item) => (
                          <Select.Option key={item} value={item}>
                            {productTypesForOptions[item]}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name={['logicValue', 'PERCENT_DISCOUNT_FOR_ONE_PRODUCT_TYPE', 'PERCENT_DISCOUNT_FROM_PRODUCT_TYPE']}
                      label='Percent Discount from Product Type'
                      rules={[{ required: true, message: 'Please input Percent Discount from Product Type!' }]}
                    >
                      <Input suffix='%' type='number' />
                    </Form.Item>
                  </>
                )
              }}
            </Form.Item>

            <Form.Item noStyle dependencies={[['logicType']]}>
              {(form) => {
                if (form.getFieldValue('logicType') !== 'PERCENT_DISCOUNT_FOR_ALL_PRODUCT_TYPES') return null

                return (
                  <>
                    <Form.Item
                      name={['logicValue', 'PERCENT_DISCOUNT_FOR_ALL_PRODUCT_TYPES', 'PRODUCT_TYPE']}
                      label='Product Type'
                      rules={[{ required: true, message: 'Please input Product Type!' }]}
                    >
                      <Select placeholder='Select Product Type'>
                        {Object.keys(productTypesForOptions).map((item) => (
                          <Select.Option key={item} value={item}>
                            {productTypesForOptions[item]}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name={['logicValue', 'PERCENT_DISCOUNT_FOR_ALL_PRODUCT_TYPES', 'PERCENT_DISCOUNT_FROM_PRODUCT_TYPE']}
                      label='Percent Discount from Product Type'
                      rules={[{ required: true, message: 'Please input Percent Discount from Product Type!' }]}
                    >
                      <Input suffix='%' type='number' />
                    </Form.Item>
                  </>
                )
              }}
            </Form.Item>
          </div>

          <div className={styles.container}>
            <Form.Item name='availabilities' label='Availabilities'>
              <Select placeholder='Select Availabilities' allowClear mode='multiple'>
                <Select.Option value={PROMOCODE_AVAILABILITY_TYPE.LOGGED_IN_USERS}>Logged In</Select.Option>
                <Select.Option value={PROMOCODE_AVAILABILITY_TYPE.ONE_TIME_PER_LOGGED_USER}>One Time per User</Select.Option>
                <Select.Option value={PROMOCODE_AVAILABILITY_TYPE.VIP_ONLY}>VIP Only</Select.Option>
              </Select>
            </Form.Item>
          </div>

          <div className={styles.container}>
            <Form.Item name='expirationTypes' label='Expirations'>
              <Select placeholder='Select Expirations' allowClear mode='multiple'>
                <Select.Option value={PROMOCODE_EXPIRATION_TYPE.ACTIVE_TILL}>Active Till</Select.Option>
                <Select.Option value={PROMOCODE_EXPIRATION_TYPE.NUMBER_OF_USES}>Number of Uses</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item noStyle dependencies={[['expirationTypes']]}>
              {(form) => {
                const expirationTypes = form.getFieldValue('expirationTypes') || []

                if (!expirationTypes.includes('ACTIVE_TILL')) return null

                return (
                  <Form.Item
                    name={['expirationValues', 'ACTIVE_TILL']}
                    label='Active Till'
                    initialValue={moment()}
                    rules={[{ required: true, message: 'Please input Expiration Value!' }]}
                  >
                    <DatePicker format='MM/DD/YYYY' />
                  </Form.Item>
                )
              }}
            </Form.Item>

            <Form.Item noStyle dependencies={[['expirationTypes']]}>
              {(form) => {
                const expirationTypes = form.getFieldValue('expirationTypes') || []

                if (!expirationTypes.includes('NUMBER_OF_USES')) return null

                return (
                  <Form.Item
                    name={['expirationValues', 'NUMBER_OF_USES']}
                    label='Number of Uses'
                    rules={[
                      { required: true, message: 'Please input Expiration Value!' },
                      () => ({
                        validator(_, value) {
                          if (promocode.data) return Promise.resolve()
                          if (value > promocode.data.promocode.usages) return Promise.resolve()

                          return Promise.reject(new Error("Number of Uses shouldn't be less than actual usages"))
                        }
                      })
                    ]}
                  >
                    <Input prefix={promocode.data ? `${promocode.data?.promocode?.usages || 0} of ` : ''} />
                  </Form.Item>
                )
              }}
            </Form.Item>
          </div>

          <div className={styles.container}>
            <div className={styles.labeledItem}>
              <div className={styles.examplePageTitle}>Home</div>
              <div className={styles.exampleUrl}>{seoUrlPrefix + `?promocode=${code}`}</div>
              <div className={styles.exampleDescription}>Home Description</div>
            </div>
            <div className={styles.labeledItem}>
              <div className={styles.examplePageTitle}>Store Title</div>
              <div className={styles.exampleUrl}>{seoUrlPrefix + `{storeUrl}?promocode=${code}`}</div>
              <div className={styles.exampleDescription}>Store Description</div>
            </div>
            <div className={styles.labeledItem}>
              <div className={styles.examplePageTitle}>Search page</div>
              <div className={styles.exampleUrl}>{seoUrlPrefix + `search?promocode=${code}`}</div>
              <div className={styles.exampleDescription}>Product Description</div>
            </div>
            <div className={styles.labeledItem}>
              <div className={styles.examplePageTitle}>Product Title</div>
              <div className={styles.exampleUrl}>{seoUrlPrefix + `products/{productUrl}?promocode=${code}`}</div>
              <div className={styles.exampleDescription}>Product Description</div>
            </div>
            <div className={styles.labeledItem}>
              <div className={styles.examplePageTitle}>Cart Page</div>
              <div className={styles.exampleUrl}>{seoUrlPrefix + `cart?promocode=${code}`}</div>
              <div className={styles.exampleDescription}>Cart Page Description</div>
            </div>
          </div>
          <Divider />
        </Form>
      </Layout.Content>
      <Layout.Footer className={styles.footer}>
        <Button onClick={() => history.goBack()}>Cancel</Button>
        <Button
          type='primary'
          onClick={form.submit}
          loading={
            createPromocode.isLoading ||
            updatePromocode.isLoading ||
            updatePromocodeImage.isLoading ||
            uploadAsset.isLoading ||
            removeAsset.isLoading ||
            deactivatePromocode.isLoading
          }
        >
          Submit
        </Button>
      </Layout.Footer>
    </Layout>
  )
}

export default connector(CreatePromocode)
