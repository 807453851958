import React from 'react'
import { Form, InputNumber } from 'antd'
import notification from 'mrx-notification'
import { SettingOwner, SettingType } from '@merchx-v2/shared-types'
import { useUpdateSettingValue } from '../../hooks'
import { Setting } from '../../types'

type PropsType = {
  ownerType: SettingOwner
  ownerId: number
  setting: Setting
  onValueChanged?: (setting: Setting, newValue: any) => void
  selfUpdate?: boolean
}

const FormItemIntegerSetting = (props: PropsType) => {
  const { selfUpdate = false, setting, ownerType, ownerId, onValueChanged } = props

  const updateSettingValue = useUpdateSettingValue()

  const handleOnValueChanged = async (value) => {
    if (onValueChanged) {
      onValueChanged(setting, value + '')
    }

    if (selfUpdate) {
      updateSettingValue.mutate(
        {
          ownerType,
          ownerId,
          settingId: setting.id,
          settingData: {
            name: setting.name,
            alias: setting.alias,
            type: setting.type as SettingType,
            value: value + '',
            required: setting.required
          }
        },
        {
          onSuccess: () => {
            notification.success({
              message: 'Successfully',
              description: `${setting.name} setting was updated!`
            })
          },
          onError: (error) => {
            notification.error({
              message: `${setting.name} setting error!`,
              description: error.toString()
            })
          }
        }
      )
    }
  }

  return (
    <Form.Item
      name={setting.alias}
      label={setting.name}
      key={setting.id}
      initialValue={setting.value}
      rules={[{ required: setting.required, message: `Please input "${setting.name}" value` }]}
    >
      <InputNumber style={{ width: '100%' }} onChange={handleOnValueChanged} />
    </Form.Item>
  )
}

export default FormItemIntegerSetting
