import { ColumnsType } from 'antd/lib/table'
import { CustomerDto } from '@merchx-v2/shared-types'

export const createTableColumns = (): ColumnsType<CustomerDto> => {
  return [
    {
      title: 'ID',
      dataIndex: 'id'
    },
    {
      title: 'First Name',
      dataIndex: 'firstName'
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName'
    },
    {
      title: 'Email',
      dataIndex: 'email'
    }
  ]
}
