import React from 'react'
import Editor from 'react-simple-code-editor'
import Prism, { highlight, languages } from 'prismjs'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import './highlightingSettings.css'
import styles from './CodeEditor.module.scss'
require('prismjs/components/prism-jsx')

type Props = {
  value: string
  isCss?: boolean
  onChange: (value: string) => void
}

Prism.hooks.add('wrap', env => {
  if (env.type === 'tag') {
    if (
      env.content === '<span class="token punctuation">&lt;</span>param' ||
      env.content === '<span class="token punctuation">&lt;/</span>param'
    ) {
      env.classes.push('param')
    }
  }
})

const CodeEditor: React.FC<Props> = ({ value = '', onChange, isCss = false }) => {
  const handleEditorChange = (value: string) => {
    onChange(value)
  }

  return (
    <div className={styles.codeEditor}>
      <Editor
        value={value}
        onValueChange={handleEditorChange}
        highlight={code => (isCss ? highlight(code, languages.css, 'css') : highlight(code, languages.jsx, 'jsx'))}
        padding={10}
        textareaClassName={styles.codeTextArea}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 12,
          overflow: 'auto',
          width: '100%',
          height: '100%'
        }}
      />
    </div>
  )
}

export default CodeEditor
