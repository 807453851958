export const CHANGE_CURRENT_PAGE = 'products/CHANGE_CURRENT_PAGE'
export const CHANGE_PAGE_SIZE = 'products/CHANGE_PAGE_SIZE'
export const CREATE_PRODUCT = 'products/CREATE_PRODUCT'
export const DUPLICATE_PRODUCT = 'products/DUPLICATE_PRODUCT'
export const FETCH_PRODUCT = 'products/FETCH_PRODUCT'
export const FETCH_PRODUCTS_LIST = 'products/FETCH_PRODUCTS_LIST'
export const FETCH_PRODUCTS_LIST_FOR_OLD_TABLE = 'products/FETCH_PRODUCTS_LIST_FOR_OLD_TABLE'
export const FETCH_PRODUCTS_FOR_OPTIONS = 'products/FETCH_PRODUCTS_FOR_OPTIONS'
export const FETCH_ORDER_ITEM_PRODUCTS_FOR_OPTIONS = 'products/FETCH_ORDER_ITEM_PRODUCTS_FOR_OPTIONS'
export const PRODUCTS_LOADED = 'products/PRODUCTS_LOADED'
export const PRODUCT_PRICES_LOADED = 'products/PRODUCT_PRICES_LOADED'
export const REMOVE_PRODUCT = 'products/REMOVE_PRODUCT'
export const SAVE_SUBSCRIPTION_DATA = 'landings/SAVE_SUBSCRIPTION_DATA'
export const SET_PRODUCT_PRICES = 'products/SET_PRODUCT_PRICES'
export const UPDATE_PRODUCT = 'products/UPDATE_PRODUCT'
export const SET_ACTIVE_PRODUCT = 'products/SET_ACTIVE_PRODUCT'
export const UPDATE_VIRTUAL_PRODUCT = 'products/UPDATE_VIRTUAL_PRODUCT'
export const FETCH_PRODUCT_AVAILABLE_ATTRIBUTE_VALUES = 'products/FETCH_PRODUCT_AVAILABLE_ATTRIBUTE_VALUES'
export const UPDATE_PRODUCT_SUBSCRIPTIONS = 'products/UPDATE_PRODUCT_SUBSCRIPTIONS'
