import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Campaign } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'archiveCampaign', boolean>

const ARCHIVE_CAMPAIGN = `
  mutation archiveCampaign($campaignId: Int!) {
    archiveCampaign(campaignId: $campaignId)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type ArchiveCampaign = (campaignId: number) => Promise<number>

const archiveCampaign: ArchiveCampaign = async (campaignId) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: ARCHIVE_CAMPAIGN,
      variables: { campaignId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { archiveCampaign } = data
    if (!archiveCampaign) {
      throw new Error("Can't archive campaign!")
    }

    return campaignId
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type ArchiveCampaignContext = { prevCampaign?: Campaign }

type ArchiveCampaignEvents = {
  onMutate: (campaignId: number) => Promise<ArchiveCampaignContext>
  onError: (error: string, campaignId: number, context: ArchiveCampaignContext) => void
  onSettled: (campaignId?: number) => void
}

const archiveCampaignEvents: ArchiveCampaignEvents = {
  onMutate: async (campaignId) => {
    await queryClient.cancelQueries(['campaign', campaignId])

    // Snapshot the previous value
    const prevCampaign = queryClient.getQueryData<Campaign>(['campaign', campaignId])

    if (prevCampaign) {
      queryClient.removeQueries(['campaign', campaignId])
    }

    return { prevCampaign }
  },
  onError: (_err, campaignId, context) => {
    if (context?.prevCampaign) {
      // Restore campaign on any error
      queryClient.setQueryData<Campaign>(['campaign', campaignId], context.prevCampaign)
    }
  },
  onSettled: (campaignId: number) => {
    if (campaignId) {
      queryClient.invalidateQueries(['campaign', campaignId])
      queryClient.invalidateQueries(['campaignsList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useArchiveCampaign = () => useMutation(archiveCampaign, archiveCampaignEvents)
