import { SettingData } from './types'

export const sendgridSettingsData: SettingData[] = [
  {
    name: 'apiKey',
    label: 'API Key',
    rules: [{ required: true, message: 'Please input sendgrid setting API key!' }]
  },
  {
    name: 'from',
    label: 'From',
    rules: [{ required: true, message: 'Please input sendgrid setting from email!' }]
  },
  {
    name: 'fromName',
    label: 'From Name',
    rules: [{ required: false, message: 'Please input sendgrid setting from name!' }]
  }
]
