import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Checkbox, Button, Form, Input, Table, Typography, UploadFile, Modal } from 'antd'
import { DeleteTwoTone } from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table'
import moment from 'moment'
import notification from 'mrx-notification'
import { UploadImages, ProgressLoader } from 'components'
import { getAvailablePaymentsForRefund } from 'utils/getAvailablePaymentsForRefund'
import { useUploadAsset } from 'features/assets/hooks'
import { DecreaseBalanceModal } from 'features/stores/components'
import styles from './ProductsTab.module.scss'
import { useRemoveOrderItem, useRefundPayment, useResolveOrderItem } from '../../hooks'
import { OrderItem, Payment } from '../../types'

type Props = {
  orderId?: number
  orderStatus?: string
  payments?: Payment[]
  products: OrderItem[]
  subTotal?: number
  total?: number
  totalCost?: number
  processing?: number
  shipping?: number
  promocodeDiscount?: number
  orderRefresh: () => void
}

const ProductsTab = (props: Props) => {
  const { products, payments, orderStatus, subTotal, total, processing, shipping, promocodeDiscount, totalCost, orderRefresh } = props

  const [isFrontArtworkUploading, setIsFrontArtworkUploading] = useState(false)
  const [isBackArtworkUploading, setIsBackArtworkUploading] = useState(false)
  const [isRemoveModal, setIsRemoveModal] = useState(false)
  const [itemForRemove, setItemForRemove] = useState(null)
  const [isRefund, setIsRefund] = useState(false)
  const [refundedTotal, setRefundedTotal] = useState(0)

  const [isDecreaseBalanceModal, setIsDecreaseBalanceModal] = useState(false)
  const [orderItemToDecrease, setOrderItemToDecrease] = useState<OrderItem>()

  const [form] = Form.useForm()

  const removeOrderItem = useRemoveOrderItem()
  const refundPayment = useRefundPayment()
  const resolveOrderItem = useResolveOrderItem()
  const uploadAsset = useUploadAsset()

  useEffect(() => {
    if (itemForRemove) {
      form.setFieldsValue({
        amount: (itemForRemove.total / 100).toFixed(2)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemForRemove])

  useEffect(() => {
    if (payments) {
      setRefundedTotal(
        payments.filter((item) => ['void', 'refunded'].includes(item.status)).reduce((sum, payment) => sum + payment.total, 0)
      )
    }
  }, [payments])

  useEffect(() => {
    if (removeOrderItem.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Order Item was deleted successfully!'
      })
      setIsRemoveModal(false)
      setItemForRemove(null)
      setIsRefund(false)
    }
  }, [removeOrderItem.isSuccess])

  useEffect(() => {
    if (resolveOrderItem.isError) {
      notification.error({
        message: 'Error',
        // @ts-ignore
        description: resolveOrderItem.error instanceof Error ? resolveOrderItem.error.message : resolveOrderItem.error.toString()
      })
    }

    if (resolveOrderItem.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Order Item was resolved successfully!'
      })
    }
  }, [resolveOrderItem.isError, resolveOrderItem.isSuccess])

  useEffect(() => {
    uploadAsset.isSuccess && orderRefresh && orderRefresh()

    if (uploadAsset.isSuccess || uploadAsset.isError) {
      setIsFrontArtworkUploading(false)
      setIsBackArtworkUploading(false)
    }
  }, [uploadAsset.isSuccess, uploadAsset.isError])

  const doConfirm = async () => {
    if (isRefund) {
      const availablePayments = getAvailablePaymentsForRefund(payments)

      if (!availablePayments.length) {
        notification.error({
          message: 'Error',
          description: 'No available payments for refund'
        })
        return
      }

      const { amount } = await form.validateFields()
      let amountInCents = Math.round(parseFloat(amount) * 100)
      const availableAmount = availablePayments.reduce((sym, payment) => sym + payment.maxAmount, 0)

      if (availableAmount < amountInCents) {
        notification.error({
          message: 'Error',
          description: `Available amount is $${(availableAmount / 100).toFixed(2)}`
        })
        return
      }

      for (const payment of availablePayments) {
        if (!amountInCents) {
          continue
        }

        let amountForRefund = amountInCents

        if (payment.maxAmount < amountInCents) {
          amountForRefund = payment.maxAmount
          amountInCents -= payment.maxAmount
        }

        await refundPayment.mutate({
          paymentId: payment.id,
          refundPaymentData: {
            amount: amountForRefund,
            comment: 'Order item has been removed and refunded'
          }
        })
      }
    }
    removeOrderItem.mutate(itemForRemove.id)
  }

  const uploadItemArtwork = async (orderItemId: number, file: UploadFile) => {
    const { type, name: filename } = file

    setIsFrontArtworkUploading(true)
    await uploadAsset.mutate({
      ownerType: 'ORDER_ITEM_ARTWORK',
      ownerId: orderItemId,
      assetData: {
        name: 'artwork',
        type,
        filename,
        role: 'DEFAULT'
      },
      file: file.originFileObj as File
    })
  }

  const uploadItemArtworkBack = async (orderItemId: number, file: UploadFile) => {
    const { type, name: filename } = file

    setIsBackArtworkUploading(true)
    await uploadAsset.mutate({
      ownerType: 'ORDER_ITEM_ARTWORK_BACK',
      ownerId: orderItemId,
      assetData: {
        name: 'artwork-back',
        type,
        filename,
        role: 'DEFAULT'
      },
      file: file.originFileObj as File
    })
  }

  const handleDecreaseBalance = (orderItem: OrderItem) => {
    setIsDecreaseBalanceModal(true)
    setOrderItemToDecrease(orderItem)
  }

  const columns: ColumnsType<OrderItem> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      render: (record) => <Link to={`/products/${record.productId}/edit`}>{record.displayName}</Link>,
      key: 'displayName'
    },
    {
      title: 'Varaint key',
      dataIndex: 'variantKey',
      key: 'variantKey'
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku'
    },
    {
      title: 'Store',
      render: (record) => (record.store ? <Link to={`/stores/${record.store.id}/edit`}>{record.store.name}</Link> : ''),
      key: 'storeId'
    },
    {
      title: 'Customer Comment',
      dataIndex: 'customerComment',
      key: 'customerComment'
    },
    {
      title: 'Customer Image',
      render: (record) =>
        record.asset ? (
          <div>
            <a target='_blank' rel='noopener noreferrer' href={record.asset.signedUrl}>
              {record.asset.name}
            </a>
          </div>
        ) : (
          ''
        ),
      key: 'asset'
    },
    {
      title: 'Artwork',
      render: (record) => (
        <>
          {record.assetArtwork ? (
            <div>
              <a target='_blank' rel='noreferrer' href={record.assetArtwork.signedUrl}>
                <img className={styles.artworkPreview} src={record.assetArtwork.signedUrl} alt={record.assetArtwork.name} />
              </a>
            </div>
          ) : (
            ''
          )}
          {record.status === 'HOLD' && record.holdReason.match(/PENDING_CS_APPROVE/) && (
            <button className={styles.uploadButton}>
              {isFrontArtworkUploading
                ? <ProgressLoader barsLength={8} />
                : (<>
                  Front artwork
                  <UploadImages
                    className={styles.uploader}
                    filesToUpload={[]}
                    assetsToRemove={[]}
                    numberOfImages={1}
                    onChange={async (filesToUpload) => {
                      if (filesToUpload[0]) {
                        await uploadItemArtwork(record.id, filesToUpload[0])
                      }
                    }}
                  />
                </>)
              }
            </button>
          )}
          {record.assetArtworkBack ? (
            <div style={{ marginTop: '6px' }}>
              <a target='_blank' rel='noreferrer' href={record.assetArtworkBack.signedUrl}>
                <img className={styles.artworkPreview} src={record.assetArtworkBack.signedUrl} alt={record.assetArtworkBack.name} />
              </a>
            </div>
          ) : (
            ''
          )}
          {record.status === 'HOLD' && record.holdReason.match(/PENDING_CS_APPROVE/) && (
            <button className={styles.uploadButton} style={{ marginTop: '6px' }}>
              {isBackArtworkUploading
                ? <ProgressLoader barsLength={8} />
                : (<>
                  Back artwork
                  <UploadImages
                    className={styles.uploader}
                    filesToUpload={[]}
                    assetsToRemove={[]}
                    numberOfImages={1}
                    onChange={async (filesToUpload) => {
                      if (filesToUpload[0]) {
                        await uploadItemArtworkBack(record.id, filesToUpload[0])
                      }
                    }}
                  />
                </>)
              }
            </button>
          )}
        </>
      ),
      key: 'asset'
    },
    {
      title: 'Date added',
      render: (_value, record) => `${moment(record.createdAt).format('MM/DD/YYYY HH:mm:ss')}`,
      key: 'createdAt'
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: 'Cost',
      render: (_value, record) => `$${(record.cost / 100).toFixed(2)}`,
      key: 'discount'
    },
    {
      title: 'Total Cost',
      render: (_value, record) => `$${(record.totalCost / 100).toFixed(2)}`,
      key: 'discount'
    },
    {
      title: 'Price',
      render: (_value, record) => `$${(record.price / 100).toFixed(2)}`,
      key: 'price'
    },
    {
      title: 'Discount',
      render: (_value, record) => `$${(record.discount / 100).toFixed(2)}`,
      key: 'discount'
    },
    {
      title: 'Profit',
      render: (_value, record) => `$${(record.profit / 100).toFixed(2)}`,
      key: 'discount'
    },
    {
      title: 'Total',
      render: (_value, record) => `$${(record.total / 100).toFixed(2)}`,
      key: 'total'
    },
    {
      title: 'Action',
      key: 'x',
      width: '10%',
      align: 'center',
      render: (_value, record) => (
        <span>
          {['ACTIVE', 'RESOLVED'].includes(record.status) ? (
            <DeleteTwoTone
              twoToneColor='#ec1c24'
              style={{ fontSize: '20px' }}
              onClick={(e) => {
                e.stopPropagation()
                setItemForRemove(record)
                setIsRemoveModal(true)
              }}
            />
          ) : (
            record.status && (
              <>
                <div style={{ color: record.status === 'DELETED' ? 'red' : 'purple', opacity: 0.7 }}>
                  {record.status.charAt(0).toUpperCase() + record.status.slice(1)}
                </div>
                <div style={{ fontSize: '10px' }}>{record.holdReason.replace(',', '\n')}</div>
                {record.status === 'HOLD' && (
                  <Button
                    onClick={() => resolveOrderItem.mutate(record.id)}
                    type='primary'
                    loading={resolveOrderItem.variables === record.id && resolveOrderItem.isLoading}
                  >
                    {console.log(record)}
                    Resolve
                  </Button>
                )}
              </>
            )
          )}
        </span>
      )
    },
    {
      title: 'Store Balance Action',
      key: 'x2',
      width: '10%',
      align: 'center',
      render: (_value, record) => (
        <span hidden={!record.storeId}>
          <Button onClick={() => handleDecreaseBalance(record)} type='primary'>
            Decrease
          </Button>
        </span>
      )
    }
  ]

  const isOrderProcessedInFulfillment = ['COMPLETED', 'DISPATCHED'].includes(orderStatus)

  return (
    <>
      <Modal
        title={
          <p>
            Are you sure you want to <span style={{ color: 'red' }}>REMOVE</span> this item?
          </p>
        }
        visible={isRemoveModal}
        onOk={doConfirm}
        onCancel={() => setIsRemoveModal(false)}
      >
        {isOrderProcessedInFulfillment && (
          <p>
            This action will <span style={{ color: 'purple' }}>DUPLICATE</span> order in fulfillment. Are you sure you want to continue?
          </p>
        )}
        {isRefund && (
          <Form layout='vertical' form={form} hideRequiredMark>
            <Form.Item name='amount' label='Amount' rules={[{ required: true, message: 'Please input amount!' }]}>
              <Input type='number' />
            </Form.Item>
          </Form>
        )}
        <Checkbox checked={isRefund} onClick={() => setIsRefund(!isRefund)} /> Refund payment
      </Modal>
      <DecreaseBalanceModal
        visibleModal={isDecreaseBalanceModal}
        onCancel={() => setIsDecreaseBalanceModal(false)}
        storeId={orderItemToDecrease?.storeId}
        sum={orderItemToDecrease?.profit}
        payload={JSON.stringify({ orderId: orderItemToDecrease?.orderId, orderItemId: orderItemToDecrease?.id })}
      />
      <Table
        columns={columns}
        dataSource={products}
        bordered
        rowKey={(record) => record.id}
        pagination={false}
        summary={() => {
          return (
            <>
              <Table.Summary.Row className={styles.totalRow}>
                <Table.Summary.Cell index={0} colSpan={10} />
                <Table.Summary.Cell index={10}>Total Cost</Table.Summary.Cell>
                <Table.Summary.Cell index={11}>{`$${(totalCost / 100).toFixed(2)}`}</Table.Summary.Cell>
                <Table.Summary.Cell index={12} colSpan={2} />
                <Table.Summary.Cell index={14}>Sub total</Table.Summary.Cell>
                <Table.Summary.Cell index={15}>
                  <Typography>{`$${(subTotal / 100).toFixed(2)}`}</Typography>
                </Table.Summary.Cell>
              </Table.Summary.Row>

              <Table.Summary.Row className={styles.totalRow}>
                <Table.Summary.Cell index={0} colSpan={8} />
                <Table.Summary.Cell index={8}>Promocode Discount {`$${(promocodeDiscount / 100).toFixed(2)}`}</Table.Summary.Cell>
                <Table.Summary.Cell index={9}>
                  <span style={{ color: 'tomato' }}>Refunded {`$-${((refundedTotal / 100) * -1).toFixed(2)}`}</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={10}>Shipping</Table.Summary.Cell>
                <Table.Summary.Cell index={11}>{`$${(shipping / 100).toFixed(2)}`}</Table.Summary.Cell>
                <Table.Summary.Cell index={12}>Processing</Table.Summary.Cell>
                <Table.Summary.Cell index={13}>{`$${(processing / 100).toFixed(2)}`}</Table.Summary.Cell>
                <Table.Summary.Cell index={14}>Grand total</Table.Summary.Cell>
                <Table.Summary.Cell index={15}>{`$${(total / 100).toFixed(2)}`}</Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          )
        }}
      />
    </>
  )
}

export default ProductsTab
