import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Campaign, CampaignInput } from '../types'

type CreateCampaignArgs = {
  workspaceId: number
  campaignData: CampaignInput
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'createCampaign', Campaign>

const CREATE_CAMPAIGN = `
  mutation createCampaign ($workspaceId: Int!, $campaignData: CampaignInput!) {
    createCampaign(workspaceId: $workspaceId, campaignData: $campaignData) {
      id
      name
      hasCustomDomain
      domainId
      status
      domain {
        id
        name
      }
      attributes {
        attribute
        value
      }
      mailingPlugin
      mailingSettingsId
      mailingSettingsName
      tags
      faviconUrl
      defaultDomain
      googleGlobalTag
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type CreateCampaign = (args: CreateCampaignArgs) => Promise<Campaign>

const createCampaign: CreateCampaign = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_CAMPAIGN,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createCampaign } = data
    if (!createCampaign) {
      throw new Error("Can't create campaign!")
    }

    return createCampaign
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type CreateCampaignEvents = {
  onSettled: (data?: Campaign) => void
}

const createCampaignEvents: CreateCampaignEvents = {
  onSettled: (campaign) => {
    if (campaign?.id) {
      queryClient.invalidateQueries(['campaignsList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCreateCampaign = () => useMutation(createCampaign, createCampaignEvents)
