// TODO - remove becouse of legacy

import { Landing, StepState, WizardStepStatus } from '../../types'

const getDeploymentsStep: (landing: Landing, currentStep: number) => StepState = (landing, currentStep) => {
  const isValid = true
  let status = 'wait' as WizardStepStatus

  if (isValid) {
    status = 'finish' as WizardStepStatus
  }

  if (currentStep === 5) {
    status = 'process' as WizardStepStatus
  }

  return {
    isValid,
    status
  }
}

export { getDeploymentsStep }
