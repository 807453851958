import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { PostmarkSetting } from '../types'

type PostmarkSettingSelector = (state: ReduxState, postmarkSettingId?: number) => PostmarkSetting | undefined

const getPostmarkSetting: PostmarkSettingSelector = (state, postmarkSettingId) => {
  let result
  if (postmarkSettingId && Object.prototype.hasOwnProperty.call(state.postmarkSettings.postmarkSettings, postmarkSettingId)) {
    result = state.postmarkSettings.postmarkSettings[postmarkSettingId] as PostmarkSetting
  }
  return result
}

export const postmarkSettingSelector: PostmarkSettingSelector = createSelector(getPostmarkSetting, (postmarkSetting) => postmarkSetting)
