import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

type AuthorizeSettingInput = {
  name: string
  apiGateway: string
  apiLoginId: string
  transactionKey: string
  clientKey?: string
  signatureKey?: string
}

type Payload = {
  authorizeSettingId: number
  authorizeSettingData: AuthorizeSettingInput
}

export type UpdateAuthorizeSettingAction = FSA<undefined, Payload, string>

type UpdateAuthorizeSetting = (args: Payload) => MrxThunk<UpdateAuthorizeSettingAction, Promise<boolean>>

type QueryResponse = GraphQLResponse<'updateAuthorizeSetting', boolean>

const UPDATE_AUTHORIZE_SETTING = `
  mutation updateAuthorizeSetting ($authorizeSettingId: Int!, $authorizeSettingData: AuthorizeSettingInput!) {
    updateAuthorizeSetting (authorizeSettingId: $authorizeSettingId, authorizeSettingData: $authorizeSettingData)
  }
`

const updateAuthorizeSetting: UpdateAuthorizeSetting = (args) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.UPDATE_AUTHORIZE_SETTING,
    meta: { done: false }
  })

  try {
    const { authorizeSettingId, authorizeSettingData } = args
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_AUTHORIZE_SETTING,
      variables: { authorizeSettingId, authorizeSettingData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateAuthorizeSetting } = data
    if (!updateAuthorizeSetting) {
      throw new Error("Can't update Authorize setting!")
    }

    dispatch({
      type: t.UPDATE_AUTHORIZE_SETTING,
      payload: args,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.UPDATE_AUTHORIZE_SETTING,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { updateAuthorizeSetting }
