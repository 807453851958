import axios from 'utils/axios'
import { useMutation } from 'react-query'
import hash from 'object-hash'
import { StoreProfitEvents } from '@merchx-v2/shared-types'

import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { queryClient } from 'queryClient'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'decreaseBalance', boolean>

const DECREASE_BALANCE = `
  mutation DecreaseBalance ($storeId: Int!, $sum: Int!, $note: String!, $event: StoreProfitEvent!, $payload: String) {
    decreaseBalance(storeId: $storeId, sum: $sum, note: $note, event: $event, payload: $payload)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type DecreaseBalanceArgs = {
  storeId: number
  sum: number
  note: string
  event: StoreProfitEvents
  payload?: string
}

type DecreaseBalance = (args: DecreaseBalanceArgs) => Promise<DecreaseBalanceArgs>

const decreaseBalance: DecreaseBalance = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: DECREASE_BALANCE,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { decreaseBalance } = data
    if (!decreaseBalance) {
      throw new Error("Can't decrease store balance!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type DecreaseBalanceEvents = {
  onSettled: (data?: DecreaseBalanceArgs) => void
}

const decreaseBalanceEvents: DecreaseBalanceEvents = {
  onSettled: (data) => {
    if (data?.storeId) {
      queryClient.invalidateQueries(['storeProfitsPage', hash({ storeId: data.storeId })])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useDecreaseBalance = () => useMutation(decreaseBalance, decreaseBalanceEvents)
