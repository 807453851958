import * as React from 'react'
import { getWebSocket } from 'utils/webSocket'
import { connector, PropsFromRedux } from './container'

type WebSocketWrapperProps = React.PropsWithChildren<PropsFromRedux>

const WebSocketWrapper = (props: WebSocketWrapperProps) => {
  React.useEffect(() => {
    console.log('Web socket called')
    const socket = getWebSocket()
    socket.on('connect', () => props.wsConnected(socket))
    socket.on('connect_error', (error) => props.wsError(error))
    socket.on('disconnect', () => props.wsDisconnected)

    // socket.on('subscribeToChannelResult', (subscribeResult) => props.subscribeToChannelResult(subscribeResult))
    // socket.on('unsubscribeFromChannelResult', (unsubscribeResult) => props.unsubscribeFromChannelResult(unsubscribeResult))

    // socket.on('buildLogsUpdated', (logs) => props.buildLogsUpdated(logs))
    // socket.on('deployLandingLogsUpdated', (logs) => props.deployLandingLogs(logs))
    // socket.on('jobInfoUpdated', (updatedInfo) => props.jobInfoUpdated(updatedInfo))

    return () => {
      console.log('WebSocket destruction')
      props.wsDisconnect(socket)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>{props.children}</>
}

export default connector(WebSocketWrapper)
