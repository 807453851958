import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

type Payload = {
  productId: number
  productAttributeId: number
}

export type RemoveProductAttributeAction = FSA<undefined, Payload, string>
type RemoveProductAttribute = (productId: number, productAttributeId: number) => MrxThunk<RemoveProductAttributeAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'removeProductAttribute', boolean>

const REMOVE_PRODUCT_ATTRIBUTE = `
  mutation removeProductAttribute ($productAttributeId: Int!) {
    removeProductAttribute (productAttributeId: $productAttributeId)
  }
`

const removeProductAttribute: RemoveProductAttribute = (productId, productAttributeId) => async (dispatch) => {
  let isRemoved = false

  dispatch({
    type: t.REMOVE_PRODUCT_ATTRIBUTE,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_PRODUCT_ATTRIBUTE,
      variables: { productAttributeId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Empty response!')
    }

    const { removeProductAttribute } = data
    if (!removeProductAttribute) {
      throw new Error("Can't remove Product Attribute!")
    }

    dispatch({
      type: t.REMOVE_PRODUCT_ATTRIBUTE,
      payload: { productId, productAttributeId },
      meta: { done: true }
    })

    isRemoved = true
  } catch (err) {
    dispatch({
      type: t.REMOVE_PRODUCT_ATTRIBUTE,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isRemoved
}

export { removeProductAttribute }
