import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Layout, Drawer, PageHeader, Tabs } from 'antd'
import notification from 'mrx-notification'
import * as rootStyles from 'assets/layoutStyle'
import { ProductsTab, ShippingAddressTab, PaymentAddressTab } from '../../components'
import { useCart } from '../../hooks'
import styles from './CartDetails.module.scss'

type Props = {
  cartId?: number
  onClose: () => void
}

const CartDetails = (props: Props) => {
  const { cartId, onClose } = props

  const cart = useCart(cartId)

  useEffect(() => {
    if (cart.error) {
      notification.error({
        message: 'Carts details error!',
        description: cart.error instanceof Error ? cart.error.message : cart.error.toString()
      })
    }
  }, [cart.error])

  return (
    <Drawer title='Cart Details' width='80%' onClose={onClose} visible={!!cartId} forceRender>
      <Layout style={rootStyles.root}>
        <PageHeader style={{ margin: '-24px' }} title='Cart Details' />
        {cart && (
          <>
            <div className={styles.listContainer}>
              <div className={styles.leftColumn}>
                <div className={styles.listItem}>Cart ID: {cart?.data?.id}</div>
                <div className={styles.listItem}>
                  Campaign: <Link to={`/campaigns/${cart?.data?.landing?.campaign?.id}`}>{cart?.data?.landing?.campaign?.name}</Link>
                </div>
                <div className={styles.listItem}>Customer: {`${cart.data?.customer?.firstName || ''} ${cart.data?.customer?.lastName || ''}`}</div>
                <div className={styles.listItem}>Date Added: {moment(cart?.data?.createdAt).format('MM/DD/YYYY')}</div>
                <div className={styles.listItem}>Comment: {cart?.data?.comment}</div>
              </div>
              <div className={styles.rightColumn}>
                <div className={styles.listItem}>Status: {cart?.data?.status}</div>
                <div className={styles.listItem}>
                  Landing:{' '}
                  <Link to={`/campaigns/${cart?.data?.landing?.campaign?.id}/landingWizard?landingId=${cart?.data?.landingId}`}>
                    {cart?.data?.landing?.name}
                  </Link>
                </div>
                <div className={styles.listItem}>Email: {cart?.data?.customer?.email || ''}</div>
                <div className={styles.listItem}>Payment Method: {cart?.data?.landing?.paymentPlugin}</div>
                <div className={styles.listItem}>Shipping Method: {cart?.data?.landing?.fulfillmentPlugin}</div>
              </div>
            </div>
            <Tabs type='card' className={styles.tabsContainer}>
              <Tabs.TabPane tab='Products' key='1'>
                <ProductsTab
                  products={cart?.data?.cartItems}
                  promocode={cart?.data?.promocode}
                  subtotal={cart?.data?.subtotal}
                  shipping={cart?.data?.shipping}
                  processing={cart?.data?.processing}
                  promocodeDiscount={cart?.data?.promocodeDiscount}
                  total={cart?.data?.total}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab='Shipping Address' key='3'>
                <ShippingAddressTab address={cart?.data?.shippingAddress} />
              </Tabs.TabPane>
              <Tabs.TabPane tab='Payment Address' key='4'>
                <PaymentAddressTab address={cart?.data?.billingAddress} />
              </Tabs.TabPane>
            </Tabs>
          </>
        )}
      </Layout>
    </Drawer>
  )
}

export default CartDetails
