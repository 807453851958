import { JobUpdatedInfo } from '@merchx-v2/web-socket/dist/webSocket/types/jobUpdatedInfo'
import * as t from '../actionTypes'

export type JobInfoUpdatedAction = BaseFSA<JobUpdatedInfo>
type JobInfoUpdated = (updatedInfo: JobUpdatedInfo) => MrxThunk<JobInfoUpdatedAction>

const jobInfoUpdated: JobInfoUpdated = updatedInfo => async dispatch => {
  dispatch({
    type: t.JOB_INFO_UPDATED,
    payload: updatedInfo,
    meta: { done: true }
  })
}

export { jobInfoUpdated }
