import produce from 'immer'
import * as t from './actionTypes'
import { actionTypes as nt } from 'features/navigation'
import { actionTypes as wt } from 'features/workspaces'
import { isSuccessAction, isErrorAction } from 'types'
import { TasksQueueState } from './types'
import createReducer from 'store/createReducer'
import {
  CancelTaskAction,
  ChangeCurrentPageAction,
  ChangeUpdateLandingsTasksCurrentPageAction,
  ChangePageSizeAction,
  FetchTaskListAction,
  RestartTaskAction,
  TaskLoadedAction,
  FetchUpdateLandingsTasksListAction
} from './actions'

const initState: TasksQueueState = {
  tasks: {},
  totalNewTasks: 0,
  totalInProgressTasks: 0,
  pagination: {
    pages: {},
    total: 0,
    currentPage: 1,
    size: 10
  },
  updateLandingTasks: {},
  updateLandingsTasksPagination: {
    pages: {},
    total: 0,
    currentPage: 1,
    size: 10
  },
  UIState: {
    isCanceledTask: false,
    isListLoading: false,
    isTaskRestarting: false,
    isUpdateLandingsTasksLoading: false
  }
}

const reducer = createReducer(initState, {
  [t.FETCH_TASKS_LIST_PAGE]: (state, action: FetchTaskListAction) =>
    produce(state, draft => {
      draft.UIState.isListLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isListLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        action.payload.items.forEach(item => {
          draft.tasks[item.id] = item
        })
        const { page, total, items, totalInProgressTasks, totalNewTasks } = action.payload
        draft.pagination.total = total
        draft.pagination.currentPage = page
        draft.pagination.pages[page] = items.map(item => item.id)
        draft.totalInProgressTasks = totalInProgressTasks
        draft.totalNewTasks = totalNewTasks
      }
    }),

  [t.FETCH_UPDATE_LANDINGS_TASK_LIST]: (state, action: FetchUpdateLandingsTasksListAction) =>
    produce(state, draft => {
      draft.UIState.isUpdateLandingsTasksLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isUpdateLandingsTasksLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { items, count } = action.payload
        items.forEach(item => {
          draft.updateLandingTasks[item.id] = item
        })
        draft.updateLandingsTasksPagination.total = count
        draft.updateLandingsTasksPagination.pages[state.updateLandingsTasksPagination.currentPage] = items.map(item => item.id)
      }
    }),

  [t.CANCEL_TASK]: (state, action: CancelTaskAction) =>
    produce(state, draft => {
      draft.UIState.isCanceledTask = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isCanceledTask = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.tasks[action.payload]) {
          draft.tasks[action.payload].status = 'CANCELED'
        }
      }
    }),

  [t.RESTART_TASK]: (state, action: RestartTaskAction) =>
    produce(state, draft => {
      draft.UIState.isTaskRestarting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isTaskRestarting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.tasks[action.payload]) {
          draft.tasks[action.payload].status = 'NEW'
          draft.tasks[action.payload].startedAt = new Date()
          draft.tasks[action.payload].endedAt = null
          draft.tasks[action.payload].retryNumber = 1
          draft.tasks[action.payload].progress = 0
        }
      }
    }),

  [t.TASK_LOADED]: (state, action: TaskLoadedAction) =>
    produce(state, draft => {
      if (action.payload) {
        const { task } = action.payload

        if (draft.pagination.pages) {
          draft.pagination.pages[1].push(task.id)
        } else {
          draft.pagination.pages[1] = [task.id]
        }

        draft.tasks[task.id] = task
      }
    }),

  [t.CHANGE_PAGE_SIZE]: (state, action: ChangePageSizeAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.pagination.size = action.payload
      }
    }),

  [t.CHANGE_CURRENT_PAGE]: (state, action: ChangeCurrentPageAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.pagination.currentPage = action.payload
      }
    }),

  [t.CHANGE_UPDATE_LANDINGS_TASKS_CURRENT_PAGE]: (state, action: ChangeUpdateLandingsTasksCurrentPageAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.updateLandingsTasksPagination.currentPage = action.payload
      }
    }),

  [wt.SWITCH_WORKSPACE]: state =>
    produce(state, draft => {
      draft.pagination = initState.pagination
    }),

  [nt.LOCATION_CHANGE]: state =>
    produce(state, draft => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default reducer
