import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { OrderItem } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'resolveOrderItem', number>

const RESOLVE_ORDER_ITEM = `
    mutation resolveOrderItem ($orderItemId: Int!) {
    resolveOrderItem (orderItemId: $orderItemId)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type ResolveOrderItem = (orderItemId: number) => Promise<number>

const resolveOrderItem: ResolveOrderItem = async (orderItemId) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: RESOLVE_ORDER_ITEM,
      variables: {
        orderItemId
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { resolveOrderItem } = data
    if (!resolveOrderItem) {
      throw new Error("Can't resolve order item!")
    }

    return orderItemId
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type ResolveOrderItemContext = { prevOrderItem?: OrderItem }

type ResolveOrderItemEvents = {
  onMutate: (orderItemId: number) => Promise<ResolveOrderItemContext>
  onError: (error: string, orderItemId: number, context: ResolveOrderItemContext) => void
  onSettled: (orderItemId?: number) => void
}

const resolveOrderItemEvents: ResolveOrderItemEvents = {
  onMutate: async (orderItemId) => {
    await queryClient.cancelQueries(['orderItem', orderItemId])

    // Snapshot the previous value
    const prevOrderItem = queryClient.getQueryData<OrderItem>(['orderItem', orderItemId])

    if (prevOrderItem) {
      queryClient.invalidateQueries(['orderItem', orderItemId])
    }

    return { prevOrderItem }
  },
  onError: (_err, orderItemId, context) => {
    if (context?.prevOrderItem) {
      // Restore currrency on any error
      queryClient.setQueryData<OrderItem>(['orderItem', orderItemId], context.prevOrderItem)
    }
  },
  onSettled: (orderItemId: number) => {
    if (orderItemId) {
      queryClient.invalidateQueries(['orderItem', orderItemId])
      queryClient.invalidateQueries(['order'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useResolveOrderItem = () => useMutation(resolveOrderItem, resolveOrderItemEvents)
