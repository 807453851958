import React, { useEffect, useMemo } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Table } from 'antd'
import notification from 'mrx-notification'
import { createTableColumns } from './createTableColumns'
import { useStoreUsersList } from '../../hooks'
import styles from './StoreUsersList.module.scss'

type Props = {
  storeId: number
}

const StoreUsersList: React.FC<Props> = ({ storeId }) => {
  const storeUsersList = useStoreUsersList({ storeId })
  const invitesList = useMemo(() => storeUsersList.data?.pages.map((page) => page.items).flat() || [], [storeUsersList.dataUpdatedAt])
  useEffect(() => storeUsersList.remove, [])

  useEffect(() => {
    if (storeUsersList.error) {
      notification.error({
        message: 'Stores error!',
        description: storeUsersList.error instanceof Error ? storeUsersList.error.message : storeUsersList.error.toString()
      })
    }
  }, [storeUsersList.error])

  const page = storeUsersList.data?.pages.at(-1).currentPage || 1
  const size = storeUsersList.data?.pages.at(-1).pageSize || 30
  const columns = useMemo(() => createTableColumns(), [])
  return (
    <div className={styles.container}>
      <InfiniteScroll
        style={{ paddingBottom: '30px' }}
        dataLength={page * size}
        next={storeUsersList.fetchNextPage}
        hasMore={storeUsersList.hasNextPage}
        loader={<h4>Loading...</h4>}
        refreshFunction={storeUsersList.refetch}
        scrollableTarget='storeScroll'
      >
        <Table
          columns={columns}
          dataSource={invitesList}
          bordered
          rowKey={(record) => record.id}
          pagination={false}
          loading={storeUsersList.isLoading}
        />
      </InfiniteScroll>
    </div>
  )
}

export default React.memo(StoreUsersList)
