import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

export type RemoveZipwhipSettingAction = FSA<undefined, number, string>
type RemoveZipwhipSettingColor = (zipwhipSettingId: number) => MrxThunk<RemoveZipwhipSettingAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'removeZipwhipSetting', boolean>

const REMOVE_ZIPWHIP_SETTING = `
  mutation removeZipwhipSetting ($zipwhipSettingId: Int!) {
    removeZipwhipSetting(zipwhipSettingId: $zipwhipSettingId)
  }
`

const removeZipwhipSetting: RemoveZipwhipSettingColor = (zipwhipSettingId) => async (dispatch) => {
  let isRemoved = false

  dispatch({
    type: t.REMOVE_ZIPWHIP_SETTING,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_ZIPWHIP_SETTING,
      variables: { zipwhipSettingId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Empty response!')
    }

    const { removeZipwhipSetting } = data
    if (!removeZipwhipSetting) {
      throw new Error("Can't remove zipwhip setting!")
    }

    dispatch({
      type: t.REMOVE_ZIPWHIP_SETTING,
      payload: zipwhipSettingId,
      meta: { done: true }
    })

    isRemoved = true
  } catch (err) {
    dispatch({
      type: t.REMOVE_ZIPWHIP_SETTING,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isRemoved
}

export { removeZipwhipSetting }
