// TODO - remove becouse of legacy

import React, { useState } from 'react'
import { connector, PropsFromRedux } from './container'
import { Table, Layout, Tag, Progress } from 'antd'
import formatDuration from 'utils/formatDuration'
import useInterval from 'utils/useInterval'
import styles from './LandingWizardBuilds.module.scss'
import moment from 'moment'

const tagColors = {
  PREVIEW: 'blue',
  PRODUCTION: 'green'
}

const statusColors = {
  NEW: 'gold',
  UPDATE_DEPENDENCIES: 'purple',
  DOWNLOAD_ASSETS: 'purple',
  UPDATE_COMPONENTS: 'purple',
  UPDATE_PAGES: 'purple',
  BUILD: 'blue',
  UPLOAD_FILES: 'purple',
  DONE: 'green',
  ERROR: 'volcano'
}

const LandingWizardBuilds = (props: PropsFromRedux) => {
  const [now, setNow] = useState(moment())

  useInterval(() => {
    setNow(moment())
  }, 1000)

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version'
    },
    {
      title: 'Type',
      render: (record) => {
        return <Tag color={tagColors[record.type]}>{record.type}</Tag>
      },
      key: 'type'
    },
    {
      title: 'Progress',
      render: (record) => {
        return <Progress percent={record.progress} size='small' status='active' />
      },
      key: 'progress'
    },
    {
      title: 'Status',
      render: (record) => <Tag color={statusColors[record.status]}>{record.status}</Tag>,
      key: 'status'
    },
    {
      title: 'Start time',
      render: (startedAt: Date) => (startedAt ? moment(startedAt).format('YYYY/MM/DD HH:mm:ss') : ''),
      dataIndex: 'startedAt',
      key: 'startedAt'
    },
    {
      title: 'End time',
      render: (endedAt: Date) => (endedAt ? moment(endedAt).format('YYYY/MM/DD HH:mm:ss') : ''),
      dataIndex: 'endedAt',
      key: 'endedAt'
    },
    {
      title: 'Build time',
      render: (record) => {
        if (record.startedAt === null && record.endedAt === null && record.buildTime === 0) {
          return <div>0</div>
        }
        if (record.buildTime === 0) {
          const start = moment(record.startedAt)
          const end = record.endedAt !== null ? moment(record.endedAt) : now
          const showMilliseconds = record.endedAt !== null
          const duration = moment.duration(end.diff(start))
          return <div>{duration ? formatDuration(duration, showMilliseconds) : ''}</div>
        } else {
          const duration = moment.duration(record.buildTime)
          return <div>{formatDuration(duration)}</div>
        }
      },
      key: 'buildTime'
    }
    // {
    //   title: 'Actions',
    //   render: record => {
    //     if (record.status === 'DONE') {
    //       return <Button>Restore</Button>
    //     } else if (record.status === 'IN_PROGRESS') {
    //       return <Button>Deploy</Button>
    //     }
    //   },
    //   key: 'actions'
    // }
  ]

  return (
    <div className={styles.contentWrapper}>
      <Layout.Content className={styles.contentComponent}>
        <Table columns={columns} dataSource={props.builds} bordered rowKey={(record) => record.id} />
      </Layout.Content>
    </div>
  )
}

export default connector(LandingWizardBuilds)
