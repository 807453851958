import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { SiteType } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Page, PageData } from '../types'

type CreatePageArgs = {
  ownerType: SiteType
  ownerId: number
  pageData: PageData
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'createPage', Page>

const CREATE_PAGE = `
  mutation CreatePage($ownerType: SiteType!, $ownerId: Int!, $pageData: PageInput!) {
    createPage(ownerType: $ownerType, ownerId: $ownerId, pageData: $pageData) {
      id
      ownerType
      ownerId
      ownerVersion
      name
      route
      order
      sourceCode
      sourceStyle
      actionsCode
      reducerCode
      canBeSkippedOnBuild
      goalName
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type CreatePage = (args: CreatePageArgs) => Promise<Page>

const createPage: CreatePage = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_PAGE,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createPage } = data
    if (!createPage) {
      throw new Error("Can't create page!")
    }

    return createPage
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type CreatePageEvents = {
  onSettled: (data?: Page) => void
}

const createPageEvents: CreatePageEvents = {
  onSettled: (page) => {
    if (page?.id) {
      queryClient.invalidateQueries([`${page.ownerType.toLowerCase()}`, +page.ownerId])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCreatePage = () => useMutation(createPage, createPageEvents)
