import React, { useState } from 'react'
import { SearchableSelectBase } from 'components'
import { useCustomersOptions } from '../../hooks/useCustomersForOptions'

type PropsType = {
  initialOption: SelectOption
  disabled?: boolean
  allowClear?: boolean
  selected?: string | number
  className?: string
  onSelect: (selectOption: SelectOption & { email: string }) => void
}

const CustomersSelect = (props: PropsType) => {
  const [searchText, setSearchText] = useState<string>()
  const customerOptions = useCustomersOptions(searchText)

  return (
    <SearchableSelectBase
      onSearch={setSearchText}
      options={customerOptions.data}
      isLoading={customerOptions.isLoading || customerOptions.isRefetching}
      {...props}
    />
  )
}

export default CustomersSelect
