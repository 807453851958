// TODO - remove becouse of legacy

import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { QuizDto } from '@merchx-v2/shared-types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'removeQuiz', boolean>

const REMOVE_QUIZ = `
  mutation removeQuiz ($quizId: Int!) {
    removeQuiz(quizId: $quizId) 
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveQuiz = (quizId: number) => Promise<number>

const removeQuiz: RemoveQuiz = async (quizId) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_QUIZ,
      variables: {
        quizId
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeQuiz } = data
    if (!removeQuiz) {
      throw new Error("Can't remove quiz!")
    }

    return quizId
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveQuizContext = { prevQuiz?: QuizDto }

type RemoveQuizEvents = {
  onMutate: (quizId: number) => Promise<RemoveQuizContext>
  onError: (error: string, quizId: number, context: RemoveQuizContext) => void
  onSettled: (quizId?: number) => void
}

const removeQuizEvents: RemoveQuizEvents = {
  onMutate: async (quizId) => {
    await queryClient.cancelQueries(['quiz', quizId])

    // Snapshot the previous value
    const prevQuiz = queryClient.getQueryData<QuizDto>(['quiz', quizId])

    if (prevQuiz) {
      queryClient.removeQueries(['quiz', quizId])
    }

    return { prevQuiz }
  },
  onError: (_err, quizId, context) => {
    if (context?.prevQuiz) {
      // Restore quiz on any error
      queryClient.setQueryData<QuizDto>(['quiz', quizId], context.prevQuiz)
    }
  },
  onSettled: (quizId) => {
    if (quizId) {
      queryClient.invalidateQueries(['quiz', quizId])
      queryClient.invalidateQueries(['quizzesList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useRemoveQuiz = () => useMutation(removeQuiz, removeQuizEvents)
