import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { SettingOwner } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Setting, SettingDataInput } from '../types'
import { queryClient } from 'queryClient'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'batchUpdateSettingsValues', Setting[]>

const BATCH_UPDATE_SETTINGS_VALUES = `
  mutation batchUpdateSettingsValues($ownerType: SettingOwnerEnum!, $ownerId: Int!, $settingsData: [SettingInput!]) {
    batchUpdateSettingsValues(ownerType: $ownerType, ownerId: $ownerId, settingsData: $settingsData) {
      id
      name
      ownerType
      ownerId
      ownerVersion
      alias
      type
      value
      jsonValue
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type BatchUpdateSettingsValuesArgs = {
  ownerType: SettingOwner
  ownerId: number
  settingsData: SettingDataInput[]
}

type BatchUpdateSettingsValues = (args: BatchUpdateSettingsValuesArgs) => Promise<BatchUpdateSettingsValuesArgs>

const batchUpdateSettingsValues: BatchUpdateSettingsValues = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: BATCH_UPDATE_SETTINGS_VALUES,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { batchUpdateSettingsValues } = data
    if (!batchUpdateSettingsValues) {
      throw new Error("Can't batch update settings values!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type BatchUpdateSettingsValuesEvents = {
  onSettled: (data?: BatchUpdateSettingsValuesArgs) => void
}

const batchUpdateSettingsValuesEvents: BatchUpdateSettingsValuesEvents = {
  onSettled: (data: BatchUpdateSettingsValuesArgs) => {
    if (data) {
      queryClient.invalidateQueries(['template'])
      queryClient.invalidateQueries(['landing'])
    }
  }
}
/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useBatchUpdateSettingsValues = () => useMutation(batchUpdateSettingsValues, batchUpdateSettingsValuesEvents)
