import { StripeSettingsDto } from '@merchx-v2/shared-types/dist/dto/stripeSettings/stripe-settings.dto'
import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type StripeSettingsListSelector = (state: ReduxState) => StripeSettingsDto[]
const getStripeSettingsList: StripeSettingsListSelector = (state) => {
  const result = [] as StripeSettingsDto[]
  const pageIds = state.stripeSettings.pagination.pages[state.stripeSettings.pagination.currentPage] || []
  pageIds.forEach((pageId) => {
    result.push(state.stripeSettings.stripeSettings[pageId])
  })
  return result
}

export const stripeSettingsListSelector: StripeSettingsListSelector = createSelector(getStripeSettingsList, (list) => list)
