import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Popconfirm, Layout, PageHeader, DatePicker, Typography, Button } from 'antd'
import moment, { Moment } from 'moment'
import notification from 'mrx-notification'
import useReactRouter from 'use-react-router'
import * as rootStyles from 'assets/layoutStyle'
import { Breadcrumbs } from 'components'
import { useBanCustomerAccount, useCustomerAccount, useUnbanCustomerAccount } from '../../hooks'
import styles from './CustomerAccountDetails.module.scss'

const dateFormat = 'MM/DD/YYYY'

const CustomerAccountDetails = (props) => {
  const { match } = props

  const { history } = useReactRouter()

  const { data: customerAccount, error } = useCustomerAccount(+match?.params?.customerAccountId)
  const banCustomerAccount = useBanCustomerAccount()
  const unbanCustomerAccount = useUnbanCustomerAccount()

  const [bannedTill, setBannedTill] = useState<Moment>()

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Customer account details page error!',
        description: (error as Error).message
      })
    }
  }, [error])

  useEffect(() => {
    if (banCustomerAccount.error) {
      notification.error({
        message: 'Ban customer account error!',
        description: banCustomerAccount.error instanceof Error ? banCustomerAccount.error.message : banCustomerAccount.error.toString()
      })
    }
  }, [banCustomerAccount.error])

  useEffect(() => {
    if (unbanCustomerAccount.error) {
      notification.error({
        message: 'Unban customer account error!',
        description:
          unbanCustomerAccount.error instanceof Error ? unbanCustomerAccount.error.message : unbanCustomerAccount.error.toString()
      })
    }
  }, [unbanCustomerAccount.error])

  const doConfirm = () => {
    if (!customerAccount) return

    if (customerAccount.bannedTill) {
      unbanCustomerAccount.mutate({ customerAccountId: customerAccount.id })
      return
    }

    if (!bannedTill) return

    banCustomerAccount.mutate({ customerAccountId: customerAccount.id, bannedTill: bannedTill.toDate() })
  }

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title='Customer Account Details'
          extra={
            customerAccount
              ? [
                  <DatePicker key='1' format={dateFormat} value={bannedTill} onChange={setBannedTill} allowClear={false} />,
                  <Popconfirm
                    key='cancel-button'
                    title={`Are you sure ${customerAccount.bannedTill ? 'unban' : 'ban'} this customer account?`}
                    onConfirm={() => doConfirm()}
                    onCancel={(e) => e?.stopPropagation()}
                    okText='Yes'
                    cancelText='No'
                  >
                    <Button disabled={banCustomerAccount.isLoading || unbanCustomerAccount.isLoading} key='2'>
                      {customerAccount.bannedTill ? 'Unban' : 'Ban'} customerAccount
                    </Button>
                  </Popconfirm>
                ]
              : []
          }
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Customer Accounts', url: '/customerAccounts' },
            { title: 'Customer Account Details', url: '/customerAccounts' }
          ]}
        />
      </Layout.Content>

      {customerAccount && (
        <div className={styles.contentComponent}>
          <Typography.Title level={3}>{`${customerAccount.firstName} ${customerAccount.lastName}`} </Typography.Title>

          <div className={styles.listContainer}>
            {customerAccount.image?.signedUrl && (
              <div className={styles.leftColumn}>
                <img
                  width={200}
                  height={200}
                  src={customerAccount.image?.signedUrl}
                  alt={customerAccount.username || customerAccount.email}
                  style={{ marginBottom: 10, objectFit: 'contain' }}
                />
              </div>
            )}
            <div className={styles.leftColumn}>
              <div className={styles.listItem}>ID: {customerAccount.id}</div>
              <div className={styles.listItem}>Username: {customerAccount.username}</div>
              <div className={styles.listItem}>Email: {customerAccount.email}</div>
              <div className={styles.listItem}>Date Added: {moment(customerAccount.createdAt).format('MM/DD/YYYY HH:mm:ss')}</div>
              <div className={styles.listItem}>
                Last Login: {customerAccount.lastLoginAt ? moment(customerAccount.lastLoginAt).format('MM/DD/YYYY HH:mm:ss') : ''}
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.listItem}>
                Customer:{' '}
                {customerAccount.customer && (
                  <Link to={`/sales/customers/${customerAccount.customer.id}`}>
                    {`${customerAccount.customer.firstName} ${customerAccount.customer.lastName}`}
                  </Link>
                )}
              </div>
              <div className={styles.listItem}>Email Confirmation: {customerAccount.isEmailConfirmed ? 'Yes' : 'No'}</div>
              <div className={styles.listItem}>Deactivated: {customerAccount.isDeactivated ? 'Yes' : 'No'}</div>
              <div className={styles.listItem}>
                Banned Till: {customerAccount.bannedTill ? moment(customerAccount.bannedTill).format('MM/DD/YYYY HH:mm:ss') : ''}
              </div>
              <div className={styles.listItem}>
                Deleted At: {customerAccount.deletedAt ? moment(customerAccount.deletedAt).format('MM/DD/YYYY HH:mm:ss') : ''}
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
}

export default CustomerAccountDetails
