import * as React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { User } from 'features/authentication/types'

type Props = {
  user: User
}

const SystemAdminRoute: React.FC<Props & RouteProps> = ({ component: Component, user, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (user?.roles?.includes('SYSTEM_ADMIN')) {
          return Component && <Component {...props} />
        }
        return <Redirect to='/' />
      }}
    />
  )
}

const mapStateToProps = (state: any) => ({ user: state.authentication.user })
export default connect(mapStateToProps)(SystemAdminRoute)
