import React, { useContext, useEffect, useState } from 'react'
import { Form, Typography, Row, Col, Button } from 'antd'
import { CloseCircleTwoTone, PlusOutlined } from '@ant-design/icons'
import notification from 'mrx-notification'
import { SettingFilter, SettingOwner, SettingType } from '@merchx-v2/shared-types'
import { GlobalContext } from 'appContexts'
import ProductsSelect from 'features/products/components/ProductsSelect'
import { useUpdateSettingValue } from '../../hooks'
import styles from './FormItemProductsListSetting.module.scss'
import { Setting } from 'features/settings/types'

type SelectArrayItem = {
  id: number
  name: string
}

type PropsType = {
  ownerType: SettingOwner
  ownerId: number
  setting: Setting
  selfUpdate?: boolean
  landingId?: number

  onValueChanged?: (setting: Setting, newValue: any) => void
}

const FormItemProductsListSetting = (props: PropsType) => {
  const { selfUpdate = false, setting, landingId, ownerType, ownerId, onValueChanged } = props
  const [isTouched, setIsTouched] = useState<boolean>(false)
  // @ts-ignore
  const [productsList, setProductsList] = useState<SelectArrayItem[]>(setting?.jsonValue || [])

  const { campaignId } = useContext(GlobalContext)

  const updateSettingValue = useUpdateSettingValue()

  useEffect(() => {
    onValueChanged(setting, JSON.stringify(productsList))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsList, setting])

  if (!setting) {
    return
  }

  const handleValueChanged = (index, newValue) => {
    const updatedSelectArray = productsList.map((item) => ({ ...item }))
    updatedSelectArray[index] = newValue
    setIsTouched(true)
    setProductsList(updatedSelectArray)
  }

  const handleAddItem = () => {
    setIsTouched(true)
    setProductsList([...productsList, { id: 0, name: '' }])
  }

  const handleRemoveItem = (indexToRemove) => {
    const updatedSelectArray = [...productsList]
    updatedSelectArray.splice(indexToRemove, 1)
    setIsTouched(true)
    setProductsList(updatedSelectArray)
  }

  const handleSave = async () => {
    updateSettingValue.mutate(
      {
        ownerType,
        ownerId,
        settingId: setting.id,
        settingData: {
          name: setting.name,
          alias: setting.alias,
          type: setting.type as SettingType,
          value: JSON.stringify(productsList),
          required: setting.required,
          filters: setting.filters as SettingFilter[]
        }
      },
      {
        onSuccess: () => {
          notification.success({
            message: 'Successfully',
            description: `${setting.name} setting was updated!`
          })
          setIsTouched(false)
        },
        onError: (error) => {
          notification.error({
            message: `${setting.name} setting error!`,
            description: error.toString()
          })
        }
      }
    )
  }

  return (
    <div>
      <Typography style={{ marginBottom: '10px' }}>{setting.name} </Typography>
      {productsList?.map((field, index) => {
        return (
          <Row key={field.id} className={styles.rowContainer}>
            <Col className={styles.rowNumberContainer}>
              <p>{index + 1})</p>
            </Col>
            <Col className={styles.colContainer}>
              <Form.Item
                style={{ width: '100%' }}
                name={`${setting.name}${index}name`}
                initialValue={field.name}
                rules={[
                  {
                    required: setting.required,
                    whitespace: true,
                    message: 'Please input name or delete this field.'
                  }
                ]}
              >
                <ProductsSelect
                  landingId={+landingId}
                  campaignId={campaignId}
                  settingId={setting.id}
                  initialOption={{
                    id: field.id,
                    name: field.name
                  }}
                  onSelect={(selectedOption: SelectOption) => handleValueChanged(index, selectedOption)}
                  filters={setting?.filters}
                />
              </Form.Item>
            </Col>
            <Col
              className={styles.removeButtonContainer}
              onClick={() => {
                handleRemoveItem(index)
              }}
            >
              <CloseCircleTwoTone twoToneColor='tomato' />
            </Col>
          </Row>
        )
      })}
      <Form.Item>
        <Button
          type='dashed'
          onClick={() => {
            handleAddItem()
          }}
        >
          <PlusOutlined /> ADD PRODUCT
        </Button>
        {isTouched && selfUpdate && (
          <Button
            className={styles.saveButton}
            type='primary'
            loading={updateSettingValue.isLoading}
            onClick={() => {
              handleSave()
            }}
          >
            <PlusOutlined /> Save
          </Button>
        )}
      </Form.Item>
    </div>
  )
}

export default FormItemProductsListSetting
