import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { ProductColor, GraphQLProductColor } from '../types'

export type CreateProductColorAction = FSA<undefined, ProductColor, string>
type CreateProductColor = (productColorData: ProductColorInput) => MrxThunk<CreateProductColorAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'createProductColor', GraphQLProductColor>

const CREATE_PRODUCT_COLOR = `
  mutation createProductColor ($workspaceId: Int!, $productColorData: ProductColorDataInput!) {
    createProductColor(workspaceId: $workspaceId, productColorData: $productColorData) {
      id
      workspaceId
      name
      displayName
      createdAt
      updatedAt
    }
  }
`

type ProductColorInput = {
  name: string
  displayName: string
}

const createProductColor: CreateProductColor = (productColorData) => async (dispatch, getState) => {
  let isSaved = false

  dispatch({
    type: t.CREATE_PRODUCT_COLOR,
    meta: { done: false }
  })

  try {
    const workspaceId = getState()?.workspaces?.activeWorkspace?.id
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_PRODUCT_COLOR,
      variables: { workspaceId, productColorData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createProductColor } = data
    if (!createProductColor) {
      throw new Error("Can't create product color!")
    }

    dispatch({
      type: t.CREATE_PRODUCT_COLOR,
      payload: createProductColor,
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.CREATE_PRODUCT_COLOR,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { createProductColor }
