import { ProductPriceOwner, CreateProductPriceDto } from '@merchx-v2/shared-types'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { UpdatedLandingVersion } from 'features/landings/types'
import * as eternalListsFeature from 'features/eternalLists'
import { Product } from 'features/types'
import * as t from '../actionTypes'
import { GraphQLProductInput } from '../types'

type Payload = {
  list: 'products'
  index: number
  item: GraphQLProductInput
  filters: {
    campaignId: number
    productId: number
  }
}

type Args = {
  campaignId: number
  productId: number
  productData: GraphQLProductInput
  productPriceOwnerType: ProductPriceOwner
  productPriceOwnerId: number
  productPricesData: CreateProductPriceDto[]
  DTGProductId?: number
  index?: number
}

export type UpdateProductAction = FSA<undefined, Payload, string>

type UpdateProduct = (args: Args) => MrxThunk<eternalListsFeature.actions.UpdateEternalItemAction<'products'>, Promise<boolean>>

type QueryResponse = GraphQLResponse<'updateProduct', UpdatedLandingVersion[]>

const UPDATE_PRODUCT = `
  mutation UpdateProduct($productId: Int!, $productData: ProductInput!, $productPriceOwnerType: ProductPriceOwner!, $productPriceOwnerId: Int!, $productPricesData: [ProductPriceInput]!) {
    updateProduct(productId: $productId, productData: $productData, productPriceOwnerType: $productPriceOwnerType, productPriceOwnerId: $productPriceOwnerId, productPricesData: $productPricesData) {
      landingId
      version
    }
  }
`

const updateProduct: UpdateProduct = (args) => async (dispatch) => {
  let isSaved = false

  dispatch({
    type: t.UPDATE_PRODUCT,
    meta: { done: false }
  })

  try {
    const { productId, productData, productPriceOwnerType, productPriceOwnerId, productPricesData, DTGProductId, index, campaignId } = args

    if (DTGProductId) {
      productData.attributes.push({
        attribute: 'DTG_PRODUCT_ID',
        value: DTGProductId + ''
      })
    } else {
      productData.attributes = productData.attributes.filter((item) => item.attribute !== 'DTG_PRODUCT_ID')
    }

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_PRODUCT,
      variables: { productId, productData, productPriceOwnerType, productPriceOwnerId, productPricesData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateProduct } = data
    if (!updateProduct) {
      throw new Error("Can't update product!")
    }

    if (index) {
      dispatch({
        type: t.UPDATE_PRODUCT,
        payload: {
          list: 'products',
          index,
          filters: { campaignId, productId },
          item: { id: productId, ...productData } as Product
        },
        meta: { done: true }
      })
    }
    dispatch({
      type: eternalListsFeature.actionTypes.UPDATE_ETERNAL_LIST_ITEM,
      payload: {
        list: 'products',
        index,
        filters: { campaignId, productId },
        item: { id: productId, ...productData } as Product
      },
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.UPDATE_PRODUCT,
      payload: extractErrorInfo(err) as string,
      error: true
    })
  }

  return isSaved
}

export { updateProduct }
