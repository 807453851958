// TODO - remove becouse of legacy

import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { AdSpendRowInput, AdSpendRow } from '../types'

export type SaveAdSpendsAction = FSA<undefined, AdSpendRow[], string>
type SaveAdSpends = (rows: AdSpendRowInput[]) => MrxThunk<SaveAdSpendsAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'saveAdSpends', AdSpendRow[]>

const SAVE_AD_SPENDS = `
  mutation SaveAdSpends($workspaceId: Int!, $adSpendsData: AdSpendsInput!) {
    saveAdSpends(workspaceId: $workspaceId, adSpendsData: $adSpendsData) {
      campaign {
        id
        name
      }
      sumOfExpenses
      numberOfClicks
    }
  }
`

export const saveAdSpends: SaveAdSpends = (rows) => async (dispatch, getState) => {
  let isSaved = false
  dispatch({
    type: t.SAVE_AD_SPENDS,
    meta: { done: false }
  })

  try {
    const workspaceId = getState()?.workspaces?.activeWorkspace?.id

    const {
      adSpends: { dateList }
    } = getState()

    if (!rows || !rows.length) {
      throw new Error('Data should contain rows!')
    }

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: SAVE_AD_SPENDS,
      variables: {
        workspaceId,
        adSpendsData: {
          day: dateList.format('YYYY-MM-DD'),
          rows
        }
      }
    })

    guardFromErrors(errors)
    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { saveAdSpends } = data

    if (!saveAdSpends) {
      throw new Error("Can't save ad spend!")
    }

    dispatch({
      type: t.SAVE_AD_SPENDS,
      payload: saveAdSpends.map((adSpend) => ({
        ...adSpend,
        sumOfExpenses: parseFloat((adSpend.sumOfExpenses / 100).toFixed(2))
      })),
      meta: { done: true }
    })

    isSaved = true
  } catch (err) {
    dispatch({
      type: t.SAVE_AD_SPENDS,
      payload: extractErrorInfo(err),
      error: true
    })
  }
  return isSaved
}
