import { useInfiniteQuery } from 'react-query'
import hash from 'object-hash'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { PluginSettingsList, PluginSettingsListFilter } from '../types'

const DEFAULT_PAGE_SIZE = 30

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'pluginSettingsListPage', PluginSettingsList>

const FETCH_PLUGIN_SETTINGS_LIST = `
query pluginSettingsListPage ($workspaceId: Int!, $filters: PluginSettingsListPageFilter!, $size: Int, $page: Int) {
  pluginSettingsListPage (workspaceId: $workspaceId, filters: $filters, size: $size, page: $page) {
    items {
      id
      workspaceId
      ownerType
      ownerId
      pluginType
      pluginName
      actualFrom
      createdAt
      updatedAt
    }
    total
  }
}
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type PageParam = {
  workspaceId: number
  page?: number
  size?: number
  filters: PluginSettingsListFilter
}

type FetchPluginSettingsListArgs = {
  queryKey: string | string[]
  pageParam?: PageParam
}

type FetchPluginSettingsList = (args: FetchPluginSettingsListArgs) => Promise<PluginSettingsList>

const fetchPluginSettingsList: FetchPluginSettingsList = async ({ pageParam }) => {
  const { workspaceId, page = 1, size = DEFAULT_PAGE_SIZE, filters = {} } = pageParam || {}
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_PLUGIN_SETTINGS_LIST,
      variables: { page, size, filters, workspaceId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { pluginSettingsListPage } = data
    if (!pluginSettingsListPage) {
      throw new Error("Can't get plugin settings list page!")
    }

    return {
      items: pluginSettingsListPage.items,
      total: pluginSettingsListPage.total,
      currentPage: page,
      pageSize: size,
      filters,
      workspaceId
    }
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

type GetNextPageParam = (args: PluginSettingsList) => PageParam

type PluginSettingsListEvents = {
  keepPreviousData: boolean
  getPreviousPageParam: GetNextPageParam
  getNextPageParam: GetNextPageParam
}

const pluginSettingsListEvents: PluginSettingsListEvents = {
  keepPreviousData: true,
  getPreviousPageParam: ({ workspaceId, pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, filters }) => {
    if (currentPage > 1) {
      return {
        page: currentPage - 1,
        size: pageSize,
        filters,
        workspaceId
      }
    }

    return undefined
  },
  getNextPageParam: ({ workspaceId, pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, total = 0, filters }) => {
    if (pageSize * currentPage < total) {
      return {
        page: currentPage + 1,
        size: pageSize,
        filters,
        workspaceId
      }
    }

    return undefined
  }
}

export const usePluginSettingsList = (workspaceId: number, filters: PluginSettingsListFilter) => {
  const cacheKey = hash({ workspaceId, filters })

  return useInfiniteQuery(
    ['pluginSettingsList', cacheKey],
    (prev) => {
      return fetchPluginSettingsList({
        queryKey: ['pluginSettingsList', cacheKey],
        pageParam: { ...prev.pageParam, workspaceId, filters }
      })
    },
    pluginSettingsListEvents
  )
}
