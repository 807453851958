import produce from 'immer'
import * as t from './actionTypes'
import { actionTypes as nt } from 'features/navigation'
import { isSuccessAction, isErrorAction } from 'types'
import { ProductAttributesState } from './types'
import createReducer from 'store/createReducer'
import {
  CreateProductAttributeAction,
  FetchProductAttributeAction,
  FetchProductAttributesListAction,
  RemoveProductAttributeAction,
  UpdateProductAttributeAction
} from './actions'

const initState: ProductAttributesState = {
  productAttributes: {},
  UIState: {
    isCreateProductAttribute: false,
    isFetchProductAttribute: false,
    isListLoading: false,
    isRemoveProductAttribute: false,
    isUpdateProductAttribute: false
  }
}

const setProductAttributesDefaults = (draft: ProductAttributesState, productId: number) => {
  if (!draft.productAttributes[productId]) {
    draft.productAttributes[productId] = {}
  }
}
const reducer = createReducer(initState, {
  [t.CREATE_PRODUCT_ATTRIBUTE]: (state, action: CreateProductAttributeAction) =>
    produce(state, (draft) => {
      draft.UIState.isCreateProductAttribute = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isCreateProductAttribute = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { productId, productAttribute } = action.payload
        setProductAttributesDefaults(draft, productId)
        draft.productAttributes[productId][productAttribute.id] = productAttribute
      }
    }),

  [t.FETCH_PRODUCT_ATTRIBUTES_LIST]: (state, action: FetchProductAttributesListAction) =>
    produce(state, (draft) => {
      draft.UIState.isListLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isListLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { productId, productAttributesList } = action.payload
        setProductAttributesDefaults(draft, productId)
        productAttributesList.forEach((item) => {
          draft.productAttributes[productId][item.id] = item
        })
      }
    }),

  [t.FETCH_PRODUCT_ATTRIBUTE]: (state, action: FetchProductAttributeAction) =>
    produce(state, (draft) => {
      draft.UIState.isFetchProductAttribute = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isFetchProductAttribute = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { productId, productAttribute } = action.payload
        setProductAttributesDefaults(draft, productId)
        draft.productAttributes[productId][productAttribute.id] = productAttribute
      }
    }),

  [t.REMOVE_PRODUCT_ATTRIBUTE]: (state, action: RemoveProductAttributeAction) =>
    produce(state, (draft) => {
      draft.UIState.isRemoveProductAttribute = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isRemoveProductAttribute = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { productId, productAttributeId } = action.payload
        if (draft.productAttributes[productId]) {
          if (draft.productAttributes[productId][productAttributeId]) {
            delete draft.productAttributes[productId][productAttributeId]
          }
        }
      }
    }),

  [t.UPDATE_PRODUCT_ATTRIBUTE]: (state, action: UpdateProductAttributeAction) =>
    produce(state, (draft) => {
      draft.UIState.isUpdateProductAttribute = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isUpdateProductAttribute = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { productId, productAttributeId, productAttributeData } = action.payload
        if (draft.productAttributes[productId][productAttributeId]) {
          draft.productAttributes[productId][productAttributeId].value = productAttributeData.value
        }
      }
    }),

  [nt.LOCATION_CHANGE]: (state) =>
    produce(state, (draft) => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default reducer
