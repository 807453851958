import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { DashboardReport } from '../types'

export type FetchDashboardReportAction = FSA<undefined, DashboardReport, string>
type FetchDashboardReport = (startDate: Date, endDate: Date, limit?: number) => MrxThunk<FetchDashboardReportAction>
type QueryResponse = GraphQLResponse<'dashboardReport', DashboardReport>

const FETCH_DASHBOARD_REPORT = `
  query fetchDashboardReport($startDate: DateTime!, $endDate: DateTime!, $workspaceId: Int!, $limit: Int) {
    dashboardReport(startDate: $startDate, endDate: $endDate, workspaceId: $workspaceId, limit: $limit) {
      topCampaignsRate {
        id
        name
        total
      }
      customers
      totalAmountRate
      ordersCount
      salesForGoogleChart
      salvageRate
      multiPurchaserRate
      topCampaigns {
        id
        name
        total
        ordersCount
      }
      topLandings {
        id
        name
        total
        ordersCount
      }
      totalAmount
    }
  }
`

const fetchDashboardReport: FetchDashboardReport =
  (startDate, endDate, limit = 5) =>
  async (dispatch, getState) => {
    dispatch({
      type: t.FETCH_DASHBOARD_REPORT,
      meta: { done: false }
    })

    try {
      const {
        workspaces: {
          activeWorkspace: { id: workspaceId }
        }
      } = getState()
      const {
        data: { data, errors }
      }: QueryResponse = await axios.post('/graphql', {
        query: FETCH_DASHBOARD_REPORT,
        variables: { startDate, endDate, workspaceId, limit }
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { dashboardReport } = data
      if (!dashboardReport) {
        throw new Error("Can't get Dashboard report!")
      }

      dispatch({
        type: t.FETCH_DASHBOARD_REPORT,
        payload: dashboardReport,
        meta: { done: true }
      })
    } catch (err) {
      dispatch({
        type: t.FETCH_DASHBOARD_REPORT,
        payload: extractErrorInfo(err),
        error: true
      })
    }
  }

export { fetchDashboardReport }
