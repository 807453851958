// TODO - remove becouse of legacy

import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { FailedFulfillments } from '../types'

type FailedFulfillmentsSelector = (state: ReduxState) => FailedFulfillments[]

const getFailedFulfillments: FailedFulfillmentsSelector = (state) => {
  let result = [] as FailedFulfillments[]

  if (state.reports.failedFulfillments) {
    result = Object.values(state.reports.failedFulfillments) as FailedFulfillments[]
  }

  return result
}

export const failedFulfillmentsSelector: FailedFulfillmentsSelector = createSelector(
  getFailedFulfillments,
  (failedFulfillments) => failedFulfillments
)
