import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { ProductColor } from '../types'

type ProductColorList = {
  items: ProductColor[]
  total: number
}

type Payload = ProductColorList & {
  page: number
}

export type FetchProductColorsListAction = FSA<undefined, Payload, string>
type FetchProductColorsList = () => MrxThunk<FetchProductColorsListAction>
type QueryResponse = GraphQLResponse<'productColorsList', ProductColorList>

const FETCH_PRODUCT_COLORS_LIST = `
  query fetchProductColorsList($page: Int, $size: Int, $filters: ProductColorsFilters!) {
    productColorsList(page: $page, size: $size, filters: $filters) {
      items {
        id
        workspaceId
        name
        displayName
        createdAt
        updatedAt
      },
      total
    }
  }
`

const fetchProductColorsList: FetchProductColorsList = () => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_PRODUCT_COLORS_LIST,
    meta: { done: false }
  })

  try {
    const {
      workspaces: {
        activeWorkspace: { id: workspaceId }
      },
      productColors: {
        pagination: { currentPage: page, size }
      }
    } = getState()
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_PRODUCT_COLORS_LIST,
      variables: { page, size, filters: { workspaceId } }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { productColorsList } = data
    if (!productColorsList) {
      throw new Error("Can't get product colors list page!")
    }

    dispatch({
      type: t.FETCH_PRODUCT_COLORS_LIST,
      payload: {
        page,
        total: productColorsList.total,
        items: productColorsList.items
      },
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_PRODUCT_COLORS_LIST,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchProductColorsList }
