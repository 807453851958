import { PublicProductV2Dto } from '@merchx-v2/shared-types'
import axios from 'utils/axios'
import { useQuery } from 'react-query'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'publicProductV2', PublicProductV2Dto>

// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchProductArgs = {
  campaignId: number
  landingId: number
  productId: number
}

type FetchProduct = (args: FetchProductArgs) => Promise<PublicProductV2Dto>

const FETCH_PUBLIC_PRODUCT_V2 = `
  query getPublicProductV2($campaignId: Int!, $landingId: Int!, $productId: Int!) {
    publicProductV2(campaignId: $campaignId, landingId: $landingId, productId: $productId) {
      id
      
      storeId
      storeName

      productType
      productType2
      customizable

      name
      displayName
      description
      sku

      tags
      imageUrl
      imageUrls

      weight
      weightAccuracy
      weightUnit

      hasCustomShipping
      customShippingPrice

      hasCustomSeo
      seoTitle
      seoUrl
      seoDescription
      seoKeywords

      numberOfSales

      isObserverOwnsProduct

      prices {
        id
        productId
        name
        displayName
        sku
        price
        discount
        memberPrice
        productType
        imageUrl
        imageUrls
        variantKey
        variantSchema
        isMainPrice
      }

      variantValues {
        variantOption
        values
      }

      createdAt
      updatedAt
    }
  }
`

const fetchProductV2: FetchProduct = async (args) => {
  try {
    if (args.productId) {
      const {
        data: { data, errors }
      }: QueryResponse = await axios.post('/graphql', {
        query: FETCH_PUBLIC_PRODUCT_V2,
        variables: args
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { publicProductV2 } = data
      if (!publicProductV2) {
        throw new Error("Can't get product!")
      }

      return publicProductV2
    }
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useProductV2 = (args: FetchProductArgs) => useQuery(['publicProductV2', args], () => fetchProductV2(args))
