import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import FormData from 'form-data'
import axiosVanila from 'axios'
import md5 from 'spark-md5'
import { AssetOwner, CreatePresignedUrlDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { PresignedUrl } from '../types'

type CreatePresignedUrlArgs = {
  ownerType: AssetOwner
  ownerId: number
  assetData: CreatePresignedUrlDto
  file: File
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'createPresignedUrl', PresignedUrl>

const CREATE_PRESIGNED_URL = `
 mutation createPresignedUrl($ownerType: AssetOwner!, $ownerId: Int!, $assetData: AssetPresignedUrlInput!) {
  createPresignedUrl(ownerType: $ownerType, ownerId: $ownerId, assetData: $assetData)
 }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type CreatePresignedUrl = (args: CreatePresignedUrlArgs) => Promise<PresignedUrl>

export const createPresignedUrl: CreatePresignedUrl = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_PRESIGNED_URL,
      variables: {
        ownerType: args.ownerType,
        ownerId: args.ownerId,
        assetData: {
          name: args.assetData.name,
          type: args.assetData.type,
          filename: args.assetData.filename
        }
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createPresignedUrl } = data
    if (!createPresignedUrl) {
      throw new Error("Can't create presigned url!")
    }

    const spark = new md5.ArrayBuffer()
    spark.append(await args.file.arrayBuffer())
    const dataHash = spark.end()

    if (dataHash !== createPresignedUrl.dataHash) {
      const formData = new FormData()
      Object.entries(createPresignedUrl.fields).forEach(([key, value]) => {
        formData.append(key, value)
      })

      formData.append('file', args.file)

      // Send file to S3
      await axiosVanila.post(createPresignedUrl.url, formData)
    }

    return createPresignedUrl
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type CreatePresignedUrlEvents = {
  onSettled: (data?: PresignedUrl) => void
}

const createPresignedUrlEvents: CreatePresignedUrlEvents = {
  onSettled: (data) => {
    if (data) {
      queryClient.invalidateQueries(['assetsList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCreatePresignedUrl = () => useMutation(createPresignedUrl, createPresignedUrlEvents)
