import { useQuery } from 'react-query'
import axios from 'utils/axios'
import { guardFromErrors } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'cartsListCsv', string>

const FETCH_CARTS_LIST_CSV = `
  query cartsListCsv($filters: CartsFilters!) {
    cartsListCsv(filters: $filters)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchCartsListCsvFilters = {
  status?: string
  query?: string
  sorting?: {
    field?: string
    order?: 'ASC' | 'DESC'
  }
  startDate?: Date
  endDate?: Date
}

type FetchCartsListCsv = (filters: FetchCartsListCsvFilters) => Promise<string>

const fetchCartsListCsv: FetchCartsListCsv = async (filters) => {
  const {
    data: { data, errors }
  }: QueryResponse = await axios.post('/graphql', {
    query: FETCH_CARTS_LIST_CSV,
    variables: { filters }
  })

  guardFromErrors(errors)

  if (!data) {
    throw new Error('Response body is empty!')
  }

  const { cartsListCsv } = data
  if (!cartsListCsv) {
    throw new Error("Can't get carts list csv!")
  }

  return cartsListCsv
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCartsListCsv = (filters: FetchCartsListCsvFilters) =>
  useQuery(['cartsListCsv', filters], () => fetchCartsListCsv(filters), { enabled: false, retry: 0 })
