// TODO - remove becouse of legacy

import produce from 'immer'
import moment from 'moment-timezone'
import * as t from './actionTypes'
import { actionTypes as nt } from 'features/navigation'
import createReducer from 'store/createReducer'
import { isSuccessAction, isErrorAction } from 'types'
import { AdSpendsState } from './types'
import {
  SaveAdSpendsAction,
  FetchAdSpendReportAction,
  FetchAdSpendsAction,
  ChangeDateListAction,
  ChangeDatesRangeReportAction
} from './actions'

const initState: AdSpendsState = {
  dateList: moment(),
  datesRangeReport: [moment().startOf('day'), moment().add(1, 'day').endOf('day')],
  listRows: null,
  reportRows: null,
  UIState: {
    isLoadingList: false,
    isLoadingReport: false,
    isLoadingSave: false
  }
}

const adSpendsReducer = createReducer<AdSpendsState>(initState, {
  [t.SAVE_AD_SPENDS]: (state, action: SaveAdSpendsAction) =>
    produce(state, (draft) => {
      draft.UIState.isLoadingSave = !action.meta?.done
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.error = action.payload
        draft.UIState.isLoadingSave = false
      }
      if (isSuccessAction(action)) {
        draft.listRows = action.payload
        draft.reportRows = null
      }
    }),

  [t.FETCH_AD_SPENDS_REPORT]: (state, action: FetchAdSpendReportAction) =>
    produce(state, (draft) => {
      draft.UIState.isLoadingReport = !action.meta?.done
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.error = action.payload
        draft.UIState.isLoadingReport = false
      }
      if (isSuccessAction(action)) {
        draft.reportRows = action.payload
      }
    }),

  [t.FETCH_AD_SPENDS]: (state, action: FetchAdSpendsAction) =>
    produce(state, (draft) => {
      draft.UIState.isLoadingList = !action.meta?.done
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.error = action.payload
        draft.UIState.isLoadingList = false
      }
      if (isSuccessAction(action)) {
        draft.listRows = action.payload
      }
    }),

  [t.CHANGE_DATE_LIST]: (state, action: ChangeDateListAction) =>
    produce(state, (draft) => {
      draft.dateList = action.payload
      draft.listRows = null
    }),

  [t.CHANGE_DATES_RANGE_REPORT]: (state, action: ChangeDatesRangeReportAction) =>
    produce(state, (draft) => {
      draft.datesRangeReport = action.payload
      draft.reportRows = null
    }),

  [nt.LOCATION_CHANGE]: (state) =>
    produce(state, (draft) => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default adSpendsReducer
