import axios from 'utils/axios'
import { useQuery } from 'react-query'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Order } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'order', Order>

// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchOrder = (orderId: number) => Promise<Order>

const fetchOrder: FetchOrder = async (orderId) => {
  try {
    const FETCH_ORDER = `
      query fetchOrder ($orderId: Int!) {
        order(orderId: $orderId) {
          id
          landingId
          attributes {
            attribute
            value
          }
          customerId
          customer {
            id
            email
            phone
            firstName
            lastName
            createdAt
            updatedAt
            customerTags {
              id
              tag
            }
          }
          billingAddressId
          billingAddress {
            id
            firstName
            lastName
            countryId
            country {
              id
              name
              iso2
              iso3
              worldPart
              code
              currencyId
              currency {
                id
                name
                code
                symbol
              }
            }
            zipCode
            state
            city
            address
            address2
          }
          addressValidation {
            id
            address1
            address2
            city
            stateIso2
            state
            zipCode
            plus4Code
            changes
            reasonsForBad
            error
          }
          shippingAddressId
          shippingAddress {
            id
            firstName
            lastName
            countryId
            country {
              id
              name
              iso2
              iso3
              worldPart
              code
              currencyId
              currency {
                id
                name
                code
                symbol
              }
            }
            zipCode
            state
            city
            address
            address2
          }
          customerSubscriptionId
          customerSubscription {
            status
          }
          subscriptionCycleNumber
          saleSource
          customSaleSource
          status
          holdReasons
          orderItems {
            id
            orderId
            productId
            productPriceId
            paymentId
            productTagId
            productTag {
              id
              name
            }
            storeId
            store {
              id
              name
            }
            customerComment
            asset {
              id
              name
              extension
              ownerType
              ownerId
              mimeType
              s3bucket
              s3key
              signedUrl
              createdAt
              updatedAt
            }
            assetArtwork {
              id
              name
              extension
              ownerType
              ownerId
              mimeType
              s3bucket
              s3key
              signedUrl
              createdAt
              updatedAt
            }
            assetArtworkBack {
              id
              name
              extension
              ownerType
              ownerId
              mimeType
              s3bucket
              s3key
              signedUrl
              createdAt
              updatedAt
            }
            variantKey
            displayName
            sku
            quantity
            price
            discount
            sum
            shipping
            status
            holdReason
            processing
            cost
            totalCost
            profit
            total
            description
            createdAt
          }
          orderEvents {
            id
            event
            createdAt
          }
          orderTasks {
            id
            status
            taskType
            progress
            payload
            retryNumber
            message
            startDate
            startedAt
            endedAt
          }
          orderNotes {
            id
            note
            workspaceId
            userId
            author {
              id
              email
              avatar
              firstName
              lastName
              createdAt
            }
            createdAt
          }
          payments {
            id
            orderId
            systemType
            paymentMethod
            externalTransactionId
            status
            reason
            total
            comment
            bin
            cardNumber
            cardExpDate
          }
          paymentSettings {
            authorizeSettings {
              id
              name
              apiGateway
              apiLoginId
              transactionKey
              clientKey
            }
            stripeSettings {
              id
              name
              apiVersion
              host
              port
              secretKey
              publishableKey
            }
            payPalSettings {
              id
              name
              accountEmail
              clientId
              secretKey
              accessToken
            }
          }
          token   
          subTotal
          total
          promocode
          promocodeDiscount
          shipping
          processing
          totalCost
          comment
          trackingNumber
          invoiceId
          shippedAt
          createdAt
          updatedAt
        }
      }
    `
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_ORDER,
      variables: { orderId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { order } = data
    if (!order) {
      throw new Error("Can't get order!")
    }

    return order
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useOrder = (orderId: number) => useQuery(['order', orderId], () => fetchOrder(orderId))
