import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { DTGProduct } from '../types'

type DTGProductsForOptionsSelector = (state: ReduxState) => DTGProduct[]
const getDTGProductsForOptions: DTGProductsForOptionsSelector = state =>
  state.DTGProducts.DTGProductsForOptions || ([] as DTGProduct[])

export const DTGProductForOptionsSelector: DTGProductsForOptionsSelector = createSelector(
  getDTGProductsForOptions,
  DTGProducts => DTGProducts
)
