import axios from 'utils/axios'
import { CreateCustomerDto } from '@merchx-v2/shared-types'
import { useMutation } from 'react-query'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { queryClient } from 'queryClient'

type AssignStoreOwnerArgs = {
  storeId: number
  email: string
  customerData?: CreateCustomerDto
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'assignStoreOwner', boolean>

const ASSIGN_STORE_OWNER = `
mutation assignStoreOwner ($storeId: Int!, $email: String!, $customerData: CustomerInput) {
  assignStoreOwner (storeId: $storeId, email: $email, customerData: $customerData)
}
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type AssignStore = (args: AssignStoreOwnerArgs) => Promise<AssignStoreOwnerArgs>

const assignStoreOwner: AssignStore = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: ASSIGN_STORE_OWNER,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { assignStoreOwner } = data
    if (!assignStoreOwner) {
      throw new Error("Can't create store!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type AssignStoreOwnerEvents = {
  onSettled: (data?: AssignStoreOwnerArgs) => void
}

const assignStoreOwnerEvents: AssignStoreOwnerEvents = {
  onSettled: (data) => {
    if (data?.storeId) {
      queryClient.invalidateQueries(['storesPage'])
      queryClient.invalidateQueries(['store', data.storeId])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useAssignStoreOwner = () => useMutation(assignStoreOwner, assignStoreOwnerEvents)
