import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'confirmCorrectedAddress', boolean>

const CONFIRM_CORRECTED_ADDRESS = `
  mutation confirmCorrectedAddress($orderId: Int!) {
    confirmCorrectedAddress(orderId: $orderId)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type ConfirmCorrectedAddress = (orderId: number) => Promise<boolean>

const confirmCorrectedAddress: ConfirmCorrectedAddress = async (orderId) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CONFIRM_CORRECTED_ADDRESS,
      variables: { orderId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { confirmCorrectedAddress } = data
    if (!confirmCorrectedAddress) {
      throw new Error("Can't confirm corrected address")
    }

    return confirmCorrectedAddress
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type CreateOrderNoteEvents = {
  onSettled: (result: boolean) => void
}

const confirmCorrectedAddressEvents: CreateOrderNoteEvents = {
  onSettled: (result) => {
    if (result) {
      queryClient.invalidateQueries(['order'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useConfirmCorrectedAddress = () => useMutation(confirmCorrectedAddress, confirmCorrectedAddressEvents)
