import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { TopLanding } from '../types'

export type FetchTopLandingsReportAction = FSA<undefined, TopLanding[], string>
type FetchTopLandingReport = (startDate: Date, endDate: Date, limit?: number) => MrxThunk<FetchTopLandingsReportAction>
type QueryResponse = GraphQLResponse<'topLandingsReport', TopLanding[]>

const FETCH_TOP_LANDINGS_REPORT = `
  query fetchTopLandingsReport ($startDate: DateTime!, $endDate: DateTime!, $workspaceId: Int!, $limit: Int) {
    topLandingsReport(startDate: $startDate, endDate: $endDate, workspaceId: $workspaceId, limit: $limit) {
      id
      name
      total
      ordersCount
    }
  }
`

const fetchTopLandingsReport: FetchTopLandingReport = (startDate, endDate, limit) => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_TOP_LANDINGS_REPORT,
    meta: { done: false }
  })

  try {
    const {
      workspaces: {
        activeWorkspace: { id: workspaceId }
      }
    } = getState()
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_TOP_LANDINGS_REPORT,
      variables: { startDate, endDate, workspaceId, limit }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { topLandingsReport } = data
    if (!topLandingsReport) {
      throw new Error("Can't get Top Landings report!")
    }

    dispatch({
      type: t.FETCH_TOP_LANDINGS_REPORT,
      payload: topLandingsReport,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_TOP_LANDINGS_REPORT,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchTopLandingsReport }
