const debounce = <T extends (...args: any[]) => any>(callback: T, delay: number): T => {
  let id: NodeJS.Timeout

  const wrapper = (...args: unknown[]) => {
    const exec = () => {
      callback.apply(wrapper, args)
    }

    if (id) {
      clearTimeout(id)
    }

    // @ts-ignore TODO - fix wrong type
    id = setTimeout(exec, delay)
  }

  return wrapper as T
}

export default debounce
