import * as t from '../actionTypes'

type Payload = {
  page: number
  countryId: number
}

export type ChangeCurrentPageAction = BaseFSA<Payload>
type ChangeCurrentPage = (page: number, countryId: number) => MrxThunk<ChangeCurrentPageAction>

const changeCurrentPage: ChangeCurrentPage = (page, countryId) => async (dispatch) => {
  dispatch({
    type: t.CHANGE_CURRENT_PAGE,
    payload: {
      page,
      countryId
    }
  })
}

export { changeCurrentPage }
