import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

export type RemoveUserAction = FSA<undefined, number, string>
type RemoveUser = (workspaceId: number, userId: number) => MrxThunk<RemoveUserAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'removeUserFromWorkspace', boolean>

const REMOVE_USER = `
  mutation removeUser($workspaceId: Int!, $userId: Int!) {
    removeUserFromWorkspace(workspaceId: $workspaceId, userId: $userId)
  }
`

export const removeUser: RemoveUser = (workspaceId, userId) => async (dispatch) => {
  let isRemoved = false

  dispatch({
    type: t.REMOVE_USER,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_USER,
      variables: { workspaceId, userId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeUserFromWorkspace } = data

    if (!removeUserFromWorkspace) {
      throw new Error("Can't remove user!")
    }

    dispatch({
      type: t.REMOVE_USER,
      payload: userId,
      meta: { done: true }
    })
    isRemoved = true
  } catch (err) {
    dispatch({
      type: t.REMOVE_USER,
      payload: extractErrorInfo(err),
      error: true
    })
  }
  return isRemoved
}
