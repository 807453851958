import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

type Payload = {
  countryId: number
  regionId: number
}

export type RemoveRegionAction = FSA<undefined, Payload, string>
type RemoveRegion = (countryId: number, regionId: number) => MrxThunk<RemoveRegionAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'removeRegion', boolean>

const REMOVE_REGION = `
  mutation removeRegion ($regionId: Int!) {
    removeRegion(regionId: $regionId) 
  }
`

const removeRegion: RemoveRegion = (countryId, regionId) => async (dispatch) => {
  let isRemoved = false

  dispatch({
    type: t.REMOVE_REGION,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_REGION,
      variables: { regionId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Empty response!')
    }

    const { removeRegion } = data
    if (!removeRegion) {
      throw new Error("Can't remove region!")
    }

    dispatch({
      type: t.REMOVE_REGION,
      payload: { countryId, regionId },
      meta: { done: true }
    })

    isRemoved = true
  } catch (err) {
    dispatch({
      type: t.REMOVE_REGION,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isRemoved
}

export { removeRegion }
