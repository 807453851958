import { createSelector, ParametricSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type isLoadingKeyType =
  | 'isCreateProductTag'
  | 'isRemoveProductTag'
  | 'isUpdateProductTag'
  | 'isListLoaded'
  | 'isProductTagsForOptionsLoaded'

const getIsLoadingSelectorByKey: ParametricSelector<ReduxState, isLoadingKeyType, boolean> = (state, isLoadingKey) =>
  state.productTags.UIState[isLoadingKey] || false

export const isLoadingSelector = createSelector(getIsLoadingSelectorByKey, isLoading => isLoading)
