import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

import { CustomerTag } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'addCustomerTag', CustomerTag>

const REMOVE_CUSTOMER_TAG = `
  mutation addCustomerTag ($customerId: Int!, $tag: CustomerTagEnum!) {
    addCustomerTag(customerId: $customerId, tag: $tag) {
      id
      tag
      customerId
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type AddCustomerTagArgs = {
  customerId: number
  tag: string
}

type AddCustomerTag = (args: AddCustomerTagArgs) => Promise<CustomerTag>

const addCustomerTag: AddCustomerTag = async ({ customerId, tag }) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_CUSTOMER_TAG,
      variables: {
        customerId,
        tag
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { addCustomerTag } = data
    if (!addCustomerTag) {
      throw new Error("Can't remove customer tag!")
    }

    return addCustomerTag
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type AddCustomerTagEvents = {
  onSettled: (customerTag?: CustomerTag) => void
}

const addCustomerTagEvents: AddCustomerTagEvents = {
  onSettled: (customerTag) => {
    if (customerTag?.customerId) {
      queryClient.invalidateQueries(['customer', customerTag.customerId])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useAddCustomerTag = () => useMutation(addCustomerTag, addCustomerTagEvents)
