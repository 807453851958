import produce from 'immer'
import { SiteType } from '@merchx-v2/shared-types'
import * as t from './actionTypes'
import { actionTypes as nt } from 'features/navigation'
import { isSuccessAction, isErrorAction } from 'types'
import { SiteSnapshotsState } from './types'
import createReducer from 'store/createReducer'
import { TakeSiteSnapshotAction, FetchSiteSnapshotsAction, RestoreSiteFromFileAction, RestoreSiteFromSnapshotAction } from './actions'

const initState: SiteSnapshotsState = {
  siteSnapshots: {
    LANDING: {},
    TEMPLATE: {}
  },
  UIState: {
    isLoadingSnapshots: false,
    isTakeSnapshot: false,
    isRestoreFromFile: false,
    isRestoreFromSnapshot: false
  }
}

const setOwnerDefaults = (draft: SiteSnapshotsState, ownerType: SiteType, ownerId: number) => {
  if (!draft.siteSnapshots[ownerType][ownerId]) {
    draft.siteSnapshots[ownerType][ownerId] = {}
  }
}

const reducer = createReducer(initState, {
  [t.FETCH_SITE_SNAPSHOTS]: (state, action: FetchSiteSnapshotsAction) =>
    produce(state, (draft) => {
      draft.UIState.isLoadingSnapshots = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isLoadingSnapshots = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { ownerType, ownerId, siteSnapshots } = action.payload
        setOwnerDefaults(draft, ownerType, ownerId)
        siteSnapshots.forEach((snapshot) => {
          draft.siteSnapshots[ownerType][ownerId][snapshot.id] = snapshot
        })
      }
    }),

  [t.TAKE_SITE_SNAPSHOT]: (state, action: TakeSiteSnapshotAction) =>
    produce(state, (draft) => {
      draft.UIState.isTakeSnapshot = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isTakeSnapshot = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { ownerType, ownerId, siteSnapshot } = action.payload
        setOwnerDefaults(draft, ownerType, ownerId)
        draft.siteSnapshots[ownerType][ownerId][siteSnapshot.id] = siteSnapshot
      }
    }),

  [t.RESTORE_SITE_FROM_FILE]: (state, action: RestoreSiteFromFileAction) =>
    produce(state, (draft) => {
      draft.UIState.isRestoreFromFile = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isRestoreFromFile = false
        draft.UIState.error = action.payload
      }
    }),

  [t.RESTORE_SITE_FROM_SNAPSHOT]: (state, action: RestoreSiteFromSnapshotAction) =>
    produce(state, (draft) => {
      draft.UIState.isRestoreFromSnapshot = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isRestoreFromSnapshot = false
        draft.UIState.error = action.payload
      }
    }),

  [nt.LOCATION_CHANGE]: (state) =>
    produce(state, (draft) => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default reducer
