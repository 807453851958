import produce from 'immer'
import * as t from './actionTypes'
import { actionTypes as nt } from 'features/navigation'
import { isErrorAction } from 'types'
import { FilesState } from './types'
import createReducer from 'store/createReducer'
import {
  UploadFileAction
} from './actions'

const initState: FilesState = {
  UIState: {
    isUploadFile: false
  }
}

const reducer = createReducer<FilesState>(initState, {
  [t.UPLOAD_FILE]: (state, action: UploadFileAction) =>
    produce(state, draft => {
      draft.UIState.isUploadFile = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isUploadFile = false
        draft.UIState.error = action.payload
      }
    }),

  [nt.LOCATION_CHANGE]: state =>
    produce(state, draft => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default reducer
