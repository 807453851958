import * as t from './actionTypes'
import { actionTypes as wt } from 'features/workspaces'
import produce from 'immer'
import { isSuccessAction, isErrorAction } from 'types'
import { NginxSettingsState } from './types'
import createReducer from 'store/createReducer'
import { addItemToPagination } from 'utils/addItemToPagination'
import { removeItemFromPagination } from 'utils/removeItemFromPagination'
import {
  ChangeCurrentPageAction,
  ChangePageSizeAction,
  CreateNginxSettingAction,
  FetchNginxSettingsListAction,
  FetchNginxSettingsForOptionsAction,
  RemoveNginxSettingAction,
  UpdateNginxSettingAction
} from './actions'

const initState: NginxSettingsState = {
  nginxSettings: {},
  nginxSettingsForOptions: [],
  pagination: {
    currentPage: 1,
    total: 0,
    pages: {},
    size: 10
  },
  UIState: {
    isNginxOptionsLoading: false,
    isCreateNginxSetting: false,
    isListLoading: false,
    isRemoveNginxSetting: false,
    isUpdateNginxSetting: false
  }
}

const reducer = createReducer(initState, {
  [t.CREATE_NGINX_SETTING]: (state, action: CreateNginxSettingAction) =>
    produce(state, draft => {
      draft.UIState.isCreateNginxSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isCreateNginxSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { id } = action.payload
        draft.nginxSettings[id] = action.payload
        addItemToPagination(draft.pagination, id)
      }
    }),

  [t.FETCH_NGINX_SETTINGS_LIST]: (state, action: FetchNginxSettingsListAction) =>
    produce(state, draft => {
      draft.UIState.isListLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isListLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        action.payload.items.forEach(item => {
          draft.nginxSettings[item.id] = item
        })
        const { page, total, items } = action.payload
        draft.pagination.total = total
        draft.pagination.pages[page] = items.map(item => item.id)
      }
    }),

  [t.FETCH_NGINX_SETTINGS_FOR_OPTIONS]: (state, action: FetchNginxSettingsForOptionsAction) =>
    produce(state, draft => {
      draft.UIState.isNginxOptionsLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isNginxOptionsLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.nginxSettingsForOptions = action.payload
      }
    }),

  [t.REMOVE_NGINX_SETTING]: (state, action: RemoveNginxSettingAction) =>
    produce(state, draft => {
      draft.UIState.isRemoveNginxSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isRemoveNginxSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.nginxSettings[action.payload]) {
          delete draft.nginxSettings[action.payload]
          removeItemFromPagination(draft.pagination, action.payload)
        }
      }
    }),

  [t.UPDATE_NGINX_SETTING]: (state, action: UpdateNginxSettingAction) =>
    produce(state, draft => {
      draft.UIState.isUpdateNginxSetting = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isUpdateNginxSetting = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.nginxSettings[action.payload.nginxSettingId]) {
          draft.nginxSettings[action.payload.nginxSettingId].name = action.payload.nginxSettingData.name
          draft.nginxSettings[action.payload.nginxSettingId].host = action.payload.nginxSettingData.host
          draft.nginxSettings[action.payload.nginxSettingId].port = action.payload.nginxSettingData.port
          draft.nginxSettings[action.payload.nginxSettingId].userName = action.payload.nginxSettingData.userName
        }
      }
    }),

  [t.CHANGE_CURRENT_PAGE]: (state, action: ChangeCurrentPageAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.pagination.currentPage = action.payload
      }
    }),

  [t.CHANGE_PAGE_SIZE]: (state, action: ChangePageSizeAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.pagination.size = action.payload
      }
    }),

  [wt.SWITCH_WORKSPACE]: state =>
    produce(state, draft => {
      draft.pagination = initState.pagination
    })
})

export default reducer
