import React, { useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import { Table, Layout, PageHeader, Radio, Empty } from 'antd'
import notification from 'mrx-notification'
import Chart from 'react-google-charts'
import { Breadcrumbs } from 'components'
import { LandingsSelect } from 'features/landings/components'
import { TemplatesSelect } from 'features/templates/components'
import * as rootStyles from 'assets/layoutStyle'
import styles from './SalesFunnelAnalyzeReport.module.scss'
import { connector, PropsFromRedux } from './container'

type FunnelAnalyzeReportType = 'graph' | 'table'

const defaultGraphOptions = {
  vAxis: { title: 'Conversion rate, %', minValue: 0 },
  hAxis: { slantedTextAngle: 90 },
  isStacked: 'false',
  legend: 'none',
  aggregationTarget: 'multiple',
  focusTarget: 'category',
  chartArea: {
    left: 20,
    right: 20,
    top: 40,
    bottom: 20
  }
}

const SalesFunnelAnalyzeReport = (props: PropsFromRedux) => {
  const { error, isLoading, reportData, fetchSalesFunnelAnalyzeReport } = props

  const { history } = useReactRouter()

  const [reportType, setReportType] = useState<FunnelAnalyzeReportType>('graph')
  const [selectedMode, setSelectedMode] = useState('template')
  const [selectedItemId, setSelectedItemId] = useState<number>()
  const [graphOptions, setGraphOptions] = useState({
    title: 'Template/Landing Title',
    ...defaultGraphOptions
  })

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Sales funnel analyze report error!',
        description: error
      })
    }
  }, [error])

  useEffect(() => {
    if (selectedItemId) {
      let fetchFunnelArgs = {}
      if (selectedMode === 'template') {
        fetchFunnelArgs = { templateId: selectedItemId }
      } else {
        fetchFunnelArgs = { landingId: selectedItemId }
      }
      fetchSalesFunnelAnalyzeReport(fetchFunnelArgs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItemId])

  const renderSelect = () => {
    if (selectedMode === 'landing') {
      return (
        <div className={styles.select}>
          <LandingsSelect
            key='1'
            initialOption={{
              id: 0,
              name: 'Select landing'
            }}
            onSelect={(selectedOption: SelectOption) => {
              setGraphOptions({
                title: `Landing - "${selectedOption.name}"`,
                ...defaultGraphOptions
              })
              setSelectedItemId(selectedOption.id)
            }}
          />
        </div>
      )
    }
    if (selectedMode === 'template') {
      return (
        <div className={styles.select}>
          <TemplatesSelect
            key='2'
            initialOption={{
              id: 0,
              name: 'Select template'
            }}
            onSelect={(selectedOption: SelectOption) => {
              setGraphOptions({
                title: `Template - "${selectedOption.name}"`,
                ...defaultGraphOptions
              })
              setSelectedItemId(selectedOption.id)
            }}
          />
        </div>
      )
    }
  }

  const columns = [
    {
      title: 'Page',
      dataIndex: 'page',
      key: 'page'
    },
    {
      title: 'Visits',
      dataIndex: 'visits',
      key: 'number'
    },
    {
      title: '%',
      dataIndex: 'rate',
      key: 'rate'
    },
    {
      title: 'Goal',
      dataIndex: 'goalName',
      key: 'goalName'
    },
    {
      title: 'Goal %',
      dataIndex: 'goalRate',
      key: 'goalRate'
    }
  ]

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title='Sales Funnel Analyze'
          extra={[
            <div key='12' className={styles.extraContainer}>
              <Radio.Group value={selectedMode} key='button-group'>
                <Radio.Button value='landing' onClick={() => setSelectedMode('landing')}>
                  LANDING
                </Radio.Button>
                <Radio.Button value='template' onClick={() => setSelectedMode('template')}>
                  TEMPLATE
                </Radio.Button>
              </Radio.Group>
              {renderSelect()}
            </div>
          ]}
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Reports', url: '/' },
            { title: 'Sales Funnel Analyze Report', url: '/' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <Radio.Group className={styles.reportSwitch} value={reportType} key='button-group'>
          <Radio.Button value='graph' onClick={() => setReportType('graph')}>
            GRAPH
          </Radio.Button>
          <Radio.Button value='table' onClick={() => setReportType('table')}>
            TABLE
          </Radio.Button>
        </Radio.Group>
        {reportData?.chartData?.length === 0 && <Empty className={styles.emptyImage} />}
        {reportType === 'graph' && reportData?.chartData?.length > 0 && (
          <div className={styles.chartContainer}>
            <Chart
              chartType='SteppedAreaChart'
              width='100%'
              height='600px'
              data={[['Page visits', 'Rate', { role: 'annotation' }, { role: 'tooltip' }], ...reportData.chartData]}
              options={graphOptions}
            />
          </div>
        )}
        {reportType === 'table' && reportData?.chartData?.length > 0 && (
          <div className={styles.tableContainer}>
            <Table
              columns={columns}
              dataSource={reportData.totalData}
              bordered
              rowKey={(record) => record.page}
              pagination={false}
              loading={isLoading}
            />
          </div>
        )}
      </Layout.Content>
    </Layout>
  )
}

export default connector(SalesFunnelAnalyzeReport)
