import React from 'react'
import { SearchableSelectBase } from 'components'
import { PropsFromRedux, connector } from './container'

const PaymentSettingsSelect = (props: PropsFromRedux) => {
  const { plugin, fetchAuthorizeSettingsOptions, fetchStripeSettingsOptions, fetchPaypalSettingsOptions, ...rest } = props

  switch (plugin) {
    case 'STRIPE':
      return <SearchableSelectBase plugin={plugin} onSearch={fetchStripeSettingsOptions} {...rest} />
    case 'PAYPAL':
      return <SearchableSelectBase plugin={plugin} onSearch={fetchPaypalSettingsOptions} {...rest} />
    case 'AUTHORIZE':
    default:
      return <SearchableSelectBase plugin={plugin} onSearch={fetchAuthorizeSettingsOptions} {...rest} />
  }
}

export default connector(PaymentSettingsSelect)
