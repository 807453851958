import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { UpdateCustomGatewayArtworkDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'updateCustomGatewayArtwork', boolean>

const UPDATE_ARTWORK = `
  mutation updateCustomGatewayArtwork($customGatewayArtworkId: Int!, $customGatewayArtworkData: CustomGatewayArtworkInput!) {
    updateCustomGatewayArtwork(customGatewayArtworkId: $customGatewayArtworkId, customGatewayArtworkData: $customGatewayArtworkData)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateCustomGatewayArtworkArgs = {
  customGatewayArtworkId: number
  customGatewayArtworkData: UpdateCustomGatewayArtworkDto
}

type UpdateCustomGatewayArtwork = (args: UpdateCustomGatewayArtworkArgs) => Promise<UpdateCustomGatewayArtworkArgs>

const updateCustomGatewayArtwork: UpdateCustomGatewayArtwork = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_ARTWORK,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateCustomGatewayArtwork } = data
    if (!updateCustomGatewayArtwork) {
      throw new Error("Can't update artwork!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUpdateCustomGatewayArtwork = () => useMutation(updateCustomGatewayArtwork)
