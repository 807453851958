import { DeploymentPlugin, FulfillmentPlugin, MailingPlugin, PaymentPlugin, SmsPlugin } from '@merchx-v2/shared-types'
import axios from 'utils/axios'
import * as t from '../actionTypes'
import { extractErrorInfo, guardFromErrors } from 'utils/graphqlHelpers'

export type UpdateWorkspaceAction = FSA<undefined, { workspaceId: number; workspaceData: WorkspaceInput }, string>
type UpdateWorkspace = (workspaceData: WorkspaceInput) => MrxThunk<UpdateWorkspaceAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'updateWorkspace', boolean>

type WorkspaceInput = {
  name: string
  paymentPlugin: PaymentPlugin
  paymentSettingsId: number
  fulfillmentPlugin: FulfillmentPlugin
  fulfillmentSettingsId: number
  mailingPlugin: MailingPlugin
  mailingSettingsId: number
  deploymentPlugin: DeploymentPlugin
  deploymentSettingsId: number
  smsPlugin: SmsPlugin
  smsSettingsId: number
  shippingCost: number
  processingCost: number
  applyProcessingOnce: boolean
  allowMultiplePayments: boolean
  autoArchiveCampaignsWithoutSales: boolean
  daysWithoutSalesToArchive: number
}

const UPDATE_WORKSPACE = `
  mutation updateWorkspace ($workspaceId: Int!, $workspaceData: WorkspaceInput!) {
    updateWorkspace(workspaceId: $workspaceId, workspaceData: $workspaceData) 
  }

`

const updateWorkspace: UpdateWorkspace = (workspaceData) => async (dispatch, getState) => {
  let isSaved = false

  dispatch({
    type: t.UPDATE_WORKSPACE,
    meta: { done: false }
  })

  try {
    const workspaceId = getState()?.workspaces?.activeWorkspace?.id

    if (!workspaceId) {
      throw new Error('Active workspace not set!')
    }

    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_WORKSPACE,
      variables: {
        workspaceId,
        workspaceData
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateWorkspace } = data

    if (!updateWorkspace) {
      throw new Error("Workspace defaults wasn't updated!")
    }

    dispatch({
      type: t.UPDATE_WORKSPACE,
      payload: { workspaceId, workspaceData },
      meta: { done: true }
    })
    isSaved = true
  } catch (err) {
    dispatch({
      type: t.UPDATE_WORKSPACE,
      payload: extractErrorInfo(err),
      error: true
    })
  }
  return isSaved
}

export { updateWorkspace }
