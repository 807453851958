import React, { useState } from 'react'
import { Tag, Tooltip, Select } from 'antd'
import useDebounce from 'utils/useDebounce'
import { useSimpleProductTagsOptions } from '../../hooks'

type PropsType = {
  campaignId?: number
  value?: string[]
  placeholder?: string
  onChange?: (tagsList: string[]) => void
}

const ProductTagsSelector = (props: PropsType) => {
  const {
    placeholder = 'Please start typing the product tag',
    value,
    campaignId,

    onChange
  } = props

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_isOpened, setIsOpened] = React.useState(false)
  const [searchText, setSearchText] = useState<string>()
  const debouncedSearchText = useDebounce(searchText, 500)

  const options = useSimpleProductTagsOptions({ searchText: debouncedSearchText, campaignId, ownerType: 'PRODUCT' })

  const handleTagRemoved = (removedTag) => {
    onChange(value.filter((tag) => tag !== removedTag))
  }

  const handleValueChanged = (tag: string) => {
    tag && !~value.indexOf(tag) && onChange([...value, tag])
    setSearchText(tag)
  }

  const handleDropdownOpened = (opened: boolean) => {
    setIsOpened(opened)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      // e.preventDefault()
      ~options?.data?.indexOf(searchText) && !~value.indexOf(searchText) && onChange([...value, searchText])
    }
  }

  return (
    <>
      <Select
        style={{ width: '100%' }}
        showSearch
        placeholder={placeholder}
        value={searchText}
        filterOption={false}
        onDropdownVisibleChange={handleDropdownOpened}
        onChange={handleValueChanged}
        onSearch={(search: string) => setSearchText(search)}
        loading={options.isLoading}
        onKeyDown={handleKeyDown}
        allowClear
      >
        {options?.data?.map((item, idx) => (
          <Select.Option key={idx} value={item}>
            {item}
          </Select.Option>
        ))}
      </Select>

      {value.map((tag) => {
        const isLongTag = tag.length > 20

        const tagElem = (
          <Tag style={{ userSelect: 'none', marginTop: '10px' }} key={tag} closable onClose={() => handleTagRemoved(tag)}>
            <span>{isLongTag ? `${tag.slice(0, 20)}...` : tag}</span>
          </Tag>
        )
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        )
      })}
    </>
  )
}

export default ProductTagsSelector
