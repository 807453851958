import { createSelector } from 'reselect'

const getProductColorsOptions: SelectOptionsSelector = state =>
  state.productColors.productColorsForOptions.map(item => ({
    id: item.id,
    name: item.name
  })) || ([] as SelectOption[])

export const productColorsSelectOptionsSelector: SelectOptionsSelector = createSelector(
  getProductColorsOptions,
  options => options
)
