export const WS_CONNECTED = 'WS_CONNECTED'
export const WS_DISCONNECT = 'WS_DISCONNECT'
export const WS_DISCONNECTED = 'WS_DISCONNECTED'
export const WS_SUBSCRIBE = 'WS_SUBSCRIBE'
export const WS_UNSUBSCRIBE = 'WS_UNSUBSCRIBE'
export const WS_ERROR = 'WS_ERROR'

export const SUBSCRIBE_TO_CHANNEL = 'SUBSCRIBE_TO_CHANNEL'
export const SUBSCRIBE_TO_CHANNEL_RESULT = 'SUBSCRIBE_TO_CHANNEL_RESULT'
export const UNSUBSCRIBE_FROM_CHANNEL = 'UNSUBSCRIBE_FROM_CHANNEL'
export const UNSUBSCRIBE_FROM_CHANNEL_RESULT = 'UNSUBSCRIBE_FROM_CHANNEL_RESULT'

export const BUILD_LOGS_UPDATED = 'BUILD_LOGS_UPDATED'
export const DEPLOY_LANDING_LOGS = 'DEPLOY_LANDING_LOGS'
export const JOB_INFO_UPDATED = 'JOB_INFO_UPDATED'
