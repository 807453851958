import React, { useEffect, useState } from 'react'
import { Form, Modal, Upload, Button, Popconfirm, message } from 'antd'
import hash from 'object-hash'
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import notification from 'mrx-notification'
import { useClearImageSetting, useUploadSettingImage } from '../../hooks'
import styles from './FormItemImageSetting.module.scss'
import { CustomFile, Setting } from '../../types'

type PropsType = {
  setting: Setting
  selfUpdate?: boolean
  onValueChanged?: (setting: Setting, newValue: any) => void
  onImageUploaded?: (setting: Setting, file: CustomFile) => void
}

const FormItemImageSetting = React.memo((props: PropsType) => {
  const {
    setting,
    selfUpdate,

    onValueChanged,
    onImageUploaded
  } = props

  const [previewVisible, setPreviewVisible] = useState<boolean>(false)
  const [previewImage, setPreviewImage] = useState<string>('')
  const [previewTitle, setPreviewTitle] = useState<string>('')

  const clearImageSetting = useClearImageSetting()
  const uploadSettingImage = useUploadSettingImage()

  useEffect(() => {
    // if setting already having the image URL let's use it to display the preview
    if (setting?.jsonValue?.signedUrl) {
      setPreviewImage(setting.jsonValue.signedUrl)
      setPreviewTitle(setting.name)
    } else {
      setPreviewImage('')
      setPreviewTitle('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(setting)])

  const handleCancel = () => setPreviewVisible(false)

  // Helping us to check the upload status of new image
  const handleOnImageChanged = (info: UploadChangeParam) => {
    if (info.file.status !== 'uploading') {
    }
    if (info.file.status === 'done') {
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`)
    }
  }

  // Help us to update the image preview
  const handleImage = async (file: any, name: string) => {
    file.url = URL.createObjectURL(file)
    const fixedName = name.trim().replace(/\s+/g, '_') as string
    setPreviewImage(file.url)
    setPreviewTitle(fixedName)

    if (selfUpdate) {
      uploadSettingImage.mutate(
        {
          ownerType: setting.ownerType,
          ownerId: setting.ownerId,
          settingId: setting.id,
          assetData: { name: fixedName, file }
        },
        {
          onSuccess: () => {
            notification.success({
              message: 'Successfully',
              description: `${setting.name} setting was updated!`
            })
          },
          onError: (error) => {
            notification.error({
              message: `${setting.name} setting error!`,
              description: error.toString()
            })
          }
        }
      )
    } else {
      onImageUploaded(setting, { file, name: fixedName })
    }
  }

  const handleRemoveImage = () => {
    clearImageSetting.mutate(
      { ownerType: setting.ownerType, ownerId: +setting.ownerId, settingId: setting.id },
      {
        onSuccess: (data) => {
          notification.success({
            message: 'Successfully',
            description: 'Image was uploaded successfully!'
          })
          onValueChanged(setting, data + '')
        },
        onError: (error) => {
          notification.error({
            message: `${setting.name} setting error!`,
            description: error.toString()
          })
        }
      }
    )
  }

  return (
    <Form.Item
      name={setting.alias}
      label={setting.name}
      key={setting.id}
      valuePropName=''
      rules={[{ required: setting.required, message: 'Please upload file!' }]}
    >
      <div className={styles.uploadSettingImageContainer}>
        {previewImage && (
          <img
            src={previewImage}
            alt=''
            className={styles.image}
            onClick={() => {
              if (previewImage) {
                setPreviewVisible(true)
              }
            }}
          />
        )}
        <div>
          <Upload
            name='file'
            accept='image/*'
            customRequest={(info: any) => {
              const fileName = info.file.name.replace(/\.[^.]+$/gm, '')
              handleImage(info.file, fileName)
            }}
            showUploadList={false}
            onChange={(info: UploadChangeParam<UploadFile<any>>) => handleOnImageChanged(info)}
          >
            <Button
              className={styles.uploadButton}
              icon={<UploadOutlined />}
              loading={uploadSettingImage.isLoading}
              disabled={uploadSettingImage.isLoading}
            >
              Click to Upload
            </Button>
          </Upload>
          {previewImage && (
            <Popconfirm
              title='Are you sure delete this image?'
              onConfirm={handleRemoveImage}
              onCancel={(e) => e?.stopPropagation()}
              okText='Yes'
              cancelText='No'
            >
              <Button
                className={styles.removeButton}
                loading={clearImageSetting.isLoading}
                disabled={clearImageSetting.isLoading || uploadSettingImage.isLoading}
                icon={<DeleteOutlined />}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              />
            </Popconfirm>
          )}
        </div>
      </div>
      <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt='example' style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </Form.Item>
  )
})

export default FormItemImageSetting
