import React, { useEffect, useState } from 'react'
import { AutoComplete, Button } from 'antd'
import hash from 'object-hash'
import { CloseCircleTwoTone, PlusOutlined } from '@ant-design/icons'
import { ProductAttributes } from 'consts'
import { Attribute } from 'features/types'

import styles from './ManageAttributes.module.scss'

type AttributeInput = Attribute & {
  required?: boolean
}

type Props = {
  attributesOptions: any[]
  initValues?: AttributeInput[]
  onAttributesChanged: (value: Attribute[]) => void
}

const attributeTypes = [{ value: ProductAttributes.size }, { value: ProductAttributes.color }, { value: ProductAttributes.variant }]

const ManageAttributes = (props: Props) => {
  const { attributesOptions, initValues, onAttributesChanged } = props
  const [attributes, setAttributes] = useState<AttributeInput[]>(initValues || [])

  useEffect(() => {
    setAttributes(initValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(initValues)])

  const handleAttributeChanged = (idx: number) => (value: any) => {
    if (!attributes.find((item) => item.attribute.toLowerCase() === value.toLowerCase())) {
      const newAttributes = [...attributes]
      newAttributes[idx].attribute = value
      newAttributes[idx].value = ''
      setAttributes(newAttributes)
      onAttributesChanged && onAttributesChanged(newAttributes)
    }
  }

  const handleAttributeValueChanged = (idx: number) => (value: string) => {
    const newAttributes = [...attributes]
    newAttributes[idx].value = value
    setAttributes(newAttributes)
    onAttributesChanged && onAttributesChanged(newAttributes)
  }

  const handleAttributeRemoved = ({ currentTarget }: React.MouseEvent<HTMLSpanElement>) => {
    if (currentTarget.dataset.required === 'true') {
      return
    }
    const newAttributes = [...attributes]
    newAttributes.splice(+currentTarget.dataset.idx, 1)
    setAttributes(newAttributes)
    onAttributesChanged && onAttributesChanged(newAttributes)
  }

  return (
    <>
      <div className={styles.attributesContainer}>
        {attributes.map((attribute, idx) => {
          const valueOptions = attributesOptions.find((item) => item.attribute === (attribute.attribute || ''))
          return (
            <React.Fragment key={idx}>
              <AutoComplete
                disabled={attribute.required}
                value={attribute.attribute}
                onChange={handleAttributeChanged(idx)}
                options={attributeTypes}
                placeholder='ATTRIBUTE'
              />
              <AutoComplete
                value={attribute.value}
                onChange={handleAttributeValueChanged(idx)}
                options={valueOptions?.values.map((value) => ({ value }))}
                placeholder='VALUE'
              />
              <CloseCircleTwoTone
                data-idx={idx}
                data-required={attribute.required}
                twoToneColor='tomato'
                onClick={handleAttributeRemoved}
                className={styles.deleteButton}
              />
            </React.Fragment>
          )
        })}
        <Button type='dashed' onClick={() => setAttributes([...attributes, { attribute: '', value: '' }])}>
          <PlusOutlined /> Add attribute
        </Button>
      </div>
    </>
  )
}

export default ManageAttributes
