import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import featureStateSelector from './featureStateSelector'
import { SitesManagementState } from '../types'

export const isNeedToFetchListSelector = createSelector<ReduxState, SitesManagementState, boolean>(
  featureStateSelector,
  state => {
    const { currentPage, pages } = state.pagination
    return !pages[currentPage]
  }
)

export default isNeedToFetchListSelector
