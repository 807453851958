import React, { useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import { Table, Popconfirm, Layout, PageHeader } from 'antd'
import notification from 'mrx-notification'
import { ColumnsType } from 'antd/lib/table'
import useHeightResize from 'utils/useHeightResize'
import { Breadcrumbs } from 'components'
import CreateProductColor from '../CreateProductColor'
import UpdateProductColor from '../UpdateProductColor'
import * as rootStyles from 'assets/layoutStyle'
import styles from './ProductColorsList.module.scss'
import { connector, PropsFromRedux } from './container'
import { ProductColor } from '../../types'

type HeightResizeRule = [number, number, number]
const rules: HeightResizeRule[] = [
  [0, 800, 8],
  [800, Infinity, 10]
]

const ProductColorsList = (props: PropsFromRedux) => {
  const {
    productColors,
    isLoading,
    error,
    isNeedToFetchList,
    total,
    currentPage,

    changeCurrentPage,
    changePageSize,
    fetchProductColorsList,
    removeProductColor
  } = props

  const [selectedProductColorId, setSelectedProductColorId] = useState<number>()
  const [isUpdateDrawerVisible, setIsUpdateDrawerVisible] = useState<boolean>(false)

  const { history } = useReactRouter()

  const handleChangePageSize = (newSize: number) => {
    changePageSize(newSize)
    fetchProductColorsList()
  }

  useHeightResize(handleChangePageSize, rules)

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Product colors list page error!',
        description: error
      })
    }
  }, [error])

  useEffect(() => {
    isNeedToFetchList && fetchProductColorsList()
  }, [fetchProductColorsList, isNeedToFetchList])

  const doConfirm = async (productColorId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    const isRemoved = await removeProductColor(productColorId)
    if (isRemoved) {
      notification.success({
        message: 'Successfully',
        description: 'Product Color was deleted successfully!'
      })
    }
  }

  const columns: ColumnsType<ProductColor> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (record: ProductColor) => (
        <span>
          <Popconfirm
            title='Are you sure delete this color?'
            onConfirm={(e) => doConfirm(record.id, e)}
            onCancel={(e) => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <a
              href='/#'
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              Remove
            </a>
          </Popconfirm>
        </span>
      )
    }
  ]

  const handleOnRow = (record: ProductColor) => {
    return {
      onClick: () => {
        setSelectedProductColorId(record.id)
        setIsUpdateDrawerVisible(true)
      }
    }
  }

  const handleOnClose = () => {
    setIsUpdateDrawerVisible(false)
  }

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader onBack={() => history.goBack()} title='Product Color' extra={[<CreateProductColor key='1' />]} />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Catalogs', url: '/' },
            { title: 'Colors List', url: '/' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <Table
          columns={columns}
          dataSource={productColors}
          bordered
          rowKey={(record) => record.id}
          onRow={handleOnRow}
          loading={isLoading}
          pagination={{
            onChange: changeCurrentPage,
            current: currentPage,
            total
          }}
        />
      </Layout.Content>
      {selectedProductColorId && (
        <UpdateProductColor productColorId={selectedProductColorId} onClose={handleOnClose} visible={isUpdateDrawerVisible} />
      )}
    </Layout>
  )
}

export default connector(ProductColorsList)
