import React, { useEffect } from 'react'
import { Button, Drawer, Input, Form, Select } from 'antd'
import notification from 'mrx-notification'
import { Store } from 'antd/lib/form/interface'
import { CurrenciesSelect } from 'features/currencies/components'

import styles from './UpdateCountry.module.scss'
import { useCountry, useUpdateCountry } from '../../hooks'

interface FormStore extends Store {
  name: string
  currencyId: number
  iso2: string
  iso3: string
  worldPart: string
  code: string
}

type Props = {
  countryId?: number
  onClose: () => void
  visible: boolean
}

const UpdateCountry = (props: Props) => {
  const { onClose, countryId, visible } = props

  const [form] = Form.useForm()

  const { data: country, isLoading } = useCountry(countryId)

  const updateCountry = useUpdateCountry()

  useEffect(() => {
    if (country) {
      form.setFieldsValue({
        name: country.name,
        currencyId: country.currencyId,
        currencyName: country.currencyName,
        iso2: country.iso2,
        iso3: country.iso3,
        worldPart: country.worldPart,
        code: country.code
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country])

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  useEffect(() => {
    if (updateCountry.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Country was updated successfully!'
      })
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCountry.isSuccess])

  if (!country) {
    return null
  }

  const onFinishHandler = (values: FormStore) => {
    updateCountry.mutate({ countryId, countryData: values })
  }

  return (
    <Drawer title='Update Country' width='400' onClose={handleClose} visible={visible}>
      <Form
        layout='vertical'
        hideRequiredMark
        onFinish={onFinishHandler}
        form={form}
        initialValues={{
          name: country.name,
          currencyId: country.currencyId,
          currencyName: country.currency.name,
          iso2: country.iso2,
          iso3: country.iso3,
          worldPart: country.worldPart,
          code: country.code
        }}
      >
        <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input country name!' }]}>
          <Input style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item name='currencyName' noStyle>
          <Input type='hidden' />
        </Form.Item>

        <Form.Item name='currencyId' label='Currency' rules={[{ required: true, message: 'Please select country currency!' }]}>
          <CurrenciesSelect
            initialOption={{
              id: country?.currencyId || 0,
              name: country?.currency?.name || 'Select a currency'
            }}
            onSelect={(selectedOption: SelectOption) => {
              form.setFieldsValue({
                currencyId: selectedOption.id,
                currencyName: selectedOption.name
              })
            }}
          />
        </Form.Item>

        <Form.Item name='iso2' label='ISO 2' rules={[{ required: true, message: 'Please input ISO 2!' }]}>
          <Input style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item name='iso3' label='ISO 3' rules={[{ required: true, message: 'Please input ISO 3' }]}>
          <Input style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item name='worldPart' label='World Part' rules={[{ required: true, message: 'Please select world part!' }]}>
          <Select style={{ width: '100%' }}>
            <Select.Option value='America'>America</Select.Option>
            <Select.Option value='Africa'>Africa</Select.Option>
            <Select.Option value='Asia'>Asia</Select.Option>
            <Select.Option value='Australia'>Australia</Select.Option>
            <Select.Option value='Europe'>Europe</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item name='code' label='Code' rules={[{ required: true, message: 'Please input code' }]}>
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right'
          }}
        >
          <Button onClick={handleClose} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default UpdateCountry
