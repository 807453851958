import React, { useEffect } from 'react'
import { Button, Popconfirm, Progress } from 'antd'
import { RedoOutlined } from '@ant-design/icons'
import { connector, PropsFromRedux } from './container'
import useInterval from 'utils/useInterval'
import styles from './SitePreview.module.scss'

const SitePreview = (props: PropsFromRedux) => {
  const { buildInfo, environment, ownerType, ownerId, version, route = '/', buildPreview, takeSiteBuildInfo } = props

  useEffect(() => {
    takeSiteBuildInfo({ ownerType, ownerId, version, environment })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version])

  useInterval(() => {
    if (!buildInfo?.artifactsUrl) {
      takeSiteBuildInfo({ ownerType, ownerId, version, environment })
    }
  }, 15000)

  const handleBuildPreviewClicked = () => {
    buildPreview({ ownerType, ownerId, version, environment })
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
      {!buildInfo && (
        <Button size='large' type='primary' onClick={handleBuildPreviewClicked}>
          Build preview
        </Button>
      )}
      {buildInfo && !buildInfo.artifactsUrl && (
        <div className={styles.buildingContainer}>
          <div className={styles.buildingText}>
            <span>{buildInfo.status.replace('_', ' ').toLowerCase()}</span>
            {buildInfo.status === 'ERROR' && (
              <span className={styles.buildingAction}>
                <Popconfirm
                  title='Are you sure restart this task?'
                  onConfirm={() => handleBuildPreviewClicked()}
                  onCancel={(e) => e?.stopPropagation()}
                  okText='Yes'
                  cancelText='No'
                >
                  <RedoOutlined
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  />
                </Popconfirm>
              </span>
            )}
          </div>
          <Progress percent={buildInfo.progress} status='active' />
        </div>
      )}
      {buildInfo?.artifactsUrl && (
        <iframe
          title='code-preview'
          style={{ border: '1px solid #aaaaaa', width: '100%', height: '700px' }}
          src={`${buildInfo.artifactsUrl}index.html#${route}`}
        />
      )}
    </div>
  )
}

export default connector(SitePreview)
