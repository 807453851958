import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { PostmarkSetting } from '../types'

type PostmarkSettingsList = {
  items: PostmarkSetting[]
  total: number
}

type Payload = PostmarkSettingsList & {
  page: number
}

export type FetchPostmarkSettingsListAction = FSA<undefined, Payload, string>
type FetchPostmarkSettingsList = () => MrxThunk<FetchPostmarkSettingsListAction>
type QueryResponse = GraphQLResponse<'postmarkSettingsListPage', PostmarkSettingsList>

const FETCH_POSTMARK_SETTINGS_LIST = `
  query fetchPostmarkSettingsList ($page: Int, $size: Int, $workspaceId: Int!) {
    postmarkSettingsListPage (page: $page, size: $size, workspaceId: $workspaceId) {
      items {
        id
        name
        apiKey
        from
      }
      total
    }
  }
`

const fetchPostmarkSettingsList: FetchPostmarkSettingsList = () => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_POSTMARK_SETTINGS_LIST,
    meta: { done: false }
  })

  try {
    const {
      workspaces: {
        activeWorkspace: { id: workspaceId }
      },
      postmarkSettings: {
        pagination: { currentPage: page, size }
      }
    } = getState()
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_POSTMARK_SETTINGS_LIST,
      variables: { page, size, workspaceId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { postmarkSettingsListPage } = data
    if (!postmarkSettingsListPage) {
      throw new Error("Can't get Postmark Settings list page!")
    }

    dispatch({
      type: t.FETCH_POSTMARK_SETTINGS_LIST,
      payload: {
        page,
        total: postmarkSettingsListPage.total,
        items: postmarkSettingsListPage.items
      },
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_POSTMARK_SETTINGS_LIST,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchPostmarkSettingsList }
