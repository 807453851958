import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

import { Country, CountryInput } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'updateCountry', boolean>

const UPDATE_COUNTRY = `
  mutation updateCountry($countryId: Int!, $countryData: CountryInput!) {
    updateCountry(countryId: $countryId, countryData: $countryData)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateCountryArgs = {
  countryId: number
  countryData: CountryInput
}

type UpdateCountry = (args: UpdateCountryArgs) => Promise<UpdateCountryArgs>

const updateCountry: UpdateCountry = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_COUNTRY,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateCountry } = data
    if (!updateCountry) {
      throw new Error("Can't update country!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateCountryContext = { prevCountry?: Country }

type UpdateCountryEvents = {
  onMutate: (variables: UpdateCountryArgs) => Promise<UpdateCountryContext>
  onError: (error: string, variables: UpdateCountryArgs, context: UpdateCountryContext) => void
  onSettled: (data?: UpdateCountryArgs) => void
}

const updateCountryEvents: UpdateCountryEvents = {
  onMutate: async (variables: UpdateCountryArgs) => {
    await queryClient.cancelQueries(['country', variables.countryId])

    // Snapshot the previous value
    const prevCountry = queryClient.getQueryData<Country>(['country', variables.countryId])

    // Optimistically update to the new values
    if (prevCountry) {
      queryClient.setQueryData<Country>(['country', variables.countryId], {
        ...prevCountry,
        id: variables.countryId,
        ...variables.countryData
      })
    }

    return { prevCountry }
  },
  onError: (_err, variables, context) => {
    if (context?.prevCountry) {
      // Restore previous version of country on any error
      queryClient.setQueryData<Country>(['country', variables.countryId], context.prevCountry)
    }
  },
  onSettled: (data) => {
    if (data?.countryId) {
      queryClient.invalidateQueries(['country', data.countryId])
      queryClient.invalidateQueries(['countriesList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUpdateCountry = () => useMutation(updateCountry, updateCountryEvents)
