import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { SiteType } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Component, ComponentInput } from '../types'

type CreateComponentArgs = {
  ownerType: SiteType
  ownerId: number
  componentData: ComponentInput
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'createComponent', Component>

const CREATE_COMPONENT = `
  mutation CreateComponent($ownerType: SiteType!, $ownerId: Int!, $componentData: ComponentInput!) {
    createComponent(ownerType: $ownerType, ownerId: $ownerId, componentData: $componentData) {
      id
      name
      ownerType
      ownerId
      ownerVersion
      sourceCode
      sourceStyle
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type CreateComponent = (args: CreateComponentArgs) => Promise<Component>

const createComponent: CreateComponent = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_COMPONENT,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createComponent } = data
    if (!createComponent) {
      throw new Error("Can't create component!")
    }

    return createComponent
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type CreateComponentEvents = {
  onSettled: (data?: Component) => void
}

const createComponentEvents: CreateComponentEvents = {
  onSettled: (data) => {
    if (data?.id) {
      queryClient.invalidateQueries([`${data.ownerType.toLowerCase()}`, +data.ownerId])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCreateComponent = () => useMutation(createComponent, createComponentEvents)
