import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Workspace } from '../types'

type GraphQLMe = { workspaces: Workspace[] }

export type FetchWorkspacesListAction = FSA<undefined, Workspace[], string>
type FetchWorkspacesList = () => MrxThunk<FetchWorkspacesListAction>
type QueryResponse = GraphQLResponse<'me', GraphQLMe>

const FETCH_WORKSPACES_LIST = `
  query fetchWorkspacesList {
    me {
      workspaces {
        id
        name
      }
    }
  }
`

const fetchWorkspacesList: FetchWorkspacesList = () => async dispatch => {
  dispatch({
    type: t.FETCH_WORKSPACES_LIST,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_WORKSPACES_LIST,
      variables: {}
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { me } = data
    if (!me) {
      throw new Error("Can't get product sizes list page!")
    }

    dispatch({
      type: t.FETCH_WORKSPACES_LIST,
      payload: me.workspaces,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_WORKSPACES_LIST,
      payload: extractErrorInfo(err),
      error: true
    })
  }
}

export { fetchWorkspacesList }
