// TODO - remove becouse of legacy

import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

const mapStateToProps = (state: ReduxState) => {
  return {
    failedFulfillmentReport: selectors.failedFulfillmentsSelector(state),
    error: selectors.errorSelector(state),
    isLoading: selectors.isLoadingSelector(state, 'isFailedFulfillmentReportLoading'),
    isLoadingCsv: selectors.isLoadingSelector(state, 'isFailedFulfillmentCsvReportLoading')
  }
}

const mapDispatchToProps = {
  fetchFailedFulfillmentCsvReport: actions.fetchFailedFulfillmentCsvReport,
  fetchFailedFulfillmentReport: actions.fetchFailedFulfillmentReport
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector>
