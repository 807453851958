// TODO - remove becouse of legacy

import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Landing, LandingSeoInput } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'updateLandingSeo', boolean>

const UPDATE_LANDING_SEO = `
  mutation UpdateLandingSeo($landingId: Int!, $landingSeo: LandingSeoInput!) {
    updateLandingSeo(landingId: $landingId, landingSeo: $landingSeo)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateLandingSeoArgs = {
  landingId: number
  landingSeo: LandingSeoInput
}

type UpdateLandingSeo = (args: UpdateLandingSeoArgs) => Promise<UpdateLandingSeoArgs>

const updateLandingSeo: UpdateLandingSeo = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: UPDATE_LANDING_SEO,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateLandingSeo } = data
    if (!updateLandingSeo) {
      throw new Error("Can't update landing seo!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type UpdateLandingSeoContext = { prevLanding?: Landing }

type UpdateLandingSeoEvents = {
  onMutate: (variables: UpdateLandingSeoArgs) => Promise<UpdateLandingSeoContext>
  onError: (error: string, variables: UpdateLandingSeoArgs, context: UpdateLandingSeoContext) => void
  onSettled: (data?: UpdateLandingSeoArgs) => void
}

const updateLandingSeoEvents: UpdateLandingSeoEvents = {
  onMutate: async (variables: UpdateLandingSeoArgs) => {
    await queryClient.cancelQueries(['landing', variables.landingId])

    // Snapshot the previous value
    const prevLanding = queryClient.getQueryData<Landing>(['landing', variables.landingId])

    // Optimistically update to the new values
    if (prevLanding) {
      queryClient.setQueryData<Landing>(['landing', variables.landingId], {
        ...prevLanding,
        id: variables.landingId,
        ...variables.landingSeo
      })
    }

    return { prevLanding }
  },
  onError: (_err, variables, context) => {
    if (context?.prevLanding) {
      // Restore previous version of landing on any error
      queryClient.setQueryData<Landing>(['landing', variables.landingId], context.prevLanding)
    }
  },
  onSettled: (data) => {
    if (data?.landingId) {
      queryClient.invalidateQueries(['landing', data.landingId])
      queryClient.invalidateQueries(['landingsList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useUpdateLandingSeo = () => useMutation(updateLandingSeo, updateLandingSeoEvents)
