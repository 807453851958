import * as t from '../actionTypes'
import { Moment } from 'moment'

export type SetReportDatesAction = BaseFSA<{ startDate: Moment; endDate: Moment }>

export const setReportDates = (startDate: Moment, endDate: Moment): MrxThunk<SetReportDatesAction> => dispatch => {
  dispatch({
    type: t.SET_REPORT_DATES,
    payload: {
      startDate,
      endDate
    }
  })
}
