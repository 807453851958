import React from 'react'
import { Select } from 'antd'
import hash from 'object-hash'
import useDebounce from 'utils/useDebounce'

const { Option } = Select

type Props = {
  isLoading: boolean
  options: SelectOption[]
  initialOption?: SelectOption
  placeholder?: string
  plugin?: string
  disabled?: boolean
  allowClear?: boolean
  selected?: number | string
  className?: string
  onSelect: (selectedOption: SelectOption) => void
  onSearch: (searchText: string) => void
  onClear?: () => void
}

const defaultOption = { id: 0, name: '' }

const SearchableSelectBase = React.memo((props: Props) => {
  const {
    isLoading,
    initialOption = defaultOption,
    allowClear = false,
    disabled,
    placeholder,
    options = [],
    plugin = '',
    selected,
    onSelect,
    onSearch,
    onClear,
    className
  } = props

  const [selectedOption, setSelectedOption] = React.useState<SelectOption>(initialOption)
  const [extendedOptions, setExtendedOptions] = React.useState<SelectOption[]>([initialOption])
  const [isOpened, setIsOpened] = React.useState<boolean>(false)
  const [searchText, setSearchText] = React.useState<string>('')
  const debouncedSearchText = useDebounce(searchText, 500)

  React.useEffect(() => {
    if (plugin) {
      setSelectedOption({ id: 0, name: 'Not set' })
    }
  }, [plugin])

  React.useEffect(() => {
    setSelectedOption(initialOption)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(initialOption)])

  React.useEffect(() => {
    // Let's always add selected item to options list
    const newOptions = [...options]
    if (selectedOption?.id) {
      if (!newOptions.some((item) => item.id === selectedOption.id)) {
        newOptions.push(selectedOption)
      }
    }
    setExtendedOptions(newOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(options), hash(selectedOption)])

  React.useEffect(() => {
    if (isOpened) {
      onSearch(debouncedSearchText)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText])

  const handleValueChanged = (optionId: number) => {
    const selectedOption = options.find((item) => item.id === optionId) || defaultOption
    setSelectedOption(() => selectedOption)
    setSearchText('')
    onSelect(selectedOption)
  }

  const handleDropdownOpened = (opened: boolean) => {
    if (opened && (extendedOptions.length === 1 || extendedOptions.length === 0)) {
      onSearch(debouncedSearchText)
    }
    setIsOpened(opened)
  }

  return (
    <Select
      className={className}
      style={{ width: '100%' }}
      showSearch
      placeholder={placeholder || ''}
      value={selected !== undefined ? selected : selectedOption?.id || ''}
      filterOption={false}
      onDropdownVisibleChange={handleDropdownOpened}
      onChange={handleValueChanged}
      onSearch={(search: string) => setSearchText(search)}
      loading={isLoading}
      disabled={disabled}
      allowClear={allowClear}
      onClear={onClear}
    >
      {extendedOptions.map((item) => (
        <Option key={item.id} value={item.id}>
          {item.name}
        </Option>
      ))}
    </Select>
  )
})

export default SearchableSelectBase
