import { createSelector } from 'reselect'

const getShipstationSettingsOptions: SelectOptionsSelector = (state) =>
  state.shipstationSettings.shipstationSettingsForOptions.map((item) => ({
    id: item.id,
    name: item.name
  })) || ([] as SelectOption[])

export const shipstationSettingsSelectOptionsSelector: SelectOptionsSelector = createSelector(
  getShipstationSettingsOptions,
  (options) => options
)
