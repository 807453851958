import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { Category } from '../types'

type CategoriesListSelector = (state: ReduxState, campaignId: number) => Category[]
const getCategoriesList: CategoriesListSelector = (state, campaignId) => {
  const result = [] as Category[]
  if (
    Object.prototype.hasOwnProperty.call(state.categories.pagination, campaignId) &&
    Object.prototype.hasOwnProperty.call(state.categories.categories, campaignId)
  ) {
    const { pages, currentPage } = state.categories.pagination[campaignId]
    const pageCategoryIds = pages[currentPage] || []

    pageCategoryIds.forEach((categoryId) => {
      result.push(state.categories.categories[campaignId][categoryId])
    })
  }

  return result
}

export const categoriesListSelector: CategoriesListSelector = createSelector(getCategoriesList, (list) => list)
