import { Setting } from 'features/settings/types'

export const isSettingValueValid = (setting: Setting) => {
  if (!setting.required) {
    return true
  }

  if (setting.value === null) {
    return false
  }

  switch (setting.type) {
    case 'BOOLEAN':
      return setting.value === 'true' || setting.value === 'false'

    default:
      return setting.value !== ''
  }
}
