import { useInfiniteQuery } from 'react-query'
import hash from 'object-hash'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

import { CustomersList, GraphQLCustomersList, CustomersListFilters } from '../types'

const DEFAULT_PAGE_SIZE = 15

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'customersListPage', GraphQLCustomersList>

const FETCH_CUSTOMERS_LIST = `
  query fetchCustomersList ($page: Int, $size: Int, $filters: CustomersFilters!) {
    customersListPage(page: $page, size: $size, filters: $filters) {
      items {
        id
        workspaceId
        email
        phone
        firstName
        lastName
        phone
        numberOfOrders
        numberOfNotes
        numberOfSubscriptions
        customerTags {
          id
          customerId
          tag
        }
        createdAt
        updatedAt
      }
      total
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type PageParam = {
  page?: number
  size?: number
  filters: CustomersListFilters
}

type FetchCustomersListArgs = {
  queryKey: string | string[]
  pageParam?: PageParam
}

type FetchCustomersList = (args: FetchCustomersListArgs) => Promise<CustomersList>

const fetchCustomersList: FetchCustomersList = async ({ pageParam }) => {
  const { page = 1, size = DEFAULT_PAGE_SIZE, filters } = pageParam || {}
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_CUSTOMERS_LIST,
      variables: { page, size, filters }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { customersListPage } = data
    if (!customersListPage) {
      throw new Error("Can't get customers list page!")
    }

    return {
      items: customersListPage.items,
      total: customersListPage.total,
      currentPage: page,
      pageSize: size,
      filters
    }
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

type GetNextPageParam = (args: CustomersList) => PageParam

type CustomersListEvents = {
  keepPreviousData: boolean
  getPreviousPageParam: GetNextPageParam
  getNextPageParam: GetNextPageParam
}

const customersListEvents: CustomersListEvents = {
  keepPreviousData: true,
  getPreviousPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, filters }) => {
    if (currentPage > 1) {
      return {
        page: currentPage - 1,
        size: pageSize,
        filters
      }
    }

    return undefined
  },
  getNextPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, total = 0, filters }) => {
    if (pageSize * currentPage < total) {
      return {
        page: currentPage + 1,
        size: pageSize,
        filters
      }
    }
    return undefined
  }
}

export const useCustomersList = (filters: CustomersListFilters) => {
  const cacheKey = hash(filters)

  return useInfiniteQuery<CustomersList, Error, CustomersList>(
    ['customersList', cacheKey],
    (prev) => {
      return fetchCustomersList({
        queryKey: ['customersList', cacheKey],
        pageParam: { ...prev.pageParam, filters }
      })
    },
    customersListEvents
  )
}
