import * as actions from './actions'
import * as hooks from './hooks'
import * as actionTypes from './actionTypes'
import reducer from './reducer'
import * as components from './components'
import * as selectors from './selectors'
import * as types from './types'

console.log('DTG Products feature initialized!')

export { actions, hooks, actionTypes, reducer, components, selectors, types }
