import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Table, Popconfirm } from 'antd'
import { EditTwoTone, DeleteTwoTone } from '@ant-design/icons'
import notification from 'mrx-notification'

import { ColumnProps } from 'antd/es/table'
import { OrderNote } from 'features/orders/types'
import CreateOrderNote from '../CreateOrderNote'
import UpdateOrderNote from '../UpdateOrderNote'
import { useOrder, useRemoveOrderNote } from '../../hooks'
import { connector, PropsFromRedux } from './container'
import styles from './NotesTab.module.scss'

type NotesTabType = PropsFromRedux & {
  orderId?: number
}

const NotesTab = (props: NotesTabType) => {
  const { orderId, user } = props

  const [selectedRecord, setSelectedRecord] = useState<OrderNote>()
  const [isUpdateDrawerVisible, setIsUpdateDrawerVisible] = useState<boolean>(false)

  // eslint-disable-next-line
  const [now, _setNow] = useState(moment())

  const order = useOrder(orderId)
  const removeOrderNote = useRemoveOrderNote()

  useEffect(() => {
    if (order.error) {
      notification.error({
        message: 'Order Note list error!',
        description: order.error instanceof Error ? order.error.message : order.error.toString()
      })
    }
  }, [order.error])

  useEffect(() => {
    if (removeOrderNote.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Order Note was removed successfully!'
      })
    }
  }, [removeOrderNote.isSuccess])

  const doConfirm = async (orderNoteId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    removeOrderNote.mutate(orderNoteId)
  }

  const columns: ColumnProps<OrderNote>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Author Name',
      render: (record: OrderNote) => {
        return (
          <div>
            {record.author?.firstName} {record.author?.lastName}
          </div>
        )
      },
      key: 'firstName'
    },
    {
      title: 'Note',
      render: (record: OrderNote) => {
        return <div>{record.note}</div>
      },
      key: 'note'
    },
    {
      title: 'Date',
      render: (record: OrderNote) => moment(record.createdAt).format('MM/DD/YYYY HH:mm:ss'),
      key: 'createdAt'
    },
    {
      title: '',
      dataIndex: '',
      key: 'edit',
      width: '10%',
      align: 'center',
      render: (record: OrderNote) => {
        let canEdit = record.userId === user.id
        if (user.roles.includes('WORKSPACE_ADMIN')) {
          canEdit = true
        }
        if (user.roles.includes('SYSTEM_ADMIN')) {
          canEdit = true
        }
        if (!canEdit) return null
        return (
          <span>
            <EditTwoTone
              style={{ fontSize: '20px' }}
              onClick={(e) => {
                e.stopPropagation()
                setSelectedRecord(record)
                setIsUpdateDrawerVisible(true)
              }}
            />
          </span>
        )
      }
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '10%',
      align: 'center',
      render: (record: OrderNote) => {
        let canDelete = record.userId === user.id
        if (user.roles.includes('WORKSPACE_ADMIN')) {
          canDelete = true
        }
        if (user.roles.includes('SYSTEM_ADMIN')) {
          canDelete = true
        }
        if (!canDelete) return null
        return (
          <span>
            <Popconfirm
              title='Are you sure delete this note?'
              onConfirm={(e) => doConfirm(record.id, e)}
              onCancel={(e) => e?.stopPropagation()}
              okText='Yes'
              cancelText='No'
            >
              <DeleteTwoTone
                twoToneColor='#ec1c24'
                style={{ fontSize: '20px' }}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              />
            </Popconfirm>
          </span>
        )
      }
    }
  ]

  const handleOnClose = () => {
    setIsUpdateDrawerVisible(false)
  }

  const renderUpdate = (e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    return <UpdateOrderNote orderId={orderId} orderNoteId={selectedRecord?.id} onClose={handleOnClose} visible={isUpdateDrawerVisible} />
  }

  return (
    <>
      <CreateOrderNote className={styles.createButton} orderId={orderId} />
      <Table
        columns={columns}
        dataSource={order?.data?.orderNotes?.map((item) => item).flat()}
        bordered
        rowKey={(record) => record.id}
        loading={order.isLoading}
      />
      {selectedRecord && renderUpdate()}
    </>
  )
}

export default connector(NotesTab)
