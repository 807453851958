import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

const FETCH_CAMPAIGN_LEVEL_CSV_REPORT = `
query CampaignAnalysisReportExportToCSV($filters: CampaignAnalysisReportFilters!) {
  campaignAnalysisReportExportToCSV(filters: $filters) 
}
`

const campaignAnalysisReportExportToCSV = (startDate, endDate, campaignIds) => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_CAMPAIGN_LEVEL_CSV_REPORT
  })

  try {
    const {
      workspaces: {
        activeWorkspace: { id: workspaceId }
      }
    } = getState()

    const {
      data: { data, errors }
    } = await axios.post('/graphql', {
      query: FETCH_CAMPAIGN_LEVEL_CSV_REPORT,
      variables: {
        filters: {
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD'),
          workspaceId,
          campaignIds
        }
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { campaignAnalysisReportExportToCSV } = data
    if (!campaignAnalysisReportExportToCSV) {
      throw new Error("Can't fetch campaign analysis report CSV file url!")
    }

    dispatch({
      type: t.FETCH_CAMPAIGN_LEVEL_CSV_REPORT,
      meta: { done: true }
    })

    return campaignAnalysisReportExportToCSV
  } catch (err) {
    dispatch({
      type: t.FETCH_CAMPAIGN_LEVEL_CSV_REPORT,
      payload: extractErrorInfo(err),
      meta: { error: true }
    })
  }
}

export default campaignAnalysisReportExportToCSV
