import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

const FETCH_OVERVIEW_REPORT = `
query GetOverviewReport($filters: OverviewReportFilters!) {
  overviewReport(filters: $filters) {
    day
    grossProfit
    totalNumberOfSales
    profitToSale
    numberOfCampaigns
    averageSalesToCampaigns
    facebookProfit
    facebookNumberOfSales
    facebookProfitToSale
    totalAdSpend
    averageCPA
    emailProfit
    emailNumberOfSales
    emailProfitToSale
    emailProfitToFacebookProfit
    subscriptionProfit
    subscriptionNumberOfSales
    subscriptionProfitToSale
    expectedSubscriptionsToCharge
    ecomProfit
    ecomNumberOfSales
    ecomProfitToSale
  }
}

`

const fetchOverviewReportList = (startDate, endDate) => async (dispatch, getState) => {
  dispatch({
    type: t.FETCH_OVERVIEW_REPORT
  })

  try {
    const {
      workspaces: {
        activeWorkspace: { id: workspaceId }
      }
    } = getState()

    const {
      data: {
        data: { overviewReport },
        errors
      }
    } = await axios.post('/graphql', {
      query: FETCH_OVERVIEW_REPORT,
      variables: {
        filters: { startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD'), workspaceId, includeTotals: true }
      }
    })

    if (!overviewReport) {
      throw new Error("Can't get overview report!")
    }

    guardFromErrors(errors)

    dispatch({
      type: t.FETCH_OVERVIEW_REPORT,
      payload: overviewReport,
      meta: { done: true }
    })
  } catch (err) {
    dispatch({
      type: t.FETCH_OVERVIEW_REPORT,
      payload: extractErrorInfo(err),
      meta: { error: true }
    })
  }
}

export default fetchOverviewReportList
