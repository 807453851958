import React, { useContext } from 'react'
import { FormInstance, Typography, Form } from 'antd'
import { GlobalContext } from 'appContexts'
import { SuppliersSelect } from 'features/customers/components'
import SupplierProductsSelect from '../SupplierProductsSelect'
import styles from './EditProductSupplier.module.scss'

type Props = {
  form: FormInstance
}

const EditProductSupplier = (props: Props) => {
  const { form } = props

  const { campaignId } = useContext(GlobalContext)

  return (
    <div className={styles.productFormBlock}>
      <Typography style={{ color: 'black', fontWeight: 'bold', paddingBottom: '8px' }}>Supplier</Typography>

      <div className={styles.productFormRow}>
        <Form.Item noStyle dependencies={[['supplierid']]}>
          {(formInstance) => {
            const supplierId = formInstance.getFieldValue('supplierId')

            return (
              <Form.Item
                name='supplierId'
                label='Choose Supplier'
                rules={[{ required: true, message: 'Please select Supplier!' }]}
                className={styles.productFormRowItem}
              >
                <SuppliersSelect
                  initialOption={{ id: supplierId, name: null }}
                  selected={supplierId}
                  onSelect={(supplier) => form.setFieldsValue({ supplierId: supplier.id })}
                  allowClear
                  onClear={() => form.setFieldsValue({ supplierId: undefined })}
                />
              </Form.Item>
            )
          }}
        </Form.Item>
      </div>

      <div className={styles.productFormRow}>
        <Form.Item noStyle dependencies={[['supplierid', 'supplierProductId']]}>
          {(formInstance) => {
            const supplierId = formInstance.getFieldValue('supplierId')

            if (!supplierId || supplierId === -1) return null

            const supplierProductId = formInstance.getFieldValue('supplierProductId')

            return (
              <Form.Item
                name='supplierProductId'
                label='Choose Supplier Product'
                rules={[{ required: true, message: 'Please select Supplier Product!' }]}
                className={styles.productFormRowItem}
              >
                <SupplierProductsSelect
                  ownerId={supplierId}
                  campaignId={campaignId}
                  initialOption={{ id: supplierProductId, name: null }}
                  onSelect={(supplierProduct) => form.setFieldsValue({ supplierProductId: supplierProduct.id })}
                  allowClear
                  onClear={() => form.setFieldsValue({ supplierProduct: undefined })}
                />
              </Form.Item>
            )
          }}
        </Form.Item>
      </div>
    </div>
  )
}

export default EditProductSupplier
