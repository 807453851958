import React, { useContext, useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import { Button, Drawer, Form, Layout, PageHeader, Switch } from 'antd'
import { InventoryDto } from '@merchx-v2/shared-types'
import notification from 'mrx-notification'
import { GlobalContext } from 'appContexts'
import { Breadcrumbs } from 'components'
import * as rootStyles from 'assets/layoutStyle'

import { useAddVariantItemsToStock, useInventoryList, useSetVariantItemsOnStock } from '../../hooks'
import styles from './InventoryListPage.module.scss'
import InventoryList from '../InventoryList'
import ProductsSelect from 'features/products/components/ProductsSelect'
import { Store } from 'antd/lib/form/interface'

interface FormStore extends Store {
  showOnlyExistingItems: boolean
  productId: number
}

const InventoryListPage = () => {
  const { history } = useReactRouter()

  const { campaignId } = useContext(GlobalContext)
  const [isSettingsOpened, setIsSettingsOpened] = useState(false)
  const [form] = Form.useForm()

  const [showOnlyExistingItems, setShowOnlyExistingItems] = useState(true)
  const [productId, setProductId] = useState<number>()

  const addVariantItemsToStock = useAddVariantItemsToStock()
  const setVariantItemsOnStock = useSetVariantItemsOnStock()
  const inventoryList = useInventoryList({ campaignId, showOnlyExistingItems, productId })

  let inventory: InventoryDto[] = []
  inventoryList && inventoryList.data && inventoryList.data.pages.forEach((page) => page.items.forEach((order) => inventory.push(order)))
  inventory = inventory.flat()

  const page = inventoryList.data?.pages.length ? inventoryList.data?.pages[inventoryList.data?.pages.length - 1].currentPage : 1
  const size = inventoryList.data?.pages.length ? inventoryList.data?.pages[inventoryList.data?.pages.length - 1].pageSize : 30

  useEffect(() => {
    form.setFieldsValue({ name: 'showOnlyExistingItems', value: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (inventoryList.error) {
      notification.error({
        message: 'Inventory list error!',
        description: inventoryList.error instanceof Error ? inventoryList.error.message : inventoryList.error.toString()
      })
    }
  }, [inventoryList.error])

  useEffect(() => {
    if (addVariantItemsToStock.error) {
      notification.error({
        message: 'Add variant items to stock error!',
        description:
          addVariantItemsToStock.error instanceof Error ? addVariantItemsToStock.error.message : addVariantItemsToStock.error.toString()
      })
    }
  }, [addVariantItemsToStock.error])

  useEffect(() => {
    if (setVariantItemsOnStock.error) {
      notification.error({
        message: 'Set variant items to stock error!',
        description:
          setVariantItemsOnStock.error instanceof Error ? setVariantItemsOnStock.error.message : setVariantItemsOnStock.error.toString()
      })
    }
  }, [setVariantItemsOnStock.error])

  const handleSettingsChanged = async (values: FormStore) => {
    setShowOnlyExistingItems(values.showOnlyExistingItems)
    setProductId(values.productId === 0 ? undefined : values.productId)
    setIsSettingsOpened(false)
  }

  const handleMessage = (message: string) => {
    notification.info({
      message: 'Inventory list',
      description: message
    })
  }

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader
          onBack={() => history.goBack()}
          title='Inventory'
          extra={[
            <Button key='settings-button' onClick={() => setIsSettingsOpened(!isSettingsOpened)}>
              Settings
            </Button>
          ]}
        />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Reporting', url: '/reporting' },
            { title: 'Inventory', url: '/reporting/inventory' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <InventoryList
          inventory={inventory}
          dataLength={page * size}
          isLoading={
            inventoryList.isLoading || inventoryList.isFetching || addVariantItemsToStock.isLoading || setVariantItemsOnStock.isLoading
          }
          hasNextPage={!!inventoryList.hasNextPage}
          fetchNextPage={inventoryList.fetchNextPage}
          refetch={inventoryList.refetch}
          onAddVariantItemsToStock={(productId: number, variantKey: string, quantity: number) =>
            addVariantItemsToStock.mutate({ campaignId, productId, variantKey, quantity })
          }
          onSetVariantItemsOnStock={(productId: number, variantKey: string, quantity: number) =>
            setVariantItemsOnStock.mutate({ campaignId, productId, variantKey, quantity })
          }
          onMessage={handleMessage}
        />
        <Drawer visible={isSettingsOpened} onClose={() => setIsSettingsOpened(false)}>
          <Form layout='vertical' hideRequiredMark onFinish={handleSettingsChanged} form={form}>
            <Form.Item name='showOnlyExistingItems' label='Show only present values'>
              <Switch />
            </Form.Item>

            <Form.Item name='productId' label='Product' rules={[{ required: false }]} className={styles.productFormRowItem}>
              <ProductsSelect
                campaignId={campaignId}
                settingId={0}
                allowClear
                initialOption={{
                  id: 0,
                  name: 'Select a product'
                }}
                onSelect={(selectedOption: SelectOption) => {
                  form.setFieldsValue({
                    productId: selectedOption.id
                  })
                }}
              />
            </Form.Item>

            <div
              style={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right'
              }}
            >
              <Button onClick={() => setIsSettingsOpened(false)} className={styles.cancelButton}>
                Cancel
              </Button>
              <Button
                type='primary'
                htmlType='submit'
                loading={inventoryList.isLoading || addVariantItemsToStock.isLoading || setVariantItemsOnStock.isLoading}
              >
                Apply
              </Button>
            </div>
          </Form>
        </Drawer>
      </Layout.Content>
    </Layout>
  )
}

export default InventoryListPage
