import { createSelector, Selector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { ProductColorsState } from '../types'

const getFeatureState: Selector<ReduxState, ProductColorsState> = state => state.productColors

export const featureStateSelector = createSelector<ReduxState, ProductColorsState, ProductColorsState>(
  getFeatureState,
  state => state
)

export default featureStateSelector
