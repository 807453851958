import React, { useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { StoreDto } from '@merchx-v2/shared-types'
import { Table, Button } from 'antd'
import notification from 'mrx-notification'
import { createTableColumns } from './createTableColumns'
import { useStoreProfitsList } from '../../hooks'
import styles from './StoreProfitsList.module.scss'
import DecreaseBalanceModal from '../DecreaseBalanceModal'
import IncreaseBalanceModal from '../IncreaseBalanceModal'

type Props = {
  storeId: number
  store?: StoreDto
}

const StoreProfitsList: React.FC<Props> = ({ storeId, store }) => {
  const [isDecreaseModalVisible, setIsDecreaseModalVisible] = useState(false)
  const [isIncreaseModalVisible, setIsIncreaseModalVisible] = useState(false)

  const storeProfitsList = useStoreProfitsList({ storeId })
  const profitsList = useMemo(() => storeProfitsList.data?.pages.map((page) => page.items).flat() || [], [storeProfitsList.dataUpdatedAt])
  useEffect(() => storeProfitsList.remove, [])

  useEffect(() => {
    if (storeProfitsList.error) {
      notification.error({
        message: 'Stores error!',
        description: storeProfitsList.error instanceof Error ? storeProfitsList.error.message : storeProfitsList.error.toString()
      })
    }
  }, [storeProfitsList.error])

  const page = storeProfitsList.data?.pages.at(-1).currentPage || 1
  const size = storeProfitsList.data?.pages.at(-1).pageSize || 30
  const columns = useMemo(() => createTableColumns(), [])

  return (
    <div className={styles.container}>
      <DecreaseBalanceModal visibleModal={isDecreaseModalVisible} onCancel={() => setIsDecreaseModalVisible(false)} storeId={storeId} />
      <IncreaseBalanceModal visibleModal={isIncreaseModalVisible} onCancel={() => setIsIncreaseModalVisible(false)} storeId={storeId} />
      <div className={styles.buttonsContainer}>
        <Button className={styles.button} onClick={() => setIsIncreaseModalVisible(true)}>
          Increase Balance
        </Button>
        <Button className={styles.button} onClick={() => setIsDecreaseModalVisible(true)}>
          Decrease Balance
        </Button>
      </div>
      <InfiniteScroll
        style={{ paddingBottom: '30px' }}
        dataLength={page * size}
        next={storeProfitsList.fetchNextPage}
        hasMore={storeProfitsList.hasNextPage}
        loader={<h4>Loading...</h4>}
        refreshFunction={storeProfitsList.refetch}
        scrollableTarget='storeScroll'
      >
        <Table
          columns={columns}
          dataSource={profitsList}
          bordered
          rowKey={(record) => record.id}
          loading={storeProfitsList.isLoading}
          pagination={false}
          sticky
          summary={() => (
            <Table.Summary fixed='top'>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Balance</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{`$${((store?.balance || 0) / 100).toFixed(2)}`}</Table.Summary.Cell>
                <Table.Summary.Cell index={2}>Pending Balance</Table.Summary.Cell>
                <Table.Summary.Cell index={3}>{`$${((store?.pendingBalance || 0) / 100).toFixed(2)}`}</Table.Summary.Cell>
                <Table.Summary.Cell index={4} colSpan={2} />
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </InfiniteScroll>
    </div>
  )
}

export default React.memo(StoreProfitsList)
