import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import { changeLocation } from '../../actions'
import { logout } from 'features/authentication/actions'
import * as workspacesFeature from 'features/workspaces'

import * as selectors from '../../selectors'

type OwnProps = {
  // location: { pathname: string }
}

const mapStateToProps = (state: ReduxState) => ({
  activeTabs: selectors.activeTabsSelector(state),
  user: selectors.navigationUserStateSelector(state),
  error: workspacesFeature.selectors.errorSelector(state)
})

const mapDispatchToProps = {
  changeLocation,
  logout
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps
