import * as actions from './actions'
import * as actionTypes from './actionTypes'
import reducer from './reducer'
import * as components from './components'
import * as selectors from './selectors'
import * as types from './types'

console.log('Addresses feature initialized!')

export { actions, actionTypes, reducer, components, selectors, types }
