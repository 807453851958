import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query'
import hash from 'object-hash'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { StoreProductsPageItemDto } from '@merchx-v2/shared-types/dist/dto/stores/store-products-page-item.dto'

const DEFAULT_PAGE_SIZE = 30

export type StoreProductsPage = {
  items: StoreProductsPageItemDto[]
  total: number
  currentPage: number
  pageSize: number
  storeId: number
  searchText?: string
}

type StoreProductsPageArgs = {
  storeId: number
  searchText?: string
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'storeProductsPage', StoreProductsPage>

const FETCH_STORES_PRODUCTS_LIST = `
query storeProductsPage ($page: Int, $size: Int, $storeId: Int!, $searchText: String) {
  storeProductsPage (page: $page, size: $size, storeId: $storeId, searchText: $searchText) {
    items {
      id
      productType
      productName
      supplierName
      productType
      price
      imageUrl
      sortIndex
    }
    total
  }
}
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type PageParam = {
  page?: number
  size?: number
  storeId: number
  searchText?: string
}

type FetchStoreProductsPageArgs = {
  queryKey: string | string[]
  pageParam?: PageParam
}

type FetchStoreProductsPage = (args: FetchStoreProductsPageArgs) => Promise<StoreProductsPage>

const fetchStoreProductsPage: FetchStoreProductsPage = async ({ pageParam }) => {
  const { page = 1, size = DEFAULT_PAGE_SIZE, storeId, searchText } = pageParam || {}
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_STORES_PRODUCTS_LIST,
      variables: { page, size, storeId, searchText }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { storeProductsPage } = data
    if (!storeProductsPage) {
      throw new Error("Can't get store products list page!")
    }

    return {
      items: storeProductsPage.items,
      total: storeProductsPage.total,
      currentPage: page,
      pageSize: size,
      storeId,
      searchText
    }
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

const queryOptions: UseInfiniteQueryOptions<StoreProductsPage> = {
  retry: false,
  keepPreviousData: true,
  refetchOnMount: true,
  getPreviousPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, storeId, searchText }) => {
    if (currentPage > 1) {
      return {
        page: currentPage - 1,
        size: pageSize,
        storeId,
        searchText
      }
    }

    return undefined
  },
  getNextPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, total = 0, storeId, searchText }) => {
    if (pageSize * currentPage < total) {
      return {
        page: currentPage + 1,
        size: pageSize,
        storeId,
        searchText
      }
    }
    return undefined
  }
}

export const useStoreProductsPage = (args: StoreProductsPageArgs) => {
  const cacheKey = ['storeProductsPage', hash(args)]

  return useInfiniteQuery(
    cacheKey,
    (prev) =>
      fetchStoreProductsPage({
        queryKey: cacheKey,
        pageParam: { ...prev.pageParam, storeId: args.storeId, searchText: args.searchText }
      }),
    queryOptions
  )
}
