import * as React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

const ProtectedRoute: React.FC<RouteProps> = ({ component: Component, ...rest }) => {
  const authToken = sessionStorage.getItem('token') || localStorage.getItem('token')

  return (
    <Route
      {...rest}
      render={props => {
        if (authToken) {
          return Component && <Component {...props} />
        }
        return <Redirect to='/login' />
      }}
    />
  )
}

export default ProtectedRoute
