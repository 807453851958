// TODO - remove becouse of legacy

import React from 'react'
import { CreateQuizResultDto } from '@merchx-v2/shared-types'
import { Input, Upload, Button } from 'antd'
import { DeleteTwoTone, UploadOutlined } from '@ant-design/icons'
import styles from './QuizResultInput.module.scss'

type Props = {
  result: CreateQuizResultDto
  disabled?: boolean
  previewImage?: string
  onResultChanged: (result: string) => void
  onResultRemoved: () => void
  onDescriptionChanged: (description: string) => void
  onLinkChanged: (link: string) => void
  handleAddImage: (file: any) => void
  setPreviewImage: (url: string) => void
}

const QuizResultInput = (args: Props) => {
  const {
    result,
    disabled,
    previewImage,
    onResultChanged,
    onResultRemoved,
    onDescriptionChanged,
    onLinkChanged,
    handleAddImage,
    setPreviewImage
  } = args

  // @ts-ignore FIXME
  const imageUrl = previewImage || result.image?.signedUrl

  return (
    <div className={styles.root}>
      <div className={styles.labeledInput}>
        <div className={styles.fieldContainer} style={{ marginBottom: 10 }}>
          <Input value={result.result} placeholder='Title' onChange={(e) => onResultChanged(e.target.value)} disabled={disabled} />
        </div>
        <div className={styles.fieldContainer} style={{ marginBottom: 10 }}>
          <Input.TextArea
            value={result.description}
            placeholder='Description'
            onChange={(e) => onDescriptionChanged(e.target.value)}
            disabled={disabled}
          />
        </div>
        <div className={styles.fieldContainer}>
          <Input value={result.link} placeholder='Link' onChange={(e) => onLinkChanged(e.target.value)} disabled={disabled} />
        </div>
        <div className={styles.imageContainer}>
          {!!imageUrl && (
            <img
              src={imageUrl}
              alt=''
              className={styles.image}
              onClick={() => {
                setPreviewImage(imageUrl)
              }}
            />
          )}
          <Upload accept='image/*' customRequest={(info) => handleAddImage(info.file)} showUploadList={false}>
            <Button icon={<UploadOutlined />} loading={disabled} disabled={disabled || typeof result.id !== 'number'}>
              Click to Upload
            </Button>
          </Upload>
        </div>
      </div>
      <DeleteTwoTone twoToneColor='tomato' style={{ marginLeft: 10, opacity: 0 }} onClick={() => onResultRemoved()} />
    </div>
  )
}

export default QuizResultInput
