import React from 'react'
import Plugins from '../Plugins'
import MainLayout from '../MainLayout'
import features from 'features'
import { Switch } from 'react-router-dom'
// import AdminRoute from './AdminRoute'
import PublicRoute from './PublicRoute'
import ProtectedRoute from './ProtectedRoute'
import SystemAdminRoute from './SystemAdminRoute'

export const Router = () => {
  return (
    <Switch>
      <PublicRoute path='/login' component={features.authentication.components.Login} />
      <PublicRoute path='/register' component={features.authentication.components.Register} />
      <PublicRoute path='/validateEmail' component={features.workspaces.pages.ValidateEmail} />
      <MainLayout>
        <Switch>
          <ProtectedRoute path='/' exact component={features.stores.components.StoresList} />
          <ProtectedRoute path='/reporting/customersData' component={features.reports.components.CustomersDataReport} />
          <ProtectedRoute path='/reporting/overview' component={features.overviewReport.pages.OverviewReportList} />
          <ProtectedRoute path='/reporting/salesFunnelAnalyze' component={features.reports.components.SalesFunnelAnalyzeReport} />
          <ProtectedRoute path='/reporting/canceledSubscriptions' component={features.reports.components.CanceledSubscriptions} />
          <ProtectedRoute path='/reporting/subscriptionStats' component={features.reports.components.SubscriptionStatsReport} />
          <ProtectedRoute path='/reporting/inventory' component={features.inventory.components.InventoryListPage} />
          <ProtectedRoute path='/reporting/costs/processing' component={features.processingCosts.pages.ProcessingCostsList} />
          <ProtectedRoute path='/reports/topCampaigns/:startDate?/:endDate?' component={features.reports.components.TopCampaignsReport} />
          <ProtectedRoute
            path='/reports/topCampaignsRate/:startDate?/:endDate?'
            component={features.reports.components.TopCampaignsRateReport}
          />
          <ProtectedRoute path='/reports/topLandings/:startDate?/:endDate?' component={features.reports.components.TopLandingsReport} />

          <ProtectedRoute
            path='/campaigns/:campaignId/categories/:categoryId/edit'
            exact
            component={features.categories.components.EditCategory}
          />
          <ProtectedRoute path='/campaigns/:campaignId/categories/create' exact component={features.categories.components.EditCategory} />

          <ProtectedRoute path='/campaigns/:campaignId' exact component={features.campaigns.components.CampaignDetails} />
          <ProtectedRoute path='/campaigns' exact component={features.campaigns.components.CampaignsList} />

          <ProtectedRoute path='/stores/create' exact component={features.stores.components.CreateStore} />
          <ProtectedRoute path='/stores/:storeId/edit' exact component={features.stores.components.EditStore} />
          <ProtectedRoute path='/stores/:storeId/details' exact component={features.stores.components.StoreDetails} />
          <ProtectedRoute path='/stores' component={features.stores.components.StoresList} />

          <ProtectedRoute
            path='/customerAccounts/:customerAccountId'
            exact
            component={features.customerAccounts.components.CustomerAccountDetails}
          />
          <ProtectedRoute path='/customerAccounts' component={features.customerAccounts.components.CustomerAccountsList} />

          <ProtectedRoute path='/landings/landingWizard' exact component={features.landings.pages.LandingWizard} />
          <ProtectedRoute path='/landings' component={features.landings.pages.LandingsListPage} />

          <ProtectedRoute path='/promocodes/create' exact component={features.promocodes.components.EditPromocode} />
          <ProtectedRoute path='/promocodes/:promocodeCode' exact component={features.promocodes.components.EditPromocode} />
          <ProtectedRoute path='/promocodes' component={features.promocodes.components.PromocodesList} />

          <ProtectedRoute path='/products/create' exact component={features.products.components.EditProduct} />
          <ProtectedRoute path='/products/:productId/edit' component={features.products.components.EditProduct} />
          <ProtectedRoute path='/products' component={features.products.components.ProductList} />

          <ProtectedRoute path='/sales/orders/:orderId/customers/:customerId' component={features.customers.components.CustomerDetails} />
          <ProtectedRoute path='/sales/orders/edit/:orderId' component={features.orders.components.ManualOrder} />
          <ProtectedRoute path='/sales/orders' component={features.orders.components.OrdersList} />

          <ProtectedRoute path='/sales/invoices/:orderId/customers/:customerId' component={features.customers.components.CustomerDetails} />
          <ProtectedRoute path='/sales/invoices/edit/:orderId' component={features.orders.components.ManualOrder} />
          <ProtectedRoute path='/sales/invoices/createInvoice' component={features.orders.components.CreateInvoiceOrder} />
          <ProtectedRoute path='/sales/invoices' component={features.orders.components.InvoicesList} />

          <ProtectedRoute
            path='/sales/customerSubscriptions'
            component={features.customerSubscriptions.components.CustomerSubscriptionsList}
          />

          <ProtectedRoute
            path='/sales/customers/:customerId/orders/:orderId'
            component={features.orders.components.OrderDetailsForCustomerDetails}
          />
          <ProtectedRoute path='/sales/needDesign' component={features.orderItems.components.NeedDesignList} />
          <ProtectedRoute path='/sales/customizableList' component={features.orderItems.components.CustomizableList} />
          <ProtectedRoute path='/sales/holdedOrders' component={features.orders.components.HoldedOrdersList} />
          <ProtectedRoute path='/sales/customers/:customerId' component={features.customers.components.CustomerDetails} />
          <ProtectedRoute path='/sales/customers' component={features.customers.components.CustomersList} />
          <ProtectedRoute path='/sales/carts' component={features.carts.components.CartsList} />

          <SystemAdminRoute path='/catalogs/awsCertificates' component={features.awsCertificates.components.AwsCertificatesList} />
          <SystemAdminRoute path='/catalogs/DTGProducts' component={features.DTGProducts.components.DTGProductsList} />
          <SystemAdminRoute path='/catalogs/DTGSizes' component={features.DTGSizes.components.DTGSizesList} />
          <SystemAdminRoute path='/catalogs/DTGVariants' component={features.DTGVariants.components.DTGVariantsList} />
          <SystemAdminRoute path='/catalogs/countries' component={features.countries.components.CountriesList} />
          <SystemAdminRoute path='/catalogs/currencies' component={features.currencies.components.CurrenciesList} />
          <SystemAdminRoute path='/catalogs/colors' component={features.productColors.components.ProductColorsList} />
          <SystemAdminRoute path='/catalogs/tags' component={features.productTags.components.ProductTagsList} />
          <SystemAdminRoute path='/catalogs/sizes' component={features.productSizes.components.ProductSizesList} />

          <ProtectedRoute path='/settings/defaults' component={features.workspaces.pages.WorkspaceDetails} />
          <ProtectedRoute path='/settings/domains' component={features.domains.components.DomainsList} />
          <ProtectedRoute path='/settings/plugins' component={Plugins} />
          <ProtectedRoute path='/settings/shippingMethods' component={features.shippingMethods.components.ShippingMethodsList} />
          <ProtectedRoute path='/settings/shippingRules' component={features.shippingRules.components.ShippingRulesList} />
          <ProtectedRoute path='/settings/campaignPlugins' component={features.plugins.pages.PluginSettingsList} />
          <ProtectedRoute path='/settings/templates/:templateId' component={features.templates.pages.TemplateDetails} />
          <SystemAdminRoute path='/settings/users/:userId' component={features.users.components.UserDetails} />
          <SystemAdminRoute path='/settings/users' component={features.users.components.UsersList} />
          <ProtectedRoute path='/tasksQueue' component={features.tasksQueue.components.TasksQueueList} />

          <ProtectedRoute path='/services/redeployment' component={features.reports.components.CampaignTemplatesReport} />
          <ProtectedRoute path='/services/alerts' component={features.alerts.components.AlertsList} />
        </Switch>
      </MainLayout>
    </Switch>
  )
}
