// TODO - remove becouse of legacy

import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Template, TemplateInput } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'createTemplate', Template>

const CREATE_TEMPLATE = `
  mutation createTemplate($templateData: TemplateInput!) {
    createTemplate(templateData: $templateData) {
      id
      name
      workspaceId
      buildType
      version
      templateType
      route
      mainPreviewThumbImageId
      mainPreviewImageId
      previewImage1Id
      previewImage2Id
      previewImage3Id
      repository
      repositoryProvider
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type CreateTemplate = (templateData: TemplateInput) => Promise<Template>

const createTemplate: CreateTemplate = async (templateData) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_TEMPLATE,
      variables: { templateData }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createTemplate } = data
    if (!createTemplate) {
      throw new Error("Can't create template!")
    }

    return createTemplate
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type CreateTemplateEvents = {
  onSettled: (data?: Template) => void
}

const createTemplateEvents: CreateTemplateEvents = {
  onSettled: (template) => {
    if (template?.id) {
      queryClient.invalidateQueries(['templatesList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCreateTemplate = () => useMutation(createTemplate, createTemplateEvents)
