// TODO - remove becouse of legacy

import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { CreateQuizDto, QuizDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'createQuiz', QuizDto>

const CREATE_QUIZ = `
  mutation CreateQuiz($quizData: QuizInput!) {
    createQuiz(quizData: $quizData) {
      id
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type CreateQuizArgs = {
  quizData: CreateQuizDto
}

type CreateQuiz = (args: CreateQuizArgs) => Promise<CreateQuizArgs>

const createQuiz: CreateQuiz = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: CREATE_QUIZ,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createQuiz } = data
    if (!createQuiz) {
      throw new Error("Can't create quiz!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCreateQuiz = () => useMutation(createQuiz)
