import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { AwsSetting } from '../types'

type AwsSettingsForOptionsSelector = (state: ReduxState) => AwsSetting[]
const getAwsSettingsForOptions: AwsSettingsForOptionsSelector = state =>
  state.awsSettings.awsSettingsForOptions || ([] as AwsSetting[])

export const awsSettingsForOptionsSelector: AwsSettingsForOptionsSelector = createSelector(
  getAwsSettingsForOptions,
  awsSettings => awsSettings
)
