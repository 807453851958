import axios from 'utils/axios'
import { useQuery } from 'react-query'
import { TagOwner } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'tagsForOptions', string[]>

const FETCH_TAGS_FOR_OPTIONS = `
  query fetchTagsForOptions ($workspaceId: Int!, $ownerType: TagOwner!) {
    tagsForOptions(workspaceId: $workspaceId, ownerType: $ownerType)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type TagsForOptionsArgs = {
  workspaceId: number
  ownerType: TagOwner
}

type FetchTagsForOptions = (args: TagsForOptionsArgs) => Promise<string[]>

const fetchTagsForOptions: FetchTagsForOptions = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_TAGS_FOR_OPTIONS,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { tagsForOptions } = data
    if (!tagsForOptions) {
      throw new Error("Can't get tags!")
    }

    return tagsForOptions
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useTagsOptions = (args: TagsForOptionsArgs) => useQuery(['tagsOptions', args], () => fetchTagsForOptions(args))
