// TODO - remove becouse of legacy

import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'duplicateQuiz', boolean>

const DUPLICATE_QUIZ = `
  mutation duplicateQuiz ($quizId: Int!, $newName: String!) {
    duplicateQuiz(quizId: $quizId, newName: $newName)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type DuplicateQuizArgs = {
  quizId: number
  newName: string
}

type DuplicateQuiz = (args: DuplicateQuizArgs) => Promise<DuplicateQuizArgs>

const duplicateQuiz: DuplicateQuiz = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: DUPLICATE_QUIZ,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { duplicateQuiz } = data
    if (!duplicateQuiz) {
      throw new Error("Can't duplicate quiz!")
    }

    return args
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useDuplicateQuiz = () => useMutation(duplicateQuiz)
