import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { PostmarkSetting } from '../types'

type PostmarkSettingsListSelector = (state: ReduxState) => PostmarkSetting[]
const getPostmarkSettingsList: PostmarkSettingsListSelector = state => {
  const result = [] as PostmarkSetting[]
  const pageIds = state.postmarkSettings.pagination.pages[state.postmarkSettings.pagination.currentPage] || []
  pageIds.forEach(pageId => {
    result.push(state.postmarkSettings.postmarkSettings[pageId])
  })
  return result
}

export const postmarkSettingsListSelector: PostmarkSettingsListSelector = createSelector(
  getPostmarkSettingsList,
  list => list
)
