import React, { useState, useEffect, useRef, useContext } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Drawer, Input, Checkbox, Form } from 'antd'
import { Store } from 'antd/lib/form/interface'
import notification from 'mrx-notification'
import { GlobalContext } from 'appContexts'
import { useCreateShippingMethod } from '../../hooks'

import styles from './CreateShippingMethod.module.scss'

interface FormStore extends Store {
  carrierCode: string
  serviceCode: string
  isDefault: boolean
}

const CreateShippingMethod = () => {
  const [isVisible, setVisible] = useState(false)
  const refEl = useRef<Input>(null)

  const { campaignId } = useContext(GlobalContext)

  const createShippingMethod = useCreateShippingMethod()

  useEffect(() => {
    if (isVisible) {
      refEl.current && refEl.current.focus()
    }
  }, [isVisible])

  const showDrawer = () => {
    setVisible(!isVisible)
  }

  const [form] = Form.useForm()

  const handleClose = () => {
    setVisible(false)
    form.resetFields()
  }

  useEffect(() => {
    if (createShippingMethod.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'ShippingMethod was created successfully!'
      })
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createShippingMethod.isSuccess])

  const onFinishHandler = async (values: FormStore) => {
    createShippingMethod.mutate({ ...values, campaignId })
  }

  return (
    <>
      <Button type='primary' onClick={showDrawer}>
        <PlusOutlined />
        CREATE
      </Button>
      <Drawer title='Create ShippingMethod' width='400' onClose={handleClose} visible={isVisible} forceRender>
        <Form
          layout='vertical'
          hideRequiredMark
          onFinish={onFinishHandler}
          form={form}
          initialValues={{
            isDefault: false
          }}
        >
          <Form.Item name='carrierCode' label='Carrier code' rules={[{ required: true, message: 'Please input carrier code!' }]}>
            <Input style={{ width: '100%' }} ref={refEl} />
          </Form.Item>

          <Form.Item name='serviceCode' label='Service code' rules={[{ required: true, message: 'Please input service code!' }]}>
            <Input style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name='isDefault' label='Default'>
            <Checkbox style={{ width: '100%' }} onChange={event => form.setFieldValue('isDefault', event.target.checked)} />
          </Form.Item>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button onClick={handleClose} className={styles.closeButton}>
              Close
            </Button>
            <Button type='primary' htmlType='submit' loading={createShippingMethod.isLoading}>
              Create
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

export default CreateShippingMethod
