import { SettingData } from './types'

export const facebookCatalogData: SettingData[] = [
  {
    name: 'catalogId',
    label: 'Catalog ID',
    rules: [{ required: true, message: 'Please input catalog ID!' }]
  },
  {
    name: 'accessToken',
    label: 'Access Token',
    rules: [{ required: true, message: 'Please input access token!' }]
  }
]
