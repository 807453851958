export const CHANGE_CURRENT_PAGE = 'orders/CHANGE_CURRENT_PAGE'
export const CHANGE_PAGE_SIZE = 'orders/CHANGE_PAGE_SIZE'
export const FETCH_ORDERS_LIST = 'orders/FETCH_ORDERS_LIST'
export const FETCH_ORDER = 'orders/FETCH_ORDER'
export const FETCH_ORDERS_LIST_CSV = 'orders/FETCH_ORDERS_LIST_CSV'
export const CANCEL_ORDER = 'orders/CANCEL_ORDER'
export const HOLD_ORDER = 'orders/HOLD_ORDER'
export const REFUND_PAYMENT = 'orders/REFUND_PAYMENT'
export const REMOVE_ORDER_ITEM = 'orders/REMOVE_ORDER_ITEM'
export const REMOVE_ORDER_NOTE = 'orders/REMOVE_ORDER_NOTE'
export const CREATE_ORDER_NOTE = 'orders/CREATE_ORDER_NOTE'
export const UPDATE_ORDER = 'orders/UPDATE_ORDER'
export const UPDATE_ORDER_NOTE = 'orders/UPDATE_ORDER_NOTE'
export const UPDATE_ORDER_TRACKING_NUMBER = 'orders/UPDATE_ORDER_TRACKING_NUMBER'
export const CHANGE_DATES_RANGE_REPORT = 'orders/CHANGE_DATES_RANGE_REPORT'
export const RESEND_ORDER_TASK = 'orders/RESEND_ORDER_TASK'
