import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { OrderItem } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'removeOrderItem', number>

const REMOVE_ORDER_ITEM = `
  mutation removeOrderItem ($orderItemId: Int!) {
    removeOrderItem (orderItemId: $orderItemId)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveOrderItem = (orderItemId: number) => Promise<number>

const removeOrderItem: RemoveOrderItem = async (orderItemId) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_ORDER_ITEM,
      variables: {
        orderItemId
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeOrderItem } = data
    if (!removeOrderItem) {
      throw new Error("Can't remove order item!")
    }

    return orderItemId
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveOrderItemContext = { prevOrderItem?: OrderItem }

type RemoveOrderItemEvents = {
  onMutate: (orderItemId: number) => Promise<RemoveOrderItemContext>
  onError: (error: string, orderItemId: number, context: RemoveOrderItemContext) => void
  onSettled: (orderItemId?: number) => void
}

const removeOrderItemEvents: RemoveOrderItemEvents = {
  onMutate: async (orderItemId) => {
    await queryClient.cancelQueries(['orderItem', orderItemId])

    // Snapshot the previous value
    const prevOrderItem = queryClient.getQueryData<OrderItem>(['orderItem', orderItemId])

    if (prevOrderItem) {
      queryClient.removeQueries(['orderItem', orderItemId])
    }

    return { prevOrderItem }
  },
  onError: (_err, orderItemId, context) => {
    if (context?.prevOrderItem) {
      // Restore currrency on any error
      queryClient.setQueryData<OrderItem>(['orderItem', orderItemId], context.prevOrderItem)
    }
  },
  onSettled: (orderItemId: number) => {
    if (orderItemId) {
      queryClient.invalidateQueries(['orderItem', orderItemId])
      queryClient.invalidateQueries(['order'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useRemoveOrderItem = () => useMutation(removeOrderItem, removeOrderItemEvents)
