import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { TaskType } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'resendOrderTask', boolean>

const RESEND_ORDER_TASK = `
  mutation ResendOrderTask($orderId: Int!, $taskType: String!) {
    resendOrderTask(orderId: $orderId, taskType: $taskType)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type ResendOrderTaskArgs = {
  orderId: number
  taskType: TaskType
}

type ResendOrderTask = (args: ResendOrderTaskArgs) => Promise<boolean>

const resendOrderTask: ResendOrderTask = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: RESEND_ORDER_TASK,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { resendOrderTask } = data
    if (!resendOrderTask) {
      throw new Error("Can't resend order task!")
    }

    return resendOrderTask
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type ResendOrderTaskEvents = {
  onSettled: (data?: boolean) => void
}

const resendOrderTaskEvents: ResendOrderTaskEvents = {
  onSettled: (data) => {
    if (data === true) {
      queryClient.invalidateQueries(['order'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useResendOrderTask = () => useMutation(resendOrderTask, resendOrderTaskEvents)
