import * as t from '../actionTypes'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

export type RemoveShipstationSettingAction = FSA<undefined, number, string>
type RemoveShipstationSettingColor = (shipstationSettingId: number) => MrxThunk<RemoveShipstationSettingAction, Promise<boolean>>
type QueryResponse = GraphQLResponse<'removeShipstationSetting', boolean>

const REMOVE_SHIPSTATION_SETTING = `
  mutation removeShipstationSetting ($shipstationSettingId: Int!) {
    removeShipstationSetting(shipstationSettingId: $shipstationSettingId)
  }
`

const removeShipstationSetting: RemoveShipstationSettingColor = (shipstationSettingId) => async (dispatch) => {
  let isRemoved = false

  dispatch({
    type: t.REMOVE_SHIPSTATION_SETTING,
    meta: { done: false }
  })

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_SHIPSTATION_SETTING,
      variables: { shipstationSettingId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Empty response!')
    }

    const { removeShipstationSetting } = data
    if (!removeShipstationSetting) {
      throw new Error("Can't remove shipstation setting!")
    }

    dispatch({
      type: t.REMOVE_SHIPSTATION_SETTING,
      payload: shipstationSettingId,
      meta: { done: true }
    })

    isRemoved = true
  } catch (err) {
    dispatch({
      type: t.REMOVE_SHIPSTATION_SETTING,
      payload: extractErrorInfo(err),
      error: true
    })
  }

  return isRemoved
}

export { removeShipstationSetting }
