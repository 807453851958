import { OrderStatus } from '@merchx-v2/shared-types/dist/constants/orderStatus'

export const replaceOrderStatus = (orderStatus?: OrderStatus): string => {
  let status

  switch (orderStatus) {
    case 'SHIPPING': {
      status = 'RECEIVED BY CG'
      break
    }

    case 'DISPATCHED': {
      status = 'IN PRODUCTION'
      break
    }

    case 'COMPLETED': {
      status = 'DISPATCHED'
      break
    }

    default: {
      status = orderStatus
    }
  }

  return status
}
