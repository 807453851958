export enum ProductTypes {
  crew = 'CREW',
  faceMask = 'FACE_MASK',
  mug = 'MUG',
  poster = 'POSTER',
  stailnessSteelWaterBottle = 'STAINLESS_STEEL_WATER_BOTTLE',
  embroideredBaseballHat = 'EMBROIDERED_BASEBALL_HAT',
  hat = 'HAT',
  sticker = 'STICKER',
  subscription = 'SUBSCRIPTION',
  totebag = 'TOTEBAG',
  tshirt = 'TSHIRT',
  hoodie = 'HOODIE',
  externalProduct = 'EXTERNAL_PRODUCT',
  longSleevedShirt = 'LONG_SLEEVED_SHIRT',
  embroideredPoloShirt = 'EMBROIDERED_POLO_SHIRT'
}

export default ProductTypes
