import { useInfiniteQuery } from 'react-query'
import hash from 'object-hash'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Campaign, CampaignsFilters } from '../types'

const DEFAULT_PAGE_SIZE = 15

export type CampaignsPage = {
  items: Campaign[]
  total: number
  currentPage: number
  pageSize: number
  filters?: CampaignsFilters
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'campaignsListPage', CampaignsPage>

const FETCH_CAMPAIGNS_LIST = `
query campaignsListPage($page: Int, $size: Int, $filters: CampaignsFilters!) {
  campaignsListPage(page: $page, size: $size, filters: $filters) {
    items {
      id
      name
      status
      defaultDomain
      tags
      archived
    }
    total
  }
}
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type PageParam = {
  page?: number
  size?: number
  filters: CampaignsFilters
}

type FetchCampaignsListArgs = {
  queryKey: string | string[]
  pageParam?: PageParam
}

type FetchCampaignsList = (args: FetchCampaignsListArgs) => Promise<CampaignsPage>

const fetchCampaignsList: FetchCampaignsList = async ({ pageParam }) => {
  const { page = 1, size = DEFAULT_PAGE_SIZE, filters = undefined } = pageParam || {}

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_CAMPAIGNS_LIST,
      variables: { page, size, filters }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { campaignsListPage } = data
    if (!campaignsListPage) {
      throw new Error("Can't get campaigns list page!")
    }

    return {
      items: campaignsListPage.items,
      total: campaignsListPage.total,
      currentPage: page,
      pageSize: size,
      filters
    }
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

type GetNextPageParam = (args: CampaignsPage) => PageParam | undefined

type CampaignsListEvents = {
  keepPreviousData: boolean
  getPreviousPageParam: GetNextPageParam
  getNextPageParam: GetNextPageParam
}

const campaignsListEvents: CampaignsListEvents = {
  keepPreviousData: true,
  getPreviousPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, filters }) => {
    if (currentPage > 1) {
      return {
        page: currentPage - 1,
        size: pageSize,
        filters
      }
    }

    return undefined
  },
  getNextPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, total = 0, filters }) => {
    if (pageSize * currentPage < total) {
      return {
        page: currentPage + 1,
        size: pageSize,
        filters
      }
    }
    return undefined
  }
}

export const useCampaignsList = (filters: CampaignsFilters) => {
  const cacheKey = ['campaignsList', hash(filters)]

  return useInfiniteQuery(
    cacheKey,
    (prev) =>
      fetchCampaignsList({
        queryKey: cacheKey,
        pageParam: { ...prev.pageParam, filters }
      }),
    campaignsListEvents
  )
}
