import * as t from '../actionTypes'

export type ChangePageSizeAction = BaseFSA<number>
type ChangePageSize = (size: number) => MrxThunk<ChangePageSizeAction>

const changePageSize: ChangePageSize = size => async dispatch => {
  dispatch({
    type: t.CHANGE_PAGE_SIZE,
    payload: size
  })
}

export { changePageSize }
