import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

import { Country } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'removeCountry', boolean>

const REMOVE_COUNTRY = `
  mutation removeCountry ($countryId: Int!) {
    removeCountry(countryId: $countryId) 
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveCountry = (countryId: number) => Promise<number>

const removeCountry: RemoveCountry = async (countryId) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_COUNTRY,
      variables: {
        countryId
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeCountry } = data
    if (!removeCountry) {
      throw new Error("Can't remove country!")
    }

    return countryId
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveCountryContext = { prevCountry?: Country }

type RemoveCountryEvents = {
  onMutate: (countryId: number) => Promise<RemoveCountryContext>
  onError: (error: string, countryId: number, context: RemoveCountryContext) => void
  onSettled: (countryId?: number) => void
}

const removeCountryEvents: RemoveCountryEvents = {
  onMutate: async (countryId) => {
    await queryClient.cancelQueries(['country', countryId])

    // Snapshot the previous value
    const prevCountry = queryClient.getQueryData<Country>(['country', countryId])

    if (prevCountry) {
      queryClient.removeQueries(['country', countryId])
    }

    return { prevCountry }
  },
  onError: (_err, countryId, context) => {
    if (context?.prevCountry) {
      // Restore country on any error
      queryClient.setQueryData<Country>(['country', countryId], context.prevCountry)
    }
  },
  onSettled: (countryId) => {
    if (countryId) {
      queryClient.invalidateQueries(['country', countryId])
      queryClient.invalidateQueries(['countriesList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useRemoveCountry = () => useMutation(removeCountry, removeCountryEvents)
