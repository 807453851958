import produce from 'immer'
import * as t from './actionTypes'
import { actionTypes as nt } from 'features/navigation'
import { actionTypes as wt } from 'features/workspaces'
import { isSuccessAction, isErrorAction } from 'types'
import { ProductSizesState } from './types'
import createReducer from 'store/createReducer'
import { addItemToPagination } from 'utils/addItemToPagination'
import { removeItemFromPagination } from 'utils/removeItemFromPagination'
import {
  ChangeCurrentPageAction,
  ChangePageSizeAction,
  CreateProductSizeAction,
  FetchProductSizeAction,
  FetchProductSizesListAction,
  RemoveProductSizeAction,
  UpdateProductSizeAction,
  FetchProductSizesForOptionsAction
} from './actions'

const initState: ProductSizesState = {
  productSizes: {},
  productSizesForOptions: [],
  pagination: {
    pages: {},
    total: 0,
    currentPage: 1,
    size: 10
  },
  UIState: {
    isCreateProductSize: false,
    isFetchProductSize: false,
    isListLoading: false,
    isRemoveProductSize: false,
    isUpdateProductSize: false,
    isProductSizesForOptionsLoaded: false
  }
}

const reducer = createReducer(initState, {
  [t.CREATE_PRODUCT_SIZE]: (state, action: CreateProductSizeAction) =>
    produce(state, draft => {
      draft.UIState.isCreateProductSize = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isCreateProductSize = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { id } = action.payload
        draft.productSizes[id] = action.payload
        addItemToPagination(draft.pagination, id)
      }
    }),

  [t.FETCH_PRODUCT_SIZES_LIST]: (state, action: FetchProductSizesListAction) =>
    produce(state, draft => {
      draft.UIState.isListLoading = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isListLoading = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        action.payload.items.forEach(item => {
          draft.productSizes[item.id] = item
        })
        const { page, total, items } = action.payload
        draft.pagination.total = total
        draft.pagination.pages[page] = items.map(item => item.id)
      }
    }),

  [t.FETCH_PRODUCT_SIZE]: (state, action: FetchProductSizeAction) =>
    produce(state, draft => {
      draft.UIState.isFetchProductSize = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isFetchProductSize = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.productSizes[action.payload.id] = action.payload
      }
    }),

  [t.REMOVE_PRODUCT_SIZE]: (state, action: RemoveProductSizeAction) =>
    produce(state, draft => {
      draft.UIState.isRemoveProductSize = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isRemoveProductSize = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        if (draft.productSizes[action.payload]) {
          delete draft.productSizes[action.payload]
          removeItemFromPagination(draft.pagination, action.payload)
        }
      }
    }),

  [t.UPDATE_PRODUCT_SIZE]: (state, action: UpdateProductSizeAction) =>
    produce(state, draft => {
      draft.UIState.isUpdateProductSize = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isUpdateProductSize = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        const { productSizeId, productSizeData } = action.payload
        if (draft.productSizes[productSizeId]) {
          draft.productSizes[productSizeId].name = productSizeData.name
          draft.productSizes[productSizeId].displayName = productSizeData.displayName
        }
      }
    }),

  [t.CHANGE_CURRENT_PAGE]: (state, action: ChangeCurrentPageAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.pagination.currentPage = action.payload
      }
    }),

  [t.CHANGE_PAGE_SIZE]: (state, action: ChangePageSizeAction) =>
    produce(state, draft => {
      if (action.payload) {
        draft.pagination.size = action.payload
      }
    }),

  [t.FETCH_PRODUCT_SIZES_FOR_OPTIONS]: (state, action: FetchProductSizesForOptionsAction) =>
    produce(state, draft => {
      draft.UIState.isProductSizesForOptionsLoaded = !(action.meta && action.meta.done)
      delete draft.UIState.error

      if (isErrorAction(action)) {
        draft.UIState.isProductSizesForOptionsLoaded = false
        draft.UIState.error = action.payload
      }

      if (isSuccessAction(action)) {
        draft.productSizesForOptions = action.payload
      }
    }),

  [wt.SWITCH_WORKSPACE]: state =>
    produce(state, draft => {
      draft.pagination = initState.pagination
    }),

  [nt.LOCATION_CHANGE]: state =>
    produce(state, draft => {
      if (draft.UIState.error) {
        delete draft.UIState.error
      }
    })
})

export default reducer
