import React from 'react'
import { Socket } from 'socket.io-client'
import { ServerToClientEvents, ClientToServerEvents } from '@merchx-v2/web-socket'

export type MerchxWebSocket = Socket<ServerToClientEvents, ClientToServerEvents>

export type TGlobalContext = {
  campaignId?: number
  workspaceId?: number
  setCampaignId: React.Dispatch<React.SetStateAction<number>>
  setWorkspaceId: React.Dispatch<React.SetStateAction<number>>
  webSocket: MerchxWebSocket
}

export const GlobalContext = React.createContext<TGlobalContext>({
  campaignId: undefined,
  workspaceId: undefined,
  setCampaignId: () => null,
  setWorkspaceId: () => null,
  webSocket: null
})
