import React, { useEffect, useState } from 'react'
import { Form, Typography, Row, Col, Button, Upload, Input } from 'antd'
import { CloseCircleTwoTone, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { AssetOwner } from '@merchx-v2/shared-types'
import { UploadFile } from 'antd/lib/upload/interface'
import notification from 'mrx-notification'

import { useUploadAsset } from 'features/assets/hooks'
import { Setting } from '../../types'
import styles from './FormItemQuizSetting.module.scss'

type Result = {
  title: string
  imageId: number
  description: string
  image?: any
  fileName?: string
  countCorrects: number
  count: number
}

type Answer = {
  title: string
  count: number
}

type Question = {
  title: string
  imageId?: number
  image?: any
  fileName?: string
  answers: Answer[]
}

type PropsType = {
  setting: Setting
  onValueChanged?: (setting: Setting, newValue: any) => void
}

const FormItemQuizSetting = (props: PropsType) => {
  const {
    setting,

    onValueChanged
  } = props
  const [results, setResults] = useState<Result[]>([])
  const [questions, setQuestions] = useState<Question[]>([])

  const uploadAsset = useUploadAsset()

  useEffect(() => {
    if (setting) {
      setResults(setting.jsonValue.value.results)
      setQuestions(setting.jsonValue.value.questions)
    }
  }, [setting])

  useEffect(() => {
    const value = {
      results,
      questions
    }

    onValueChanged(setting, JSON.stringify(value))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results, questions])

  if (!setting) {
    return
  }

  const handleResultValueChanged = (index, newValue) => {
    const updatedSelectArray = results.map((item) => ({ ...item }))
    updatedSelectArray[index] = newValue
    setResults(updatedSelectArray)
  }

  const handleQuestionValueChanged = (index, newValue) => {
    const updatedSelectArray = questions.map((item) => ({ ...item }))
    updatedSelectArray[index] = newValue
    setQuestions(updatedSelectArray)
  }

  const handleAnswerValueChanged = (index, questionIndex, newValue) => {
    const updatedSelectArray = questions.map((item) => ({ ...item, answers: [...item.answers] }))
    updatedSelectArray[questionIndex].answers[index] = newValue
    setQuestions(updatedSelectArray)
  }

  const handleAddResult = () => {
    setResults([...results, { title: '', imageId: 0, description: '', countCorrects: 0, count: 0 }])

    const updatedQuestionArray = [...questions].map(({ answers, ...item }) => ({
      ...item,
      answers: [...answers, { title: '', count: 0 }]
    }))
    setQuestions(updatedQuestionArray)
  }

  const handleAddQuestion = () => {
    setQuestions([
      ...questions,
      {
        title: '',
        imageId: 0,
        answers: results.map(() => ({ title: '', count: 0 }))
      }
    ])
  }

  const handleRemoveQuestion = (indexToRemove) => {
    const updatedSelectArray = [...questions]
    updatedSelectArray.splice(indexToRemove, 1)
    setQuestions(updatedSelectArray)
  }

  const handleRemoveResult = (indexToRemove) => {
    const updatedSelectArray = [...results]
    updatedSelectArray.splice(indexToRemove, 1)
    setResults(updatedSelectArray)

    const updatedQuestionArray = [...questions].map((question) => {
      question.answers.splice(indexToRemove, 1)
      return question
    })
    setQuestions(updatedQuestionArray)
  }

  const handleAddResultImage = async (file: UploadFile<any>, name: string, index: number) => {
    file.url = URL.createObjectURL(file)
    const fixedName = name.trim().replace(/\s+/g, '_') as string
    const updatedSelectArray = results.map((item) => ({ ...item }))
    updatedSelectArray[index].image = file
    updatedSelectArray[index].fileName = fixedName

    uploadAsset.mutate({
      ownerType: setting.ownerType as AssetOwner,
      ownerId: setting.ownerId,
      assetData: { name: fixedName, type: file.type, filename: file.name, role: 'DEFAULT' },
      file: file.originFileObj
    })
    if (uploadAsset?.data?.id) {
      notification.success({
        message: 'Successfully',
        description: 'Image was uploaded successfully!'
      })
      updatedSelectArray[index].imageId = uploadAsset.data.id
    }

    setResults(updatedSelectArray)
  }

  const handleAddQuestionImage = async (file: UploadFile<any>, name: string, index: number) => {
    file.url = URL.createObjectURL(file)
    const fixedName = name.trim().replace(/\s+/g, '_') as string
    const updatedSelectArray = questions.map((item) => ({ ...item }))
    updatedSelectArray[index].image = file
    updatedSelectArray[index].fileName = fixedName

    uploadAsset.mutate(
      {
        ownerType: setting.ownerType as AssetOwner,
        ownerId: setting.ownerId,
        assetData: { name: fixedName, type: file.type, filename: file.name, role: 'DEFAULT' },
        file: file.originFileObj
      },
      {
        onSuccess: (data) => {
          notification.success({
            message: 'Successfully',
            description: 'Image was uploaded successfully!'
          })
          updatedSelectArray[index].imageId = data.id
        },
        onError: (error) => {
          notification.error({
            message: `${setting.name} setting error!`,
            description: error.toString()
          })
        }
      }
    )
    if (uploadAsset?.data?.id) {
    }

    setQuestions(updatedSelectArray)
  }

  return (
    <div className={styles.form}>
      <Typography className={styles.title} style={{ marginBottom: '10px' }}>
        Results
      </Typography>
      {results.map((field, index) => {
        return (
          <Col key={index} className={styles.mainCol}>
            <Row className={styles.rowContainer} style={{ width: 400 }}>
              <Col className={styles.colContainer}>
                <p>
                  {index + 1}) Result №{index + 1}
                </p>
              </Col>
              <Col
                className={styles.removeButtonContainer}
                onClick={() => {
                  handleRemoveResult(index)
                }}
              >
                <CloseCircleTwoTone twoToneColor='tomato' />
              </Col>
            </Row>
            <Row className={styles.rowContainer} style={{ width: 400 }}>
              <Col className={styles.colContainer}>
                <Form.Item
                  style={{ width: 300 }}
                  label='Title'
                  name={`${setting.name}${index}title`}
                  initialValue={field.title}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Please input result title.'
                    }
                  ]}
                >
                  <Input style={{ width: 300 }} onChange={(e) => handleResultValueChanged(index, { ...field, title: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>
            <Row className={styles.rowContainer} style={{ width: 400 }}>
              <Col className={styles.colContainer}>
                <Form.Item
                  label='Image'
                  valuePropName=''
                  style={{ width: 300 }}
                  rules={[
                    {
                      required: true,
                      message: 'Please input result image.'
                    }
                  ]}
                >
                  {field.image && <img src={field.image.signedUrl || field.image.url} alt='' className={styles.image} />}
                  <Upload
                    name={`${setting.name}${index}resultImage`}
                    accept='image/*'
                    customRequest={(info: any) => {
                      const fileName = info.file.name.replace(/\.[^.]+$/gm, '')
                      handleAddResultImage(info.file, fileName, index)
                    }}
                    showUploadList={false}
                  >
                    <Button loading={false}>
                      <UploadOutlined /> Click to Upload
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row className={styles.rowContainer} style={{ width: 400 }}>
              <Col className={styles.colContainer}>
                <Form.Item
                  style={{ width: 300 }}
                  label='Description'
                  name={`${setting.name}${index}resultDescription`}
                  initialValue={field.description}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Please input result description.'
                    }
                  ]}
                >
                  <Input
                    style={{ width: 300 }}
                    onChange={(e) => handleResultValueChanged(index, { ...field, description: e.target.value })}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        )
      })}
      <Form.Item className={styles.title}>
        <Button
          type='dashed'
          onClick={() => {
            handleAddResult()
          }}
        >
          <PlusOutlined /> ADD RESULT
        </Button>
      </Form.Item>
      {!!results.length && (
        <>
          <Typography className={styles.title} style={{ marginBottom: 10 }}>
            Questions
          </Typography>
          {questions.map((field, index) => {
            return (
              <Col key={index} className={styles.mainCol}>
                <Row className={styles.rowContainer} style={{ width: 400 }}>
                  <Col className={styles.colContainer}>
                    <p>
                      {index + 1}) Question №{index + 1}
                    </p>
                  </Col>
                  <Col
                    className={styles.removeButtonContainer}
                    onClick={() => {
                      handleRemoveQuestion(index)
                    }}
                  >
                    <CloseCircleTwoTone twoToneColor='tomato' />
                  </Col>
                </Row>
                <Row className={styles.rowContainer} style={{ width: 400 }}>
                  <Col className={styles.colContainer}>
                    <Form.Item
                      style={{ width: 300 }}
                      label='Title'
                      name={`${setting.name}${index}questionTitle`}
                      initialValue={field.title}
                      rules={[
                        {
                          required: !field.imageId,
                          whitespace: true,
                          message: 'Please input question title or image.'
                        }
                      ]}
                    >
                      <Input
                        style={{ width: 300 }}
                        onChange={(e) => handleQuestionValueChanged(index, { ...field, title: e.target.value })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className={styles.rowContainer} style={{ width: 400 }}>
                  <Col className={styles.colContainer}>
                    <Form.Item
                      label='Image'
                      valuePropName=''
                      style={{ width: 300 }}
                      rules={[
                        {
                          required: !field.title,
                          message: 'Please input question title or image.'
                        }
                      ]}
                    >
                      {field.image && <img src={field.image.signedUrl || field.image.url} alt='' className={styles.image} />}
                      <Upload
                        name={`${setting.name}${index}questionImage`}
                        accept='image/*'
                        customRequest={(info: any) => {
                          const fileName = info.file.name.replace(/\.[^.]+$/gm, '')
                          handleAddQuestionImage(info.file, fileName, index)
                        }}
                        showUploadList={false}
                      >
                        <Button loading={false}>
                          <UploadOutlined /> Click to Upload
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                {field.answers.map((answer, answerIndex) => {
                  return (
                    <Col key={answerIndex} className={styles.colContainer}>
                      <Col className={styles.colContainer}>
                        <p>Answer to Result №{answerIndex + 1}</p>
                      </Col>
                      <Col className={styles.colContainer}>
                        <Form.Item
                          style={{ width: 300 }}
                          name={`${setting.name}${answerIndex}title${index}`}
                          initialValue={answer.title}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Please input answer title.'
                            }
                          ]}
                        >
                          <Input
                            style={{ width: 300 }}
                            onChange={(e) => handleAnswerValueChanged(answerIndex, index, { ...answer, title: e.target.value })}
                          />
                        </Form.Item>
                      </Col>
                    </Col>
                  )
                })}
              </Col>
            )
          })}
          <Form.Item className={styles.title}>
            <Button
              type='dashed'
              onClick={() => {
                handleAddQuestion()
              }}
            >
              <PlusOutlined /> ADD QUESTION
            </Button>
          </Form.Item>
        </>
      )}
    </div>
  )
}

export default FormItemQuizSetting
