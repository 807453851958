import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'

type CurrentPageSelector = (state: ReduxState, countryId: number) => number

const getCurrentPageSelector: CurrentPageSelector = (state, countryId) => {
  let currentPage = 1
  if (countryId && Object.prototype.hasOwnProperty.call(state.regions.pagination, countryId)) {
    currentPage = state.regions.pagination[countryId].currentPage
  }
  return currentPage
}

export const currentPageSelector: CurrentPageSelector = createSelector(getCurrentPageSelector, (currentPage) => currentPage)
