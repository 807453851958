import React, { useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import { Table, Popconfirm, Layout, PageHeader } from 'antd'
import notification from 'mrx-notification'
import { ColumnsType } from 'antd/lib/table'
import useHeightResize from 'utils/useHeightResize'
import { Breadcrumbs } from 'components'
import * as rootStyles from 'assets/layoutStyle'
import styles from './AuthorizeSettingsList.module.scss'
import { connector, PropsFromRedux } from './container'
import { AuthorizeSetting } from '../../types'
import CreateAuthorizeSetting from '../CreateAuthorizeSetting'
import UpdateAuthorizeSetting from '../UpdateAuthorizeSetting'

type HeightResizeRule = [number, number, number]

const rules: HeightResizeRule[] = [
  [0, 800, 6],
  [800, 1000, 7],
  [1000, 1200, 9],
  [1200, Infinity, 10]
]

const AuthorizeSettingsList = (props: PropsFromRedux) => {
  const {
    authorizeSettings,
    isLoading,
    error,
    isNeedToFetchList,
    total,
    currentPage,
    size,

    changeCurrentPage,
    changePageSize,
    fetchAuthorizeSettingsList,
    removeAuthorizeSetting
  } = props

  const { history } = useReactRouter()
  const [authorizeSettingId, setAuthorizeSettingId] = useState<number>()

  const handleChangePageSize = (newSize: number) => {
    changePageSize(newSize)
    fetchAuthorizeSettingsList()
  }

  useHeightResize(handleChangePageSize, rules)

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Authorize settings list page error!',
        description: error
      })
    }
  }, [error])

  useEffect(() => {
    isNeedToFetchList && fetchAuthorizeSettingsList()
  }, [fetchAuthorizeSettingsList, isNeedToFetchList])

  const doConfirm = async (authorizeSettingId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    const isRemoved = await removeAuthorizeSetting(authorizeSettingId)
    if (isRemoved) {
      notification.success({
        message: 'Successfully',
        description: 'Authorize setting was deleted successfully!'
      })
    }
  }

  const handleOnRowClick = (record: AuthorizeSetting) => ({
    onClick: () => {
      setAuthorizeSettingId(record.id)
    }
  })

  const columns: ColumnsType<AuthorizeSetting> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'API gateway',
      dataIndex: 'apiGateway',
      render: (record) => <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{record}</div>,
      key: 'apiGateway'
    },
    {
      title: 'API Login ID',
      dataIndex: 'apiLoginId',
      key: 'apiLoginId'
    },
    {
      title: 'Transaction Key',
      dataIndex: 'transactionKey',
      key: 'transactionKey'
    },
    {
      title: 'Client Key',
      dataIndex: 'clientKey',
      render: (record) => <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{record}</div>,
      key: 'clientKey'
    },
    {
      title: 'Signature Key',
      dataIndex: 'signatureKey',
      render: (record) => <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{record}</div>,
      key: 'signatureKey'
    },
    {
      title: 'Action',
      key: 'x',
      render: (_value, record) => (
        <span>
          <Popconfirm
            title='Are you sure delete this setting?'
            onConfirm={(e) => doConfirm(record.id, e)}
            onCancel={(e) => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <a
              href='/#'
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              Remove
            </a>
          </Popconfirm>
        </span>
      )
    }
  ]

  return (
    <Layout className={styles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader onBack={() => history.goBack()} title='Authorize Settings List' extra={[<CreateAuthorizeSetting key='1' />]} />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Settings', url: '/' },
            { title: 'Plugins', url: '' },
            { title: 'Authorize Settings', url: '/settings/plugins' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <Table
          columns={columns}
          dataSource={authorizeSettings}
          bordered
          rowKey={(record) => record.id}
          loading={isLoading}
          onRow={handleOnRowClick}
          pagination={{
            onChange: changeCurrentPage,
            current: currentPage,
            pageSize: size,
            total
          }}
        />
        <UpdateAuthorizeSetting authorizeSettingId={authorizeSettingId} onClose={() => setAuthorizeSettingId(undefined)} />
      </Layout.Content>
    </Layout>
  )
}

export default connector(AuthorizeSettingsList)
