import axios from 'utils/axios'
import { useQuery } from 'react-query'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Campaign } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'campaign', Campaign>

const FETCH_CAMPAIGN = `
  query fetchCampaign($campaignId: Int!) {
    campaign(campaignId: $campaignId) {
      id
      name
      domainId
      status
      domain {
        id
        name
      }
      attributes {
        attribute
        value
      }
      mailingPlugin
      mailingSettingsId
      mailingSettingsName
      hasCustomDomain
      tags
      defaultDomain
      faviconUrl
      seoTitle
      seoDescription
      seoKeywords
      googleGlobalTag
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type FetchCampaign = (campaignId?: number) => Promise<Campaign>

const fetchCampaign: FetchCampaign = async (campaignId) => {
  try {
    if (!campaignId) {
      return undefined
    }
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_CAMPAIGN,
      variables: { campaignId }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { campaign } = data
    if (!campaign) {
      throw new Error("Can't get campaign!")
    }

    return campaign
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useCampaign = (campaignId?: number) => useQuery(['campaign', campaignId], () => fetchCampaign(campaignId))
