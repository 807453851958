import React, { useEffect } from 'react'
import { Button, Drawer, Input, Form } from 'antd'
import { Store } from 'antd/lib/form/interface'
import notification from 'mrx-notification'

import { useUpdateCurrency, useCurrency } from '../../hooks'
import styles from './UpdateCurrency.module.scss'

interface FormStore extends Store {
  name: string
  code: string
  symbol: string
}

type Props = {
  currencyId?: number
  onClose: () => void
  visible: boolean
}

const UpdateCurrency = (props: Props) => {
  const {
    currencyId,
    visible,

    onClose
  } = props

  const [form] = Form.useForm()

  const { data: currency, isLoading } = useCurrency(currencyId)

  const updateCurrency = useUpdateCurrency()

  useEffect(() => {
    if (currency) {
      form.setFieldsValue({
        name: currency.name,
        code: currency.code,
        symbol: currency.symbol
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency])

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  useEffect(() => {
    if (updateCurrency.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Currency was updated successfully!'
      })
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCurrency.isSuccess])

  if (!currency) {
    return null
  }

  const onFinishHandler = (values: FormStore) => {
    updateCurrency.mutate({ currencyId, currencyData: values })
  }

  return (
    <Drawer title='Update Currency' width='400' onClose={handleClose} visible={visible}>
      <Form
        layout='vertical'
        hideRequiredMark
        onFinish={onFinishHandler}
        form={form}
        initialValues={{
          name: currency?.name,
          code: currency.code,
          symbol: currency.symbol
        }}
      >
        <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input currency name!' }]}>
          <Input style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item name='code' label='Code' rules={[{ required: true, message: 'Please input currency code!' }]}>
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name='symbol' label='Symbol' rules={[{ required: true, message: 'Please input currency symbol!' }]}>
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right'
          }}
        >
          <Button onClick={handleClose} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default UpdateCurrency
