// TODO - remove becouse of legacy

import { useInfiniteQuery } from 'react-query'
import hash from 'object-hash'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Template, TemplatesFilters } from '../types'

const DEFAULT_PAGE_SIZE = 64

export type TemplatesPage = {
  items: Template[]
  total: number
  currentPage: number
  pageSize: number
  filters?: TemplatesFilters
}

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'templatesListPage', TemplatesPage>

const FETCH_TEMPLATES_LIST_PAGE = `
  query fetchTemplatesListPage($page: Int, $size: Int, $filters: TemplatesFilters!) {
    templatesListPage(page: $page, size: $size, filters: $filters) {
      items {
        id
        name
        workspaceId
        templateType
        route
        mainPreviewThumbImageId
        mainPreviewThumbImageUrl
        mainPreviewImageId
        mainPreviewImageUrl
        previewImage1Id
        previewImage1Url
        previewImage2Id
        previewImage2Url
        previewImage3Id
        previewImage3Url
        archived
      }
      total
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type PageParam = {
  page?: number
  size?: number
  filters?: TemplatesFilters
}

type FetchTemplatesListArgs = {
  queryKey: string | string[]
  pageParam?: PageParam
}

type FetchTemplatesList = (args: FetchTemplatesListArgs) => Promise<TemplatesPage>

const fetchTemplatesList: FetchTemplatesList = async ({ pageParam }) => {
  const { page = 1, size = DEFAULT_PAGE_SIZE, filters = undefined } = pageParam || {}

  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: FETCH_TEMPLATES_LIST_PAGE,
      variables: { page, size, filters }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { templatesListPage } = data
    if (!templatesListPage) {
      throw new Error("Can't get templates list page!")
    }

    return {
      items: templatesListPage.items,
      total: templatesListPage.total,
      currentPage: page,
      pageSize: size,
      filters
    }
  } catch (error) {
    throw new Error(extractErrorInfo(error))
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

type GetNextPageParam = (args: TemplatesPage) => PageParam | undefined

type TemplatesListEvents = {
  keepPreviousData: boolean
  getPreviousPageParam: GetNextPageParam
  getNextPageParam: GetNextPageParam
}

const templatesListEvents: TemplatesListEvents = {
  keepPreviousData: true,
  getPreviousPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, filters }) => {
    if (currentPage > 1) {
      return {
        page: currentPage - 1,
        size: pageSize,
        filters
      }
    }

    return undefined
  },
  getNextPageParam: ({ pageSize = DEFAULT_PAGE_SIZE, currentPage = 0, total = 0, filters }) => {
    if (pageSize * currentPage < total) {
      return {
        page: currentPage + 1,
        size: pageSize,
        filters
      }
    }
    return undefined
  }
}

export const useTemplatesList = (filters?: TemplatesFilters) => {
  const cacheKey = ['templatesList', hash(filters)]

  return useInfiniteQuery(
    cacheKey,
    (prev) =>
      fetchTemplatesList({
        queryKey: cacheKey,
        pageParam: { ...prev.pageParam, filters }
      }),
    templatesListEvents
  )
}
