import React, { useEffect, useState } from 'react'
import { Form, Typography, Row, Col, Input, Button } from 'antd'
import { CloseCircleTwoTone, PlusOutlined } from '@ant-design/icons'
import notification from 'mrx-notification'
import { SettingOwner, SettingType } from '@merchx-v2/shared-types'
import { useUpdateSettingValue } from '../../hooks'
import { Setting } from '../../types'
import styles from './FormItemSelectArraySetting.module.scss'

type SelectArrayItem = {
  label: string
  value: string
}

type PropsType = {
  ownerType: SettingOwner
  ownerId: number
  setting: Setting
  selfUpdate?: boolean

  onValueChanged?: (setting: Setting, newValue: any) => void
}

const FormItemSelectArraySetting = (props: PropsType) => {
  const { selfUpdate = false, setting, ownerType, ownerId, onValueChanged } = props
  const [isTouched, setIsTouched] = useState<boolean>(false)
  const [selectArray, setSelectArray] = useState<SelectArrayItem[]>(setting?.jsonValue?.value || [])

  const updateSettingValue = useUpdateSettingValue()

  useEffect(() => {
    onValueChanged(setting, JSON.stringify(selectArray))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectArray, setting])

  const handleLabelChanged = (index, newValue) => {
    const updatedSelectArray = selectArray.map((item) => ({ ...item }))
    updatedSelectArray[index].label = newValue
    setIsTouched(true)
    setSelectArray(updatedSelectArray)
  }

  const handleValueChanged = (index, newValue) => {
    const updatedSelectArray = selectArray.map((item) => ({ ...item }))
    updatedSelectArray[index].value = newValue
    setIsTouched(true)
    setSelectArray(updatedSelectArray)
  }

  const handleAddItem = () => {
    setIsTouched(true)
    setSelectArray([...selectArray, { label: '', value: '' }])
  }

  const handleRemoveItem = (indexToRemove) => {
    const updatedSelectArray = [...selectArray]
    updatedSelectArray.splice(indexToRemove, 1)
    setIsTouched(true)
    setSelectArray(updatedSelectArray)
  }

  const handleSave = async () => {
    updateSettingValue.mutate(
      {
        ownerType,
        ownerId,
        settingId: setting.id,
        settingData: {
          name: setting.name,
          alias: setting.alias,
          type: setting.type as SettingType,
          value: JSON.stringify(selectArray),
          required: setting.required
        }
      },
      {
        onSuccess: () => {
          notification.success({
            message: 'Successfully',
            description: `${setting.name} setting was updated!`
          })
          setIsTouched(false)
        },
        onError: (error) => {
          notification.error({
            message: `${setting.name} setting error!`,
            description: error.toString()
          })
        }
      }
    )
  }

  return (
    <div>
      <Typography style={{ marginBottom: '10px', fontSize: '16px' }}>{setting.name} (Label / Value)</Typography>
      {selectArray.map((field, index) => {
        return (
          <Row key={field.label} className={styles.rowContainer}>
            <Col>
              <Form.Item
                name={`${setting.name}${index}label`}
                initialValue={field.label}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Please input label or delete this field.'
                  }
                ]}
              >
                <Input style={{ width: '98%' }} onChange={(e) => handleLabelChanged(index, e.target.value)} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name={`${setting.name}${index}value`}
                initialValue={field.value}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Please input value or delete this field.'
                  }
                ]}
              >
                <Input style={{ width: '98%' }} onChange={(e) => handleValueChanged(index, e.target.value)} />
              </Form.Item>
            </Col>
            <Col>
              <CloseCircleTwoTone
                twoToneColor='tomato'
                onClick={() => {
                  handleRemoveItem(index)
                }}
              />
            </Col>
          </Row>
        )
      })}
      <Form.Item>
        <Button
          type='dashed'
          onClick={() => {
            handleAddItem()
          }}
        >
          <PlusOutlined /> Add field
        </Button>
        {isTouched && selfUpdate && (
          <Button
            type='primary'
            onClick={() => {
              handleSave()
            }}
          >
            <PlusOutlined /> Save
          </Button>
        )}
      </Form.Item>
    </div>
  )
}

export default FormItemSelectArraySetting
