import React from 'react'
import styles from './PaymentAddressTab.module.scss'
import { Address } from 'features/addresses/types'

type Props = {
  address: Address
}

const PaymentAddressTab = ({ address }: Props) => {
  return (
    <div className={styles.listContainer}>
      <div className={styles.leftColumn}>
        <div className={styles.listItem}>Country: {address?.country?.name}</div>
        <div className={styles.listItem}>ZIP Code: {address.zipCode}</div>
        <div className={styles.listItem}>State: {address.state}</div>
      </div>
      <div className={styles.rightColumn}>
        <div className={styles.listItem}>City: {address.city}</div>
        <div className={styles.listItem}>Address 1: {address.address}</div>
        <div className={styles.listItem}>Address 2: {address.address2}</div>
      </div>
    </div>
  )
}

export default PaymentAddressTab
