import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import axios from 'utils/axios'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'banCustomerAccount', boolean>

const BAN_CUSTOMER_ACCOUNT = `
  mutation banCustomerAccount ($customerAccountId: Int!, $bannedTill: DateTime!) {
    banCustomerAccount(customerAccountId: $customerAccountId, bannedTill: $bannedTill)
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type BanCustomerAccountArgs = {
  customerAccountId: number
  bannedTill: Date
}

type BanCustomerAccount = (args: BanCustomerAccountArgs) => Promise<number>

const banCustomerAccount: BanCustomerAccount = async ({ customerAccountId, bannedTill }) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: BAN_CUSTOMER_ACCOUNT,
      variables: {
        customerAccountId,
        bannedTill
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { banCustomerAccount } = data
    if (!banCustomerAccount) {
      throw new Error("Can't ban customer account!")
    }

    return customerAccountId
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type BanCustomerAccountEvents = {
  onSettled: (customerId?: number) => void
}

const banCustomerAccountEvents: BanCustomerAccountEvents = {
  onSettled: (customerAccountId) => {
    if (customerAccountId) {
      queryClient.invalidateQueries(['customerAccount', customerAccountId])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useBanCustomerAccount = () => useMutation(banCustomerAccount, banCustomerAccountEvents)
