import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { ShippingRuleActionDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'removeShippingRuleAction', boolean>

const REMOVE_SHIPPING_RULE_ACTION = `
  mutation removeShippingRuleAction ($shippingRuleActionId: Int!) {
    removeShippingRuleAction(shippingRuleActionId: $shippingRuleActionId) 
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveShippingRuleAction = (shippingRuleActionId: number) => Promise<number>

const removeShippingRuleAction: RemoveShippingRuleAction = async (shippingRuleActionId) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_SHIPPING_RULE_ACTION,
      variables: {
        shippingRuleActionId
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeShippingRuleAction } = data
    if (!removeShippingRuleAction) {
      throw new Error("Can't remove shipping rule action!")
    }

    return shippingRuleActionId
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveShippingRuleActionContext = { prevShippingRuleAction?: ShippingRuleActionDto }

type RemoveShippingRuleActionEvents = {
  onMutate: (shippingRuleActionId: number) => Promise<RemoveShippingRuleActionContext>
  onError: (error: string, shippingRuleActionId: number, context: RemoveShippingRuleActionContext) => void
  onSettled: (shippingRuleActionId?: number) => void
}

const removeShippingRuleActionEvents: RemoveShippingRuleActionEvents = {
  onMutate: async (shippingRuleActionId) => {
    await queryClient.cancelQueries(['shippingRuleAction', shippingRuleActionId])

    // Snapshot the previous value
    const prevShippingRuleAction = queryClient.getQueryData<ShippingRuleActionDto>(['shippingRuleAction', shippingRuleActionId])

    if (prevShippingRuleAction) {
      queryClient.removeQueries(['shippingRuleAction', shippingRuleActionId])
    }

    return { prevShippingRuleAction }
  },
  onError: (_err, shippingRuleActionId, context) => {
    if (context?.prevShippingRuleAction) {
      // Restore currrency on any error
      queryClient.setQueryData<ShippingRuleActionDto>(['shippingRuleAction', shippingRuleActionId], context.prevShippingRuleAction)
    }
  },
  onSettled: (shippingRuleActionId) => {
    if (shippingRuleActionId) {
      queryClient.invalidateQueries(['shippingRuleAction', shippingRuleActionId])
      queryClient.invalidateQueries(['shippingRuleActionsList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useRemoveShippingRuleAction = () => useMutation(removeShippingRuleAction, removeShippingRuleActionEvents)
