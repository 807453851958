import { connect, ConnectedProps } from 'react-redux'
import { ReduxState } from 'store/createRootReducer'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

type Props = {
  workspaceId: number
}

const mapStateToProps = (state: ReduxState) => ({
  isLoading: selectors.isLoadingSelector(state, 'isInviteUser')
})

const mapDispatchToProps = {
  inviteUser: actions.inviteUser
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector> & Props
