// TODO - remove becouse of legacy

import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'
import { Landing } from '../types'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'setLandingTemplate', Landing>

const SET_LANDING_TEMPLATE = `
  mutation setLandingTemplate($landingId: Int!, $templateId: Int!) {
    setLandingTemplate(landingId: $landingId, templateId: $templateId) {
      id
      name
      campaignId
      campaign {
        id
        workspaceId
        name
        status
        defaultDomain
        createdAt
        updatedAt
      }
      workspaceId
      templateId
      deploymentPlugin
      deploymentSettingsId
      paymentPlugin
      landingType
      paymentSettingsId
      fulfillmentPlugin
      fulfillmentSettingsId
      mailingPlugin
      mailingSettingsId
      alternativeMailingPlugin
      alternativeMailingSettingsId
      smsSettingsId
      smsSettingsName
      faviconUrl
      version
      landingQuiz {
        id
        name
      }
      assets(ownerType: LANDING) {
        id
        name
        extension
        ownerType
        ownerId
        mimeType
        s3bucket
        s3key
        signedUrl
        createdAt
        updatedAt
      }
      autoresponders(ownerType: LANDING) {
        id
        ownerType
        ownerId
        autoresponderType
        sourceCode
        orderItemSourceCode
        useAlternativeEmailing
        subjectSourceCode
        version
        fromName
        settings {
          id
          ownerType
          ownerId
          name
          alias
          type
          value
          jsonValue
          required
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      components(ownerType: LANDING) {
        id
        name
        ownerType
        ownerId
        sourceCode
        sourceStyle
        createdAt
        updatedAt
      }
      dependencies(ownerType: LANDING) {
        id
        name
        ownerType
        ownerId
        version
        createdAt
        updatedAt
      }
      pages(ownerType: LANDING) {
        id
        name
        route
        order
        sourceCode
        sourceStyle
        actionsCode
        reducerCode
        settings {
          id
          ownerType
          ownerId
          name
          alias
          type
          value
          jsonValue
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      settings {
        id
        ownerType
        ownerId
        name
        alias
        type
        value
        jsonValue
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type SetLandingTemplateArgs = {
  landingId: number
  templateId: number
}

type SetLandingTemplate = (args: SetLandingTemplateArgs) => Promise<Landing>

const setLandingTemplate: SetLandingTemplate = async (args) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: SET_LANDING_TEMPLATE,
      variables: args
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { setLandingTemplate } = data
    if (!setLandingTemplate) {
      throw new Error("Can't update landing!")
    }

    return setLandingTemplate
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type SetLandingTemplateContext = { prevLanding?: Landing }

type SetLandingTemplateEvents = {
  onMutate: (variables: SetLandingTemplateArgs) => Promise<SetLandingTemplateContext>
  onError: (error: string, variables: SetLandingTemplateArgs, context: SetLandingTemplateContext) => void
  onSettled: (data?: Landing) => void
}

const setLandingTemplateEvents: SetLandingTemplateEvents = {
  onMutate: async (variables: SetLandingTemplateArgs) => {
    await queryClient.cancelQueries(['landing', variables.landingId])

    // Snapshot the previous value
    const prevLanding = queryClient.getQueryData<Landing>(['landing', variables.landingId])

    // Optimistically update to the new values
    if (prevLanding) {
      queryClient.setQueryData<Landing>(['landing', variables.landingId], {
        ...prevLanding,
        id: variables.landingId
      })
    }

    return { prevLanding }
  },
  onError: (_err, variables, context) => {
    if (context?.prevLanding) {
      // Restore previous version of landing on any error
      queryClient.setQueryData<Landing>(['landing', variables.landingId], context.prevLanding)
    }
  },
  onSettled: (data) => {
    if (data?.id) {
      queryClient.invalidateQueries(['landing', data.id])
      queryClient.invalidateQueries(['landingsList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useSetLandingTemplate = () => useMutation(setLandingTemplate, setLandingTemplateEvents)
