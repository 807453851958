import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { Product } from 'features/types'

type ProductSelector = (state: ReduxState, campaignId: number, productId: number) => Product | undefined

const getProduct: ProductSelector = (state, campaignId, productId) => {
  let result = null
  if (campaignId && productId) {
    if (Object.prototype.hasOwnProperty.call(state.products.campaignProducts, campaignId)) {
      if (Object.prototype.hasOwnProperty.call(state.products.campaignProducts[campaignId], productId)) {
        result = state.products.campaignProducts[campaignId][productId] as Product
      }
    }
  }
  return result
}

export const productSelector: ProductSelector = createSelector(getProduct, (product) => product)
