import React, { useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import { Table, Popconfirm, Layout, PageHeader } from 'antd'
import { PayPalSettingsDto } from '@merchx-v2/shared-types/dist/dto/paypalSettings/paypal-settings.dto'
import notification from 'mrx-notification'
import { ColumnsType } from 'antd/lib/table'
import useHeightResize from 'utils/useHeightResize'
import { Breadcrumbs } from 'components'
import * as rootStyles from 'assets/layoutStyle'
import styles from './PayPalSettingsList.module.scss'
import { connector, PropsFromRedux } from './container'
import CreatePayPalSetting from '../CreatePayPalSetting'
import UpdatePayPalSetting from '../UpdatePayPalSetting'

type HeightResizeRule = [number, number, number]

const rules: HeightResizeRule[] = [
  [0, 800, 6],
  [800, 1000, 7],
  [1000, 1200, 9],
  [1200, Infinity, 10]
]

const PayPalSettingsList = (props: PropsFromRedux) => {
  const {
    payPalSettings,
    isLoading,
    error,
    isNeedToFetchList,
    total,
    currentPage,
    size,

    changeCurrentPage,
    changePageSize,
    fetchPayPalSettingsList,
    removePayPalSetting
  } = props

  const { history } = useReactRouter()
  const [payPalSettingId, setPayPalSettingId] = useState<number>()

  const handleChangePageSize = (newSize: number) => {
    changePageSize(newSize)
    fetchPayPalSettingsList()
  }

  useHeightResize(handleChangePageSize, rules)

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'PayPal settings list page error!',
        description: error
      })
    }
  }, [error])

  useEffect(() => {
    isNeedToFetchList && fetchPayPalSettingsList()
  }, [fetchPayPalSettingsList, isNeedToFetchList])

  const doConfirm = async (payPalSettingId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    const isRemoved = await removePayPalSetting(payPalSettingId)
    if (isRemoved) {
      notification.success({
        message: 'Successfully',
        description: 'PayPal setting was deleted successfully!'
      })
    }
  }

  const handleOnRowClick = (record: PayPalSettingsDto) => ({
    onClick: () => {
      setPayPalSettingId(record.id)
    }
  })

  const columns: ColumnsType<PayPalSettingsDto> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Account Email',
      dataIndex: 'accountEmail',
      key: 'accountEmail'
    },
    {
      title: 'Client ID',
      dataIndex: 'clientId',
      key: 'clientId'
    },
    {
      title: 'Secret Key',
      dataIndex: 'secretKey',
      render: (record) => <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{record}</div>,
      key: 'secretKey'
    },
    {
      title: 'Access Token',
      dataIndex: 'accessToken',
      render: (record) => <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{record}</div>,
      key: 'accessToken'
    },
    {
      title: 'Action',
      key: 'x',
      render: (_value, record) => (
        <span>
          <Popconfirm
            title='Are you sure delete this setting?'
            onConfirm={(e) => doConfirm(record.id, e)}
            onCancel={(e) => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <a
              href='/#'
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              Remove
            </a>
          </Popconfirm>
        </span>
      )
    }
  ]

  return (
    <Layout className={styles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader onBack={() => history.goBack()} title='PayPal Settings List' extra={[<CreatePayPalSetting key='1' />]} />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Settings', url: '/' },
            { title: 'Plugins', url: '' },
            { title: 'PayPal Settings', url: '/settings/plugins' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <Table
          columns={columns}
          dataSource={payPalSettings}
          bordered
          rowKey={(record) => record.id}
          loading={isLoading}
          onRow={handleOnRowClick}
          pagination={{
            onChange: changeCurrentPage,
            current: currentPage,
            pageSize: size,
            total
          }}
        />
        <UpdatePayPalSetting payPalSettingId={payPalSettingId} onClose={() => setPayPalSettingId(undefined)} />
      </Layout.Content>
    </Layout>
  )
}

export default connector(PayPalSettingsList)
