import axios from 'utils/axios'
import { useMutation } from 'react-query'
import { queryClient } from 'queryClient'
import { ShippingMethodDto } from '@merchx-v2/shared-types'
import { guardFromErrors, extractErrorInfo } from 'utils/graphqlHelpers'

/// ////////////////////////////////////////////////////////////////////////////////
// GRAPHQL
/// ////////////////////////////////////////////////////////////////////////////////

type QueryResponse = GraphQLResponse<'removeShippingMethod', boolean>

const REMOVE_SHIPPING_METHOD = `
  mutation removeShippingMethod ($shippingMethodId: Int!) {
    removeShippingMethod(shippingMethodId: $shippingMethodId) 
  }
`

/// ////////////////////////////////////////////////////////////////////////////////
// FUNCTION
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveShippingMethod = (shippingMethodId: number) => Promise<number>

const removeShippingMethod: RemoveShippingMethod = async (shippingMethodId) => {
  try {
    const {
      data: { data, errors }
    }: QueryResponse = await axios.post('/graphql', {
      query: REMOVE_SHIPPING_METHOD,
      variables: {
        shippingMethodId
      }
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeShippingMethod } = data
    if (!removeShippingMethod) {
      throw new Error("Can't remove shipping method!")
    }

    return shippingMethodId
  } catch (err) {
    throw new Error(extractErrorInfo(err) as string)
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK EVENTS
/// ////////////////////////////////////////////////////////////////////////////////

type RemoveShippingMethodContext = { prevShippingMethod?: ShippingMethodDto }

type RemoveShippingMethodEvents = {
  onMutate: (shippingMethodId: number) => Promise<RemoveShippingMethodContext>
  onError: (error: string, shippingMethodId: number, context: RemoveShippingMethodContext) => void
  onSettled: (shippingMethodId?: number) => void
}

const removeShippingMethodEvents: RemoveShippingMethodEvents = {
  onMutate: async (shippingMethodId) => {
    await queryClient.cancelQueries(['shippingMethod', shippingMethodId])

    // Snapshot the previous value
    const prevShippingMethod = queryClient.getQueryData<ShippingMethodDto>(['shippingMethod', shippingMethodId])

    if (prevShippingMethod) {
      queryClient.removeQueries(['shippingMethod', shippingMethodId])
    }

    return { prevShippingMethod }
  },
  onError: (_err, shippingMethodId, context) => {
    if (context?.prevShippingMethod) {
      // Restore currrency on any error
      queryClient.setQueryData<ShippingMethodDto>(['shippingMethod', shippingMethodId], context.prevShippingMethod)
    }
  },
  onSettled: (shippingMethodId) => {
    if (shippingMethodId) {
      queryClient.invalidateQueries(['shippingMethod', shippingMethodId])
      queryClient.invalidateQueries(['shippingMethodsList'])
    }
  }
}

/// ////////////////////////////////////////////////////////////////////////////////
// HOOK
/// ////////////////////////////////////////////////////////////////////////////////

export const useRemoveShippingMethod = () => useMutation(removeShippingMethod, removeShippingMethodEvents)
