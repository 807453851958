import React from 'react'
import { Form, Switch, Typography, Checkbox } from 'antd'
import EditProductPriceVariant from '../EditProductPriceVariant'
import EditProductPriceVariantImage from '../EditProductPriceVariantImage'
import styles from './EditProductPrices.module.scss'

const EditProductPrices = () => {
  const disabledMainVariants: string[] = Form.useWatch('disabledMainVariants') || []
  const supplierProductId = Form.useWatch('supplierProductId')
  const mainPriceVariant = Form.useWatch('mainPriceVariant')

  return (
    <>
      <Form.Item noStyle dependencies={[['shouldAddMainVariant']]}>
        {(formInstance) => {
          const shouldAddMainVariant = formInstance.getFieldValue('shouldAddMainVariant')

          if (shouldAddMainVariant !== false) return null

          return (
            <div className={styles.productFormBlock}>
              <div className={styles.productFormOptionBlock}>
                <EditProductPriceVariantImage />
                <EditProductPriceVariant />
              </div>
            </div>
          )
        }}
      </Form.Item>

      <Form.Item noStyle dependencies={[['shouldAddMainVariant', 'mainVariant', 'shouldAddSecondaryVariant', 'mainVariantValues']]}>
        {(formInstance) => {
          const mainVariant = formInstance.getFieldValue('mainVariant')
          const shouldAddMainVariant = formInstance.getFieldValue('shouldAddMainVariant')
          const shouldAddSecondaryVariant = formInstance.getFieldValue('shouldAddSecondaryVariant')
          const mainVariantValues = formInstance.getFieldValue('mainVariantValues')

          if (!shouldAddMainVariant) return null
          if (!mainVariant) return null
          if (shouldAddSecondaryVariant !== false) return null
          if (!mainVariantValues?.length) return null

          return (
            <>
              {mainVariantValues.map((mainVariantValue) => (
                <div key={mainVariantValue} className={styles.productFormBlock}>
                  <div className={styles.productFormOptionBlock}>
                    <div className={styles.previewBlockItemTitle}>
                      <div className={styles.previewBlockItemName}>{mainVariantValue}</div>
                    </div>
                    <div hidden={!supplierProductId} className={styles.previewBlockItemTitle}>
                      <div className={styles.labeledItem}>
                        <Typography style={{ color: 'black', paddingBottom: '8px', whiteSpace: 'nowrap' }}>Is Active?</Typography>
                        <Switch
                          key='active-status'
                          checked={!disabledMainVariants.some((key) => key === mainVariantValue)}
                          onChange={() => {
                            if (disabledMainVariants.some((key) => key === mainVariantValue)) {
                              formInstance.setFieldValue(
                                'disabledMainVariants',
                                disabledMainVariants.filter((key) => key !== mainVariantValue)
                              )
                            } else {
                              formInstance.setFieldValue('disabledMainVariants', [...disabledMainVariants, mainVariantValue])
                            }
                          }}
                        />
                      </div>
                    </div>
                    {!disabledMainVariants.includes(mainVariantValue) && (
                      <>
                        <EditProductPriceVariantImage mainVariant={mainVariantValue} />
                        <EditProductPriceVariant mainVariant={mainVariantValue} />
                      </>
                    )}
                  </div>
                </div>
              ))}
            </>
          )
        }}
      </Form.Item>

      <Form.Item
        noStyle
        dependencies={[
          [
            'shouldAddMainVariant',
            'mainVariant',
            'shouldAddSecondaryVariant',
            'secondaryVariant',
            'mainVariantValues',
            'secondaryVariantValues',
            'removedVariantKeys'
          ]
        ]}
      >
        {(formInstance) => {
          const mainVariant = formInstance.getFieldValue('mainVariant')
          const shouldAddMainVariant = formInstance.getFieldValue('shouldAddMainVariant')
          const shouldAddSecondaryVariant = formInstance.getFieldValue('shouldAddSecondaryVariant')
          const secondaryVariant = formInstance.getFieldValue('secondaryVariant')
          const mainVariantValues = formInstance.getFieldValue('mainVariantValues')
          const secondaryVariantValues = formInstance.getFieldValue('secondaryVariantValues')
          const removedVariantKeys: string[] = formInstance.getFieldValue('removedVariantKeys') || []

          if (!shouldAddMainVariant) return null
          if (!mainVariant) return null
          if (!shouldAddSecondaryVariant) return null
          if (!secondaryVariant) return null
          if (!mainVariantValues?.length) return null
          if (!secondaryVariantValues?.length) return null

          const handleChange = (value: string) => () => {
            formInstance.setFieldValue('mainPriceVariant', value)
          }

          return (
            <>
              {mainVariantValues.map((mainVariantValue) => (
                <div key={mainVariantValue} className={styles.productFormBlock}>
                  <div className={styles.previewBlockItemTitle}>
                    <div className={styles.previewBlockItemName}>{mainVariantValue}</div>
                    <Checkbox checked={mainPriceVariant === mainVariantValue} onChange={handleChange(mainVariantValue)}>
                      Display in a lists
                    </Checkbox>
                  </div>
                  <div hidden={!supplierProductId} className={styles.previewBlockItemTitle}>
                    <div className={styles.labeledItem}>
                      <Typography style={{ color: 'black', paddingBottom: '8px', whiteSpace: 'nowrap' }}>Is Active?</Typography>
                      <Switch
                        key='active-status'
                        checked={!disabledMainVariants.some((key) => key === mainVariantValue)}
                        onChange={() => {
                          if (disabledMainVariants.some((key) => key === mainVariantValue)) {
                            formInstance.setFieldValue(
                              'disabledMainVariants',
                              disabledMainVariants.filter((key) => key !== mainVariantValue)
                            )
                          } else {
                            formInstance.setFieldValue('disabledMainVariants', [...disabledMainVariants, mainVariantValue])
                          }
                        }}
                      />
                    </div>
                  </div>

                  {!disabledMainVariants.includes(mainVariantValue) && (
                    <>
                      <EditProductPriceVariantImage mainVariant={mainVariantValue} />

                      {secondaryVariantValues
                        .filter((secondaryVariantValue) => !removedVariantKeys.includes(mainVariantValue + '/' + secondaryVariantValue))
                        .map((secondaryVariantValue) => {
                          return (
                            <div key={mainVariantValue + '/' + secondaryVariantValue} className={styles.previewBlockItem}>
                              <EditProductPriceVariant mainVariant={mainVariantValue} variant={secondaryVariantValue} />
                            </div>
                          )
                        })}
                    </>
                  )}
                </div>
              ))}
            </>
          )
        }}
      </Form.Item>
    </>
  )
}

export default EditProductPrices
