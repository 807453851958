import { createSelector } from 'reselect'
import { ReduxState } from 'store/createRootReducer'
import { Task, TasksQueueState } from '../types'
import featureStateSelector from './featureStateSelector'

export const taskQueueListSelector = createSelector<ReduxState, TasksQueueState, Task[]>(
  featureStateSelector,
  state => {
    const { pages, currentPage } = state.pagination
    return (pages[currentPage] || []).map(id => state.tasks[id])
  }
)
