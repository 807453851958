import { SettingData } from './types'

export const customGatewaySettingsData: SettingData[] = [
  {
    name: 'clientId',
    label: 'Client ID',
    rules: [{ required: true, message: 'Please input Custom Gateway setting Client ID!' }]
  },
  {
    name: 'clientSecret',
    label: 'Client Secret',
    rules: [{ required: true, message: 'Please input Custom Gateway setting Client Secret!' }]
  },
  {
    name: 'retailerApiKey',
    label: 'Retailer API Key',
    rules: [{ required: true, message: 'Please input Custom Gateway setting Retailer API Key!' }]
  },
  {
    name: 'retailerCompanyRefId',
    label: 'Retailer Company Ref Id',
    rules: [{ required: true, message: 'Please input Custom Gateway setting Retailer Company Ref Id!' }]
  },
  {
    name: 'supplierApiKey',
    label: 'Supplier API Key',
    rules: [{ required: true, message: 'Please input Custom Gateway setting Supplier API Key!' }]
  },
  {
    name: 'supplierCompanyRefId',
    label: 'Supplier Company Ref Id',
    rules: [{ required: true, message: 'Please input Custom Gateway setting Supplier Company Ref Id!' }]
  },
  {
    name: 'internalLogin',
    label: 'Internal Login',
    rules: [{ required: true, message: 'Please input Custom Gateway setting Internal Login!' }]
  },
  {
    name: 'internalPassword',
    label: 'Internal Password',
    rules: [{ required: true, message: 'Please input Custom Gateway setting Internal Password!' }]
  },
  {
    name: 'carrierCode',
    label: 'Carrier Code',
    rules: [{ required: true, message: 'Please input Custom Gateway setting Carrier Code!' }]
  },
  {
    name: 'serviceCode',
    label: 'Service Code',
    rules: [{ required: true, message: 'Please input Custom Gateway setting Service Code!' }]
  },
  {
    name: 'shippingCarrier',
    label: 'Shipping Carrier',
    rules: [{ required: true, message: 'Please input Custom Gateway setting Shipping Carrier!' }],
    options: [{ key: '1', value: 'ShipStation', title: 'ShipStation' }]
  }
]
