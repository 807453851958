import React, { useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Table, Layout, PageHeader, Popconfirm } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import notification from 'mrx-notification'
import { Breadcrumbs } from 'components'

import { useCurrenciesList, useRemoveCurrency } from '../../hooks'
import CreateCurrency from '../CreateCurrency'
import UpdateCurrency from '../UpdateCurrency'
import * as rootStyles from 'assets/layoutStyle'
import styles from './CurrenciesList.module.scss'
import { CurrencyDto } from '@merchx-v2/shared-types'

const CurrenciesList = React.memo(() => {
  const [selectedCurrencyId, setSelectedCurrencyId] = useState<number>()
  const [isUpdateDrawerVisible, setIsUpdateDrawerVisible] = useState<boolean>(false)

  const { history } = useReactRouter()

  const removeCurrency = useRemoveCurrency()
  const currenciesList = useCurrenciesList()
  const currencies: CurrencyDto[] = []
  currenciesList &&
    currenciesList.data &&
    currenciesList.data.pages.forEach((page) => page.items.forEach((currency) => currencies.push(currency)))

  const page = currenciesList.data?.pages.length ? currenciesList.data?.pages[currenciesList.data?.pages.length - 1].currentPage : 1
  const size = currenciesList.data?.pages.length ? currenciesList.data?.pages[currenciesList.data?.pages.length - 1].pageSize : 30

  useEffect(() => {
    return () => currenciesList.remove()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (currenciesList.error) {
      notification.error({
        message: 'Currencies list page error!',
        description: currenciesList.error instanceof Error ? currenciesList.error.message : currenciesList.error.toString()
      })
    }
  }, [currenciesList.error])

  useEffect(() => {
    if (removeCurrency.isSuccess) {
      notification.success({
        message: 'Successfully',
        description: 'Currency was removed successfully!'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeCurrency.isSuccess])

  const doConfirm = async (currencyId: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    removeCurrency.mutate(currencyId)
  }

  const handleOnRow = (record: CurrencyDto) => {
    return {
      onClick: () => {
        setSelectedCurrencyId(record.id)
        setIsUpdateDrawerVisible(true)
      }
    }
  }

  const handleOnCurrencyClose = () => {
    setIsUpdateDrawerVisible(false)
  }

  const columns: ColumnsType<CurrencyDto> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol'
    },
    {
      title: 'Action',
      key: 'x',
      render: (_value, record) => (
        <span>
          <Popconfirm
            title='Are you sure delete this setting?'
            onConfirm={(e) => doConfirm(record.id, e)}
            onCancel={(e) => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <a
              href='/#'
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              Remove
            </a>
          </Popconfirm>
        </span>
      )
    }
  ]

  return (
    <Layout style={rootStyles.root}>
      <Layout.Header style={rootStyles.header}>
        <PageHeader onBack={() => history.goBack()} title='Currencies List' extra={[<CreateCurrency key='1' />]} />
      </Layout.Header>
      <Layout.Content style={rootStyles.contentBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Main', url: '/' },
            { title: 'Catalogs', url: '/' },
            { title: 'Currencies List', url: '/' }
          ]}
        />
      </Layout.Content>
      <Layout.Content className={styles.contentComponent}>
        <InfiniteScroll
          style={{ paddingBottom: '30px' }}
          dataLength={page * size}
          next={currenciesList.fetchNextPage}
          hasMore={!!currenciesList.hasNextPage}
          loader={<h4>Loading...</h4>}
          refreshFunction={currenciesList.refetch}
          scrollThreshold={0.8}
        >
          <Table
            columns={columns}
            dataSource={currencies.map((item) => item).flat()}
            bordered
            rowKey={(record) => record.id}
            loading={currenciesList.isLoading}
            onRow={handleOnRow}
            pagination={false}
          />
        </InfiniteScroll>
      </Layout.Content>
      {selectedCurrencyId && (
        <UpdateCurrency currencyId={selectedCurrencyId} onClose={handleOnCurrencyClose} visible={isUpdateDrawerVisible} />
      )}
    </Layout>
  )
})

export default CurrenciesList
