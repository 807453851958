import React, { useEffect, useRef } from 'react'
import { Button, Drawer, Input, Form, Select } from 'antd'
import notification from 'mrx-notification'
import { SiteType } from '@merchx-v2/shared-types'
import { useCreateAutoresponder } from '../../hooks'
import styles from './CreateAutoresponder.module.scss'

type PropsType = {
  ownerType: SiteType
  ownerId: number
  isVisible: boolean
  onClose: () => void
  onAutoresponderCreated: (autoresponderId: number) => void
}

const CreateAutoresponder = (props: PropsType) => {
  const { ownerType, ownerId, isVisible, onClose, onAutoresponderCreated } = props

  const createAutoresponder = useCreateAutoresponder()

  const refEl = useRef<Input>(null)

  const [form] = Form.useForm()

  useEffect(() => {
    if (isVisible === true) {
      refEl.current && refEl.current.focus()
    }
  })

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  const onFinishHandler = async (values: any) => {
    createAutoresponder.mutate(
      {
        ownerType,
        ownerId,
        autoresponderData: {
          autoresponderType: values.autoresponderType,
          sourceCode: '',
          orderItemSourceCode: '',
          subjectSourceCode: '',
          fromName: ''
        }
      },
      {
        onSuccess: (data) => {
          notification.success({
            message: 'Successfully',
            description: 'Template Autoresponder was created successfully!'
          })
          onAutoresponderCreated(data.id)
          handleClose()
        },
        onError: (error) => {
          notification.error({
            message: 'Error',
            description: error.toString()
          })
        }
      }
    )
  }

  return (
    <Drawer title='Create Template Autoresponder' width='400' onClose={handleClose} visible={isVisible} forceRender>
      <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
        <Form.Item
          name='autoresponderType'
          label='Autoresponder Type'
          rules={[{ required: true, message: 'Please input template page name!' }]}
        >
          <Select>
            <Select.Option key='DOUBLE_OPT_IN' value='DOUBLE_OPT_IN'>
              Double Opt In
            </Select.Option>
            <Select.Option key='ORDER_CANCELLATION' value='ORDER_CANCELLATION'>
              Order Cancellation
            </Select.Option>
            <Select.Option key='ORDER_CONFIRMATION' value='ORDER_CONFIRMATION'>
              Order Confirmation
            </Select.Option>
            <Select.Option key='ORDER_PARTIAL_REFUND' value='ORDER_PARTIAL_REFUND'>
              Order Partial Refund
            </Select.Option>
            <Select.Option key='PARTIAL_ORDER' value='PARTIAL_ORDER'>
              Partial Order
            </Select.Option>
            <Select.Option key='SHIPPING_CONFIRMATION' value='SHIPPING_CONFIRMATION'>
              Shipping Confirmation
            </Select.Option>
            <Select.Option key='SURVEY' value='SURVEY'>
              Survey
            </Select.Option>
            <Select.Option key='VIP_CLUB_CONFIRMATION' value='VIP_CLUB_CONFIRMATION'>
              Vip Club Confirmation
            </Select.Option>
            <Select.Option key='VIP_LOGIN' value='VIP_LOGIN'>
              Vip Login
            </Select.Option>
            <Select.Option key='VIP_PRE_BILLING' value='VIP_PRE_BILLING'>
              Vip Pre Billing
            </Select.Option>
            <Select.Option key='VIP_PRE_BILLING_MONTHLY' value='VIP_PRE_BILLING_MONTHLY'>
              Vip Pre Billing Monthly
            </Select.Option>
            <Select.Option key='VIP_CANCELLATION' value='VIP_CANCELLATION'>
              Vip Cancellation
            </Select.Option>
            <Select.Option key='SUBSCRIPTION_CANCELLATION' value='SUBSCRIPTION_CANCELLATION'>
              Subscription cancellation
            </Select.Option>
            <Select.Option key='SUBSCRIPTION_WELCOME' value='SUBSCRIPTION_WELCOME'>
              Subscription welcome
            </Select.Option>
            <Select.Option key='WELCOME_ORDER_SMS' value='WELCOME_ORDER_SMS'>
              Welcome Order Sms
            </Select.Option>
            <Select.Option key='WELCOME_ORDER_2' value='WELCOME_ORDER_2'>
              Welcome Order 2
            </Select.Option>
            <Select.Option key='PARTIAL_OPT_IN_SMS' value='PARTIAL_OPT_IN_SMS'>
              Partial Opt In Sms
            </Select.Option>
          </Select>
        </Form.Item>
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right'
          }}
        >
          <Button onClick={handleClose} className={styles.closeButton}>
            Close
          </Button>
          <Button type='primary' htmlType='submit' loading={createAutoresponder.isLoading}>
            Create
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default CreateAutoresponder
